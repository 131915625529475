import moment from 'moment';

export function convertToGraphData(response) {
    let resources = [];
    let events = [];
    let indexId = 0;
    
    let func = response.sort((a, b) => Number(b.onlineStatus) - Number(a.onlineStatus))?.map((chargerSlots, index) => {
        resources = [...resources, { id: `r${index}`, name: chargerSlots?.chargePointId, onlineStatus: chargerSlots?.onlineStatus }];
        indexId++;
        return chargerSlots?.slots?.map((slots, i) => {
            let utctonormalend = new Date(slots.endDate);
            let newEndDate = new Date(utctonormalend.getTime() + slots.coolDown * 60000);
            newEndDate = moment(newEndDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "+0000";
            let currenttime = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "+0000";

            if (slots.slotStatus == 'Yellow') {
                events = [
                    ...events,
                    {
                        id: indexId,
                        start: slots.startTime,
                        end: slots.endDate,
                        resourceId: `r${index}`,
                        title: '',
                        bgColor: (chargerSlots?.onlineStatus && slots.startTime > currenttime) ? slots.slotStatus : 'darkgray',
                        reservationId: slots.reservationId,
                        resizable: false
                    }
                ];
            } else if (slots.startTime == slots.endDate) {
                events = [...events];
            }else {
                events = [
                    ...events,
                    {
                        id: indexId,
                        start: slots.startTime,
                        end: slots.endDate,
                        resourceId: `r${index}`,
                        title: '',
                        bgColor: (chargerSlots?.onlineStatus && slots.startTime > currenttime) ? slots.slotStatus : 'darkgray',
                        reservationId: slots.reservationId,
                        resizable: (chargerSlots?.onlineStatus && slots.startTime > currenttime && slots.slotStatus === 'Green') ? true : false
                    },
                    {
                        id: ++indexId,
                        start: slots.endDate,
                        end: newEndDate,
                        resourceId: `r${index}`,
                        bgColor: (chargerSlots?.onlineStatus && slots.startTime > currenttime) ? 'Grey' : 'darkgray',
                        title: '',
                        reservationId: slots.reservationId,
                        resizable: false
                    }
                ];
            }
        });
    });
    let endOutput = {
        events, resources
    }

    return endOutput;
    
}