import React from 'react';
import CsrLandingTemplate from '../Screens/CsrLandingTemplate';
import $ from 'jquery';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

//API imports 
import * as baseUrl from '../views/constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import {getSecurityHeaders} from '../common/HttpRequestClass'


 class CsrLanding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            multiple: false,
            options: [],
            selectFindStatus: false,
            saveSelectedCpid: '',
            dataArray: [],
            selectDataArray: [],
            pageSize: 10,
            activePage: 1,
            noOfRecords: '',
            errors: {},
            modalsession: false,
            intial_data: [],
            chargePoint: '',
            fields: {},
            cpIdSorting: false,
            modalView: false,
            fromDateApi: '',
            toDateApi: '',
            curPageFirstCreTime: "",
            curPageLastCreTime: "",
            prevPageFirstCreTime: "",
            listOfFirstCreatedtimes: [],
            loading: false,
        }
        this.chargepointIDChange = this.chargepointIDChange.bind(this);
        this.getCsrListing = this.getCsrListing.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.getInventorybasedonchargepoint = this.getInventorybasedonchargepoint.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
        this.getChargePoint = this.getChargePoint.bind(this);
        this.ClearChargepoint = this.ClearChargepoint.bind(this);
        this.colorCodeChange = this.colorCodeChange.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.ascendingOrder = this.ascendingOrder.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);
        this.getQuickViewList = this.getQuickViewList.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.createdDatesArray = this.createdDatesArray.bind(this);
    }

    createdDatesArray(dateTime) {
        let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
        this.setState({
            listOfFirstCreatedtimes: listOfFirstCreatedtimes
        });
    }

    chargepointIDChange(cpid) {
        this.setState({ selectFindStatus: true, saveSelectedCpid: cpid }, () => {
            if (cpid.length === 0) {
                this.getCsrListing(this.state.pageSize, this.state.activePage)
            } else {
                let errors = {};
                errors["chargePointNotExists"] = "";
                errors["selectChargepoint"] = "";
                this.setState({
                    errors: errors
                }, ()=>{
                    this.getInventorybasedonchargepoint(this.state.pageSize, this.state.activePage, cpid);
                });
            }
        });
    }
   // api to get csr listing
    async getCsrListing(pageSize, pageNo, countValue){
        document.getElementById("loader_image_div").style.display = "block";
        var url = "";
        if (this.state.selectFindStatus === true) {
            url = baseUrl.URLPath +servicesUrl.getCsrListing+"?pageSize=" + pageSize + "&pageNo=" + 1 + "&chargePointId=" + this.state.saveSelectedCpid + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi;
        } else {
            url = baseUrl.URLPath +servicesUrl.getCsrListing+"?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi;
        }   
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t,true);
        if (response !== undefined) {
            if(countValue === false){
                if(response.count.count === 0){
                    document.getElementById("loader_image_div").style.display = "none";
                } else{
                    setTimeout(() => {
                        this.setState({
                            dataArray: response.cpList,
                            selectDataArray: response.cpList,
                            prevPageFirstCreTime: this.state.curPageFirstCreTime,
                            curPageFirstCreTime: moment(response.cpList[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                            curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                        },()=>{
                            document.getElementById("loader_image_div").style.display = "none";
                        });
                    }, 100);
                }
            }else{
                if(response.count.count === 0){
                    document.getElementById("loader_image_div").style.display = "none";
                } else{
                    setTimeout(() => {
                        this.setState({
                            dataArray: response.cpList,
                            selectDataArray: response.cpList,
                            noOfRecords: response.count.count,
                            prevPageFirstCreTime: this.state.curPageFirstCreTime,
                            curPageFirstCreTime: moment(response.cpList[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                            curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                        },()=>{
                            document.getElementById("loader_image_div").style.display = "none";
                        });
                    }, 100);
                }
            }
        }
    }

    componentDidMount() {
        this.getCsrListing(this.state.pageSize, this.state.activePage);
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    async getInventorybasedonchargepoint(pageSize, pageNo, action){
        this.setState({ loading: true })
        var errors = {};
        var url = "";
        if (this.state.selectFindStatus === true) {
            url = baseUrl.URLPath + "chargepoints/csr?pageSize=" + pageSize + "&pageNo=" + 1 + "&chargePointId=" + this.state.saveSelectedCpid;
        } else {
            url = baseUrl.URLPath + "chargepoints/csr?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;
        }
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t,true);
        if(response.status === 404){
            errors["chargePointNotExists"] = this.props.t('charge_point_not_exists')
            this.setState({errors: errors}); 
        }
        if(!response.status){
            if (response.count.count === 0) {
                if (action === "click" || action === "enter") {
                    this.setState({ loading: false })
                    $("#notexists").text(this.props.t('charge_point_not_exists'));
                }
                this.setState({
                    selectDataArray: [],
                    noOfRecords: 0,
                    loading: false
                });
            } else {
                if (action === "click" || action === "enter") {                   
                    this.setState({ loading: false })
                    $("#notexists").text(" ");
                }
                this.setState({
                    selectDataArray: response.cpList,
                    noOfRecords: response.count.count,
                    loading: false
                });
            }
          
        }
    }

    handleKeyDown(ref, e) {
        var key = e.key;
        var serial_no = e.target.value;
        var sno = serial_no.slice(0, serial_no.length - 1)
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
        let errors = this.state.errors;

        if (serial_no !== 0) {
            errors["selectChargepoint"] = "";
            this.setState({ errors });
        }

        if (key === "Backspace" || key === "Delete") {
            errors["selectChargepoint"] = "";
            $("#notexists").text("");
            if (sno === "") {
                this.setState({
                    saveSelectedCpid: sno,
                    errors
                }, () => {
                    this.getCsrListing(this.state.pageSize, this.state.activePage);
                });
            }
        }

        this.getSearchInventoryList(sno);

        if (e.keyCode === 13) {
            $("#selectcharge").hide();
            this.FindCpName(ref, e);
            $("#rbt_id").hide();
        }

        if ((e.keyCode === 86 && ctrl)) {
            $("#selectcharge").hide();
            this.getSearchInventoryList(serial_no)
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9., -]");
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    async getSearchInventoryList(cpid){
    console.log("sssssssssss",cpid)
     let url = baseUrl.URLPath + servicesUrl.getChargepointsPartilaSearch+"?cpid=" + cpid;
     let type = ApiMethodTypes.GET;
     let headers = getSecurityHeaders();
     let payload = {}
     let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
     if(response){
        this.setState({
            options: response,
            intial_data: response
        });
     }
    }

    FindCpName(ref, event) {
        this.setState({
            saveSelectedCpid: ref.current.inputNode.value
        }, () => {
            if (this.state.saveSelectedCpid === null || this.state.saveSelectedCpid === "") {
                let errors = this.state.errors;
                errors['selectChargepoint'] = this.props.t('enter_chargepoint_id')
                this.setState({
                    errors: errors
                }, () => {
                    $("#notexists").text(" ");
                })
            } else {
                let errors = this.state.errors;
                errors['selectChargepoint'] = "";
                this.setState({
                    errors: errors
                }, () => {
                    this.getInventorybasedonchargepoint(this.state.pageSize, this.state.activePage, "click");
                })
            }
        })
    }

    getChargePoint(event) {
        var chargePoint = event.target.value;
        let errors = {};
        errors["chargePointNotExists"] = "";

        this.setState({
            chargePoint: chargePoint,
            errors: errors
        }, () => {
            if (chargePoint.length === 0) {
                this.getCsrListing(this.state.pageSize, this.state.activePage);
            }
        });
    }

    ClearChargepoint(ref, event) {
        let errors = this.state.errors;
        errors["selectChargepoint"] = "";
        this.setState({
            selectFindStatus: false,
            errors: errors,
            saveSelectedCpid: '',
            pageSize: 10,
            activePage: 1,
            chargePoint: ''
        }, () => {
            $("#notexists").text("");
            this.getCsrListing(this.state.pageSize, this.state.activePage);
        });
        ref.current.clear();

    }

    colorCodeChange() {
        var id = document.getElementById("selectedColor").value;
        let fields = this.state.fields;
        fields["codeId"] = id;
        this.setState({ fields: fields });
    }

    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;
        this.setState({
            activePage: 1,
            pageSize: entries
        }, () => {
            this.getCsrListing(entries, 1);
        })
    }

    //function for sorting table data on click
    ascendingOrder() {
        if (this.state.cpIdSorting === false) {
            this.setState({ cpIdSorting: true, selectDataArray: this.state.selectDataArray.sort(this.dynamicSort("chargePointId")) })
        }
        else {
            this.state.selectDataArray.sort(this.dynamicSort("chargePointId"));
            this.setState({ cpIdSorting: false, selectDataArray: this.state.selectDataArray.reverse() });
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;

        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a, b) {
            if (sortOrder === -1) {
                return b[property].localeCompare(a[property]);
            } else {

                return a[property].localeCompare(b[property]);
            }
        }
    }
  
    async getQuickViewList(chargepointid){
        var url = baseUrl.URLPath + servicesUrl.getCustomerServiceCP + "?cpid=" + chargepointid;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response) {
            this.setState({
                fields: response,
            },()=>{
                this.toggleView();
            })
        }
    }

    toggleView() {
        this.setState({
            modalView: !this.state.modalView
        });
    }

    handlePageChange(pageNumber) {
        if (pageNumber < this.state.activePage) {
            //prev
            this.setState({
                activePage: pageNumber,
                fromDateApi: this.state.curPageFirstCreTime,
                toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
            }, () => {
                this.getCsrListing(this.state.pageSize, pageNumber, false);
            });
        } else {
            //next
            let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
            this.setState({
                activePage: pageNumber,
                fromDateApi: '',
                toDateApi: this.state.curPageLastCreTime,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
            }, () => {
                if (pageNumber > _datesArrayLength + 1) {
                    this.createdDatesArray(this.state.curPageFirstCreTime);
                }
                this.getCsrListing(this.state.pageSize, pageNumber, false);
            });
        }
    }

    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }

    render() {
        return (
            <>
                <CsrLandingTemplate
                    modalsession={this.state.modalsession}
                    modalView={this.state.modalView}
                    multiple={this.state.multiple}
                    options={this.state.options}
                    selectDataArray={this.state.selectDataArray}
                    fields={this.state.fields}
                    pageSize={this.state.pageSize}
                    activePage={this.state.activePage}
                    noOfRecords={this.state.noOfRecords}
                    errors={this.state.errors}
                    sessiontagOk={this.sessiontagOk}
                    chargepointIDChange={this.chargepointIDChange}
                    handleKeyDown={this.handleKeyDown}
                    getChargePoint={this.getChargePoint}
                    clearChargepoint={this.ClearChargepoint}
                    FindCpName={this.FindCpName}
                    colorCodeChange={this.colorCodeChange}
                    showEntriesChange={this.showEntriesChange}
                    ascendingOrder={this.ascendingOrder}
                    getQuickViewList={this.getQuickViewList}
                    handlePageChange={this.handlePageChange}
                    toggleView={this.toggleView}
                    loading={this.state.loading}
                />

                {/* Loader */}
                <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>
            </>
        )
    }
}

export default withTranslation()(CsrLanding);