import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from '../views/constants';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { Alert } from 'reactstrap';

class builds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal1: false,
      startDate: new Date(),
      buildStatus: "Active",
      popupStatus: "",
      build_id: "",
      dataArray: [],
      page_title: "Builds",
      noOfRecords: '',
      pageSize: "10",
      activePage: 1,
      message: "",
      sucess: false,
      colour: 'sucess',
      models: [],
      modelName: ''
    }
    this.backToList = this.backToList.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleArchive = this.toggleArchive.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.AddArchive = this.AddArchive.bind(this);
    this.onChangeBuildSatus = this.onChangeBuildSatus.bind(this);
    this.CloseArchive = this.CloseArchive.bind(this);
    this.getModels = this.getModels.bind(this);
    this.onChangeModel = this.onChangeModel.bind(this);
  }

  onChangeModel(e) {
    this.setState({ modelName: e.target.value, activePage: 1 }, () => { this.getBuilds(this.state.pageSize, this.state.activePage) });
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.setState({ pageSize: entries });
    this.getBuilds(entries, 1);
  }

  handlePageChange(pageNumber) {
    if (pageNumber != this.state.activePage) {
      this.setState({ activePage: pageNumber }, ()=>{
        this.getBuilds(this.state.pageSize, this.state.activePage);
      });
    }
  }

  tagOk() {
    this.props.history.push('/');
    window.location.reload();
  }

  toggle = (boolean) => {
    this.setState({
      modal: boolean
    });
  }

  //toggle function for archive popup  
  toggleArchive = (build_type_id, text) => {
    this.setState({
      modal1: !this.state.modal1,
      build_id: build_type_id,
      popupStatus: text
    });
  }

  CloseArchive = () => {
    this.setState({
      modal1: !this.state.modal1
    });
  }

  // function to add in archive list 
  AddArchive() {
    this.setState({
      modal1: !this.state.modal1
    });
    var buildId = this.state.build_id;
    var isArchive_val = "";
    if (this.state.popupStatus == "Active") {
      isArchive_val = false;
    } else {
      isArchive_val = true;
    }
    var url = baseUrl.WebPath + "dashboard/build/" + buildId + "/archive?isArchive=" + isArchive_val;
    fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle(true)
          localStorage.clear();
        }
        if (resp.status == 412) {
          this.setState({ modal2: false })
          this.setState({ message: "Failed to archive as this version is prerequisite of another version.", colour: 'danger' })
          this.onShowAlert();
          return resp.json();
        }
        if (resp.status == 200) {
          this.setState({ modal2: false })
          this.setState({ message: "This build is added to " + this.state.popupStatus.toLowerCase() + " sucessfully.", colour: 'success' })
          this.onShowAlert();
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.getBuilds(this.state.pageSize, 1);
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  //change function for build status filter
  onChangeBuildSatus() {
    var Status = document.getElementById("buildStatusId").value;
    this.setState({ buildStatus: Status, activePage: 1 }, () => { this.getBuilds(this.state.pageSize, this.state.activePage); });
  }

  backToList() {
    this.props.history.push('/dx2.1ProductList')
  }

  getBuilds(pageSize, activePage) {

    let model_name = this.state.modelName;
    let position = model_name?.search(/DX/i);

    if(model_name && position > -1) {
      var url = baseUrl.WebPath + "dashboard/builds?pageSize=" + pageSize + "&pageNo=" + activePage + "&status=" + this.state.buildStatus + "&model=" + this.state.modelName;
    } else {
      var url = baseUrl.URLPath + "dashboard/builds?pageSize=" + pageSize + "&pageNo=" + activePage + "&status=" + this.state.buildStatus + "&model=" + this.state.modelName;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle(true)
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ dataArray: response.data, noOfRecords: response.count.count });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  componentDidMount() {
    this.getModels();
    this.getBuilds(this.state.pageSize, this.state.activePage);
  }

  getModels() {
    var url = baseUrl.URLPath + "cpinventory/evmodel/";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ models: response });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  render() {

    const data = this.state.dataArray.map((dataArray, index) => {
      var archiveicon = "";
      var unarchiveicon = "";
      if (dataArray.isArchive == false) {
        archiveicon = "inline-block";
        unarchiveicon = "none";
      } else {
        archiveicon = "none";
        unarchiveicon = "inline-block";
      }

      var date = moment(dataArray.created_time).format("DD-MMM-YYYY HH:mm").toUpperCase();
      var modified_date = moment(dataArray.modified_time).format("DD-MMM-YYYY HH:mm").toUpperCase();

      return (
        <tr key={index}>
          <td>{dataArray.version_name}</td>
          <td>{dataArray.version_code}</td>
          <td>{dataArray?.buildTypeName ?? "-"}</td>
          <td>{date}</td>
          <td>{modified_date}</td>
          <td>{dataArray.prerequisite}</td>
          <td>
            <a href={dataArray.download_url} download> <i class="fas fa-download action-icon" title="Download Firmware"></i></a>
            <a href={dataArray.releaseNotes_URL} download ><i class="fas fa-sticky-note pl-2 pr-2 action-icon" title="Release Notes"></i></a>
            <Link to={{ pathname: '/dxeditBuild', state: dataArray.id }} download style={{ display: archiveicon }} >
            <i class="fas fa-pencil-alt pr-2 action-icon" title="Edit"></i></Link>
            <i class="fas fa-pencil-alt pr-2 action-icon edit_archive_icon" style={{ display: unarchiveicon, color: "#000", opacity: "0.5", cursor: "auto" }} title="Release Notes"></i>
            <i class="fas fa-archive action-icon cursor_defult" style={{ display: archiveicon }} title="Archive" onClick={this.toggleArchive.bind(this, dataArray.id, "Archive")}></i>
            <label onClick={this.toggleArchive.bind(this, dataArray.id, "Active")} className="unarchive_icon mb-0 cursor_defult" title="Active" style={{ display: unarchiveicon }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20.55 5.22l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.15.55L3.46 5.22C3.17 5.57 3 6.01 3 6.5V19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.49-.17-.93-.45-1.28zM12 9.5l5.5 5.5H14v2h-4v-2H6.5L12 9.5zM5.12 5l.82-1h12l.93 1H5.12z" /></svg></label>
          </td>
        </tr>
      )
    });

    const Models = this.state?.models?.map((model, index)=>{
      if(model && model?.trim() !=""){
        return <option value={model?.trim()}>{model?.trim()}</option>
      }
    })

    return (
      <>
        <main class="content-div">
            <p> Builds
                <div className="breadcrumb_div">
                    FOTA &gt; Builds
                </div>
            </p>
          <div className="page-outerdiv">
            <div className="row menubar mt-3">
              <div className="col-md-1 mt-2">
                  <button>
                    <Link to={{ pathname: '/dxaddBuild' }}>
                      <i className="fa fa-plus"></i> <span>Add</span>
                    </Link>
                  </button>
              </div>

              <div className="col-md-3">
                <select className="browser-default custom-select select_height " id="modelId" onChange={this.onChangeModel} value={this.state.modelName}>
                  <option value="">Select Model</option>
                  {Models}
                </select>
              </div>

              <div className="col-md-3">
                <select className="browser-default custom-select select_height " id="buildStatusId" onChange={this.onChangeBuildSatus} value={this.state.buildStatus}>
                  <option value="">Select Status</option>
                  <option value="All">All</option>
                  <option value="Active">Active</option>
                  <option value="Archive">Archive</option>
                </select>
              </div>

              <div className="col-md-5">
                <div className="pull-right">
                  <label className="sub-text pull-left">Show entries</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </div>

            <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2">{this.state.message}</Alert>
    
            {/* table start */}
            <div className="row mt-1 ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th>Version</th>
                      <th>Version Code</th>
                      <th>Build Type</th>
                      <th>Release Date</th>
                      <th>Modified Date</th>
                      <th>Prerequisite</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                    {
                      (this.state.dataArray.length == 0) ?
                        <tr>
                          <td colSpan="7" style={{ textAlign: "center" }}>No Data Available</td>
                        </tr> : null
                    }
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-4">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
              {/* End table  */}
            </div>
          </div>
        </main>

        <MDBModal isOpen={this.state.modal} size="md" className="model_top">
          <MDBModalHeader>Session has expired</MDBModalHeader>
          <MDBModalBody>
            Please login again.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {/* confirmation message popup for archive  */}
        <MDBModal isOpen={this.state.modal1} toggle={this.toggleArchive} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleArchive}>Confirmation</MDBModalHeader>
          <MDBModalBody>
            Do you want to {this.state.popupStatus.toLowerCase()} this build?
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.CloseArchive}>Close</MDBBtn>
            <MDBBtn color="primary" onClick={this.AddArchive} >Yes</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default builds;