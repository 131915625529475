import moment from 'moment';
import * as baseUrl from './constants';

export function dateRangeValidateForWeek(fromDate, toDate) {
    let startDate = moment(fromDate, "YYYY-MM-DD");
    let endDate = moment(toDate, "YYYY-MM-DD");
    const ListLogDuration = localStorage.getItem('listLogDuration')-1;

    let result = endDate.diff(startDate,'days');
    let isvalid = true;
    if(ListLogDuration >= result){
        isvalid = true;
    }else{
        isvalid = false;
    }

    return isvalid;
}

export function dateRangeValidate(fromDate, toDate) {
    let DateRangeDuration = localStorage.getItem('dateRangeDuration')-1;
    let startDate = moment(fromDate, "YYYY-MM-DD");
    let endDate = moment(toDate, "YYYY-MM-DD");

    let result = endDate.diff(startDate,'days');
    let isvalid = true;
    if(DateRangeDuration >= result){
        isvalid = true;
    }else{
        isvalid = false;
    }

    return isvalid;
}

export function dateRangeValidate1(fromDate, toDate) {
    let startDate = moment(fromDate, "MM-DD-YYYY");
    let endDate = moment(toDate, "MM-DD-YYYY");
    let DateRangeDuration = localStorage.getItem('dateRangeDuration')-1;

    let result = endDate.diff(startDate,'days');
    let isvalid = true;
    if(DateRangeDuration >= result){
        isvalid = true;
    }else{
        isvalid = false;
    }

    return isvalid;
}

export function activeDateFormat(date) {
    if (date !== "") {
        let dt1 = new Date(date).getDate();
        let dt2 = new Date(date).getMonth();
        let dt3 = new Date(date).getFullYear();

        if(isNaN(dt1) || isNaN(dt2) || isNaN(dt3)){ }
        else{ return [new Date(dt3, dt2, dt1), new Date(dt3, dt2, dt1)]; }
    }
}

export function dateRangeValidateForCPlog(fromDate, toDate) {
  let DateRangeDuration = localStorage.getItem('listLogDuration')-1;
  let startDate = moment(fromDate);
  let endDate = moment(toDate);

  let result = endDate.diff(startDate,'days');
  let isvalid = true;
  if(DateRangeDuration >= result){
      isvalid = true;
  }else{
      isvalid = false;
  }
  return isvalid;
}

export function dateRangeValidateForCSCPlog(fromDate, toDate) {
  let DateRangeDuration = localStorage.getItem('listLogDuration')-1;
  let startDate = moment(fromDate);
  let endDate = moment(toDate);

  let result = endDate.diff(startDate,'days');
  let isvalid = true;
  if(DateRangeDuration >= result){
      isvalid = true;
  }else{
      isvalid = false;
  }
  return isvalid;
}

export function getDateRange(){
    let url = baseUrl.URLPath + "settings";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then((resp)=>{
      return resp.json();
    }).then((response)=>{

      //max allowed date range duration (in days) for cpms logs, cscp logs, cp logs pages
      if(response.listDurationDay!==undefined && response.listDurationDay!=="" &&response.listDurationDay!==null){
        localStorage.setItem("dateRangeDuration", response.listDurationDay);
      }else{
        localStorage.setItem("dateRangeDuration", 30);
      }

      //max allowed date range duration (in days) for cp ping pong page
      if(response.listLogDuration!==undefined && response.listLogDuration!==""&&response.listLogDuration!==null){
        localStorage.setItem("listLogDuration", response.listLogDuration);
      }else{
        localStorage.setItem("listLogDuration", 7);
      }

      //default date range duration (in days) for cscp logs page
      if(response.defaultDuration!==undefined && response.defaultDuration!==""&&response.defaultDuration!==null){
        localStorage.setItem("defaultDuration", response.defaultDuration);
      }else{
        localStorage.setItem("defaultDuration", 30);
      }

      //date range duration (in days) for Heartbeat logs page
      if(response.heartbeatDuration!==undefined && response.heartbeatDuration!==""&&response.heartbeatDuration!==null){
        localStorage.setItem("heartbeatDuration", response.heartbeatDuration);
      }else{
        localStorage.setItem("heartbeatDuration", 7);
      }

      //date range duration (in days) for Cp Ping Pong logs page
      if(response.pingPongDuration!==undefined && response.pingPongDuration!==""&&response.pingPongDuration!==null){
        localStorage.setItem("pingPongDuration", response.pingPongDuration);
      }else{
        localStorage.setItem("pingPongDuration", 7);
      }
    }).catch(()=>{
      console.log('error in getting date range duration');
    })
  }

  export function dateRangeValidateForHeartbeat(fromDate, toDate) {
    let DateRangeDuration = localStorage.getItem('heartbeatDuration')-1;
    let startDate = moment(fromDate, "YYYY-MM-DD HH:mm:ss.SSS");
    let endDate = moment(toDate, "YYYY-MM-DD HH:mm:ss.SSS");

    let result = endDate.diff(startDate,'days');
    let isvalid = true;
    if(DateRangeDuration >= result){
        isvalid = true;
    }else{
        isvalid = false;
    }

    return isvalid;
}

export function dateformatinUTC(date){
  var currentDate = new Date(date);
  var twoDigitMonth = ((currentDate.getUTCMonth() + 1) >= 10) ? (currentDate.getUTCMonth() + 1) : '0' + (currentDate.getUTCMonth() + 1);
  var twoDigitDate = ((currentDate.getUTCDate()) >= 10) ? (currentDate.getUTCDate()) : '0' + (currentDate.getUTCDate());
  var twohoursDate = ((currentDate.getUTCHours()) >= 10) ? (currentDate.getUTCHours()) : '0' + (currentDate.getUTCHours());
  var twominDate = ((currentDate.getUTCMinutes()) >= 10) ? (currentDate.getUTCMinutes()) : '0' + (currentDate.getUTCMinutes());
  var twosecDate = ((currentDate.getUTCSeconds()) >= 10) ? (currentDate.getUTCSeconds()) : '0' + (currentDate.getUTCSeconds());
  var twomillisecDate = ((currentDate.getUTCMilliseconds()) >= 10) ? (currentDate.getUTCMilliseconds()) : '0' + (currentDate.getUTCMilliseconds());
  var csFormatDate =currentDate.getUTCFullYear()+"-"+twoDigitMonth+"-"+twoDigitDate+"T"+twohoursDate+":"+twominDate+":"+twosecDate+":"+twomillisecDate;
  
  return csFormatDate;
}


export function dateformatinUTCwS(date){
  var currentDate = new Date(date);
  var twoDigitMonth = ((currentDate.getUTCMonth() + 1) >= 10) ? (currentDate.getUTCMonth() + 1) : '0' + (currentDate.getUTCMonth() + 1);
  var twoDigitDate = ((currentDate.getUTCDate()) >= 10) ? (currentDate.getUTCDate()) : '0' + (currentDate.getUTCDate());
  var twohoursDate = ((currentDate.getUTCHours()) >= 10) ? (currentDate.getUTCHours()) : '0' + (currentDate.getUTCHours());
  var twominDate = ((currentDate.getUTCMinutes()) >= 10) ? (currentDate.getUTCMinutes()) : '0' + (currentDate.getUTCMinutes());
  var twosecDate = ((currentDate.getUTCSeconds()) >= 10) ? (currentDate.getUTCSeconds()) : '0' + (currentDate.getUTCSeconds());
  var csFormatDate =currentDate.getUTCFullYear()+"-"+twoDigitMonth+"-"+twoDigitDate+"T"+twohoursDate+":"+twominDate+":"+twosecDate;

  return csFormatDate;
}



// utc time in mm-dd-yyyy hh:mm
export function  formatUTCDateandTime(currentDate){
  var twoDigitMonth=((currentDate.getUTCMonth()+1)>=10)? (currentDate.getUTCMonth()+1) : '0' + (currentDate.getUTCMonth()+1);  
  var twoDigitDate=((currentDate.getUTCDate())>=10)? (currentDate.getUTCDate()) : '0' + (currentDate.getUTCDate());
  var twominDate=((currentDate.getUTCMinutes())>=10)? (currentDate.getUTCMinutes()) : '0' + (currentDate.getUTCMinutes());
  var twohoursDate=((currentDate.getUTCHours())>=10)? (currentDate.getUTCHours()) : '0' + (currentDate.getUTCHours());
  var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" +currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
  return createdDateTo;
}

export function dateRangeValidateForCpPingPongLogs(fromDate, toDate) {
  let DateRangeDuration = localStorage.getItem('pingPongDuration')-1;
  let startDate = moment(fromDate, "YYYY-MM-DD HH:mm:ss.SSS");
  let endDate = moment(toDate, "YYYY-MM-DD HH:mm:ss.SSS");

  let result = endDate.diff(startDate,'days');
  let isvalid = true;
  if(DateRangeDuration >= result){
      isvalid = true;
  }else{
      isvalid = false;
  }

  return isvalid;
}

export function dateRangeValidateForCpmsLogs(fromDate, toDate) {
  let DateRangeDuration = localStorage.getItem('listLogDuration')-1;
  let startDate = moment(fromDate, "YYYY-MM-DD HH:mm:ss.SSS");
  let endDate = moment(toDate, "YYYY-MM-DD HH:mm:ss.SSS");

  let result = endDate.diff(startDate,'days');
  let isvalid = true;
  if(DateRangeDuration >= result){
      isvalid = true;
  }else{
      isvalid = false;
  }

  return isvalid;
}

export const formatUTC = (dateInt, addOffset) => {
  let date = (!dateInt || dateInt.length < 1) ? new Date() : new Date(dateInt);
  if (typeof dateInt === "string") {
      return date;
  } else {
      const offset = addOffset ? date.getTimezoneOffset() : -(date.getTimezoneOffset());
      const offsetDate = new Date();
      offsetDate.setTime(date.getTime() + offset * 60000)
      return offsetDate;
  }
}