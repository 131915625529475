//react & third party imports
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { hasPermission } from './views/auth';
import { withTranslation } from 'react-i18next';

import WebSocketMessage from './Socket/WebSocket';

//API Imports
import * as baseUrl from './views/constants';
import servicesUrl from './common/servicesUrl';
import APICallUtility from './common/APICallUtility';
import ApiMethodTypes from './common/ApiMethodTypes';
import { getSecurityHeaders } from './common/HttpRequestClass';

//Login Components
import Login from './views/login';
import forgotPassword from './views/forgotPassword';
import resetPassword from './views/resetpassword';
import updatePassword from './views/updatePassword';

//Common Components
import Header from './views/layout/header';
import Sidebar from './views/layout/sidebar';
import Footer from './views/layout/footer';

//Dashboard Imports
import Dashboard from './views/dashboard/dashboard';
import Inventory from './views/inventory';

//Chargepoint Imports
import ChargePoint from './Controllers/ChargePointOverview';
import ViewChargePoint from './views/viewChargePoint';
// import addChargePoint from './views/addChargePoint';
import editChargePoint from './views/editChargepoint';
import BulkFCPImport from './views/bulkFCPImport';

//Authorization Imports
import Authorization from './Controllers/Authorization';
import ViewTag from './views/viewTag';
import ocppNewTag from './views/ocppnewtag';
import editTag from './views/editTag';

//Connector Status Imports
import ConnectorStatus from './Controllers/ConnectorStatus';

//Charging Profile Imports
import ChargingProfile from './views/chargingProfile';
import ViewChargingProfile from './views/viewChargingProfile';
import AddChargingProfile from './views/addChargingProfile';
import EditChargingProfile from './views/editChargingProfile';

//Charging Schedule Imports
import ChargingSchedule from './views/chargingSchedule';
import ViewChargingSchedule from './views/viewChargingSchedule';
import AddChargingSchedule from './views/addChargingSchedule';
import EditChargingSchedule from './views/editChargingSchedule';

//Unknown CP Imports
import Unknowncp from './views/unknowncp';

//Reservation & Second Seating Imports
import ReservationsAndSecondSeating from './Controllers/ReservationsAndSecondSeating';

//Charging Session Imports
import Transactions from './views/transactions';
import meterValues from './views/meterValues';

//Settings Imports
import Setting from './views/settings';

//User Management Imports
import UserManagementController from './views/userManagementController';
import AddUserManagement from './views/addUserManagement';
import EditUser from './views/editUser';
import userLoginHistory from './views/userLoginHistory';

//Preferences Imports
import Preferences from './views/preferences';

//OAuth User Management Imports
import oauthUsers from './views/oauthUsers';
import addOauthUser from './views/addOauthUser';

//Organization Imports
import OrganisationList from './views/organisationList';
import organisationView from './views/organisationView';
import organisationAdd from './views/organisationAdd';
import OrganisationEdit from './views/organisationEdit';

//Build Notification Imports
import buildNotification from './views/buildNotification';

//Status Notification Imports
import statusnotificationlist from './views/statusnotificationlist';
import addstatusnotificationlist from './views/addstatusnotificationlist';
import EditStatusNotificationList from './views/editStatusNotificationList';

//Operations Imports
// import logs from './views/logs';
// import cpmsLogs from './views/cpmsLogs';
import ChangeAvailability from './views/changeAvailability';
import ChangeConfiguration from './views/changeConfiguration';
import ClearCatch from './views/clearCatch';
import GetDiagnostics from './views/getDiagnostics';
import RemoteStartTransaction from './views/remoteStartTransaction';
import RemoteStopTransaction from './views/remoteStopTransaction';
import Reset from './views/reset';
import UnlockConnector from './views/unlockConnector';
import UpdateFirmware from './views/updateFirmware';
import DataTransfer from './views/dataTransfer';
import GetConfiguration from './views/getConfiguration';
import GetLocalListVersion from './views/getLocalListVersion';
import SendLocalList from './views/sendLocalList';
import TriggerMessage from './views/triggerMessage';
import GetCompositeSchedule from './views/getCompositeSchedule';
import ClearChargingProfile from './views/clearChargingProfile';
import SetChargingProfile from './views/setChargingProfile';

//Maintenance Imports
import executeHistoryLogs from './views/executeHistoryLogs';
import errorLogs from './views/errorLogs';
import auditMail from './views/auditMail';
import OwtChargePointList from './views/owtChargePointList';
import InventoryList from './views/inventoryList';
import viewInventory from './views/viewInventory';
import editInventory from './views/editInventory';
import bulkCPImport from './views/bulkCPImport';
import cpPingPong from './views/cpPingPong';
import cpPingPongLogs from './views/cpPingPongLogs';
import Heartbeat from './views/Heartbeat';
import FirmwareStatus from './views/firmwareStatus';
import showDiagnostics from './views/showDiagnostics';
import uploadDiagnostics from './views/uploadDiagnostics';
import log from './views/log';
import csCpLogs from './views/csCpLogs';
import buildOrganization from './views/buildOrganization';
import OTAManagement from './Controllers/OTAManagement';
import EditOTAManagement from './Controllers/editOTAManagement';

//Market Place Imports
import vendor from './views/vendor';

//Vehicles Imports
import vehicles from './views/vehicles';

//CSR Imports
import CsrLanding from './Controllers/CsrLanding'
import viewDetails from './views/viewDetails';
import cpDashboard from './views/cpDashboard';
import StatusNotificationDescription from './views/dashboard/statusNotificationDescription';

//Notifications Imports
import Notifications from './views/notifications';
import NotificationDetails from './views/NotificationDetails';

//Group Management Imports
import SiteManagement from './views/site-management/siteManagement';

import inviteUser from './views/site-management/inviteUser';

//css imports
import './App.css';

// unblock user 
import unblockUser from './views/unblockUser';

// qr code
import QRCodeAuthorizationTags from './Controllers/QRCodeAuthorizationTags';
import QRCodeManagement from './Controllers/QRCodeManagement';

// QR Code Responsive Pages Imports
import Share from './ShareQRCode/Share';

// MK3eSIM Landing Page
import Esim from "./esimActivation/Esim";

// Webshop Subscription Pages Imports
import Mysubscription from './views/subscription';
import ServiceManagementPage from "./views/ServiceManagementPage";

// Reports pages Imports
import Reports from './views/Reports';
import ChargersReports from './views/ChargersReport';
import UserStatistics from './views/Statistics';
import userHistory from './views/userHistory';
import cloudUserHistory from './views/cloudUserHistory';

//Billing Imports
import addBuild_billing from './views/addBuildFota';
import buildOrganization_billing from './views/buildOrganization';
import Builds_Fota from './views/buildsFota';
import editBuild_billing from './views/editBuild';
import productList_billing from './views/productList';
import buildTypes_billing from './views/buildTypes';
import BillDetails_billing from './views/BillDetails';
import showOfflineData from './views/showOfflineData';
import ConvenienceFee from './views/ConvenienceFee';
import PaymentGateway from './views/PaymentGateway';

// Guest Vouchers Imports
import GuestVouchers from "./Controllers/GuestVouchers";

// Load Management Imports
import LoadManagement from './Controllers/LoadManagement';

import ApiAccess from './Controllers/ApiAccess';


const history = require('history').createBrowserHistory;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: { value: "" },
      user: true,
      page_name: "",
      count: 0,
      getOperationList: []
    }

    this.getCustomization = this.getCustomization.bind(this);
  }

  UNSAFE_componentWillMount() {
    var auth_token = localStorage.getItem("Authorization");
    if (auth_token) { localStorage.setItem("choosen_tenant", "none"); }

    this.setState({
      page_name: window.location.href
    });

    var location = window.location.href;
    var id = location.split("/");

    var x = id[3];
    var remove_after = x.indexOf('?');
    var result =  x.substring(0, remove_after);

    if ((id.length === 4 && id[3] === "") || (id[4] === "resetpassword" || id[4] === "invite" || id[4] === "unblock" || result === "share" || x === "activatesimcard")) {
      this.setState({ value: "1" });
    } else {
      var user = localStorage.getItem("user");
      if (user === null) {
        window.location.replace('/');
        this.setState({
          user: false
        })
      } else {
        this.getCustomization();
      }
    }
  }

  async getCustomization() {
    let tenant = localStorage.getItem('tenant');
    let url = baseUrl.URLPath + servicesUrl.getCustomizationWithTenant + tenant;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    this.setState({ getOperationList: response ?? [] })
  }

  render() {
    if (this.state.value === "1") {
      return (
        <>
          <BrowserRouter history={history} basename="/">
            <Route exact path="/" component={Login} />
            <Route exact path="/forgotPassword" component={forgotPassword} />
            <Route path="/:token/resetpassword" component={resetPassword} />
            <Route exact path="/updatePassword" component={updatePassword} />
            <Route path="/:token/invite" component={inviteUser} />
            <Route path="/:token/unblock" component={unblockUser} />
            <Route path="/share" component={Share} />
            <Route path="/activatesimcard" component={Esim} />
          </BrowserRouter>
          <div id='app_modal'></div>
        </>

      );
    } else if (this.state.user) {
      const user = { permissions: [] }

      if (localStorage.getItem("roleAccess") !== undefined) {
        user.permissions = localStorage.getItem("roleAccess");
      }

      return (
        <BrowserRouter history={history} basename="/">
          <div className='root'>
          <Sidebar user={user} customizationSettings={this.state.getOperationList} />

            <div id="header">
              <Header {...this.props} customizationSettings={this.state.getOperationList} />
            </div>
            <WebSocketMessage />
            {/* Landing page */}
            {/* {hasPermission(user, ['Dashboard_R']) && <Route exact path="/dashboard" component={Dashboard} />} */}
            <Route exact path="/dashboard" component={Dashboard} />
            {
              localStorage.getItem("role") === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" ? <></> :
              hasPermission(user, ['Prefereces_R']) && <Route path="/preferences" render={(props) => <Preferences {...props} customizationSettings={this.state.getOperationList} deeplink_R={hasPermission(user, ['Deeplink_Credentials_R'])} deeplink_W={hasPermission(user, ['Deeplink_Credentials_W'])} />} />
            }
            {
              this.state.getOperationList?.map((operationObject, index) => {
                if (operationObject.operation === "Dashboard") {
                  return operationObject.subOperations.map((operation, i) => {
                    if (operation.name === "Inventory" && operation.access === true) {
                      return <>
                         { hasPermission(user, ['Inventory_R']) &&  <Route path="/inventory" component={Inventory} /> }
                     </>
                    } else {
                      return <></>
                    }
                  })
                } else if (operationObject.operation === "ChargePoints" && operationObject.active === true) {
                  return operationObject.subOperations.map((operation, i) => {
                    if (operation.name === "Overview" && operation.access === true) {
                      return <>
                        {/* Chargepoints */}
                        {hasPermission(user, ['ChargePoint_R']) && <Route path="/chargePoint" render={(props) => <ChargePoint ChargePoint_W={hasPermission(user, ['ChargePoint_W'])} {...props} customizationSettings={this.state.getOperationList} />} />}
                        {/* {hasPermission(user, ['ChargePoint_W']) && <Route path="/addChargePoint" component={addChargePoint} />} */}
                        {hasPermission(user, ['ChargePoint_U']) && <Route path="/editChargePoint" component={editChargePoint} />}
                        {hasPermission(user, ['ChargePoint_R']) && <Route path="/viewChargePoint" render={(props) => <ViewChargePoint ChargePoint_W={hasPermission(user, ['ChargePoint_U'])}  customizationSettings={this.state.getOperationList}  Reservation_R={hasPermission(user, ['Resevations_R']) } {...props} />} />}
                        {hasPermission(user, ['ChargePoint_R']) && <Route path="/bulkFCPImport" render={(props) => <BulkFCPImport {...props} />} />}
                      </>
                    } else if (operation.name === "Authorization" && operation.access === true) {
                      return <>
                        {/* OCPP TAgs */}
                        {hasPermission(user, ['OCPPTags_R']) && <Route path="/Ocpptags" render={(props) => <Authorization ChargePoint_W={hasPermission(user, ['OCPPTags_W'])} {...props} />} />}
                        {hasPermission(user, ['OCPPTags_W']) && <Route path="/ocppNewTag" component={ocppNewTag} />}
                        {hasPermission(user, ['OCPPTags_R']) && <Route path="/viewTag" render={(props) => <ViewTag ChargePoint_W={hasPermission(user, ['OCPPTags_W'])} {...props} />} />}
                        {hasPermission(user, ['OCPPTags_W']) && <Route path="/editTag" component={editTag} />}
                      </>
                    } else if (operation.name === "Connectors" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['ConnectorStatus_R']) && <Route path="/connectorStatus" component={ConnectorStatus} />}
                      </>
                    } else if (operation.name === "Charging Profile" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['ChargingProfile_R']) && <Route path="/chargingProfile" render={(props) => <ChargingProfile ChargingProfile_W={hasPermission(user, ['ChargingProfile_W'])} {...props} />} />}
                        {hasPermission(user, ['ChargingProfile_W']) && <Route path="/addChargingProfile" component={AddChargingProfile} />}
                        {hasPermission(user, ['ChargingProfile_V']) && <Route path="/viewChargingProfile" component={ViewChargingProfile} />}
                        {hasPermission(user, ['ChargingSchedule_U']) && <Route path="/editChargingProfile" component={EditChargingProfile} />}
                      </>
                    } else if (operation.name === "Charging Schedule" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['ChargingSchedule_R']) && <Route path="/chargingSchedule" render={(props) => <ChargingSchedule ChargingSchedule_W={hasPermission(user, ['ChargingSchedule_W'])} {...props} />} />}
                        {hasPermission(user, ['ChargingSchedule_W']) && <Route path="/addChargingSchedule" component={AddChargingSchedule} />}
                        {hasPermission(user, ['ChargingSchedule_V']) && <Route path="/viewChargingSchedule" component={ViewChargingSchedule} />}
                        {hasPermission(user, ['ChargingProfile_U']) && <Route path="/editChargingSchedule" component={EditChargingSchedule} />}
                      </>
                    } else {
                      return <></>
                    }
                  })
                } else if (operationObject.operation === "Unknown Charge Points" && operationObject.active === true) {
                  return <>
                    {hasPermission(user, ['unknown_R']) && <Route path="/unknowncp" component={Unknowncp} />}
                  </>
                } else if (operationObject.operation === "Reservation & Second Seating" && operationObject.active === true) {
                  return <>
                    {hasPermission(user, ['Resevations_R']) && <Route path="/reservations" component={ReservationsAndSecondSeating} />}
                  </>
                } else if (operationObject.operation === "Charging Session" && operationObject.active === true) {
                  return <>
                    {hasPermission(user, ['Transactions_R']) && <Route path="/transactions" component={Transactions} />}
                    {hasPermission(user, ['Transactions_R']) && <Route path="/meterValues" component={meterValues} /> }
                  </>
                } else if (operationObject.operation === "Settings") {
                  return operationObject.subOperations.map((operation, i) => {
                    if (operation.name === "OCPP" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['settings_W']) && <Route path="/settings" component={Setting} />}
                      </>
                    } else if (operation.name === "Users" && operation.access === true) {
                      return <>
                        {/* UserManagement Pages */}
                        {hasPermission(user, ['Users_R']) && <Route path="/userManagementController" component={UserManagementController} />}
                        {hasPermission(user, ['Users_R']) && <Route path="/addUserManagement" render={(props) => <AddUserManagement {...props} />} />}
                        {hasPermission(user, ['Users_R']) && <Route path="/editUser" render={(props) => <EditUser {...props} />} />}
                        {hasPermission(user, ['Users_R']) &&  <Route path="/userLoginHistory" component={userLoginHistory} /> }                        
                        <Route path="/userHistory" component={userHistory} />                        
                        <Route path="/cloudUserHistory" component={cloudUserHistory} />
                          <Route path="/billDetails" component={BillDetails_billing} />
                      </>
                    } else if (operation.name === "OAuth Users" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['oAuthUsers_R']) && <Route path="/oauthUsers" component={oauthUsers} /> }
                        {hasPermission(user, ['oAuthUsers_R']) &&  <Route path="/addOauthUser" component={addOauthUser} /> }
                      </>
                    } else if (operation.name === "Organization" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['Organizations_R']) && <Route path="/organisationList" render={(props) => <OrganisationList ChargePoint_W={hasPermission(user, ['Organizations_W'])} {...props} />} />}
                        {hasPermission(user, ['Organizations_W']) && <Route path="/organisationAdd" component={organisationAdd} />}
                        {hasPermission(user, ['Organizations_R']) && <Route path="/organisationView" component={organisationView} />}
                        {hasPermission(user, ['Organizations_R']) && <Route path="/organisationEdit" render={(props) => <OrganisationEdit ChargePoint_W={hasPermission(user, ['Organizations_W'])} {...props} />} />}
                      </>
                    } else if (operation.name === "Build Notification" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['BuildNotification_R']) && <Route path="/buildNotification" component={buildNotification} />}
                      </>
                    } else if (operation.name === "Status Notification List" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['StatusNotifications_R']) && <Route path="/statusnotificationlist" component={statusnotificationlist} />}
                        {hasPermission(user, ['StatusNotifications_R']) && <Route path="/addstatusnotificationlist" component={addstatusnotificationlist} />}
                        {hasPermission(user, ['StatusNotifications_R']) && <Route path="/editStatusNotification" component={EditStatusNotificationList} />}
                      </>
                    } else if (operation.name == "Convenience Fee" && operation.access == true) {
                      return <>
                        {hasPermission(user, ['ConvenienceFee_R']) && <Route path="/convenienceFee" component={ConvenienceFee} />}
                      </>
                    } else if(operation.name == "Payment Gateway" && operation.access == true){
                      return <>
                      {hasPermission(user, ['PaymentGateway_R']) && <Route path="/paymentGateway" component={PaymentGateway} />}
                      </>
                    }
                    else {
                      return <></>
                    }
                  })
                } else if (operationObject.operation === "Operations" && operationObject.active === true) {
                  return operationObject.subOperations.map((operation, i) => {
                     if (operation.name === "Change Availability" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['ChangeAvailability_W']) && <Route path="/changeAvailability" component={ChangeAvailability} />}
                      </>
                    } else if (operation.name === "Change Configuration" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['ChangeConfiguration_W']) && <Route path="/changeConfiguration" component={ChangeConfiguration} />}
                      </>
                    } else if (operation.name === "Clear Cache" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['ClearCache_W']) && <Route path="/clearCatch" component={ClearCatch} />}
                      </>
                    } else if (operation.name === "Get Diagnostics" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['GetDiagnostics_W']) && <Route path="/getDiagnostics" component={GetDiagnostics} />}
                      </>
                    } else if (operation.name === "Remote Start Transaction" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['RemoteStartTransaction_W']) && <Route path="/remoteStartTransaction" component={RemoteStartTransaction} />}
                      </>
                    } else if (operation.name === "Remote Stop Transaction" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['RemoteStopTransaction_W']) && <Route path="/remoteStopTransaction" component={RemoteStopTransaction} />}
                      </>
                    } else if (operation.name === "Reset" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['Reset_W']) && <Route path="/reset" component={Reset} />}
                      </>
                    } else if (operation.name === "Unlock Connector" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['UnlockConnector_W']) && <Route path="/unlockConnector" component={UnlockConnector} />}
                      </>
                    } else if (operation.name === "Update Firmware" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['UpdateFirmware_W']) && <Route path="/updateFirmware" component={UpdateFirmware} />}
                      </>
                    } else if (operation.name === "Data Transfer" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['DataTransfer_W']) && <Route path="/dataTransfer" component={DataTransfer} />}
                      </>
                    } else if (operation.name === "Get Configuration" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['GetConfiguration_W']) && <Route path="/getConfiguration" component={GetConfiguration} />}
                      </>
                    } else if (operation.name === "Get Local List Version" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['GetLocalListVersion_W']) && <Route path="/getLocalListVersion" component={GetLocalListVersion} />}
                      </>
                    } else if (operation.name === "Send Local List" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['SendLocalList_W']) && <Route path="/sendLocalList" component={SendLocalList} />}
                      </>
                    } else if (operation.name === "Trigger Message" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['TriggerMessage_W']) && <Route path="/triggerMessage" component={TriggerMessage} />}
                      </>
                    } else if (operation.name === "Get Composite Schedule" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['GetCompositeSchedule_W']) && <Route path="/getCompositeSchedule" component={GetCompositeSchedule} />}
                      </>
                    } else if (operation.name === "Clear Charging Profile" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['ClearChargingProfile_W']) && <Route path="/clearChargingProfile" component={ClearChargingProfile} />}
                      </>
                    } else if (operation.name === "Set Charging Profile" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['SetChargingProfile_W']) && <Route path="/setChargingProfile" component={SetChargingProfile} />}
                      </>
                    } else {
                      return <></>
                    }
                  })
                } else if (operationObject.operation == "Dx2.1 FOTA" && operationObject.active == true && JSON.parse(localStorage.getItem("showDXProperties")) === true) {
                  return operationObject.subOperations.map((operation, i) => {
                    if(operation.name == "Builds" && operation.access == true){
                      return <>
                      {hasPermission(user, ['builds_R']) && <Route path="/dx2.1Builds" render={(props) => <Builds_Fota ChargePoint_W={hasPermission(user, ['builds_R'])} {...props} />} />}
                      {hasPermission(user, ['builds_R']) && <Route path="/dxaddBuild" component={addBuild_billing} />}
                      {hasPermission(user, ['builds_R']) && <Route path="/dxeditBuild" component={editBuild_billing} />}
                      {hasPermission(user, ['builds_R']) && <Route path="/dxbuildOrganization" component={buildOrganization_billing} />}
                    </>
                    } else if (operation.name == "Products" && operation.access == true) {
                      return <> 
                        {hasPermission(user, ['Products_R']) && <Route path="/dx2.1ProductList" component={productList_billing} />}
                        {hasPermission(user, ['Products_R']) && <Route path="/buildTypes" component={buildTypes_billing} />}
                      </>
                    }
                  })
                } else if (operationObject.operation === "Maintenance" && operationObject.active === true) {
                  return operationObject.subOperations.map((operation, i) => {
                    if (operation.name === "Execution History Logs" && operation.access === true) {
                      return <>
                         {hasPermission(user, ['ExecutionHostory_R']) && <Route path="/executeHistoryLogs" component={executeHistoryLogs} /> }
                        </>
                    } else if (operation.name === "Error Logs" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['ErrorLog_R']) &&<Route path="/errorLogs" component={errorLogs} /> }
                      </>
                    } else if (operation.name === "Audit Mail" && operation.access === true) {
                      return <>
                      {hasPermission(user, ['AuditMail_R']) && <Route path="/auditMail" component={auditMail} /> }
                    </>
                    } else if (operation.name === "OWT Charge Points" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['OWTInventory_R']) && <Route path="/owtChargePointList" component={OwtChargePointList} />}
                      </>
                    } else if (operation.name === "Inventory" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['Inventory_R']) && <Route path="/inventoryList" render={(props) => <InventoryList ChargePoint_W={hasPermission(user, ['BulkImport_W'])} {...props} />} />}
                        {hasPermission(user, ['Inventory_R']) && <Route path="/bulkCPImport" component={bulkCPImport} />}
                        {hasPermission(user, ['Inventory_R']) &&<Route path="/editInventory" component={editInventory} /> }
                        {hasPermission(user, ['Inventory_R']) &&<Route path="/viewInventory" component={viewInventory} /> }
                      </>
                    } else if (operation.name === "CP PingPong Old" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['cp_pingpong_R']) && <Route path="/cpPingPong" component={cpPingPong} />}
                      </>
                    } else if (operation.name === "CP PingPong" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['cp_pingpong_new_R']) && <Route path="/cpPingPongLogs" component={cpPingPongLogs} />}
                      </>
                    } else if (operation.name === "Heartbeat Logs" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['Heartbeat_Log_R']) && <Route path="/heartbeatLogs" component={Heartbeat} /> }
                      </>
                    } else if (operation.name === "Firmware Status" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['FirmwareUpdateStatus_R']) && <Route path="/firmwareStatus" component={FirmwareStatus} /> }
                      </>
                    } else if (operation.name === "Show Diagnostics" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['showDiagnostics_R']) && <Route path="/showDiagnostics" component={showDiagnostics} />}
                      </>
                    } else if (operation.name === "Upload Diagnostics" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['uploadDiagnostics_W']) && <Route path="/uploadDiagnostics" component={uploadDiagnostics} />}
                      </>
                    } else if (operation.name === "CP Logs" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['CPOprMsg_R']) && <Route path="/log" component={log} />}
                      </>
                    } else if (operation.name === "CS-CP Logs" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['CsCPLogs_R']) && <Route path="/csCpLogs" component={csCpLogs} /> }
                      </>
                    } else if (operation.name == "Show OfflineData" && operation.access == true) {
                      return <>
                      {hasPermission(user, ['showOfflineData_R']) && <Route path="/showOfflineData" component={showOfflineData} />}
                    </>
                    } else if (operation.name === "OTA Management" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['OTAManagement_R']) && <Route path="/otaManagement" render={(props) => <OTAManagement {...props} />} />}
                        {hasPermission(user, ['OTAManagement_R']) && <Route path="/editBuild" component={EditOTAManagement} />}
                        {hasPermission(user, ['PublishNotification_R']) && <Route path="/buildOrganization" component={buildOrganization} />}
                      </>
                    } else {
                      return <></>
                    }
                  })
                } else if (operationObject.operation === "Market Place" && operationObject.active === true) {
                  return operationObject.subOperations.map((operation, i) => {
                    if (operation.name === "Vendor" && operation.access === true) {
                      return <>
                         {hasPermission(user, ['Vendor_R']) && <Route path="/vendor" component={vendor} /> }
                        </>
                    } else {
                      return <></>
                    }
                  })
                } else if (operationObject.operation === "Vehicles" && operationObject.active === true) {
                  return <>
                      {hasPermission(user, ['Vehicles_R']) && <Route path="/vehicles" component={vehicles} /> }
                    </>
                } else if (operationObject.operation === "CSR" && operationObject.active === true) {
                  return <>
                    {hasPermission(user, ['CSR_R']) && <Route path="/csrLanding" component={CsrLanding} />}
                    {hasPermission(user, ['CSR_R']) && <Route path="/viewDetails" component={viewDetails} />}
                    {hasPermission(user, ['CSR_R']) && <Route path="/cpDashboard" component={cpDashboard} />}
                    {hasPermission(user, ['StatusNotifications_R']) && <Route path="/statusNotificationDescription" component={StatusNotificationDescription} /> }
                  </>
                } else if (operationObject.operation === "Notifications" && operationObject.active === true) {
                  return <>
                    {hasPermission(user, ['PushNotifications_R']) && <Route path="/notifications" component={Notifications} />}
                    <Route path="/notification" component={NotificationDetails} />
                  </>
                } else if (operationObject.operation === "Group Management" && operationObject.active === true) {
                  return operationObject.subOperations.map((operation, i) => {
                    if (operation.name === "Groups" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['Sitemanagement_R']) &&
                          <Route
                            path="/sitemanagement"
                            render={
                              (props) => <SiteManagement
                                {...props}
                                Sitemanagement_W={hasPermission(user, ['Sitemanagement_W'])}
                                Reservation_R={hasPermission(user, ['Resevations_R'])}
                                customizationSettings={this.state.getOperationList}
                              />
                            }
                          />
                        }
                      </>
                    } else if (operation.name === "Guest Vouchers" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['QRCodeManagement_R']) && <Route path="/qrcodemanagement" component={GuestVouchers} />}
                        {/* {hasPermission(user, ['QRCodeManagement_R']) && <Route path="/qrcodemanagement" component={QRCodeManagement} />} */}
                      </>
                    } 
                    else if (operation.name === "QR Code Authorizationtags" && operation.access === true) {
                      return <>
                        {hasPermission(user, ['QRCodeAuthorization_R']) && <Route path="/qrcodeauthorizationtags" component={QRCodeAuthorizationTags} />}
                      </>
                    }
                  })
                } else if (operationObject.operation == "Dx2.1 Reports" && operationObject.active == true && JSON.parse(localStorage.getItem("showDXProperties")) === true) {
                  return operationObject.subOperations.map((operation, i) => {
                    if (operation.name == "Statistics" && operation.access == true) {
                      return <>
                        {hasPermission(user, ['Statistics_R']) && <Route path="/reports" render={(props) => <Reports {...props} />} />}
                      </>
                    } else if (operation.name == "Chargers Report" && operation.access == true) {
                      return <>
                        {hasPermission(user, ['ChargersReport_R']) && <Route path="/chargersReport" render={(props) => <ChargersReports {...props} />} />}
                      </>
                    } else if (operation.name == "User Statistics" && operation.access == true) {
                      return <>
                        {hasPermission(user, ['UserStatistics_R']) && <Route path="/userStatistics" render={(props) => <UserStatistics {...props} />} />}
                      </>
                    }
                  })
                } else if (operationObject.operation === "My Subscription" && operationObject.active === true) {
                  return <>
                    {hasPermission(user, ['MySubscription_R']) &&  <Route path="/mysubscription" render={(props) => <Mysubscription MySubscription_R={hasPermission(user, ['MySubscription_R'])} {...props} />} />}
                  </>
                } else if(operationObject.operation === "Service Management" && operationObject.active === true){
                  return <>
                    {hasPermission(user, ['ServicePartner_R']) && <Route path="/servicemanagement" component={ServiceManagementPage} />}
                    
                  </>
                } else if(operationObject.operation === "Load Management" && operationObject.active === true){
                  return <>
                    {hasPermission(user, ['LoadManagement_R']) && <Route path="/loadmanagement" component={LoadManagement} />}
                    
                  </>
                } else if(operationObject.operation === "API Access" && operationObject.active === true){
                  return <>
                  {hasPermission(user, ['Apiaccess_R']) && <Route path="/apiAccess" component={ApiAccess} />}
                  </>
                }
                
              })
            }
             {/* <Route exact path="/guestvouchers" component={GuestVouchers}/> */}
             {/* <Route exact path="/loadmanagement" component={LoadManagement}/> */}
            <Footer />
            <div id='app_modal'></div>
          </div>
        </BrowserRouter>
      )
    }
  }
}

export default withTranslation()(App);
