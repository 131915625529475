/* eslint-disable no-useless-escape */
import React from 'react';
import { MDBCol } from "mdbreact";
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import moment from 'moment';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

class userManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataArray: [],
            multiple: false,
            options: [],
            requestFromDate: '',
            requesttoDate: '',
            fromDate: "",
            toDate: "",
            startDate1: '',
            activePage: 1,
            noOfRecords: '',
            data: new Map(),
            pageSize: "10",
            pageNo: 1,
            modalsession: "",
            items: [],
            summaryData: {},
            errors: {},
            recordCount: {},
            email: "",
            customRange: "",
            activeFirstDiv: "activeDate",
            activeSecDiv: "",
            activeThirdDiv: "",
            activeLastDiv: "",
        }
        this.handlePageChange = this.handlePageChange.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeRange = this.onChangeRange.bind(this);
        this.cancelDate = this.cancelDate.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.getLoginHistory = this.getLoginHistory.bind(this);
        this.backToList = this.backToList.bind(this)
    }

    backToList() {
        this.props.history.push('/userManagementController');
    }

    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;
        this.state.data.clear();
        this.setState({ pageSize: entries });
        this.getLoginHistory(entries, 1);
    }

    cancelDate() {
        var today = new Date();
        var today1 = moment(today.setDate(today.getDate())).format("YYYY-MM-DD")
        var priorDate = moment(today.setDate(today.getDate() - 7)).format("YYYY-MM-DD")

        this.setState({
            fromDate: priorDate,
            toDate: today1,
            requestfromDate: "",
            requesttoDate: "",
            customRange: "",
            activeFirstDiv: "activeDate",
            activeSecDiv: "",
            activeThirdDiv: "",
            activeLastDiv: "",
        }, ()=>{
            this.getLoginHistory(this.state.pageSize, 1);
        })
    }

    onChangeRange(dateCount, e) {

        this.setState({
            activeFirstDiv: "",
            activeSecDiv: "",
            activeThirdDiv: "",
            activeLastDiv: "",
            email: ""
        })
        if (dateCount === 7) {
            this.setState({
                activeFirstDiv: "activeDate",
            })
        }
        if (dateCount === 14) {
            this.setState({
                activeSecDiv: "activeDate",
            })
        }
        if (dateCount === 30) {
            this.setState({
                activeThirdDiv: "activeDate",
            })
        }
        if (dateCount === "Custom Range") {
            this.setState({
                activeLastDiv: "activeDate"
            })
        }

        if (dateCount === "Custom Range") {
            if (document.getElementById("customRangeDiv").classList.contains('coolclass') === false) {
                this.setState({
                    customRange: "coolclass"
                })
            }
        } else {
            var today = new Date();
            var today1 = moment(today.setDate(today.getDate())).format("YYYY-MM-DD")
            var priorDate = moment(today.setDate(today.getDate() - dateCount)).format("YYYY-MM-DD")

            this.setState({
                customRange: "",
                requestfromDate: "",
                requesttoDate: "",
                toDate: today1,
                fromDate: priorDate,
                activePage: 1
            }, ()=>{
                this.getLoginHistory(this.state.pageSize, 1);
            })
        }
    }

    onChangeDate(e) {
        this.getLoginHistory(this.state.pageSize, 1);
    }

    onChangeEmail(e) {

        var errors = {};
        errors["emailDoesnotExist"] = "";
        if (this.state.email === '') {
            errors["emailDoesnotExist"] = this.props.t('enter_email');
        } else if (this.state.email !== '') {
            if (!this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                errors["emailDoesnotExist"] = this.props.t('enter_valid_email_id');
            } else {
                this.getLoginHistory(this.state.pageSize, 1, "Find");
                this.setState({ activePage: 1 })
                errors["emailDoesnotExist"] = "";
            }
        }
        this.setState({
            errors: errors
        })

    }

    changeHandler(field, e) {

        var email = e.target.value;
        this.setState({ email: email });
        if (e.target.value === "") {

            var today2 = new Date();
            var today1 = moment(today2.setDate(today2.getDate())).format("YYYY-MM-DD");
            var priorDate = moment(today2.setDate(today2.getDate() - 7)).format("YYYY-MM-DD");
            var errors = {};
            errors["emailDoesnotExist"] = "";
            this.setState({
                errors: errors,
                email: "",
                fromDate: priorDate,
                toDate: today1,
                activeFirstDiv: "activeDate"
            })
            this.getLoginHistory(this.state.pageSize, 1);
            this.setState({ activePage: 1 });
        }
    }

    handlePageChange(pageNumber) {
        if (pageNumber !== this.state.activePage) {
            this.setState({ activePage: pageNumber }, () => {
                this.getLoginHistory(this.state.pageSize, pageNumber, "Find");
            });
        }
    }

    requestfromdateChange = date => {
        this.setState({ requestfromDate: date, fromDate: this.formatCalDate(date) });
    };

    requestTodateChange = date => {
        this.setState({ requesttoDate: date, toDate: this.formatCalDate(date) });
    }


    formatCalDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }


    componentDidMount() {
        var today = new Date();
        var today1 = moment(today.setDate(today.getDate())).format("YYYY-MM-DD")
        var priorDate = moment(today.setDate(today.getDate() - 7)).format("YYYY-MM-DD")

        this.setState({
            fromDate: priorDate,
            toDate: today1
        },()=>{
            this.getLoginHistory(this.state.pageSize, this.state.activePage);
            this.getSummary();
        })
    }

    async getSummary() {
        var url = baseUrl.URLPath + "user/loginhistory/summary";
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if (response !== undefined) {
            this.setState({
                summaryData: response.summaryLogin,
                recordCount: response.recordCount,
            })
        }

    }

    async getLoginHistory(pageSize, pageNo, action) {
        ;
        var errors = {};
        var toDate = "";
        var fromDate = "";
        var email = this.state.email;
        if (email === undefined || email === "" || action === undefined) {
            email = "";
            toDate = this.state.toDate;
            fromDate = this.state.fromDate;
        } else {
            if (action !== "") {
                this.setState({
                    activeFirstDiv: "",
                    activeSecDiv: "",
                    activeThirdDiv: "",
                    activeLastDiv: "",
                })
            }
        }
        var url = baseUrl.URLPath + "user/loginhistory?email=" + email + "&fromDate=" + fromDate + "&toDate=" + toDate + "&pageNo=" + pageNo + "&pageSize=" + pageSize;

        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if (response !== undefined) {
            if (action === "Find" && response.count.count === 0) {
                errors["emailDoesnotExist"] = this.props.t('email_id_does_not_exist')
                this.setState({
                    errors: errors
                })
            }
            this.setState({
                items: response.historyList,
                noOfRecords: response.count.count,
                dataArray: response.historyList,
            })
            var data = this.state.data;
            data.set(pageNo, response.count.count)
        }
    }


    render() {

        const { t } = this.props

        var dataList = this.state.items.map((item, index) => {
            var date = "";
            var date1 = "";
            if (item.loginDate === null || item.loginDate === "") {
                date = "";
                date1 = "";
            } else {
                date = moment(item.loginDate).format("DD MMM YYYY");
                date1 = moment(item.loginDate).format("HH:mm");
            }
            return (
                <tr>
                    <td>{item.firstName + " " + item.lastName}</td>
                    <td>{item.company}</td>
                    <td>{item.email}</td>
                    <td className="wrap-word">{date && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {date}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {date1} </div> </>}</td>
                    <td className='wrap-word'>{item.lastLoginErrorMSG}</td>
                </tr>
            );
        })


        var totalUser = "";
        var data = Object.keys(this.state.recordCount).map((key, index) => {
            if (key !== "totalUser") {
                return (
                    <div className="userType px-0 mt-1">
                        <div className="m-2px p-2px rounded h-165">
                            <img src={process.env.PUBLIC_URL + "/assets/img/man-blue.png"} className="py-2 pic-center" alt="" />
                            <div className="font-weight-bold w-100 text-center userTypeCount">{this.state.recordCount[key]}</div>
                            <div className="w-100 text-dark text-center userTypetext">{key}</div>
                        </div>
                    </div>
                );
            } else {
                totalUser = this.state.recordCount[key]
                return <></>
            }
        });

        return (
            <main class="content-div">
                <p>{t('user_login_history')}
                    <div className="breadcrumb_div">
                        {t('Settings')} &gt; <Link to="/userManagementController">{t('Users')}</Link> &gt; <span className="breadcrumb_page"> {t('user_login_history')}</span>
                    </div>
                </p>
                <div className="page-outerdiv">
                    <div className="row">
                        <div className="col-md-12">
                            <button type="button" className="btn_primary" onClick={this.backToList}>
                                <i class="fa fa-angle-left mr-2" aria-hidden="true"></i>{t('back')}
                            </button>
                            <label className="totlauser pull-right mt-1">{t('total_user')} : {totalUser}</label>
                        </div>
                    </div>
                    <div className="row mt-2 mx-0">
                        <div className="col-md-9 px-0">
                            <div className="rounded border bor-lg user-mangMainDiv" style={{ height: "auto" }}>
                                <div className="row mx-0">
                                    <div className="col-md-12 px-0">
                                        <div className="p-2px m-2px rounded text-white bg-dark text-center f-9 h-18">{t('user_type')}</div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-md-12 px-0">
                                        <div className="row mx-0 userTypeContainer">
                                            {data}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row menubar mt-3">
                                <div className="col-md-12 ">
                                    <div className="">
                                        <Form.Group className="custom_select mb-0 pull-right ml-0 selectdiv" >
                                            <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                                                <option>10</option>
                                                <option>20</option>
                                                <option>30</option>
                                                <option>40</option>
                                                <option>50</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <label className="pull-right sub-text ">{t('show_entries')}</label>
                                    </div>
                                </div>
                            </div>
                            <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        <th>{t('name')}</th>
                                        <th>{t('company')}</th>
                                        <th>{t('email')}</th>
                                        <th>{t('last_login')}</th>
                                        <th>{t('status')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList}
                                </tbody>
                            </Table>
                            <div className="col-md-12 mt-2 mb-4 text-right">
                                <Pagination
                                    hideDisabled
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.pageSize}
                                    totalItemsCount={this.state.noOfRecords}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                        </div>

                        <div className="col-md-3">
                            {/* timefilter */}
                            <div className="userType-rightdiv">
                                <div className="row mx-1">
                                    <div className={`col-md-12 userType-rightrow ${this.state.activeFirstDiv} `} onClick={this.onChangeRange.bind(this, 7)} >
                                        {t('last_7days')}
                                    </div>
                                    <div className={`col-md-12 userType-rightrow ${this.state.activeSecDiv} `} onClick={this.onChangeRange.bind(this, 14)}>
                                        {t('last_14days')}
                                    </div>
                                    <div className={`col-md-12 userType-rightrow ${this.state.activeThirdDiv} `} onClick={this.onChangeRange.bind(this, 30)}>
                                        {t('last_30days')}
                                    </div>
                                    <div className={`col-md-12 userType-rightrow ${this.state.activeLastDiv} `} onClick={this.onChangeRange.bind(this, "Custom Range")}>
                                        {t('custom_range')}
                                    </div>
                                </div>
                                <div className={`row mt-1 ${this.state.customRange}`} id="customRangeDiv" >
                                    <div className="col-md-6 pr-1 p-rel">
                                        <div className="pull-left mt-2" >
                                            <DatePicker
                                                selected={this.state.requestfromDate}
                                                onChange={date => this.requestfromdateChange(date)}
                                                timeFormat="HH:mm"
                                                timeIntervals={1}
                                                className="cal_margin tag_cal"
                                                timeCaption="time"
                                                dateFormat="dd-MMM-yyyy"
                                                placeholderText={t('from_date')}
                                                maxDate={(this.state.requesttoDate !== null && this.state.requesttoDate !== "") ? this.state.requesttoDate : new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-1 p-rel ">
                                        <div className="pull-right mt-2 rightCal" >
                                            <DatePicker
                                                selected={this.state.requesttoDate}
                                                onChange={date => this.requestTodateChange(date)}
                                                timeFormat="HH:mm"
                                                timeIntervals={1}
                                                className="cal_margin tag_cal "
                                                timeCaption="time"
                                                dateFormat="dd-MMM-yyyy"
                                                placeholderText={t('to_date')}
                                                minDate={this.state.requestfromDate}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 pr-1">
                                        <button className="btn btn-primary pl-3 pr-3" onClick={this.cancelDate}>{t('cancel')}</button>
                                    </div>
                                    <div className="col-md-6 pl-1">
                                        <button className="btn btn-primary pl-4 pr-4" onClick={this.onChangeDate} >{t('apply')}</button>
                                    </div>
                                </div>

                            </div>
                            <div className="lookup-user mt-4">
                                <div className="font-weight-bold f-12 mb-1">{t('look_upby_user')}</div>
                                <div className="row">

                                    <MDBCol md="12" className="mt-2" >
                                        <div class="input-group">
                                            <i className="fas fa-search fa-sm input-searchIcon"></i>
                                            <input type="text" id="lookbyuserId" value={this.state.email} style={{ width: "70%" }} onChange={this.changeHandler.bind(this, "email")} class="form-control input-searchBox pad-2" placeholder={t('search')} name="srch-term" />
                                            <div class="input-group-btn">
                                                <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeEmail} >{t('find')}</button>
                                            </div>
                                        </div>
                                        <span className="error_msg w3-animate-top mt-1"> <span style={{ color: "red" }}>{this.state.errors["emailDoesnotExist"]}</span></span>

                                        {/* <div className="pull-left mt-0"  >
                                    <MDBInput
                                        id = "lookbyuserId"
                                        value={this.state.email}
                                        onChange={this.changeHandler.bind(this, "email")}
                                        type="text"
                                        name="email"
                                        label="Search"
                                    >
                                    </MDBInput>
                                    </div> */}

                                    </MDBCol>
                                </div>
                            </div>
                            <div className="summary-login mt-4">
                                <div className="font-weight-bold f-12 mb-1 ">{t('summary_login')}</div>
                                <div className="f-10 rounded sum-div w-100 d-flex justify-content-between border border-0">
                                    <div>{t('total_no_of_successful_logins')}</div>
                                    <div className="text-primary">{this.state.summaryData.successAttempts}</div>
                                </div>
                                <div className="f-10 rounded sum-div w-100 d-flex justify-content-between border border-0">
                                    <div>{t('total_no_of_unsuccessful_logins')}</div>
                                    <div className="text-primary">{this.state.summaryData.failedAttempts}</div>
                                </div>
                                <div className="f-10 rounded sum-div w-100 d-flex justify-content-between border border-0">
                                    <div>{t('total_no_of_logins_attempt')}</div>
                                    <div className="text-primary">{this.state.summaryData.totalAttempts}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default withTranslation()(userManagement);