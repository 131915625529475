import React from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import * as baseUrl from './constants';
import { createBrowserHistory } from 'history';
import servicesUrl from '../common/servicesUrl';
import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();

class changeAvailability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectorID: "",
      fields: {
        connectorId: "",
        availabilityType: ''
      },
      sucess: false,
      colour: 'sucess',
      cpList: [],
      errors: {},
      file: "",
      EVSN: "",
      chargePointId: "",
      id: "",
      modalsession: false,
      chargePoint: '',
      operationId: ''
    }
    this.onShowAlert = this.onShowAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectedCP = this.getSelectedCP.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.togglesession = this.togglesession.bind(this);
  }

  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  fileChange() {
    let errors = this.state.errors;
    var imagedata = document.getElementById("buildFile").files[0];
    var simple_file_upload = document.getElementsByClassName('simple_file_upload')[0];
    simple_file_upload.nextElementSibling.setAttribute('data-file', simple_file_upload.files[0].name);
    simple_file_upload.addEventListener('focus', function () {
      simple_file_upload.classList.add('focus');
    });
    simple_file_upload.addEventListener('blur', function () {
      simple_file_upload.classList.remove('focus');
    });
    if (imagedata !== "") {
      errors["nofileError"] = "";
    }
    this.setState({
      file: imagedata, errors: errors
    });
  }

  async handleSubmit() {
    var errors = {};
    if (this.getCPList().length === 0) {
      errors["emptyChargePoint"] = this.props.t('select_charge_point')
    }
    if (this.state.file === "" || this.state.file === undefined) {
      errors["nofileError"] = this.props.t('select_diagnostics')
    }

    if (this.getCPList().length !== 0 && this.file !== '' && this.state.file !== undefined) {
      var chargePointId = localStorage.getItem('chargePointId');

      var url = baseUrl.URLPath + servicesUrl.getDiagnosticsSN + "?EVSN=" + chargePointId;

      this.setState({ errors: errors })
      var data = new FormData();
      data.append("file", this.state.file);

      fetch(url, {
        method: "PUT",
        body: data,
        headers: {
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.setState({ message: this.props.t('diagnostics_uploaded'), colour: 'success' }, ()=>{
              this.onShowAlert();
            })
          } else if (resp.status === 401) {
            window.location.reload();
          } else if (resp.status === 400) {
            return resp.json();
          } else if (resp.status === 409) {
            this.setState({ message: this.props.t('notification_exists'), colour: 'danger' }, ()=>{
              this.onShowAlert();
              return resp.json();
            })
          } else if (resp.status === 404) {
            this.setState({ message: this.props.t('diagnostics_not_exist'), colour: 'danger' },()=>{
              this.onShowAlert();
              return resp.json();
            })
          } else {
            return resp.json();
          }
        })
        .then((response) => {
          this.setState({ message: response[0].recommendation, colour: 'danger' }, ()=>{
            this.onShowAlert();
          })
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    } else {
      this.setState({ errors: errors })
    }
  }

  getCPList() {
    var cplist = this.state.cpList;
    return cplist;
  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {
    e.preventDefault();
    let fields = this.state.fields;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields: fields });
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields: fields });
    }
  }

  availabilityChange() {
    var x = document.getElementById("availabilityType").value;
    let fields = this.state.fields;
    fields["availabilityType"] = x;
    this.setState({ fields: fields });
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  getSelectedCP(cpList) {
    let errors = this.state.errors;

    if (cpList !== "") {
      errors["emptyChargePoint"] = "";
    }

    this.setState({ cpList: cpList, errors: errors });
  }

  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata !== undefined) {
      if (propsdata.chargePoint !== undefined && propsdata.operationId !== undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }
  }

  render() {
    const { t } = this.props
    return (
        <main className="content-div" >
          <p>{t('Upload Diagnostics')}
            <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('Upload Diagnostics')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>

            <MDBRow className="mb-4">
              <MDBCol sm="12">
                <div>
                  <form
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>
                      <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} ></ChargePointSelection>
                      <MDBCol md="12">
                        <span className="selection_charge_point_error w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptyChargePoint"]}</span></span>
                      </MDBCol>
                      <MDBCol md="4">
                        <label className="mt-1" >
                          <input type="file" class="simple_file_upload" id="buildFile" onChange={this.fileChange.bind(this)} />
                          <span data-file="No file choosen">{t('choose_diagnostics')}</span>
                        </label>
                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["nofileError"]}</span></span>
                      </MDBCol>
                    </MDBRow>
                    <button type="button" onClick={this.handleSubmit} className="mt-5 refresh-btn">{t('perform')}</button>
                  </form>
                </div>
              </MDBCol>
            </MDBRow>
          </div>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </main>
    );
  }
}

export default withTranslation()(changeAvailability);