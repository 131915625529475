import React from 'react';
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import * as baseUrl from './constants';
import $ from "jquery";
import { Alert } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import Loader from '../views/loader';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

import moment from 'moment';
import servicesUrl from '../common/servicesUrl';


class showDiagnostics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpIdSorting: false,
      noOfRecords: 0,
      data: new Map(),
      pageSize: 10,
      activePage: 1,
      intial_data: [],
      multiple: false,
      options: [],
      selectDataArray: [],
      saveSelectedCpid: "",
      fileName: "",
      diagnosticURL: "",
      model: false,
      startDate: new Date(),
      dataArray: [],
      getArray: [],
      getChargepoint: [],
      modal2: false,
      org_name: "",
      fields: {
        productName: "",
        description: "",
        buildType: "",
        productCode: "",
        chargePointId: ""
      },
      orgId: "",
      sucess: false,
      colour: 'sucess',
      message: "",
      errors: {},
      identifier: "",
      modal: false,
      modal1: false,
      archiveStatus: "Active",
      popupStatus: "",
      id: "",
      chargePointId: "",
      modalsession: false,
      icondisplay: "",
      loading: false
    }

    this.getDiagnosticbasedonchargepoint = this.getDiagnosticbasedonchargepoint.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
    this.chargepointIDChange = this.chargepointIDChange.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.getShowdiagnostics = this.getShowdiagnostics.bind(this);
    this.AddArchive = this.AddArchive.bind(this);
    this.toggleArchive = this.toggleArchive.bind(this);
    this.CloseArchive = this.CloseArchive.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.tagOk2 = this.tagOk2.bind(this);
    this.onClickDownloadicon = this.onClickDownloadicon.bind(this);
    this.ClearChargepoint = this.ClearChargepoint.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
  }


  // api to get chargepointId based on search filter
  chargepointIDChange(cpid) {
    this.setState({ saveSelectedCpid: cpid }, ()=>{
      if (cpid.length === 0) {
        this.getShowdiagnostics(this.state.pageSize, this.state.activePage)
      } else {
        let errors = {};
        errors["chargePointNotExists"] = "";
        errors["selectChargepoint"] = "";
        this.setState({
          errors: errors
        }, ()=>{
          this.getShowdiagnostics(this.state.pageSize, this.state.activePage, cpid);
        });
      }
    })
  }

  // search filter 
  handleKeyDown(ref, e) {
    let errors = this.state.errors;
    var serial_no = e.target.value;
    var sno = serial_no.slice(0, serial_no.length - 1);
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);

    if (serial_no !== 0) {
      errors["selectChargepoint"] = "";
      this.setState({ errors });
    }

    if (key === "Backspace" || key === "Delete") {
      errors["selectChargepoint"] = "";
      $("#notexists").text("");

      if (sno === "") {
        this.setState({ errors, saveSelectedCpid: sno }, ()=>{
          this.getShowdiagnostics(this.state.pageSize, this.state.activePage)
        });
      } else {
        this.setState({ errors });
      }
    }

    this.getSearchInventoryList(sno)

    if (e.keyCode === 13) {
      this.FindCpName(ref, "enter");
      $("#rbt_id").hide();
    }

    if ((e.keyCode === 86 && ctrl)) {
      this.getSearchInventoryList(serial_no)
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }

        break;
    }
  }

  async getSearchInventoryList(cpid) {
    var url = baseUrl.URLPath + "diagnostics/lookup/search?cpid=" + cpid;

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      this.setState({
        options: response,
        intial_data: response
      });
    }
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    
    this.setState({
      activePage: 1,
      pageSize: entries
    }, ()=>{
      this.getShowdiagnostics(this.state.pageSize, this.state.activePage);
    });
  }


  FindCpName(ref, event) {
    let errors = this.state.errors;
    let saveSelectedCpid = ref.current.inputNode.value;

    if (saveSelectedCpid === null || saveSelectedCpid === "") {
      errors["entercharge"] = this.props.t('enter_charge_point_id')
      $("#notexists").text("");
      this.setState({ errors, saveSelectedCpid })
    } else {
      errors["entercharge"] = "";
      this.setState({ errors, saveSelectedCpid, pageSize: 10, activePage: 1 }, ()=>{
        this.getShowdiagnostics(this.state.pageSize, this.state.activePage, event);
      })
    }
  }

  ClearChargepoint(ref, event) {
    let errors = this.state.errors;
    errors["selectChargepoint"] = "";
    $("#notexists").text("");

    this.setState({
      saveSelectedCpid: "",
      pageSize: 10,
      activePage: 1,
      errors
    }, ()=>{
      this.getShowdiagnostics(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid);
    });

    ref.current.clear();
  }

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      this.setState({
        activePage: pageNumber
      }, ()=>{
        this.getShowdiagnostics(this.state.pageSize, pageNumber);
      });
    }
  }

  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }

  tagOk2() {
    window.location.reload();
  }

  CloseArchive = () => {
    this.setState({
      modal1: !this.state.modal1
    });
  }

  toggleArchive = (id, text) => {
    this.setState({
      modal1: !this.state.modal1,
      id: id,
      popupStatus: text
    });
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });

  }

  // function to add in archive list 
  async AddArchive() {
    this.setState({
      modal1: !this.state.modal1
    });

    var id = this.state.id;
    var status = "";
    if (this.state.popupStatus === "Active") {
      status = "2";
    } else {
      status = "1";
    }

    var url = baseUrl.URLPath + servicesUrl.getDiagnosticsArchive + "?status=" + status + "&id=" + id;

    let type = ApiMethodTypes.PUT;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response.status === 412) {
      this.setState({ message: this.props.t('failed_msg'), colour: 'danger', modal2: false }, ()=>{
        this.onShowAlert();
      })
    } else {
      this.setState({ modal2: false }, ()=>{
        this.toggle2()
      })
    }

    this.getShowdiagnostics(this.state.pageSize, this.state.activePage);
  }

  componentDidMount() {
    var orgname = localStorage.getItem("tenant");
    this.setState({
      org_name: orgname
    });
  
    this.getShowdiagnostics(this.state.pageSize, this.state.activePage);
  }

  async getDiagnosticbasedonchargepoint() {
    if (this.state.fields.chargePointId !== "") {
      var url = baseUrl.URLPath + servicesUrl.getDiagnosticsCP + "?chargePointId=" + this.state.fields.chargePointId;
      let type = ApiMethodTypes.GET;
      let headers = getSecurityHeaders();
      let payload = {};

      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      if (response !== undefined) {
        this.setState({ getArray: response })
      }
    }
  }

  async getShowdiagnostics(pageSize, pageNo, action) {
    this.setState({ loading: true })
    var url = baseUrl.URLPath + "diagnostics/all?chargePointId=" + this.state.saveSelectedCpid + "&pageSize=" + pageSize + "&pageNo=" + pageNo;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      if (response.count.count === 0) {
        if (action === "click" || action === "enter") {
          this.setState({ loading: false })
          $("#notexists").text(this.props.t('charge_point_id_not_exists.'));
        }

        this.setState({
          getArray: [],
          noOfRecords: 0,
          selectDataArray: [],
          loading: false
        })
      } else {
        this.setState({
          getArray: response.diagnostics,
          noOfRecords: response.count.count,
          selectDataArray: response.diagnostics,
          loading: false
        })

        if (action === "click" || action === "enter") {
          this.setState({ loading: false })
          $("#notexists").text(" ");
        }
      }
    }
  }

  onClickDownloadicon(diagnosticURL) {
    if (diagnosticURL === "" || diagnosticURL === null) {
      this.setState({ message: this.props.t('file_not_exists'), colour: 'danger' }, ()=>{
        this.onShowAlert();
      })
    }
  }

  //function for sorting table data on click
  ascendingOrder() {
    let array = this.state.getArray;

    if (this.state.cpIdSorting === false) {
      array = array.sort(this.dynamicSort("chargePointId"))
      this.setState({ getArray: array, cpIdSorting: true })
    } else {
      array = array.sort(this.dynamicSort("chargePointId"))
      this.setState({ getArray: array.reverse(), cpIdSorting: false })
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  render() {
    const { t } = this.props
    const ref = React.createRef();
    let icondisplay = "none";

    const data = this.state.selectDataArray.map((getArray, index) => {
      if (getArray.diagnosticURL !== null && getArray.diagnosticURL !== "") {
        icondisplay = "inline-block"
      } else {
        icondisplay = "none"
      }

      var archiveicon = "";
      var unarchiveicon = "";
      var startTime = "";
      var startTime1 = "";

      if (getArray.archiveStatus === "2") {
        archiveicon = "inline-block";
        unarchiveicon = "none";
      } else {
        archiveicon = "none";
        unarchiveicon = "inline-block";
      }

      if (getArray.startTime === null || getArray.startTime === "") {
        startTime = "";
        startTime1 = "";
      } else {
        startTime = moment(getArray.startTime).format("DD MMM YYYY");
        startTime1 = moment(getArray.startTime).format("HH:mm");
      }

      return (
        <tr>
          <td className=""> {getArray.chargePointId}</td>
          <td className="">{getArray.fileName}</td>
          <td className="wrap-word">{startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>}</td>
          <td className=""><a href={getArray.diagnosticURL} download> <i class="fas fa-download action-icon" title="Download Diagnostics" style={{ display: icondisplay }} onClick={this.onClickDownloadicon.bind(this, getArray.diagnosticURL)}></i></a>
            <i class="fas fa-archive action-icon cursor_defult pl-2 pr-2" style={{ display: archiveicon }} title="Archive" onClick={this.toggleArchive.bind(this, getArray.id, "Archive")}></i>
            <label onClick={this.toggleArchive.bind(this, getArray.id, "Active")} className="unarchive_icon mb-0 cursor_defult pl-2 pr-2" title="Active" style={{ display: unarchiveicon }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20.55 5.22l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.15.55L3.46 5.22C3.17 5.57 3 6.01 3 6.5V19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.49-.17-.93-.45-1.28zM12 9.5l5.5 5.5H14v2h-4v-2H6.5L12 9.5zM5.12 5l.82-1h12l.93 1H5.12z" /></svg></label>
          </td>
        </tr>
      )
    })

    return (
      <>
        <main class="content-div" >
          <p>{t('Show Diagnostics')}
            <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('Show Diagnostics')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row">
              <div className="col-md-4 pull-right">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    multiple={this.state.multiple}
                    options={this.state.options}
                    minLength={3}
                    placeholder={t('enter_charge_point')}
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    onChange={this.chargepointIDChange.bind(this)}
                    id="rbt_id"
                    ref={ref}
                  />
                  <button type="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref, "click")} >{t('find')}</button>
                  </div>
                  <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.state.errors["entercharge"]}</span></span>
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>
                </div>
              </div>

              <div className="col-md-8">
                <Form.Group className="custom_select mb-0 pull-right ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
                <label className="pull-right sub-text mb-0">{t('show_entries')}</label>
              </div>
            </div>
            
            <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>

            <div className="row mt-2 ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="">{t('charge_point_id')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('file_name')}</th>
                      <th>{t('received_date')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </div>
        </main>

        <MDBModal isOpen={this.state.modal1} toggle={this.toggleArchive} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleArchive}>{t('confirmation')}</MDBModalHeader>
          <MDBModalBody>
            {t('do_you_want_to')} {this.state.popupStatus.toLowerCase()} {t('this_diagnostic')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.CloseArchive.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.AddArchive.bind(this)}>{t('yes')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('diagnostic_added_to')} {this.state.popupStatus.toLowerCase()} {t('sucessfully')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk2.bind(this)}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        {this.state.loading ? (
            <Loader loader={this.state.loading} />
            ) : ("")}
        
      </>
    );
  }
}

export default withTranslation()(showDiagnostics);