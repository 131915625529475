import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import * as baseUrl from './constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import servicesUrl from '../common/servicesUrl';
import CpSearch from '../common/chargePointSearch';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { getSecurityHeaders, getSecurityHeadersWithTimezone } from '../common/HttpRequestClass';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';

class Ocppnewtag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { idTag: '', parentIdTag: '', expiryDate: '', inTransaction: '0', note: '', blocked: '0', tagType: 'other' },
      sucess: {},
      errors: {},
      activeItem: "1",
      dataArray: "",
      isChecked: "1",
      startDate1: "",
      startDate: "",
      model: "false",
      page_title: "Add OCPP Tag",
      modalsession: false,
      allCPList: [],
      acpId: '',
      noWallboxExists: '',
      selectedItems: [],
      associatedCPList: [],
      RfidAccessVal: false,
      isFreeChargeEnabled: false,
      deletedWallboxes: [],
      fromDateApi: "",
      toDateApi: "",
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: [],
      activePage: 1,
      cpid:"",
      pageSize: 30,
      modal: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.redirectToListPage = this.redirectToListPage.bind(this)
    this.toggle = this.toggle.bind(this)
    this.tagOk = this.tagOk.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.rfidTypeChange = this.rfidTypeChange.bind(this);
    this.getAllCpList = this.getAllCpList.bind(this);
    this.getCustomization = this.getCustomization.bind(this);
    this.getSecurityData = this.getSecurityData.bind(this);
    this.handleWallboxChange = this.handleWallboxChange.bind(this);
    this.clearWallboxerror = this.clearWallboxerror.bind(this);
    this.handleWallboxPress = this.handleWallboxPress.bind(this);
    this.isItemSelected = this.isItemSelected.bind(this);
    this.updateCheckboxSelection = this.updateCheckboxSelection.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.removeSelectedItem = this.removeSelectedItem.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.hideLoder = this.hideLoder.bind(this);
    this.resetAsyncTimes = this.resetAsyncTimes.bind(this);
  }

  resetAsyncTimes() {
    this.setState({
      fromDateApi: "",
      toDateApi: ""
    })
  }

  rfidTypeChange() {
    let val = document.getElementById('rfidTagType').value;
    let field = this.state.fields;
    field["tagType"] = val;
    this.setState({ fields: field });
    $('.error_tagType').text("");
  }

  redirectToListPage() {
    this.props.history.push('/Ocpptags')
  }

  handleChange = date => {
    var currentDate = new Date(date);
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var createdDateTo = twoDigitMonth + "-" + twoDigitDate + "-" + currentDate.getFullYear() + ' ' + twohoursDate + ':' + twominDate;

    this.setState({ startDate1: createdDateTo, startDate: date });
  };

  tagOk() {
    this.props.history.push('/Ocpptags');
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    let errors = {};
    var idTag = this.state.fields['idTag'];
    var tag_type = this.state.fields["tagType"];

    if (idTag === '') {
      errors["idtagempty"] = this.props.t('enter_a_id_tag');
    }

    if (idTag === "0815" ) {
      errors["idtagempty"] = this.props.t('rfid_tag_reserved_for_free_charging');
    }

    if (tag_type === '') {
      $('.error_tagType').text(this.props.t('select_rfid_tag_type'));
    }

    let valid = true;

    if ((this.state.RfidAccessVal === true && this.state.isFreeChargeEnabled === false) > 0) {
      if (!this.state.selectedItems.length > 0) {
        $('.error_cpids').text(this.props.t('select_atleast_onecharge_point'));
        valid = false;
      } else {
        valid = true;
      }
    }

    if (idTag !== '' && tag_type !== '' && valid && idTag !== "0815") {
      if (!idTag.match(/^[0-9a-zA-Z]{0,20}$/)) {
        errors["entervalididtag"] = this.props.t('enter_valid_id_tag');
      } else {
        let url = baseUrl.URLPath + "ocpptags";
        let data = {
          "idTag": this.state.fields.idTag,
          "expiryDate": this.state.startDate1,
          "inTransaction": this.state.fields.inTransaction,
          "note": this.state.fields.note,
          "blocked": this.state.fields.blocked,
          "parentIdTag": { "idTag": this.state.fields.parentIdTag },
          "tagType": this.state.fields.tagType,
          "associatedCPList": this.state.selectedItems,
          "deAssociatedCPList": this.state.deletedWallboxes
        }

        document.getElementById("loader_image_div").style.display = "block";

        let type = ApiMethodTypes.POST;
        let headers = getSecurityHeadersWithTimezone();

        let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t, true);

        if(response){
          if (response.status === 404) {
            errors["entervalidID"] = this.props.t('enter_valid_id_tag')
            this.setState({ errors: errors }, ()=>{
              this.hideLoder();
            });
          } else if (response.status === 400) {
            errors["equalids"] = this.props.t('enter_valid_id_tag')
            this.setState({ errors: errors }, ()=>{
              this.hideLoder();
            });
          } else if (response.status === 409) {
            errors["idtagexist"] = this.props.t('id_tag_is_exists')
            this.setState({ errors: errors }, ()=>{
              this.hideLoder();
            });
          } else {
            if (response !== null && response.hasOwnProperty("errorList")) {
              this.hideLoder();
              if (response.errorList[0] === this.props.t('id_and_parent_tag_not_be_equal')) {
                errors["parentchild"] = this.props.t('Id_tag_and_parent_tag_not_same');
              } else if (response.errorList[0] === this.props.t('child_id_not_become_parent_of_another_id')) {
                errors["childtag"] = this.props.t('child_tag_not_become_the_parent_of_another_tag');
              } else if (response.errorList[0] === this.props.t('enter_valid_parent_id_tag')) {
                errors["entervalidtag"] = this.props.t('enter_valid_parent_id_tag');
              }
            } else if(response && response?.message === "new_ocpp_tag_is_added_successfully"){
              this.hideLoder();
              this.toggle();
            }

            this.setState({
              errors: errors
            });
          }
        }
      }
    }

    this.setState({ errors: errors });
  }

  hideLoder() {
    document.getElementById("loader_image_div").style.display = "none";
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    var errors = {};
    if (fields[field] === 0) {
      errors["idtagempty"] = "";
      this.setState({ errors: errors })
    } else {
      errors["idtagempty"] = "";
      this.setState({ errors: errors, fields })
    }
  }

  componentDidMount() {
    document.getElementById("loader_image_div").style.display = "block";
    setTimeout(() => {
      this.getCustomization();
      this.getSecurityData();
      this.getAllCpList(true);
      document.getElementById("loader_image_div").style.display = "none";
    }, 5000);
  }

  async getAllCpList(countValue, cpid, text, status) {
    var cpId = "";
    var fromDateApi = this.state.fromDateApi;
    var toDateApi = this.state.toDateApi;

    if (cpid !== "" && cpid !== undefined && cpid !== null) {
      cpId = cpid
      this.setState({
        activePage: 1,
        pageSize: this.state.pageSize,
        cpId: cpid
      })
      toDateApi = "";
    }

    if (text === "Clear") {
      this.setState({
        cpId: cpid
      })
    }
    if(status === "Clear"){
      this.setState({
        fromDateApi: fromDateApi,
        toDateApi: toDateApi
      })
    }
    document.getElementById("loader_image_div").style.display = "block";

    var url = baseUrl.URLPath + servicesUrl.getAllCplistForNewRfid + "?cpid=" + cpId + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi + "&pageSize=" + this.state.pageSize;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

    if (response.data !== undefined) {
      var noWallboxExists1 = 1;
      if (cpId !== "" && response.data) {
        localStorage.setItem("noWallboxExists", response.count.count);
        this.hideLoder();
      }

      if (response.data.length === 0) {
        setTimeout(() => {
          this.setState({
            // associatedCPList: [],
            // anoOfRecords: response.count.count,
          }, () => {
            this.hideLoder();
          });
        }, 100);
      }
      else {
        var chargePoint;
        chargePoint = Array.from(Object.values(response.data), cp => cp.chargePointId);
        var totalcount = "";

        if (countValue === false) {
          totalcount = this.state.noOfRecords
        } else {
          totalcount = response.count.count
        }
        setTimeout(() => {
        this.setState({
          allCPList: response.data,
          noOfRecords: totalcount,
          noWallboxExists: noWallboxExists1,
          prevPageFirstCreTime: this.state.curPageFirstCreTime,
          curPageFirstCreTime: moment(response.data[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
          curPageLastCreTime: moment(response.data[response.data.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS")
        }, () => {
          this.hideLoder();
        });
      }, 100)
      }
    }
  }

  async getCustomization() {
    let tenant = localStorage.getItem('tenant');
    let url = baseUrl.URLPath + servicesUrl.getCustomizationWithTenant + tenant;

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      let accessVal = false;

      for (let operation in response) {
        if (response[operation].operation === 'Charging Session') {
          for (let subOperations in response[operation].subOperations) {
            if (response[operation].subOperations[subOperations].name === "RFID Access Control") {
              accessVal = response[operation].subOperations[subOperations].access;
            }
          }
        }
      }

      this.setState({
        RfidAccessVal: accessVal
      })
    }
  }

  /* To get SecurityData list*/
  async getSecurityData() {
    let url = baseUrl.URLPath + servicesUrl.getSecurityPreferences;

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({
        isFreeChargeEnabled: response.isFreeChargeEnabled
      })
    }
  }

  handleWallboxChange(e) {
    this.setState({
      cpid: e.target.value
    }, () => {
      this.clearWallboxerror();
      if (this.state.cpid.length === 0) {
        this.getRFIDCPlist();
      }
    });
  }

  clearWallboxerror() {
    this.setState({ noWallboxExists: '' });
  }

  handleWallboxPress(e) {
    var key = e.key;

    if (e.keyCode === 13) {
      this.setState({ cpid: e.target.value }, ()=>{
        this.getAllCpList();
      });
    }

    if ((key === "Backspace" || key === "Delete") && e.target.value.length === 1) {
      this.setState({
        cpid: ""
      }, () => {
        this.clearWallboxerror();
        this.getAllCpList();
      });
    }
  }

  isItemSelected(item) {
    const retVal = this.state.selectedItems.find(el => el === item) ? true : false;
    return retVal;
  }

  updateCheckboxSelection(event, item) {
    const allItems = [...this.state.selectedItems];
    const deletedWallboxes = [...this.state.deletedWallboxes];
    const selectedItemIndex = allItems.findIndex(el => el === item);
    $('.error_cpids').text("");

    if (event.target.checked) {
      const isAlreadyDeleted = deletedWallboxes.findIndex(el => el === item);
      allItems.push(item);
      deletedWallboxes.splice(isAlreadyDeleted, 1);
    } else {
      allItems.splice(selectedItemIndex, 1);
      deletedWallboxes.push(item);
    }

    this.setState({ ...this.state, selectedItems: allItems, deletedWallboxes });
  }

  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
      //prev
      this.setState({
        activePage: pageNumber,
        fromDateApi: this.state.curPageFirstCreTime,
        toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getAllCpList(false);
      });
    } else {
      //next
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({
        activePage: pageNumber,
        fromDateApi: '',
        toDateApi: this.state.curPageLastCreTime,
        prevPageFirstCreTime: this.state.curPageFirstCreTime,
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.createdDatesArray(this.state.curPageFirstCreTime);
        }
        this.getAllCpList(false);
      });
    }
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }

  removeSelectedItem(item, index) {
    const allItems = [...this.state.selectedItems];
    allItems.splice(index, 1);
    const deletedWallboxIndex = this.state.allCPList.findIndex(el => el === item);
    const deletedWallboxes = [...this.state.deletedWallboxes];
    if (deletedWallboxIndex > -1) {
      deletedWallboxes.push(item);
    }
    this.setState({ ...this.state, selectedItems: allItems, deletedWallboxes });
  }

  //show entries change
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.setState({
      activePage: 1,
      pageSize: entries,
      fromDateApi: "",
      toDateApi: ""
    }, () => {
      this.getAllCpList(true,this.state.acpId);
    })
  }

  render() {
  const{t}=this.props;

    return (
      <>
        <main className="content-div" >
          <p>{t('Authorization')}
            <div className="breadcrumb_div">
              {t('charge_point')} &gt; <Link to="/Ocpptags"><span>{t('Authorization')}</span></Link > &gt; <span className="breadcrumb_page">{t('add')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button className="pull-right btn_primary" type="button" data-test="button" onClick={this.redirectToListPage} >
                    <i className="fas fa-angle-left mr-2"></i>  {t('back')}
                  </button>
                </div>
              </div>
            </div>
            <MDBRow className="mb-4 mt-3">
              <MDBCol sm="12">
                <div className="alert alert-success alert-dismissible mt-2" id="suc_msg" style={{ display: "none" }} ></div>
                <div>
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>
                      <MDBCol md="4" className="form_margin" >
                        <MDBInput
                          name="tag"
                          value={this.state.fields["idTag"]}
                          onChange={this.changeHandler.bind(this, "idTag")}
                          type="text"
                          id="idTag"
                          label={t('rfid_tag')+" *"}
                        >
                          <div className="valid-feedback">{t('looks_good')}</div>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["idtagempty"]}</span></span>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["entervalididtag"]}</span></span>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["idtagexist"]}</span></span>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="form_margin" >
                        <MDBInput
                          value={this.state.fields["note"]}
                          name="noteId"
                          onChange={this.changeHandler.bind(this, "note")}
                          type="text"
                          id="noteId"
                          label={t('additional_note')}
                        >
                          <div className="valid-feedback">{t('looks_good')}</div>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4"></MDBCol>
                      <MDBCol md="4" className="form_margin">
                        <span className="cal_label mr-2">{t('expiry_date_time')}</span>
                        <div className="pull-left mt-2" style={{ width: "55%" }} >
                          <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            className="cal_margin tag_cal"
                            timeCaption="time"
                            dateFormat="dd-MMM-yyyy HH:mm"
                            minDate={new Date()}
                          />
                        </div>
                      </MDBCol>

                      <MDBCol md="4" className="form_margin">
                        <label className="pull-left mr-1 mt-2 pt-1 code_clr pl-2">
                          {t('choose_rfid_tag_type')} * <span className="custom_tooltip"><i className="fa fa-info-circle" aria-hidden="true"></i>
                            <div className="custom_tooltiptext">
                              <div className="custom_tooltip_header">{t('rfid_tag_type')}</div>
                              <div className="custom_tooltip_body">
                                <div>{t('type_of_rfid_tag')}</div>
                                <div><span className="font-bold">{t('private')}: </span>{t('rfid_tag_intended_for_private_use')}</div>
                                <div><span className="font-bold">{t('company')}: </span>{t('rfid_tag_was_issued_by_company')}</div>
                                <div><span className="font-bold">{t('other')}: </span>{t('default_setting_no_further_logic_involved')}</div>
                              </div>
                            </div>
                          </span> </label>
                        <select className="browser-default custom-select select_height w_53 indent" id="rfidTagType" onChange={this.rfidTypeChange} value={this.state.fields.tagType}>
                          <option value="other">{t('other')}</option>
                          <option value="private">{t('private')}</option>
                          <option value="company">{t('company')}</option>
                        </select>
                        <div className="mt-3"><span className="error_msg w3-animate-top error_tagType" style={{ color: "red" }}></span></div>
                      </MDBCol>
                      <MDBCol md="4"></MDBCol>
                    </MDBRow>
                  </form>
                </div>
              </MDBCol>
            </MDBRow>

            {(this.state.RfidAccessVal === true && this.state.isFreeChargeEnabled === false) > 0 &&
              <>
                <div className="col-md-12">
                  <div className='row'>
                    <div className="col-md-4 mt-1 mb-2">
                    <CpSearch
                        getData={this.getAllCpList}
                        pagename="chargePoint"
                        reset={this.resetAsyncTimes}
                        noWallboxExists={this.state.noWallboxExists}
                        activePage={this.state.activePage}
                        >
                        </CpSearch>
                    </div>

                    <div className="col-md-8">
                      <div className="pull-right">
                        <label className="pull-left sub-text">{t('show_entries')}</label>
                        <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv p-rel cpselectdiv" >
                          <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                            <option>10</option>
                            <option>20</option>
                            <option selected="selected">30</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-1">
                  <div className="mt-1 mb-2"><span className="error_msg w3-animate-top error_cpids" style={{ color: "red" }}></span></div>
                  <div className='content-innerdiv mt-2 hauto'>
                    <div className='row rfidDiv'>
                      <div className="col-md-9 lightBlue">
                        <label><b>{t('charge_point')}:</b></label>
                        <div className="row">
                          {this.state.allCPList.map((el, index) => {
                            return (
                              <div className="col-md-3 p-0">
                                <Form.Check
                                  className=""
                                  custom
                                  id={`${el.chargePointId}_${index}`}
                                  type="checkbox"
                                  checked={this.isItemSelected(el.chargePointId)}
                                  onChange={(event) => this.updateCheckboxSelection(event, el.chargePointId)}
                                  label={el.chargePointId}
                                />
                              </div>
                            )
                          })
                          }
                        </div>
                      </div>
                      {this.state.selectedItems.length > 0 &&
                        <div className="col-md-3 lightBlue">
                          <label>
                            <b>{t('rfid_tag_valid_for_charge_point')}:</b>
                          </label>
                          <div className="row">
                            <div className="col-md-12">
                              {
                                this.state.selectedItems.map((el, index) =>
                                  <span key={index} className="cpChargePointSspan">
                                    {el} <i className="fa fa-times" onClick={() => this.removeSelectedItem(el, index)}></i>
                                  </span>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3 mb-1 text-right">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.pageSize}
                        totalItemsCount={this.state.noOfRecords}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Prev"
                        nextPageText="Next"
                        itemClassPrev="prevBtn"
                        pageRangeDisplayed="1"
                        activeClass="activeLi active"
                        itemClassNext="nextBtn"
                        disableInitialCallback={true}
                        disabledClass="disabled disabledtextColor"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
            <div className='col-md-12 mt-2 mb-5'>
              <button type="button" data-test="button" onClick={this.handleSubmit.bind(this)} className="btn_primary mr-1 pull-right">
                {t('save')} <i className="fas fa-save ml-2"></i>
              </button>
            </div>
          </div>
        </main>

        <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>

        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('ocpp_tag_successfully_added')}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.tagOk}>{t('ok')}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(Ocppnewtag);