import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import currencyFormatter from 'currency-formatter';
import './cpDashboard.css';
import { withTranslation } from 'react-i18next';

class Transactionstable extends React.Component {
  
    render() { 
        const { t } = this.props
        let actionDisplay = false;
        // Print the table heading 
        let title = this.props.tableField.map((tableTilte, index) => {
            if (tableTilte.isSelected) {
                var icon = "";
                if (tableTilte.key === "id" || tableTilte.key === "start_time" || tableTilte.key === "stop_time") {
                    icon = <i className="fas fa-sort sortIcon" onClick={this.props.sorting}></i>
                }

                if (tableTilte.key === "actions") {
                    actionDisplay = true;
                }

                if (tableTilte.key === "referenceNumber" || tableTilte.key === "customerNumber") {
                    if (this.props.editAction === true) {
                        return (
                            <th key={tableTilte.key}>
                                {this.props.t(tableTilte.key)}
                                {icon}
                            </th>
                        )
                    } else {
                        return <></>
                    }
                } else {
                    return (
                        <th key={tableTilte.key}>
                            {this.props.t(tableTilte.key)}
                            {icon}
                        </th>
                    )
                }
            } else {
                return <></>
            }
        });

        // Print the table body
        const data = this.props.item.map((dataArray, index) => {
            var startTime, startTime1, stopTime, stopTime1;

            if (dataArray.startTime === null || dataArray.startTime === "") {
                startTime = "";
                startTime1 = "";
            } else {
                startTime = moment(dataArray.startTime).format("DD MMM YYYY");
                startTime1 = moment(dataArray.startTime).format("HH:mm");
            }

            if (dataArray.stopTime === null || dataArray.stopTime === "") {
                stopTime = "";
                stopTime1 = "";
            } else {
                stopTime = moment(dataArray.stopTime).format("DD MMM YYYY");
                stopTime1 = moment(dataArray.stopTime).format("HH:mm");
            }

            var status_img = "";
            var status_text = "";

            if (dataArray.chargingSessionState === "activeCharging") {
                status_img = "activeCharging";
                status_text = t('active_charging');
            } else if (dataArray.chargingSessionState === "Finished") {
                status_img = "Finished";
                status_text = t('finished');
            } else if (dataArray.chargingSessionState === "activeNotCharging") {
                status_img = "activeNotCharging";
                status_text = t('active_not_charging');
            } else if (dataArray.chargingSessionState === "prepairing") {
                status_img = "prepairing";
                status_text = t('prepairing');
            } else if (dataArray.chargingSessionState === "activePreparing") {
                status_img = "prepairing";
                status_text = t('active_preparing');
            } else if (dataArray.chargingSessionState === "SuspendedEV") {
                status_img = "activeNotCharging";
                status_text = t('active_not_charging');
            } else if (dataArray.chargingSessionState === "Halted") {
                status_img = "Halted";
                status_text = t('halted');
            }

            var trsactionData = { dataArray };
            var chargedEnergy = '';

            if (dataArray.chargedEnergy === null || dataArray.chargedEnergy === "") {
                chargedEnergy = 0;
            } else {
                chargedEnergy = dataArray.chargedEnergy;
            }

            if (dataArray.transactionId === dataArray.vendorTransactionId) {
                return (
                    <tr>
                        {                            
                            this.props.tableField.map((tableTilte, index) => {
                                if (tableTilte.isSelected === true) {
                                    return Object.entries(dataArray).map(([key, value]) => {
                                        return <>
                                            {
                                                (tableTilte.key === key) ? <>
                                                    {
                                                        (key === "transactionId") ? <td><Link to={{ pathname: '/meterValues', state: trsactionData }}>{value}</Link></td> :
                                                            (key === "startTime") ? <td className="wrap-word wide-column">{startTime && <><span className="nowrap pb-0 w-100"><img src={process.env.PUBLIC_URL + "/assets/img/cs_table_calendar_icon_sm.svg"} width="14" alt=""/> {startTime} | {startTime1} </span> </>} </td> :
                                                                (key === "stopTime") ? <td className="wrap-word">{stopTime && <><span className="nowrap pb-0 w-100"><img src={process.env.PUBLIC_URL + "/assets/img/cs_table_calendar_icon_sm.svg"} width="14" alt=""/> {stopTime} | {stopTime1} </span></>}  </td> :
                                                                    (key === "chargedEnergy") ? <td className="wrap-word"><span className="nowrap pb-0 w-100">{currencyFormatter.format(chargedEnergy, { code: dataArray?.currencyCode, symbol: '' })} kWh {(chargedEnergy * 1000)?.toFixed()} wh</span></td> :
                                                                        (key === "chargingSessionState") ? <td className="wrap-word  p-rel ">
                                                                            <div className="click-to-top">
                                                                                <img src={process.env.PUBLIC_URL + "/assets/img/cs-status/" + status_img + ".png"} style={{ "height": "16px" }} alt="" />
                                                                                <span>{status_text}</span>
                                                                            </div>
                                                                        </td> : ((key === "customerNumber" || key === "referenceNumber") && this.props.editAction === true) ? <td>{value}</td> :
                                                                            (key === "chargePointId") ? <td className="nowrap">{value} {dataArray.connectorId ? '(' + dataArray.connectorId + ')' : ''}</td> :
                                                                                (key === "chargingSessionCost" || key === "costsPerKwh" || key === "costsPerChargingSession") ?  key === "chargingSessionCost" ? 
                                                                                <td>  {currencyFormatter.format(value, { code: dataArray?.currencyCode, symbol: '' })} { dataArray?.chargingSessionCurrency }</td> :<td>  {currencyFormatter.format(value, { code: dataArray?.currencyCode, symbol: '' })} </td> : 
                                                                                (key === "freecharge") ? <td>{dataArray.freeCharge ? "Yes" : "No"}</td> 
                                                                                : <td className="nowrap">{value}</td>
                                                    }
                                                </> : null
                                            }
                                        </>
                                    })
                                } else {
                                    return <></>
                                }
                            })
                        }
                        {actionDisplay && <>
                            {localStorage.getItem("role") !== "WEBASTO_CUSTOMER_ADMIN_READ_ONLY" ? <>
                                <td>
                                    {
                                        (this.props.editAction === true) ? <>
                                            {(dataArray.chargingSessionState === "prepairing" || dataArray.chargingSessionState === "activePreparing" || dataArray.chargingSessionState === "activeCharging") ?
                                                // (dataArray.energyLimitPermission) ? <i className="fas fa-pencil-alt pr-2 action-icon" title={t('remote_start_transaction')} onClick={this.props.toggleRemoteStart.bind(this, dataArray.chargePointId, dataArray.transactionId, dataArray.energyLevel, dataArray.idTag, dataArray.customerNumber, dataArray.referenceNumber, dataArray.chargingSessionState, dataArray.chargedEnergy)} ></i> : <i className="fas fa-pencil-alt pr-2 action-icon icon_fadeOut" title={t('remote_start_transaction')}></i>
                                                (dataArray.energyLimitPermission) ? <img src={process.env.PUBLIC_URL + "/assets/img/start.svg"} height={18} width={18} title={t('remote_start_transaction')} className='cursor-p'
                                                    onClick={this.props.toggleRemoteStart.bind(this, dataArray.chargePointId, dataArray.transactionId, dataArray.energyLevel, dataArray.idTag, dataArray.customerNumber, dataArray.referenceNumber, dataArray.chargingSessionState, dataArray.chargedEnergy)}/> : <img src={process.env.PUBLIC_URL + "/assets/img/start.svg"} height={18} width={18} style={{opacity : '0.4'}} title={t('remote_start_transaction')}/> 
                                                : ""}
                                        </> : <></>
                                    }
                                    {
                                        (this.props.carAction === true) ? 
                                        // <i className="fas fa-car-side" title={t('add_mileage_and_inventory_no')} onClick={this.props.displayCarIcon.bind(this, dataArray.transactionId, dataArray.mileage, dataArray.vehicleInventoryNumber)} ></i> : <></>
                                        <img src={process.env.PUBLIC_URL + "/assets/img/mileage.svg"} height={18} width={18} title={t('add_mileage_and_inventory_no')} className='cursor-p ml-2'
                                                                    onClick={this.props.displayCarIcon.bind(this, dataArray.transactionId, dataArray.mileage, dataArray.vehicleInventoryNumber)} /> : <></>
                                    }
                                </td>
                            </> : <td></td>
                            }
                        </>
                        }
                        <td></td>
                    </tr>
                )
            } else {
                return (
                    <tr>
                        {
                            this.props.tableField.map((tableTilte, index) => {
                                if (tableTilte.isSelected === true) {
                                    return Object.entries(dataArray).map(([key, value]) => {
                                        return <>
                                            {
                                                (tableTilte.key === key) ? <>
                                                    {
                                                        (key === "transactionId") ? 
                                                        <td>
                                                            <Link to={{
                                                                pathname: '/meterValues',
                                                                state: trsactionData,
                                                            }}>{value}</Link>{dataArray.vendorTransactionId ? '(' + dataArray.vendorTransactionId + ')' : ''}</td> :                                                         
                                                        (key === "startTime") ? 
                                                        <td className="wrap-word">{startTime && <><span className="nowrap pb-0 w-100"><img src={process.env.PUBLIC_URL + "/assets/img/cs_table_calendar_icon_sm.svg"} width="14" alt=""/> {startTime} | {startTime1}</span></>}</td> : 
                                                        (key === "stopTime") ? 
                                                        <td className="wrap-word">{stopTime && <><span className="nowrap pb-0 w-100"><img src={process.env.PUBLIC_URL + "/assets/img/cs_table_calendar_icon_sm.svg"} width="14" alt=""/> {stopTime} | {stopTime1}</span> </>}  </td> :
                                                        (key === "chargedEnergy") ? 
                                                        <td className="wrap-word"><span className="nowrap pb-0 w-100">{currencyFormatter.format(chargedEnergy, { code: dataArray?.currencyCode, symbol: '' })} kWh {(dataArray.chargedEnergy * 1000)?.toFixed()} wh</span></td> :
                                                        (key === "chargingSessionState") ? 
                                                        <td className="wrap-word  p-rel "><div className="click-to-top"><img src={process.env.PUBLIC_URL + "/assets/img/cs-status/" + status_img + ".png"} style={{ "height": "16px" }} alt="" /><span>{status_text}</span></div></td> : 
                                                        ((key === "customerNumber" || key === "referenceNumber") && this.props.editAction === true) ? 
                                                        <td>{value}</td> :
                                                        (key === "chargePointId") ? 
                                                        <td className="nowrap">{value} {dataArray.connectorId ? '(' + dataArray.connectorId + ')' : ''}</td> :                                                        
                                                        (key === "chargingSessionCost" || key === "costsPerKwh" || key === "costsPerChargingSession") ?  key === "chargingSessionCost" ? 
                                                        <td>{currencyFormatter.format(value, { code: dataArray?.currencyCode, symbol: ''})}{dataArray?.chargingSessionCurrency }</td> :
                                                        <td>{currencyFormatter.format(value, { code: dataArray?.currencyCode, symbol: '' })}</td> : 
                                                        (key === "freeCharge") ? <td>{dataArray.freeCharge ? "Yes" : "No"}</td> : 
                                                        <td className="nowrap">{value}</td>
                                                    }
                                                </> : null
                                            }
                                        </>
                                    })
                                } else {
                                    return <></>
                                }
                            })
                        }
                        {actionDisplay && <>
                            {localStorage.getItem("role") !== "WEBASTO_CUSTOMER_ADMIN_READ_ONLY" ? <>
                                <td>
                                    {
                                        (this.props.editAction === true) ? <>
                                            {(dataArray.chargingSessionState === "prepairing" || dataArray.chargingSessionState === "activePreparing" || dataArray.chargingSessionState === "activeCharging") ?
                                                (dataArray.energyLimitPermission) ? <img src={process.env.PUBLIC_URL + "/assets/img/start.svg"} height={18} width={18} title={t('remote_start_transaction')} className='cursor-p'
                                                    onClick={this.props.toggleRemoteStart.bind(this, dataArray.chargePointId, dataArray.transactionId, dataArray.energyLevel, dataArray.idTag, dataArray.customerNumber, dataArray.referenceNumber, dataArray.chargingSessionState, dataArray.chargedEnergy)}/> : <img src={process.env.PUBLIC_URL + "/assets/img/start.svg"} height={18} width={18} style={{opacity : '0.4'}} title={t('remote_start_transaction')}/> 
                                        : ""}
                                        </> : <></>
                                    }
                                    {
                                        (this.props.carAction === true) ? 
                                        <img src={process.env.PUBLIC_URL + "/assets/img/mileage.svg"} height={18} width={18} title={t('add_mileage_and_inventory_no')} className='cursor-p ml-2'
                                                                    onClick={this.props.displayCarIcon.bind(this, dataArray.transactionId, dataArray.mileage, dataArray.vehicleInventoryNumber)} /> : <></>
                                    }
                                </td>
                            </> : <td></td>
                            }
                        </>
                        }
                        <td></td>
                    </tr>
                )
            }
        });

        return (
            <Table striped hover size="sm" className="page-table responsive">
                <thead>
                    <tr>
                        {title}
                        {/* <th className="p-rel">
                            <div className="gear_iconDiv">
                                <i className="fas fa-cog gear_icon" onClick={this.props.settingAction}></i>
                            </div>
                        </th> */}
                        
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data}
                </tbody>
            </Table>
        )
    }
}
export default withTranslation()(Transactionstable);