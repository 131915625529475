import React from 'react';
import { MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { createBrowserHistory } from 'history';
import Calendar from 'react-calendar';
import { Form, Table } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import moment from 'moment';
import { activeDateFormat } from './_methods';
import { withTranslation } from 'react-i18next';
//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders } from '../common/HttpRequestClass'
import { hasPermission } from './auth';
import Loader from '../views/loader';

const history = createBrowserHistory();

class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalsession: false,
            chargePointId: "",
            fromDate: "",
            toDate: "",
            date1: "",
            date2: "",
            calenderFrom: "none",
            calenderTo: "none",
            pageSize: 10,
            pageNo: 1,
            model: "",
            fwVersion: "",
            notificationId: "",
            notificationsList: [],
            saveSelectedValue: "",
            disabled: true,
            dropdownValue: "",
            noOfRecords: 0,
            activePage: 1,
            viewsession: false,
            reqMessage: "",
            resMessage: "",
            user: {
                permissions: []
            },
            loading: false,
        }
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.getNotifications = this.getNotifications.bind(this);
        this.changeDateTo = this.changeDateTo.bind(this);
        this.changeDateFrom = this.changeDateFrom.bind(this);
        this.displayCalender = this.displayCalender.bind(this);
        this.closeCalender = this.closeCalender.bind(this);
        this.fromChange = this.fromChange.bind(this);
        this.toChange = this.toChange.bind(this);
        this.viewModal = this.viewModal.bind(this);
        this.viewsession = this.viewsession.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClick1 = this.handleClick1.bind(this);
        this.keyPress1 = this.keyPress1.bind(this);
        this.keyPress2 = this.keyPress2.bind(this);
    }

    handlePageChange(pageNumber) {
        if (pageNumber !== this.state.activePage) {
            this.setState({
                activePage: pageNumber,
                pageNo: pageNumber
            }, () => {
                this.getNotifications(this.state.pageSize, this.state.pageNo)
            });
        }
    }

    UNSAFE_componentWillMount() {
        let user = { permissions: [] };
        user.permissions = localStorage.getItem("roleAccess");
        this.setState({ user: user })
    }

    componentDidMount() {
        let fromDate = this.formatLocalCalDate(moment().subtract(29, 'd'));
        let toDate = this.formatLocalCalDate(moment());

        let startdate = moment().subtract(29, 'd');
        let enddate = moment();

        this.setState({
            date1: this.formatCalDate(startdate),
            dateOne: this.formateDate(startdate),
            date2: this.formatCalDate(enddate),
            dateTwo: this.formateDate(enddate),
            fromDate: fromDate,
            toDate: toDate
        }, () => {
            this.getNotifications(10, 1, "");
        });
    }

    keyPress1(e) {
        var key = e.key;
        $('.date_range_error').text('');
        $('.future_err1').text("");

        if (e.keyCode === 13) {
            if (new Date() >= new Date(e.target.value)) {
                this.setState({
                    fromDate: new Date(e.target.value),
                    date1: this.formatCalDate(new Date(e.target.value))
                }, () => {
                    this.getNotifications(this.state.pageSize, 1);
                })
            } else {
                this.setState({ fromDate: "", date1: "" }, () => {
                    $('.future_err1').text(this.props.t('you_not_select_future_date'));
                });
            }
        }

        if (key === "Delete" || key === "Backspace") {
            this.setState({ date1: "" }, () => {
                this.getNotifications(this.state.pageSize, 1);
            })
        }
    }

    keyPress2(e) {
        var key = e.key;
        $('.date_range_error').text('');
        $('.future_err2').text("");

        if (e.keyCode === 13) {
            if (new Date() >= new Date(e.target.value)) {
                this.setState({
                    toDate: new Date(e.target.value),
                    date2: this.formatCalDate(new Date(e.target.value))
                }, () => {
                    this.getNotifications(this.state.pageSize, 1);
                })
            } else {
                this.setState({ toDate: "", date2: "" }, () => {
                    $('.future_err2').text(this.props.t('you_not_select_future_date'));
                });
            }
        }

        if (key === "Delete" || key === "Backspace") {
            this.setState({
                date2: ""
            }, () => {
                this.getNotifications(this.state.pageSize, 1);
            });
        }
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    async viewsession() {
        this.setState({
            viewsession: !this.state.viewsession
        });
    }

    viewModal(reqMessage, resMessage) {
        this.setState({
            reqMessage: reqMessage,
            resMessage: resMessage
        }, ()=>{
            this.viewsession();
        })
    }

    sessiontagOk() {
        history.push('/');
        window.location.reload();
    }

    changeHandler(field, e) {
        this.setState({ [field]: e.target.value }, ()=>{
            if (field === "chargePointId") {
                $('.noexists').text("");
            }
        });
    }

    async getNotifications(pageSize, pageNo, action) {
        this.setState({ loading: true })
        if (!this.state.chargePointId && action === "find") {
            $('.noexists').text(this.props.t('enter_chargepoint_id'));
        } else {
            let url = baseUrl.URLPath + servicesUrl.getNotifications + pageSize + "&pageNo=" + pageNo + "&model=" + this.state.model + "&fwVersion=" + this.state.fwVersion + "&notificationId=" + this.state.notificationId + "&fromTime=" + this.state.date1 + "&toTime=" + this.state.date2 + "&cpId=" + this.state.chargePointId;
            let type = ApiMethodTypes.GET;
            let headers = getSecurityHeaders();
            let payload = {}
            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
            if (response.pnList && response.pnList.length) {
                this.setState({ notificationsList: response.pnList, noOfRecords: response.count.count, loading: false }, ()=>{
                    if (action === "find") { $('.noexists').text("") }
                });
            } else {
                this.setState({ notificationsList: [], noOfRecords: 0, loading: false }, ()=>{
                    if (action === "find") { $('.noexists').text(this.props.t('chargepoint_not_exists')); }
                })
            }
        }
    }

    changeDateFrom(e) {
        this.setState({ fromDate: e.target.value });
    }

    changeDateTo(e) {
        this.setState({ toDate: e.target.value });
    }

    displayCalender(type, event) {
        var calenderFrom = "";
        var calenderTo = "";

        if (type === "from") {
            calenderFrom = "block";
            calenderTo = "none";
        } else {
            calenderTo = "block";
            calenderFrom = "none";
        }

        this.setState({
            calenderFrom: calenderFrom,
            calenderTo: calenderTo
        })
    }

    closeCalender(type, event) {
        if (type === "from") {
            this.setState({
                calenderFrom: "none"
            })
        } else {
            this.setState({
                calenderTo: "none"
            })
        }
    }

    formateDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    fromChange = date => {
        var fdate = this.formatLocalCalDate(date);
        this.setState({ 
            fromDate: fdate,
            date1: this.formatCalDate(date),
            dateOne: this.formateDate(date),
            pageNo: 1,
            pageSize: 10,
            calenderFrom: "none"
        }, ()=>{
            this.getNotifications(this.state.pageSize, 1);
        });
    }

    toChange = date => {
        var fdate = this.formatLocalCalDate(date);
        this.setState({ 
            toDate: fdate,
            date2: this.formatCalDate(date),
            dateTwo: this.formateDate(date),
            pageNo: 1,
            pageSize: 10,
            calenderTo: "none"
        }, ()=>{
            this.getNotifications(this.state.pageSize, 1);
        });
    }

    formatLocalCalDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        var monthname = d.toLocaleString('default', { month: 'short' });
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [day, monthname, year].join(' ');
    }

    formatCalDate(date) {
        var format = date.toISOString().substr(0, 19) + "Z";
        return format;
    }

    // method to call onChange Operation Name
    dropDownChange = () => {
        var id = document.getElementById("selectDropdown").value;

        if (id !== "" && id !== undefined) {
            this.setState({
                saveSelectedValue: id,
                disabled: false
            })
        } else {
            this.setState({
                saveSelectedValue: "",
                disabled: true
            })
        }
    }

    handleClick() {
        this.setState({
            chargePointId: ""
        }, ()=>{
            this.getNotifications(10, 1, "");
        });
        $('.noexists').text("");
    }

    handleClick1() {
        this.setState({
            dropdownValue: "",
            fwVersion: "",
            notificationId: "",
            model: ""
        }, ()=>{
            this.getNotifications(10, 1, "");
        });
    }

    onchangeDropdown = (e) => {
        if (this.state.saveSelectedValue === "FW Version") {
            this.setState({
                fwVersion: e.target.value,
                model: "",
                notificationId: "",
                dropdownValue: e.target.value
            });
        } else if (this.state.saveSelectedValue === "Notificationid") {
            this.setState({
                fwVersion: "",
                model: "",
                notificationId: e.target.value,
                dropdownValue: e.target.value
            });
        } else if (this.state.saveSelectedValue === "Model") {
            this.setState({
                fwVersion: "",
                model: e.target.value,
                notificationId: "",
                dropdownValue: e.target.value
            });
        }
    }

    showEntriesChange = () => {
        var entries = document.getElementById("showEntries").value;

        this.setState({
            activePage: 1,
            pageSize: entries,
            pageNo: 1
        }, ()=>{
            this.getNotifications(entries, 1);
        });
    }

    csvExport = () => {
        let timeZone =  moment.tz.guess();
        let url = baseUrl.URLPath + "pushnotifications/download/CSV?model=" + this.state.model + "&fwVersion=" + this.state.fwVersion + "&notificationId=" + this.state.notificationId + "&fromTime=" + this.state.date1 + "&toTime=" + this.state.date2 + "&cpId=" + this.state.chargePointId;
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
                'timeZone': timeZone
            }
        }).then((resp) => {
            if (resp.status === 401) {
                window.location.reload();
            } else {
                return resp.blob();
            }
        }).then((response) => {
            if (response !== undefined) {
                let d = new Date();
                let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                let url = window.URL.createObjectURL(response);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'Notifications_' + dformat + '.csv';
                a.click();
            }
        }).catch((error) => {
            console.log(error, "catch the loop")
        })
    }
    render() {
        const { t } = this.props
        let user = this.state.user;
        return (
            <main className="content-div">
                <p>{t('Notifications')}</p>
                <div className="page-outerdiv">
                    <div className="row menubar">
                        <div className="col-md-12">
                            {hasPermission(user, ["PushNotificationsExPort_R"]) && <>
                                <button color="primary accent-2" onClick={this.csvExport}>
                                    <i className="fas fa-file-export"></i>
                                    <span>{t('export_all')}</span>
                                </button>
                            </>
                            }
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <div class="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <input
                                    type="text"
                                    // id="chargePointId"
                                    value={this.state.chargePointId}
                                    onChange={this.changeHandler.bind(this, "chargePointId")}
                                    class="form-control input-searchBox pad-2"
                                    placeholder={t('enter_charge_point')}
                                    name="chargePointId"
                                />
                                <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div class="input-group-btn">
                                    <button class="input-searchBtn find-btn" type="submit" onClick={this.getNotifications.bind(this, 10, 1, "find")}>{t('find')}</button>
                                </div>
                            </div>
                            <span className="error_msg w3-animate-top mt-2 noexists" style={{ color: "red" }}></span>
                        </div>
                        <div className="col-md-3 p-rel">
                            <div class="input-group">
                                <input type="text" class="form-control input-searchBox pl-3" placeholder={t('from_time')} name="fromDate" value={this.state.fromDate} onChange={this.changeDateFrom} onKeyDown={this.keyPress1} />
                                <div class="input-group-btn">
                                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, "from")}><i className="fas fa-calendar-alt fa-sm"></i></button>
                                </div>
                            </div>
                            <div style={{ display: this.state.calenderFrom }} className="calenderContainer">
                                <div onClick={this.closeCalender.bind(this, "from")}><i class="far fa-times-circle"></i></div>
                                <Calendar
                                    selected={this.state.fromDate}
                                    onChange={date => this.fromChange(date)}
                                    value={activeDateFormat(this.state.fromDate)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="time"
                                    dateFormat="dd-MMM-yyyy HH:mm"
                                    onBlur=""
                                    maxDate={(this.state.toDate !== "" && this.state.toDate !== null) ? new Date(this.state.toDate) : new Date()}
                                />
                            </div>
                            <span className="pull-left future_err1 w3-animate-top mt-1" style={{ color: "red" }}></span>
                        </div>
                        <div className="col-md-3 p-rel">
                            <div class="input-group">
                                <input type="text" class="form-control input-searchBox pl-3" placeholder={t('to_time')} name="toDate" onKeyDown={this.keyPress2} onChange={this.changeDateTo} value={this.state.toDate} />
                                <div class="input-group-btn">
                                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, "to")}> <i className="fas fa-calendar-alt fa-sm"></i></button>                      </div>
                            </div>
                            <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                                <div onClick={this.closeCalender.bind(this, "to")}><i class="far fa-times-circle"></i></div>
                                <Calendar
                                    selected={this.state.toDate}
                                    onChange={date => this.toChange(date)}
                                    value={activeDateFormat(this.state.toDate)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="time"
                                    dateFormat="dd-MMM-yyyy HH:mm"
                                    minDate={new Date(this.state.fromDate)}
                                    maxDate={new Date()}
                                />
                            </div>
                            <span className="pull-left future_err2 w3-animate-top mt-1" style={{ color: "red" }}></span>
                        </div>
                        <div className='col-md-12 date_range_error' style={{ color: "red", textAlign: "center" }}></div>
                    </div>
                    <div className="row mt-1">
                        <MDBCol md="3" className="mt-1" >
                            <select className="browser-default custom-select select_height" id="selectDropdown" onChange={this.dropDownChange}>
                                <option value=""> {t('select')} </option>
                                <option value="FW Version">{t('fw_version')}</option>
                                <option value="Notificationid">{t('notification_id')}</option>
                                <option value="Model">{t('model')}</option>
                            </select>
                        </MDBCol>
                        <div className="col-md-4 mt-2 pt-1 custom_input_adjust">
                            <div class="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <input type="text" value={this.state.dropdownValue} onChange={this.onchangeDropdown} disabled={this.state.disabled} class="form-control input-searchBox pad-2" placeholder={t('fw_version_notificationid')} name="adminAddress" />
                                <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick1} >
                                    <span aria-hidden="true">×</span></button>
                                <div class="input-group-btn">
                                    <button class="input-searchBtn find-btn" type="submit" onClick={this.getNotifications.bind(this, 10, 1)}>{t('find')}</button>
                                </div>
                                <span className="error_msg w3-animate-top"><span style={{ color: "red" }}></span></span>
                            </div>
                        </div>
                        <div className="col-md-5 mt-2 pt-1 custom_input_adjust2">
                            <div className="pull-right">
                                <label className="sub-text pull-left" >{t('show_entries')}</label>
                                <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        {/* <th style={{width: "5%"}}></th> */}
                                        <th style={{ width: "11%" }}>{t('charge_point_id')}</th>
                                        <th style={{ width: "11%" }}>{t('model')}</th>
                                        <th style={{ width: "10%" }}>{t('fw_version')}</th>
                                        <th style={{ width: "8%" }}>{t('notification_id')}</th>
                                        <th style={{ width: "40%" }}>{t('notification_description')}</th>
                                        <th style={{ width: "10%" }}>{t('created_date')}</th>
                                        <th style={{ width: "5%" }}>{t('action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.notificationsList.map((notification, index) => {
                                            var createdDate = "";
                                            var createdDate1 = "";

                                            if (notification.createdDate === null || notification.createdDate === "") {
                                                createdDate = "";
                                                createdDate1 = "";
                                            } else {
                                                createdDate = moment(notification.createdDate).format("DD MMM YYYY");
                                                createdDate1 = moment(notification.createdDate).format("HH:mm");
                                            }
                                            return (
                                                <tr>
                                                    <td>{notification.chargePointId}</td>
                                                    <td>{notification.model}</td>
                                                    <td>{notification.fwVersion}</td>
                                                    <td>{notification.notificationId}</td>
                                                    <td>{notification.notificationDescription}</td>
                                                    <td className="wrap-word">{createdDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdDate1} </div> </>}</td>
                                                    <td>
                                                        <i className="fas fa-eye action-icon mt-1 ml-2" title="View" style={{ cursor: 'pointer' }} onClick={this.viewModal.bind(this, notification.requestMessage, notification.responseMessage)}></i>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-12 mt-2 mb-4 text-right">
                            <Pagination
                                hideDisabled
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.noOfRecords}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                    <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
                    <MDBModalBody>
                        {t('please_login_again')}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button onClick={this.sessiontagOk} type="button" data-test="button" className="btn_primary">{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.viewsession} toggle={this.viewsession} size="lg" className="model_top">
                    <MDBModalBody>
                        <MDBRow className="mb-4 mt-3" >
                            <MDBCol sm="12">
                                <div className="view-bg-row" >
                                    <label className=""><b>{t('request_message')}: </b></label>
                                    <label className="wrap-word">{this.state.reqMessage}</label>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-4 mt-3" >
                            <MDBCol sm="12">
                                <div className="view-bg-row" >
                                    <label><b>{t('response_message')}: </b></label>
                                    <label className="wrap-word">{this.state.resMessage}</label>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button onClick={this.viewsession} type="button" data-test="button" className="btn_primary">{t('close')}</button>
                    </MDBModalFooter>
                </MDBModal>

                {this.state.loading ? (
                    <Loader loader={this.state.loading} />
                ) : ("")}
            </main>
        );
    }
}

export default withTranslation()(Notifications);