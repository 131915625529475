import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import apiCallUtility from '../common/APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class getLocalListVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpList: [],
      sucess: false,
      colour: 'sucess',
      errors: {},
      chargePoint: '',
      operationId: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectedCP = this.getSelectedCP.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  async handleSubmit(event) {
    var errors = {};
    if (this.getCPList().length === 0) {
      errors["emptyChargePoint"] = this.props.t('select_charge_point')
    }
    if (this.getCPList().length !== 0) {
      var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), {}, this.getCPList(), this.props.t);
      if (response === this.props.t('unable_toprocess_request_try_after_some_time')) {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, () => {
          this.onShowAlert();
        })
      } else if (response === 'Request is processed Successfully') {
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, () => {
          this.onShowAlert();
        })
      }
    }

    this.setState({ errors: errors })
  }

  getCPList() {
    var Id = this.state.operationId;
    var cplist = "";

    if (Id !== null && Id !== "") {
      cplist = Id.split();
    } else {
      cplist = this.state.cpList;
    }

    return cplist;
  }

  getSelectedCP(cpList) {
    this.setState({ cpList: cpList });
  }

  getMessageType() {
    return constants.GET_LOCAL_LIST_VERSION;
  }

  componentDidMount() {
    let propsdata = this.props.location.state;

    if (propsdata !== undefined) {
      if (propsdata.chargePoint !== undefined && propsdata.operationId !== undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }
  }

  componentWillUnmount() {
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }

  render() {
    const { t } = this.props;

    return (
      <main className="content-div">
        <p>{t('Get Local List Version')}
          <div className="breadcrumb_div">
            {t('Operations')} &gt; <span className="breadcrumb_page">{t('Get Local List Version')}</span>
          </div>
        </p>
        <div className="page-outerdiv">
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
          <MDBRow className="mb-4">
            <MDBCol sm="12">
              <div>
                <form className="needs-validation">
                  <MDBRow>
                    <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="GetLocalListVersion"></ChargePointSelection>
                    <MDBCol md="12">
                      <span className="selection_charge_point_error w3-animate-top">
                        <span style={{ color: "red" }}>{this.state.errors["emptyChargePoint"]}</span>
                      </span>
                    </MDBCol>
                    <MDBCol md="2"></MDBCol>
                  </MDBRow>
                  <button className="mt-5 refresh-btn" onClick={this.handleSubmit} type="button" data-test="button">{t('perform')}</button>
                </form>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </main>
    );
  }
}

export default withTranslation()(getLocalListVersion);