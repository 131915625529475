import React from 'react';
import { Link } from 'react-router-dom';
import { MDBBtn, MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import * as baseUrl from '../views/constants';
import { Alert } from 'reactstrap';

class addBuild extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      models: [],
      modelName: '',
      model: false,
      orgsArray: [],
      isModelDX: false,
      prerequisites: [],
      organization: '',
      dataArray: [],
      fields: {
        version: "",
        releaseNotes: "",
        targetIds: "",
        prerequisite: "",
        file: "",
        releaseNotes: ''
      },
      productId: '',
      buildTypes: [],
      buildTypeId: "",
      errors: {},
      activeItem: "1",
      sucess: false,
      colour: '',
      message: ''
    }

    this.getModels = this.getModels.bind(this);
    this.toggle = this.toggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.organizationChange = this.organizationChange.bind(this);
    this.getPrerequisite = this.getPrerequisite.bind(this);
    this.onChangeModel = this.onChangeModel.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.numberHandler = this.numberHandler.bind(this);
    this.productChange = this.productChange.bind(this);
    this.getBuildTypes = this.getBuildTypes.bind(this);
    this.buildTypeChange = this.buildTypeChange.bind(this);
    this.prerequisiteChange = this.prerequisiteChange.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.releaseChange = this.releaseChange.bind(this);
    this.backToList = this.backToList.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getModels();
    this.getOrganizations();
  }

  getModels() {
    var url = baseUrl.URLPath + "cpinventory/evmodel/";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();
        } else if (resp.status == 200) {
          return resp.json();
        }
      }).then((response) => {
        if (response != undefined) {
          this.setState({ models: response ?? [] });
        }
      }).catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  toggle() {
    this.setState({ model: !this.state.model });
  }

  tagOk() {
    this.props.history.push('/');
    window.location.reload();
  }

  onChangeModel(e){
    let model_name = e.target.value;
    let position = model_name?.search(/(?:DX|FC)/i);

    if(model_name && position > -1) {
      this.setState({
        modelName: model_name,
        isModelDX: (model_name && position > -1) ? true : false
      });
    } else {
      this.setState({
        modelName: model_name,
        isModelDX: (model_name && position > -1) ? true : false
      }, () => {
        this.getPrerequisite();
      });
    }
  }

  getOrganizations() {
    var url = baseUrl.URLPath + "admin/v1.0/account/all";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();
        } else if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ orgsArray: response?.organisationDTOS ?? [] })
        } 
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  getPrerequisite() {
    var url = baseUrl.URLPath + "dashboard/prerequisite?model=" + this.state.modelName + "&type=" + this.state.buildTypeId;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();
        } else if (resp.status == 200) {
          return resp.json();
        }
      }).then((response) => {
        if (response != undefined) {
          this.setState({ prerequisites: response ? [{ version: 'none' }, ...response] : [{ version: 'none' }] })
        }
      }).catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }
  
  organizationChange(e) {
    let tenant = e.target.value;

    this.setState({
      organization: tenant
    }, ()=>{
      this.getProducts();
    })
  }

  getProducts() {
    if (this.state.organization) {
      var url = baseUrl.URLPath + "web/dashboard/" + this.state.organization + "/products?pageNo=0&pageSize=0";

      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.toggle();
            localStorage.clear();
          } else if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {
            this.setState({ dataArray: response?.productList ?? [] });
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    }
  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {
    let fields = this.state.fields;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value == "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  productChange(e) {
    this.setState({ productId: e.target.value }, ()=>{
      this.getBuildTypes();
    });
  }

  getBuildTypes() {
    var url = baseUrl.URLPath + "web/dashboard/buildtypes/" + this.state.productId + "?pageNo=0&pageSize=0";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();
        } else if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ buildTypes: response ?? [] })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  buildTypeChange(e) {
    this.setState({ buildTypeId: e.target.value }
      , () => {
        this.getPrerequisite();
      });
  }

  prerequisiteChange(e) {
    let fields = this.state.fields;
    fields['prerequisite'] = e.target.value;

    this.setState({ fields });
  }

  fileChange(e) {
    let fields = this.state.fields;
    var imagedata = document.getElementById("buildFile").files[0];

    var simple_file_upload = document.getElementsByClassName('simple_file_upload')[0];
    simple_file_upload.nextElementSibling.setAttribute('data-file', simple_file_upload.files[0].name);

    fields['file'] = imagedata;

    this.setState({ fields });
  }

  releaseChange() {
    let fields = this.state.fields;
    var imagedata = document.getElementById("releaseNotes").files[0];
    fields['releaseNotes'] = imagedata;

    var simple_file1 = document.getElementsByClassName('simple_upload1')[0];
    simple_file1.nextElementSibling.setAttribute('data-file', simple_file1.files[0].name);
    
    this.setState({ fields });
  }
  
  backToList() {
    this.props.history.push('/dx2.1Builds')
  }

  handleSubmit() {
    let fields = this.state.fields;

    var errors = {};

    if (this.state.modelName == "") {
      errors["modalError"] = "Please select model"
    } else {
      errors["modalError"] = ""
    }

    if (fields.version == "") {
      errors["versionError"] = "Please enter the version"
    } else {
      errors["versionError"] = ""
    }

    if (fields.prerequisite == "") {
      errors["prerequisite"] = "Please select the prerequisite"
    } else {
      errors["prerequisite"] = ""
    }

    if (fields.file == "" || fields.file == undefined) {
      errors["FirmwareFileError"] = "Please select firmware file"
    } else {
      errors["FirmwareFileError"] = ""
    }

    if (fields.releaseNotes == "" || fields.releaseNotes == undefined) {
      errors["releaseNoteError"] = "Please select release notes"
    } else {
      errors["releaseNoteError"] = ""
    }

    if (this.state.isModelDX && this.state.organization == "") {
      errors["orgError"] = "Please select the organization"
    } else {
      errors["orgError"] = ""
    }

    if (this.state.isModelDX && this.state.productId == "") {
      errors["prodError"] = "Please select the product"
    } else {
      errors["prodError"] = ""
    }

    if (this.state.isModelDX && this.state.buildTypeId == "") {
      errors["buildTypeError"] = "Please select the build type"
    } else {
      errors["buildTypeError"] = ""
    }

    this.setState({ errors: errors })
    
    var data = new FormData();
    data.append("model", this.state.modelName);
    data.append("target_type_id", 0);
    data.append("version_name", fields.version);
    data.append("prerequisite", fields.prerequisite);
    data.append("file", fields?.file);
    data.append("release_note", fields.releaseNotes);
    data.append("target_ids", fields.targetIds);

    if(this.state.isModelDX){
      data.append("build_type_id", this.state.buildTypeId);
    }

    if(errors['FirmwareFileError'] == "" && errors['buildTypeError'] == "" && errors['modalError'] == "" && errors['orgError'] == "" && errors['prerequisite'] == "" && errors["prodError"] == "" && errors['releaseNoteError'] == '' && errors['versionError'] == ''){
      if(this.state.isModelDX){
        var url = baseUrl.URLPath + "web/dashboard/" + this.state.organization + "/ota/" + this.state.productId;
      } else {
        var url = baseUrl.URLPath + "dashboard/ota/upload";
      }

      fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status == 201) {
            this.setState({ message: "New build is added sucessfully.", colour: 'success' })
            this.onShowAlert();
          } else if (resp.status == 404) {
            return resp.json();
          } else {
            return resp.json();
          }
        })
        .then((response) => {
          var fileExten = response[0]?.recommendation;
          var errorMessage = response?.message;

          if (errorMessage == "Please select .bin file to upload.") {
            errors["FirmwareFileError"] = errorMessage
          } else if (fileExten == "Please select .gbl file to upload." || fileExten == "Please select .zip file to upload.") {
            errors["FirmwareFileError"] = fileExten
          } else if (fileExten == "Prerequsite should not be greater or equal of version.") {
            errors["prerequisite"] = fileExten
          } else if (fileExten == "Build already exists with this version, build type and product.") {
            this.setState({ message: fileExten, colour: 'danger' })
            this.onShowAlert();
          } else if (fileExten == "Access Denied") {
            this.setState({ message: fileExten, colour: 'danger' })
            this.onShowAlert();
          }

          this.setState({ errors: errors })
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        }) 
    }
  }

  render() {
    const models = this.state.models.map((model, index) => {
      return (
        <option value={model} key={`model_key_${index}`}>{model}</option>
      )
    });

    const prerequisites = this.state.prerequisites.map((data, index) => {
      return (
        <option value={data.id} key={`prerequisite_key_${index}`}>{data.version}</option>
      )
    });

    const organisationDTOS = this.state.orgsArray.map((org, index) => {
      return (
        <option value={org.id} key={`org_key_${index}`}>{org.company}</option>
      )
    });

    const products = this.state.dataArray.map((product, index) => {
      return (
        <option value={product.product_id} key={`product_key_${index}`}>{product.product_name}</option>
      )
    });

    const buildTypes = this.state.buildTypes.map((data, index) => {
      return (
        <option value={data.id} key={`buildtype_key_${index}`}>{data.name}</option>
      )
    });

    return (
      <>
        <main class="content-div">
          <p> Builds
            <div className="breadcrumb_div">
              FOTA &gt; <Link to="./builds">Builds</Link > &gt; <span className="breadcrumb_page">Add</span>
            </div>
          </p>
          <form className='needs-validation' noValidate>
            <div className="page-outerdiv">
              <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <span class=" form-title">Details:</span>
                </div>
                <MDBCol md="4" >
                  <div className="w_f">
                    <label className="pull-left pt-3">Model: *</label>
                    <select className="browser-default custom-select select_height w-75 pull-right" id="modelId" onChange={this.onChangeModel.bind(this)} value={this.state.modelName}>
                      <option value="">Select Model</option>
                      {models}
                    </select>
                  </div>
                  <span className="error_msg w3-animate-top">
                    <span style={{ color: "red" }}>{this.state.errors["modalError"]}</span>
                  </span>
                </MDBCol>
                {
                  this.state.isModelDX && <>
                    <MDBCol md="4">
                      <div className="md-form">
                        <select className="browser-default custom-select select_height mb_8" id="organization" onChange={this.organizationChange.bind(this)} value={this.state.organization}>
                          <option value="">Select Organization *</option>
                          {organisationDTOS}
                        </select>
                        <span style={{ color: "red" }}>{this.state.errors["orgError"]}</span>
                      </div>
                    </MDBCol>
                    <MDBCol md="4">
                      <div className="md-form">
                        <select className="browser-default custom-select select_height mb_8" id="productId" onChange={this.productChange.bind(this)} value={this.state.productId}>
                          <option value="">Select Product *</option>
                          {products}
                        </select>
                        <span style={{ color: "red" }}>{this.state.errors["prodError"]}</span>
                      </div>
                    </MDBCol>
                    <MDBCol md="4">
                      <div className="md-form mt-2" >
                        <select className="browser-default custom-select select_height mb_8" id="buildTypes" onChange={this.buildTypeChange.bind(this)} value={this.state.buildTypeId}>
                          <option value="">Select Build Type *</option>
                          {buildTypes}
                        </select>
                        <span style={{ color: "red" }}>{this.state.errors["buildTypeError"]}</span>
                      </div>
                    </MDBCol>
                  </>
                }
                <MDBCol md="4" className='mt-2'>
                  <MDBInput
                    value={this.state.fields["version"]}
                    onChange={this.numberHandler.bind(this, "version")}
                    type="text"
                    label="Version *"
                    autoComplete="off"
                  >
                    <span style={{ color: "red" }}>{this.state.errors["versionError"]}</span>
                  </MDBInput>
                </MDBCol>
                
                <MDBCol md="4">
                  <div className="md-form mt-2">
                    <select className="browser-default custom-select select_height mb_8" id="prerequisites" onChange={this.prerequisiteChange.bind(this)} value={this.state.fields.prerequisite}>
                      <option value="">Select Prerequisite *</option>
                      {prerequisites}
                    </select>
                    <span style={{ color: "red" }}>{this.state.errors["prerequisite"]}</span>
                  </div>
                </MDBCol>
                <MDBCol md="4">
                  <label className="mt-1">
                    <input type="file" class="simple_file_upload" id="buildFile" onChange={this.fileChange.bind(this)} />
                    <span data-file="No file choosen">Choose Firmware file</span>
                  </label>
                  <span className="error_msg w3-animate-top">
                    <span style={{ color: "red" }}>{this.state.errors["FirmwareFileError"]}</span>
                  </span>
                </MDBCol>
                <MDBCol md="4" >
                  <label className="mt-1">
                    <input type="file" class="simple_upload1" id="releaseNotes" onChange={this.releaseChange.bind(this)} />
                    <span data-file="No file choosen">Choose Release Notes</span>
                  </label>
                  <span className="error_msg w3-animate-top">
                  <span style={{ color: "red" }}>{this.state.errors["releaseNoteError"]}</span>
                  </span>
                </MDBCol>
              </div>

              <div className="row">
                <MDBCol md="12" className="text-right mt-3">
                  <MDBBtn color='primary' type='button' onClick={this.handleSubmit}>
                    Save
                  </MDBBtn>
                  <MDBBtn color='primary' onClick={this.backToList} >
                    <span style={{ color: "#fff" }}> Back </span>
                  </MDBBtn>
                </MDBCol>
              </div>
            </div>
          </form>
        </main>
        <MDBModal isOpen={this.state.model} size="md" className="model_top">
          <MDBModalHeader>Session has expired</MDBModalHeader>
          <MDBModalBody>
            Please login again.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}
export default addBuild;