import React from 'react';
import { render } from 'react-dom';
import { withTranslation } from 'react-i18next';
import { MDBCol } from 'mdbreact';
import { Table } from 'react-bootstrap';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import Calendar from 'react-calendar';
import Pagination from "react-js-pagination";
import moment from 'moment';
import $ from 'jquery';

//API Imports
import * as baseUrl from '../views/constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeadersWithTimezone } from '../common/HttpRequestClass';

class Inventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color_black: true,
            graphView: 'none',
            tableView: 'block',
            bgColor1: "",
            bgColor2: "active",
            modalsession: false,
            startDate: "",
            toDate: "",
            startDate1: "",
            startDate2: "",
            frequency: "DAILY",
            calenderFrom: "none",
            calenderTo: "none",
            inventory: [
                {
                    date: "",
                    startDate: "",
                    endDate: "",
                    modelList: []
                }
            ],
            current_inventory: [
                {
                    date: "",
                    startDate: "",
                    endDate: "",
                    modelList: []
                }
            ],
            temp_inventory: [],
            series: [],
            options: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: this.props.t('inventory_dashboard_title')
                },
                xAxis: {
                    categories: []
                },
                tooltip: {
                    pointFormat: `<p>${this.props.t('name')}: {series.name}</p> <br> <p>${this.props.t('count')}: {point.y}</p>`
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true
                        }
                    },
                    series: {
                        pointWidth: 20
                    }
                },
                series: [
                    {
                        name: '',
                        data: [],
                        visible: false
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ['downloadPNG', 'downloadSVG', 'downloadXLS']
                        },
                    },
                    enabled: true,
                },
                navigation: {
                    buttonOptions: {
                        align: 'right',
                        verticalAlign: 'top',
                        y: 0
                    }
                },
                credits: {
                    enabled: false
                }
            },
            activePage: '',
            pageSize: 10,
            noOfRecords: "",
        }
        // this.sessiontagOk = this.sessiontagOk.bind(this);
        this.GetFromDate = this.GetFromDate.bind(this);
        this.GetToDate = this.GetToDate.bind(this);
        this.formatCalDate = this.formatCalDate.bind(this);
        this.getInventory = this.getInventory.bind(this);
        this.frequencyChange = this.frequencyChange.bind(this);
        this.changeFrom = this.changeFrom.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.changeToDate = this.changeToDate.bind(this);
        this.keyPress2 = this.keyPress2.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    changePage(pageNumber) {
        let page_inventories = [];

        for (let i = (pageNumber - 1) * 10; i < (pageNumber * 10) && i < this.state.current_inventory.length; i++) {
            page_inventories.push(this.state.current_inventory[i]);
        }

        this.setState({
            temp_inventory: page_inventories, activePage: pageNumber
        });
    }

    GetFromDate = date => {
        this.setState({ startDate: date, startDate1: this.formatCalDate(date) }, ()=>{
            this.getInventory();
        });
    }

    GetToDate = date => {
        this.setState({ toDate: date, startDate2: this.formatCalDate(date) }, ()=>{
            this.getInventory();
        });
    }

    formatCalDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day,].join('-');
    }

    async getInventory() {
        if (this.state.startDate1 !== "" && this.state.startDate2 !== "" && this.state.frequency !== "") {
            var url = baseUrl.URLPath + servicesUrl.getDashboardInventory + this.state.startDate1 + "&toDate=" + this.state.startDate2 + "&frequency=" + this.state.frequency;
            let type = ApiMethodTypes.GET;
            let headers = getSecurityHeadersWithTimezone();

            // payload
            let payload = {}

            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

            if (response !== undefined) {

                let table_inventory = [];

                response.inventory = response.inventory.map((data, index) => {
                    if (data.modelList.length !== 0) {
                        table_inventory.push(data);
                    }

                    return data;
                })

                this.setState({
                    frequency: response.frequency,
                    inventory: response.inventory,
                    current_inventory: table_inventory
                });
                this.changePage(1);

                var model_names = [];
                var models_count = 0;
                var datesArray = [];
                var xAxis = [];
                var xAxis_count = 0;
                var dataSeries = [];

                response.inventory = response.inventory.map((obj, i) => {
                    obj.modelList = obj.modelList.map((Obj, i) => {
                        model_names.push(Obj.Name);

                        return Obj;
                    });

                    return obj;
                });

                var unique_model_names = [...new Set(model_names)];
                models_count = unique_model_names.length;

                if (response.frequency === 'DAILY') {
                    response.inventory = response.inventory.map((dates, index) => {
                        datesArray = [...datesArray, dates.date];
                        xAxis.push(dates.date);

                        return dates;
                    })
                } else if (response.frequency === 'MONTH' || response.frequency === 'WEEK') {
                    response.inventory = response.inventory.map((dates, index) => {
                        datesArray = [...datesArray, dates.startDate];
                        xAxis.push(dates.startDate + " - " + dates.endDate);

                        return dates;
                    })
                }

                xAxis_count = xAxis.length;

                for (var j = 0; j < models_count; j++) {
                    window['arr' + j] = new Array(xAxis_count);
                    for (var a = 0; a < xAxis_count; a++) {
                        window['arr' + j][a] = 0;
                    }
                }

                if (response.frequency === "DAILY") {
                    response.inventory = response.inventory.map((inventories, index) => {
                        var date = inventories.date;
                        var pos = datesArray.indexOf(date);
                        inventories.modelList = inventories.modelList.map((model, i) => {
                            var Pos = unique_model_names.indexOf(model.Name);
                            window['arr' + Pos][pos] = model.count;

                            return model;
                        })

                        return inventories;
                    })
                } else if (response.frequency === 'MONTH' || response.frequency === 'WEEK') {
                    response.inventory.map((inventories, index) => {
                        var date = inventories.startDate;
                        var pos = datesArray.indexOf(date);
                        inventories.modelList = inventories.modelList.map((model, i) => {
                            var Pos = unique_model_names.indexOf(model.Name);
                            window['arr' + Pos][pos] = model.count;

                            return model;
                        })

                        return inventories;
                    })
                }

                for (var k = 0; k < models_count; k++) {
                    dataSeries.push({ "name": unique_model_names[k], "data": window['arr' + k] });
                }
                this.setState(prevState => ({
                    ...prevState.state,
                    //series:[...dataSeries],
                    options: {
                        ...prevState.options,
                        xAxis: {
                            ...prevState.options.xAxis,
                            categories: xAxis
                        },
                        series: [...dataSeries]
                    }
                }), () => {
                    $('#chart-data').remove();
                    $("#inventory-chart").append("<div id='chart-data'></div>");
                    render(<HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={this.state.options} />,
                        document.getElementById('chart-data'))
                });
            }
        }
    }
    componentDidMount() {
        var fdate = this.formatLocalCalDate(moment().startOf('week'));
        var tdate = this.formatLocalCalDate(moment());

        this.setState({ 
            startDate: fdate, 
            toDate: tdate, 
            startDate1: this.formatCalDate(moment().startOf('week')), 
            startDate2: this.formatCalDate(moment()) 
        }, ()=>{
            this.getInventory();
            this.changePage(1);
        });
    }

    frequencyChange() {
        var id = document.getElementById("getFrequency").value;
        this.setState({ frequency: id }, ()=>{
            this.getInventory();
        });
    }
    closeCalender(type, event) {
        if (type === "from") {
            this.setState({
                calenderFrom: "none"
            })
        } else {
            this.setState({
                calenderTo: "none"
            })
        }
    }

    displayCalender(type, event) {
        var calenderFrom = "";
        var calenderTo = "";
        if (type === "from") {
            calenderFrom = "block";
            calenderTo = "none";
        } else {
            calenderTo = "block";
            calenderFrom = "none";
        }
        this.setState({
            calenderFrom: calenderFrom,
            calenderTo: calenderTo
        })
    }
    changeFrom(e) {
        this.setState({ startDate: e.target.value });
    }
    changeToDate(e) {
        this.setState({ toDate: e.target.value });
    }
    keyPress(e) {
        var key = e.key;
        if (e.keyCode === 13) {
            if (new Date() >= e.target.value) {
                var dateFormat = moment(e.target.value).format("YYYY-MM-DD");
                var timeFormat = new Date().toISOString().substr(0, 19) + "Z";
                var convertedTime = timeFormat.slice(10);
                let startDate1 = dateFormat + convertedTime;
                this.setState({ startDate: e.target.value, startDate1: startDate1 }, ()=>{
                    this.getInventory();
                    this.changePage(1);
                });
            } else {
                this.setState({ startDate: "", startDate1: "" });
                $('.future_err1').text(this.props.t('cannot_select_futuredate'));
            }
        }

        if (key === "Delete" || key === "Backspace") {
            this.setState({ startDate1: "" }, ()=>{
                this.getInventory();
                this.changePage(1);
            });
        }
    }
    keyPress2(e) {
        var key = e.key;
        if (e.keyCode === 13) {
            if (new Date() >= e.target.value) {
                var dateFormat = moment(e.target.value).format("YYYY-MM-DD");
                var timeFormat = new Date().toISOString().substr(0, 19) + "Z"
                var convertedTime = timeFormat.slice(10);
                let startDate2 = dateFormat + convertedTime
                
                this.setState({ toDate: e.target.value, startDate2: startDate2 }, ()=>{
                    this.getInventory();
                    this.changePage(1);
                });
            } else {
                this.setState({ toDate: "", startDate2: "" });
                $('.future_err2').text(this.props.t('cannot_select_futuredate'));
            }
        }
        if (key === "Delete" || key === "Backspace") {
            this.setState({
                startDate2: ""
            }, ()=>{
                this.getInventory();
                this.changePage(1);
            })
        }
    }
    fromChange = date => {
        var fdate = this.formatLocalCalDate(date);
        this.setState({ startDate: fdate, calenderFrom: "none", startDate1: this.formatCalDate(date) }, ()=>{
            this.getInventory();
            this.changePage(1);
        });
    };

    toChange = date => {
        var fdate = this.formatLocalCalDate(date);
        this.setState({ toDate: fdate, startDate2: this.formatCalDate(date), calenderTo: "none" }, ()=>{
            this.getInventory();
            this.changePage(1);
        });
    };

    formatLocalCalDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        var monthname = d.toLocaleString('default', { month: 'short' });
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [day, monthname, year].join(' ');
    }
    changeColor() {
        var bgColor1 = '';
        var bgColor2 = '';
        var tableView = "";
        var graphView = "";
        if (this.state.color_black === true) {
            bgColor1 = "active";
            bgColor2 = "";
            tableView = "none";
            graphView = "block";
            this.getInventory();
        } else {
            bgColor1 = "";
            bgColor2 = "active";
            tableView = "block";
            graphView = "none";
            this.getInventory();
        }
        this.setState({ color_black: !this.state.color_black, bgColor1: bgColor1, bgColor2: bgColor2, tableView: tableView, graphView: graphView })
    }
    render() {
        const { t } = this.props
        let modelsCount = 0;
        const inventoryTable = this.state.temp_inventory.map((item, index) => {

            modelsCount = modelsCount + item.modelList.length;
            return item.modelList.map((Model, index) => {
                if (index === 0) {
                    return (
                        <tr>
                            {(this.state.frequency === "DAILY") ? <td rowSpan={item.modelList.length}>{item.date}</td> : null}
                            {(this.state.frequency === "MONTH" || this.state.frequency === "WEEK") ? <><td rowSpan={item.modelList.length}>{item.startDate}</td><td rowSpan={item.modelList.length}>{item.endDate}</td></> : null}
                            <td>{Model.Name}</td>
                            <td>{Model.count}</td>
                        </tr>
                    )
                } else {
                    return (
                        <tr>
                            <td>{Model.Name}</td>
                            <td>{Model.count}</td>
                        </tr>
                    )
                }
            })
        });

        var dates;
        if (this.state.frequency !== "") {
            if (this.state.frequency !== "" && this.state.frequency === "DAILY") {
                dates = <th>{t('date')}</th>
            } else if (this.state.frequency === "MONTH" || this.state.frequency === "WEEK") {
                dates = <>
                    <th>{t('start_date')}</th>
                    <th>{t('end_date')}</th>
                </>
            }
        }

        return (
            <main className="content-div">
                <p>{t('Inventory')}
                    <div className="breadcrumb_div">{t('Dashboard')} &gt; {t('Inventory')}</div>
                </p>

                <div className="page-outerdiv">
                    <div className="row col-md-6">
                        <div className="pull-right">
                            <label className="pull-left sub-text"> {t('view')} </label>
                            <div className="toggle-btnb">
                                <label className={this.state.bgColor1} onClick={this.changeColor.bind(this)} >{t('graph')}</label>
                                <label className={this.state.bgColor2} onClick={this.changeColor.bind(this)}>{t('table')}</label>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row mt-2">
                        <div className="col-md-3 p-rel">
                            <div class="input-group">
                                <input type="text" class="form-control input-searchBox pl-3" placeholder={t('from_time')} name="srch-term" id="schedulefromDate" value={this.state.startDate} onChange={this.changeFrom} onKeyDown={this.keyPress} />
                                <div class="input-group-btn">
                                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'from')} >
                                        <i className="fas fa-calendar-alt fa-sm"></i>
                                    </button>
                                </div>
                            </div>

                            <div style={{ display: this.state.calenderFrom }} className="calenderContainer">
                                <div onClick={this.closeCalender.bind(this, 'from')}><i class="far fa-times-circle"></i></div>
                                <Calendar
                                    selected={this.state.startDate}
                                    onChange={date => this.fromChange(date)}
                                    timeIntervals={1}
                                    timeCaption="time"
                                    dateFormat="dd-MMM-yyyy"
                                    maxDate={(this.state.toDate !== "" && this.state.toDate !== null) ? new Date(this.state.toDate) : new Date()}
                                />
                            </div>
                            <span className="pull-left future_err1 w3-animate-top mt-1" style={{ color: "red" }}></span>
                        </div>

                        <div className="col-md-3 p-rel">
                            <div class="input-group">
                                <input type="text" class="form-control input-searchBox pl-3" placeholder={t('to_time')} name="srch-term" id="srch-term" value={this.state.toDate} onChange={this.changeToDate} onKeyDown={this.keyPress2} />
                                <div class="input-group-btn">
                                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'to')}>
                                        <i className="fas fa-calendar-alt fa-sm"></i>
                                    </button>
                                </div>
                            </div>
                            <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                                <div onClick={this.closeCalender.bind(this, 'to')}><i class="far fa-times-circle"></i></div>
                                <Calendar
                                    selected={this.state.toDate}
                                    onChange={date => this.toChange(date)}
                                    timeIntervals={1}
                                    // className="cal_margin tag_cal"
                                    timeCaption="time"
                                    dateFormat="dd-MMM-yyyy"
                                    maxDate={new Date()}
                                />
                            </div>
                            <span className="pull-left future_err2 w3-animate-top mt-1" style={{ color: "red" }}></span>
                        </div>
                        <MDBCol md="3" className="mt-2 pl-0"></MDBCol>
                        <MDBCol md="3" className="mt-2">
                            <select className="browser-default custom-select select_height mb_8" id="getFrequency" onChange={this.frequencyChange.bind(this)}>
                                <option value="">-- {t('select_frequency')} --</option>
                                <option value="DAILY" selected>{t('daily').toUpperCase()}</option>
                                <option value="WEEK">{t('week').toUpperCase()}</option>
                                <option value="MONTH">{t('month').toUpperCase()}</option>
                            </select>
                            <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} ></span></span>
                        </MDBCol>

                    </div>

                    <div style={{ display: this.state.tableView }}>



                        <div className="row mt-2">
                            <div className="col-md-12">
                                <Table striped hover size="sm" className="page-table">
                                    <thead>
                                        <tr>
                                            {dates}
                                            <th>{t('model_name')}</th>
                                            <th>{t('count')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inventoryTable}
                                        {
                                            (modelsCount === 0 && this.state.frequency === "DAILY") ?
                                                <tr>
                                                    <td colSpan="3" style={{ textAlign: "center" }}>{t('no_data_available')}</td>
                                                </tr> : null
                                        }
                                        {
                                            (modelsCount === 0 && (this.state.frequency === "WEEK" || this.state.frequency === "MONTH")) ?
                                                <tr>
                                                    <td colSpan="4" style={{ textAlign: "center" }}>{t('no_data_available')}</td>
                                                </tr> : null
                                        }

                                        {/* {inventoryTable} */}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="col-md-12 mt-2 mb-4 text-right">
                                <Pagination
                                    hideDisabled
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={this.state.current_inventory.length}
                                    onChange={this.changePage.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: this.state.graphView }} >

                        <div className="row" id="graphView"  >
                            <div className="col-md-12">
                                <div className="mt-1 img-main-div" >
                                    <div id="inventory-chart">

                                    </div>

                                    {/* //width="100%"/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withTranslation()(Inventory);