import React from 'react';
import { Doughnut } from "react-chartjs-2";
import { withTranslation } from 'react-i18next';

//API Imports
import * as baseUrl from '../constants';
import servicesUrl from '../../common/servicesUrl';
import APICallUtility from '../../common/APICallUtility';
import ApiMethodTypes from '../../common/ApiMethodTypes';
import { getSecurityHeaders } from '../../common/HttpRequestClass';

class StatusGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offlineCount: '',
      onlineCount: '',
      unknownCount: '',
      dataDoughnut: {
        labels: [this.props.t('offline'), this.props.t('online'), this.props.t('unknown')],
        dataLabels: { enabled: false },
        datasets: [{
          data: [],
          backgroundColor: ["#00529e", "#72C93E", "#FF9336"],
          hoverBackgroundColor: []
        }]
      },
      statusData: {
        "tenantId": '',
        "onlineCPCount": '1',
        "oflineCPCount": '1',
        "unknownCPCount": '1'
      }
    }

    this.getChargepointsCount = this.getChargepointsCount.bind(this);
    this.refreshStatus = this.refreshStatus.bind(this);
  }

  componentDidMount() {
    this.getChargepointsCount();
  }

  async getChargepointsCount() {
    let url = baseUrl.URLPath + servicesUrl.getChargepointsCount;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    document.getElementById("loader_image_div_status").style.display = "block";

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

    if (response !== undefined) {
      var total = response.oflineCPCount + response.onlineCPCount;
      var perOnline = 0;
      var perOffline = 0;
      var perUnknown = 0;
      if (total === 0) {
        perOnline = 0;
        perOffline = 0;
        perUnknown = 0;
        this.state.dataDoughnut.datasets[0].data = [perOffline, perOnline];
        this.setState({
          statusData: response,
          offlineCount: perOffline,
          onlineCount: perOnline,
          unknownCount: response.perUnknown,
        })
      }
      else {
        perOnline = (response.onlineCPCount / total) * 100;
        perOffline = (response.oflineCPCount / total) * 100;
        perUnknown = response.unknownCPCount;
        perOffline = perOffline.toFixed(2);
        perOnline = perOnline.toFixed(2);
        perUnknown = perUnknown.toFixed();
        this.state.dataDoughnut.datasets[0].data = [perOffline, perOnline];
        this.setState({
          statusData: response,
          offlineCount: perOffline,
          onlineCount: perOnline,
          unknownCount: perUnknown,
        })

      }
    }
    document.getElementById("loader_image_div_status").style.display = "none";
  }

  refreshStatus() {
    this.getChargepointsCount();
  }

  render() {
    return (
      <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success dashboard-height">
        <div className="content-box">
          <div className="dashboard-subtitle">
            {this.props.t('chargingSessionState')}
            <span className="pull-right cursor-pointer">
              <img src={process.env.PUBLIC_URL + "/assets/img/refresh_btn.svg"} width="22" onClick={this.refreshStatus} />
            </span>
          </div>
          <div className="mt-3 mb-2 mt-3">
            <Doughnut data={this.state.dataDoughnut} options={{ responsive: true, legend: { display: false } }} height={100} width={168} />
          </div>
          <div className="row white-space mt-5">
            <div className="col-md-4 text-center green_text f-12">
              <span>{this.props.t('online')}</span><br></br>
              <span>{this.state.onlineCount}%</span>
            </div>
            <div className="col-md-4 text-center gblue_text  f-12">
              <span>{this.props.t('offline')}</span><br></br>
              <span>{this.state.offlineCount}%</span>
            </div>
            <div className="col-md-4 text-center yellow_text  f-12">
              <span>{this.props.t('unknown')}</span><br></br>
              <span>{this.state.unknownCount}</span>
            </div>
          </div>
        </div>
        {/* Loader */}
        <div className="loader center" id="loader_image_div_status" style={{ display: "none", borderRadius: "18px" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>
      </div>
    )
  };
}

export default withTranslation()(StatusGraph);
