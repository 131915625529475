import React from 'react';
import { createBrowserHistory } from 'history';
import { hasPermission } from '../auth';
import { withTranslation } from 'react-i18next';
import $ from "jquery";

import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap';
import * as roles from '../roles';

// API Imports
import * as baseUrl from '../constants';
import servicesUrl from '../../common/servicesUrl';
import APICallUtility from '../../common/APICallUtility';
import ApiMethodTypes from '../../common/ApiMethodTypes';
import { getSecurityHeaders } from '../../common/HttpRequestClass';

// custom style imports
import '../cpDashboard.css';

const history = createBrowserHistory();

class Header extends React.Component {
  constructor(props) {
    super(props);
    let selectedSite;
    let selectedLanguage;

    try {
      selectedSite = JSON.parse(localStorage.getItem('SelectedSite'));
    } catch (error) {
      selectedSite = null;
    }

    try {
      selectedLanguage = localStorage.preferredLanguage
    } catch (error) {
      selectedLanguage = '';
    }

    this.state = {
      active: this.props.activetab,
      userName: "",
      title: this.props.page_title,
      modelswitch: false,
      modelSwitchSite: false,
      modelDelete: false,
      orgArray: [],
      orgName: "",
      choosenTenant: "",
      disableMenu: "disabled-link",
      switch_org_name: "",
      disable_switchacc: "disabled-link",
      logoff_Account: "none",
      UserTenant: localStorage.getItem("UserTenant"),
      user: { permissions: [] },
      information: false,
      error: false,
      warning: false,
      notificationsModal: false,
      notificationSettingModal : false,
      notificationsList: [],
      savemodal: false,
      count: 0,
      availableSites: [],
      selectedSite,
      choosenSiteId: '',
      listOfLanguages: [],
      selectedLanguage: selectedLanguage,
      openHelp : false,
      openProfile : false,

    }
    this.modalRef = React.createRef();
    this.modalHelp = React.createRef();
    this.modalProfile = React.createRef();
    this.modalNotifSetting = React.createRef();
    // this.getLanguages = this.getLanguages.bind(this);
    this.handleHelp = this.handleHelp.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
    this.getAllAvailableSites = this.getAllAvailableSites.bind(this);
    this.resetNotificationCount = this.resetNotificationCount.bind(this);
    this.getNotificationType = this.getNotificationType.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.setNotificationType = this.setNotificationType.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.SwitchOkAcctoggle = this.SwitchOkAcctoggle.bind(this);
    this.switchAccountOrgChange = this.switchAccountOrgChange.bind(this);
    this.LogOffAccount = this.LogOffAccount.bind(this);
    this.SwitchAcctoggle = this.SwitchAcctoggle.bind(this);
    this.logOut = this.logOut.bind(this);
    this.openSwitchSiteModal = this.openSwitchSiteModal.bind(this);
    this.switchSiteChange = this.switchSiteChange.bind(this);
    this.closeSwitchSiteModal = this.closeSwitchSiteModal.bind(this);
    this.confirmSwitchSiteModal = this.confirmSwitchSiteModal.bind(this);
    this.logOffSite = this.logOffSite.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.getNotificationCount = this.getNotificationCount.bind(this);
    // this.onChangeLanguage = this.onChangeLanguage.bind(this);
    this.GetAllOrganizations = this.GetAllOrganizations.bind(this);
    this.getAuthorizationDetails = this.getAuthorizationDetails.bind(this);
  }

  // async getLanguages() {
  //   let url = baseUrl.URLPath + servicesUrl.getPreferredLanguages;
  //   let type = ApiMethodTypes.GET;
  //   let headers = getSecurityHeaders();
  //   let payload = {};

  //   let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

  //   this.setState({
  //     listOfLanguages: response ?? []
  //   });
  // }

  handleHelp(){
    if (!this.state.openHelp) {
      document.addEventListener("click", this.handleOutsideClickHelp, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClickHelp, false);
    }

  this.setState({ openHelp: !this.state.openHelp })

    // this.setState((prevState)=>({openHelp : !prevState.openHelp}))
    
  }

  handleOutsideClickHelp = e => {
    if (e.target !== null && e.target !== undefined) {
      if (!this.node?.contains(e.target)) this.handleHelp();
    }
}
  
  handleProfile(){

    if (!this.state.openProfile) {
      document.addEventListener("click", this.handleOutsideClickProfile, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClickProfile, false);
    }

  this.setState({ openProfile: !this.state.openProfile })
    // this.setState({openProfile : !this.state.openProfile})

  }
  handleOutsideClickProfile = e => {
    if (e.target !== null && e.target !== undefined) {
      if (!this.node?.contains(e.target)) this.handleProfile();
    }
}
  async getAllAvailableSites() {
    let url = baseUrl.URLPath + servicesUrl.getAvailableGroups;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if(response){
      if(response.status === 403){
        this.setState({ availableSites: [] });
      } else {
        this.setState({ availableSites: response ?? [] });
      }
    } else {
      this.setState({ availableSites: [] });
    }
  }

  async resetNotificationCount() {
    let url = baseUrl.URLPath + servicesUrl.resetNotifications;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    this.setState({
      count: response?.count?.count ?? 0,
      notificationsList: response?.pnList ?? this.state.notificationsList
    });
  }

  async getNotificationType() {
    let url = baseUrl.URLPath + servicesUrl.getNotificationTypes;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    this.setState({
      error: response?.error ?? false,
      warning: response?.warning ?? false,
      information: response?.info ?? false,
      notificationSettingModal : true
    });
  }

  handleNotifSettingsModal =()=>{
    if (!this.state.notificationSettingModal) {
      document.addEventListener("click", this.handleOutsideClickNotifSetting, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClickNotifSetting, false);
    }

  this.setState({ notificationSettingModal: !this.state.notificationSettingModal })
  }

  handleOutsideClickNotifSetting = e => {
    if (e.target !== null && e.target !== undefined) {
      if (!this.node?.contains(e.target)) this.handleNotifSettingsModal();
    }
}

  async getNotifications() {
    let url = baseUrl.URLPath + servicesUrl.getNotifications + 10 + "&pageNo=" + 1;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    this.setState({ notificationsList: response?.pnList ?? [] });
  }

  async setNotificationType() {
    let url = baseUrl.URLPath + servicesUrl.saveNotificationTypes;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();

    //payload
    let payload = {
      error: this.state.error,
      warning: this.state.warning,
      info: this.state.information,
      userId: "",
      id: ""
    }

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    this.setState({
      savemodal: response ? !this.state.savemodal : this.state.savemodal,
      error: response?.error ?? false,
      warning: response?.warning ?? false,
      information: response?.info ?? false
    });
  }

  toggleNotifications() {
    if (!this.state.notificationsModal) {
      document.addEventListener("click", this.handleOutsideClickNotif, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClickNotif, false);
    }

    this.setState({
      notificationsModal: !this.state.notificationsModal
    });
  }

  handleOutsideClickNotif =  e => {
    if (e.target !== null && e.target !== undefined) {
      if (!this.node?.contains(e.target)) this.toggleNotifications();
    }
}

  async SwitchOkAcctoggle() {
    if (this.state.identifier !== "") {
      localStorage.setItem("switchOrgName", this.state.switch_org_name);
      this.state.logoff_Account = "block";

      let url = baseUrl.URLPath + servicesUrl.changeTenant;
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      headers["stenantid"] = this.state.identifier;

      //payload
      let payload = {}

      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      if (response !== undefined) {
        let getViewDetails = localStorage.getItem("saveDetails")
        localStorage.removeItem('SelectedSite');
        if (response.status === "SUCCESS" && getViewDetails === "true") {
          localStorage.setItem("saveDetails", false);
          localStorage.setItem("Authorization", "Bearer " + response.token);
          localStorage.setItem("tenant", this.state.identifier);
          localStorage.setItem("choosen_tenant", this.state.identifier);
          history.push('/dashboard');
          this.removeFilterDetails();
          window.location.reload();
        } else if (response.status === "SUCCESS") {
          localStorage.setItem("saveDetails", false);
          localStorage.setItem("Authorization", "Bearer " + response.token);
          localStorage.setItem("tenant", this.state.identifier);
          localStorage.setItem("choosen_tenant", this.state.identifier);
          history.push('/dashboard');
          this.removeFilterDetails();
          window.location.reload();
        }
      }

      this.setState({
        modelswitch: !this.state.modelswitch
      });
    }
  }

  switchAccountOrgChange(event) {
    var index = event.nativeEvent.target.selectedIndex;
    var switch_org_name = event.nativeEvent.target[index].text;
    this.setState({
      identifier: event.target.value,
      switch_org_name: switch_org_name
    });
  }

  async LogOffAccount(event) {
    this.state.identifier = event.target.value;

    let url = baseUrl.URLPath + servicesUrl.logOffTenant;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();

    //payload
    let payload = {}

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response?.status === "SUCCESS") {
      localStorage.removeItem('SelectedSite');
      localStorage.setItem("Authorization", "Bearer " + response.token);
      localStorage.setItem("choosen_tenant", "none");
      localStorage.setItem("tenant", localStorage.getItem("UserTenant"));
      localStorage.setItem("switchOrgName", "");
      history.push('/dashboard')
      this.removeFilterDetails();
      window.location.reload();
    }
  }

  SwitchAcctoggle() {
    this.setState({
      modelswitch: !this.state.modelswitch,
      identifier: this.state.identifier !== "" ? localStorage.getItem("tenant") : this.state.identifier
    });
  }

  logOut() {
    localStorage.clear();
    history.push('/');
    window.location.reload();
  }

  openSwitchSiteModal() {
    this.setState({ ...this.state, modelSwitchSite: true });
  }

  switchSiteChange(event) {
    const choosenSiteId = event.target.value;
    this.setState({ ...this.state, choosenSiteId });
  }

  closeSwitchSiteModal() {
    this.setState({ ...this.state, modelSwitchSite: false });
  }

  async confirmSwitchSiteModal() {
    const selectedSite = this.state.availableSites.find(el => el.id === this.state.choosenSiteId);
    if (!selectedSite) {
      this.setState({ ...this.state, modelSwitchSite: false });
      return;
    }
    this.setState({ ...this.state, selectedSite });

    let url = baseUrl.URLPath + servicesUrl.changeSite;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();
    headers["siteid"] = selectedSite.id;

    //payload
    let payload = {}

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      localStorage.setItem("Authorization", `${response?.tokenType} ${response?.token}`);
      const { id, name } = this.state.selectedSite;
      const siteDetails = { id, name };
      localStorage.setItem("SelectedSite", JSON.stringify(siteDetails));
      history.push('/dashboard');
      this.removeFilterDetails();
      window.location.reload();
    }
  }

  removeFilterDetails(){
    localStorage.removeItem('filterEmail');
    localStorage.removeItem('filterOrganization');
    localStorage.removeItem('filterRole');
    localStorage.removeItem('filterShowEntries');
    localStorage.removeItem('filterFromDate');
    localStorage.removeItem('filterToDate');
    localStorage.removeItem('filterActivePage');
    localStorage.removeItem('filterCurPageFirstCreTime');
    localStorage.removeItem('filterCurPageLastCreTime');
    localStorage.removeItem('filterPrevPageFirstCreTime');
    localStorage.removeItem('filterNoOfRecords');
    localStorage.removeItem('filterListOfFirstCreatedtimes');
  }
  
  async getAuthorizationDetails() {
    var url = baseUrl.URLPath + servicesUrl.getAuthorizationDetails;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response) {
      localStorage.setItem("userPackage", JSON.stringify(response.userPackage));
    } else {
      console.log("Login Failed!");
    }
  }

  async logOffSite() {
    let url = baseUrl.URLPath + servicesUrl.logOffSite;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();

    //payload
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response?.status === 'SUCCESS') {
      localStorage.removeItem("SelectedSite");
      localStorage.setItem("Authorization", `${response?.tokenType} ${response?.token}`);
      history.push('/dashboard');
      this.removeFilterDetails();
      window.location.reload();
    }
  }

  UNSAFE_componentWillMount() {
    var user = localStorage.getItem('user');
    this.setState({ userName: user });
  }
  // handleClickOutside = (event) => {
  //   if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
  //     this.setState({notificationsModal : false})
  //   }
  //   if(this.modalHelp.current && !this.modalHelp.current.contains(event.target)){
  //     this.setState({openHelp : false})

  //   }
  //   if((this.modalProfile.current && !this.modalProfile.current.contains(event.target)) || (this.modalProfile.current && this.modalProfile.current.contains(event.target))){
  //     this.setState({openProfile : false})
  //   }
  //   if(this.modalNotifSetting.current && !this.modalNotifSetting.current.contains(event.target)){
  //     this.setState({notificationSettingModal : false})
  //   }
  // };
  // componentWillUnmount() {
  //   document.removeEventListener('mousedown', this.handleClickOutside);
  // }

  componentDidMount() {
    var localSelectedLng = localStorage.getItem("localSelectedLng");
    let user = { permissions: [] };
    user.permissions = localStorage.getItem("roleAccess");
    // document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({ 
      user: user, 
      choosenTenant: localStorage.getItem("choosen_tenant"),
      disable_switchacc: (hasPermission(user, ['SwitchAccount'])) ? "" : this.state.disable_switchacc,
      disableMenu: (localStorage.getItem("choosen_tenant") === "none" && hasPermission(user, ['SwitchAccount'])) ? "" : this.state.disableMenu
    }, ()=>{
      this.GetAllOrganizations();
    });

    if(localStorage.getItem("role") !== roles.WEBASTO_CUSTOMER_ADMIN_READ_ONLY){
      this.getNotificationCount();
    }

    if (this.props.shouldreset === true) { this.resetNotificationCount() };

    setInterval(() => { this.getNotificationCount() }, 1000 * 60 * 60);

    if(roles.WEBASTO_SUPERADMIN_FIRST_LEVEL !== localStorage.getItem('role')){
      this.getAllAvailableSites();
    }

    // this.getLanguages();

    let lan = "";
    if (localSelectedLng === "" || localSelectedLng === null) {
      lan = localStorage.preferredLanguage;
    } else {
      lan = localStorage.localSelectedLng
    }

    if (lan === "French") {
      this.props.i18n.changeLanguage('fr')
    } else if (lan === "English") {
      this.props.i18n.changeLanguage('en')
    }

    this.setState({
      selectedLanguage: lan
    })
    
    const getRole = localStorage.getItem('role');
    if (getRole === "WEBASTO_END_USER_RESIDENTIAL") {      
      this.getAuthorizationDetails();
    }
  }

  showSidebar() {
    var js = document.getElementsByClassName('sidebar-main-div');
    if ($(js).hasClass("scroll_nav")) {
      $(js).removeClass("scroll_nav");
    } else {
      $(js).addClass("scroll_nav");
    }
  }

  async getNotificationCount() {
    let url = baseUrl.URLPath + servicesUrl.getNotificationsCount;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    //payload
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    this.setState({ count: response?.count?.count ?? 0 });
  }

  // onChangeLanguage(lng) {
  //   let lngV = lng.target.value;
  //   this.setState({
  //     selectedLanguage: lng.target.value
  //   }, () => {
  //     localStorage.setItem("localSelectedLng", lngV);
  //     window.location.reload();
  //   })
  // }

  async GetAllOrganizations() {
    let url = baseUrl.URLPath + servicesUrl.getOrganizationsList;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    //payload
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    this.setState({ orgArray: response?.organisationDTOS }, ()=>{
      if (localStorage.getItem("roleAccess") === "Inventory_R,CSR_R" && this.state.orgArray.length === 0) {
        this.setState({ orgName: localStorage.getItem("tenant") })
      }

      let switch_org = localStorage.getItem("switchOrgName");

      for (let orgArray in this.state.orgArray) {
        if (switch_org && (switch_org.toLowerCase() === this.state.orgArray[orgArray].company.toLowerCase())) {
          switch_org = this.state.orgArray[orgArray].company.split(" ")[0];
          this.setState({ logoff_Account: "block", identifier: this.state.orgArray[orgArray].identifier });
        }

        if (this.state.UserTenant.toLowerCase() === this.state.orgArray[orgArray].identifier.toLowerCase()) {
          this.setState({ orgName : this.state.orgArray[orgArray].company });
        }
      }
    })
  }

  render() {
    const {t} = this.props;
    let switch_org = localStorage.getItem("switchOrgName");
    const siteManagementCustomization = this.props.customizationSettings.find(el => el.operation === 'Group Management');
    let hasSitemanagement = (siteManagementCustomization && siteManagementCustomization?.active) ? true : false;

    const organisation = this.state.orgArray.map((orgArray, index) => {
      var setOrg = false;
      var HideOrg = "";
      if (switch_org && (switch_org.toLowerCase() === orgArray.company.toLowerCase())) {
        switch_org = orgArray.company.split(" ")[0];
        setOrg = true
      }
      if (this.state.UserTenant.toLowerCase() === orgArray.identifier.toLowerCase()) {
        HideOrg = "none";
      }
      return (
       (this.state.UserTenant.toLowerCase() === orgArray.identifier.toLowerCase() ) ? "" : <option value={orgArray.identifier} selected={orgArray.identifier === this.state.UserTenant ? false : setOrg} style={{ "display": HideOrg }} > {orgArray.company} </option>
      )
    })

    const sitesDropdownHTML = this.state.availableSites?.map((el, index) => <option value={el.id} defaultValue={this.state.selectedSite}>
      {(el?.name?.length >= 28) ? el?.name?.substring(0, 25) + "..." : el?.name}</option>)

    const noticationsData = this.state.notificationsList.map((notification, index) => {
      if (index < 3) {
        return (
          <div className='d-flex justify-content-between mt-2'>
              <p className='notification-row'>{notification.notificationDescription}</p>
              <p className='notif-date'>
              {notification.createdDate}
              </p>
          </div>
        )
      } else {
        return <></>
      }
    })

    return (
      <>
        <header>
          {/* <!-- Navbar--> */}
          {/* <nav className="row navbar fixed-top white custom-navbar"> */}
          <nav className="navbar fixed-top navbar-expand-md navbar-light white double-nav scrolling-navbar custom-navbar">
            {/* <!-- SideNav slide-out button --> */}
            {/* <div className="breadcrumb-dn slide-arrow" onClick={this.showSidebar}>
              <p><i className="fas fa-bars" id="toggle_arrow" style={{ color: "#00529e" }}> </i></p>
            </div> */}
            {/* <div className="text-left w-15 pl-4">
              {localStorage.getItem("role") !== "WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE" ?
                  <a href="/dashboard" className="pl-0"><img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{ width: "120px" }} alt="" /></a>
                  :
                  <img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{ width: "120px" }} alt="" />
              }
            </div> */}
            {/* <!-- Navbar links--> */}
            {/* <div className="mr-auto pl-2 input-div">
              <div className="input-group input-search ">
                <i className="fas fa-search fa-sm"></i>
                <input type="text" className="border-0 small" placeholder={this.props.t('search_something')} aria-label="Search" aria-describedby="basic-addon2" />
              </div>
            </div> */}
            {/* <!--Navigation icons--> */}

            <div className='d-flex col-5'>  
                <button className='header-nav-btn  mr-3'>
                  <span>{t('Organization')}: <span>{(localStorage.getItem("switchOrgName") === "" || localStorage.getItem("switchOrgName") === null) ? this.state.orgName : localStorage.getItem("switchOrgName")}</span></span>
                  <img src={process.env.PUBLIC_URL + "/assets/img/svg/3dots_menu_white.svg"} className="dotsicon" height='25' alt=''
                  onClick={this.SwitchAcctoggle}
                  />
                </button>
                { hasPermission(this.state.user, ['Sitemanagement_R']) &&
                  <button className='header-nav-btn'>
                  <span>{t('group')}: {this.state.selectedSite && <span>{(this.state.selectedSite.name.length >= 28) ? this.state.selectedSite?.name?.substring(0, 25) + "..." : this.state.selectedSite?.name}</span>}</span>
                  <img src={process.env.PUBLIC_URL + "/assets/img/svg/3dots_menu_white.svg"} className="dotsicon" height='25' alt=''
                  onClick = {this.openSwitchSiteModal}/>
                  </button>
                }
                
            </div>
            <div className='col-3'></div>
            
            <div className={`${this.state.count > 0 ? 'nav-right mt-3' : 'nav-right'}`}>
            <ul className="nav navbar-nav">
                <div className='d-flex'>
                  {/* <li className='d-flex header-nav-btn' >
                    <img src={process.env.PUBLIC_URL + "/assets/img/svg/globe.svg"} className="dotsicon mr-2" height='18' alt=''/>
                    <select id="countryList" value={this.state.selectedLanguage} onChange={this.onChangeLanguage}>

                      {
                        this.state.listOfLanguages?.map((language, index) => {
                          return <option value={language} key={index}>{language}</option>
                        })
                      }

                    </select>

                  </li> */}
                
                  { hasPermission(this.state.user, ['PushNotifications_R']) && <>
                    <li className="nav-item mr-3 ml-3 cursor-pointer" onClick={() => { this.resetNotificationCount(); this.toggleNotifications(); this.getNotifications(); }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/svg/notification.svg"} className="dotsicon mr-2" height='37' alt=''/>
                      <div className="numberCircle">{(this.state.count>999)? "999+" : this.state.count ? this.state.count : ""}</div>
                    </li>
                    </>
                  }
                  
                  {
                    this.state.notificationsModal && (
                      <div className="notificationpopup" ref={node => {this.node = node;}}>
                        <div className='d-flex justify-content-between p-3'>
                          <p className='notification-head m-0 p-0'>{t('notifications')}</p>
                          <img src={process.env.PUBLIC_URL + "/assets/img/svg/notification_settings.svg"} height='20' width='20' alt='' 
                          data-toggle="modal" data-target="#noticationssetting" className='cursor-pointer'
                          onClick={()=> {this.getNotificationType(); this.handleNotifSettingsModal();}}/>
                        </div>
                        <div className='m-0 p-0'>
                        <hr className='hr-gray m-0 p-0'/>
                        </div>
                        <div className='p-3 '>
                          {noticationsData}
                        </div>
                        
                        <div className='text-center'>
                          <button className='show-notif-btn cursor-pointer' onClick={() => { window.location.replace("/notifications") }}>{t('show_all_notification')}</button>
                          </div>
                      </div>
                    )
                  }
                  {
                    this.state.notificationSettingModal && (
                      <div className='notificationpopup mr-4 text-left' ref={node => {this.node = node;}} id="noticationssetting" style={{zIndex:'20'}}>
                          {/* <!-- Modal Header --> */}
                          <div className=" row mx-0 p-2" ref={(node) => { if (node) { node.style.setProperty("border-bottom", "none", "important"); } }}>
                            <h4 className="notification-head mt-1 col-md-12 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{this.props.t('notification_settings')}</h4>
                          </div>
                          <div className='m-0 p-0'>
                        <hr className='hr-gray m-0 p-0'/>
                        </div>
                          {/* <!-- Modal body --> */}
              <div className="modal-body p-3">
                <div style={{ color: "#757D86", fontSize:'13px' }}>{this.props.t('notification_settings_desc')}</div>
                <div id="notificationid">
                  <MDBRow>
                    <MDBCol sm="12">
                     
                        <Form.Check
                          className="mt-3"
                          custom
                          checked={this.state.information}
                          id="notifyIfInfo"
                          type="checkbox"
                          label={<div>{this.props.t('notification_info')}</div>}
                          onChange={() => { this.setState({ information: !this.state.information }) }}
                        />
                     
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol sm="12">
                      
                        <Form.Check
                          className="mt-3"
                          custom
                          checked={this.state.warning}
                          id="notifyIfWarning"
                          type="checkbox"
                          label={<div>{this.props.t('notification_warn')}</div>}
                          onChange={() => { this.setState({ warning: !this.state.warning }) }}
                        />
                     
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol sm="12">
                      
                        <Form.Check
                          className="mt-3"
                          custom
                          checked={this.state.error}
                          id="notifyIfError"
                          type="checkbox"
                          label={<div>{this.props.t('notification_error')}</div>}
                          onChange={() => { this.setState({ error: !this.state.error }) }}
                        />
                      
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>

              {/* <!-- Modal footer --> */}
              <div className='d-flex justify-content-end p-2 '>
                <button type="button" data-test="button" onClick={this.handleNotifSettingsModal} className="notif-cancel-btn">{this.props.t('cancel')}</button>
                <button type="button" data-test="button"  onClick={this.setNotificationType} className="show-notif-btn ml-4 mr-3">{this.props.t('save')}</button>
              </div>
                      </div>

                    
                    )

                  }

                  <li className='cursor-pointer mr-2' style={{position:'relative'}}>
                  <img src={process.env.PUBLIC_URL + "/assets/img/svg/help.svg"} className="dotsicon mr-2" height='37' alt=''
                  onClick={this.handleHelp}/>
                  </li>
                  {
                    this.state.openHelp && (
                    <> </>
                    )
                  }
                  {
                  this.state.openHelp && (
                    <div className="notificationpopup p-3 help-icon"  ref={node => {this.node = node;}} style={{position : 'absolute', left:'-5px',minWidth:'175px'}} >
                    <a href={baseUrl.FAQ} target='_blank' className='d-flex cursor-pointer'>
                      <img src={process.env.PUBLIC_URL + "/assets/img/svg/faqs.svg"} alt='' height='18'/>
                      <p className='help-para'>{t('faq')}</p>
                    </a>
                    <a href={baseUrl.HELPLINE} target='_blank' className='d-flex cursor-pointer p-0'>
                      <img src={process.env.PUBLIC_URL + "/assets/img/svg/hotline.svg"} alt='' height='18' />
                      <p className='help-para mb-0' >{t('webasto_hotline')}</p>
                    </a>
                  </div>
                  )
                  }
                  

                  <li className='d-flex cursor-pointer mr-3' onClick={this.handleProfile} style={{position:'relative'}}>
                  <img src={process.env.PUBLIC_URL + "/assets/img/svg/user_icon.svg"} className="dotsicon mr-1" height='37' alt=''/>
                  <span className='d-none d-xl-inline-block ml-1 profile-pic-span mr-1'>{this.state.userName}</span>
                  </li>
                  
                  {
                  this.state.openProfile && (
                    <div className="notificationpopup p-3 profile-icon" ref={node => {this.node = node;}}  style={{position : 'absolute', right:'0',left: 'auto', minWidth:'145px'}}>
                    <div className='d-flex cursor-pointer' onClick={() => { window.location.replace("/userManagementController") }}>
                      <img src={process.env.PUBLIC_URL + "/assets/img/svg/my_account.svg"} alt='' height='18'/>
                      <p className='help-para '>{t('my_account')}</p>
                    </div>
                    <div className='d-flex p-0 cursor-pointer' onClick={this.logOut}>
                      <img src={process.env.PUBLIC_URL + "/assets/img/svg/logout.svg"} alt='' height='18'/>
                      <p className='help-para mb-0'>{t('logout')}</p>
                    </div>
                  </div>
                  )
                  }
                </div>
              
              </ul>
            </div>

          </nav>
          {/* <!-- /.Navbar--> */}
        </header>

       {/* Switch organization */}
       <MDBModal isOpen={this.state.modelswitch} size="lg" backdrop="static" className="model_top mw-650" data-backdrop="static" data-keyboard="false">
          <MDBModalHeader className='popup-header'>{this.props.t('switch_account')}</MDBModalHeader>
          <MDBModalBody>
            <div className="row p-4">
              <label className="switch_acc ml-3">{this.props.t('select_organization')}</label>
              <div className="col-md-7 mb-3 popup-dropdown">
                <select className="browser-default custom-select select_height mb_8" id="organization" onChange={this.switchAccountOrgChange.bind(this)}>
                  <option value="">{this.props.t('select_organization')}</option>
                  {organisation}
                </select>
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <div className='d-flex justify-content-between p-2'>
              <div className='pl-2'>
                {this.state.logoff_Account === 'block' &&
                <button type="button" data-test="button"  onClick={this.LogOffAccount} className="btn_cancel logout_btn mt-2 mb-2">{this.props.t('logout_from_organization')}: {(localStorage.getItem("switchOrgName") === "" || localStorage.getItem("switchOrgName") === null) ? this.state.orgName : localStorage.getItem("switchOrgName")} </button>
                }
              </div>
              <div className='pr-2'>
                <button type="button" data-test="button" onClick={this.SwitchAcctoggle} className="btn_cancel mt-2 mb-2 mr-2">{this.props.t('cancel')} <i className="fas fa-times ml-2"></i></button>
                <button type="button" data-test="button" onClick={this.SwitchOkAcctoggle} className="btn_primary mt-2 mb-2" style={{padding : '7px 25px'}}>{this.props.t('ok')}</button>

              </div>
            </div>

          </MDBModalFooter>
        </MDBModal>

        {/* Switch Group */}
        <MDBModal isOpen={this.state.modelSwitchSite} size="lg" backdrop="static" className="model_top mw-650" data-backdrop="static" data-keyboard="false">
          <MDBModalHeader className='popup-header'>{this.props.t('switch_group')}</MDBModalHeader>
          <MDBModalBody>
            <div className="row p-4">
              <label className="switch_acc ml-3">{this.props.t('select_group')}</label>
              <div className="col-md-8 mb-4 popup-dropdown">
                <select defaultValue={this.state.selectedSite?.id} className="browser-default custom-select select_height mb_8" id="switch_site_dropdown" onChange={this.switchSiteChange.bind(this)}>
                  <option value="">{this.props.t('select_group')}</option>
                  {sitesDropdownHTML}
                </select>
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
          <div className='d-flex justify-content-between p-2'>
              <div >
                {this.state.selectedSite && 
                <button type="button" data-test="button"  onClick={this.logOffSite} className="btn_cancel logout_btn mt-2 mb-2">{this.props.t('logout_from_group')}: {this.state.selectedSite && <span>{(this.state.selectedSite.name.length >= 28) ? this.state.selectedSite?.name?.substring(0, 25) + "..." : this.state.selectedSite?.name}</span>}</button>
                }
              </div>
              <div >
                <button type="button" data-test="button" onClick={this.closeSwitchSiteModal} className="btn_cancel mt-2 mb-2 mr-2">{this.props.t('cancel')} <i className="fas fa-times ml-2"></i></button>
                <button type="button" data-test="button" onClick={this.confirmSwitchSiteModal} className="btn_primary mt-2 mb-2" style={{padding : '7px 25px'}}>{this.props.t('ok')}</button>

              </div>
            </div>
          </MDBModalFooter>
        </MDBModal>

        <div className="modal" id="" ref={(node) => { if (node) { node.style.setProperty("z-index", "100002", "important"); } }} style={{ display: (this.state.savemodal) ? "block" : "none" }}>
          {/* Operations Modal */}
          <div className="modal-backdrop fade show"></div>
          <div className="modal-dialog modal_top t-200" ref={(node) => { if (node) { node.style.setProperty("z-index", "100003", "important"); } }}>
            <div className="modal-content b-15">

              {/* <!-- Modal Header --> */}
              <div className="modal-header row mx-0">
                <h4 className="modal-title fw-800 mt-1 col-md-12 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{this.props.t('success')}</h4>
              </div>

              {/* <!-- Modal body --> */}
              <div className="modal-body">
                {this.props.t('notification_save_alert')}
              </div>

              {/* <!-- Modal footer --> */}
              <div className="modal-footer border">
                <button type="button" data-test="button" onClick={() => { window.location.reload() }} className="btn_primary">{this.props.t('ok')}</button>
              </div>
            </div>
          </div>
        </div>

        {/* <MDBModal isOpen={this.state.notificationsModal} toggle={this.toggleNotifications} size="md" className="model_top w-800">
          <div className="modal-header row mx-0" toggle={this.toggleNotifications}>
            <h4 className="modal-title mt-1 col-md-6" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{this.props.t('notification_header')}</h4>
            <div className="col-md-5"></div>
            <div className="col-md-1"><img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Operations.png"} data-toggle="modal" data-target="#noticationssetting" style={{ position: "relative", top: "5px", fontSize: "18px" }} onClick={this.getNotificationType.bind(this)} alt=""></img></div>
          </div>
          <MDBModalBody>
            <Table striped hover size="md" className="page-table">
              <tbody>
                {
                  this.state.notificationsList.map((notification, index) => {
                    if (index < 5) {
                      return (
                        <tr onClick={() => { localStorage.setItem("notificationObject", JSON.stringify(notification)); window.location.replace("/notification") }}>
                          <td style={{ width: "15%" }}>{notification.createdDate}</td>
                          <td style={{ width: "15%" }}>{notification.chargePointId}</td>
                          <td style={{ width: "10%" }}>{notification.type}</td>
                          <td style={{ width: "60%" }}>{notification.notificationDescription}</td>
                        </tr>
                      )
                    } else {
                      return <></>
                    }
                  })
                }
              </tbody>
            </Table>
          </MDBModalBody>
          <div data-test="modal-footer" className="modal-footer" style={{ justifyContent: "space-between" }}>
            <button type="button" data-test="button" onClick={() => { window.location.replace("/notifications") }} className="btn_primary">{this.props.t('show_all')}</button>
            <button type="button" data-test="button" onClick={this.toggleNotifications} className="btn_primary">{this.props.t('ok')}</button>
          </div>
        </MDBModal> */}

        
      </>
    )
  }
}

export default withTranslation()(Header);
