import React from 'react';
import * as baseUrl from '../views/constants';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

import ConnectorStatusTemplate from '../Screens/ConnectorStatusTemplate';
import servicesUrl from '../common/servicesUrl';

 class ConnectorStatus extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            modalsession: false,
            multiple: false,
            options: [],
            errors: {},
            pageSize: 10,
            activePage: 1,
            connectorsData: [],
            noOfRecords: null,
            statusChange: '',
            chargePoint: '',
            loading: false,
        }
        this.togglesession = this.togglesession.bind(this);
        this.getConnectors = this.getConnectors.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.getSearchCPList = this.getSearchCPList.bind(this);
        this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
        this.clearChargePoint = this.clearChargePoint.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.exportStatus = this.exportStatus.bind(this);
    }

    exportStatus(){
        let usertimezone = new Date().toLocaleDateString(undefined, {day:'2-digit',timeZoneName: 'long' }).substring(4).match(/\b(\w)/g).join('');
        var url = baseUrl.URLPath + "chargepoints/connectors/download/xls?chargePointId=" + this.state.chargePoint + "&status=" + this.state.statusChange + "&timeZone=" + usertimezone;

        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then((resp) => {
            if (resp.status === 401) {
                // this.togglesession();
                // localStorage.clear();
                window.location.reload();
            } else if (resp.status === 200) {
                return resp.blob();
            }
        }).then((response) => {
            if (response !== undefined) {
                let d = new Date();
                let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                let url = window.URL.createObjectURL(response);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'Connectors_Status_' + dformat + '.xls';
                a.click();
            }
        }).catch((error) => {
            console.log(error, "catch the loop")
        })
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }

    componentDidMount(){
        this.getConnectors(this.state.pageSize, this.state.activePage, this.state.statusChange, this.state.chargePoint);
    }

    async getConnectors(pageSize, pageNo, status, chargePointId) {
        this.setState({ loading: true })
        let errors = {};
        var url = baseUrl.URLPath +servicesUrl.getConnectors+"?pageSize=" + pageSize + "&pageNo=" + pageNo + "&status=" + status + "&cpid=" + chargePointId;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if(response){
            if(response.status === 404){
                errors["chargePointNotExists"] = this.props.t(response.message);
                this.setState({
                    errors: errors,
                    chargePoint: '',
                    loading: false
                })
            } else {
                this.setState({
                    connectorsData: response.data,
                    noOfRecords: response.count.count,
                    loading: false
                })
            }
        }
    }

    handleKeyDown(ref, e) {
        var cpid = e.target.value;
        $("#nochargeexists").hide();

        if (cpid.length > 1) {
            $("#selectcharge").hide();
        } else {
            $("#selectcharge").show();
        }

        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
        var cpId = cpid.slice(0, cpid.length - 1);

        this.getSearchCPList(cpId);

        if (e.keyCode === 13) {
            $("#selectcharge").hide();
            this.onChangeChargepoint(ref, e);
            $("#rbt_id").hide();
        } else if (key === "Backspace" || key === "Delete") {
            if (cpId === "") {
                this.setState({
                    chargePoint: cpId
                },()=>{
                    this.getConnectors(this.state.pageSize, this.state.activePage, this.state.statusChange, this.state.chargePoint)
                })
            }
        } else if ((e.keyCode === 86 && ctrl)) {
            $("#selectcharge").hide();
            this.setState({
                options: []
            },()=>{
                this.getSearchCPList(cpid)
            });
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9., -]");
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    async getSearchCPList(cpid) {
        var url = baseUrl.URLPath + "chargepoints/search/partial?cpid=" + cpid;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if (response !== undefined) {
            this.setState({
                options: response,
                intial_data: response
            });
        }
    }

    onChangeChargepoint(ref, e) {
        var serial_no = e.target.value;
        var cpid = ref.current.inputNode.value;
        var errors = {};

        if (cpid === null || cpid === "") {
            errors["chargePointNotExists"] = this.props.t('enter_chargepoint_id');
            this.setState({
                errors: errors
            }, () => {
                this.getConnectors(this.state.pageSize, this.state.activePage, this.state.statusChange, this.state.chargePoint);
            });
        } else {
            errors["chargePointNotExists"] = "";
            this.setState({
                errors: errors,
                chargePoint: cpid,
                activePage: 1
            }, () => {
                this.getConnectors(this.state.pageSize, this.state.activePage, this.state.statusChange, cpid);
            });
        }

        $("#nochargeexists").show();

        if (serial_no.length > 1) {
            $("#selectcharge").hide();
        } else {
            $("#selectcharge").show();
        }
    }

    clearChargePoint(ref, event) {
        let errors = this.state.errors;
        if(errors['chargePointNotExists']!==''){
            errors["chargePointNotExists"] = "";
            this.setState({
                chargePoint: '',
                errors: errors
            });
        } else {
            errors["chargePointNotExists"] = "";
            this.setState({
                chargePoint: '',
                activePage: 1,
                errors: errors
            }, () => {
                this.getConnectors(this.state.pageSize, this.state.activePage, this.state.statusChange, this.state.chargePoint);
            }); 
        }
        ref.current.clear();
    }

    onStatusChange() {
        var id = document.getElementById("status").value;

        this.setState({ statusChange: id }, () => {
            this.getConnectors(this.state.pageSize, this.state.activePage, this.state.statusChange, this.state.chargePoint);
        });
    }

    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;

        this.setState({
            activePage: 1,
            pageSize: entries
        },()=>{
            this.getConnectors(this.state.pageSize, this.state.activePage, this.state.statusChange, this.state.chargePoint);
        })
    }

    refreshData(){
        this.getConnectors(this.state.pageSize, this.state.activePage, this.state.statusChange, this.state.chargePoint);
    }

    handlePageChange(pageNumber) {
        if (pageNumber !== this.state.activePage) {
            this.setState({ activePage: pageNumber }, () => {
                this.getConnectors(this.state.pageSize, this.state.activePage, this.state.statusChange, this.state.chargePoint);
            });
        }
    }

    render(){
        return(
            <ConnectorStatusTemplate
                {...this.state}
                handleKeyDown = {this.handleKeyDown}
                onChangeChargepoint = {this.onChangeChargepoint}
                clearChargePoint = {this.clearChargePoint}
                onStatusChange = {this.onStatusChange}
                showEntriesChange = {this.showEntriesChange}
                refreshData = {this.refreshData}
                handlePageChange = {this.handlePageChange}
                sessiontagOk = {this.sessiontagOk}
                exportStatus = {this.exportStatus}
            />
        );
    }
}

export default withTranslation()(ConnectorStatus);