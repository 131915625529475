import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';

class CustomFilterDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      errors: {},
      isOpen: false,
    }
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleDropdown = () => {
   if(this.props.showHistory) {
    this.props.getFilterHistory() 
   }
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
    this.props.emptySearchValues();
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        isOpen: false,
      });
      this.props.emptySearchValues();
    }
  };
  handleApply = () => {
    this.setState({
      isOpen: false,
    });
    this.props.handleSubmit();
  }

  render() {
    const { t } = this.props
    const { selectedOptions, iconSrc, placeholder, headerText, option } = this.props;
    const checkIdGetName = (val) => {
      const a1 = val;
      let ObjVal;
      if (this.props.type === "filterUserListName") {
        ObjVal = this.props.filterUserListName;
      } else {
        ObjVal = this.props.filterReportUserListName;
      }
      const checking = ObjVal.find((item) => (item.id === a1 ? item.name : null));
      return checking?.name;
    };
    return (
      <div className="custom-filter-dropdown" ref={this.wrapperRef}>
        <div className="filter-header" onClick={this.toggleDropdown}>
          <button className="button">
            <img src={process.env.PUBLIC_URL + iconSrc} className={this.props.cssClass + " icon pr-2"} />
            <span className="filterName">{headerText}</span>
            <span className="caret-icon"></span>
          </button>
        </div>
        <div className="filter-body" style={{marginTop:"2.15px"}}>
          {(this.state.isOpen) && (
            <div className="dropdown-options">
              <div className="dropdown-list">
                {option?.map((val) => (
                  <Form.Check
                    custom
                    type="checkbox"
                    id={`custom-${val}`}
                    label={(this.props.category === "Users") ? checkIdGetName(val) : val}
                    // label={(val?.length >= 21) ? val?.substring(0, 17) + "..." : val}
                    key={val}
                    value={val}
                    checked={selectedOptions[this.props.category].includes(val)}
                    onChange={() => this.props.handleCheckboxChange(this.props.category, val)}
                    className="checkbox"

                  />
                ))}
                <span className="error_msg w3-animate-top"><p className="dropdownErrorMsg">{this.props.errorsMsg}</p></span>
              </div>
              <button type="button" className="btn" onClick={this.handleApply} disabled={!this.props.isApplyButtonDisabled}>
                {t('apply')}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomFilterDropdown);
