import React from 'react';
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { hasPermission } from './auth';

//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders } from '../common/HttpRequestClass'

class viewOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      expdate: '',
      sucess: {},
      errors: {},
      disabled: true,
      id: "",
      activeItem: "1",
      isChecked: "1",
      startDate1: "",
      startDate: new Date(),
      par_id_tag: "",
      modalsession: false,
      user: {
        permissions: []
      },
      modal: false,
      modal1: false
    }
    this.editOrgnization = this.editOrgnization.bind(this)
    this.backToList = this.backToList.bind(this)
    this.deleteOrgnization = this.deleteOrgnization.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
    this.closeOkPopup = this.closeOkPopup.bind(this)
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleConfirmation = () => {
    this.setState({
      modal1: !this.state.modal1
    });
  }

  closeOkPopup() {
    this.setState({
      modal: !this.state.modal
    });
    this.props.history.push('/organisationList');
  }

  backToList() {
    this.props.history.push('/organisationList');
  }

  async getAdminData() {
    let orgid = this.props.location.state;
    this.state.id = orgid;
    let url = baseUrl.URLPath + servicesUrl.getAdminAccountByid + "?id=" + orgid;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      var data1 = [];
      data1 = response.organisationDTOS;
      this.setState({ fields: data1[0] })
    }
  }

  componentDidMount() {
    let user = { permissions: [] };
    user.permissions = localStorage.getItem("roleAccess");

    this.setState({ user }, () => {
      this.getAdminData();
    })
  }

  redirectToListPage() {
    this.props.history.push('/organisationList')
  }

  editOrgnization() {
    this.props.history.push('./organisationEdit');
  }

  // Delete Orgnization 
  deleteOrgnization() {

    this.setState({
      modal1: !this.state.modal1
    });
    let errors = {};
    let url = baseUrl.URLPath + servicesUrl.getAdminAccountByid + "?id=" + this.state.id;
    fetch(url,
      {
        method: 'DELETE',
        body: JSON.stringify("NULL"),
        headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("Authorization"),
        }
      }
    ).then(resp => {

      if (resp.status === 401) {
        window.location.reload();
      }
      else if (resp.status === 404) {
        this.setState({ errors: errors });
        return resp.json();
      }
      else if (resp.status === 500) {
        alert("internal server error")
      } else if (resp.status === 400) {
        alert("Bad request")
      } else {
        this.setState({
          modal: !this.state.modal
        });
      }
    })
      .catch(error => alert('Error:' + error));
    this.setState({ errors: errors });
  }


  render() {
    const { t } = this.props
    let user = this.state.user;

    return (
      <>
        <main class="content-div" >
          <p> {t('Organization')}
            <div className="breadcrumb_div">
              {t('Settings')} &gt; <Link to="/organisationList">{t('Organization')}</Link > &gt; <span className="breadcrumb_page">{t('view')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button className="btn_primary" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> {t('back')}</button>
                </div>
              </div>
            </div>
            <form className='needs-validation'>
              <div className="row mb-4 mt-3">
                <div className="col-sm-6">
                  <div className="content-innerdiv">
                    <div className="" >
                      <label>
                        {t('Organization')}:
                      </label>
                      <label className='text-break'>
                        {this.state.fields["company"]}
                      </label>
                    </div>
                    <div className="">
                      <label>
                        {t('office_add')}:
                      </label>
                      <label>
                        {this.state.fields["officeAddress"]}
                      </label>
                    </div>
                    <div className=" ">
                      <label>
                        {t('state')}
                      </label>
                      <label>
                        {this.state.fields.state}
                      </label>
                    </div>
                    <div className="">
                      <label>
                        {t('url')}:
                      </label>
                      <label>
                        {this.state.fields["url"]}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="content-innerdiv">
                    <div className="" >
                      <label>
                        {t('short_name')}:
                      </label>
                      <label >
                        {this.state.fields["identifier"]}
                      </label>
                    </div>
                    <div className=" ">
                      <label>
                        {t('city')}:
                      </label>
                      <label>
                        {this.state.fields.city}
                      </label>
                    </div>
                    <div className="">
                      <label>
                        {t('country')}
                      </label>
                      <label>
                        {this.state.fields.country}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-right">
                  {hasPermission(user, ['Organizations_W']) &&
                    <Link to={{
                      pathname: '/organisationEdit',
                      state: this.state.id,
                    }}>
                      <button className="refresh-btn mt-5 mr-3" onClick={this.editOrgnization} id="editBtn">{t('edit')} <i class="fas fa-pencil-alt ml-2"></i></button>
                    </Link>}
                </div>
              </div>
            </form>
          </div>
        </main>

        <MDBModal isOpen={this.state.modal1} toggle={this.toggleConfirmation} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleConfirmation}>{t('confirmation')}</MDBModalHeader>
          <MDBModalBody>
            {t('delete_confirmation')}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="danger" onClick={this.toggleConfirmation}>{t('close')}</MDBBtn>
            <MDBBtn color="primary" onClick={this.deleteOrgnization}>{t('yes')}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('org_deleted_successfully')}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.closeOkPopup}>{t('ok')}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(viewOrganization);