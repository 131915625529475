import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import {Table } from 'react-bootstrap'
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
//API imports 
import * as baseUrl from './constants'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import {getSecurityHeaders} from '../common/HttpRequestClass'

const history = createBrowserHistory();

class addChargingSchedule extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
            modal:false,  
            modalSuccess:false,  
            modalsession:false,
            page_title:"Add Charging Schedule",
            startScheduleDate:"",
            errors:{},
            fields:{
                  "duration": "",
                  "chargingScheduleName": "",
                  "startSchedule":"",
                  "schedulingUnit":"",
                  "minChargingRate":"",
                  "chargingSchedulePeriod": []
                },
                schedulePeriod:{
                                "startPeriod":"",
                                "limit":"",
                                "numberPhases":""
                            },

        }

        this.backToList = this.backToList.bind(this)
        this.setStartSchedule = this.setStartSchedule.bind(this)
        this.saveChargingSchedulePeriod = this.saveChargingSchedulePeriod.bind(this)
        this.addChargingSchedulePeriod = this.addChargingSchedulePeriod.bind(this)
        this.toggle = this.toggle.bind(this);
        this.saveChargingSchedule = this.saveChargingSchedule.bind(this);
        this.handlePeriodChange = this.handlePeriodChange.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.CloseSuccessPopup = this.CloseSuccessPopup.bind(this);
        this.chargeRateUnit = this.chargeRateUnit.bind(this);
        
}

//This function is used for back button
backToList(){
  this.props.history.push('/chargingSchedule');
}

//This function for setting the date to state
setStartSchedule(date){
  var dateNew =  this.formatDateandTime(date)
  var fields = this.state.fields;
  fields["startSchedule"] = dateNew
  this.setState({ 
    startScheduleDate:date ,
    fields
   })
}

formatDateandTime(currentDate){
  var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
  var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
  var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
  var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
  var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" +currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
  return createdDateTo;
}

//This is for making user should enter only numbers

  numberHandler(field, e) {
    let errors = this.state.errors;
    let fields = this.state.fields;

    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value === "" || e.target.value === ".") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }

    var chargingScheduleName = this.state.fields['chargingScheduleName'];
    if (chargingScheduleName.length !== 0) {
      errors["chargingScheduleNameError"] = "";

      this.setState({ errors });
    }
  }

secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var i_d = d < 10 ? "0" : ""
    d = i_d+d;
    var h = Math.floor(seconds % (3600*24) / 3600);
    var h_d = h < 10 ? "0" : ""
    h = h_d+h;
    var m = Math.floor(seconds % 3600 / 60);
    var m_d = m < 10 ? "0" : ""
    m = m_d + m;
    var s = Math.floor(seconds % 60);
    var s_d = s < 10 ? "0" : ""
    s = s_d + s;

    return d+":"+h+":"+m+":"+s;
  }


// function to open popup
  async toggle(){
    this.setState({
      modal: !this.state.modal
    });
    
    var emptyschedulePeriod = {};
    emptyschedulePeriod["startPeriod"] = ""
    emptyschedulePeriod["limit"] = "";
    emptyschedulePeriod["numberPhases"] =""
    this.setState({
      schedulePeriod:emptyschedulePeriod,
    })
    this.setState({
      modal: !this.state.modal
    });
  }

  async togglesession(){
    ;
    this.setState({
    modalsession: !this.state.modalsession
    });
  }

  sessiontagOk(){
    history.push('/');
    window.location.reload();
  }

  //  function call when click on save button to save all the data 
async saveChargingSchedule(event){
  event.preventDefault();
  let errors = {};

  var SchedulingUnit= this.state.fields['schedulingUnit'];

  var chargingSchedulePeriod= this.state.fields['chargingSchedulePeriod'];
  if(SchedulingUnit===''){
    errors["schedulingUnitError"] = this.props.t('select_acharging_rate_unit');
  }
  if(chargingSchedulePeriod.length===0){
    errors["emptyaddChargingScheduleError"] = this.props.t('please_add_charging_schedule_period');
  }
  if( errors.hasOwnProperty("schedulingUnitError") || chargingSchedulePeriod.length===0 || errors.hasOwnProperty("emptyaddChargingScheduleError") )
  {

  }else{
    let url = baseUrl.URLPath + "chargingschedules"
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();
    let payload = this.state.fields;
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if(response.status === 409){
      errors["chargingScheduleNameError"] = this.props.t('charging_schedule_name_already_exists')
      this.setState({errors: errors});
    }
    if(response === 404){
      this.setState({errors: errors});
    }
    if(response.status === undefined && response !== undefined && response !== ""){
      this.toggleAdd()
    }
  }
  this.setState({errors: errors});
}

  addChargingSchedulePeriod(){
 
    var duration= this.state.fields['duration'];
    if(duration.length!==0){
      this.setState({
        modal: !this.state.modal
      });
      $('.err_duration').empty();
    }else{
      $('.err_duration').text(this.props.t('duration_should_not_empty'));
    }
    

  }

  toggleAdd(){
    this.setState({
      modalSuccess: !this.state.modalSuccess
    });
  }
  
  CloseSuccessPopup(){
    this.setState({
      modalSuccess: !this.state.modalSuccess
    });
    this.props.history.push('/chargingSchedule');
  }

  handlePeriodChange(field, evt) {
    let errors = this.state.errors;
    var startfield = document.getElementById("startperiod").value;
    var limitfield = document.getElementById("limit").value;

    var schedulePeriod = this.state.schedulePeriod;
    schedulePeriod[field] = evt.target.validity.valid ? evt.target.value : "";

    if (schedulePeriod["startperiod"] === startfield) {
      errors["emptyStartPeriodError"] = " ";
    }

    if (schedulePeriod["limit"] === limitfield) {
      errors["emptylimitError"] = " ";
    }

    this.setState({ schedulePeriod, errors });
  }

  chargeRateUnit(e){
    var x = e.target.value;
    let fields = this.state.fields;
    let errors = this.state.errors;
    fields["schedulingUnit"] = x; 
    if(x!==0){
      errors["schedulingUnitError"] = " ";
    }

    this.setState({fields, errors});
  }

  //  function call when click on add charging period save button 
  saveChargingSchedulePeriod(){

    var startPeriod = this.state.schedulePeriod['startPeriod'];
    var limit = this.state.schedulePeriod['limit'];
    let errors = {};

    if(startPeriod===''){
      errors["emptyStartPeriodError"] = this.props.t('please_enter_start_period');
    }

    if( startPeriod < this.state.fields.duration ){

      if(limit===''){
        errors["emptylimitError"] = this.props.t('please_enter_alimit');
      }else{
          this.state.fields['chargingSchedulePeriod'].push(this.state.schedulePeriod);
          this.setState({
            modal: !this.state.modal
          });
          var emptyschedulePeriod = {};
          emptyschedulePeriod["startPeriod"] =""
          emptyschedulePeriod["limit"] = "";
          emptyschedulePeriod["numberPhases"] =""
          this.setState({
            schedulePeriod:emptyschedulePeriod
          })
      }

    }else{
      errors["emptyStartPeriodError"] = this.props.t('start_period_must_less_than_duration');
    }

      this.setState({
        errors: errors
      });
  }

render(){
  const{t}=this.props
  // print 
   let schedulePeriodRows = "";
   if(this.state.fields.chargingSchedulePeriod.length > 0){
        schedulePeriodRows  = this.state.fields.chargingSchedulePeriod.map((periodArray, index) => {
        return(
            <tr>
              <td>{ periodArray.startPeriod +" ("+this.secondsToDhms(periodArray.startPeriod)+") "  }</td>
              <td>{ periodArray.limit }</td>
              <td>{ periodArray.numberPhases}</td>
            </tr>
          )
      });
    }
  return (
    <>
       <main class="content-div" >
       <p> {t('Charging Schedule')}
              <div className="breadcrumb_div">
                 {t('charge_point')} &gt; <Link to="/chargingSchedule">{t('Charging Schedule')}</Link > &gt; <span className="breadcrumb_page">{t('add')}</span>
              </div>
          </p>
        <form
          className='needs-validation'
          onSubmit={this.submitHandler}
          noValidate>

            <div className="page-outerdiv">
              {/* <div className="breadcrumb_div">Charge Point > <Link to="/chargingSchedule" > <span className="">Charging Schedule</span></Link> >  Add</div> */}
              <div className="row">
                <div className="col-md-12">
                  <div className="pull-left">
                  <button className="btn_primary" type="button" data-test="button" onClick={this.backToList}><i class="fas fa-angle-left"></i> {t('back')}</button>
                  </div>
                </div>
              </div>
              <div className="row mb-3 mt-3">
                <div className="col-md-4 md-form">
                  <input name="chargingScheduleName" onInput={this.numberHandler.bind(this,"chargingScheduleName")} type="text" id="chargingScheduleNameId" className="form-control text_lowercase" onChange={this.changeHandler} value={this.state.fields["chargingScheduleName"]} />
                  <label htmlFor="chargingScheduleNameId" className={`${(this.state.fields['chargingScheduleName']!=="" && this.state.fields['chargingScheduleName']!==null)? "active" : ""} ml-15`}>{t('charging_schedule_name')}</label>
                  <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["chargingScheduleNameError"]}</span></span>
                </div>
                <div className="col-md-4 md-form">
                  <input pattern="[0-9]*" name="duration" onInput={this.numberHandler.bind(this,"duration")} type="text" id="durationId" className="form-control text_lowercase" onChange={this.changeHandler} value={this.state.fields["duration"]} />
                  <label htmlFor="durationId" className={`${(this.state.fields['duration']!=="" && this.state.fields['duration']!==null)? "active" : ""} ml-15`}>{t('chargingDuration')} ( {this.secondsToDhms(this.state.fields["duration"])} )</label>
                  <span className="pull-left err_duration w3-animate-top mt-1" style={{color: "red"}}></span>
                </div>
                <div className="col-md-4 md-form">
                  <input pattern="[{0-9}.]*" name="minChargingRate" onInput={this.numberHandler.bind(this,"minChargingRate")} type="text" id="minChargingRate" className="form-control text_lowercase" value={this.state.fields["minChargingRate"]} />
                  <label htmlFor="minChargingRate" className={`${(this.state.fields['minChargingRate']!=="" && this.state.fields['minChargingRate']!==null)? "active" : ""} ml-15`}>{t('min_charging_rate')}</label>
                </div>
                <MDBCol md="4" >
                  <div className="w_f">
                    <label className="pull-left pt-3">{t('charging_rate_unit')}:</label>
                    <select className="browser-default custom-select select_height w-50 pull-right" id="chargeRateUnit" onChange={this.chargeRateUnit.bind(this)}>
                      <option value="">{t('select_rate_unit')}</option>
                      <option value="W">{t('w')}</option>
                      <option value="A">{t('a')}</option>
                    </select>
                  </div>
                  <span className="error_msg w3-animate-top ml-0 mt-0">  <span style={{color: "red"}}>{this.state.errors["schedulingUnitError"]}</span></span> 
                </MDBCol>
                <MDBCol md="4" style={{position:"relative"}} >
                    <span className="cal_label">{t('start_schedule')}</span>
                      <div className="pull-left mt-2" style={{width:"50%"}} >
                        <DatePicker
                          selected={this.state.startScheduleDate}
                          onChange={this.setStartSchedule}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={1}
                          className="cal_margin tag_cal"
                          timeCaption="time"
                          dateFormat="dd-MMM-yyyy HH:mm"
                        />
                      </div>
                    <span className="error_msg w3-animate-top cal_errormsg"> <span style={{color: "red"}} >{this.state.errors["emptyscheduleDate"]}</span></span>      	
                </MDBCol>

                <MDBCol md="6" className="mb-2">
                  <label class="form-title charge_pl pull-left mt-3 mb-0">{t('add_charging_schedule_period')}:</label>
                </MDBCol>
                <MDBCol md="6">
                  <button type="button" data-test="button" className="pull-right refresh-btn" onClick={this.addChargingSchedulePeriod}><i class="fas fa-plus"></i> {t('add')}</button>
                </MDBCol>
                  <MDBCol md="12" >
                    <Table striped hover size="sm" className="page-table mb-0" >
                      <thead>
                        <tr>
                          <th>{t('start_period')}</th>
                          <th>{t('Limit')}</th> 
                          <th>{t('number_phases')} </th>
                        </tr>
                      </thead>
                      <tbody>
                       {schedulePeriodRows}
                      </tbody>
                    </Table>
                    <span className="error_msg w3-animate-top cal_errormsg ml-0 mt-0"> <span style={{color: "red"}} >{this.state.errors["emptyaddChargingScheduleError"]}</span></span>      	
                </MDBCol>
                <MDBCol md="12" className="text-right mt-3" >
                  <button className="btn_primary mt-5 mr-3" type="button" data-test="button" onClick={this.saveChargingSchedule}>{t('save')} <i class="fas fa-save ml-2"></i></button>
                  
                  {/* <MDBBtn color="primary" className="mt-5" onClick={this.saveChargingSchedule} >Save</MDBBtn>
                  <MDBBtn color="primary" className="mt-5" onClick={this.backToList} >Back</MDBBtn> */}
                </MDBCol>
              </div>
            </div>
         </form>

         {/* add charging schedule popup  */}
          <MDBModal isOpen={this.state.modal} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggle}>{t('add_charging_schedule_period')}</MDBModalHeader>
            <MDBModalBody>
              <div className="row">
                <div className="col-md-6 md-form">
                  <input pattern="[0-9]*" name="startPeriod" onInput={this.handlePeriodChange.bind(this,"startPeriod")} type="text" id="startperiod" className="form-control text_lowercase" value={this.state.schedulePeriod["startPeriod"]} />
                  <label htmlFor="startperiod" className={`${(this.state.schedulePeriod['startPeriod']!=="" && this.state.schedulePeriod['startPeriod']!==null)? "active" : ""} ml-15`}>{t('start_period')} ( {this.secondsToDhms( this.state.schedulePeriod["startPeriod"] )} )</label>
                  <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["emptyStartPeriodError"]}</span></span>
                </div>
                <div className="col-md-6 md-form">
                  <input pattern="[0-9]*" name="limit" onInput={this.handlePeriodChange.bind(this,"limit")} type="text" id="limit" className="form-control text_lowercase" value={this.state.schedulePeriod["limit"]} />
                  <label htmlFor="limit" className={`${(this.state.schedulePeriod["limit"]!=="" && this.state.schedulePeriod["limit"]!==null)? "active" : ""} ml-15`}>{t('limit')}</label>
                  <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["emptylimitError"]}</span></span>
                </div>
                <div className="col-md-6 md-form">
                  <input name="numberPhases" onInput={this.handlePeriodChange.bind(this,"numberPhases")} type="text" id="numberPhases" className="form-control text_lowercase" value={this.state.schedulePeriod["numberPhases"]} />
                  <label htmlFor="numberPhases" className={`${(this.state.schedulePeriod["numberPhases"]!=="" && this.state.schedulePeriod["numberPhases"]!==null)? "active" : ""} ml-15`}>{t('number_of_phases')}</label>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_cancel" onClick={this.toggle}>{t('cancel')} <i class="fas fa-times ml-2"></i></button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.saveChargingSchedulePeriod}>{t('save')} <i class="fas fa-save ml-2"></i></button>
            </MDBModalFooter>
          </MDBModal>
           {/* end charging schedule popup  */}

           <MDBModal isOpen={this.state.modalSuccess}  backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggleAdd}>{t('success')}</MDBModalHeader>
              <MDBModalBody>
              {t('charging_schedule_added')}
              </MDBModalBody>
            <MDBModalFooter>
            <button className="btn_primary"  data-test="button" type="button" onClick={this.CloseSuccessPopup}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
             <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
            <button className="btn_primary"  data-test="button" type="button" onClick={this.sessiontagOk}>{t('ok')}</button>
            </MDBModalFooter>
        </MDBModal>

      </main>
    </>
  );
}
}

export default withTranslation()(addChargingSchedule);