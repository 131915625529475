import React from 'react';
import { MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink} from 'mdbreact';
import {Form,Table } from 'react-bootstrap'
import * as baseUrl from './constants';

import Pagination from "react-js-pagination";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import  $ from "jquery";

import { createBrowserHistory } from 'history';
import Calendar from 'react-calendar';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import servicesUrl from '../common/servicesUrl';

const history = createBrowserHistory();

class inventoryList extends React.Component {  
    constructor (props) {
            super(props);
            let buildinfo = this.props;
            this.state = {
              calenderFrom:"none",
              calenderTo:"none",
              startDate:'',
              toDate:'',
              startDate1:'',
              startDate2:'',
              buildName:buildinfo.location.state.build, // for publish build
              modelName:buildinfo.location.state.model, // for publish model
              model:false,
              list: [],
              activeItem: "1",
              dataArray:[],
              organizationList:[],
              modal2: false,
              page_title:"Publish",
              org_name:"",
              fields:{
                  productName:"",
                  description:"",
                  buildType:"",
                  productCode:""
              },
              orgId:"",
              sucess:false,
              colour:'sucess',
              message:"",
              errors:{},
              activeArray: [],
              checked:false,
              activeDeactiveArray:'',
              disabledAttr:true,
              logmodel:"",
              pageNo:1,
              pageSize:"10",
              data:new Map(),

              noOfRecords:'',
              modelArray:[],
              publishlogData:[],
              identifier:"",
              selected_org:"",
              activePage: 1,
              modalsession:false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.sendNotification = this.sendNotification.bind(this);
        this.toggle = this.toggle.bind(this)
        this.tagOk = this.tagOk.bind(this)
        this.tabToggle = this.tabToggle.bind(this)
        this.GetFromDate = this.GetFromDate.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.onChangeModelSatus = this.onChangeModelSatus.bind(this);
        this.showEntriesChange=this.showEntriesChange.bind(this);
        this.handleOrgChange=this.handleOrgChange.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.closeCalender = this.closeCalender.bind(this);
        this.changeScheduleFrom = this.changeScheduleFrom.bind(this);
        this.changeScheduleTo = this.changeScheduleTo.bind(this);
        this.keyPress1 = this.keyPress1.bind(this);
        this.keyPress2 = this.keyPress2.bind(this);

        }

        changeScheduleFrom(e){ 
          this.setState({startDate: e.target.value});
          }
             changeScheduleTo(e){
               this.setState({toDate: e.target.value});
              }
         
             keyPress1(e){
               var key = e.key;
             if(e.keyCode === 13){
              if (new Date() >= e.target.value) {
                this.setState({ startDate: e.target.value, startDate1: moment(e.target.value).format("YYYY-MM-DD") }, ()=>{
                  this.getPublishLogData(this.state.pageSize, 1);
                });
              } else {
                this.setState({ startDate: "", startDate1: "" });
                $('.future_err1').text(this.props.t('cannot_select_futuredate'));
              }
               }
               if(key === "Delete" || key === "Backspace"){
                this.setState({ startDate1: "" }, ()=>{
                  this.getPublishLogData(this.state.pageSize,1);
                })
                 }
               
            }
            keyPress2(e){
             var key = e.key;
           if(e.keyCode === 13){
            if (new Date() >= e.target.value) {
              this.setState({ toDate: e.target.value, startDate2: moment(e.target.value).format("YYYY-MM-DD") }, ()=>{
                this.getPublishLogData(this.state.pageSize, 1);
              });
            } else {
              this.setState({ toDate: "", startDate2: "" });
              $('.future_err2').text(this.props.t('cannot_select_futuredate'));
            }
             }
             if(key === "Delete" || key === "Backspace"){
              this.setState({ startDate2: "" }, ()=>{
                this.getPublishLogData(this.state.pageSize,1);
              })
               }
             
          }

        closeCalender(type,event){
          if(type==="from"){
            this.setState({
             calenderFrom:"none"
            })
          }else{
           this.setState({
             calenderTo:"none"
            })
          }
        }


        displayCalender(type,event){
          var calenderFrom = "";
          var calenderTo = "";
          if(type==="from"){
           calenderFrom =  "block";
           calenderTo =  "none";
          }else{
           calenderTo =  "block";
           calenderFrom =  "none";
          }
          this.setState({
           calenderFrom:calenderFrom,
           calenderTo:calenderTo
     })
        }
        // function onclick of tab
      tabToggle(tab, event) {
        var orgname = localStorage.getItem("tenant");
        localStorage.setItem('org', orgname)

        this.setState({
          identifier: orgname,
          org_name: orgname,
          toDate: "",
          startDate: ""
        }, () => {
          this.getPublishLogData(this.state.pageSize, this.state.activePage);
        });

        if (this.state.activeItem !== tab) {
          this.setState({
            activeItem: tab
          });
        }
      }


        //change function for build status filter
        onChangeModelSatus() {
            var Status = document.getElementById("modelId").value;
            //  alert(Status)
            this.setState({ logmodel: Status }, () => {
              this.getPublishLogData(this.state.pageSize,1);
            });
        }
        async togglesession(){
          
          this.setState({
          modalsession: !this.state.modalsession
          });
        }
        
        
        sessiontagOk(){
          // this.props.history.push('/');
          history.push('/');
          window.location.reload();
        }

        
        handlePageChange(pageNumber) {
          
        
          if(pageNumber !==this.state.activePage){
            var data=this.state.data;
            var pageData=data.get(pageNumber);
            if(pageData===undefined){
          this.setState({activePage: pageNumber});
          this.getPublishLogData(this.state.pageSize,pageNumber);
            }else{
              this.setState({activePage: pageNumber});
              this.setState({orgArray:pageData})
            }
          }
        }

         // popup calling function
        toggle = () => {
            this.setState({
            modal: !this.state.modal
            });
        }
        
         // After clicking on ok button of popup
        tagOk(){
          window.location.reload();
        }

        GetFromDate = date => {
          var fdate =this.formatCalDate1(date);

          this.setState({startDate:fdate, startDate1: this.formatCalDate(date), calenderFrom: "none"}, ()=>{
            this.getPublishLogData(this.state.pageSize,1);
          });
        };

        dateChange = date => {
          var fdate =this.formatCalDate1(date);

          this.setState({toDate:fdate, startDate2: this.formatCalDate(date), calenderTo: "none" }, ()=>{
            this.getPublishLogData(this.state.pageSize,1);
          });
        }

        formatCalDate1(date){
          var format = date.toISOString();
          var xyz = moment(format).format("DD MMM YYYY")
          return xyz;
        }

        formatCalDate(date){
          var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;
          return [year, month, day].join('-');
      }        

    componentDidMount(){
        // get org name 
        var orgname  = localStorage.getItem("tenant");
        
        if(this.state.buildName!==""){
          localStorage.setItem("orgBuild",this.state.buildName);
          localStorage.setItem("orgModel",this.state.modelName);
        }else{
          this.setState({
            buildName:localStorage.getItem("orgBuild"),
            modelName:localStorage.getItem("orgModel")
          })
        }
       

        this.setState({
            org_name:orgname
        })
        // get all org
        var url = baseUrl.URLPath + "admin/v1.0/account/all";
        fetch(url,{
          method: "GET",
          headers: {
                "content-type":"application/json",
                "Authorization":localStorage.getItem("Authorization")
              }
          })
          .then((resp) => {
            
            if(resp.status===401){
              // alert("Access token is expired, please login again")
              // this.togglesession();
        
              // localStorage.clear();
              window.location.reload();
            }
            if(resp.status===200){
              return resp.json();
            }
          }) 
          .then((response) =>{
            
            if(response!==undefined){
          this.setState({ organizationList:response.organisationDTOS })
          var org_name_array = response.organisationDTOS;
          for(var i=0; i < org_name_array.length;i++){
           
                if(this.state.org_name.toLowerCase === org_name_array[i].company.toLowerCase){
                 
                    this.setState({ identifier:org_name_array[i].identifier })
                }
            }
            this.getPublishLogData(this.state.pageSize,this.state.activePage);
          }
          })
          .catch((error) => {
              console.log(error, "catch the loop")
          })

         // api to get the list of orgnization based on model name 
        var url1 = baseUrl.URLPath + servicesUrl.getCpInventoryOrgByModel + "?model="+this.state.modelName;
        fetch(url1,{
          method: "GET",
          headers: {
                "content-type":"application/json",
                "Authorization":localStorage.getItem("Authorization")
              }
          })
          .then((resp) => {
            
            if(resp.status===401){
              window.location.reload();
            }
            if(resp.status===200){
              return resp.json();
            }          }) 
          .then((response) =>{
            
            if(response!==undefined){
          this.setState({ dataArray:response })
            }
          })
          .catch((error) => {
              console.log(error, "catch the loop")
          })
           this.getModel(); 
    }

    handleOrgChange(event){
      this.setState({
        identifier: event.target.value,
        org_name: event.target.value
      }, ()=>{
        localStorage.setItem('org', this.state.identifier);
        this.getPublishLogData(this.state.pageSize,1);
      })
  }

    getPublishLogData(pageSize,pageNo){
      var org = localStorage.getItem('org');
      var url = baseUrl.URLPath + servicesUrl.getPublishnotificationslogsOrg + "?organization="+org+"&model="+this.state.logmodel+"&start_date="+this.state.startDate1+"&end_date="+this.state.startDate2+"&pageNo="+pageNo+"&pageSize="+pageSize;
   
      fetch(url,{
        method: "GET",
        headers: {
              "content-type":"application/json",
              "Authorization":localStorage.getItem("Authorization")
            }
        })
        .then((resp) => {
          
          if(resp.status===401){
            window.location.reload();
          }
          if(resp.status===200){
            return resp.json();
          }
        }) 
        .then((response) =>{
          if(response!==undefined){
          this.setState({
              publishlogData:response.logs,
              noOfRecords:response.count.count
              
          })
        }
        })
        .catch((error) => {
            console.log(error, "catch the loop")
        })
    }

    showEntriesChange(){
      var entries=document.getElementById("showEntries").value;

      this.setState({ activePage: 1, pageSize: entries }, ()=>{
        this.getPublishLogData(entries,1);
      });
    }

    getModel() {
      var url = baseUrl.URLPath + "cpinventory/evmodel/";
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {
          
          if (resp.status === 401) {
            // this.togglesession();
            // localStorage.clear();
            window.location.reload();
          }
          if (resp.status === 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response !== undefined) {
            this.setState({ modelArray: response });
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
  
    }

 
  
    handleChange(id,cpid,event){
        
        var checkedValue = document.getElementById(id).checked;
        if(checkedValue===true){
          this.state.list.push(cpid);
          this.setState({
            // deleteList: [ ...this.state.deleteList, cpid], 
            activeArray:this.state.list,
            disabledAttr:""
            });
          // [].concat(arr)
        }else{
          const index = this.state.list.indexOf(cpid);
            if (index > -1) {
              this.state.list.splice(this.state.list.index, 1);
              this.setState({
                // deleteList: [ ...this.state.deleteList, cpid], 
                activeArray:this.state.list
                });
            }else{
              this.setState({
                // deleteList: [ ...this.state.deleteList, cpid], 
                activeArray:"",
                disabledAttr:true
                });
            }
        }
       
      }
  
      sendNotification(){
        
      let url = baseUrl.URLPath + "publishnotifications/tenants";
     //localhost:8080/cpms/configurations
      let data = { "build":this.state.buildName, "model":this.state.modelName, "tenants": this.state.activeArray }
      fetch(url, {
        method: 'POST', // or 'POST'
        body: JSON.stringify(data), // data can be `string` or {object}!
         headers:{
           "content-type":"application/json",
           "Authorization":localStorage.getItem("Authorization"),
         }
        }).then(resp =>{
          if(resp.status===401){
            // this.togglesession();
            // // alert("Access token is expired, please login again")
            // localStorage.clear();
            window.location.reload();
          }
        else if(resp.status===500){
            console.log("internal server error")
          } else if(resp.status===400){
           console.log("Bad request")
         }else{
            if(resp.status===200){
               this.toggle()
            }
          }

        }).then((response) =>{

        })
        .catch(error => alert('Error:' + error));
        
      }
//function for sorting table data on click
ascendingOrder(){
  this.setState({publishlogData : this.state.publishlogData.reverse()})
}
    render() {

   



     const{t}=this.props
    //model list 
    const modelArray = this.state.modelArray.map((modelArray, index) => {
      return (
        <option value={modelArray}>{modelArray}</option>
      )
    })

    //organization list 
    const organizationList = this.state.organizationList.map((organizationList, index) => {
        var setOrg = false;
        if(this.state.org_name.toLowerCase() === organizationList.company.toLowerCase()){
            setOrg = true;
        }
        return(
          <option value={organizationList.identifier} selected={setOrg} >{organizationList.company}</option>
        )
    })
 
     //publish log data 
     console.log(this.state.publishlogData);
    //  let publishLogRow = "";
     let publishLogRow = this.state.publishlogData.map((publishlogData, index) => {    
        var time = publishlogData.sentDate.split(" ");
        return(             
          <tr>
            <td className="wrap-word">{ time[0] !== null ?  moment(time[0]).format("DD MMM YYYY") && <div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {moment(time[0]).format("DD MMM YYYY")}</div> :"" }</td>
            <td className="wrap-word">{ time[1] !== null ? time[1]:"" } </td>
            <td>{publishlogData.tenantId}</td>
            <td>{publishlogData.modelName}</td>
            <td>{publishlogData.buildURL}</td>
        </tr>
        )
    });
    
    

      let data = "";
      if(this.state.modelName!==""){
       data = this.state.dataArray.map((dataArray, index) => {
        return(
                <tr key={index}>
                  
                    <td className="text-center"> 
                    <Form.Check 
                        custom
                        type="checkbox"
                        id={`custom-${dataArray.tenantId}`}
                        label=""
                        //checked = {false}
                        onChange={this.handleChange.bind(this,`custom-${dataArray.tenantId}`,dataArray.tenantId)}
                        key={dataArray.tenantId}
                        />
                    </td>
                    <td>{dataArray.tenantId}</td>
                </tr>
             )
        })
      }

      return (
        <>
         <main class="content-div" >
          <p>{t('publish')}
              <div className="breadcrumb_div">
              {t('fota')} &gt; <Link to="/builds" > <span>{t('builds')}</span></Link > {`>`} <span className="breadcrumb_page">{t('publish')}</span>
              </div>
           </p>
            <div className="page-outerdiv">
            {/* <div className="breadcrumb_div"> FOTA > <Link to="/builds" > <span>Builds</span></Link > > Publish</div> */}
              {/* end page title */}

              <MDBNav  className="mt-3 border-0">
          <MDBNavItem className="nav-first">
            <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "1" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this,"1")} role="tab" >
            {t('publish')}
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className="nav-last">
            <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "2" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this,"2")} role="tab" >
             {t('publish_logs')}
            </MDBNavLink>
          </MDBNavItem>
         
        </MDBNav>
        <MDBTabContent activeItem={this.state.activeItem} className="pt-2 border-0" >
        <MDBTabPane tabId="1" role="tabpanel">

              {/* page menu bar */}
              <div className="row menubar mt-4">
                    <div class="col-md-3">
                         <label className="title_font">{t('selected_build')} {this.state.buildName}</label>
                    </div>
                    <div class="col-md-3">
                        <label className="title_font">{t('model_name')}: {this.state.modelName}</label>
                    </div>
                </div>
            
              {/* end page menu bar  */}
              {/* table start */}
             <div className="row mt-2 ">
               <div className="col-md-6">
                <Table striped hover size="sm" className="page-table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>{t('Organization')}</th>
                    </tr>
                    </thead>
                    <tbody>
                        {data}
                    </tbody>
               </Table>
              </div>
              <div className="col-md-12 text-right mt-3">
                <button type="button" data-test="button" onClick={this.sendNotification} disabled={this.state.disabledAttr} className="pull-right refresh-btn" >
                     {t('publish')}
                </button>
              </div>
              {/* End table  */}
              </div>
            
           
          </MDBTabPane>
            <MDBTabPane tabId="2" role="tabpanel">
           
            <div className="row mt-2">
           
          {/* <MDBCol md="3" className="mt-2 pr-0">
               <div > 
                  <span className="cal_label"> From  </span>
                  <div className="pull-right mt-2 newdata" style={{width:"82%"}} >
                        <DatePicker
                        selected={this.state.startDate}
                        onChange={date => this.GetFromDate(date)}
                        timeFormat="HH:mm"
                        timeIntervals={1}
                        className="cal_margin tag_cal"
                        timeCaption="time"
                        dateFormat="dd-MMM-yyyy"
                        />
                    </div>
                </div>
                </MDBCol> */}


<div className="col-md-2 p-rel t-8">
                  <div class="input-group">
                      <input type="text" class="form-control input-searchBox pl-3" placeholder={t('from_time')} name="srch-term" id="srch-term" value={this.state.startDate} onChange={this.changeScheduleFrom}  onKeyDown={this.keyPress1}  /> 
                      <div class="input-group-btn">
                          <button class="input-searchBtn" type="submit" onClick = {this.displayCalender.bind(this,'from')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                      </div>
                    </div>
                    <div style={{display:this.state.calenderFrom}} className="calenderContainer">
                    <div onClick={this.closeCalender.bind(this, 'from')}><i class="far fa-times-circle"></i></div>
                      <Calendar
                         selected={this.state.startDate}
                         onChange={date => this.GetFromDate(date)}
                         timeFormat="HH:mm"
                         timeIntervals={1}
                         //className="cal_margin tag_cal"
                         timeCaption="time"
                         dateFormat="dd-MMM-yyyy"
                        onBlur={this.closeCalender.bind(this,'from')}
                        // maxDate={new Date()}
                        maxDate={(this.state.toDate!==""&&this.state.toDate!==null)? new Date(this.state.toDate):new Date()}
                      />
                    </div>
                    <span className="pull-left future_err1 w3-animate-top mt-1" style={{color: "red"}}></span>
                </div>

                {/* <MDBCol md="3" className="mt-2 pl-0"  >
                <div>
                <span className="cal_label">To</span>
                <div className="pull-left mt-2 newdata" style={{width:"82%"}} >
                        <DatePicker
                        selected={this.state.toDate}
                        onChange={date => this.dateChange(date)}
                        
                        timeFormat="HH:mm"
                        timeIntervals={1}
                        className="cal_margin tag_cal"
                        timeCaption="time"
                        dateFormat="dd-MMM-yyyy"
                        />
                    </div>
                    </div>
                </MDBCol> */}

<div className="col-md-2 p-rel t-8">
                <div class="input-group">
                      <input type="text" class="form-control input-searchBox pl-3" placeholder={t('to_time')} name="srch-term" id="srch-term" value={this.state.toDate} onChange={this.changeScheduleTo}  onKeyDown={this.keyPress2}  /> 
                      <div class="input-group-btn">
                      <button class="input-searchBtn" type="submit" onClick = {this.displayCalender.bind(this,'to')}> <i className="fas fa-calendar-alt fa-sm"></i></button>                      </div>
                    </div>
                    <div style={{display:this.state.calenderTo}} className="calenderContainer">
                    <div onClick={this.closeCalender.bind(this, 'to')}><i class="far fa-times-circle"></i></div>
                      <Calendar
                         selected={this.state.toDate}
                         onChange={date => this.dateChange(date)}
                         
                         timeFormat="HH:mm"
                         timeIntervals={1}
                         //className="cal_margin tag_cal"
                         timeCaption="time"
                         dateFormat="dd-MMM-yyyy"
                         minDate={new Date(this.state.startDate)}
                          maxDate={new Date()}
                      />
                    </div>
                    <span className="pull-left future_err2 w3-animate-top mt-1" style={{color: "red"}}></span>
                </div>
                <div className="col-md-3">
                    <select className="browser-default custom-select select_height" id="organization" onChange={this.handleOrgChange.bind(this)} value={this.state.org_name}>
                        <option value="">{t('select_organization')}  </option>
                        {organizationList}
                    </select>
                </div>
         
                    <div className="col-md-3">
                    <select className="browser-default custom-select select_height" id="modelId" onChange={this.onChangeModelSatus.bind(this)}>
                        <option value="" >{t('select_model')}</option>
                        {modelArray}
                    </select>
                </div>
          </div>
         
          

          <div className="row mt-4">
          <div className="col-md-12">
          <div className="pull-right">
                     <label className="sub-text pull-left" >{t('show_entries')}</label>
                      <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                        <Form.Control as="select" className="showentry_sel custom_selectBox"  id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}> 
                          <option>10</option>
                          <option>20</option>
                          <option>30</option>
                          <option>40</option>
                          <option>50</option>
                        </Form.Control>
                    </Form.Group>
                   </div>
          </div>
          </div>
          {/* table start */}
          <div className="row ">
           <div className="col-md-12">
            <Table striped hover size="sm" className="page-table">
              <thead>
                <tr>
                  <th>{t('date')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                  <th>{t('time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                  <th>{t('Organization')}</th> 
                  <th>{t('model')}</th>
                  <th>{t('url')}</th>
              </tr>
              </thead>
              <tbody>
              {publishLogRow}
              </tbody>
            </Table>
          </div>
          <div className="col-md-12 mt-2 mb-4 text-right">
           <Pagination
              hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageSize}
              totalItemsCount={this.state.noOfRecords}
              onChange={this.handlePageChange.bind(this)}
            />
          </div> 
          
          {/* End table  */}
          </div>

            </MDBTabPane>
          </MDBTabContent>
          </div>

          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('publish_successfully')}
            </MDBModalBody>
          <MDBModalFooter>
        {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
        <button className="btn_primary"  data-test="button" type="button" onClick={this.tagOk}>{t('ok')}</button>
            </MDBModalFooter>
        </MDBModal>

          {/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
      <MDBModalBody>
       {t('please_login_again')}
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <button className="btn_primary"  data-test="button" type="button" onClick={this.sessiontagOk}>{t('ok')}</button>
      </MDBModalFooter>
      </MDBModal>

          </main>
     </>
      );
    }
}

export default withTranslation()(inventoryList);