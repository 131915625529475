import React from 'react';
import ReactDOM from 'react-dom';

import * as baseUrl from '../views/constants';
import CustomModal from './CustomModal';

export class APICallUtility extends React.Component {
    static modalsession = true;
    static refreshRequest = false;

    static async sessiontagOk() {
        window.location.replace('/')
        window.location.reload();
    }

    static async closeModal(){
        let node = document.getElementById('app_modal');
        ReactDOM.unmountComponentAtNode(node);
    }

    //method to check object is empty or not @ Lakshmikanth
    static async isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    //common method for commn messgaes oprations @ Lakshmikanth
    static async cpmsProcessMessages(type, payload, cplist,t, loader, image) {
        var data = {
            "type": type,
            "payload": payload,
            "cplist": cplist
        };

        var timeZone = localStorage.getItem("timezone");
        let url = baseUrl.URLPath + "cs/operations/" + type;
        let responseFromAPI = "";

        let headers = {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization"),
            "timeZone": timeZone
        };

        await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headers
        }).then(resp => {
            if (resp.status === 401) {
                setTimeout(() => {
                    this.refreshToken(url, 'POST', data, headers, t);
                    if(loader === true){
                        document.getElementById("loader_image_div").style.display = "none";
                    }
                }, 1);            
            }else if (resp.status === 403) {
                    this.renderAccessDenied(t);
            } else if (resp.status === 500) {
                    this.renderInternalServerError(t);
            } else if (resp.status === 200) {
                return resp.json();
            }else if (resp.status === 404) {
                this.renderChargerOffline(t);
            }else {
                responseFromAPI = "Bad request";
            }
        }).then(response => {
            if (response[0] === "3") {
                responseFromAPI = "Request is processed Successfully";
            }
            else {
                responseFromAPI = response[3];
            }
        }
        ).catch(error => {
            responseFromAPI = error;
        });

        return responseFromAPI;
    }

    static async aesDecrypt(encryptedHex) {
        var aesjs = require('aes-js');
        // An example 128-bit key
        var key = [49, 50, 51, 52, 53, 54, 55, 56, 57, 49, 49, 50, 51, 52, 53, 54];

        // The initialization vector (must be 16 bytes)
        var iv = [48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48];


        var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);

        // The cipher-block chaining mode of operation maintains internal
        // state, so to decrypt a new instance must be instantiated.
        var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
        var decryptedBytes = aesCbc.decrypt(encryptedBytes);

        // Convert our bytes back into text
        var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);

        return decryptedText;
    }

    //common method for api calls @ Lakshmikanth
    static async cpmsAPIFetch(url, methodType, payload, headers, t, loader, image) {
        let responseFromAPI = '';
        let isPayloadAvailable = await this.isEmpty(payload);

        let reqObj = {
            method: methodType,
            headers: headers
        }

        if(payload && !isPayloadAvailable){
            reqObj['body'] = JSON.stringify(payload)
        }
        var isAccessDenied = false
        await fetch(url, reqObj)
        .then(resp => {
            if (resp.status === 401) {
                
                setTimeout(() => {
                    this.refreshToken(url, methodType, payload, headers, t, loader, image);
                    if(loader === true){
                        document.getElementById("loader_image_div").style.display = "none";
                    }
                }, 1);
                
            } else if (resp.status === 403) {
                  isAccessDenied = true;
                  return resp.json();
               
            } else if (resp.status === 500) {
                setTimeout(() => {
                    this.renderInternalServerError(t);
                    if(loader === true){
                        document.getElementById("loader_image_div").style.display = "none";
                    }
                }, 1);
            } else {
                if(image === true){
                    return resp.blob();
                } else {
                    return resp.json(); 
                }
            }
        }).then(response => {
            if(response.status === 403 && response.message !== "" ){
                responseFromAPI = response; 
            }else if(isAccessDenied){
                setTimeout(() => {
                    this.renderAccessDenied(t);
                    if(loader === true){
                        document.getElementById("loader_image_div").style.display = "none";
                    }
                }, 1);
            }else{
                  responseFromAPI = response;  
            }
        }).catch(error => {
            setTimeout(() => {
                console.warn('API Failed', error);
                if(loader === true){
                    document.getElementById("loader_image_div").style.display = "none";
                }
            }, 1);
        });

        return responseFromAPI;
    }

    static async renderUnauthorized(url, methodType, payload, headers, t, loader, image) {
        localStorage.setItem("switchOrgName","");
        localStorage.removeItem('SelectedSite');
        window.location.replace('/dashboard');
    }

    static async renderAccessDenied(t){
        ReactDOM.render(
            <CustomModal
                open={this.modalsession}
                title={t('access_denied')}
                bodyMessage={t('user_access_denied')}
                PrimaryButtonText={t('ok')}
                PrimaryButtonOperation={this.closeModal}
            />, document.getElementById('app_modal')
        )
    }

    static async renderChargerOffline(t){
        ReactDOM.render(
            <CustomModal
                open={this.modalsession}
                title={t('alert')}
                bodyMessage={t('cp_not_online')}
                PrimaryButtonText={t('ok')}
                PrimaryButtonOperation={this.closeModal}
            />, document.getElementById('app_modal')
        )
    }

    static async renderInternalServerError(t) {
        ReactDOM.render(
            <CustomModal
                open={this.modalsession}
                title={t('alert')}
                bodyMessage={t('server_error_please_contact_admin')}
                PrimaryButtonText={t('ok')}
                PrimaryButtonOperation={this.closeModal}
            />
            , document.getElementById('app_modal')
        )
    }

    static async refreshToken(url, methodType, payload, headers, t, loader, image) {
        let urls = baseUrl.LoginPath + "/login/v1.0/refresh/token";

        let refreshTokenReference = localStorage.getItem('refreshTokenReference');

        if (refreshTokenReference === "null" || refreshTokenReference === null || refreshTokenReference === "undefined" || refreshTokenReference === undefined || refreshTokenReference === "") {
            localStorage.clear();
            window.location.replace('/');
            window.location.reload();
        } else {
            let reftoken = await this.aesDecrypt(refreshTokenReference.trim());
            let body = { "refreshTokenReference": reftoken.trim() };
            if(this.refreshRequest === true){
                
                setTimeout(() => {
                    this.renderUnauthorized(url, methodType, payload, headers, t, loader, image);
                }, 5000);
            } else if(this.refreshRequest === false){
                this.refreshRequest = true;
                await fetch(urls, {
                    method: 'PUT',
                    headers: {
                        "Accept": "*/*",
                        "Authorization": "w",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(body)
                })
                    .then(resp => {
                        if (resp.status === 200) {
                            return resp.json()
                        } else if (resp.status === 404) {
                            
                            this.refreshRequest = false;
                            let updateheader = headers;
                            updateheader['Authorization'] = localStorage.getItem('Authorization');
                            this.renderUnauthorized(url, methodType, payload, updateheader, t, loader, image);
                        }
                    })
                    .then((response) => {
                        if (response) {
                            
                            this.refreshRequest = false;
                            localStorage.setItem('refreshTokenReference', response?.refreshTokenReference?.trim());
                            localStorage.setItem('Authorization', 'Bearer ' + response?.token?.trim());
                            let headders = headers;
                            headders['Authorization'] = localStorage.getItem('Authorization')
                            this.renderUnauthorized(url, methodType, payload, headders, t, loader, image);
                        }
                    })
                    .catch(err => console.log(err));
            }
        }
    }
}

export default APICallUtility;