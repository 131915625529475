import React from 'react';
import * as baseUrl from '../views/constants';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';
import QRCodeAuthorizationTagsTemplate from '../Screens/QRCodeAuthorizationTagsTemplate';
import servicesUrl from '../common/servicesUrl';

class QRCodeAuthorizationTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalsession: false,
            multiple: false,
            options: [],
            errors: {},
            pageSize: 10,
            activePage: 1,
            noOfRecords: null,
            statusChange: '',
            chargePoint: '',
            isOnSearchFind: false,
            isSearchNotFound: false,
            availableAuthTag:[],
            searchSuggestions:[],
            searchStr:"",
            hideShowEntries: false,
            loading: false
        }
         this.getElem =null;
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.getQRCodeAuthTagId = this.getQRCodeAuthTagId.bind(this)
        this.onChangeTypeahead = this.onChangeTypeahead.bind(this);
        this.clearSearchSuggestions = this.clearSearchSuggestions.bind(this);
        this.onBlurEvent = this.onBlurEvent.bind(this);
        this.searchSite = this.searchSite.bind(this)
   
    }

    

    componentDidMount(){
        this.getQRCodeAuthTagId(1,this.state.pageSize, this.state.searchStr);
    }

    onChangeTypeahead(site) {
        $('.empty_site').text("")
        this.getQRCodeAuthTagId(1,this.state.pageSize, site);
    }

    onBlurEvent(event) {
        this.setState({ ...this.state, searchStr: event.target.value });
    }

    async getQRCodeAuthTagId(Page,pageSize,idTag){
        this.setState({ loading: true })
        let isSearchNotFound = this.state.isSearchNotFound;
        let Tag = idTag;

        if(isSearchNotFound || idTag?.trim() === "" || idTag === undefined || idTag == null) {
            Tag = "";
        }
            let url = `${baseUrl.URLPath}${servicesUrl.getQRcodeAuthTag}?pageSize=${pageSize}&pageNo=${Page}&authTag=${Tag}`;
            let type = ApiMethodTypes.GET;
            let headers = getSecurityHeaders();
            let payload = {}
            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
            if (this.state.isOnSearchFind) {
                if (response.count > 0) {
                    if(Tag !== "" && Tag !== undefined && Tag !== null){
                        this.setState({ ...this.state, hideShowEntries: true, availableAuthTag: response.data, noOfRecords: response.count, isSearchNotFound: false, isOnSearchFind: false, loading: false });
                    } else {
                        this.setState({ ...this.state, availableAuthTag: response.data, noOfRecords: response.count, isSearchNotFound: false, isOnSearchFind: false, loading: false });
                    }
                } else {
                    this.setState({ ...this.state, hideShowEntries: false, isSearchNotFound: true, loading: false });
                }
            } else {
                this.setState({ ...this.state, hideShowEntries: false, availableAuthTag: response.data, noOfRecords:response.count, isSearchNotFound:false, isOnSearchFind:false, loading: false });
            }
    }

    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;
        this.setState({
            activePage: 1,
            pageSize: entries
        }, () => {
            this.getQRCodeAuthTagId(1, this.state.pageSize);
        })
    }

    handlePageChange(pageNumber) {
        if (pageNumber !== this.state.activePage) {
            this.setState({ activePage: pageNumber }, () => {
                this.getQRCodeAuthTagId(pageNumber, this.state.pageSize, this.state.searchStr);
            });
        }
    }

    handleKeyDown(event) {
        this.getSearchSuggestions(event.target.value);
        if (event.key === 'Enter') {
            this.setState({ ...this.state, searchStr: event.target.value, isOnSearchFind: true });
            $("#rbt_id").hide();
            this.getQRCodeAuthTagId(1, this.state.pageSize, event.target.value);
        } else {
            this.setState({ ...this.state, isSearchNotFound: false });
        }
    }

    async getSearchSuggestions(searchStr) {
        let url = `${baseUrl.URLPath}${servicesUrl.getQRauthTagsPartial}?authTag=${searchStr}`;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response.status===400 || response.status===404) {
        }else{
            this.setState({ ...this.state, searchSuggestions: response })
        }
    }

    async getSearchInventoryList(Idtag) {
        var url = baseUrl.URLPath + servicesUrl.getOcppTagsPartialSearch + "?idTag=" + Idtag;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let response = await APICallUtility.cpmsAPIFetch(url, type, {}, headers, this.props.t, true);
        if (response !== undefined) {
            this.setState({
                options: response,
                intial_data: response
            });
        }
    }

    clearSearchSuggestions() {
        this.getElem.clear();
        $('.empty_site').text("");
        this.setState({ ...this.state, activePage: 1, searchStr: '', hideShowEntries: false,isSearchNotFound: false });
        this.getQRCodeAuthTagId(1, this.state.pageSize, '');
    }

    searchSite() {
        this.setState({ ...this.state, isOnSearchFind: true });
        this.getQRCodeAuthTagId(1, this.state.pageSize, this.state.searchStr);
    }

    render() {
        // const { t } = this.props
       

        return (
            <QRCodeAuthorizationTagsTemplate
                {...this.state}
                hideShowEntries = {this.state.hideShowEntries}
                handleKeyDown={this.handleKeyDown}
                FindCpName={this.FindCpName}
                showEntriesChange={this.showEntriesChange}
                handlePageChange={this.handlePageChange}
                onChangeTypeahead = {this.onChangeTypeahead}
                searchSuggestions = {this.state.searchSuggestions}
                clearSearchSuggestions = {this.clearSearchSuggestions}
                searchSite = {this.searchSite}
                getParentElem={(el) => (this.getElem = el) }
                onBlurEvent = {this.onBlurEvent}

            />
        );
    }
}

export default withTranslation()(QRCodeAuthorizationTags);
