//react imports
import React from 'react';
import ReactDOM from 'react-dom';

//i18n multilingual imports
import { withTranslation } from 'react-i18next';

//third party imports
import moment from 'moment';
import $ from "jquery";

//API imports 
import * as baseUrl from '../views/constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import {getSecurityHeaders} from '../common/HttpRequestClass'

//component imports
import ReservationsAndSecondSeatingTemplate from '../Screens/ReservationsAndSecondSeatingTemplate';

class ReservationsAndSecondSeating extends React.PureComponent {
    typeAHeadRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            reservationResponse: [],
            errorObject: {},
            dateTime: '',
            pageSize: 10,
            activePage: 1,
            modalsession: false,
            availableSites: [],
            noOfRecords: 0,
            reservationStatus: false,
            isactivate: false,
            activemessage: '',
            resources: [],
            events: [],
            slotsBasedOnCpId: [],
            modalAdd: false,
            deleteReserve: false,
            confirmAdd: false,
            modal: false,
            confirmDelete: false,
            successReserve: false,
            email: '',
            errors: {},
            searchSiteId: '',
            selectCpId: '',
            isActiveRulesOpen: false,
            guarantedSlotDuration: '',
            cooldown: '',
            minimumChargingDuration: '',
            reporting: false,
            searchSuggestions: [],
            searchStr: '',
            isSearchNotFound: false,
            fromdateselect: '',
            todateselect: '',
            successmessage: '',
            deletemessage: '',
            reservationId: '',
            reservestart: '',
            reserveend: '',
            reservecpid: '',
            reservationInfo: {},
            successTitle: '',
            loading: false,
        }
        this.selectedSlotChange = this.selectedSlotChange.bind(this);
        this.getSearchSuggestions = this.getSearchSuggestions.bind(this);
        this.onChangeReservationStatus = this.onChangeReservationStatus.bind(this);
        this.toggleActiverules = this.toggleActiverules.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.getAvailableSites = this.getAvailableSites.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.getDoughnutData = this.getDoughnutData.bind(this);
        this.changeActiveDeactivate = this.changeActiveDeactivate.bind(this);
        this.applyActiveDeactivate = this.applyActiveDeactivate.bind(this);
        this.toggleActivate = this.toggleActivate.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);

        this.getReservationOverview = this.getReservationOverview.bind(this);
        this.cancelReservation = this.cancelReservation.bind(this);
        this.addReservation = this.addReservation.bind(this);
        this.toggleAdd = this.toggleAdd.bind(this);
        this.toggleConfirm = this.toggleConfirm.bind(this);
        this.toggleDelete = this.toggleDelete.bind(this);
        this.toggleOverview = this.toggleOverview.bind(this);
        this.closeDelete = this.closeDelete.bind(this);
        this.closeSuccess = this.closeSuccess.bind(this);
        this.toggleDeleteReserve = this.toggleDeleteReserve.bind(this);

        this.onChangecpId = this.onChangecpId.bind(this);
        this.closeActiverules = this.closeActiverules.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onChangeTypeahead = this.onChangeTypeahead.bind(this);
        this.clearSearchSuggestions = this.clearSearchSuggestions.bind(this);
        this.searchSite = this.searchSite.bind(this);
        this.reserReservationId = this.reserReservationId.bind(this);
        this.setReservation = this.setReservation.bind(this);
        this.resetSlotsBasedOnCpId = this.resetSlotsBasedOnCpId.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
        this.clearPrevData = this.clearPrevData.bind(this);
    }

    resetSlotsBasedOnCpId(){
        // this.state.slotsBasedOnCpId = []
        this.setState({
            slotsBasedOnCpId: []
        })
    }

    setReservation(start, end, cpid) {
        // this.state.reservecpid = cpid;
        // this.state.reservestart = start;
        // this.state.reserveend = end;
        this.setState({
            reservestart: start,
            reserveend: end,
            reservecpid: cpid
        })
    }

    clearSearchSuggestions() {
        this.typeAHeadRef.current.clear();
        $('.empty_site').text("")
        this.setState({ ...this.state, activePage: 1, searchStr: '' }, () => {
            this.getAvailableSites(1, this.state.pageSize, '');
        });
    }

    onChangeTypeahead(site) {
        $('.empty_site').text("")
        this.getAvailableSites(1, this.state.pageSize, site);
    }

    onChangeReservationStatus() {
        this.setState({
            ...this.state,
            reservationStatus: !this.state.reservationStatus
        });
    }

    closeActiverules() {
        this.setState({
            ...this.state,
            isActiveRulesOpen: !this.state.isActiveRulesOpen
        });
    }

    toggleActiverules(activeRules) {
        this.getGroupsDetails(activeRules?.id);
        this.setState({
            ...this.state,
            isActiveRulesOpen: !this.state.isActiveRulesOpen,
        });
    }

    async getGroupsDetails(siteId){
            let url =`${baseUrl.URLPath}${servicesUrl.getSitemanagement}?siteid=${siteId}`;
            let type = ApiMethodTypes.GET;
            let headers = getSecurityHeaders(); 
            let payload={}
            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
            let state = this.state;           
            this.setState({ 
                ...this.state,
                guarantedSlotDuration: response?.guarantedslotDuration,
                minimumChargingDuration: response?.minimumCharginduration,
                cooldown: response?.cooldown,
                reporting: response?.reporting
            });
    }

    componentDidMount() {
        this.getAvailableSites(this.state.activePage, this.state.pageSize, '');
    }

    showEntriesChange(event) {
        const pageSize = event.target.value;
        if (pageSize !== this.state.pageSize) {
            let targetId = localStorage.getItem('setOpenedId');
            if (targetId) {
                ReactDOM.unmountComponentAtNode(document.getElementById(targetId))
            }
            this.setState({ pageSize, activePage: 1 }, () => {
                this.getAvailableSites(this.state.activePage, this.state.pageSize, '');
            });
        }
    }

    async getAvailableSites(page, pageSize, site){
        this.setState({ loading: true })
        let url = baseUrl.URLPath + servicesUrl.getGroupList + '?pageSize=' + pageSize + '&pageNo=' + page + '&site=' + site; 
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if(response){
            this.setState({ loading: false })
            if (response?.count?.count > 0) {
                $('.empty_site').text('')
                this.setState({ ...this.state, availableSites: response.sites, noOfRecords: response.count.count });
            } else {
                $('.empty_site').text(this.props.t('group_not_exists'))
            }
        }
    }

async getSearchSuggestions(searchStr){
    let url = baseUrl.URLPath + servicesUrl.getGroupsByPartial + '?site=' + searchStr;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if(response !=undefined){
        this.setState({ ...this.state, searchSuggestions: response })
    }
}

   
    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }

    getDoughnutData(currentSite) {
        const isEmpty = !currentSite.onlineCPCount && !currentSite.oflineCPCount;
        let total = 0;
        let perOnline = 0;
        let perOffline = 0;
        if (!isEmpty) {
            total = currentSite.oflineCPCount + currentSite.onlineCPCount;
            perOnline = (currentSite.onlineCPCount / total) * 100;
            perOffline = (currentSite.oflineCPCount / total) * 100;
            perOffline = perOffline.toFixed();
            perOnline = perOnline.toFixed();
        }

        const data = {
            datasets: [
                {
                    data: isEmpty ? [100] : [perOffline, perOnline],
                    backgroundColor: isEmpty ? ['#d6d6d6'] : ['#00529e', 'rgba(0, 255, 0, 1)'],
                    borderWidth: 1,
                },
            ],
            options: {
                plugins: {
                    legend: {
                        display: true
                    }
                }
            }
        };
        return data;
    }

    changeActiveDeactivate(id, status) {
        let available = this.state.availableSites;
        let selectedIndex = available.findIndex(el => el.id == id);

        if (selectedIndex > -1) {
            this.applyActiveDeactivate(id, status);
        }
    }

    async applyActiveDeactivate(id, status) {
        let url = baseUrl.URLPath + servicesUrl.updateReservationStatus + "?siteId=" + id + "&status=" + !status;
        let type = ApiMethodTypes.PUT;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response != undefined) {
            if (response.status === 403) {
                this.setState({
                    isactivate: true,
                    activemessage: response.message
                })
            } else if (response.status === 404) {
                this.setState({
                    isactivate: true,
                    activemessage: response.message
                })
            } else {
                let available = this.state.availableSites;
                let selectedIndex = available.findIndex(el => el.id == id);
                available[selectedIndex].reservationStatus = !available[selectedIndex].reservationStatus;
                this.setState({
                    availableSites: available,
                    isactivate: true,
                    activemessage: response.message
                })
            }
        }
    }

    toggleActivate() {
        this.setState({
            isactivate: !this.state.isactivate
        })
    }

    handlePageChange(pageNumber) {
        if (pageNumber != this.state.activePage) {
            let targetId = localStorage.getItem('setOpenedId');
            if (targetId) {
                ReactDOM.unmountComponentAtNode(document.getElementById(targetId))
            }

            this.setState({ ...this.state, activePage: pageNumber }, () => {
                this.getAvailableSites(this.state.activePage, this.state.pageSize, '');
            });
        }
    }

    async getSlotsBasedCpidAndReservationDate(cpId, reservationDate){
     document.getElementById("loader_image_div").style.display = "block";
     let timeZone =  moment.tz.guess();
     let url = baseUrl.URLPath + servicesUrl.getSlotsBasedOnChargerAndReservationDate + "?cpId=" + cpId + "&reservarionDate=" + reservationDate + "&timeZone=" + timeZone;
     let type = ApiMethodTypes.GET;
     let headers = getSecurityHeaders();
     let payload = {}
     let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t,true);
     if(response.status === 404){
        let errors = this.state.errors;
                errors["reservationsError"] = response.message
                setTimeout(() => {
                    this.setState({
                        ...this.state,
                        errors: errors
                    }, () => {
                        document.getElementById("loader_image_div").style.display = "none";
                    });
                }, 100);
     }
     if (response != undefined && response?.length > 0) {
        setTimeout(() => {
            this.setState({
                ...this.state,
                slotsBasedOnCpId: response
            }, () => {
                document.getElementById("loader_image_div").style.display = "none";
            });
        }, 100);
    }else{
        if(response !== undefined && response?.length === 0){
            document.getElementById("loader_image_div").style.display = "none";
        }
    }
    }

    async getReservationOverview(reservationId){
        document.getElementById("loader_image_div").style.display = "block";
        let url = baseUrl.URLPath + servicesUrl.getReservationOverview + "?reservationId=" + reservationId;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t,true);
        if (response != undefined) {
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    reservationInfo: response
                }, () => {
                    document.getElementById("loader_image_div").style.display = "none";
                });
            }, 100);
        }
    }

    async cancelReservation(reservationId){
        let timeZone =  moment.tz.guess();
        document.getElementById("loader_image_div").style.display = "block";
        let url = baseUrl.URLPath + servicesUrl.cancelReservation;
        let type = ApiMethodTypes.DELETE;
        let headers = getSecurityHeaders();
        let payload =  {
            "reservationId": reservationId,
            "timeZone": timeZone
          }
       
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t,true);
        if (response != undefined) {
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    deleteReserve: !this.state.deleteReserve,
                    deletemessage: this.props.t(response.message)
                }, () => {
                    document.getElementById("loader_image_div").style.display = "none";
                });
            }, 100);
        }
    }

    async addReservation(cpId, startTime, endTime, userEmail){
      
        let timeZone = moment.tz.guess()
        document.getElementById("loader_image_div").style.display = "block";
        let url = baseUrl.URLPath + servicesUrl.addReservation;
        let type = ApiMethodTypes.POST;
        let headers = getSecurityHeaders();
        let payload = { 
                        "chargePointId": cpId,
                        "startDateTime": startTime,
                        "stopDateTime": endTime,
                        "timeZone":timeZone,
                        "userEmail": userEmail,
                     }

     let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t,true);
     if(response.status === 404 && response != undefined){
        setTimeout(() => {
            this.setState({
                ...this.state,
                successReserve: !this.state.successReserve,
                successmessage: this.props.t(response.message),
                successTitle: this.props.t('alert'),
            }, () => {
                document.getElementById("loader_image_div").style.display = "none";
            });
        }, 100);
     }else{
        setTimeout(() => {
            this.setState({
                ...this.state,
                successReserve: !this.state.successReserve,
                successmessage: this.props.t(response.message),
                successTitle: this.props.t('success')
            }, () => {
                document.getElementById("loader_image_div").style.display = "none";
            });
        }, 100);
     }
    
    }

    toggleAdd(siteId) {
        let errors = this.state.errors;
        errors['reserveDateError'] = '';
        this.setState({
            ...this.state,
            modalAdd: !this.state.modalAdd,
            searchSiteId: siteId ?? '',
            errors:errors
        })
    }

    toggleConfirm() {
        this.setState({
            ...this.state,
            confirmAdd: !this.state.confirmAdd
        })
    }

    toggleDelete() {
        this.setState({
            ...this.state,
            confirmDelete: !this.state.confirmDelete
        })
    }

    toggleOverview(reserveid) {
        this.setState({
            ...this.state,
            modal: !this.state.modal,
            reservationId: reserveid
        }, () => {
            this.getReservationOverview(reserveid)
        })
    }

    closeDelete(getReservations) {
        this.setState({
            ...this.state,
            deleteReserve: !this.state.deleteReserve,
            modal: !this.state.modal
        }, ()=>{
            let selectedDate = localStorage.getItem('selectedDate');
            if(selectedDate){
                var currentSiteId = localStorage.getItem('currentReservationSiteId');
                getReservations(currentSiteId,selectedDate);
            } else {
                var today = moment();
                today = today.subtract(1, "days");
                today = today.format("YYYY-MM-DD");
                today = today + "T18:30:00:000";
                var currentSiteId = localStorage.getItem('currentReservationSiteId');
                getReservations(currentSiteId,today);
            }
        })
    }

    closeSuccess(getReservations) {
        this.setState({
            ...this.state,
            successReserve: !this.state.successReserve,
            modalAdd: !this.state.modalAdd
        }, ()=>{
            let selectedDate = localStorage.getItem('selectedDate');
            if(selectedDate){
                var currentSiteId = localStorage.getItem('currentReservationSiteId');
                getReservations(currentSiteId,selectedDate);
            } else {
                var today = moment();
                today = today.subtract(1, "days");
                today = today.format("YYYY-MM-DD");
                today = today + "T18:30:00:000";
                var currentSiteId = localStorage.getItem('currentReservationSiteId');
                getReservations(currentSiteId,today);
            }
        })
    }

    toggleDeleteReserve() {
        this.setState({
            ...this.state,
            confirmDelete: !this.state.confirmDelete
        }, () => {
            this.cancelReservation(this.state.reservationId)
        })
    }

    onChangecpId() {
        let cpid = document.getElementById('chargersList').value;
        let today = moment(this.state.dateTime).utc().format("YYYY-MM-DDTHH:mm:ss") + ":000";

        this.setState({
            ...this.state,
            selectCpId: cpid
        }, () => {
            if (this.state.selectCpId != '' && this.state.dateTime != '' && this.state.errors['reserveDateError'] === '') {
                this.getSlotsBasedCpidAndReservationDate(this.state.selectCpId, today)
            }
        });
    }

    clearPrevData(){
        this.setState({
            ...this.state,
            selectCpId: '',
            dateTime: '',
            fromdateselect: '',
            todateselect: ''
        })
    }


    searchSite() {
        this.setState({ ...this.state, isOnSearchFind: true }, () => {
            this.getAvailableSites(1, this.state.pageSize, this.state.searchStr);
        });
    }

    // for from date calender 
    handleFromChange = date => {
        let errors = this.state.errors;
        if(new Date() > date){
            errors['reserveDateError'] = 'You can not select past dates.';
        } else {
            errors['reserveDateError'] = '';
        }
        
        let fromD = '';
        if (date != undefined && date != "" && date != null) {
            fromD = moment(date).utc().format("YYYY-MM-DDTHH:mm:ss") + ":000"
        }
        this.setState({
            ...this.state,
            dateTime: date,
            errors: errors
        }, () => {
            if (this.state.selectCpId != '' && fromD != '' && errors['reserveDateError'] === '') {
                this.getSlotsBasedCpidAndReservationDate(this.state.selectCpId, fromD)
            }
        });
    }

    selectedSlotChange(slot) {
        this.setState({
            ...this.state,
            fromdateselect: slot?.startDateTime,
            todateselect: slot?.stopDateTime
        })
    }

    reserReservationId(reserveid) {
        this.setState({
            reservationId: reserveid
        })
    }

    FindCpName() {
        this.state.searchStr = this.typeAHeadRef.current.inputNode.value;

        if (this.state.searchStr == null || this.state.searchStr == "") {
            $('.empty_site').text(this.props.t('please_enter_group_name'))
        } else {
            $('.empty_site').text('')
            this.state.pageSize = 10;
            this.state.activePage = 1;
            this.getAvailableSites(this.state.activePage, this.state.pageSize, this.state.searchStr);
        }
    }

    // search filter 
    handleKeyDown(e) {
        var serial_no = e.target.value;
        $(".empty_site").text('');

        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false);
        var sno = serial_no.slice(0, serial_no.length - 1)

        this.getSearchSuggestions(sno)

        if (key == "Backspace" || key == "Delete") {
            var x = e.target.value
            var sno = x.slice(0, x.length - 1)
            if (sno == "") {
                this.state.searchStr = sno;
                this.getAvailableSites(this.state.activePage, this.state.pageSize, '')
            }
        }

        if (e.keyCode == 13) {
            this.FindCpName();
            $("#rbt_id").hide();
        }

        if ((e.keyCode == 86 && ctrl)) {
            $(".empty_site").text('');
            this.getSearchSuggestions(serial_no)
        }
    }

    render() {
        return (
            <ReservationsAndSecondSeatingTemplate
                typeAHeadRef={this.typeAHeadRef}
                reservationResponse = {this.state.reservationResponse}
                resetSlotsBasedOnCpId = {this.resetSlotsBasedOnCpId}
                deletemessage={this.state.deletemessage}
                reserReservationId={this.reserReservationId}
                reservestart={this.state.reservestart}
                reservationInfo={this.state.reservationInfo}
                reserveend={this.state.reserveend}
                reservecpid={this.state.reservecpid}
                errorObject={this.state.errorObject}
                setReservation={this.setReservation}
                reservationid={this.state.reservationId}
                fromdateselect={this.state.fromdateselect}
                todateselect={this.state.todateselect}
                dateTime={this.state.dateTime}
                isSearchNotFound={this.state.isSearchNotFound}
                searchStr={this.state.searchStr}
                modalsession={this.state.modalsession}
                isactivate={this.state.isactivate}
                activemessage={this.state.activemessage}
                availableSites={this.state.availableSites}
                activePage={this.state.activePage}
                pageSize={this.state.pageSize}
                noOfRecords={this.state.noOfRecords}
                calenderResources={this.state.resources}
                calenderEvents={this.state.events}
                getChargersBasedonEmail={this.state.getChargersBasedonEmail}
                slotsBasedOnCpId={this.state.slotsBasedOnCpId}
                modalAdd={this.state.modalAdd}
                deleteReserve={this.state.deleteReserve}
                modal={this.state.modal}
                confirmDelete={this.state.confirmDelete}
                successReserve={this.state.successReserve}
                confirmAdd={this.state.confirmAdd}
                isActiveRulesOpen={this.state.isActiveRulesOpen}
                email={this.state.email}
                searchSiteId={this.state.searchSiteId}
                errors={this.state.errors}
                selectCpId={this.state.selectCpId}
                guarantedSlotDuration={this.state.guarantedSlotDuration}
                cooldown={this.state.cooldown}
                minimumChargingDuration={this.state.minimumChargingDuration}
                reporting={this.state.reporting}
                searchSuggestions={this.state.searchSuggestions}
                successmessage={this.state.successmessage}
                reservationId={this.state.reservationId}
                sessiontagOk={this.sessiontagOk}
                toggleActivate={this.toggleActivate}
                closeActiverules={this.closeActiverules}
                showEntriesChange={this.showEntriesChange}
                getDoughnutData={this.getDoughnutData}
                toggleActiverules={this.toggleActiverules}
                onChangeReservationStatus={this.onChangeReservationStatus}
                changeActiveDeactivate={this.changeActiveDeactivate}
                handlePageChange={this.handlePageChange}
                getReservationOverview={this.getReservationOverview}
                cancelReservation={this.cancelReservation}
                addReservation={this.addReservation}
                toggleAdd={this.toggleAdd}
                toggleConfirm={this.toggleConfirm}
                toggleDelete={this.toggleDelete}
                toggleOverview={this.toggleOverview}
                closeDelete={this.closeDelete}
                closeSuccess={this.closeSuccess}
                FindCpName = {this.FindCpName}
                toggleDeleteReserve={this.toggleDeleteReserve}
                emailKeyPress={this.emailKeyPress}
                changeEmail={this.changeEmail}
                clearEmail={this.clearEmail}
                onChangeEmail={this.onChangeEmail}
                onChangecpId={this.onChangecpId}
                handleKeyDown={this.handleKeyDown}
                onChangeTypeahead={this.onChangeTypeahead}
                clearSearchSuggestions={this.clearSearchSuggestions}
                searchSite={this.searchSite}
                handleFromChange={this.handleFromChange}
                selectedSlotChange={this.selectedSlotChange}
                clearPrevData = {this.clearPrevData}
                successTitle = {this.state.successTitle}
                loading = {this.state.loading}
            />
        );
    }
}

export default withTranslation()(ReservationsAndSecondSeating);