import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import * as baseUrl from '../views/constants';
import servicesUrl from "../common/servicesUrl";
import { MDBModal, MDBModalBody ,MDBModalHeader , MDBModalFooter } from "mdbreact";

export default function CheckoutForm({ clientSecret, props }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [respData, setRespData] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [alertModel, setAlertModel] = useState(false);
  const [page, setPage] = useState('');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    // stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    //   switch (paymentIntent.status) {
    //     case "succeeded":
    //       setMessage("Payment succeeded!");
    //       break;
    //     case "processing":
    //       setMessage("Your payment is processing.");
    //       break;
    //     case "requires_payment_method":
    //       setMessage("Your payment was not successful, please try again.");
    //       break;
    //     default:
    //       setMessage("Something went wrong.");
    //       break;
    //   }
    // });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "https://google.com",
        },
        redirect: "if_required",
      });

      if (error) {
        // Handle error scenario
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
      } else {

        // Handle success scenario
        // setMessage("Payment successful!");
        setRespData(paymentIntent);
        localStorage.setItem("transid", paymentIntent.id)
        setPaymentSuccess(true);
        // You can perform additional actions here upon successful payment
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: {
      type: 'tabs'
    }
  }
  
  const proceedToCharge = () => {
    // Your logic for charging goes here
    saveTransaction();
    setPaymentSuccess(false);
  };

  const closeAlert = () => {
    setAlertModel(false);
  };

  const saveTransaction = async () => {
    var transactionId = localStorage.getItem("transid");
    const date = new Date();
    let createTransaction =
    {
        "billAmount": parseFloat(localStorage.getItem("totalAmt")),
        "ccFee": 0,
        "customerEmail": localStorage.getItem("email"),
        "customerProfileId": localStorage.getItem("customerProfile"),
        "paidAmount": parseFloat(localStorage.getItem("totalAmt")),
        "paymentDate": date,
        "paymentGatewayType": "",
        "paymentTransactionId": transactionId,
        "paymentType": "USER_SUBSCRIPTION",
        "status": respData.status,
        "packageId":localStorage.getItem("packageId"),
        "sessionCurrency":localStorage.getItem("currencySymbol")
    }

    var _this = this;

    let url = baseUrl.URLPath + servicesUrl.saveSubData;
    // const data = await billRequest.callService('POST', createTransaction, url);
    const data = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(createTransaction),
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    }).then(resp => {
      if (resp.status === 401 || resp.status === 404 || resp.status === 500) {

      } else if (resp.status === 400) {
        return resp.json();
      } else if (resp.status === 200) {
        return resp.json();
      }
    }).then(response => {
      if(response.message === "subscription_payment_incomplete"){
        setPaymentSuccess(true);
      }else{
        return response
      }
    }
    ).catch(error => {
      console.error(error)
    });

    // if (data.executed) {
    //   if (data.response.success) {
    //     localStorage.setItem("refundId", data.response.id)
    //     alert(
    //       "Payment Success",
    //       "",
    //       [
    //         { text: "OK", onPress: () => _this.CallBack() }
    //       ]
    //     );
    //     localStorage.setItem('paymentDone', "START");
    //   } else if (data.response.status == "FAILURE") {

    //     if (data.response.errorCode == "1104") {
    //       _this.activateAccount();
    //       localStorage.setItem('token', data.response.token);
    //     } else if (data.response.errorCode == "3117") {
    //       localStorage.setItem('token', data.response.token);
    //     } else {
    //       // alert(translate(strings[data.response.errorMessage]));
    //     }
    //   }
    // } else {
    //   const value = localStorage.getItem('isServerMaintance');
    //   if (value == "Yes") {
    //     _this.setState({
    //       isMaintancePage: true,
    //     })
    //   }
    // }

  }
 

  return (
    <><form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <div className="cardfooter">
        <p className="totalamount">Total: <span style={{ fontSize: "20px", fontWeight: "bold" }}>{localStorage.getItem('currencySymbol')} {localStorage.getItem("totalAmt")}</span></p>
        <button disabled={isLoading || !stripe || !elements} id="submit" className="btn-primary payBtnCss">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>

      <MDBModal isOpen={paymentSuccess} size="md" className="model-top">
        <MDBModalBody className='text-center'>
          <img
            src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/successfully.svg"}
            width="72"
            height="72"
            alt="" />
          <h3 style={{ color: "#3B886B", paddingTop: "15px" }}>{localStorage.getItem('currencySymbol')}{respData?.amount/100}</h3>
          <p className='esim-popup-para'>
            Your payment has been successful.
          </p>
          <button className='btn qr-btn' onClick={proceedToCharge} style={{ margin: "1rem 0" }}>
            Close
          </button>
        </MDBModalBody>
      </MDBModal>
       {/* set charging limit warning popup message */}
       <MDBModal isOpen={alertModel} size="md" data-toggle="modal" backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false">
        <MDBModalHeader>Alert</MDBModalHeader>
        <MDBModalBody>Subscription payment incomplete.</MDBModalBody>
        <MDBModalFooter>
           <button type="button" data-test="button" className={`btn_primary`} onClick={closeAlert}>OK</button>
        </MDBModalFooter>
    </MDBModal>
      </>
  );
}