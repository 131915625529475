import React from 'react';
import { Link } from 'react-router-dom';
import * as baseUrl from '../views/constants';
import { withTranslation } from 'react-i18next';
import { MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import moment from 'moment';
class EsimActivated extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: "EN",
            errors: {},
        }
        this.ChangeLanguage = this.ChangeLanguage.bind(this);
    }

    componentDidMount(){
        let Language = localStorage.getItem("Language");
            if(Language === 'Deutsch'){
                this.props.i18n.changeLanguage('de')
                this.setState({
                    lang: 'DE'
                  })
            } else{
                this.props.i18n.changeLanguage('en')
            }
       }
    
    ChangeLanguage(lang) {
        if (lang === "Deutsch") {
            this.props.i18n.changeLanguage('de')
            var language = 'DE'
        } else if (lang === "English") {
            this.props.i18n.changeLanguage('en')
            var language = 'EN'
        }
        this.setState({
            lang: language,
        })
        localStorage.setItem("Language", lang)

    }


    render() {
        const { t } = this.props;
        const simActDetails =  JSON.parse(localStorage.getItem("simActDetails"));
        let date = simActDetails.expirationDate ? moment(simActDetails?.expirationDate).format("DD MMM YYYY").split(" ") : '';
        date = date[1] ? simActDetails.expirationDate ? moment(simActDetails.expirationDate).format("DD MMM YYYY").replace(date[1] , t(date[1])) : "" :"";
        return (
            <main className='esim-main-container'>
                <div className='container-fluid p-0 m-0'>
                    <div className='row '>
                        <div className='d-none d-md-block col-md-5'>
                            <div className='esim-welcome m-0'>
                                <div className='esim-welcome-2' >
                                    <h1 className='text-center esim-h1' >{t('welcome')}</h1>
                                    <h1 className='text-center esim-h1'>{t('lets_get_started')}</h1>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-7 pt-1 mb-3">
                            <nav className="nav_bar pl-0 pr-0">
                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/webasto-logo.png"} className="logo esim-navbar-logo ml-1" alt="logo" width="105" />
                                <ul className="navbar-nav ml-auto">
                                    <li id="navbar-static-tools" className="nav-item">
                                        <span className="nav-link esim-lang-navitem mr-4" id="navbar-tools" data-toggle="dropdown" aria-haspopup="false" aria-expanded="true">
                                            <img src={process.env.PUBLIC_URL + '/assets/img/globe.svg'} className="d-inline-block align-top logo esim-flag-icon" alt="globe" />
                                            <span className='esim-nav-lang'>{this.state.lang}</span>
                                            <i className="fas fa-caret-down fa-sm esim-fa-icon"></i>
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-right drop_menu  pt-1 pb-1" aria-labelledby="userDropdown">
                                            <span className="dropdown-item cursor-p" onClick={this.ChangeLanguage.bind(this, 'English')}>
                                                <span className="esim-drpdwn-item">
                                                    {/* <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/eng.svg"}  className="d-inline-block align-top logo flag_icon country_img" alt="eng" /> */}
                                                    English (EN)</span>
                                            </span>
                                            <span className="dropdown-item cursor-p " onClick={this.ChangeLanguage.bind(this, 'Deutsch')}>
                                                <span className="esim-drpdwn-item">
                                                    {/* <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/germany.svg"}  className="d-inline-block align-top logo flag_icon country_img" alt="germany" /> */}
                                                    {/* {this.props.t('german')}</span> */}
                                                    Deutsch (DE)</span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                            <div className="welcome_bg d-md-none mt-3">
                                <h4 className='text-center' style={{ color: "#fff", fontWeight: "bold" }}>{t('welcome')}</h4>
                                <h4 className='text-center' style={{ color: "#fff" }}>{t('lets_get_started')}</h4>
                            </div>
                            <div className='esim-welcome-child1'>
                                <h5 className='esim-h3'>{t('esim_charging_station_activated')}</h5>
                                {/* <h5 className='esim-h3 d-block d-md-none'>{t('esim_of_your_webasto')}<br />{t('charging_station_already_activated')}</h5> */}

                                <p className='esim-para' >{t('your_charging_station_id')}:<br />
                                    <span className='esim-para' style={{ fontWeight: 'bold' }}>{simActDetails?.chargePointId}</span></p>
                                <p className='esim-para'>{t('expiration_date')}: <strong style={{ color: 'black' }}> {date}</strong></p>
                                <p className='esim-para'>{t('remaining_time')}: <strong style={{ color: 'black' }}>{simActDetails?.remainingDays} {t('days')}</strong></p>
                                <p className='esim-para'>{t('you_can_activate_esim_for_annual_fee_more_info_soon')} </p>
                                    
                                {/* <p className='esim-para d-block d-md-none'>{t('you_can_activate_esim')}<br />{t('charging_station_for_annual_fee')}
                                    <br />{t('info_follow_soon')}</p> */}
                            </div>

                            <div className='text-center'>
                                <button type="button" className='esim-btn esim-btn2'>{t('coming_soon')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withTranslation()(EsimActivated);
