import React from 'react';
import { MDBRow, MDBCol, MDBInput } from 'mdbreact';
import apiCallUtility from '../common/APICallUtility'
import * as constants from './constants';
import { Alert } from 'reactstrap';
import ChargePointSelection from './chargePointSelection';
import { withTranslation } from 'react-i18next';

class setChargingProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page_title: "Set Charging Profile",
      sucess: false,
      colour: 'sucess',
      message: "",
      errors: {},
      profileName: "",
      modalsession: false,
      items: [],
      fields: {
        connectorID: '',
        csChargingProfiles: '',
        transactionId:''
      },
      chargePoint: '',
      operationId: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectedCP = this.getSelectedCP.bind(this);
    this.ProfileChange = this.ProfileChange.bind(this);
  }

  ProfileChange() {
    let errors = this.state.errors;
    var profileName = document.getElementById("profileNameId").value;
    let fields = this.state;
    fields["csChargingProfiles"] = profileName;

    if (profileName !== 0) {
      errors["emptycsChargingProfiles"] = "";
    }

    this.setState({ fields, errors });
  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {
    let errors = this.state.errors;
    let fields = this.state.fields;

    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      errors["emptyConnectorId"] = ""
      this.setState({ fields, errors });

    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields });


    }

  }

  onShowAlert(){
    
   this.setState({sucess:true},()=>{
     window.setTimeout(()=>{
       this.setState({sucess:false})
     },5000)
   });
  }
  
  async handleSubmit(event) {
    

  var payload=this.getPayLoad();
  var errors={};
  var connectorId = payload.connectorId;
  var csChargingProfiles = payload.csChargingProfiles;

  if(this.getCPList()?.length=== 0 || this.getCPList() === undefined ){
    errors["emptyChargePoint"]=this.props.t('select_charge_point')
  }
  if(isNaN(connectorId)){
    errors["emptyConnectorId"] = this.props.t('enter_connector_id')
  }
  if(!csChargingProfiles){
    errors["emptycsChargingProfiles"] = this.props.t('select_charging_profile_name')
  }
  
  if(this.getCPList()?.length!== 0 && this.getCPList() !== undefined && !isNaN(connectorId) && csChargingProfiles){
    var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList(), this.props.t);
   // alert(response);
   if (response === this.props.t('unable_toprocess_request_try_after_some_time')) {
    this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
      this.onShowAlert();
    })
  } else  if (response === 'Request is processed Successfully'){
    this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
      this.onShowAlert();
    })
  }
  }
  this.setState({errors:errors})
  }

  getSelectedCP(cpList) {
    let errors = this.state.errors;
    if (cpList !== "") {
      errors["emptyChargePoint"] = ""
    }
    this.setState({ cpList: cpList, errors });
  }

  getCPList() {
    var cplist = [];

    var Id = this.state.operationId;
    if (Id !== null && Id !== "") {
      cplist = Id.split();
    } else {
      cplist = this.state.cpList;
    }

    return cplist;
  }

  getMessageType() {
    return constants.SET_CHARGING_PROFILE_REQUEST;
  }

  getPayLoad() {
    var payload = {
      "connectorId": parseInt(this.state.fields.connectorID),
      "csChargingProfiles": this.state.fields.csChargingProfiles,
      "transactionId": parseInt(this.state.transactionId)
    };

    return payload;
  }

  async getChargingProfile(){
    var url = constants.URLPath + "chargingprofiles/search"
    await fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if(resp.status===401){
          window.location.reload();
        }
        if (resp.status === 500) {
          alert(this.props.t('internal_server_error'))
        }
        if (resp.status === 200) {
          return resp.json();
        } 
      }) 
      .then((response) =>{
        
        if(response!==undefined){
            this.setState({
             items:response
            })
        }

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata !== undefined) {
      if (propsdata.chargePoint !== undefined && propsdata.operationId !== undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }
    this.getChargingProfile()
  }
  componentWillUnmount() {
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }
  render() {
   const{t}=this.props
    let ChargingprofileData = "";
    if (this.state.items.length !== 0) {
      ChargingprofileData = this.state.items.map((data, index) =>
        <option value={data.chargingProfileId} >{data.chargingProfileName}</option>
      );
    }

    return (
      <>
        <main class="content-div" >
          <p> {t('Set Charging Profile')}
            <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page">  {t('Set Charging Profile')}  </span>
            </div>
          </p>
          <div className="page-outerdiv">
            <Alert color={this.state.colour} isOpen={this.state.sucess} > {this.state.message} </Alert>
            <MDBRow className="mb-4 mt-3">
              <MDBCol sm="12">
                <div>
                  <form className="needs-validation">
                    <MDBRow>
                      <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="SetChargingProfile" ></ChargePointSelection>
                      <MDBCol md="12">
                        <span className="selection_charge_point_error w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptyChargePoint"]}</span></span>
                      </MDBCol>
                      <MDBCol md="4" >
                        <select className="browser-default custom-select select_height selm" id="profileNameId" onChange={this.ProfileChange}>
                          <option value="">{t('charging_profile_name')} *</option>
                          {ChargingprofileData}
                        </select>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} >{this.state.errors["emptycsChargingProfiles"]}</span></span>
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.fields.connectorID}
                          name="connectorID"
                          onInput={this.numberHandler.bind(this, "connectorID")}
                          type="text" pattern="[0-9]*"
                          id="connectorID"
                          label={t('connector_id_integer')+" *"}
                          required
                        >
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} >{this.state.errors["emptyConnectorId"]}</span></span>
                          <small className="form-text text-muted">
                            {t('if_empty0charge_point')}
                          </small>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.transactionId}
                          name="ID of the Active Transaction"
                          onInput={this.numberHandler.bind(this, "transactionId")}
                          type="text" pattern="[0-9]*"
                          id="transactionId"
                          label={t('id_of_the_active_transaction')}
                          required
                        >
                          <span className="error_msg w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptytransactionId"]}</span></span>
                        </MDBInput>
                      </MDBCol>
                    </MDBRow>
                    <button className="mt-5 refresh-btn" type="button" data-test="button" onClick={this.handleSubmit} >{t('perform')}</button>
                  </form>
                </div>
              </MDBCol>

            </MDBRow>
          </div>
        </main>
      </>
    );
  }
}

export default withTranslation()(setChargingProfile);