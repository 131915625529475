import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import $ from "jquery";
import moment from 'moment';
import { Alert } from 'reactstrap';
//API Imports
import * as baseUrl from './constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getHeaders, getSecurityHeaders, getActiveSecurityHeaders } from '../common/HttpRequestClass';

//css imports
import '../App.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalactivate: false,
      fields: { email: '', pwd: '' },
      errors: {},
      data: [],
      activeItem: "1",
      captchaMatchStatus: false,
      Usercapcha: "",
      orginactive: false,
      captchaLength: 5,
      colour:'sucess',
      message:"",
     success:false,
     hasActivePackageSubscription:false,
     userPackageSubscriptionEnabled:false,
     userRole:""
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCapcha = this.handleChangeCapcha.bind(this);
    this.RefreshNewCapcha = this.RefreshNewCapcha.bind(this);
    this.makeid = this.makeid.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
    this.getDateRange = this.getDateRange.bind(this);
    this.toggleactivate = this.toggleactivate.bind(this);
    this.toggleOrgactivate = this.toggleOrgactivate.bind(this);
    this.toggleactivesuccess = this.toggleactivesuccess.bind(this);
    this.accountActivate = this.accountActivate.bind(this);
    this.getAuthorizationDetails = this.getAuthorizationDetails.bind(this);
  }

  UNSAFE_componentWillMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }

  componentDidMount() {
    this.RefreshNewCapcha()
    $('#txtCaptcha').bind("copy", function (e) {
      e.preventDefault();
    });
    $('#cat').bind("paste", function (e) {
      e.preventDefault();
    });

    if(localStorage.getItem('unblock_user') === 'true'){
      this.setState({message:"Your account is unblocked successfully. Please login.", colour:'success', success:true}, ()=>{
        window.setTimeout(()=>{
          localStorage.setItem('unblock_user', 'false');
          this.setState({success:false});
        },5000)
      })
    }
  }

  /* handleChange:Used to keep fields in state*/
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleChangeCapcha(e) {
    this.setState({ Usercapcha: e.target.value });
  }

  //Refresh Catpcha
  RefreshNewCapcha() {
    this.setState({
      captchaMatchStatus: false,
      Usercapcha: "",
      capchaText: this.makeid(this.state.captchaLength)
    }, ()=>{
      document.getElementById("txtCaptcha").value = this.state.capchaText;
    })
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /* handleSubmit:Used to perform Validations*/
  async handleSubmit(event) {
    localStorage.clear();
    event.preventDefault();

    // Variable 
    let errors = {};
    var email = this.state.fields['email'];
    var pwd = this.state.fields['pwd'];
    var capcha = this.state.Usercapcha;

    //if email and password is blank   
    if (email === '') {
      errors["emailempty"] = "E-mail is empty.";
    }
    if (pwd === "") {
      errors["passwordempty"] = "Password is empty.";
    }
    if (capcha === "") {
      errors["captchaempty"] = "Please Enter Captcha.";
    }
    if (this.state.capchaText !== capcha) {
      errors["invalidCapcha"] = "Invalid Captcha.";
      this.RefreshNewCapcha();
    }
    //  you have to enter at least 6 digit!
    this.setState({
      errors: errors
    });
    //if email and password is not blank and email is in wrong format   
    if (email && pwd && capcha && this.state.capchaText === capcha) {
      let url = baseUrl.LoginPath + servicesUrl.login;
      let type = ApiMethodTypes.POST;
      let headers = getHeaders();

      // payload
      let payload = { 
        "email": this.state.fields.email.toLowerCase(), 
        "password": this.state.fields.pwd, 
        "tenant": "webasto" 
      }

      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      if(response.status === 404 || response.status === 400) {
        errors["invalidEmailPass"] = "Your E-mail / password is incorrect."
        this.setState({ errors: errors }, () => { this.RefreshNewCapcha(); });
      } else {
          if (response?.status === "FAILURE") {
            if (response?.errorMessage === "you_must_change_your_password") {
              localStorage.setItem("Authorization", "Bearer " + response.token);
              this.getUserDetails("updatepassword");
              this.getDateRange();
            } else if (response.errorMessage === "your_account_is_not_activated") {
              errors["active"] = this.props.t('your_account_is_not_activated');
              this.toggleactivate();
              this.setState({ errors: errors });
            }else if(response.errorMessage === "your_organization_is_not_activated"){
              this.toggleOrgactivate();
            }else if(response.errorMessage === "user_account_blocked"){
              errors["invalidEmailPass"] = this.props.t(response.errorMessage) + moment(response.blockedDate).local().format('YYYY-MM-DD HH:mm:ss')
              this.setState({
                errors: errors
              });
              this.RefreshNewCapcha()
            } else {
              errors["invalidEmailPass"] = this.props.t(response.errorMessage)
              this.setState({ errors: errors });
              this.RefreshNewCapcha()
            }
          } else {
            if (response.active === "0") { } 
            else {
              localStorage.setItem("Authorization", "Bearer " + response.token);
              localStorage.setItem('refreshTokenReference', response?.refreshTokenReference?.trim() ?? '');
              localStorage.setItem("activeAuthorization", "Bearer " + response?.token ?? '');
              localStorage.setItem("loginStatus", response.loginStatus);
              localStorage.setItem("hasActivePackageSubscription", response?.hasActivePackageSubscription);
              localStorage.setItem("userPackageSubscriptionEnabled", response?.userPackageSubscriptionEnabled);
              this.setState({
                hasActivePackageSubscription:response?.hasActivePackageSubscription,
                userRole:response?.userRole,
                userPackageSubscriptionEnabled:response?.userPackageSubscriptionEnabled
              })
              this.getAuthorizationDetails();
            }
          }
      }
    }
  }


  async getAuthorizationDetails() {
 
    var url = baseUrl.URLPath + servicesUrl.getAuthorizationDetails;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let errors = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response) {
      localStorage.setItem("csOperation", response?.enableCSOperation ?? '');
      localStorage.setItem("broker", response?.enableBroker ?? '');
      localStorage.setItem("saveConnector", response?.enableConnector ?? '');
      localStorage.setItem("vehicle", response?.enableVehicle ?? '');
      localStorage.setItem("role", response?.role ?? '');
      localStorage.setItem("cronJobStatus", response?.enableCronJob ?? '');
      localStorage.setItem("emailNotificationStatus", response?.enableEmailNotification ?? '');
      localStorage.setItem("saveInventory", response?.enableInventoryUpdate ?? '');
      localStorage.setItem("enableCPImport", response?.enableCPImport ?? '');
      localStorage.setItem("isRollOutEnabled", response?.isRollOutEnabled ?? '');
      localStorage.setItem('preferredLanguage', response?.preferredLanguage ?? '');
      localStorage.setItem("roleAccess", response.roleAccess);
      localStorage.setItem("userPackage", JSON.stringify(response.userPackage));
      localStorage.setItem("showDXProperties", JSON.stringify(response.showDXProperties));
      this.getUserDetails();
      this.getDateRange();
    } else {
      console.log("Login Failed!");
    }
  }

  async getUserDetails(method_name) {
    var url = baseUrl.URLPath + servicesUrl.getUserDetails;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if(response){
      localStorage.setItem("tenant", response.tenant);
      localStorage.setItem("orgId", response.accountId);
      localStorage.setItem("UserTenant", response.tenant);
      localStorage.setItem("userId", response.userId);
      localStorage.setItem("email", response.email);
      localStorage.setItem("tenant", response.tenant);
      localStorage.setItem("role", response.role);
      localStorage.setItem("enableFord", response.tenant);
  
      if (method_name === "updatepassword") {
        this.props.history.push({
          pathname: '/updatepassword',
          state: { syspassword: this.state.fields.pwd }
        })
      } else if (response.role === "WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE") {
        localStorage.setItem("user", response.firstName);
        this.props.history.push('/csrLanding');
        window.location.reload();
      } else if(this.state.hasActivePackageSubscription === false && this.state.userPackageSubscriptionEnabled === true && this.state.userRole === 'WEBASTO_END_USER_RESIDENTIAL') {
        localStorage.setItem("user", response.firstName);
          this.props.history.push('/mysubscription');
          window.location.reload();
      }
      else {
        localStorage.setItem("user", response.firstName);
        this.props.history.push('/dashboard');
        window.location.reload();
      }
    }
  }

  async getDateRange() {
    let url = baseUrl.URLPath + servicesUrl.getDateRange;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    //max allowed date range duration (in days) for cpms logs, cscp logs, cp logs pages
    localStorage.setItem("dateRangeDuration", response?.listDurationDay ?? 30);
    //max allowed date range duration (in days) for cp ping pong page
    localStorage.setItem("listLogDuration", response?.listLogDuration ?? 7);
    //default date range duration (in days) for cscp logs page
    localStorage.setItem("defaultDuration", response?.defaultDuration ?? 30);
    //date range duration (in days) for Heartbeat logs page
    localStorage.setItem("heartbeatDuration", response?.heartbeatDuration ?? 7);
    //date range duration (in days) for cp ping pong logs page
    localStorage.setItem("pingPongDuration", response?.pingPongDuration ?? 7);
  }

  async toggleactivate() {
    this.setState({ modalactivate: !this.state.modalactivate });
  }

  toggleOrgactivate(){
    this.setState({ orginactive: !this.state.orginactive });
  }

  toggleactivesuccess(){
    this.setState({ modalactivesuccess: !this.state.modalactivesuccess });
  }

  async accountActivate() {
    let errors = {};
    this.setState({
      modalactivate: !this.state.modalactivate
    });

    let url = baseUrl.URLPath + servicesUrl.accountActivate;
    let type = ApiMethodTypes.PUT;
    let headers = getActiveSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if(response) {
      this.setState({
        modalactivesuccess: !this.state.modalactivesuccess
      });
    }

    this.setState({ errors: errors });
  }

  render() {
    
    return (
      <>
        <div className="login-page">
          {/* Navbar */}
          {/* satrt login page  */}
          <section className="view intro-2">
            <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
              <div className="container">
                <form className="form-login" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                      {/* Form with header */}
                      <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                        <div className="card-body">
                          {/* Header */}
                          <div className="col-md-12 login_icon">
                            <img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{ width: "138px" }} alt="" />
                          </div>
                          <p className="h4 text-center mb-3 pb-3 pt-2 title_font"> Sign in</p>
                          <Alert color={this.state.colour} isOpen={this.state.success} className="mt-2" >{this.state.message}</Alert>
                          {/* Body */}
                          <div className="md-form">
                            <i className="fas fa-user prefix white-text" />
                            <input type="text" id="email" className="form-control pl-0 text_lowercase" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
                            <label htmlFor="email" className={(this.state.fields['email'] !== "" && this.state.fields["email"] !== null) ? "active" : ""}>E-mail</label>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["emailempty"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidemail"]}</span></p></span>
                          </div>
                          <div className="md-form">
                            <i className="fas fa-lock prefix white-text" />
                            <input type="password" id="password" className="form-control" onChange={this.handleChange.bind(this, "pwd")} value={this.state.fields["pwd"]} autoComplete="off" />
                            <label htmlFor="password" className={(this.state.fields['pwd'] !== "" && this.state.fields["pwd"] !== null) ? "active" : ""}>Password</label>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }} >{this.state.errors["passwordempty"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidpwd"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["active"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["invalidEmailPass"]}</span></p></span>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12 mb-2"><span htmlFor="" className="capcha_label">Captcha</span></div>
                            <div className=" col-md-12  col-xs-6" >
                              <input type="text" id="txtCaptcha" readOnly={true} className="input_captcha" />
                              <i title="Refresh Captcha" aria-hidden="true" onClick={this.RefreshNewCapcha} className="fas fa-refresh refresh"></i>
                              <input type="text" autoComplete="off" className="form-control refres_lbl" name="cat" maxLength={this.state.captchaLength} placeholder="Enter the Captcha" onChange={this.handleChangeCapcha} value={this.state.Usercapcha} />
                              <span className="error_msg1 w3-animate-top mt-2 mb-2" ><p><span style={{ color: "red" }} > {this.state.errors["invalidCapcha"]} </span></p></span>
                            </div>
                          </div>
                          <div className="text-center mt-2">
                            <button type='submit' className="btn login-btn btn-lg waves-effect waves-light" onSubmit={this.handleSubmit}>Sign In</button>
                            <p className="mt-2" />
                            <div className="inline-ul text-center d-flex justify-content-center">
                              <Link className="txt2" to={{ pathname: '/forgotPassword', state: this.state.fields["email"] }}>Forgot password?</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Form with header */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
        {/* end  login page  */}

        {/* popup for confirma account activate */}
        <MDBModal isOpen={this.state.modalactivate} toggle={this.toggleactivate} size="md" className="model_active">
          <MDBModalHeader toggle={this.toggleactivate}>Activate Account</MDBModalHeader>
          <MDBModalBody>
            Your account is deactivated, Are you sure you want to activate it?
          </MDBModalBody>
          <MDBModalFooter>
          <button onClick={this.toggleactivate} className="btn_cancel" type="button" data-test="button"> Cancel <i className="fas fa-times ml-2"></i></button>
          <button className="btn_primary" onClick={this.accountActivate} type="button" data-test="button">Yes</button>
          </MDBModalFooter>
        </MDBModal>

        {/* popup for sucessfull activate */}
        <MDBModal isOpen={this.state.modalactivesuccess} toggle={this.toggleactivesuccess} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleactivesuccess}>Success</MDBModalHeader>
          <MDBModalBody>
            Account activated successfully.
          </MDBModalBody>
          <MDBModalFooter>
          <button className="btn_primary" onClick={this.handleSubmit} type="button" data-test="button">OK</button>
          </MDBModalFooter>
        </MDBModal>

        {/* popup for confirma account activate */}
        <MDBModal isOpen={this.state.orginactive} toggle={this.toggleOrgactivate} size="md" className="model_active">
          <MDBModalHeader toggle={this.toggleOrgactivate}>Alert</MDBModalHeader>
          <MDBModalBody>
            Please contact administrator
          </MDBModalBody>
          <MDBModalFooter>
          <button className="btn_primary" onClick={this.toggleOrgactivate} type="button" data-test="button">Ok</button>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
};

export default withTranslation()(Login);