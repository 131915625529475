import React from 'react';
import { MDBInput, MDBCol, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { Typeahead } from 'react-bootstrap-typeahead';
import $, { data } from "jquery";
import Loader from './loader';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import {getSecurityHeaders,getSecurityHeadersWithTimezone} from '../common/HttpRequestClass'

const history = createBrowserHistory();

class vehicles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoder: false,

      vehicleName1: "",
      items: [],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      list: [],
      intial_data: [],

      vehicleIDs: [],
      options: [],
      multiple: false,
      deletemodal1: false,
      modalsession: false,

      errors: {},
      id: '',
      page_title: "Vehicles",
      vehiclesArray: [],
      fields: { id: '', manufacturer: '', oemUserName: '', oemPassword: '', energyType: '', vin: '', displayName: '', vehicleId: '' },
      loading: false,
    }
    this.manufacturerChange = this.manufacturerChange.bind(this);
    this.vehicleedit = this.vehicleedit.bind(this);
    this.vehicledelete = this.vehicledelete.bind(this);
    this.energytypeChange = this.energytypeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addtoggle = this.addtoggle.bind(this);
    this.deletetoggle = this.deletetoggle.bind(this);
    this.saveeditdata = this.saveeditdata.bind(this);
    this.deletetoggleOk = this.deletetoggleOk.bind(this);
    this.deletetoggle1 = this.deletetoggle1.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.vehicleNameChange = this.vehicleNameChange.bind(this);
    this.getSelectedVehicleName = this.getSelectedVehicleName.bind(this);
    this.getVehicleList = this.getVehicleList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.FindChargePoint = this.FindChargePoint.bind(this);
    this.ClearChargepoint = this.ClearChargepoint.bind(this);
    this.getUniqueVehicleList = this.getUniqueVehicleList.bind(this);
    this.onBlurEvent = this.onBlurEvent.bind(this);
  }

  // method to clear search filter
  ClearChargepoint(ref, event) {
    let errors = {};
    errors["vehicleNameNotexists"] = "";
    this.setState({
      errors: errors,
      pageSize: 10,
      activePage: 1
    }, ()=>{
      this.getVehicleList(this.state.pageSize, 1);
    });
    ref.current.clear();
    this.setState({
      options: [],
      errors: {}
    })
    this.getUniqueVehicleList();
  }


  async togglesession() {
    ;
    this.setState({
      modalsession: !this.state.modalsession
    });
  }
  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }
  handlePageChange(pageNumber) {
    ;
    if (pageNumber !== this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData === undefined) {
        this.setState({ activePage: pageNumber });
        this.getVehicleList(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ dataArray: pageData })
      }
    }
  }

  //show entries change
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    
    this.setState({
      activePage: 1,
      pageSize: entries
    }, ()=>{
      this.getVehicleList(entries, 1);
    });
  }

 async getVehiclesCount(){
  var url = baseUrl.URLPath + servicesUrl.getVehiclesCount;
  let type = ApiMethodTypes.GET;
  let headers = getSecurityHeaders();
  let payload = {}
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if(response){
    this.setState({ noOfRecords: response.count });
  }

 }
  componentDidMount() {
    this.getVehiclesCount();
    this.getVehicleList(this.state.pageSize, this.state.activePage);
    this.getUniqueVehicleList();
  }
  // to get vehicle list
async getVehicleList(pageNo, pageSize){
  this.setState({ loading: true })
  var url = baseUrl.URLPath +servicesUrl.getVehicleList+"?pageNo=" + pageSize + "&pageSize=" + pageNo;
  let type = ApiMethodTypes.GET;
  let headers = getSecurityHeadersWithTimezone();
  let payload = {}
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if(response){
    this.setState({ vehiclesArray: response, loading: false })
  }

}

  confirmOk() {
    window.location.reload();
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });

  }
  closetoggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleedit() {
    this.setState({
      editmodal: !this.state.editmodal
    });

  }
  closetoggleedit = () => {
    this.setState({
      editmodal: !this.state.editmodal
    });
  }
  addtoggle = () => {
    this.setState({
      addmodal: !this.state.addmodal
    });
  }
  closeaddtoggle = () => {
    this.setState({
      addmodal: false
    });
    window.location.reload();
  }
  updatetoggle = () => {
    this.setState({
      updatemodal: !this.state.updatemodal
    });
  }
  closeupdatetoggle = () => {
    this.setState({
      updatemodal: false
    });
    window.location.reload();
  }
  deletetoggle = () => {
    this.setState({
      deletemodal: !this.state.deletemodal
    });
  }
  deletetoggle1 = () => {
    this.setState({
      deletemodal1: !this.state.deletemodal1
    });
  }
  closedeletetoggle = () => {
    this.setState({
      deletemodal: false
    });
    window.location.reload();
  }


  /* Edit Get*/
async vehicleedit(vid){
  let url = baseUrl.URLPath + servicesUrl.getVehicles + "?id=" + vid;
  let type = ApiMethodTypes.GET;
  let headers = getSecurityHeaders();
  let payload = {}
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if(!response.status){
  this.setState({fields: response})
  this.toggleedit();
  document.getElementById('manufacturer').value = this.state.fields.oem;
  document.getElementById('energyType').value = this.state.fields.energyType;
  document.getElementById('vin').value = this.state.fields.vin;
  document.getElementById('displayName').value = this.state.fields.name;
  document.getElementById('vehicleIde').value = this.state.fields.oemVehicleId;

  }
}

  /* Edit Put */
async saveeditdata(vid,vname){
  let errors = {};
  var oemUserName = this.state.fields['oemUserName'];
  var oemPassword = this.state.fields['oemPassword'];

  if (oemUserName === undefined) {
    errors["userNameEmpty"] = this.props.t('enter_username');
  }
  if (oemPassword === undefined) {
    errors["passwordEmpty"] = this.props.t('enter_password');
  }
  if (errors.hasOwnProperty("userNameEmpty") || errors.hasOwnProperty("passwordEmpty")) {}
  else{
    let url = baseUrl.URLPath + servicesUrl.getVehiclesBynameById + "?id=" + vid + "&name=" + vname;
    let type = ApiMethodTypes.PUT;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if(response.status === "SUCCESS"){
    this.updatetoggle()
    this.setState({
      updatestatus: this.props.t('succees_condition') ,
      updatemsg: this.props.t('vehicle_updated_successfully')
    })
  }else if(response.status === "FAILURE"){
    this.updatetoggle();
    this.setState({
    updatestatus: this.props.t('failure_condition'),
    updatemsg: this.props.t(data.errorMessage)
    });
  }
    
  }
  this.setState({ errors: errors })
}

  vehicledelete(vid) {
    localStorage.setItem("deleteVid", vid);

    this.setState({
      deletemodal1: false,
    });
    this.deletetoggle1();
  }
  /* Delete */
async deletetoggleOk(){
  var getVid = localStorage.getItem("deleteVid");
  let url = baseUrl.URLPath + servicesUrl.getVehicles + "?id=" + getVid;
  let type = ApiMethodTypes.DELETE;
  let headers = getSecurityHeaders();
  let payload = {}
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if(response.status === "SUCCESS"){
    this.deletetoggle();
      this.setState({
        deletemodal1: false,
        deletestatus: this.props.t('succees_condition') ,
        deletemsg: this.props.t('vehicle_deleted_successfully')
      });

  }else if(response.status === "FAILURE"){
    this.deletetoggle();
    this.setState({
      deletestatus: this.props.t('failure_condition') ,
      deletemsg: this.props.t(data.errorMessage) ,
      deletemodal1: false,

      });
  }
}

  /* Add */
  async handleSubmit(event) {
    this.setState({ showLoder: true});
    event.preventDefault();
    let errors = {};
    var manufacturer = document.getElementById("manufacturer").value;
    var oemUserName = this.state.fields['oemUserName'];
    var oemPassword = this.state.fields['oemPassword'];
    var energyType = document.getElementById("energyType").value;
    var vin = this.state.fields['vin'];
    var vehicleId = this.state.fields['vehicleId'];
    var displayName = this.state.fields['displayName'];
    if (manufacturer === '') {
      errors["manufacturerempty"] = this.props.t('select_manufacturer');
    }
    if (oemUserName === '') {
      errors["oemUserNameempty"] = this.props.t('enter_username');
    }
    if (oemPassword === '') {
      errors["oemPassworderr"] = this.props.t('enter_password')
    }
    if (energyType === '') {
      errors["energyTypeerr"] = this.props.t('select_energy_type');
    }

    if (vin === "") {
      errors["vinerr"] = this.props.t('enter_vin');
    }
    if (vehicleId === "") {
      errors["vehicleIderr"] = this.props.t('enter_vehicleId');
    }
    if (displayName === "") {
      errors["displayNameerr"] = this.props.t('enter_vehicle_nam');
    }
    if (errors.hasOwnProperty("manufacturerempty") || errors.hasOwnProperty("oemUserNameempty") || errors.hasOwnProperty("oemPassworderr") || errors.hasOwnProperty("energyTypeerr") || errors.hasOwnProperty("vinerr") || errors.hasOwnProperty("vehicleIderr") || errors.hasOwnProperty("displayNameerr")) { 
      this.setState({
        showLoder: false,
      })
    }
    else {
      let requestPayload = { "manufacturer": document.getElementById('manufacturer').value, "oemUserName": this.state.fields.oemUserName, "oemPassword": this.state.fields.oemPassword, "energyType": document.getElementById('energyType').value, "vin": this.state.fields.vin, "displayName": this.state.fields.displayName, "vehicleId": this.state.fields.vehicleId };
      let url = baseUrl.URLPath + servicesUrl.addVehicle
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      let response = await APICallUtility.cpmsAPIFetch(url, type, requestPayload, headers, this.props.t);
      if(!response.status){
        this.setState({showLoder:false})
      }
      if (response.status === "SUCCESS") {
        this.addtoggle();
        this.setState({
          addstatus: this.props.t('succees_condition') ,
          addmsg: this.props.t('vehicle_added_successfully')
        });
      }
      else {
        if (response.status === "FAILURE") {
          this.addtoggle();
          this.setState({
            addstatus: this.props.t('failure_condition'),
            addmsg: this.props.t(response.errorMessage) 
          });
        }
      }

     }
   
    this.setState({ errors: errors });
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  manufacturerChange() {
    var x = document.getElementById("manufacturer").value;
    let fields = this.state.fields;
    fields["manufacturer"] = x;
    this.setState({ fields });
  }
  energytypeChange() {
    var x = document.getElementById("energyType").value;
    let fields = this.state.fields;
    fields["energyType"] = x;
    this.setState({ fields });
  }


  // logic to get vehicleName based on search filter
  vehicleNameChange(vehicleName) {
    
    if (vehicleName.length === 0) {
      this.getVehicleList(this.state.pageSize, this.state.activePage)
      this.setState({
        vehiclesArray: this.state.items,
      });
    } else {
      let errors = {};
      errors["vehicleNameNotexists"] = "";
      errors["selectVendorName"] = "";
      this.setState({
        errors: errors
      });
      this.getSelectedVehicleName(vehicleName);
    }
  }


  //api to get selected vehicle name
  async getSelectedVehicleName(name){
    this.setState({ loading: true })
    var errors = {};
    var url = baseUrl.URLPath + servicesUrl.getVehiclesByname + "?name=" + name;
    //api/v1.0/vehicles/all/getbyname/{name}
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if(response){
      if (response.length === 0) {
        errors["vehicleNameNotexists"] = this.props.t('vehicle_nam_not_exists');
        this.setState({
          errors: errors,
          loading: false
        });
      } else {
        this.setState({ vehiclesArray: response,
          loading: false })
      }
    }

  }

  handleKeyDown(ref, e) {
    var serial_no = e.target.value;
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);

    var list = this.state.intial_data;

    if (key === "Backspace" || key === "Delete") {
      if (serial_no.length <= 1) {
        list = [];
        this.setState({
          options: list
        });
      } else {
        this.getUniqueVehicleList(serial_no)
      }
    } else {
      if (e.keyCode === 13) {
        this.FindChargePoint(ref, e);
        $("#rbt_id").hide();
      }
      if ((e.keyCode === 86 && ctrl)) {
        this.getUniqueVehicleList(serial_no)
      }

      if (serial_no.length >= 0) {
        if (serial_no.length > 1) {
          this.getUniqueVehicleList(serial_no)
        }
      }
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }

        break;
    }
  }

  FindChargePoint(ref, event) {
    var name = ref.current.inputNode.value;
    var errors = {};

    if (name === null || name === "") {
      errors["connectorEmpty"] = this.props.t('enter_vehicle_nam');
      this.setState({
        errors: errors
      });
    }
    else {
      errors["connectorEmpty"] = "";
      this.getSelectedVehicleName(name);
    }
  }


 async getUniqueVehicleList(){
  var url = baseUrl.URLPath + servicesUrl.getUniqueVehicleList;
  let type = ApiMethodTypes.GET;
  let headers = getSecurityHeaders();
  let payload = {}
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if(response){
    var option = [];
    var data = response;
    this.setState({
      intial_data: option,
      options: data
    });
  }

 }
  
  onBlurEvent(event) {
    var vehicleName1 = event.target.value;
    this.setState({
      vehicleName1: vehicleName1
    })
    if (vehicleName1.length === 0) {
      this.getVehicleList(this.state.pageSize, this.state.activePage)
      this.setState({
        vehiclesArray: this.state.items,
      });
    }
    let errors = {};
    errors["vehicleNameNotexists"] = "";
    this.setState({
      errors: errors
    });
  }

  //function for sorting table data on click
  ascendingOrder() {
    this.setState({ vehiclesArray: this.state.vehiclesArray.reverse() })
  }

  render() {
    const { t } = this.props
    const ref = React.createRef();
    const data = this.state.vehiclesArray.map((vehiclesArray, index) => {
      var vehicleLastUpdateTime = "";
      var vehicleLastUpdateTime1 = "";
      if (vehiclesArray.base.vehicleLastUpdateTime === null || vehiclesArray.base.vehicleLastUpdateTime === "") {
        vehicleLastUpdateTime = "";
        vehicleLastUpdateTime1 = "";
      } else {
        vehicleLastUpdateTime = moment(vehiclesArray.base.vehicleLastUpdateTime).format("DD MMM YYYY");
        vehicleLastUpdateTime1 = moment(vehiclesArray.base.vehicleLastUpdateTime).format("HH:mm");
      }
      return (
        <tr>
          <td className="">{vehiclesArray.name}</td>
          <td className=""> {vehiclesArray.batteryState.chargingStatus}</td>
          <td className="">{vehiclesArray.base.soc} %</td>
          <td className="">{vehiclesArray.oem}</td>
          <td className="wrap-word">{vehicleLastUpdateTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {vehicleLastUpdateTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {vehicleLastUpdateTime1} </div> </>}</td>
          <td className="">&nbsp;<i className="fas fa-pencil-alt" title="Edit" style={{ cursor: 'pointer' }} onClick={this.vehicleedit.bind(this, vehiclesArray.id)} ></i>  &nbsp;
            <i className="far fa-trash-alt" title="Delete" style={{ cursor: 'pointer' }} onClick={this.vehicledelete.bind(this, vehiclesArray.id)}></i>
          </td>
        </tr>
      )

    })
    return (
      <>

        <main class="content-div" >
          <p>{t('Vehicles')}
          </p>
          <div className="page-outerdiv">
            {/* <div className="breadcrumb_div">Vehicles</div> */}

            {/* <MDBBtn color='primary' type="submit" className="btn btn-primary sm_btn btn-sm mt-1" style={{color:"#fff"}} onClick={this.toggle} >
 <i className="fa fa-plus"></i>
 </MDBBtn> */}
            <div className="row menubar">
              <div className="col-md-2 ">
                <div className="">
                  <button onClick={this.toggle} type="button" data-test="button">
                    <i className="fa fa-plus">
                    </i>
                    <span>{t('add')}</span>
                  </button>
                </div>
              </div>
              <div className="col-md-10">
                <div className="pull-right">
                  <label className="pull-left sub-text">{t('show_entries')}</label>
                  <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-md-4  pull-right">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    multiple={this.state.multiple}
                    options={this.state.options}
                    minLength={3}
                    placeholder={t('enter_vehicle_name')}
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    onChange={this.vehicleNameChange.bind(this)}
                    onBlur={this.onBlurEvent}
                    // onFocus={this.ClearChargepoint.bind(this,ref)}
                    ref={ref}
                  />
                  <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)} >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindChargePoint.bind(this, ref)} >{t('find')}</button>
                  </div>
                  {/* <MDBBtn color='primary' type="submit" className="btn btn-primary sm_btn btn-sm mt-1 pull-right" style={{color:"#fff"}} onClick={this.FindChargePoint.bind(this,ref)} >
                    Find
                </MDBBtn> */}
                  <span className="pull-left error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.state.errors["vehicleNameNotexists"]}</span></span>
                  <span className="pull-left error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.state.errors["connectorEmpty"]}</span></span>
                </div>
              </div>
            </div>

            <MDBModal isOpen={this.state.modal} toggle={this.toggle} className="custom_model_w" size="lg" backdrop={true} wrapClassName="custom_backdropclass">
              <div data-test="modal-header" class="modal-header">
                <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('insert_your_data')}</div>
                <button type="button" data-test="button" class="close" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              {/* <MDBModalHeader toggle={this.toggle}>Please insert your data</MDBModalHeader> */}
              {this.state.showLoder === true ? <Loader loader={this.state.showLoder} /> : ""}

              <MDBModalBody>
                <form
                  className="needs-validation"
                  onSubmit={this.submitHandler}
                  noValidate
                >
                  <div className="row" style={{ marginTop: '0.05rem' }}>
                    <MDBCol md="5" >
                      <label className="pull-left sel_lbl">{t('display_nam_of_vehicle')} :</label>
                    </MDBCol>
                    <MDBCol md="5" >
                      <MDBInput
                        id="displayName"
                        value={this.state.fields["displayName"]}
                        onChange={this.changeHandler.bind(this, "displayName")}
                        type="text"
                        name="displayName"
                      >
                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["displayNameerr"]}</span></span>


                      </MDBInput>

                    </MDBCol>
                  </div>
                  <div className="row" style={{ marginTop: '0.05rem' }}>
                    <MDBCol md="5" >
                      <label className="pull-left sel_lbl">{t('manufacturer')} :</label>
                    </MDBCol>
                    <MDBCol md="5">
                      <select className="browser-default custom-select select_height mb_8" id="manufacturer"
                        onChange={this.manufacturerChange.bind(this)}>
                        <option value="BMW">{t('bmw')}</option>
                        <option value="TESLA">{t('tesla')}</option>
                        <option value="VW">{t('vw')}</option>
                        <option value="NISSAN">{t('nissan')}</option>
                        <option value="MERCEDES">{t('mercedes')}</option>
                      </select>
                      <span className="error_msg w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["manufacturerempty"]}</span></span>

                    </MDBCol>
                  </div>
                  <div className="row" style={{ marginTop: '0.05rem' }}>
                    <MDBCol md="12">
                      <label className="pull-left sel_lbl"><b>{t('oem_credentials')}:</b></label>
                    </MDBCol>
                  </div>
                  <div className="row" style={{ marginTop: '0.05rem' }}>
                    <MDBCol md="5" >
                      <label className="pull-left sel_lbl">{t('username')}:</label>
                    </MDBCol>
                    <MDBCol md="5" >
                      <MDBInput
                        id="oemUserName"
                        value={this.state.fields["oemUserName"]}
                        onChange={this.changeHandler.bind(this, "oemUserName")}
                        type="text"
                        name="oemUserName"
                      >
                        <span className="error_msg w3-animate-top"> <span style={{ color: "red" }}>{this.state.errors["oemUserNameempty"]}</span></span>

                      </MDBInput>

                    </MDBCol>
                  </div>
                  <div className="row" style={{ marginTop: '0.05rem' }}>
                    <MDBCol md="5">
                      <label className="pull-left sel_lbl">{t('password')}:</label>
                    </MDBCol>
                    <MDBCol md="5">
                      <MDBInput
                        id="oemPassword"
                        value={this.state.fields["oemPassword"]}
                        onChange={this.changeHandler.bind(this, "oemPassword")}
                        type="password"
                        name="oemPassword"
                      >
                        <span className="error_msg w3-animate-top"> <span style={{ color: "red" }}>{this.state.errors["oemPassworderr"]}</span></span>

                      </MDBInput>

                    </MDBCol>
                  </div>
                  <div className="row" style={{ marginTop: '0.05rem' }}>
                    <MDBCol md="5">
                      <label className="pull-left sel_lbl">{t('energy_type')} :</label>
                    </MDBCol>
                    <MDBCol md="5">
                      <select className="browser-default custom-select select_height mb_8" id="energyType" onChange={this.energytypeChange.bind(this)} >
                        <option value="BATTERY">{t('battery')}</option>
                        <option value="HYBRID">{t('hybrid')}</option>
                      </select>
                      <span className="error_msg w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["energyTypeerr"]}</span></span>

                    </MDBCol>
                  </div>
                  <div className="row" style={{ marginTop: '0.05rem' }}>
                    <MDBCol md="5">
                      <label className="pull-left sel_lbl">{t('vin')} :</label>
                    </MDBCol>
                    <MDBCol md="5">
                      <MDBInput
                        id="vin"
                        value={this.state.fields["vin"]}
                        onChange={this.changeHandler.bind(this, "vin")}
                        type="text"
                        name="vin"
                      >
                        <span className="error_msg w3-animate-top"> <span style={{ color: "red" }}>{this.state.errors["vinerr"]}</span></span>
                      </MDBInput>
                    </MDBCol>
                  </div>
                  <div className="row" style={{ marginTop: '0.05rem' }}>
                    <MDBCol md="5">
                      <label className="pull-left sel_lbl">{t('vehicle_id')}</label>
                    </MDBCol>
                    <MDBCol md="5">
                      <MDBInput
                        id="vehicleId"
                        value={this.state.fields["vehicleId"]}
                        onChange={this.changeHandler.bind(this, "vehicleId")}
                        type="text"
                        name="vehicleId"
                      >
                        <span className="error_msg w3-animate-top"> <span style={{ color: "red" }}>{this.state.errors["vehicleIderr"]}</span></span>

                      </MDBInput>

                    </MDBCol>
                  </div>
                </form>
              </MDBModalBody>
              <MDBModalFooter className="border-top-0 p-3">
                <button type="button" data-test="button" className="btn_cancel" onClick={this.closetoggle}> {t('cancel')} <i class="fas fa-times ml-2"></i></button>
                <button type="button" data-test="button" className="btn_primary" onClick={this.handleSubmit}> {t('add_vehicle')} <i class="fas fa-plus ml-2"></i></button>
                {/* <MDBBtn color="danger" onClick={this.closetoggle}>Cancel</MDBBtn>
 <MDBBtn color="primary" onClick={this.handleSubmit}>Add Vehicle</MDBBtn> */}
              </MDBModalFooter>
            </MDBModal>
            <div className="row ">

              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="">{t('vehicle_name')}</th>
                      <th className="">{t('charging_status')}</th>
                      <th className="">{t('state_of_charge')}</th>
                      <th className="">{t('manufacturer')}</th>
                      <th className="">{t('last_updated')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </div>


          <MDBModal isOpen={this.state.editmodal} toggle={this.toggleedit} className="custom_model_w" size="lg" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
            <MDBModalHeader toggle={this.toggleedit}>{t('edit_your_data')}</MDBModalHeader>
            <MDBModalBody>
              <form
                className="needs-validation"
                onSubmit={this.submitHandler}
                noValidate
              >
                <div className="row" style={{ marginTop: '0.05rem' }}>
                  <MDBCol md="5" >
                    <label className="pull-left sel_lbl">{t('display_nam_of_vehicle')}</label>
                  </MDBCol>
                  <MDBCol md="5" >
                    <MDBInput
                      id="displayName"
                      value={this.state.fields["name"]}
                      onChange={this.changeHandler.bind(this, "name")}
                      type="text"
                      name="displayName"
                    >
                    </MDBInput>
                    {/* <span className="error_msg w3-animate-top"><span style={{color: "red"}}>{this.state.errors["displayName"]}</span></span> */}
                  </MDBCol>
                </div>
                <div className="row" style={{ marginTop: '0.05rem' }}>
                  <MDBCol md="5" >
                    <label className="pull-left sel_lbl">{t('manufacturer')} :</label>
                  </MDBCol>
                  <MDBCol md="5">
                    <select className="browser-default custom-select select_height mb_8" id="manufacturer" disabled
                      onChange={this.manufacturerChange.bind(this)}>
                      <option value="BMW">{t('bmw')}</option>
                      <option value="TESLA">{t('tesla')}</option>
                      <option value="VW">{t('vw')}</option>
                      <option value="NISSAN">{t('nissan')}</option>
                      <option value="MERCEDES">{t('mercedes')}</option>
                    </select>

                  </MDBCol>
                </div>
                <div className="row" style={{ marginTop: '0.05rem' }}>
                  <MDBCol md="12">
                    <label className="pull-left sel_lbl"><b>{t('oem_credentials')}:</b></label>
                  </MDBCol>
                </div>
                <div className="row" style={{ marginTop: '0.05rem' }}>
                  <MDBCol md="5" >
                    <label className="pull-left sel_lbl">{t('username')}:</label>
                  </MDBCol>
                  <MDBCol md="5" >
                    <MDBInput
                      id="oemUserName"
                      value={this.state.fields["oemUserName"]}
                      onChange={this.changeHandler.bind(this, "oemUserName")}
                      type="text"
                      name="oemUserName"
                    >
                      <span className="error_msg w3-animate-top"> <span style={{ color: "red" }}>{this.state.errors["userNameEmpty"]}</span></span>
                    </MDBInput>

                  </MDBCol>
                </div>
                <div className="row" style={{ marginTop: '0.05rem' }}>
                  <MDBCol md="5">
                    <label className="pull-left sel_lbl">{t('password')}:</label>
                  </MDBCol>
                  <MDBCol md="5">
                    <MDBInput
                      id="oemPassword"
                      value={this.state.fields["oemPassword"]}
                      onChange={this.changeHandler.bind(this, "oemPassword")}
                      type="password"
                      name="oemPassword"

                    >
                      <span className="error_msg w3-animate-top"> <span style={{ color: "red" }}>{this.state.errors["passwordEmpty"]}</span></span>
                    </MDBInput>
                  </MDBCol>
                </div>
                <div className="row" style={{ marginTop: '0.05rem' }}>
                  <MDBCol md="5">
                    <label className="pull-left sel_lbl">{t('energy_type')} :</label>
                  </MDBCol>
                  <MDBCol md="5">
                    <select className="browser-default custom-select select_height mb_8" disabled id="energyType" onChange={this.energytypeChange.bind(this)} >
                      <option value="BATTERY">{t('battery')}</option>
                      <option value="HYBRID">{t('hybrid')}</option>
                    </select>

                  </MDBCol>
                </div>
                <div className="row" style={{ marginTop: '0.05rem' }}>
                  <MDBCol md="5">
                    <label className="pull-left sel_lbl">{t('vin')} :</label>
                  </MDBCol>
                  <MDBCol md="5">
                    <MDBInput
                      id="vin"
                      value={this.state.fields["vin"]}
                      onChange={this.changeHandler.bind(this, "vin")}
                      type="text"
                      name="vin"
                      disabled
                    >
                    </MDBInput>
                  </MDBCol>
                </div>
                <div className="row" style={{ marginTop: '0.05rem' }}>
                  <MDBCol md="5">
                    <label className="pull-left sel_lbl">{t('vehicle_id')} :</label>
                  </MDBCol>
                  <MDBCol md="5">
                    <MDBInput
                      id="vehicleId"
                      value={this.state.fields["oemVehicleId"]}
                      onChange={this.changeHandler.bind(this, "oemVehicleId")}
                      type="text"
                      name="vehicleId"
                      disabled
                    >
                      <span className="error_msg w3-animate-top"> <span style={{ color: "red" }}>{this.state.errors["oemVehicleId"]}</span></span>
                    </MDBInput>

                  </MDBCol>
                </div>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={this.closetoggleedit}>{t('cancel')}</MDBBtn>
              <MDBBtn color="primary" onClick={this.saveeditdata.bind(this, this.state.fields["name"], this.state.fields.id)}>{t('update')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.addmodal} toggle={this.addtoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.addtoggle}>{this.state.addstatus}</MDBModalHeader>
            <MDBModalBody>
              {/* Vehicle added successfully. */}
              {this.state.addmsg}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={this.closeaddtoggle}>{t('close')}</MDBBtn>
              <MDBBtn color="primary" onClick={this.closeaddtoggle}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.updatemodal} toggle={this.updatetoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.updatetoggle}>{this.state.updatestatus}</MDBModalHeader>
            <MDBModalBody>
              {this.state.updatemsg}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={this.closeupdatetoggle}>{t('close')}</MDBBtn>
              <MDBBtn color="primary" onClick={this.closeupdatetoggle}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>


          <MDBModal isOpen={this.state.deletemodal1} toggle={this.deletetoggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.deletetoggle1}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {/* {this.state.deletemsg} */}
              {t('are_you_want_to_delete')}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={this.closedeletetoggle}>{t('cancel')}</MDBBtn>
              <MDBBtn color="primary" onClick={this.deletetoggleOk}>{t('yes')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>


          <MDBModal isOpen={this.state.deletemodal} toggle={this.deletetoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.deletetoggle}>{this.state.deletestatus}</MDBModalHeader>
            <MDBModalBody>
              {this.state.deletemsg}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="danger" onClick={this.closedeletetoggle}>Cancel</MDBBtn> */}
              <MDBBtn color="primary" onClick={this.confirmOk}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
              <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {this.state.loading ? (
            <Loader loader={this.state.loading} />
          ) : ("")}

        </main>
      </>
    );
  }
}

export default withTranslation()(vehicles);