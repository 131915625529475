import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Table } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import Pagination from "react-js-pagination";
import CustomModal from '../common/CustomModal';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCol, MDBRow, MDBInput } from "mdbreact";
import { event } from 'jquery';
import Loader from '../views/loader';

import moment from 'moment';

import * as baseUrl from '../views/constants';
import CustomFilterDropdown from "../common/CustomFilterDropdown";
import Select, { components } from "react-select";
import SuccessPopup from '../common/SuccessPopup';
import ConfirmationPopup from "../common/ConfirmationPopup";

class GuestVouchersTemplate extends React.Component {
    render() {

        const { t } = this.props
        const ref = React.createRef();

        const { selectedOption } = this.props;
        const options = [
            { value: 10, label: "10" },
            { value: 20, label: "20" },
            { value: 30, label: "30" },
            { value: 40, label: "40" },
            { value: 50, label: "50" },
        ];
        const colourStyles = {
            menuList: (styles) => ({
                ...styles,
                background: "white",
            }),
            option: (styles, { isFocused, isSelected }) => {
                // Only apply the background color when an option is selected
                if (isSelected) {
                    return {
                        ...styles,
                        background: "#30436E",
                        color: "white",
                        zIndex: 1,
                    };
                }
                return {
                    ...styles,
                    background: isFocused ? "white" : "transparent",
                    color: "#30436E",
                    zIndex: 1,
                };
            },
            menu: (base) => ({
                ...base,
                zIndex: 100,
            }),
        };

        const DropdownIndicator = (props) => {
            return (
                <components.DropdownIndicator {...props}>
                    {/* <span style={{ transform: 'rotate(360deg)' }}>▼</span> */}
                    <span className="caret-icon-w"></span>
                </components.DropdownIndicator>
            );
        };

        const data = this.props.QRCodeMangData?.map((dataArray, index) => {

            var endDate;

            if (dataArray.endDate === null || dataArray.endDate === "") {
                endDate = "NA";
            } else {
                endDate = moment(dataArray.endDate).format("DD MMM YYYY");
            }

            return (
                <tr key={dataArray.qrId}>
                    <td className='gv-table-td' style={{ textDecoration: 'underline' }}>
                        <Link to={{ pathname: '/viewTag', state: dataArray.authorizationTagId }} style={{color : '#555555'}}>
                            {dataArray.qrId}
                        </Link>
                    </td>
                    <td className='gv-table-td'>{dataArray.siteName}</td>
                    <td className='gv-table-td'>{dataArray.energyBalance === null ? t('unlimited') : dataArray.energyBalance + " kWh"}</td>
                    <td className='gv-table-td'>{dataArray.energyLimit === null ? t('unlimited') : dataArray.energyLimit + " kWh"}</td>
                    <td style={{ color: parseFloat(dataArray.energyConsumedPercentage?.replace(/%/g, '')) >= 90 ? 'red' : parseFloat(dataArray.energyConsumedPercentage?.replace(/%/g, '')) >= 25 ? 'orange' : 'green', fontWeight: '600' }}>
                        {
                        dataArray.energyConsumedPercentage === null ? "0.00 %" : dataArray.energyConsumedPercentage + " %"
                        }
                    </td>
                    <td className='gv-table-td'>{dataArray.exprireInDayLimitActive ? endDate : "unlimited"}</td>
                    <td className='gv-table-td'>{dataArray.qrCodeAssigned ? dataArray.guestUserName : t('not_assigned')}</td>
                    {this.props.status ? (
                        <td className='d-inline-flex'>
                        <button className='gv-table-btn ml-5'>
                            <img src={process.env.PUBLIC_URL + "/assets/img/download.svg"} height={17} width={16}
                            onClick={this.props.exportQRCodeDetails.bind(this, dataArray.id, dataArray.qrId)} />
                        </button>
                        <button className='gv-table-btn'>
                            <img src={process.env.PUBLIC_URL + "/assets/img/edit.svg"} height={16} width={16}
                                onClick={
                                    // () => this.props.handleEditingVoucher(dataArray.qrId, dataArray.id, dataArray.guestUserName, dataArray.siteName)
                                    () => this.props.handleEditingVoucher(dataArray)
                                    } />
                        </button>
                        <button className='gv-table-btn'>
                            <img src={process.env.PUBLIC_URL + "/assets/img/delete.svg"} height={16} width={16}
                                onClick={this.props.handleDeactivateVoucher.bind(this,this.props.status, dataArray.id)} />
                        </button>
                    </td>
                    ) : (
                        <td>
                        <button className='gv-table-btn'>
                            <img src={process.env.PUBLIC_URL + "/assets/img/undo.svg"} 
                            height={25} width={25}
                            onClick={this.props.handleActivateVoucherPopup.bind(this,this.props.status, dataArray.id)} />
                        </button>
                    </td>
                    )}
                    
                </tr>
            )
        });

        return (
            <main className="content-div smpage">
                <p>{t("guest_vouchers")}</p>
                <div className="page-outerdiv" id="charging_station">
                    <div className="row mb-0 pb-0">
                        <div className="col-md-7 mb-0 pb-0" >
                            <div className="row">
                                <div className='col-md-1 mr-4'>
                                    <button className={this.props.activeTab === 'Active' ? 'gv-active' : 'gv-inactive'}
                                        onClick={this.props.handleActiveTab.bind(this, 'Active',true)}>{t('active')}</button>
                                </div>
                                <div className='col-md-1'>
                                    <button className={this.props.activeTab === 'Inactive' ? 'gv-active' : 'gv-inactive'}
                                        onClick={this.props.handleActiveTab.bind(this, 'Inactive',false)}>{t('inactive')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 mb-0 pb-0 d-flex justify-content-end" >

                            <button
                                className="gv-refresh-btn mr-4 ml-5 mb-2"
                                type="button"
                                data-test="button"
                                onClick={this.props.handleRefreshButton}
                            >
                                {/* <i className="fas fa-refresh fa-sm mr-2"></i> */}
                                <img src={process.env.PUBLIC_URL + "/assets/img/gv_refresh.svg"} className="gv-refresh-btn-icon" />
                                {t('refreshBtn')}
                            </button>

                            <button
                                className="create-guest-btn mr-0 mb-2"
                                type="button"
                                onClick={this.props.handleCreateNewGVBtn}
                            >
                                <img src={process.env.PUBLIC_URL + "/assets/img/gv_plus.svg"} style={{ height: '18px', width: '18px' }} />
                                <span style={{ marginLeft: '18px' }}>{t('create_new_guest_voucher')}</span>
                            </button>
                        </div>
                    </div>
                    <hr className='gv-hr' />

                    <div class="row mb-4" style={{ alignItems: "center" }}>

                        <div className='col-md-9'>
                            <div className='row'>
                                <div class="col-md-4 pr-0">
                                    <CustomFilterDropdown
                                        iconSrc={process.env.PUBLIC_URL + "/assets/img/qr_code.svg"}
                                        placeholder={t("search_for_voucher_id")}
                                        headerText={t("voucher_id")}
                                        option={this.props.filterVoucherList}
                                        toggleDropdown={() => this.props.toggleDropdown("isOpen1")}
                                        handleCheckboxChange={this.props.handleCheckboxChange}
                                        handleSubmit={() => this.props.handleSubmit("isOpen1")}
                                        isOpen={this.props.isOpen1}
                                        selectedOptions={this.props.selectedOptions}
                                        searchValue={this.props.searchValue}
                                        emptySearchValues={this.props.emptySearchValues}
                                        handleSearchChange={this.props.handleSearchChange.bind( this, "filterVoucherList" )}
                                        errorsMsg={this.props.errors["nodatafound"]}
                                        category={"voucherID"}
                                        cssClass="chargeStationIcon"
                                        isApplyButtonDisabled={this.props.activeApplyBtn}
                                        getFilterHistory={this.props.getFilterHistory}
                                        showHistory={true}
                                        inputType="checkbox"
                                    />
                                </div>
                                <div className="col-md-4 pr-0">
                                    <CustomFilterDropdown
                                        iconSrc={process.env.PUBLIC_URL + "/assets/img/group_sharing.svg"}
                                        placeholder={t("search_for_group")}
                                        headerText={t("group")}
                                        option={this.props.filterGroupList}
                                        toggleDropdown={() => this.props.toggleDropdown("isOpen2")}
                                        handleCheckboxChange={this.props.handleCheckboxChange}
                                        handleSubmit={() => this.props.handleSubmit("isOpen2")}
                                        isOpen={this.props.isOpen2}
                                        selectedOptions={this.props.selectedOptions}
                                        searchValue={this.props.searchGroupValue}
                                        emptySearchValues={this.props.emptySearchValues}
                                        handleSearchChange={this.props.handleGroupSearchChange.bind( this, "filterGroupList" )}
                                        errorsMsg={this.props.errors["nodatafound"]}
                                        category={"group"}
                                        cssClass="chargeStationIcon"
                                        isApplyButtonDisabled={this.props.activeApplyBtn}
                                        getFilterHistory={this.props.getFilterHistory}
                                        showHistory={true}
                                        type = "filterGroupList"
                                        filterGroupListName={this.props.filterGroupListName}
                                        inputType="checkbox"
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='col-md-3'>
                            <div className="lastdiv">
                                <div
                                    className="pull-right show_entries"
                                    style={{ display: "flex" }}
                                >
                                    <label className="sub-text">{t("show_entries")}</label>
                                    <Select
                                        className="react-select-container"
                                        defaultValue={this.props.selectedOption}
                                        onChange={this.props.showEntriesChange}
                                        options={options}
                                        isSearchable={false}
                                        placeholder="10"
                                        styles={colourStyles}
                                        components={{ DropdownIndicator }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="selecteditems" style={{ flexWrap: "wrap" }}>

                                {Object.keys(this.props.selectedValues).map((category) =>
                                    this.props.selectedValues[category].map((val, index) => (
                                        <>
                                            <button className="icon-button mr-3 mb-3" key={index}>
                                                <img
                                                    src={
                                                        category === "voucherID"
                                                            ? process.env.PUBLIC_URL + "/assets/img/qr_code.svg"
                                                            :
                                                            process.env.PUBLIC_URL + "/assets/img/group_sharing.svg"

                                                    }
                                                    width={
                                                        category === "voucherID"
                                                            ? 30
                                                            : 34
                                                    }
                                                    className="icon pr-2"
                                                    alt={val}
                                                />
                                                <span className="name">
                                                    {category === "group"
                                                        ? this.props.checkIdGetName(val, "selectedValues")
                                                        : val}
                                                </span>
                                                <span
                                                    className="close-icon"
                                                    onClick={() =>
                                                        this.props.removeSelectedValue(category, val)
                                                    }
                                                ></span>
                                            </button>
                                        </>
                                    ))
                                )}

                                {(this.props.selectedValues.voucherID.length !== 0 ||
                                    this.props.selectedValues.group.length !== 0) && (
                                        <div className="mr-2">
                                            <button
                                                type="button"
                                                data-test="button"
                                                aria-label="Close"
                                                className="clearall-btn"
                                                onClick={this.props.removeAllSelected}
                                            >
                                                <span aria-hidden="true">×</span> {t("clear_all")}
                                            </button>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.props.showLoder === true && (
                            <div className="page_loader center" id="loader_image_div">
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"}
                                    width="80"
                                    height="80"
                                    alt=""
                                />
                            </div>
                        )}
                    </div>
                    {/* end search bar div */}

                    {/* table start */}
                    {this.props.activeTab === 'Active' &&
                        <div className="row">
                            <div className="col-md-12">
                                <Table striped hover size="sm" className="page-table responsive">
                                    <thead>
                                        <tr>
                                        <th className='gv-table-th'>{t('Voucher ID')}</th>
                                        <th className='gv-table-th'>{t('Group')}</th>
                                        <th className='gv-table-th'>{t('Energy balance')}</th>
                                        <th className='gv-table-th'>{t('Energy limitation')}</th>
                                        <th className='gv-table-th'>{t('Consumed')}</th>
                                        <th className='gv-table-th'>{t('Expiration date')}</th>
                                        <th className='gv-table-th'>{t('Guest name')}</th>
                                        <th className='gv-table-th'>{t('Action')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                        
                                    { data }
                                    {
                                        (this.props.QRCodeMangData === undefined || this.props.QRCodeMangData?.length === 0) ? <td colspan="12" class="text-center pt-2 pb-1 pl-0"><span>{t('no_data_found')}</span>
                                        </td> : null
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    }

                    {this.props.activeTab === 'Inactive' &&
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <Table striped hover size="sm" className="page-table responsive">
                                    <thead>
                                        <tr>
                                        <th className='gv-table-th'>{t('Voucher ID')}</th>
                                        <th className='gv-table-th'>{t('Group')}</th>
                                        <th className='gv-table-th'>{t('Energy balance')}</th>
                                        <th className='gv-table-th'>{t('Energy limitation')}</th>
                                        <th className='gv-table-th'>{t('Consumed')}</th>
                                        <th className='gv-table-th'>{t('Expiration date')}</th>
                                        <th className='gv-table-th'>{t('Guest name')}</th>
                                        <th className='gv-table-th'>{t('Action')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                        
                                    { data }
                                    {
                                        (this.props.QRCodeMangData === undefined || this.props.QRCodeMangData?.length === 0) ? <td colspan="12" class="text-center pt-2 pb-1 pl-0"><span>No data found</span>
                                        </td> : null
                                    }
                                    {/* {
                                        <td colSpan={12} className='text-center pt-5 pb-5'>{this.props.errors["nodatafound"]}</td>
                                    } */}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-12">
                                <Table striped hover size="sm" className="page-table responsive">
                                    <thead>
                                        <tr >
                                            {this.props.tableHeaders.map((tableItem, index) => {
                                                return <th className='gv-table-th'>{tableItem}</th>;
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.isDataAvailable ? this.props.tempvoucherList.map((eachObj, index) => {
                                            return (
                                                <tr key={eachObj.voucherID}>
                                                    <td className='gv-table-td' style={{ textDecoration: 'underline' }}>
                                                        <Link to={{ pathname: '/viewTag', props: '4TbTOgiyWsrIvCt310qd' }}>
                                                            {eachObj.voucherID}
                                                        </Link>
                                                    </td>
                                                    <td className='gv-table-td'>{eachObj.group}</td>
                                                    <td className='gv-table-td'>{eachObj.energyBalance} kWh</td>
                                                    <td className='gv-table-td'>{eachObj.energyLimitation} kWh</td>
                                                    <td style={{ color: eachObj.consumed >= 90 ? 'red' : eachObj.consumed >= 25 ? 'orange' : 'green', fontWeight: '600' }}>
                                                        {eachObj.consumed}%
                                                    </td>
                                                    <td className='gv-table-td'>{eachObj.expirationData}</td>
                                                    <td className='gv-table-td'>{eachObj.guestName ? eachObj.guestName : t('not_assigned')}</td>
                                                    <td>
                                                        <button className='gv-table-btn'>
                                                            <img src={process.env.PUBLIC_URL + "/assets/img/undo.svg"} height={25} width={25} alt=''
                                                                onClick={this.handleActivateVoucherPopup}
                                                            />
                                                        </button>
                                                    </td>

                                                    // already commented code
                                                    <td>
                                                        <button
                                                            onClick={this.openOverViewPopup.bind(this, eachObj.chargePointId,eachObj.model)}
                                                            style={{
                                                            border: "none",
                                                            backgroundColor: "transparent",
                                                            }}
                                                        >
                                                            <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/assets/img/svg/edit.svg"
                                                            }
                                                            style={{ marginRight: 3, width: 25 }}
                                                            />
                                                        </button>
                                                        </td>
                                                </tr>
                                            );
                                        }) : <td colSpan={8} style={{ textAlign: "center" }}>{t('no_data_available')}</td>}
                                    </tbody>
                                </Table>
                            </div>
                        </div> */}
                        </>
                    }
                    {this.props.isDataAvailable && <div className="row">
                        <div className="col-md-12 mt-2 mb-4 text-right">
                            <Pagination
                                activePage={this.props.activePage}
                                itemsCountPerPage={this.props.pageSize}
                                totalItemsCount={this.props.noOfRecords}
                                onChange={this.props.handlePageChange}
                                prevPageText={t('prev')}
                                nextPageText={t('next')}
                                itemClassPrev="prevBtn"
                                pageRangeDisplayed="1"
                                activeClass="activeLi active"
                                itemClassNext="nextBtn"
                                hideFirstLastPages={true}
                                disableInitialCallback={true}
                                disabledClass="disabled disabledtextColor"
                            />
                        </div>
                    </div>}
                </div>

                {/* Loader */}
                <div
                    className="page_loader center"
                    id="loader_image_div"
                    style={{ display: "none" }}
                >
                    <img
                        src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"}
                        width="80"
                        height="80"
                        alt=""
                    />
                </div>

                {/* table content popup success  */}
                {/* <MDBModal
                isOpen={this.props.successTbContent}
                toggle={this.successTableContent}
                size="md"
                className="model_top deleteReportModal mw-450"
              >
                <MDBModalBody className="p-4">
                  <div className="pt-2">
                    <div className="popupRightClose mb-3">
                      <img
                        src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"}
                        className="pull-right closeIcon"
                        onClick={() => {
                          this.successTableContent.bind(this);
                          window.location.reload();
                        }}
                      />
                    </div>
                  </div>
                  <p className="text-center fontMedium pb-2 pt-4">
                    {t("table_saved_successfully")}
                  </p>
                </MDBModalBody>
                <div className="confirmdelete pb-5">
                  <button
                    className="ltblueBtn w-170 mb-3"
                    onClick={() => {
                      this.successTableContent.bind(this);
                      window.location.reload();
                    }}
                  >
                    {t("Ok")}
                  </button>
                </div>
              </MDBModal> */}

                <MDBModal isOpen={this.props.isCreateOrEditGVPopupOpen} size="lg" className="model__top mw-720 gvcreate_popup">
                    <MDBModalBody>
                        <div className='gv-popup p-2'>
                            {this.props.userAction === 'EditingGV' ? <h1 className='gv-createpopup-heading mt-3'>{t('editing_the_guest_voucher')}</h1> :
                                <h1 className='gv-createpopup-heading mt-3'>{t('Creating_the_guest_voucher')}</h1>}
                            <div className='gv-popup-block'>
                                {this.props.userAction === 'EditingGV' &&
                                    <>
                                        <p className='gv-createpopup-p mb-3 mt-2'>ID: {this.props.editVoucherID}</p>
                                        <hr className='gv-popup-hr' />
                                        <p className='gv-createpopup-p'>{t('assign_voucher_to_guest')}</p>
                                        <label class="gv-switch mr-3" style={{ float: 'left' }}>
                                            <input type="checkbox"
                                                checked={this.props.isVoucherAssignToGuestActivated}
                                                onChange={this.props.handleOnChangeVoucherGuest}
                                            />
                                            <span class="gv-slider round"></span>
                                        </label>
                                        <p className='gv-createpopup-p1'>{t('if_activate_voucher_marked_with_name')}</p>
                                        <div className="mb-4">
                                            {this.props.isVoucherAssignToGuestActivated && (
                                                <>
                                                    <div class="md-form input-group input-group-sm mb-1 w-40 ml-2">
                                                        <span className='gv-createpopup-p1 mt-3 ml-2 mr-2'>{t('guest_name')}</span>
                                                        <input type="text"
                                                            // value={this.props.guestName}
                                                            defaultValue={this.props.guestName}
                                                            onChange={this.props.handleOnChangeGuestName}
                                                            class="form-control gv-popup-input"
                                                            placeholder={t('enter_name')}
                                                            spellCheck={false}
                                                            maxlength="21"
                                                            aria-label="Sizing example input"
                                                            aria-describedby="inputGroupMaterial-sizing-sm" />
                                                    </div>
                                                    <span className="error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.props.errors["guestNameLimiter"]}</span></span>
                                                </>
                                            )}
                                        </div>
                                        <hr className='gv-popup-hr' />
                                    </>
                                }

                                {this.props.userAction === 'EditingGV' ? 
                                <><p className='gv-createpopup-p'>{t('associated_group')}</p><div className='d-flex'>
                                        <div className="col-md-5">
                                            <div className="selecteditems">
                                                <button className="icon-button mt-1">
                                                    <img src={process.env.PUBLIC_URL + "/assets/img/group_sharing.svg"} width="32" className="icon pr-2" />
                                                    <span className="name">{this.props.siteName}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div></>
                                 : 
                                <><p className='gv-createpopup-p'>{t('select_appropriate_group')}</p><div className='d-flex'>
                                        <div className='col-md-5 p-0 mb-3'>
                                            <CustomFilterDropdown
                                                iconSrc={process.env.PUBLIC_URL + "/assets/img/group_sharing.svg"}
                                                placeholder={t("search_for_group")}
                                                headerText={t("group")}
                                                option={this.props.filterPopupGroupList}
                                                toggleDropdown={() => this.props.toggleDropdown("isOpen3")}
                                                handleCheckboxChange={this.props.handlePopupCheckboxChange}
                                                handleSubmit={() => this.props.handlePopupSubmit("isOpen3")}
                                                isOpen={this.props.isOpen3}
                                                selectedOptions={this.props.selectedPopupOptions}
                                                searchValue={this.props.searchPopupValue}
                                                emptySearchValues={this.props.emptySearchValues}
                                                handleSearchChange={this.props.handlePopupSearchChange.bind(this, "filterPopupGroupList")}
                                                errorsMsg={this.props.errors["nodatafound"]}
                                                category={"group"}
                                                cssClass="chargeStationIcon"
                                                isApplyButtonDisabled={this.props.activePopupApplyBtn}
                                                getFilterHistory={this.props.getFilterHistory}
                                                showHistory={false}
                                                type="filterGroupList"
                                                filterGroupListName={this.props.filterGroupListName}
                                                inputType="radio" />
                                            <span className="error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.props.errors["gvpopupgrouperror"]}</span></span>
                                        </div>

                                        <div className="col-md-5">
                                            <div className="selecteditems">
                                                {(this.props.selectedPopupValues.group).map((val, index) => (
                                                    (index === 0) ? <button className="icon-button mt-1">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/group_sharing.svg"} width="32" className="icon pr-2" alt={val} />
                                                        <span className="name">{this.props.checkIdGetName(val)}</span>
                                                        {/* <span className="name">{(val?.length >= 21) ? val?.substring(0, 17) + "..." : val}</span> */}
                                                        <span className="close-icon" onClick={() => this.props.removeSelectedValueForPopup("group", val)}></span>
                                                    </button> : ""
                                                ))}
                                            </div>
                                            <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.props.errors["optionsErr"]}</span></span>
                                        </div>

                                        {/* <div className="col-md-4 p-0">
                                        {(this.props.selectedPopupValues.group?.length > 1) && (
                                            <div>
                                                <button type="button" className="morebutton mt-1 mr-5" onClick={() => this.setprops({ moremodalGroup: !this.props.moremodalGroup })}>{(this.props.selectedPopupValues.group?.length - 1) + "+"} {t('More')} <span class="caret-icon-w"></span></button>
                                            </div>
                                        )}
                                        <div>
                                            {(this.props.moremodalGroup) && (this.props.selectedPopupValues.group?.length >= 2) && (<>
                                                <div className='finaldatapopup'>
                                                    <div className="selecteditems">
                                                        {this.props.selectedPopupValues.group.slice(1, this.props.selectedPopupValues.group.length)?.map((value, index) => (
                                                            <button class="icon-button mb-2">
                                                                <img src={process.env.PUBLIC_URL + "/assets/img/group_sharing.svg"} width="32" className="icon pr-2" />
                                                                <span className="name">{(value?.length >= 21) ? value?.substring(0, 17) + "..." : value}</span>
                                                                <span class="close-icon" onClick={() => this.props.removeSelectedValueForPopup("group", value)}></span>
                                                            </button>
                                                        )
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                            )}
                                        </div>
                                    </div> */}
                                    </div></>
                                }

                                <div className='mt-5 pt-3'>
                                    <hr className='gv-popup-hr' />
                                </div>

                                <p className='gv-createpopup-p'>{t('set_energy_limitation')}</p>
                                <label class="gv-switch mr-3" style={{ float: 'left' }}>
                                    <input type="checkbox"
                                        checked={this.props.isEnergyLimitActivated}
                                        onChange={this.props.handleOnChangeEnergyLimit}
                                    />
                                    <span class="gv-slider round"></span>
                                </label>
                                <p className='gv-createpopup-p1'>{t('if_activate_energy_limited_to_defined_amount')}</p>
                                <div className="mb-4">
                                    {this.props.isEnergyLimitActivated && (
                                        <>
                                            <div class="md-form input-group input-group-sm mb-1 w-40 ml-2">
                                                <input type="text"
                                                    value={this.props.energyLimitValue}
                                                    className="form-control"
                                                    aria-label="Sizing example input"
                                                    aria-describedby="inputGroupMaterial-sizing-sm"
                                                    onChange={this.props.onChangeLimitEnergyusageValue}
                                                    onKeyDown={this.props.handleEnergyLimit}
                                                    pattern="[0-9]*"
                                                />
                                                <span className='gv-createpopup-p1 mt-3 ml-2'>kWh</span>
                                            </div>
                                            <span className="error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.props.errors["energyLimiterr"]}</span></span>
                                        </>
                                    )}
                                </div>
                                <hr className='gv-popup-hr' />
                                <p className='gv-createpopup-p'>{t('set_peroid_of_use')}</p>
                                <label class="gv-switch mr-3" style={{ float: 'left' }}>
                                    <input type="checkbox"
                                        checked={this.props.isPeriodOfDaysActivated}
                                        onChange={this.props.handleOnChangePeriodOfDays}
                                    />
                                    <span class="gv-slider round"></span>
                                </label>
                                <p className='gv-createpopup-p1'>{t('if_activate_voucher_valid_for_certain_days')}
                                    <br />
                                    {t('period_starts_with_first_charging_session')}
                                </p>
                                <div className="mb-4">
                                    {this.props.isPeriodOfDaysActivated && (
                                        <>
                                            <div class="md-form input-group input-group-sm mb-1 w-40 ml-2">
                                                <input type="text"
                                                    value={this.props.ExpiryInDaysvalue}
                                                    class="form-control"
                                                    aria-label="Sizing example input"
                                                    aria-describedby="inputGroupMaterial-sizing-sm"
                                                    onChange={this.props.onChangeLimitPeriodOfUseValue}
                                                    onKeyDown={this.props.handlePeriodOfUse}
                                                    pattern="[0-9]*"
                                                />
                                                <span className='gv-createpopup-p1 mt-3 ml-2'>{t('days')}</span>
                                            </div>
                                            <span className="error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.props.errors["daysLimiterr"]}</span></span>
                                        </>
                                    )}
                                </div>
                                {this.props.userAction === 'CreatingNewGV' &&
                                    <>
                                        <hr className='gv-popup-hr' />

                                        <p className='gv-createpopup-p'>{t('set_no_of_duplicate_vouchers')}</p>
                                        <div className="mb-4 ml-2">

                                            <div class="md-form input-group input-group-sm w-40">
                                                <input type="text"
                                                    value={this.props.noOfDuplicateVouchers}
                                                    onChange={this.props.onChangeDuplicateVouchers}
                                                    class="form-control"
                                                    aria-label="Sizing example input"
                                                    aria-describedby="inputGroupMaterial-sizing-sm"
                                                    pattern="[0-9]*"
                                                     />
                                                <span className='gv-createpopup-p1 mt-3 ml-2'>pc.</span>
                                            </div>
                                            <div className="error_msg w3-animate-top" style={{ color: "red" }}>{this.props.errors["duplicateQRCodeerr"]}</div>
                                        </div></>

                                }
                            </div>
                            <div className='mb-3 d-flex justify-content-center'>
                                {this.props.userAction === "CreatingNewGV" ?
                                    <button className="blueBtnNew createReportBtn w-50" onClick={this.props.handleCreateNewGV}> {t('continue')}</button>
                                    :
                                    <button className="blueBtnNew createReportBtn w-50" onClick={this.props.handleEditGV}> {t('save')}</button>
                                }
                                <button className="ltblueBtn createReportBtn ml-3 w-50" onClick={this.props.closeGVPopUp}> {t('close')}</button>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModal>

                <ConfirmationPopup
                    open={this.props.isDeactivatePopupOpen}
                    toggleOperation={this.props.closeDeactivatePopup}
                    title={t('deactivate_voucher')}
                    content={`${t('are_you_sure_want_deactivate_voucher')} ${t('the_process_can_undone_in_inactive_tab')}`}
                    btnImage={process.env.PUBLIC_URL + "/assets/img/deactive.svg"}
                    btnText={t("yes_deactivate")}
                    btnBgColor='#FFD3D8'
                    onClickOperation={this.props.SuccessDeativatePopup}
                />

                 <ConfirmationPopup
                    open={this.props.warningModel}
                    toggleOperation={this.props.warningPopup}
                    title={t('')}
                    content={this.props.warningMsg}
                    btnImage={process.env.PUBLIC_URL + "/assets/img/deactive.svg"}
                    btnText={t("close")}
                    btnBgColor='#FFD3D8'
                    onClickOperation={this.props.warningPopup}
                />

                <SuccessPopup
                    open={this.props.isSuccessDeactivatePopupOpen}
                    image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                    content={t('the_voucher_successfully_deactivated')}
                    btnText={t("close")}
                    onClickOperation={this.props.closeSuccessDeativatePopup}
                />

                {/* <MDBModal isOpen={this.props.isActivateVoucherPopupOpen} toggle={this.closeActivateVoucherPopup} size='md' className="model_top">
                <MDBModalBody>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                <h5 className='gv-deactivate-h5'>{t('activate_voucher')}</h5>
                <p className='gv-deactivate-p'>{t('are_you_sure_want_activate_voucher')} 
                </p>
                <button
                  className="gv-deactivate-btn"
                  type="button"
                onClick={this.SuccessAtivateVoucherPopup}
                  style={{backgroundColor : '#CAF2C2'}}
                >
                    <img src={process.env.PUBLIC_URL + "/assets/img/undo_popup.svg"} height={22} width={22} alt='' className='mr-2'/>
                  {t("yes_activate")}
                </button>
              </div>
                </MDBModalBody>
            </MDBModal> */}

                <ConfirmationPopup
                    open={this.props.isActivateVoucherPopupOpen}
                    toggleOperation={this.props.closeActivateVoucherPopup}
                    title={t('activate_voucher')}
                    content={t('are_you_sure_want_activate_voucher')}
                    btnImage={process.env.PUBLIC_URL + "/assets/img/undo_popup.svg"}
                    btnText={t("yes_activate")}
                    btnBgColor='#CAF2C2'
                    onClickOperation={this.props.SuccessAtivateVoucherPopup}
                />

                <SuccessPopup
                    open={this.props.isSuccessActivatePopupOpen}
                    image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                    content={t('the_voucher_successfully_activated')}
                    btnText={t("close")}
                    onClickOperation={this.props.closeSuccessActivateVoucherPopup}
                />

                <SuccessPopup
                    open={this.props.isChangesUpdatedPopupOpen}
                    image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                    content={t('changes_updated_successfully')}
                    btnText={t("close")}
                    onClickOperation={this.props.closeChangesUpdatedPopup}
                />

                <MDBModal isOpen={this.props.isVoucherCreatedSuccessPopupOpen} size='md' className="model_top">
                    <MDBModalBody>
                        <div className='d-flex flex-column justify-content-center align-items-center p-2'>
                            <img src={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                                width="50"
                                height="50"
                                className='mt-2'
                                alt="" />

                            <h5 className='gv-deactivate-h5 mt-4'>{t('voucher_created_successfully')}</h5>
                            <p className='gv-deactivate-p mt-2 pl-4 pr-4 text-center'>{t('vouchers_canbe_dowloaded_edited_in_overview')} </p>
                            <div className='d-flex mb-2'>
                                <button
                                    className="blueBtnNew w-50"
                                    type="button"
                                    onClick={this.props.downloadGuestVoucher}
                                >
                                    {t("download_now")}
                                </button>
                                <button
                                    className="ltblueBtn w-50 ml-3"
                                    type="button"
                                    onClick={this.props.closeVoucherCreatedPopup}
                                >
                                    {t("close_download_later")}
                                </button>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModal>

                {/* warning popup */}  
                <MDBModal isOpen={this.props.alertModel} size='md' className="model_top">
                    <MDBModalBody>
                        <div className='d-flex flex-column justify-content-center align-items-center p-2'>
                            <h5 className='gv-deactivate-h5 mt-4'>{t('voucher_created_successfully')}</h5>
                            <p className='gv-deactivate-p mt-2 pl-4 pr-4 text-center'>{t('vouchers_canbe_dowloaded_edited_in_overview')} </p>
                            <div className='d-flex mb-2'>
                                <button
                                    className="blueBtnNew w-50"
                                    type="button"
                                    onClick={this.props.downloadGuestVoucher}
                                >
                                    {t("download_now")}
                                </button>
                                <button
                                    className="ltblueBtn w-50 ml-3"
                                    type="button"
                                    onClick={this.props.closeVoucherCreatedPopup}
                                >
                                    {t("close_download_later")}
                                </button>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModal>         
                
                {/* <MDBModal isOpen={this.props.alertModel} size="md" className="model-top">
                    <MDBModalBody className='text-center'>
                        <p className='esim-popup-para'>
                            {t(this.props.alertMsg)}
                        </p>
                        <button className='esim-popup-btn' onClick={this.props.toggleAlert}>
                            {t('close')}
                        </button>
                    </MDBModalBody>
                </MDBModal> */}

                {this.props.isLoading ? (
                    <Loader loader={this.props.isLoading} />
                ) : ("")}

            </main>
        )
    }
}

export default withTranslation()(GuestVouchersTemplate);