import React from 'react';
import { MDBRow, MDBCol, MDBInput } from 'mdbreact';
import apiCallUtility from '../common/APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class dataTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpList: [],
      fields: {
        vendorId: "",
        messageId: "",
        data: ""
      },
      sucess: false,
      colour: 'sucess',
      errors: {},
      chargePoint: '',
      operationId: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectedCP = this.getSelectedCP.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  async handleSubmit(event) {
    var payload = this.getPayLoad();
    var errors = {};
    var vendorId = payload.vendorId;

    if (this.getCPList().length === 0) {
      errors["emptyChargePoint"] = this.props.t('select_charge_point')
    }

    if (vendorId === '') {
      errors["emptyvendorId"] = this.props.t('enter_vendor_id')
    }

    if (this.getCPList().length !== 0 && vendorId !== '') {
      var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList(), this.props.t);

      if (response === this.props.t('unable_toprocess_request_try_after_some_time')) {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, () => {
          this.onShowAlert();
        })
      } else if (response === 'Request is processed Successfully') {
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, () => {
          this.onShowAlert();
        })
      }
    }

    this.setState({ errors: errors })
  }

  getCPList() {
    var Id = this.state.operationId;
    var cplist = "";

    if (Id !== null && Id !== "") {
      cplist = Id.split();
    } else {
      cplist = this.state.cpList;
    }

    return cplist;
  }

  getPayLoad() {
    var payload = {
      "vendorId": this.state.fields["vendorId"],
      "messageId": this.state.fields["messageId"],
      "data": this.state.fields["data"]
    }

    return payload;
  }

  getMessageType() {
    return constants.DATA_TRANSFOR;
  }

  changeHandler(field, e) {
    let errors = this.state.errors;
    let fields = this.state.fields;
    fields[field] = e.target.value;

    if (fields[field] !== 0) {
      errors["emptyvendorId"] = "";
    }

    this.setState({ fields, errors });
  }

  getSelectedCP(cpList) {
    let errors = this.state.errors;
    this.setState({ cpList: cpList }, () => {
      if (cpList !== "") {
        errors["emptyChargePoint"] = "";
        this.setState({ errors });
      }
    });
  }

  componentWillUnmount() {
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }

  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata !== undefined) {
      if (propsdata.chargePoint !== undefined && propsdata.operationId !== undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <main className="content-div" >
        <p> {t('Data Transfer')}
          <div className="breadcrumb_div">
            {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Data Transfer')}</span>
          </div>
        </p>
        <div className="page-outerdiv">
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
          <MDBRow className="mb-4">
            <MDBCol sm="12">
              <div>
                <form className="needs-validation">
                  <MDBRow>
                    <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="DataTransfer" ></ChargePointSelection>
                    <MDBCol md="12">
                      <span className="selection_charge_point_error w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptyChargePoint"]}</span></span>
                    </MDBCol>
                    <MDBCol md="4">
                      <MDBInput
                        name="chargebox"
                        onChange={this.changeHandler.bind(this, "vendorId")}
                        type="text"
                        label={t('vendor_id_string') + " *"}
                        required
                      >
                        <span className="error_msg w3-animate-top">
                          <span style={{ color: "red" }}>{this.state.errors["emptyvendorId"]}</span>
                        </span>
                      </MDBInput>
                    </MDBCol>

                    <MDBCol md="4">
                      <MDBInput
                        name="chargebox"
                        onChange={this.changeHandler.bind(this, "messageId")}
                        type="text"
                        id="availabilityType"
                        label={t('message_id_string')}
                        required
                      />
                    </MDBCol>
                    <MDBCol md="4">
                      <MDBInput
                        name="chargebox"
                        onChange={this.changeHandler.bind(this, "data")}
                        type="text"
                        label={t('data_text')}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  <button type="button" className="mt-5 refresh-btn" onClick={this.handleSubmit}>{t('perform')}</button>
                </form>
              </div>
            </MDBCol>

          </MDBRow>
        </div>

      </main>
    );
  }
}

export default withTranslation()(dataTransfer);