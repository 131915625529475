import React from 'react';

class QRCodeDeactivated extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.backButton = this.backButton.bind(this)
    }


    backButton() {
        var page = localStorage.getItem('prePage');
        this.props.setPage(page);
        localStorage.setItem('page', page);
    }

    handleSubmit() {
        var page = localStorage.getItem('prePage');
        this.props.setPage(page);
        localStorage.setItem('page', page);
    }

    render() {
        return (
            <>
               <div className="welcome-parent bg_white">
                  <div className="container-fluid">
                         <nav className="nav_bar">
                            <span className="navbar-brand" >
                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/close.svg"} className="img-fluid rounded-start" alt="left-aarow" onClick={this.backButton.bind(this)} />
                            </span>
                            {/* <span className="navbar-brand margin-auto">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/logo.svg"} style={{width: '46px'}} alt="logo" />
                            </span>
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <span><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/germany.svg"} style={{ width: "32px" }} className="d-inline-block align-top logo" alt="germany" /></span> &nbsp;
                                    <span><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/english.svg"} style={{ width: "32px" }} className="d-inline-block align-top logo" alt="english" /></span>
                                </li>
                            </ul> */}
                        </nav>
                        <div className='row justify-content-center'>
                            <div className="content-div pl-0 pr-0">
                                <div className='card-body d-flex flex-column justify-content-center' style={{ padding: '1rem 2rem' }}>
                                    <div className="mx-auto text-center">
                                        <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/error_icon.svg"} style={{ width: "200px"}} className="img-fluid rounded-start chargeicon pb-4" alt="error-logo" />
                                        {/* <div className="card-text" style={{paddingBottom: '20px', color: '#757D86'}}>QR Code is deactivated, Please reach out to the issuer of the QR Code.</div> */}
                                        <h5 className="invalid-txt dblue-title" style={{ paddingBottom: '20px' }}>Something went Wrong!</h5>
                                        <div className="footer-blue">The charging session could not be started. If this problem persists, please contact the issuer of this voucher.</div>
                                    </div>
                                    <button type="button" className='btn qr-btn' onClick={this.handleSubmit.bind(this)}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default QRCodeDeactivated;