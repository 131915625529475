import React from 'react';
import { withTranslation } from 'react-i18next';
import EditOTAManagementTemplate from '../Screens/editOTAManagementTemplate';

//API Imports
import * as baseUrl from '../views/constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((item, index) => {
        item.sequence = index
    })
    return result;
};


class EditOTAManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: 1,
            addFirmware: false,
            version: "",
            errors: {},
            builds: [],
            user: { permissions: [] },
            isRollOutEnabled: false,
            sequentialEnabled: false,
            modelId: "",
            modelName: "",
            confirmSquentialUpdate: false,
            successSquentialUpdate: false,
            sequenceUpdateSuccessMessage: "",
            file: "",
            releaseNotes: "",
            firmwareUploadSuccess: false,
            ReOrderSuccess: false,
            editFirmware: false,
            editVersion: "",
            editFile: "",
            editFile1: "",
            editReleaseNote: "",
            editReleaseNote1: "",
            buildId: "",
            firmwareUpdateSuccess: false,
            confirmDeletebuild: false,
            deleteBuildVersion: '',
            deleteText: '',
            deleteAlert: false,
            deleteStatus: "",
            deleteStatusMessage: "",
            disabledAttr:true,
            confirmOrderSeq:false,
            disabledAttr1:false,
            loading: false,
        }
        this.tabToggle = this.tabToggle.bind(this);
        this.getBuilds = this.getBuilds.bind(this);
        this.openAddTriggerModal = this.openAddTriggerModal.bind(this);
        this.closeAddTriggerModal = this.closeAddTriggerModal.bind(this);
        this.backToList = this.backToList.bind(this);
        this.openConfirmTriggerSequenceUpdate = this.openConfirmTriggerSequenceUpdate.bind(this);
        this.updateFirmwareSequence = this.updateFirmwareSequence.bind(this);
        this.toggleSuccessSquentialUpdate = this.toggleSuccessSquentialUpdate.bind(this);
        this.onChangeVersion = this.onChangeVersion.bind(this);
        this.addBuild = this.addBuild.bind(this);
        this.fileChange = this.fileChange.bind(this);
        this.releaseChange = this.releaseChange.bind(this);
        this.toggleSuccessFirmwareUpload = this.toggleSuccessFirmwareUpload.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.saveSequence = this.saveSequence.bind(this);
        this.toggleReOrderSuc = this.toggleReOrderSuc.bind(this);
        this.openEditFirmware = this.openEditFirmware.bind(this);
        this.onChangeVersion1 = this.onChangeVersion1.bind(this);
        this.fileChange1 = this.fileChange1.bind(this);
        this.releaseChange1 = this.releaseChange1.bind(this);
        this.closeEditFirmware = this.closeEditFirmware.bind(this);
        this.toggleSuccessFirmwareUpdate = this.toggleSuccessFirmwareUpdate.bind(this);
        this.updateBuild = this.updateBuild.bind(this);
        this.toggleConfirmDelete = this.toggleConfirmDelete.bind(this);
        this.onChangeDeleteText = this.onChangeDeleteText.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteBuild = this.deleteBuild.bind(this);
        this.toggleDeleteAlert = this.toggleDeleteAlert.bind(this);
        this.openConfirmOrderSeq = this.openConfirmOrderSeq.bind(this)
        this.closeSuccessFirmwareUpdate = this.closeSuccessFirmwareUpdate.bind(this)
    }

    onChangeDeleteText(e){
        this.setState({ deleteText: e.target.value, errors: {} })
    }

    toggleConfirmDelete(version, build_id) {
        this.setState({ confirmDeletebuild: !this.state.confirmDeletebuild, deleteBuildVersion: version, buildId: build_id });
    }

    closeDeleteModal(){
        this.setState({ confirmDeletebuild: !this.state.confirmDeletebuild, deleteText: '', errors: {} });
    }

    componentDidMount() {
        var data = {};
        let modelId = "";
        let modelName = "";

        if (this.props.location.state === null) {
            data = JSON.parse(localStorage.getItem("otaModelId"));
            modelId = data?.modelId;
            modelName = data?.modelName;
        } else {
            modelId = this.props.location.state.modelId;
            modelName = this.props.location.state.modelName;
            data = { modelId: modelId, modelName: modelName };
            localStorage.setItem("otaModelId", JSON.stringify(data));
        }

        let user = { permissions: [] }

        user.permissions = localStorage.getItem("roleAccess");

        this.setState({
            user,
            modelId: modelId,
            modelName: modelName
        }, () => {
            this.getBuilds();
        });
    }

    tabToggle(tab) {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            }, () => {
                if (tab === 1) {
                    this.getBuilds();
                }
            });
        };
    }

    openAddTriggerModal() {
        this.setState({ addFirmware: !this.state.addFirmware, version: "", file: "", releaseNote: ""  })
    }

    openConfirmTriggerSequenceUpdate() {
        this.setState({ confirmSquentialUpdate: !this.state.confirmSquentialUpdate })
    }

    closeAddTriggerModal() {
        this.setState({
            addFirmware: !this.state.addFirmware,
            version: "",
            errors: {},
            file: "",
            releaseNotes: "",
        })
    }

    async getBuilds() {
        let url = baseUrl.URLPath + servicesUrl.getBuildsBasedonModel + "?modelId=" + this.state.modelId;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        this.setState({ builds: response?.data ?? [], sequentialEnabled: response?.sequentialEnabled })
    }

    backToList() {
        this.props.history.push('/otaManagement');
    }

    async updateFirmwareSequence() {
        let url = baseUrl.URLPath + servicesUrl.updateFirmwareUpdate + "?modelId=" + this.state.modelId + "&sequential=" + !this.state.sequentialEnabled;
        let type = ApiMethodTypes.PUT;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if (response?.message === "ota_model_updated_successfully") {
            this.setState({
                sequenceUpdateSuccessMessage: response?.message,
                sequentialEnabled: response?.data?.sequentialEnabled,
                successSquentialUpdate: !this.state.successSquentialUpdate,
                confirmSquentialUpdate: !this.state.confirmSquentialUpdate,
                sequentialEnabled: !this.state.sequentialEnabled
            })
        }
    }

    toggleSuccessSquentialUpdate() {
        this.setState({ loading: !this.state.loading }, () => {
            this.setState({ successSquentialUpdate: !this.state.successSquentialUpdate })
            setTimeout(() => {
                this.setState({ 
                    loading: false });
            }, 1000);         
            })
    }

    onChangeVersion(e) {
        let errors = this.state.errors;
        errors["versionError"] = "";

        this.setState({ version: e.target.value.replace(/\s/g, ''), errors });
    }

    onChangeVersion1(e) {
        let errors = this.state.errors;
        errors["versionError"] = "";

        this.setState({ editVersion: e.target.value.replace(/\s/g, ''), errors, disabledAttr1: true });
    }

    addBuild() {
        let errors = this.state.errors;
        let version = this.state.version;

        if (version === "" || version === undefined || version == null) {
            errors["versionError"] = this.props.t('enter_the_version')
        }

        if (this.state.file === "" || this.state.file === undefined) {
            errors["nofileError"] = this.props.t('select_firmware_file')
        }

        if (this.state.releaseNotes === "" || this.state.releaseNotes === undefined) {
            errors["releaseNoteError"] = this.props.t('select_release_notes')
        }

        if (this.state.file !== '' && this.state.file !== undefined && this.state.version !== "" && this.state.releaseNotes !== "" && this.state.releaseNotes !== undefined) {
            var url = baseUrl.URLPath + servicesUrl.addDashboardBuild;

            var data = new FormData();
            data.append("file", this.state.file);
            data.append("version_name", this.state.version);
            data.append("target_type_id", 0);
            data.append("release_note", this.state.releaseNotes);
            // data.append("prerequisite", prerequisiteValue);
            // data.append("target_ids", this.state.fields.targetIds);
            data.append("model", this.state.modelName);
            data.append("model_id", this.state.modelId);

            document.getElementById("loader_image_div").style.display = "block";
            fetch(url, {
                method: "POST",
                body: data,
                headers: {
                    "Authorization": localStorage.getItem("Authorization"),
                }
            })
                .then((resp) => {
                    if (resp.status === 401) {
                        window.location.reload();
                    } else if (resp.status === 201) {
                        setTimeout(() => {
                            document.getElementById("loader_image_div").style.display = "none";
                            this.setState({ addFirmware: !this.state.addFirmware, version: "", releaseNotes: "", file: ""  }, () => {
                                this.toggleSuccessFirmwareUpload();
                                this.getBuilds();
                            });
                        }, 2000);
                    } else if (resp.status === 404) {
                        return resp.json();
                    } else {
                        return resp.json();
                    }
                })
                .then((response) => {
                    if (response.status === 404) {
                        errors["versionError"] = this.props.t(response.message);
                        document.getElementById("loader_image_div").style.display = "none";
                        this.setState({ errors: errors })
                    } else {
                        if (response.status === "success") {
                            setTimeout(() => {
                                document.getElementById("loader_image_div").style.display = "none";
                                this.setState({ addFirmware: !this.state.addFirmware }, () => {
                                    this.toggleSuccessFirmwareUpload();
                                    this.getBuilds();
                                })
                            }, 2000);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error, "catch the loop")
                })
        }

        this.setState({ errors: errors });
    }

    updateBuild() {
        let errors = this.state.errors;
        let version = this.state.editVersion;

        if (version === "" || version === undefined || version == null) {
            errors["versionError"] = this.props.t('enter_the_version')
        }

        if (this.state.editVersion !== '' && this.state.editVersion !== undefined && this.state.editVersion !== null) {
            var url = baseUrl.URLPath + servicesUrl.getDashboardBuild + "?buildId=" + this.state.buildId;

            this.setState({ errors: errors })
            var data = new FormData();
            data.append('model', this.state.modelName);
            data.append("file", this.state.editFile1);
            data.append("version_name", this.state.editVersion);
            // data.append("buildId", this.state.buildId);
            data.append("release_note", this.state.editReleaseNote1);
            // data.append("prerequisite", this.state.prerequisite);

            document.getElementById("loader_image_div").style.display = "block";
            fetch(url, {
                method: "PUT",
                body: data,
                headers: {
                    "Authorization": localStorage.getItem("Authorization"),
                }
            })
                .then((resp) => {
                    if (resp.status === 201) {
                        setTimeout(() => {
                            document.getElementById("loader_image_div").style.display = "none";
                            this.setState({ editFirmware: false, editFile1: "", editReleaseNote1: ""  })
                            this.toggleSuccessFirmwareUpdate();
                        }, 2000);
                    }
                    if (resp.status === 401) {
                        window.location.reload();
                    }

                    if (resp.status === 404) {

                    }
                    if (resp.status === 409) {
                        errors["buildexist"] = this.props.t('build_already_exists_with_this_model_and_version');
                        this.setState({ errors: errors });
                        document.getElementById("loader_image_div").style.display = "none";
                    }

                    return resp.json();
                })
                .then((response) => {
                    if (response.status === 404) {
                        errors["versionError"] = this.props.t(response.message);
                        document.getElementById("loader_image_div").style.display = "none";
                        this.setState({ errors: errors })
                    }

                    this.setState({
                        errors: errors
                    });

                })
                .catch((error) => {
                    console.log(error, "catch the loop")
                })
        }

        this.setState({ errors: errors });
    }

    fileChange() {
        let errors = this.state.errors;
        var imagedata = document.getElementById("buildFile").files[0];
        var simple_file_upload = document.getElementsByClassName('simple_file_upload')[0];
        simple_file_upload.nextElementSibling.setAttribute('data-file', simple_file_upload.files[0].name);
        simple_file_upload.addEventListener('focus', function () {
            simple_file_upload.classList.add('focus');
        });
        simple_file_upload.addEventListener('blur', function () {
            simple_file_upload.classList.remove('focus');
        });
        if (imagedata !== 0) {
            errors["nofileError"] = "";
        }
        this.setState({ file: imagedata, errors });
    }

    fileChange1() {
        let errors = this.state.errors;
        var imagedata = document.getElementById("buildFile").files[0];
        var simple_file_upload = document.getElementsByClassName('simple_file_upload')[0];
        simple_file_upload.nextElementSibling.setAttribute('data-file', simple_file_upload.files[0].name);
        simple_file_upload.addEventListener('focus', function () {
            simple_file_upload.classList.add('focus');
        });
        simple_file_upload.addEventListener('blur', function () {
            simple_file_upload.classList.remove('focus');
        });
        if (imagedata !== 0) {
            errors["nofileError"] = "";
        }
        this.setState({ ...this.state, editFile1: imagedata, errors, disabledAttr1: true });
    }

    releaseChange() {
        let errors = this.state.errors;
        var imagedata = document.getElementById("releaseNotes").files[0];
        var simple_file1 = document.getElementsByClassName('simple_upload1')[0];
        simple_file1.nextElementSibling.setAttribute('data-file', simple_file1.files[0].name);
        simple_file1.addEventListener('focus', function () {
            simple_file1.classList.add('focus');
        });
        simple_file1.addEventListener('blur', function () {
            simple_file1.classList.remove('focus');
        });
        if (imagedata !== 0) {
            errors["releaseNoteError"] = "";
        }
        this.setState({ releaseNotes: imagedata, errors });
    }

    releaseChange1() {
        let errors = this.state.errors;
        var imagedata = document.getElementById("releaseNotes").files[0];
        var simple_file1 = document.getElementsByClassName('simple_upload1')[0];
        simple_file1.nextElementSibling.setAttribute('data-file', simple_file1.files[0].name);
        simple_file1.addEventListener('focus', function () {
            simple_file1.classList.add('focus');
        });
        simple_file1.addEventListener('blur', function () {
            simple_file1.classList.remove('focus');
        });
        if (imagedata !== 0) {
            errors["releaseNoteError"] = "";
        }
        this.setState({ ...this.state, editReleaseNote1: imagedata, errors, disabledAttr1: true });
    }

    toggleSuccessFirmwareUpload() {
        this.setState({ firmwareUploadSuccess: !this.state.firmwareUploadSuccess })
    }

    toggleSuccessFirmwareUpdate() {
        this.setState({ firmwareUpdateSuccess: !this.state.firmwareUpdateSuccess })
    }

    closeSuccessFirmwareUpdate() {
        this.setState({ firmwareUpdateSuccess: !this.state.firmwareUpdateSuccess }, ()=>{
            this.getBuilds();
        })
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const builds = reorder(
            this.state.builds,
            result.source.index,
            result.destination.index
        );

        this.setState({
            builds,
            disabledAttr:false
        });
    }

    async saveSequence() {
        this.openConfirmOrderSeq();
        const newArray = this.state.builds.map(item => ({
            buildId: item.id,
            sequence: item.sequence,
            modelId: this.state.modelId
        }));
        let url = baseUrl.URLPath + servicesUrl.saveBuildSequence;
        let type = ApiMethodTypes.PUT;
        let headers = getSecurityHeaders();
        let payload = newArray;
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if(response !== undefined){
            if(response.message === "ota_builds_ordered_successfully"){
                this.setState({
                    addModel: !this.state.addModel
                });
                this.toggleReOrderSuc();
                this.getBuilds();
            }
        }
    }

      toggleReOrderSuc(){
        this.setState({ ReOrderSuccess: !this.state.ReOrderSuccess ,  disabledAttr:true })
    }

    openConfirmOrderSeq(){
        this.setState({ confirmOrderSeq: !this.state.confirmOrderSeq })
    }

    

    openEditFirmware(dataArray) {
        this.setState({
            buildId: dataArray?.id,
            editFile: dataArray?.fileName,
            editReleaseNote: dataArray?.releaseNote,
            editVersion: dataArray?.version_name,
            editFirmware: !this.state.editFirmware,
            disabledAttr1: false
        })
    }

    closeEditFirmware() {
        this.setState({
            editFile1: "",
            editReleaseNote1: "",
            editVersion: "",
            editFirmware: !this.state.editFirmware,
            errors: {},
            disabledAttr1: false
        })
    }

    toggleDeleteAlert(){
        this.setState({ deleteAlert: !this.state.deleteAlert, confirmDeletebuild: !this.state.confirmDeletebuild, errors: {} })
    }

    async deleteBuild() {
        let errors = this.state.errors;

        if(this.state.deleteText?.trim() === "" || this.state.deleteText === undefined || this.state.deleteText == null) {
            errors['deleteTextError'] = this.props.t('deleteTextEmpty')
            this.setState({ errors })
        } else if (this.state.deleteText?.trim() !== "Delete" && this.state.deleteText?.trim() !== "delete") {
            errors['deleteTextError'] = this.props.t('deleteTextError')
            this.setState({ errors })
        } else {
            var url = baseUrl.URLPath + servicesUrl.deleteBuild + "?buildId=" + this.state.buildId;

            fetch(url, {
                method: ApiMethodTypes.DELETE,
                headers: {
                    "content-type": "application/json",
                    "Authorization": localStorage.getItem("Authorization"),
                }
            }).then((resp) => {
                if (resp.status === 401) {
                    window.location.reload();
                }
                if (resp.status === 200) {
                    return resp.json();
                }
                if (resp.status === 404) {
                    return resp.json();
                }
            }).then((response) => {
                if(response?.message === "build_not_exist") {
                    this.setState({ deleteAlert: true, deleteStatus: "alert", deleteStatusMessage: response?.message, deleteText: '' });
                } else {
                    this.setState({ deleteAlert: true, deleteStatus: "success", deleteStatusMessage: response?.message, deleteText: '' }, ()=>{
                        this.getBuilds();
                    });
                }
            }).catch((error) => {
                console.log(error, "catch the loop")
            })
        }
    }
    render() {
        return (
            <EditOTAManagementTemplate
                data={this.state.builds}
                confirmSquentialUpdate={this.state.confirmSquentialUpdate}
                user={this.state.user}
                activeItem={this.state.activeItem}
                sequentialEnabled={this.state.sequentialEnabled}
                addFirmware={this.state.addFirmware}
                editFirmware={this.state.editFirmware}
                editFile={this.state.editFile}
                editReleaseNote={this.state.editReleaseNote}
                editVersion={this.state.editVersion}
                version={this.state.version}
                modelName={this.state.modelName}
                successSquentialUpdate={this.state.successSquentialUpdate}
                sequenceUpdateSuccessMessage={this.state.sequenceUpdateSuccessMessage}
                firmwareUploadSuccess={this.state.firmwareUploadSuccess}
                ReOrderSuccess={this.state.ReOrderSuccess}
                buildId={this.state.buildId}
                firmwareUpdateSuccess={this.state.firmwareUpdateSuccess}
                confirmDeletebuild={this.state.confirmDeletebuild}
                deleteBuildVersion = {this.state.deleteBuildVersion}
                deleteText = {this.state.deleteText}
                deleteAlert = {this.state.deleteAlert}
                deleteStatus = {this.state.deleteStatus}
                deleteStatusMessage = {this.state.deleteStatusMessage}
                disabledAttr = {this.state.disabledAttr}
                confirmOrderSeq = {this.state.confirmOrderSeq}
                disabledAttr1 = {this.state.disabledAttr1}
                errors= {this.state.errors}
                tabToggle= {this.tabToggle}
                openAddTriggerModal= {this.openAddTriggerModal}
                openEditFirmware= {this.openEditFirmware}
                closeAddTriggerModal= {this.closeAddTriggerModal}
                backToList= {this.backToList}
                openConfirmTriggerSequenceUpdate={this.openConfirmTriggerSequenceUpdate}
                updateFirmwareSequence={this.updateFirmwareSequence}
                toggleSuccessSquentialUpdate={this.toggleSuccessSquentialUpdate}
                onChangeVersion={this.onChangeVersion}
                addBuild={this.addBuild}
                fileChange={this.fileChange}
                releaseChange={this.releaseChange}
                toggleSuccessFirmwareUpload={this.toggleSuccessFirmwareUpload}
                onDragEnd={this.onDragEnd}
                saveSequence={this.saveSequence}
                toggleReOrderSuc={this.toggleReOrderSuc}
                onChangeVersion1={this.onChangeVersion1}
                fileChange1={this.fileChange1}
                releaseChange1 = {this.releaseChange1}
                closeEditFirmware={this.closeEditFirmware}
                toggleSuccessFirmwareUpdate={this.toggleSuccessFirmwareUpdate}
                updateBuild={this.updateBuild}
                toggleConfirmDelete={this.toggleConfirmDelete}
                onChangeDeleteText = {this.onChangeDeleteText}
                closeDeleteModal = {this.closeDeleteModal}
                deleteBuild = {this.deleteBuild}
                toggleDeleteAlert = {this.toggleDeleteAlert}
                openConfirmOrderSeq = {this.openConfirmOrderSeq}
                closeSuccessFirmwareUpdate = {this.closeSuccessFirmwareUpdate}
                loading = {this.state.loading}
            />
        )   
    }

}

export default withTranslation()(EditOTAManagement);