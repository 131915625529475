import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import apiCallUtility from '../common/APICallUtility'
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import servicesUrl from '../common/servicesUrl';
import { withTranslation } from 'react-i18next';

class remoteStartTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      dataArray: [],
      cpList: [],
      tagList: [],
      items: [],
      fields: { connectorId: '', ocppTag: "", chargingProfile: "" },
      sucess: false,
      colour: 'sucess',
      errors: {},
      chargePoint: '',
      operationId: ''
    }

    this.setSelectedStatus = this.setSelectedStatus.bind(this);
    this.removeCpId = this.removeCpId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectedCP = this.getSelectedCP.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.chargingProfileChange = this.chargingProfileChange.bind(this);
    this.getChargingProfile = this.getChargingProfile.bind(this);
  }

  chargingProfileChange() {
    var chargingProfile = document.getElementById("defaultcpId").value;
    let fields = this.state.fields;
    fields["chargingProfile"] = chargingProfile;
    this.setState({ fields });
  }

  async handleSubmit(event) {
    var payload = this.getPayLoad();
    var errors = {};
    var connectorId = payload.connectorId;
    var ocpptag = document.getElementById("tagList").value;

    if (this.getCPList().length === 0) {
      errors["emptyChargePoint"] = this.props.t('select_charge_point')
    }

    if (isNaN(connectorId)) {
      errors["emptyConnectorId"] = this.props.t('enter_connector_id')
    }

    if (connectorId === 0) {
      errors["connectorIdzero"] = this.props.t('connector_id_min_valid') + ' 0'
    }

    if (ocpptag === "") {
      errors["emptyTag"] = this.props.t('please_select_rfid')
    }

    if (this.getCPList().length !== 0 && ocpptag !== "" && !isNaN(connectorId) && (connectorId !== 0)) {
      var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList(), this.props.t);
      if (response === "Unable to process your request, please try after some time.") {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, () => {
          this.onShowAlert();
        })
      } else if (response === 'Request is processed Successfully') {
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, () => {
          this.onShowAlert();
        })
      }
    }

    this.setState({ errors: errors })
  }

  getSelectedCP(cpList) {
    let errors = this.state.errors;
    if (cpList !== "") {
      errors["emptyChargePoint"] = "";
    }

    this.setState({ cpList: cpList, errors }, () => {
      this.getRFIDTag(cpList);
    });
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  getCPList() {
    var Id = this.state.operationId;
    var cplist = "";
    if (Id !== null && Id !== "") {
      cplist = Id.split();
    } else {
      cplist = this.state.cpList;
    }

    return cplist;
  }

  getPayLoad() {
    var payload = {
      "idTag": this.state.fields.ocppTag,
      "connectorId": parseInt(this.state.fields.connectorId),
      "chargingProfile": this.state.fields.chargingProfile,
    };

    if (isNaN(payload.connectorId)) {
      delete payload.connectorId;
    }

    if(this.state.fields.chargingProfile===""){
      delete payload.chargingProfile
    }
    return payload;
  }

  getMessageType() {
    return baseUrl.REMOTE_START_TRANSACTION;
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  numberHandler(field, e) {
    e.preventDefault();
    let errors = this.state.errors;
    let fields = this.state.fields;

    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      errors["emptyConnectorId"] = "";
      this.setState({ fields, errors });
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata !== undefined) {
      if (propsdata.chargePoint !== undefined && propsdata.operationId !== undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        }, () => {
          this.getRFIDTag(propsdata.operationId)
        });
      }
    }

    this.getChargingProfile();
  }

  getRFIDTag(cpid) {
    var url = baseUrl.URLPath + servicesUrl.getOcpptagList + "?cpId=" + cpid
    var errors = {};
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status === 401) {
          window.location.reload();
        } else if (resp.status === 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response !== undefined) {
          if (cpid[0] !== "" && cpid[0] !== null && cpid[0] !== undefined && response.data.length === 0) {
            errors["emptyTag"] = this.props.t('rfid_found_for_charge_point');
          } else {
            errors["emptyTag"] = "";
          }

          this.setState({ tagList: response.data, errors: errors })
        }
        this.setSelectedStatus();
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  getChargePoints(event) {
    var url = baseUrl.URLPath + "chargepoint/search";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status === 401) {
          window.location.reload();
        } else {
          return resp.json();
        }
      })
      .then((response) => {
        this.setState({ dataArray: response }, () => {
          this.setSelectedStatus();
        })
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  setSelectedStatus() {
    for (var i = 0; i < this.state.cpList.length; i++) {
      var txt = document.getElementById("idStatus").innerHTML;
      txt += "<span class='chargepoint_span' id=chargepoint_span" + this.state.cpList[i] + ">" +
        this.state.cpList[i] + "<i class='fa fa-times' id=" + this.state.cpList[i] + " )}></i></span>";
      document.getElementById("idStatus").innerHTML = txt;
    }
  }

  removeCpId(id) {
    var idList = this.state.cpList;
    var index = this.state.cpList.indexOf(id);;

    idList.splice(index, 1);
    this.setState({ cpList: idList })
    document.getElementById("chargepoint_span" + id).remove();
  }

  handleChange(id, e) {
    let isChecked = document.getElementById(`custom-${id}`).checked;
    if (isChecked) {
      var txt = document.getElementById("idStatus").innerHTML;

      this.state.cpList.push(id);
      txt += "<span class='chargepoint_span' id=chargepoint_span" + id + ">" +
        id + " <i class='fa fa-times' id=" + id + "></i></span>";
      document.getElementById("idStatus").innerHTML = txt;
    } else {
      var idList = this.state.cpList;
      var index = this.state.cpList.indexOf(id);
      idList.splice(index, 1);
      this.setState({ cpList: idList });
      document.getElementById("chargepoint_span" + id).remove();
    }
  }

  idTagChange() {
    let errors = this.state.errors;
    let fields = this.state.fields;

    var x = document.getElementById("tagList").value;
    if (x !== "") {
      errors["emptyTag"] = ""
    }

    fields["ocppTag"] = x;
    this.setState({ fields: fields, errors });
  }

  connectorIdChange() {
    var x = document.getElementById("connectorId").value;
    let fields = this.state.fields;
    fields["connectorId"] = x;
    this.setState({ fields });
  }

  // for show hide model 
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
    this.getChargePoints(this);
  }

  async getChargingProfile() {
    var url = baseUrl.URLPath + "chargingprofiles/transactions";

    await fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status === 401) {
          window.location.reload();
        } else if (resp.status === 500) {
          alert(this.props.t('internal_server_error'))
        } else if (resp.status === 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response !== undefined) {
          this.setState({
            items: response.chargingProfiles,
          })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  componentWillUnmount() {
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }

  render() {
    const { t } = this.props
    const tagList = this.state.tagList.map((tag, index) => {
      return (
        <>
          {
            (tag.idTag.trim() !== 0 || tag.idTag.trim() !== "") ? <option value={tag.idTag}>{tag.idTag}</option> : null
          }

        </>
      )
    });


    const ChargingprofileData = this.state.items.map((data, index) => {
      return (
        <option value={data.chargingProfileName} >{data.chargingProfileName}</option>
      )
    });

    const data = this.state.dataArray.map((dataArray, index) => {
      for (var i = 0; i < this.state.cpList.length; i++) {
        if (dataArray.chargePointId === this.state.cpList[i]) {
          return (
            <tr>
              <td className="text-center">
                <Form.Check onChange={this.handleChange.bind(this, dataArray.chargePointId)}
                  custom
                  type="checkbox"
                  id={`custom-${dataArray.chargePointId}`}
                  label=""
                  checked
                />
              </td>
              <td>
                {dataArray.chargePointId}
              </td>
            </tr>
          )
        }
      }

      return (
        <tr>
          <td className="text-center">
            <Form.Check onChange={this.handleChange.bind(this, dataArray.chargePointId)}
              custom
              type="checkbox"
              id={`custom-${dataArray.chargePointId}`}
              label=""
            />
          </td>
          <td>
            {dataArray.chargePointId}
          </td>
        </tr>
      )
    }
    );
    return (
      <main className="content-div" >
        <p> {t('Remote Start Transaction')}
          <div className="breadcrumb_div">
            {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Remote Start Transaction')}</span>
          </div>
        </p>

        <div className="page-outerdiv">
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
          <MDBRow className="mb-4">
            <MDBCol sm="12">
              <div>
                <form className="needs-validation">
                  <MDBRow>
                    <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="RemoteStartTransaction"></ChargePointSelection>
                    <MDBCol md="12">
                      <span className="selection_charge_point_error w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["emptyChargePoint"]}</span></span>
                    </MDBCol>
                    <MDBCol md="4">
                      <MDBInput
                        value={this.state.fields["connectorId"]}
                        name="ConnectorID"
                        onInput={this.numberHandler.bind(this, "connectorId")}
                        type="text" pattern="[0-9]*"
                        id="connectorID"
                        label={t('connector_id_integer') + " *"}
                        required
                      >
                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }} >{this.state.errors["emptyConnectorId"]}</span></span>
                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }} >{this.state.errors["connectorIdzero"]}</span></span>
                        <small id="heartbeatinterval" className="form-text text-muted"></small>
                      </MDBInput>
                    </MDBCol>
                    <MDBCol md="4">
                      <select className="browser-default custom-select select_height mb_8" id="tagList" onChange={this.idTagChange.bind(this)}>
                        <option value="" >-- {t('select_rfid_tag')} *--</option>
                        {tagList}
                      </select>
                      <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["emptyTag"]}</span></span>
                    </MDBCol>
                    <MDBCol md="4">
                      <select className="browser-default custom-select select_height mb_8" id="defaultcpId" onChange={this.chargingProfileChange.bind(this)} >
                        <option value="">-- {t('select_charging_profile')} --</option>
                        {ChargingprofileData}
                      </select>
                    </MDBCol>
                  </MDBRow>
                  <button className="mt-5 refresh-btn" type="button" onClick={this.handleSubmit.bind(this)}>{t('perform')}</button>
                </form>
              </div>
            </MDBCol>
          </MDBRow>
        </div>

        {/* model to select multipal charge point  */}
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg" backdrop={false} wrapClassName="custom_backdropclass">            <MDBModalHeader toggle={this.toggle}>{t('select_chargePoint')}</MDBModalHeader>
          <MDBModalBody>
            {/* display row of selecting charge point */}
            <MDBRow>
              <MDBCol md="12">
                <span className="form-title tp-0 pt-0 mb-2 mt-0">{t('selected_Charge_Point')}:</span>
              </MDBCol>
              <MDBCol md="12" id="idStatus" className="chargepoint_seldiv mb-1"></MDBCol>
            </MDBRow>
            {/* end display row of selecting charge point */}

            {/* Charge Point table  */}
            <MDBRow>
              <MDBCol md="12" className="chargepoint_listdiv" >
                <Table striped bordered hover size="sm" >
                  <thead>
                    <tr>
                      <th className="text-center">
                        {t('select_label')}
                      </th>
                      <th className="">{t('charge_point')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                    <tr>
                      <td className="text-center">
                        <Form.Check
                          custom
                          type="checkbox"
                          id={`custom-3`}
                          label=""
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </MDBCol>
              <div className="col-md-12 mt-4">
              </div>
            </MDBRow>
            {/* end Charge Point table  */}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.toggle}>{t('done')}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        {/* end model to select multipal charge point  */}
      </main>
    );
  }
}

export default withTranslation()(remoteStartTransaction);