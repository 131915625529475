import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { MDBBtn, MDBRow, MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTabPane, MDBTabContent } from 'mdbreact';
import $ from "jquery";
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';

//API Imports
import * as baseUrl from './constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

import '../App.css';

const history = createBrowserHistory();

class editChargePoint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      fields: {
        id: "",
        chargePointId: '',
        description: '',
        address: {
          addressId: '',
          street: "",
          houseNumber: "",
          zipCode: "",
          city: "",
          country: "",
          createdTime: "",
          modifiedTime: "",
          locationLatitude: "",
          locationLongitude: "",
        },
        active: "",
        installationSite: "",
        chargePointVendor: "",
        chargePointModel: ""
      },
      model1: false,
      model2: false,
      errors: {},
      activeItem: "1",
      transactions: [],
      startDate: new Date(),
      chargePointId: '',
      connectorStatus: [],
      sucess: false,
      colour: 'sucess',
      metervalues: [],
      connectorId: '',
      transctionId: '',
      dataArray: [],
      disabledAttr: true,
      modal1: false,
      modal2: false
    }
    this.addNew = this.addNew.bind(this)
    this.backToList = this.backToList.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.redirectToListPage = this.redirectToListPage.bind(this)
    this.tagOk1 = this.tagOk1.bind(this)
    this.toggle2 = this.toggle2.bind(this)
    this.toggle1 = this.toggle1.bind(this)
    this.onShowAlert = this.onShowAlert.bind(this);
    this.installationSiteChange = this.installationSiteChange.bind(this);
    this.getChargePointDetails = this.getChargePointDetails.bind(this);
  }

  //  function to change installationSite type
  installationSiteChange() {
    var id = document.getElementById("installationSite").value;
    let fields = this.state.fields;
    fields["installationSite"] = id;
    this.setState({ fields: fields, disabledAttr: "" });
    $('.error_installationSite').text("");
  }

  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }
  

  redirectToListPage() {
    this.props.history.push('/chargepoint')
  }

  toggle1(event) {
    event.preventDefault();

    this.setState({
      modal1: !this.state.modal1
    });
  }

  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }

  tagOk1() {
    this.props.history.push('/chargepoint');
  }

  // displaying value in all the fields
  componentDidMount() {
    var data = {}

    if (this.props.location.state === null) {
      data = JSON.parse(localStorage.getItem("cpview"));
      this.setState({
        id: data?.id,
        chargePointId: data?.chargePointId
      }, ()=>{
        this.getChargePointDetails();
      });
    } else {
      this.setState({
        id: this.props.location.state.id,
        chargePointId: this.props.location.state.chargePointId
      }, ()=>{
        data = {
          id: this.state.id,
          chargePointId: this.state.chargePointId
        }
        localStorage.setItem("cpview", JSON.stringify(data));
        this.getChargePointDetails();
      })
    }
  }

  async getChargePointDetails() {
    var url = baseUrl.URLPath + servicesUrl.getChargePointDetails + "?id=" + this.state.id;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    var fields1 = [];
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response.data.address === null) {
      fields1.id = response.data.id;
      fields1.chargePointId = response.data.chargePointId
      fields1.description = response.data.description
      fields1.chargePointModel = response.data.chargePointModel
      fields1.chargePointVendor = response.data.chargePointVendor
      fields1.installationSite = response.data.installationSite;
      fields1.address = {
        addressId: '',
        street: "",
        houseNumber: "",
        zipCode: "",
        city: "",
        country: "",
        createdTime: "",
        modifiedTime: "",
        locationLatitude: "",
        locationLongitude: "",
      }
      this.setState({ fields: fields1 })
    } else if (response.data.address !== null) {
      fields1.id = response.data.id;
      fields1.chargePointId = response.data.chargePointId
      fields1.description = response.data.description
      fields1.chargePointModel = response.data.chargePointModel
      fields1.chargePointVendor = response.data.chargePointVendor;
      fields1.installationSite = response.data.installationSite;
      var addr = response.data.address;
      fields1.address = {
        addressId: addr.id,
        street: addr.street,
        houseNumber: addr.houseNumber,
        zipCode: addr.zipCode,
        city: addr.city,
        country: addr.country,
        createdTime: addr.createdTime,
        modifiedTime: addr.modifiedTime,
        locationLatitude: addr.locationLatitude,
        locationLongitude: addr.locationLongitude,
      }

      if (addr.country !== null && addr.country !== "") {
        try {
          document.getElementById('countryId').getElementsByTagName('option')[addr.country].selected = 'selected';

        } catch (error) {
        
        }
      }
      this.setState({ fields: fields1 })

    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  addNew() {
    this.props.history.push('/chargePoint');
  }

  // back button click
  backToList() {
    this.props.history.push('/viewChargePoint');
  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {
    let fields = this.state;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  //  when click on submit button
  async handleSubmit(event) {

    event.preventDefault();
    this.setState({
      modal1: !this.state.modal1
    });
    let errors = {};
    let description = this.state.fields.description?.trim() ?? '';
    if (this.state.fields.installationSite !== "private" && this.state.fields.installationSite !== "company") {
      $('.error_installationSite').text(this.props.t('site_be_private_or_company_only'));
      errors["installationSite"] = this.props.t('site_be_private_or_company_only');
    } else {
      $('.error_installationSite').text("");
      delete errors.installationSite;
    }
    if(description && description.length > 20){
      errors['description'] = 'Description should not exceed 20 characters';
    }
    if (errors.hasOwnProperty("entervalidzipcode") || errors.hasOwnProperty("installationSite") || errors.hasOwnProperty("description")) { }
    else {
      var url = baseUrl.URLPath + "chargepoints";
      let type = ApiMethodTypes.PUT;
      let headers = getSecurityHeaders();
      let payload = { "chargePointVendor": this.state.fields.chargePointVendor, "id": this.state.fields.id, "chargePointModel": this.state.fields.chargePointModel, "chargePointId": this.state.fields.chargePointId, "description": this.state.fields.description?.trim() ?? '', "address": { "street": this.state.fields.address.street, "houseNumber": this.state.fields.address.houseNumber, "zipCode": this.state.fields.address.zipCode, "city": this.state.fields.address.city, "country": this.state.fields.address.country, "locationLatitude": this.state.fields.address.locationLatitude, "locationLongitude": this.state.fields.address.locationLongitude }, "installationSite": this.state.fields.installationSite };

      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if (response.status === "FAILURE") { } 
      else { this.toggle2(); }
    }

    this.setState({ errors: errors });
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    if (field === "chargePointId" || field === "description") {
      fields[field] = e.target.value;
    } else {
      fields['address'][field] = e.target.value;
    }
    this.setState({ fields });
    this.setState({ fields, disabledAttr: "" });
  }

  countryChange(default_cal) {
    let fields = this.state.fields;
    fields['address']['country'] = default_cal.target.value;
    this.setState({ fields, disabledAttr: "" });
  }

  render() {
    const { t } = this.props;

    return (
      <main class="content-div" >
        <p>{t('Overview')}
          <div className="breadcrumb_div">
            {t('charge_point')} &gt; <Link to="/chargePoint" > <span className="">{t('Overview')}</span></Link>  &gt;  <Link to={{
              pathname: '/viewChargePoint',
              state: {
                chargePointId: this.state.fields.chargePointId, id: this.state.fields.id
              },
            }}> {t('view')} </Link> <span className="breadcrumb_page"> &gt; {t('edit')}</span>
          </div>
        </p>
        <form
          className='needs-validation'
          noValidate
        >
          <div className="page-outerdiv">
            <MDBRow>
              <MDBCol md="12" className="">
                <MDBTabContent activeItem={this.state.activeItem} className="pt-2 border-0" >
                  {/* details */}
                  <MDBTabPane tabId="1" role="tabpanel">
                    <div className="row">

                      {/* sub title */}
                      <div className="col-md-12 mb-3">
                        <span className="form-title">{t('details')}:</span>
                      </div>
                      <MDBCol md="4">
                        <MDBInput
                          name="organization"
                          value={this.state.fields.chargePointVendor}
                          disabled="true"
                          type="text"
                          id="organization"
                          label={t('Organization') + " *"}
                          autoComplete="off"
                        >
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          name="chargePoint"
                          value={this.state.fields.chargePointModel}
                          disabled="true"
                          type="text"
                          id="chargePointModel"
                          label={t('model') + " *"}
                          autoComplete="off"
                        >
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          name="chargePoint"
                          onChange={this.changeHandler.bind(this, "chargePointId")}
                          value={this.state.fields.chargePointId}
                          disabled={true}
                          type="text"
                          id="chargePointId"
                          label={t('charge_point_id_label') + " *"}
                          autoComplete="off"
                        >
                        </MDBInput>
                      </MDBCol>

                      <MDBCol md="8">
                        <MDBInput
                          value={this.state.fields.description}
                          onChange={this.changeHandler.bind(this, "description")}
                          type="text"
                          name="description"
                          label={t('description')}
                          maxLength="50"
                        >
                          <div className="mt-3">
                            <span className="error_msg w3-animate-top" style={{ color: "red" }}>{this.state.errors['description']}</span>
                          </div>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4">
                        <label className="pull-left mr-1 mt-2 pt-1 code_clr pl-2">
                          <b style={{ fontSize: "14px" }}>{t('installation_site')} * <span className="custom_tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                            <div className="custom_tooltiptext">
                              <div className="custom_tooltip_header">{t('installation_site')}</div>
                              <div className="custom_tooltip_body">
                                <div>{t('location_of_chargingstation')}</div>
                                <div><span className="font-bold">{t('private')}: </span>{t('chargingstation_installed_in_private_place')}</div>
                                <div><span className="font-bold">{t('company')}: </span>{t('chargingstation_installed_at_compan_loc')}</div>
                                <div><span className="font-bold">{t('other')}: </span>{t('chargingstation_not_installed_company_loc_or_private_loc')}</div>
                              </div>
                            </div>
                          </span></b> </label>
                        <select className="browser-default custom-select select_height w_59 indent" id="installationSite" onChange={this.installationSiteChange} value={this.state.fields.installationSite}>
                          <option value="other">{t('other')}</option>
                          <option value="company">{t('company')}</option>
                          <option value="private">{t('private')}</option>
                        </select>
                        <div className="mt-3"><span className="error_msg w3-animate-top error_installationSite" style={{ color: "red" }}></span></div>
                      </MDBCol>
                    </div>

                    <div className="row">
                      {/* sub title */}
                      <div className="col-md-12 mb-3">
                        <span className="form-title" >{t('address')}:</span>
                      </div>
                      {/* end sub title */}

                      <MDBCol md="4" >
                        <MDBInput
                          name="houseNumber"
                          onChange={this.changeHandler.bind(this, "houseNumber")}
                          value={this.state.fields.address.houseNumber}
                          min="0"
                          pattern="[0-9]*"
                          id="houseNumberId"
                          label={t('house_number')}
                          type="text"
                        >
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          name="street"
                          value={this.state.fields.address.street}
                          onChange={this.changeHandler.bind(this, "street")}
                          type="text"
                          id="streetId"
                          label={t('street')}
                          autoComplete="off"
                        >
                        </MDBInput>
                      </MDBCol>

                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.fields.address.city}
                          onChange={this.changeHandler.bind(this, "city")}
                          type="text"
                          id="cityId"
                          name="city"
                          label={t('city')}
                        >
                        </MDBInput>
                      </MDBCol>

                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.fields.address.zipCode}
                          onChange={this.changeHandler.bind(this, "zipCode")}
                          onInput={this.numberHandler.bind(this, "zipCode")}
                          type="text" pattern="[0-9]*"
                          id="zipcodeId"
                          name="zipcode"
                          label={t('zipCode')}
                        >
                        </MDBInput>
                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["entervalidzipcode"]}</span></span>
                      </MDBCol>

                      <MDBCol md="4">
                        <select className="browser-default custom-select select_height" id="countryId" onChange={this.countryChange.bind(this)} >
                          <option id="Country" value="">{t('select_country')}</option>
                          <option id="Afghanistan" value="Afghanistan">{t('Afghanistan')}</option>
                          <option id="Åland Islands" value="Åland Islands">{t('Åland_islands')}</option>
                          <option id="Albania" value="Albania">{t('Albania')}</option>
                          <option id="Algeria" value="Algeria">{t('Algeria')}</option>
                          <option id="American Samoa" value="American Samoa">{t('American_samoa')}</option>
                          <option id="Andorra" value="Andorra">{t('Andorra')}</option>
                          <option id="Angola" value="Angola">{t('Angola')}</option>
                          <option id="Anguilla" value="Anguilla">{t('Anguilla')}</option>
                          <option id="Antarctica" value="Antarctica">{t('Antarctica')}</option>
                          <option id="Antigua and Barbuda" value="Antigua and Barbuda">{t('Antigua_and_barbuda')}</option>
                          <option id="Argentina" value="Argentina">{t('Argentina')}</option>
                          <option id="Armenia" value="Armenia">{t('Armenia')}</option>
                          <option id="Aruba" value="Aruba">{t('Aruba')}</option>
                          <option id="Australia" value="Australia">{t('Australia')}</option>
                          <option id="Austria" value="Austria">{t('Austria')}</option>
                          <option id="Azerbaijan" value="Azerbaijan">{t('Azerbaijan')}</option>
                          <option id="Bahamas" value="Bahamas">{t('Bahamas')}</option>
                          <option id="Bahrain" value="Bahrain">{t('Bahrain')}</option>
                          <option id="Bangladesh" value="Bangladesh">{t('Bangladesh')}</option>
                          <option id="Barbados" value="Barbados">{t('Barbados')}</option>
                          <option id="Belarus" value="Belarus">{t('Belarus')}</option>
                          <option id="Belgium" value="Belgium">{t('Belgium')}</option>
                          <option id="Belize" value="Belize">{t('Belize')}</option>
                          <option id="Benin" value="Benin">{t('Benin')}</option>
                          <option id="ermuda" value="Bermuda">{t('Bermuda')}</option>
                          <option id="Bhutan" value="Bhutan">{t('Bhutan')}</option>
                          <option id="Bolivia" value="Bolivia">{t('Bolivia')}</option>
                          <option id="Bosnia and Herzegovina" value="Bosnia and Herzegovina">{t('Bosnia_and_Herzegovina')}</option>
                          <option id="Botswana" value="Botswana">{t("Botswana")}</option>
                          <option id="Bouvet Island" value="Bouvet Island">{t('Bouvet_island')}</option>
                          <option id="Brazil" value="Brazil">{t('Brazil')}</option>
                          <option id="British Indian Ocean Territory" value="British Indian Ocean Territory">{t('British_indian_ocean_territory')}</option>
                          <option id="Brunei Darussalam" value="Brunei Darussalam">{t('Brunei_darussalam')}</option>
                          <option id="Bulgaria" value="Bulgaria">{t('Bulgaria')}</option>
                          <option id="Burkina Faso" value="Burkina Faso">{t('Burkina_faso')}</option>
                          <option id="Cambodia" value="Cambodia">{t("Cambodia")}</option>
                          <option id="Cameroon" value="Cameroon">{t('Cameroon')}</option>
                          <option id="Canada" value="Canada">{t('Canada')}</option>
                          <option id="Cape Verde" value="Cape Verde">{t('Cape_verde')}</option>
                          <option id="Cayman Islands" value="Cayman Islands">{t('Cayman_islands')}</option>
                          <option id="Central African Republic" value="Central African Republic">{t('Central_african_republic')}</option>
                          <option id="Chad" value="Chad">{t('Chad')}</option>
                          <option id="Chile" value="Chile">{t('Chile')}</option>
                          <option id="China" value="China">{t('China')}</option>
                          <option id="Christmas Island" value="Christmas Island">{t('Christmas_island')}</option>
                          <option id="Cocos (Keeling) Islands" value="Cocos (Keeling) Islands">{t('Cocos_keeling_islands')}</option>
                          <option id="Colombia" value="Colombia">{t('Colombia')}</option>
                          <option id="Comoros" value="Comoros">{t('Comoros')}</option>
                          <option id="Congo" value="Congo">{t('Congo')}</option>
                          <option id="Congo, The Democratic Republic of The" value="Congo, The Democratic Republic of The">{t('Congo_the_democratic_republic_of_the')}</option>
                          <option id="Cook Islands" value="Cook Islands">{t('Cook_islands')}</option>
                          <option id="Costa Rica" value="Costa Rica">{t('Costa_rica')}</option>
                          <option id="Cote D'ivoire" value="Cote D'ivoire">{t('Cote_divoire')}</option>
                          <option id="Croatia" value="Croatia">{t('Croatia')}</option>
                          <option id="Cuba" value="Cuba">{t('Cuba')}</option>
                          <option id="Cyprus" value="Cyprus">{t('Cyprus')}</option>
                          <option id="Czech Republic" value="Czech Republic">{t("Czech_republic")}</option>
                          <option id="Denmark" value="Denmark">{t('Denmark')}</option>
                          <option id="Djibouti" value="Djibouti">{t('Djibouti')}</option>
                          <option id="Dominica" value="Dominica">{t('Dominica')}</option>
                          <option id="Dominican Republic" value="Dominican Republic">{t('Dominican_republic')}</option>
                          <option id="Ecuador" value="Ecuador">{t("Ecuador")}</option>
                          <option id="Egypt" value="Egypt">{t('Egypt')}</option>
                          <option id="El Salvador" value="El Salvador">{t('El_salvador')}</option>
                          <option id="Equatorial Guinea" value="Equatorial Guinea">{t('Equatorial_guinea')}</option>
                          <option id="Eritrea" value="Eritrea">{t('Eritrea')}</option>
                          <option id="Estonia" value="Estonia">{t('Estonia')}</option>
                          <option id="Ethiopia" value="Ethiopia">{t('Ethiopia')}</option>
                          <option id="Falkland Islands (Malvinas)" value="Falkland Islands (Malvinas)">{t('Falkland_islands_malvinas')}</option>
                          <option id="Faroe Islands" value="Faroe Islands">{t('Faroe_islands')}</option>
                          <option id="Fiji" value="Fiji">{t('Fiji')}</option>
                          <option id="Finland" value="Finland">{t('Finland')}</option>
                          <option id="France" value="France">{t('France')}</option>
                          <option id="French Guiana" value="French Guiana">{t('French_guiana')}</option>
                          <option id="French Polynesia" value="French Polynesia">{t('French_polynesia')}</option>
                          <option id="French Southern Territories" value="French Southern Territories">{t('French_southern_territories')}</option>
                          <option id="Gabon" value="Gabon">{t('Gabon')}</option>
                          <option id="Gambia" value="Gambia">{t('Gambia')}</option>
                          <option id="Georgia" value="Georgia">{t('Georgia')}</option>
                          <option id="Germany" value="Germany">{t('Germany')}</option>
                          <option id="Ghana" value="Ghana">{t('Ghana')}</option>
                          <option id="Gibraltar" value="Gibraltar">{t('Gibraltar')}</option>
                          <option id="Greece" value="Greece">{t('Greece')}</option>
                          <option id="Greenland" value="Greenland">{t('Greenland')}</option>
                          <option id="Grenada" value="Grenada">{t('Grenada')}</option>
                          <option id="Guadeloupe" value="Guadeloupe">{t('Guadeloupe')}</option>
                          <option id="Guam" value="Guam">{t('Guam')}</option>
                          <option id="Guatemala" value="Guatemala">{t('Guatemala')}</option>
                          <option id="Guernsey" value="Guernsey">{t('Guernsey')}</option>
                          <option id="Guinea" value="Guinea">{t('Guinea')}</option>
                          <option id="Guinea-bissau" value="Guinea-bissau">{t('Guinea_bissau')}</option>
                          <option id="Guyana" value="Guyana">{t('Guyana')}</option>
                          <option id="Haiti" value="Haiti">{t('Haiti')}</option>
                          <option id="Heard Island and Mcdonald Islands" value="Heard Island and Mcdonald Islands">{t('Heard_island_and_mcdonald_islands')}</option>
                          <option id="Holy See (Vatican City State)" value="Holy See (Vatican City State)">{t('Holy_see_vatican_city_state')}</option>
                          <option id="Honduras" value="Honduras">{t('Honduras')}</option>
                          <option id="Hong Kong" value="Hong Kong">{t('Hong_kong')}</option>
                          <option id="Hungary" value="Hungary">{t('Hungary')}</option>
                          <option id="Iceland" value="Iceland">{t('Iceland')}</option>
                          <option id="India" value="India">{t('India')}</option>
                          <option id="Indonesia" value="Indonesia">{t('Indonesia')}</option>
                          <option id="Iran, Islamic Republic of" value="Iran, Islamic Republic of">{t('Iran_islamic_republic_of')}</option>
                          <option id="Iraq" value="Iraq">{t('Iraq')}</option>
                          <option id="Ireland" value="Ireland">{t('Ireland')}</option>
                          <option id="Isle of Man" value="Isle of Man">{t('Isle_of_man')}</option>
                          <option id="Israel" value="Israel">{t('Israel')}</option>
                          <option id="Italy" value="Italy">{t('Italy')}</option>
                          <option id="Jamaica" value="Jamaica">{t('Jamaica')}</option>
                          <option id="Japan" value="Japan">{t('Japan')}</option>
                          <option id="Jersey" value="Jersey">{t('Jersey')}</option>
                          <option id="Jordan" value="Jordan">{t('Jordan')}</option>
                          <option id="Kazakhstan" value="Kazakhstan">{t('Kazakhstan')}</option>
                          <option id="Kenya" value="Kenya">{t('Kenya')}</option>
                          <option id="Kiribati" value="Kiribati">{t('Kiribati')}</option>
                          <option id="Korea, Democratic People's Republic of" value="Korea, Democratic People's Republic of">{t('Korea_democratic_peoples_republic_of')}</option>
                          <option id="Korea, Republic of" value="Korea, Republic of">{t('Korea_republic_of')}</option>
                          <option id="Kuwait" value="Kuwait">{t('Kuwait')}</option>
                          <option id="Kyrgyzstan" value="Kyrgyzstan">{t('Kyrgyzstan')}</option>
                          <option id="Lao People's Democratic Republic" value="Lao People's Democratic Republic">{t('Lao_peoples_democratic_republic')}</option>
                          <option id="Latvia" value="Latvia">{t('Latvia')}</option>
                          <option id="Lebanon" value="Lebanon">{t('Lebanon')}</option>
                          <option id="Lesotho" value="Lesotho">{t('Lesotho')}</option>
                          <option id="Liberia" value="Liberia">{t('Liberia')}</option>
                          <option id="Libyan Arab Jamahiriya" value="Libyan Arab Jamahiriya">{t('Libyan_arab_jamahiriya')}</option>
                          <option id="Liechtenstein" value="Liechtenstein">{t('Liechtenstein')}</option>
                          <option id="Lithuania" value="Lithuania">{t('Lithuania')}</option>
                          <option id="Luxembourg" value="Luxembourg">{t('Luxembourg')}</option>
                          <option id="Macao" value="Macao">{t('Macao')}</option>
                          <option id="Macedonia, The Former Yugoslav Republic of" value="Macedonia, The Former Yugoslav Republic of">{t('Macedonia_the_former_yugoslav_republic_of')}</option>
                          <option id="Madagascar" value="Madagascar">{t('Madagascar')}</option>
                          <option id="Malawi" value="Malawi">{t('Malawi')}</option>
                          <option id="Malaysia" value="Malaysia">{t('Malaysia')}</option>
                          <option id="Maldives" value="Maldives">{t('Maldives')}</option>
                          <option id="Mali" value="Mali">{t('Mali')}</option>
                          <option id="Malta" value="Malta">{t('Malta')}</option>
                          <option id="Marshall Islands" value="Marshall Islands">{t('Marshall_islands')}</option>
                          <option id="Martinique" value="Martinique">{t('Martinique')}</option>
                          <option id="Mauritania" value="Mauritania">{t('Mauritania')}</option>
                          <option id="Mauritius" value="Mauritius">{t('Mauritius')}</option>
                          <option id="Mayotte" value="Mayotte">{t('Mayotte')}</option>
                          <option id="Mexico" value="Mexico">{t('Mexico')}</option>
                          <option id="Micronesia, Federated States of" value="Micronesia, Federated States of">{t('Micronesia_federated_states_of')}</option>
                          <option id="Moldova, Republic of" value="Moldova, Republic of">{t('Moldova_republic_of')}</option>
                          <option id="Monaco" value="Monaco">{t('Monaco')}</option>
                          <option id="Mongolia" value="Mongolia">{t('Mongolia')}</option>
                          <option id="Montenegro" value="Montenegro">{t('Montenegro')}</option>
                          <option id="Montserrat" value="Montserrat">{t('Montserrat')}</option>
                          <option id="Morocco" value="Morocco">{t('Morocco')}</option>
                          <option id="Mozambique" value="Mozambique">{t('Mozambique')}</option>
                          <option id="Myanmar" value="Myanmar">{t('Myanmar')}</option>
                          <option id="Namibia" value="Namibia">{t('Namibia')}</option>
                          <option id="Nauru" value="Nauru">{t('Nauru')}</option>
                          <option id="Nepal" value="Nepal">{t('Nepal')}</option>
                          <option id="Netherlands" value="Netherlands">{t('Netherlands')}</option>
                          <option id="Netherlands Antilles" value="Netherlands Antilles">{t('Netherlands_antilles')}</option>
                          <option id="New Caledonia" value="New Caledonia">{t('New_caledonia')}</option>
                          <option id="New Zealand" value="New Zealand">{t('New_zealand')}</option>
                          <option id="Nicaragua" value="Nicaragua">{t('Nicaragua')}</option>
                          <option id="Niger" value="Niger">{t('Niger')}</option>
                          <option id="Nigeria" value="Nigeria">{t('Nigeria')}</option>
                          <option id="Niue" value="Niue">{t('Niue')}</option>
                          <option id="Norfolk Island" value="Norfolk Island">{t('Norfolk_island')}</option>
                          <option id="Northern Mariana Islands" value="Northern Mariana Islands">{t('Northern_mariana_islands')}</option>
                          <option id="Norway" value="Norway">{t('Norway')}</option>
                          <option id="Oman" value="Oman">{t('Oman')}</option>
                          <option id="Pakistan" value="Pakistan">{t('Pakistan')}</option>
                          <option id="Palau" value="Palau">{t('Palau')}</option>
                          <option id="Palestinian Territory, Occupied" value="Palestinian Territory, Occupied">{t('Palestinian_territory_occupied')}</option>
                          <option id="Panama" value="Panama">{t('Panama')}</option>
                          <option id="Papua New Guinea" value="Papua New Guinea">{t('Papua_new_guinea')}</option>
                          <option id="Paraguay" value="Paraguay">{t('Paraguay')}</option>
                          <option id="Peru" value="Peru">{t('Peru')}</option>
                          <option id="Philippines" value="Philippines">{t('Philippines')}</option>
                          <option id="Pitcairn" value="Pitcairn">{t('Pitcairn')}</option>
                          <option id="Poland" value="Poland">{t('Poland')}</option>
                          <option id="Portugal" value="Portugal">{t('Portugal')}</option>
                          <option id="Puerto Rico" value="Puerto Rico">{t('Puerto_Rico')}</option>
                          <option id="Qatar" value="Qatar">{t('Qatar')}</option>
                          <option id="Reunion" value="Reunion">{t('Reunion')}</option>
                          <option id="Romania" value="Romania">{t('Romania')}</option>
                          <option id="Russian Federation" value="Russian Federation">{t('Russian_federation')}</option>
                          <option id="Rwanda" value="Rwanda">{t('Rwanda')}</option>
                          <option id="Saint Helena" value="Saint Helena">{t('Saint_helena')}</option>
                          <option id="Saint Kitts and Nevis" value="Saint Kitts and Nevis">{t('Saint_kitts_and_nevis')}</option>
                          <option id="Saint Lucia" value="Saint Lucia">{t('Saint_lucia')}</option>
                          <option id="Saint Pierre and Miquelon" value="Saint Pierre and Miquelon">{t('Saint_pierre_and_miquelon')}</option>
                          <option id="Saint Vincent and The Grenadines" value="Saint Vincent and The Grenadines">{t('Saint_vincent_and_the_grenadines')}</option>
                          <option id="Samoa" value="Samoa">{t('Samoa')}</option>
                          <option id="San Marino" value="San Marino">{t('San_marino')}</option>
                          <option id="Sao Tome and Principe" value="Sao Tome and Principe">{t('Sao_tome_and_principe')}</option>
                          <option id="Saudi Arabia" value="Saudi Arabia">{t('Saudi_arabia')}</option>
                          <option id="Senegal" value="Senegal">{t('Senegal')}</option>
                          <option id="Serbia" value="c">{t('Serbia')}</option>
                          <option id="Seychelles" value="Seychelles">{t('Seychelles')}</option>
                          <option id="Sierra Leone" value="Sierra Leone">{t('Sierra_leone')}</option>
                          <option id="Singapore" value="Singapore">{t('Singapore')}</option>
                          <option id="Slovakia" value="Slovakia">{t('Slovakia')}</option>
                          <option id="Slovenia" value="Slovenia">{t('Slovenia')}</option>
                          <option id="Solomon Islands" value="Solomon Islands">{t('Solomon_islands')}</option>
                          <option id="Somalia" value="Somalia">{t('Somalia')}</option>
                          <option id="South Africa" value="South Africa">{t('South_africa')}</option>
                          <option id="South Georgia and The South Sandwich Islands" value="South Georgia and The South Sandwich Islands">{t('South_georgia_and_the_south_sandwich_islands')}</option>
                          <option id="Spain" value="Spain">{t('Spain')}</option>
                          <option id="Sri Lanka" value="Sri Lanka">{t('Sri_lanka')}</option>
                          <option id="Sudan" value="Sudan">{t('Sudan')}</option>
                          <option id="Suriname" value="Suriname">{t('Suriname')}</option>
                          <option id="Svalbard and Jan Mayen" value="Svalbard and Jan Mayen">{t('Svalbard_and_jan_mayen')}</option>
                          <option id="Swaziland" value="Swaziland">{t('Swaziland')}</option>
                          <option id="Sweden" value="Sweden">{t('Sweden')}</option>
                          <option id="Switzerland" value="Switzerland">{t('Switzerland')}</option>
                          <option id="Syrian Arab Republicr" value="Syrian Arab Republic">{t('Syrian_arab_republic')}</option>
                          <option id="Taiwan, Province of China" value="Taiwan, Province of China">{t('Taiwan_province_of_china')}</option>
                          <option id="Tajikistan" value="Tajikistan">{t('Tajikistan')}</option>
                          <option id="Tanzania, United Republic of" value="Tanzania, United Republic of">{t('Tanzania_united_republic_of')}</option>
                          <option id="Thailand" value="Thailand">{t('Thailand')}</option>
                          <option id="Timor-leste" value="Timor-leste">{t('Timor_leste')}</option>
                          <option id="Togo" value="Togo">{t('Togo')}</option>
                          <option id="Tokelau" value="Tokelau">{t('Tokelau')}</option>
                          <option id="Tonga" value="Tonga">{t('Tonga')}</option>
                          <option id="Trinidad and Tobago" value="Trinidad and Tobago">{t('Trinidad_and_tobago')}</option>
                          <option id="Tunisia" value="Tunisia">{t('Tunisia')}</option>
                          <option id="Turkey" value="Turkey">{t('Turkey')}</option>
                          <option id="Turkmenistan" value="Turkmenistan">{t('Turkmenistan')}</option>
                          <option id="Turks and Caicos Islands" value="Turks and Caicos Islands">{t('Turks_and_caicos_islands')}</option>
                          <option id="Tuvalu" value="Tuvalu">{t('Tuvalu')}</option>
                          <option id="Uganda" value="Uganda">{t('Uganda')}</option>
                          <option id="Ukraine" value="Ukraine">{t('Ukraine')}</option>
                          <option id="United Arab Emirates" value="United Arab Emirates">{t('United_arab_emirates')}</option>
                          <option id="United Kingdom" value="United Kingdom">{t('United_kingdom')}</option>
                          <option id="United States" value="United States">{t('United_states')}</option>
                          <option id="United States Minor Outlying Islands" value="United States Minor Outlying Islands">{t('United_states_minor_outlying_islands')}</option>
                          <option id="Uruguay" value="Uruguay">{t('Uruguay')}</option>
                          <option id="Uzbekistan" value="Uzbekistan">{t('Uzbekistan')}</option>
                          <option id="Vanuatu" value="Vanuatu">{t('Vanuatu')}</option>
                          <option id="Venezuela" value="Venezuela">{t('Venezuela')}</option>
                          <option id="Viet Nam" value="Viet Nam">{t('Viet_nam')}</option>
                          <option id="Virgin Islands, British" value="Virgin Islands, British">{t('Virgin_islands_british')}</option>
                          <option id="Virgin Islands, U.S." value="Virgin Islands, U.S.">{t('Virgin_islands_us')}</option>
                          <option id="Wallis and Futuna" value="Wallis and Futuna">{t('Wallis_and_futuna')}</option>
                          <option id="Western Sahara" value="Western Sahara">{t('Western_sahara')}</option>
                          <option id="Yemen" value="Yemen">{t('Yemen')}</option>
                          <option id="Zambia" value="Zambia">{t('Zambia')}</option>
                          <option id="Zimbabwe" value="Zimbabwe">{t('Zimbabwe')}</option>

                        </select>
                      </MDBCol>

                      <MDBCol md="12" className="text-right">
                        <button type='submit' className="mt-5 mr-3 refresh-btn" disabled={this.state.disabledAttr} onClick={this.toggle1} id="editBtn" >
                          <i class="fas fa-save mr-2"></i> {t('save')}
                        </button>

                        <Link to={{
                          pathname: '/viewChargePoint',
                          state: {
                            chargePointId: this.state.fields.chargePointId, id: this.state.fields.id
                          },
                        }}>
                          <button className="mt-5 refresh-btn text-secondary" onClick={this.backToList} style={{ borderColor: "grey" }}>
                            <i class="fas fa-times mr-2"></i> {t('cancel')}
                          </button>
                        </Link>
                      </MDBCol>
                    </div>
                  </MDBTabPane>
                  {/* end details */}
                </MDBTabContent>
              </MDBCol>
            </MDBRow>
          </div>
        </form>

        <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle1}>{t('confirmation')}</MDBModalHeader>
          <MDBModalBody>
            {t('sure_want_to_save_changes')}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="danger" onClick={this.toggle1}>{t('close')}</MDBBtn>
            <MDBBtn color="primary" onClick={this.handleSubmit}>{t('yes')}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('chargepoint_updated_successfully')}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.tagOk1}>{t('ok')}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </main>
    );
  }
}

export default withTranslation()(withRouter(editChargePoint));