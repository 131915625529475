import React from 'react';
import { Form, Table } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import moment from 'moment';
import Calendar from 'react-calendar';
import { Line } from "react-chartjs-2";
import currencyFormatter from 'currency-formatter';
import { withTranslation } from 'react-i18next';
import { noDecimalCurrencies } from '../common/noDecimalCurrencies';
//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders, getSecurityHeadersWithTimezone } from '../common/HttpRequestClass'
import * as headers from '../common/APIConstants';


class meterValues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMaxValues: {
        min: 0,
        suggestedMax: 10,
      },
      meterValuesChart: [],
      dataLine: {
        labels: [],
        dataLabels: {
          enabled: false
        },

        datasets: [
          {
            label: "kW",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "rgba(225, 204,230, .3)",
            borderColor: "#00529E",
            data: []
          }
        ],
        options: {
          legend: { display: false }, tooltips: {
            enabled: false
          }
        }
      },
      businessstartDate: "",
      businessendDate: "",
      startDate: "",
      endDate: "",
      value: [null, null],
      value2: [null, null],

      calenderfromDisplay: "none",
      businessCalenderDisplay: "none",
      transactionId: '',
      billingId: '',
      connectorId: '',
      chargePointId: '',
      businessErrors: '',
      dataArray: [],
      transData: {},
      model1: false,
      page_title: "Meter Values",
      modalsession: false,
      color_black: false,
      bgColor1: "active",
      bgColor2: "",
      businessError: [],
      noOfRecords: '',
      businessRecords: '',
      data: new Map(),
      businessdata: new Map(),
      pageSize: "10",
      businessPageSize: "10",
      businesspageNo: 1,
      activePage: 1,
      businessactivePage: 1,
      pageNo: 1,
      graphView: 'block',
      tableView: 'none',
      fields: {
        findValue: "",
        finderrorCode: "",
        paymentDate: '',
        paymentTransactionId: '',
        serialNumber: '',
        rate: '',
        totalKwh: '',
        totalTime: '',
        chargedKwh: '',
        chargedTime: '',
        status: '',
        paidAmount: '',
        ccFee: '',
        refundAmount: '',
        billAmount: '',
        chargingSessionCurrency: ''
      },
      errors: {}
    }
    this.onBack = this.onBack.bind(this);
    this.getMetreValues = this.getMetreValues.bind(this);
    this.getBusinessErrors = this.getBusinessErrors.bind(this);
    this.displayfromCalender = this.displayfromCalender.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.showEntriesChange2 = this.showEntriesChange2.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.businessPageChange = this.businessPageChange.bind(this);
    this.onChangeBusinessDate = this.onChangeBusinessDate.bind(this);
    this.onChangeMeterValueDate = this.onChangeMeterValueDate.bind(this);
    this.changeErrorCode = this.changeErrorCode.bind(this);
    this.onChangeErrorCode = this.onChangeErrorCode.bind(this);
    this.getMeterValuesChart = this.getMeterValuesChart.bind(this);
    this.closeMeterValuesCalender = this.closeMeterValuesCalender.bind(this);
    this.closeBusinessCalender = this.closeBusinessCalender.bind(this);
    this.onChangeRefresh = this.onChangeRefresh.bind(this);
    this.onClickRefresh = this.onClickRefresh.bind(this);
    this.getSessionData.bind(this);
    this.downloadSafeEVFile = this.downloadSafeEVFile.bind(this);
    this.keyPress3 = this.keyPress3.bind(this);
    this.clearValue = this.clearValue.bind(this);
  }

  clearValue() {
    let errors = this.state.errors;
    let fields = this.state.fields;

    if (this.state.errors["chargePowerError"] !== "") {
      fields.findValue = '';
      errors["chargePowerError"] = "";

      this.setState({ fields, errors });
    } else {
      fields.findValue = '';
      errors["chargePowerError"] = "";

      this.setState({ fields, errors }, () => {
        this.getMetreValues(this.state.pageSize, 1);
      });
    }
  }

  keyPress3(e) {
    var key = e.key;
    let fields = this.state.fields;
    let errors = this.state.errors;

    if (e.keyCode === 13) {
      if (fields.findValue === "") {
        errors["chargePowerError"] = this.props.t('enter_value');
        this.setState({ errors });
      } else {
        errors["chargePowerError"] = "";
        this.setState({ errors }, () => {
          this.getMetreValues(this.state.pageSize, 1);
        });
      }
    }

    if (key === "Backspace" || key === "Delete") {
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno === "") {
        fields.findValue = sno;
        this.setState({ fields }, () => {
          this.getMetreValues(this.state.pageSize, 1);
        })
      }
    }
  }

  // function for value filter
  onChangeValue() {
    let errors = {}
    if (this.state.fields.findValue === "") {
      errors["chargePowerError"] = this.props.t('enter_value')
      this.setState({
        errors: errors
      });
    } else {
      errors["chargePowerError"] = "";

      this.setState({
        errors: errors
      }, () => {
        this.getMetreValues(this.state.pageSize, 1);
      });
    }
  }

  // function for errorcode filter
  onChangeErrorCode() {
    var getErrors = document.getElementById("finderrorCode").value;
    this.setState({ finderrorCode: getErrors }, () => {
      this.getBusinessErrors(this.state.businessPageSize, 1);
    });
  }

  changeHandler(field, e) {
    let errors = this.state.errors;
    let fields = this.state.fields;

    errors["chargePowerError"] = "";
    fields[field] = e.target.value;

    this.setState({ fields, errors });
  }

  changeErrorCode(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.getErrorCode = document.getElementById('finderrorCode')

    this.setState({ fields });
  }

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      this.setState({ activePage: pageNumber }, () => {
        this.getMetreValues(this.state.pageSize, pageNumber);
      });
    }
  }

  businessPageChange(businesspageNumber) {
    if (businesspageNumber !== this.state.businessactivePage) {
      this.setState({ businessactivePage: businesspageNumber }, () => {
        this.getBusinessErrors(this.state.businessPageSize, businesspageNumber);
      });
    }
  }

  showEntriesChange2() {
    var entries = document.getElementById("showEntries2").value;

    this.setState({
      businessPageSize: entries,
      businessactivePage: 1
    }, () => {
      this.getBusinessErrors(entries, 1);
    });
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries1").value;

    this.setState({
      pageSize: entries,
      activePage: 1
    }, () => {
      this.getMetreValues(entries, 1);
    });
  }

  closeMeterValuesCalender(type, event) {
    this.setState({
      calenderfromDisplay: type === "from" ? "none" : "block"
    })
  }

  displayfromCalender(type, event) {
    this.setState({
      calenderfromDisplay: type === "from" ? "block" : "none"
    })
  }

  closeBusinessCalender(type, event) {
    this.setState({
      businessCalenderDisplay: type === "to" ? "none" : "block"
    })
  }

  displayBusinessCalender(type, event) {
    this.setState({
      businessCalenderDisplay: type === "to" ? "block" : "none"
    })
  }

  // date picker onchange function
  onChangeMeterValueDate(value, event) {
    if (value !== null && value !== "") {
      var startDate = moment(value[0]).format("YYYY-MM-DD").toUpperCase();
      var endDate = moment(value[1]).format("YYYY-MM-DD").toUpperCase();

      this.setState({
        value: value,
        calenderfromDisplay: "none",
        startDate: startDate,
        endDate: endDate
      }, () => {
        this.getMetreValues(this.state.pageSize, 1);
      });
    } else {
      this.setState({
        value: value,
        calenderfromDisplay: "none"
      })
    }
  }

  onChangeBusinessDate(value2, event) {
    if (value2 !== null && value2 !== "") {
      var businessstartDate = moment(value2[0]).format("YYYY-MM-DD").toUpperCase();
      var businessendDate = moment(value2[1]).format("YYYY-MM-DD").toUpperCase();

      this.setState({
        value2: value2,
        businessstartDate: businessstartDate,
        businessendDate: businessendDate,
        businessCalenderDisplay: "none"
      }, () => {
        this.getBusinessErrors(this.state.businessPageSize, 1);
      });
    } else {
      this.setState({
        value2: value2,
        businessCalenderDisplay: "none"
      });
    }
  }

  // get Date
  convertMinSec(time) {
    if (time !== undefined) {
      var time_min = time.split(":");
      var day_min = time_min[0] * 1440;
      var hr_min = time_min[1] * 60;
      var min = time_min[2];
      var total_min = Math.round(parseInt(day_min) + parseInt(hr_min) + parseInt(min));
      return total_min + " min / " + time_min[3] + "sec";
    }
  }

  changeColor() {
    var bgColor1 = '';
    var bgColor2 = '';
    var tableView = "";
    var graphView = "";
    if (this.state.color_black === true) {
      bgColor1 = "active";
      bgColor2 = "";
      tableView = "none";
      graphView = "block";
      this.getMeterValuesChart();
    } else {
      bgColor1 = "";
      bgColor2 = "active";
      tableView = "block";
      graphView = "none";

      this.setState({ activePage: 1 }, () => {
        this.getMetreValues(this.state.pageSize, 1);
      })
    }

    this.setState({ color_black: !this.state.color_black, bgColor1: bgColor1, bgColor2: bgColor2, tableView: tableView, graphView: graphView })
  }

  onBack() {
    this.props.history.push("/transactions");
  }

  componentDidMount() {
    let gettransData = this.props.location.state
    if (gettransData !== undefined) {
      localStorage.setItem('savetrnsData', JSON.stringify(this.props.location.state)); //stringify object and store
    } else {
      var getSavedtransdata = JSON.parse(localStorage.getItem('savetrnsData')); //retrieve the object
      this.props.location.state = getSavedtransdata
    }

    var transaction = this.props.location.state.dataArray;

    this.setState({ transactionId: transaction?.transactionId, chargePointId: transaction?.chargePointId, billingId: transaction?.billId }, () => {
      this.getBusinessErrors(this.state.businessPageSize, this.state.businessactivePage);
      this.getMeterValuesChart();
      this.getSessionData(this.state.transactionId);
      console.log(transaction.billId);
      if (transaction.billId === undefined || transaction.billId === null || transaction.billId === "") {
        console.log("getBillDetails called..!");
      } else {
        this.getBillDetails(this.state.billingId);
      }
    });
  }

  async getBillDetails(Id) {

    let url = baseUrl.WebPath + "billings/detail/" + Id;

    await fetch(url, headers.getHeaders())
      .then(response => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 401) {
          this.toggle();
          localStorage.clear();
        } else if (response.status == 500) {
          alert('Internal Server Error');
        }
      })
      .then(result => {
        console.log(result, "resp");
        if (result != undefined) {
          let fields = this.state.fields;

          fields['paymentDate'] = result.paymentDate;
          fields['paymentTransactionId'] = result.paymentTransactionId;
          fields['serialNumber'] = result.serialNumber;
          fields['rate'] = result.rate;
          fields['totalKwh'] = result.totalKwh;
          fields['totalTime'] = result.totalTime;
          fields['chargedKwh'] = result.chargedKwh;
          fields['chargedTime'] = result.chargedTime;
          fields['status'] = result.status;
          fields['paidAmount'] = result.paidAmount;
          fields['ccFee'] = result.ccFee;
          fields['refundAmount'] = result.refundAmount;
          fields['billAmount'] = result.billAmount;
          fields['chargingSessionCurrency'] = result.chargingSessionCurrency;
          this.setState({
            fields: fields
          });
        }
      })
      .catch(error => {
        console.error(error);
      })
  }

  async getSessionData(trans_id) {
    document.getElementById("loader_image_div").style.display = "block";
    var url = baseUrl.URLPath + servicesUrl.getTransactionsByTransId + "?tranId=" + trans_id + "&chargepointid=" + this.state.chargePointId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      document.getElementById("loader_image_div").style.display = "none";
      this.setState({ transData: response });
    }
  }

  async getMetreValues(pageSize, pageNo) {
    let errors = {};
    let value = this.state.fields.findValue;
    if (value !== "" && this.state.errors["chargePowerError"] !== "" && this.state.errors["chargePowerError"] !== undefined) {
      value = "";
    }
    var url = baseUrl.URLPath + servicesUrl.getTransMeterValue + "?transId=" + this.state.transactionId + "&chargepointid=" + this.state.chargePointId + "&value=" + value + "&fromDate=" + moment(this.state.startDate).format("MM-DD-YYYY") + "&toDate=" + moment(this.state.endDate).format("MM-DD-YYYY") + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeadersWithTimezone();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      if (response.count.count === 0) {
        if (this.state.fields.findValue !== "") {
          errors["chargePowerError"] = this.props.t('value_not_exists');

          this.setState({ errors });
        }
      } else {
        this.setState({
          dataArray: response.meterValues,
          noOfRecords: response.count.count
        })
      }
    }

  }


  // api to get business errors
  async getBusinessErrors(businessPageSize, businesspageNo) {
    let errors = this.state.fields.finderrorCode;
    let url = baseUrl.URLPath + servicesUrl.transactionsGetBusinessErrors + "?transId=" + this.state.transactionId + "&chargepointid=" + this.state.chargePointId + "&errorCode=" + errors + "&fromDate=" + this.state.businessstartDate + "&todate=" + this.state.businessendDate + "&pageNo=" + businesspageNo + "&pageSize=" + businessPageSize;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      this.setState({
        businessError: response.businessErrors,
        businessRecords: response.count.count
      })
    }
  }

  //api to get metre values chart
  async getMeterValuesChart() {
    var chart_Date = [];
    var chart_Count = [];
    var url = baseUrl.URLPath + servicesUrl.getTransactionsMetervalueBytransid + "?transId=" + this.state.transactionId + "&chargePointId=" + this.state.chargePointId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      for (var i = 0; i < response.data.length; i++) {
        var date = moment(response.data[i].valueTime).format("hh:mm A").toUpperCase();
        chart_Date.push(date);
        chart_Count.push(response.data[i]["chargingPower"]);
      }
      let dataLine = {
        labels: chart_Date,
        datasets: [
          {
            data: chart_Count,
            label: "kW",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "rgba(225, 204,230, .3)",
            borderColor: "#00529E",
          }
        ]
      }

      this.setState({ datasets: response.data, dataLine: dataLine, meterValuesChart: response.data })
    }
  }


  async onClickRefresh(pageSize, pageNo) {
    document.getElementById("loader_image_div").style.display = "block";
    let value = this.state.fields.findValue;
    var url = baseUrl.URLPath + servicesUrl.getTransMeterValue + "?transId=" + this.state.transactionId + "&chargepointid=" + this.state.chargePointId + "&value=" + value + "&fromDate=" + moment(this.state.startDate).format("MM-DD-YYYY") + "&toDate=" + moment(this.state.endDate).format("MM-DD-YYYY") + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeadersWithTimezone();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      document.getElementById("loader_image_div").style.display = "none";
      this.setState({
        dataArray: response.meterValues,
        noOfRecords: response.count.count
      })
    }
    this.getMeterValuesChart();
    this.getBusinessErrors(this.state.businessPageSize, this.state.businessactivePage);
    this.getSessionData(this.state.transactionId);
    this.getBillDetails(this.state.billingId)
  }


  onChangeRefresh() {
    this.setState({ activePage: 1 }, () => {
      this.onClickRefresh(this.state.pageSize, 1)
    });
  }

  async downloadSafeEVFile() {
    var url = baseUrl.URLPath + servicesUrl.downloadSafeEvFiles + "?transactionId=" + this.state.transactionId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeadersWithTimezone();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false, true);

    if (response !== undefined) {
      let url = window.URL.createObjectURL(response);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'Chargingstation_' + this.state.transData.chargePointId + "_Sessionid_" + this.state.transactionId + '_SAFE_FILE.xml';
      a.click();
    }
  }

  render() {
    let totalBillingAmount = this.state.fields.billAmount + this.state.fields.ccFee;

    var paymentTime = '';
    var paymentTime1 = '';
    if (this.state.fields.paymentDate != null) {
      paymentTime = moment(this.state.fields.paymentDate).format("DD-MMM-YYYY").toUpperCase();
      paymentTime1 = moment(this.state.fields.paymentDate).format("HH:mm").toUpperCase();
    }

    const { t } = this.props;
    var startMeterDate = '';
    if (this.state.startDate !== null || this.state.startDate !== "") {
      startMeterDate = moment(this.state.startDate).format("DD MMM YYYY");
    }
    if (this.state.startDate === "") {
      startMeterDate = "";
    }
    var endMeterDate = '';
    if (this.state.endDate !== null || this.state.endDate !== "") {
      endMeterDate = moment(this.state.endDate).format("DD MMM YYYY");
    }
    if (this.state.endDate === "") {
      endMeterDate = "";
    }

    var startBusinessDate = '';
    if (this.state.businessstartDate !== null || this.state.businessstartDate !== "") {
      startBusinessDate = moment(this.state.businessstartDate).format("DD MMM YYYY");
    }
    if (this.state.businessstartDate === "") {
      startBusinessDate = "";
    }
    var endBusinessDate = '';
    if (this.state.businessendDate !== null || this.state.businessendDate !== "") {
      endBusinessDate = moment(this.state.businessendDate).format("DD MMM YYYY");
    }
    if (this.state.businessendDate === "") {
      endBusinessDate = "";
    }

    var startTime = '';
    var startTime1 = '';
    if (this.state.transData.startTime !== null) {
      startTime = moment(this.state.transData.startTime).format("DD-MMM-YYYY").toUpperCase();
      startTime1 = moment(this.state.transData.startTime).format("HH:mm").toUpperCase();
    }
    var stopTime = '';
    var stopTime1 = '';
    if (this.state.transData.stopTime !== null) {
      stopTime = moment(this.state.transData.stopTime).format("DD-MMM-YYYY").toUpperCase();
      stopTime1 = moment(this.state.transData.stopTime).format("HH:mm").toUpperCase();
    }
    var chargingGoalReachedTime = '';
    if (this.state.transData.chargingGoalReachedTime !== null) {
      chargingGoalReachedTime = moment(this.state.transData.chargingGoalReachedTime).format("DD-MMM-YYYY HH:mm").toUpperCase();
    }

    var timeStamp = "";
    var timeStamp1 = "";

    const errorsData = this.state.businessError.map((businessError, index) => {

      if (businessError.timeStamp === null || businessError.timeStamp === "") {
        timeStamp = "";
        timeStamp1 = "";
      } else {
        timeStamp = moment(businessError.timeStamp).format("DD-MMM-YYYY");
        timeStamp1 = moment(businessError.timeStamp).format("HH:mm").toUpperCase();
      }

      return (
        <tr>
          <td>{timeStamp && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {timeStamp}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {timeStamp1} </div></>} </td>
          <td>{businessError.internalErrorCode}</td>
          <td>{businessError.desc}</td>
        </tr>
      )
    });

    var valueTime = "";
    var valueTime1 = "";

    const data = this.state.dataArray.map((dataArray, index) => {
      if (dataArray.valueTime === null || dataArray.valueTime === "") {
        valueTime = "";
        valueTime1 = "";
      } else {
        valueTime = moment(dataArray.valueTime).format("DD-MMM-YYYY").toUpperCase();
        valueTime1 = moment(dataArray.valueTime).format("HH:mm").toUpperCase();
      }

      return (
        <tr>
          <td>{dataArray.value}</td>
          <td>{valueTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {valueTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {valueTime1} </div></>} </td>
          <td>{dataArray.chargingPower}</td>
          <td>{dataArray.unit}</td>
        </tr>
      )
    })

    var status_img = "";
    var status_text = "";
    if (this.state.transData.chargingSessionState === "activeCharging") {
      status_img = "activeCharging";
      status_text = t('active_charging');
    }
    if (this.state.transData.chargingSessionState === "Finished") {
      status_img = "Finished";
      status_text = t('finished');
    }
    if (this.state.transData.chargingSessionState === "activeNotCharging") {
      status_img = "activeNotCharging";
      status_text = t('active_not_charging');
    }
    if (this.state.transData.chargingSessionState === "prepairing") {
      status_img = "prepairing";
      status_text = t('prepairing');
    }
    if (this.state.transData.chargingSessionState === "activePreparing") {
      status_img = "prepairing";
      status_text = t('active_preparing');
    }
    if (this.state.transData.chargingSessionState === "Halted") {
      status_img = "Halted";
      status_text = t('halted');
    }

    let options = {
      responsive: true,
      legend: {
        labels: {
          fontColor: 'orange'
        }
      },
      scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'kW',
            fontColor: '#00529e'
          },
          ticks: this.state.displayMaxValues
        }],
        xAxes: [{
          scaleLabel: {
            display: true,
            fontColor: '#00529e'
          }
        }]
      }
    }

    return (
      <>
        <main class="content-div" >
          {/* page Title */}
          <p> {t('Charging Session')}
            <div className="breadcrumb_div">
              <Link to="./transactions">{t('Charging Session')}</Link> &gt; <span className="breadcrumb_page">{t('meter_values')}</span>
            </div>
          </p>
          {/* End page Title */}
          <div className="page-outerdiv container_h">
            {/* btn row */}
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button class="pull-right refresh-btn" type="button" onClick={this.onBack}><i className="fas fa-angle-left mr-2"></i>{t('back')}
                  </button>
                </div>
                <div className="pull-right">
                  <button class="pull-right refresh-btn" type="button" onClick={this.onChangeRefresh}>{t('refreshBtn')}<i className="fas fa-refresh ml-2"></i></button>
                </div>
              </div>
            </div>
            {/* end btn row */}

            <div className="row" >
              <div className="col-md-12">
                <div className="page-subtitle">
                  {t('meter_values')}
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="content-innerdiv">
                      <div>
                        <label>{t('charge_point_id')}</label>
                        <label>{this.state.transData.chargePointId}</label>
                      </div>
                      <div>
                        <label>{t('connector_id')}</label>
                        <label>{this.state.transData.connectorId}</label>
                      </div>
                      <div>
                        <label>{t('charging_duration_label')}</label>
                        <label><i class="far fa-clock"></i>{this.convertMinSec(this.state.transData.chargingDuration)}</label>
                      </div>
                      <div>
                        <label>{t('startValue')}</label>
                        <label>{this.state.transData.startValue}</label>
                      </div>
                      <div>
                        <label>{t('vehicle_mileage')}</label>
                        <label>{(this.state.transData.mileage !== null && this.state.transData.mileage !== "") ? this.state.transData.mileage : ""}</label>
                      </div>
                      <div>
                        <label>{t('vehicle_inventory_number')}</label>
                        <label>{(this.state.transData.vehicleInventoryNumber !== null && this.state.transData.vehicleInventoryNumber !== "") ? this.state.transData.vehicleInventoryNumber : ""}</label>
                      </div>
                      <div>
                        <label>{t('max_charging')}</label>
                        <label>{this.state.transData.maximumChargingPower}</label>
                      </div>
                      <div>
                        <label>{t('tagType')}</label>
                        <label>{this.state.transData.tagType}</label>
                      </div>
                      <div>
                        <label>{t('chargingStationLocation')}</label>
                        <label>{this.state.transData.chargingStationLocation}</label>
                      </div>
                      <div >
                        <label>{t('customerNumber')}</label>
                        <label>{this.state.transData.customerNumber}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4" >
                    <div className="content-innerdiv">
                      <div>
                        <label>{t('charging_status')}</label>
                        <label className="wrap-word  p-rel ">
                          <div class="click-to-top">
                            <img src={process.env.PUBLIC_URL + "/assets/img/cs-status/" + status_img + ".png"} style={{ "height": "16px" }} alt="" />
                            <span>{status_text}</span>
                          </div>
                        </label>
                      </div>
                      <div >
                        <label>{t('id_tag_label')}</label>
                        <label>{this.state.transData.idTag}</label>
                      </div>
                      <div >
                        <label>{t('startTime')}</label>
                        <label className="wrap-word">{startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>} </label>
                      </div>
                      <div >
                        <label>{t('chargingSessionDuration_label')}</label>
                        <label><i class="far fa-clock"></i>  {this.convertMinSec(this.state.transData.chargingSessionDuration)}</label>
                      </div>
                      <div >
                        <label>{t('goal_reached_time')}</label>
                        <label>{chargingGoalReachedTime}</label>
                      </div>
                      <div >
                        <label>{t('stopValue')}</label>
                        <label>{this.state.transData.stopValue}</label>
                      </div>
                      <div >
                        <label>{t('current_charging_power')}</label>
                        <label>{this.state.transData.currentChargingPower}</label>
                      </div>

                      <div >
                        <label>{t('referenceNumber')}</label>
                        <label>{this.state.transData.referenceNumber}</label>
                      </div>
                      {(this.state.transData.transactionEndOCMFData != null && this.state.transData.transactionEndOCMFData !== undefined && this.state.transData.transactionStartOCMFData != null && this.state.transData.transactionStartOCMFData !== undefined) &&
                        <>
                          <div>
                            <label>{t('PublicKey')}</label>
                            <label className='publicKey'>{this.state.transData.chargerPublicKey}</label>
                          </div>
                        </>
                      }


                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="content-innerdiv">
                      <div >
                        <label>{t('transaction_id')}</label>
                        <label>{this.state.transactionId}</label>
                      </div>
                      <div >
                        <label>{t('reservationId')}</label>
                        <label> {this.state.transData.reservationId}</label>
                      </div>
                      <div >
                        <label>{t('stopTime')}</label>
                        <label className="wrap-word">{stopTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {stopTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {stopTime1} </div> </>} </label>
                      </div>
                      <div >
                        <label>{t('chargedEnergy')}</label>
                        <label>{this.state.transData.chargedEnergy ? currencyFormatter.format(this.state.transData.chargedEnergy, { code: this.state.transData.currencyCode, symbol: '' }) + ' kWh' : "0 kWh"}</label>
                      </div>
                      <div >
                        <label>{t('stop_reason')}</label>
                        <label>{this.state.transData.stopReason}</label>
                      </div>
                      <div>
                        <label>{t('energyLevel')}</label>
                        <label>{(this.state.transData.energyLevel !== "" && this.state.transData.energyLevel !== null && this.state.transData.energyLevel !== undefined) ? 
                        this.state.transData.energyLevel : ""}
                        {
                          (this.state.transData.chargingType === 'ENERGY_LEVEL' || this.state.transData.chargingType === null) ? 'kWh' : 'hr'
                        }</label>
                      </div>
                      <div>
                        <label>{t('note')}</label>
                        <label>{this.state.transData.note}</label>
                      </div>
                      {(this.state.transData.transactionEndOCMFData != null && this.state.transData.transactionStartOCMFData != null && this.state.transData.transactionEndOCMFData !== undefined && this.state.transData.transactionStartOCMFData !== undefined) && <>
                        <div>
                          <label className='vertical_top mt-2'>{t('safe_ev_files')}</label>
                          <label><button type="button" data-test="button" className="border-0 rounded pull-right mr-2 p-1 pl-2 pr-2 safeev_btn" onClick={this.downloadSafeEVFile}> {t('download_files')} </button></label>
                        </div>
                      </>
                      }
                    </div>
                  </div>
                </div>
                <div className="page-subtitle">
                  {t('charging_session_costs')}
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className="content-innerdiv">
                      <div className="row">
                        <div className='col-md-3 content'>
                          <div>
                            <label>{t('plugged_in_time')}</label>
                            <label>{startTime && <> {startTime} {startTime1} </>} </label>
                          </div>
                          <div>
                            <label>{t('plugged_out_time')}</label>
                            <label>{stopTime && <> {stopTime} {stopTime1} </>} </label>
                          </div>
                        </div>
                        <div className='col-md-4 content'>
                          <div>
                            <label>{t('startValue')}</label>
                            <label>{this.state.transData.startValue ? this.state.transData.startValue + " Wh" : null}</label>
                          </div>
                          <div>
                            <label>{t('stopValue')}</label>
                            <label>{this.state.transData.stopValue ? this.state.transData.stopValue + " Wh" : null}</label>
                          </div>
                          <div>
                            <label>{t('chargedEnergy')}</label>
                            <label>{this.state.transData.chargedEnergy ? currencyFormatter.format(this.state.transData.chargedEnergy, { code: noDecimalCurrencies.includes(this.state.transData.currencyCode) ? 'USD' : this.state.transData.currencyCode, symbol: '' }) + ' kWh' : '0 kWh'}</label>
                          </div>
                        </div>
                        <div className='col-md-5 content'>
                          <div>
                            <label>{t('costsPerChargingSession')}</label>
                            <label>{currencyFormatter.format(this.state.transData.costsPerChargingSession, { code: this.state.transData.currencyCode, symbol: '' })}{' '}{this.state.transData.chargingSessionCurrency}</label>
                          </div>
                          <div>
                            {
                              (this.state.transData.chargingType === 'ENERGY_LEVEL' || this.state.transData.chargingType === null) ?
                                <label>{t('costsPerKwh')}</label> :
                                <label>{t('costPerHr')}</label>
                            }
                            {
                              (this.state.transData.chargingType === 'ENERGY_LEVEL' || this.state.transData.chargingType === null) ?
                                <label>                                  
                                  {currencyFormatter.format(this.state.transData.costsPerKwh, { code: noDecimalCurrencies.includes(this.state.transData.currencyCode) ? 'USD' : this.state.transData.currencyCode, symbol: '' })}{' '}{this.state.transData.chargingSessionCurrency}
                                </label> :
                                <label>                                  
                                  {currencyFormatter.format(this.state.transData.costsPerTimeHr, { code: noDecimalCurrencies.includes(this.state.transData.currencyCode) ? 'USD' : this.state.transData.currencyCode, symbol: '' })}{' '}{this.state.transData.chargingSessionCurrency}
                                </label>
                            }
                          </div>
                          {/* <div>
                            <label style={{ fontSize: '16px' }}>{t('chargingSessionCost')}</label>
                            <label style={{ fontSize: '16px', fontWeight: 'bold' }}>{currencyFormatter.format(this.state.transData.chargingSessionCost, { code: this.state.transData.currencyCode, symbol: '' })}{' '}{this.state.transData.chargingSessionCurrency}</label>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.fields.paymentTransactionId ? (
                  <>
                    <div className="page-subtitle">
                      {t('bill_details')}
                    </div>
                    <div className='row mt-2'>
                      <div className='col-md-6'>
                        <div className='content-innerdiv'>
                          <div>
                            <span><b> Payment Transaction Id : </b></span>
                            <label>{this.state.fields.paymentTransactionId}</label>
                          </div>
                          <div style={{ display: "flex" }}>
                            <span><b> Payment Date : </b></span>&nbsp;
                            {/* <label>{this.state.fields.paymentDate}</label> */}
                            <label className="wrap-word">{paymentTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm pr-1"></i>{paymentTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {paymentTime1} </div> </>} </label>
                          </div>
                          {/* <div>
                        <span><b> Serial No : </b></span>
                        <label>{this.state.fields.serialNumber}</label>
                      </div> */}
                          {/* <div>
                        <span><b> Total Charged (kWh) : </b></span>
                        <label>{this.state.fields.totalKwh}</label>
                      </div> */}
                          {/* <div>
                        <span><b> Charged (kWh) : </b></span>
                        <label>{this.state.fields.chargedKwh}</label>
                      </div> */}
                          <div>
                            <span><b> Status : </b></span>
                            <label>{this.state.fields.status}</label>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='content-innerdiv'>
                          {/* <div>
                        <span><b> Transaction Id : </b></span>
                        <label>{this.state.fields.paymentTransactionId}</label>
                      </div>
                      <div>
                        <span><b> Rate : </b></span>
                        <label>{this.state.fields.rate}</label>
                      </div>
                      <div>
                        <span><b> Total Time : </b></span>
                        <label>{this.state.fields.totalTime}</label>
                      </div>
                      <div>
                        <span><b> Charged Time : </b></span>
                        <label>{this.state.fields.chargedTime}</label>
                      </div> */}
                          <div>
                            <span><b>Total Amount Authorized : </b></span>
                            <label>{(this.state.fields.paidAmount === null || this.state.fields.paidAmount === undefined || this.state.fields.paidAmount === "") ? '0.00' : parseFloat(this.state.fields.paidAmount).toFixed(2)} {this.state.fields.chargingSessionCurrency}</label>
                          </div>
                          <div>
                            <span><b>Charged Amount : </b></span>
                            <label>{(this.state.fields.billAmount === null || this.state.fields.billAmount === undefined || this.state.fields.billAmount === "") ? '0.00' : parseFloat(this.state.fields.billAmount).toFixed(2)} {this.state.fields.chargingSessionCurrency}</label>
                          </div>
                          <div>
                            <span><b>Refund Amount : </b></span>
                            <label>{(this.state.fields.refundAmount === null || this.state.fields.refundAmount === undefined || this.state.fields.refundAmount === "") ? '0.00' : parseFloat(this.state.fields.refundAmount).toFixed(2)} {this.state.fields.chargingSessionCurrency}</label>
                          </div>
                          <div>
                            <span><b>Total Billing Amount : </b></span>
                            <label>{(totalBillingAmount === null || totalBillingAmount === undefined || totalBillingAmount === "") ? '0.00' : parseFloat(totalBillingAmount).toFixed(2)} {this.state.fields.chargingSessionCurrency}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className='row mt-3' style={{ margin: "1px", borderRadius: "10px" }}>
                  <div className='col-md-12 h4 text-center p-2' style={{ background: "whitesmoke" }}>Bill Details</div>
                </div>
                <div className='row mt-3'>
                  <div className='col-md-12'>
                    <div className='content-innerdiv'>
                      <div className="col-md-12 datatable">
                        <table className="table">
                          <thead>
                            <tr><th><span><b>Total Amount Authorized</b></span></th>
                              <th><span><b>Charged Amount</b></span></th>
                              <th><span><b>Refund Amount</b></span></th>
                              <th><span><b>Total Billing Amount</b></span></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>${(this.state.fields.paidAmount === null || this.state.fields.paidAmount === undefined || this.state.fields.paidAmount === "") ? '0.00' : parseFloat(this.state.fields.paidAmount).toFixed(2)}</td>
                              <td>${(this.state.fields.billAmount === null || this.state.fields.billAmount === undefined || this.state.fields.billAmount === "") ? '0.00' : parseFloat(this.state.fields.billAmount).toFixed(2)}</td>
                              <td>${(this.state.fields.refundAmount === null || this.state.fields.refundAmount === undefined || this.state.fields.refundAmount === "") ? '0.00' : parseFloat(this.state.fields.refundAmount).toFixed(2)}</td>
                              <td>${(totalBillingAmount === null || totalBillingAmount === undefined || totalBillingAmount === "") ? '0.00' : parseFloat(totalBillingAmount).toFixed(2)}</td>                                                </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> */}
                  </>
                ) : ""}
              </div>
            </div>

            {/* table start */}
          </div>
          <div className="page-outerdiv container_h">
            <div className="row">
              <div className="col-md-6">
                <div className="page-subtitle">{this.props.t('charging_power')}</div>
              </div>
              <div className="col-md-6">
                <div className="pull-right">
                  <label className="pull-left sub-text"> {this.props.t('view')} </label>
                  <div className="toggle-btnb">
                    <label className={this.state.bgColor1} onClick={this.changeColor.bind(this)}>{this.props.t('graph')}</label>
                    <label className={this.state.bgColor2} onClick={this.changeColor.bind(this)}>{this.props.t('table')}</label>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: this.state.tableView }}>
              <div className="row" id="tableView">
                <div className="col-md-3">
                  <div class="input-group">
                    <i className="fas fa-search fa-sm input-searchIcon"></i>
                    <input type="text" id="findValue" value={this.state.fields["findValue"]} onChange={this.changeHandler.bind(this, "findValue")} onKeyDown={this.keyPress3} class="form-control input-searchBox pad-2" placeholder={t('find_a_value')} />
                    <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.clearValue}>
                      <span aria-hidden="true">x</span>
                    </button>
                    <div class="input-group-btn">
                      <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeValue}>{t('find')}</button>
                    </div>
                  </div>
                  <span className="error_msg w3-animate-top mt-2">
                    <span style={{ color: "red" }}>{this.state.errors["chargePowerError"]}</span>
                  </span>
                </div>

                <div className="col-md-3">
                  <div class="input-group">
                    <input type="text" class="form-control input-searchBox" value={(startMeterDate !== "" || endMeterDate !== "") ? (startMeterDate + " - " + endMeterDate) : ""} placeholder={this.props.t('date')} name="srch-term" id="srch-term" />
                    <div class="input-group-btn">
                      <button class="input-searchBtn" type="submit" onClick={this.displayfromCalender.bind(this, 'from')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                    </div>
                  </div>

                  <div style={{ display: this.state.calenderfromDisplay }} className="calenderContainer">
                    <div onClick={this.closeMeterValuesCalender.bind(this, 'from')}><i class="far fa-times-circle"></i></div>
                    <Calendar
                      onChange={this.onChangeMeterValueDate}
                      value={this.state.value}
                      selectRange="true"
                      format="dd-M-yyyy"
                      dayPlaceholder=""
                      yearPlaceholder=""
                      monthPlaceholder=""
                      nativeInputAriaLabel="Date"
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pull-right">
                    <label className="sub-text pull-left" >{this.props.t('show_entries')}</label>
                    <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                      <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries1" onChange={this.showEntriesChange}>
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>40</option>
                        <option>50</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>

                <div className="col-md-12 mt-2">
                  <Table striped hover size="sm" className="page-table" >
                    <thead>
                      <tr>
                        <th className="pl-4">{this.props.t('value')}</th>
                        <th>{this.props.t('value_time')}</th>
                        <th>{this.props.t('charging_power')}(kW)</th>
                        <th>{this.props.t('unit')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data}
                    </tbody>
                  </Table>
                </div>

                <div className="col-md-12 mt-2 mb-4 text-right">
                  <Pagination
                    hideDisabled
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.pageSize}
                    totalItemsCount={this.state.noOfRecords}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: this.state.graphView }} >
              <div className="row" id="graphView"  >
                <div className="col-md-12">
                  <div className="mt-1 img-main-div" >
                    <Line data={this.state.dataLine} height={"105px"} options={options} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* businessErrors start table */}
          <div className="page-outerdiv container_h" style={{ marginBottom: '45px' }}>
            <div className="row">
              <div className="col-md-2">
                <div className="page-subtitle">{this.props.t('error_codes')}</div>
              </div>
              <div className="col-md-3 mt-2">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" class="form-control input-searchBox pad-2" placeholder={this.props.t('filter_error_code')} name="srch-term"
                    id="finderrorCode"
                    value={this.state.fields["finderrorCode"]}
                    onChange={this.changeErrorCode.bind(this, "finderrorCode")}
                  />
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeErrorCode}>{this.props.t('find')}</button>
                  </div>
                </div>
              </div>
              <div className="col-md-3  mt-2">
                <div class="input-group">
                  <input type="text" class="form-control input-searchBox pl-3" placeholder={this.props.t('date')} name="srch-term" id="srch-term" value={(startBusinessDate !== "" || endBusinessDate !== "") ? (startBusinessDate + " - " + endBusinessDate) : ""} />
                  <div class="input-group-btn">
                    <button class="input-searchBtn" type="submit" onClick={this.displayBusinessCalender.bind(this, 'to')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                  </div>
                </div>

                <div style={{ display: this.state.businessCalenderDisplay }} className="calenderContainer">
                  <div onClick={this.closeBusinessCalender.bind(this, 'to')}><i class="far fa-times-circle"></i></div>
                  <Calendar
                    onChange={this.onChangeBusinessDate}
                    value={this.state.value2}
                    selectRange="true"
                    format="dd-M-yyyy"
                    dayPlaceholder=""
                    yearPlaceholder=""
                    monthPlaceholder=""
                    nativeInputAriaLabel="Date"
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="pull-right">
                  <label className="sub-text pull-left" >{this.props.t('show_entries')}</label>
                  <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries2" onChange={this.showEntriesChange2}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="pl-4">{this.props.t('time_stamp')}</th>
                      <th>{this.props.t('internal_error_code')}</th>
                      <th>{this.props.t('description')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorsData}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.businessRecords}
                  onChange={this.businessPageChange.bind(this)}
                />
              </div>
              {/* businessErrors end table */}

              {/* End table  */}
            </div>
          </div>
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/ajax-loading-gif-transparent-background-1.gif"} width="80" height="80" alt="" />
          </div>
        </main>
      </>
    );
  }
}

export default withTranslation()(meterValues);