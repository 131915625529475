import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import ChargePointSelection from './chargePointSelection';
import apiCallUtility from '../common/APICallUtility'
import * as constants from './constants';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class reset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      cpList: [],
      sucess: false,
      colour: 'sucess',
      errors: {},
      chargePoint: '',
      operationId: ''
    }
    this.getSelectedCP = this.getSelectedCP.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }
  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  async handleSubmit(event) {
    var payload = this.getPayLoad();
    var errors = {};
    var type = payload.type;
    if (this.getCPList().length === 0) {
      errors["emptyChargePoint"] = this.props.t('select_charge_point')
    }
    if (type === '') {
      errors["emptyType"] = this.props.t('select_reset_type')
    }

    if (this.getCPList().length !== 0 && type !== '') {
      var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList(), this.props.t);

      if (response === this.props.t('unable_toprocess_request_try_after_some_time')) {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, () => {
          this.onShowAlert();
        })
      } else if (response === 'Request is processed Successfully') {
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, () => {
          this.onShowAlert();
        })
      }
    }
    this.setState({ errors: errors })

  }

  getCPList() {
    var Id = this.state.operationId;
    var cplist = "";
    if (Id !== null && Id !== "") {
      cplist = Id.split();
    } else {
      cplist = this.state.cpList;
    }

    return cplist;
  }

  getPayLoad() {
    var payload = {
      "type": this.state.type
    }

    return payload;
  }

  getMessageType() {
    return constants.RESET;
  }

  getSelectedCP(cpList) {
    let errors = this.state.errors;
    if (cpList !== "") {
      errors["emptyChargePoint"] = "";
    }

    this.setState({ cpList: cpList, errors });
  }

  handleChange() {
    var x = document.getElementById("type").value;
    let errors = this.state.errors;
    if (x !== "") {
      errors["emptyType"] = "";
    }

    this.setState({ type: x, errors });
  }

  componentWillUnmount() {
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }

  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata !== undefined) {
      if (propsdata.chargePoint !== undefined && propsdata.operationId !== undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }
  }

  render() {
    const { t } = this.props;

    return (
      <main className="content-div" >
        <p> {t('Reset')}
          <div className="breadcrumb_div">
            {t('Operations')} &gt; <span className="breadcrumb_page">  {t('Reset')}  </span>
          </div>
        </p>
        <div className="page-outerdiv">
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
          <MDBRow className="mb-4">
            <MDBCol sm="12">
              <div>
                <form className="needs-validation">
                  <MDBRow>
                    <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="Reset"></ChargePointSelection>
                    <MDBCol md="12">
                      <span className="selection_charge_point_error w3-animate-top"> <span style={{ color: "red" }}>{this.state.errors["emptyChargePoint"]}</span></span>
                    </MDBCol>
                    <div className="pl-3 pr-3">
                      <label className="input_label pt-2">{t('reset_type')}</label>
                    </div>
                    <MDBCol md="4">
                      <select className="browser-default custom-select select_height mb_8" id="type" onChange={this.handleChange}>
                        <option value="">-- {t('select_reset')} *--</option>
                        <option value="Hard">{t('hard')}</option>
                        <option value="Soft">{t('soft')}</option>
                      </select>
                      <span className="error_msg w3-animate-top"> <span style={{ color: "red" }}>{this.state.errors["emptyType"]}</span></span>
                    </MDBCol>
                  </MDBRow>
                  <button type="button" className="mt-5 refresh-btn" onClick={this.handleSubmit} >{t('perform')}</button>
                </form>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </main>
    );
  }
}

export default withTranslation()(reset);