import React from 'react';
import { MDBCol, MDBModal, MDBModalBody, MDBModalFooter, MDBRow } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Pagination from "react-js-pagination";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { getDateRange, dateRangeValidateForHeartbeat, dateformatinUTC } from './_methods';
import { withTranslation } from 'react-i18next';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';
import './cpDashboard.css';

class Heartbeat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            isCheckboxReadonly: false,
            checked: false,
            cpIdSorting: false,
            modalView: false,
            calenderFrom: "none",
            calenderTo: "none",
            fields: {
                OperationNameC: "",
                chargePointId: ""
            },
            fromDate: '',
            toDate: '',
            fromDateApi: '',
            toDateApi: '',
            page_title: "CP Logs",
            collapseID: "",
            items: [],
            operationData: [],
            dataArray: [],
            noOfRecords: '',
            data: new Map(),
            pageSize: 10,
            activePage: 1,
            pageNo: 1,
            messageId: '',
            curPageFirstCreTime:"",
            curPageLastCreTime:"",
            prevPageFirstCreTime:"",
            listOfFirstCreatedtimes:[]
        }
        this.GetFromDate = this.GetFromDate.bind(this);
        this.getOperationLog = this.getOperationLog.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClick1 = this.handleClick1.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.toggleView1 = this.toggleView1.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);
        this.createdDatesArray = this.createdDatesArray.bind(this);
    }

    toggleView(messageId, requestMessage, responseMessage) {
        this.setState({ 
            displaymessage: messageId,
            displayReq: requestMessage,
            displayRes: responseMessage,
            modalView: !this.state.modalView
        });
    }

    toggleView1() {
        this.setState({
            modalView: !this.state.modalView
        });
    }

    handleClick() {
        let fields = this.state.fields;
        let errors = this.state.errors;
        fields.chargePointId = "";
        errors["entercharge"] = "";
        $("#notexists").text("");
        this.setState({ fields, errors, pageSize: 10, activePage: 1 }, ()=>{
            this.getOperationLog(this.state.pageSize, this.state.activePage);
        });
    }

    handleClick1() {
        this.setState({
            messageId: "",
            pageSize: 10,
            activePage: 1
        }, ()=>{
            this.getOperationLog(this.state.pageSize, 1);
            $(".error_emptymessageid").text("");
            $(".error_nomessageid").text("");
        });
    }

    changeHandler(field, e) {
        let fields = this.state.fields;
        let errors = this.state.errors;
        fields[field] = e.target.value;
        errors["entercharge"] = "";
        $("#notexists").text("");
        
        this.setState({ fields, errors },()=>{
            if (this.state.fields.chargePointId.length === 0) {
                this.setState({ activePage: 1 }, ()=>{
                    this.getOperationLog(this.state.pageSize, 1);
                });
            }
        });
    }

    keyPress(e) {
        var key = e.key;
        let errors = this.state.errors;

        if (e.keyCode === 13) {
            if (this.state.fields.chargePointId === "") {
                errors["entercharge"] = this.props.t('enter_charge_point_id');
                $("#notexists").text("");
                this.setState({ errors });
            } else {
                errors["entercharge"] = "";
                this.setState({ errors }, ()=>{
                    this.getOperationLog(this.state.pageSize, 1, "enter");
                });
            }
        }

        if (key === "Backspace" || key === "Delete") {
            var x = e.target.value
            var sno = x.slice(0, x.length - 1)
            if (sno === "") {
                let fields = this.state.fields;
                fields.chargePointId = sno;

                this.setState({ fields }, ()=>{
                    this.getOperationLog(this.state.pageSize, 1);
                })
            }
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9., -]");
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    GetFromDate = date => {
        $('.future_err1').text("");
        this.setState({ fromDate: date });
        this.setState({
            fromDateApi: dateformatinUTC(date),
            activePage: 1
        }, () => {
            if (dateRangeValidateForHeartbeat(this.state.fromDate, this.state.toDate) === true) {
                $('.date_range_error').text('');
                this.getOperationLog(this.state.pageSize, 1);
            } else {
                $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('heartbeatDuration')} ${this.props.t('days')}`);
            }
        });
        this.setState({ disabledAttr: "" });
    };

    dateChange = date => {
        $('.future_err2').text("");
        this.setState({ toDate: date });
        this.setState({
            toDateApi: dateformatinUTC(date),
            activePage: 1
        }, () => {
            if (dateRangeValidateForHeartbeat(this.state.fromDate, this.state.toDate) === true) {
                $('.date_range_error').text('');
                this.getOperationLog(this.state.pageSize, 1);
            } else {
                $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('heartbeatDuration')} ${this.props.t('days')}`);
            }
        });
        this.setState({ disabledAttr: "" });
    }

    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;

        let fromDateApi = dateformatinUTC(moment(this.state.fromDate));
        let toDateApi = dateformatinUTC(moment(this.state.toDate));

        this.setState({
            activePage: 1,
            pageSize: entries,
            fromDateApi: fromDateApi,
            toDateApi: toDateApi
        }, () => {
            this.getOperationLog(entries, 1);
        });
    }

    createdDatesArray(dateTime) {
        let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
        this.setState({
            listOfFirstCreatedtimes: listOfFirstCreatedtimes
        });
    };

    handlePageChange(pageNumber) {
        if (pageNumber < this.state.activePage) {
            //prev
            this.setState({
                activePage: pageNumber,
                fromDateApi: this.state.curPageFirstCreTime,
                toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
            }, () => {
                this.getOperationLog(this.state.pageSize, pageNumber, "", false);
            })
        } else {
            //next
            let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
            this.setState({
                activePage: pageNumber,
                fromDateApi: dateformatinUTC(this.state.fromDate),
                toDateApi: this.state.curPageLastCreTime,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
            }, () => {
                if (pageNumber > _datesArrayLength + 1) {
                    this.createdDatesArray(this.state.curPageFirstCreTime);
                }
                this.getOperationLog(this.state.pageSize, pageNumber, "", false);
            })
        }
    }

    async getOperationLog(pageSize, pageNo, action, countValue) {
        document.getElementById("loader_image_div").style.display = "block";
        let opeName = this.state.OperationNameC;
        let chargePointId = this.state.fields.chargePointId
        var url = "";
        if (opeName === undefined) {
            url = baseUrl.URLPath + "cp/operations/heartbeat/logs?charge_point_id=" + chargePointId + "&start_date=" + this.state.fromDateApi + "&end_date=" + this.state.toDateApi + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
        }
        else {
            url = baseUrl.URLPath + "cp/operations/heartbeat/logs?charge_point_id=" + chargePointId + "&start_date=" + this.state.fromDateApi + "&end_date=" + this.state.toDateApi + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
        }
        let type = ApiMethodTypes.GET;
     let headers = getSecurityHeaders();
     let payload = {};

     let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);
     if (response !== undefined) {
        if (response.status === 400) {
            setTimeout(() => {
                document.getElementById("loader_image_div").style.display = "none";
                $('.date_range_error').text(response.message);
              }, 100);
        } else if ( response.count === null || response.count.count === 0) {
            setTimeout(() => {
                document.getElementById("loader_image_div").style.display = "none";
                if (action === "messageClick") {
                    $('.error_nomessageid').text(this.props.t('msg_id_not_exists'));
                }
                if (action === "click" || action === "enter") {
                    $("#notexists").text(this.props.t('charge_point_id_not_exists.'));
                }
                this.setState({
                    items: [],
                    noOfRecords: 0
                })
              }, 100);
        } else {
            setTimeout(() => {
                document.getElementById("loader_image_div").style.display = "none";
                var totalcount = "";
                if (countValue === false) {
                    totalcount = this.state.noOfRecords
                } else {
                    totalcount = response.count.count
                }
                this.setState({
                    prevPageFirstCreTime: this.state.curPageFirstCreTime,
                    items: response.logs,
                    noOfRecords: totalcount,
                    curPageFirstCreTime: moment(response.logs[0].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                    curPageLastCreTime: moment(response.logs[response.logs.length - 1].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                })
                if (action === "click" || action === "enter") {
                    $("#notexists").text(" ");
                    $("#messageid").text(" ");
                }
              }, 100);
        }
    }
    }

    componentDidMount() {
        let duration = localStorage.getItem('heartbeatDuration');

        let fromDate = moment().subtract(duration, 'd');
        let fromDateApi = dateformatinUTC(moment().subtract(duration, 'd'));
        let toDate = new Date();
        let toDateApi = dateformatinUTC(moment(toDate));
        this.setState({
            fromDate: new Date(fromDate),
            fromDateApi: fromDateApi,
            toDate: toDate,
            toDateApi: toDateApi,
        }, () => {
            getDateRange();
            this.getOperationLog(this.state.pageSize, this.state.activePage);
        })
    }

    //change function for chargepoint status filter
    onChangeChargepoint() {
        let errors = this.state.errors;
        if (this.state.fields.chargePointId === "") {
          errors["entercharge"] = this.props.t('enter_charge_point_id');
          $("#notexists").text("");
          this.setState({ errors })
        } else {
          errors["entercharge"] = "";
          this.setState({ errors }, ()=>{
            this.getOperationLog(this.state.pageSize, 1, "click");
          })
        }
      }

    //function for sorting table data on click
    ascendingOrder() {
        let items = this.state.items;
        if (this.state.cpIdSorting === false) {
          items = items.sort(this.dynamicSort("chargePointId"));
          this.setState({ items: items, cpIdSorting: true })
        } else {
          items = items.sort(this.dynamicSort("chargePointId"));
          this.setState({ items: items.reverse(), cpIdSorting: false });
        }
      }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a, b) {
            if (sortOrder === -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        }
    }

    render() {
        const{t}=this.props
        const operationData = this.state.items.map((item, index) => {
            var createdTime = "";
            var createdTime1 = "";
            var responseTime = "";
            var responseTime1 = "";

            if (item.responseMessage !== "null" || item.responseMessage !== "") { }
            if (item.createdTime === null || item.createdTime === "") {
                createdTime = "";
                createdTime1 = "";
            } else {
                createdTime = moment(item.createdTime).format("DD MMM YYYY");
                createdTime1 = moment(item.createdTime).format("HH:mm:ss");
            }

            if (item.responseTime === null || item.responseTime === "") {
                responseTime = "";
                responseTime1 = "";
            } else {
                responseTime = moment(item.responseTime).format("DD MMM YYYY");
                responseTime1 = moment(item.responseTime).format("HH:mm:ss");
            }

            return (
                <tr>
                    <td className="wrap-word">{createdTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdTime1} </div> </>}</td>
                    <td className="">{item.chargePonintId}</td>
                    <td className="">{item.name}</td>
                    <td className="wrap-word">{responseTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {responseTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {responseTime1} </div> </>}</td>
                    <td className=""><i class="fas fa-eye action-icon cursor_defult" title="View" onClick={this.toggleView.bind(this, item.messageId, item.requestMessage, item.responseMessage)} ></i></td>
                </tr>
            )
        });
        return (
            <>
                <main class="content-div" >
                    <p> {t('Heartbeat Logs')}
                        <div className="breadcrumb_div">
                            {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('Heartbeat Logs')}</span>
                        </div>
                    </p>
                    <div className="page-outerdiv">
                        <div className="row mt-2">
                            <div className="col-md-3 pull-right mt-2">
                                <div class="input-group">
                                    <i className="fas fa-search fa-sm input-searchIcon"></i>
                                    <input type="text" id="chargePointId" value={this.state.fields["chargePointId"]} onChange={this.changeHandler.bind(this, "chargePointId")} onKeyDown={this.keyPress}
                                        class="form-control input-searchBox pad-2" placeholder={t('enter_charge_point')} name="srch-term" />
                                    <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="input-group-btn">
                                        <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint} >{t('find')}</button>
                                    </div>
                                </div>
                                <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.state.errors["entercharge"]}</span></span>
                                <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>
                            </div>

                            <MDBCol md="4" className='pr-0 calWidth'>
                                <span className="cal_label mr-2">{t('from_time')}</span>
                                <div className="pull-left mt-2" style={{ width: "68%" }} >
                                    <DatePicker
                                        selected={this.state.fromDate}
                                        onChange={this.GetFromDate}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        id="expiryDateId"
                                        className="cal_margin tag_callogs"
                                        timeCaption="time"
                                        dateFormat="dd-MMM-yyyy HH:mm"
                                        maxDate={(this.state.toDate!==""&&this.state.toDate!==null)? new Date(this.state.toDate):new Date()}
                                    />
                                </div>
                                <div className="error_msg w3-animate-top col-md-12 future_err1 pl-0" style={{ color: "red" }}></div>
                            </MDBCol>

                            <MDBCol md="4" className='pr-0 calWidth'>
                                <span className="cal_label mr-2">{t('to_time')}</span>
                                <div className="pull-left mt-2" style={{ width: "68%" }} >
                                    <DatePicker
                                        selected={this.state.toDate}
                                        onChange={this.dateChange}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        id="expiryDateId"
                                        className="cal_margin tag_callogs"
                                        timeCaption="time"
                                        dateFormat="dd-MMM-yyyy HH:mm"
                                        minDate={new Date(this.state.fromDate)}
                                        maxDate={new Date()}
                                    />
                                </div>
                                <div className="error_msg w3-animate-top col-md-12 future_err2 pl-0" style={{ color: "red" }}></div>
                            </MDBCol>

                            {/* <div className='col-md-3'></div> */}
                            <div className="col-md-2 mt-2 pull-right">
		                      <button
		                        className="btn_primary pull-right mr-0"
		                        type="button"
		                        data-test="button"
		                        onClick={this.getOperationLog.bind(this, this.state.pageSize, this.state.activePage)}
		                      >
		                        {t('refreshBtn')} <i className="fas fa-refresh fa-sm ml-2"></i>
		                      </button>
		                    </div>
                      
                            <div className='col-md-9 date_range_error' style={{ color: "red" }}></div>
                            <div className="col-md-12 pt-1">
                                <div className="pull-right">
                                    <label className="pull-left sub-text">{t('show_entries')}</label>
                                    <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                        <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                                            <option>10</option>
                                            <option>20</option>
                                            <option>30</option>
                                            <option>40</option>
                                            <option>50</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>

                        {/* table start */}
                        <div className="row ">
                            <div className="col-md-12">
                                <Table striped hover size="sm" className="page-table">
                                    <thead>
                                        <tr>
                                            <th className="">{t('created_date_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                                            <th className="">{t('charge_point_id')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                                            <th className="">{t('operation_name')}</th>
                                            <th className="">{t('response_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                                            <th className="">{t('view')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {operationData}
                                        {
                                            (this.state.items.length === 0)? <td colSpan={5} style={{textAlign:"center"}}>{t('no_data_for_heartbeat_logs')}</td> : null
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            <div className="col-md-12 mt-2 mb-4 text-right">
                                <Pagination
                                    hideDisabled
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.pageSize}
                                    totalItemsCount={this.state.noOfRecords}
                                    onChange={this.handlePageChange.bind(this)}
                                    prevPageText={t('prev')}
                                    nextPageText={t('next')}
                                    itemClassPrev="prevBtn"
                                    pageRangeDisplayed="1"
                                    activeClass="activeLi active"
                                    itemClassNext="nextBtn"
                                    hideFirstLastPages={true}
                                />
                            </div>
                            {/* End table  */}
                        </div>
                    </div>

                    {/* Loader */}
                    <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                        <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                    </div>

                    {/* Popup for  View */}
                    <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size="lg" className="model_top">
                        <div data-test="modal-header" class="modal-header">
                            <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('view')}</div>
                        </div>
                        <MDBModalBody>
                            <MDBRow className="mb-4 mt-3" >
                                <MDBCol sm="12">
                                    <div className="view-page-row view-bg-row" >
                                        <b>{t('message_id')}</b>  <span className="wrap-word">
                                            {this.state.displaymessage}
                                        </span>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-4 mt-3" >
                                <MDBCol sm="12">
                                    <div className="view-page-row view-bg-row" >
                                        <label className="">
                                            {t('request_message')}
                                        </label>
                                        <label className="wrap-word">
                                            {this.state.displayReq}
                                        </label>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-4 mt-3" >
                                <MDBCol sm="">
                                    <div className="view-page-row view-bg-row">
                                        <label className="w-40">{t('response_message')}</label>
                                        <label className="wrap-word">{this.state.displayRes}</label>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter className="border-top-0 p-3">
                            <button onClick={this.toggleView1} className="refresh-btn">{t('close')}</button>
                        </MDBModalFooter>
                    </MDBModal>
                </main>
            </>
        );
    }
}

export default withTranslation()(Heartbeat);