import React from 'react';
import { withTranslation } from 'react-i18next';
import Chart from "react-apexcharts";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';

//API Imports
import * as baseUrl from '../constants';
import servicesUrl from '../../common/servicesUrl';
import APICallUtility from '../../common/APICallUtility';
import ApiMethodTypes from '../../common/ApiMethodTypes';
import { getSecurityHeaders } from '../../common/HttpRequestClass';

class chargingSessionsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userValue: [],
      chart_Date: [],
      chart_Count: [],
      startDate: null,
      endDate: null,
      value: [null, null],
      series: [{
        name: '',
        data: []
      }],
      options: {
        chart: {
          width: '100%',
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '40%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
          fill: '#00529e'
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
          colors: ['#00529e']
        },
        tooltip: {
          enabled: true,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    }

    this.onChange = this.onChange.bind(this);
    this.getChargingSessionData = this.getChargingSessionData.bind(this);
    this.refreshChargingSessions = this.refreshChargingSessions.bind(this);
  }

  //  api to get charging session chart
  componentDidMount() {

    var today = new Date(),
      date = ('0' + (today.getMonth() + 1)).slice(-2) + '/' + ('0' + (today.getDate())).slice(-2) + '/' + today.getFullYear();

    var now = new Date();
    now.setDate(now.getDate() - 7); // add -7 days to your date variable 
    var garphInitData = [moment(now).format("MM/DD/YYYY").toUpperCase(), date]
    this.setState({
      startDate: moment(now).format("MM/DD/YYYY").toUpperCase(),
      endDate: date,
      value: garphInitData
    }, () => {
      this.getChargingSessionData();
    })
  }

  async getChargingSessionData() {
    var timezone =  moment.tz.guess();
    var chart_Date = [], chart_Count = [];
    let chargePointId = localStorage.getItem("chargerId") ?? '';
    let startdate = moment(this.state.startDate).format("YYYY-MM-DD").toUpperCase();
    let enddate = moment(this.state.endDate).format("YYYY-MM-DD").toUpperCase();

    var url = baseUrl.URLPath + servicesUrl.getDashboardSessions + startdate + "&endDate=" + enddate + "&chargePointId=" + chargePointId+"&timeZone=" + timezone;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    document.getElementById("loader_image_div_charging_sessions").style.display = "block";

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

    if (response) {
      response = response.reverse();
      for (var i = 0; i < response.length; i++) {
        var date = moment(response[i].date).format("DD-MMM");
        chart_Date.push(date);
        chart_Count.push(response[i]["count"]);
      }
      var series = [{ data: chart_Count }];
      var data_options = { xaxis: { categories: chart_Date } };
      this.setState({ options: data_options, series: series })
    }
    document.getElementById("loader_image_div_charging_sessions").style.display = "none";
  }

  // date picker onchange function
  onChange(value, event) {
    if (value) {
      var startDate = moment(value[0]).format("MM/DD/YYYY").toUpperCase();
      var endDate = moment(value[1]).format("MM/DD/YYYY").toUpperCase();
      this.setState({ value: value, startDate: startDate, endDate: endDate }, () => { this.getChargingSessionData(); });
    } else { this.setState({ value: value }); }
  }

  refreshChargingSessions() {   
    this.getChargingSessionData();
  }

  render() {
    return (
      <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success dashboard-height">
        <div className="content-box">
          <div className="dashboard-subtitle">
            {this.props.t('charging_sessions')}
            <span className="pull-right cursor-pointer">
              <img src={process.env.PUBLIC_URL + "/assets/img/refresh_btn.svg"} width="22" onClick={this.refreshChargingSessions} />
            </span></div>
          <div className="mt-2 mb-5">
            <DateRangePicker
              onChange={this.onChange}
              value={this.state.value}
              selectRange="true"
              dayPlaceholder=""
              yearPlaceholder=""
              monthPlaceholder=""
              format="MM/d/y"
              nativeInputAriaLabel="Date"
            />
          </div>
          <div className="mt-3 bottom-pos pb-3 pr-3 mr-15 w-100">
            <Chart
              options={this.state.options}
              series={this.state.series}
              data={this.state.chart_Date}
              type="bar"
              width="100%"
              height="220"
            />
          </div>
        </div>
        {/* Loader */}
        <div className="loader center" id="loader_image_div_charging_sessions" style={{ display: "none", borderRadius: "18px" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>
      </div>
    )
  };
}

export default withTranslation()(chargingSessionsGraph);
