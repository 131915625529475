import React from 'react';
import { Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class NewSiteReservationRules extends React.Component{
    render(){
        const{t}=this.props;
        return (
          <div>
            <form>
              <div className="row mb-3 mt-3">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 md-form">
                      <input
                        name="guarantedSlotDuration"
                        type="text"
                        id="guarantedSlotDuration"
                        pattern="[0-9]*"
                        className="form-control"
                        onChange={this.props.updateSiteSettings}
                        value={this.props.guarantedSlotDuration}
                      />
                      <label
                        htmlFor="guarantedSlotDuration"
                        className={`${
                          this.props.guarantedSlotDuration !== undefined &&
                          this.props.guarantedSlotDuration !== null
                            ? "active"
                            : ""
                        } ml-20`}
                      >
                        {t('guaranted_slot_duration')} ({t('mins')}) *
                      </label>
                      <span className="error_msg w3-animate-top">
                        <span style={{ color: "red" }}>
                          {this.props.errors["guarantedSlotDuration"]}
                        </span>
                      </span>
                    </div>
                    <div className="col-md-6 md-form">
                      <input
                        name="cooldown"
                        type="text"
                        id="cooldown"
                        pattern="[0-9]*"
                        className="form-control"
                        onChange={this.props.updateSiteSettings}
                        value={this.props.cooldown}
                      />
                      <label htmlFor="cooldown" className={`${this.props.cooldown !== undefined && this.props.cooldown !== null? "active" : ""} ml-20`}>
                        {t('cooldown')} ({t('mins')}) *
                      </label>
                      <span className="error_msg w3-animate-top">
                        <span style={{ color: "red" }}>
                          {this.props.errors["cooldown"]}
                        </span>
                      </span>
                    </div>
                    <div className="col-md-6 md-form">
                      <Form.Check
                        custom
                        type="checkbox"
                        id={'group_reporting'}
                        label={t('reporting')}
                        checked = {this.props.isReportingChecked}
                        onChange={this.props.changeReportingChecked}
                        disabled 
                        style={{ paddingLeft: '1rem' }}                       
                      />
                    </div>
                    <div className="col-md-6 md-form">
                      <input
                        name="minimumChargingDuration"
                        type="text"
                        id="minimumChargingDuration"
                        pattern="[0-9]*"
                        className="form-control"
                        onChange={this.props.updateSiteSettings}
                        value={this.props.minimumChargingDuration}
                      />
                      <label
                        htmlFor="minimumChargingDuration"
                        className={`${
                          this.props.minimumChargingDuration !== undefined &&
                          this.props.minimumChargingDuration !== null
                            ? "active"
                            : ""
                        } ml-20`}
                      >
                        {t('minimum_charging_duration')} ({t('mins')}) *
                      </label>
                      <span className="error_msg w3-animate-top">
                        <span style={{ color: "red" }}>
                          {this.props.errors["minimumChargingDuration"]}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="text-right">
                    {
                        (this.props.isNewSite) ?
                            <button type="button" data-test="button" className="btn_primary mr-3 mb-2 Op-modal-close" onClick={this.props.saveNewSite}>
                                {t('save')} <i className="fas fa-save ml-2"></i>
                            </button> :
                            <button type="button" data-test="button" className="btn_primary mr-3 mb-2 Op-modal-close" onClick={()=>{this.props.updateExistingSite(this.props.id)}}>
                                {t('update')}
                            </button>
                    }
                    <button className="mt-5 btn_cancel" type="button" data-test="button" onClick={this.props.cancel}>
                        {t('cancel')} <i className="fas fa-times ml-2"></i>
                    </button>
                </div>
                </div>
              </div>
            </form>
          </div>
        );
    }
}

export default withTranslation()(NewSiteReservationRules);