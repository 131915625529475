import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import $ from "jquery";
import moment from 'moment';

//API Imports
import * as baseUrl from './constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

class viewInventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveMfgDate: "",
      fields: { 
        masterPassword: '', 
        chargePointName: '', 
        startSchedule: '', 
        serialNumber: '', 
        manufacturingDate: '', 
        mfgDate: '', 
        modelName: '', 
        partNumber: '', 
        mesSerialNumber: '', 
        fwVersion: '', 
        ocppPassword: '', 
        accessCode: '', 
        vendorCode: '' ,
        Model_Variant:'',
        mk3PublicKey:'',
      }
    }

    this.backToList = this.backToList.bind(this);
    this.getInventory = this.getInventory.bind(this);
  }

  backToList() {
    this.props.history.push('/inventoryList');
  }

  componentDidMount() {
    var cpid = this.props.location.state;

    this.setState({ serialNumber: cpid }, () => {
      this.getInventory(cpid);
    });
  }

  async getInventory(cpid) {
    var url = baseUrl.URLPath + servicesUrl.getCpInventory + "?cpid=" + cpid;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({
        ...this.state,
        fields: response,
        saveMfgDate: (response?.manufacturingDate !== null && response?.manufacturingDate !== "") ? new Date(response?.manufacturingDate) : ""
      });

      // $("#timecalender").hide();
    }
  }

  render() {
    const { t } = this.props;

    return (
      <main className="content-div" >
        <p>{t('Inventory')}
          <div className="breadcrumb_div">
            {t('Maintenance')} &gt;
            <Link to="/inventoryList">
              <span>{t('Inventory')} &gt; </span>
            </Link>
            <span className="breadcrumb_page">{t('view')}</span>
          </div>
        </p>

        <div className="page-outerdiv">
          <div className="row">
            <div className="col-md-12">
              <div className="pull-left">
                <button className="btn_primary" onClick={this.backToList}>
                  <i className="fa fa-angle-left mr-2" aria-hidden="true"></i> {t('back')}
                </button>
              </div>
            </div>
          </div>

          <form className="needs-validation" >
            <div className="row mb-4 mt-3">
              <div className="col-sm-6">
                <div className="content-innerdiv">
                  <div>
                    <label>{t('charge_point_id')} : </label>
                    <label>{this.state.fields["serialNumber"]}</label>
                  </div>

                  <div>
                    <label>{t('mf_date')} : </label>
                    { this.state.saveMfgDate ? 
                    <label id="timecalender">
                      <i className="fas fa-calendar-alt fa-sm mr-1" id="calendericon"></i>  {moment(this.state.saveMfgDate).format("DD MMM YYYY") +" "}
                      <i className="far fa-clock fa-sm mr-1" id="clockicon"></i> {moment(this.state.saveMfgDate).format("HH:mm")}
                    </label>
                     :    "" }
                  </div>

                  <div>
                    <label>{t('model')} : </label>
                    <label>{this.state.fields["modelName"]} </label>
                  </div>

                  <div>
                    <label>{t('part_no')} : </label>
                    <label>{this.state.fields["partNumber"]}</label>
                  </div>

                  <div>
                    <label>{t('mes_serial_no')} * : </label>
                    <label>{this.state.fields["mesSerialNumber"]}</label>
                  </div>

                  <div>
                    <label>{t('fw_version')} : </label>
                    <label>{this.state.fields["fwVersion"]}</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="content-innerdiv">
                  <div>
                    <label>{t('ocpp_password')} : </label>
                    <label>{this.state.fields["ocppPassword"]}</label>
                  </div>

                  <div>
                    <label>{t('vendor_code')} : </label>
                    <label>{this.state.fields["vendorCode"]}</label>
                  </div>

                  <div>
                    <label>{t('access_code')} : </label>
                    <label>{this.state.fields["accessCode"]}</label>
                  </div>

                  <div>
                    <label>{t('charge_point_name')} : </label>
                    <label>{this.state.fields["chargePointName"]}</label>
                  </div>

                  <div>
                    <label>{t('master_password')} : </label>
                    <label>{this.state.fields["masterPassword"]}</label>
                  </div>
                  {this.state.fields["Model_Variant"] ? <>
                  <div>
                    <label>{t('modelvariant')} : </label>
                    <label>{this.state.fields["Model_Variant"]}</label>
                  </div>
                  </> : ""
                   }

                 {this.state.fields["mk3PublicKey"] ? <>
                  <div>
                    <label>{t('mk3PublicKey')} : </label>
                    <label>{this.state.fields["mk3PublicKey"]}</label>
                  </div>
                  </>
                  :"" }

                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    );
  }
}

export default withTranslation()(viewInventory);