import React from 'react';
import { MDBCol } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Pagination from "react-js-pagination";
import $ from "jquery";
import Calendar from 'react-calendar';
import { activeDateFormat, dateRangeValidateForWeek, getDateRange } from './_methods';
//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders, getSecurityHeadersWithTimezone } from '../common/HttpRequestClass';
import './cpDashboard.css';

class cpPingPong extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewdisplay: "",
      cpIdSorting: false,
      modalView: false,
      errors: {},
      modalsession: false,
      fields: {
        chargePointId: ""
      },
      startDate: '',
      toDate: '',
      startDate1: '',
      startDate2: '',
      collapseID: "",
      items: [],
      errorData: [],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      calenderFrom: "none",
      calenderTo: "none",
    }
    this.GetFromDate = this.GetFromDate.bind(this);
    this.Logs = this.Logs.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
    this.exportCpPingPong = this.exportCpPingPong.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.changeScheduleFrom = this.changeScheduleFrom.bind(this);
    this.changeScheduleTo = this.changeScheduleTo.bind(this);
    this.keyPress1 = this.keyPress1.bind(this);
    this.keyPress2 = this.keyPress2.bind(this);
  }

  changeScheduleFrom(e) {
    this.setState({ startDate: e.target.value });
  }

  changeScheduleTo(e) {
    this.setState({ toDate: e.target.value });
  }

  keyPress1(e) {
    var key = e.key;
    $('.future_err1').text("");
    $('.date_range_error').text('');
    if (e.keyCode === 13) {
      if (new Date() >= new Date(e.target.value)) {
        let date = e.target.value;

        this.setState({ startDate: date, startDate1: moment(date).format("YYYY-MM-DD") }, ()=>{
          if (dateRangeValidateForWeek(this.state.startDate1, this.state.startDate2) === true) {
            this.Logs(this.state.pageSize, 1);
          } else {
            $('.date_range_error').text(`Please select date range between ${localStorage.getItem('listLogDuration')} days`);
          }
        });
        
      } else {
        this.setState({ startDate: "", startDate1: "" });
        $('.future_err1').text("You can't select future date");
      }
    }

    if (key === "Delete" || key === "Backspace") {
      this.setState({ startDate1: "" }, ()=>{
        this.Logs(this.state.pageSize, 1);
      });
    }
  }

  keyPress2(e) {
    var key = e.key;
    $('.future_err2').text("");
    $('.date_range_error').text('');

    if (e.keyCode === 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ toDate: e.target.value, startDate2: moment(e.target.value).format("YYYY-MM-DD") }, ()=>{
          if (dateRangeValidateForWeek(this.state.startDate1, this.state.startDate2) === true) {
            this.Logs(this.state.pageSize, 1);
          } else {
            $('.date_range_error').text(`Please select date range between ${localStorage.getItem('listLogDuration')} days`);
          }
        });
      } else {
        this.setState({ toDate: "", startDate2: "" });
        $('.future_err2').text("You can't select future date");
      }
    }

    if (key === "Delete" || key === "Backspace") {
      this.setState({ startDate2: "" }, ()=>{
        this.Logs(this.state.pageSize, 1);
      });
    }
  }

  handleClick() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    fields.chargePointId = "";
    errors["entercharge"] = "";

    this.setState({ fields, errors, pageSize: 10, activePage: 1 }, ()=>{
      this.Logs(this.state.pageSize, 1);
      $("#notexists").text(" ");
    });
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    let errors = this.state.errors;
    fields[field] = e.target.value;
    // fields.chargePointId = document.getElementById('chargePointId')
    errors["entercharge"] = "";

    this.setState({ fields, errors });
    $("#notexists").text("");
  }

  keyPress(e) {
    var key = e.key;
    let errors = this.state.errors;

    if (e.keyCode === 13) {
      if (this.state.fields.chargePointId === "") {
        errors["entercharge"] = "Please enter charge point id"
        $("#notexists").text("");
        this.setState({ errors });
      } else {
        errors["entercharge"] = "";
        this.setState({ errors }, ()=>{
          this.Logs(this.state.pageSize, 1, "enter");
        });
      }
    }

    if (key === "Backspace" || key === "Delete") {
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno === "") {
        let fields = this.state.fields;
        fields.chargePointId = sno;

        this.setState({ fields }, ()=>{
          this.Logs(this.state.pageSize, 1);
        })
      }
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }

  GetFromDate = date => {
    var fdate = this.formatCalDate1(date);

    this.setState({ startDate: fdate, startDate1: this.formatCalDate(date) });
    if (dateRangeValidateForWeek(this.state.startDate1, this.state.startDate2) === true) {
      $('.date_range_error').text('');
      this.Logs(this.state.pageSize, 1);
      this.setState({
        calenderFrom: "none"
      })
    } else {
      $('.date_range_error').text(`Please select date range between ${localStorage.getItem('listLogDuration')} days`);
      this.setState({
        calenderFrom: "none"
      })
    }
  }

  dateChange = date => {
    var fdate = this.formatCalDate1(date);
    this.setState({ toDate: fdate, startDate2: this.formatCalDate(date) });
    if (dateRangeValidateForWeek(this.state.startDate1, this.state.startDate2) === true) {
      $('.date_range_error').text('');
      this.Logs(this.state.pageSize, 1);
      this.setState({
        calenderTo: "none"
      })
    } else {
      $('.date_range_error').text(`Please select date range between ${localStorage.getItem('listLogDuration')} days`);
      this.setState({
        calenderTo: "none"
      })
    }
  }

  formatCalDate1(date) {
    var format = date.toISOString();
    var xyz = moment(format).format("DD MMM YYYY")
    return xyz;
  }

  // get date 
  formatCalDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    // return [year, month, day].join('-');
    return [year, month, day,].join('-');
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    this.setState({
      activePage: 1,
      pageSize: entries
    }, ()=>{
      this.Logs(entries, 1);
    });
  }

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData === undefined) {
        this.setState({ activePage: pageNumber });
        this.Logs(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
      }
    }
  }

  async Logs(pageSize, pageNo, action) {
    document.getElementById("loader_image_div").style.display = "block";
    let chargePoints = this.state.fields.chargePointId;
    var url = baseUrl.URLPath + "chargepoints/pingpong?cpid=" + chargePoints + "&fromTime=" + this.state.startDate1 + "&toTime=" + this.state.startDate2 + "&pageNo=" + pageNo + "&pageSize=" + pageSize;

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);
    if (response !== undefined) {
      if (response.status === 400) {
        $('.date_range_error').text(response.message);
      } else if (response.count.count === 0) {
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
          if (action === "click" || action === "enter") {
            $("#notexists").text("Charge point id not exists.");
          }
          this.setState({
            items: [],
            noOfRecords: 0
          });
        }, 100);
      } else {
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
          this.setState({
            items: response.pingPongs,
            noOfRecords: response.count.count
          })
        }, 100);
        if (action === "click" || action === "enter") {
          $("#notexists").text(" ");
        }
      }
    }
  }

  componentDidMount() {
    let fromDate = moment().subtract(6, 'd').format('YYYY-MM-DD');
    let toDate = moment().format('YYYY-MM-DD');

    let startdate = moment().subtract(6, 'd');
    let enddate = moment();

    this.setState({
      startDate: this.formatCalDate1(startdate),
      toDate: this.formatCalDate1(enddate),
      startDate1: fromDate,
      startDate2: toDate
    }, ()=>{
      getDateRange();
      this.Logs(this.state.pageSize, this.state.activePage);
    });
  }

  //change function for chargepoint status filter
  onChangeChargepoint() {
    let errors = this.state.errors;
    if (this.state.fields.chargePointId === "") {
      errors["entercharge"] = "Please enter charge point id";
      $("#notexists").text("");
      this.setState({ errors })
    } else {
      errors["entercharge"] = "";
      this.setState({ errors }, ()=>{
        this.Logs(this.state.pageSize, 1, "click");
      })
    }
  }

  async exportCpPingPong() {
    var url = baseUrl.URLPath + "chargepoints/pingpong/download/CSV?cpid=" + this.state.fields.chargePointId + "&fromTime=" + this.state.startDate1 + "&toTime=" + this.state.startDate2;

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeadersWithTimezone();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false, true);

    if (response !== undefined) {
      let d = new Date();
      let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
      let url = window.URL.createObjectURL(response);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'CP_PingPong_' + dformat + '.csv';
      a.click();
    }
  }

  //function to display calender popup
  displayCalender(type, event) {
    var calenderFrom = "";
    var calenderTo = "";
    if (type === "from") {
      calenderFrom = "block";
      calenderTo = "none";
    } else {
      calenderTo = "block";
      calenderFrom = "none";
    }
    this.setState({
      calenderFrom: calenderFrom,
      calenderTo: calenderTo
    })
  }

  //function to close calender popup
  closeCalender(type, event) {
    if (type === "from") {
      this.setState({
        calenderFrom: "none"
      })
    } else {
      this.setState({
        calenderTo: "none"
      })
    }
  }

  //function for sorting table data on click
  ascendingOrder() {
    let items = this.state.items;
    if (this.state.cpIdSorting === false) {
      items = items.sort(this.dynamicSort("chargePointId"));
      this.setState({ items: items, cpIdSorting: true })
    } else {
      items = items.sort(this.dynamicSort("chargePointId"));
      this.setState({ items: items.reverse(), cpIdSorting: false });
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  render() {
    const errorData = this.state.items.map((item, index) => {
      var pingTime = "";
      var pingTime1 = "";
      var pongTime = "";
      var pongTime1 = "";

      if (item.pingTime === null || item.pingTime === "") {
        pingTime = "";
        pingTime1 = "";
      } else {
        pingTime = moment(item.pingTime).format("DD MMM YYYY");
        pingTime1 = moment(item.pingTime).format("HH:mm");
      }

      if (item.pongTime === null || item.pongTime === "") {
        pongTime = "";
        pongTime1 = "";
      } else {
        pongTime = moment(item.pongTime).format("DD MMM YYYY");
        pongTime1 = moment(item.pongTime).format("HH:mm");
      }

      return (
        <tr key={`pingPong_${index}`}>
          <td>{item.chargePointId}</td>
          <td className="wrap-word">{pingTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {pingTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {pingTime1} </div> </>}</td>
          <td className="wrap-word">{pongTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {pongTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {pongTime1} </div> </>}</td>
        </tr>
      )
    });

    return (
        <main className="content-div" >
          <p>CP PingPong
            <div className="breadcrumb_div">
              Maintenance &gt; <span className="breadcrumb_page">CP PingPong</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row ">
              <div className="col-md-3 t-8">
                <div className="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="chargePointId" value={this.state.fields["chargePointId"]} onKeyDown={this.keyPress} onChange={this.changeHandler.bind(this, "chargePointId")} className="form-control input-searchBox pad-2" placeholder="Charge Point ID" name="adminAddress" />
                  <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="input-group-btn">
                    <button className="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint} >Find</button>
                  </div>
                  <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.state.errors["entercharge"]}</span></span>
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>
                </div>
              </div>
              <div className="col-md-3 p-rel mt-2">
                <div className="input-group">
                  <input type="text" className="form-control input-searchBox pl-3" placeholder="From Time" name="srch-term" id="srch-term" value={this.state.startDate} onChange={this.changeScheduleFrom} onKeyDown={this.keyPress1} />
                  <div className="input-group-btn">
                    <button className="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'from')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                  </div>
                </div>
                <div style={{ display: this.state.calenderFrom }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'from')}><i className="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.startDate}
                    onChange={date => this.GetFromDate(date)}
                    value={activeDateFormat(this.state.startDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    onBlur={this.closeCalender.bind(this, 'from')}
                    maxDate={(this.state.toDate !== "" && this.state.toDate !== null) ? new Date(this.state.toDate) : new Date()}
                  />
                </div>
                <span className="pull-left future_err1 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>

              <div className="col-md-3 p-rel mt-2">
                <div className="input-group">
                  <input type="text" className="form-control input-searchBox pl-3" placeholder="To Time" name="srch-term" id="srch-term" value={this.state.toDate} onChange={this.changeScheduleTo} onKeyDown={this.keyPress2} />
                  <div className="input-group-btn">
                    <button className="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'to')}> <i className="fas fa-calendar-alt fa-sm"></i></button>                      </div>
                </div>
                <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'to')}><i className="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.toDate}
                    onChange={date => this.dateChange(date)}
                    value={activeDateFormat(this.state.toDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    minDate={new Date(this.state.startDate)}
                    maxDate={new Date()}
                  />
                </div>
                <span className="pull-left future_err2 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>

              <MDBCol md="3" className=""></MDBCol>
              <div className='col-md-3'></div>
              <div className='col-md-9 date_range_error' style={{ color: "red", textAlign: "left" }}></div>
              <div className="col-md-12 pt-1">
                <Form.Group className="custom_select mb-0 pull-right ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
                <label className="pull-right sub-text">Show entries</label>
              </div>

            </div>

            {/* table start */}
            <div className="row ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="">Charge Point ID <i className="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>Ping Time <i className="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>Pong Time <i className="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorData}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right" >
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
              {/* End table  */}
            </div>
          </div>

          {/* Loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>
        </main>
    );
  }
}

export default cpPingPong;