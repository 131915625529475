import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";

export default class SuccessPopup extends React.Component {
    render() {
        let {image, content, btnText, open, onClickOperation} = this.props
       
        return (
            <MDBModal isOpen={open} size={(this.props.size!=="")? this.props.size : "md"} data-toggle="modal" backdrop="static" className={(this.props.ModalClass!=="" && this.props.ModalClass!==undefined && this.props.ModalClass!==null)? this.props.ModalClass:"model_top"} data-backdrop="static" data-keyboard="false">
                <MDBModalBody>
                <div className='d-flex flex-column justify-content-center align-items-center pt-3'>
                <img  src={image}
                  width="50"
                  height="50"
                  alt="" />
                <p className='gv-deactivate-p mt-3'>{content}</p>
                <button
                  className="gv-deactivate-success-btn"
                  type="button"
                onClick={onClickOperation}
                >
                  {btnText}
                </button>
              </div>
                
                
                </MDBModalBody>
                
            </MDBModal>
        );
    }
}