import React from 'react';
import { Alert } from 'reactstrap';

//API Imports
import * as baseUrl from './constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders, getHeaders } from '../common/HttpRequestClass';

class resetpassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { newpwd: '', confirmpwd: '', token: '' },
      reset_Password: false,
      errors: {},
      message: "",
      sucess: false,
      colour: 'sucess',
      Tokenexpired: false,
      Tokenmsg: ""
    }
    this.validateUrl = this.validateUrl.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.handleLogin = this.handleLogin.bind(this)
  }

  async validateUrl() {
    var location = window.location.href;
    var allurl = location.split("/");
    var n = allurl.length;
    this.state.fields["token"] = allurl[n - 2];

    var url = baseUrl.LoginPath + servicesUrl.verifyToken + allurl[n - 2];
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    // payload
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response.status === 404) {
      this.setState({ Tokenexpired: true, Tokenmsg: "Invalid forgot password link, please click on latest link or generate new forgot password link." });
      localStorage.clear()
    } else if (response.status === 400) {
      localStorage.clear()
      this.setState({ Tokenexpired: true, Tokenmsg: "This forgot password link got expired, please generate new forgot password link." });
    }
  }

  UNSAFE_componentWillMount() {
    this.validateUrl();
  }

  async handleSubmit(event) {
    event.preventDefault();
     let errors = {};
     let validPassword = true;
     var newpwd= this.state.fields['newpwd'];
     var confirmpwd= this.state.fields['confirmpwd'];
     if (newpwd === '' && confirmpwd === '') {
      errors["pwdempty"] = "Password  cannot be empty";
      errors["confirmpassword"] = "confirm password  cannot be empty";
    } else if (newpwd === '') {
      errors["pwdempty"] = "Password  cannot be empty";
    } else if (confirmpwd === '') {
      errors["confirmpassword"] = "Confirm password  cannot be empty";
    } else if (confirmpwd !== newpwd) {
      errors["confirmpassword"] = "New password and confirm password should be same";
    } else if (newpwd !== '') {
      if(newpwd.length <= 20) {
        if (!newpwd.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&+=])[A-Za-z\\d!@#$%^&+=]{8,}$")) {
          errors["entervalidpwd"] = "Password must have at least 8 characters that include at least 1 lowercase character (a-z), 1 uppercase character (A-Z), 1 number (0-9) and 1 sepcial character (!@#$%^&+=)";
          validPassword = false;
        }
      } else {
        errors["pwdexceedchars"] = "Password must not be more than 20 characters."
      }
        
    } else if (newpwd !== '' && confirmpwd !== '' && confirmpwd !== newpwd && validPassword) {
      errors["confirmpassword"] = "New password and confirm password should be same";
    }
    this.setState({errors: errors});
    
    if (newpwd !== '' && confirmpwd !== '' && confirmpwd === newpwd && validPassword) {
      let url = baseUrl.LoginPath + servicesUrl.resetPassword;
      let type = ApiMethodTypes.PUT;
      let headers = getHeaders();

      // payload
      let payload = {
        "password": this.state.fields.newpwd,
        "confirmPassword": this.state.fields.confirmpwd,
        "token": this.state.fields.token
      }

      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      if (response.status === 400) {
        if(response.message!==""){
          if(response.message==="password_shouldn_t_contain_name"){
             errors["entervalidpwd"] = "Password shouldn't contain first name or last name";
           }else if(response.message==="password_already_used"){
             errors["entervalidpwd"] = "Password is already used.";
           }
         }else{
           errors["entervalidpwd"] = "Password must have at least 8 characters that include at least 1 lowercase character (a-z), 1 uppercase character (A-Z), 1 number (0-9) and 1 sepcial character (!@#$%^&+=)";
         }
      } else if (response.status === 404) {
        errors["pwdempty"] = "Sorry the reset password link has expired.";
      } else if (response.message === "Success") {
        this.setState({ message: "Your password has been changed successfully!", colour: 'success', reset_Password: true }, () => {
          this.onShowAlert();
        });
      }
    }
    this.setState({ errors: errors });
 }

  handleChange(field, e) {
    
    let fields = this.state.fields;
    fields[field] = e.target.value;
    
    this.setState({ fields });
    
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 50000000)
    });
  }

  handleLogin() {
    this.props.history.push('/');
  }

  render() {
    if (this.state.Tokenexpired === true) {
      return (
        <h6 className="text-center pt-3">{this.state.Tokenmsg}</h6>
      )
    } else if (this.state.reset_Password === true) {
      return (
        <div className="login-page">
          <header>
            <section className="view intro-2">
              <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                      <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                        <div className="card-body">
                          <div className="col-md-12 login_icon">
                            <img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{ width: "138px" }} alt="" />
                          </div>
                          <p class="h4 text-center mb-3 pb-3 pt-2 title_font">Reset Password</p>
                          <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                          <div className="text-center" >
                            <button type='submit' className="btn login-btn btn-lg waves-effect waves-light" onClick={this.handleLogin}>Sign In</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </header>
        </div>
      )
    } else {
      return (
        <div className="login-page">
          <header>
            <section className="view intro-2">
              <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                      <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                        <div className="card-body">
                          <div className="col-md-12 login_icon">
                            <img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{ width: "138px" }} alt="" />
                          </div>
                          <p class="h4 text-center mb-3 pb-3 pt-2 title_font">Reset Password</p>
                          <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                          <div className="md-form">
                            <i className="fas fa-lock prefix white-text" />
                            <input type="password" id="orangeForm-name" className="form-control active" onChange={this.handleChange.bind(this, "newpwd")} value={this.state.fields["newpwd"]} />
                            <label htmlFor="orangeForm-name">New-Password</label>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["pwdempty"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidpwd"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["pwdexceedchars"]}</span></p></span>
                          </div>
                          <div className="md-form">
                            <i className="fas fa-lock prefix white-text" />
                            <input type="password" id="orangeForm-pass" className="form-control" onChange={this.handleChange.bind(this, "confirmpwd")} value={this.state.fields["confirmpwd"]} />
                            <label htmlFor="orangeForm-pass">Confirm-Password</label>
                            <span className="error_msg1 w3-animate-top"><p> <span style={{ color: "red" }} >{this.state.errors["confirmpassword"]}</span></p></span>
                          </div>
                          <div className="text-center">
                            <button type='submit' className="btn login-btn btn-lg waves-effect waves-light mb-4" onClick={this.handleSubmit}>submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </header>
        </div>
      )
    }
  }
}

export default resetpassword;