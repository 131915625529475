import React from "react";
import { withTranslation } from 'react-i18next';
import CustomFilterDropdown from '../common/CustomFilterDropdown';
import moment from "moment";
import { Form, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import CustomDropdown from "../common/CustomDropdown";
import SuccessPopup from '../common/SuccessPopup';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCol, MDBRow, MDBInput } from "mdbreact";
import Select, { components } from "react-select";
import ConfirmationPopup from "../common/ConfirmationPopup";
import CustomModal from "../common/CustomModal";




var tableHeaders = [
    " ",
    "Charge Point ID",
    "Backend Connection",
    "Heartbeat",
    "Group",
    "Status",
    "Trial Start",
    "Trial End",
    "Actions",
  ];


class LoadManagement extends React.Component {
  
    render() {
      const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            {/* <span style={{ transform: 'rotate(360deg)' }}>▼</span> */}
            <span className="caret-icon-w"></span>
          </components.DropdownIndicator>
        );
      };
      const { selectedOption } = this.props;
  
      const options = [
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
        { value: "40", label: "40" },
        { value: "50", label: "50" },
      ];
  
      const colourStyles = {
        menuList: (styles) => ({
          ...styles,
          background: "white",
        }),
        option: (styles, { isFocused, isSelected }) => {
          // Only apply the background color when an option is selected
          if (isSelected) {
            return {
              ...styles,
              background: "#30436E",
              color: "white",
              zIndex: 1,
            };
          }
          return {
            ...styles,
            background: isFocused ? "white" : "transparent",
            color: "#30436E",
            zIndex: 1,
          };
        },
        menu: (base) => ({
          ...base,
          zIndex: 100,
        }),
      };
        const { t } = this.props;


        
        return (
            <main className="content-div" id="loadmanagement">
                <p>{t("load_management")}</p>
                <div className="page-outerdiv">
                <div class="row mb-4" style={{alignItems: "center"}}>
                                <div class="col-md-10">
                                    <div className="row" style={{alignItems: "center"}}>
                                    <div className="col-md-3 pr-0">
                                    <CustomFilterDropdown
                                        iconSrc={process.env.PUBLIC_URL + "/assets/img/cs_charge_point_icon.svg"}
                                        placeholder={t('search_for_charge_point_id')}
                                        headerText={t('charge_point_id')}
                                        option={this.props.filterChargersList}
                                        toggleDropdown={() => this.props.toggleDropdown("isOpen1")}
                                        handleCheckboxChange={this.props.handleCheckboxChange}
                                        handleSubmit={() => this.props.handleSubmit("isOpen1")}
                                        isOpen={this.props.isOpen1}
                                        selectedOptions={this.props.selectedOptions}
                                        searchValue={this.props.searchValue}
                                        handleSearchChange={this.props.handleSearchChargersChange.bind(this, 'filterChargersList')}
                                        errorsMsg={this.props.errors["nodatafound"]}
                                        category={"chargingStation"}
                                        cssClass="chargeStationIcon"
                                        isApplyButtonDisabled={this.props.activeApplyBtn}
                                        onSelect={this.props.handleFilterChange}
                                        emptySearchValues={this.props.emptySearchValues}
                                        getFilterHistory={this.props.getFilterHistory}
                                        showHistory={true}
                                    />
                                        </div>
                                        <div className="col-md-3 pr-0">
                                        <CustomDropdown
                                            iconSrc={process.env.PUBLIC_URL + "/assets/img/llm_icon.svg"}
                                            headerText={t('select_llm_status')}
                                            options={this.props.llmStatusList} 
                                            isOpenDropdown={this.props.isOpenLLMStatusDropdown}
                                            cssClass="chargeStationIcon"
                                            category={"llmStatus"}
                                            onSelect={this.props.handleLLMStatusDropdown}
                                        /></div>

                                    <div className="col-md-3 pr-0">
                                    <CustomFilterDropdown
                                        iconSrc={process.env.PUBLIC_URL + "/assets/img/group_icon.svg"}
                                        placeholder={t('search_for_group')}
                                        headerText={t('select_group')}
                                        option={this.props.filterGroupList}
                                        handleCheckboxChange={this.props.handleCheckboxChange}
                                        toggleDropdown={() => this.props.toggleDropdown("isOpen3")}
                                        handleSubmit={() => this.props.handleSubmit("isOpen3")}
                                        isOpen={this.props.isOpen3}
                                        selectedOptions={this.props.selectedOptions}
                                        searchValue={this.props.searchGroupValue}
                                        handleSearchChange={this.props.handleSearchGroupChange.bind(this, 'filterGroupList')}
                                        errorsMsg={this.props.errors["nodatafound"]}
                                        category={"group"}
                                        // cssClass="RFIDIcon"
                                        isApplyButtonDisabled={this.props.activeApplyBtn}
                                        onSelect={this.handleFilterChange}
                                        emptySearchValues={this.props.emptySearchValues}
                                        getFilterHistory={this.props.getFilterHistory}
                                        showHistory={true}
                                        type = "filterGroupList"
                                        filterGroupListName={this.props.filterGroupListName}
                                    />
                                        </div>
                                        <div className="col-md-3 pr-0 pl-3">
                                            <div className="row activ-deactive-btns" >
                                                <div className="col-md-6 p-0 pr-2 mt-1 pt-0 d-flex flex-start cursor-p "  onClick={this.props.handleActivateBtn}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/img/activate.svg"} height={13} width={34} alt="" className="mr-1 mt-1"/>
                                                    <span className="llm-activate">{t('activ')}</span>
                                                    
                                                </div>
                                                
                                                <div className="col-md-6 p-0 pl-2 mt-1 pt-0 ml-0 d-flex flex-start cursor-p" onClick={this.props.handleDeactivateBtn}>
                                                <img src={process.env.PUBLIC_URL + "/assets/img/deactivate.svg"} height={13} width={34} alt="" className="mr-1 mt-1"/>
                                                    <span className="llm-deactivate">{t('deavtiv')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="selecteditems" style={{ flexWrap: "wrap" }}>

                                {Object.keys(this.props.selectedValues).map((category) =>
                                    this.props.selectedValues[category].map((val, index) => (
                                        <>
                                            <button className="icon-button mr-3 mb-3" key={index}>
                                                <img
                                                    src={
                                                        category === "chargingStation"
                                                            ? process.env.PUBLIC_URL + "/assets/img/cs_charge_point_icon.svg"
                                                            : category === "llmStatus" 
                                                            ? process.env.PUBLIC_URL + "/assets/img/llm_icon.svg" 
                                                            : process.env.PUBLIC_URL + "/assets/img/group_icon.svg"


                                                    }
                                                    width={
                                                        category === "Groups"
                                                            ? 30 
                                                            : category === "llmStatus" 
                                                            ? 32
                                                            : 34
                                                    }
                                                    className="icon pr-2"
                                                    alt={val}
                                                />
                                                <span className="name">
                                                    {category === "group"
                                                        ? this.props.checkIdGetName(val, "selectedValues")
                                                        : val}
                                                </span>
                                                <span
                                                    className="close-icon"
                                                    onClick={() =>
                                                        this.props.removeSelectedValue(category, val)
                                                    }
                                                ></span>
                                            </button>
                                        </>
                                    ))
                                )}

                                {(this.props.selectedValues.chargingStation?.length !== 0 ||
                                 this.props.selectedValues.llmStatus?.length !== 0 ||
                                    this.props.selectedValues.group?.length !== 0) && (
                                        <div className="mr-2">
                                            <button
                                                type="button"
                                                data-test="button"
                                                aria-label="Close"
                                                className="clearall-btn"
                                                onClick={this.props.removeAllSelected}
                                            >
                                                <span aria-hidden="true">×</span> {t("clear_all")}
                                            </button>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>

                            <div className="row">
                        {this.props.showLoder === true && (
                            <div className="page_loader center" id="loader_image_div">
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"}
                                    width="80"
                                    height="80"
                                    alt=""
                                />
                            </div>
                        )}
                    </div>
                      {/* end search bar div */}
              <div className="lastdiv mt-2">
                <div
                  className="pull-right show_entries"
                  style={{ display: "flex" }}
                >
                  <label className="sub-text">{t("show_entries")}</label>
                  <Select
                    className="react-select-container"
                    defaultValue={selectedOption}
                    onChange={this.props.showEntriesChange}
                    options={options}
                    isSearchable={false}
                    placeholder="10"
                    styles={colourStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              </div>

                             {/* table start */}
            <div className="row mt-2">
              <div className="col-md-12">
              <Table striped hover size="sm" className="page-table responsive newtable">
              <thead>
                <tr>
                  {tableHeaders.map((tableItem, index) => {
                    return <th key={index}>{tableItem}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {this.props.isDataAvailable ? this.props.chargerList.map((eachObj, index) => {
                  let trailPeriodStart = "";
                  let trailPeriodStart1 = "";
                  var isOnline = eachObj.backendConnection;
                  if (eachObj.trailPeriodStart === null || eachObj.trailPeriodStart === "") {
                    trailPeriodStart = "";
                    trailPeriodStart1 = "";
                  } else {
                    trailPeriodStart = moment(eachObj.trailPeriodStart).format("DD MMM YYYY");
                    trailPeriodStart1 = moment(eachObj.trailPeriodStart).format("HH:mm");
                  }
                  let trailPeriodEnd = "";
                  let trailPeriodEnd1 = "";
                  if (eachObj.trailPeriodEnd === null || eachObj.trailPeriodEnd === "") {
                    trailPeriodEnd = "";
                    trailPeriodEnd1 = "";
                  } else {
                    trailPeriodEnd = moment(eachObj.trailPeriodEnd).format("DD MMM YYYY");
                    trailPeriodEnd1 = moment(eachObj.trailPeriodEnd).format("HH:mm");
                  }
                  let onboarddate = "";
                  let onboarddate1 = "";
                  if (eachObj.heartBeatTime === null || eachObj.heartBeatTime === "" || eachObj.heartBeatTime === undefined) {
                    onboarddate = "";
                    onboarddate1 = "";
                  } else {
                    onboarddate = moment(eachObj.heartBeatTime).format("DD MMM YYYY");
                    onboarddate1 = moment(eachObj.heartBeatTime).format("HH:mm");
                  }
                  return (
                    <tr key={eachObj.chargePointId}>
                        <td className="text-center">
                            <Form.Check
                                custom
                                type="checkbox"
                                id={`custom-${eachObj.chargePointId}`}
                                onChange={this.props.handleChange.bind(this, `custom-${eachObj.chargePointId}`, eachObj.chargePointId)}
                                key={eachObj.chargePointId}
                                label=""
                                className="checkbox"                            
                                checked={this.props.selectedChargers?.includes(eachObj.chargePointId) ? true : false}
                            />
                        </td>
                      <td className="td-wrap-word1">{eachObj.chargePointId}</td>
                      <td style={{width: 135,textAlign:"center"}}>
                        <i className="fas fa fa-circle fs-16"   style={{
                            color: isOnline ? "green" : "red",
                          }}>
                        </i>
                        {eachObj.backendConnection}
                      </td>
                      <td>
                      { onboarddate ? <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/cs_table_calendar_icon_sm.svg"
                          }
                          style={{ marginRight: 3 }}
                        /> : "" }
                        {onboarddate + " " +onboarddate1}
                      </td>
                      <td className="td-wrap-word1">{eachObj.groupName}</td>
                      <td>{eachObj.llmStatus}</td>
                     
                      <td> { trailPeriodStart ? <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/cs_table_calendar_icon_sm.svg"
                          }
                          style={{ marginRight: 3 }}
                        /> : "" } {trailPeriodStart + " " +trailPeriodStart1}</td>
                      <td>{ trailPeriodEnd ? <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/cs_table_calendar_icon_sm.svg"
                          }
                          style={{ marginRight: 3 }}
                        /> : "" } {trailPeriodEnd + " " +trailPeriodEnd1}</td>
                      <td className="text-left">
                        <label className="switch">
                            <input type="checkbox" onChange={this.props.onChangeLlmStatus.bind(this, eachObj.chargePointId, eachObj.llmStatus)} 
                            // defaultChecked={eachObj.llmStatus === "Active" ? true : false }
                            checked = {eachObj.llmStatus === "Active"}
                              />
                            <span className="llm-slider round"></span>
                        </label>
                        { 
                            eachObj.llmStatus === "Active" && 
                            <button className="llm-extnd-btn cursor-p" type="button" onClick={()=>this.props.handleExtendTrialBtn(eachObj.chargePointId)}>
                            {t('extend_trail')}
                            </button>
                        }
                        
                      </td>
                    </tr>
                  );
                }) : <td colSpan={8} style={{ textAlign: "center" }}>{t('no_data_available')}</td> }
              </tbody>
            </Table>

              </div>
            </div>
            { this.props.isDataAvailable && <div className="row">
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  activePage={this.props.activePage}
                  itemsCountPerPage={this.props.pageSize}
                  totalItemsCount={this.props.noOfRecords}
                  onChange={this.props.handlePageChange.bind(this)}
                  prevPageText={t('prev')}
                  nextPageText={t('next')}
                  itemClassPrev="prevBtn"
                  pageRangeDisplayed="1"
                  activeClass="activeLi active"
                  itemClassNext="nextBtn"
                  hideFirstLastPages={true}      
                  disableInitialCallback={true}
                  disabledClass="disabled disabledtextColor"                   
                />
              </div>
            </div>}
               {/* Loader  */}
          <div
            className="page_loader center"
            id="loader_image_div"
            style={{ display: "none" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"}
              width="80"
              height="80"
              alt=""
            />
          </div>
        </div>

                {/* <MDBModal isOpen={this.props.isActivatePopupOpen} size='md' className="model_top">
                    <MDBModalBody>
                        <div className='d-flex flex-column justify-content-center align-items-center p-2'>
                                <h5 className='llm-popup-h5'>{t('load_mngmnt_activation')}</h5>
                            
                                <p className='llm-popup-p mt-2 pl-4 pr-4 text-center'>{t('are_you_sure_to_activate_free_trial_for_charger')} </p>

                            <div className='d-flex mb-2'>
                                
                                <button
                                    className="blueBtnNew w-50"
                                    type="button"
                                    onClick={this.props.handleActivateSuccess}
                                    >
                                    {t("activ")}
                                </button>
                                
                                <button
                                    className="ltblueBtn w-50 ml-3"
                                    type="button"
                                    onClick={this.props.closeActivateLLMPopup}
                                >
                                    {t("cancel")}
                                </button>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModal> */}

                <ConfirmationPopup
                    open={this.props.isActivatePopupOpen}
                    toggleOperation={this.props.closeActivateLLMPopup}
                    title={t('load_mngmnt_activation')}
                    content={t('are_you_sure_to_activate_free_trial_for_charger')}
                    btnImage={process.env.PUBLIC_URL + "/assets/img/undo_popup.svg"}
                    btnText={t("yes_activate")}
                    btnBgColor='#CAF2C2'
                    onClickOperation={this.props.handleActivateSuccess}
                />
                
                {/* <MDBModal isOpen={this.props.isDeactivatePopupOpen} size='md' className="model_top">
                    <MDBModalBody>
                        <div className='d-flex flex-column justify-content-center align-items-center p-2'>
                            

                            <h5 className='llm-popup-h5'>{t('confirmation')}</h5>
                            
                            <p className='llm-popup-p mt-2 pl-4 pr-4 mb-4 text-center'>{t('are_u_sure_want_to_deactivate_this_chargers')} </p>

                            <div className='d-flex mb-2'>
                                 <button
                                        className="blueBtnNew w-50"
                                        type="button"
                                        onClick={this.props.handleDeactivateSuccess}
                                        >
                                        {t("yes")}
                                    </button>
                                
                                <button
                                    className="ltblueBtn w-50 ml-3"
                                    type="button"
                                    onClick={this.props.closeDeactivateLLMPopup}
                                >
                                    {t("no")}
                                </button>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModal> */}

                <ConfirmationPopup
                    open={this.props.isDeactivatePopupOpen}
                    toggleOperation={this.props.closeDeactivateLLMPopup}
                    title={t('load_mngmnt_deactivation')}
                    content={t('are_u_sure_want_to_deactivate_this_chargers')}
                    btnImage={process.env.PUBLIC_URL + "/assets/img/deactive.svg"}
                    btnText={t("yes_deactivate")}
                    btnBgColor='#FFD3D8'
                    onClickOperation={this.props.handleDeactivateSuccess}
                />

                <MDBModal isOpen={this.props.isLlmExtendTrialPopupOpen} size='md' className="model_top">
                    <MDBModalBody>
                        <div className='d-flex flex-column justify-content-center align-items-center p-2'>
                            <h5 className='llm-popup-h5'>{t('llm_extend_trial')}</h5>
                            <p className='llm-popup-p mt-2 pl-4 pr-4 mb-4 text-center'>{t('are_u_sure_want_extend_trial_for_charger')} </p>
                            <div className='d-flex mb-2'>
                                 <button
                                        className="blueBtnNew w-50"
                                        type="button"
                                        onClick={this.props.handleLlmExtendSuccess}
                                        >
                                        {t("extend_trail")}
                                    </button>
                                <button
                                    className="ltblueBtn w-50 ml-3"
                                    type="button"
                                    onClick={this.props.closeLlmExtendTrialPopup}
                                >
                                    {t("cancel")}
                                </button>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModal>

                <MDBModal isOpen={this.props.isWarningPopupOpen} size='md' className="model_top">
                    <MDBModalBody>
                        <div className='d-flex flex-column justify-content-center align-items-center p-2'>
                            
                            <p className='llm-popup-p mt-2 pl-5 pr-5 mb-4 text-center'>{t('select_charger_from_list')} </p>

                            <div className='mb-2 d-flex justify-content-center'>
                                 <button
                                        className="blueBtnNew w-60"
                                        type="button"
                                        onClick={this.props.closeWarningPopup}
                                        >
                                        {t("close")}
                                    </button>
                                
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModal>
                <SuccessPopup
                    open={this.props.isLLMActivateSuccessPopupOpen}
                    image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                    content={t('llm_activated_successfully')}
                    btnText={t("close")}
                    onClickOperation={this.props.closeLLMActivatedSuccessPopup}
                />
                
                <SuccessPopup
                    open={this.props.isLLMDeactivateSuccessPopupOpen}
                    image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                    content={t('llm_deactivated_successfully')}
                    btnText={t("close")}
                    onClickOperation={this.props.closeLLMDeactivatedSuccessPopup}
                />

                <SuccessPopup
                    open={this.props.isLlmExtendSuccessPopupOpen}
                    image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                    content={t('llm_extend_trial_successful')}
                    btnText={t("close")}
                    onClickOperation={this.props.closeLlmExtendSuccessPopup}
                />

                <CustomModal
                    open={this.props.isAlertForSingleCPLllm}
                    toggle={this.props.closeAlertForSingleCPPopup}
                    title={t("alert")}
                    bodyMessage={t("charger_llm_subscription_already_exist")}
                    PrimaryButtonText={t("ok")}
                    PrimaryButtonOperation={this.props.closeAlertForSingleCPPopup}
                />

                <CustomModal
                    open={this.props.isExtendTrialAlertPopupOpen}
                    toggle={this.props.closeExtendTrialAlertPopup}
                    title={t("alert")}
                    bodyMessage={t("charger_llm_subscription_not_found")}
                    PrimaryButtonText={t("ok")}
                    PrimaryButtonOperation={this.props.closeExtendTrialAlertPopup}
                />
            </main>
        )
    }

}


export default withTranslation()(LoadManagement);