import { useEffect, useState } from "react";
import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import servicesUrl from '../common/servicesUrl';

function Payment(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [stripeCustomerID, setstripeCustomerID] = useState("");
  const [transID, setTransID] = useState("");

const aesDecrypt = (encryptedHex) => {

  var aesjs = require('aes-js');
  // An example 128-bit key
  var key = [49, 50, 51, 52, 53, 54, 55, 56, 57, 49, 49, 50, 51, 52, 53, 54];

  // The initialization vector (must be 16 bytes)
  var iv = [48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48];


  var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);

  // The cipher-block chaining mode of operation maintains internal
  // state, so to decrypt a new instance must be instantiated.
  var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);

  //WCC
  // var decryptedBytes = aesCbc.decrypt(encryptedBytes);
// DX2.1
  var decryptedBytes = aesjs.padding.pkcs7.strip(aesCbc.decrypt(encryptedBytes));

  // Convert our bytes back into text
  var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  
  return decryptedText;
}

  const getSetupIntent = async () => {
    try {
      let url = servicesUrl.stripeUrl + servicesUrl.getSetupIntent
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: "Bearer " + aesDecrypt(localStorage.getItem("stripeSecretKey")),
        },
        body: '', // Additional customer details can be added here
      });

      const responseData = await response.json();
      // Handle success or error response
      if (response.ok) {
        this.attachPaymentMethod(responseData.payment_method)
      } else {
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  // Create a Stripe Customer ID...
  const createStripeCustomer = async () => {
    try {
      let url = servicesUrl.stripeUrl + servicesUrl.stripeCustomerProfileID;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: "Bearer " + aesDecrypt(localStorage.getItem("stripeSecretKey")),
        },
        body: 'email=', // Additional customer details can be added here
      });

      const responseData = await response.json();
      // Handle success or error response
      if (response.ok) {
        setstripeCustomerID(responseData.id);
        // passing stripeCustomerID to get the client_secret
        stripePayment_intentsDirectPayment(responseData.id);
      } else {
      }
    } catch (error) {
      console.error('create customer Error:', error);
    }
  };

  // Stripe Ephemeral_keys API Integration.........
  const createStripeEphemeral_keys = async (stripeCustomerID) => {
    try {
      let url = servicesUrl.stripeUrl + servicesUrl.stripeEphemeral_keys + stripeCustomerID;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Stripe-Version': '2022-11-15',
          Authorization: "Bearer " + aesDecrypt(localStorage.getItem("stripeSecretKey")),
        },
        body: '', // Additional customer details can be added here
      });
      const responseData = await response.json();
      // Handle success or error response
      if (response.ok) {
        setTransID(responseData.id);
        localStorage.setItem('ephemeralKeysID', responseData.id);
        stripePayment_intentsDirectPayment(responseData.id, stripeCustomerID);
      } else {
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Stripe Payment Intents API Integration From Saved Card List.........
  const stripePayment_intentsDirectPayment = async (stripeCustomerID) => {
    try {
      let paymentAccountID = localStorage.getItem("stripeAccountId");
      let total = parseInt(localStorage.getItem("totalAmt"));
      let currency = localStorage.getItem('currencyCode').toLowerCase();
      total = total * 100
      total = total.toString()
      if (paymentAccountID === "" || paymentAccountID === null || paymentAccountID === undefined) {
        var url = servicesUrl.stripeUrl + servicesUrl.stripePayment_intents + "customer=" + stripeCustomerID + "&amount=" + total + "&currency=" + currency + "&automatic_payment_methods[enabled]=" + true + "&capture_method=" + ("manual");
      } else {
        var url = servicesUrl.stripeUrl + servicesUrl.stripePayment_intents + "customer=" + stripeCustomerID + "&amount=" + total + "&transfer_data[destination]=" + paymentAccountID + "&currency=" + currency + "&payment_method_types[]=" + "card" + "&capture_method=" + ("manual");
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: "Bearer " + aesDecrypt(localStorage.getItem("stripeSecretKey")),
        },
        body: '',
      });
      const responseData = await response.json();
      if (response.ok) {
          setClientSecret(responseData.client_secret);
      } else {
        localStorage.setItem('page', 'QRCodeDeactivated');
        props.setPage('QRCodeDeactivated');
        localStorage.setItem('prePage', 'selectCharger');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    setStripePromise(loadStripe(aesDecrypt(localStorage.getItem('stripePublishKey'))));
    // setClientSecret(STRIPE_SECRET_KEY);
    // getSetupIntent();
    createStripeCustomer();
  }, []);



  const options = {
    clientSecret: clientSecret
  }

  function backButton () {
    localStorage.setItem('page', 'addpayment');
    props.setPage('addpayment');
  }

  return (
    <>
      <div className="welcome-parent bg_white">
        <div className='selectchr_bg container-w'>
          <div className="nav_bar">
            <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/left-arrow-blue.svg"} className="img-fluid rounded-start" alt="" style={{ cursor: 'pointer' }} onClick={backButton} />
            <h3 className='text-center' style={{ color: '#00315E', fontSize: '19px', paddingTop: '5px' }}>
              Select payment methods
            </h3>
            <p>&nbsp;&nbsp;&nbsp;</p>
          </div>
        </div>
        <div className="container-fluid pb-3 pt-1">
          {clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm clientSecret={clientSecret} props={props} />
            </Elements>
          )}
        </div>
      </div>
    </>
  );
}

export default Payment;
