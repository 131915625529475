/* eslint-disable no-useless-escape */
import React from 'react';
import { MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import $ from "jquery";
import * as baseUrl from './constants';
import * as Roles from './roles';
import * as PlatformUser from './PlatformUser';
import * as TenantUser from './TenantUser';
import { withTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import servicesUrl from "../common/servicesUrl";
//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders, getActiveSecurityHeaders } from '../common/HttpRequestClass';

const history = createBrowserHistory();


class addUserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sucess1: false,
      colour: "sucess",
      message: "",
      roledisplay: "",
      defaultroledisplay: "",
      fields: { id: '', active: '', email: '', firstName: '', lastName: '', mobileNumber: '', organisation: '', role: '', employeeId: '', status: '', rfidTag: '', aliasName: '', language: '', userPackage:''},
      sucess: {},
      errors: {},
      activeItem: "1",
      model: "false",
      dataArray: [],
      modalsession: false,
      roles: [],
      multipleRfidArray: [
        {
          rfidTag: '', aliasName: '', tagType: 'other'
        }
      ],
      multipleRfidArrayAlias: [],
      availableSites: [],
      selectedSites: [],
      listOfLanguages: [],
      listOfPackage:[],
      orgIdentifier:"",
      isServiceManagementActive: false,
      isOpenProfileModal: false,
      profileData : {serviceAccessCode : "", companyName : '', city:'', zipCode:'', address:'', country:'', telephone:'', email:'', websiteUrl:''}

    };
    this.getLanguages = this.getLanguages.bind(this);
    this.toggle = this.toggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.addNew = this.addNew.bind(this);
    this.backToList = this.backToList.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.roleidChange = this.roleidChange.bind(this);
    this.organizationChange = this.organizationChange.bind(this);
    this.activeChange = this.activeChange.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.addMultipleRfid = this.addMultipleRfid.bind(this);
    this.removeRfid = this.removeRfid.bind(this);
    this.changeRfid = this.changeRfid.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.onUpdateSiteSelectAll = this.onUpdateSiteSelectAll.bind(this);
    this.getIsAllSitesSelected = this.getIsAllSitesSelected.bind(this);
    // this.languageChange = this.languageChange.bind(this);
    this.packageLevelChange = this.packageLevelChange.bind(this);
    this.handleServiceManagement = this.handleServiceManagement.bind(this);
    this.toggleProfileModal = this.toggleProfileModal.bind(this);
    this.handleFormInputChange = this.handleFormInputChange.bind(this);
    this.handleCompanyNameError = this.handleCompanyNameError.bind(this);
    //this.handleTelephoneError = this.handleTelephoneError.bind(this);
    this.handleEmailError = this.handleEmailError.bind(this);
    this.handleSaveSMProfile = this.handleSaveSMProfile.bind(this);
    this.validateURL = this.validateURL.bind(this);

  }

  validateURL(e) {
    let errors = {}
    let websiteUrl = e.target.value;
    if(websiteUrl!=="" && websiteUrl!== null){
      //var urlregex = new RegExp("^((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
      //var pattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/;
      var pattern = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/ ;
      var var_name = pattern.test(websiteUrl);
      if(!var_name){
        errors["iswebsiteUrlEmpty"] =this.props.t('enter_a_valid_url');
        this.setState({errors : errors})
      }
      }
    }

  handleSaveSMProfile(e){
    let errors = {}
    e.preventDefault();
    var companyName = this.state.profileData.companyName

    if(companyName === "" || companyName===null){
      errors["iscompanyNameEmpty"] = this.props.t('please_enter_companyName');
      this.setState({errors : errors})
    }
    else{
      //let telephone = this.state.profileData.telephone;
      let email = this.state.profileData.email;
      let websiteUrl = this.state.profileData.websiteUrl;

      // if (telephone !== '' && telephone !== null) {
        
      //   if(!telephone.match(/^(?:\+[\d\s-]{1,18}|[\d\s-]{1,18})$/)){
      //     errors["istelephoneEmpty"] = this.props.t("enter_valid_telephone_no");
      //   }
      // }
      if (email !== '' && email !== null) {
        if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
          errors["isemailEmpty"] = this.props.t('enter_valid_email_id');
        }
      }
      if(websiteUrl!=="" && websiteUrl!== null){
        // var urlregex = new RegExp("^((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
        // var var_name = urlregex.test(websiteUrl);
        // if(!var_name){
        //   errors["iswebsiteUrlEmpty"] =this.props.t('enter_a_valid_url');
        // }
        //var pattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/;
        var pattern = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/ ;
        var var_name = pattern.test(websiteUrl);
        if(!var_name){
          errors["iswebsiteUrlEmpty"] =this.props.t('enter_a_valid_url');
          this.setState({errors : errors})
        }
        }

      if(errors.hasOwnProperty("isemailEmpty") || errors.hasOwnProperty("iswebsiteUrlEmpty")){
        this.setState({errors: errors})
      }
      else{
         this.setState({isOpenProfileModal:false})
      }  
      
    }
  }

  handleEmailError(e){
    let errors = {}
    let email = e.target.value
    if (email !== '') {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
        errors["isemailEmpty"] = this.props.t('enter_valid_email_id');
      }
    }else{
      errors["isemailEmpty"] = "";
    }
    this.setState({errors : errors})

  }

  // handleTelephoneError(e){
  //   let errors = {}
  //   let telephone = e.target.value
  //   if (telephone !== '') {
     
  //     if(!telephone.match(/^(?:\+[\d\s-]{1,18}|[\d\s-]{1,18})$/)){
  //       errors["istelephoneEmpty"] = this.props.t("enter_valid_telephone_no");
  //     }
  //   }
  //   else{
  //     errors['validTelephone'] = '';
  //   }
  //   this.setState({errors : errors})

  // }

  handleCompanyNameError(e){
    let errors={}
    if (e.target.value===""){
      errors["iscompanyNameEmpty"] = this.props.t('please_enter_companyName');
    }
    else{
      errors["iscompanyNameEmpty"] = ""
    }
    this.setState({errors : errors})
  }

  handleFormInputChange(e){
    const { name, value } = e.target;
    let errors = {}
    
      this.setState((prevState) => ({
        profileData: { ...prevState.profileData, [name]: value, errors : errors},
      }));
  }

toggleProfileModal(){
    let profileData = {companyName : '', city:'', zipCode:'', address:'', country:'', telephone:'', email:'', websiteUrl:''}

      this.setState({
        isServiceManagementActive: false, isOpenProfileModal : false,
      profileData : profileData}) 
  }

  async handleServiceManagement() {
        this.setState((prevState) => ({
      isServiceManagementActive: !prevState.isServiceManagementActive,
    }));
    
    if(!this.state.isServiceManagementActive){
      var url = baseUrl.URLPath +servicesUrl.getAccessCode;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if(response){
      if(response?.status === 401){

      }else{
        this.setState((prevState) => ({
          profileData: {
            ...prevState.profileData, 
            serviceAccessCode: response.accessCode,
          },
          isOpenProfileModal : true,
          errors : {}

        }));
      }
    
    }
    

    }
  }

  async getLanguages() {
    let url = baseUrl.URLPath + 'admin/v1.0/account/languages/preferred';
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response) {
      this.setState({
        listOfLanguages: response
      })
    }
  }

  onShowAlert() {
    this.setState({ sucess1: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess1: false });
      }, 5000);
    });
  }

  changeRfid(index, field, e) {

    let tempArray = [...this.state.multipleRfidArray];


    tempArray[index][field] = e.target.value;
    if (field === "rfidTag") {
      if (tempArray[index][field] !== 0) {

        $(`.rfidTag_${index}`).text("")
      }
    }
    if (field === "aliasName") {
      if (tempArray[index][field] !== 0) {

        $(`.aliasName_${index}`).text("")
      }
    }
    if (field === "tagType") {
      if (tempArray[index][field] !== 0) {

        $(`.tagType_${index}`).text("")
      }
    }
    this.setState({ multipleRfidArray: tempArray });
    console.log(this.state.multipleRfidArray)


  }


  addMultipleRfid() {
    let errors = {};
    this.setState({
      multipleRfidArray: [...this.state.multipleRfidArray, { rfidTag: '', aliasName: '', tagType: 'other' }]
    })
    errors["emptyidTagArray"] = "";
    this.setState({ errors: errors })
  }
  removeRfid(index) {
    let data = this.state.multipleRfidArray

    // remove object
    data.splice(index, 1);
    this.setState({
      multipleRfidArray: data,
      disabledAttr: ""
    });

    let lookup = this.state.multipleRfidArray.reduce((a, e) => {
      a[e.rfidTag] = ++a[e.rfidTag] || 0;
      return a;
    }, {});

    let duplicateCount = this.state.multipleRfidArray.filter(e => lookup[e.rfidTag]);

    if (duplicateCount.length === 0) {
      $('.rfidexists').text("");
    }
  }

  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }
  roleidChange() {
    var x = document.getElementById("role").value;
    let fields = this.state.fields;
    let errors = this.state.errors;
    if (x !== 0) {
      errors["role"] = "";
    }
    fields["role"] = x;
    this.setState({ fields, errors });
  }
  tagOk() {
    this.props.history.push('/userManagementController');
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  addNew() {
    this.props.history.push('/Userlist');
  }

  backToList() {
    this.props.history.push('/userManagementController');
  }

  // checking the format of number
  numberHandler(field, e) {
    let fields = this.state;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }
  changeHandler(field, e) {
    var email = document.getElementById("email").value;
    var firstName = document.getElementById("firstName").value;
    var lastName = document.getElementById("lastName").value;
    var mobileNumber = document.getElementById("mobileNumber").value;
    var employeeId = document.getElementById("employeeId").value;

    let fields = this.state.fields;
    let errors = this.state.errors;
    fields[field] = e.target.value;

    if (field === "employeeId") {
      $(".employeeidexists").text("");
    }

    if (e.target.value !== "") {
      errors["rfidempty"] = "";
    }

    if (fields["email"] === email) {
      errors["emailempty"] = "";
      errors["userexists"] = "";
    }

    if (fields["firstName"] === firstName) {
      errors["firstName"] = "";
    }

    if (fields["lastName"] === lastName) {
      errors["lastName"] = "";
    }

    if (fields["mobileNumber"] === mobileNumber) {
      errors["mobileNumber"] = "";
    }

    if (fields["employeeId"] === employeeId) {
      errors["employeeId"] = "";
    }

    this.setState({ fields, errors });
  }

  isHaveAlphabetic(string) {
    if (/[a-zA-Z]/.test(string) === true) {
      return true;
    } else {
      return false;
    }
  }
  isLengthAllowed(string) {
    if (string.length <= 20) {
      return true;
    } else {
      return false;
    }
  }
  isLengthAllowed128(string) {
    if (string.length <= 128) {
      return true;
    } else {
      return false;
    }
  }
  activeChange = () => {
    var x = document.getElementById("myCheck").checked;
    let fields = this.state.fields;
    if (x) {
      fields.status = true;
    } else {
      fields.status = false;
    }
    this.setState({ fields });
  };
  async handleSubmit(event) {
    
    event.preventDefault();
    let errors = {};
    let validFreeCharging = 0;

    if(this.state.fields.role !== "WEBASTO_SUPER_ADMIN_DEVOPS" && this.state.fields.role !=="WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" && this.state.fields.role !=="WEBASTO_CUSTOMER_ADMIN_READ_ONLY"){
    this.state.multipleRfidArray.forEach((element, index) => {
      if (element.rfidTag !== "" && element.rfidTag !== null) {
        $(`.rfidTag_${index}`).text("");
        if (!element.rfidTag.match(/^[0-9a-zA-Z_@,.!#%&*]{0,20}$/)) {
          errors["rfidempty"] = this.props.t('please_enter_valid_rfid_tag');
        }
      }
      else {
        $(`.rfidTag_${index}`).text(this.props.t('please_enter_rfid_tag'))
      }

      if (element.rfidTag === "0815" && element.rfidTag !== null && element.rfidTag !== "") {
        $(`.rfidTag_${index}`).text(this.props.t('rfid_tag_reserved_for_free_charging'));
        validFreeCharging = 1;
      }

      if (element.aliasName !== "" && element.aliasName !== null) {
        $(`.aliasName_${index}`).text("");
      }
      else {
        $(`.aliasName_${index}`).text(this.props.t('please_enter_alias_name'))
      }

    })
  }

    var email = this.state.fields['email'];
    var organisation = this.state.fields['organisation'];
    var firstName = this.state.fields['firstName'];
    var lastName = this.state.fields['lastName'];
    var mobileNumber = this.state.fields['mobileNumber'];
    var role = this.state.fields['role'];
    var userPackage = this.state.fields['userPackage'];
    var UserRole = localStorage.getItem("role");

    if( (role==="WEBASTO_END_USER_RESIDENTIAL") && (UserRole === "WEBASTO_SUPER_ADMIN_DEVOPS" || UserRole === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE") && this.state.orgIdentifier === "webasto") {
      userPackage = userPackage ? userPackage : "Entry";
    }else{
      userPackage = null;
    }
   
    var siteManagementsIds = [];
    for (let site in this.state.selectedSites) {
      siteManagementsIds.push(this.state.selectedSites[site].id);
    }

    var getRole = localStorage.getItem("role")
   
    if (email === '') {
      errors["emailempty"] = this.props.t('please_enter_email');
    }
    if (email !== '') {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
        errors["entervalidemail"] = this.props.t('enter_valid_email_id');
      }
    }
    if (organisation === '') {
      errors["organisationempty"] = this.props.t('please_select_organization');
    }
    if (firstName === '') {
      errors["firstName"] = this.props.t('enter_first_name');
    }
    if (lastName === '') {
      errors["lastName"] = this.props.t('enter_last_name');
    }

    if (mobileNumber === "") {
      errors["mobileNumber"] = this.props.t('enter_mobile_no');
    }
    if (mobileNumber !== '') {
      if (!mobileNumber.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {

        errors["validphoneNo"] = this.props.t('enter_10digit_mobile_no');
      }
    }
    if (getRole !== "WEBASTO_SITE_ADMIN") {
      if (role === "") {
        errors["role"] = this.props.t('please_select_role');
      }
    }
    if (role === "WEBASTO_END_USER_COMMERCIAL" && this.state.multipleRfidArray.length === 0) {
      errors["emptyidTagArray"] = this.props.t('add_rfid_and_alias_name');
    }

    if (getRole !== Roles.WEBASTO_SITE_ADMIN) {
      if (validFreeCharging === 1 || errors.hasOwnProperty("emailempty") || errors.hasOwnProperty("organisationempty") || errors.hasOwnProperty("lastName") || errors.hasOwnProperty("firstName") || errors.hasOwnProperty("mobileNumber") || errors.hasOwnProperty("role") || errors.hasOwnProperty("entervalidemail") || errors.hasOwnProperty("validphoneNo") || errors.hasOwnProperty("rfidempty") || errors.hasOwnProperty("emptyidTagArray") || errors.hasOwnProperty("aliasNameEmpty")) { }
      else {
        let url = "";
        let data = {};
        if (getRole === Roles.WEBASTO_CUSTOMER_ADMIN || getRole === Roles.WEBASTO_SITE_ADMIN) {
          url = TenantUser.CREATE_USER;
        } else if (getRole === Roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || getRole === Roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
          if(this.state.isServiceManagementActive){
            url = PlatformUser.CREATE_USER_SERVICEPARTNER;
          }else{
            url = PlatformUser.CREATE_USER;
          }
        }

        if(this.state.fields.role === "WEBASTO_SUPER_ADMIN_DEVOPS" || this.state.fields.role ==="WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" || this.state.fields.role ==="WEBASTO_CUSTOMER_ADMIN_READ_ONLY"){
            data = { "email": this.state.fields.email.toLowerCase(), "firstName": this.state.fields.firstName, "lastName": this.state.fields.lastName, "mobileNumber": this.state.fields.mobileNumber, "role": this.state.fields.role, "organisation": this.state.fields.organisation, "employeeId": this.state.fields.employeeId, "status": this.state.fields.status, "rfids": [], siteManagementsIds, "preferredLanguage": this.state.fields.language, "userPackage":userPackage };
        }else{
          if(this.state.isServiceManagementActive){
            data = { 
              "email": this.state.fields.email.toLowerCase(),
              "firstName": this.state.fields.firstName,
              "lastName": this.state.fields.lastName, 
              "mobileNumber": this.state.fields.mobileNumber, 
              "role": this.state.fields.role, 
              "organisation": this.state.fields.organisation,
              "employeeId": this.state.fields.employeeId, 
              "status": this.state.fields.status, 
              "rfids": this.state.multipleRfidArray,
              siteManagementsIds, 
              "preferredLanguage": this.state.fields.language,
              "userPackage":userPackage ,
              "partnerAccessCode":this.state.profileData.serviceAccessCode ,
              "partnerAddress":this.state.profileData.address ,
              "partnerCity": this.state.profileData.city,
              "partnerCompanyName":this.state.profileData.companyName ,
              "partnerCountry":this.state.profileData.country ,
              "partnerEmail": this.state.profileData.email,
              "partnerService": this.state.isServiceManagementActive,
              "partnerTelephone": this.state.profileData.telephone,
              "partnerWebsiteURL": this.state.profileData.websiteUrl,
              "partnerZipCode": this.state.profileData.zipCode,
           };
          }else{
            data = { 
                  "email": this.state.fields.email.toLowerCase(),
                  "firstName": this.state.fields.firstName,
                  "lastName": this.state.fields.lastName, 
                  "mobileNumber": this.state.fields.mobileNumber, 
                  "role": this.state.fields.role, 
                  "organisation": this.state.fields.organisation,
                  "employeeId": this.state.fields.employeeId, 
                  "status": this.state.fields.status, 
                  "rfids": this.state.multipleRfidArray,
                  siteManagementsIds, 
                  "preferredLanguage": this.state.fields.language,
                  "userPackage":userPackage ,
                  
                };
          }
        }

        document.getElementById("loader_image_div").style.display = "block";
        let type = ApiMethodTypes.POST;
        let headers = getSecurityHeaders();

        let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t, true);

        this.hideLoder();
          if (response.status === "SUCCESS") {
            this.toggle()
            $('.rfidexists').text("");
          }
          else if (response.status === "FAILURE" && response.errorCode === 3104 && response.errorMessage === 'user_already_exists') {
            errors["userexists"] = this.props.t('user_already_exists')
            this.setState({
              errors: errors
            });
            $('.rfidexists').text("");
          }
          else if (response.status === "FAILURE" && response.errorCode === 3123 && response.errorMessage === 'rfid_tag_issue') {
            var rfids = response.rfids[0];
            var errorRfid = rfids.rfidTag;
            if (rfids.errorReason === 'rfid_is_already_assigned') {
              errors["rfidexists"] = this.props.t('rfid_is_already_assigned');
              $('.rfidexists').text("");
              this.setState({ errors: errors, message: errorRfid + "  " + this.props.t('is_already_assigned'), colour: 'danger' }, ()=>{
                this.onShowAlert();
              });
            } else if (rfids.errorReason === 'duplicate_rfid_tag') {
              $('.rfidexists').text(this.props.t('duplicate_rfidds_not_allowed'));
            }


          } else if (response.status === "FAILURE" && response.errorCode === 3124 && response.errorMessage === 'employeeid_is_already_exists') {
            $('.employeeidexists').text(this.props.t('employeeId_already_exists'));
          }
          else { }


          this.setState({
            errors: errors
          });
      }
    } else {

      if (errors.hasOwnProperty("emailempty") || errors.hasOwnProperty("organisationempty") || errors.hasOwnProperty("lastName") || errors.hasOwnProperty("firstName") || errors.hasOwnProperty("mobileNumber") || errors.hasOwnProperty("entervalidemail") || errors.hasOwnProperty("validphoneNo") || errors.hasOwnProperty("rfidempty") || errors.hasOwnProperty("aliasNameEmpty") || errors.hasOwnProperty("emptyidTagArray") || errors.hasOwnProperty("employeeId")) { }

      else {
        let url = "";
        let getRole = localStorage.getItem('role');
        if (getRole === Roles.WEBASTO_CUSTOMER_ADMIN || getRole === Roles.WEBASTO_SITE_ADMIN) {
          url = TenantUser.CREATE_USER;
        } else if (getRole === Roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || getRole === Roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
          url = PlatformUser.CREATE_USER;
        }

        let data = { "email": this.state.fields.email, "firstName": this.state.fields.firstName, "lastName": this.state.fields.lastName, "mobileNumber": this.state.fields.mobileNumber, "role": this.state.fields.role, "organisation": this.state.fields.organisation, "status": this.state.fields.status, "employeeId": this.state.fields.employeeId, "rfids": this.state.multipleRfidArray, siteManagementsIds };
        document.getElementById("loader_image_div").style.display = "block";
        let type = ApiMethodTypes.POST;
        let headers = getSecurityHeaders();

        let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t, true);
        
        this.hideLoder();
          if (response.status === "SUCCESS") {
            this.toggle();
          } else if (response.status === "FAILURE" && response.errorCode === 3104 && response.errorMessage === 'user_already_exists') {
            errors["userexists"] = this.props.t('user_already_exists')
            this.setState({
              errors: errors
            });
          } else if (response.status === "FAILURE" && response.errorCode === 3123 && response.errorMessage === 'rfid_tag_issue') {
            let rfids = response.rfids[0];

            if (rfids.errorReason === 'rfid_is_already_assigned') {
              this.setState({ message: rfids.rfidTag +"  " + this.props.t('is_already_assigned'), colour: 'danger' })
              this.onShowAlert();
            }
          }
          else { }

          this.setState({
            errors: errors
          });

      }
    }
    this.setState({ errors: errors });
  }
  hideLoder() {
    document.getElementById("loader_image_div").style.display = "none";
  }

  organizationChange(e) {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index]
    var orgIdentifier =  optionElement.getAttribute('data-identifier');
    var orgid = document.getElementById("organisation").value;
    let fields = this.state.fields;
    let errors = this.state.errors;
    fields["organisation"] = orgid;
    this.setState({ fields });
    if (orgid !== 0) {
      errors["organisationempty"] = "";
      this.setState({
        orgIdentifier:orgIdentifier, errors
      },()=>{
        if(orgIdentifier==="webasto"){
          this.getPackages(); // get all the packages
        }
      })
    }
    if (localStorage.getItem("role") !== "WEBASTO_END_USER_RESIDENTIAL") {
      this.getAllAvailableSites(orgid);
    }
   
  }


  // languageChange(){
  //   let selectedLanguage = document.getElementById('language').value;
  //   let fields = this.state.fields;
  //   fields['language'] = selectedLanguage;
  //   this.setState({
  //     fields
  //   })
  // }

  async getAccountDetails(){
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        let fields = this.state.fields;
        fields.status = true;
        if (response !== undefined) {
          this.setState({ dataArray: response.organisationDTOS, fields })
        }
        this.getRoles();
  }

  componentDidMount() {
    document.getElementById("myCheck").checked = true;

    this.getAccountDetails();
    
      // this.getLanguages();
     
  }

  
  async getAllAvailableSites(orgId) {
    let url = `${baseUrl.URLPath}sitemanagement/usersites?orgId=` + orgId;
    let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if(response){
          let beforeSort = response;
          let afterSort = [];
          let names = [];
      for (let item in beforeSort) {
          names.push(beforeSort[item].name);
      }
      names = names.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });

      for (let site in names) {
        for (let itm in beforeSort) {
          if (beforeSort[itm].name === names[site]) {
            afterSort.push(beforeSort[itm]);
          }
        }
      }

      this.setState({ ...this.state, availableSites: beforeSort });
        }
  }

  getIsSiteSelected(site) {
    const siteIndex = this.state.selectedSites.findIndex((el) => el === site);
    return siteIndex > -1;
  }

  getIsAllSitesSelected() {
    const { selectedSites, availableSites } = this.state;
    let isAllChecked = true;
    if (availableSites) {
      availableSites.forEach((element) => {
        const isChecked = selectedSites.some((el) => el.id === element.id);
        if (!isChecked) {
          isAllChecked = false;
        }
      });
    } else {
      isAllChecked = false;
    }
    return isAllChecked;
  }

  onSelectSite(event, el) {
    const isChecked = event.target.checked;
    const selectedSites = [...this.state.selectedSites];
    const elementIndex = selectedSites.findIndex((element) => element === el);
    if (isChecked) {
      if (elementIndex < 0) {
        selectedSites.push(el);
      }
    } else {
      selectedSites.splice(elementIndex, 1);
    }

    this.setState({ ...this.state, selectedSites });
  }

  onUpdateSiteSelectAll(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      const selectedSites = [...this.state.availableSites];
      this.setState({ ...this.state, selectedSites });
    } else {
      this.setState({ ...this.state, selectedSites: [] });
    }
  }

  async getRoles() {
    var url = baseUrl.URLPath + "roles/";
    let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if(response){
          let fields = this.state.fields;
        fields.status = true;
        if (response !== undefined) {
          this.setState({ roles: response.roles })
        }
        }
  }

  // get packages 
  async getPackages() {
    var url = baseUrl.URLPath + "platform/user/packages?tenantId=" + this.state.orgIdentifier;
    let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if (response !== undefined) {
          this.setState({listOfPackage:response})
        }
  }

  packageLevelChange(e) {
    let fields = this.state.fields;
    fields["userPackage"] = e.target.value;
    this.setState({ fields }, () => {});
  }

  render() {
    const{t}=this.props
    var getRole = localStorage.getItem("role");

    let addCommercialAndResidential = false;
    let isCommercialAndResidential = false;

    let addCommercialAndResidentialAndSiteAdmin = false;
    let isCommercialAndResidentialAndSiteAdmin = false;

    if (getRole === "WEBASTO_SUPER_ADMIN_DEVOPS" ||
      getRole === "WEBASTO_CUSTOMER_ADMIN" ||
      getRole === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" ||
      getRole === "WEBASTO_SITE_ADMIN") {
      addCommercialAndResidential = true;
    }

    if (this.state.fields.role === "WEBASTO_END_USER_COMMERCIAL" || this.state.fields.role === "WEBASTO_END_USER_RESIDENTIAL") {
      isCommercialAndResidential = true;
    }

    if (getRole === "WEBASTO_SUPER_ADMIN_DEVOPS" ||
      getRole === "WEBASTO_CUSTOMER_ADMIN" ||
      getRole === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE") {
      addCommercialAndResidentialAndSiteAdmin = true;
    }

    if (this.state.fields.role === "WEBASTO_END_USER_COMMERCIAL" || this.state.fields.role === "WEBASTO_END_USER_RESIDENTIAL" || this.state.fields.role === "WEBASTO_SITE_ADMIN") {
      isCommercialAndResidentialAndSiteAdmin = true;
    }

    const organisationDTOS = this.state.dataArray.map((dataArray, index) => {
      return (
        <option value={dataArray.id} data-identifier={dataArray.identifier} >{dataArray.company}</option>
      )
    })

    const roles = this.state.roles.map((role, index) => {
      return (
        <option value={role.identifier}>{role.displayName}</option>
      )
    })

    const packageList = this.state.listOfPackage?.map((data, index) => {
      return (
        <option value={data.identifier} data-order={data.packageOrder} > {data.packageName}</option>
      )
    })

    return (
      <>
        <main className="content-div">
          <p>
            {" "}
            {t("Users")}
            <div className="breadcrumb_div">
              {t("Settings")} &gt;{" "}
              <Link to="/userManagementController">{t("Users")}</Link> &gt;{" "}
              <span className="breadcrumb_page">{t("add")}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button
                    type="button"
                    data-test="button"
                    className="btn_primary"
                    onClick={this.backToList}
                  >
                    <i className="fa fa-angle-left mr-2" aria-hidden="true"></i>{" "}
                    {t("back")}
                  </button>
                </div>
              </div>
            </div>
            <MDBRow className="mb-4">
              <MDBCol sm="12">
                <div>
                  <form className="needs-validation">
                    <MDBRow>
                      <div className="col-md-12 mb-3">
                        <span className=" form-title">{t("user_details")}</span>
                      </div>

                      <MDBCol md="4">
                        <select
                          className="browser-default custom-select select_height mb_8"
                          id="organisation"
                          onChange={this.organizationChange.bind(this)}
                        >
                          <option value="">
                            -- {t("select_organization")} * --
                          </option>
                          {organisationDTOS}
                        </select>
                        <span className="error_msg w3-animate-top">
                          {" "}
                          <span style={{ color: "red" }}>
                            {this.state.errors["organisationempty"]}
                          </span>
                        </span>
                      </MDBCol>
                      <div className="col-md-4 md-form">
                        <input
                          name="Email"
                          type="text"
                          id="email"
                          className="form-control text_lowercase"
                          onChange={this.changeHandler.bind(this, "email")}
                          value={this.state.fields["email"]}
                        />
                        <label
                          htmlFor="email"
                          className={`${
                            this.state.fields["email"] !== "" &&
                            this.state.fields["email"] !== null
                              ? "active"
                              : ""
                          } ml-15`}
                        >
                          {t("e_mail")} *
                        </label>
                        <span className="error_msg w3-animate-top">
                          {" "}
                          <span style={{ color: "red" }}>
                            {this.state.errors["validemail"]}
                          </span>
                        </span>
                        <span className="error_msg w3-animate-top">
                          {" "}
                          <span style={{ color: "red" }}>
                            {this.state.errors["emailempty"]}
                          </span>
                        </span>
                        <span className="error_msg w3-animate-top">
                          {" "}
                          <span style={{ color: "red" }}>
                            {this.state.errors["entervalidemail"]}
                          </span>
                        </span>
                        <span className="error_msg w3-animate-top">
                          {" "}
                          <span style={{ color: "red" }}>
                            {this.state.errors["userexists"]}
                          </span>
                        </span>
                      </div>
                      <div className="col-md-4 md-form">
                        <input
                          name="firstName"
                          type="text"
                          id="firstName"
                          className="form-control text_lowercase"
                          onChange={this.changeHandler.bind(this, "firstName")}
                          value={this.state.fields["firstName"]}
                        />
                        <label
                          htmlFor="firstName"
                          className={`${
                            this.state.fields["firstName"] !== "" &&
                            this.state.fields["firstName"] !== null
                              ? "active"
                              : ""
                          } ml-15`}
                        >
                          {t("firstName")} *
                        </label>
                        <span className="error_msg w3-animate-top">
                          {" "}
                          <span style={{ color: "red" }}>
                            {this.state.errors["firstName"]}
                          </span>
                        </span>
                      </div>
                      <div className="col-md-4 md-form">
                        <input
                          name="lastName"
                          type="text"
                          id="lastName"
                          className="form-control text_lowercase"
                          onChange={this.changeHandler.bind(this, "lastName")}
                          value={this.state.fields["lastName"]}
                        />
                        <label
                          htmlFor="lastName"
                          className={`${
                            this.state.fields["lastName"] !== "" &&
                            this.state.fields["lastName"] !== null
                              ? "active"
                              : ""
                          } ml-15`}
                        >
                          {t("lastName")} *
                        </label>
                        <span className="error_msg w3-animate-top">
                          {" "}
                          <span style={{ color: "red" }}>
                            {this.state.errors["lastName"]}
                          </span>
                        </span>
                      </div>
                      <div className="col-md-4 md-form">
                        <input
                          name="mobileNumber"
                          type="text"
                          id="mobileNumber"
                          className="form-control text_lowercase"
                          onChange={this.changeHandler.bind(
                            this,
                            "mobileNumber"
                          )}
                          value={this.state.fields["mobileNumber"]}
                        />
                        <label
                          htmlFor="mobileNumber"
                          className={`${
                            this.state.fields["mobileNumber"] !== "" &&
                            this.state.fields["mobileNumber"] !== null
                              ? "active"
                              : ""
                          } ml-15`}
                        >
                          {t("mobile_no")} *
                        </label>
                        <span className="error_msg w3-animate-top">
                          {" "}
                          <span style={{ color: "red" }}>
                            {this.state.errors["mobileNumber"]}
                          </span>
                        </span>
                        <span className="error_msg w3-animate-top">
                          {" "}
                          <span style={{ color: "red" }}>
                            {this.state.errors["validphoneNo"]}
                          </span>
                        </span>
                      </div>
                      <MDBCol md="4">
                        <select
                          className="browser-default custom-select select_height mb_8"
                          id="role"
                          onChange={this.roleidChange.bind(this)}
                          value={this.state.fields.role}
                        >
                          <option value=""> -- {t("select_role")} * --</option>
                          {getRole === "WEBASTO_SITE_ADMIN" ? (
                            <>
                              <option value="WEBASTO_END_USER_COMMERCIAL">
                                {t("end_user_commercal")}
                              </option>
                              <option value="WEBASTO_END_USER_RESIDENTIAL">
                                {t("end_user_residential")}
                              </option>
                            </>
                          ) : (
                            <>{roles}</>
                          )}
                        </select>
                        <span className="error_msg w3-animate-top">
                          {" "}
                          <span style={{ color: "red" }}>
                            {this.state.errors["role"]}
                          </span>
                        </span>
                      </MDBCol>
                      {/* <MDBCol md="4">
                        <select
                          className="browser-default custom-select select_height mb_8"
                          id="language"
                          onChange={this.languageChange.bind(this)}
                          value={this.state.fields.language}
                        >
                          <option value="">
                            {" "}
                            -- {t("select_language")} --
                          </option>
                          {this.state.listOfLanguages?.map(
                            (language, index) => {
                              return <option key={index}>{language}</option>;
                            }
                          )}
                        </select>
                      </MDBCol> */}
                      {(getRole === "WEBASTO_SUPER_ADMIN_DEVOPS" || getRole === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE") &&
                        this.state.orgIdentifier === "webasto" &&
                        this.state.fields.role ===
                          "WEBASTO_END_USER_RESIDENTIAL" && (
                          <MDBCol md="4">
                            <label class="pull-left mr-1 mt-2 pt-1 code_clr pl-0">
                              {t("change_package_level")} *
                            </label>
                            <select
                              className="browser-default custom-select select_height w_53 indent pull-right"
                              id="packageLevel"
                              onChange={this.packageLevelChange.bind(this)}
                              value={
                                this.state.fields.userPackage === ""
                                  ? "Entry"
                                  : this.state.fields.userPackage
                              }
                            >
                              {packageList}
                            </select>
                          </MDBCol>
                        )}

                      <div className="col-md-4 md-form">
                        <input
                          name="employeeId"
                          type="text"
                          id="employeeId"
                          className="form-control text_lowercase"
                          onChange={this.changeHandler.bind(this, "employeeId")}
                          value={this.state.fields["employeeId"]}
                        />
                        <label
                          htmlFor="employeeId"
                          className={`${
                            this.state.fields["employeeId"] !== "" &&
                            this.state.fields["employeeId"] !== null
                              ? "active"
                              : ""
                          } ml-15`}
                        >
                          {t("employee_number_optional")}
                        </label>
                        <span className="error_msg w3-animate-top">
                          {" "}
                          <span style={{ color: "red" }}>
                            {this.state.errors["employeeId"]}
                          </span>
                        </span>
                      </div>

                      {(getRole === "WEBASTO_SUPER_ADMIN_DEVOPS" ||  getRole === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE") &&
                        this.state.orgIdentifier === "webasto" &&
                        this.state.fields.role ===
                          "WEBASTO_END_USER_RESIDENTIAL" && (
                          <MDBCol md="4" className="mt-4 pl-1 custom-checkbox">
                            <Form.Check
                              custom
                              checked={this.state.isServiceManagementActive}
                              onChange={this.handleServiceManagement}
                              type="checkbox"
                              id="myCheckId"
                              label={t("activation_service_management")}
                              className='activation-sm-style'
                            />
                          </MDBCol>
                        )}
                      {((isCommercialAndResidential &&
                        addCommercialAndResidential) ||
                        (isCommercialAndResidentialAndSiteAdmin &&
                          addCommercialAndResidentialAndSiteAdmin)) &&
                        this.state.availableSites &&
                        this.state.availableSites.length > 0 && (
                          <>
                            <div className="col-md-12 mt-5">
                              <div className="d-flex align-items-center">
                                <span className="form-title border-0 mt-0">
                                  {t("group_access")}
                                </span>
                                <Form.Check
                                  custom
                                  type="checkbox"
                                  id="siteAccessCheck"
                                  label={this.props.t("all")}
                                  className="ml-5"
                                  checked={this.getIsAllSitesSelected()}
                                  onChange={this.onUpdateSiteSelectAll}
                                />
                              </div>
                            </div>
                            <MDBCol md="10">
                              <MDBRow>
                                {this.state.availableSites?.map((el, index) => (
                                  <MDBCol md="3" key={index}>
                                    <Form.Check
                                      custom
                                      type="checkbox"
                                      id={`siteAccess_siteName_${el.id}`}
                                      label={el.name}
                                      checked={this.getIsSiteSelected(el)}
                                      onChange={(event) =>
                                        this.onSelectSite(event, el)
                                      }
                                    />
                                  </MDBCol>
                                ))}
                              </MDBRow>
                              <span className="error_msg w3-animate-top mt-1">
                                {" "}
                                <span style={{ color: "red" }}>
                                  {this.state.errors["siteAccess"]}
                                </span>
                              </span>
                            </MDBCol>
                          </>
                        )}
                      <MDBCol md="12" className="mt-4">
                        <Form.Check
                          custom
                          value={this.state.fields.status}
                          onChange={this.activeChange}
                          type="checkbox"
                          id="myCheck"
                          label={t("active")}
                        />
                      </MDBCol>
                      {this.state.fields.role !==
                        "WEBASTO_SUPER_ADMIN_DEVOPS" &&
                        this.state.fields.role !==
                          "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" &&
                        this.state.fields.role !==
                          "WEBASTO_CUSTOMER_ADMIN_READ_ONLY" && (
                          <>
                            <MDBCol md="12" className="mt-4">
                              <i
                                className="fa fa-plus-circle mr-2"
                                aria-hidden="true"
                                onClick={this.addMultipleRfid}
                                style={{ fontSize: "18px" }}
                              ></i>
                              <h>{t("additional_rfid_tag")}</h>
                              <span className="error_msg w3-animate-top mt-2">
                                {" "}
                                <span style={{ color: "red" }}>
                                  {this.state.errors["emptyidTagArray"]}
                                </span>
                              </span>
                            </MDBCol>

                            {this.state.multipleRfidArray.map(
                              (multipleRfidArray, index) => {
                                return (
                                  <>
                                    <div className="col-md-3 md-form mt-4">
                                      <input
                                        name={`rfid_${index}`}
                                        type="text"
                                        id={`rfid_${index}`}
                                        className="form-control text_lowercase"
                                        onChange={this.changeRfid.bind(
                                          this,
                                          index,
                                          "rfidTag"
                                        )}
                                        value={multipleRfidArray.rfidTag}
                                      />
                                      <label
                                        htmlFor={`rfid_${index}`}
                                        className={`${
                                          multipleRfidArray.rfidTag !== "" &&
                                          multipleRfidArray.rfidTag !== null
                                            ? "active"
                                            : ""
                                        } ml-15`}
                                      >
                                        {t("rfid_tag_optional")}
                                      </label>
                                      <span className="error_msg w3-animate-top">
                                        {" "}
                                        <span style={{ color: "red" }}>
                                          {this.state.errors["rfidempty"]}
                                        </span>
                                      </span>
                                      <span className="error_msg w3-animate-top">
                                        {" "}
                                        <span style={{ color: "red" }}>
                                          {this.state.errors["rfidexists"]}
                                        </span>
                                      </span>
                                      <span
                                        className={`rfidTag_${index}`}
                                        style={{
                                          color: "red",
                                          fontSize: "13px",
                                        }}
                                      ></span>
                                    </div>

                                    <div className="col-md-3 md-form mt-4">
                                      <input
                                        name={`aliasName_${index}`}
                                        type="text"
                                        id={`aliasName_${index}`}
                                        className="form-control text_lowercase"
                                        onChange={this.changeRfid.bind(
                                          this,
                                          index,
                                          "aliasName"
                                        )}
                                        value={multipleRfidArray.aliasName}
                                      />
                                      <label
                                        htmlFor={`aliasName_${index}`}
                                        className={`${
                                          multipleRfidArray.aliasName !== "" &&
                                          multipleRfidArray.aliasName !== null
                                            ? "active"
                                            : ""
                                        } ml-15`}
                                      >
                                        {t("rfid_tag_alias")}
                                      </label>
                                      <span className="error_msg w3-animate-top">
                                        {" "}
                                        <span style={{ color: "red" }}>
                                          {this.state.errors["aliasNameEmpty"]}
                                        </span>
                                      </span>
                                      <span
                                        className={`aliasName_${index}`}
                                        style={{
                                          color: "red",
                                          fontSize: "13px",
                                        }}
                                      ></span>
                                    </div>
                                    <MDBCol md="5" className="form_margin">
                                      <label className="pull-left mr-1 mt-2 pt-1 code_clr pl-2">
                                        {t("choose_rfid_tag_type")} *
                                        <span className="custom_tooltip">
                                          <i
                                            className="fa fa-info-circle"
                                            aria-hidden="true"
                                          ></i>
                                          <div className="custom_tooltiptext">
                                            <div className="custom_tooltip_header">
                                              {t("rfid_tag_type")}
                                            </div>
                                            <div className="custom_tooltip_body">
                                              <div>{t("type_of_rfid_tag")}</div>
                                              <div>
                                                <span className="font-bold">
                                                  {t("private")}:{" "}
                                                </span>
                                                {t(
                                                  "rfid_tag_intended_for_private_use"
                                                )}
                                              </div>
                                              <div>
                                                <span className="font-bold">
                                                  {t("company")}:{" "}
                                                </span>
                                                {t(
                                                  "rfid_tag_was_issued_by_company"
                                                )}
                                              </div>
                                              <div>
                                                <span className="font-bold">
                                                  {t("other")}:{" "}
                                                </span>
                                                {t(
                                                  "default_setting_no_further_logic_involved"
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </span>
                                      </label>
                                      <select
                                        className="browser-default custom-select select_height w_53 indent mt-1"
                                        id="rfidTagType"
                                        onChange={this.changeRfid.bind(
                                          this,
                                          index,
                                          "tagType"
                                        )}
                                        value={multipleRfidArray.tagType}
                                      >
                                        <option value="other">
                                          {t("other")}
                                        </option>
                                        <option value="private">
                                          {t("private")}
                                        </option>
                                        <option value="company">
                                          {t("company")}
                                        </option>
                                      </select>
                                      <div className="mt-3">
                                        <span
                                          className={`tagType_${index}`}
                                          style={{
                                            color: "red",
                                            fontSize: "13px",
                                          }}
                                        ></span>
                                      </div>
                                    </MDBCol>
                                    <MDBCol md="1" className=" form_margin">
                                      <i
                                        className="fas fa-times-circle mt-4"
                                        onClick={this.removeRfid.bind(
                                          this,
                                          index
                                        )}
                                      ></i>
                                    </MDBCol>
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                    </MDBRow>
                    <div className="error_msg w3-animate-top">
                      {" "}
                      <span
                        style={{ color: "red" }}
                        className="rfidexists"
                      ></span>
                    </div>
                    <div className="error_msg w3-animate-top">
                      {" "}
                      <span
                        style={{ color: "red" }}
                        className="employeeidexists"
                      ></span>
                    </div>

                    <button
                      type="button"
                      data-test="button"
                      className="mt-5 btn_primary mr-3"
                      onClick={this.handleSubmit}
                    >
                     
                      {t("save")} <i className="fas fa-save ml-2"></i>
                    </button>
                  </form>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        </main>
        <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('Success')}</MDBModalHeader>
          <MDBModalBody>
            {t('user_successfully_added')}
          </MDBModalBody>
          <MDBModalFooter>
            <button className="btn_primary" data-test="button" type="button" onClick={this.tagOk}>{t('OK')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.isOpenProfileModal}  size='md' className='modal-smpopup'>
          <MDBModalBody className="service-management-profile">
          <h4 className="mb-4">{t('service_management_profile')}</h4>
            
            <form className='smpopup-form'>
              <div className='smpopup-form-input-containers'>
              <div className='md-form mt-2 mb-3'>
              <input name="serviceAccessCode" type="text" id="serviceAccessCode"  readOnly={true} value={this.state.profileData.serviceAccessCode} 
              className='form-control disable_clr pl-0'/>
              <label htmlFor="serviceAccessCode"  className={`${
                            this.state.profileData["serviceAccessCode"] !== "" &&
                            this.state.profileData["serviceAccessCode"] !== null
                              ? "active"
                              : ""
                          } ml-11 disable_clr`}>{t('service_management_access_code_auto_generated')}</label>

              
              </div>
              
              <div className='md-form mb-4'>    
              <input name="companyName" type="text"  id="companyName" value={this.state.profileData["companyName"]} 
              className='form-control pl-0' onChange={this.handleFormInputChange}
              onBlur={this.handleCompanyNameError} maxLength="35" />
		          <label htmlFor="companyName" className={`${
                            this.state.profileData["companyName"] !== "" &&
                            this.state.profileData["companyName"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('company_name')}*</label>

              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["iscompanyNameEmpty"]}</span></span>
              </div>
              
              <div className='md-form mb-3'>
              <input name="city" type="text" id="city" value={this.state.profileData["city"]} 
              className='form-control pl-0' onChange={this.handleFormInputChange}/>
              <label  htmlFor="city" className={`${
                            this.state.profileData["city"] !== "" &&
                            this.state.profileData["city"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('city')}</label>
              </div>

              <div className='md-form mb-3'>
              <input name="zipCode" type="text" value={this.state.profileData.zipCode} id="zipCode"
              className='form-control pl-0' onChange={this.handleFormInputChange}/>
              <label  htmlFor="zipCode" className={`${
                            this.state.profileData["zipCode"] !== "" &&
                            this.state.profileData["zipCode"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('zipCode')}</label>
              </div>

              <div className='md-form mb-3'>
              <input name="address" type="text" value={this.state.profileData.address} id="address"
              className='form-control pl-0' onChange={this.handleFormInputChange}/>
              <label  htmlFor="address" className={`${
                            this.state.profileData["address"] !== "" &&
                            this.state.profileData["address"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('address')}</label>
              </div>

              <div className='md-form mb-3'>
              <input name="country" type="text" value={this.state.profileData.country} id="country"
              className='form-control pl-0' onChange={this.handleFormInputChange}/>
              <label  htmlFor="country" className={`${
                            this.state.profileData["country"] !== "" &&
                            this.state.profileData["country"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('country')}</label>
              </div>

              <div className='md-form mb-4'>
              <input name="telephone" type="text" value={this.state.profileData.telephone} id="telephone"
              className='form-control pl-0' onChange={this.handleFormInputChange}/>
              <label  htmlFor="telephone" className={`${
                            this.state.profileData["telephone"] !== "" &&
                            this.state.profileData["telephone"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('telephone')}</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["istelephoneEmpty"]}</span></span>

              </div>

              <div className='md-form mb-4'>
              <input name="email" type="text" value={this.state.profileData.email} id="email"
              className='form-control pl-0' onChange={this.handleFormInputChange} onBlur={this.handleEmailError}/>
              <label  htmlFor="email" className={`${
                            this.state.profileData["email"] !== "" &&
                            this.state.profileData["email"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('email')}</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["isemailEmpty"]}</span></span>

              </div>

              <div className='md-form mb-3'>
              <input name="websiteUrl" type="text" value={this.state.profileData.websiteUrl} id="websiteUrl"
              className='form-control pl-0' onChange={this.handleFormInputChange} onBlur={this.validateURL}/>
              <label  htmlFor="websiteUrl" className={`${
                            this.state.profileData["websiteUrl"] !== "" &&
                            this.state.profileData["websiteUrl"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('website_url')}</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["iswebsiteUrlEmpty"]}</span></span>
              </div>

              </div>
              <div className='smpopup-btns mt-4' >
                <button type="button" className='smpopup-savebtn' onClick={this.handleSaveSMProfile}>{t('save')}</button>
                <button type="button" className='smpopup-cancelbtn' onClick={this.toggleProfileModal}>{t('cancel')}</button>
              </div>
            
            </form>
          </MDBModalBody>
          
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(addUserManagement);
