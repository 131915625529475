import React from 'react';
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Table } from 'react-bootstrap'
import Pagination from "react-js-pagination";
import { withTranslation } from 'react-i18next';
import $ from "jquery";
import Loader from '../views/loader';

class QRCodeAuthorizationTagsTemplate extends React.Component {
    render() {
        let data = "";
        const { t } = this.props
        if(this.props.availableAuthTag){
            data =  this.props.availableAuthTag?.map((dataArray, index) => {
                return (
                    <tr>
                        <td>
                            <Link to={{ pathname: '/viewTag', state: dataArray.authorizationTagId }}>{dataArray.authorizationTag}</Link>
                        </td>
                        <td className="wrap-word">
                            {dataArray.qrId}
                        </td>
                    </tr>
                )
            });
        }
      

        return (
            <main className="content-div" >
                <p>{t('qrcode_authorizationtags')}
                    <div className="breadcrumb_div">
                        {t('Group Management')} &gt; <span className="breadcrumb_page">{t('qrcode_authorizationtags')}</span>
                    </div>
                </p>
                <div className="page-outerdiv">
                    <div className="row mt-2">
                        <div className="col-md-9 pull-right t-8">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <div className="input-group">
                                        <i className="fas fa-search fa-sm input-searchIcon"></i>
                                        <Typeahead
                                            labelKey="name"
                                            className="w_80"
                                            id="rbt_id"
                                            minLength={3}
                                            options={this.props.searchSuggestions}
                                            placeholder={t('choose_authorization_tag')}
                                            onKeyDown={this.props.handleKeyDown.bind(this)}
                                            onChange={this.props.onChangeTypeahead}
                                            onBlur={this.props.onBlurEvent}
                                            ref={this.props.getParentElem}
                                        />
                                        <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.props.clearSearchSuggestions}>
                                            <span aria-hidden="true">x</span>
                                        </button>
                                        <div class="input-group-btn">
                                            <button class="input-searchBtn find-btn" type="submit" onClick={() => {
                                                if (this.props.searchStr === "" || this.props.searchStr === null) {
                                                    $('.empty_site').text(t('please_enter_qrauth_tag'))
                                                } else {
                                                    $('.empty_site').text("")
                                                    this.props.searchSite();
                                                }
                                            }}>{t('find')}</button>
                                        </div>
                                            <span className="pull-left error_msg w3-animate-top mt-1 empty_site" style={{ color: "red" }}></span>
                                            {
                                                this.props.isSearchNotFound && <span className="pull-left error_msg w3-animate-top mt-1">
                                                    <span style={{ color: "red" }}>{t('authorizationtags_not_exists')}</span>
                                                </span>
                                            }
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 pull-right t-8">
                            {
                                !this.props.hideShowEntries &&
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="pull-right">
                                            <label className="sub-text pull-left">{t('show_entries')}</label>
                                            <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                                <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.props.showEntriesChange} value={this.props.pageSize}>
                                                    <option>10</option>
                                                    <option>20</option>
                                                    <option>30</option>
                                                    <option>40</option>
                                                    <option>50</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-12">
                            <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        <th>{t('Authorizationtags')}</th>
                                        <th>{t('valid_for_qr_codeid')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data}
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-12 mt-4">
                        <Pagination
                                // hideDisabled
                                activePage={this.props.activePage}
                                itemsCountPerPage={this.props.pageSize}
                                totalItemsCount={this.props.noOfRecords}
                                onChange={this.props.handlePageChange}
                                prevPageText={t('prev')}
                                nextPageText={t('next')}
                                itemClassPrev="prevBtn"
                                pageRangeDisplayed="1"
                                activeClass="activeLi active"
                                itemClassNext="nextBtn"
                                hideFirstLastPages={true}
                                disableInitialCallback={true}
                                disabledClass="disabled disabledtextColor"
                            />
                        </div>
                    </div>
                </div>
                {this.props.loading ? (
                <Loader loader={this.props.loading} />
                ) : ("")}
            </main>
        )
    }
}

export default withTranslation()(QRCodeAuthorizationTagsTemplate);