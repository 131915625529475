import React from 'react';
import { MDBBtn,MDBRow,MDBCol, MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import * as baseUrl from './constants';
import {Link} from 'react-router-dom';
import { withTranslation } from 'react-i18next';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

class bulkImport extends React.Component {  
    constructor (props){
        super(props);
        this.state = { // variables
          fields: { organisation:''},
            data:{},
            sucess:{},
            errors:{},
            docFile:"",
            identifier:"",
            downloadLink:"",
            model:"false",
            model1:"false",
            modelTile:"",
            modelBody:"",
            selectedFile:"",
            page_title:"Bulk Import",
            downloadUrl:"",
            dataArray:[],
            modal: false,
            togglesession: false
        }
        this.handleSubmit = this.handleSubmit.bind(this) 
        this.startDownload = this.startDownload.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.organizationChange = this.organizationChange.bind(this)
        this.getOrglist = this.getOrglist.bind(this)
        this.togglesession = this.togglesession.bind(this);
        this.tagOk = this.tagOk.bind(this);
        this.getTemplate = this.getTemplate.bind(this);
        this.getUplodedTemplate = this.getUplodedTemplate.bind(this);
      }


      organizationChange(){
        let errors = {};
        
        var x = document.getElementById("organisation").value;
        this.setState({identifier:x});
        let fields = this.state.fields;
        fields["organisation"] = x;
        this.setState({fields});
        errors["notifyOrg"] =  this.props.t('excel_sheet_org_id_is')+" " +x+" "+ this.props.t('before_uploading')
        this.setState({errors: errors});
      
    }
    async getOrglist(){

      var url = baseUrl.URLPath + "admin/v1.0/account/all";
      let type = ApiMethodTypes.GET;
  let headers = getSecurityHeaders();
  let payload = {};

  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if(response!==undefined){
    this.setState({ dataArray:response.organisationDTOS})
      }
     
 }

  // functions call when click on download button for downloading status report
  async startDownload(){
    document.getElementById("loader_image_div").style.display = "block";

     
    this.setState({ 
      modal: !this.state.modal
    });

    var errors={};
    var Url1 = baseUrl.URLPath + "cpinventory/bulkupload"

    var data = new FormData();
    data.append("file",this.state.docFile);
    data.append("tenantId",this.state.identifier);

  await fetch(Url1, {
    method: "POST",
    body: data,
    headers:{
      "Authorization":localStorage.getItem("Authorization"),
      }
    }).then(resp =>{
             if(resp.status === 400){
              document.getElementById("loader_image_div").style.display = "none";
            errors["wrongFile"] = this.props.t('there_is_a_problem_in_processing_the_file.');
              this.setState({
                  errors: errors
                });                  }
              if(resp.status===500){
                document.getElementById("loader_image_div").style.display = "none";
                errors["internalServer"] = this.props.t('server_error_please_contact_admin');
                this.setState({
                    errors: errors
                  });
              }else if(resp.status===200){
                
                return resp.json();
              }else if(resp.status===401){
                setTimeout(() => {
                  window.location.reload();
                  
              }, 1);
              }
            
            }).then((response) =>{
                
              if (response.status === "FAILURE") {
                        document.getElementById("loader_image_div").style.display = "none";
                        errors["choosefile"] = this.props.t(response.errorMessage);
                        this.setState({
                            errors: errors
                          }); 
                    }  
                    else if (response.status === "SUCCESS") {
                         setTimeout(() => {
                            document.getElementById("loader_image_div").style.display = "none";
                            this.setState({
                                modal1: !this.state.modal1,
                            });
                        }, 2000)
                            this.setState({
                                downloadLink: response.downloadLink
                            })
                    }   else  {
                        document.getElementById("loader_image_div").style.display = "none"; 
                        errors["choosefile"] = this.props.t('there_is_a_problem_in_processing_the_file.');
                        this.setState({
                            errors: errors
                          });
                } 
                });
               
  }
  handleChange(e){
    var errors={};
    var imagedata = document.querySelector('input[type="file"]').files[0];
     this.setState({
        docFile:imagedata
     })
     errors["choosefile"]="";
     this.setState({errors:errors});
  }

//toggle (show and hide) confirmation popup
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  togglesession(){
    this.setState({
      togglesession: !this.state.togglesession
    });
  }

//toggle (show and hide) success popup 
  toggleSuccess = (text) =>{
    this.setState({
      modal1: !this.state.modal1
    });
    this.props.history.push('/inventoryList')
    if(text==="Download"){
       this.getUplodedTemplate();
    }
  }

  // download uploded template 
  async getUplodedTemplate() {
    var url = baseUrl.URLPath + "cpinventory/download";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false, true);
      if (response !== undefined) {
        let url = window.URL.createObjectURL(response);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'Inventory-Template.xlsx';
        a.click();
    }
  }

  //when click on save button 
  handleSubmit(event){
    
    var errors={};
    if(this.state.fields.organisation!==''){
      event.preventDefault()
      errors["organisationempty"]="";
      this.setState({errors:errors});
    } else{
      errors["organisationempty"]= this.props.t('please_select_organization');
      this.setState({errors:errors});
    }

    if(this.state.docFile==="" || this.state.docFile === undefined){
      event.preventDefault();
      errors["choosefile"]= this.props.t('choose_a_file');
      this.setState({errors:errors});
    } else{
      errors["choosefile"]="";
      this.setState({errors:errors});
    }

    if(this.state.docFile!=="" && this.state.fields.organisation!=="" && this.state.docFile !== undefined){
       this.setState({
        modal: !this.state.modal
      }); 
    }
   }

   UNSAFE_componentWillMount(){
        var the_arr = baseUrl.URLPath.split('/');
        the_arr.pop();
        the_arr.pop();
        var newlink = the_arr.join('/') + "/";
        this.setState({
          downloadUrl:newlink
        })
   }

  async getTemplate() {
    
    var url = baseUrl.URLPath + "cpinventory/download/template";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false, true);
    if (response !== undefined) {
      let url = window.URL.createObjectURL(response);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'Inventory-Template.xlsx';
      a.click();
  }
  }
      
    componentDidMount(){     
        this.getOrglist();
    }
    tagOk() {
      this.props.history.push('/');
      window.location.reload();
    }

    render() {
      const{t}=this.props

      const organisationDTOS =this.state.dataArray.map((dataArray, index) => {
        return(
          <option value={dataArray.identifier}>{dataArray.company}</option>
        )
       
      })
        return (
   <>
       <main class="content-div" >
       <p>{t('Inventory')}
              <div className="breadcrumb_div">
             {t('Maintenance')}&gt; <Link to="/inventoryList" > <span className="">{t('Inventory')}</span></Link> &gt; <span className="breadcrumb_page">{t('bulk_import')}</span>
              </div>
           </p>
        <div className="page-outerdiv">
        <form
          className='needs-validation'
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBCol sm="12" >
            <table  id="table-to-xlsx" className="excel_visi">
              <thead>
                <th>
                 {t('chargePoint_id')}*
                </th>
                <th>
                {t('description')}
                </th>
                <th>{t('Houseno')}</th>
                <th>{t('street')}</th>
                <th>{t('zipcode')}</th>
                <th>{t('city')}</th>
                <th>{t('country')}</th>
                <th>{t('comments')}</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                </tr>
              </tbody>
            </table>

            {/* </tableToExcel> */}
          </MDBCol>
          <MDBRow className="mb-4 mt-3" >
          <MDBCol sm="12" >
            <div class="md-form input-group mb-0" > 
              {/* <a href = {this.state.downloadUrl +"/cpinventory/download/template"} > */}
              <div  className="fa fa-download download_input cursor-pointer" onClick={this.getTemplate.bind(this)} >
            </div>
           {/* </a> */}
              <div class="input-group-append">
                <span class="input-group-text md-addon">{t('download_template')}</span>
              </div>
            </div>
          </MDBCol>
          <MDBCol sm="6"  className="mt-5" >
          <input type="file" accept=".xls,.xlsx" name="file" id="exampleFile"  onChange={this.handleChange.bind(this)}/>
          <span className="error_msg w3-animate-top mt-1">  <span style={{color: "red"}}>{this.state.errors["choosefile"]}</span></span> 
          <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["wrongFile"]}</span></span>
          <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["internalServer"]}</span></span>
        </MDBCol>
        <MDBCol md="3" className="m_26" >
          <select className="browser-default custom-select select_height" id="organisation" onChange={this.organizationChange.bind(this)} >                          
            <option value="">-- {t('select_organization')} --</option>
            {organisationDTOS}
          </select> 
          <span className="error_msg w3-animate-top mt-2">  <span style={{color: "green"}}>{this.state.errors["notifyOrg"]}</span></span>
          <span className="error_msg w3-animate-top mt-1">  <span style={{color: "red"}}>{this.state.errors["organisationempty"]}</span></span> 
          </MDBCol>
        <MDBCol sm="3"  className="">
          <button type = "button" className="mt-5 btn_primary" onClick={this.handleSubmit}>{t('upload')}</button>
        </MDBCol>
        </MDBRow >
    </form>
      </div>
      </main>

      <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>

          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('do_you_want_to_start_import')}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={this.toggle} >{t('cancel')}</MDBBtn>
              <MDBBtn color="primary" onClick={this.startDownload} >{t('start')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modal1} toggle={this.toggleSuccess} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleSuccess}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
                {t('select_download_button_to_downlaod_the_results')}
            </MDBModalBody>
            <MDBModalFooter>
            {/* <a style={{ color:'#fff'}} href={this.state.downloadUrl+ "cpinventory/download"} > */}
              <MDBBtn color="primary" onClick={this.toggleSuccess.bind(this,"Download")}>{t('download')} 
            </MDBBtn>
            {/* </a> */}
              <MDBBtn color="primary" onClick={this.toggleSuccess.bind(this,"ok")} >{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

            <MDBModal isOpen={this.state.togglesession} toggle={this.togglesession} size="md" className="model_top">
              <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
              <MDBModalBody>
                {t('please_login_again')}
              </MDBModalBody>
              <MDBModalFooter>
                <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>{t('ok')}</button>
              </MDBModalFooter>
            </MDBModal>
          </>
      )
    }      

}

export default withTranslation()(bulkImport);