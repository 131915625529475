import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { Alert } from 'reactstrap';
import apiCallUtility from '../common/APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { withTranslation } from 'react-i18next';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

class changeConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpList: [],
      fields: {
        key: '',
        value: '',
        id: '',
      },
      preDefinedKey: 'Predefined',
      sucess: false,
      colour: 'sucess',
      errors: {},
      chargePoint: '',
      operationId: '',
      configKeyList: [],
      modalsession: false,
      configErrorMessage: '',
      configError: false,
      closePopup: true
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectedCP = this.getSelectedCP.bind(this);
    this.ChangeDropdowns = this.ChangeDropdowns.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.getPredefinedKey = this.getPredefinedKey.bind(this);
    this.getCPList = this.getCPList.bind(this);
    this.getPayLoad = this.getPayLoad.bind(this);
    this.getMessageType = this.getMessageType.bind(this);
    this.getConfigKey = this.getConfigKey.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.toggleConfigError = this.toggleConfigError.bind(this);
    this.updateConfigurekeys = this.updateConfigurekeys.bind(this);
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    
    var payload = this.getPayLoad();
    var errors = {};
    var value = payload.value;
    if (this.getCPList().length === 0) {
      errors["emptyChargePoint"] = this.props.t('select_charge_point')
    }
    if (document.getElementById("keyType").value === "Predefined" && payload.key === 'Predefined') {
      errors["emptyKey"] = this.props.t('please_select_key')
    } else if (payload.key === '') {
      errors["emptyCustomKey"] = this.props.t('select_custom_key')
    }
    if (value === '') {
      errors["emptyValue"] = this.props.t('enter_value')
    }

    if (Object.keys(errors).length === 0) {
      var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList(), this.props.t);
      if (response === this.props.t('unable_toprocess_request_try_after_some_time')) {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
          this.onShowAlert();
        })
      } else  if (response === 'Request is processed Successfully'){
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
          this.onShowAlert();
        })
      }
    }

    this.setState({ errors: errors })
  }

  getPredefinedKey(e) {
    var key = document.getElementById("preDefinedDropDown").value;
    let errors = this.state.errors;

    if (key !== "Predefined") { errors["emptyKey"] = "" }

    this.setState({ preDefinedKey: key, errors });
  }

  getCPList() {
    var Id = this.state.operationId;
    var cplist="";
    if (Id !== null && Id !== "") {
      cplist = Id.split();
    } else {
      cplist = this.state.cpList;
    }

    return cplist;
  }

  getPayLoad() {
    var payload = {};

    if (document.getElementById("keyType").value === "Predefined") {
      payload.key = this.state.preDefinedKey;
      payload.value = this.state.fields.value;
    } else {
      payload.key = this.state.fields.key;
      payload.value = this.state.fields.value;
    }

    return payload;
  }

  getMessageType() {
    return constants.CHANGE_CONFIGURATION;
  }

  toggleConfigError(message){
    if(message!=='' && message!==undefined){
      this.setState({
        configError: !this.state.configError,
        configErrorMessage: this.props.t(message)
      })
    }else{
      this.setState({
        configError: !this.state.configError
      })
    }
  }

  getSelectedCP(cpList) {
    let errors = this.state.errors;
    errors['emptyChargePoint'] = "";
    this.setState({ cpList: cpList, errors: errors });
  }

  updateConfigurekeys(response){
    this.setState({
      configKeyList: response
    })
  }

  async getConfigKey(cpList) {
    var url = constants.URLPath + "chargepoints/keylist";
    var data = { "cpList": cpList }
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();

    let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t, false);

    if(response!==undefined && response.length>0){
      this.setState({ configKeyList: response, closePopup: true })
    }else if(response.status === 404){
      this.setState({ configErrorMessage: response.message, closePopup: false }, ()=>{
        this.toggleConfigError(this.state.configErrorMessage);
      })
    }else{
      this.setState({ configKeyList: [], closePopup: true })
    }
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    let errors = this.state.errors;

    if (fields[field] !== 0) {
      errors["emptyValue"] = "";
    }

    this.setState({ fields, errors });
  }

  componentDidMount() {
    document.getElementById('preDefinedKey').style.cssText = '';
    document.getElementById('customkey').style.display = 'none';
    let propsdata = this.props.location.state;
    if (propsdata !== undefined) {
      if (propsdata.chargePoint !== undefined && propsdata.operationId !== undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        }, ()=>{
          this.getConfigKey([propsdata.operationId]);
        });
      }
    }
  }

  ChangeDropdowns() {
    var type = document.getElementById("keyType").value;

    if (type === 'Predefined') {
      document.getElementById('preDefinedKey').style.cssText = '';
      document.getElementById('customkey').style.display = 'none';
    } else {
      document.getElementById('customkey').style.cssText = '';
      document.getElementById('preDefinedKey').style.display = 'none';
    }

    this.setState({ disabled: false })
  }

  componentWillUnmount() {
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }

  render() {
   const{t}=this.props;
    let configKeyListLi = "";

    if (this.state.configKeyList !== "" && this.state.configKeyList !== undefined && this.state.configKeyList.length !== 0) {
      configKeyListLi = this.state.configKeyList.map((configKeyListData) => {
        return (
          <option value={configKeyListData.key}>{configKeyListData.value}</option>
        )
      })
    }

    return (
      <main className="content-div" >
        <p>{t('Change Configuration')}
          <div className="breadcrumb_div">
            {t('Operations')} &gt; <span className="breadcrumb_page">{t('Change Configuration')}</span>
          </div>
        </p>

        <div className="page-outerdiv">
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
          <MDBRow className="mb-4">
            <MDBCol sm="12">
              <div>
                <form
                  className="needs-validation"
                  onSubmit={this.submitHandler}
                  noValidate
                >
                  <MDBRow>
                    <ChargePointSelection {...this.props} toggleConfigError={this.toggleConfigError} updateConfigurekeys={this.updateConfigurekeys} closePopup={this.state.closePopup} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="ChangeConfiguration" ></ChargePointSelection>
                    <MDBCol md="12">
                      <span className="selection_charge_point_error w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptyChargePoint"]}</span></span>
                    </MDBCol>

                    <MDBCol md="4">
                      <select className="browser-default custom-select select_height " id="keyType" onChange={this.ChangeDropdowns} >
                        <option value="Predefined">{t('predefined')}</option>
                        <option value="custom">{t('custom')}</option>
                      </select>
                    </MDBCol>

                    <MDBCol md="4" id="preDefinedKey">
                      <select id="preDefinedDropDown" className="browser-default custom-select select_height mb_8" onChange={this.getPredefinedKey.bind(this)}><option value="Predefined">-- {t('select_key')} * --</option>
                        {configKeyListLi}
                      </select>
                      <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["emptyKey"]}</span></span>
                    </MDBCol>

                    <MDBCol md="4" id="customkey">
                      <MDBInput
                        name="chargebox"
                        type="text"
                        label={t('configuration_key')}
                        value={this.state.fields["key"]}
                        onChange={this.changeHandler.bind(this, "key")}
                        required
                      >
                        <span className="error_msg w3-animate-top">
                          <span style={{ color: "red" }}>{this.state.errors["emptyCustomKey"]}</span>
                        </span>
                      </MDBInput>
                    </MDBCol>

                    <MDBCol md="4">
                      <MDBInput
                        name="chargebox"
                        type="text"
                        label={t('value_label')+" *"}
                        value={this.state.fields["value"]}
                        onChange={this.changeHandler.bind(this, "value")}
                        required
                      >
                        <span className="error_msg w3-animate-top">
                          <span style={{ color: "red" }}>{this.state.errors["emptyValue"]}</span>
                        </span>
                      </MDBInput>
                    </MDBCol>
                  </MDBRow>
                  <button onClick={this.handleSubmit} type="button" className="mt-5 refresh-btn">{t('perform')}</button>
                </form>
              </div>
            </MDBCol>
          </MDBRow>
        </div>

        {/* popup for session expire */}
        <MDBModal isOpen={this.state.configError} toggle={this.toggleConfigError} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleConfigError}>{t('alert')}</MDBModalHeader>
          <MDBModalBody>
            {this.state.configErrorMessage}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.toggleConfigError.bind(this,'')}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
      </main>
    );
  }
}
export default withTranslation()(changeConfiguration);
