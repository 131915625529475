import React from 'react';
import ApiAccessTemplate from '../Screens/ApiAccessTemplate';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';

//API imports 
import * as baseUrl from '../views/constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders } from '../common/HttpRequestClass'
import status from '../views/dashboard/status';


class ApiAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOn: false,
            data: [],
            client_Id: "",
            description:"",
            client_Secret: "",
            referer: "",
            status: "",
            SwaggerData:{
                "basePath": "/cpms",
                "definitions": {
                    
                },
                "paths": {
                },
                "host": "localhost:8080",
                "swagger": "2.0",
                "info": {
                    "license": {},
                    "contact": {
                        "name": "Webasto Team"
                    },
                    "description": "OCPP CPMS Web Portal RESTful APIs",
                    "title": "OCPP CPMS Web Portal",
                    "version": "2.0.2"
                },
                "tags": []
                
            },
            AccessAPIMenu: [],
            itemDetails: {},    
            clikedMenu: "",
            enabledAPIAccess: false,
            disabledAPIAccess: false,
            apiAccessDisabled: false,
            apiAccessEnabled: false,
            loading: true
        }
        this.isEnableAPIAccess = this.isEnableAPIAccess.bind(this);
        this.getAPIAccessMenu = this.getAPIAccessMenu.bind(this);
        this.setContentData = this.setContentData.bind(this);
        this.getEnableAccessValue = this.getEnableAccessValue.bind(this);
        this.enableAPIAccess = this.enableAPIAccess.bind(this);
        this.disableAPIAccess = this.disableAPIAccess.bind(this);
        this.closeDisableSuccessPopup = this.closeDisableSuccessPopup.bind(this);
        this.getSwaggerApiData = this.getSwaggerApiData.bind(this);
        this.closeEnableSuccessPopup = this.closeEnableSuccessPopup.bind(this);
        this.closeWarnings = this.closeWarnings.bind(this);
    }

 
    componentDidMount() {
        localStorage.setItem('old_accordion_link', ``);
        this.getAPIAccessMenu();
        this.getSwaggerApiData("");
        this.getEnableAccessValue();
    }

    isEnableAPIAccess(e) {
        let value = e.target.checked;
        if(value === true) {
            this.setState({ enabledAPIAccess: true });
        } else {
            this.setState({ disabledAPIAccess: true });
        }
    }

    // to enable API Access
    enableAPIAccess () {
        this.setState({ 
            enabledAPIAccess: false,
            isOn: !this.state.isOn
        }, () => {
           this.getClientDetails();
        });
    }

    // to disable API Access
    disableAPIAccess () {
        this.setState({ 
            disabledAPIAccess: false,
            isOn: !this.state.isOn 
        }, () => {
                this.getClientDetails();
        });
    }

    // to close the API Disable Success Popup
    closeDisableSuccessPopup () {
        this.setState({ apiAccessDisabled: false })
    }

     // to close the API Enable Success Popup
     closeEnableSuccessPopup () {
        this.setState({ apiAccessEnabled: false })
    }

    closeWarnings () {
        this.setState({ 
            disabledAPIAccess: false,
            enabledAPIAccess: false
        })
    }

    // get client details
    async getClientDetails() {
        document.getElementById("loader_image_div").style.display = "block";
        var url = baseUrl.URLPath + servicesUrl.getClientDetails + "?status=" + this.state.isOn;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let response = await APICallUtility.cpmsAPIFetch(url, type, {}, headers, this.props.t, false);
        if (response != undefined) {            
            document.getElementById("loader_image_div").style.display = "none";
            if(response.status === true) {
                setTimeout(() => {
                    this.setState({
                        client_Id: response?.clientId,
                        client_Secret: response?.clientSecret,
                        referer: response?.referer,
                        description: response?.description,
                        apiAccessEnabled: true,
                    }, () => {
                        this.getSwaggerApiData("");
                    });
                }, 100);
            } else {
                    this.setState({
                        client_Id: response?.clientId,
                        client_Secret: response?.clientSecret,
                        referer: response?.referer,
                        description: response?.description,
                        apiAccessDisabled: true,
                    })
            }
        }
    }

    // get swagger api data 
    async getSwaggerApiData(packageName) {
        this.setState({ loading: true })
        var url = baseUrl.URLPath + servicesUrl.getSwaggerApis + "?packageName=" + packageName;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let response = await APICallUtility.cpmsAPIFetch(url, type, {}, headers, this.props.t, true);
        if (response !== undefined) {
            setTimeout(() => {
                this.setState({
                    SwaggerData: response ?? {}
                }, () => {
                    this.setState({ loading: false })
                });
            }, 100);
        }
    }

    // get api access menu 
    async getAPIAccessMenu() {
        document.getElementById("loader_image_div").style.display = "block";
        var url = baseUrl.URLPath + servicesUrl.getAccessAPI;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let response = await APICallUtility.cpmsAPIFetch(url, type, {}, headers, this.props.t, true);
        if (response !== undefined) {
            
                if(response.status === 403){

                }else{
                    setTimeout(() => {
                    this.setState({
                        AccessAPIMenu: response ?? [],
                        itemDetails: response[0] ?? ''
                    }, () => {
                        document.getElementById("loader_image_div").style.display = "none";
                    });
                }, 100);
                }
              
        }
    }

    //getEnableAccessValue

    async getEnableAccessValue() {
        document.getElementById("loader_image_div").style.display = "block";
        var url = baseUrl.URLPath + servicesUrl.getUserEnableAccessValue;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let response = await APICallUtility.cpmsAPIFetch(url, type, {}, headers, this.props.t, true);
        if (response !== undefined) {
            if (response.status === 404) {
                document.getElementById("loader_image_div").style.display = "none";
            } else {
                setTimeout(() => {
                    this.setState({
                        client_Id: response?.clientId,
                        client_Secret: response?.clientSecret,
                        referer: response?.referer,
                        description:response?.description,
                        isOn: response.status
                    }, () => {
                        document.getElementById("loader_image_div").style.display = "none";
                    });
                }, 10);
            }
        }
    }


    setContentData(item) {
        let old_link = localStorage.getItem('old_accordion_link');
        // if (old_link !== "") {
        //     $(old_link).click();
        // }
        // $(`#operations-tag-${item.name}`).click();
        this.setState({
            itemDetails: item,
            clikedMenu: item.item
        });
        localStorage.setItem('old_accordion_link', `#operations-tag-${item.name}`);
        this.getSwaggerApiData(item.item);
    }



    render() {
        return (
            <>
                <ApiAccessTemplate
                    isOn={this.state.isOn}
                    isEnableAPIAccess={this.isEnableAPIAccess}
                    clientId={this.state.client_Id}
                    clientSecret={this.state.client_Secret}
                    referer={this.state.referer}
                    SwaggerData={this.state.SwaggerData}
                    AccessAPIMenu={this.state.AccessAPIMenu}
                    setContentData={this.setContentData}
                    itemDetails={this.state.itemDetails}
                    clikedMenu={this.state.clikedMenu}
                    enabledAPIAccess={this.state.enabledAPIAccess}
                    disabledAPIAccess={this.state.disabledAPIAccess}
                    disableAPIAccess={this.disableAPIAccess}
                    enableAPIAccess={this.enableAPIAccess}
                    apiAccessDisabled={this.state.apiAccessDisabled}
                    closeDisableSuccessPopup={this.closeDisableSuccessPopup}
                    apiAccessEnabled={this.state.apiAccessEnabled}
                    closeEnableSuccessPopup={this.closeEnableSuccessPopup}
                    closeWarnings={this.closeWarnings}
                    description = {this.state.description}
                    loading = {this.state.loading}
                />

                {/* Loader */}
                <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>
            </>
        )
    }
}

export default withTranslation()(ApiAccess);