import { MDBRow, MDBCol } from 'mdbreact';
import React from 'react';
import { Form } from 'react-bootstrap';
import $ from 'jquery';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';

//API imports 
import * as baseUrl from '../constants'
import servicesUrl from '../../common/servicesUrl'
import APICallUtility from '../../common/APICallUtility'
import ApiMethodTypes from '../../common/ApiMethodTypes'
import {getSecurityHeaders} from '../../common/HttpRequestClass'

class NewSiteWallboxes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItems: [],
            availableWallBoxes: [],
            siteWallboxes: [],
            deletedWallboxes: [],
            wallbox: '',
            cpactivePage: 1,
            cppageSize: "30",
            cppageNo: 1,
            cpnoOfRecords: "",
            listOfFirstCreatedtimes: [],
            prevPageFirstCreTime: "",
            curPageFirstCreTime: "",
            curPageLastCreTime: "",
            fromDateApi: "",
            toDateApi: "",
            btnAction: this.props.btnAction,
            noWallboxExists_w: '',
            modalsession: false,
            multiple: false,
            errors: {},
            intial_data: [],
            options: [],
            saveSelectedCpid: ""
        };
        this.removeSelectedItem = this.removeSelectedItem.bind(this);
        this.isItemSelected = this.isItemSelected.bind(this);
        this.updateCheckboxSelection = this.updateCheckboxSelection.bind(this);
        this.getSiteWallBoxes = this.getSiteWallBoxes.bind(this);
        this.getAvailableWallboxes = this.getAvailableWallboxes.bind(this);
        this.cphandlePageChange = this.cphandlePageChange.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.chargepointIDChange = this.chargepointIDChange.bind(this);
        this.ClearChargepoint = this.ClearChargepoint.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
    }

    componentDidMount() {
        this.getAvailableWallboxes(true);
        if (this.state.btnAction === "Edit" && this.props.selectedItems.length !== 0) {
            this.setState({ selectedItems: this.props.selectedItems });
        }
        if (this.props.id === "") {
            this.setState({ selectedItems: this.props.selectedItems });
        }

    }

    async getAvailableWallboxes(countValue, cpid) {
        document.getElementById("loader_image_div").style.display = "block";
        var siteId = "";
        if (cpid) {
            this.setState({
                cppageSize: this.state.cppageSize,
                fromDateApi: "",
                toDateApi: "",
            })
        }
        if (this.props.id) {
            siteId = this.props.id
        }
        let url = `${baseUrl.URLPath}${servicesUrl.updateSitemanagementWallbox}?pageNo=` + this.state.cpactivePage + "&siteid=" + siteId + "&pageSize=" + this.state.cppageSize + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi + "&chargePointId=" + this.state.saveSelectedCpid;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t,true);
        if (response.count.count === 0) {
            let errors = {};
            errors["chargePointNotExists"] = this.props.t('charge_point_not_exists');
            setTimeout(() => {
                this.setState({
                    errors: errors,
                    saveSelectedCpid: ""
                }, () => {
                    document.getElementById("loader_image_div").style.display = "none";
                });
            }, 100);
            const selectedSite = this.props.id;
            if (selectedSite && this.state.btnAction === "Edit" && this.props.selectedItems.length === 0) {
                this.getSiteWallBoxes(selectedSite, true);
            }

        } else {
            var totalcount = "";
            if (countValue === false) {
                totalcount = this.state.cpnoOfRecords
            } else {
                totalcount = response.count.count
            }
            setTimeout(() => {
                this.setState({
                    availableWallBoxes: response.data,
                    cpnoOfRecords: totalcount,
                    prevPageFirstCreTime: this.state.curPageFirstCreTime,
                    curPageFirstCreTime: moment(response.data[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                    curPageLastCreTime: moment(response.data[response.data.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                }, () => {
                    document.getElementById("loader_image_div").style.display = "none";
                });
            }, 100);
            const selectedSite = this.props.id;
            if (selectedSite && this.state.btnAction === "Edit" && this.props.selectedItems.length === 0) {
                this.getSiteWallBoxes(selectedSite, true);
            }
        }
    }

    async getSiteWallBoxes(siteId){
      let url = `${baseUrl.URLPath}sitemanagement/wallbox/siteid?siteid=${siteId}`
      let type = ApiMethodTypes.GET;
      let headers = getSecurityHeaders();
      let payload = {}
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if(response){
        this.setState({ ...this.state, selectedItems: response,  siteWallboxes: response, btnAction: ""});
      }

    }

    updateCheckboxSelection(event, item) {
        const allItems = [...this.state.selectedItems];
        const deletedWallboxes = [...this.state.deletedWallboxes];
        const selectedItemIndex = allItems.findIndex(el => el === item);
        if (event.target.checked) {
            if (selectedItemIndex < 0) {
                allItems.push(item);
            }
        } else {
            const deletedWallboxIndex = this.state.siteWallboxes.findIndex(el => el === item);
            const isAlreadyDeleted = deletedWallboxes.findIndex(el => el === item) > -1;
            if (deletedWallboxIndex > -1 && !isAlreadyDeleted) {
                deletedWallboxes.push(item);
            }
            allItems.splice(selectedItemIndex, 1);
        }
        this.setState({ ...this.state, selectedItems: allItems, deletedWallboxes });
        var data = {
            selectedItems: allItems,
            deletedWallboxes: deletedWallboxes
        }
        this.props.updatedata(data);
    }

    removeSelectedItem(item, index) {
        const allItems = [...this.state.selectedItems];
        allItems.splice(index, 1);
        const deletedWallboxIndex = this.state.siteWallboxes.findIndex(el => el === item);
        const deletedWallboxes = [...this.state.deletedWallboxes];
        if (deletedWallboxIndex > -1) {
            deletedWallboxes.push(item);
        }
        this.setState({ ...this.state, selectedItems: allItems, deletedWallboxes });
        var data = {
            selectedItems: allItems,
            deletedWallboxes: deletedWallboxes
        }
        this.props.updatedata(data);
    }

    isItemSelected(item) {
        const retVal = this.state.selectedItems.find(el => el === item) ? true : false;
        return retVal;
    }

    //show entries change
    showEntriesChange() {
        var entries = document.getElementById("cpshowEntries").value;
        this.setState({
            cpactivePage: 1,
            cppageSize: entries,
            fromDateApi: "",
            toDateApi: "",

        }, () => {
            this.getAvailableWallboxes(true);
            })
    }

    cphandlePageChange(pageNumber) {
        if (pageNumber < this.state.cpactivePage) {
            //prev
            this.setState({
                cpactivePage: pageNumber,
                fromDateApi: this.state.curPageFirstCreTime,
                toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
            }, () => {
                this.getAvailableWallboxes(false);
            });
        } else {
            //next
            let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
            this.setState({
                cpactivePage: pageNumber,
                fromDateApi: '',
                toDateApi: this.state.curPageLastCreTime,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
            }, () => {
                if (pageNumber > _datesArrayLength + 1) {
                    this.createdDatesArray(this.state.curPageFirstCreTime);
                }
                this.getAvailableWallboxes(false);
            });
        }
    }

    createdDatesArray(dateTime) {
        let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
        this.setState({
            listOfFirstCreatedtimes: listOfFirstCreatedtimes
        });
    }
    
    // typehead 
    chargepointIDChange(cpid) {
        this.setState({ saveSelectedCpid: cpid }, () => {
            if (cpid.length === 0) {
                this.getAvailableWallboxes(true);
            } else {
                let errors = {};
                errors["chargePointNotExists"] = "";
                errors["selectChargepoint"] = "";
                this.setState({
                    errors: errors
                });
                this.getAvailableWallboxes(true, cpid);
            }
        })
    }


    ClearChargepoint(ref, event) {
        if (this.state.saveSelectedCpid !== "") {
            let errors = this.state.errors;

            if (errors["chargePointNotExists"] !== "") {
                errors["chargePointNotExists"] = "";
                errors["selectChargepoint"] = "";
                ref.current.clear();

                this.setState({
                    errors: errors,
                    saveSelectedCpid: ""
                }, () => {
                    this.getAvailableWallboxes(true)
                });
            } else {
                errors["chargePointNotExists"] = "";
                errors["selectChargepoint"] = "";
                ref.current.clear();

                this.setState({
                    errors: errors,
                    cpactivePage: 1,
                    pageNumber: 1,
                    fromDateApi: '',
                    toDateApi: '',
                    saveSelectedCpid: ""
                }, () => {
                    this.getAvailableWallboxes(true)
                });
            }
        } else {
            let errors = this.state.errors;
            errors["chargePointNotExists"] = "";
            errors["selectChargepoint"] = "";

            this.setState({
                errors: errors
            })
            ref.current.clear()
        }
    }

    FindCpName(ref, event) {
        event.preventDefault();
        let saveSelectedCpid = ref.current.inputNode.value;
        var errors = {};
        if (saveSelectedCpid === null || saveSelectedCpid === "") {
            errors["selectChargepoint"] = this.props.t('select_the_charge_point')
            this.setState({
                errors: errors,
                saveSelectedCpid: saveSelectedCpid
            });
        } else {
            errors["selectChargepoint"] = "";
            this.setState({
                fromDateApi: "",
                toDateApi: "",
                errors,
                saveSelectedCpid: saveSelectedCpid
            }, ()=>{
                this.getAvailableWallboxes(true, this.state.saveSelectedCpid);
            })
        }

        var serial_no = event.target.value;
        if (serial_no.length > 1) {
            $("#selectcharge").hide();
        } else {
            $("#selectcharge").show();
        }

    }

    handleKeyDown(ref, e) {
        var serial_no = e.target.value;
        $("#nochargeexists").hide();
        if (serial_no.length > 1) {
            $("#selectcharge").hide();
        } else {
            $("#selectcharge").show();
        }
        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);

        var sno = serial_no.slice(0, serial_no.length - 1)
        this.getSearchInventoryList(sno)
        if (key === "Backspace" || key === "Delete") {
            if (sno === "") {
                this.setState({ saveSelectedCpid: sno }, ()=>{
                    this.getAvailableWallboxes(true)
                })
            }
        }
        if (e.keyCode === 13) {
            $("#selectcharge").hide();
            this.FindCpName(ref, e);
            $("#rbt_id").hide();
        }
        if ((e.keyCode === 86 && ctrl)) {
            $("#selectcharge").hide();
            this.getSearchInventoryList(serial_no)
        }
        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9 -]");
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }
    async getSearchInventoryList(cpid){
        var siteId = "";
        if(this.props.id){
            siteId = this.props.id 
        }
        let url = `${baseUrl.URLPath}${servicesUrl.getSearchInventoryListWallbox}?chargePointId=${cpid}&siteid=${siteId}`
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
         if(response){
            this.setState({
                options: response,
                intial_data: response
            });
         }
    }

    render() {
        const{t}=this.props
        const ref = React.createRef();
        return (
            <div className="maxHeightGM">
                <MDBRow className="mt-3">
                    <MDBCol md="5">
                        <div className="input-group">
                            <i className="fas fa-search fa-sm input-searchIcon"></i>
                            <Typeahead
                                labelKey="name"
                                className="w_80"
                                id="rbt_id"
                                minLength={3}
                                multiple={this.state.multiple}
                                options={this.state.options}
                                placeholder={t('enter_charge_point')}
                                onChange={this.chargepointIDChange.bind(this)}
                                onKeyDown={this.handleKeyDown.bind(this, ref)}
                                onBlur={this.getChargePoint}
                                ref={ref}
                            />
                            <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}>
                                <span aria-hidden="true">x</span>
                            </button>
                            <div className="input-group-btn">
                                <button className="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)} >{t('find')}</button>
                            </div>
                            <span className="error_msg w3-animate-top mt-2">
                                <span style={{ color: "red" }}>{this.state.errors["selectChargepoint"]}</span>
                            </span>
                            <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>
                                <span style={{ color: "red" }}>{this.state.errors["chargePointNotExists"]}</span>
                            </span>
                        </div>

                    </MDBCol>
                    <MDBCol md="7">
                        <div className="pull-right">
                            <label className="pull-left sub-text">{t('show_entries')}</label>
                            <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv p-rel cpselectdiv" >
                                <Form.Control as="select" className="showentry_sel custom_selectBox" id="cpshowEntries" onChange={this.showEntriesChange}>
                                    <option>10</option>
                                    <option>20</option>
                                    <option selected="selected">30</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </MDBCol>
                </MDBRow>
                <div className="cpLeft" style={{ display: "inline-block" }}>
                    <div className='cpbody cpbody1 mb-3 '>
                        <MDBRow>

                            <MDBCol md="12" className="chargepoint_listdiv" >
                                <ul>
                                    {
                                        this.state.availableWallBoxes.map((el, index) =>
                                            <li key={index} className='cp-li-element'>
                                                <Form.Check
                                                    custom
                                                    type="checkbox"
                                                    id={`${el.chargePointId}_${index}`}
                                                    label={el.chargePointId}
                                                    checked={this.isItemSelected(el.chargePointId)}
                                                    onChange={(event) => this.updateCheckboxSelection(event, el.chargePointId)}
                                                />
                                            </li>
                                        )
                                    }
                                </ul>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </div>
                <div className="cpRightGM" id="cpRightGM" >
                    <div className="">
                       {t('selected_Charge_Point')}
                    </div>
                    <div id='idStatus' className='p-1 selectedCp'>
                        {
                            this.state.selectedItems?.map((el, index) =>
                                <span key={index} className="cpChargePointSpan">
                                    {el} <i class="fa fa-times" onClick={() => this.removeSelectedItem(el, index)}></i>
                                </span>
                            )
                        }
                    </div>
                </div>
                <div className="cpFooter">
                    <div className='row'>
                        <div className="col-md-4 mt-1 mb-1 text-right">
                            <Pagination
                                activePage={this.state.cpactivePage}
                                itemsCountPerPage={this.state.cppageSize}
                                totalItemsCount={this.state.cpnoOfRecords}
                                onChange={this.cphandlePageChange.bind(this)}
                                prevPageText={t('prev')}
                                nextPageText={t('next')}
                                itemClassPrev="prevBtn"
                                pageRangeDisplayed="1"
                                activeClass="activeLi active"
                                itemClassNext="nextBtn"
                                disableInitialCallback={true}
                                disabledClass="disabled disabledtextColor"
                                hideFirstLastPages={true}
                            />
                        </div>
                        <div className='col-md-8 text-right'>
                            <div className="text-right">
                                {
                                    (this.props.isNewSite) ?
                                        <button type="button" data-test="button" class="btn_primary mr-3 mb-2 Op-modal-close" onClick={this.props.saveNewSite}>{t('save')} <i class="fas fa-save ml-2"></i></button> :
                                        <button type="button" data-test="button" class="btn_primary mr-3 mb-2 Op-modal-close" onClick={() => { this.props.updateExistingSite(this.props.id, this.state.selectedItems, this.state.deletedWallboxes) }}>{t('update')}</button>
                                }
                                <button className="mt-1 btn_cancel" type="button" data-test="button" onClick={this.props.cancel}>
                                    {t('cancel')} <i class="fas fa-times ml-2"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Loader */}
                <div className="operations_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>
            </div >
        );
    }
}

export default withTranslation()(NewSiteWallboxes);