import React from 'react';
import { Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { MDBBtn, MDBRow, MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';


class NewSiteSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            modalsession: false,
            reservation: false,
            qrcode: false,
            selectedOption: "",
            name: "React"
        };
    }

    isHaveAlphabetic(string) {
        if (/[a-zA-Z]/.test(string) === true) {
            return true;
        } else {
            return false;
        }
    }

    isZipLengthAllowed(string){
        if (string.length <= 20 && string.length >=5) {
            return true;
        } else {
            return false;
        }
    }

    isLengthAllowed(string) {
        if (string.length <= 128) {
            return true;
        } else {
            return false;
        }
    }
    generatePercentageOptions =()=> {
        const percentages = [];
        for (let i = 0; i <= 100; i++) {
          percentages.push(`${i}%`);
        }
        return percentages;
      }

    render() {
        const{t}=this.props
        const percentageOptions = this.generatePercentageOptions();

        return (
            <div>
                <form>
                    <div className="row mb-3 mt-3">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-12 md-form">
                                    <input name="name" type="text" id="name" className="form-control" onChange={this.props.updateSiteSettings} value={this.props.name} />
                                    <label htmlFor="name" className={`${(this.props.name !== "" && this.props.name !== null) ? "active" : ""} ml-20`}>{t('name')}*</label>
                                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["name"]}</span></span>
                                </div>
                                <div className="col-md-12 md-form">
                                    <input name="city" type="text" id="city" className="form-control" onChange={this.props.updateSiteSettings} value={this.props.city} />
                                    <label htmlFor="city" className={`${(this.props.city !== "" && this.props.city !== null) ? "active" : ""} ml-20`}>{t('city')}*</label>
                                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["city"]}</span></span>
                                </div>
                                <div className="col-md-12 md-form">
                                    <input name="zipcode" type="text" id="zipcode" className="form-control" onChange={this.props.updateSiteSettings} value={this.props.zipcode} />
                                    <label htmlFor="zipcode" className={`${(this.props.zipcode !== "" && this.props.zipcode !== null) ? "active" : ""} ml-20`}>{t('zipcode')}*</label>
                                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["zipcode"]}</span></span>
                                </div>
                                <div className="col-md-12 md-form">
                                    <input name="address" type="text" id="address" className="form-control" onChange={this.props.updateSiteSettings} value={this.props.address} />
                                    <label htmlFor="address" className={`${(this.props.address !== "" && this.props.address !== null) ? "active" : ""} ml-20`}>{t('address')}*</label>
                                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["address"]}</span></span>
                                </div>
                                <div className="col-md-12 md-form">
                                <select className="browser-default custom-select select_height" id="country" onChange={this.props.countryChange.bind(this)}  value={this.props.country}>
                                    <option value="Country">{t('country')} *</option>
                                    <option value="AF">{t('Afghanistan')}</option>
                                    <option value="AX">{t('Aland_islands')}</option>
                                    <option value="AL">{t('Albania')}</option>
                                    <option value="DZ">{t('Algeria')}</option>
                                    <option value="AS">{t('American_samoa')}</option>
                                    <option value="AD">{t('Andorra')}</option>
                                    <option value="AO">{t('Angola')}</option>
                                    <option value="AI">{t('Anguilla')}</option>
                                    <option value="AQ">{t('Antarctica')}</option>
                                    <option value="AG">{t('Antigua_and_barbuda')}</option>
                                    <option value="AR">{t('Argentina')}</option>
                                    <option value="AM">{t('Armenia')}</option>
                                    <option value="AW">{t('Aruba')}</option>
                                    <option value="AU">{t('Australia')}</option>
                                    <option value="AT">{t('Austria')}</option>
                                    <option value="AZ">{t('Azerbaijan')}</option>
                                    <option value="BS">{t('Bahamas')}</option>
                                    <option value="BH">{t('Bahrain')}</option>
                                    <option value="BD">{t('Bangladesh')}</option>
                                    <option value="BB">{t('Barbados')}</option>
                                    <option value="BY">{t('Belarus')}</option>
                                    <option value="BE">{t('Belgium')}</option>
                                    <option value="BZ">{t('Belize')}</option>
                                    <option value="BJ">{t('Benin')}</option>
                                    <option value="BM">{t('Bermuda')}</option>
                                    <option value="BT">{t('Bhutan')}</option>
                                    <option value="BO">{t('Bolivia')}</option>
                                    <option value="BQ">{t('Bonaire_saint_eustatius_and_saba')}</option>
                                    <option value="BA">{t('Bosnia_and_Herzegovina')}</option>
                                    <option value="BW">{t('Botswana')}</option>
                                    <option value="BR">{t('Brazil')}</option>
                                    <option value="IO">{t('British_indian_ocean_territory')}</option>
                                    <option value="VG">{t('British_virgin_islands')}</option>
                                    <option value="BN">{t('Brunei')}</option>
                                    <option value="BG">{t('Bulgaria')}</option>
                                    <option value="BF">{t('Burkina_faso')}</option>
                                    <option value="BI">{t('Burundi')}</option>
                                    <option value="CV">{t('Cabo_verde')}</option>
                                    <option value="KH">{t('Cambodia')}</option>
                                    <option value="CM">{t('Cameroon')}</option>
                                    <option value="CA">{t('Canada')}</option>
                                    <option value="KY">{t('Cayman_islands')}</option>
                                    <option value="CF">{t('Central_african_republic')}</option>
                                    <option value="TD">{t('Chad')}</option>
                                    <option value="CL">{t('Chile')}</option>
                                    <option value="CN">{t('China')}</option>
                                    <option value="CX">{t('Christmas_island')}</option>
                                    <option value="CC">{t('Cocos_islands')}</option>
                                    <option value="CO">{t('Colombia')}</option>
                                    <option value="KM">{t('Comoros')}</option>
                                    <option value="CK">{t('Cook_islands')}</option>
                                    <option value="CR">{t('Costa_rica')}</option>
                                    <option value="HR">{t('Croatia')}</option>
                                    <option value="CU">{t('Cuba')}</option>
                                    <option value="CW">{t('Curacao')}</option>
                                    <option value="CY">{t('Cyprus')}</option>
                                    <option value="CZ">{t('Czechia')}</option>
                                    <option value="CD">{t('Democratic_republic_of_the_congo')}</option>
                                    <option value="DK">{t('Denmark')}</option>
                                    <option value="DJ">{t('Djibouti')}</option>
                                    <option value="DM">{t('Dominica')}</option>
                                    <option value="DO">{t('Dominican_republic')}</option>
                                    <option value="EC">{t('Ecuador')}</option>
                                    <option value="EG">{t('Egypt')}</option>
                                    <option value="SV">{t('El_salvador')}</option>
                                    <option value="GQ">{t('Equatorial_guinea')}</option>
                                    <option value="ER">{t('Eritrea')}</option>
                                    <option value="EE">{t('Estonia')}</option>
                                    <option value="SZ">{t('Eswatini')}</option>
                                    <option value="ET">{t('Ethiopia')}</option>
                                    <option value="FK">{t('Falkland_islands')}</option>
                                    <option value="FO">{t('Faroe_islands')}</option>
                                    <option value="FJ">{t('Fiji')}</option>
                                    <option value="FI">{t('Finland')}</option>
                                    <option value="FR">{t('France')}</option>
                                    <option value="GF">{t('French_guiana')}</option>
                                    <option value="PF">{t('French_polynesia')}</option>
                                    <option value="TF">{t('French_southern_territories')}</option>
                                    <option value="GA">{t('Gabon')}</option>
                                    <option value="GM">{t('Gambia')}</option>
                                    <option value="GE">{t('Georgia')}</option>
                                    <option value="DE">{t('Germany')}</option>
                                    <option value="GH">{t('Ghana')}</option>
                                    <option value="GI">{t('Gibraltar')}</option>
                                    <option value="GR">{t('Greece')}</option>
                                    <option value="GL">{t('Greenland')}</option>
                                    <option value="GD">{t('Grenada')}</option>
                                    <option value="GP">{t('Guadeloupe')}</option>
                                    <option value="GU">{t('Guam')}</option>
                                    <option value="GT">{t('Guatemala')}</option>
                                    <option value="GG">{t('Guernsey')}</option>
                                    <option value="GN">{t('Guinea')}</option>
                                    <option value="GW">{t('Guinea-Bissau')}</option>
                                    <option value="GY">{t('Guyana')}</option>
                                    <option value="HT">{t('Haiti')}</option>
                                    <option value="HN">{t('Honduras')}</option>
                                    <option value="HK">{t('Hong_kong')}</option>
                                    <option value="HU">{t('Hungary')}</option>
                                    <option value="IS">{t('Iceland')}</option>
                                    <option value="IN">{t('India')}</option>
                                    <option value="ID">{t('Indonesia')}</option>
                                    <option value="IR">{t('Iran')}</option>
                                    <option value="IQ">{t('Iraq')}</option>
                                    <option value="IE">{t('Ireland')}</option>
                                    <option value="IM">{t('Isle_of_man')}</option>
                                    <option value="IL">{t('Israel')}</option>
                                    <option value="IT">{t('Italy')}</option>
                                    <option value="CI">{t('Ivory_coast')}</option>
                                    <option value="JM">{t('Jamaica')}</option>
                                    <option value="JP">{t('Japan')}</option>
                                    <option value="JE">{t('Jersey')}</option>
                                    <option value="JO">{t('Jordan')}</option>
                                    <option value="KZ">{t('Kazakhstan')}</option>
                                    <option value="KE">{t('Kenya')}</option>
                                    <option value="KI">{t('Kiribati')}</option>
                                    <option value="KW">{t('Kuwait')}</option>
                                    <option value="KG">{t('Kyrgyzstan')}</option>
                                    <option value="LA">{t('Laos')}</option>
                                    <option value="LV">{t('Latvia')}</option>
                                    <option value="LB">{t('Lebanon')}</option>
                                    <option value="LS">{t('Lesotho')}</option>
                                    <option value="LR">{t('Liberia')}</option>
                                    <option value="LY">{t('Libya')}</option>
                                    <option value="LI">{t('Liechtenstein')}</option>
                                    <option value="LT">{t('Lithuania')}</option>
                                    <option value="LU">{t('Luxembourg')}</option>
                                    <option value="MO">{t('Macao')}</option>
                                    <option value="MG">{t('Madagascar')}</option>
                                    <option value="MW">{t('Malawi')}</option>
                                    <option value="MY">{t('Malaysia')}</option>
                                    <option value="MV">{t('Maldives')}</option>
                                    <option value="ML">{t('Mali')}</option>
                                    <option value="MT">{t('Malta')}</option>
                                    <option value="MH">{t('Marshall_islands')}</option>
                                    <option value="MQ">{t('Martinique')}</option>
                                    <option value="MR">{t('Mauritania')}</option>
                                    <option value="MU">{t('Mauritius')}</option>
                                    <option value="YT">{t('Mayotte')}</option>
                                    <option value="MX">{t('Mexico')}</option>
                                    <option value="FM">{t('Micronesia')}</option>
                                    <option value="MD">{t('Moldova')}</option>
                                    <option value="MC">{t('Monaco')}</option>
                                    <option value="MN">{t('Mongolia')}</option>
                                    <option value="ME">{t('Montenegro')}</option>
                                    <option value="MS">{t('Montserrat')}</option>
                                    <option value="MA">{t('Morocco')}</option>
                                    <option value="MZ">{t('Mozambique')}</option>
                                    <option value="MM">{t('Myanmar')}</option>
                                    <option value="NA">{t('Namibia')}</option>
                                    <option value="NR">{t('Nauru')}</option>
                                    <option value="NP">{t('Nepal')}</option>
                                    <option value="NL">{t('Netherlands')}</option>
                                    <option value="NC">{t('New_caledonia')}</option>
                                    <option value="NZ">{t('New_zealand')}</option>
                                    <option value="NI">{t('Nicaragua')}</option>
                                    <option value="NE">{t('Niger')}</option>
                                    <option value="NG">{t('Nigeria')}</option>
                                    <option value="NU">{t('Niue')}</option>
                                    <option value="NF">{t('Norfolk_island')}</option>
                                    <option value="KP">{t('North_korea')}</option>
                                    <option value="MK">{t('North_macedonia')}</option>
                                    <option value="MP">{t('Northern_mariana_islands')}</option>
                                    <option value="NO">{t('Norway')}</option>
                                    <option value="OM">{t('Oman')}</option>
                                    <option value="PK">{t('Pakistan')}</option>
                                    <option value="PW">{t('Palau')}</option>
                                    <option value="PS">{t('Palestinian_territory')}</option>
                                    <option value="PA">{t('Panama')}</option>
                                    <option value="PG">{t('Papua_new_guinea')}</option>
                                    <option value="PY">{t('Paraguay')}</option>
                                    <option value="PE">{t('Peru')}</option>
                                    <option value="PH">{t('Philippines')}</option>
                                    <option value="PN">{t('Pitcairn')}</option>
                                    <option value="PL">{t('Poland')}</option>
                                    <option value="PT">{t('Portugal')}</option>
                                    <option value="PR">{t('Puerto_Rico')}</option>
                                    <option value="QA">{t('Qatar')}</option>
                                    <option value="CG">{t('Republic_of_the_congo')}</option>
                                    <option value="RE">{t('Reunion')}</option>
                                    <option value="RO">{t('Romania')}</option>
                                    <option value="RU">{t('Russia')}</option>
                                    <option value="RW">{t('Rwanda')}</option>
                                    <option value="BL">{t('Saint_barthelemy')}</option>
                                    <option value="SH">{t('Saint_helena')}</option>
                                    <option value="KN">{t('Saint_kitts_and_nevis')}</option>
                                    <option value="LC">{t('Saint_lucia')}</option>
                                    <option value="MF">{t('Saint_martin')}</option>
                                    <option value="PM">{t('Saint_pierre_and_miquelon')}</option>
                                    <option value="VC">{t('Saint_vincent_and_the_grenadines')}</option>
                                    <option value="WS">{t('Samoa')}</option>
                                    <option value="SM">{t('San_marino')}</option>
                                    <option value="ST">{t('Sao_tome_and_principe')}</option>
                                    <option value="SA">{t('Saudi_arabia')}</option>
                                    <option value="SN">{t('Senegal')}</option>
                                    <option value="RS">{t('Serbia')}</option>
                                    <option value="SC">{t('Seychelles')}</option>
                                    <option value="SL">{t('Sierra_leone')}</option>
                                    <option value="SG">{t('Singapore')}</option>
                                    <option value="SX">{t('Sint_maarten')}</option>
                                    <option value="SK">{t('Slovakia')}</option>
                                    <option value="SI">{t('Slovenia')}</option>
                                    <option value="SB">{t('Solomon_islands')}</option>
                                    <option value="SO">{t('Somalia')}</option>
                                    <option value="ZA">{t('South_africa')}</option>
                                    <option value="GS">{t('South_georgia_and_the_south_sandwich_islands')}</option>
                                    <option value="KR">{t('South_korea')}</option>
                                    <option value="SS">{t('South_sudan')}</option>
                                    <option value="ES">{t('Spain')}</option>
                                    <option value="LK">{t('Sri_lanka')}</option>
                                    <option value="SD">{t('Sudan')}</option>
                                    <option value="SR">{t('Suriname')}</option>
                                    <option value="SJ">{t('Svalbard_and_jan_mayen')}</option>
                                    <option value="SE">{t('Sweden')}</option>
                                    <option value="CH">{t('Switzerland')}</option>
                                    <option value="SY">{t('Syria')}</option>
                                    <option value="TW">{t('Taiwan')}</option>
                                    <option value="TJ">{t('Tajikistan')}</option>
                                    <option value="TZ">{t('Tanzania_united_republic_of')}</option>
                                    <option value="TH">{t('Thailand')}</option>
                                    <option value="TL">{t('Timor_leste')}</option>
                                    <option value="TG">{t('Togo')}</option>
                                    <option value="TK">{t('Tokelau')}</option>
                                    <option value="TO">{t('Tonga')}</option>
                                    <option value="TT">{t('Trinidad_and_tobago')}</option>
                                    <option value="TN">{t('Tunisia')}</option>
                                    <option value="TR">{t('Turkey')}</option>
                                    <option value="TM">{t('Turkmenistan')}</option>
                                    <option value="TC">{t('Turks_and_caicos_islands')}</option>
                                    <option value="TV">{t('Tuvalu')}</option>
                                    <option value="VI">{t('Us_virgin_islands')}</option>
                                    <option value="UG">{t('Uganda')}</option>
                                    <option value="UA">{t('Ukraine')}</option>
                                    <option value="AE">{t('United_arab_emirates')}</option>
                                    <option value="GB">{t('United_kingdom')}</option>
                                    <option value="US">{t('United_states')}</option>
                                    <option value="UM">{t('United_states_minor_outlying_islands')}</option>
                                    <option value="UY">{t('Uruguay')}</option>
                                    <option value="UZ">{t('Uzbekistan')}</option>
                                    <option value="VU">{t('Vanuatu')}</option>
                                    <option value="VE">{t('Venezuela')}</option>
                                    <option value="VN">{t('Vietnam')}</option>
                                    <option value="WF">{t('Wallis_and_futuna')}</option>
                                    <option value="EH">{t('Western_sahara')}</option>
                                    <option value="YE">{t('Yemen')}</option>
                                    <option value="ZM">{t('Zambia')}</option>
                                    <option value="ZW">{t('Zimbabwe')}</option>
                                </select>
                                <span className="error_msg w3-animate-top mt-1">
                                    <span style={{ color: "red" }}>{this.state.errors["countryempty"]}</span>
                                </span>
                                </div>
                                </div>
                        </div>
                                { (this.props.isNewSite) ? "" : this.props.ownerQrStatus ?
                                   <>
                                    <div className="col-md-6">
                                        <div className="row group-feature grp-editpop-bg">
                                            <div className="col-md-12">
                                            <h6><b>{t("group_features")}</b></h6>
                                             <p style={{ fontSize: '13px' , marginTop:'18px' }}>{t("group_features_text")}</p>

                                                <form>
                                                    <div className="radio">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value={this.props.reservationStatus}
                                                                checked={this.props.reservationStatus}
                                                                onChange={this.props.changeReservationStatus}
                                                            />
                                                            <span className="text">{t("reservation_and_second_seating")}</span>
                                                        </label>
                                                    </div>
                                                    <div className="radio">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value={this.props.qrCodeAccessStatus}
                                                                checked={this.props.qrCodeAccessStatus}
                                                                onChange={this.props.changeQRCodeStatus}
                                                            />
                                                            
                                                            <span className="text">{t("share_chargestation_via_qrcode")}</span>
                                                        </label>
                                                    </div>

                                                    <div className="checkbox">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                value={this.props.energylimitationStatus}
                                                                checked={this.props.energylimitationStatus}
                                                                onChange={this.props.changeenergylimitationStatus.bind(this)}
                                                            />
                                                            
                                                            <label className="text pl-2 ml-1 align-middle">{t("enable_energylimitation_inside_the_app")}</label>
                                                        </label>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                        {/* {!this.props.isBankAccountSettingsAvailable ?
                                         <div className='grp-editpop-bg mt-3 row' style={{backgroundColor:"#00000059", opacity : "0.8"}}>
                                            <span className='col-md-6 admin-payopt-p'>{t('payment_option')}</span>
                                            <div className='col-md-12'>
                                                <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "130px"}}>
                                                <button type='button' className='grp-editpp-btn' >
                                                    {t('contact_us')}
                                                </button>
                                                </div>
                                                
                                            </div>
                                        </div> : 
                                        <div className='grp-editpop-bg mt-3 row pb-3'>
                                            <span className='col-md-6 admin-payopt-p' style={{color:"#00529B"}}>{t('payment_option')}</span>
                                            <div className='ml-3 mt-3 col-md-12'>
                                                <div>
                                                        <Form.Check
                                                        onChange={this.props.handleOnchangePaymentMode.bind(this, "split")}
                                                        custom
                                                        type="radio"
                                                        id = "split"
                                                        label={t('split_payment')}
                                                        name = "paymentmode"
                                                        checked={this.props.paymentMode === "split"}
                                                        
                                                    />
                                                </div>
                                                <div className='mt-3 d-flex mb-3' >
                                                    
                                                        <select
                                                            id="percentageSelect1"
                                                            value={this.props.selectedPercentage1}
                                                            onChange={this.props.handleChangeInPercentage1}
                                                            className='percentage-select share-percent mr-3'
                                                        >
                                                            <option>{t('owner-share')}</option>
                                                            {percentageOptions.map((percentage, index) => (

                                                            <option key={index} value={percentage}>
                                                                {percentage}
                                                            </option>
                                                            ))}
                                                        </select>
                                                        <select
                                                            id="percentageSelect2"
                                                            value={this.state.selectedPercentage2}
                                                            onChange={this.handleChangeInPercentage2}
                                                            className='percentage-select share-percent'
                                                        >
                                                             <option>{t('platform-share')}</option>
                                                            {percentageOptions.map((percentage, index) => (
                                                            <option key={index} value={percentage}>
                                                                {percentage}
                                                            </option>
                                                            ))}
                                                        </select>
                                                </div>
                                                <div>
                                                    <span className="error_msg w3-animate-top percentages_error" style={{ color: "red" }}></span>
                                                </div>
                                                <div className='mt-2 d-flex align-items-center'>
                                                    <Form.Check
                                                    onChange={this.props.handleOnchangePaymentMode.bind(this, "direct")}
                                                    custom
                                                    type="radio"
                                                    id = "direct"
                                                    label={t('direct_payment')}
                                                    name = "paymentmode"
                                                    checked={this.props.paymentMode === "direct"}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    } */}
                                    {this.props.isPaymentModeActive && 
                                    <>
                                     <div className='row mt-3'>
                                    <div className='resuser-payment-div '>
                                            <div  className={`grp-editpop-bg pb-3 ${!this.props.isBankAccountSettingsAvailable ? 'blurred' : ''}`}>
                                                    <span className='col-md-12 admin-payopt-p' style={{color:"#00529B"}}>{t('payment_option')}</span>
                                                    <div className='ml-1 mt-3 col-md-12' style={{display : 'flex', flexDirection : 'column'}}>
                                                        <div className='payment-radio-types'>
                                                                <Form.Check
                                                                onChange={this.props.handleOnchangePaymentMode.bind(this, "split")}
                                                                custom
                                                                type="radio"
                                                                id = "split"
                                                                label={t('split_payment')}
                                                                name = "paymentmode"
                                                                checked={this.props.paymentMode === "split"}
                                                                
                                                            />
                                                        </div>
                                                        {this.props.paymentMode === "split" &&
                                                        <div className='mt-2 d-flex mb-3' >
                                                        <div className='d-flex flex-column mr-3'>
                                                        <select
                                                            id="percentageSelect1"
                                                            value={this.props.selectedPercentage1}
                                                            onChange={this.props.handleChangeInPercentage1}
                                                            className='percentage-select share-percent mr-3 w-100'
                                                        >
                                                            <option>{t('owner-share')}</option>
                                                            {percentageOptions.map((percentage, index) => (

                                                            <option key={index} value={percentage}>
                                                                {percentage}
                                                            </option>
                                                            ))}
                                                        </select>
                                                        <span style={{fontSize : '12px'}} className='mt-1'>{t('owner-share')}</span>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                        <select
                                                            id="percentageSelect2"
                                                            value={this.props.selectedPercentage2}
                                                            onChange={this.props.handleChangeInPercentage2}
                                                            className='percentage-select share-percent w-100'
                                                        >
                                                            <option>{t('platform-share')}</option>
                                                            {percentageOptions.map((percentage, index) => (
                                                            <option key={index} value={percentage}>
                                                                {percentage}
                                                            </option>
                                                            ))}
                                                        </select>
                                                        <span style={{fontSize : '12px'}} className='mt-1'>{t('platform-share')}</span>
                                                        </div>
                                                        
                                                </div>
                                                        }
                                                        
                                                        <div>
                                                            <span className="error_msg w3-animate-top percentages_error" style={{ color: "red" }}></span>
                                                        </div>
                                                        <div className='mt-2 d-flex align-items-center payment-radio-types'>
                                                            <Form.Check
                                                            onChange={this.props.handleOnchangePaymentMode.bind(this, "direct")}
                                                            custom
                                                            type="radio"
                                                            id = "direct"
                                                            label={t('direct_payment')}
                                                            name = "paymentmode"
                                                            checked={this.props.paymentMode === "direct"}
                                                        />
                                                        </div>
                                                    </div>
                                            </div>
                                            {
                                                !this.props.isBankAccountSettingsAvailable &&(
                                                    <div className='contact-us-overlay pb-3'>
                                                    <span className='col-md-6 admin-payopt-p ' style={{color:"#00529B", opacity:'1', fontFamily : 'Inter-Bold'}}>{t('payment_option')}</span>
                                                    <div className='col-md-12' style={{opacity : "1"}}>
                                                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "130px"}}>
                                                        <button type='button' className='grp-editpp-btn' onClick={this.props.onClickContactUS}>
                                                                {t('contact_us')}
                                                        </button>
                                                        </div>
                                                
                                                    </div>
                                                    </div>
                                                )
                                            }
                                    </div>
                                    </div>
                                    </>}
                                   
                                    
                                       
                                    </div>
                                    
                                    </> :""
                                    }
                           
                    </div>
                </form>
                <div className="text-right">
                    {
                        (this.props.isNewSite) ?
                            <button type="button" data-test="button" class="btn_primary mr-3 mb-2 Op-modal-close" onClick={this.props.saveNewSite}>
                                {t('save')} <i class="fas fa-save ml-2"></i>
                            </button> :
                            <button type="button" data-test="button" class="btn_primary mr-3 mb-2 Op-modal-close" onClick={()=>{this.props.updateExistingSite(this.props.id)}}>
                                {t('update')}
                            </button>
                    }
                    <button className="mt-5 btn_cancel" type="button" data-test="button" onClick={this.props.cancel}>
                       {t('cancel')} <i class="fas fa-times ml-2"></i>
                    </button>
                </div>

                
            </div>
        );
    }
}

export default withTranslation()(NewSiteSettings);