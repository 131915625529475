import React from 'react';
import Chart from "react-apexcharts";
import * as baseUrl from '../constants';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import servicesUrl from '../../common/servicesUrl';
import { withTranslation } from 'react-i18next';

class heartbeatStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userValue: [],
      user_date: [],
      calendarValue: new Date(),
      startDate: null,
      value: [null, null],
      series: [{ data: [] }],
      options: {
        chart: {
          width: '100%',
          type: 'bar',
          height: 350,
          toolbar: {
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              reset: false,
              pan: false
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
          fill: '#00529e'
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
          colors: ['rgb(114, 201, 62)']
        },
        tooltip: {
          enabled: false
        }
      },
    }

    this.onChange = this.onChange.bind(this);
    this.getHeatbeatValues = this.getHeatbeatValues.bind(this);
  }

  //api to get charging session chart
  componentDidMount() {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let start_date = moment(today).format("YYYY-MM-DD").toUpperCase();

    this.setState({
      value: start_date,
      startDate: start_date
    }, () => {
      this.getHeatbeatValues(date);
    })
  }

  getHeatbeatValues(startDate) {
    var user_value = [];
    let chargePointId = localStorage.getItem("chargerId");

    var url = baseUrl.URLPath + servicesUrl.getCustomerserviceCPHeartbeats + "?chargePointId=" + chargePointId + "&date=" + startDate;
    var Date = moment(startDate).format("DD-MM-YYYY").toUpperCase();

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status === 401) {
          window.location.reload();
        } else if (resp.status === 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response !== undefined) {
          let series = this.state.series;

          for (var i = 0; i < response.length; i++) {
            user_value.push(response[i]["value"])
          }

          series[0].data = user_value;

          var data_options = {
            xaxis: {
              categories: [Date]
            },
          };

          this.setState({ options: data_options, series })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  // date picker onchange function
  onChange(value) {
    if (value !== null && value !== "") {
      var startDate = moment(value).format("YYYY-MM-DD").toUpperCase();

      this.setState({
        calendarValue: value,
        startDate: startDate
      }, () => {
        this.getHeatbeatValues(startDate);
      });
    } else {
      this.setState({
        calendarValue: value
      })
    }
  }

  render() {
    return (
      <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success dashboard-height">
        <div className="content-box">
          <div className="dashboard-subtitle">{this.props.t('heartbeat_frequency')}</div>
          <div className="mt-2 mb-5">
            <DatePicker
              onChange={this.onChange}
              value={this.state.calendarValue}
              format="dd-M-yyyy"
              dayPlaceholder=""
              yearPlaceholder=""
              monthPlaceholder=""
              nativeInputAriaLabel="Date"
            />
          </div>
          <div className="mt-3 bottom-pos pb-3 pr-3 mr-15 w-100">
            <Chart
              options={this.state.options}
              series={this.state.series}
              data={this.state.user_date}
              type="bar"
              width="100%"
              height="232"
            />
          </div>
        </div>
      </div>
    )
  };
}

export default withTranslation()(heartbeatStatus);