import React from 'react';
import { MDBCol, MDBModal } from "mdbreact";
import { Form } from 'react-bootstrap'
import Pagination from "react-js-pagination";
import * as baseUrl from './../views/constants';
import $ from "jquery";
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';

//API Imports
import APICallUtility from '../common/APICallUtility';
import servicesUrl from './servicesUrl';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

class chargePointSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            multiple:false,
            errors:{},
            intial_data:[],
            options:[],
            saveSelectedCpid:"",
            selectFindStatus:false
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.chargepointIDChange = this.chargepointIDChange.bind(this);
        this.ClearChargepoint = this.ClearChargepoint.bind(this);
    }

    async getSearchInventoryList(cpid) {
        var url="";
        if(this.props.pageName === 'Vendor'){
            url = baseUrl.URLPath + servicesUrl.getBrokerVendorMapperPartialSearch + "?vendorId=" + this.props.vid + "&cpid=" + cpid;
        }else{
            url = baseUrl.URLPath + "chargepoints/search/partial?cpid=" + cpid;
        }

        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false);

        if (response !== undefined) {
            this.setState({
                options: response,
                intial_data: response
            });
        }
    }

      // search filter 
      handleKeyDown(ref, e) {
        var serial_no = e.target.value;
        $("#nochargeexists").hide();
        if (serial_no.length > 1) {
            $("#selectcharge").hide();
        } else {
            $("#selectcharge").show();
        }
        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
        var sno = serial_no.slice(0, serial_no.length - 1)

        if(e.target.value.length > 1){
            this.getSearchInventoryList(sno)
        }
        if (key === "Backspace" || key === "Delete") {
            if (sno === "") {
                this.setState({ saveSelectedCpid: sno }, ()=>{
                    this.props.getData(this.props.vid, true, false);
                })
            }
        }
        if (e.keyCode === 13) {
            $("#selectcharge").hide();
            this.FindCpName(ref, e);
            $("#rbt_id").hide();
        }
        if ((e.keyCode === 86 && ctrl)) {
            $("#selectcharge").hide();
            this.getSearchInventoryList(serial_no)
        }
        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9 -]");
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }


    async FindCpName(ref, event) {
        let saveSelectedCpid = ref.current.inputNode.value;
        var errors = {};

        if (saveSelectedCpid === null || saveSelectedCpid === "") {
            errors["selectChargepoint"] = this.props.t('enter_charge_point_id');
            this.setState({
                errors: errors,
                pageSize: 10,
                activePage: 1,
                saveSelectedCpid: saveSelectedCpid
            }, ()=>{
                this.props.getData(this.props.vid, true, false );
            });
        } else {
            errors["selectChargepoint"] = "";
            this.props.getData(this.props.vid, true, false, ref.current.inputNode.value);
            setTimeout(()=>{
                var data = localStorage.getItem("vennoWallboxExists");
                if(data==="0" && data!==undefined && data!==""){
                    $("#selectcharge").show();
                    errors["selectChargepoint"] = this.props.t('charge_point_not_exists');
                    this.setState({
                        errors: errors,
                        saveSelectedCpid: saveSelectedCpid
                    });
                }
            }, 100);
        }

        if(this.props.noWallboxExists===""){
            var serial_no = ref.current.inputNode.value;
            if (serial_no.length > 1){
                $("#selectcharge").hide();
                errors["selectChargepoint"] = "";
                this.setState({
                    errors: errors,
                    saveSelectedCpid: saveSelectedCpid
                });
            } else {
                $("#selectcharge").show();
            }
       }

    }

    chargepointIDChange(cpid) {
        this.setState({ saveSelectedCpid: cpid }, () => {
        if (cpid.length === 0) {
            this.props.getData(this.props.vid, true, false);
        } else {
            let errors = {};
            errors["chargePointNotExists"] = "";
            errors["selectChargepoint"] = "";
            this.setState({
                errors: errors
            });
            this.props.getData(this.props.vid, true, false,cpid);
        }
     })
    }

    ClearChargepoint(ref, event) {
        if (this.state.saveSelectedCpid !== "") {
            let errors = {};
            errors["chargePointNotExists"] = "";
            errors["selectChargepoint"] = "";

            this.props.getData(this.props.vid, true, false);
            this.setState({
                errors: errors,
                saveSelectedCpid: ""
            });
            ref.current.clear();
        } else {
            let errors = this.state.errors;
            errors["chargePointNotExists"] = "";
            errors["selectChargepoint"] = "";
            this.setState({
                errors: errors
            })
            ref.current.clear();
        }

    }

    render() {
        const{t}=this.props
        const ref = React.createRef();
        var displayDiv = "none";
        if(this.props.cpList.length>0){
            displayDiv = "table-cell"
        }
        $("#cpRightSide").height($("#cpLeftSide").height());

        const getChargePoints = this.props.data.map((chargePoint, index) => {

            if (this.props.isCheckBox) {                
                for (var i = 0; i < this.props.cpList.length; i++) {
                    if (chargePoint.matchedChargepointId === this.props.cpList[i]) {
                        return (
                            <li className='cp-li-element1'><Form.Check onChange={this.props.handleChange.bind(this, chargePoint.matchedChargepointId)}
                                custom
                                type="checkbox"
                                id={`custom-${chargePoint.matchedChargepointId}`}
                                label=""
                                checked
                            />
                                {chargePoint.matchedChargepointId}
                            </li>
                        )
                    }
                }
                return (
                    <li className='cp-li-element1'>
                        <Form.Check onChange={this.props.handleChange.bind(this, chargePoint.matchedChargepointId)}
                            custom
                            type="checkbox"
                            id={`custom-${chargePoint.matchedChargepointId}`}
                            label=""
                        //  checked
                        />
                        {chargePoint.matchedChargepointId}
                    </li>
                )
            } else {
                return <></>
            }
        })
       
        return (
            <>
                <MDBModal isOpen={this.props.isOpen} size="lg" data-toggle="modal" backdrop="static" className="cpModel" data-backdrop="static" data-keyboard="false" style={{ width: '1024px !important' }}>
                    <div className="cpLeft" id="cpLeftSide">
                        <div className='cpHeader'>
                            <label className='cptitle'>{t('select_chargePoint')}
                                {this.props.pageName === 'Vendor' ? <span className="cpvendor">{t('vendor_nam')}: {this.props.selectedVendorName} </span> : null}
                            </label>
                            <div className='row'>
                            <div className=" pl-2 cpSearchDiv model-input typehead-txt" >
                                <div className="input-group ml-3">
                                    <i className="fas fa-search fa-sm input-searchIcon"></i>
                                    <Typeahead
                                        labelKey="name"
                                        className="w_70"
                                        multiple={this.state.multiple}
                                        options={this.state.options}
                                        id="rbt_id"
                                        minLength={3}
                                        placeholder={t('enter_charge_point')}
                                        onKeyDown={this.handleKeyDown.bind(this, ref)}
                                        onChange={this.chargepointIDChange.bind(this)}
                                        ref={ref}
                                    />
                                    <button type="button" className="close closeRicon model-Lm" id="clearInput" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)} style={{width: '35px'}} >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div className="input-group-btn">
                                        <button className="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)} >{t('find')}</button>
                                    </div>
                                    <span className="pull-left error_msg w3-animate-top mt-0" id="nochargeexists" style={{ color: "red" }}>{this.state.errors["chargePointNotExists"]}</span>
                                    <span className="pull-left error_msg w3-animate-top mt-0" id="selectcharge" style={{ color: "red" }}>{this.state.errors["selectChargepoint"]}</span>
                                </div>
                            </div>
                            <div className='show-entry'>
                            <div className="pull-right">
                                <label className="pull-left sub-text">{t('show_entries')}</label>
                                <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv p-rel" >
                                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="cpshowEntries" onChange={this.props.showEntriesChange}>
                                        <option>10</option>
                                        <option>20</option>
                                        <option selected="selected">30</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            </div>
                            </div>
                        </div>
                        <div className='cpbody cpbodym'>
                            <MDBCol md="12" className="chargepoint_listdiv" >
                                <ul>
                                    {getChargePoints}
                                    {/* {this.props.cpcheckbox} */}
                                </ul>
                            </MDBCol>

                        </div>
                        <div className='modelFooter cpFooterb cpFooter'>
                            <div className='row'>
                                <div className="col-md-4 mt-1 mb-1 text-right">
                                    <Pagination
                                        activePage={this.props.cpactivePage}
                                        itemsCountPerPage={this.props.itemsCountPerPage}
                                        totalItemsCount={this.props.noOfRecords}
                                        onChange={this.props.handlePageChange.bind(this)}
                                        prevPageText={t('prev')}
                                        nextPageText={t('next')}
                                        itemClassPrev="prevBtn"
                                        pageRangeDisplayed="1"
                                        activeClass="activeLi active"
                                        itemClassNext="nextBtn"
                                        disableInitialCallback={true}
                                        disabledClass = "disabled disabledtextColor"
                                        hideFirstLastPages={true}
                                    />
                                </div>
                                <div className='col-md-8 text-right'>
                                    <button className="btn_cancel mr-2 ml-2" onClick={()=>{
                                        let errors = {};
                                        errors["chargePointNotExists"] = "";
                                        errors["selectChargepoint"]="";
                                        this.setState({
                                            errors: errors
                                        },()=>{
                                            this.props.cancelFunction()
                                        })
                                        }} type="button" data-test="button"> {t('cancel')} <i class="fas fa-times ml-2"></i></button>
                                    <button className="btn_primary mr-4 ml-2" onClick={(e)=>{
                                        let errors = {};
                                        errors["chargePointNotExists"] = "";
                                        errors["selectChargepoint"]="";
                                        this.setState({
                                            errors: errors
                                        },()=>{
                                            this.props.doneFunction(e)
                                        })
                                        
                                    }} disabled={this.props.btnDisable} type="button" data-test="button">{this.props.btnText}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                    <div className="cpRight" id = "cpRightSide" style={{display:displayDiv}}>
                        <div className="cprightTitle">
                            {t('selected_charge_point')}
                        </div>
                        <div id='idStatus' className='p-3 cpmodelRightSide'>
                        {
                            this.props.cpList?.map((cpId, index)=>{
                                return(
                                    <span class='cpChargePointSpan' id={`chargepoint_span ${this.props.cpList[index]}`}>
                                        {this.props.cpList[index]} <i class='fa fa-times' id={this.props.cpList[index]} onClick={this.props.removeCpId.bind(this,this.props.cpList[index])}></i>
                                    </span>
                                );
                            })
                        }
                    </div>
                        
                    </div>
                    
                </MDBModal>
            </>
        )
    }
}

export default withTranslation()(chargePointSelection);
