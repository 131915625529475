import React from "react";
import {
  MDBCol,
  MDBRow,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBBtn
} from "mdbreact";
import Pagination from "react-js-pagination";
import { Form, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import $, { data } from "jquery";
import moment from "moment";
import { dateformatinUTC } from "./_methods";
import Tansactionstable from "./tansactionsTable";
import { withTranslation } from "react-i18next";
import "./cpDashboard.css";
import CustomModal from "../common/CustomModal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import CustomFilterDropdown from "../common/CustomFilterDropdown";
import CustomFilterNSDropdown from "../common/CustomFilterNSDropdown";

import Select, { components } from "react-select";
import ViewchargepointModal from "./viewChargePointModal";



//API imports
import * as baseUrl from "./constants";
import servicesUrl from "../common/servicesUrl";
import APICallUtility from "../common/APICallUtility";
import ApiMethodTypes from "../common/ApiMethodTypes";
import { getSecurityHeaders } from "../common/HttpRequestClass";
import { hasPermission } from "./auth";
// import ViewChargePoint from "./viewChargePoint";

var tableHeaders = [
  "CP ID",
  "Model",
  "Customer",
  "Backend",
  "Status",
  "Firmware",
  "Heartbeat",
  "",
];

// var location = {
//   hash: "",
//   key: "zie6xz",
// pathname: "/viewChargePoint",
// search: "",
// state : {
//   chargePointId: "496688",
// cpmodel: null,
// id: "6556dffc8e90c21abca95ff6",
// onlineStatus: false,
// }
// }

var dummyChargePointData = {
  date: "2022-11-30T00:00:00.000+0000",
  tenantId: "webasto",
  createdBy: null,
  createdDate: "2022-11-30T11:56:33.897+0000",
  updatedBy: null,
  updatedDate: "2023-11-17T09:52:09.413+0000",
  id: "638744f1f23bff4d80d82102",
  chargePointId: "QAcp4693",
  description: null,
  active: 1,
  chargePointSerialNumber: "QAcp4693",
  chargeBoxSerialNumber: "QAcp4693",
  fwVersion: "v1.1.2",
  iccid: "1234",
  imsi: "1234",
  meterType: "volt",
  meterSerialNumber: "190244492",
  chargePointVendor: "WEB",
  chargePointModel: "UNITE",
  heartbeatTime: "2023-11-10T03:54:59.000+0000",
  address: {
    street: "####",
    houseNumber: "####",
    zipCode: "####",
    city: "####",
    country: "####",
    locationLatitude: null,
    locationLongitude: null,
    latitude: "####",
    longitude: "####"
  },
  heartbeat: null,
  partNumber: "123456",
  backendConnection: false,
  lostBackendConnectionTime: 83384,
  connectionLostTime: "2023-11-17T06:30:40.000+0000",
  mfgDate: null,
  latestFW: null,
  fwReleaseDate: null,
  lastBusinessError: null,
  lastChargingDate: null,
  editAccess: false,
  adminUser: null,
  isArchive: null,
  siteStatus: null,
  fordOnboardDate: null,
  localListVersion: null,
  assignedEmail: null,
  installationSite: null,
  logbookData: null,
  siteName: null,
  isSiteAdminAccess: true,
  isChargerOwner: true,
  vendorName: null
}

class ServiceManagementPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOptions: {
        chargingStation: [],
        backend: [],
        firmware: [],
        status: [],
      },
      selectedValues: {
        chargingStation: [],
        backend: [],
        firmware: [],
        status: [],
      },
      searchedValues: [],
      modal: false,
      moremodal: false,
      searchQuery: "",
      isactiveEnergy: "",
      noOfRecords: "",
      data: new Map(),
      pageSize: 10,
      activePage: 1,
      pageNo: 1,
      calendertoDisplay: "none",
      calenderfromDisplay: "none",
      disabled: true,
      rfidTag: "",
      sessionId: "",
      statusId: "",
      transactionId: "",
      modalsession: false,
      startDate: new Date(),
      page_title: "Charging Session",
      scheduletoDate: "",
      customerNumber: "",
      refNumber: "",
      chargePointId: "",
      calenderFrom: "none",
      calenderTo: "none",
      fields: {
        saveSelectedValue: "",
        dropDownIds: "",
        chargePointId: "",
      },
      dataArray: [],
      remoteStart: false,
      selectedRfid: "",
      rfidTagsList: [],
      note: "",
      showLoder: false,
      energyLevel: "",
      selectedcpId: "",
      selectedTransactionId: "",
      selectedStatus: "",
      selectedChargedEnergy: "",
      getOperationList: [],
      getFieldsList: [],
      mileage: "",
      inventoryNumber: "",
      mileageNdInventory: false,
      transId: "",
      modalMileageNdInventory: false,
      exportBtn: false,
      selectedItems: [],
      availableWallBoxes: [],
      siteWallboxes: [],
      deletedWallboxes: [],
      errors: {},
      wallbox: "",
      successTbContent: false,
      tableCustomization: false,
      tableCustMsg: "Saved",
      exportError: false,
      rfidAcpid: "",
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: [],
      fromDate: "",
      fromDateApi: "",
      toDate: "",
      toDateApi: "",
      ASetChargingLimit: false,
      user: {
        permissions: [],
      },
      settingPopup: false,
      typeOfExportCSV: false,
      typeOfExportXLS: false,
      typeOfExport: "0",
      reportModal: false,
      createReportModal: false,
      isAlternativeEmailActive: false,
      deleteReport: false,
      reportEditModal: false,
      reportFields: {
        reportName: "",
        alternativeEmail: "",
      },
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      isOpen5: false,
      isOpen6: false,
      isOpenState: false,
      exportFormat: "",
      reportCycle: "",
      groupList: [],
      filterChargerList: [],
      filterrfidTagsList: [],
      filterUserList: [],
      filterUserListName: [],
      searchValue: "",
      searchValue:"",
      searchFirmwareValue:"",
      start: moment().subtract(1, "days"),
      end: moment(),
      fileType: "",
      selectedOption: null,
      settingsMenu: false,
      reportId: "",
      currentOpenDropdown: null,
      isCreateReportSuccess: false,
      reportModal1: false,
      activeApplyBtn: false,
      activeReportApplyBtn: false,
      isOverViewPopupOpen: false,
      isOpenProfileModal: false,
      isSuccessPopupOpen: false,
      isSharePopupOpen: false,
      isActiveShareEmail : true,
      isActiveDownloadPdf:false,
      isEmailShareChecked : false,
      isEmailSuccessPopupOpen:false,
      isPdfDownloaded : false,
      isPdfSuccessPopupOpen : false,
      customerMail : '',
      individualCpId : '',
      profileData: {
        serviceAccessCode : "",
        companyName: "",
        city: "",
        zipCode: "",
        address: "",
        country: "",
        telephone: "",
        email: "",
        websiteUrl: "",
        
      },
      backendDropdownList : ["Online", "Offline"],
      statusDropdownList : [],
      chargerList:[],
      filterFirmwareList:[],
      smCPModel:"",
      smCPId:"",
      isDataAvailable : true,
      prevCompName: "",
    };
    this.removeSelectedValue = this.removeSelectedValue.bind(this);
    this.toggle = this.toggle.bind(this);
    this.moreToggle = this.moreToggle.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.exportSMpartner = this.exportSMpartner.bind(this);
    this.showExportPopup = this.showExportPopup.bind(this);
    this.closeExportPopup = this.closeExportPopup.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.idTagChange = this.idTagChange.bind(this);
    this.successTableContent = this.successTableContent.bind(this);
    this.toggleExportError = this.toggleExportError.bind(this);
    this.createdDatesArray = this.createdDatesArray.bind(this);
    this.updateCheckboxSelection = this.updateCheckboxSelection.bind(this);
    this.toggleASetChargingLimit = this.toggleASetChargingLimit.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchFirmwareChange = this.handleSearchFirmwareChange.bind(this);
    this.getReportList = this.getReportList.bind(this);
    this.checkIdGetName = this.checkIdGetName.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    //this.openOverViewPopup = this.openOverViewPopup.bind(this);
    this.toggleOverViewPopup = this.toggleOverViewPopup.bind(this);
    this.handleEditSMProfile = this.handleEditSMProfile.bind(this);
    this.toggleProfileModal = this.toggleProfileModal.bind(this);
    this.handleFormInputChange = this.handleFormInputChange.bind(this);
    this.handleCompanyNameError = this.handleCompanyNameError.bind(this);
    this.handleTelephoneError = this.handleTelephoneError.bind(this);
    this.handleEmailError = this.handleEmailError.bind(this);
    this.handleSaveSMProfile = this.handleSaveSMProfile.bind(this);
    this.handleMandatoryField = this.handleMandatoryField.bind(this);
    this.toggleSuccessPopup = this.toggleSuccessPopup.bind(this);
    this.handleShareBtn = this.handleShareBtn.bind(this);
    this.toggleSharePopup = this.toggleSharePopup.bind(this);
    this.handleShareEmail = this.handleShareEmail.bind(this);
    this.handleDownloadPdf = this.handleDownloadPdf.bind(this);
    this.handleEmailShareChecked = this.handleEmailShareChecked.bind(this);
    this.handleEmailShareSubmitBtn = this.handleEmailShareSubmitBtn.bind(this);
    this.toggleEmailSuccessPopup = this.toggleEmailSuccessPopup.bind(this);
    this.handleCustomerMailChange = this.handleCustomerMailChange.bind(this);
    this.handleDownloadPdfBtn = this.handleDownloadPdfBtn.bind(this);
    this.closeOverviewPopup = this.closeOverviewPopup.bind(this);
    this.validateURL = this.validateURL.bind(this);
    this.getProfileData = this.getProfileData.bind(this);
    this.getFilterHistory = this.getFilterHistory.bind(this);
    this.handleSearchBackendChange = this.handleSearchBackendChange.bind(this);
    this.getConnectorStatusList = this.getConnectorStatusList.bind(this);
    this.handleTogglePdfSuccessPopup = this.handleTogglePdfSuccessPopup.bind(this);
    this.handleEmailErrorEmail = this.handleEmailErrorEmail.bind(this)

  }

  handleTogglePdfSuccessPopup(){
    this.setState({isPdfSuccessPopupOpen : false})
  }
  closeOverviewPopup(){
    this.setState({isOverViewPopupOpen : false})
  }

  handleDownloadPdfBtn(){
    this.setState({isPdfDownloaded : true, isSharePopupOpen:true, isPdfSuccessPopupOpen:true})
    this.exportSMpartner();
  }
  handleCustomerMailChange(e){
    const { name, value } = e.target;
    this.setState({customerMail : value})

  }

  toggleEmailSuccessPopup(){
    this.setState({isEmailSuccessPopupOpen:false, isSharePopupOpen:false})
  }

  async handleEmailShareSubmitBtn(){
    
    const { companyName, city, zipCode, address, country, telephone, email, websiteUrl } = this.state.profileData;
    let isCompanyFilled = (companyName!=="" && companyName!== null);
    let isCityFilled = (city!=="" && city!== null);
    let isZipCodeFilled = (zipCode!=="" && zipCode!== null);
    let isAddressFilled = (address!=="" && address!== null);
    let isCountryFilled = (country!=="" && country!== null);
    let isTelephoneFilled = (telephone!=="" && telephone!== null);
    let isEmailFilled = (email!=="" && email!== null);
    
    // if(isCompanyFilled && isCityFilled && isZipCodeFilled && isAddressFilled && isCountryFilled && isTelephoneFilled && isEmailFilled){
    // }

    let customerEmail = document.getElementById("customerMailId").value;
    let isChecked = this.state.isEmailShareChecked
    let errors = {}
    if(customerEmail !== "" && (customerEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/))) {
        if(isChecked){
            let url = baseUrl.URLPath + servicesUrl.shareSMAccessCode + "?email="+customerEmail;
            let type = ApiMethodTypes.GET;
            let headers = getSecurityHeaders();
            let payload = {};
            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
            if (response.message ==="service_partner_access_code_share_success") {
              this.setState({isEmailSuccessPopupOpen:true})
            }else if(response.message === "insufficient_service_partner_detials"){
               this.toggleASetChargingLimit();
            }   
        }else{
          errors["isemailEmpty"] = this.props.t("Please_enter_a_valid_email_address");
          this.setState({errors:errors})
        }
    }else if(customerEmail !== "" && (!customerEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) ){
        errors["isemailEmpty"] = this.props.t("Please_enter_a_valid_email_address");
        this.setState({errors:errors})
    }else if(customerEmail === "" || customerEmail === null){
        errors["isemailEmpty"] = this.props.t("Please_enter_a_valid_email_address");
        this.setState({errors:errors})
    }  
  }

    handleEmailShareChecked(){
      this.setState((prevState)=>({isEmailShareChecked : !prevState.isEmailShareChecked}))
    }

  handleShareEmail(){
    let errors = {}
    this.setState({isActiveShareEmail : true, isActiveDownloadPdf:false, errors:errors})
  }

  handleDownloadPdf(){
    let errors = {}
    this.setState({isActiveDownloadPdf:true, isActiveShareEmail:false, errors:errors})
  }

  toggleSharePopup() {
    let errors = {}
    this.setState({ isSharePopupOpen: false,  isEmailShareChecked: false , errors:errors });
  }

  handleShareBtn() {
    this.setState({ isSharePopupOpen: true, errors:{}, customerMail:'', isEmailShareChecked: false, isActiveShareEmail: true, isActiveDownloadPdf: false, isPdfDownloaded: false, isPdfSuccessPopupOpen: false});
  }

  toggleSuccessPopup() {
    this.setState({ isSuccessPopupOpen: false, isOpenProfileModal: false });
  }

  handleMandatoryField(e) {
    let errors = {};
    const { name, value } = e.target;
    if (value === "") {
      errors[`is${name}Empty`] = this.props.t(`please_enter_${name}`);
    } else {
      errors[`is${name}Empty`] = "";
    }
    this.setState({ errors: errors });
  }
  
  validateURL(e) {
    let errors = {}
    let websiteUrl = e.target.value;
    if(websiteUrl!=="" && websiteUrl!== null){
      //var urlregex = new RegExp("^((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
      //var pattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/;
      var pattern = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/ ;
      var var_name = pattern.test(websiteUrl);
      if(!var_name){
        errors["iswebsiteUrlEmpty"] =this.props.t('enter_a_valid_url');
        this.setState({errors : errors})
      }
      }
    }
    
  async handleSaveSMProfile(e){
    let errors = {}    
    e.preventDefault();
    const { profileData } = this.state;
    const { companyName, city, zipCode, address, country, telephone, email, websiteUrl } = profileData;
    const data = {
      companyName: companyName,
      city: city,
      address: address,
      zipCode: zipCode,
      country: country,
      telephone: telephone,
      email: email,
    };

    for (let key in data) {
      if (!data[key]) {
        errors[`is${key}Empty`] = this.props.t(`please_enter_${key}`);
      }
    }
    
    if (Object.keys(errors).length === 0) {
      let telephone = this.state.profileData.telephone;
      let email = this.state.profileData.email;
      let websiteUrl = this.state.profileData.websiteUrl;

      if (telephone === '' || telephone === null) {
        // if (!telephone.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
  
        //   errors["istelephoneEmpty"] = this.props.t('enter_valid_telephone_no');
        // }
        errors["istelephoneEmpty"] = this.props.t("enter_valid_telephone_no");
        
      }
      if (email !== '' && email !== null) {
        if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
          errors["isemailEmpty"] = this.props.t('enter_valid_email_id');
        }
      }
      if(websiteUrl!=="" && websiteUrl!== null){
          // var urlregex = new RegExp("^((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
          // var var_name = urlregex.test(websiteUrl);
          
          // if(!var_name){
          //   errors["iswebsiteUrlEmpty"] =this.props.t('enter_a_valid_url');
          // }
          //var pattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/;
          var pattern = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/ ;
          var var_name = pattern.test(websiteUrl);
          if(!var_name){
            errors["iswebsiteUrlEmpty"] =this.props.t('enter_a_valid_url');
            this.setState({errors : errors})
          }
       }

      if(errors.hasOwnProperty("istelephoneEmpty") || errors.hasOwnProperty("isemailEmpty") || errors.hasOwnProperty("iswebsiteUrlEmpty")){
        this.setState({errors: errors})
      }else{
        let type = ApiMethodTypes.PUT;
        var url = baseUrl.URLPath + servicesUrl.updateSMProfile + "/user" ; 
        let headers = getSecurityHeaders();
        let payload = {
          "accessCode": this.state.profileData.serviceAccessCode,
          "companyName": this.state.profileData.companyName,
          "city": this.state.profileData.city,
          "zipCode": this.state.profileData.zipCode,
          "address": this.state.profileData.address,
          "country": this.state.profileData.country,
          "telephone": this.state.profileData.telephone,
          "email": this.state.profileData.email,
          "websiteURL": this.state.profileData.websiteUrl,
        };
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if(response.message==="service_partner_update_success"){
          this.setState({ 
            isSuccessPopupOpen : true});
          }
      }
      }else{
        this.setState({ errors: errors });
    }
     

  }
  handleEmailError(e) {
    let errors = {};
    let email = e.target.value;
    if (email !== "") {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
        errors["isemailEmpty"] = this.props.t("enter_valid_email_id");
      }
    } else {
      errors["isemailEmpty"] = this.props.t("please_enter_email");
    }
    this.setState({ errors: errors });
  }
  handleEmailErrorEmail(e) {
    let errors = {};
    let email = e.target.value;
    if (email !== "") {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
        errors["isemailEmpty"] = this.props.t("Please_enter_a_valid_email_address");
      }
    } else {
      errors["isemailEmpty"] = this.props.t("Please_enter_a_valid_email_address");
    }
    this.setState({ errors: errors });
  }
  
  handleTelephoneError(e) {
    let errors = {};
    let telephone = e.target.value;
    // if (telephone !== "") {
    //   if(!telephone.match(/^(?:\+[\d\s-]{1,18}|[\d\s-]{1,18})$/)){
    //     errors["istelephoneEmpty"] = this.props.t("enter_valid_telephone_no");
    //   }
    // } else {
    //   errors["istelephoneEmpty"] = this.props.t("please_enter_telephone");
    // }
    if(telephone === '' || telephone === null){
      errors["istelephoneEmpty"] = this.props.t("please_enter_telephone");
      
    }
    this.setState({ errors: errors });
    
  }

  handleCompanyNameError(e) {
    let errors = {};
    if (e.target.value === "") {
      errors["iscompanyNameEmpty"] = this.props.t("please_enter_company_name");
    } else {
      errors["iscompanyNameEmpty"] = "";
    }
    this.setState({ errors: errors });
  }
  handleFormInputChange(e) {
    const { name, value } = e.target;
    let errors = {}
      this.setState((prevState) => ({
        profileData: { ...prevState.profileData, [name]: value, errors: errors},
      }));
    
    
  }

  toggleProfileModal() {
  this.state.profileData.companyName = this.state.prevCompName
  this.setState({
      isOpenProfileModal: false, 
    });
  }
  handleEditSMProfile() {

    this.getProfileData()
    let errors = {};
    this.setState({
      errors: errors,
      isOpenProfileModal: true,
    });
  }

  openOverViewPopup(id,model) {
    this.setState({ isOverViewPopupOpen: true , smCPId : id, smCPModel:model});
  }
  toggleOverViewPopup() {
    this.setState({ isOverViewPopupOpen: false });
  }

  removeAllSelected = () => {
    const updatedObject = { ...this.state.selectedValues };
    updatedObject.chargingStation = [];
    updatedObject.backend = [];
    updatedObject.firmware = [];
    updatedObject.status = [];

    const newObject = { ...this.state.selectedOptions };
    newObject.chargingStation = [];
    newObject.backend = [];
    newObject.firmware = [];
    newObject.status = [];
    setTimeout(() => {
      this.setState(
        {
          selectedValues: updatedObject,
          selectedOptions: newObject,
          showLoder: !this.state.showLoder,
          isDataAvailable : true,
          fromDateApi: "",
          toDateApi: "",
          activePage: 1
        },
        () => {
          this.getChargeList(this.state.pageSize, 1);
        }
      );
    }, 1000);
    this.setState({
      showLoder: !this.state.showLoder,
    });
  };

  toggleDropdown = (value) => {
    let errors = {};
    const isOpenState = {};
    isOpenState[value] = !this.state[value];
    if (
      this.state.currentOpenDropdown &&
      this.state.currentOpenDropdown !== value
    ) {
      isOpenState[this.state.currentOpenDropdown] = false;
    }

    this.setState({
      ...isOpenState,
      currentOpenDropdown: value,
    });

    errors["nodatafound"] = "";
    errors["nodatarfidfound"] = "";
    errors["nodatauserfound"] = "";

    this.setState({
      searchValue: "",
      searchFirmwareValue: "",
      filterChargerList: [],
      filterStatusList: [],
      filterFirmwareList: [],
      filterBackendList: [],
      errors: errors,
      activeApplyBtn: false,
    });
  };

  handleCheckboxChange = (category, val) => {
    this.setState((prevState) => {
      const updatedOptions = { ...prevState.selectedOptions };
      const updatedCategoryOptions = updatedOptions[category];

      if (updatedCategoryOptions.includes(val)) {
        updatedOptions[category] = updatedCategoryOptions.filter(
          (item) => item !== val
        );
      } else {
        updatedOptions[category] = [...updatedCategoryOptions, val];
      }

      this.setState({
        selectedOptions: updatedOptions,
        activeApplyBtn: updatedOptions[category].length > 0,
      });
    });
  };

  async handleSearchChange(field, event) {
    const value = event.target.value;
    this.setState({ searchValue: value });
    let errors = {};
    const lowercaseValue = value.toLowerCase();
    if (value !== "" && value.length >= 3) {
      let filterData = await this.getChargerList(value, "");
      let filterChargerList = filterData ? filterData.map((data) => data) : [];
      if (filterChargerList.length === 0) {
        errors["nodatafound"] = this.props.t("No data found..!");
        this.setState({ errors: errors, filterChargerList: [] });
      } else {
        errors["nodatafound"] = "";
        this.setState({ filterChargerList: filterChargerList, errors: errors });
      }
    } else {
      this.setState({ errors: errors, filterChargerList: [] });
    }
  }

  
  async handleSearchFirmwareChange(field, event) {
    const value = event.target.value;
    this.setState({ searchFirmwareValue: value })
    let errors = {};
    if (value !== "" && value.length >= 3) {
        let filterData = await this.getFirmwareList(value,"");
        let filterFirmwareList = filterData ? filterData.map(data => data) : [];
        if (filterFirmwareList.length === 0) {
            errors["nodatarfidfound"] = this.props.t('No data found..!');
            this.setState({ errors: errors, filterFirmwareList: [] })
        } else {
            errors["nodatarfidfound"] = "";
            this.setState({ filterFirmwareList: filterFirmwareList, errors: errors })
        }
    } else {
        this.setState({ errors: errors, filterFirmwareList: [] })
    }
}

async handleSearchBackendChange(field, event){
  const value = event.target.value;
  this.setState({searchFirmwareValue:value})
  let errors = {};
  if (value !== "" && value.length >= 3) {
      let filterData = await this.getFirmwareList(value,"");
      let filterFirmwareList = filterData ? filterData.map(data => data) : [];
      if (filterFirmwareList.length === 0) {
          errors["nodatarfidfound"] = this.props.t('No data found..!');
          this.setState({ errors: errors, filterFirmwareList: [] })
      } else {
          errors["nodatarfidfound"] = "";
          this.setState({ filterFirmwareList: filterFirmwareList, errors: errors })
      }
  } else {
      this.setState({ errors: errors, filterFirmwareList: [] })
  }
}





  // handleSubmit for Custom Filter Dropdown
  handleSubmit = (value) => {
    const isOpenState = {};
    isOpenState[value] = !this.state[value];

    const categoryMapping = {
      isOpen1: "customer",
      isOpen2: "chargingStation",
      isOpen3: "backend",
      isOpen4: "firmware",
      isOpen5: "status"
    };

    const updatedSelectedValues = { ...this.state.selectedOptions };

    if (categoryMapping[value]) {
      updatedSelectedValues[categoryMapping[value]] =
        this.state.selectedOptions[categoryMapping[value]];
    }

    this.setState(
      {
        ...isOpenState,
        selectedValues: updatedSelectedValues,
        searchValue: "",
        searchFirmwareValue: "",
        filterChargerList: [],
        filterBackendList: [],
        filterStatusList: [],
        activeApplyBtn: false,
        toDateApi: "",
        fromDateApi: "",
        activePage: 1
      },
      () => {
        this.getChargeList(this.state.pageSize, 1);
      }
    );
  };

  removeSelectedValue = (category, value) => {
    const { selectedValues } = this.state;
    const updatedSelectedValues = { ...selectedValues };
    updatedSelectedValues[category] = updatedSelectedValues[category].filter(
      (val) => val !== value
    );
    this.setState(
      {
        selectedOptions: updatedSelectedValues,
        selectedValues: updatedSelectedValues,
      },
      () => {
        this.getChargeList(this.state.pageSize, 1);
      }
    );
  };

  // function to open popup
  async toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  async moreToggle() {
    this.setState({
      moremodal: !this.state.moremodal,
    });
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [
      ...this.state.listOfFirstCreatedtimes,
      dateTime,
    ];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes,
    });
  }

  toggleExportError() {
    this.setState({
      exportError: !this.state.exportError,
    });
  }

  onChangeAlternativeEmailActive = () => {
    this.setState({
      isAlternativeEmailActive: !this.state.isAlternativeEmailActive,
    });
  };

  successTableContent = () => {
    this.setState({
      successTbContent: !this.state.successTbContent,
    });
  };




  idTagChange() {
    var x = document.getElementById("tagList").value;
    this.setState({ selectedRfid: x }, () => {
      $(".error_rfid").text("");
    });
  }




  showEntriesChange = (selectedOption) => {
    // var entries = document.getElementById("showEntries").value;
    var entries = selectedOption.value;
    this.setState(
      {
        activePage: 1,
        pageSize: entries,
        fromDateApi: '',
        toDateApi: '',
      },
      () => {
        this.getChargeList("","",false);
      }
    );
  };

  emptySearchValues = () => {
    let errors = {};
    errors["nodatafound"] = "";
    this.setState({
        searchValue: "",
        searchFirmwareValue: "",
        errors: errors,
    })
}

handlePageChange(pageNumber) {
  if (pageNumber < this.state.activePage) {
    //prev
    this.setState({
      activePage: pageNumber,
      fromDateApi: this.state.curPageFirstCreTime,
      toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
    }, () => {
      this.getChargeList("","",false);
    });
  } else {
    //next
    let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
    this.setState({
      activePage: pageNumber,
      fromDateApi: '',
      toDateApi: this.state.curPageLastCreTime,
      prevPageFirstCreTime: this.state.curPageFirstCreTime,
    }, () => {
      if (pageNumber > _datesArrayLength + 1) {
        this.createdDatesArray(this.state.curPageFirstCreTime);
      }
      this.getChargeList("","",false);
    });
  }
}

  getStatusKey(value) {
    let key = "";
    if (value === this.props.t("active_charging")) {
      key = "activeCharging";
    } else if (value === this.props.t("finished")) {
      key = "Finished";
    } else if (value === this.props.t("activeNotCharging")) {
      key = "activeNotCharging";
    } else if (value === this.props.t("prepairing")) {
      key = "prepairing";
    } else if (value === this.props.t("active_preparing")) {
      key = "activePreparing";
    } else if (value === this.props.t("active_not_charging")) {
      key = "SuspendedEV";
    } else if (value === this.props.t("halted")) {
      key = "Halted";
    }
    return key;
  }



  componentDidMount() {
    this.getProfileData()
    this.getChargeList(this.state.pageSize, this.state.activePage);
    this.getConnectorStatusList();
  }

  async getChargeList(pageSize, pageNo, count) {
    this.state.isDataAvailable = true;
    document.getElementById("loader_image_div").style.display = "block";
    let errors = {};
    var url = baseUrl.URLPath + "service/partner/user/chargers?pageSize=" + this.state.pageSize + "&pageNo=" + this.state.activePage + "&fromTime="+this.state.fromDateApi + "&toTime="+this.state.toDateApi;
    let types = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();

    let payload = {
      "backendConnection": this.state.selectedValues.backend,
      "chargePointIds": this.state.selectedValues.chargingStation,
      "fwVersion": this.state.selectedValues.firmware,
      "status": this.state.selectedValues.status
    };
    let response = await APICallUtility.cpmsAPIFetch(url, types, payload, headers, this.props.t);
    if (response) {
      document.getElementById("loader_image_div").style.display = "none";
        if (response.status === 404) {
          
        } else {
          if(response.count===0){
            document.getElementById("loader_image_div").style.display = "none";
            this.setState({isDataAvailable : false})

          }else{

          if(count===false){
            setTimeout(() => {
              this.setState({
                chargerList: response.data ? response.data :[] ,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                curPageFirstCreTime: moment(response.data[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                curPageLastCreTime: moment(response.data[response.data.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              }, () => {
                  document.getElementById("loader_image_div").style.display = "none";
              })
          }, 100);
          }else{
            setTimeout(() => {
              this.setState({
                chargerList: response.data ? response.data :[] ,
                noOfRecords: response.count ? response.count : 0,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                curPageFirstCreTime: moment(response.data[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                curPageLastCreTime: moment(response.data[response.data.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              }, () => {
                  document.getElementById("loader_image_div").style.display = "none";
              })
          }, 100);
          }
         


      }

        }
    }
}

  async getProfileData(){
    let type = ApiMethodTypes.GET;
    var url = baseUrl.URLPath +servicesUrl.getUserServicePartnerDetails;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if(response){
      let dataFromResponse = {
        serviceAccessCode : response.accessCode ? response.accessCode :"" ,
        companyName : response.companyName ? response.companyName :"" ,
        city : response.city ? response.city :"" ,
        zipCode : response.zipCode ? response.zipCode :"" ,
        address : response.address ? response.address :"" ,
        country : response.country ? response.country :"" ,
        telephone : response.telephone ? response.telephone :"" ,
        email : response.email ? response.email :"" ,
        websiteUrl : response.websiteURL ? response.websiteURL :"" ,
      }
      this.setState({profileData : dataFromResponse, prevCompName: response.companyName ? response.companyName :""})

    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleFilterOutsideClick);
  }

  //function for sorting table data on click
  ascendingOrder() {
    this.setState({ dataArray: this.state.dataArray.reverse() });
  }

  //function for sorting table data on click
  descendingOrder() {
    this.setState({ dataArray: this.state.dataArray.reverse() });
  }

  showExportPopup = () => {
    this.setState({
      exportBtn: !this.state.exportBtn,
      settingsMenu: !this.state.settingsMenu,
      fileType: "",
    });
  };

  closeExportPopup = () => {
    let errors = {};
    errors["exportFormatErr"] = "";
    this.setState({
      exportBtn: !this.state.exportBtn,
      errors: errors,
    });
  };
  // showExportPopup = () => {
  //     this.setState(prevState => ({
  //         exportBtn: !prevState.exportBtn
  //     }));
  // }


  handleExportFormat = (type, event) => {
    this.setState({
      exportFormat: event.target.value,
      reportExportType: type,
    });
  };

  exportSMpartner = () => {
    let errors = {};
  
      this.setState({
        showLoder: !this.state.showLoder,
      });
      var timezone = moment().tz(moment.tz.guess()).format("z");
      var url = "";
      url = baseUrl.URLPath + servicesUrl.downloadSMpartner;
   
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem("Authorization"),
        },
      })
      .then((resp) => {
        if (resp.status === 401) {
          window.location.reload();
        }
        if (resp.status === 200) {
          return resp.blob();
        }
        if (resp.status === 500) {
          this.setState({
            showLoder: !this.state.showLoder,
          });
        }
        if(resp.status === 404){   
          return resp.json();
        }
      })
      .then((response) => {
        if (response !== undefined) {
          if(response.status===404 && response.message ==="insufficient_service_partner_detials"){
            this.toggleASetChargingLimit();
            this.setState({
              showLoder: !this.state.showLoder,
              isPdfSuccessPopupOpen: false,
             // isSharePopupOpen: false
            });
          }else{
          let d = new Date();
          let dformat = `${d.getDate().toString().padStart(2, "0")}-${(
            d.getMonth() + 1
          )
            .toString()
            .padStart(
              2,
              "0"
            )}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
          let url = window.URL.createObjectURL(response);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Accesscode.pdf";
          a.click();
          this.setState({
            exportBtn: !this.state.exportBtn,
            showLoder: !this.state.showLoder,
          });
        }
        }
      })
      .catch((error) => {
        this.setState(
          {
            showLoder: !this.state.showLoder,
          },
          () => {
            this.toggleExportError();
          }
        );
        console.log(error, "catch the loop");
      });
  };



  updateCheckboxSelection(event, item) {
    const allItems = [...this.state.selectedItems];
    const deletedWallboxes = [...this.state.deletedWallboxes];
    const selectedItemIndex = allItems.findIndex((el) => el === item);
    if (event.target.checked) {
      if (selectedItemIndex < 0) {
        allItems.push(item);
      }
    } else {
      const deletedWallboxIndex = this.state.siteWallboxes.findIndex(
        (el) => el === item
      );
      const isAlreadyDeleted =
        deletedWallboxes.findIndex((el) => el === item) > -1;
      if (deletedWallboxIndex > -1 && !isAlreadyDeleted) {
        deletedWallboxes.push(item);
      }
      allItems.splice(selectedItemIndex, 1);
    }
    this.setState({ ...this.state, selectedItems: allItems, deletedWallboxes });
  }



  //display warning popup for set charging limit
  toggleASetChargingLimit() {
    this.setState({
      ASetChargingLimit: !this.state.ASetChargingLimit,
    });
  }

  UNSAFE_componentWillMount() {
    var user = {
      permissions: [],
    };
    user.permissions = localStorage.getItem("roleAccess");
    this.setState({
      user: user,
    });
  }

  onKeyPress(event) {
    if (
      !/^\d*\.?\d*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault();
    }
  }

  // new functions

  //get charger list
  async getChargerList(cpid, text) {
    var url = baseUrl.URLPath + servicesUrl.getSMchargers + "?chargePointId=" + cpid;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(
      url,
      type,
      payload,
      headers,
      this.props.t
    );
    if (response !== undefined) {
      if (response.message === "cp_not_exists") {
        response = [];
      } else {
        response = response;
      }
       this.setState({ filterChargerList: response })
      return response;
    }
  }

  async getFirmwareList(firmwareV) {
    var url = baseUrl.URLPath + servicesUrl.getSMFilterFirmwareList + "?firmware=" + firmwareV;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let errors = {};
    let response = await APICallUtility.cpmsAPIFetch(
      url,
      type,
      payload,
      headers,
      this.props.t
    );
    if (response !== undefined) {
      if (response.message === "ocpp_tag_does_not_exist") {
        response = [];
      } else {
        response = response;
      }
      return response;
    }
  }

  async getUserList(userName) {
    var url =
      baseUrl.URLPath +
      servicesUrl.getTraUserList +
      "?name=" +
      userName +
      "&fromTime=" +
      this.state.fromDateApi +
      "&toTime=" +
      this.state.toDateApi;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let errors = {};
    let response = await APICallUtility.cpmsAPIFetch(
      url,
      type,
      payload,
      headers,
      this.props.t
    );
    if (response !== undefined) {
      if (response.message === "user_not_exists") {
        response = [];
      } else {
        response = response;
      }
      return response;
    }
  }

  async getReportList() {
    var url = baseUrl.URLPath + servicesUrl.getReport;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let errors = {};
    let response = await APICallUtility.cpmsAPIFetch(
      url,
      type,
      payload,
      headers,
      this.props.t
    );
    if (response !== undefined) {
      if (response) {
        this.setState({
          reportList: response,
        });
      } else if (response.status === 404) {
      }
    }
  }



  checkIdGetName(val, type) {
    const a1 = val;
    let ObjVal;
    if (type === "selectedValues") {
      ObjVal = this.state.filterUserListName;
    } else {
      ObjVal = this.state.filterReportUserListName;
    }
    const checking = ObjVal.find((item) => (item.id === a1 ? item.name : null));
    return checking?.name;
  }

  // get History data 
  async getFilterHistory() {
    var url = baseUrl.URLPath + servicesUrl.getSMFilterHistory;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let errors = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
        if (response) {
            let filterChargerList = response.chargePointIds;
            let filterFirmwareList = response.fwVersion;
            this.setState({
                filterChargerList: filterChargerList,
                filterFirmwareList: filterFirmwareList
            })
        } else if (response.status === 404) {

        }
    }
}



async getConnectorStatusList(){
  var url = baseUrl.URLPath + servicesUrl.getSMconnectorStatusList;
  let type = ApiMethodTypes.GET;
  let headers = getSecurityHeaders();
  let payload = {}
  let errors = {}
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if (response !== undefined) {
      if (response) {
          let statusDropdownList = response;
          this.setState({
            statusDropdownList: statusDropdownList
          })
      } else if (response.status === 404) {

      }
  }
}


  render() {

    const { t } = this.props;
    const { selectedOption } = this.state;

    const options = [
      { value: "10", label: "10" },
      { value: "20", label: "20" },
      { value: "30", label: "30" },
      { value: "40", label: "40" },
      { value: "50", label: "50" },
    ];

    const colourStyles = {
      menuList: (styles) => ({
        ...styles,
        background: "white",
      }),
      option: (styles, { isFocused, isSelected }) => {
        // Only apply the background color when an option is selected
        if (isSelected) {
          return {
            ...styles,
            background: "#30436E",
            color: "white",
            zIndex: 1,
          };
        }
        return {
          ...styles,
          background: isFocused ? "white" : "transparent",
          color: "#30436E",
          zIndex: 1,
        };
      },
      menu: (base) => ({
        ...base,
        zIndex: 100,
      }),
    };


    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          {/* <span style={{ transform: 'rotate(360deg)' }}>▼</span> */}
          <span className="caret-icon-w"></span>
        </components.DropdownIndicator>
      );
    };

    const table = { field: [] };
    let user = this.state.user;
 
    let editAction = false;
    let carAction = false;

    for (let Operation in this.state.getOperationList) {
      if (
        this.state.getOperationList[Operation].operation ===
          "Charging Session" &&
        this.state.getOperationList[Operation].active === true
      ) {
        for (let oper in this.state.getOperationList[Operation].subOperations) {
          if (
            this.state.getOperationList[Operation].subOperations[oper].name ===
              "Remote Start Transaction" &&
            this.state.getOperationList[Operation].subOperations[oper]
              .access === true
          ) {
            editAction = true;
          }
          if (
            this.state.getOperationList[Operation].subOperations[oper].name ===
              "Logbook" &&
            this.state.getOperationList[Operation].subOperations[oper]
              .access === true
          ) {
            carAction = true;
          }
        }
      }
    }

    const tagList = this.state.rfidTagsList.map((tag, index) => {
      return (
        <>
          {" "}
          {tag.idTag.trim() !== 0 || tag.idTag.trim() !== "" ? (
            <option value={tag.idTag}>{tag.idTag}</option>
          ) : null}{" "}
        </>
      );
    });
    const groupList = this.state.groupList ? this.state.groupList : [];

    const { start, end } = this.state;

    const label =
      start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
    return (
      <>
        <main className="content-div smpage">
          <p>{t("Service Management")}</p>
          <div className="page-outerdiv" id="charging_station">
            <div className="row mb-2 mt-3" style={{ alignItems: "center"}}>
              <div className="col-md-7 pr-3">
                    <div className="smpage-editprofile">
                    <button class="company-name">
                    <span className="company-text">{this.state.profileData.companyName}</span>
                    <img src={process.env.PUBLIC_URL + "/assets/img/svg/3dots_menu_white.svg"} 
                    className="dotsicon" height='30' onClick={this.handleEditSMProfile} />
                  </button>
                  </div>
                </div>
              <div className="col-md-5 pr-0 mr-0 pull-right"  >
                <div className="row" style={{width:"100%", display:"flex", justifyContent:'end'}} >
                  <div className="col-md-6 pr-0" >
                    <div className="smpage-accesscode" >
                      <span className='span1' >{t('access_code')}:</span>
                      <span className='span2' >{this.state.profileData.serviceAccessCode}</span>
                    </div>
                  </div>
                  <div className="col-md-3 pr-0 pull-right mr-0 pr-0" >
                    <div
                      className="smpage-sharebtn"
                      type="button"
                      onClick={this.handleShareBtn}
                    >
                      <img src={process.env.PUBLIC_URL +"/assets/img/svg/share.svg"} style={{height:'20px', width:'20px'}} />
                      <span style={{marginLeft:'5px'}}>{t('share')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4 mt-5" style={{ alignItems: "center" }}>
              <div class="col pr-0">
                <CustomFilterDropdown
                  iconSrc={process.env.PUBLIC_URL + "/assets/img/cs_charge_point_icon.svg" }
                  placeholder={t("search_for_chargepoint_id")}
                  headerText={t("charging_station")}
                  option={this.state.filterChargerList}
                  toggleDropdown={() => this.toggleDropdown("isOpen2")}
                  handleCheckboxChange={this.handleCheckboxChange}
                  handleSubmit={() => this.handleSubmit("isOpen2")}
                  isOpen={this.state.isOpen2}
                  selectedOptions={this.state.selectedOptions}
                  searchValue={this.state.searchValue}
                  emptySearchValues={this.emptySearchValues}
                  handleSearchChange={this.handleSearchChange.bind( this, "filterChargerList" )}
                  errorsMsg={this.state.errors["nodatafound"]}
                  category={"chargingStation"}
                  cssClass="chargeStationIcon"
                  isApplyButtonDisabled={this.state.activeApplyBtn}
                  getFilterHistory = {this.getFilterHistory}
                  showHistory = {true}
                />
              </div>
              <div className="col pr-0">
                <CustomFilterNSDropdown
                  iconSrc={
                    process.env.PUBLIC_URL + "/assets/img/svg/backend.svg"
                  }
                  headerText={t("backend")}
                  option={this.state.backendDropdownList}
                  toggleDropdown={() => this.toggleDropdown("isOpen3")}
                  handleCheckboxChange={this.handleCheckboxChange}
                  handleSubmit={() => this.handleSubmit("isOpen3")}
                  isOpen={this.state.isOpen3}
                  selectedOptions={this.state.selectedOptions}
                  searchValue={this.state.searchValue}
                  emptySearchValues={this.emptySearchValues}
                  handleSearchChange={this.handleSearchBackendChange.bind(
                    this,
                    "filterBackendList"
                  )}
                  errorsMsg={this.state.errors["nodatafound"]}
                  category={"backend"}
                  cssClass="chargeStationIcon"
                  isApplyButtonDisabled={this.state.activeApplyBtn}
                />
              </div>
              <div className="col pr-0">
                <CustomFilterDropdown
                  iconSrc={
                    process.env.PUBLIC_URL + "/assets/img/svg/firmware.svg"
                  }
                  placeholder={t("search_for_firmware")}
                  headerText={t("firmware")}
                  option={this.state.filterFirmwareList}
                  toggleDropdown={() => this.toggleDropdown("isOpen4")}
                  handleCheckboxChange={this.handleCheckboxChange}
                  handleSubmit={() => this.handleSubmit("isOpen4")}
                  isOpen={this.state.isOpen4}
                  selectedOptions={this.state.selectedOptions}
                  searchValue={this.state.searchFirmwareValue}
                  emptySearchValues={this.emptySearchValues}
                  handleSearchChange={this.handleSearchFirmwareChange.bind(
                    this,
                    "filterFirmwareList"
                  )}
                  errorsMsg={this.state.errors["nodatafound"]}
                  category={"firmware"}
                  cssClass="chargeStationIcon"
                  isApplyButtonDisabled={this.state.activeApplyBtn}
                  getFilterHistory = {this.getFilterHistory}
                  showHistory = {true}
                />
              </div>
              <div className="col pr-0 mr-2">
                <CustomFilterNSDropdown
                  iconSrc={
                    process.env.PUBLIC_URL + "/assets/img/svg/status.svg"
                  }
                  //placeholder={t('search_for_customer')}
                  headerText={t("status")}
                  option={this.state.statusDropdownList}
                  toggleDropdown={() => this.toggleDropdown("isOpen5")}
                  handleCheckboxChange={this.handleCheckboxChange}
                  handleSubmit={() => this.handleSubmit("isOpen5")}
                  isOpen={this.state.isOpen5}
                  selectedOptions={this.state.selectedOptions}
                  searchValue={this.state.searchValue}
                  emptySearchValues={this.emptySearchValues}
                  handleSearchChange={this.handleSearchChange.bind(
                    this,
                    "filterStatusList"
                  )}
                  errorsMsg={this.state.errors["nodatafound"]}
                  category={"status"}
                  cssClass="chargeStationIcon"
                  isApplyButtonDisabled={this.state.activeApplyBtn}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="selecteditems" style={{ flexWrap: "wrap" }}>
                 
                   {Object.keys(this.state.selectedValues).map((category) =>
                    this.state.selectedValues[category].map((val, index) => (
                      <>
                        <button className="icon-button mr-3 mb-3" key={index}>
                          <img
                            src={
                              category === "chargingStation"
                                ? process.env.PUBLIC_URL +
                                  "/assets/img/cs_charge_point_icon.svg"
                                : category === "backend"
                                ? process.env.PUBLIC_URL +
                                  "/assets/img/svg/backend.svg"
                                : category === "firmware"
                                ? process.env.PUBLIC_URL +
                                  "/assets/img/svg/firmware.svg"
                                : process.env.PUBLIC_URL +
                                  "/assets/img/svg/status.svg"
                            }
                            width={
                              category === "chargingStation"
                                ? 34
                                : category === "RfidTag"
                                ? 32
                                : 26
                            }
                            className="icon pr-2"
                            alt={val}
                          />
                          <span className="name">
                            {category === "Users"
                              ? this.checkIdGetName(val, "selectedValues")
                              : val}
                          </span>
                          <span
                            className="close-icon"
                            onClick={() =>
                              this.removeSelectedValue(category, val)
                            }
                          ></span>
                        </button>
                      </>
                    ))
                  )} 

                   {(this.state.selectedValues.chargingStation.length !== 0 ||
                    this.state.selectedValues.backend.length !== 0 ||
                    this.state.selectedValues.firmware.length !== 0
                    ||  this.state.selectedValues.status.length !== 0) && (
                    <div className="mr-2">
                      <button
                        type="button"
                        data-test="button"
                        aria-label="Close"
                        className="clearall-btn"
                        onClick={this.removeAllSelected}
                      >
                        <span aria-hidden="true">×</span> {t("clear_all")}
                      </button>
                    </div>
                  )} 
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.showLoder === true && (
                <div className="page_loader center" id="loader_image_div">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"}
                    width="80"
                    height="80"
                    alt=""
                  />
                </div>
              )}
            </div>
            {/* end search bar div */}
            <div className="lastdiv mt-2">
              <div
                className="pull-right show_entries"
                style={{ display: "flex" }}
              >
                <label className="sub-text">{t("show_entries")}</label>
                <Select
                  className="react-select-container"
                  defaultValue={selectedOption}
                  onChange={this.showEntriesChange}
                  options={options}
                  isSearchable={false}
                  placeholder="10"
                  styles={colourStyles}
                  components={{ DropdownIndicator }}
                />
              </div>
            </div>

            {/* table start */}
            <div className="row mt-2">
              <div className="col-md-12">
              <Table striped hover size="sm" className="page-table responsive">
              <thead>
                <tr>
                  {tableHeaders.map((tableItem, index) => {
                    return <th>{tableItem}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {this.state.isDataAvailable ? this.state.chargerList.map((eachObj, index) => {
                  let onboarddate = "";
                  let onboarddate1 = "";
                  var isOnline = eachObj.backendConnection;
                  if (eachObj.heartBeatTime === null || eachObj.heartBeatTime === "") {
                    onboarddate = "";
                    onboarddate1 = "";
                  } else {
                    onboarddate = moment(eachObj.heartBeatTime).format("DD MMM YYYY");
                    onboarddate1 = moment(eachObj.heartBeatTime).format("HH:mm");
                  }
                  return (
                    <tr key={eachObj.chargePointId}>
                      <td>{eachObj.chargePointId}</td>
                      <td>{eachObj.model}</td>
                      <td>{eachObj.customerName}</td>
                      <td>
                        <i className="fas fa fa-circle fs-16"   style={{
                            color: isOnline ? "green" : "red",
                          }}>
                        </i>
                        {/* <button
                        
                          className="smpagetable-backendtdcell"
                        ></button> */}
                        {eachObj.backendConnection}
                      </td>
                      <td>{eachObj.currentStatus}</td>
                      <td>{eachObj.fwVersion}</td>
                      <td>
                         { onboarddate ? <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/cs_table_calendar_icon_sm.svg"
                          }
                          style={{ marginRight: 3 }}
                        /> : "" }
                        {onboarddate + " " +onboarddate1}
                      </td>
                      <td>
                        <button
                          onClick={this.openOverViewPopup.bind(this, eachObj.chargePointId,eachObj.model)}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/img/svg/edit.svg"
                            }
                            style={{ marginRight: 3, width: 25 }}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                }) : <td colSpan={8} style={{ textAlign: "center" }}>{t('no_data_available')}</td> }
              </tbody>
            </Table>

              </div>
            </div>
            { this.state.isDataAvailable && <div className="row">
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                  prevPageText={t('prev')}
                  nextPageText={t('next')}
                  itemClassPrev="prevBtn"
                  pageRangeDisplayed="1"
                  activeClass="activeLi active"
                  itemClassNext="nextBtn"
                  hideFirstLastPages={true}      
                  disableInitialCallback={true}
                  disabledClass="disabled disabledtextColor"                   
                />
              </div>
            </div>}
          </div>

          {/* Loader */}
          <div
            className="page_loader center"
            id="loader_image_div"
            style={{ display: "none" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"}
              width="80"
              height="80"
              alt=""
            />
          </div>

          {/* table content popup success  */}
          <MDBModal
            isOpen={this.state.successTbContent}
            toggle={this.successTableContent}
            size="md"
            className="model_top deleteReportModal mw-450"
          >
            <MDBModalBody className="p-4">
              <div className="pt-2">
                <div className="popupRightClose mb-3">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"}
                    className="pull-right closeIcon"
                    onClick={() => {
                      this.successTableContent.bind(this);
                      window.location.reload();
                    }}
                  />
                </div>
              </div>
              <p className="text-center fontMedium pb-2 pt-4">
                {t("table_saved_successfully")}
              </p>
            </MDBModalBody>
            <div className="confirmdelete pb-5">
              <button
                className="ltblueBtn w-170 mb-3"
                onClick={() => {
                  this.successTableContent.bind(this);
                  window.location.reload();
                }}
              >
                {t("Ok")}
              </button>
            </div>
          </MDBModal>

          <MDBModal isOpen={this.state.isOpenProfileModal} size='md' className='modal-smpopup'>
          <MDBModalBody className="service-management-profile">
            <h4 className="mb-4 mt-2"> {t('edit_service_management_profile')}</h4>
            <form className='smpopup-form'>
              <div className='smpopup-form-input-containers'>
              
              
              <div className='md-form mb-4'>    
              <input name="companyName" type="text" id="companyName" value={this.state.profileData["companyName"]} 
              className='form-control pl-0' onChange={this.handleFormInputChange}
              onBlur={this.handleCompanyNameError} maxLength="35" />
		          <label htmlFor="companyName" className={`${
                            this.state.profileData["companyName"] !== "" &&
                            this.state.profileData["companyName"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('company_name')}*</label>

              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["iscompanyNameEmpty"]}</span></span>
              </div>
              
              <div className='md-form mb-4'>
              <input name="city" type="text" id="city" value={this.state.profileData["city"]} 
              className='form-control pl-0' onChange={this.handleFormInputChange} onBlur={this.handleMandatoryField}/>
              <label  htmlFor="city" className={`${
                            this.state.profileData["city"] !== "" &&
                            this.state.profileData["city"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('city')}*</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["iscityEmpty"]}</span></span>
              </div>

              <div className='md-form mb-4'>
              <input name="zipCode" type="text" value={this.state.profileData.zipCode} id="zipCode"
              className='form-control pl-0' onChange={this.handleFormInputChange} onBlur={this.handleMandatoryField}/>
              <label  htmlFor="zipCode" className={`${
                            this.state.profileData["zipCode"] !== "" &&
                            this.state.profileData["zipCode"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('zipCode')}*</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["iszipCodeEmpty"]}</span></span>

              </div>

              <div className='md-form mb-4'>
              <input name="address" type="text" value={this.state.profileData.address} id="address"
              className='form-control pl-0' onChange={this.handleFormInputChange} onBlur={this.handleMandatoryField}/>
              <label  htmlFor="address" className={`${
                            this.state.profileData["address"] !== "" &&
                            this.state.profileData["address"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('address')}*</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["isaddressEmpty"]}</span></span>
              
              </div>

              <div className='md-form mb-4'>
              <input name="country" type="text" value={this.state.profileData.country} id="country"
              className='form-control pl-0' onChange={this.handleFormInputChange} onBlur={this.handleMandatoryField}/>
              <label  htmlFor="country" className={`${
                            this.state.profileData["country"] !== "" &&
                            this.state.profileData["country"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('country')}*</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["iscountryEmpty"]}</span></span>

              </div>

              <div className='md-form mb-4'>
              <input name="telephone" type="text" value={this.state.profileData.telephone} id="telephone"
              className='form-control pl-0' onChange={this.handleFormInputChange} onBlur={this.handleTelephoneError}/>
              <label  htmlFor="telephone" className={`${
                            this.state.profileData["telephone"] !== "" &&
                            this.state.profileData["telephone"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('telephone')}*</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["istelephoneEmpty"]}</span></span>

              </div>

              <div className='md-form mb-4'>
              <input name="email" type="text" value={this.state.profileData.email} id="email"
              className='form-control pl-0' onChange={this.handleFormInputChange} onBlur={this.handleEmailError}/>
              <label  htmlFor="email" className={`${
                            this.state.profileData["email"] !== "" &&
                            this.state.profileData["email"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('email')}*</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["isemailEmpty"]}</span></span>

              </div>

              <div className='md-form mb-4'>
              <input name="websiteUrl" type="text" value={this.state.profileData.websiteUrl} id="websiteUrl"
              className='form-control pl-0' onChange={this.handleFormInputChange} onBlur={this.validateURL}/>
              <label  htmlFor="websiteUrl" className={`${
                            this.state.profileData["websiteUrl"] !== "" &&
                            this.state.profileData["websiteUrl"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('website_url')}</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["iswebsiteUrlEmpty"]}</span></span>

              </div>


             
              </div>
              <div className='smpopup-btns mt-3' >
                
                <button type="button" className='smpopup-savebtn' onClick={this.handleSaveSMProfile}>{t('save')}</button>
                
                <button type="button" className='smpopup-cancelbtn' onClick={this.toggleProfileModal}>{t('cancel')}</button>
              </div>
            
            </form>
          </MDBModalBody>

          <MDBModal isOpen={this.state.isSuccessPopupOpen} size="md" className="model_top">
            <MDBModalBody>
            <div className='flex-col-justify-n-align-center'>
            <img src={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"} width="60" height="60"  alt="" />
            <p className='successpopup-msg'>{t('sm_profile_hasbeen_successfully_changed')}</p>
            <button className='successpopup-closebtn' type='button' onClick={() => window.location.reload()}>{t('close')}</button>

            </div>
            </MDBModalBody> 
         </MDBModal>
          
        </MDBModal>

          <MDBModal
            isOpen={this.state.isSuccessPopupOpen}
            size="md"
            className="model_top"
          >
            <MDBModalBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                  width="60"
                  height="60"
                  alt=""
                />
                <p className="successpopup-msg">
                  {t('sm_profile_hasbeen_successfully_changed')}
                </p>
                <button
                  className="successpopup-closebtn"
                  type="button"
                  onClick={this.toggleSuccessPopup}
                >
                  {t("close")}
                </button>
              </div>
            </MDBModalBody>
          </MDBModal>

          <MDBModal
            isOpen={this.state.isOverViewPopupOpen}
            size="xl"
            className="smoverview-popup"
          >
            <MDBModalBody>
             {<ViewchargepointModal smCPModel={this.state.smCPModel} smCPId={this.state.smCPId} closeModal = {this.closeOverviewPopup}/>}
            </MDBModalBody>
          </MDBModal>

          <MDBModal
            isOpen={this.state.isSharePopupOpen}
            size="md"
            className="model_top"
          >
           <MDBModalBody>
              <div>
                <div style={{ width: "70%", backgroundColor: "#F4F8FD", borderRadius:'25px', padding:0, marginBottom:"20px" }}>
                  <button style={{width:'45%'}}className={this.state.isActiveShareEmail?'active-share-btn':'not-active-btn'} onClick={this.handleShareEmail}>{t('share_via_email')}</button>
                  <button style={{width:'55%'}}className={this.state.isActiveDownloadPdf?'active-share-btn':'not-active-btn'} onClick={this.handleDownloadPdf}>{t('download_as_pdf')}</button>
                </div>
                <div
                  className={this.state.isActiveShareEmail ? 'display-share-email' : 'block-share-email'}
                >
                    <h4 className="share-email-heading">{t('share_access_code')}</h4>
                    <p>{t('please_enter_emailaddrof_customer_with_whom_you_share_access_code')}
                    </p>
                    <div className='md-form mb-4'>
                        <input name="customerMail" type="text" value={this.state.customerMail} id="customerMailId"
                        className='form-control text_lowercase pl-0' onChange={this.handleCustomerMailChange} onBlur={this.handleEmailErrorEmail}/>
                        <label  htmlFor="email" className={`${
                                      this.state["customerMail"] !== "" &&
                                      this.state["customerMail"] !== null
                                        ? "active"
                                        : ""
                                    } ml-11`}>{t('email')}*</label>
                        <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["isemailEmpty"]}</span></span>

                    </div>
                  <div className="emailshare-checkbox">
                    <input type="checkbox" value={this.state.isEmailShareChecked} 
                    id="shareEmailId"
                    onChange={this.handleEmailShareChecked}/>
                    <label style={{marginLeft:'5px'}} htmlFor="shareEmailId">{t('i_want_to_share_the_access_code')}</label>
                    
                  </div>
                  <div className="emailshare-checkbox">
                  <button
                    type="button"
                    disabled={!this.state.isEmailShareChecked}
                    onClick={this.handleEmailShareSubmitBtn}
                    className={`smpopup-savebtn ${this.state.isEmailShareChecked?'show-emailsharebtn':'disable-emailsharebtn'}`}
                  >
                    {t("submit")}
                  </button>
                  
                  <button
                    type="button"
                    className="smpopup-cancelbtn"
                    onClick={this.toggleSharePopup}
                    style={{marginLeft:"10px"}}
                  >
                    {t("cancel")}
                  </button>
                  </div>
                </div>
                <div  className={this.state.isActiveDownloadPdf ? 'display-download-pdf' : 'block-download-pdf'}
                  >   
                  <h4 className="share-email-heading">{t('download_access_code_as_pdf_file')}</h4>
                  <p>{t('download_your_access_code_as_pdf_to_keep_and_share')}</p>
                  <br/>
                  {/* <div className={this.state.isPdfDownloaded ? "pdf-downloaded" : "pdf-not-downloaded"}>
                  <h4>{t('pdf_file_hasbeen_downloaded_successfully')}</h4>
                  </div> */}
                  <div className="emailshare-checkbox">
                  <button
                    type="button"
                    className="smpopup-savebtn"
                    
                    onClick={this.handleDownloadPdfBtn}
                  >
                    {t("download")}
                  </button>
                  <button
                    type="button"
                    className="smpopup-cancelbtn"
                    onClick={this.toggleSharePopup}
                    style={{marginLeft:"10px"}}
                  >
                    {t("cancel")}
                  </button>
                  </div>


                </div>
              </div>
 </MDBModalBody>
          </MDBModal>

          <MDBModal isOpen={this.state.isPdfSuccessPopupOpen} size="md" className="model_top">
            <MDBModalBody>
            <div className='flex-col-justify-n-align-center pt-2 pb-2'>
            <img src={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"} width="60" height="60"  alt="" />
            <p className='successpopup-msg m-3' >{t('the_pdf_download_was_successful')}</p>
            <button className='successpopup-closebtn' type='button' onClick={this.handleTogglePdfSuccessPopup}>{t('close')}</button>

            </div>
            </MDBModalBody> 
         </MDBModal>

          <MDBModal
            isOpen={this.state.isEmailSuccessPopupOpen}
            size="md"
            className="model_top"
          >
            <MDBModalBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                  width="60"
                  height="60"
                  alt=""
                />
                <p className="successpopup-msg">
                  {t('email_hasbeen_successfully_sent_to_user')}
                </p>
                <button
                  className="successpopup-closebtn"
                  type="button"
                  onClick={this.toggleEmailSuccessPopup}
                >
                  {t("close")}
                </button>
              </div>
            </MDBModalBody>
          </MDBModal>

          {/* set charging limit warning popup message */}
          <CustomModal
            open={this.state.ASetChargingLimit}
            toggle={this.toggleASetChargingLimit}
            title={t("alert")}
            bodyMessage={t("insufficient_service_partner_detials")}
            PrimaryButtonText={t("ok")}
            PrimaryButtonOperation={this.toggleASetChargingLimit}
          />
          
        </main>
      </>
    );
  }
}
export default withTranslation()(ServiceManagementPage);
