import React from 'react';
import * as baseUrl from '../views/constants';
import $ from "jquery";
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import CustomModal from '../common/CustomModal';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

import servicesUrl from './servicesUrl';

 class cpPartialSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            multiple: false,
            errors: {},
            intial_data: [],
            options: [],
            saveSelectedCpid: "",
            selectFindStatus: false,
            noWallboxExists: props.noWallboxExists,
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.chargepointIDChange = this.chargepointIDChange.bind(this);
        this.ClearChargepoint = this.ClearChargepoint.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.togglesession = this.togglesession.bind(this);
    }

    async getSearchInventoryList(cpid) {
        var url ="";
        if(this.props.pagename==="associatedRfidTag"){
             url = baseUrl.URLPath + servicesUrl.getOcpptagsAssociatedPartialSearch+ "?ocppTagId="+this.props.id+"&cpId=" + cpid;
        }
        if(this.props.pagename==="deassociatedRfidTag"){
             url = baseUrl.URLPath + servicesUrl.getOcpptagsUnassociatedPartialSearch + "?ocppTagId="+this.props.id+"&cpId=" + cpid;
        }
        if(this.props.pagename==="chargePoint"){
            url = baseUrl.URLPath + servicesUrl.getChargepointsPartilaSearch + "?cpid=" + cpid;
        }

        // var url = "";
        // if (this.props.pagename === "associatedRfidTag") {
        //     url = baseUrl.URLPath + "ocpptags/" + this.props.id + "/associated/search/partial?cpId=" + cpid;
        // }
        // if (this.props.pagename === "deassociatedRfidTag") {
        //     url = baseUrl.URLPath + "ocpptags/" + this.props.id + "/unassociated/search/partial?cpId=" + cpid;
        // }
        // if (this.props.pagename === "chargePoint") {
        //     url = baseUrl.URLPath + "chargepoints/search/partial?cpid=" + cpid;
        // }

        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false);

        if (response !== undefined) {
            this.setState({
                options: response,
                intial_data: response
            });
        }
    }

    // search filter 
    handleKeyDown(ref, e) {
        var serial_no = e.target.value;
        $("#nochargeexists").hide();
        $("#selectcharge").hide();
        if (serial_no.length > 1) {
            $("#selectcharge").hide();
        }
        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
        var sno = serial_no.slice(0, serial_no.length - 1)
        if (e.target.value.length > 1) {
            this.getSearchInventoryList(sno)
        }
        if (key === "Backspace" || key === "Delete") {
            if (sno === "") {
                $("#selectcharge").hide();
                this.setState({ saveSelectedCpid: sno }, ()=>{
                    this.props.getData(true, "", "Clear");
                })
            }
        }
        if (e.keyCode === 13) {
            $("#selectcharge").hide();
            this.FindCpName(ref, e);
            $("#rbt_id").hide();
        }
        if ((e.keyCode === 86 && ctrl)) {
            $("#selectcharge").hide();
            this.getSearchInventoryList(serial_no)
        }
        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9 -]");
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }


   async FindCpName(ref, event) {
        event.preventDefault();
        let saveSelectedCpid = ref.current.inputNode.value;
        let selectFindStatus = this.state.selectFindStatus;
        this.props.reset();
        var errors = {};
        if (ref.current.inputNode.value === null || ref.current.inputNode.value === "") {
            $("#selectcharge").show();
            errors["selectChargepoint"] = this.props.t('select_the_charge_point');
        } else {
            await this.props.getData(true, ref.current.inputNode.value, "", "Clear")
            errors["selectChargepoint"] = "";
            selectFindStatus = true;
            setTimeout(
                function () {
                    var data = localStorage.getItem("noWallboxExists");
                    if (data === "0" && data !== undefined && data !== "") {
                        $("#selectcharge").show();
                        errors["selectChargepoint"] = this.props.t('charge_point_not_exists');
                        this.setState({
                            errors: errors
                        });
                    }
                }
                    .bind(this),
                200
            );
        }

        // if (this.props.noWallboxExists === "") {
        //     var serial_no = event.target.value;
        //     if (serial_no.length > 1) {
        //         $("#selectcharge").hide();
        //         errors["selectChargepoint"] = "";
        //         this.setState({
        //             errors: errors
        //         });
        //     } else {
        //         $("#selectcharge").show();
        //     }
        // }

        this.setState({
            errors: errors,
            selectFindStatus: selectFindStatus,
            saveSelectedCpid: saveSelectedCpid
        });
    }

    chargepointIDChange(cpid) {
        this.setState({ saveSelectedCpid: cpid, selectFindStatus: true }, ()=>{
            if (cpid.length === 0) {
                this.props.getData(true);
            } else {
                let errors = {};
                errors["chargePointNotExists"] = "";
                errors["selectChargepoint"] = "";
                this.setState({
                    errors: errors
                });
                this.props.getData(true, cpid);
            }
        })
    }

    ClearChargepoint(ref, event) {
        var cp = ref.current.inputNode.value;
        let cp_not_exists = this.state.errors["chargePointNotExists"];
        let cp_empty = this.state.errors["selectChargepoint"];

        if((cp_not_exists || cp_empty ) && cp){
            let errors = {};
            localStorage.removeItem("noWallboxExists");
            this.setState({
                errors: errors,
                chargePoint: "",
                selectFindStatus: false,
                saveSelectedCpid: ""
            });
            ref.current.clear();
        } else if((cp_not_exists || cp_empty) && (!cp)) {
            let errors = {};
            localStorage.removeItem("noWallboxExists");
            this.setState({
                errors: errors,
                selectFindStatus: false,
            });
        } else if(!cp_not_exists && !cp_empty && cp && this.props.activePage !== 1) {
            localStorage.removeItem("noWallboxExists");
            this.setState({
                chargePoint: "",
                selectFindStatus: false,
                saveSelectedCpid: ""
            });
            ref.current.clear();
        } else if(!cp_not_exists && !cp_empty && cp && this.props.activePage === 1) {
            localStorage.removeItem("noWallboxExists");
            this.props.getData(true, "", "Clear");
            this.setState({
                chargePoint: "",
                selectFindStatus: false,
                saveSelectedCpid: ""
            });
            ref.current.clear();
        } else if((cp_not_exists || cp_empty) && !cp && this.props.activePage !== 1) {
            let errors = {};
            localStorage.removeItem("noWallboxExists");
            this.setState({
                errors: errors,
                selectFindStatus: false,
            });
        } else if((cp_not_exists || cp_empty) && cp && this.props.activePage === 1) {
            let errors = {};
            localStorage.removeItem("noWallboxExists");
            this.setState({
                errors: errors,
                selectFindStatus: false,
            });
        } 

        // if (cp === "") {
        //     let errors = {};
        //     errors["chargePointNotExists"] = "";
        //     this.setState({
        //         errors: errors
        //     });
        // } else {
        //     let errors = {};
        //     errors["chargePointNotExists"] = "";
        //     localStorage.removeItem("noWallboxExists");
        //     this.props.getData(true, "", "Clear");
        //     this.setState({
        //         errors: errors,
        //         chargePoint: "",
        //         selectFindStatus: false,
        //         saveSelectedCpid: ""
        //     });
        //     ref.current.clear();
        // }
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }

    render() {
        const{t}=this.props
        const ref = React.createRef();

        return (
            <>
                <div className="mr-auto pl-2 ">
                    <div className="input-group">
                        <i className="fas fa-search fa-sm input-searchIcon"></i>
                        <Typeahead
                            labelKey="name"
                            className="w_80"
                            multiple={this.state.multiple}
                            options={this.state.options}
                            minLength={3}
                            placeholder={t('enter_charge_point')}
                            onKeyDown={this.handleKeyDown.bind(this, ref)}
                            onChange={this.chargepointIDChange.bind(this)}
                            controlId="partialSearch"
                            ref={ref}
                        />
                        <button type="button" className="close close_icon" id="clearInput" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}  >
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="input-group-btn">
                            <button className="input-searchBtn find-btn" onClick={this.FindCpName.bind(this, ref)} >{t('find')}</button>
                        </div>
                        <span className="pull-left error_msg w3-animate-top mt-0" id="nochargeexists"><span style={{ color: "red" }}>{this.state.errors["chargePointNotExists"]}</span></span>
                        <span className="pull-left error_msg w3-animate-top mt-0" id="selectcharge"><span style={{ color: "red" }}> {this.state.errors["selectChargepoint"]}</span></span>
                    </div>
                </div>
                <CustomModal
                    open={this.modalsession}
                    title={'Session has expired'}
                    bodyMessage={'Please login again'}
                    PrimaryButtonText={'OK'}
                    PrimaryButtonOperation={this.props.sessiontagOk}
                />
            </>
        )
    }
}

export default withTranslation()(cpPartialSearch);