import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Form } from 'react-bootstrap'
import { MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBCol} from 'mdbreact';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import CustomModal from '../common/CustomModal';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Loader from '../views/loader';

class EditOTAManagementTemplate extends React.Component {

    render() {
        const { t } = this.props;
        // let user = this.props.user;

        const data = this.props.data.map((dataArray, index) => {
            var date = "";
            var date1 = "";

            if (dataArray.created_time === null || dataArray.created_time === "") {
                date = "";
                date1 = "";
            } else {
                date = moment(dataArray.created_time).format("DD MMM YYYY");
                date1 = moment(dataArray.created_time).format("HH:mm");
            }

            return (
                <Draggable key={dataArray.id} draggableId={dataArray.id} index={index} isDragDisabled={false} >
                    {(provided, snapshot) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                        >
                            <td className='text-center '  {...provided.dragHandleProps} ><i className="fas fa-grip-vertical drag-icon mt-2"></i></td>
                            <td className='align-middle'>{dataArray.version_name}</td>
                            <td className='align-middle'>{dataArray.model}</td>
                            <td className="wrap-word">{date && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {date}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {date1} </div> </>}</td>
                            <td className='align-middle'>
                                <a href={dataArray.download_url} download> <i className="fas fa-download action-icon" title={t('download_firmware')}></i></a>
                                <a href={dataArray.releaseNotes_URL} download ><i className="fas fa-sticky-note pl-2 pr-2 action-icon" title={t('release_notes')}></i></a>
                                <i className="fas fa-pencil-alt pr-2 action-icon" title={t('edit')} onClick={this.props.openEditFirmware.bind(this, dataArray)}></i>
                                <i className="fas fa-trash-alt pr-2 action-icon" title={t('delete')} onClick={this.props.toggleConfirmDelete.bind(this, dataArray.version_name, dataArray.id)}></i>
                            </td>
                        </tr>
                    )}
                </Draggable>
            )
        })

        const addFirmware = <div className="row">
            <div className='col-md-12'>
                <div className="col-md-12" >
                    <label className="font-weight-bold">{t('model')}: {this.props.modelName}</label>
                </div>
                <div className="col-md-8 md-form mt-1">
                    <input
                        name="Version"
                        type="text"
                        id="Version"
                        className="form-control font-weight-bold"
                        value={this.props.version}
                        onChange={this.props.onChangeVersion}
                    />
                    <label htmlFor="Version" className={`${this.props.version !== "" && this.props.version !== null ? "active" : ""} ml-15 font-weight-bold`}>
                        {t('version')} *
                    </label>
                </div>
                <span className="error_msg w3-animate-top ml-3">
                    <span style={{ color: "red" }}>{this.props.errors["versionError"]}</span>
                </span>
                <MDBCol md="12">
                    <label className="mt-1" >
                        <input type="file" className="simple_file_upload" id="buildFile" onChange={this.props.fileChange} />
                        <span data-file="No file choosen" className="font-weight-bold">{t('choose_firmware_file')} *</span>
                    </label>
                    <span className="error_msg w3-animate-top mt-1">
                        <span style={{ color: "red" }}>{this.props.errors["nofileError"]}</span>
                    </span>
                </MDBCol>
                <MDBCol md="12">
                    <label className="mt-1">
                        <input type="file" className="simple_upload1" id="releaseNotes" onChange={this.props.releaseChange} />
                        <span data-file="No file choosen" className="font-weight-bold">{t('choose_release_notes')} *</span>
                    </label>
                    <span className="error_msg w3-animate-top mt-1">
                        <span style={{ color: "red" }}>{this.props.errors["releaseNoteError"]}</span>
                    </span>
                </MDBCol>
            </div>
        </div>

        const customHeader = <div className="modal-header row mx-0">
            <h4 className="modal-title fw-800 mt-1 col-md-12 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('add_firmware')}</h4>
        </div>

        const customHeader1 = <div className="modal-header row mx-0">
        <h4 className="modal-title fw-800 mt-1 col-md-12 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('edit_firmware')}</h4>
        </div>

        const editFirmware = <div className="row">
            <div className='col-md-12'>
                <div className="col-md-12" >
                    <label className="font-weight-bold">{t('model')}: {this.props.modelName}</label>
                </div>
                <div className="col-md-8 md-form mt-1">
                    <input
                        name="Version"
                        type="text"
                        id="Version"
                        className="form-control font-weight-bold"
                        value={this.props.editVersion}
                        onChange={this.props.onChangeVersion1}
                    />
                    <label htmlFor="Version" className={`${this.props.editVersion !== "" && this.props.editVersion !== null ? "active" : ""} ml-15 font-weight-bold`}>
                        {t('version')} *
                    </label>
                </div>
                <span className="error_msg w3-animate-top ml-3">
                    <span style={{ color: "red" }}>{this.props.errors["versionError"]}</span>
                </span>
                <MDBCol md="12">
                    <label className="mt-1" >
                        <input type="file" className="simple_file_upload" id="buildFile" onChange={this.props.fileChange1} />
                        <span data-file={this.props.editFile} className="font-weight-bold">{t('choose_firmware_file')} *</span>
                    </label>
                    <span className="error_msg w3-animate-top mt-1">
                        <span style={{ color: "red" }}>{this.props.errors["nofileError"]}</span>
                    </span>
                </MDBCol>
                <MDBCol md="12">
                    <label className="mt-1">
                        <input type="file" className="simple_upload1" id="releaseNotes" onChange={this.props.releaseChange1} />
                        <span data-file={this.props.editReleaseNote} className="font-weight-bold">{t('choose_release_notes')} *</span>
                    </label>
                    <span className="error_msg w3-animate-top mt-1">
                        <span style={{ color: "red" }}>{this.props.errors["releaseNoteError"]}</span>
                    </span>
                </MDBCol>
            </div>
        </div>

        const deleteContent = <div className="row">
            <div className="col-md-12">
                <div>{t('type_in')} <label className="font-weight-bold">{t('delete')}</label> {t('to_delete_build_version')} {this.props.deleteBuildVersion}</div>
                <div className="row">
                    <div className="col-md-8 md-form mt-1">
                        <input
                            name="deleteText"
                            type="text"
                            id="deleteText"
                            className="form-control"
                            value={this.props.deleteText}
                            onChange={this.props.onChangeDeleteText}
                            placeholder={t('type_text')}
                        />
                    <span className="error_msg w3-animate-top">
                        <span style={{ color: "red" }}>{this.props.errors["deleteTextError"]}</span>
                    </span>
                    </div>
                </div>
            </div>
        </div>

        return (
            <main className="content-div" >
                <p>{t('edit_builds')}
                    <div className="breadcrumb_div">
                        {t('Maintenance')} &gt; <Link to="./otaManagement">{t('ota_management')} </Link > &gt; <span className="breadcrumb_page">{t('edit_builds')}</span>
                    </div>
                </p>

                <div className="page-outerdiv">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <button type="button" className="btn_primary mt-1" onClick={this.props.backToList}>
                                <i className="fa fa-angle-left mr-2" aria-hidden="true"></i> {t('back')}
                            </button>
                        </div>
                    </div>

                    {/* active tab */}
                    <MDBNav className="mt-4 p-rel border-0 mb-2">
                        <MDBNavItem className="nav-first">
                            <MDBNavLink to="#" className={`b-75 navTabPadding ${this.props.activeItem === 1 ? "activetab" : ""}`} onClick={this.props.tabToggle.bind(this, 1)} role="tab">
                                {t('firmwares')}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem className="nav-last">
                            <MDBNavLink to="#" className={`b-75 navTabPadding ${this.props.activeItem === 2 ? "activetab" : ""}`} onClick={this.props.tabToggle.bind(this, 2)} role="tab">
                                {t('Settings')}
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav>
                    {/* active tab */}

                    <MDBTabContent activeItem={this.props.activeItem} className="pt-2 p-rel border-0 pl-0 pr-0" >
                        <MDBTabPane tabId={1} role="tabpanel">
                            <div className="row">
                                <div className="col-md-12">
                                    <button type="button" className="custom_back_button mt-2" onClick={this.props.openAddTriggerModal}>
                                        <i className="fa fa-plus"></i> <span className="add-btn">{t('add')}</span>
                                    </button>
                                    <div className="pull-right">
                                        <button type="button" className={this.props.disabledAttr === true ? "btn_primary mt-1 btn_cancel" : "btn_primary mt-1"} onClick={this.props.openConfirmOrderSeq} disabled={this.props.disabledAttr} style={{display:this.props.data.length === 0 ? "none":"block"}}>{t('update')}</button>
                                    </div>
                                </div>
                                <div className="mt-3 ml-2 content-div">
                                    {t('ota_management_desc')}
                                </div>
                                <div className="col-md-12">
                                    <Table striped hover size="sm" className="page-table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{t('version')}</th>
                                                <th>{t('model')}</th>
                                                <th>{t('release_date')}</th>
                                                <th>{t('action')}</th>
                                            </tr>
                                        </thead>
                                        {
                                            (this.props.data.length === 0) ? <td colSpan={12} className='text-center pt-5 pb-5'>{t('no_data_found')}</td> : null
                                        }
                                        <DragDropContext onDragEnd={this.props.onDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <tbody
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {data}
                                                        {provided.placeholder}
                                                    </tbody>
                                                )}
                                            </Droppable>
                                        </DragDropContext>


                                    </Table>
                                </div>
                            </div>
                        </MDBTabPane>
                        <MDBTabPane tabId={2} role="tabpanel">
                            <div className="col-md-12">
                                <Form.Check
                                    className="pull-left mr-2 mt-2 pt-1 code_clr pl-3"
                                    custom
                                    value={this.props.sequentialEnabled}
                                    checked={this.props.sequentialEnabled}
                                    id="sequentialUpdate"
                                    type="checkbox"
                                    label={t('sequential_update')}
                                    style={{ position: "relative", left: "20px" }}
                                    onChange={this.props.openConfirmTriggerSequenceUpdate}
                                />
                            </div>
                            
                        </MDBTabPane>
                    </MDBTabContent>
                </div>

                {/* Add Firmware modal by Lakshmikanth */}
                <CustomModal
                    open={this.props.addFirmware}
                    customHeader={customHeader}
                    bodyMessage={addFirmware}
                    PrimaryButtonText={<>{t('save')} <i className="fas fa-save ml-2"></i></>}
                    PrimaryButtonOperation={this.props.addBuild}
                    AdditionalButtonText={<>{t('cancel')} <i className="fas fa-times ml-2"></i></>}
                    AdditionalButtonStyle={`btn_cancel`}
                    AdditionalButtonOperation={this.props.closeAddTriggerModal}
                />

                {/* Add Firmware modal by Lakshmikanth */}
                <CustomModal
                    open={this.props.editFirmware}
                    customHeader={customHeader1}
                    bodyMessage={editFirmware}
                    PrimaryButtonText={<>{t('cancel')} <i className="fas fa-times ml-2"></i></>}
                    PrimaryButtonStyle={`btn_cancel`}
                    PrimaryButtonOperation={this.props.closeEditFirmware}
                    AdditionalButtonText={t('update')}
                    AdditionalButtonOperation={this.props.updateBuild}
                    isSecondaryEnable = {this.props.disabledAttr1}
                />

                {/* sequence update confirmation modal by Lakshmikanth */}
                <CustomModal
                    open={this.props.confirmSquentialUpdate}
                    title={t('confirmation')}
                    bodyMessage={t('sequential_update_desc')}
                    PrimaryButtonText={t('yes')}
                    PrimaryButtonOperation={this.props.updateFirmwareSequence}
                    AdditionalButtonText={t('no')}
                    AdditionalButtonOperation={this.props.openConfirmTriggerSequenceUpdate}
                />

                {/* delete modal by Lakshmikanth */}
                <CustomModal
                    open={this.props.confirmDeletebuild}
                    title={t('delete_build_version')}
                    bodyMessage={deleteContent}
                    PrimaryButtonText={<><i className="fas fa-times mr-2"></i> {t('cancel')}</>}
                    PrimaryButtonStyle={`btn_cancel`}
                    PrimaryButtonOperation={this.props.closeDeleteModal}
                    AdditionalButtonText={<><i className="fas fa-trash-alt mr-2"></i> <span>{t('delete')}</span></>}
                    AdditionalButtonStyle={`btn_secondary`}
                    AdditionalButtonOperation={this.props.deleteBuild}
                />

                {/* sequence update success modal by Lakshmikanth */}
                <CustomModal
                    open={this.props.successSquentialUpdate}
                    title={t('success')}
                    bodyMessage={t(this.props.sequenceUpdateSuccessMessage)}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.toggleSuccessSquentialUpdate}
                />

                {/* Firmware upload success modal by Lakshmikanth */}
                <CustomModal
                    open={this.props.firmwareUploadSuccess}
                    title={t('success')}
                    bodyMessage={t('new_build_added_sucessfully')}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.toggleSuccessFirmwareUpload}
                />

                {/* Firmware upload success modal by Lakshmikanth */}
                <CustomModal
                    open={this.props.firmwareUpdateSuccess}
                    title={t('success')}
                    bodyMessage={t('build_updated_successfully')}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.closeSuccessFirmwareUpdate}
                />

                {/* build Redorder success modal */}
                <CustomModal
                    open={this.props.ReOrderSuccess}
                    title={t('success')}
                    bodyMessage={t('build_order_updated_sucessfully')}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.toggleReOrderSuc}
                />

                {/* Delete alert modal */}
                <CustomModal
                    open={this.props.deleteAlert}
                    title={t(this.props.deleteStatus)}
                    bodyMessage={t(this.props.deleteStatusMessage)}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.toggleDeleteAlert}
                />

                <CustomModal
                    open={this.props.confirmOrderSeq}
                    title={t('confirmation')}
                    bodyMessage={t('build_order_sequence_confirmation')}
                    PrimaryButtonText={t('yes')}
                    PrimaryButtonOperation={this.props.saveSequence}
                    AdditionalButtonText={t('no')}
                    AdditionalButtonOperation={this.props.openConfirmOrderSeq}
                />
              

                <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>

                {this.props.loading ? (
                    <Loader loader={this.props.loading} />
                ) : (
                    ""
                )}
            </main>
        )
    }
}

export default withTranslation()(EditOTAManagementTemplate);