import React from 'react';
import { Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import * as baseUrl from '../views/constants';
import moment from 'moment';
import InvalidQRCode from './InvalidQRCode';
import { withTranslation } from 'react-i18next';

class Welcome extends React.Component {
    constructor(props) {
        super(props);
        let vocherLang;
        try {
            vocherLang = localStorage.vocherLang
          } catch (error) {
            vocherLang = '';
          }
        this.state = {
            termsOfservice: false,
            dataPrivacy: false,
            qrCodeValidate: false,
            expDate: null,
            totalEnergyLimit: "",
            remainingEnergyCredit: "",
            unlimitedEnergyAccess: false,
            unlimitedDaysAccess: false,
            qrId: "",
            message: "",
            consumedEnergy:"",
            vocherLang: vocherLang,
            Flagname:"eng"
        }
        this.handleFirstCheckbox = this.handleFirstCheckbox.bind(this)
        this.handleSecondCheckbox = this.handleSecondCheckbox.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.isCheckboxesChecked = this.isCheckboxesChecked.bind(this)
        this.ChangeLanguage = this.ChangeLanguage.bind(this)
    }


    handleFirstCheckbox(e) {
        this.setState({
            termsOfservice: e.target.checked
        })
    }
    handleSecondCheckbox(e) {
        this.setState({
            dataPrivacy: e.target.checked
        })
    }

    handleClick = () => {
        document.getElementById("checkboxs").style.border = "";
    }


    handleSubmit() {
        if (this.state.termsOfservice === false || this.state.dataPrivacy === false) {
            console.log("Please check the checkboxs..!");
            document.getElementById("checkboxs").style.border = "2px solid red";
        }
        else {
            this.getSessionDetails();
        }
    }

    isCheckboxesChecked() {
        var termsOfservice = localStorage.getItem('termsOfservice');
        var dataPrivacy = localStorage.getItem('dataPrivacy');
        if (dataPrivacy === "true" && termsOfservice === "true") {
            this.setState({
                termsOfservice: true,
                dataPrivacy: true
            })
        }
    }

    aesDecrypt(encryptedHex) {
        var aesjs = require('aes-js');
        // An example 128-bit key
        var key = [49, 50, 51, 52, 53, 54, 55, 56, 57, 49, 49, 50, 51, 52, 53, 54];

        // The initialization vector (must be 16 bytes)
        var iv = [48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48];


        var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);

        // The cipher-block chaining mode of operation maintains internal
        // state, so to decrypt a new instance must be instantiated.
        var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
        var decryptedBytes = aesCbc.decrypt(encryptedBytes);

        // Convert our bytes back into text
        var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);

        return decryptedText;
    }

    aesMethod = (master_password_n) => {
        var aesjs = require('aes-js');
        // An example 128-bit key
        var key = [49, 50, 51, 52, 53, 54, 55, 56, 57, 49, 49, 50, 51, 52, 53, 54];

        // The initialization vector (must be 16 bytes)
        var iv = [48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48];

        // Convert text to bytes (text must be a multiple of 16 bytes)
        var textBytes = aesjs.utils.utf8.toBytes(master_password_n);

        var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
        var encryptedBytes = aesCbc.encrypt(aesjs.padding.pkcs7.pad(textBytes));

        // To print or store the binary data, you may convert it to hex
        var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
        return encryptedHex;
    }

    componentDidMount() {
        const queryParams = window.location.search;
        let key = queryParams.split("code=");
        let code = key[1];
        this.getQRcodeDetail(code);
        this.isCheckboxesChecked();
        let Language = localStorage.getItem("Language");
        let Flagname = localStorage.getItem("Flagname");
        if(Language && Flagname){
            this.setState({
                vocherLang: Language,
                Flagname:Flagname
              })
        }else{
            this.props.i18n.changeLanguage('en')
        }
    }

    getQRcodeDetail(id) {
        var Apiurl = baseUrl.LoginPath + "/qrcode/charging/validate";
        let data = { "data": id };
        fetch(Apiurl, {
            method: "POST",
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "content-type": "application/json",
            }
        })
            .then((resp) => {
                if (resp.status === 401 || resp.status === 500) {
                    this.props.setPage('QRCodeDeactivated');
                    localStorage.setItem('page', 'QRCodeDeactivated');
                    localStorage.setItem('prePage', '');
                } else {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response.message === "qr_code_validated_successfully") {
                    let preId = localStorage.getItem('qrId');
                    if (preId !== response.data.id) {
                        // localStorage.clear();
                        localStorage.setItem("termsOfservice", false);
                        localStorage.setItem("dataPrivacy", false);
                    }
                    this.setState({
                        qrId: response.data.id,
                        qrCodeValidate: true,
                        expDate: response.data.expirationDate,
                        totalEnergyLimit: response.data.totalEnergyLimit,
                        remainingEnergyCredit: response.data.remainingEnergyCredit,
                        unlimitedEnergyAccess: response.data.unlimitedEnergyAccess,
                        unlimitedDaysAccess: response.data.unlimitedDaysAccess,
                        consumedEnergy:response.data.consumedEnergy
                    })
                    localStorage.setItem('qrId', response.data.id);
                    localStorage.setItem('unlimitedEnergyAccess', response.data.unlimitedEnergyAccess);
                    localStorage.setItem('unlimitedDaysAccess', response.data.unlimitedDaysAccess);
                    localStorage.setItem('expDate', response.data.expirationDate);
                    localStorage.setItem('chargedKwh', response.data.totalEnergyLimit)
                } else {
                    if (response.message === "qr_code_expired" || response.message === "qr_code_data_not_found" || response.message === "qr_code_status" || response.message === "reached_energy_limit" || response.message === "group_not_exists"  || response.message === "qr_code_is_inactive") {
                        this.setState({
                            message: response.message,
                            qrCodeValidate: false
                        })
                        // this.props.setPage('InvalidQRCode');
                        // localStorage.setItem('page', 'InvalidQRCode');
                    }
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }

    getSessionDetails() {
        let id = localStorage.getItem('qrId');
        var Apiurl = baseUrl.LoginPath + "/qrcode/charging/checktransction?qrId=" + id;
        fetch(Apiurl, {
            method: "GET",
            headers: {
                "content-type": "application/json",
            }
        })
            .then((resp) => {
                if (resp.status === 401 || resp.status === 500) {
                    this.props.setPage('QRCodeDeactivated');
                    localStorage.setItem('page', 'QRCodeDeactivated');
                    localStorage.setItem('prePage', '');
                }
                return resp.json();
            })
            .then((response) => {
                if (response.message === "no_transaction_found_for_qr_code") {
                    this.redirectTOChargerList();
                } else {
                    if (response.message === "transaction_list") {
                        if (response.data.rfid === "" || response.data.rfid === null || response.data.rfid === undefined) {
                            this.redirectTOChargerList();
                        } else {
                            localStorage.setItem('cp', response.data.cpId);
                            localStorage.setItem('rfid', response.data.rfid);
                            localStorage.setItem('transId', response.data.transactionId);
                            localStorage.setItem('page', 'chargingprocess');
                            this.props.setPage('chargingprocess');
                            if (this.state.dataPrivacy === true && this.state.termsOfservice === true) {
                                localStorage.setItem("termsOfservice", true);
                                localStorage.setItem("dataPrivacy", true);
                            }
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }

    redirectTOChargerList() {
        if (this.state.dataPrivacy === true && this.state.termsOfservice === true) {
            localStorage.setItem("termsOfservice", true);
            localStorage.setItem("dataPrivacy", true);
        }
        this.props.setPage('selectCharger');
        localStorage.setItem('page', 'selectCharger');
    }
    
    ChangeLanguage(lang,img){
        if (lang === "German") {
            this.props.i18n.changeLanguage('de')
          } else if (lang === "English") {
            this.props.i18n.changeLanguage('en')
          }
          this.setState({
            vocherLang: lang,
            Flagname:img
          })
          localStorage.setItem("Language",lang)
          localStorage.setItem("Flagname",img)
    }

    render() {
        const { t } = this.props;
        const usedEnergyValue = ((this.state.consumedEnergy ? parseFloat(this.state.consumedEnergy) : 0 )/ (this.state.totalEnergyLimit ? parseFloat(this.state.totalEnergyLimit): 0 ) ) * 100;
        let date = this.state.expDate ? moment(this.state.expDate).format("DD MMM YYYY").split(" "):"";
            date = date[1] ? this.state.expDate ? moment(this.state.expDate).format("DD MMM YYYY").replace(date[1] , t(date[1])) : "" :"";
        if (this.state.qrCodeValidate) {
            return (
                <>
                    <div className="welcome-parent bg_white">
                        <div className="container-fluid pb-3 pt-1">
                            <nav className="nav_bar pl-0 pr-0">
                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/Ampure_Logo.svg"} className="logo" alt="logo" width="105" />
                                <ul className="navbar-nav ml-auto">
                                    <li id="navbar-static-tools" className="nav-item dropdown">
                                        <span className="nav-link cursor-p" id="navbar-tools" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src={process.env.PUBLIC_URL + `/assets/img/shareqrcode/${this.state.Flagname}.svg`}  className="d-inline-block align-top logo flag_icon" alt="eng" />
                                            <i className="fas fa-caret-down fa-lg flag_arrow"></i>
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-right drop_menu  pt-1 pb-1" aria-labelledby="userDropdown">
                                            <span className="dropdown-item cursor-p" onClick={this.ChangeLanguage.bind(this,'English','eng')}>
                                                <span className="fw-500">
                                                    <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/eng.svg"}  className="d-inline-block align-top logo flag_icon country_img" alt="eng" />
                                                    {this.props.t('english')}</span>
                                            </span>
                                            <span className="dropdown-item cursor-p " onClick={this.ChangeLanguage.bind(this,'German','germany')}>
                                                <span className="fw-500">
                                                    <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/germany.svg"}  className="d-inline-block align-top logo flag_icon country_img" alt="germany" />
                                                    {this.props.t('german')}</span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div className="welcome_bg">
                            <h4 className='text-center' style={{ color: "#fff", fontWeight: "bold" }}>{t('welcome')}</h4>
                            <h4 className='text-center' style={{ color: "#fff" }}>{t('lets_get_started')}</h4>
                        </div>
                        <div className="container-fluid">
                            <div className='row justify-content-center'>

                                <div className="col-md-12 welcome-div" style={{ border: 'none' }}>
                                    <div className='d-flex flex-column pt-3'>
                                        {this.state.unlimitedEnergyAccess && this.state.unlimitedDaysAccess ?
                                            <div className='card-container enegybox_unlimited'>
                                                <div className='card-text text-center'>
                                                    <p className="fs_25_bold">
                                                        {t("Unlimited")}
                                                        <br />
                                                        {t("voucher")}
                                                    </p>
                                                </div>
                                            </div> :
                                            <div>
                                                <div className='card-container enegybox'>
                                                    <div className='card-text text-center'>                                                        
                                                        <p>
                                                            <span className="welcome-txt-bold">
                                                                {this.state.unlimitedEnergyAccess ? <> {t('Unlimited')}  <span className='unit-f'> kWh </span></> 
                                                                : <>
                                                                {this.state.consumedEnergy? parseFloat(this.state.consumedEnergy)?.toFixed(2) : 0}
                                                                <span className='unit-f'> kWh / </span>
                                                                 
                                                                {this.state.totalEnergyLimit ? parseFloat(this.state.totalEnergyLimit)?.toFixed(2) :0}
                                                                <span className='unit-f'> kWh</span>
                                                                </>}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className='card-text text-center'>
                                                        <ProgressBar now={this.state.unlimitedEnergyAccess ? "50" : isNaN(usedEnergyValue) ? "100" : usedEnergyValue} style={{ width: "86%" }} />
                                                        <span className="percenttext">
                                                            {this.state.unlimitedEnergyAccess ?
                                                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/unlimited.svg"} />
                                                                : `${parseInt(usedEnergyValue)} %`}
                                                        </span>
                                                    </div>
                                                    <div className='card-text text-center  pb-0'  style={{ paddingBottom: "0px" }}>
                                                        <p style={{ marginBottom: '0px' }}>
                                                            <span className='grey-label'>
                                                                {
                                                                    this.state.unlimitedDaysAccess
                                                                        ? t("unlimited_usage_time") 
                                                                        : this.state.expDate ?  t('available_until') + " " + date: ""
                                                                        // this.state.expDate ? new Date(this.state.expDate)?.getMonth() === 5 || new Date(this.state.expDate)?.getMonth() === 6 ? t('available_until') + " " + moment(this.state.expDate).format("DD MMMM YYYY") : t('available_until') + " " + moment(this.state.expDate).format("DD MMM YYYY") : ""
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='card-text pt-3 welcome_cardtxt'>
                                            <div className='d-inline-flex pb-2'><div><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/point_arrows.svg"} className="d-inline-block point_arrows" /></div>{t('connect_your_car_to_the_charging_station')}</div>
                                            <div className='d-inline-flex pb-2'><div><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/point_arrows.svg"} className="d-inline-block point_arrows" /></div>{t('select_your_charging_station')}</div>
                                            <div className='d-inline-flex pb-2'><div><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/point_arrows.svg"} className="d-inline-block point_arrows" /></div>{t('start_your_charging_station')}</div>
                                            <div className='d-inline-flex pb-2'><div><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/point_arrows.svg"} className="d-inline-block point_arrows" /></div>{t('use_your_voucher_until_you_reach_the_limitations')}</div>
                                        </div>

                                        <div id='checkboxs' className='mln-15' style={{ padding: "5px 0px", borderRadius: "5px" }}>
                                            <div className="form-check ml-15 mb-2">
                                                <input type="checkbox" className="form-check-input cursor-p" id="checkBox1" onChange={this.handleFirstCheckbox.bind(this)} checked={this.state.termsOfservice} />
                                                <label className="form-check-label" htmlFor="checkBox1">{t('iagree_terms_condition')} <a href={baseUrl.TERMSANDCONDITION_QR} target="_blank" className='a_tag'>{t('terms_and_conditions')}</a> {t('voucher_terms')}</label>
                                            </div>
                                            <div className="form-check ml-15">
                                                <input type="checkbox" className="form-check-input cursor-p" id="checkBox2" onChange={this.handleSecondCheckbox.bind(this)} checked={this.state.dataPrivacy} />
                                                <label className="form-check-label" htmlFor="checkBox2">{t('iagree_data_privacy')} <a href={baseUrl.DATAPRIVACYLINK_QR}  target="_blank" className='a_tag'>{t('data_privacy')}</a> {t('voucher_terms')} </label>
                                            </div>
                                        </div>
                                        <button type="button" className='btn qr-btn' onClick={this.handleSubmit}>{t('select_acharging_station')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (this.state.message && this.state.qrCodeValidate === false) {
            return (
                <InvalidQRCode message={this.state.message} > </InvalidQRCode>
            )
        } else {
            return (
                <> <p></p> </>
            )
        }
    }
}

export default withTranslation()(Welcome);
