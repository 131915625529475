import React from 'react';
import { Link } from 'react-router-dom';
import {MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import * as baseUrl from './constants';
import '../App.css';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { withTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import servicesUrl from '../common/servicesUrl';
import Loader from '../views/loader';

const history = createBrowserHistory();

class viewDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      alertRecords: '',
      alertdata: new Map(),
      alertPageSize: "10",
      alertActivePage: 1,
      alertpageNo: 1,
      dataArray: [],
      ocppLogArray: [],
      alertsArray: [],
      activeItem: "1",
      page_title: "View Details",
      model1: "false",
      model2: "false",
      errors: {},
      sucess: false,
      colour: 'sucess',
      modalsession: false,
      chargePointId: '',

      id: "",
      fields:
      {
        id: "",
        chargePointId: '',
        description: '',
        chargePointModel: '',
        fwVersion: '',
        chargePointSerialNumber: '',
        partNumber: '',
        address: {
          addressId: '',
          street: "",
          houseNumber: "",
          zipCode: "",
          city: "",
          country: "",
          createdTime: "",
          modifiedTime: "",
          locationLatitude: "",
          locationLongitude: "",
        },
        active: "",
        chargeBoxSerialNumber: "",
        iccid: "",
        meterSerialNumber: "",
        meterType: "",
        timeFilterChange: "",
        isLoading: false,
      },


        }
        this.backToList = this.backToList.bind(this);
        this.redirectToListPage = this.redirectToListPage.bind(this);
        this.tagOk1 = this.tagOk1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.tagOk2 = this.tagOk2.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.tabToggle = this.tabToggle.bind(this);
    
        this.onShowAlert=this.onShowAlert.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.getSessionLogs = this.getSessionLogs.bind(this);
        this.timeFilterChange = this.timeFilterChange.bind(this);
        this.getOcppLogs = this.getOcppLogs.bind(this);
        this.getAlertDetails = this.getAlertDetails.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.showEntriesChange=this.showEntriesChange.bind(this);
        this.alertsShowEntriesChange = this.alertsShowEntriesChange.bind(this);
        this.pageChangeForAlerts = this.pageChangeForAlerts.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.toggleView1 = this.toggleView1.bind(this);
}

toggleView(requestMessage,responseMessage){
  this.setState({displayReq:requestMessage, displayRes:responseMessage, modalView: !this.state.modalView})
  this.setState({})
  this.setState({
    
  });
}
toggleView1(){
  this.setState({
    modalView: !this.state.modalView
  }); 
}

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    this.setState({
      pageSize: entries,
      activePage: 1
    }, () => {
      this.getSessionLogs(entries, 1);
    })
  }

  alertsShowEntriesChange() {
    var entries = document.getElementById("alerts").value;

    this.setState({
      pageSize: entries,
      activePage: 1
    }, () => {
      this.getAlertDetails(entries, 1);
    })
  }


  handlePageChange(pageNumber) {
    
    if (pageNumber !== this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData === undefined) {
        this.setState({ activePage: pageNumber });
        this.getSessionLogs(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
      }
    }
  }


  pageChangeForAlerts(alertNo) {
    
    if (alertNo !== this.state.alertActivePage) {
      var data = this.state.alertdata;
      var pageData = data.get(alertNo);
      if (pageData === undefined) {
        this.setState({ alertActivePage: alertNo });
        this.getAlertDetails(this.state.alertPageSize, alertNo);
      } else {
        this.setState({ alertActivePage: alertNo });
      }
    }
  }





  async togglesession() {
    ;
    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }
  onShowAlert() {
    

    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });

  }


  redirectToListPage() {
    this.props.history.push('/chargepoint')
  }

  toggle1(event) {
    event.preventDefault();
    ;
    this.setState({
      modal1: !this.state.modal1
    });
  }

  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }

  tagOk1() {
    this.props.history.push('/chargepoint');
  }

  tagOk2() {
  }

  // displaying value in all the fields
  componentDidMount() {

    
    let id, chargePointId;
    var data = {};

    if (this.props.location.state === null) {
      data = JSON.parse(localStorage.getItem("cpview"));

      id = data.id;
      chargePointId = data.chargePointId;
    } else {
      id = this.props.location.state.id;
      chargePointId = this.props.location.state.chargePointId;

      data = {
        id: id,
        chargePointId: chargePointId
      }

      localStorage.setItem("cpview", JSON.stringify(data));
    }

    this.setState({ id: id, chargePointId: chargePointId }, () => {
      this.getSessionLogs(this.state.pageSize, this.state.activePage);

      var url = baseUrl.URLPath + servicesUrl.getChargePointDetails + "?id=" + this.state.id;

      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {
          if (resp.status === 401) {
            window.location.reload();
          }
          if (resp.status === 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response.data.address === null) {
            var fields1 = [];
            fields1.chargePointId = response.data.chargePointId
            fields1.id = response.data.id
            fields1.description = response.data.description
            fields1.description = response.data.description
            fields1.chargePointVendor = response.data.chargePointVendor
            fields1.chargePointModel = response.data.chargePointModel
            fields1.address = {
              addressId: '',
              street: "",
              houseNumber: "",
              zipCode: "",
              city: "",
              country: "",
              createdTime: "",
              modifiedTime: "",
              locationLatitude: "",
              locationLongitude: "",
            }
            this.setState({ fields: fields1 })
          } else {
            this.setState({ fields: response.data }, () => {
              if (this.state.fields.address.country !== null && this.state.fields.address.country !== "") {
                let fields = this.state.fields;
                fields.address.country = "country";
                this.setState({ fields });
              }
            })
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    })
  }

  // tab view click
  tabToggle(tab, event) {
    
    var data = {
      id: this.state.id,
      chargePointId: this.state.chargePointId
    }

    this.props.location.state = data;
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab

      });
      if (tab === "1") {
        localStorage.setItem("tab1Selected", "tab1")
        this.getSessionLogs(this.state.pageSize, this.state.activePage);

      }
      if (tab === "2") {

        this.getOcppLogs();

      } if (tab === "3") {
        this.getAlertDetails(this.state.alertPageSize, this.state.alertActivePage);

      }


    }

  };



  // back button click
  backToList() {
    this.props.history.push('/csrLanding');
  }



  // api to get session details
  getSessionLogs(pageSize, pageNo) {
    this.setState({ isLoading: true });
    let defaultTime = this.state.timeFilterChange;
    if (defaultTime === undefined) {
      defaultTime = "1week";
    }
    let chargePointId = localStorage.getItem("getCpid");

    this.setState({ timeFilterChange: defaultTime }, () => {
      var url = baseUrl.URLPath + "customerservice/sessions?chargePointId=" + chargePointId + "&fromTime=" + this.state.timeFilterChange + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status === 401) {
            window.location.reload();
          }
          if (resp.status === 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response !== undefined) {
            this.setState({
              dataArray: response.list,
              noOfRecords: response.count.count,
              isLoading: false
            })
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    })
  }


  // method to call onChange Time filter
  timeFilterChange() {
    var id = document.getElementById("timeFilter").value;
    let fields = this.state.fields;
    fields["timeFilterChange"] = id;
    this.setState({ timeFilterChange: id }, () => {
      this.getSessionLogs(this.state.pageSize, this.state.activePage);
      this.getOcppLogs();
      this.getAlertDetails(this.state.alertPageSize, this.state.alertActivePage);


    });
  }



  // api to get ocpp logs details
  getOcppLogs() {
    let defaultTime = this.state.timeFilterChange;
    if (defaultTime === undefined) {
      defaultTime = "1week";
    }

    let chargePointId = localStorage.getItem("getCpid");

    this.setState({ timeFilterChange: defaultTime, isLoading: true }, () => {
      var url = baseUrl.URLPath + servicesUrl.getCustomerserviceCPLogs + "?cpid=" + chargePointId + "&fromTime=" + this.state.timeFilterChange;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status === 401) {
            window.location.reload();
          }
          if (resp.status === 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response !== undefined) {
            this.setState({
              ocppLogArray: response,
              isLoading: false
            })
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    })
  }


  // api to get Alerts details
  getAlertDetails(alertPageSize, alertpageNo) {

    let timeFilterChange = this.state.timeFilterChange;
    if (timeFilterChange === undefined) {
      timeFilterChange = "1week";
    }
    let chargePointId = localStorage.getItem("getCpid");

    this.setState({ timeFilterChange: timeFilterChange, isLoading: true }, ()=>{

      var url = baseUrl.URLPath + "alerts/getbycp?cpid=" + chargePointId + "&fromTime=" + this.state.timeFilterChange + "&pageNo=" + alertpageNo + "&pageSize=" + alertPageSize;

      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status === 401) {
            window.location.reload();
          }
          if (resp.status === 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response !== undefined) {
            this.setState({
              alertsArray: response.list,
              alertRecords: response.count.count,
              isLoading: false
            })
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    })
  }

  //sorting functionality for Transactions Table data
  ascendingOrderForTransactions() {
    this.setState({ dataArray: this.state.dataArray.reverse() })
  }

  //sorting functionality for ocpp Logs Table data
  ascendingOrderForOcppLogs() {
    this.setState({ ocppLogArray: this.state.ocppLogArray.reverse() })
  }

  //sorting functionality for alertsArray Table data
  ascendingOrderForAlertsArray() {
    this.setState({ alertsArray: this.state.alertsArray.reverse() })
  }
  render() {
 const{t}=this.props;
localStorage.setItem("saveDetails", true);

    const transactions = this.state.dataArray.map((dataArray, index) => {
      var startTime = "";
      var startTime1 = "";
      var stopTime = "";
      var stopTime1 = "";
      var chargingDuration = "";
      var chargingSessionDuration = "";
      var chargedEnergy = "";

      if (dataArray.startTime === null || dataArray.startTime === "") {
        startTime = "";
        startTime1 = "";
      } else {
        startTime = moment(dataArray.startTime).format("DD MMM YYYY");
        startTime1 = moment(dataArray.startTime).format("HH:mm");
      }

      if (dataArray.stopTime === null || dataArray.stopTime === "") {
        stopTime = "";
        stopTime1 = "";
      } else {
        stopTime = moment(dataArray.stopTime).format("DD MMM YYYY");
        stopTime = moment(dataArray.stopTime).format("HH:mm");
      }

      if (dataArray.chargingDuration === "null") {
        chargingDuration = "";
      } else {
        chargingDuration = dataArray.chargingDuration;
      }

      if (dataArray.chargingSessionDuration === "null") {
        chargingSessionDuration = "";
      } else {
        chargingSessionDuration = dataArray.chargingSessionDuration;
      }

      if (isNaN(dataArray.chargedEnergy)) {
        chargedEnergy = "";
      } else {
        chargedEnergy = dataArray.chargedEnergy;
      }

      var status_img = "";
      var status_text = "";

      if(dataArray.chargingSessionState==="activeCharging"){
         status_img = "activeCharging"; 
         status_text = "Active Charging";
      } else if(dataArray.chargingSessionState==="Finished"){
        status_img = "Finished"; 
        status_text = "Finished";
      } else if(dataArray.chargingSessionState==="activeNotCharging"){
        status_img = "activeNotCharging";
        status_text = "Active Not Charging";
      } else if(dataArray.chargingSessionState==="prepairing"){
        status_img = "prepairing";
        status_text = "Prepairing";
      } else if(dataArray.chargingSessionState === "activePreparing"){
        status_img = "prepairing";
        status_text = "Active Preparing";
      } else if(dataArray.chargingSessionState === "SuspendedEV"){
        status_img = "activeNotCharging";
        status_text = "Active Not Charging";
      } else if(dataArray.chargingSessionState === "Halted"){
        status_img = "Halted";
        status_text = "Halted";
      }

      return (
        <tr>
          <td>{dataArray.chargingSesionId}</td>
          <td>{dataArray.idTag}</td>
          <td className="wrap-word">{startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>}</td>
          <td className="wrap-word">{stopTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {stopTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {stopTime1} </div> </>}</td>
          <td>{chargingDuration}</td>
          <td>{chargingSessionDuration}</td>
          <td className="text-right">{chargedEnergy}</td>
          <td className="text-right">{dataArray.maximumChargingPower}</td>
          <td className = "wrap-word  p-rel ">
            <div class="click-to-top">
              <img src={process.env.PUBLIC_URL + "/assets/img/cs-status/"+status_img+".png"} style={{"height":"16px"}} alt="" />
              <span>{status_text}</span>
            </div>
          </td>

        </tr>
      )
    });

    let active = "";

    if (this.state.fields.active === "0" || this.state.fields.active === "Inactive") {
      active = "Inactive";
    } else {
      if (this.state.fields.active !== "") {
        active = "Active";
      }
    }


    var page_title1 = "";
    if (this.props.location.state === null) {

      var data1 = JSON.parse(localStorage.getItem("cpview"));
      page_title1 = data1.chargePointId;
    } else {

      page_title1 = this.props.location.state.chargePointId;
      localStorage.setItem("getCpid", page_title1)
    }





    const ocppLogs = this.state.ocppLogArray.map((ocppLogArray, index) => {
      var startDate = "";
      var startDate1 = "";
      if (ocppLogArray.startDate === null) {
        startDate = "";
        startDate1 = "";
      } else {
        startDate = moment(ocppLogArray.startDate).format("DD MMM YYYY");
        startDate1 = moment(ocppLogArray.startDate).format("HH:mm");
      }

      return (
        <tr>

          <td className="wrap-word">{startDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startDate1} </div> </>}</td>
          <td>{ocppLogArray.message}</td>
          <td>{ocppLogArray.source}</td>
          <td className=""><i class="fas fa-eye action-icon cursor_defult"  title="View"  onClick={this.toggleView.bind(this,ocppLogArray.requestMessage,ocppLogArray.responseMessage)} ></i></td>

         
          </tr>
        )
    });

    const alertsLogs = this.state.alertsArray.map((alertsArray, index) => {
      var createdDate = "";
      var createdDate1 = "";
      if (alertsArray.createdDate === null) {
        createdDate = "";
        createdDate1 = "";
      } else {
        createdDate = moment(alertsArray.createdDate).format("DD MMM YYYY");
        createdDate1 = moment(alertsArray.createdDate).format("HH:mm");
      }

      return (
        <tr>

          <td className="wrap-word">{createdDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdDate1} </div> </>}</td>
          <td>{alertsArray.message}</td>
          <td>{alertsArray.source}</td>
          <td>{alertsArray.alertLavel}</td>
          <td>{alertsArray.category}</td>

          {/* <td></td> */}

        </tr>
      )
    });

    return (
      <>
        <main class="content-div" >
          <p>{page_title1} ({active})
              <div className="breadcrumb_div">
              <Link to="/csrLanding" >{t('CSR')}</Link> &gt; <span className="breadcrumb_page">{t('details')}</span>
            </div>
          </p>

          <div className="page-outerdiv">
            <div className="pull-right">
              <button class="refresh-btn" type="button" data-test="button" onClick={this.backToList} ><i className="fas fa-angle-left mr-2"></i> {t('back')}
          </button></div>

            {/* end breadCrumb */}
            <form
              className='needs-validation'
              onSubmit={this.toggle1}
              noValidate
            >
              <MDBNav className="mt-2 p-rel border-0">

                <MDBNavItem className="nav-first">
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "1" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "1")} role="tab" >
                    {t('sessions')}
            </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem className="">
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "2" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "2")} role="tab" >
                    {t('ocpp_logs')}
            </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem className="nav-last">
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "3" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "3")} role="tab" >
                    {t('alerts')}
            </MDBNavLink>
                </MDBNavItem>
                {/* timefilter */}
                <div class="time_filter">
                  <label className="pull-left mr-3 mt-2 pt-1">{t('select_duration')}</label>
                  <select className="browser-default custom-select select_height  timeindent" id="timeFilter" onChange={this.timeFilterChange} >
                    <option value="1week">{t('last_1week')}</option>
                    {/* <option value="">Select Time</option> */}
                    <option value="2week">{t('last_2week')}</option>
                    <option value="3week">{t('last_3week')}</option>
                    <option value="1month">{t('last_1month')}</option>
                    <option value="2month">{t('last_2month')}</option>
                  </select>
                </div>

              </MDBNav>


              <MDBTabContent activeItem={this.state.activeItem} className="pt-2 p-rel border-0 pl-0 pr-0" >
                {/* details */}
                <MDBTabPane tabId="1" role="tabpanel">

                  <div id="transactions">
                    <div className="row ">
                      <div className="col-md-12 mt-2">
                        <div className="pull-right">
                          <label className="pull-left sub-text">{t('show_entries')}</label>
                          <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                            <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                              <option>10</option>
                              <option>20</option>
                              <option>30</option>
                              <option>40</option>
                              <option>50</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-0"><div class="col-md-12">
                      <table class="table page-table table-sm table-striped table-hover">
                        <thead>
                          <tr>
                            <th>{t('chargingSesionId')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrderForTransactions.bind(this)}></i></th>
                            <th>{t('id_tag_label')}</th>
                            <th>{t('startTime')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrderForTransactions.bind(this)}></i></th>
                            <th>{t('stopTime')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrderForTransactions.bind(this)}></i></th>
                            <th>{t('charging_duration_label')}</th>
                            <th>{t('chargingSessionDuration_label')}</th>
                            <th>{t('chargedEnergy')}</th>
                            <th>{t('max_power')}</th>
                            <th>{t('chargingSessionState')}</th>

                          </tr>

                        </thead>
                        <tbody>
                          {transactions}
                        </tbody></table></div>
                      <div className="col-md-12 mt-2 mb-4 text-right">
                        <Pagination
                          hideDisabled
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.pageSize}
                          totalItemsCount={this.state.noOfRecords}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div><div class="col-md-12 mt-4"></div></div>
                  </div>
                </MDBTabPane>

                {/* end sessions */}

                {/* ocpplogs */}
                <MDBTabPane tabId="2" role="tabpanel">

                  <div id="">
                    <div class="row  "><div class="col-md-12">
                      <table class="table page-table table-sm table-striped table-hover">
                        <thead>
                          <tr>
                            <th>{t('date_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrderForOcppLogs.bind(this)}></i></th>
                            <th>{t('message')}</th>
                            <th>{t('sent_by')}</th>
                            <th>{t('view')}</th>
                          </tr>

                        </thead>
                        <tbody>
                          {ocppLogs}
                        </tbody></table></div><div class="col-md-12 mt-4"></div></div>
                  </div>

                </MDBTabPane>
                {/* end ocpplogs */}

                {/*  alerts */}
                <MDBTabPane tabId="3" role="tabpanel">
                  <div id="">
                    <div className="row  mt-2">
                      <div className="col-md-12 mt-2">
                        <div className="pull-right">
                          <label className="pull-left sub-text">{t('show_entries')}</label>
                          <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                            <Form.Control as="select" className="showentry_sel custom_selectBox" id="alerts" onChange={this.alertsShowEntriesChange}>
                              <option>10</option>
                              <option>20</option>
                              <option>30</option>
                              <option>40</option>
                              <option>50</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                      </div>
                    </div>      <div class="row mt-0"><div class="col-md-12">
                      <table class="table page-table table-sm table-striped table-hover">
                        <thead>
                          <tr>
                            <th>{t('date_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrderForAlertsArray.bind(this)}></i></th>
                            <th>{t('message')}</th>
                            <th>{t('sent_by')}</th>
                            <th>{t('alert_level')}</th>
                            <th>+{t('category')}</th>
                            {/* <th></th> */}
                            {/* <th>Info</th>
                    <th>Action Suggested</th> */}


                          </tr>

                        </thead>
                        <tbody>
                          {alertsLogs}
                        </tbody></table></div>
                      <div className="col-md-12 mt-2 mb-4 text-right">
                        <Pagination
                          hideDisabled
                          activePage={this.state.alertActivePage}
                          itemsCountPerPage={this.state.alertPageSize}
                          totalItemsCount={this.state.alertRecords}
                          onChange={this.pageChangeForAlerts.bind(this)}
                        />
                      </div>
                      <div class="col-md-12 mt-4"></div></div>
                  </div>
                </MDBTabPane>
                {/*  end alerts */}


              </MDBTabContent>
            </form>
          </div>
          {/* popup for session expire */}

          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <button className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
      {/* <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn> */}
      </MDBModalFooter>
      </MDBModal>
         {/* Popup for  View */}
         <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size="lg" className="model_top">
        <MDBModalHeader toggle={this.toggleView}>{t('view')}</MDBModalHeader>
        <MDBModalBody>
        <MDBRow className="mb-4 mt-3" >
       
       <MDBCol sm="12">
       <div className="view-page-row view-bg-row" >
            <label className="">
              {t('request_message')}
            </label>
            <label className="wrap-word">
              { this.state.displayReq}
            
            </label>
          </div>
          </MDBCol>
          </MDBRow>
          <MDBRow className="mb-4 mt-3" >

          <MDBCol sm="">
       <div className="view-page-row view-bg-row">
            <label className="w-40">
               {t('response_message')}
            </label>
            <label className="wrap-word">
              
            { this.state.displayRes}
            </label>               
          </div>
          </MDBCol>
         </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          {/* <MDBBtn color="danger" onClick={this.toggleView1}>Close</MDBBtn> */}
          <button className="btn_primary" onClick={this.toggleView1}>{t('Close')}</button>
          {/* <MDBBtn color="primary">Save</MDBBtn> */}
          {/* onClick={this.addBuildtype} */}
        </MDBModalFooter>
      </MDBModal>

      {this.state.isLoading ? (
        <Loader loader={this.state.isLoading} />
      ) : ("")}
    </main>

    </>
  );
}
}


export default withTranslation()(viewDetails);