import React from 'react';
import {MDBRow,MDBCol, MDBInput,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import {Link} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
//API imports 
import * as baseUrl from './constants'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import {getSecurityHeaders} from '../common/HttpRequestClass'

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

class addstatusnotificationlist extends React.Component{  
    constructor (props) {
        super(props);
        this.state = {
            fields:{ category: '', type: '', model:'', errorCode: '', info: '', description:'', pushId:''},
            models:[],
            notificationType:[],
            sucess:{},
            errors:{},
            disabled:"true",
            id:"",
            activeItem:"1",
            model1:"false",
            model2:"false",
            disabledAttr:true,
            modalsession:false,
            categoryList:[],
            errorNotification:''
        }
        this.handleSubmit = this.handleSubmit.bind(this)
      
        this.getCategoryList=this.getCategoryList.bind(this);
        this.redirectToListPage = this.redirectToListPage.bind(this)
        
        this.toggle1 = this.toggle1.bind(this)
        
        
    
       this.toggle2 = this.toggle2.bind(this)
     
       this.CloseSuccessPopup = this.CloseSuccessPopup.bind(this);
       this.togglesession = this.togglesession.bind(this)
       
}


async togglesession(){
  this.setState({
  modalsession: !this.state.modalsession
  });
}
componentDidMount(){
  this.getModels();
  this.getNotificationType();
  this.getCategoryList();
}

sessiontagOk(){
  // this.props.history.push('/');
  history.push('/');
  window.location.reload();
}
toggle1 = () => {
  this.setState({
  modal1: !this.state.modal1
  });
}

CloseSuccessPopup(){

  this.props.history.push('/statusnotificationlist');
}
toggle2 = () => {
  this.setState({
    modal2: !this.state.modal2
  });
}

async handleSubmit(event){
  let errors = {};
  event.preventDefault();
  var category= this.state.fields['category'];
  var type= this.state.fields['type'];
  var model= this.state.fields['model'];
  var errorCode= this.state.fields['errorCode'];
  var info= this.state.fields['info'];
  var description=this.state.fields['description'];
  var pushid=this.state.fields['pushId'];
 
  if(category.trim()===""){
    errors["category"] = this.props.t('enter_a_category');
  }
  if(type.trim()===""){
    errors["type"] = this.props.t('select_type');
  }
  if(info.trim()===""){
    errors["info"] = this.props.t('enter_info');
  }
  if(description.trim()===""){
    errors["description"] = this.props.t('enter_description');
  }
  if(pushid.trim()===""){
    errors["pushId"] = this.props.t('enter_push_id');
  }
  if(model.trim()===""){
    errors["model"] = this.props.t('please_select_model');
  }
  if(category === "FW Update" || category === "Guest User" || category === "General"){
    if(category.trim()!=="" && type.trim()!=="" && model.trim()!=="" && info.trim()!=="" && description.trim()!=="" && pushid.trim!==""){ 
      let url = baseUrl.URLPath + "pushnotifications";
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      let payload = {"pushId":this.state.fields.pushId.trim(), "category": this.state.fields.category.trim(), "type": this.state.fields.type.trim(), "model": this.state.fields.model.trim(), "errorCode": this.state.fields.errorCode, "info": this.state.fields.info.trim(),"description": this.state.fields.description.trim()}; 
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if(response!==undefined){
        if(response.status === 409){
          this.setState({
            errorNotification: this.props.t(response.message)
          });
          this.toggle2();
        }else{
          this.toggle1();
        }
      }
      }
  }else{
    if(errorCode.trim()===""){
      errors["errorCode"] = this.props.t('error_code_validation');
    }
    if(category.trim()!=="" && type.trim()!=="" && model.trim()!=="" && errorCode.trim()!=="" && info.trim()!=="" && description.trim()!=="" && pushid.trim!==""){ 
      let url = baseUrl.URLPath + "pushnotifications";
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      let payload = {"pushId":this.state.fields.pushId.trim(), "category": this.state.fields.category.trim(), "type": this.state.fields.type.trim(), "model": this.state.fields.model.trim(), "errorCode": this.state.fields.errorCode.trim(), "info": this.state.fields.info.trim(),"description": this.state.fields.description.trim()}; 
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if(response!==undefined){
        if(response.status === 409){
          this.setState({
            errorNotification: this.props.t(response.message)
          });
          this.toggle2();
        }else{
          this.toggle1();
        }
      }
        }
  }
  
      this.setState({errors:errors});
  
  
}
redirectToListPage(){
  this.props.history.push('/Ocpptags')
}
  changeHandler(field, e) {
    let fields = this.state.fields;
    var errors = this.state.errors;
    if (field === "category") {
      errors[field] = "";
      let target_value = e.target.value;

      if (target_value === "FW Update" || target_value === "Guest User" || target_value === "General") {
        fields.model = "All products";
      } else {
        fields.model = "";
      }

      if (this.state.fields.category === target_value) {

      } else {
        fields["description"] = "";
        fields["errorCode"] = "";
        fields["info"] = "";
        fields["type"] = "";
        fields["category"] = target_value;
      }

      this.setState({ fields: fields, errors: errors });
    } else if (field === "pushId") {
      if (e.target.validity.valid) {
        fields[field] = e.target.value;

        errors[field] = "";
        this.setState({ fields: fields, errors: errors });
      }
    } else {
      fields[field] = e.target.value;
      errors[field] = "";
      this.setState({ fields: fields, errors: errors });
    }
  }

async getModels(){
  let url = baseUrl.URLPath + "cpinventory/evmodel/";
  let type = ApiMethodTypes.GET;
  let headers = getSecurityHeaders();
  let payload = {};
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if(response !== undefined){
    this.setState({models: response});
  }
}

async getNotificationType(){
  let url = baseUrl.URLPath + "pushnotifications/search/type";
  let type = ApiMethodTypes.GET;
  let headers = getSecurityHeaders();
  let payload = {};
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if(response !== undefined){
    this.setState({notificationType: response});
  }
}

async getCategoryList(){
  let url = baseUrl.URLPath + "pushnotifications/search/category";
  let type = ApiMethodTypes.GET;
  let headers = getSecurityHeaders();
  let payload = {};
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if (response !== undefined) {
    this.setState({categoryList: response});
}
}

render() {
  const{t}=this.props
  let categories = this.state.categoryList.map((category, index) => {
    return (
      <option value={this.state.categoryList[index]}>{this.props.t(this.state.categoryList[index])}</option>
    );
  });

  let models = this.state.models.map((model, index) => {
    if (this.state.fields.category === "Received Statusnotification from charger") {
      return (
        <option value={this.state.models[index]}>{this.state.models[index]}</option>
      );
    } else {
      return <></>
    }
  });

  if (this.state.fields.category !== "Received Statusnotification from charger") {
    models = <option value="All products">{t('all_products')}</option>;
  }

  let notificationType = this.state.notificationType.map((type, index) => {
    if (this.state.notificationType[index] !== null) {
        return (
          <option value={this.state.notificationType[index]}>{t(this.state.notificationType[index])}</option>
        );
    } else {
      return <></>
    }
});

  return (
    <>
  <main class="content-div" >
  <p>{t('statusnotificationlist')}
              <div className="breadcrumb_div">
              {t('Settings')} &gt; <Link to="/statusnotificationlist" > <span className="">{t('statusnotificationlist')}</span></Link>  &gt;<span className="breadcrumb_page">{t('add')}</span>
              </div>
          </p>
       
        <div className="page-outerdiv">
     
     
        <MDBRow className="mb-4  mt-3">
            <MDBCol sm="12">
            <div class="alert alert-success alert-dismissible mt-2" id="suc_msg" style={{display:"none"}} >
          
         </div>

               <div>
        <form
          className='needs-validation'
       
          noValidate
        >
          <MDBRow className="viewInput">
              <MDBCol md="4">
                <MDBInput
                  name="pushId"
                  value={this.state.fields["pushId"]}
                  onChange={this.changeHandler.bind(this, "pushId")}
                  type="text"
                  id="pushId"
                  label={t('push_id')+"*"}
                  pattern="[0-9]*"
                />
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["pushId"]}</span></span>
              </MDBCol>
              <MDBCol md="4">
                <div className="w_f">
                  <select className="browser-default custom-select select_height " id="category" onChange={this.changeHandler.bind(this, "category")} value={this.state.fields.category}>
                    <option value="">--{t('select_category')}*--</option>
                    {categories}
                  </select>
                </div>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["category"]}</span></span>
              </MDBCol>

             <MDBCol md="4">
             <div className="w_f">
                 {/* Charging Profile */}
               
                  <select className="browser-default custom-select select_height " id="type_status" onChange={this.changeHandler.bind(this,"type")} value={this.state.fields.type}>
      <option value="">--{t('select_notificationtype')}*--</option>
      {notificationType}

       </select>
                </div>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["type"]}</span></span>
            </MDBCol>
     
            <MDBCol md="4 mt-2">
            <div className="w_f">
            <select className="browser-default custom-select select_height " id="con_status" onChange={this.changeHandler.bind(this,"model")} value={this.state.fields.model}>
   <option value="">--{t(`select_model`)}*--</option>
   {models}
  
    </select>
                </div>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["model"]}</span></span>
            </MDBCol>

            <MDBCol md="4 mt-2">
                <MDBInput
                  // disabled={this.state.disabled}
                  name="errorCode"
                  value={this.state.fields["errorCode"]}
                  onChange={this.changeHandler.bind(this, "errorCode")}
                  type="text"
                  id="errorCode"
                  label={`Error Code${(this.state.fields.category==="FW Update" || this.state.fields.category==="Guest User" || this.state.fields.category==="General")? "":"*"}`}
                >
          

           
              </MDBInput>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["errorCode"]}</span></span>
            </MDBCol>
            <MDBCol md="4 mt-2">
                <MDBInput
                  // disabled={this.state.disabled}
                  name="info"
                  value={this.state.fields["info"]}
                  onChange={this.changeHandler.bind(this, "info")}
                  type="text"
                  id="info"
                  label={t('Information')+"*"}
                >
          

           
              </MDBInput>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["info"]}</span></span>
            </MDBCol>
            <MDBCol md="4 mt-2">
                <MDBInput
                  // disabled={this.state.disabled}
                  name="description"
                  value={this.state.fields["description"]}
                  onChange={this.changeHandler.bind(this, "description")}
                  type="text"
                  id="description"
                  label={t('description_for_customer')+"*"}
                >
              </MDBInput>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["description"]}</span></span>
            </MDBCol>
            <MDBCol md="12" className="text-right">
            {/* <MDBBtn color='primary'  onClick={this.toggle1} className="mt-5 mr-3 v-hiiden" id="saveBtn" >
              Save
            </MDBBtn> */}
            <button className="mt-5 mr-3 btn_primary"  onClick={this.handleSubmit}  id="saveBtn" type="button" data-test="button">
            {t('save')}<i class="fas fa-save ml-2"></i>
            </button>
            <Link to="/statusnotificationlist">
            <button className="mt-5 btn_cancel" type="button" data-test="button">
            {t('cancel')} <i class="fas fa-times ml-2"></i>
            </button>
            </Link>
            </MDBCol>  
              
          </MDBRow>
        </form>
      </div>
         </MDBCol>
          
        </MDBRow>
        </div>
        <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
<MDBModalHeader toggle={this.toggle1}>{t('success')}</MDBModalHeader>
<MDBModalBody>
 {t('status_notification_added_successfully')}
</MDBModalBody>
<MDBModalFooter>

<button type="button" data-test="button" className="btn_primary" onClick={this.CloseSuccessPopup} >{t('ok')}</button>
</MDBModalFooter>
</MDBModal>


<MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
<MDBModalHeader toggle={this.toggle2}>{t('Error')}</MDBModalHeader>
<MDBModalBody>
 {this.state.errorNotification}
</MDBModalBody>
<MDBModalFooter>
{/* <MDBBtn color="danger" onClick={this.toggle2}>Close</MDBBtn> */}
<button type="button" data-test="button" className="btn_primary" onClick={this.toggle2}>{t('ok')}</button>
</MDBModalFooter>
</MDBModal>


{/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
      <MDBModalBody>
       {t('please_login_again')}
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
      </MDBModalFooter>
      </MDBModal>
    </main>

    </>
  );
}
}

export default withTranslation()(addstatusnotificationlist);