import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { Form, Table } from 'react-bootstrap'
import $ from "jquery";
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { hasPermission } from './auth';
import { withTranslation } from 'react-i18next';
import './cpDashboard.css';
import Loader from '../views/loader';

//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders } from '../common/HttpRequestClass'
const history = createBrowserHistory();


class organizationList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectFindStatus: false,
      fields: { organisation: '' },
      getOrgList: [],
      activePage: 1,
      active: "active",
      activeArray: [],
      checked: false,
      activeDeactiveArray: '',
      list: [],
      cpid1: '',
      dataArray: [
      ],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      page_title: "Organization",
      modalsession: false,
      errors: {},
      user: {
        permissions: [

        ]
      },
      loading: false

    }
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.organizationChange = this.organizationChange.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.addNew = this.addNew.bind(this);
    this.activeorgnization = this.activeorgnization.bind(this);
    this.redirectToListPage = this.redirectToListPage.bind(this);
    this.tagOk1 = this.tagOk1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.tagOk2 = this.tagOk2.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.tagOk3 = this.tagOk3.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.Focus = this.Focus.bind(this);
    this.keyPress = this.keyPress.bind(this);




  }
  handleClick() {
    let errors = this.state.errors;
    let fields = this.state.fields;
    fields.organisation = '';
    errors["entercharge"] = ""
    $("#notexists").text("");

    this.setState({
      fields, errors, pageSize: 10, activePage: 1
    }, () => {
      this.getOrganizationList(this.state.pageSize, this.state.activePage);
    });
  }
  Focus() {


    //   let fields = this.state.fields;
    //   fields.organisation='';

    //   this.setState({
    // fields
    //   });
    //   this.getOrganizationList(this.state.pageSize,this.state.activePage);



  }
  changeHandler(field, e) {
    let errors = this.state.errors;
    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.chargePointid = document.getElementById('organisation')

    errors["entercharge"] = "";
    this.setState({ fields, errors });
    $("#notexists").text("");
  }


  //change function for chargepoint status filter
  onChangeChargepoint() {
    let errors = this.state.errors;
    var chargePoint = document.getElementById("organisation").value;

    $("input[type='checkbox']").prop("checked", false);
    this.setState({ organisation: chargePoint, selectFindStatus: true, activeArray: "" }, () => {
      this.getOrganizationList(this.state.pageSize, this.state.activePage);
    });

    if (this.state.fields.organisation === "") {
      errors["entercharge"] = this.props.t('enter_org')
      $("#notexists").text("");
      this.setState({ errors })
    } else {
      errors["entercharge"] = ""
      this.setState({ errors }, ()=>{
        this.getOrganizationList(this.state.pageSize, this.state.activePage, "click");
      })
    }
  }
  keyPress(e) {
    let errors = this.state.errors;
    let fields = this.state.fields;
    var key = e.key;

    if (e.keyCode === 13) {
      $("input[type='checkbox']").prop("checked", false);
      this.setState({ chargePointId: e.target.value, activeArray: "", selectFindStatus: true }, ()=>{
        this.getOrganizationList(10, 1);
      });

      if (this.state.fields.organisation === "") {
        errors["entercharge"] = this.props.t('enter_org')
        $("#notexists").text("");
        this.setState({ errors })
      } else {
        errors["entercharge"] = "";
        this.setState({ errors }, ()=>{
          this.getOrganizationList(this.state.pageSize, this.state.activePage, "enter");
        })
      }
    }

    if (key === "Backspace" || key === "Delete") {
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno === "") {
        fields.organisation = sno;
        this.setState({ fields }, ()=>{
          this.getOrganizationList(10, 1);
        })
      }
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }

  organizationChange() {
    var x = document.getElementById("organisation").value;
    let fields = this.state.fields;
    fields["organisation"] = x;
    this.setState({ organisation: x, activeArray: "", selectFindStatus: true }, () => {
      this.getOrganizationList(this.state.pageSize, this.state.activePage);
    });
  }

  async togglesession() {

    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }


  handlePageChange(pageNumber) {

    if (pageNumber !== this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData === undefined) {
        this.setState({ activePage: pageNumber });
        this.getOrganizationList(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ dataArray: pageData })
      }
    }
  }

  redirectToListPage() {
    this.props.history.push('/chargepoint')
  }


  toggle1() {
    this.setState({
      modal1: !this.state.modal1
    });
  }
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }
  toggle3 = () => {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  tagOk1() {
    window.location.reload();
  }
  tagOk2() {
    window.location.reload();
  }
  tagOk3() {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    
    this.setState({
      pageSize: entries,
      activePage: 1
    }, ()=>{
      this.getOrganizationList(entries, 1);
    });
  }

  async getOrganizationList(pageSize, pageNo, action) {
    this.setState({ loading: true })
    var url = "";
    if (this.state.selectFindStatus === true) {
      url = baseUrl.URLPath + servicesUrl.getOrganizationAdminList + "?pageSize=" + pageSize + "&pageNo=" + 1 + "&orgName=" + this.state.fields.organisation;
    } else {
      url = baseUrl.URLPath + servicesUrl.getOrganizationAdminList + "?pageSize=" + pageSize + "&pageNo=" + pageNo + "&orgName=" + this.state.fields.organisation;
    }

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      if (response.count.count === 0) {
        this.setState({ loading: false })
        if (action === "click" || action === "enter") {
          $("#notexists").text(this.props.t('organisation_not_exists'));
        }
      } else {
        this.setState({
          dataArray: response.organizationList,
          noOfRecords: response.count.count,
          loading: false
        })
        if (action === "click" || action === "enter") {
          $("#notexists").text(" ");
        }
      }
    }
  }

  async adminAccount() {
    var url = baseUrl.URLPath + servicesUrl.getOrganizationsList;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      this.setState({ getOrgList: response.organisationDTOS })
    }
  }

  componentDidMount() {
    let user = this.state.user;
    user.permissions = localStorage.getItem("roleAccess");

    this.setState({ user }, ()=>{
      this.adminAccount();
      this.getOrganizationList(this.state.pageSize, this.state.activePage);
    })
  }

  // calling function when click on checkbox
  handleChange(id, identifier, event) {
    var checkedValue = document.getElementById(id).checked;
    let list = this.state.list;

    if (checkedValue === true) {
      list[0] = id;
      this.setState({
        activeArray: identifier, list
      });
    }

    if (checkedValue === false) {
      list[0] = "";
      document.getElementById(id).checked = false;
      this.setState({
        activeArray: "", list
      });
    }
  }

  addNew() {
    this.props.history.push('/organisationAdd');
  }

  // calling function when click on active deactive button
  activeorgnization(status) {
    var url="";
    if (this.state.activeArray.length !== 0) {
      if (status === true) {
        url = baseUrl.URLPath + servicesUrl.activateAdminAccount + "?tenant=" + this.state.activeArray;
      } else {
        url = baseUrl.URLPath + servicesUrl.deactivateAdminAccount + "?tenant=" + this.state.activeArray;

      }

      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status === 401) {
            window.location.reload();
          } else {
            return resp.json();
          }
        })
        .then((response) => {
          if (status === false) {
            this.toggle1()

          } else {
            this.toggle2()
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    } else if (this.state.activeArray.length === 0) {
      this.toggle3()
    }
  }




  render() {
    const { t } = this.props
    let user = this.state.user;

    let data = "";
    if (this.state.dataArray) {
      data = this.state.dataArray.map((dataArray, index) => {
        if (dataArray.active === false || dataArray.active === "Inactive") {
          dataArray.active = "Inactive";
        } else {
          if (dataArray.active === true) {
            dataArray.active = "Active";
          }
        }

        if (dataArray.address !== null) {
          return (
            <tr>
              <td className="text-center">
                <Form.Check
                  custom
                  type="checkbox"
                  id={`custom-${dataArray.id}`}
                  //checked = {false}
                  onChange={this.handleChange.bind(this, `custom-${dataArray.id}`, dataArray.identifier)}
                  key={dataArray.id}
                  label="" />
              </td>
              <td className="text-break" width={200}>
                <Link to={{
                  pathname: '/organisationView',
                  state: dataArray.id,
                }}>
                  {dataArray.company}
                </Link>
              </td>
              <td className="text-break" width={200}>{dataArray.identifier}</td>
              <td className="text-break" width={200}>{dataArray.officeAddress}</td>
              <td className="text-break" >{dataArray.city}</td>
              <td className="text-break">{dataArray.state}</td>
              <td className="text-break" >{dataArray.country}</td>
              <td className="text-break" width={200}>{dataArray.url}</td>
              <td className="">{dataArray.active}</td>
            </tr>
          );
        } else {
          return (
            <tr>
              <td className="text-center">
                <Form.Check
                  custom
                  type="checkbox"
                  id={`custom-${dataArray.id}`}
                  //checked = {false}
                  onChange={this.handleChange.bind(this, `custom-${dataArray.id}`, dataArray.identifier)}
                  key={dataArray.id}
                  label="" />
              </td>
              <td className="text-break" width={200}>   
                <Link to={{
                  pathname: '/organisationView',
                  state: dataArray.id,
                }}>
                  {dataArray.company}
                </Link>
              </td>
              <td className="text-break" width={200}>{dataArray.identifier}</td>
              <td className="text-break" width={200}>{dataArray.officeAddress}</td>
              <td className="text-break" >{dataArray.city}</td>
              <td className="text-break" >{dataArray.state}</td>
              <td className="text-break" >{dataArray.country}</td>
              <td className="text-break" width={200}>{dataArray.url}</td>
              <td>{dataArray.active}</td>
            </tr>
            // <></>
          );
        }
      })

    }


    return (
      <>


        <main class="content-div" >
          <p>{t('Organization')}
            <div className="breadcrumb_div">
              {t('Settings')} &gt; <span className="breadcrumb_page"> {t('Organization')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            {/* <div className="breadcrumb_div"> Settings > Organization </div> */}
            {/* page title */}

            {/* end page title */}
            {/* page menu bar */}

            <div className="row menubar">

              <div className="col-md-12 ">
                <div className="">
                  {hasPermission(user, ['Organizations_W']) && <button onClick={this.addNew}>
                    <i className="fa fa-plus">
                    </i>
                    <span>{t('add')}</span>
                  </button>}
                  {/* <button>
                    <i className="fas fa-pencil-alt">
                    </i>
                    <span>Edit</span>
                </button> */}
                  {/* <button onClick={this.deleteAll}>
                    <i className="fas fa-trash">
                    </i>
                    <span>Delete</span>
                  </button> */}
                  {hasPermission(user, ['Organizations_W']) &&
                    <button onClick={this.activeorgnization.bind(this, true)}>
                      <i className="fas fa-toggle-on">
                      </i>
                      <span>{t('active')}</span>
                    </button>}
                  {hasPermission(user, ['Organizations_W']) &&
                    <button onClick={this.activeorgnization.bind(this, false)}>
                      <i className="fas fa-toggle-off">
                      </i>
                      <span>{t('deactive')}</span>
                    </button>}
                  <div className="pull-right">
                    <label className="sub-text pull-left" >{t('show_entries')}</label>
                    <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                      <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>40</option>
                        <option>50</option>
                      </Form.Control>
                    </Form.Group>
                  </div>


                </div>
              </div>
            </div>
            {/* end page menu bar  */}
            <div className="row">
              <MDBCol md="4" className="mt-2" >
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="organisation" onKeyDown={this.keyPress} value={this.state.fields["organisation"]} style={{ width: "70%" }} onChange={this.changeHandler.bind(this, "organisation")} class="form-control input-searchBox pad-2" placeholder={t('enter_organization')} name="adminAddress" onFocus={this.Focus} />
                  <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint} >{t('find')}</button>
                  </div>
                </div>
                <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.state.errors["entercharge"]}</span></span>
                <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>

              </MDBCol>
            </div>

            {/* table start */}
            <div className="row ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="">{t('Organization')}</th>
                      <th className="">{t('short_name')}</th>
                      <th className="">{t('office_add')}</th>
                      <th className="">{t('city')}</th>
                      <th className="">{t('state')}</th>
                      <th className="">{t('country')}</th>
                      <th className="">{t('url')}</th>
                      <th className="">{t('chargingSessionState')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
              {/* End table  */}
            </div>
          </div>
          <MDBModal isOpen={this.state.modal3} size="md" className="model_top" backdrop="static" data-backdrop="static" data-keyboard="false">
            <MDBModalHeader toggle={this.toggle3}>{t('Warning')}</MDBModalHeader>
            <MDBModalBody>
              {t('select_organization')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
              <button onClick={this.tagOk3} className="btn_primary" type="button" data-test="button">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modal1} size="md" className="model_top" backdrop="static" data-backdrop="static" data-keyboard="false">
            <MDBModalHeader toggle={this.toggle1}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('org_deactivate_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
              <button onClick={this.tagOk1} className="btn_primary" type="button" data-test="button">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modal2} size="md" className="model_top" backdrop="static" data-backdrop="static" data-keyboard="false">
            <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('org_activate_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="primary" onClick={this.tagOk2}>OK</MDBBtn> */}
              <button onClick={this.tagOk2} className="btn_primary" type="button" data-test="button">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for session expire */}

          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
              <button onClick={this.sessiontagOk} className="btn_primary" type="button" data-test="button">{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          {this.state.loading ? (
            <Loader loader={this.state.loading} />
          ) : ("")}
        </main>
      </>
    );
  }
}

export default withTranslation()(organizationList);