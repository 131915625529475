import React from 'react';
import * as baseUrl from '../views/constants';
import { withTranslation } from 'react-i18next';
import InvalidQRCode from './InvalidQRCode';

class TransactionSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEnergyLimitReached: false,
            // transactionDetails: {
            //     "Date/Time:": "30 June 2024, 12:08AM",
            //     "Paid Via:": "Visa (0789)",
            //     "Venue:": "New York Marriott Marquis",
            //     "Duration:": "",
            //     "Electricity Consumed:": "",
            //     "Public Rate:": "",
            //     "Total Charging cost:": "",
            // },
            transactionDetails: {
                "Date/Time:": "30 June 2024, 12:08AM",
                "Duration:": "",
                "Electricity Consumed:": "",
                "Public Rate:": "",
                "Total Charging cost:": "",
            },
            chargingStationLocation: "",
            city: "",
            country: "",
            zipCode: "",
            amount: "",
            transactionId: "",
            showLoder: false
        }

        this.downloadTransactionSummary = this.downloadTransactionSummary.bind(this);
        this.getChargerSessionDetails = this.getChargerSessionDetails.bind(this);
        this.getTransactionSummary = this.getTransactionSummary.bind(this);
        this.closeThisPage = this.closeThisPage.bind(this);

    }

    closeThisPage() {
        this.props.setPage('selectCharger');
        localStorage.setItem('page', 'selectCharger');
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                showLoder: !this.state.showLoder,
            }, () => {
                this.getTransactionSummary();
            })
        }, 5000)
        this.setState({
            showLoder: !this.state.showLoder
        })
    }

    toSeconds(days, hours, minutes, seconds) {
        return parseInt(days) * 86400 + parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    }
    secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = d;
        var hDisplay = h > 0 ? h + (h === 1 ? " hr " : " hr ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " min " : " min ") : "";
        var sDisplay = s > 0 ? s + (s === 1 ? " sec " : " sec ") : "";
        if (h === 0 && m === 0) {
            var sDisplay = s > 0 ? s + (s === 1 ? " sec " : " sec ") : "";
            return sDisplay;
        } else {
            return hDisplay + mDisplay;
        }
    }
    async getChargerSessionDetails() {
        var timeZone = localStorage.getItem("timezone");
        var cpId = localStorage.getItem('cp');
        var id = localStorage.getItem('qrId');
        var rfid = localStorage.getItem('rfid');
        var paymentTransactionId = localStorage.getItem('transid');

        // let url = baseUrl.LoginPath + "/qrcode/charging/session/details?qrId=" + id + "&cpId=" + cp + "&rfId=" + rfid + "&time_zone=" + timeZone;
        let url = baseUrl.LoginPath + "/qrcode/charging/session/details?qrId=" + id + "&cpId=" + cpId + "&rfId=" + rfid + "&paymentTransactionId=" + paymentTransactionId;
        await fetch(url, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then(resp => {
            if (resp.status === 401 || resp.status === 404 || resp.status === 500 || resp.status === 400) {
                localStorage.setItem('page', 'QRCodeDeactivated');
                this.props.setPage('QRCodeDeactivated');
                localStorage.setItem('prePage', 'selectCharger');
            } else if (resp.status === 200) {
                return resp.json();
            }
        }).then(response => {
            debugger
            this.setState({ transactionId: response.transactionId },
                () => this.getTransactionSummary(response.transactionId)
            )
        }
        ).catch(error => {
            console.log(error)
        });

    }

    async getTransactionSummary() {

        let transactionId = localStorage.getItem('transactionId');        

        var timeZone = localStorage.getItem("timezone");

        let url = baseUrl.LoginPath + "/qrcode/charging/invoice/details?transactionId=" + transactionId + "&time_zone=" + timeZone;
        await fetch(url, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then(resp => {
            if (resp.status === 401 || resp.status === 404 || resp.status === 500 || resp.status === 400) {
                localStorage.setItem('page', 'QRCodeDeactivated');
                this.props.setPage('QRCodeDeactivated');
                localStorage.setItem('prePage', 'selectCharger');
            } else if (resp.status === 200) {
                return resp.json();
            }
        }).then(response => {
            let transactionDetails = {};
            transactionDetails["Date/Time:"] = response.dateTime;
            // transactionDetails["Paid Via:"] = response.paidVia;
            // transactionDetails["Venue:"] = response.chargingStationLocation;
            let time = "";
            let arrayTime = "";
            let sec = "";
            if (response.chargingDuration) {
                time = response.chargingDuration;
                arrayTime = time.split(":");
                sec = this.toSeconds(arrayTime[0], arrayTime[1], arrayTime[2], arrayTime[3]);
            }
            transactionDetails["Duration:"] = sec ? this.secondsToHms(sec) : "";
            console.log(transactionDetails["Duration:"], "Duration..................!!!!!!!!!");
            // transactionDetails["Duration:"] = response.chargingDuration;
            if (response.chargedEnergy === null) {
                transactionDetails['Electricity Consumed:'] = 0 + ' kWh';
            } else {
                transactionDetails['Electricity Consumed:'] = response.chargedEnergy + ' kWh';
            }
            const costsPerKwh = response.costsPerKwh ? response.costsPerKwh : '0';
            const costsPerHr = response.costsPerTimeHr ? response.costsPerTimeHr : '0';
            if (response.chargingType === 'ENERGY_LEVEL' || response.chargingType === null) {
                transactionDetails["Public Rate:"] = response.currencySymbol + costsPerKwh + ' / kWh';
            } else {
                transactionDetails["Public Rate:"] = response.currencySymbol + costsPerHr + ' / Hour';
            }
            transactionDetails["Total Charging cost:"] = response.currencySymbol + response.chargingSessionCost;
            // transactionDetails["Platform Fee(10%):"] = response.currencySymbol + response.costsPerChargingSession;
            let chargingStationLocation = response.chargingStationLocation;
            let transactionId = response.transactionId;
            let city = response.city;
            let country = response.city;
            let zipCode = response.zipCode;
            let total = response.chargingSessionCost + response.costsPerChargingSession;
            let amount = response.currencySymbol + total

            this.setState({ transactionDetails, chargingStationLocation, city, country, zipCode, amount, transactionId })
        }
        ).catch(error => {
            console.log(error)
        });

    }

    async downloadTransactionSummary() {
        //var timeZone = localStorage.getItem("timezone");
        var timeZone = "";
        let transactionId = this.state.transactionId;
        let url = baseUrl.LoginPath + "/qrcode/charging/details?trans_id=" + transactionId + "&time_zone=" + timeZone;

        await fetch(url, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then(resp => {
            if (resp.status === 401 || resp.status === 404 || resp.status === 500 || resp.status === 400) {
                localStorage.setItem('page', 'QRCodeDeactivated');
                this.props.setPage('QRCodeDeactivated');
                localStorage.setItem('prePage', 'selectCharger');
            } else {
                return resp.blob();
            }
        }).then(response => {
            let url = window.URL.createObjectURL(response);
            let a = document.createElement('a');
            a.href = url;
            a.download = transactionId + '.pdf';
            a.click();
        }
        ).catch(error => {
            console.log(error)
        });
    }
    render() {
        const { t } = this.props;
        const { transactionDetails } = this.state;
        if (!this.state.isEnergyLimitReached) {
            return (
                <>
                    {this.state.showLoder === true ?
                        <div className="transaction_summary_loader center" id="loader_image_div">
                            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                        </div> :
                        <div className="welcome-parent" style={{ backgroundSize: "cover" }}>
                            <div className='selectchr_bg container-w'>
                                <div className="nav_bar">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/close.svg"} style={{ width: "18px", cursor: "pointer" }} alt="close-icon" onClick={this.closeThisPage} />
                                    <h3 className='ml-3' style={{ color: '#00529E', fontSize: '18px', paddingTop: '5px', fontFamily: "Inter-Medium" }}>{t('transaction_summary')}</h3>
                                    <p>&nbsp;&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className='row wallbox-details-div'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className="col-9">
                                            <div className="card-body pl-0">
                                                <p className="charger-title"><b>
                                                    {/* {cp.chargePointId} */}
                                                    {localStorage.getItem('cp')}
                                                </b></p>
                                                <p className="charger-details txtblack overflow-txt">
                                                    {/* {cp.aliasName} */}
                                                    {localStorage.getItem('aliasName')}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <button className='paid-div float-right' style={{ cursor: "auto" }}>Paid</button>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <hr style={{ borderTop: '1px dashed #757D86', margin: '0px 0px 15px' }} />

                                    </div>
                                    <div className='col-12'>
                                        <div className='d-flex flex-column'>
                                            {Object.keys(transactionDetails).map((key) => (
                                                <div key={key} className='d-flex justify-content-between'>
                                                    <p style={{ color: "#757D86", fontFamily: "Inter-Medium", fontSize: "14px" }}>{key}</p>
                                                    <p style={{ color: "#283A4C", fontFamily: "Inter-SemiBold", fontSize: "14px" }}>{transactionDetails[key]}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className='row wallbox-details-div p-3 mt-2 mb-2'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p><span style={{ color: "#757D86", fontFamily: "Inter-Medium", fontSize: "16px" }}>Amount</span> <br />
                                                <span style={{ color: "#757D86", fontFamily: "Inter-Medium", fontSize: "12px" }}>(Inclusive of All Taxes)</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "#283A4C", fontFamily: "Inter-SemiBold", fontSize: "16px" }}>{this.state.amount}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 mt-4 mb-2'>
                                    {
                                        ((this.state.chargingStationLocation !== null && this.state.chargingStationLocation !== "") ||
                                            (this.state.city !== null && this.state.city !== "") ||
                                            (this.state.country !== null && this.state.country !== "") ||
                                            (this.state.zipCode !== null && this.state.zipCode !== "")) ?
                                            <p className='text-center' style={{ color: "#757D86", fontFamily: "Inter-Medium", fontSize: "12px" }}>
                                                {this.state.chargingStationLocation} - {this.state.city}, {this.state.country}, {this.state.zipCode}
                                            </p> : ""
                                    }
                                </div>

                                <div className='col-12 mt-4 mb-2 pb-2'>
                                    <div className='d-flex justify-content-around'>

                                        <button className='wallbox-details-div-btn' type='button' onClick={this.downloadTransactionSummary}>
                                            <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/invoice_icon.svg"} style={{ width: "12px" }} alt="invoice" />
                                            <span className='ml-2 transaction-btn-span'>Download Invoice</span>
                                        </button>

                                        {/* <button className='wallbox-details-div-btn' type='button'>
                                        <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/share1.svg"} style={{ width: "12px" }} alt="invoice" />
                                        <span className='ml-2 transaction-btn-span'>Share Invoice</span>
                                        </button> */}

                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </>
            )
        } else {
            return (
                <InvalidQRCode message={this.state.message}></InvalidQRCode>
            )
        }
    }
}

export default withTranslation()(TransactionSummary);
