import React from 'react';
import { MDBCol, MDBModal, MDBModalBody, MDBModalFooter, MDBRow } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import $ from 'jquery';
import { dateRangeValidate, getDateRange, dateRangeValidateForCSCPlog, dateformatinUTC } from './_methods';
import { withTranslation } from 'react-i18next';
import '../pagination.css';
import './cpDashboard.css';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';
import servicesUrl from '../common/servicesUrl';

class ServiceManagementCSCPLogs extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          checked: false,
          modalView: false,
          modalsession: false,
          cpIdSorting: false,
          oprNameSorting: false,
          fields: {
            chargePointId: ""
          },
          fromDate: "",
          toDate: "",
          fromDateApi: "",
          toDateApi: "",
          calDate: "",
          page_title: "CP Logs",
          collapseID: "",
          operationData: [],
          dataArray: [
            {
    
              chargepointid: '1',
              address: '',
              noofattempts: '',
            },
          ],
          noOfRecords: '',
          data: new Map(),
          pageSize: "10",
          activePage: 1,
          pageNo: 1,
          calenderFrom: "none",
          calenderTo: "none",
          operationsList: [],
          messageId: '',
          errors: {},
          sourceType: "",
          operationName: "",
          items: [],
          curPageFirstCreTime: "",
          curPageLastCreTime: "",
          prevPageFirstCreTime: "",
          listOfFirstCreatedtimes: []
    
        }
        this.getCsCpMergeLogs = this.getCsCpMergeLogs.bind(this);
        this.getCsCpOperation = this.getCsCpOperation.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
        this.onChangeMessageId = this.onChangeMessageId.bind(this);
        this.handleClick1 = this.handleClick1.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.toggleView1 = this.toggleView1.bind(this);
        this.exportCsCpLogs = this.exportCsCpLogs.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.bulkImport = this.bulkImport.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);
        this.changeScheduleFrom = this.changeScheduleFrom.bind(this);
        this.changeScheduleTo = this.changeScheduleTo.bind(this);
        this.keyPress3 = this.keyPress3.bind(this);
        this.idTagChange = this.idTagChange.bind(this);
        this.createdDatesArray = this.createdDatesArray.bind(this);
        
      }
      bulkImport() {
        this.props.history.push('/csCpLogsBulkImport')
      }
    
    
      changeScheduleFrom(e) {
        this.setState({ startDate: e.target.value });
      }
    
    
      changeScheduleTo(e) {
        this.setState({ toDate: e.target.value });
      }
      handleClick1() {
        this.setState({
          messageId: "",
          pageSize: 10,
          activePage: 1
        }, ()=>{
          this.getCsCpMergeLogs(this.state.pageSize, 1);
          $(".error_emptymessageid").text("");
          $(".error_nomessageid").text("");
        })
      }    
    
      toggleView(messageId, requestMessage, responseMessage) {
        this.setState({
          modalView: !this.state.modalView,
          displaymessage: messageId,
          displayReq: requestMessage,
          displayRes: responseMessage
        });
      }
    
      toggleView1() {
        this.setState({
          modalView: !this.state.modalView
        });
      }
    
      handleClick() {
        let fields = this.state.fields;
        let errors = this.state.errors;
    
        fields.chargePointId = "";
        errors["entercharge"] = "";
        $("#notexists").text("");
    
        this.setState({
          fields,
          pageSize: 10,
          activePage: 1,
          errors
        }, ()=>{
          this.getCsCpMergeLogs(this.state.pageSize, 1);
        });
      }
    
      idTagChange() {
        var operation = document.getElementById("tagList").value;
        this.setState({ operationName: operation }, ()=>{
          this.getCsCpMergeLogs(10, 1, "");
        })
      }
    
      changeHandler(field, e) {
        let errors = this.state.errors;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        errors["entercharge"] = "";
    
        this.setState({ fields, errors }, ()=>{
          if (this.state.fields.chargePointId.length === 0) {
            this.setState({ activePage: 1 }, ()=>{
              this.getCsCpMergeLogs(this.state.pageSize, 1);
            });
          }
        });
    
        $("#notexists").text(" ");
      }
    
      keyPress(e) {
        var key = e.key;
        let errors = this.state.errors;
        let fields = this.state.fields;
    
        if (e.keyCode === 13) {
            if (this.state.fields.chargePointId === "") {
              errors["entercharge"] = this.props.t('enter_charge_point_id');
              $("#notexists").text("");
              this.setState({ errors })
            } else {
              errors["entercharge"] = "";
              this.setState({ errors }, ()=>{
                this.getCsCpMergeLogs(this.state.pageSize, 1, "enter");
              })
            }
        }
    
        if (key === "Backspace" || key === "Delete") {
          var x = e.target.value
          var sno = x.slice(0, x.length - 1)
          if (sno === "") {
            fields.chargePointId = sno;
            this.setState({ fields }, ()=>{
              this.getCsCpMergeLogs(this.state.pageSize, 1, "");
            })
          }
        }
    
        switch (e.keyCode) {
          case 8:  // Backspace
          case 9:  // Tab
          case 13: // Enter
          case 37: // Left
          case 38: // Up
          case 39: // Right
          case 40: // Down
            break;
          default:
            var regex = new RegExp("^[a-zA-Z0-9., -]");
            if (!regex.test(key)) {
              e.preventDefault();
              return false;
            }
            break;
        }
      }
    
      keyPress3(e) {
        var key = e.key;
    
        if (this.state.messageId === "") {
          $(".error_emptymessageid").text("Please enter message id");
        } else {
          $(".error_emptymessageid").text("");
          this.getCsCpMergeLogs(this.state.pageSize, 1);
        }
    
        if (key === "Backspace" || key === "Delete") {
          $(".error_emptymessageid").text("");
        }
    
        if (e.keyCode === 13) {
          this.setState({ messageId: e.target.value }, ()=>{
            this.getCsCpMergeLogs(10, 1, "messageClick");
          });
        }
      }
    
      // for from date calender 
      handleFromChange = date => {
        $('.future_err1').text("");
        this.setState({
          fromDate: date,
          fromDateApi: dateformatinUTC(date),
          activePage: 1
        }, () => {
          if (dateRangeValidateForCSCPlog(this.state.fromDate, this.state.toDate) === true) {
            $('.date_range_error').text('');
            this.getCsCpMergeLogs(this.state.pageSize, 1);
          } else {
            $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('listLogDuration')} ${this.props.t('days')}`);
          }
        });
      }
    
      // for to date calender 
      handleToChange = date => {
        this.setState({
          toDate: date,
          toDateApi: dateformatinUTC(date),
          activePage: 1
        }, () => {
          if (dateRangeValidateForCSCPlog(this.state.fromDate, this.state.toDate) === true) {
            $('.date_range_error').text('');
            this.getCsCpMergeLogs(this.state.pageSize, 1)
          } else {
            $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('listLogDuration')} ${this.props.t('days')}`);
          }
        });
      }
    
      showEntriesChange(e) {
        var entries = e.target.value;
        let fromDateApi = dateformatinUTC(moment(this.state.fromDate));
        let toDateApi = dateformatinUTC(moment(this.state.toDate));
    
        this.setState({
          activePage: 1,
          pageSize: entries,
          fromDateApi: fromDateApi,
          toDateApi: toDateApi
        },()=>{
          this.getCsCpMergeLogs(entries, 1);
        });
      }
    
      createdDatesArray(dateTime) {
        let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
        this.setState({
          listOfFirstCreatedtimes: listOfFirstCreatedtimes
        });
      };
    
      handlePageChange(pageNumber) {
        if (pageNumber < this.state.activePage) {
          //prev
          this.setState({
            activePage: pageNumber,
            fromDateApi: this.state.curPageFirstCreTime,
            toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
          }, () => {
            this.getCsCpMergeLogs(this.state.pageSize, pageNumber, "", false);
          });
        } else {
          //next
          let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
          this.setState({
            activePage: pageNumber,
            fromDateApi: dateformatinUTC(this.state.fromDate),
            toDateApi: this.state.curPageLastCreTime,
            prevPageFirstCreTime: this.state.curPageFirstCreTime
          }, () => {
            if (pageNumber > _datesArrayLength + 1) {
              this.createdDatesArray(this.state.curPageFirstCreTime);
            }
            this.getCsCpMergeLogs(this.state.pageSize, pageNumber, "", false);
          });
        }
      }
    
      async getCsCpMergeLogs(pageSize, pageNo, action, countValue) {
        document.getElementById("smloader_image_div1").style.display = "block";
        let chargePointId =  this.props.chargePointIdCSCP;
        let messageId = this.state.messageId;
    
        var url = baseUrl.URLPath + servicesUrl.getSMcscplogs + "?chargePointId=" + chargePointId + "&start_date=" + this.state.fromDateApi + "&end_date=" + this.state.toDateApi + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&name=" + this.state.operationName;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};
    
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);
    
        if (response !== undefined) {
          if (response.status === 404) {
            document.getElementById("smloader_image_div1").style.display = "none";
          }else if (response.status === 400) {
            setTimeout(() => {
              document.getElementById("smloader_image_div1").style.display = "none";
              $('.date_range_error').text(response.message);
            }, 100);
          } else if (response.count.count === 0) {
            setTimeout(() => {
              document.getElementById("smloader_image_div1").style.display = "none";
    
              if (action === "messageClick") {
                $('.error_nomessageid').text(this.props.t('msg_id_not_exists'));
              }
    
              if (action === "click" || action === "enter") {
                $("#notexists").text(this.props.t('charge_point_id_not_exists.'));
              }
    
              this.setState({
                items: [],
                noOfRecords: 0
              })
            }, 100);
          } else {
            setTimeout(() => {
              document.getElementById("smloader_image_div1").style.display = "none";
              var totalcount = "";
    
              if (countValue === false) {
                totalcount = this.state.noOfRecords
              } else {
                totalcount = response.count.count
              }
    
              this.setState({
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                items: response.logs,
                noOfRecords: totalcount,
                curPageFirstCreTime: moment(response.logs[0].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                curPageLastCreTime: moment(response.logs[response.logs.length - 1].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              })
    
              if (action === "click" || action === "enter") {
                $("#notexists").text(" ");
                $("#messageid").text(" ");
              }
            }, 100);
          }
        }
      }
    
      async getCsCpOperation(pageSize, pageNo, name, action) {
        
        var url = baseUrl.URLPath + "operations/name?source=";
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};
    
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false);
    
        if (response !== undefined) {
          this.setState({
            operationsList: response
          })
          if (action === "click" || action === "enter") {
            $("#notexists").text(" ");
          }
        }
      }
    
      componentDidMount() {
        let duration = localStorage.getItem('listLogDuration');
        duration = duration - 1;
        let fromDate = moment().subtract(duration, 'd');
        let fromDateApi = dateformatinUTC(moment().subtract(duration, 'd'));
        let toDate = new Date();
        let toDateApi = dateformatinUTC(moment(toDate));
    
        this.setState({
          fromDate: new Date(fromDate),
          fromDateApi: fromDateApi,
          toDate: toDate,
          toDateApi: toDateApi,
        }, () => {
          getDateRange();
        });
    
        setTimeout(() => {
          this.getCsCpMergeLogs(this.state.pageSize, this.state.activePage);
          this.getCsCpOperation(this.state.pageSize, this.state.activePage);
        }, 100);
      }
    
      //change function for chargepoint status filter
      onChangeChargepoint() {
        let errors = this.state.errors;
    
        if (this.state.fields.chargePointId === "") {
          errors["entercharge"] = this.props.t('enter_charge_point_id')
          $("#notexists").text("");
          this.setState({ errors });
        } else {
          errors["entercharge"] = "";
          this.setState({ errors }, ()=>{
            this.getCsCpMergeLogs(this.state.pageSize, 1, "click");
          });
        }
      }
    
      onChangeMessageId() {
        var messageId = document.getElementById("messageId").value;
    
        this.setState({ messageId: messageId }, () => {
          this.getCsCpMergeLogs(10, 1, "messageClick");
        });
    
        if (this.state.messageId === "") {
          $(".error_emptymessageid").text(this.props.t('enter_message_id'));
        } else {
          $(".error_emptymessageid").text("");
        }
      }
    
      exportCsCpLogs() {
        var timezone = moment().tz(moment.tz.guess()).format('z');
    
        var url = baseUrl.URLPath + "chargepoint/logs/download/CSV?chargePointId=" + this.state.fields.chargePointId + "&startDate=" + this.state.fromDateApi + "&endDate=" + this.state.toDateApi;
    
        fetch(url, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization"),
            "timeZone": timezone
          }
        })
          .then((resp) => {
            if (resp.status === 401) {
                window.location.reload();
            }
            if (resp.status === 200) {
              return resp.blob();
            }
          })
          .then((response) => {
            if (response !== undefined) {
                let d = new Date();
                let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                let url = window.URL.createObjectURL(response);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'Operation_MergeLogs_' + dformat + '.csv';
                a.click();
            }
          })
          .catch((error) => {
              console.log(error, "catch the loop")
          })
      }
    
      //function for sorting table data on click
      ascendingOrder() {
        if (this.state.cpIdSorting === false) {
          let items = this.state.items;
          items = items.sort(this.dynamicSort("chargePonintId"));
    
          this.setState({ items: items, cpIdSorting: true })
        } else {
          let items = this.state.items;
          items = items.sort(this.dynamicSort("chargePonintId"));
    
          this.setState({ items: items.reverse(), cpIdSorting: false });
        }
      }
    
      operationNameSorting() {
        if (this.state.oprNameSorting === false) {
          let items = this.state.items;
          items = items.sort(this.dynamicSort("name"));
    
          this.setState({ items: items, oprNameSorting: true })
        } else {
          let items = this.state.items;
          items = items.sort(this.dynamicSort("name"));
    
          this.setState({ items: items.reverse(), oprNameSorting: false });
        }
      }
    
      dynamicSort(property) {
        var sortOrder = 1;
    
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
    
        return function (a, b) {
          if (sortOrder === -1) {
            return b[property].localeCompare(a[property]);
          } else {
    
            return a[property].localeCompare(b[property]);
          }
        }
      }
    

      render(){

        const{t}=this.props;

    const itemsList = this.state.operationsList.map((item, index) => {
      return (
        <>
          {(item.trim() !== 0 || item.trim() !== "") ? <option value={item}>{item}</option> : null}
        </>
      )
    });

        const operationData = this.state.items.map((item, index) => {
            var createdTime = "";
            var createdTime1 = "";
            var responseTime = "";
            var responseTime1 = "";
      
            if (item.createdTime === null || item.createdTime === "") {
              createdTime = "";
              createdTime1 = "";
            } else {
              createdTime = moment(item.createdTime).format("DD MMM YYYY");
              createdTime1 = moment(item.createdTime).format("HH:mm:ss");
            }
      
            if (item.responseTime === null || item.responseTime === "") {
              responseTime = "";
              responseTime1 = "";
            } else {
              responseTime = moment(item.responseTime).format("DD MMM YYYY");
              responseTime1 = moment(item.responseTime).format("HH:mm:ss");
            }
      
            return (
              <tr>
                <td className="wrap-word">{createdTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdTime1} </div> </>}</td>
                {/* <td>{item.chargePonintId}</td> */}
                <td>{item.name}</td>
                <td className="wrap-word">{responseTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {responseTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {responseTime1} </div> </>}</td>
                <td>{item.source}</td>
                <td className="text-center"><i class="fas fa-eye action-icon cursor_defult" title="View" onClick={this.toggleView.bind(this, item.messageId, item.requestMessage, item.responseMessage)} ></i></td>
              </tr>
            );
          });

        return (
          <>
      
            <main class="mt-2" >
                
               
                <div className="page-outerdivsmpopup-outerdiv ml-0">
                  {/* page menu bar */}
                  <div className=" row col-md-7 menubar" >
      
                    {(localStorage.getItem("enableCPImport") === "true" && localStorage.getItem("tenant") === "ford") ? <><button onClick={this.bulkImport.bind(this, 0)} ><i className="fas fa-file-import"></i> <span>{t('bulk_import')}</span></button></> : " "}
      
                    {/* <button color="primary accent-2" onClick={this.exportCsCpLogs} >
                      <i className="fas fa-file-export"></i>
                      <span>Export</span>
                    </button> */}
      
                  </div>
                  {/* end page menu bar  */}
              <div className="row mt-2" >
   
                <MDBCol md="3" className='pr-0 mt-1'>
                <span className="cal_label" >{t('from_time')}</span>
                <div className="pull-left mt-2" style={{ width: "60%"}} >
                  <DatePicker
                    selected={this.state.fromDate}
                    onChange={this.handleFromChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    id="expiryDateId"
                    className="smcscp-calmargin smcscp-tag_callogs"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    maxDate={(this.state.toDate!==""&&this.state.toDate!==null)? new Date(this.state.toDate):new Date()}
                  />
                </div>
                <div className="error_msg w3-animate-top col-md-12  datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
                </MDBCol>
                  
                <MDBCol md="3" className='pr-0 mt-1'>
                      <span className="cal_label">{t('to_time')}</span>
                      <div className="pull-left mt-2" style={{ width: "60%"}} >
                        <DatePicker
                          selected={this.state.toDate}
                          onChange={this.handleToChange}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={1}
                          id="expiryDateId"
                          className="smcscp-calmargin smcscp-tag_callogs"
                          timeCaption="time"
                          dateFormat="dd-MMM-yyyy HH:mm"
                          minDate={new Date(this.state.fromDate)}
                          maxDate={new Date()}
                        />
                      </div>
                      <div className="error_msg w3-animate-top col-md-12  datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
                    </MDBCol>
                    <MDBCol md="3" className="mt-2" >
                      <select className="browser-default custom-select select_height mb_8" id="tagList" onChange={this.idTagChange.bind(this)} value={this.state.operationName}>
                        <option value="">{t('select_operation_name')}</option>
                        {itemsList}
                      </select>
                    </MDBCol>
                    <div className="col-md-3 mt-2 pt-1">
                      <div className="pull-right">
                        <label className="sub-text pull-left" >{t('show_entries')}</label>
                        <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                          <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange.bind(this)} value={this.state.pageSize}>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                            <option>50</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>
                    
                    <div className='col-md-12 date_range_error' style={{ color: "red" }}></div>
                  </div>
      
                  {/* table start */}
                  <div className="row ">
                    <div className="col-md-12">
                      <Table striped hover size="sm" className="page-table">
                        <thead>
                          <tr>
                            <th>{t('created_date_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                        {/* <th className="">{t('charge_point_id')} <i class="fas fa-sort sortIcon" onClick={this.operationNameSorting.bind(this)}></i></th> */}
                            <th className="">{t('operation_name')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                            <th>{t('response_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                            <th>{t('source')}</th>
                            <th className="text-center" >{t('view')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {operationData}
                          {
                            (this.state.items.length === 0) ? <td colSpan={6} style={{ textAlign: "center" }}>{t('no_data_available_for_cs_cp_logs')}</td> : null
                          }
                        </tbody>
                      </Table>
                    </div>
                    <div className="col-md-12 mt-2 mb-4 text-right">
                      <div className="col-md-12 mt-2 mb-4 text-right">
                        <Pagination
                          hideDisabled
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.pageSize}
                          totalItemsCount={this.state.noOfRecords}
                          onChange={this.handlePageChange.bind(this)}
                          prevPageText={t('prev')}
                          nextPageText={t('next')}
                          itemClassPrev="prevBtn"
                          pageRangeDisplayed="1"
                          activeClass="activeLi active"
                          itemClassNext="nextBtn"
                          hideFirstLastPages={true}
                        />
                      </div>
                    </div>
                    {/* End table  */}
                  </div>
                </div>
      
             
                {/* <div className="page_loader center" id="smloader_image_div1" style={{ display: "none" }}>
                  <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div> */}
      
                {/* Popup for  View */}
                <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size="lg" className="model_top">
                  <div data-test="modal-header" class="modal-header">
                    <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('view')}</div>
                    <button type="button" class="close" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  {/* <MDBModalHeader toggle={this.toggleView}>View</MDBModalHeader> */}
                  <MDBModalBody className="body-scroll">
                    <MDBRow className="mb-4 mt-3" >
      
                      <MDBCol sm="12">
                        <div className="view-page-row view-bg-row" >
      
                          <b>{t('message_id')}</b>  <span className="wrap-word">
                            {this.state.displaymessage}
      
                          </span>
      
      
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="mb-4 mt-3" >
      
                      <MDBCol sm="12">
                        <div className="view-page-row view-bg-row" >
                          <label className="">
                            {t('request_message')}
                          </label>
                          <label className="wrap-word">
                            {this.state.displayReq}
      
                          </label>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="mb-4 mt-3" >
      
                      <MDBCol sm="">
                        <div className="view-page-row view-bg-row">
                          <label className="w-40">
                            {t('response_message')}
                          </label>
                          <label className="wrap-word">
      
                            {this.state.displayRes}
                          </label>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBModalBody>
                  <MDBModalFooter className="border-top-0 p-3">
                    <button type="button" className="btn_primary" onClick={this.toggleView1}>{t('close')}</button>
                  </MDBModalFooter>
                </MDBModal>
                   {/* Loader */}
                
              </main>
              <div className="smpageLoder1 center" id="smloader_image_div1" style={{ display: "none" }}>
                  <div className='smimg-center'>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                  </div>
                </div>
            </>
          );
      }

    

}
export default withTranslation()(ServiceManagementCSCPLogs);