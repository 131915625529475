import React from 'react';
import ReactDOM from 'react-dom';

//3rd party imports for translations
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import App from './App';
import * as serviceWorker from './serviceWorker';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.css";
import './index.css';

//react high charts imports
import Highcharts from 'highcharts/highstock';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

let eng;
let fr;
let de;


let x = () => {
    
    fetch(`${window.location.origin}/multilingualfiles/en.json`)
        .then(response => response.json())
        .then((jsonData) => {
            eng = jsonData;
            y();
        })
        .catch((error) => {
            // handle your errors here
            console.error(error)
        })
}

let y = () => {
    fetch(`${window.location.origin}/multilingualfiles/fr.json`)
        .then(response => response.json())
        .then((jsonData) => {
            fr = jsonData;
            z();
            i18next.init(i18n
                // detect user language
                .use(LanguageDetector)
                // pass the i18n instance to react-i18next.
                .use(initReactI18next)
                // init i18next
                .init({
                    debug: true,
                    fallbackLng: ['en'],
                    preload: ['en', 'fr'],
                    resources: {
                        en: { translation: eng },
                        fr: { translation: fr }
                    }
                }));
            ReactDOM.render(<I18nextProvider i18n={i18next}><App i18n={i18next} /></I18nextProvider>, document.getElementById('root'));
        })
        .catch((error) => {
            // handle your errors here
            console.error(error)
        })
}

let z = () => {
    fetch(`${window.location.origin}/multilingualfiles/de.json`)
        .then(response => response.json())
        .then((jsonData) => {
            de = jsonData;
            i18next.init(i18n
                // detect user language
                .use(LanguageDetector)
                // pass the i18n instance to react-i18next.
                .use(initReactI18next)
                // init i18next
                .init({
                    debug: true,
                    fallbackLng: ['en'],
                    preload: ['en', 'fr', 'de'],
                    resources: {
                        en: { translation: eng },
                        fr: { translation: fr },
                        de: { translation: de }
                    }
                }));
            ReactDOM.render(<I18nextProvider i18n={i18next}><App i18n={i18next} /></I18nextProvider>, document.getElementById('root'));
        })
        .catch((error) => {
            // handle your errors here
            console.error(error)
        })
}


x();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
