import React from 'react';
import { Form, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import $ from "jquery";
import { withTranslation } from 'react-i18next';
import Loader from '../views/loader';

//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders } from '../common/HttpRequestClass';

class ChargingProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpIdSorting: false,
      errors: {},
      selectFindStatus: false,
      intial_data: [],
      saveSelectedProfile: "",
      multiple: false,
      options: [],
      cpProfiles: "",
      selectDataArray: [],
      page_title: " Charging Profile",
      activePage: 1,
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      pageNo: 1,
      modalsession: false,
      user: {
        permissions: []
      },
      items: [],
      isLoading: false,
    }
    this.addNew = this.addNew.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this)
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.chargingProfileChange = this.chargingProfileChange.bind(this);
    this.getChargingProfile = this.getChargingProfile.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.getprofileNames = this.getprofileNames.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
  }

  FindCpName(ref, event) {
    let errors = this.state.errors;
    let saveSelectedProfile = ref.current.inputNode.value;
    let selectFindStatus = true;

    if (saveSelectedProfile === null || saveSelectedProfile === "") {
      errors["selectChargepoint"] = this.props.t('please_enter_charging_profile');
      $("#notexists").text("");
      this.setState({ errors, saveSelectedProfile: saveSelectedProfile, selectFindStatus: selectFindStatus })
    } else {
      errors["selectChargepoint"] = "";
      this.setState({ errors, saveSelectedProfile: saveSelectedProfile, selectFindStatus: selectFindStatus }, () => {
        this.getChargingProfile(this.state.pageSize, this.state.activePage, "click", true);
        if (this.state.selectDataArray.length === 0) {
          if (this.state.saveSelectedProfile === null || this.state.saveSelectedProfile === "") {
            let errors = {}
            errors["selectChargepoint"] = this.props.t('please_enter_charging_profile');
            this.setState({ errors: errors });
          } else {
            let errors = {}
            errors["selectChargepoint"] = "";
            this.setState({ errors: errors });
          }
        }
      })
    }
  }

  getprofileNames(event) {

    var crgProfiles = event.target.value;
    this.setState({
      cpProfiles: crgProfiles
    })
    if (crgProfiles.length === 0) {
      this.getChargingProfile(this.state.pageSize, this.state.activePage, this.state.saveSelectedProfile)

      this.setState({
        items: this.state.items,
      });
    }
    var errors = {};
    errors["idTagNotExists"] = "";

    this.setState({ errors: errors });
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    this.setState({ pageSize: entries, activePage: 1 }, ()=>{
      this.getChargingProfile(entries, 1, "", true);
    })
  }


  // function for add new chrge profile
  addNew() {
    this.props.history.push('/addChargingProfile');
  }

  componentDidMount() {
    let user = this.state.user;
    user.permissions = localStorage.getItem("roleAccess");

    this.setState({ user }, ()=>{
      this.getChargingProfile(this.state.pageSize, 1, "", true);
    });

    $("#nochargeexists").hide();
  }

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      this.setState({ activePage: pageNumber }, ()=>{
        this.getChargingProfile(this.state.pageSize, pageNumber, "", true);
      });
    }
  }

  async getChargingProfile(pageSize, pageNo, action, isLoading) {
    
    this.setState({ isLoading: isLoading })
    var url = "";

    if (this.state.selectFindStatus === true) {
      url = baseUrl.URLPath + servicesUrl.getChargingProfiles + "?pageNo=" + 1 + "&pageSize=" + pageSize + "&chargingProfileName=" + this.state.saveSelectedProfile;
    } else {
      url = baseUrl.URLPath + servicesUrl.getChargingProfiles + "?pageNo=" + pageNo + "&pageSize=" + pageSize + "&chargingProfileName=" + this.state.saveSelectedProfile;
    }

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      if (response.status===403) {
        this.setState({
          isLoading: false
        });
      }else{
      if (response.count.count === 0) {
        if (action === "click" || action === "enter") {
          $("#notexists").text(this.props.t('charging_profile_not_exists'));
        }
        this.setState({
          items: [],
          noOfRecords: 0,
          selectDataArray: [],
          isLoading: false
        });
      } else {
        this.setState({
          items: response.chargingProfiles,
          noOfRecords: response.count.count,
          selectDataArray: response.chargingProfiles,
          isLoading: false
        })
        if (action === "click" || action === "enter") {
          $("#notexists").text(" ");
        }
        var data = this.state.data;
        data.set(pageNo, response.count.count);
        var option = [];
        for (var i = 0; i < this.state.items.length; i++) {
          option.push(this.state.items[i].chargingProfileName);
        }
        this.setState({ options: option });
      }
    }
    }
  }


  chargingProfileChange(chargingProfileName) {
   
    this.setState({ saveSelectedProfile: chargingProfileName, selectFindStatus: true }, ()=>{
      if (chargingProfileName.length === 0) {
        this.getChargingProfile(this.state.pageSize, this.state.activePage)
      } else {
        let errors = {};
        errors["idTagNotExists"] = "";
        this.setState({
          errors: errors
        }, ()=>{
          this.getChargingProfile(this.state.pageSize, this.state.activePage, this.state.saveSelectedProfile);
        });
      }
    });
  }

  // search filter 
  handleKeyDown(ref, e) {
    var serial_no = e.target.value;
    var sno = serial_no.slice(0, serial_no.length - 1);
    let errors = this.state.errors;
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);

    if (serial_no !== 0) {
      errors["selectChargepoint"] = "";
    }

    if (key === "Backspace" || key === "Delete") {
      errors["selectChargepoint"] = "";
      $("#notexists").text("");
      if (sno === "") {
        this.setState({ saveSelectedProfile: sno }, ()=>{
          this.getChargingProfile(this.state.pageSize, this.state.activePage, this.state.saveSelectedProfile, false);
        });
      }
    }
    if(key === "Enter") {
      this.setState({ errors }, ()=>{
        this.getChargingProfile(this.state.pageSize, this.state.activePage, this.state.saveSelectedProfile, true);
      });
    } else {
      this.setState({ errors }, ()=>{
        this.getChargingProfile(this.state.pageSize, this.state.activePage, this.state.saveSelectedProfile, false);
      });
    }
    
    if (e.keyCode === 13) {
      this.FindCpName(ref, e);
      $("#rbt_id").hide();
    }

    if ((e.keyCode === 86 && ctrl)) {
      this.getChargingProfile(this.state.pageSize, this.state.activePage, this.state.saveSelectedProfile, false);
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        if (!regex.test(key)) {
          e.preventDefault(); return false;
        }
        break;
    }
  }

  ClearChargepoint(ref, event) {
    let errors = {};
    errors["selectChargepoint"] = "";
    $("#notexists").text("");

    this.setState({
      errors: errors,
      selectFindStatus: false,
      saveSelectedProfile: "",
      pageSize: 10,
      activePage: 1
    }, ()=>{
      this.getChargingProfile(this.state.pageSize, this.state.activePage, this.state.saveSelectedProfile, true);
    });

    ref.current.clear()
  }

  //function for sorting table data on click
  ascendingOrder() {
    let items = this.state.items;
    if (this.state.cpIdSorting === false) {
      items = items.sort(this.dynamicSort("chargingProfileName"));
      this.setState({ items: items, cpIdSorting: true });
    } else {
      items = items.sort(this.dynamicSort("chargingProfileName"));
      this.setState({ items: items.reverse(), cpIdSorting: false });
    }
  }

  stackLevelSorting() {
    let items = this.state.items;
    if (this.state.cpIdSorting === false) {
      items = items.sort(function (a, b) {
        return a.stackLevel - b.stackLevel;
      });
      this.setState({ items: items, cpIdSorting: true })
    } else {
      this.setState({ items: items.reverse(), cpIdSorting: false });
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  render() {
    const { t } = this.props
    
    const ref = React.createRef();

    return (
      <main class="content-div" >
        <p>{t('chargingProfileId')}
          <div className="breadcrumb_div">
            {t('charge_point')} &gt; <span className="breadcrumb_page">{t('chargingProfileId')}</span>
          </div>
        </p>
        <div className="page-outerdiv">
          {/* <div className="breadcrumb_div">Charge Point > Charging Profile</div> */}
          {/* page title */}
          {/* <p>Reservations</p> */}
          {/* end page title */}
          {/* page menu bar */}
          <div className="row menubar">
            <div className="col-md-12 ">
              <div className="">
                {this.props.ChargingProfile_W &&
                  <button onClick={this.addNew} type="button" data-test="button">
                    <i className="fa fa-plus">
                    </i>
                    <span>{t('add')}</span>
                  </button>
                }
                <div className="pull-right">
                  <label className="sub-text pull-left">{t('show_entries')}</label>
                  <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>          </div>          {/* <button onClick={this.toggle1}>
                      <i className="fas fa-trash">
                      </i>
                      <span>Delete</span>
                    </button>  */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4  pull-right">
              <div class="input-group">
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <Typeahead
                  labelKey="name"
                  className="w_80" multiple={this.state.multiple}
                  options={this.state.options}
                  minLength={3}
                  placeholder={t('choose_charging_profile')}
                  onKeyDown={this.handleKeyDown.bind(this, ref)}
                  onChange={this.chargingProfileChange.bind(this)}
                  // onFocus={this.ClearChargepoint.bind(this,ref)} 
                  onBlur={this.getprofileNames}
                  id="rbt_id"
                  ref={ref}
                />
                <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}>
                  <span aria-hidden="true">×</span>
                </button>
                <div class="input-group-btn">
                  <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)} >{t('find')}</button>
                </div>
                {/* <MDBBtn color='primary' type="submit" className="btn btn-primary sm_btn btn-sm mt-1 pull-right" style={{color:"#fff"}} onClick={this.FindCpName.bind(this,ref)}
 >
                    Find
                </MDBBtn> */}
                <span className="pull-left error_msg w3-animate-top mt-1" id="notexists" style={{ color: "red" }}><span style={{ color: "red" }}>{this.state.errors["chargePointNotExists"]}</span></span>
                <span className="pull-left error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.state.errors["selectChargepoint"]}</span></span>
              </div>
            </div>
          </div>    {/* end page menu bar  */}    {/* table start */}
          <div className="row mt-2 ">
            <div className="col-md-12">
              <Table striped hover size="sm" className="page-table">
                <thead>
                  <tr>
                    <th className="">{t('name')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                    {/* <th>Transaction Id</th> */}
                    <th className="">{t('stack_level')} <i class="fas fa-sort sortIcon" onClick={this.stackLevelSorting.bind(this)}></i></th>
                    <th className="">{t('purpose')}</th>
                    <th className="">{t('kind')}</th>
                    <th className="">{t('recurrency_kind')}</th>
                    <th className="">{t('valid_from')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                    <th className="">{t('valid_to')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                    <th className="">{t('Charging Schedule')}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.items.map((items, index) => {
                    var Fromdate = "";
                    var Fromdate1 = "";
                    var Todate = "";
                    var Todate1 = "";

                    if (items.validFrom === null || items.validFrom === "") {
                      Fromdate = "";
                      Fromdate1 = "";
                    } else {
                      Fromdate = moment(items.validFrom).format("DD MMM YYYY");
                      Fromdate1 = moment(items.validFrom).format("HH:mm");
                    }

                    if (items.validTo === null || items.validTo === "") {
                      Todate = "";
                      Todate1 = "";
                    } else {
                      Todate = moment(items.validTo).format("DD MMM YYYY");
                      Todate1 = moment(items.validTo).format("HH:mm");
                    }

                    return (
                      <tr>
                        <td className=""><Link to={{
                          pathname: '/viewChargingProfile',
                          state: items.chargingProfileId,
                        }}>{items.chargingProfileName}
                        </Link>
                        </td>
                        <td className="">{items.stackLevel}</td>
                        <td className="">{items.chargingProfilePurpose}</td>
                        <td className="">{items.chargingProfileKind}</td>
                        <td className="">{items.recurrencyKind}</td>
                        <td className="wrap-word">{Fromdate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {Fromdate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {Fromdate1} </div> </>}</td>
                        <td className="wrap-word">{Todate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {Todate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {Todate1} </div> </>}</td>
                        <td className=""><Link to={{
                          pathname: '/viewchargingSchedule',
                          state: items.scheduleId,
                        }}>{items.chargingSchedule}
                        </Link>
                        </td>
                      </tr>);
                  })
                  }
                </tbody>
              </Table>
            </div>
            <div className="col-md-12 mt-2 mb-4 text-right">
              <Pagination
                hideDisabled
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.pageSize}
                totalItemsCount={this.state.noOfRecords}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        {this.state.isLoading ? (
          <Loader loader={this.state.isLoading} />
          ) : ("")}
      </main>
    );
  }
}

//export default ChargingProfile;
export default withTranslation()(ChargingProfile)