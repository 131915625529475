//react imports
import React from 'react';

//react scheduler imports
import Scheduler, { SchedulerData, ViewTypes, CellUnits } from "react-big-scheduler";
import { DragDropContext as dragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

//third party imports
import moment from 'moment';

//react scheduler config
import schedulerConfig from '../common/schedulerConfig';
import { convertToGraphData } from '../common/ReservationDataConverter';

//react scheduler styles
import "react-big-scheduler/lib/css/style.css";

class schedulerComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.schedulerObject = new SchedulerData(new moment(new Date()), ViewTypes.Day, false, false, schedulerConfig);
        this.prevClick = this.prevClick.bind(this);
        this.nextClick = this.nextClick.bind(this);
        this.onSelectDate = this.onSelectDate.bind(this);
        this.eventClicked = this.eventClicked.bind(this);
        this.onScrollRight = this.onScrollRight.bind(this);
        this.onScrollLeft = this.onScrollLeft.bind(this);
        this.onScrollTop = this.onScrollTop.bind(this);
        this.onScrollBottom = this.onScrollBottom.bind(this);
        this.initialRender = this.initialRender.bind(this);
        this.updateEventEnd = this.updateEventEnd.bind(this);
        this.updateEventStart = this.updateEventStart.bind(this);
    }

    prevClick() {
        this.schedulerObject.prev();

        var today = moment(this.schedulerObject.startDate);
        today = today.subtract(1, "days");
        today = today.format("YYYY-MM-DD");
        today = today + "T18:30:00:000";
        localStorage.setItem('selectedDate', today);

        this.props.getReservationSlots(this.props.groupId, today);
    }

    nextClick() {
        this.schedulerObject.next();

        var today = moment(this.schedulerObject.startDate);
        today = today.subtract(1, "days");
        today = today.format("YYYY-MM-DD");
        today = today + "T18:30:00:000";
        localStorage.setItem('selectedDate', today);

        this.props.getReservationSlots(this.props.groupId, today);
    }

    onSelectDate(schedulerData, data) {
        
        var today = moment(data._d);
        today = today.subtract(1, "days");
        today = today.format("YYYY-MM-DD");
        today = today + "T18:30:00:000";
        localStorage.setItem('selectedDate', today);

        this.schedulerObject.setDate(data._d)

        this.props.getReservationSlots(this.props.groupId, today);
    }

    eventClicked(schedulerData, event) {
        let isOnline = schedulerData.resources.find(obj => event.resourceId === obj.id)?.onlineStatus;

        if(isOnline){
            if (event.bgColor === 'DarkBlue') {
                let reservationId = event.reservationId;
                this.props.reserReservationId(reservationId)
                this.props.toggleOverview(reservationId);
            } else if(event.bgColor === 'Green'){
                let startDate = event.start;
                let endDate = event.end;
                let cpId = schedulerData.resources.find(obj => event.resourceId === obj.id).name;
                this.props.resertemail();
                this.props.setReservation(startDate, endDate, cpId);
                this.props.toggleAdd(this.props.groupId);
            }
        }
    }

    updateEventStart(schedulerData, event, newStart) {
        if (event.bgColor === 'Green') {
            this.props.resertemail();
            let startDate = event.start;
            let endDate = event.end;
            let cpId = schedulerData.resources.find(obj => event.resourceId === obj.id).name;
            let newStartDate = moment(newStart).utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "+0000";
            let latestStartdate = newStartDate < startDate ? startDate : newStartDate
            schedulerData.updateEventStart(event, latestStartdate);
            this.props.setReservation(latestStartdate, endDate, cpId);
        }
    }

    updateEventEnd(schedulerData, event, newEnd) {
        if(event.bgColor === 'Green'){
            this.props.resertemail();
            let startDate = event.start;
            let endDate = event.end;
            let cpId = schedulerData.resources.find(obj => event.resourceId === obj.id).name;
            let newEndDate = moment(newEnd).utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "+0000";
            let latestEnddate = newEndDate > endDate ? endDate : newEndDate
            schedulerData.updateEventEnd(event, latestEnddate);
            this.props.setReservation(startDate, latestEnddate, cpId);
        }
    }

    onScrollRight(schedulerData, schedulerContent, maxScrollLeft) {
        if (schedulerData.ViewTypes === ViewTypes.Day) {
            schedulerData.next();
            schedulerData.setEvents(this.props.calenderEvents);
            this.setState({
                viewModel: schedulerData
            });

            schedulerContent.scrollLeft = maxScrollLeft - 10;
        }
    }

    onScrollLeft(schedulerData, schedulerContent, maxScrollLeft) {
        if (schedulerData.ViewTypes === ViewTypes.Day) {
            schedulerData.prev();
            schedulerData.setEvents(this.props.calenderEvents);
            this.setState({
                viewModel: schedulerData
            });

            schedulerContent.scrollLeft = 10;
        }
    }

    onScrollTop(schedulerData, schedulerContent, maxScrollTop) { }

    onScrollBottom(schedulerData, schedulerContent, maxScrollTop) { }

    componentDidMount(){
        this.initialRender(this.props);
    }

    initialRender(nextProps) {
        let data = nextProps.reservationResponse;
        let grapgdata = convertToGraphData(data);

        this.schedulerObject.setResources(grapgdata.resources);
        this.schedulerObject.setEvents(grapgdata.events);
        this.schedulerObject.cellUnit = CellUnits.Hour;

        document.getElementById("loader_image_div").style.display = "none";
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        this.initialRender(nextProps);
    }

    render() {
        return (
            <>
                <Scheduler
                    schedulerData={this.schedulerObject}
                    prevClick={this.prevClick}
                    nextClick={this.nextClick}
                    onSelectDate={this.onSelectDate}
                    eventItemClick={this.eventClicked}
                    updateEventStart = {this.updateEventStart}
                    updateEventEnd={this.updateEventEnd}
                    onScrollLeft={this.onScrollLeft}
                    onScrollRight={this.onScrollRight}
                    onScrollTop={this.onScrollTop}
                    onScrollBottom={this.onScrollBottom}
                    dayStartFrom={0}
                />
            </>
        );
    }
}

export default dragDropContext(HTML5Backend)(schedulerComponent);