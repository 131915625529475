import dotenv from 'dotenv'
dotenv.config();

export const LoginPath = process.env.REACT_APP_APIPATH;
export const APIPath   = process.env.REACT_APP_APIPATH +"/web/";
export const WebPath   = process.env.REACT_APP_APIPATH +"/api/web/";
export const FOTAPath  = process.env.REACT_APP_APIPATH +"/api/";
export const URLPath = process.env.REACT_APP_APIPATH + "/api/";
export const DataPrivacyPath = process.env.REACT_APP_DATAPRIVACYPATH;
export const Imprint = process.env.REACT_APP_IMPRINT;
export const OcppHeartbeatMinValue = process.env.REACT_APP_HEARTBEAT_MINVALUE;
export const OcppHeartbeatMaxValue = process.env.REACT_APP_HEARTBEAT_MAXVALUE;
export const TERMSANDCONDITION_QR = process.env.REACT_APP_TERMSANDCONDITION;
export const DATAPRIVACYLINK_QR = process.env.REACT_APP_DATAPRIVACYQR;
export const FAQ = process.env.REACT_APP_FAQ;
export const HELPLINE = process.env.REACT_APP_HELPLINE;
export const CHANGE_AVILABILITY = "ChangeAvailability";
export const CHANGE_CONFIGURATION = "ChangeConfiguration";
export const CLEAR_CACHE = "ClearCache";
export const GET_DIAGNOSTICS = "GetDiagnostics";
export const REMOTE_START_TRANSACTION = "RemoteStartTransaction";
export const REMOTE_STOP_TRANSACTION = "RemoteStopTransaction";
export const RESET = "Reset";
export const UNLOCK_CONNECTOR = "UnlockConnector";
export const UPDATE_FIRMWARE = "UpdateFirmware";
export const RESERVE_NOW = "ReserveNow";
export const CANCEL_RESERVATION = "CancelReservation";
export const DATA_TRANSFOR = "DataTransfer";
export const GET_CONFIGURATION = "GetConfiguration";
export const GET_LOCAL_LIST_VERSION = "GetLocalListVersion";
export const SEND_LOCAL_LIST = "SendLocalList";
export const TRIGGER_MESSAGE = "TriggerMessage";
export const GET_COMPOSITE_SCHEDULE_REQUEST = "GetCompositeSchedule";
export const CLEAR_CHARGING_PROFILE_REQUEST = "ClearChargingProfile";
export const SET_CHARGING_PROFILE_REQUEST = "SetChargingProfile";
export const UPLOAD_DIAGNOSTICS = "uploadDiagnostics";