import React from 'react';
import { MDBRow, MDBInput, MDBCol, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from '../views/constants';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import $ from "jquery";
import { Alert } from 'reactstrap';
import Pagination from "react-js-pagination";


class productList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: false,
      startDate: new Date(),
      dataArray: [],
      modal2: false,
      page_title: "Products",
      org_name: "",
      fields: {
        productName: "",
        description: "",
        buildType: "",
        productCode: ""
      },
      orgId: "1",
      sucess: false,
      colour: 'sucess',
      message: "",
      errors: {},
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      orgsArray:[],
      user:{
        permissions:[]
      }
    }
    this.onShowAlert = this.onShowAlert.bind(this);
    this.sessiontoggle = this.sessiontoggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.getProductList = this.getProductList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.productCountApi = this.productCountApi.bind(this);
    this.getOrgProductCount = this.getOrgProductCount.bind(this);
  }
  handlePageChange(pageNumber) {
    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.getProductList(pageNumber, this.state.pageSize);
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ dataArray: pageData })
      }
    }
  }
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.state.data.clear();
    this.setState({ pageSize: entries });
    this.getProductList(1, entries);
  }
  tagOk() {
    this.props.history.push('/');
  }
  sessiontoggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });

  }
  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
    var errors = {};
    this.setState({ errors: errors })
  }
  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  async getProductList(pageNo, pageSize) {
    var url = baseUrl.WebPath + "dashboard/products?pageNo=" + pageNo + "&pageSize=" + pageSize;
    await fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization":localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.sessiontoggle();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ dataArray: response });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  async getOrgProductCount(orgId) {
    var url = baseUrl.WebPath + "dashboard/" + orgId + "/products/count";
    var header = {

    }
    await fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Authorization":localStorage.getItem("Authorization")
      }
    }).then((resp) => {
      if (resp.status == 401) {
        // alert("Access token is expired, please login again")
        this.toggle();
        localStorage.clear();
      }
      if (resp.status == 200) {
        return resp.json();
      }
    })
      .then((response) => {
        if (response != undefined) {
          this.setState({ noOfRecords: response.count });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  productCountApi() {
    var url = baseUrl.WebPath + "dashboard/products/count"
    var header = {

    }
    fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Authorization":localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          // alert("Access token is expired, please login again")
          this.toggle();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ noOfRecords: response.count });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  componentDidMount() {
    this.state.user.permissions = localStorage.getItem("roleAccess");
    var orgname = localStorage.getItem("tenant");
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({orgsArray : response.organisationDTOS })
        } else {
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
    this.productCountApi();
    this.getProductList(this.state.activePage, this.state.pageSize);

  }


  addProduct() {
    var errors = {};
    if (this.state.fields.productName == "") {

      errors["productNameError"] = "Please enter the product name."

    }
    if (this.state.fields.productCode == "") {

      errors["productCodeError"] = "Please enter the product code."
      this.setState({ errors: errors })
    }
    this.setState({ errors: errors })
    if (this.state.fields.productName != "" && this.state.fields.productCode != "") {

      let data = {
        "product_name": this.state.fields.productName,
        "description": this.state.fields.description,
        "build_name": this.state.fields.buildType,
        "product_code": this.state.fields.productCode
      }
      var url = baseUrl.WebPath + "dashboard/" + this.state.org_name + "/products";
      fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json",
          "Authorization":localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status == 201) {
            this.setState({ modal2: false })
            this.setState({ message: "New product is added sucessfully.", colour: 'success' })
            this.onShowAlert();
            this.productCountApi();
            this.getProductList(this.state.activePage, this.state.pageSize);
          }
          else if (resp.status == 401) {
            this.sessiontoggle();
            localStorage.clear();
          } else if (resp.status == 404) {
            return resp.json();
          } else {
            return resp.json();
          }
        }).then((response) => {
          var errors = {};
          if(response.status == 400){
            errors['productCodeError'] = response.message
            this.setState({ errors: errors })
          }
        }).catch((error) => {
          console.log(error, "catch the loop")
        })
    }
  }

  async getProducts() {
    var orgId = document.getElementById("organization").value;
    this.setState({ orgId: orgId });
    await this.getOrgProductCount(orgId);
    if (orgId != '') {
      var url = baseUrl.WebPath + "dashboard/" + orgId + "/products?pageNo=1&pageSize=" + this.state.pageSize;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization":localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.sessiontoggle();
            localStorage.clear();
          }
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {
            this.setState({ dataArray: response.productList });
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    } else {
      // this.setState({ dataArray: [] });
      this.getProductList(this.state.activePage, this.state.pageSize);
    }
  }


  render() {
    const data = this.state.dataArray.map((dataArray, index) => {
      var trsactionData = {
        date: this.state.startDate,
        dataArray
      }

      return (
        <tr key={index}>
          <td>{dataArray.product_name}</td>
          <td>{dataArray.product_code}</td>
          <td>{dataArray.description}</td>
          <td><Link to={{
            pathname: '/buildTypes',
            state: dataArray.product_id
          }}>view</Link></td>
          {/* <td><Link to={{
            pathname: '/dx2.1Builds',
            state: dataArray.product_id,

          }}>view</Link></td>
          <td><i class="fas fa-archive action-icon" title="Archive"></i></td> */}

        </tr>
      )
    })
    let userTenant = localStorage.getItem("tenant");
    const user = this.state.user;
    let selectedId = "";

    // const organisationDTOS = this.state.orgsArray.map((dataArray, index) => {
    //   if (userTenant === dataArray.identifier) {
    //     selectedId = dataArray.id
    //   }
    //   return (
    //     <option value={dataArray.id} selected={selectedId == dataArray.id} >{dataArray.company}</option>
    //   )
    // })

    const organisationDTOS = this.state.orgsArray.map((dataArray, index) => {
      return (
       <option value={dataArray.id}>{dataArray.company}</option>
     )
   })

    return (
      <>

        <main class="content-div">
        <p>Products
            <div className="breadcrumb_div">FOTA &gt; <span className="breadcrumb_page">Products</span></div>
          </p>
          {/* <p className="breadcrumb_div">Maintenance > FOTA</p> */}
          <div className="page-outerdiv">
            {/* page title */}
            {/* <p>Products</p> */}
            {/* end page title */}
            {/* page menu bar */}
            <div className="row menubar">
              <div className="col-md-9 ">
                <div className="mt-3">
                  <button onClick={this.toggle(2)}>
                    <i className="fa fa-plus">
                    </i>
                    <span>Add</span>
                  </button>
                </div>
              </div>
              <div className="col-md-3  pull-right">
                <select className="browser-default custom-select select_height" id="organization" onChange={this.getProducts.bind(this)}>
                  <option value="">Select Organization</option>
                  {organisationDTOS}
                </select>
              </div>
            </div>
            <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
            <div className="row mt-4">
              <div className="col-md-12">
                <label className="pull-left sel_lbl">Show entries</label>
                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left " >
                  <Form.Control as="select" className="form_select showentry_sel" id="showEntries" onChange={this.showEntriesChange}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </div>

            {/* end page menu bar  */}

            {/* table start */}
            <div className="row mt ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Product Code</th>
                      <th>Description</th>
                      <th>Build Types</th>
                      {/* <th>Builds Repo</th>
                      <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                    {
                      (this.state.dataArray.length == 0) ?
                        <tr>
                          <td colSpan="6" style={{ textAlign: "center" }}>No Data Available</td>
                        </tr> : null
                    }
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12">
                <Pagination
                  hideDisabled

                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>

              {/* End table  */}
            </div>
          </div>
        </main>

        <MDBModal isOpen={this.state.modal2} toggle={this.toggle(2)}>
          <MDBModalHeader toggle={this.toggle(2)}>Add Product</MDBModalHeader>
          <MDBModalBody className="needs-validation">
            <MDBRow>
              <MDBCol md="6">
                <select className="browser-default custom-select select_height" id="Organization" value={this.state.org_name} onChange={(e)=>{this.setState({org_name: e.target.value})}}>
                  <option value="">Select Organization</option>
                  {organisationDTOS}
                </select>
              </MDBCol>
              <MDBCol md="6" >
                <MDBInput
                  name="productName"
                  min="0"
                  pattern="[0-9]*"
                  id="productName"
                  label="Product Name*"
                  autoComplete="off"
                  onChange={this.changeHandler.bind(this, "productName")}
                >
                </MDBInput>
              </MDBCol>
              <MDBCol md="6" >
                <select className="browser-default custom-select select_height" id="productCode" value={this.state.fields.productCode} onChange={(e) => { let fields = this.state.fields; fields["productCode"] = e.target.value;  this.setState({ fields: fields }) }}>
                  <option value="">-- Select Product Code* --</option>
                  <option value="DXLITE">DXLITE</option>
                </select>
              </MDBCol>
              <MDBCol md="6" >
                <MDBInput
                  name="description"
                  min="0"
                  id="description"
                  label="Description"
                  autoComplete="off"
                  onChange={this.changeHandler.bind(this, "description")}
                >
                </MDBInput>
              </MDBCol>
              <div className='col-md-12'>
              <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.state.errors["productCodeError"]}</span></span>
              </div>
              
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="danger" onClick={this.toggle(2)}>Close</MDBBtn>
            <MDBBtn color="primary" onClick={this.addProduct.bind(this)}>Save</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal isOpen={this.state.modal} toggle={this.sessiontoggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.sessiontoggle}>Session has expired</MDBModalHeader>
          <MDBModalBody>
            Please login again.
          </MDBModalBody>
          <MDBModalFooter>
            {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
            <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default productList;