import React from 'react';
import { Link } from 'react-router-dom';
import * as baseUrl from '../views/constants';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
//API imports 
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes';

import { getSecurityHeaders, getActiveSecurityHeaders } from '../common/HttpRequestClass';


class Welcome extends React.Component {
    constructor(props) {
        super(props);
        let eSimLang;
        try {
            eSimLang = localStorage.eSimLang
          } catch (error) {
            eSimLang = '';
          }
        this.state = {
            email: "",
            lang: "EN",
            isLearMorePopupOpen: false,
            isInfoPopupOpen: false,
            termsOfservice: false,
            dataPrivacy: false,
            errors: {},
            isActivatePopupOpen: false,
            isEmailSentPopupOpen: false,
            isEmailVerifSuccessPopupOpen: false,
            isEmailVerifFailurePopupOpen: false,
            iccid: "",
            status: '',
            CPID:"",
            imei:"",
            simActivationDetails:{},
            activationResp: {},
            isSimAlreadyActivated:false,
            verifyESimData:{},
            errorMessageFromResponse : '',
            isErrorPopupOpen : false,
        }

        this.ChangeLanguage = this.ChangeLanguage.bind(this);
        this.handleLearnMore = this.handleLearnMore.bind(this);
        this.handleEmailInputChange = this.handleEmailInputChange.bind(this);
        this.handleInfoIcon = this.handleInfoIcon.bind(this);
        this.handleFirstCheckbox = this.handleFirstCheckbox.bind(this);
        this.handleSecondCheckbox = this.handleSecondCheckbox.bind(this);
        this.handleLearnMorePopup = this.handleLearnMorePopup.bind(this);
        this.handleInfoPopup = this.handleInfoPopup.bind(this);
        this.handleEmailBlur = this.handleEmailBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleActivatePopup = this.handleActivatePopup.bind(this);
        this.handleToggleActivate = this.handleToggleActivate.bind(this);
        this.handleEmailSentPopup = this.handleEmailSentPopup.bind(this);
        this.handleEmailVerifSuccess = this.handleEmailVerifSuccess.bind(this);
        this.handleEmailVerifFailure = this.handleEmailVerifFailure.bind(this);
        this.getEsimCardDetails = this.getEsimCardDetails.bind(this);
        this.activateESim = this.activateESim.bind(this);
        this.handleSimAlreadyActivated = this.handleSimAlreadyActivated.bind(this);
        this.handleErrorPopup = this.handleErrorPopup.bind(this);
    }

    handleErrorPopup(){
        this.setState({isErrorPopupOpen : false})
    }
    async activateESim() {
        document.getElementById("loader_image_div").style.display = "block";
        let errors = {};
        var Apiurl = baseUrl.LoginPath + "/sim/activation/activate?icicid="+this.state.iccid+"&email="+this.state.email+"&cpid="+this.state.CPID;

        await fetch(Apiurl, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                'Authorization': "Bearer",
            }
        })
            .then((resp) => {
                if (resp.status === 401 || resp.status === 500) {
                    
                }
                return resp.json();
            })
            .then((response) => {

                if(response.status === 500 || response.status === 401 || response.status === 404 ||response.status === 400 ){
                    if(response.message === "sim_wrong_email"){
                        document.getElementById("loader_image_div").style.display = "none";

                      errors["isemailEmpty"] = this.props.t("your_email_address_required");
                      this.setState({
                          errors:errors
                      })
                    } else if(response.message === "simcard_already_activated" || response.message === "sim_verification_limit"){
                        document.getElementById("loader_image_div").style.display = "none";
                        this.setState({errorMessageFromResponse : response.message}, ()=>{
                            this.setState({isErrorPopupOpen : true})
                        })
                        
                    }
                   
                    else{
                       if(response.status === 400 || response.status === 404 || response.status === 400){
                        document.getElementById("loader_image_div").style.display = "none";
                        localStorage.setItem('page', 'esimError');
                        this.props.setPage('esimError');
                       }
                    }
                  }else{
                    this.setState({
                        activationResp:response
                    })
                    if(response.message === "sim_activation_verify_mail_sent"){
                        document.getElementById("loader_image_div").style.display = "none";
                        this.setState({isEmailSentPopupOpen: true})
                    }
                }
            })
            .catch((error) => {
                document.getElementById("loader_image_div").style.display = "none";
                console.log(error, "catch the loop")
            })
    }

    async verifyESim(id) {
        document.getElementById("loader_image_div").style.display = "block";
        let errors = {};
        var Apiurl = baseUrl.LoginPath + "/sim/activation/verify?id="+id;
        await fetch(Apiurl, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                'Authorization': "Bearer",
            }
        })
            .then((resp) => {
                if (resp.status === 401 || resp.status === 500) {
                 
                }
                return resp.json();
            })
            .then((response) => {
                if(response.status === "FAILURE" || response.status===404 || response.status===400 || response.status===500){
                    if(response.errorMessage === "sim_activation_invalid_link"){
                        document.getElementById("loader_image_div").style.display = "none";
                        this.setState({ isEmailVerifFailurePopupOpen: true})
                      }else if(response.errorMessage === "simcard_already_activated"){
                        document.getElementById("loader_image_div").style.display = "none";
                        this.setState({ isSimAlreadyActivated: true})
                      }else{
                        document.getElementById("loader_image_div").style.display = "none";
                        localStorage.setItem('page', 'esimError');
                        this.props.setPage('esimError');
                      }
                }else{
                    if(response.status === "SUCCESS" && response.message === "simcard_already_activated"){
                        document.getElementById("loader_image_div").style.display = "none";
                        this.setState({ isSimAlreadyActivated: true, verifyESimData:response})
                        
                    }else{
                        document.getElementById("loader_image_div").style.display = "none";
                        this.setState({
                            isEmailVerifSuccessPopupOpen:true
                        })
                    }
                }
            })
            .catch((error) => {
                document.getElementById("loader_image_div").style.display = "none";
                console.log(error, "catch the loop")
            })
    }

    async getEsimCardDetails() {
        document.getElementById("loader_image_div").style.display = "block";

        let errors = {};
        var Apiurl = baseUrl.LoginPath + "/sim/activation/details?icicid="+this.state.iccid+"&cpid="+this.state.CPID+"&imei=" + this.state.imei;
        await fetch(Apiurl, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                'Authorization': "Bearer",
            }
        })
            .then((resp) => {
                if (resp.status === 401 || resp.status === 500) {
                }
                return resp.json();
            })
            .then((response) => {

                if(response.status === 500 || response.status === 401 || response.status === 404 ){
                    if(response.message === "simcard_already_activated" || response.message === "charger_iccid_not_matched" || response.message === "imei_iccid_not_matched"){
                        document.getElementById("loader_image_div").style.display = "none";
                        this.setState({errorMessageFromResponse : response.message}, ()=>{
                            this.setState({isErrorPopupOpen : true})
                        })
                    }
                    else{
                        document.getElementById("loader_image_div").style.display = "none";
                        localStorage.setItem('page', 'esimError');
                        this.props.setPage('esimError');
                    }
                }else{
                    document.getElementById("loader_image_div").style.display = "none";

                      this.setState({
                        simActivationDetails:response
                       })
                       localStorage.setItem('simActDetails',JSON.stringify(response));
                    //    response.status = "DEACTIVATED";
                       if(response.status === "ACTIVATED"){
                        localStorage.setItem('simActDetails',JSON.stringify(response));
                        localStorage.setItem('page', 'esimActivated');
                        this.props.setPage('esimActivated');
                       } else {                
                        localStorage.setItem('page', '');
                        this.props.setPage('');
                       }
                }
            })
            .catch((error) => {
                document.getElementById("loader_image_div").style.display = "none";
                console.log(error, "catch the loop")
            })
    }

    handleEmailVerifFailure() {
        this.setState({ isEmailVerifFailurePopupOpen: false, isEmailSentPopupOpen: false });
    }
    handleEmailVerifSuccess() {
        this.setState({ isEmailVerifSuccessPopupOpen: false, isEmailSentPopupOpen: false })
    }
    handleSimAlreadyActivated(){
        this.setState({ isSimAlreadyActivated: !this.state.isSimAlreadyActivated});
    }
    
    
    handleEmailSentPopup() {
         this.setState({isEmailSentPopupOpen : false })
        // this.setState({isEmailVerifSuccessPopupOpen : true})
       // this.setState({ isEmailVerifFailurePopupOpen: true })
    }
    handleToggleActivate() {
        this.setState({ isActivatePopupOpen: false })
    }
    handleActivatePopup() {
        this.setState({ isActivatePopupOpen: false })
        this.activateESim();
    }

    handleSubmit(){
        var errors = {}
        var email = this.state.email;
        var check1 = this.state.termsOfservice;
        var check2 = this.state.dataPrivacy;
        var checkBoxError = this.state.ischBoxError;
        var isActivatePopupOpen = this.state.isActivatePopupOpen;
        if(email !== "" && email !== null) {
            if(check1 && check2) {
                if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
                    errors["isemailEmpty"] = this.props.t("enter_valid_email_id");
                  }
                else {
                    isActivatePopupOpen = true;
                }
            } else{
                errors["checkBoxError"] = true;
            }
        } else {
            errors["isemailEmpty"] = this.props.t("enter_your_email");
 
        }

        this.setState({errors : errors, isActivatePopupOpen: isActivatePopupOpen})
    }

    handleEmailBlur(e) {
        let errors = {};
        let email = e.target.value;
        if (email !== "") {
            if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
                errors["isemailEmpty"] = this.props.t("enter_valid_email_id");
            }
        } else {
            errors["isemailEmpty"] = this.props.t("enter_your_email");
        }
        this.setState({ errors: errors });
    }

    ChangeLanguage(lang) {
        if (lang === "Deutsch") {
            this.props.i18n.changeLanguage('de')
            var language = 'DE'
        } else if (lang === "English") {
            this.props.i18n.changeLanguage('en')
            var language = 'EN'
        }
        this.setState({
            lang: language,
        })
        localStorage.setItem("Language", lang)
    }

    aesMethod = (master_password_n) => {
        var aesjs = require('aes-js');
        // An example 128-bit key
        var key = [49, 50, 51, 52, 53, 54, 55, 56, 57, 49, 49, 50, 51, 52, 53, 54];

        // The initialization vector (must be 16 bytes)
        var iv = [48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48];

        // Convert text to bytes (text must be a multiple of 16 bytes)
        var textBytes = aesjs.utils.utf8.toBytes(master_password_n);

        var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
        var encryptedBytes = aesCbc.encrypt(aesjs.padding.pkcs7.pad(textBytes));

        // To print or store the binary data, you may convert it to hex
        var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
        return encryptedHex;
    }

    componentDidMount() {
        const queryParamsURL = window.location.search;
        let param =  queryParamsURL.split("?")
        const queryParams = new URLSearchParams(param[1])
        let id = queryParams.get("id") ?  queryParams.get("id").trim() : "";
        let iccid = queryParams.get("iccid") ? this.aesMethod(queryParams.get("iccid").trim()) :"";
        let CPID = "";
        let imei = "";
        if(iccid){
             iccid = this.aesMethod(queryParams.get("iccid").trim());
             CPID = this.aesMethod(queryParams.get("CPID").trim());
             imei = this.aesMethod(queryParams.get("imei").trim());
             this.setState({
                iccid:iccid,
                CPID:CPID,
                imei:imei
            }, () => {this.getEsimCardDetails();})
        }else{
            var a = window.location.href;
            var id_O = a.split("/");
            let id= id_O[id_O.length-1]
            this.verifyESim(id);
        }
        
        let Language = localStorage.getItem("Language");
        if(Language === 'Deutsch'){
            this.props.i18n.changeLanguage('de')
            this.setState({
                lang: 'DE'
              })
        } else{
            this.props.i18n.changeLanguage('en')
        }
    }

    handleLearnMore() {
        this.setState({ isLearMorePopupOpen: true })
    }

    handleEmailInputChange(e) {
        this.setState({ email: e.target.value, errors: {} })
    }

    handleInfoIcon() {
        this.setState({ isInfoPopupOpen: true })
    }

    handleFirstCheckbox(e) {
        this.setState({
            termsOfservice: e.target.checked, errors: {}
        })
    }
    handleSecondCheckbox(e) {
        this.setState({
            dataPrivacy: e.target.checked, errors: {}
        })
    }
    handleLearnMorePopup() {
        this.setState({ isLearMorePopupOpen: false })
    }
    handleInfoPopup() {
        this.setState({ isInfoPopupOpen: false })
    }

    render() {
        const { t } = this.props;

        return (
            <main className='esim-main-container'>
                <div className='container-fluid p-0 m-0'>
                    <div className='row '>
                        {/* left side */}
                        <div className='d-none d-md-block col-md-5'>
                            <div className='esim-welcome m-0'>
                                <div className='esim-welcome-2 p-3' >
                                    <h1 className='text-center esim-h1' >{t('welcome')}</h1>
                                    <h1 className='text-center esim-h1'>{t('lets_get_started')}</h1>
                                </div>
                            </div>
                        </div>
                         
                         {/* right side */}
                        <div className="col-sm-12 col-md-7 pt-1 mb-3">
                            <nav className="nav_bar pl-0 pr-0">
                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/webasto-logo.png"} className="logo esim-navbar-logo ml-1" alt="logo" width="105" />
                                <ul className="navbar-nav ml-auto">
                                    <li id="navbar-static-tools" className="nav-item">
                                        <span className="nav-link esim-lang-navitem mr-4" id="navbar-tools" data-toggle="dropdown" aria-haspopup="false" aria-expanded="true">
                                            <img src={process.env.PUBLIC_URL + '/assets/img/globe.svg'} className="d-inline-block align-top logo esim-flag-icon" alt="globe" />
                                            <span className='esim-nav-lang'>{this.state.lang}</span>
                                            <i className="fas fa-caret-down fa-sm esim-fa-icon"></i>
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-right drop_menu  pt-1 pb-1" aria-labelledby="userDropdown">
                                            <span className="dropdown-item cursor-p" onClick={this.ChangeLanguage.bind(this, 'English')}>
                                                <span className="esim-drpdwn-item">
                                                    {/* <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/eng.svg"}  className="d-inline-block align-top logo flag_icon country_img" alt="eng" /> */}
                                                    English (EN)</span>
                                            </span>
                                            <span className="dropdown-item cursor-p " onClick={this.ChangeLanguage.bind(this, 'Deutsch')}>
                                                <span className="esim-drpdwn-item">
                                                    {/* <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/germany.svg"}  className="d-inline-block align-top logo flag_icon country_img" alt="germany" /> */}
                                                    {/* {this.props.t('german')}</span> */}
                                                    Deutsch (DE)</span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                            <div className="welcome_bg d-md-none mt-3">
                                <h4 className='text-center' style={{ color: "#fff", fontWeight: "bold" }}>{t('welcome')}</h4>
                                <h4 className='text-center' style={{ color: "#fff" }}>{t('lets_get_started')}</h4>
                            </div>
                            <div className='esim-welcome-child1'>
                                <h5 className='esim-h3'>{t('activate_esim_of_your_webasto_charging_station')}</h5>
                                {/* <h5 className='esim-h3 d-block d-md-none'>{t('activate_esim_of_your')} <br /> {t('webasto_charging_station_mobile')}</h5> */}
                                <p className='esim-para' style={{lineHeight: "30px"}}>{t('your_charging_station_id')}:<br />
                                    <span className='esim-h3'>{this.state.simActivationDetails?.chargePointId}</span></p>
                                <p className='esim-para'>{t('the_first_12months_free')}</p>
                                <p className='esim-para'><span className='esim-learnmore' onClick={this.handleLearnMore}>{t('learn_more')}</span> {t('about_future_subscription')}</p>
                                <div className='d-flex flex-column justify-content-center align-items-center pb-2 mb-3'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <input type='text' value={this.state.email} className='esim-input' placeholder={`${t('enter_email_address')}*`} onChange={this.handleEmailInputChange}  
                                        // onBlur={this.handleEmailBlur} 
                                        />
                                        <i class="fa fa-info-circle cursor-p mt-1 mr-2" onClick={this.handleInfoIcon}></i>
                                    </div>
                                    <span className="error_msg w3-animate-top mt-1 ml-0 mr-4"><span className='esim-error-msg'>{this.state.errors["isemailEmpty"]}</span></span>
                                </div>
                            </div>
                            <div className='esim-welcome-child2'>
                                <div className={this.state.errors["checkBoxError"] ? 'error-checkbox-div' : ''}>
                                    <div id='checkboxs1' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                                        {/* <div className="form-check esim-checkbox-div">
                                                <input type="checkbox" className="esim-checkbox"  id="checkBox3" onChange={this.handleFirstCheckbox.bind(this)} checked={this.state.termsOfservice} />
                                                <label htmlFor="checkBox3" style={{color:'black'}}>{t('i_agree_to_the')} <a href={baseUrl.TERMSANDCONDITION_QR} target="_blank" className='a_tag'>{t('terms_and_conditions')}</a></label>
                                            </div> */}
                                        <div className="esim-checkbox-div">
                                            <input type="checkbox" className="esim-checkbox" id="checkBox3" onChange={this.handleFirstCheckbox.bind(this)} checked={this.state.termsOfservice} />
                                            <label className="form-check-label esim-label" htmlFor="checkBox3" style={{ color: 'black' }}>{t('iagree_terms_condition')} <a href={baseUrl.TERMSANDCONDITION_QR} target="_blank" className='a_tag1'>{t('terms_and_conditions')}</a></label>
                                        </div>

                                        <div className="esim-checkbox-div mt-2">
                                            <input type="checkbox" className="esim-checkbox" id="checkBox4" onChange={this.handleSecondCheckbox.bind(this)} checked={this.state.dataPrivacy} />
                                            <label className="form-check-label esim-label" htmlFor="checkBox4" style={{ color: 'black' }}>{t('iagree_data_privacy')} <a href={baseUrl.DATAPRIVACYLINK_QR} target="_blank" className='a_tag1'>{t('data_privacy')}</a></label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='text-center activate_now_btn'>
                                <button type="button" className='esim-btn' onClick={this.handleSubmit}>{t('activate_now')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Loader */}
                <div className="esim_page_loader center" id="loader_image_div" style={{ display: "none" }}>
                        <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                    </div>

                <MDBModal isOpen={this.state.isLearMorePopupOpen} size="md" className="model-top">
                    <MDBModalBody className='text-center'>
                        <p className='esim-popup-para'>
                            {t('learn_more_popup_para1')}
                        </p>
                        <hr style={{ border: '1px solid gray', margin: '6px' }} />
                        <p className='esim-popup-para'>
                            {t('learn_more_popup_para2')}
                        </p>
                        <button className='esim-popup-btn' onClick={this.handleLearnMorePopup}>
                            {t('close')}
                        </button>
                    </MDBModalBody>
                </MDBModal>

                <MDBModal isOpen={this.state.isInfoPopupOpen} size="md" className="model-top">
                    <MDBModalBody className='text-center'>
                        <h5 className='esim-popup-para' style={{ fontWeight: 'bold' }}>{t('please_note')}</h5>
                        <p className='esim-popup-para'>
                            {t('esim_info_popup')}
                        </p>
                        <button className='esim-popup-btn' onClick={this.handleInfoPopup}>
                            {t('close')}
                        </button>
                    </MDBModalBody>
                </MDBModal>

                <MDBModal isOpen={this.state.isActivatePopupOpen} size="md" className="model-top">
                    <MDBModalBody className='text-center'>
                        <h5 className='esim-popup-para' style={{ fontWeight: 'bold' }}>{t('activate_now')}</h5>
                        <p className='esim-popup-para'>
                            {t('are_you_sure_to_receive_email_confirmation')}
                        </p>
                        <div className='esim-activate-btndiv'>
                            <button className='esim-popup-btn' onClick={this.handleActivatePopup}>
                                {t('yes')}
                            </button>
                            <button className='esim-popup-btn1' onClick={this.handleToggleActivate}>
                                {t('no')}
                            </button>
                        </div>

                    </MDBModalBody>
                </MDBModal>

                <MDBModal isOpen={this.state.isEmailSentPopupOpen} size="md" className="model-top">
                    <MDBModalBody className='text-center'>
                        <p className='esim-popup-para p15'>
                            {t('verification_email_sent_please_confirm_toactivate')}
                        </p>
                        <button className='esim-popup-btn' onClick={this.handleEmailSentPopup}>
                            {t('close')}
                        </button>
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.isEmailVerifSuccessPopupOpen} size="md" className="model-top">
                    <MDBModalBody className='text-center'>
                        <img
                            src={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                            width="60"
                            height="60"
                            alt=""
                        />
                        <p className='esim-popup-para'>
                            {t('activation_of_esim_successful')}
                        </p>
                        <button className='esim-popup-btn' onClick={this.handleEmailVerifSuccess}>
                            {t('close')}
                        </button>
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.isEmailVerifFailurePopupOpen} size="md" className="model-top">
                    <MDBModalBody className='text-center'>
                        <img
                            src={process.env.PUBLIC_URL + "/assets/img/not_successs.svg"}
                            width="60"
                            height="60"
                            alt=""
                        />
                        <p className='esim-popup-para'>
                            {t('email_verf_not_success')} {t('please_try_again_or_contact_support')}
                        </p>

                        <button className='esim-popup-btn' onClick={this.handleEmailVerifFailure}>
                            {t('close')}
                        </button>
                    </MDBModalBody>
                </MDBModal>
                {/* sim alredy activated message with details */}
                <MDBModal isOpen={this.state.isSimAlreadyActivated} size="md" className="model-top">
                    <MDBModalBody className='text-center'>
                        {/* <img
                            src={process.env.PUBLIC_URL + "/assets/img/not_successs.svg"}
                            width="60"
                            height="60"
                            alt=""
                        /> */}
                        <p className='esim-popup-para'>
                            {t('esim_charging_station_activated')}
                        </p>
                        <p className='esim-popup-para' >{t('your_charging_station_id')}:<br />
                        <span className='esim-popup-para' style={{ fontWeight: 'bold' }}>{this.state.verifyESimData.chargePointId}</span></p>
                        <p className='esim-popup-para'>{t('expiration_date')}: <strong style={{ color: 'black' }}>{this.state.verifyESimData.expirationDate ? moment(this.state.verifyESimData?.expirationDate).format("DD MMM YYYY") : ""}</strong></p>
                        <p className='esim-popup-para'>{t('remaining_duration')}: <strong style={{ color: 'black' }}>{this.state.verifyESimData.remainingDays} {t('days')}</strong></p>

                        <button className='esim-popup-btn' onClick={this.handleSimAlreadyActivated}>
                            {t('close')}
                        </button>
                    </MDBModalBody>
                </MDBModal>
                {/* error popup */} 
                <MDBModal isOpen={this.state.isErrorPopupOpen} size="md" className="model-top">
                    <MDBModalBody className='text-center'>
                        <img
                            src={process.env.PUBLIC_URL + "/assets/img/not_successs.svg"}
                            width="60"
                            height="60"
                            alt=""
                        />
                        <p className='esim-popup-para'>
                            {t(this.state.errorMessageFromResponse)}
                        </p>

                        <button className='esim-popup-btn' onClick={this.handleErrorPopup}>
                            {t('close')}
                        </button>
                    </MDBModalBody>
                </MDBModal>
                
            </main>
        )
    }
}

export default withTranslation()(Welcome);
