export function getHeaders() {
    
    let headers = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization")
        }
    }
    return headers;
}

export function postHeaders(bodyData) {

    let headers = {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization")
        }
    }

    return headers;
}