import React from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import { Typeahead } from 'react-bootstrap-typeahead';
import $ from "jquery";
import { createBrowserHistory } from 'history'
import Pagination from "react-js-pagination";
import servicesUrl from '../common/servicesUrl';
import { withTranslation } from 'react-i18next';
import Loader from '../views/loader';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

const history = createBrowserHistory();
class inventoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpIdSorting: false,
      hideActionBtn: "none", // show hide btn varible based on permission 
      writePermission: this.props.ChargePoint_W, // ChargePoint_W conndition
      model: false,
      startDate: new Date(),
      pageNo: 1,
      owtCPData: [],
      dataArray: [],
      orgName: "",
      activeArray: [],
      options: [],
      items: [],
      list: [],
      intial_data: [],
      modal2: false,
      modal3: false,
      page_title: "OWT Charge Points",
      org_name: "",
      fields: {
        productName: "",
        description: "",
        buildType: "",
        productCode: "",
        serialnumber: ""
      },
      orgId: "",
      sucess: false,
      colour: 'sucess',
      message: "",
      errors: {},
      noOfRecords: '',
      activePage: 1,
      data: new Map(),
      pageSize: "10",
      identifier: "",
      modalsession: false,
      loading: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.organizationChange = this.organizationChange.bind(this);
    this.getowtChargePoint = this.getowtChargePoint.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessionOk = this.sessionOk.bind(this);
    this.ClearChargepoint = this.ClearChargepoint.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.serialnoChange = this.serialnoChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.tagOk1 = this.tagOk1.bind(this);
    this.warningModelOk = this.warningModelOk.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.FindChargePoint = this.FindChargePoint.bind(this);
    this.onBlurEvent = this.onBlurEvent.bind(this);


  }

  tagOk1() {
    window.location.reload();
  }

  toggle1() {
    this.setState({
      modal1: !this.state.modal1
    });
  }

  toggle3() {
    this.setState({
      modal3: !this.state.modal3
    });
  }

  warningModelOk() {
    this.setState({
      modal3: !this.state.modal3
    });
  }

  serialnoChange(serialNo) {

    if (serialNo.length === 0) {
      this.getowtChargePoint(this.state.pageSize, this.state.activePage)
      this.setState({
        owtCPData: this.state.items,
      });
    } else {
      let errors = {};
      errors["chargePointNotExists"] = "";
      errors["selectChargepoint"] = "";
      this.setState({
        errors: errors
      });
      this.getChargePointData(serialNo);
    }
  }


  onBlurEvent(event) {
    var chargePoint = event.target.value;
    if (chargePoint.length === 0) {
      this.getowtChargePoint(this.state.pageSize, this.state.activePage)
      this.setState({
        owtCPData: this.state.items,
      });
    }
    let errors = {};
    errors["chargePointNotExists"] = "";
    errors["selectChargepoint"] = "";
    this.setState({
      errors: errors
    });
  }

  handleKeyDown(ref, e) {



    var serial_no = e.target.value;
    $("#nochargeexists").hide();

    if (serial_no.length > 1) {
      $("#selectcharge").hide();
    } else {
      $("#selectcharge").show();

      this.getowtChargePoint(this.state.pageSize, this.state.activePage)

    }

    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
    if (e.keyCode === 13) {

      $("#rbt_id").hide();
    }


    if (!e) {
      e = window.event;
    }

    if (!e.ctrlKey && e.key.length === 1 && (isNaN(+e.key) || e.key === " ")) {
      return e.preventDefault();
    }

    // var getList = this.state.intial_data;
    // if(key === "Backspace" || key === "Delete"){
    //   if(serial_no.length <= 1){
    //       getList = [];
    //       this.setState({
    //           options:getList,
    //         });
    //       }else{
    //         this.getChargePointList(serial_no)
    //       }
    //   }else{
    var sno = serial_no.slice(0, serial_no.length - 1)
    this.getChargePointList(sno)
    if (e.keyCode === 13) {
      $("#selectcharge").hide();
      this.FindChargePoint(ref, e);

    }
    if ((e.keyCode === 86 && ctrl)) {

      $("#selectcharge").hide();
      this.getChargePointList(serial_no)
    }


  }


  async getChargePointList(cpId) {

    var url = baseUrl.URLPath + servicesUrl.getOwtinventoriesSearchByCp + "?chargepointId=" + cpId;
    //devcpms.evprowebasto.com/cpms/api/owtinventories/search/{chargepointId}
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      this.setState({
        options: response,
        intial_data: response
      });
    }

  }


  async getChargePointData(cpId) {
    this.setState({ loading: true })
    var errors = {};
    if (cpId === null || cpId === "") {
      errors["selectChargepoint"] = this.props.t('enter_charge_point_id');
      this.setState({
        errors: errors
      });
    }
    else {
      var url = baseUrl.URLPath + servicesUrl.getOwtinventoriesBychargepointId + "?chargepointId=" + cpId;
      let type = ApiMethodTypes.GET;
      let headers = getSecurityHeaders();
      let payload = {};

      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if (response !== undefined) {
        if (response.owtInventories.length === 0 && response.count === null) {
          errors["chargePointNotExists"] = this.props.t('charge_point_id_not_exists.');
          this.setState({
            errors: errors, loading: false
          });
          $("#nochargeexists").show();
        } else {
          $("#nochargeexists").hide();
          this.setState({ owtCPData: response.owtInventories, noOfRecords: 0, loading: false });
        }
      }
    }
  }

  async togglesession() {
    ;
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  sessionOk() {
    history.push('/');
    window.location.reload();
  }

  organizationChange(event) {
    var orgname = event.target.value;
    var x = document.getElementById("organization").value;
    this.setState({
      orgName: orgname,
      identifier: x
    })
  }

  ClearChargepoint(ref, event) {
    let errors = {};
    errors["chargePointNotExists"] = "";

    this.setState({
      errors: errors,
      pageSize: 10,
      activePage: 1
    }, () => {
      this.getowtChargePoint(this.state.pageSize, 1);
    });

    ref.current.clear();

    this.setState({
      options: [],
      errors: {}
    })
  }

  //  function after clicking on checkbox to stored cpid in an array 
  handleChange(id, cpid, event) {

    var checkedValue = document.getElementById(id).checked;
    if (checkedValue === true) {
      this.state.list.push(cpid);
      this.setState({
        activeArray: this.state.list
      });
    } else {
      const index = this.state.list.indexOf(cpid);
      if (index > -1) {
        this.state.list.splice(index, 1);
      }
      this.setState({
        activeArray: this.state.list
      });
    }

  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    this.setState({
      activePage: 1,
      pageSize: entries
    }, () => {
      this.getowtChargePoint(entries, 1);
    })
  }

  //after clicking on pagination  
  handlePageChange(pageNumber) {

    if (pageNumber !== this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData === undefined) {
        this.setState({ activePage: pageNumber });
        this.getowtChargePoint(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ orgArray: pageData })
      }
    }
  }

  async getowtChargePoint(pageSize, pageNo) {
    this.setState({ loading: true })
    var url = baseUrl.URLPath + "owtinventories?pageNo=" + pageNo + "&pageSize=" + pageSize;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({
        owtCPData: response.owtInventories,
        noOfRecords: response.count.count,
        items: response.owtInventories, 
        loading: false
      })
    }
  }

  async getAccount() {
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      this.setState({ dataArray: response.organisationDTOS })
    }
  }

  componentDidMount() {

    this.getAccount();
    this.getowtChargePoint(this.state.pageSize, 1);
  }

  FindChargePoint(ref, event) {
    var cpId = ref.current.inputNode.value;
    var errors = {};

    if (cpId === null || cpId === "") {
      errors["connectorEmpty"] = this.props.t('enter_charge_point_id');
      this.setState({
        errors: errors
      });
      this.getowtChargePoint(this.state.pageSize, this.state.activePage)
    }
    else {
      errors["connectorEmpty"] = "";
      this.getChargePointData(cpId);
    }


    var serial_no = event.target.value;



    if (serial_no.length > 1) {
      $("#selectcharge").hide();
    } else {
      $("#selectcharge").show();
    }
  }





  //function for sorting table data on click
  ascendingOrder() {

    if (this.state.cpIdSorting === false) {
      this.state.owtCPData.sort(function (a, b) {
        return a.chargePointId - b.chargePointId;
      });
      this.setState({ owtCPData: this.state.owtCPData, cpIdSorting: true })
    } else {
      this.setState({ owtCPData: this.state.owtCPData.reverse(), cpIdSorting: false });
    }
  }



  render() {
    const { t } = this.props
    const ref = React.createRef();

    const inventory = this.state.owtCPData.map((owtCPData, index) => {
      return (
        <tr>
          {/* <td className="text-center"> 
                  <Form.Check 
                    custom
                    type="checkbox"
                    id={`custom-${owtCPData.id}`}
                    label=""
                    onChange={this.handleChange.bind(this,`custom-${owtCPData.id}`,owtCPData.id)}
                    key={owtCPData.chargePointId}
                  />
                </td> */}
          <td className="">{owtCPData.chargePointId}</td>
          <td className="">{owtCPData.modelName}</td>
          <td className="">{owtCPData.partNumber}</td>
          <td className="">{owtCPData.vendor}</td>
          <td className="">{owtCPData.serialNumber}</td>
        </tr>
      )
    })

    return (
      <>

        <main class="content-div" >
          <p>{t('OWT Charge Points')}
            <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('OWT Charge Points')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            {/* <div className="breadcrumb_div"> Maintenance > OWT Charge Points</div> */}
            {/* end page title */}
            {/* page menu bar */}
            <div className="row">
              <div className="col-md-4 pull-right">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead


                    labelKey="name"
                    id="rbt_id"
                    className="w_80"
                    multiple={this.state.multiple}
                    options={this.state.options}
                    placeholder={t('enter_charge_point')}
                    minLength={3}
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    onChange={this.serialnoChange.bind(this)}
                    onBlur={this.onBlurEvent}
                    // onFocus={this.ClearChargepoint.bind(this,ref)}
                    ref={ref}
                  />
                  <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}  >
                    <span aria-hidden="true" >×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindChargePoint.bind(this, ref)} >{t('find')}</button>
                  </div>
                  {/* <MDBBtn color='primary' type="submit" className="btn btn-primary sm_btn btn-sm mt-1 pull-right" style={{color:"#fff"}} onClick={this.FindChargePoint.bind(this,ref)}>
                      Find
                    </MDBBtn> */}
                  <span className="pull-left error_msg w3-animate-top mt-1" id="nochargeexists"><span style={{ color: "red" }}>{this.state.errors["chargePointNotExists"]}</span></span>
                  <span className="pull-left error_msg w3-animate-top mt-1" id="selectcharge"><span style={{ color: "red" }}>{this.state.errors["connectorEmpty"]}</span></span>
                </div>

              </div>


              <div className="col-md-8">
                <div className="pull-right">
                  <label className="pull-left sub-text">{t('show_entries')}</label>
                  <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>

              </div>
            </div>
            {/* end page menu bar  */}
            {/* table start */}
            <div className="row">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th className="">{t('charge_point_id')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('model')}</th>
                      <th className="">{t('part_no')}</th>
                      <th className="">{t('Vendor')}</th>
                      <th className="">{t('mes_serial_no')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventory}
                  </tbody>
                </Table>
              </div>
              {/* End table  */}
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </div>
          {/* popup for msg popup */}
          <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle1}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('org_assigned_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.tagOk1}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modal3} toggle={this.toggle3} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle3}>{t('Warning')}</MDBModalHeader>
            <MDBModalBody>
              {t('select_charge_point_id_and_org')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.toggle2}>Close</MDBBtn> */}
              <MDBBtn color="primary" onClick={this.warningModelOk}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.sessionOk}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {this.state.loading ? (
            <Loader loader={this.state.loading} />
          ) : ("")}

        </main>
      </>
    );
  }
}

export default withTranslation()(inventoryList);