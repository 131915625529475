//react imports
import React from 'react';
import ReactDOM from 'react-dom';

//react bootstrap imports
import { Form, Table } from 'react-bootstrap';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCol } from "mdbreact";

//typescript imports
import { Typeahead } from 'react-bootstrap-typeahead';

//chart imports
import { Doughnut } from "react-chartjs-2";

//pagination imports
import Pagination from "react-js-pagination";

//third party imports
import moment from 'moment';
import $ from "jquery";
import DatePicker from "react-datepicker";

//i18n multilingual imports
import { withTranslation } from 'react-i18next';

//modal imports
import CustomModal from '../common/CustomModal';

//common imports
import * as roles from '../views/roles';

//react component imports
import CalenderGraph from '../components/CalenderGraph';
import Loader from '../views/loader';

//API imports 
import * as baseUrl from '../views/constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import {getSecurityHeaders} from '../common/HttpRequestClass'

//react scheduler styles
import "react-big-scheduler/lib/css/style.css";
import { hasPermission } from '../views/auth';
class ReservationAndSecondSeatingTemplate extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            getChargersBasedonEmail: [],
            modalsession: false,
            errors: {},
            email: '',
            user: {
                permissions: []
            },
            warningPopup: false,
            warningMsg: "",
        }
        this.resertemail = this.resertemail.bind(this);
        this.getChargersBasedOnEmailAndSiteid = this.getChargersBasedOnEmailAndSiteid.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.emailKeyPress = this.emailKeyPress.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.clearEmail = this.clearEmail.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.reserveSuccess = this.reserveSuccess.bind(this);
        this.getReservationSlots = this.getReservationSlots.bind(this);
        this.resetGetChargersBasedOnEmailid = this.resetGetChargersBasedOnEmailid.bind(this);
        this.closeWarningPopup = this.closeWarningPopup.bind(this);
    }

    closeWarningPopup = () => {
        this.setState({ warningPopup: !this.state.warningPopup })
    }

    resetGetChargersBasedOnEmailid(){
        this.state.getChargersBasedonEmail = [];
        this.setState({
            getChargersBasedonEmail: []
        })
    }

    componentWillMount(){
        var user = {
            permissions: []
        }
        user.permissions = localStorage.getItem("roleAccess");
        this.setState({
            user:user
        })
    }

    async getReservationSlots(id, date){
        let targetId = localStorage.getItem('targetIndex')
        document.getElementById("loader_image_div").style.display = "block";
        let timeZone =  moment.tz.guess();
        let url = baseUrl.URLPath + servicesUrl.getReservationChargersAndSlots + "?siteId=" + id + "&reservarionDate=" + date + "&timeZone=" + timeZone;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t,true);
        if (response != undefined && response?.length > 0) {
            setTimeout(() => {
                this.setState({
                    reservationResponse: response
                }, () => {
                    localStorage.setItem('setOpenedId', targetId);
                    ReactDOM.render(
                        <CalenderGraph
                            reservationResponse={this.state.reservationResponse}
                            resertemail={this.resertemail}
                            setReservation={this.props.setReservation}
                            calenderResources={this.props.calenderResources}
                            calenderEvents={this.props.calenderEvents}
                            toggleOverview={this.props.toggleOverview}
                            getReservationSlots={this.getReservationSlots}
                            groupId={id}
                            toggleAdd={this.props.toggleAdd}
                            reserReservationId={this.props.reserReservationId}
                            resetGetChargersBasedOnEmailid = {this.resetGetChargersBasedOnEmailid}
                            resetSlotsBasedOnCpId = {this.props.resetSlotsBasedOnCpId}
                        />, document.getElementById(targetId)
                    );
                });
            }, 1);
        } else if(response.status === 404 || response.status === 400 || response.status === 409) {
            this.setState({
                warningPopup: !this.state.warningPopup,
                warningMsg: response.message,
                successTitle: this.props.t('alert'),
                resources: [],
                events: []
            }, () => {
                document.getElementById("loader_image_div").style.display = "none";
            })
        } else {
            setTimeout(() => {
                this.setState({
                    resources: [],
                    events: []
                }, () => {
                    document.getElementById("loader_image_div").style.display = "none";
                });
            }, 100);
        }
    }

    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    async getChargersBasedOnEmailAndSiteid(mailId, siteId){
        document.getElementById("loader_image_div").style.display = "block";
        let url = baseUrl.URLPath + servicesUrl.getChargersBasedOnEmailAndSiteid + "?email=" + mailId + "&siteId=" + siteId;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t,true);
            if(response.status == 404){
                let errors = this.state.errors;
                errors["emailError"] = response.message
                setTimeout(() => {
                    this.setState({
                        ...this.state,
                        errors: errors
                    }, () => {
                        document.getElementById("loader_image_div").style.display = "none";
                    });
                }, 100);
        }else if(response!=undefined && response?.length > 0){
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    getChargersBasedonEmail: response
                }, () => {
                    document.getElementById("loader_image_div").style.display = "none";
                });
            }, 100);
        } else if(response!=undefined && response?.length === 0){
            let errors = this.state.errors;
            errors["emailError"] = 'no_chargers_for_email'

            setTimeout(() => {
                this.setState({
                    ...this.state,
                    errors: errors
                }, () => {
                    document.getElementById("loader_image_div").style.display = "none";
                });
            }, 100);
        }
    
    }

    emailKeyPress(e) {
        let fields = this.state;

        if (e.keyCode == 13) {
            this.setState({ email: e.target.value }, ()=>{
                if (this.state.email == "") {
                    fields.errors['emailError'] = 'please_enter_email';

                    this.setState({ ...this.state, fields });
                } else {
                    this.props.clearPrevData();
                    fields.errors['emailError'] = "";

                    this.setState({ ...this.state, fields }, ()=>{
                        this.getChargersBasedOnEmailAndSiteid(this.state.email, this.props.searchSiteId)
                    });
                }
            });
        }
    }

    onChangeEmail() {
        let errors = this.state.errors;

        if (this.state.email == "") {
            errors["emailError"] = 'please_enter_email'
            this.setState({
                ...this.state,
                errors
            });
        } else {
            this.props.clearPrevData();
            errors["emailError"] = ''
            this.setState({
                ...this.state,
                errors
            }, ()=>{
                this.getChargersBasedOnEmailAndSiteid(this.state.email, this.props.searchSiteId)
            });
        }
    }

    changeEmail(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        fields.errors['emailError'] = '';

        this.setState({ ...this.state, fields });
    }

    clearEmail() {
        let fields = this.state;
        fields.email = "";
        fields.errors['emailError'] = '';
        this.setState({ ...this.state, fields });
    }

    reserveSuccess() {
        this.props.toggleConfirm();
        if (this.state.email != '' && this.props.reservecpid != '' && this.props.reservestart != '' && this.props.reserveend != '') {
            this.props.addReservation(this.props.reservecpid, this.props.reservestart, this.props.reserveend, this.state.email);
        } else if (this.state.email != '' && this.props.selectCpId != '' && this.props.fromdateselect != '' && this.props.todateselect != '') {
            this.props.addReservation(this.props.selectCpId, this.props.fromdateselect, this.props.todateselect, this.state.email);
        }
    }

    resertemail(){
        this.state.email = '';
        this.setState({
            ...this.state,
            email: '',
            errors: {}
        })
    }

    render() {

        const { t } = this.props;
        let user = this.state.user;
        let addReserveEnable = false;

        var estartTime = '';
        var estartTime1 = '';
        if (this.props.reservestart != null) {
            estartTime = moment(this.props.reservestart).format("DD-MMM-YYYY").toUpperCase();
            estartTime1 = moment(this.props.reservestart).format("HH:mm").toUpperCase();
        }
        var estopTime = '';
        var estopTime1 = '';
        if (this.props.reserveend != null) {
            estopTime = moment(this.props.reserveend).format("DD-MMM-YYYY").toUpperCase();
            estopTime1 = moment(this.props.reserveend).format("HH:mm").toUpperCase();
        }

        let addReservationBody = <div className="row">
            <div className="col-md-8 mb-3">
                <div className="input-group">
                    <i className="fas fa-search fa-sm input-searchIcon"></i>
                    <input type="text" id="emailId" value={this.state.email} onKeyDown={this.emailKeyPress} onChange={this.changeEmail.bind(this, "email")} className="form-control input-searchBox pad-2" placeholder={t('email_placeholder')} name="adminAddress" />
                    <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.clearEmail}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="input-group-btn">
                        <button className="input-searchBtn find-btn" type="submit" onClick={this.onChangeEmail}>{t('find')}</button>
                    </div>
                </div>
                <div className="error_msg w3-animate-top mt-2">
                    <span className='reserve_error' style={{ color: "red" }}>{t(this.state.errors["emailError"])}</span>
                </div>
            </div>
            {
                (this.props.reservestart && this.props.reserveend && this.props.reservecpid) ?
                    <div className="col-md-12" style={{ marginLeft: '3px' }}>
                        <div style={{ fontWeight: 'bold', marginBottom: '2px' }}>{t('reservation_overview')}</div>
                        <div className="content-innerdiv" style={{ height: '80%' }}>
                            <div>
                                <label>{t('reservation_start_time')}</label>
                                <label>
                                    <i className="fas fa-calendar-alt fa-sm"></i> {estartTime}
                                    <span className="ml-2">
                                        <i className="far fa-clock fa-sm"></i> {estartTime1}
                                    </span>
                                </label>
                            </div>
                            <div>
                                <label>{t('reservation_end_time')}</label>
                                <label>
                                    <i className="fas fa-calendar-alt fa-sm"></i> {estopTime}
                                    <span className="ml-2">
                                        <i className="far fa-clock fa-sm"></i> {estopTime1}
                                    </span>
                                </label>
                            </div>
                            <div>
                                <label>{t('chargePoint_id')}</label>
                                <label>{this.props.reservecpid}</label>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {
                            this.state.getChargersBasedonEmail?.length > 0 && <div className="col-md-8 mb-3">
                                <select className="browser-default custom-select select_height " id="chargersList" onChange={() => {
                                    this.props.onChangecpId();
                                    let errors = this.state.errors;
                                    errors['cpIdError'] = '';
                                    this.setState({ errors: errors })
                                }} value={this.props.selectCpId}>
                                    <option value=''>-- {t('select_chargePoint')} --</option>
                                    {
                                        this.state.getChargersBasedonEmail?.map((cpid, index) => {
                                            return <option value={cpid}>{cpid}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }
                        {
                            this.state.getChargersBasedonEmail?.length > 0 && <><div className="col-md-12 mb-3">
                                <span className="cal_label mr-2">{t('reservation_date_time')}: </span>
                                <div className="pull-left mt-2">
                                    <DatePicker
                                        selected={this.props.dateTime}
                                        onChange={this.props.handleFromChange}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        id="expiryDateId"
                                        className="cal_margin tag_callogs"
                                        timeCaption="time"
                                        dateFormat="dd-MMM-yyyy HH:mm"
                                        maxDate={new Date(Date.now() + 15 * 24 * 60 * 60 * 1000)}
                                        minDate={new Date()}
                                    />
                                </div>
                            </div>
                                <div className="error_msg w3-animate-top mt-2 col-md-8">
                                    <span className='reserve_error' style={{ color: "red" }}>{t(this.props.errors["reserveDateError"])}</span>
                                </div>
                            </>
                        }
                        {
                            (this.props.slotsBasedOnCpId?.length > 0) && <>
                                <div style={{ fontWeight: 'bold' }} className='col-md-8'>{t('recommended_slots')}</div>
                                <MDBCol md="12" className="chargepoint_listdiv">
                                    <ul>
                                        {
                                            this.props.slotsBasedOnCpId?.map((slot, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Form.Check
                                                            onChange={this.props.selectedSlotChange.bind(this, slot)}
                                                            custom
                                                            type="radio"
                                                            id={`custom-${slot?.stopDateTime}`}
                                                            label=""
                                                            name={`custom`}
                                                            style={{ display: 'inline-block' }}
                                                            disabled={slot?.status == 'Green' ? false : true}
                                                        />
                                                        {moment(slot?.startDateTime).format("HH:mm")} - {moment(slot?.stopDateTime).format("HH:mm")} {' '} {slot?.status == 'Green' ? t('available') : t('unavailable')}
                                                    </div>
                                                )
                                            })
                                        }
                                    </ul>
                                </MDBCol>
                            </>
                        }

                    </>
            }
        </div>

        let startTime = moment(this.props.reservationInfo.startDateTime).format("DD-MMM-YYYY").toUpperCase();
        let startTime1 = moment(this.props.reservationInfo.startDateTime).format("HH:mm").toUpperCase();

        let endTime = moment(this.props.reservationInfo.stopDateTime).format("DD-MMM-YYYY").toUpperCase();
        let endTime1 = moment(this.props.reservationInfo.stopDateTime).format("HH:mm").toUpperCase();

        let reservationOverviewBody = <div className="row">
            <div className="col-md-6">
                <div style={{ fontWeight: 'bold' }}>{t('reservation_timeslots')}</div>
                <div className="content-innerdiv" style={{ height: '80%' }}>
                    <label style={{ fontWeight: 'bold' }}>
                        <i className="fas fa-calendar-alt fa-sm"></i> {startTime}
                        <span className="ml-2">
                            <i className="far fa-clock fa-sm"></i> {startTime1}
                        </span>
                    </label>
                    <label style={{ fontWeight: 'bold' }}>
                        <i className="fas fa-calendar-alt fa-sm"></i> {endTime}
                        <span className="ml-2">
                            <i className="far fa-clock fa-sm"></i> {endTime1}
                        </span>
                    </label>
                </div>
            </div>
            <div className="col-md-6">
                <span style={{ fontWeight: 'bold' }} className='mr-2'>{t('user')}</span>
                <span>{this.props.reservationInfo.userEmail}</span>
                <br /><br />
                <span style={{ fontWeight: 'bold' }} className='mr-2'>{t('user_rfid')}</span>
                <span className='wrap-word'>{this.props.reservationInfo.idTag}</span>
            </div>
        </div>

        if(this.state.email && this.props.reservestart && this.props.reserveend && this.props.reservecpid){
            addReserveEnable = true
        } else if(this.state.email && this.props.selectCpId && this.props.dateTime && this.props.fromdateselect && this.props.todateselect && this.props.errors["reserveDateError"] == ""){
            addReserveEnable = true
        }

        return (
            <main className="content-div">
                <p>{t('reservation_title')}</p>
                <div className="page-outerdiv">
                    <div className="row menubar">
                        <div className="col-md-12">
                            <div className="pull-right">
                                <label className="sub-text pull-left">{t('show_entries')}</label>
                                <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv">
                                    <Form.Control as="select" className="showentry_sel custom_selectBox" onChange={this.props.showEntriesChange}>
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 pull-right">
                            <div className="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <Typeahead
                                    labelKey="name"
                                    className="w_80"
                                    options={this.props.searchSuggestions}
                                    id="rbt_id"
                                    minLength={3}
                                    placeholder={t('search_group')}
                                    onKeyDown={this.props.handleKeyDown}
                                    ref={this.props.typeAHeadRef}
                                    onChange={this.props.onChangeTypeahead}
                                />

                                <button type="button" className="close close_icon" id="clearInput" aria-label="Close" onClick={this.props.clearSearchSuggestions}>
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div className="input-group-btn">
                                    <button className="input-searchBtn find-btn" type="submit" onClick={this.props.FindCpName}>{t('find')}</button>
                                </div>
                                <span className="pull-left error_msg w3-animate-top mt-1 empty_site" style={{ color: "red" }}></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {
                                this.props.availableSites?.map((currentSite, index) => {
                                    const isEmpty = !currentSite.onlineCPCount && !currentSite.oflineCPCount;
                                    let total = 0;
                                    let perOnline = 0;
                                    let perOffline = 0;
                                    const cpCount = currentSite.onlineCPCount + currentSite.oflineCPCount;
                                    if (!isEmpty) {
                                        
                                        total = currentSite.oflineCPCount + currentSite.onlineCPCount;
                                        perOnline = (currentSite.onlineCPCount / total) * 100;
                                        perOffline = (currentSite.oflineCPCount / total) * 100;
                                        perOffline = perOffline.toFixed();
                                        perOnline = perOnline.toFixed();
                                    }

                                    var today = moment();
                                    today = today.subtract(1, "days");
                                    today = today.format("YYYY-MM-DD");
                                    today = today + "T18:30:00:000";

                                    return (
                                        <>
                                            <Table striped hover size='sm' className='page-table'>
                                                <tbody>
                                                    <tr key={index}>
                                                        <td className="align-middle text-center" style={{ width: '6%' }}>
                                                            <h6>#{(this.props.activePage - 1) * this.props.pageSize + index + 1}</h6>
                                                        </td>
                                                        <td className="align-middle" style={{ width: '20%' }}>
                                                            <div className="d-flex flex-column">
                                                                <h4 style={{wordBreak: 'break-all'}}>
                                                                    {(currentSite?.name?.length >= 28) ? currentSite?.name?.substring(0, 25) + "..." : currentSite?.name}
                                                                    </h4>
                                                                <span>{currentSite.address}</span>
                                                                <span>{currentSite.zipcode} {currentSite.city}</span>
                                                            </div>
                                                        </td>
                                                        <td className="align-middle" style={{ width: '20%' }}>
                                                            <div className="d-flex align-items-center">
                                                                <div style={{ height: '60px', width: '60px', position: 'relative', top: '-5px' }}>
                                                                    <Doughnut
                                                                        data={this.props.getDoughnutData(currentSite)}
                                                                        options={{
                                                                            responsive: true,
                                                                            tooltips: { enabled: false }
                                                                        }}
                                                                        height={60}
                                                                        width={60}
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column" style={{ width: '35%' }}>
                                                                    <span className="text-success">{t('online')} {perOnline} %</span>
                                                                    <span>{t('offline')} {perOffline} %</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-center" style={{ width: '18%' }}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/img/Wallbox.png"} alt="" style={{ maxHeight: '50px', marginTop: "14px" }} />
                                                            <h3>{cpCount}</h3>
                                                        </td>
                                                        <td className='align-middle' style={{ width: '18%' }}>
                                                            <div className='row marginStyles'>
                                                                <div className='col-md-12' style={{ fontSize: "16px" }}>{t('active_rules')} <i className="fa fa-info-circle" aria-hidden="true" onClick={this.props.toggleActiverules.bind(this, currentSite)}></i></div>
                                                            </div>
                                                        </td>
                                                        { hasPermission(user, ["Resevations_W"]) && <>
                                                        {/* <td className='align-middle'>
                                                            <label className="switch_reservation">
                                                                <input type="checkbox" checked={currentSite.reservationStatus} />
                                                                <span className="slider_reservation round_reservation" onClick={this.props.changeActiveDeactivate.bind(this, currentSite.id, currentSite.reservationStatus)}>
                                                                    {!currentSite.reservationStatus ?
                                                                        <span style={{ color: '#545454', left: '32px', top: '9px', position: 'relative' }}>{t('deactivate')}</span> :
                                                                        <span style={{ color: '#ffffff', left: '18px', top: '9px', position: 'relative' }}>{t('activate')}</span>
                                                                    }
                                                                </span>
                                                            </label>
                                                        </td> */}
                                                        <td className='align-middle'>
                                                        { 
                                                            currentSite.reservationStatus ? currentSite.reservationStatus && <td className='align-middle' >
                                                            {
                                                                (currentSite.reservationStatus) ?
                                                                    <>
                                                                        <i
                                                                            id={`chevron_down_${index}`}
                                                                            className="fas fa-chevron-down"
                                                                            style={{ fontSize: '24px', display: 'block', cursor: 'pointer' }}
                                                                            href={`#demo${index}`}
                                                                            data-toggle="collapse"
                                                                            onClick={() => {
                                                                                $('.calender_graph_display').hide();
                                                                                $('.fa-chevron-up').hide();
                                                                                $('.fa-chevron-down').show();
                                                                                $(`#demo${index}`).show();
                                                                                let targetId = localStorage.getItem('setOpenedId');
                                                                                localStorage.setItem('targetIndex', `demo${index}`)
                                                                                localStorage.setItem('currentReservationSiteId', currentSite.id);
                                                                                var today = moment();
                                                                                today = today.subtract(1, "days");
                                                                                today = today.format("YYYY-MM-DD");
                                                                                today = today + "T18:30:00:000";
                                                                                localStorage.setItem('selectedDate', today);
                                                                                if(targetId){
                                                                                    ReactDOM.unmountComponentAtNode(document.getElementById(targetId))
                                                                                }
                                                                                this.getReservationSlots(currentSite.id, today, `demo${index}`);
                                                                                document.getElementById(`chevron_up_${index}`).style.display = 'block';
                                                                                document.getElementById(`chevron_down_${index}`).style.display = 'none';
                                                                            }}
                                                                        ></i>
                                                                        <i
                                                                            id={`chevron_up_${index}`}
                                                                            className="fas fa-chevron-up"
                                                                            style={{ fontSize: '24px', display: 'none', cursor: 'pointer' }}
                                                                            href={`#demo${index}`}
                                                                            data-toggle="collapse"
                                                                            onClick={() => {
                                                                                localStorage.removeItem('currentReservationSiteId');
                                                                                document.getElementById(`chevron_down_${index}`).style.display = 'block';
                                                                                document.getElementById(`chevron_up_${index}`).style.display = 'none';
                                                                            }}></i>
                                                                    </> :
                                                                    <i id={`chevron_down_${index}`} className="fas fa-chevron-down" style={{ fontSize: '24px' }}></i>
                                                            }
                                                        </td> :<td></td>
                                                        }
                                                        {/* <td className='align-middle'>
                                                            <label className="switch_reservation">
                                                                <input type="checkbox" checked={currentSite.reservationStatus} />
                                                                <span className="slider_reservation round_reservation" onClick={this.props.changeActiveDeactivate.bind(this, currentSite.id, currentSite.reservationStatus)}>
                                                                    {!currentSite.reservationStatus ?
                                                                        <span style={{ color: '#545454', left: '32px', top: '9px', position: 'relative' }}>{t('deactivate')}</span> :
                                                                        <span style={{ color: '#ffffff', left: '18px', top: '9px', position: 'relative' }}>{t('activate')}</span>
                                                                    }
                                                                </span>
                                                            </label>
                                                        </td> */}
                                                        </td>
                                                        </>}
                                                    </tr>
                                                </tbody>
                                            </Table>

                                            <div className="collapse calender_graph_display" id={`demo${index}`}></div>
                                        </>
                                    );
                                })
                            }
                            {
                                (this.props.availableSites.length == 0) ?
                                    <Table striped hover size='sm' className='page-table'>
                                        <tbody>
                                            <tr>
                                                <td colSpan={7} className="align-middle text-center">
                                                    {
                                                        (localStorage.getItem('role') == roles.WEBASTO_SUPER_ADMIN_DEVOPS || localStorage.getItem('role') == roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || localStorage.getItem('role') == roles.WEBASTO_CUSTOMER_ADMIN) ?
                                                            <>{t('no_groups_available')}</> : <>{t('You_see_if_you_are_admin_of_a_group')}</>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    : null
                            }
                        </div>
                        <div className="col-md-12 mt-2 mb-4 text-right">
                            <Pagination
                                hideDisabled
                                activePage={this.props.activePage}
                                itemsCountPerPage={this.props.pageSize}
                                totalItemsCount={this.props.noOfRecords}
                                onChange={this.props.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>

                {/* popup for session expire */}
                <CustomModal
                    open={this.props.modalsession}
                    title={t('session_has_expired')}
                    bodyMessage={t('please_login_again')}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.sessiontagOk}
                />

                {/* popup for session expire */}
                <CustomModal
                    open={this.state.modalsession}
                    title={t('session_has_expired')}
                    bodyMessage={t('please_login_again')}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.sessiontagOk}
                />

                {/* popup for toggle activate / deactivate */}
                <CustomModal
                    open={this.props.isactivate}
                    title={t('alert')}
                    bodyMessage={t(this.props.activemessage)}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.toggleActivate}
                />

                {/* popup for reservation overview */}
                <CustomModal
                    open={this.props.modal}
                    title={t('reservation_overview')}
                    bodyMessage={reservationOverviewBody}
                    PrimaryButtonText={t('delete')}
                    PrimaryButtonOperation={this.props.toggleDelete}
                    PrimaryButtonStyle={'btn_secondary'}
                    AdditionalButtonText={t('cancel')}
                    AdditionalButtonOperation={this.props.toggleOverview}
                    AdditionalButtonStyle={'btn_cancel'}
                />

                {/* popup for add reservation */}
                <CustomModal
                    open={this.props.modalAdd}
                    title={t('add_reservation')}
                    bodyMessage={addReservationBody}
                    PrimaryButtonText={t('add_reservation')}
                    PrimaryButtonOperation={this.props.toggleConfirm}
                    PrimaryButtonStyle={addReserveEnable ? '' : 'btn_cancel'}
                    isPrimaryEnable = {addReserveEnable ? true : false}
                    AdditionalButtonText={t('cancel')}
                    AdditionalButtonOperation={this.props.toggleAdd}
                    AdditionalButtonStyle={'btn_cancel'}
                />

                {/* confirm popup for reservation add */}
                <CustomModal
                    open={this.props.confirmAdd}
                    title={t('confirmation')}
                    bodyMessage={t('add_reservtion_confirm')}
                    PrimaryButtonText={t('yes')}
                    PrimaryButtonOperation={this.reserveSuccess}
                    AdditionalButtonText={t('no')}
                    AdditionalButtonOperation={this.props.toggleConfirm}
                />

                {/* popup for reservation add success / alert */}
                <CustomModal
                    open={this.props.successReserve}
                    title={this.props.successTitle}
                    bodyMessage={t(this.props.successmessage)}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={() => { this.props.closeSuccess(this.getReservationSlots) }}
                />

                {/* popup for reservation warnings */}
                <CustomModal
                    open={this.state.warningPopup}
                    title={this.props.t('alert')}
                    bodyMessage={t(this.state.warningMsg)}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.closeWarningPopup}
                />

                {/* confirm popup for reservation delete */}
                <CustomModal
                    open={this.props.confirmDelete}
                    title={t('confirmation')}
                    bodyMessage={t('cancel_reservtion_confirm')}
                    PrimaryButtonText={t('yes')}
                    PrimaryButtonOperation={this.props.toggleDeleteReserve}
                    AdditionalButtonText={t('no')}
                    AdditionalButtonOperation={this.props.toggleDelete}
                />

                {/* popup for reservation delete success */}
                <CustomModal
                    open={this.props.deleteReserve}
                    title={t('alert')}
                    bodyMessage={this.props.deletemessage}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={() => { this.props.closeDelete(this.getReservationSlots) }}
                />

                {/* popup for reservation active rules */}
                <MDBModal isOpen={this.props.isActiveRulesOpen} size="sm" className="model_top costsModelWidth ">
                    <MDBModalHeader><label className="fs-16"><b>{t('active_rules')}</b></label></MDBModalHeader>
                    <MDBModalBody>
                        <div className="w-100 mt-3 row">
                            <label className="pl-3 col-md-8"> {t('guaranted_slot_duration')}: </label>
                            <label> {this.props.guarantedSlotDuration} </label>
                        </div>
                        <div className="w-100 row">
                            <label className="pl-3 col-md-8"> {t('reporting')}: </label>
                            <label>
                                <input className="form-check-input ml-1" type="checkbox" value="" id="activeChecked" checked={this.props.reporting} readOnly />
                            </label>
                        </div>
                        <div className="w-100 row">
                            <label className="pl-3 col-md-8"> {t('cooldown')}: </label>
                            <label> {this.props.cooldown} </label>
                        </div>
                        <div className="w-100 row">
                            <label className="pl-3 col-md-8"> {t('minimum_charging_duration')}: </label>
                            <label> {this.props.minimumChargingDuration} </label>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.props.closeActiverules}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>

                {/* Loader */}
                <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>

                {this.props.loading ? (
                    <Loader loader={this.props.loading} />
                ) : ("")}
            </main>
        );
    }
}

export default withTranslation()(ReservationAndSecondSeatingTemplate);