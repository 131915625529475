import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders } from '../common/HttpRequestClass'

class addChargingProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      modal: false,
      modalSuccess: false,
      modalsession: false,
      validFromDate: null,
      validToDate: null,
      chargingScheduleData: [],
      page_title: "Add Charging Profile",
      fields: {
        "stackLevel": "",
        "chargingProfilePurpose": "",
        "chargingProfileKind": "",
        "recurrencyKind": "",
        "chargingSchedule": "",
        "validFrom": "",
        "validTo": "",
        "chargingProfileName": "",
      }
    }
    this.addNew = this.addNew.bind(this)
    this.backToList = this.backToList.bind(this)
    this.setValidFromDate = this.setValidFromDate.bind(this)
    this.setValidToDate = this.setValidToDate.bind(this)
    this.saveChargingProfile = this.saveChargingProfile.bind(this)
    this.CloseSuccessPopup = this.CloseSuccessPopup.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  //This is for adding the charge profile
  addNew() {
    this.props.history.push('/chargingprofile');
  }

  //This is for taking to the charge profile list page
  backToList() {
    this.props.history.push('/chargingprofile');
  }

  //This function for setting the date to state
  setValidFromDate(date) {
    var dateNew = this.formatDateandTime(date)
    var fields = this.state.fields;
    fields["validFrom"] = dateNew
    this.setState({
      validFromDate: date,
      fields
    })
  }

  //This function for setting the date to state
  setValidToDate(date) {
    var dateNew = this.formatDateandTime(date)
    var fields = this.state.fields;
    fields["validTo"] = dateNew
    this.setState({
      validToDate: date,
      fields
    })
  }

  // function to open popup
  async toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleAdd() {
    this.setState({
      modalSuccess: !this.state.modalSuccess
    });
  }

  CloseSuccessPopup() {
    this.setState({
      modalSuccess: !this.state.modalSuccess
    });
    this.props.history.push('/chargingProfile');
  }


  formatDateandTime(currentDate) {
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var createdDateTo = twoDigitMonth + "-" + twoDigitDate + "-" + currentDate.getFullYear() + ' ' + twohoursDate + ':' + twominDate;
    return createdDateTo;
  }

  async chargingschedules() {
    let url = baseUrl.URLPath + servicesUrl.getChargingschedules
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      this.setState({ chargingScheduleData: response });
    }
  }


  componentDidMount() {
    this.chargingschedules();
  }

  changeHandler() {
    var chargingProfileName = document.getElementById("chargingProfileNameId").value;
    let fields = this.state.fields;
    let errors = this.state.errors;
    if (fields["chargingProfileNameId"] === chargingProfileName) {
      errors["chargingProfileNameError"] = "";
      this.setState({ errors });
    }
  }

  availabilityChange() {
    let errors = this.state.errors;
    var chargingProfilePurpose = document.getElementById("chargingProfilePurposeId").value;
    var chargingSchedule = document.getElementById("chargingScheduleId").value;
    var stackLevel = this.state.fields['stackLevel'];
    var chargingProfileKind = document.getElementById("chargingProfileKindId").value;
    let fields = this.state.fields;

    if (fields["chargingProfilePurposeId"] === chargingProfilePurpose) {
      errors["chargingProfilePurposeError"] = "";
    }

    if (fields["chargingScheduleId"] === chargingSchedule) {
      errors["chargingSchedule"] = " ";
    }

    if (stackLevel !== '') {
      errors["stackLevelError"] = " ";
    }

    if (fields["chargingProfileKindId"] === chargingProfileKind) {
      errors["chargingProfileKindError"] = " ";
    }

    this.setState({ fields: fields, errors });
  }

  numberHandler(field, e) {
    e.preventDefault();
    let fields = this.state.fields;

    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields: fields });
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields: fields });
    }
  }

  async saveChargingProfile(event) {
    event.preventDefault();
    let errors = {};
    var stackLevel = this.state.fields['stackLevel'];
    var chargingProfileName = this.state.fields['chargingProfileName'];
    var chargingProfilePurpose = document.getElementById("chargingProfilePurposeId").value;
    var chargingProfileKind = document.getElementById("chargingProfileKindId").value;
    var recurrencyKind = document.getElementById("recurrencyKindId").value;
    var chargingSchedule = document.getElementById("chargingScheduleId").value;
    var validFrom = this.state.fields['validFrom'];
    var validTo = this.state.fields['validTo'];

    if (chargingProfilePurpose === '') {
      errors["chargingProfilePurposeError"] = this.props.t('select_charging_profile_purpose');
    }
    if (chargingProfileKind === "") {
      errors["chargingProfileKindError"] = this.props.t('Please_select_charging_profile_kind');
    }
    if (stackLevel === '') {
      errors["stackLevelError"] = this.props.t('Please_enter_stack_level');
    }
    if (chargingSchedule === '') {
      errors["chargingSchedule"] = this.props.t('please_select_acharging_schedule');
    }
    if (errors.hasOwnProperty("chargingProfilePurposeError") || errors.hasOwnProperty("chargingProfileKindError") || errors.hasOwnProperty("stackLevelError") || errors.hasOwnProperty("chargingSchedule")) {
    } else {
      let url = baseUrl.URLPath + servicesUrl.getChargingProfilesWId
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      let payload = { "chargingProfileName": chargingProfileName, "stackLevel": stackLevel, "chargingProfilePurpose": chargingProfilePurpose, "chargingProfileKind": chargingProfileKind, "recurrencyKind": recurrencyKind, "chargingSchedule": chargingSchedule, "validFrom": validFrom, "validTo": validTo };
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if (response.status === 404) {
        this.setState({ errors: errors });
      }
      if (response.status === 409) {
        errors["chargingProfileNameError"] = this.props.t('charging_profile_already_exists')
        this.setState({ errors: errors });
      }
      if (!response.status && response !== undefined && response !== "") {
        this.toggleAdd()
      }
    }
    this.setState({ errors: errors });
  }

  render() {
    const { t } = this.props;
    let chargingScheduleItems = "";
    if (this.state.chargingScheduleData !== undefined) {
      chargingScheduleItems = this.state.chargingScheduleData.map((chargingSchedule, index) =>
        <option value={chargingSchedule.chargingScheduleName}>{chargingSchedule.chargingScheduleName}</option>
      );
    }

    return (
      <main className="content-div" >
        <p> {t('chargingProfileId')}
          <div className="breadcrumb_div">
            {t('charge_point')} &gt; <Link to="/chargingProfile">{t('chargingProfileId')}</Link > &gt; <span className="breadcrumb_page">Add</span>
          </div>
        </p>
        <form className='needs-validation'>
          <div className="page-outerdiv">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button className="pull-right btn_primary" type="button" data-test="button" onClick={this.backToList} >
                    <i className="fas fa-angle-left mr-2"></i>  {t('Back')}
                  </button>
                </div>
              </div>
            </div>
            <div className="row mb-3 mt-3">
              <div className="col-md-6 md-form">
                <input name="chargingProfileName" onInput={this.numberHandler.bind(this, "chargingProfileName")} type="text" id="chargingProfileNameId" className="form-control text_lowercase" onChange={this.changeHandler.bind(this)} value={this.state.fields["chargingProfileName"]} />
                <label htmlFor="chargingProfileNameId" className={`${(this.state.fields['chargingProfileName'] !== "" && this.state.fields['chargingProfileName'] !== null) ? "active" : ""} ml-15`}>{t('charging_profilename')}</label>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["chargingProfileNameError"]}</span></span>
              </div>
              <div className="col-md-6 md-form">
                <input pattern="[0-9]*" onInput={this.numberHandler.bind(this, "stackLevel")} name="stackLevel" type="text" id="stackLevelid" className="form-control text_lowercase" onChange={this.availabilityChange.bind(this)} value={this.state.fields["stackLevel"]} />
                <label htmlFor="stackLevelid" className={`${(this.state.fields['stackLevel'] !== "" && this.state.fields['stackLevel'] !== null) ? "active" : ""} ml-15`}>{t('stack_level_integer')+" *"}:</label>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["stackLevelError"]}</span></span>
              </div>
              <MDBCol md="6" className="pr-2">
                <div className="w_f" style={{ marginTop: "10px" }}>
                  <label className="pull-left pt-3 pl-1">{t('charging_profilepurpose')+" *"}: </label>
                  <select className="browser-default custom-select select_height w_60 pull-right" id="chargingProfilePurposeId" onChange={this.availabilityChange.bind(this)}>
                    <option value="">{t('charging_profile_purpose')}</option>
                    <option value="ChargePointMaxProfile">{t('chargePointMaxProfile')} </option>
                    <option value="TxDefaultProfile">{t('txDefaultProfile')}</option>
                    <option value="TxProfile">{t('txProfile')}</option>
                  </select>
                </div>
                <span className="error_msg w3-animate-top mt-1 w_60 pull-right" style={{ width: " 58.5%", float: " right" }}>  <span style={{ color: "red" }}>{this.state.errors["chargingProfilePurposeError"]}</span></span>
              </MDBCol>
              <MDBCol md="6" className="pr-2">
                <div className="w_f" style={{ marginTop: "10px" }}>
                  <label className="pull-left pt-3 pl-1">{t('charging_profile_kind')+" *"}:</label>
                  <select className="browser-default custom-select select_height  w_60 pull-right" id="chargingProfileKindId" onChange={this.availabilityChange.bind(this)}>
                    <option value="">{t('select_charging_profile_kind')}</option>
                    <option value="Absolute">{t('absolute')}</option>
                    <option value="Recurring">{t('Recurring')}</option>
                    <option value="Relative">{t('relative')}</option>
                  </select>
                </div>
                <span className="error_msg w3-animate-top mt-1 w_60 pull-right" style={{ width: " 58.5%", float: " right" }}>  <span style={{ color: "red" }}>{this.state.errors["chargingProfileKindError"]}</span></span>
              </MDBCol>
              <MDBCol md="6" className="pr-2">
                <div className="w_f" style={{ marginTop: "10px" }}>
                  <label className="pull-left pt-3 pl-1">{t('recurrency_kind')}:</label>
                  <select className="browser-default custom-select select_height w_60 pull-right" id="recurrencyKindId">
                    <option value="">{t('select_recurrency_kind')}</option>
                    <option value="Daily">{t('daily')}</option>
                    <option value="Weekly">{t('weekly')}</option>
                  </select>
                </div>
              </MDBCol>
              <MDBCol md="6" className="pr-2">
                <div className="w_f" style={{ marginTop: "10px" }}>
                  <label className="pull-left pt-3 pl-1">{t('Charging Schedule')+" *"}:</label>
                  <select className="browser-default custom-select select_height w_60 pull-right" id="chargingScheduleId" onChange={this.availabilityChange.bind(this)}>
                    <option value="">{t('select_chargingschedule')}</option>
                    {chargingScheduleItems}
                  </select>

                </div>
                <span className="error_msg w3-animate-top cal_errormsg w_60 pull-right" style={{ width: " 58.5%", float: " right" }}
                > <span style={{ color: "red" }} >{this.state.errors["chargingSchedule"]}</span></span>

              </MDBCol>
              <MDBCol md="6" className="mt-2" style={{ position: "relative" }} >
                <span className="cal_label w-40 ">{t('valid_from')}</span>
                <div className="pull-left mt-2 ml-1" style={{ width: "50%" }} >
                  <DatePicker
                    selected={this.state.validFromDate}
                    onChange={this.setValidFromDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    className="cal_margin tag_cal ml-0"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    maxDate={this.state.validToDate}
                  />
                </div>
                <span className="error_msg w3-animate-top cal_errormsg"> <span style={{ color: "red" }} >{this.state.errors["emptyscheduleDate"]}</span></span>
              </MDBCol>
              <MDBCol md="6" className="mt-2" style={{ position: "relative" }} >
                <span className="cal_label w-40">{t('valid_to')}</span>
                <div className="pull-left mt-2 ml-1" style={{ width: "50%" }} >
                  <DatePicker
                    selected={this.state.validToDate}
                    onChange={this.setValidToDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    className="cal_margin tag_cal ml-0"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    minDate={this.state.validFromDate}
                  />
                </div>
                <span className="error_msg w3-animate-top cal_errormsg"> <span style={{ color: "red" }} >{this.state.errors["emptyscheduleDate"]}</span></span>
              </MDBCol>

              <MDBCol md="12" className="text-right mt-3">
                <button className="btn_primary mt-5 mr-2" type="button" data-test="button" onClick={this.saveChargingProfile}> {t('save')} <i className="fas fa-save ml-2"></i></button>
              </MDBCol>
            </div>
          </div>
        </form>

        <MDBModal isOpen={this.state.modalSuccess} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
          <MDBModalHeader toggle={this.toggleAdd}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('charging_profile_added')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.CloseSuccessPopup}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
      </main>
    );
  }
}

export default withTranslation()(addChargingProfile);