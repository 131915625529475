import moment from 'moment';

function getHeaders() {
    return {
        'Content-Type': 'application/json'
    }
}

function getAuthorization() {
    return {
        "Authorization": localStorage.getItem("Authorization"),
    }
}

function getSecurityHeaders() {
    return {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('Authorization')
    };
}

function getSecurityHeadersWithTimezoneLocationBased() {    
    let timeZone =  moment.tz.guess();
    return {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('Authorization'),
        'timeZone': timeZone
    }
}

function getSecurityHeadersWithTimezone() {
    return {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('Authorization'),
        'timeZone': localStorage.getItem('timezone')
    }
}

function getActiveSecurityHeaders() {
    return {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("activeAuthorization")
    }
}

function getSecurityHeadersWithSiteId(){
    return{
        'Content-Type':'application/json',
        'Authorization':localStorage.getItem("activeAuthorization"),
        'siteid': localStorage.getItem('siteId')
    }
}

function getTenantName(){
    return{
    "content-type": "application/json",
    "Authorization": localStorage.getItem("Authorization"),
    "tenant": localStorage.getItem("tenant")
    }
}

export {
    getHeaders,
    getSecurityHeaders,
    getSecurityHeadersWithTimezone,
    getSecurityHeadersWithTimezoneLocationBased,
    getAuthorization,
    getActiveSecurityHeaders,
    getSecurityHeadersWithSiteId,
    getTenantName
}