import React from 'react';
import { render } from 'react-dom';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import $ from "jquery";

import { hasPermission } from '../auth';

// API Imports
import * as baseUrl from '../constants';
import servicesUrl from '../../common/servicesUrl';
import APICallUtility from '../../common/APICallUtility';
import ApiMethodTypes from '../../common/ApiMethodTypes';
import { getSecurityHeaders } from '../../common/HttpRequestClass';

import Header from './header';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enablecronjob: "true",
      enableBroker: "true",
      enableConnector: "true",
      enableVehicle: "true",
      enableCSOperation: "true",
      ocppTagsDisplay: "",
      listOpen0: false, // done by manoj ux designer 
      listOpen1: false,
      listOpen2: false,
      listOpen3: false,
      listOpen4: false,
      listOpen5: false,
      listOpen6: false,
      listOpen7: false,
      listOpen8: false,
      listOpen9: false,
      listOpen10: false,
      listOpen11: false,
      listOpen13: false,
      listOpen12: false,
      listOpen14: false,
      listOpen15: false,
      prev_id: "",
      user: {
        permissions: []
      },
      userPackage: "",
      menuItem: "",
      hidesidebar : false,
      smallLogo: false
    }

    this.toggleList = this.toggleList.bind(this);
    this.toggleMenuOperation = this.toggleMenuOperation.bind(this);
    this.toggleList12 = this.toggleList12.bind(this);
    this.getEnableBrokerStatus = this.getEnableBrokerStatus.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleSidebar = this.handleSidebar.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
  }

  //toogle functionality of sidebar done by manoj ux designer 
  toggleList(img, index, e) {
    
    var js = document.getElementsByClassName('sidebar-main-div');
    
    $(js).removeClass("scroll_nav");
    this.setState({ smallLogo: false })

    $(".collapse").removeClass("show");

    if (index === this.state.prev_id) {
    } else {
      this.setState({
        listOpen0: false,
        listOpen1: false,
        listOpen2: false,
        listOpen3: false,
        listOpen4: false,
        listOpen5: false,
        listOpen6: false,
        listOpen7: false,
        listOpen8: false,
        listOpen9: false,
        listOpen10: false,
        listOpen11: false,
        listOpen13: false,
        listOpen12: false,
        listOpen14: false,
        listOpen15: false
      })
    }
    switch (index) {
      case 0: this.setState(prevState => ({ listOpen0: !prevState.listOpen0 })); break;
      case 1: this.setState(prevState => ({ listOpen1: !prevState.listOpen1 })); break;
      case 2: this.setState(prevState => ({ listOpen2: !prevState.listOpen2 })); break;
      case 3: this.setState(prevState => ({ listOpen3: !prevState.listOpen3 })); break;
      case 4: this.setState(prevState => ({ listOpen4: !prevState.listOpen4 })); break;
      case 5: this.setState(prevState => ({ listOpen5: !prevState.listOpen5 })); break;
      case 6: this.setState(prevState => ({ listOpen6: !prevState.listOpen6 })); break;
      case 7: this.setState(prevState => ({ listOpen7: !prevState.listOpen7 })); break;
      case 8: this.setState(prevState => ({ listOpen8: !prevState.listOpen8 })); break;
      case 9: this.setState(prevState => ({ listOpen9: !prevState.listOpen9 })); break;
      case 10: this.setState(prevState => ({ listOpen10: !prevState.listOpen10 })); break;
      case 11: this.setState(prevState => ({ listOpen11: !prevState.listOpen11 })); break;
      case 13: this.setState(prevState => ({ listOpen13: !prevState.listOpen13 })); break;
      case 12: this.setState(prevState => ({ listOpen12: !prevState.listOpen12 })); break;
      case 14: this.setState(prevState => ({ listOpen14: !prevState.listOpen14 })); break;
      case 15: this.setState(prevState => ({ listOpen15: !prevState.listOpen15 })); break;
      default: this.setState(prevState => ({ listOpen0: !prevState.listOpen0 })); break;
    }
    this.setState({ prev_id: index })
    localStorage.removeItem("chargerId");
  }

  toggleMenuOperation() {
    localStorage.setItem('operation', 'operationMenu')
    localStorage.removeItem("operationId")
  }

  toggleList12() {
    this.setState(prevState => ({
      toggleList12: !prevState.toggleList12
    }))
  }

  handleSidebar(){
    this.setState((prevState)=>({hidesidebar : !prevState.hidesidebar}))
  }

  showSidebar() {    
    this.setState({ smallLogo: !this.state.smallLogo})
    var js = document.getElementsByClassName('sidebar-main-div');
    if ($(js).hasClass("scroll_nav")) {
      $(js).removeClass("scroll_nav");
    } else {
      $(js).addClass("scroll_nav");
    }
  }

  componentDidMount() {
    //     function capitalizeFirstLetter(string) {
    //   return string.substring(0, 1).toUpperCase() + string.substring(1);
    // }
    var location = window.location.href;
    var id = location.split("/");
    localStorage.setItem("menuItem", id[3])
    if (id[3] === "dashboard") {
      this.setState({
        listOpen0: true
      })
    } else if (id[3] === "csrLanding") {
      this.setState({
        listOpen11: true
      })
    } else if (id[3] === "inventoryList" || id[3] === "cpPingPong") {
      this.setState({
        listOpen7: true
      })
    }

    // permission related code
    let user = this.state.user;
    user.permissions = localStorage.getItem("roleAccess");

    this.setState({
      user,
      choosenTenant: localStorage.getItem("choosen_tenant"),
      userPackage: JSON.parse(localStorage.getItem("userPackage"))
    }, () => {
      this.getEnableBrokerStatus();
    })

    this.setState({
      menuItem: localStorage.getItem("menuItem")
    });
  }

  async getEnableBrokerStatus() {
    let url = baseUrl.LoginPath + servicesUrl.getBrokerStatus;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    // payload
    let payload = {}

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    localStorage.setItem("broker", response?.enableBroker ?? '');
  }

  handleMenuClick(operation, i) {
    var js = document.getElementsByClassName('sidebar-main-div');
    $(js).removeClass("scroll_nav");
    this.setState({ menuItem: operation.link, smallLogo: false });
    localStorage.setItem("menuItem", operation.link);
  }

  renderHeader() {
    render(<Header shouldreset={true} customizationSettings={this.props.customizationSettings} />, document.getElementById('header'));
  }

  render() {
    
    $(".panel-title a").click(function(){
      $(".panel-title a").not(this).attr("aria-expanded", "false");
      $(this).attr("aria-expanded", function (_, attr) {
          return attr == "true" ? "false" : "true";
      });
    });
    
    const { listOpen0 } = this.state

    const user = this.state.user;

    let getTenanatFord = localStorage.getItem("switchOrgName");

    if (getTenanatFord !== "FORD" && getTenanatFord !== null) {
      localStorage.removeItem("enableFord")
    }

    const logoSrc = this.state.smallLogo
    ? "/assets/img/sidebar-menu/Webasto_W.svg"
    : "/assets/img/sidebar-menu/Webasto_logo.png";

    const logoClassName = this.state.smallLogo ? 'ml-3' : 'ml-4';
    const logoHeight = this.state.smallLogo ? 50 : 65;
    const logoWidth = this.state.smallLogo ? 50 : 185;

    const sidebarImageSrc = this.state.smallLogo
      ? "/assets/img/sidebar-menu/sidebar_show.svg"
      : "/assets/img/sidebar-menu/sidebar_hide.svg";

    return (
      <div className="sidebar-main-div">
        <div id="slide-out" className="side-nav sn-bg-4">
        <div className='mt_point8 mb_point8 logo pl-2'>
          <>
            <a href="/dashboard" className="pl-0">
              <img src={process.env.PUBLIC_URL + logoSrc} className={logoClassName} height={logoHeight} width={logoWidth} alt='logo' />
            </a>
            <img src={process.env.PUBLIC_URL + sidebarImageSrc} className='pull-right mt_point3 left-arrow' height={25} alt='sidebar-hide' onClick={this.showSidebar} />
          </>
        </div>
          <hr className='sidemenu_divline p-0 mt-2 mb-2' />
          <ul className="custom-scrollbar ps ps--active-y mt-3">
            <li>
              <ul className=" left-sidebar">
                {
                  (this.props.customizationSettings !== undefined) ?
                    this.props.customizationSettings.map((Operations, index) => {
                      if (Operations.operation === "Dashboard" && (localStorage.getItem('tenant') === "ford" || localStorage.getItem('tenant') === "Ford")) {
                        return <li>
                          <div className={` waves-effect arrow-r panel-heading ${(Operations?.subOperations?.find((each) => each.link === this.state.menuItem)) ? "active" : ""} `} style={{ paddingLeft: "0px" }} onClick={this.toggleList.bind(this, "w-dashboard.png", 0)} >
                            <span className="panel-title">
                              <a data-toggle="collapse" href="#collapse15"  data-bs-toggle="tooltip" data-bs-placement="top" title={this.state.smallLogo ? "Dashboard" : ""}>
                                {listOpen0 ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Dashboard.png"} className="menuicon_img" alt="" style={{ marginRight: "11px" }} /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Dashboard.png"} className="menuicon_img" alt="" style={{ marginRight: "11px" }} />} <span className='menuName'>{this.props.t('Dashboard')}</span> 
                                {/* {listOpen0 ? <i className="fas fa-chevron-right" /> : <i className="fas fa-chevron-up" />} */}
                              </a>
                            </span>
                          </div>
                          <div id="collapse15" className="panel-collapse collapse">
                            <ul className='suboperations'>
                              {
                                Operations.subOperations.map((operation, i) => {
                                  return (
                                    <>
                                      {(hasPermission(user, operation.permissions) || hasPermission({ permissions: ["allow"] }, operation.permissions)) && <li style={{ display: `${operation.access ? "block" : "none"}` }}><Link to={`/${operation.link}`} className={`waves-effect ${this.state.menuItem === operation.link ? "active" : ""}`} onClick={() => this.handleMenuClick(operation, i)}><span className='menuName'>{this.props.t(operation.name)}</span></Link> 
                                      {/* {window['listOpen' + index] ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-right" />} */}
                                      </li>}
                                    </>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </li>
                      } else if (Operations.operation === "Dashboard" && localStorage.getItem('tenant') !== "ford" && localStorage.getItem("role") !== "WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE") {
                        return <li style={{ display: `${Operations.active ? "block" : "none"}` }} className={` ${(this.state.menuItem === Operations.link) ? "active" : ""} `} >
                          <Link to="./dashboard" className=" waves-effect" onClick={() => {
                            this.toggleList.bind(this, "w-dashboard.png", 5);
                            this.handleMenuClick(Operations)
                          }} title={this.state.smallLogo ? "Dashboard" :""}>
                            {listOpen0 ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Dashboard.png"} className="menuicon_img" alt="" /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Dashboard.png"} className="menuicon_img" alt="" />}
                            <span className='menuName'>{this.props.t('Dashboard')}</span>
                          </Link>
                        </li>
                      } else if (Operations.operation === "Dashboard" && localStorage.getItem('tenant') !== "ford" && localStorage.getItem("role") === "WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE") {
                        return <></>
                      }
                      else if (Operations.operation === "Settings" && Operations.subOperations !== undefined && Operations.subOperations.length > 0 && localStorage.getItem("role") !== "WEBASTO_SUPERADMIN_FIRST_LEVEL" && localStorage.getItem("role") !== "WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE") {
                        return (
                          <li title={this.state.smallLogo ? this.props.t(Operations.operation):""}>
                            <div className={` waves-effect arrow-r panel-heading ${(Operations?.subOperations?.find((each) => each.link === this.state.menuItem)) ? "active" : ""}`} style={{ paddingLeft: "0px" }} onClick={this.toggleList.bind(this, "w-dashboard.png", index)}>
                              <span className="panel-title">
                                <a data-toggle="collapse" href={`#collapse${index}`}>
                                  {Operations?.subOperations?.find((each) => each.link === this.state.menuItem) ?
                                    <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/" + Operations.operation + ".png"} className="menuicon_img" alt="" style={{ marginRight: "11px" }} /> : 
                                    <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/w-settings.png"} className="menuicon_img" alt="" style={{ marginRight: "11px" }} /> }  <span className='menuName'>{this.props.t(Operations.operation)}</span> 
                                    {/* {window['listOpen' + index] ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />} */}
                                </a>
                              </span>
                            </div>
                            <div id={`collapse${index}`} className="panel-collapse collapse">
                              <ul className='suboperations'>
                                {
                                  Operations.subOperations.map((operation, i) => {
                                    if (operation.link === "preferences" && localStorage.getItem("role") === "WEBASTO_SUPER_ADMIN_DEVOPS") {
                                      return (<>
                                        <li style={{ display: `${operation.access ? "block" : "none"}` }}>
                                          <Link to={`/${operation.link}`} className={` ${this.state.menuItem === operation.link ? "active" : ""}`}
                                          onClick={() => this.handleMenuClick(operation, i)}><span className='menuName'>{this.props.t(operation.name)}</span></Link>
                                          </li>
                                      </>)
                                    } else if (operation.link === "preferences" && localStorage.getItem("role") === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE") {
                                      return (<></>)
                                    } else {
                                      return (
                                        <>
                                        {(hasPermission(user, operation.permissions) || hasPermission({ permissions: ["allow"] }, operation.permissions)) && <li style={{ display: `${operation.access ? "block" : "none"}` }}>
                                          <Link to={`/${operation.link}`} className={` ${this.state.menuItem === operation.link ? "active" : ""}`} 
                                        onClick={() => this.handleMenuClick(operation, i)}>
                                          <span className='menuName'>{this.props.t(operation.name)}</span></Link> 
                                          
                                          </li>}
                                        </>
                                      )
                                    }
                                  })
                                }
                              </ul>
                            </div>
                          </li>
                        );
                      }
                      else if (Operations.operation === "Settings" && Operations.subOperations !== undefined && Operations.subOperations.length > 0 && localStorage.getItem("role") === "WEBASTO_SUPERADMIN_FIRST_LEVEL" && localStorage.getItem("role") === "WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE") {
                        return <></>
                      }
                      else if (Operations.operation === "Charging Session" && hasPermission(user, Operations.permissions) && localStorage.getItem("role") !== "WEBASTO_SUPERADMIN_FIRST_LEVEL" && localStorage.getItem("role") !== "WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE") {
                        return <li style={{ display: `${Operations.active ? "block" : "none"}` }} className={`${(this.state.menuItem === Operations.link) ? "active" : ""}`} title={this.state.smallLogo ? this.props.t(Operations.operation):""}>
                          <Link to="./transactions" className=" waves-effect"
                            onClick={() => {
                              this.toggleList.bind(this, "w-dashboard.png", 5);
                              this.handleMenuClick(Operations)
                            }}>
                            {this.state.menuItem === Operations.link ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Charging Session.png"} className="menuicon_img" alt="" /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/WCharging Session.png"} className="menuicon_img" alt="" />}
                            <span className='menuName'>{this.props.t('Charging Session')}</span>
                          </Link>
                        </li>
                      } else if (Operations.operation === "Charging Session" && hasPermission(user, Operations.permissions) && localStorage.getItem("role") === "WEBASTO_SUPERADMIN_FIRST_LEVEL" && localStorage.getItem("role") === "WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE") {
                        return <></>
                      }

                      // common menu for chargepoints, operations, maintenance, market place, group management, reports, fota
                      else if (Operations.subOperations !== undefined && Operations.subOperations.length > 0) {
                          let array = [];
                          for (let ops in Operations.subOperations) {
                            array.push(Operations.subOperations[ops].permissions[0]);
                          }
                          let show = false;
                          let boolArray = [];
                          for (let op in Operations.subOperations) {
                            boolArray.push(Operations.subOperations[op].access);
                          }
                          show = boolArray.some(ele => ele === true);
                          return (
                            <>
                              {
                                (hasPermission(user, array) && show && Operations.active) &&                                
                                  <li className="" title={this.state.smallLogo ? this.props.t(Operations.operation):""}>
                                    <div className={` waves-effect arrow-r panel-heading ${(Operations?.subOperations?.find((each) => each.link === this.state.menuItem)) ? "active" : ""} ${window['listOpen' + index] ? "active" : ""}`} style={{ paddingLeft: "0px" }} onClick={this.toggleList.bind(this, "w-dashboard.png", index)}>
                                      <span className="panel-title">
                                        <a data-toggle="collapse" href={`#collapse${index}`}>
                                          {Operations?.subOperations?.find((each) => each.link === this.state.menuItem) ?
                                            <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/" + Operations.operation + ".png"} className="menuicon_img" alt="" />
                                            :
                                            <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/W" + Operations.operation + ".png"} className="menuicon_img" alt="" />}
                                          <span className='menuName'>{this.props.t(Operations.operation)}</span>
                                          {/* <i className="fas fa-chevron-down" /> */}
                                          {/* {window['listOpen' + index] ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />} */}

                                        </a>
                                      </span>
                                    </div>
                                    <div id={`collapse${index}`} className="panel-collapse collapse">
                                      <ul className='suboperations'>
                                        {
                                          Operations.subOperations.map((operation, i) => {
                                            return (
                                              <>
                                                {
                                                  ((localStorage.getItem('tenant').toLowerCase() !== "ford" &&
                                                    localStorage.getItem('tenant').toLowerCase() !== "webasto") &&
                                                    (operation.name === "Execution History Logs" ||
                                                      operation.name === "Error Logs" ||
                                                      operation.name === "Audit Mail")) ? <></> :
                                                    (hasPermission(user, operation.permissions) ||
                                                      hasPermission({ permissions: ["allow"] }, operation.permissions)) &&
                                                    <li style={{ display: `${operation.access ? "block" : "none"}` }}>
                                                      <Link to={`/${operation.link}`} className={` ${this.state.menuItem === operation.link ? "active" : ""}`} onClick={() => this.handleMenuClick(operation, i)}>
                                                      <span className='menuName'>{this.props.t(operation.name)}</span>
                                                      </Link>
                                                      </li>
                                                }

                                              </>
                                            )
                                          })
                                        }
                                      </ul>
                                    </div>
                                  </li>                                
                              }
                            </>
                          )                        
                      }

                      else if (Operations.operation === "Notifications" && hasPermission(user, Operations.permissions)) {
                        return (<li style={{ display: `${Operations.active ? "block" : "none"}` }} className={`${(this.state.menuItem === Operations.link) ? "active" : ""}`} title={this.state.smallLogo ? this.props.t(Operations.operation) : ""}>
                          <Link to="/notifications" className=" waves-effect"
                            onClick={() => {
                              this.toggleList.bind(this, "w-dashboard.png", 15);
                              this.renderHeader();
                              this.handleMenuClick(Operations);
                            }}>
                            {this.state.menuItem === Operations.link ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/" + Operations.operation + ".png"} className="menuicon_img" alt='' /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/W" + Operations.operation + ".png"} className="menuicon_img" alt='' />}
                            <span className='menuName'>{this.props.t('Notifications')}</span>
                          </Link>
                        </li>)
                      } else if (Operations.operation === "Reservation & Second Seating" && (this.state.userPackage === null || this.state.userPackage.reservationSeatingEnabled) && (hasPermission(user, Operations.permissions))) {
                        return <li style={{ display: `${Operations.active ? "block" : "none"}` }} className={`${(this.state.menuItem === Operations.link) ? "active" : ""}`} title={this.state.smallLogo ? this.props.t(Operations.operation) : ""}>
                          <Link to={`/${Operations.link}`} className=" waves-effect" onClick={() => {
                            this.toggleList(this, "w-dashboard.png", 15);
                            this.handleMenuClick(Operations)
                          }}>
                            {this.state.menuItem === Operations.link ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/" + Operations.operation + ".png"} alt="" className="menuicon_img" /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/W" + Operations.operation + ".png"} className="menuicon_img" alt='' />}
                            <span className='menuName'>{this.props.t(Operations.operation)}</span>
                          </Link>
                        </li>
                      } else if (Operations.operation === "Reservation & Second Seating" && this.state.userPackage !== null && this.state.userPackage.reservationSeatingEnabled === false) {
                        return <></>
                      } else {
                        return (
                          <>
                            {
                              (hasPermission(user, Operations.permissions)) &&
                              <li className={` ${window['listOpen' + index] ? "active" : ""} ${(this.state.menuItem === Operations.link) ? "active" : ""}`} style={{ display: `${Operations.active ? "block" : "none"}` }} title={ this.state.smallLogo ? this.props.t(Operations.operation) : "" }>
                                <Link to={`/${Operations.link}`} className=" waves-effect"
                                  onClick={() => {
                                    this.toggleList.bind(this, "w-dashboard.png", index);
                                    this.handleMenuClick(Operations)
                                  }}>
                                  {
                                    this.state.menuItem === Operations.link ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/" + Operations.operation + ".png"} className="menuicon_img" alt="" />
                                      :
                                      <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/W" + Operations.operation + ".png"} className="menuicon_img" alt="" />
                                  }
                                  <span className='menuName'>{this.props.t(Operations.operation)}</span>
                                </Link>
                              </li>
                            }
                          </>)
                      }
                    }) : null
                }
              </ul>
            </li>
            {/* Side navigation links */}
          </ul>
          <div className="sidenav-bg mask-strong"></div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Sidebar);
