import React from 'react';
import * as baseUrl from '../views/constants';
import { withTranslation } from 'react-i18next';

//Component Imports
import GuestVouchersTemplate from '../Screens/GuestVouchersTemplate';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';
import servicesUrl from '../common/servicesUrl';
import { data } from 'jquery';

class GuestVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      errors: {},
      isOpen: false,
      selectedOptions: {
        voucherID: [],
        group: []

      },
      selectedPopupOptions: {
        group: []
      },
      selectedPopupValues: {
        group: []
      },
      selectedValues: {
        voucherID: [],
        group: []
      },
      filterVoucherList: [],
      filterGroupList: [],
      filterPopupGroupList: [],
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpenState: false,
      currentOpenDropdown: null,
      searchedValues: [],
      isDataAvailable: true,
      voucherList: [],
      noOfRecords: "",
      data: new Map(),
      pageSize: 10,
      activePage: 1,
      pageNo: 1,
      QRCodeMangData: [],
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: [],
      filterGroupListName:[],
      fromDate: "",
      fromDateApi: "",
      toDate: "",
      toDateApi: "",
      activeTab: 'Active',
      showLoder: false,
      activeApplyBtn: false,
      activePopupApplyBtn: false,
      isCreateOrEditGVPopupOpen: false,
      isEnergyLimitActivated: false,
      energyLimitValue: '',
      ExpiryInDaysvalue: '',
      isPeriodOfDaysActivated: false,
      isVoucherAssignToGuestActivated: false,
      noOfDuplicateVouchers: '',
      userAction: "",
      isDeactivatePopupOpen: false,
      isSuccessDeactivatePopupOpen: false,
      isActivateVoucherPopupOpen: false,
      isSuccessActivatePopupOpen: false,
      isChangesUpdatedPopupOpen: false,
      isVoucherCreatedSuccessPopupOpen: false,
      moremodalGroup: false,
      guestName: '',
      searchValue: "",
      searchPopupValue: '',
      isSearchNotFound: false,
      qrcodeId: "",
      qrCodeStatus: true,
      searchGroupValue: "",
      isLoading: false,
      qrstatus:false,
      editVoucherID: "",
      id: "",
      alertModel: false,
      isLimitEnergyusageActive: false,
      isLimitPeriodOfUseActive: false,
      parentId: "",
      siteName: "",
      warningModel:"",
      warningMsg:""
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleActiveTab = this.handleActiveTab.bind(this);
    this.handleCreateNewGVBtn = this.handleCreateNewGVBtn.bind(this);
    this.closeGVPopUp = this.closeGVPopUp.bind(this);
    this.handleCreateNewGV = this.handleCreateNewGV.bind(this);
    this.handleEditGV = this.handleEditGV.bind(this);
    this.handleDeactivateVoucher = this.handleDeactivateVoucher.bind(this);
    this.closeDeactivatePopup = this.closeDeactivatePopup.bind(this);
    this.SuccessDeativatePopup = this.SuccessDeativatePopup.bind(this);
    this.closeSuccessDeativatePopup = this.closeSuccessDeativatePopup.bind(this);
    this.handleActivateVoucherPopup = this.handleActivateVoucherPopup.bind(this);
    this.closeActivateVoucherPopup = this.closeActivateVoucherPopup.bind(this);
    this.SuccessAtivateVoucherPopup = this.SuccessAtivateVoucherPopup.bind(this);
    this.closeSuccessActivateVoucherPopup = this.closeSuccessActivateVoucherPopup.bind(this);
    this.closeChangesUpdatedPopup = this.closeChangesUpdatedPopup.bind(this);
    this.handleOnChangeEnergyLimit = this.handleOnChangeEnergyLimit.bind(this);
    this.handleOnChangePeriodOfDays = this.handleOnChangePeriodOfDays.bind(this);
    this.handleEditingVoucher = this.handleEditingVoucher.bind(this);
    this.handleOnChangeVoucherGuest = this.handleOnChangeVoucherGuest.bind(this);
    this.closeVoucherCreatedPopup = this.closeVoucherCreatedPopup.bind(this);
    this.downloadGuestVoucher = this.downloadGuestVoucher.bind(this);
    this.getVouchersList = this.getVouchersList.bind(this);
    this.handleRefreshButton = this.handleRefreshButton.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.getFilterHistory = this.getFilterHistory.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handlePopupSearchChange = this.handlePopupSearchChange.bind(this);
    this.removeSelectedValue = this.removeSelectedValue.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.onChangeDuplicateVouchers = this.onChangeDuplicateVouchers.bind(this);
    this.createdDatesArray = this.createdDatesArray.bind(this);
    this.removeSelectedValueForPopup = this.removeSelectedValueForPopup.bind(this);
    this.onChangeLimitEnergyusageValue = this.onChangeLimitEnergyusageValue.bind(this);
    this.handleEnergyLimit = this.handleEnergyLimit.bind(this);
    this.onChangeLimitPeriodOfUseValue = this.onChangeLimitPeriodOfUseValue.bind(this);
    this.handlePeriodOfUse = this.handlePeriodOfUse.bind(this);
    this.handlePopupCheckboxChange = this.handlePopupCheckboxChange.bind(this);
    this.handlePopupSubmit = this.handlePopupSubmit.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleGroupSearchChange = this.handleGroupSearchChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkIdGetName =   this.checkIdGetName.bind(this);
    this.updateGuestVouchers = this.updateGuestVouchers.bind(this);
    this.exportQRCodeDetails = this.exportQRCodeDetails.bind(this);
    this.createQRCode = this.createQRCode.bind(this);
    this.downloadQRCode = this.downloadQRCode.bind(this);
    this.handleOnChangeGuestName = this.handleOnChangeGuestName.bind(this);
    this.warningPopup = this.warningPopup.bind(this)

  }

  async updateGuestVouchers() {
    
    var errors = {}
    let url = baseUrl.URLPath + servicesUrl.updateQRcodeDetails + "/details?id=" + this.state.id;

    let enableEnergyLimitUsage = this.state.isEnergyLimitActivated;
    let enableExpiryInDays = this.state.isPeriodOfDaysActivated;

    if(enableEnergyLimitUsage === true) {
      this.setState({ energyLimitValue: this.state.energyLimitValue })
    } else {
      this.setState({ energyLimitValue: "" })
    }

    if(enableExpiryInDays === true) {
      this.setState({ ExpiryInDaysvalue: this.state.ExpiryInDaysvalue })
    } else {
      this.setState({ ExpiryInDaysvalue: "" })
    }

    let data = {
      "energyLimitActive": enableEnergyLimitUsage,
      "expiryInDaysLimitActive": enableExpiryInDays,
      "energyLimit": this.state.energyLimitValue ? parseFloat(this.state.energyLimitValue) : 0,
      "expiryInDays": this.state.ExpiryInDaysvalue ? parseFloat(this.state.ExpiryInDaysvalue) : 0,
      "guestUserName": this.state.guestName
    }

    this.setState({ isLoading: true })
    let type = ApiMethodTypes.PUT;
    let headers = getSecurityHeaders();
    let payload = data;
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);
    if (response) {
      setTimeout(() => {
        this.setState({ isLoading: false })
        if (response !== undefined) {
          if (response.status === 404 || response.status === 400 || response.status === 403) {
            if (response.message === "qr_code_is_inactive") {
            } else if (response.message === "qr_code_expiry_days_update") {
              errors["daysLimiterr"] = this.props.t('qr_code_expiry_days_update');
            } else if (response.message === "qr_code_energy_update") {
              errors["energyLimiterr"] = this.props.t('qr_code_energy_update');
              // this.props.t('please_enter_noof_days');
            } else if (response.message === "energy_limit_must_be_positive_and_max_value") {
              errors["energyLimiterr"] = this.props.t('energy_limit_must_be_positive_and_max_value');
            } else if (response.message === "expire_in_days_must_be_positive_and_max_value") {
              errors["daysLimiterr"] = this.props.t('expire_in_days_must_be_positive_and_max_value');
            }
            else if (response.message === "group_owner_detail_not_exist" || response.message === "qr_code_status" || response.message === "group_not_exists" || response.message === "qr_code_expired" || response.message === 'package_qr_code_status') {
              this.setState({
                warningModel: !this.state.warningModel,
                warningMsg: this.props.t(response.message)
              })
           //   this.toggleAlert();
            }
            this.setState({
              errors: errors
            })
          } else {
            if (response.message === "qr_code_updated_succussfully") {
              this.setState({ isChangesUpdatedPopupOpen: true, 
                isCreateOrEditGVPopupOpen: false })
            }
          }
        }
      }, 100);
    }
  }
  removeSelectedValueForPopup(category, value) {
    const { selectedPopupValues } = this.state;
    const updatedSelectedValues = { ...selectedPopupValues };
    updatedSelectedValues[category] = updatedSelectedValues[category].filter((val) => val !== value);
    this.setState({
      selectedPopupOptions: updatedSelectedValues,
      selectedPopupValues: updatedSelectedValues
    });

    // this.setState({
    //   selectedPopupOptions: updatedSelectedValues,
    //   selectedPopupValues: updatedSelectedValues
    // }, () => {
    //   this.getVouchersList(1, this.state.pageSize)
    // });
  }

  onChangeDuplicateVouchers(e) {
    let duplicateValue = e.target.value;
    let errors = {}

    if (e.target.validity.valid) {
      if (duplicateValue === '' || (/^\d*$/.test(duplicateValue) && duplicateValue >= 1 && duplicateValue <= 100)) {
        let error = this.state.errors;
        error['duplicateQRCodeerr'] = ''
        this.setState({
          noOfDuplicateVouchers: duplicateValue,
          errors: error
        });
      } else {
        errors["duplicateQRCodeerr"] = this.props.t('please_enter_1_to_100');
        this.setState({ errors: errors })
      }
    }
  }

  handleOnChangeGuestName = (e) => {
    let guestName = e.target.value;
    let errors = {};
    if (/^[a-zA-Z\s]{0,20}$/.test(guestName)) {
      errors['guestNameLimiter'] = '';
    }
    else if(guestName.length>20){
      errors['guestNameLimiter'] = this.props.t('please_enter_maximum_20_characters');
    } 
    else {
      errors['guestNameLimiter'] = this.props.t('please_enter_valid_guestname');
    }
    this.setState({ guestName: guestName, errors:errors});
  }

  handlePeriodOfUse = (e) => {
    let daysLimit = e.target.value;
    if (e.key === "Backspace" && (daysLimit.length === 2 || daysLimit.length === 3) && daysLimit.startsWith("0")) {
      this.setState({
        ExpiryInDaysvalue: daysLimit.slice(0, 1)
      });
    } if (e.key === "Delete" && (daysLimit.length === 2 || daysLimit.length === 3) && daysLimit.startsWith("0")) {
      this.setState({
        ExpiryInDaysvalue: daysLimit.slice(1, 2)
      });
    }
  };

  onChangeLimitPeriodOfUseValue = (e) => {
    let daysLimit = e.target.value;
    let errors = {}
    if (e.target.validity.valid) {
      if (daysLimit === '' || (/^\d*$/.test(daysLimit) && daysLimit >= 1 && daysLimit <= 1000)) {
        errors['daysLimiterr'] = ''
        this.setState({
          ExpiryInDaysvalue: daysLimit
        });
      } else {
        errors["daysLimiterr"] = this.props.t('please_enter_1_to_1000');
        
      }
      this.setState({ errors: errors })
    }
  }

  handleEnergyLimit = (e) => {
    let energyLimit = e.target.value;
    if (e.key === "Backspace" && (energyLimit.length === 2 || energyLimit.length === 3) && energyLimit.startsWith("0")) {
      this.setState({
        energyLimitValue: energyLimit.slice(0, 1)
      });
    }
    if (e.key === "Delete" && (energyLimit.length === 2 || energyLimit.length === 3) && energyLimit.startsWith("0")) {
      this.setState({
        energyLimitValue: energyLimit.slice(1, 2)
      });
    }
  };

  
  onChangeLimitEnergyusageValue = (e) => {
    let energyLimit = e.target.value;
    let errors = {}
    // let error = this.state.errors;
    if (e.target.validity.valid) {
      if (energyLimit === '' || (/^\d*$/.test(energyLimit) && energyLimit >= 1 && energyLimit <= 1000)) {
        errors['energyLimiterr'] = ''
        this.setState({
          energyLimitValue: energyLimit
        });
      } else {
        errors["energyLimiterr"] = this.props.t('please_enter_1_to_1000');
        
      }
      this.setState({ errors: errors })
    }
  }
  downloadGuestVoucher() {
    //API call for download the guest voucher
    this.downloadQRCode(this.state.parentId)
  }


  closeVoucherCreatedPopup() {
    this.setState({ isVoucherCreatedSuccessPopupOpen: false }, () => {
      this.getVouchersList(1, this.state.pageSize)
    })
  }

  // handleEditingVoucher = (value, id, name, siteName) => {
  //   let emptySeletedOptions = { group: [] }
  //   this.setState({
  //     isCreateOrEditGVPopupOpen: true, userAction: 'EditingGV',
  //     editVoucherID: value,
  //     id: id,
  //     guestName: name,
  //     siteName: siteName,
  //     isVoucherAssignToGuestActivated: false, isEnergyLimitActivated: false, isPeriodOfDaysActivated: false, selectedPopupOptions: emptySeletedOptions,
  //     energyLimitValue: '', ExpiryInDaysvalue: '', guestName: '', errors: {}
  //   })
  // }

  handleEditingVoucher = (data) => {
    let emptySeletedOptions = { group: [] }
    let value = data.qrId;
    let id = data.id;
    let name = data.guestUserName;
    let siteName = data.siteName;
    // let energylimit = parseFloat(data.energyLimit).toFixed(0);
    let energylimit = data.energyLimit === null ? "" : parseFloat(data.energyLimit).toFixed(0);
    let dayslimit = (data.expiryInDays === null || data.expiryInDays === 0) ? "" : data.expiryInDays;
    
    this.setState({
      isCreateOrEditGVPopupOpen: true, userAction: 'EditingGV',
      editVoucherID: value,
      id: id,
      guestName: name,
      siteName: siteName,
      energyLimitValue: energylimit,
      ExpiryInDaysvalue: dayslimit,
      isVoucherAssignToGuestActivated: name? true:false, isEnergyLimitActivated: energylimit? true:false, isPeriodOfDaysActivated: dayslimit? true:false, selectedPopupOptions: emptySeletedOptions,
      errors: {}
    })
  }

  handleOnChangeVoucherGuest(e) {
    this.setState((prevState) => ({
      isVoucherAssignToGuestActivated: !prevState.isVoucherAssignToGuestActivated
    }))
    if(this.state.isVoucherAssignToGuestActivated){
      this.setState({guestName : ''})
    }
  }
  handleOnChangePeriodOfDays() {
    this.setState((prevState) => ({
      isPeriodOfDaysActivated: !prevState.isPeriodOfDaysActivated,
      isLimitPeriodOfUseActive: !prevState.isLimitPeriodOfUseActive
    }))
  }
  handleOnChangeEnergyLimit() {
    this.setState((prevState) => ({
      isEnergyLimitActivated: !prevState.isEnergyLimitActivated,
      isLimitEnergyusageActive: !prevState.isLimitEnergyusageActive
    }))
  }
  closeChangesUpdatedPopup() {
    this.setState({ isChangesUpdatedPopupOpen: false, isCreateOrEditGVPopupOpen: false }, () => {
      this.getVouchersList(1, this.state.pageSize);
    }, 100)
  }
  closeSuccessActivateVoucherPopup() {
    this.setState({ isSuccessActivatePopupOpen: false })
  }
  SuccessAtivateVoucherPopup() {
    this.setState({ isActivateVoucherPopupOpen: false })
    this.DeactivateQRCide();
  }
  closeActivateVoucherPopup() {
    this.setState({ isActivateVoucherPopupOpen: false })
  }
  handleActivateVoucherPopup(status,id) {
    this.setState({
        isActivateVoucherPopupOpen: true,
        statusQrId:id,
        qrstatus:!status,
    });
}
  closeSuccessDeativatePopup() {
    this.setState({ isSuccessDeactivatePopupOpen: false })
  }

  warningPopup() {
    this.setState({ warningModel: !this.state.warningModel })
  }

  SuccessDeativatePopup() {
    //api call for deactivate the voucher
    this.setState({ isDeactivatePopupOpen: false })
    this.DeactivateQRCide();
  }


  async DeactivateQRCide(){
   
    var url = baseUrl.URLPath + servicesUrl.saveActivateDeactivateStatus + "?qrId=" + this.state.statusQrId + "&status=" + this.state.qrstatus;
    let type = ApiMethodTypes.PUT;
    let headers = getSecurityHeaders();
    let response = await APICallUtility.cpmsAPIFetch(url, type, {}, headers, this.props.t, true);
    if (response !== undefined) {
        if (response.status === 400 || response.status === 404 || response.status === 403) {
            if (response.message === "group_owner_detail_not_exist" || response.message === "qr_code_status" || response.message === "group_not_exists") {
              this.setState({
                warningModel: !this.state.warningModel,
                warningMsg: this.props.t(response.message)
              })
            }
        } else {
          this.getVouchersList(1,this.state.pageSize);
           if(response.message==="qr_code_activated"){
            this.setState({
              isSuccessActivatePopupOpen:true
            })
           }else if(response.message==="qr_code_deactivated"){
            this.setState({
              isSuccessDeactivatePopupOpen:true
            })
           }
        }
    }
}

downloadQRCode(id) {
  var url = baseUrl.URLPath + servicesUrl.downloadQRCodes + "?parentId=" + id;
  fetch(url, {
      method: "GET",
      headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
      }
  }).then((resp) => {
      if (resp.status === 401) {
          // this.togglesession();
          // localStorage.clear();
          window.location.reload();
      } else if (resp.status === 200) {
          return resp.blob();
          // return resp.json();
      } else if (resp.status === 404 || resp.status === 403) {
          return resp.json();
      }
  }).then((response) => {
      if (response !== undefined) {
          if (response.message === "group_owner_detail_not_exist" || response.message === "qr_code_status" || response.message === "group_not_exists") {
            this.setState({
              warningModel: !this.state.warningModel,
              warningMsg: this.props.t(response.message),
             })
          } else {
              let d = new Date();
              let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
              let url = window.URL.createObjectURL(response);
              let a = document.createElement('a');
              a.href = url;
              a.download = this.checkIdGetName(this.state.QRSiteId) + '.pdf';
              a.click();
              this.setState({ isVoucherCreatedSuccessPopupOpen: false }, () => {
                this.getVouchersList(1, this.state.pageSize);
              }, 200)
          }
      }
  }).catch((error) => {
      console.log(error, "catch the loop")
  })
}

exportQRCodeDetails(id,qrId) {
  var url = baseUrl.URLPath + servicesUrl.exportQRcodeDetails + "?id=" + id;
  fetch(url, {
      method: "GET",
      headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
      }
  }).then((resp) => {
      if (resp.status === 401) {
          // this.togglesession();
          // localStorage.clear();
          window.location.reload();
      } else if (resp.status === 200) {
          return resp.blob();
      } else if (resp.status === 404 || resp.status === 403) {
          return resp.json();
      }
  }).then((response) => {
      if (response !== undefined) {
          if (response.message === "group_owner_detail_not_exist" || response.message === "qr_code_status" || response.message === "group_not_exists") {
            this.setState({
              warningModel: !this.state.warningModel,
              warningMsg: this.props.t(response.message),
             })
          } else {
              let d = new Date();
              let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
              let url = window.URL.createObjectURL(response);
              let a = document.createElement('a');
              a.href = url;
              a.download = qrId + '.pdf';
              a.click();
          }
      }
  }).catch((error) => {
      console.log(error, "catch the loop")
  })
}

createQRCode(){
  this.setState({ isLoading: true })
      let duplicate_value = this.state.noOfDuplicateVouchers;
      let energyLimit = this.state.energyLimitValue;
      let daysLimit = this.state.ExpiryInDaysvalue;
      let id = this.state.QRSiteId;
      let enableEnergyLimitUsage = this.state.isEnergyLimitActivated;
      let enableExpiryInDays = this.state.isPeriodOfDaysActivated;

      var url = baseUrl.URLPath + servicesUrl.createQRCode;
      var bodydata = {
              "energyLimit": energyLimit ? parseFloat(energyLimit) : 0 ,
              "expiryInDays": daysLimit ? parseFloat(daysLimit) : 0 , 
              "noOfCopies": duplicate_value ? parseFloat(duplicate_value) : 0 ,
              "siteId": id,
              "enableEnergyLimitUsage": enableEnergyLimitUsage,
              "enableExpiryInDays": enableExpiryInDays
      }
      fetch(url, {
          method: "POST",
          body: JSON.stringify(bodydata),
          headers: {
              "content-type": "application/json",
              "Authorization": localStorage.getItem("Authorization"),
          }
      }).then((resp) => {
      if (resp.status === 401) {
          setTimeout(() => {
              this.setState({ isLoading: false })
              window.location.reload()
          }, 100);
      }else if(resp.status === 400){
            this.setState({ isLoading: false })
           return resp.json();
      }else if(resp.status === 403){
          this.setState({ isLoading: false })
          return resp.json();
      }
      else if (resp.status === 200) {
          // return resp.blob();
          return resp.json();
      }
      }).then((response) => {
          if (response !== undefined) {
              if(response.status===400 || response.status===403 ){
                  let qr_warning_msg = "";
                 if(response.message==="energy_limit_must_be_positive_and_max_value"){
                   qr_warning_msg = this.props.t('energy_limit_must_be_positive_and_max_value')
                 }else if(response.message==="expire_in_days_must_be_positive_and_max_value"){
                  qr_warning_msg = this.props.t('expire_in_days_must_be_positive_and_max_value')
                 }else if(response.message==="number_of_copies_limit"){
                  qr_warning_msg = this.props.t('number_of_copies_limit')
                 }else if(response.message==="site_no_chargers"){
                  qr_warning_msg = this.props.t('site_no_chargers')
                 }else if(response.message === "group_owner_detail_not_exist"){
                  qr_warning_msg = this.props.t('group_owner_detail_not_exist')
                 }else if(response.message === "package_qr_code_status"){
                  qr_warning_msg = this.props.t('package_qr_code_status')
                }
                 this.setState({
                  warningModel: !this.state.warningModel,
                  warningMsg:qr_warning_msg,
                  isLoading: false
                 })
              }else{                 
                  if(response.message === "qr_codes_creation_success"){   
                    this.setState({ 
                      parentId: response.parentId,
                      isLoading: false }, () => {
                        this.setState({ isVoucherCreatedSuccessPopupOpen: true })
                      })
                  }
              }
              this.setState({ isLoading: false })
          }
      }).catch((error) => {
          this.setState({ isLoading: false })
          console.log(error, "catch the loop")
      })
  }

  closeDeactivatePopup() {
    this.setState({ isDeactivatePopupOpen: false })
  }

  handleDeactivateVoucher(status,id) {
      this.setState({
          isDeactivatePopupOpen: true,
          statusQrId:id,
          qrstatus:!status,
      });
  }

  handleCreateNewGV() {
    let errors = {}
    const { isEnergyLimitActivated, isPeriodOfDaysActivated, energyLimitValue, ExpiryInDaysvalue, noOfDuplicateVouchers, selectedPopupValues } = this.state;
    if (noOfDuplicateVouchers !== '' && selectedPopupValues['group'].length !== 0) {    
      if ((isEnergyLimitActivated === false || (isEnergyLimitActivated && energyLimitValue !== '')) && (isPeriodOfDaysActivated === false || (isPeriodOfDaysActivated && ExpiryInDaysvalue !== ''))) {
        // API call to create new Guest Vouchers
        this.setState({ isCreateOrEditGVPopupOpen: false, errors: errors }, 
          () => {
            this.createQRCode();
          })
      }
      else {
        if (isEnergyLimitActivated) {
          if (energyLimitValue === '') {
            errors["energyLimiterr"] = this.props.t('please_enter_energy_limit');
          }
        }
        if (isPeriodOfDaysActivated) {
          if (ExpiryInDaysvalue === '') {
            errors['daysLimiterr'] = this.props.t('please_enter_1_to_1000');
          }
        }
        this.setState({ errors: errors })
      }

    } else {
      if (noOfDuplicateVouchers === '' || noOfDuplicateVouchers === null || noOfDuplicateVouchers === undefined) {
        errors["duplicateQRCodeerr"] = this.props.t('please_enter_noof_duplicates');
      }
      if (selectedPopupValues['group'].length === 0) {
        errors['gvpopupgrouperror'] = this.props.t('please_select_group')
      }
      this.setState({ errors: errors })
    }
  }

  handleEditGV() {
    let errors = {}
    const { isEnergyLimitActivated, isPeriodOfDaysActivated, energyLimitValue, ExpiryInDaysvalue, selectedPopupValues, isVoucherAssignToGuestActivated, guestName , siteName } = this.state;
    
    if (siteName!==null && siteName.length !== 0) {
      if ((isEnergyLimitActivated === false || (isEnergyLimitActivated && energyLimitValue !== '')) && (isPeriodOfDaysActivated === false || (isPeriodOfDaysActivated && ExpiryInDaysvalue !== '')) && (isVoucherAssignToGuestActivated === false || (isVoucherAssignToGuestActivated && guestName !== '' && guestName !== null && guestName.length<=20 && guestName.length>0 && (/^[a-zA-Z\s]{1,20}$/.test(guestName))))) {
        errors['energyLimiterr'] = '';
        errors['daysLimiterr'] = '';
        errors['guestNameLimiter'] = '';

        //API call to update a guest voucher
        this.updateGuestVouchers();
        // isCreateOrEditGVPopupOpen: false, 
        this.setState({ errors: errors })
      }
      else {
        if (isEnergyLimitActivated) {
          if (energyLimitValue === '') {
            errors["energyLimiterr"] = this.props.t('please_enter_energy_limit');
          }
        }
        if (isPeriodOfDaysActivated) {
          if (ExpiryInDaysvalue === '') {
            errors['daysLimiterr'] = this.props.t('please_enter_1_to_1000');
          }
        }
        if (isVoucherAssignToGuestActivated && guestName !== null) {
          if (/^[a-zA-Z\s]{1,20}$/.test(guestName)) {
            errors['guestNameLimiter'] = '';
             //API call to update a guest voucher
              
          }
          else if(guestName.length>20){
            errors['guestNameLimiter'] = this.props.t('please_enter_maximum_20_characters');
          } 
          else {
            errors['guestNameLimiter'] = this.props.t('please_enter_valid_guestname');
            
          }
        }else{
          errors['guestNameLimiter'] = this.props.t('please_enter_valid_guestname');

        }
        this.setState({ errors: errors })
      }
    } else {
      if (isEnergyLimitActivated) {
        if (energyLimitValue === '') {
          errors["energyLimiterr"] = this.props.t('please_enter_energy_limit');
        }
      }
      if (isPeriodOfDaysActivated) {
        if (ExpiryInDaysvalue === '') {
          errors['daysLimiterr'] = this.props.t('please_enter_1_to_1000');
        }
      }
      if (isVoucherAssignToGuestActivated) {
        if (/^[a-zA-Z\s]{0,20}$/.test(guestName)) {
          errors['guestNameLimiter'] = '';
           //API call to update a guest voucher
            
        }
        else if(guestName.length>20){
          errors['guestNameLimiter'] = this.props.t('please_enter_maximum_20_characters');
          
        } 
        else {
          errors['guestNameLimiter'] = this.props.t('please_enter_valid_guestname');
          
        }
      }
      // errors['gvpopupgrouperror'] = this.props.t('please_select_group')
      
      
      this.setState({ errors: errors })
    }

  }

  closeGVPopUp() {
    this.setState({ isCreateOrEditGVPopupOpen: false })
  }
  handleCreateNewGVBtn() {
    let emptySeletedOptions = { group: [] }
    this.setState({
      isCreateOrEditGVPopupOpen: true, userAction: "CreatingNewGV", noOfDuplicateVouchers: '', selectedPopupOptions: emptySeletedOptions, selectedPopupValues: emptySeletedOptions,
      isEnergyLimitActivated: false, isPeriodOfDaysActivated: false, energyLimitValue: '', ExpiryInDaysvalue: '', errors: {}
    })
  }

  removeSelectedValue = (category, value) => {
    const { selectedValues } = this.state;
    const updatedSelectedValues = { ...selectedValues };
    updatedSelectedValues[category] = updatedSelectedValues[category].filter(
      (val) => val !== value
    );
    this.setState(
      {
        selectedOptions: updatedSelectedValues,
        selectedValues: updatedSelectedValues,
      },
      () => {
        this.getVouchersList(1, this.state.pageSize);
      }
    );
  };

  removeAllSelected = () => {
    const updatedObject = { ...this.state.selectedValues };
    updatedObject.voucherID = [];
    updatedObject.group = [];


    const newObject = { ...this.state.selectedOptions };
    newObject.voucherID = [];
    newObject.group = [];

    setTimeout(() => {
      this.setState(
        {
          selectedValues: updatedObject,
          selectedOptions: newObject,
          showLoder: !this.state.showLoder,
          isDataAvailable: true,
        },
        () => {
          this.getVouchersList(1, this.state.pageSize);
        }
      );
    }, 1000);
    this.setState({
      showLoder: !this.state.showLoder,
    });
  };


  showEntriesChange = (selectedOption) => {
    var entries = selectedOption.value;
    this.setState(
      {
        activePage: 1,
        pageSize: entries,
        fromDateApi: '',
        toDateApi: '',
      },
      () => {
        this.getVouchersList(1, entries)
      }
    );
  };

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [
      ...this.state.listOfFirstCreatedtimes,
      dateTime,
    ];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes,
    });
  }

  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
      //prev
      this.setState({
        activePage: pageNumber,
        fromDateApi: this.state.curPageFirstCreTime,
        toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getVouchersList(pageNumber, this.state.pageSize);
      });
    } else {
      //next
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({
        activePage: pageNumber,
        fromDateApi: '',
        toDateApi: this.state.curPageLastCreTime,
        prevPageFirstCreTime: this.state.curPageFirstCreTime,
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.createdDatesArray(this.state.curPageFirstCreTime);
        }
        this.getVouchersList(pageNumber, this.state.pageSize);
      });
    }
  }

  handleActiveTab(tab,status) {
    let newSelectedValues = {
      voucherID: [],
      group: []
    }
    this.setState({
      activeTab: tab,
      qrCodeStatus: status,
      selectedOptions: newSelectedValues,
      selectedValues: newSelectedValues
    }, () => {
      this.getVouchersList(1, this.state.pageSize);
    })
  }
  emptySearchValues = () => {    
    let errors = {};
    errors["nodatafound"] = "";
    this.setState({ 
      searchValue: "",
      searchGroupValue: "",
      searchPopupValue: "",
      errors: errors
    })   
  }

  async getVouchersList(Page, pageSize) {
    this.setState({
      activePage:Page
    })
    this.setState({ isLoading: true })
    let isSearchNotFound = this.state.isSearchNotFound;
    let qrcodeId = this.state.qrcodeId;

    if (isSearchNotFound) {
      qrcodeId = "";
    }
    let url = `${baseUrl.URLPath}${servicesUrl.getQRcode}`;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();
    let payload = {
      "pageNo": Page,
      "pageSize": pageSize,
      "siteIds": this.state.selectedValues.group,
      "status": this.state.qrCodeStatus,
      "vouchersIds": this.state.selectedValues.voucherID
    };

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (this.state.isOnSearchFind) {
      if (response.count > 0) {
        this.setState({ ...this.state, QRCodeMangData: response.data, noOfRecords: response.count, isSearchNotFound: false, isOnSearchFind: false, isLoading: false });
      } else {
        this.setState({ ...this.state, isSearchNotFound: true, isLoading: false });
      }
    } else {
      this.setState({ ...this.state, QRCodeMangData: response.data, noOfRecords: response.count, isSearchNotFound: false, isOnSearchFind: false, isLoading: false });
    }
  }

  handleRefreshButton() {
    this.getVouchersList(1, this.state.pageSize);
  }


  async handlePopupSearchChange(field, e) {
    const value = e.target.value;
    this.setState({ searchPopupValue: value })
    let errors = {};
    const lowercaseValue = value.toLowerCase();
    if (value !== "" && value.length >= 3) {
      let filterData = await this.getGroupList(value, "");
      let filterPopupGroupList = filterData ? filterData.map(data => data.id) : [];
      let filterGroupListName = filterData ? filterData.map(data => data) : [];
      if (filterPopupGroupList.length === 0) {
        errors["nodatafound"] = this.props.t('No data found..!');
        this.setState({ errors: errors, filterPopupGroupList: [] })
      } else {
        errors["nodatafound"] = "";
        this.setState({ filterPopupGroupList: filterPopupGroupList,
          filterGroupListName: [...this.state.filterGroupListName, ...filterGroupListName],
          errors: errors })
      }
    } else {
      this.setState({ errors: errors, filterPopupGroupList: []})
    }
  }

  async handleSearchChange(field, event) {
    const value = event.target.value;
    this.setState({ searchValue: value })
    let errors = {};
    const lowercaseValue = value.toLowerCase();
    if (value !== "" && value.length >= 3) {
      let filterData = await this.getvoucherIdsList(value, "");
      let filterVoucherList = filterData ? filterData.map(data => data) : [];
      if (filterVoucherList.length === 0) {
        errors["nodatafound"] = this.props.t('No data found..!');
        this.setState({ errors: errors, filterVoucherList: [] })
      } else {
        errors["nodatafound"] = "";
        this.setState({ filterVoucherList: filterVoucherList, errors: errors })
      }
    } else {
      this.setState({ errors: errors, filterVoucherList: [] })
    }

  };

  async getvoucherIdsList(cpid, text) {
    var url = "";
    url = baseUrl.URLPath + servicesUrl.getPartialVoucherIds + "?voucherId=" + cpid;

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      if (response.message === "cp_not_exists") {
        response = [];
      } else {
        response = response
      }
      this.setState({ filterVoucherList: response })
      return response;
    }
  }

  handlePopupCheckboxChange = (category, val) => {
    let { errors } = this.state;
    errors['gvpopupgrouperror'] = '';

    this.setState(prevState => {
        const updatedOptions = { ...prevState.selectedPopupOptions };
        const updatedCategoryOptions = updatedOptions[category];

        const isAlreadySelected = updatedCategoryOptions.includes(val);

        if (isAlreadySelected) {
            updatedOptions[category] = [val];
        } else {
            updatedOptions[category] = [val]; 
        }

        this.setState({
            selectedPopupOptions: updatedOptions,
            activePopupApplyBtn: true,
            errors: errors
        });
    });
};

  handleCheckboxChange = (category, val) => {
    this.setState((prevState) => {
      const updatedOptions = { ...prevState.selectedOptions };
      const updatedCategoryOptions = updatedOptions[category];

      if (updatedCategoryOptions.includes(val)) {
        updatedOptions[category] = updatedCategoryOptions.filter(
          (item) => item !== val
        );
      } else {
        updatedOptions[category] = [...updatedCategoryOptions, val];
      }

      this.setState({
        selectedOptions: updatedOptions,
        activeApplyBtn: updatedOptions[category].length > 0,
      });
    });
  };

  handlePopupSubmit = (value) => {
    const isOpenState = {};
    isOpenState[value] = !this.state[value];

    const categoryMapping = {
      isOpen3: "group",
    };

    const updatedSelectedValues = { ...this.state.selectedPopupOptions };

    if (categoryMapping[value]) {
      updatedSelectedValues[categoryMapping[value]] = this.state.selectedPopupOptions[categoryMapping[value]];
    }

    this.setState({
      ...isOpenState,
      selectedPopupValues: updatedSelectedValues,
      searchValue: "",
      // filterPopupGroupList : [],
      activePopupApplyBtn: false,
    }, () => {
      this.setState({
        QRSiteId: this.state.selectedPopupValues.group.toString()
      })
      console.log(this.state.selectedPopupValues.group, "test");
      // this.getVouchersList(1, this.state.pageSize)
    })

    // this.setState({
    //   ...isOpenState,
    //   selectedPopupValues: updatedSelectedValues,
    //   searchValue: "",
    //   // filterPopupGroupList : [],
    //   activePopupApplyBtn: false,
    // }, () => {
    //   console.log(this.state.selectedPopupValues.group, "test");
    //   // this.getVouchersList(1, this.state.pageSize)
    // })

  }

  handleSubmit = (value) => {
    const isOpenState = {};
    isOpenState[value] = !this.state[value];

    const categoryMapping = {
      isOpen1: "voucherID",
      isOpen2: "group",

    };

    const updatedSelectedValues = { ...this.state.selectedOptions };

    if (categoryMapping[value]) {
      updatedSelectedValues[categoryMapping[value]] = this.state.selectedOptions[categoryMapping[value]];
    }

    this.setState({
      ...isOpenState,
      selectedValues: updatedSelectedValues,
      searchValue: "",
      filterVoucherList: [],
      filterGroupList: [],
      activeApplyBtn: false,
    }, () => {
      this.getVouchersList(1, this.state.pageSize)
    })
  };

  checkIdGetName(val, type){
    console.log(this.state.filterGroupListName)
    const a1 = val; 
    let ObjVal = this.state.filterGroupListName;
    const checking = ObjVal.find((item) => (item.id === a1 ? item.name : null));
    return checking?.name;
  };

  toggleDropdown = (value) => {
    let errors = {};
    const isOpenState = {};
    isOpenState[value] = !this.state[value];
    if (
      this.state.currentOpenDropdown &&
      this.state.currentOpenDropdown !== value
    ) {
      isOpenState[this.state.currentOpenDropdown] = false;
    }

    this.setState({
      ...isOpenState,
      currentOpenDropdown: value,
    });

    errors["nodatafound"] = "";

    this.setState({
      searchValue: "",
      filterVoucherList: [],
      filterGroupList: [],
      filterPopupGroupList: [],
      errors: errors,
      activeApplyBtn: false,
    });
  };

  componentDidMount() {
    // this.setState({ qrCodeStatus: true }, () => {
    //   this.getVouchersList(1, this.state.pageSize);
    // })

    this.getVouchersList(1, this.state.pageSize);
  }

  async handleGroupSearchChange(field,e) {
    const value = e.target.value;
    this.setState({ searchGroupValue: value })
    let errors = {};
    const lowercaseValue = value.toLowerCase();
    if (value !== "" && value.length >= 3) {
      let filterData = await this.getGroupList(value, "");
      let filterGroupList = filterData ? filterData.map(data => data.id) : [];
      let filterGroupListName = filterData ? filterData.map(data => data) : [];
      if (filterGroupList.length === 0) {
        errors["nodatafound"] = this.props.t('No data found..!');
        this.setState({ errors: errors, filterGroupList: [] })
      } else {
        errors["nodatafound"] = "";
        this.setState({ filterGroupList: filterGroupList,
          filterGroupListName: [...this.state.filterGroupListName, ...filterGroupListName],
          errors: errors })
      }
    } else {
      this.setState({ errors: errors, filterGroupList: []})
    }

  }

  async getGroupList(grid, text) {
    var url = "";
    url = baseUrl.URLPath + servicesUrl.getPartialsiteName + "?siteName=" + grid;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
        if (response.message === "cp_not_exists") {
            response = [];
        } else {
            response = response
        }
         // this.setState({ filterGroupList: response })
        return response;
    }
  }

  async getFilterHistory() {
    var url = baseUrl.URLPath + servicesUrl.getQrcodeFilterHistory;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let errors = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
        if (response) {
            let filterVoucherList = response.vouchersIds;
            let filterData = response.siteData;
            let filterGroupList = filterData ? filterData.map(data => data.id) : [];
            let filterGroupListName = filterData ? filterData.map(data => data) : [];
           
            this.setState({
              filterVoucherList: filterVoucherList,
              filterGroupList:filterGroupList,
              filterGroupListName:[...this.state.filterGroupListName, ...filterGroupListName]
            })
        } else if (response.status === 404) {

        }
    }
}

  render() {

    return (
      <>
        <GuestVouchersTemplate
          selectedOption={this.state.selectedOption}
          errors={this.state.errors}
          isOpen={this.state.isOpen}
          selectedOptions={this.state.selectedOptions}
          selectedPopupOptions={this.state.selectedPopupOptions}
          selectedPopupValues={this.state.selectedPopupValues}
          selectedValues={this.state.selectedValues}
          filterVoucherList={this.state.filterVoucherList}
          filterGroupList={this.state.filterGroupList}
          filterPopupGroupList={this.state.filterPopupGroupList}
          isOpen1={this.state.isOpen1}
          isOpen2={this.state.isOpen2}
          isOpen3={this.state.isOpen3}
          isOpenState={this.state.isOpenState}
          currentOpenDropdown={this.state.currentOpenDropdown}
          searchedValues={this.state.searchedValues}
          isDataAvailable={this.state.isDataAvailable}
          voucherList={this.state.voucherList}
          noOfRecords={this.state.noOfRecords}
          data={this.state.data}
          pageSize={this.state.pageSize}
          activePage={this.state.activePage}
          pageNo={this.state.pageNo}
          curPageFirstCreTime={this.state.curPageFirstCreTime}
          curPageLastCreTime={this.state.curPageLastCreTime}
          prevPageFirstCreTime={this.state.prevPageFirstCreTime}
          listOfFirstCreatedtimes={this.state.listOfFirstCreatedtimes}
          fromDate={this.state.fromDate}
          fromDateApi={this.state.fromDateApi}
          toDate={this.state.toDate}
          toDateApi={this.state.toDateApi}
          activeTab={this.state.activeTab}
          showLoder={this.state.showLoder}
          activeApplyBtn={this.state.activeApplyBtn}
          activePopupApplyBtn={this.state.activePopupApplyBtn}
          isCreateOrEditGVPopupOpen={this.state.isCreateOrEditGVPopupOpen}
          isEnergyLimitActivated={this.state.isEnergyLimitActivated}
          energyLimitValue={this.state.energyLimitValue}
          ExpiryInDaysvalue={this.state.ExpiryInDaysvalue}
          isPeriodOfDaysActivated={this.state.isPeriodOfDaysActivated}
          isVoucherAssignToGuestActivated={this.state.isVoucherAssignToGuestActivated}
          noOfDuplicateVouchers={this.state.noOfDuplicateVouchers}
          userAction={this.state.userAction}
          isDeactivatePopupOpen={this.state.isDeactivatePopupOpen}
          isSuccessDeactivatePopupOpen={this.state.isSuccessDeactivatePopupOpen}
          isActivateVoucherPopupOpen={this.state.isActivateVoucherPopupOpen}
          isSuccessActivatePopupOpen={this.state.isSuccessActivatePopupOpen}
          isChangesUpdatedPopupOpen={this.state.isChangesUpdatedPopupOpen}
          isVoucherCreatedSuccessPopupOpen={this.state.isVoucherCreatedSuccessPopupOpen}
          moremodalGroup={this.state.moremodalGroup}
          searchValue={this.state.searchValue}
          searchPopupValue={this.state.searchPopupValue}
          status={this.state.qrCodeStatus}
          isLoading={this.state.isLoading}
          editVoucherID = {this.state.editVoucherID}
          alertModel = {this.state.alertModel}
          guestName = {this.state.guestName}
          id = {this.state.id}
          siteName = {this.state.siteName}

          handlePageChange = {this.handlePageChange}
          handleActiveTab = {this.handleActiveTab}
          handleCreateNewGVBtn = {this.handleCreateNewGVBtn}
          closeGVPopUp = {this.closeGVPopUp}
          handleCreateNewGV = {this.handleCreateNewGV}
          handleEditGV = {this.handleEditGV}
          handleDeactivateVoucher = {this.handleDeactivateVoucher}
          closeDeactivatePopup = {this.closeDeactivatePopup}
          SuccessDeativatePopup = {this.SuccessDeativatePopup}
          closeSuccessDeativatePopup = {this.closeSuccessDeativatePopup}
          handleActivateVoucherPopup = {this.handleActivateVoucherPopup}
          closeActivateVoucherPopup = {this.closeActivateVoucherPopup}
          SuccessAtivateVoucherPopup = {this.SuccessAtivateVoucherPopup}
          closeSuccessActivateVoucherPopup = {this.closeSuccessActivateVoucherPopup}
          closeChangesUpdatedPopup = {this.closeChangesUpdatedPopup}
          handleOnChangeEnergyLimit = {this.handleOnChangeEnergyLimit}
          handleOnChangePeriodOfDays = {this.handleOnChangePeriodOfDays}
          handleEditingVoucher = {this.handleEditingVoucher}
          handleOnChangeVoucherGuest = {this.handleOnChangeVoucherGuest}
          closeVoucherCreatedPopup = {this.closeVoucherCreatedPopup}
          downloadGuestVoucher = {this.downloadGuestVoucher}
          getVouchersList = {this.getVouchersList}
          handleRefreshButton = {this.handleRefreshButton}
          toggleDropdown = {this.toggleDropdown}
          getFilterHistory = {this.getFilterHistory}
          handleSearchChange = {this.handleSearchChange}
          handlePopupSearchChange = {this.handlePopupSearchChange}
          removeSelectedValue = {this.removeSelectedValue}
          showEntriesChange = {this.showEntriesChange}
          onChangeDuplicateVouchers = {this.onChangeDuplicateVouchers}
          createdDatesArray = {this.createdDatesArray}
          removeSelectedValueForPopup = {this.removeSelectedValueForPopup}
          emptySearchValues = {this.emptySearchValues}
          onChangeLimitEnergyusageValue = {this.onChangeLimitEnergyusageValue}
          handleEnergyLimit = {this.handleEnergyLimit}
          handlePeriodOfUse = {this.handlePeriodOfUse}
          onChangeLimitPeriodOfUseValue = {this.onChangeLimitPeriodOfUseValue}
          handleCheckboxChange = {this.handleCheckboxChange}
          QRCodeMangData = {this.state.QRCodeMangData}
          searchGroupValue = {this.state.searchGroupValue}
          handleGroupSearchChange = {this.handleGroupSearchChange}
          handleSubmit = {this.handleSubmit}
          filterGroupListName = {this.state.filterGroupListName}
          checkIdGetName = {this.checkIdGetName}
          removeAllSelected = {this.removeAllSelected}
          exportQRCodeDetails = {this.exportQRCodeDetails}
          createQRCode = {this.createQRCode}
          handlePopupCheckboxChange = {this.handlePopupCheckboxChange}
          handlePopupSubmit = {this.handlePopupSubmit}
          handleOnChangeGuestName = {this.handleOnChangeGuestName}
          warningPopup = {this.warningPopup}
           warningModel={this.state.warningModel}
           warningMsg={this.state.warningMsg}
        />
      </>
    )
  }
}

export default withTranslation()(GuestVouchers);