import React from 'react';
import { MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBInput } from 'mdbreact';
import Pagination from "react-js-pagination";
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import $, { data } from "jquery";
import moment from 'moment';
import { dateformatinUTC } from './_methods';
import Tansactionstable from './tansactionsTable';
import { withTranslation } from 'react-i18next';
import './cpDashboard.css';
import CustomModal from '../common/CustomModal';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CustomFilterDropdown from '../common/CustomFilterDropdown';
import Select, { components } from 'react-select';
import SuccessPopup from '../common/SuccessPopup';


//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders } from '../common/HttpRequestClass'
import { hasPermission } from './auth';


class transactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            selectedOptions: {
                chargingStation: [],
                RfidTag: [],
                Users: []
            },
            selectedReportOptions: {
                chargingStation: [],
                RfidTag: [],
                Users: []
            },
            selectedValues: {
                chargingStation: [],
                RfidTag: [],
                Users: []
            },
            selectedReportValues: {
                chargingStation: [],
                RfidTag: [],
                Users: []
            },
            searchedValues: [],
            modal: false,
            moremodal: false,
            searchQuery: '',
            isactiveEnergy: false,
            noOfRecords: '',
            data: new Map(),
            pageSize: 10,
            activePage: 1,
            pageNo: 1,
            calendertoDisplay: "none",
            calenderfromDisplay: "none",
            disabled: true,
            rfidTag: "",
            sessionId: "",
            statusId: "",
            transactionId: "",
            modalsession: false,
            startDate: new Date(),
            page_title: "Charging Session",
            scheduletoDate: '',
            startDate2: '',
            start_text: "Start",
            disabledRfid: false,
            startDate3: '',
            schedulefromDate: '',
            customerNumber: "",
            refNumber: "",
            chargePointId: '',
            calenderFrom: "none",
            calenderTo: "none",
            fields: {
                saveSelectedValue: "",
                dropDownIds: "",
                chargePointId: "",
            },
            dataArray: [],
            remoteStart: false,
            selectedRfid: "",
            rfidTagsList: [],
            note: "",
            showLoder: false,
            energyLevel: "",
            selectedcpId: "",
            selectedTransactionId: "",
            selectedStatus: "",
            selectedChargedEnergy: "",
            successTransaction: false,
            getOperationList: [],
            getDefaultFields: [
                this.props.t('transactionId'),
                this.props.t('chargePointId'),
                this.props.t('idTag'),
                this.props.t('startTime'),
                this.props.t('chargingDuration'),
                this.props.t('chargingSessionDuration'),
                this.props.t('chargedEnergy'),
                this.props.t('maximumChargingPower'),
                this.props.t('chargingSessionState'),
            ],
            getFieldsList: [],
            mileage: '',
            inventoryNumber: '',
            mileageNdInventory: false,
            transId: '',
            modalMileageNdInventory: false,
            exportBtn: false,
            selectedItems: [],
            availableWallBoxes: [],
            siteWallboxes: [],
            deletedWallboxes: [],
            errors: {},
            wallbox: '',
            successTbContent: false,
            tableCustomization: false,
            tableCustMsg: "Saved",
            exportError: false,
            rfidAcpid: "",
            curPageFirstCreTime: "",
            curPageLastCreTime: "",
            prevPageFirstCreTime: "",
            listOfFirstCreatedtimes: [],
            fromDate: '',
            fromDateApi: '',
            toDate: '',
            toDateApi: '',
            ASetChargingLimit: false,
            user: {
                permissions: []
            },
            settingPopup: false,
            typeOfExportCSV: false,
            typeOfExportXLS: false,
            typeOfExport: "0",
            reportModal: false,
            createReportModal: false,
            isAlternativeEmailActive: false,
            deleteReport: false,
            reportEditModal: false,
            reportFields: {
                reportName: "",
                alternativeEmail: ""
            },
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            isOpen6: false,
            isOpenState: false,
            exportFormat: "",
            reportCycle: "",
            groupList: [],
            filterChargerList: [],
            filterrfidTagsList: [],
            filterUserList: [],
            filterUserListName: [],
            searchValue: "",
            searchRfidValue: "",
            searchUserValue: "",
            filterReportChargerList: [],
            filterReportUserList: [],
            filterReportRfidTagsList: [],
            filterReportUserListName: [],
            reportSearchValue: "",
            reportSearchUserValue: "",
            reportSearchRfidValue: "",
            reportList: [],
            reportToggle: false,
            reportListIndex: "",
            start: moment().subtract(1, 'days'),
            end: moment(),
            fileType: "",
            delReportId: "",
            selectedOption: null,
            reportType: null,
            settingsMenu: false,
            reportId: "",
            currentOpenDropdown: null,
            isCreateReportSuccess: false,
            reportModal1: false,
            activeApplyBtn: false,
            activeReportApplyBtn: false,
            selectedFilter: '',
            cponlinestatusAlert:false,
            cponlinestatus:""
        }
        this.removeSelectedValue = this.removeSelectedValue.bind(this);
        this.toggle = this.toggle.bind(this);
        this.moreToggle = this.moreToggle.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.getTransactions = this.getTransactions.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
        this.dropDownChange = this.dropDownChange.bind(this);
        this.exportTransactionCSV = this.exportTransactionCSV.bind(this);
        this.showExportPopup = this.showExportPopup.bind(this);
        this.closeExportPopup = this.closeExportPopup.bind(this);
        this.toggleReportModal = this.toggleReportModal.bind(this);
        this.openCreateReport = this.openCreateReport.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.onClickRefreshbtn = this.onClickRefreshbtn.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.keyPress3 = this.keyPress3.bind(this);
        this.keyPress4 = this.keyPress4.bind(this);
        this.toggleRemoteStart = this.toggleRemoteStart.bind(this);
        this.beginRemoteStart = this.beginRemoteStart.bind(this);
        this.idTagChange = this.idTagChange.bind(this);
        this.getRfidTags = this.getRfidTags.bind(this);
        this.successTransaction = this.successTransaction.bind(this);
        this.getCustomization = this.getCustomization.bind(this);
        this.updateEnergyLevel = this.updateEnergyLevel.bind(this);
        this.saveMileageNdInventory = this.saveMileageNdInventory.bind(this);
        this.updateInventory = this.updateInventory.bind(this);
        this.updateMileage = this.updateMileage.bind(this);
        this.toggleMileageNdInventory = this.toggleMileageNdInventory.bind(this);
        this.togglesettingPopup = this.togglesettingPopup.bind(this);
        this.successTableContent = this.successTableContent.bind(this);
        this.reportCreateSuccess = this.reportCreateSuccess.bind(this);
        this.closeReportSuccessPopup = this.closeReportSuccessPopup.bind(this);
        this.toggleExportError = this.toggleExportError.bind(this);
        this.toggleExport = this.toggleExport.bind(this);
        this.createdDatesArray = this.createdDatesArray.bind(this);
        this.saveTableField = this.saveTableField.bind(this)
        this.updateCheckboxSelection = this.updateCheckboxSelection.bind(this);
        this.isItemSelected = this.isItemSelected.bind(this);
        this.displayCarIcon = this.displayCarIcon.bind(this);
        this.clearCPID = this.clearCPID.bind(this);
        this.toggleASetChargingLimit = this.toggleASetChargingLimit.bind(this);
        this.clearRfidSessionStatus = this.clearRfidSessionStatus.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchUserChange = this.handleSearchUserChange.bind(this)
        this.handleSearchRfidChange = this.handleSearchRfidChange.bind(this)
        this.handleExportTypeChange = this.handleExportTypeChange.bind(this)
        this.getReportList = this.getReportList.bind(this)
        this.handleCallback = this.handleCallback.bind(this)
        this.createReport = this.createReport.bind(this)
        this.updateReport = this.updateReport.bind(this)
        this.reportToggle1 = this.reportToggle1.bind(this)
        this.handleDeleteReport = this.handleDeleteReport.bind(this)
        this.checkIdGetName = this.checkIdGetName.bind(this)
        // this.toggleDropdown =  this.toggleDropdown.bind(this)
        this.getFilterHistory = this.getFilterHistory.bind(this)
        this.closeCponlinestatus = this.closeCponlinestatus.bind(this);
        this.handleSubmitCalender = this.handleSubmitCalender.bind(this);
       
    }
    handleExportTypeChange(type) {
        let errors = {}
        errors["exportFormatErr"] = "";
        this.setState({
            fileType: type,
            errors: errors,
        })
    }
    removeAllSelected = () => {
        const updatedObject = { ...this.state.selectedValues };
        updatedObject.chargingStation = [];
        updatedObject.RfidTag = [];
        updatedObject.Users = [];

        const newObject = { ...this.state.selectedOptions };
        newObject.chargingStation = [];
        newObject.RfidTag = [];
        newObject.Users = [];
        setTimeout(() => {
            this.setState({
                selectedValues: updatedObject,
                selectedOptions: newObject,
                showLoder: !this.state.showLoder,
            }, () => {
                this.getTransactions(this.state.pageSize, 1)
            })
        }, 1000)
        this.setState({
            showLoder: !this.state.showLoder
        })
    }
    // toggleDropdown = (value) => {
    //     let errors = {}
    //     const isOpenState = {};
    //     isOpenState[value] = !this.state[value];
    //     this.setState(isOpenState);
    //     errors["nodatafound"] = "";
    //     errors["nodatarfidfound"] = "";
    //     errors["nodatauserfound"] = "";
    //     this.setState({
    //         searchValue: "",
    //         searchRfidValue: "",
    //         searchUserValue: "",
    //         errors: errors
    //     })
    // };

    // toggleDropdown = (value) => {
   
    //     let errors = {};
    //     const isOpenState = {};
    //     isOpenState[value] = !this.state[value];
    //     if (this.state.currentOpenDropdown && this.state.currentOpenDropdown !== value) {
    //         isOpenState[this.state.currentOpenDropdown] = false;
    //     }

    //     this.setState({
    //         ...isOpenState,
    //         currentOpenDropdown: value,
    //     });

    //     errors["nodatafound"] = "";
    //     errors["nodatarfidfound"] = "";
    //     errors["nodatauserfound"] = "";

    //     this.setState({
    //         searchValue: "",
    //         searchRfidValue: "",
    //         searchUserValue: "",            
    //         filterChargerList: [],
    //         filterUserList: [], 
    //         filterrfidTagsList: [],
    //         filterReportChargerList: [],
    //         filterReportRfidTagsList: [],
    //         filterReportUserList: [],
    //         errors: errors,
    //         activeApplyBtn: false,
    //         activeReportApplyBtn: false,
    //     });
    // };

    handleFilterChange = (filter) => {
        this.setState({ selectedFilter: filter });
        // Perform filtering based on the selected filter
    };

    handleCheckboxChange = (category, val) => {
        this.setState((prevState) => {
            const updatedOptions = { ...prevState.selectedOptions };
            const updatedCategoryOptions = updatedOptions[category];
    
            if (updatedCategoryOptions.includes(val)) {
                updatedOptions[category] = updatedCategoryOptions.filter((item) => item !== val);
            } else {
                updatedOptions[category] = [...updatedCategoryOptions, val];
            }
    
            this.setState({
                selectedOptions: updatedOptions,
                activeApplyBtn: updatedOptions[category].length > 0,
            });
        });
    };
    
    handleCheckboxChangeInReport = (category, val) => {
        this.setState((prevState) => {
            const updatedOptions = { ...prevState.selectedReportOptions };
            const updatedCategoryOptions = updatedOptions[category];
    
            if (updatedCategoryOptions.includes(val)) {
                updatedOptions[category] = updatedCategoryOptions.filter((item) => item !== val);
            } else {
                updatedOptions[category] = [...updatedCategoryOptions, val];
            }
    
            this.setState({
                selectedReportOptions: updatedOptions,
                activeReportApplyBtn: updatedOptions[category].length > 0,
            });
        });
    };

    // handleCheckboxChange = (val) => {
    //   const { selectedOptions } = this.state;
    //   if (selectedOptions.includes(val)) {
    //     this.setState({
    //       selectedOptions: selectedOptions.filter((option) => option !== val),
    //     });
    //   } else {
    //     this.setState({ selectedOptions: [...selectedOptions, val] });
    //   }
    // };

    async handleSearchChange(field, event) {
        const value = event.target.value;
        this.setState({ searchValue: value })
        let errors = {};
        const lowercaseValue = value.toLowerCase();
        if (value !== "" && value.length >= 3) {
            let filterData = await this.getChargeList(value,"");
            let filterChargerList = filterData ? filterData.map(data => data) : [];
            if (filterChargerList.length === 0) {
                errors["nodatafound"] = this.props.t('No data found..!');
                this.setState({ errors: errors, filterChargerList: [] })
            } else {
                errors["nodatafound"] = "";
                this.setState({ filterChargerList: filterChargerList, errors: errors })
            }
        } else {
            this.setState({ errors: errors, filterChargerList: [] })
        }
    
    };

    async handleSearchUserChange(field, event) {
            const value = event.target.value;
            this.setState({ searchUserValue: value })
            let errors = {};
            const lowercaseValue = value.toLowerCase();
            // this.getUserList(value)
            if (value !== "" && value.length >= 3) {
                let filterData = await this.getUserList(value,"");
                
                let filterUserList = filterData ? filterData.map(data => data.id) : [];
                let filterUserListName = filterData ? filterData.map(data => data) : [];
                if (filterUserList.length === 0) {
                    errors["nodatauserfound"] = this.props.t('No data found..!');
                    this.setState({ errors: errors, filterUserList: []})
                } else {
                    errors["nodatauserfound"] = "";
                    this.setState({ 
                        filterUserList: filterUserList, 
                        filterUserListName: [...this.state.filterUserListName, ...filterUserListName],
                        errors: errors })
                }
            } else {
                this.setState({ errors: errors, filterUserList: [] })
            }
    }

    async handleSearchRfidChange(field, event) {
        const value = event.target.value;
        this.setState({ searchRfidValue: value })
        let errors = {};
        // this.getRfidTagsList(value);
        if (value !== "" && value.length >= 3) {
            let filterData = await this.getRfidTagsList(value,"");
            let filterrfidTagsList = filterData ? filterData.map(data => data) : [];
            if (filterrfidTagsList.length === 0) {
                errors["nodatarfidfound"] = this.props.t('No data found..!');
                this.setState({ errors: errors, filterrfidTagsList: [] })
            } else {
                errors["nodatarfidfound"] = "";
                this.setState({ filterrfidTagsList: filterrfidTagsList, errors: errors })
            }
        } else {
            this.setState({ errors: errors, filterrfidTagsList: [] })
        }
    }

    // Search change for report dropdown 
    async handleSearchChangeReport(field, event) {
        const value = event.target.value;
        this.setState({ reportSearchValue: value })
        let errors = {};

        if (value !== "" && value.length >= 3) {
            let filterData = await this.getChargeList(value, "Report");
            let filterReportChargerList = filterData ? filterData.map(data => data) : [];
            if (filterReportChargerList.length === 0) {
                errors["nodatafound"] = this.props.t('No data found..!');
                this.setState({ errors: errors, filterReportChargerList: [] })
            } else {
                errors["nodatafound"] = "";
                this.setState({ filterReportChargerList: filterReportChargerList, errors: errors })
            }
        } else {
            this.setState({ errors: errors, filterReportChargerList: [] })
        }
    };

  async handleSearchUserChangeReport(field, event) {
        const value = event.target.value;
        this.setState({ reportSearchUserValue: value })
        let errors = {};
        if (value !== "" && value.length >= 3) {
            let filterData = await this.getUserList(value, "Report");
            let filterReportUserList = filterData ? filterData.map(data => data.id) : [];
            let filterReportUserListName = filterData ? filterData.map(data => data) : [];
            if (filterReportUserList.length === 0) {
                errors["nodatauserfound"] = this.props.t('No data found..!');
                this.setState({ errors: errors, filterReportUserList: [] })
            } else {
                errors["nodatauserfound"] = "";
                this.setState({ 
                    filterReportUserList: filterReportUserList, 
                    filterReportUserListName: [...this.state.filterReportUserListName, ...filterReportUserListName],
                    errors: errors })
            }
        } else {
            this.setState({ errors: errors, filterReportUserList: [] })
        }
    }
    async handleSearchRfidChangeReport(field, event) {
        const value = event.target.value;
        this.setState({ reportSearchRfidValue: value })
        let errors = {};
        // this.getRfidTagsList(value);
        if (value !== "" && value.length >= 3) {
            let filterData = await this.getRfidTagsList(value, "Report");
            let filterReportRfidTagsList = filterData ? filterData.map(data => data) : [];
            if (filterReportRfidTagsList.length === 0) {
                errors["nodatarfidfound"] = this.props.t('No data found..!');
                this.setState({ errors: errors, filterReportRfidTagsList: [] })
            } else {
                errors["nodatarfidfound"] = "";
                this.setState({ filterReportRfidTagsList: filterReportRfidTagsList, errors: errors })
            }
        } else {
            this.setState({ errors: errors, filterrfidTagsList: [] })
        }
    }

    // handleSubmit for Custom Filter Dropdown
    handleSubmit = (value) => {
        const isOpenState = {};
        isOpenState[value] = !this.state[value];

        const categoryMapping = {
            isOpen1: "chargingStation",
            isOpen2: "RfidTag",
            isOpen3: "Users",
        };

        const updatedSelectedValues = { ...this.state.selectedOptions };

        if (categoryMapping[value]) {
            updatedSelectedValues[categoryMapping[value]] = this.state.selectedOptions[categoryMapping[value]];
        }

        this.setState({
            ...isOpenState,
            selectedValues: updatedSelectedValues,
            searchValue: "",
            searchRfidValue: "",
            searchUserValue: "",
            filterChargerList: [],
            filterUserList: [], 
            filterrfidTagsList: [],
            activeApplyBtn: false,
        }, () => {
            this.getTransactions(this.state.pageSize, 1)
        })
    };

    // handleSubmit for Custom Filter Dropdown on Reports Popup
    handleSubmitReportDropdown = (value) => {

        const isOpenState = {};
        isOpenState[value] = !this.state[value];

        const categoryMapping = {
            isOpen4: "chargingStation",
            isOpen5: "RfidTag",
            isOpen6: "Users",
        };

        const updatedSelectedValues = { ...this.state.selectedReportOptions };

        if (categoryMapping[value]) {
            updatedSelectedValues[categoryMapping[value]] = this.state.selectedReportOptions[categoryMapping[value]];
        }
        this.setState({
            ...isOpenState,
            selectedReportValues: updatedSelectedValues,
            reportSearchValue: "",
            reportSearchUserValue: "",
            reportSearchRfidValue: "",
            filterReportChargerList: [],
            filterReportRfidTagsList: [],
            filterReportUserList: [],
            activeReportApplyBtn: false
        }, () => {
            this.getTransactions(this.state.pageSize, 1)
        });
    };

    emptySearchValues = () => {
        let errors = {};
        errors["nodatafound"] = "";
        this.setState({
            searchValue: "",
            searchRfidValue: "",
            reportSearchValue: "",
            reportSearchRfidValue: "",
            reportSearchValue: "",
            reportSearchUserValue: "",
            reportSearchRfidValue: "",
            errors: errors,
        })
    }

    removeSelectedValue = (category, value) => {
        const { selectedValues } = this.state;
        const updatedSelectedValues = { ...selectedValues };
        updatedSelectedValues[category] = updatedSelectedValues[category].filter((val) => val !== value);
        this.setState({
            selectedOptions: updatedSelectedValues,
            selectedValues: updatedSelectedValues
        }, () => {
            this.getTransactions(this.state.pageSize, 1)
        });
    };

    // Report Popup Selected Items Remove function
    removeSelectedValueForReport = (category, value) => {
        const { selectedReportValues } = this.state;
        const updatedSelectedValues = { ...selectedReportValues };
        updatedSelectedValues[category] = updatedSelectedValues[category].filter((val) => val !== value);
        this.setState({
            selectedReportOptions: updatedSelectedValues,
            selectedReportValues: updatedSelectedValues
        }, () => {
            this.getTransactions(this.state.pageSize, 1)
        });
    };

    openSettingsMenu = () => {
        if (!this.state.settingsMenu) {
            document.addEventListener("click", this.handleOutsideClick, false);
          } else {
            document.removeEventListener("click", this.handleOutsideClick, false);
          }

        this.setState({ settingsMenu: !this.state.settingsMenu })
    }

    handleOutsideClick = e => {
        if (e.target !== null && e.target !== undefined) {
          if (!this.node?.contains(e.target)) this.openSettingsMenu();
        }
    }

    // function to open popup
    async toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }
    async moreToggle() {
        this.setState({
            moremodal: !this.state.moremodal
        });
    }

    createdDatesArray(dateTime) {
        let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
        this.setState({
            listOfFirstCreatedtimes: listOfFirstCreatedtimes
        });
    }

    toggleExportError() {
        this.setState({
            exportError: !this.state.exportError
        });
        // this.handleDeleteReport(this.state.delReportId);
    }

    toggleExport() {
        this.setState({
            exportBtn: this.state.exportBtn
        });
    }
    reportToggle1 () {
        this.setState({
            reportModal1: !this.state.reportModal1
        })
    }
    toggleReportModal = () => {
        this.setState(prevState => ({
            reportModal: !prevState.reportModal,
            reportEditModal: this.state.reportEditModal
        }));
        this.getReportList();
    }
    openReportModal = () => {
        this.setState(prevState => ({
            reportModal: !prevState.reportModal,
            reportToggle: false,
            settingsMenu: !this.state.settingsMenu
        }));
        this.getReportList();
    }
    closeReportModal = () => {
        this.setState(prevState => ({
            reportModal: !prevState.reportModal,
            reportToggle: false
        }))
    }

    onChangeAlternativeEmailActive = () => {
        this.setState({ isAlternativeEmailActive: !this.state.isAlternativeEmailActive })
    }

    openCreateReport = (id) => {
        let reportAction = "";
        if (id) {
            reportAction = "Update"
        } else {
            reportAction = "Create"
        }
        this.setState(({
            reportModal: !this.state.reportModal,
            createReportModal: !this.state.createReportModal,
            reportToggle: !this.state.reportToggle,
            reportAction: reportAction,
            reportId: id,
            reportExportType: "",
            reportFields: {
                reportName: "",
                alternativeEmail: ""
            },
            reportType: "",
            selectedReportOptions: {
                chargingStation: [],
                RfidTag: [],
                Users: []
            },
            selectedReportValues: {
                chargingStation: [],
                RfidTag: [],
                Users: []
            }
        }));
        if (id) {
            this.getReportDetails(id)
        }
    }
    reportChangeHandler = (field, e) => {
        let fields = this.state.reportFields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    async createReport() {
        let errors = {};
        const { selectedValues, reportFields, reportCycle, exportFormat, isAlternativeEmailActive } = this.state;

        if (selectedValues.length === 0) {
            errors["optionsErr"] = this.props.t('Please select charging station.');
        }

        if (isAlternativeEmailActive) {
            if (reportFields.alternativeEmail === "") {
                errors["emailErr"] = this.props.t('please_enter_email_address');
            } else if (!reportFields.alternativeEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
                errors["entervalidemail"] = this.props.t('Enter a valid email address.');
            }
        }
        if (reportCycle === "") {
            errors["reportCycleErr"] = this.props.t('please_select_report_cycle');
        }
        if (exportFormat === "") {
            errors["exportFormatErr"] = this.props.t('please_select_file_format');
        }
        if (reportFields.reportName === "") {
            errors["reportNameErr"] = this.props.t('please_enter_report_name');
        }
        this.setState({ errors: errors });

        if (selectedValues.length !== 0 && (isAlternativeEmailActive === false || (isAlternativeEmailActive === true &&  this.state.reportFields["alternativeEmail"] !=="" ) ) && reportCycle !== "" && exportFormat !== "" && reportFields.reportName !== "") {
            var url = baseUrl.URLPath + servicesUrl.saveTransactionsReport;        
            let timeZone =  moment.tz.guess();
            let type = ApiMethodTypes.POST;
            let headers = getSecurityHeaders();
            let payload = {
                "alternateEmailAddress": this.state.reportFields["alternativeEmail"],
                "chargePoints": this.state.selectedReportValues.chargingStation,
                "enableAlternateMailSending": this.state.isAlternativeEmailActive,
                "fileType": this.state.reportExportType,
                "reportCycle": this.state.reportType,
                "reportName": this.state.reportFields["reportName"],
                "rfIdTags": this.state.selectedReportValues.RfidTag,
                "timeZone": timeZone,
                "userIds": this.state.selectedReportValues.Users
            }
            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);
            if (response) {
                if (response !== undefined) {  
                    if (response.message === "transaction_report_create_success") {
                        setTimeout(() => {
                            this.setState({
                                createReportModal: !this.state.createReportModal,
                                isCreateReportSuccess: !this.state.isCreateReportSuccess,
                                reportMsg: response.message
                            }, () => {
                                document.getElementById("loader_image_div").style.display = "none";
                            })
                        }, 100); 
                    } else {
                        setTimeout(() => {
                            this.setState({
                                createReportModal: !this.state.createReportModal
                            }, () => {
                                document.getElementById("loader_image_div").style.display = "none";
                            })
                        }, 100);
                    }
                }
            }
        }
    };

    async updateReport() {
        let errors = {};
        const { selectedValues, reportFields, reportCycle, exportFormat, isAlternativeEmailActive } = this.state;

        if (selectedValues.length === 0) {
            errors["optionsErr"] = this.props.t('Please select charging station.');
        }

        if (isAlternativeEmailActive) {
            if (reportFields.alternativeEmail === "" || reportFields.alternativeEmail === null) {
                errors["emailErr"] = this.props.t('Please enter e-mail address.');
            } else if (!reportFields.alternativeEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
                errors["entervalidemail"] = this.props.t('Enter a valid email address.');
            }
        }
        if (reportCycle === "") {
            errors["reportCycleErr"] = this.props.t('Please select report cycle.');
        }
        if (exportFormat === "") {
            errors["exportFormatErr"] = this.props.t('Please select file format.');
        }
        if (reportFields.reportName === "") {
            errors["reportNameErr"] = this.props.t('Please enter report name.');
        }
        this.setState({ errors: errors });

        if (selectedValues.length !== 0 && (isAlternativeEmailActive === false || (isAlternativeEmailActive === true &&  this.state.reportFields["alternativeEmail"] !=="" && reportFields.alternativeEmail !== null ) ) && reportCycle !== "" && exportFormat !== "" && reportFields.reportName !== "") {
            var url = baseUrl.URLPath + servicesUrl.updateTransactionsReport;            
            let timeZone =  moment.tz.guess();
            let type = ApiMethodTypes.PUT;
            let headers = getSecurityHeaders();
            let payload = {
                "alternateEmailAddress": this.state.reportFields["alternativeEmail"],
                "chargePoints": this.state.selectedReportValues.chargingStation,
                "enableAlternateMailSending": this.state.isAlternativeEmailActive,
                "fileType": this.state.reportExportType,
                "reportCycle": this.state.reportType,
                "reportName": this.state.reportFields["reportName"],
                "rfIdTags": this.state.selectedReportValues.RfidTag,
                "timeZone": timeZone,
                "userIds": this.state.selectedReportValues.Users,
                "id": this.state.reportId
            }
            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);
            if (response) {
                if (response !== undefined) {
                    if (response.message === "transaction_report_update_success") {
                        setTimeout(() => {
                            this.setState({
                                createReportModal: !this.state.createReportModal,
                                isCreateReportSuccess: !this.state.isCreateReportSuccess,
                                reportMsg: response.message
                            }, () => {
                                document.getElementById("loader_image_div").style.display = "none";
                            })
                        }, 100);
                    } else {

                        setTimeout(() => {
                            this.setState({
                                createReportModal: !this.state.createReportModal
                            }, () => {
                                document.getElementById("loader_image_div").style.display = "none";
                            })
                        }, 100);

                    }
                }
            }
        }

    }

    closeCreateReport = () => {
        let errors = {};
        errors["optionsErr"] = "";
        errors["emailErr"] = "";
        errors["entervalidemail"] = "";
        errors["reportCycleErr"] = "";
        errors["exportFormatErr"] = "";
        errors["reportNameErr"] = "";
        this.setState({
            createReportModal: !this.state.createReportModal,
            errors: errors,
            reportToggle: false,
            isOpen4: false,
            isOpen5: false,
            reportSearchValue: "",
            reportSearchRfidValue: ""
        })
    }
    toggleDeleteReport = (id) => {
        this.setState({
            deleteReport: !this.state.deleteReport,
            reportModal: !this.state.reportModal,
            reportToggle: !this.state.reportToggle,
            delReportId: id
        })
    }
    closeDeleteReport = () => {
        this.setState({ deleteReport: !this.state.deleteReport })
    }
    isItemSelected(item) {
        const retVal = this.state.selectedItems.find(el => el === item) ? true : false;
        return retVal;
    }
    settingAction = () => {
        this.setState({
            settingPopup: !this.state.settingPopup,
            selectedItems: this.state.prevSelectedData,
            settingsMenu: !this.state.settingsMenu
        });
    }
    togglesettingPopup = () => {
        this.setState({
            settingPopup: !this.state.settingPopup
        });
    }
    successTableContent = () => {
        this.setState({
            successTbContent: !this.state.successTbContent
        });
    }
    reportCreateSuccess = () => {
        this.setState({
            isCreateReportSuccess: !this.state.isCreateReportSuccess
        })
    }
    closeReportSuccessPopup = () => {
        this.setState({
            isCreateReportSuccess: !this.state.isCreateReportSuccess
        })
    }
    toggleSaveMileageNdInventory = () => {
        this.setState({
            modalMileageNdInventory: !this.state.modalMileageNdInventory
        });
    }

    async saveMileageNdInventory(mileage, inventoryNumber) {
        if (mileage === "" || mileage === null) {
            $('.error_mileage').text(this.props.t('mileage_not be_empty'));
        }
        if (inventoryNumber === "" || inventoryNumber === null) {
            $('.error_inventoryNumber').text(this.props.t('inventory_no_not_beempty'));
        }
        if (mileage !== "" && mileage !== null && mileage > 0 && mileage <= 999999) {
            $('.error_mileage').text("");
        } else {
            $('.error_mileage').text(this.props.t('milege_min_max_validation'));
        }
        if (inventoryNumber !== "" && inventoryNumber !== null && inventoryNumber.length <= 20 && inventoryNumber.length > 0) {
            $('.error_inventoryNumber').text("");
        } else {
            $('.error_inventoryNumber').text(this.props.t('inventory_no_have_min1and_max20_char'));
        }
        if (mileage !== "" && mileage !== null && inventoryNumber !== "" && inventoryNumber !== null && mileage > 0 && mileage <= 999999 && inventoryNumber.length <= 20 && inventoryNumber.length > 0) {
            let url = baseUrl.URLPath + servicesUrl.updateByTranId + "?tranId=" + this.state.transId + "&mileage=" + this.state.mileage + "&vehicleInventoryNumber=" + this.state.inventoryNumber;
            let type = ApiMethodTypes.GET;
            let headers = getSecurityHeaders();
            let payload = {}
            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
            if (response) {
                this.toggleMileageNdInventory();
                this.toggleSaveMileageNdInventory();
            }
        }
    }

    toggleMileageNdInventory() {
        this.setState({
            mileageNdInventory: !this.state.mileageNdInventory,
        });
    }

    updateMileage(e) {
        if (e.target.validity.valid) {
            this.setState({
                mileage: e.target.value
            });
            $('.error_mileage').text("");
        }
    }

    updateInventory(e) {
        if (e.target.validity.valid) {
            this.setState({
                inventoryNumber: e.target.value
            });
            $('.error_inventoryNumber').text("");
        }
    }

    updateEnergyLevel(e) {
        if (e.target.validity.valid) {
            this.setState({
                energyLevel: e.target.value
            });
            $('.error_energyLevel').text("");
        }
    }

    async getCustomization() {
        let tenant = localStorage.getItem('tenant');
        let url = baseUrl.LoginPath + servicesUrl.transactionsGetCustomization + "?orgName=" + tenant;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response) {
            this.setState({ getOperationList: response })
        }
    }

    async getRfidTags(cpid) {
        var url = baseUrl.URLPath + servicesUrl.getOcpptagList + "?cpId=" + cpid;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response !== undefined) {
            if (cpid !== "" && cpid !== null && cpid !== undefined && response.data.length === 0) {
                $('.error_rfid').text(this.props.t('rfid_found_for_charge_point'));
            } else {
                $('.error_rfid').text("");
            }
            this.setState({ rfidTagsList: response.data })
        }

    }

    idTagChange() {
        var x = document.getElementById("tagList").value;
        this.setState({ selectedRfid: x }, () => { $('.error_rfid').text(""); });
    }

    async beginRemoteStart(cpId, transactionId) {
        let cpLists = [cpId];
        if (this.state.energyLevel) {
            var energy = this.state.energyLevel.toString();
        }
        var payload = {
            cplist: cpLists,
            payload: { idTag: this.state.selectedRfid, connectorId: 1 },
            type: "RemoteStartTransaction",
            note: this.state.note,
            customerNumber: this.state.customerNumber,
            referenceNumber: this.state.refNumber,
            energyLevel: energy,
            transactionId: transactionId
        }

        let selectedrfid = "";
        if (this.state.selectedRfid !== "" && this.state.selectedRfid !== null && this.state.selectedRfid !== undefined) {
            selectedrfid = this.state.selectedRfid.trim();
        }

        if (this.state.selectedRfid === "" || this.state.selectedRfid === null || this.state.selectedRfid === undefined || this.state.selectedRfid === "-- Select RFID Tag * --" || selectedrfid === "") {
            $('.error_rfid').text(this.props.t('rfid_not_be_empty'));
        } else if (this.state.isactiveEnergy && (this.state.energyLevel === "" || this.state.energyLevel === undefined || this.state.energyLevel === null)) {
            $('.error_energyLevel').text(this.props.t('energy_level_notbe_empty'));
        } else if (this.state.energyLevel !== "" && this.state.energyLevel !== null && this.state.energyLevel !== undefined && (parseFloat(this.state.energyLevel) <= 0 || parseFloat(this.state.energyLevel) > 9999999999 || ((parseFloat(this.state.energyLevel) <= this.state.selectedChargedEnergy) && this.state.selectedStatus === "activeCharging"))) {
            if (parseFloat(this.state.energyLevel) <= 0) {
                $('.error_energyLevel').text(this.props.t('energy_level_begreater_than0'));
            }
            if (parseFloat(this.state.energyLevel) > 9999999999) {
                $('.error_energyLevel').text(this.props.t('energy_level_not_be_greater_than9999999999'));
            }
            if ((parseFloat(this.state.energyLevel) <= this.state.selectedChargedEnergy) && this.state.selectedStatus === "activeCharging") {
                $('.error_energyLevel').text(this.props.t('energy_level_begreater_than_charged_energy'));
            }
        } else {
            var url = baseUrl.URLPath + servicesUrl.transctionsbeginRemoteStart;
            let type = ApiMethodTypes.POST;
            let headers = getSecurityHeaders();
            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

            if (response?.status === 403) {
                this.toggleASetChargingLimit();
            } else if(response?.status === 404 && response.message==="charger_is_not_online"){
                this.setState({
                    cponlinestatus:this.props.t('cp_not_online'),
                    remoteStart: !this.state.remoteStart 
                },()=>{
                    this.closeCponlinestatus();
                })
             }else if (response !== undefined) {
                if (response[2] === "Request is Processed Successfully") {
                    this.toggleRemoteStart();
                    this.successTransaction();
                }
            }
        }
    }

    closeCponlinestatus(){
        this.setState({ cponlinestatusAlert: !this.state.cponlinestatusAlert })
    }

    async updateRemoteStart(transactionId) {
        var role = localStorage.getItem("role");
        var url = ""
        if (role === "WEBASTO_SUPER_ADMIN_DEVOPS") {
            url = baseUrl.URLPath + servicesUrl.getUpdateRemotestartTrans + "?transactionId=" + transactionId + "&energyLimit=" + this.state.energyLevel;
        } else {
            url = baseUrl.URLPath + servicesUrl.getUpdateRemotestartTrans + "?transactionId=" + transactionId + "&referenceNumber=" + this.state.refNumber + "&customerNumber=" + this.state.customerNumber + "&energyLimit=" + this.state.energyLevel;
        }
        let type = ApiMethodTypes.POST;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response?.status === 403) {
            this.toggleASetChargingLimit();
        } else if (response !== undefined) {
            this.toggleRemoteStart();
            this.successTransaction();
        }
    }

    toggleRemoteStart(cpId, trans_id, energylevel, idtag, customerNumber, referenceNumber, status, chargedEnergy) {

        if (cpId !== "" && cpId !== undefined && cpId !== null) {
            this.getRfidTags(cpId);
            if (status === "activeCharging" && idtag !== "" && idtag !== null && idtag !== undefined) {
                this.setState({
                    disabledRfid: true,
                    start_text: "Update"
                })
            }
            if (status === "activePreparing") {
                this.setState({
                    disabledRfid: false,
                    start_text: "Start"
                })
            }
            let active = false;
            if (energylevel !== "" && energylevel !== null && energylevel !== undefined && energylevel > 0) {
                active = true;
            } else {
                active = false;
            }
            this.setState({ remoteStart: !this.state.remoteStart, selectedcpId: cpId, selectedTransactionId: trans_id, energyLevel: energylevel, selectedRfid: idtag, isactiveEnergy: active, selectedStatus: status, selectedChargedEnergy: chargedEnergy, refNumber: referenceNumber, customerNumber: customerNumber });
        } else {
            this.setState({ remoteStart: !this.state.remoteStart });
        }
    }

    successTransaction() {
        this.setState({
            successTransaction: !this.state.successTransaction,
            energyLevel: "",
            note: ""
        });
    }

    keyPress4(e) {
        var key = e.key;
        let fields = this.state.fields;

        if (e.keyCode === 13) {
            fields['dropDownIds'] = e.target.value;
            this.setState({ fields, activePage: 1 }, () => {
                this.getTransactions(this.state.pageSize, 1, "enter");
            });
        }

        if (key === "Backspace" || key === "Delete") {
            var x = e.target.value
            var delitem = x.slice(0, x.length - 1)
            if (delitem === "") {
                fields['dropDownIds'] = delitem;

                this.setState({
                    fields, activePage: 1
                }, () => {
                    this.getTransactions(this.state.pageSize, 1)
                })
            }
        }
    }

    showEntriesChange = (selectedOption) => {

        // var entries = document.getElementById("showEntries").value;
        var entries = selectedOption.value;

        let fromDateApi = '';
        if (this.state.fromDate !== "" && this.state.fromDate !== null && this.state.fromDate !== undefined) {
            fromDateApi = dateformatinUTC(moment(this.state.fromDate));
        }
        let toDateApi = '';
        if (this.state.toDate !== "" && this.state.toDate !== null && this.state.toDate !== undefined) {
            toDateApi = dateformatinUTC(moment(this.state.toDate));
        }

        this.setState({
            activePage: 1,
            pageSize: entries,
            fromDateApi: fromDateApi,
            toDateApi: toDateApi
        }, () => {
            this.getTransactions(entries, 1);
        });
    }

    handlePageChange(pageNumber) {
        if (pageNumber < this.state.activePage) {
            //prev
            this.setState({
                activePage: pageNumber,
                pageNo: pageNumber,
                // fromDateApi: this.state.curPageFirstCreTime,
                fromDateApi: this.state.fromDateApi,
                // toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
                toDateApi: this.state.toDateApi
            }, () => {
                this.getTransactions(this.state.pageSize, pageNumber, "", false);
            });
        } else {
            //next
            let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
            this.setState({
                activePage: pageNumber,
                pageNo: pageNumber,
                fromDateApi: dateformatinUTC(this.state.fromDate),
                // toDateApi: this.state.curPageLastCreTime,
                toDateApi: this.state.toDateApi,
                prevPageFirstCreTime: this.state.curPageFirstCreTime
            }, () => {
                if (pageNumber > _datesArrayLength + 1) {
                    this.createdDatesArray(this.state.curPageFirstCreTime);
                }
                this.getTransactions(this.state.pageSize, pageNumber, "", false);
            });
        }
    }

    closeCalender(type, event) {
        if (type === "from") {
            this.setState({
                calenderFrom: "none"
            })
        } else {
            this.setState({
                calenderTo: "none"
            })
        }
    }

    displayCalender(type, event) {
        var calenderFrom = "";
        var calenderTo = "";
        if (type === "from") {
            calenderFrom = "block";
            calenderTo = "none";
        } else {
            calenderTo = "block";
            calenderFrom = "none";
        }
        this.setState({
            calenderFrom: calenderFrom,
            calenderTo: calenderTo
        })
    }

    // method to call onChange Operation Name
    dropDownChange() {
        var id = document.getElementById("selectDropdown").value;
        let fields = this.state.fields;
        fields["saveSelectedValue"] = id;
        fields["dropDownIds"] = "";

        this.setState({
            fields,
            disabled: fields.saveSelectedValue !== "" ? false : true
        })
    }

    //to save selected table content 
    async saveTableField() {
        this.setState({ settingPopup: !this.state.settingPopup })
        let url = baseUrl.URLPath + servicesUrl.transctionsSaveTableField;
        let type = ApiMethodTypes.POST;
        let headers = getSecurityHeaders();
        let payload = { transactionColumns: this.state.selectedItems }
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response !== undefined) {
            this.successTableContent();
        }
    }

    //to get selected table content from api 
    async getTableField() {
        var url = baseUrl.URLPath + servicesUrl.transactionsGetTablecontents;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if (response) {
            if (response.status === 404) {
                this.setState({ tableCustomization: false })
            } else if (response !== undefined) {
                var prevSelectedData = [];
                for (var i = 0; i < response.length; i++) {
                    if (response[i].isSelected === true) {
                        this.state.selectedItems.push(response[i].key)
                        prevSelectedData.push(response[i].key)
                    }
                }

                const groupByMake = (arr = []) => {
                    let result = [];
                    result = arr.reduce((r, a) => {
                        r[a.type] = r[a.type] || [];
                        r[a.type].push(a);
                        return r;
                    }, Object.create(null));
                    return result;
                };
                console.log(groupByMake(response))
                this.setState({
                    // getFieldsList: response,
                    groupList: groupByMake(response),
                    prevSelectedData: prevSelectedData,
                    tableCustomization: true
                })
            }
        }
    }

    async getTableFieldTitle() {
      
        var url = baseUrl.URLPath + servicesUrl.transactionsGetTableTiltecontents;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  
        if (response) {
            if (response.status === 404) {
                this.setState({ tableCustomization: false })
            } else if (response !== undefined) {
                var prevSelectedData = [];
                for (var i = 0; i < response.length; i++) {
                    if (response[i].isSelected === true) {
                        this.state.selectedItems.push(response[i].key)
                        prevSelectedData.push(response[i].key)
                    }
  
                this.setState({
                    getFieldsList: response,
                    // groupList: groupByMake(response),
                    prevSelectedData: prevSelectedData,
                    // tableCustomization: true
                })
            }
        }
    }
}

    //change function for chargepoint status filter
    onChangeChargepoint(e) {
        let errors = {};
        if (this.state.fields.chargePointId === "") {
            errors['entercharge'] = this.props.t('enter_chargepoint_id')
            this.setState({
                errors
            });
        } else {
            errors['entercharge'] = '';

            this.setState({
                activePage: 1,
                errors
            }, () => {
                this.getTransactions(this.state.pageSize, this.state.activePage, 'click');
            });
        }
    }

    //change function for rfid, session and status filter
    onChangeDropdown() {
        let errors = {}
        var rfidStatus = document.getElementById("dropDownIds").value;

        if (this.state.fields.saveSelectedValue === "RFID Tag") {
            if (rfidStatus === "") {
                errors['enterrfidsessionstatus'] = this.props.t('please_enter_rfid_tag')
                this.setState({
                    errors
                });
            } else {
                errors['enterrfidsessionstatus'] = '';
                this.setState({ dropDownIds: rfidStatus }, () => {
                    if (rfidStatus) {
                        this.getTransactions(this.state.pageSize, this.state.activePage);
                    }
                });

            }
        } else if (this.state.fields.saveSelectedValue === "Session ID") {
            if (rfidStatus === "") {
                errors['enterrfidsessionstatus'] = this.props.t('please_enter_session_id')
                this.setState({
                    errors
                });
            } else {
                errors['enterrfidsessionstatus'] = '';
                this.setState({ dropDownIds: rfidStatus }, () => {
                    if (rfidStatus) {
                        this.getTransactions(this.state.pageSize, this.state.activePage);
                    }
                });

            }
        } else if (this.state.fields.saveSelectedValue === "Status") {
            if (rfidStatus === "") {
                errors['enterrfidsessionstatus'] = this.props.t('please_enter_status')
                this.setState({
                    errors
                });
            } else {
                errors['enterrfidsessionstatus'] = '';
                this.setState({ dropDownIds: rfidStatus }, () => {
                    if (rfidStatus) {
                        this.getTransactions(this.state.pageSize, this.state.activePage);
                    }
                });

            }
        } else if (this.state.fields.saveSelectedValue === "Transaction ID") {
            if (rfidStatus === "") {
                errors['enterrfidsessionstatus'] = this.props.t('enter_transaction_id')
                this.setState({
                    errors
                });
            } else {
                errors['enterrfidsessionstatus'] = '';
                this.setState({ dropDownIds: rfidStatus }, () => {
                    if (rfidStatus) {
                        this.getTransactions(this.state.pageSize, this.state.activePage);
                    }
                });
            }
        }
    }

    getStatusKey(value) {
        let key = "";
        if (value === this.props.t('active_charging')) {
            key = "activeCharging";
        } else if (value === this.props.t('finished')) {
            key = "Finished";
        } else if (value === this.props.t('activeNotCharging')) {
            key = "activeNotCharging";
        } else if (value === this.props.t('prepairing')) {
            key = "prepairing";
        } else if (value === this.props.t('active_preparing')) {
            key = "activePreparing";
        } else if (value === this.props.t('active_not_charging')) {
            key = "SuspendedEV";
        } else if (value === this.props.t('halted')) {
            key = "Halted";
        }
        return key;
    }

    changeHandler(field, e) {
        let fields = this.state.fields;
        let selected = fields.saveSelectedValue;

        if (selected === "Transaction ID") {
            if (/^[0-9\b]+$/.test(e.target.value) === true) {
                fields[field] = e.target.value;
                $("#notexists").text("");
                this.setState({ fields, errors: {} });
            }
        } else {
            fields[field] = e.target.value;
            $("#notexists").text("");
            this.setState({ fields, errors: {} });
        }
    }

    keyPress3(e) {
        var key = e.key;
        let errors = {};

        if (e.keyCode === 13) {
            if (this.state.fields.chargePointId === "") {
                errors['entercharge'] = this.props.t('select_charge_point')
                this.setState({
                    errors
                });
            } else {
                errors['entercharge'] = '';

                this.setState({
                    activePage: 1,
                    errors
                }, () => {
                    this.getTransactions(this.state.pageSize, 1, "enter");
                });
            }
        }

        if (key === "Backspace" || key === "Delete") {
            var x = e.target.value
            var delitem = x.slice(0, x.length - 1)
            if (delitem === "") {
                let state = this.state;
                state.fields.chargePointId = delitem;
                state.activePage = 1;

                this.setState({ state }, () => {
                    this.getTransactions(this.state.pageSize, 1)
                })
            }
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9., -]");
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    formateDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    formateDate1(date) {
        var format = date.toISOString();
        var xyz = moment(format).format("DD MMM YYYY")
        return xyz;
    }

    formatCalDate(date) {
        var format = date.toISOString().substr(0, 19) + "Z";
        return format;
    }

    formatLocalCalDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        var monthname = d.toLocaleString('default', { month: 'short' });
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [day, monthname, year].join(' ');
    }

    // get transactions based on filter
    async getTransactions(pageSize, pageNo, action, countValue) {
        document.getElementById("loader_image_div").style.display = "block";
        // let chargePointId = this.state.fields.chargePointId;
        // chargePointId = chargePointId?.trim();

        // if (chargePointId !== "" && this.state.errors["notexists"] !== "" && this.state.errors["notexists"] !== undefined) {
        //   chargePointId = "";
        // }
        // let errorMsg = "";
        // let Statuskey = "";
        // if (this.state.fields.saveSelectedValue === "RFID Tag") {
        //   this.state.rfidTag = this.state.fields.dropDownIds;
        //   this.state.sessionId = "";
        //   this.state.statusId = "";
        //   this.state.transactionId = "";
        //   errorMsg = this.props.t('rfid_tag_not_exist');
        // } else if (this.state.fields.saveSelectedValue === "Session ID") {
        //   this.state.sessionId = this.state.fields.dropDownIds;
        //   this.state.rfidTag = "";
        //   this.state.statusId = "";
        //   this.state.transactionId = "";
        //   errorMsg = this.props.t('session_tag_not_exist');
        // } else if (this.state.fields.saveSelectedValue === "Status") {
        //   Statuskey = this.getStatusKey(this.state.fields.dropDownIds)
        //   this.state.statusId = Statuskey ? Statuskey : this.state.fields.dropDownIds;
        //   this.state.sessionId = "";
        //   this.state.rfidTag = "";
        //   this.state.transactionId = "";
        //   errorMsg = this.props.t('status_tag_not_exist');
        // } else if (this.state.fields.saveSelectedValue === "Transaction ID") {
        //   this.state.sessionId = "";
        //   this.state.rfidTag = "";
        //   this.state.statusId = "";
        //   this.state.transactionId = this.state.fields.dropDownIds;
        //   errorMsg = this.props.t('transaction_id_not_exists');
        // }

        let errors = {};
        var url = baseUrl.URLPath + servicesUrl.getTransactions;
        let timeZone = moment().tz(moment.tz.guess()).format('z');

        let type = ApiMethodTypes.POST;
        let headers = getSecurityHeaders();
        let payload = {
            "chargePoints": this.state.selectedValues.chargingStation,
            "fileType": this.state.fileType,
            "fromTime": this.state.fromDateApi,
            "rfIdTags": this.state.selectedValues.RfidTag,
            "timeZone": timeZone,
            "toTime": this.state.toDateApi,
            "userIds": this.state.selectedValues.Users,
            "pageNo": pageNo,
            "pageSize": this.state.pageSize,
        }
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);


        if (response) {
            if (response !== undefined) {
                if (response.count === 0) {

                    setTimeout(() => {
                        document.getElementById("loader_image_div").style.display = "none";
                        this.setState({
                            errors,
                            dataArray: [],
                            noOfRecords: 0
                        });
                    }, 100);

                    // if (this.state.fields.saveSelectedValue && this.state.fields.dropDownIds) {
                    //   setTimeout(() => {
                    //     document.getElementById("loader_image_div").style.display = "none";
                    //     errors['enterrfidsessionstatus'] = errorMsg;
                    //     this.setState({
                    //       errors,
                    //       dataArray: [],
                    //       noOfRecords: 0
                    //     });
                    //   }, 100);

                    // } else {
                    //   if (action === "click" || action === "enter") {
                    //     setTimeout(() => {
                    //       document.getElementById("loader_image_div").style.display = "none";
                    //       errors['notexists'] = this.props.t('charge_point_id_not_exists.');
                    //       this.setState({
                    //         errors
                    //       });
                    //     }, 100);
                    //   }

                    //   setTimeout(() => {
                    //     document.getElementById("loader_image_div").style.display = "none";
                    //   }, 100);
                    // }
                } else {
                    var totalcount = "";
                    if (countValue === false) {
                        totalcount = this.state.noOfRecords
                    } else {
                        totalcount = response.count
                    }

                    setTimeout(() => {
                        this.setState({
                            prevPageFirstCreTime: this.state.curPageFirstCreTime,
                            dataArray: response.data,
                            noOfRecords: totalcount,
                            activePage: pageNo,
                            curPageFirstCreTime: moment(response.data[0]?.startTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                            curPageLastCreTime: moment(response.data[response.data.length - 1]?.startTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                        }, () => {
                            document.getElementById("loader_image_div").style.display = "none";
                        })
                    }, 100);
                    // if (action === "click" || action === "enter") {
                    //   setTimeout(() => {
                    //     document.getElementById("loader_image_div").style.display = "none";
                    //     errors['notexists'] = '';
                    //     this.setState({
                    //       errors
                    //     });
                    //   }, 100);
                    // }

                }
            }
        }
    }

    componentDidMount() {
        let fromDate = moment().subtract(1, 'd');
        let fromDateApi = dateformatinUTC(moment().subtract(1, 'd'));
        let toDate = new Date();
        let toDateApi = dateformatinUTC(moment(toDate));

        this.setState({
            fromDate: new Date(fromDate),
            fromDateApi: fromDateApi,
            toDate: toDate,
            toDateApi: toDateApi,
        }, () => {
            this.getTransactions(this.state.pageSize, this.state.activePage);
        });

        this.getRfidTags();
        this.getCustomization();
        this.getTableField();
        this.getTableFieldTitle();
    }

    //function for sorting table data on click
    ascendingOrder() {
        this.setState({ dataArray: this.state.dataArray.reverse() })
    }

    //function for sorting table data on click
    descendingOrder() {
        this.setState({ dataArray: this.state.dataArray.reverse() })
    }

    showExportPopup = () => {
        this.setState({
            exportBtn: !this.state.exportBtn,
            settingsMenu: !this.state.settingsMenu,
            fileType: ""
        });
    }

    closeExportPopup = () => {
        let errors = {}
        errors["exportFormatErr"] = "";
        this.setState({
            exportBtn: !this.state.exportBtn,
            errors: errors
        })
    }
    // showExportPopup = () => {
    //     this.setState(prevState => ({
    //         exportBtn: !prevState.exportBtn
    //     }));
    // }
    handleReportCycle = (type, event) => {

        this.setState({
            reportCycle: event.target.value,
            reportType: type
        });
    }

    handleExportFormat = (type, event) => {
        this.setState({
            exportFormat: event.target.value,
            reportExportType: type
        });
    }

    exportTransactionCSV = (type) => {
        let errors = {}
        if (type) {
            
            this.setState({
                showLoder: !this.state.showLoder
            });
            // let type = this.state.fileType
            let fromDate = '';
            if (this.state.fromDate !== '' && this.state.fromDate !== undefined) {
                fromDate = dateformatinUTC(this.state.fromDate)
            }
    
            let toDate = '';
            if (this.state.toDate !== '' && this.state.toDate !== undefined) {
                toDate = dateformatinUTC(this.state.toDate)
            }
    
            let timezone =  moment.tz.guess();
            let saveSelecetdStatus = this.state.fields.saveSelectedValue;
            var url = "";
    
            let chargePoints = [];
            let rfIdTags = [];
            let userIds = [];
            url = baseUrl.URLPath + servicesUrl.downloadTransactionsexport
            // if (type === "csv") {
            //   if (saveSelecetdStatus === "RFID Tag") {
            //     url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=csv&chargepointid=" + this.state.fields.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate + "&rfid=" + this.state.fields["dropDownIds"];
            //   } else if (saveSelecetdStatus === "Session ID") {
            //     url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=csv&chargepointid=" + this.state.fields.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate + "&sessionid=" + this.state.fields["dropDownIds"];
            //   } else if (saveSelecetdStatus === "Status") {
            //     url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=csv&chargepointid=" + this.state.fields.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate + "&status=" + this.state.fields["dropDownIds"];
            //   } else if (saveSelecetdStatus === "Transaction ID") {
            //     url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=csv&chargepointid=" + this.state.fields.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate + "&transactionId=" + this.state.fields["dropDownIds"];
            //   } else {
            //     url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=csv&chargepointid=" + this.state.fields.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate;
            //   }
            // } else {
            //   if (saveSelecetdStatus === "RFID Tag") {
            //     url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=xls&chargepointid=" + this.state.fields.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate + "&rfid=" + this.state.fields["dropDownIds"];
            //   } else if (saveSelecetdStatus === "Session ID") {
            //     url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=xls&chargepointid=" + this.state.fields.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate + "&sessionid=" + this.state.fields["dropDownIds"];
            //   } else if (saveSelecetdStatus === "Status") {
            //     url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=xls&chargepointid=" + this.state.fields.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate + "&status=" + this.state.fields["dropDownIds"];
            //   } else if (saveSelecetdStatus === "Transaction ID") {
            //     url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=xls&chargepointid=" + this.state.fields.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate + "&transactionId=" + this.state.fields["dropDownIds"];
            //   } else {
            //     url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=xls&chargepointid=" + this.state.fields.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate;
            //   }
            // }
    
            let data = {
                "chargePoints": this.state.selectedValues.chargingStation,
                "fileType": type,
                "fromTime": fromDate,
                "rfIdTags": this.state.selectedValues.RfidTag,
                "timeZone": timezone,
                "toTime": toDate,
                "userIds": this.state.selectedValues.Users
            }
    
            fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "content-type": "application/json",
                    "Authorization": localStorage.getItem("Authorization"),
                    "timeZone": timezone
                }
            })
                .then((resp) => {
                    if (resp.status === 401) {
                        window.location.reload();
                    }
                    if (resp.status === 200) {
                        return resp.blob();
                    }
                    if (resp.status === 500) {
                        this.setState({
                            showLoder: !this.state.showLoder
                        })
                    }
                })
                .then((response) => {
                    if (response !== undefined) {
                        let d = new Date();
                        let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                        let url = window.URL.createObjectURL(response);
                        let a = document.createElement('a');
                        a.href = url;
                        if (type === "CSV") {
                            a.download = 'Charging_session_' + dformat + '.csv';
                        } else {
                            a.download = 'Charging_session_' + dformat + '.xlsx';
                        }
                        a.click();
                        this.setState({
                            exportBtn: !this.state.exportBtn,
                            showLoder: !this.state.showLoder
                        })
                    }
                })
                .catch((error) => {
                    this.setState({
                        showLoder: !this.state.showLoder
                    }, () => {
                        this.toggleExportError();
                    })
                    console.log(error, "catch the loop")
                })
        } else {
            errors["exportFormatErr"] = this.props.t('please_select_file_format');
            this.setState({
                errors
            });
        }
    }

    onClickRefreshbtn() {
        this.getTransactions(this.state.pageSize, this.state.activePage, "", false);
    }

    updateCheckboxSelection(event, item) {
        const allItems = [...this.state.selectedItems];
        const deletedWallboxes = [...this.state.deletedWallboxes];
        const selectedItemIndex = allItems.findIndex(el => el === item);
        if (event.target.checked) {
            if (selectedItemIndex < 0) {
                allItems.push(item);
            }
        } else {
            const deletedWallboxIndex = this.state.siteWallboxes.findIndex(el => el === item);
            const isAlreadyDeleted = deletedWallboxes.findIndex(el => el === item) > -1;
            if (deletedWallboxIndex > -1 && !isAlreadyDeleted) {
                deletedWallboxes.push(item);
            }
            allItems.splice(selectedItemIndex, 1);
        }
        this.setState({ ...this.state, selectedItems: allItems, deletedWallboxes });
    }

    // new function 
    displayCarIcon(transId, mileage, inventoryNumber) {
        this.setState({ transId: transId, mileage: mileage, inventoryNumber: inventoryNumber });
        this.toggleMileageNdInventory();
    }

    // for from date calender 
    handleFromChange = date => {
        $('.future_err1').text("");
        let fromD = '';
        if (date !== undefined && date !== "" && date !== null) {
            fromD = dateformatinUTC(date)
        }
        this.setState({
            fromDate: date,
            fromDateApi: fromD,
            activePage: 1
        }, () => {
            this.getTransactions(this.state.pageSize, 1);
        });
    }

    // for to date calender 
    handleToChange = date => {
        let toD = '';
        if (date !== undefined && date !== "" && date !== null) {
            toD = dateformatinUTC(date)
        }

        this.setState({
            toDate: date,
            toDateApi: toD,
            activePage: 1
        }, () => {
            this.getTransactions(this.state.pageSize, 1)
        });
    }

    clearCPID() {
        let fields = this.state.fields;
        fields['chargePointId'] = '';
        let errors = {}

        if (this.state.errors["entercharge"] || this.state.errors["notexists"]) {
            errors['entercharge'] = '';
            errors['notexists'] = '';
            this.setState({
                fields: fields,
                errors
            });
        } else {
            errors['entercharge'] = '';
            errors['notexists'] = '';
            this.setState({
                activePage: 1,
                fields: fields,
                errors
            }, () => {
                this.getTransactions(this.state.pageSize, this.state.activePage);
            });
        }
    }

    clearRfidSessionStatus() {
        let errors = {}
        let fields = this.state.fields;
        fields['dropDownIds'] = '';

        errors['enterrfidsessionstatus'] = '';
        errors['rfidsessionstatusnotexists'] = '';
        this.setState({
            activePage: 1,
            fields: fields,
            errors
        }, () => {
            this.getTransactions(this.state.pageSize, this.state.activePage);
        });
    }


    //display warning popup for set charging limit 
    toggleASetChargingLimit() {
        this.setState({
            ASetChargingLimit: !this.state.ASetChargingLimit
        });
    }

    UNSAFE_componentWillMount() {
        var user = {
            permissions: []
        }
        user.permissions = localStorage.getItem("roleAccess");
        this.setState({
            user: user
        })
    }

    onKeyPress(event) {
        if ((!/^\d*\.?\d*$/.test(event.key) && event.key !== "Backspace" && event.key !== "Delete")) {
            event.preventDefault();
        }
    }

    // new functions 

    //get charger list
    async getChargeList(cpid,text) {  
        var url = "";  
        if(text==="Report"){    
            var url = baseUrl.URLPath + servicesUrl.getTraChargerList + "?cpid=" + cpid;  
        }else{
            var url = baseUrl.URLPath + servicesUrl.getTraChargerList + "?cpid=" + cpid + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi;  
        }      
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response !== undefined) {
            if (response.message === "cp_not_exists") {
                response = [];
            } else {
                response = response
            }
            // this.setState({ filterChargerList: response })
            return response;
        }
    }

    async getRfidTagsList(tagId,text) {
        var url = "";
        if(text==="Report"){
            var url = baseUrl.URLPath + servicesUrl.getTraTagList + "?tag=" + tagId;
        }else{
            var url = baseUrl.URLPath + servicesUrl.getTraTagList + "?tag=" + tagId + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi;
        }
       
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let errors = {};
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response !== undefined) {
            if (response.message === "ocpp_tag_does_not_exist") {
                response = [];
            } else {
                response = response
            }
            return response;
        }
    }

    async getUserList(userName) {        
        var url = baseUrl.URLPath + servicesUrl.getTraUserList + "?name=" + userName + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi;        
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let errors = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response !== undefined) {
            if (response.message === "user_not_exists") {
                response = [];
            } else {
                response = response
            }
            return response;
        }
    }

    async getReportList() {
        var url = baseUrl.URLPath + servicesUrl.getReport;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let errors = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response !== undefined) {
            if (response) {
                this.setState({
                    reportList: response
                })
            } else if (response.status === 404) {

            }
        }
    }

    async handleDeleteReport() {
        let id = this.state.delReportId;
        // event.preventDefault();
        // this.setState({
        //     modal1: !this.state.modal1
        // });
        var url = baseUrl.URLPath + servicesUrl.deleteReport + "?id=" + id;

        let type = ApiMethodTypes.DELETE;
        let headers = getSecurityHeaders();

        let response = await APICallUtility.cpmsAPIFetch(url, type, { "id": this.state.activeArray }, headers, this.props.t);

        if (response.status === 400) {
            //this.toggle3(this.props.t(response.message));
        } else if (response.message === "transaction_report_delete_success") {
            //this.toggle2();
            this.setState({
                deleteReport: !this.state.deleteReport,
                reportModal1: !this.state.reportModal1
            })
            // this.deleteReport();
        }
    }

    async getReportDetails(id) {
        var url = baseUrl.URLPath + servicesUrl.getReportDetails + "?id=" + id;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let errors = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response !== undefined) {
            if (response) {

                let reportFields = {}
                reportFields["alternativeEmail"] = response.alternateEmailAddress
                reportFields["reportName"] = response.reportName
                let selectedReportValues = {}
                selectedReportValues['RfidTag'] = response.rfIdTags
                selectedReportValues['Users'] = response.userIds
                selectedReportValues['chargingStation'] = response.chargePoints

                let selectedReportOptions = {}
                selectedReportOptions['RfidTag'] = response.rfIdTags
                selectedReportOptions['Users'] = response.userIds
                selectedReportOptions['chargingStation'] = response.chargePoints

                this.setState({
                    reportDetails: response,
                    isAlternativeEmailActive: response.enableAlternateMailSending,
                    reportExportType: response.fileType,
                    reportType: response.reportCycle,
                    reportCycle: response.reportCycle ? "On" : "",
                    reportFields: reportFields,
                    selectedReportValues: selectedReportValues,
                    exportFormat: response.fileType ? "On" : "",
                    selectedReportOptions: selectedReportOptions
                })
            } else if (response.status === 404) {

            }
        }
    }


    handleSubmitCalender = () => {
        this.setState({ 
            activePage: 1,
            start: this.state.fromDate,
            end: this.state.toDate 
        }, () => {
            this.getTransactions(this.state.pageSize, 1);
        });
    }

    handleCallback = (start, end) => {
        
        let fromD = '';
        if (start !== undefined && start !== "" && start !== null) {
            fromD = dateformatinUTC(start)
        }
        let toD = '';
        if (end !== undefined && end !== "" && end !== null) {
            toD = dateformatinUTC(end)
        }
        this.setState({
            toDate: end,
            toDateApi: toD,
            fromDate: start,
            fromDateApi: fromD
        });
    };

    checkIdGetName(val, type){
        const a1 = val; 
        let ObjVal;
       if(type === "selectedValues"){
        ObjVal = this.state.filterUserListName;
       } else{
        ObjVal = this.state.filterReportUserListName
       }
        const checking = ObjVal.find((item) => (item.id === a1 ? item.name : null));
        return checking?.name;
      };


      async getFilterHistory() {
        var url = baseUrl.URLPath + servicesUrl.transactionsGetFilterHistory;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let errors = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response !== undefined) {
            if (response) {
                let filterChargerList = response.chargepoints;
                let filterrfidTagsList = response.rfidTags;
                this.setState({
                    filterChargerList: filterChargerList,
                    filterrfidTagsList: filterrfidTagsList
                })
            } else if (response.status === 404) {

            }
        }
    }

 
    render() {
        const { t } = this.props
        const { selectedOption } = this.state;

        const options = [
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
        ];
        const colourStyles = {
            menuList: (styles) => ({
                ...styles,
                background: 'white',
              }),
              option: (styles, { isFocused, isSelected }) => {
                // Only apply the background color when an option is selected
                if (isSelected) {
                  return {
                    ...styles,
                    background: '#30436E',
                    color: 'white',
                    zIndex: 1,
                  };
                }
                return {
                  ...styles,
                  background: isFocused ? 'white' : 'transparent',
                  color: '#30436E',
                  zIndex: 1,
                };
              },
              menu: (base) => ({
                ...base,
                zIndex: 100,
              }),
          };
          const DropdownIndicator = (props) => {
            return (
              <components.DropdownIndicator {...props}>
                {/* <span style={{ transform: 'rotate(360deg)' }}>▼</span> */}
                <span className='caret-icon-w'></span>
              </components.DropdownIndicator>
            );
          };
        const table = { field: [] };
        let user = this.state.user;
        if (this.state.getDefaultFields !== undefined) {
            table.field = this.state.getDefaultFields;
        }

        let editAction = false;
        let carAction = false;

        for (let Operation in this.state.getOperationList) {
            if (this.state.getOperationList[Operation].operation === "Charging Session" && this.state.getOperationList[Operation].active === true) {
                for (let oper in this.state.getOperationList[Operation].subOperations) {
                    if (this.state.getOperationList[Operation].subOperations[oper].name === "Remote Start Transaction" && this.state.getOperationList[Operation].subOperations[oper].access === true) {
                        editAction = true;
                    }
                    if (this.state.getOperationList[Operation].subOperations[oper].name === "Logbook" && this.state.getOperationList[Operation].subOperations[oper].access === true) {
                        carAction = true;
                    }
                }
            }
        }

        const tagList = this.state.rfidTagsList.map((tag, index) => {
            return (
                
                <> 
                {(tag.idTag.trim() !== 0 && tag.idTag.trim() !== "") ? <option value={tag.idTag}>{tag.idTag}</option> : null} </>
            )
        });
        const groupList = this.state.groupList ? this.state.groupList : []

        const { start, end } = this.state;

        const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');
        return (
            <>
                <main className="content-div charging_session_new" >
                    <p>{t('Charging Session')} ({this.state.noOfRecords})</p>
                    <div className="page-outerdiv" id="charging_station">
                            <div className="row mb-4" style={{alignItems: "center"}}>
                                <div className="col-md-10">
                                    <div className="row">
                                    <div className="col-md-3 pr-0">
                                        <DateRangePicker
                                            initialSettings={{
                                                // startDate: moment().startOf('hour').toDate(),
                                                // endDate: moment().startOf('hour').add(32, 'hour').toDate(),
                                                startDate: moment().subtract(1, 'days').toDate(),
                                                endDate: moment().toDate(),
                                                ranges: {
                                                    Today: [moment().toDate(), moment().toDate()],
                                                    Yesterday: [
                                                        moment().subtract(1, 'days').toDate(),
                                                        moment().subtract(1, 'days').toDate(),
                                                    ],
                                                    'Last 7 Days': [
                                                        moment().subtract(6, 'days').toDate(),
                                                        moment().toDate(),
                                                    ],
                                                    'Last 30 Days': [
                                                        moment().subtract(29, 'days').toDate(),
                                                        moment().toDate(),
                                                    ],
                                                    'This Month': [
                                                        moment().startOf('month').toDate(),
                                                        moment().endOf('month').toDate(),
                                                    ],
                                                    'Last Month': [
                                                        moment().subtract(1, 'month').startOf('month').toDate(),
                                                        moment().subtract(1, 'month').endOf('month').toDate(),
                                                    ],
                                                },
                                            }}
                                            onCallback={this.handleCallback}
                                            onApply={this.handleSubmitCalender}
                                        >
                                            <button type="button" className="calendar-button">
                                                <img src={process.env.PUBLIC_URL + "/assets/img/cs_calender_icon.svg"} className="pr-2 pl-1" alt=""/>
                                                <span className="filterName">{t('select_a_date')}</span>
                                                <span className="caret-icon"></span>
                                            </button>
                                        </DateRangePicker>
                                    </div>
                                    <div className="col-md-3 pr-0">
                                        <CustomFilterDropdown
                                            iconSrc={process.env.PUBLIC_URL + "/assets/img/cs_charge_point_icon.svg"}
                                            placeholder={t('search_for_chargepoint_id')}
                                            headerText={t('charging_station')}
                                            option={this.state.filterChargerList} 
                                            handleCheckboxChange={this.handleCheckboxChange}
                                            handleSubmit={() => this.handleSubmit("isOpen1")}
                                            isOpen={this.state.isOpen1}
                                            selectedOptions={this.state.selectedOptions}
                                            searchValue={this.state.searchValue}
                                            handleSearchChange={this.handleSearchChange.bind(this, 'filterChargerList')}
                                            errorsMsg={this.state.errors["nodatafound"]}
                                            category={"chargingStation"}
                                            cssClass="chargeStationIcon"
                                            isApplyButtonDisabled={this.state.activeApplyBtn}
                                            onSelect={this.handleFilterChange}
                                            emptySearchValues={this.emptySearchValues}
                                            getFilterHistory = {this.getFilterHistory}
                                            showHistory = {true}
                                        /></div>
                                    <div className="col-md-3 pr-0">
                                        <CustomFilterDropdown
                                            iconSrc={process.env.PUBLIC_URL + "/assets/img/cs_rfid_tag_icon.svg"}
                                            placeholder={t('search_for_rfid_tag')}
                                            headerText={t('authorization_tag')}
                                            option={this.state.filterrfidTagsList}
                                            handleCheckboxChange={this.handleCheckboxChange}
                                            handleSubmit={() => this.handleSubmit("isOpen2")}
                                            isOpen={this.state.isOpen2}
                                            selectedOptions={this.state.selectedOptions}
                                            searchValue={this.state.searchRfidValue}
                                            handleSearchChange={this.handleSearchRfidChange.bind(this, 'filterrfidTagsList')}
                                            errorsMsg={this.state.errors["nodatarfidfound"]}
                                            category={"RfidTag"}
                                            cssClass="RFIDIcon"
                                            isApplyButtonDisabled={this.state.activeApplyBtn}
                                            onSelect={this.handleFilterChange}
                                            emptySearchValues={this.emptySearchValues}
                                            getFilterHistory = {this.getFilterHistory}
                                            showHistory = {true}
                                        /></div>
                                    {/* <div className="col-md-3 pr-0">
                                        <CustomFilterDropdown
                                            iconSrc={process.env.PUBLIC_URL + "/assets/img/cs_user_icon.svg"}
                                            placeholder={t('search_for_user')}
                                            headerText={t('user')}
                                            option={this.state.filterUserList}
                                            toggleDropdown={() => this.toggleDropdown("isOpen3")}
                                            handleCheckboxChange={this.handleCheckboxChange}
                                            handleSubmit={() => this.handleSubmit("isOpen3")}
                                            isOpen={this.state.isOpen3}
                                            selectedOptions={this.state.selectedOptions}
                                            searchValue={this.state.searchUserValue}
                                            handleSearchChange={this.handleSearchUserChange.bind(this, 'filterUserList')}
                                            errorsMsg={this.state.errors["nodatauserfound"]}
                                            category={"Users"}
                                            cssClass="userIcon"
                                            filterUserListName={this.state.filterUserListName}
                                            type= "filterUserListName"
                                        /></div> */}

                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="" style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <div className="mr-3">
                                        <button type="button" data-test="button" className="refresh-btn" onClick={this.onClickRefreshbtn}><img src={process.env.PUBLIC_URL + "/assets/img/cs_refresh.svg"} width="20" className="icon" alt=""/> <span>{t('refreshBtn')}</span></button>
                                    </div>
                                    <div className="">
                                        <div className="mainsettings" onClick={this.openSettingsMenu}>
                                            <img src={process.env.PUBLIC_URL + "/assets/img/cs_settings_menu_icon.svg"} width="36" className="icon" alt=""/>
                                        </div>
                                    </div>
                                    </div>
                                </div>                                
                                {this.state.settingsMenu && (
                                    <div className="settingspopup" ref={node => {
                                        this.node = node;
                                    }}>
                                        {hasPermission(user, ["SessionExport_R"]) && <>
                                            <button type="button" data-test="button" className="setting-btn mb-3" onClick={this.showExportPopup}><img src={process.env.PUBLIC_URL + "/assets/img/export_csv_xls.svg"} alt=""/> &nbsp;{t('export')}</button>
                                        </>
                                        }
                                        <button type="button" className="setting-btn mb-3" onClick={this.openReportModal}><img src={process.env.PUBLIC_URL + "/assets/img/cs_report_icon.svg"} alt=""/> &nbsp;{t('report')}</button>
                                        <button type="button" className="setting-btn" onClick={this.settingAction}><img src={process.env.PUBLIC_URL + "/assets/img/list_settings_icon.svg"} alt=""/> &nbsp;{t('list_settings')}</button>
                                    </div>
                                )}
                            </div>
                        <div className="row">
                            <div className='col-12'>
                                <div className="selecteditems" style={{ flexWrap: "wrap" }}>
                                    <button className="icon-button mr-3 mb-3">
                                        <img src={process.env.PUBLIC_URL + "/assets/img/cs_calender_icon.svg"} width="32" className="pr-2" alt=""/>
                                        <span>{this.state.start.format('DD MMM.YYYY') + " - " + this.state.end.format('DD MMM.YYYY')}</span>
                                    </button>
                                    {Object.keys(this.state.selectedValues).map((category) => (
                                       
                                        this.state.selectedValues[category].map((val, index) => (
                                            <>
                                                <button className="icon-button mr-3 mb-3" key={index}>
                                                    <img
                                                        src={(category === "chargingStation") ? process.env.PUBLIC_URL + "/assets/img/cs_charge_point_icon.svg" : ((category === "RfidTag") ? process.env.PUBLIC_URL + "/assets/img/cs_rfid_tag_icon.svg" : process.env.PUBLIC_URL + "/assets/img/cs_user_icon_sm.svg")}
                                                        width={(category === "chargingStation") ? 34 : ((category === "RfidTag") ? 32 : 26)} 
                                                        className="icon pr-2"
                                                        alt={val}
                                                    />
                                                    <span className="name">{(category === "Users") ? this.checkIdGetName(val,"selectedValues"): val}</span>
                                                    <span
                                                        className="close-icon"
                                                        onClick={() => this.removeSelectedValue(category, val)}
                                                    ></span>
                                                </button>
                                            </>
                                        ))
                                    ))}

                                    {(this.state.selectedValues.chargingStation.length !== 0 || this.state.selectedValues.RfidTag.length !== 0 || this.state.selectedValues.Users.length !== 0) && (
                                        <div className="mr-2">
                                            <button type="button" data-test="button" aria-label="Close" className="clearall-btn" onClick={this.removeAllSelected}><span aria-hidden="true">×</span> {t('clear_all')}</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {this.state.showLoder === true &&
                                <div className="page_loader center" id="loader_image_div">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                                </div>
                            }
                        </div>
                        {/* end search bar div */}
                        <div className="lastdiv mt-2">
                            <div className="pull-right show_entries" style={{ display: "flex" }}>
                                <label className="sub-text">{t('show_entries')}</label>
                                <Select
                                    className="react-select-container"
                                    defaultValue={selectedOption}
                                    onChange={this.showEntriesChange}
                                    options={options}
                                    isSearchable={false}
                                    placeholder="10"
                                    styles={colourStyles}
                                    components={{ DropdownIndicator }}
                                />
                            </div>
                        </div>
                        {/* <div className="lastdiv mt-2">
              <div className="pull-right show_entries">
                <label className="sub-text pull-left" >{t('show_entries')}</label>
                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0" ><span className="caret-icon-w"></span>
                  <Form.Control as="select" className="showentry_sel custom_selectBox2" id="showEntries" onChange={this.showEntriesChange}>
                    <option className="seo pb-2">10</option>
                    <option className="seo pb-2">20</option>
                    <option className="seo pb-2">30</option>
                    <option className="seo pb-2">40</option>
                    <option className="seo pb-2">50</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </div> */}

                        {/* table start */}
                        <div className="row">
                            <div className="col-md-12 p-rel">
                                {/* settings popup */}
                                <div className='settingOuterDiv'>
                                    <MDBModal isOpen={this.state.settingPopup} size="md" className="settingDiv listSettings" backdrop="static" data-backdrop="static" data-keyboard="false" >
                                        <MDBModalHeader toggle={this.togglesettingPopup}>{t('table_content')}</MDBModalHeader>
                                        <MDBModalBody className="mt-0">
                                            <div className="row list-settings-data">
                                                {Object.entries(this.state.groupList).map(([key, val]) => {
                                                    return (<div  key={key} className="col-md-6 pb-2"><label className="groupname">{key}</label>
                                                        {
                                                            val.map((el, index) => {
                                                                if (editAction === false && (el.displayName === "Customer Number" || el.displayName === "Reference Number")) {
                                                                    return <></>
                                                                } else {
                                                                    return (
                                                                        <Form.Check
                                                                            key={`${key}_${el.key}_${index}`}
                                                                            className=""
                                                                            custom
                                                                            id={`${el.key}_${index}`}
                                                                            type="checkbox"
                                                                            checked={this.isItemSelected(el.key)}
                                                                            disabled = {el.isDefault}
                                                                            onChange={(event) => this.updateCheckboxSelection(event, el.key)}
                                                                            label={this.props.t(el.key)}
                                                                        />
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>)
                                                }
                                                )
                                                }
                                            </div>
                                        </MDBModalBody>
                                        <MDBModalFooter style={{ margin: "0 auto" }}>
                                            <button type="button" data-test="button" className="blueBtnNew listSettingsBtn" onClick={this.saveTableField} disabled={this.state.selectedItems?.length > 0 ? "" : "true"}>{t('save')}</button>
                                            <button type="button" data-test="button" className="ltblueBtn listSettingsBtn" onClick={this.togglesettingPopup}>{t('cancel')}</button>
                                        </MDBModalFooter>
                                    </MDBModal>
                                </div>

                                <div className='overflowx'>
                                    <Tansactionstable
                                        {...this.props}
                                        sorting={this.ascendingOrder.bind(this)}
                                        item={this.state.dataArray}
                                        settingAction={this.settingAction.bind(this)}
                                        tableField={this.state.getFieldsList}
                                        editAction={editAction}
                                        toggleRemoteStart={this.toggleRemoteStart}
                                        carAction={carAction}
                                        displayCarIcon={this.displayCarIcon}
                                    >
                                    </Tansactionstable>
                                </div>
                            </div>

                            <div className="col-md-12 mt-2 mb-4 text-right" >
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.pageSize}
                                    totalItemsCount={this.state.noOfRecords}
                                    onChange={this.handlePageChange.bind(this)}
                                    prevPageText={t('prev')}
                                    nextPageText={t('next')}
                                    itemClassPrev="prevBtn"
                                    pageRangeDisplayed="1"
                                    activeClass="activeLi active"
                                    itemClassNext="nextBtn"
                                    hideFirstLastPages={true}
                                    disableInitialCallback={true}
                                    disabledClass="disabled disabledtextColor"
                                />
                            </div>
                            {/* End table  */}
                        </div>
                    </div>

                    {/* Loader */}
                    <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                        <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                    </div>

                    <MDBModal isOpen={this.state.successTransaction} toggle={this.successTransaction} size="md" className="model_top">
                        <MDBModalHeader toggle={this.successTransaction}>{t('success')}</MDBModalHeader>
                        <MDBModalBody>
                            {t('transaction')}  {this.state.start_text === "Update" ? t('updated') : t('started')} {t('successfully')}.
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" className="btn_primary" onClick={() => { this.successTransaction.bind(this); window.location.reload(); }}>{t('ok')}</button>
                        </MDBModalFooter>
                    </MDBModal>
{/* 
                    <MDBModal isOpen={this.state.remoteStart} toggle={this.toggleRemoteStart} size="md" className="model_top">
                        <MDBModalHeader toggle={this.toggleRemoteStart} className="mt-3"><b><span style={{ fontSize: "15px", paddingLeft: "9px" }}>{t('remote_start')}</span></b></MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol md="9">
                                    <select className="browser-default custom-select select_height mb_8" id="tagList" onChange={this.idTagChange.bind(this)} value={this.state.selectedRfid} disabled={this.state.disabledRfid ? true : null}>
                                        <option>-- {t('select_rfid_tag')} *--</option>
                                        {tagList}
                                    </select>
                                    <span className="error_msg w3-animate-top error_rfid" style={{ color: "red" }}></span>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-3">
                                <MDBCol md="6">
                                    <div className="md-form">
                                        <input type="text" id="refNumber" className="form-control pl-0" onChange={(e) => { this.setState({ refNumber: e.target.value }) }} value={this.state.refNumber} maxLength="20" pattern="[A-Za-z0-9]*" />
                                        <label htmlFor="refNumber" className={(this.state.refNumber !== "" && this.state.refNumber !== null) ? "active" : ""}><b>{t('referenceNumber')}</b></label>
                                        <span className="error_Msg w3-animate-top error_refnumber" style={{ color: "red" }}></span>
                                    </div>
                                </MDBCol>
                                <MDBCol md="6">
                                    <div className="md-form">
                                        <input type="text" id="customerNumber" className="form-control pl-0" onChange={(e) => { this.setState({ customerNumber: e.target.value }) }} value={this.state.customerNumber} maxLength="20" pattern="[A-Za-z0-9]*" />
                                        <label htmlFor="customerNumber" className={(this.state.customerNumber !== "" && this.state.customerNumber !== null) ? "active" : ""}><b>{t('customerNumber')}</b></label>
                                        <span className="error_Msg w3-animate-top error_customerNumber" style={{ color: "red" }}></span>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow id="customization" className="mt-3">
                                <MDBCol md="12" className="energy_limitation pl-0">
                                    <Form.Check
                                        className="mt-3"
                                        custom
                                        value={(this.state.isactiveEnergy === true) ? true : false}
                                        checked={(this.state.isactiveEnergy === true) ? true : false}
                                        id={`energyLimit`}
                                        type="checkbox"
                                        label={<div><b>{t('energy_limitation')}</b></div>}
                                        onChange={() => {
                                            this.setState({ isactiveEnergy: !this.state.isactiveEnergy }, () => {
                                                if (this.state.isactiveEnergy === false) {
                                                    this.setState({
                                                        energyLevel: ""
                                                    })
                                                } else if (this.state.isactiveEnergy === true) {
                                                    this.setState({
                                                        energyLevel: ""
                                                    })
                                                }
                                            })
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-3">
                                <MDBCol md="6">
                                    <div className="md-form">
                                        <input type="text" id="energyLevel" disabled={(this.state.isactiveEnergy !== true) ? true : false} className="form-control pl-0" pattern="[0-9.]*" onChange={this.updateEnergyLevel} value={this.state.energyLevel} />
                                        <label htmlFor="energyLevel" className={(this.state.energyLevel !== "" && this.state.energyLevel !== null) ? "active" : ""}><b>{t('charged_energy_to(kWh)')}</b></label>
                                        <span className="error_Msg w3-animate-top error_energyLevel" style={{ color: "red" }}></span>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" className="btn_primary" onClick={this.beginRemoteStart.bind(this, this.state.selectedcpId, this.state.selectedTransactionId)}>{this.state.start_text}</button>
                            <button type="button" className="btn_cancel" onClick={this.toggleRemoteStart.bind(this)}>{t('cancel')} <i className="fas fa-times ml-2"></i></button>
                        </MDBModalFooter>
                    </MDBModal> */}

                    <MDBModal isOpen={this.state.remoteStart} size="md" className="rs-model-top">
                        <MDBModalBody>
                            <div className='p-2 remote-start pt-3'>
                                <div className='d-flex justify-content-between'>
                                    <h5 className='heading mt-1'>{t('remote_start')}</h5>
                                    <img src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"} className='pull-right cursor-p' onClick={() => { this.setState({ remoteStart: false }) }} height={14}/>
                                </div>
                                <h5 className='remote-start-h5'>{t('rfid_tag')}</h5>
                                <div>
                                    <select className="browser-default custom-select rs-select-height mb-2 wid-80" id="tagList" onChange={this.idTagChange.bind(this)} value={this.state.selectedRfid} disabled={this.state.disabledRfid ? true : null}>
                                                <option value="">{t('select_rfid_tag')}</option>
                                                {tagList}
                                    </select>
                                    <p className="error_msg w3-animate-top error_rfid mt-1" style={{ color: "red" }}></p>
                                </div>
                                
                                <hr className='gv-popup-hr' />
                                <h5 className='remote-start-h5'>{t('referenceNumber')}</h5>
                                <div className='md-form input-group input-group-sm  wid-80'>
                                    <input type="text"
                                        id="refNumber"
                                        onChange={(e) => { this.setState({ refNumber: e.target.value }) }}
                                        className="form-control"
                                        value={this.state.refNumber}
                                        maxLength="20"
                                        pattern="[A-Za-z0-9]*"
                                        placeholder={t('enter_ref_no')}
                                    />
                                    <p className="error_Msg w3-animate-top error_refnumber" style={{ color: "red" }}></p>
                                </div>
                                <hr className='gv-popup-hr' />
                                <h5 className='remote-start-h5'>{t('customerNumber')}</h5>
                                <div className='md-form input-group input-group-sm  wid-80'>
                                    <input type="text"
                                        id="customerNumber"
                                        onChange={(e) => { this.setState({ customerNumber: e.target.value }) }}
                                        className="form-control"
                                        value={this.state.customerNumber}
                                        maxLength="20"
                                        pattern="[A-Za-z0-9]*"
                                        placeholder={t('enter_customer_no')}
                                    />
                                    <p className="error_Msg w3-animate-top error_customerNumber" style={{ color: "red" }}></p>

                                </div>
                                <hr className='gv-popup-hr' />
                                <h5 className='remote-start-h5'>{t('energy_limit')}</h5>
                                <label class="gv-switch mr-3" style={{ float: 'left' }}>
                                    <input type="checkbox"
                                        checked={(this.state.isactiveEnergy === true) ? true : false}
                                        value={(this.state.isactiveEnergy === true) ? true : false}
                                        onChange={() => {
                                            this.setState({ isactiveEnergy: !this.state.isactiveEnergy }, () => {
                                                if (this.state.isactiveEnergy === false) {
                                                    this.setState({
                                                        energyLevel: ""
                                                    })
                                                } else if (this.state.isactiveEnergy === true) {
                                                    this.setState({
                                                        energyLevel: ""
                                                    })
                                                }
                                            })
                                        }}
                                    />
                                    <span class="gv-slider round"></span>
                                </label>
                                <p className='gv-createpopup-p1' style={{color : "#00529E", fontSize:'14px'}}>{t('energy_limitation')}</p>
                                <div className="mb-4">
                                    {this.state.isactiveEnergy && (
                                        <>
                                        <div>
                                            <div class="md-form input-group input-group-sm mb-2 wid-80">
                                                <input type="text"
                                                    id="energyLevel"
                                                    value={this.state.energyLevel}
                                                    className="form-control"
                                                    aria-label="Sizing example input"
                                                    aria-describedby="inputGroupMaterial-sizing-sm"
                                                    onChange={this.updateEnergyLevel}
                                                    placeholder={t('charged_energy_to(kWh)')}
                                                    // onKeyDown={this.props.handleEnergyLimit}
                                                    pattern="[0-9.]*"
                                                /> </div>
                                                <p className="error_Msg w3-animate-top error_energyLevel mt-1" style={{ color: "red" }}></p>
                                            </div>
                                        </>)}
                                </div>
                                <div className='mb-3 d-flex justify-content-center'>
                                    <button className="blueBtnNew w-50 p-2" onClick={this.beginRemoteStart.bind(this, this.state.selectedcpId, this.state.selectedTransactionId)}> {this.state.start_text}</button>
                                    <button className="ltblueBtn ml-3 w-50 p-2" onClick={() => { this.setState({ remoteStart: false }) }}> {t('cancel')}</button>
                                </div>
                            </div>
                            
                        </MDBModalBody>
                    </MDBModal>

                    {/* <MDBModal isOpen={this.state.mileageNdInventory} toggle={this.toggleMileageNdInventory} size="md" className="model_top">
                        <div class="modal-header row mx-0" ref={(node) => { if (node) { node.style.setProperty("border-bottom", "none", "important"); } }}>
                            <h4 class="modal-title fw-800 mt-1 col-md-12 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('mileage_and_inventory_num_to_chargingsession')}</h4>
                        </div>
                        <MDBModalBody>
                            <MDBRow className="mt-3">
                                <MDBCol md="8">
                                    <div className="md-form">
                                        <input type="text" id="mileage" className="form-control pl-0" pattern="[0-9.]*" onChange={this.updateMileage.bind(this)} value={this.state.mileage} onKeyDown={this.onKeyPress} />
                                        <label htmlFor="mileage" className={(this.state.mileage !== "" && this.state.mileage !== null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>{t('current_mileage_of_vehicle')}:*</label>
                                        <span className="error_Msg w3-animate-top error_mileage" style={{ color: "red" }}></span>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-3">
                                <MDBCol md="8">
                                    <div className="md-form">
                                        <input type="text" id="inventoryNumber" className="form-control pl-0" onChange={this.updateInventory.bind(this)} value={this.state.inventoryNumber} pattern="[a-zA-Z0-9]*" />
                                        <label htmlFor="inventoryNumber" className={(this.state.inventoryNumber !== "" && this.state.inventoryNumber !== null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>{t('inventory_num_of_vehicle')}:*</label>
                                        <span className="error_Msg w3-animate-top error_inventoryNumber" style={{ color: "red" }}></span>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <div className="modal-footer border border-0">
                            <button type="button" className="btn_primary" onClick={this.saveMileageNdInventory.bind(this, this.state.mileage, this.state.inventoryNumber)}>{t('save')} <i className="fas fa-save ml-2"></i></button>
                            <button type="button" className="btn_cancel" onClick={this.toggleMileageNdInventory}>{t('cancel')} <i className="fas fa-times ml-2"></i></button>
                        </div>
                    </MDBModal> */}

                    <MDBModal isOpen={this.state.mileageNdInventory} className="rs-model-top">
                        <MDBModalBody>
                            <div className='p-2 mileage-inventory pt-3'>
                                <div className='d-flex justify-content-between'>
                                    <h5 className='heading mt-1'>{t('mileage_and_inventory_num_to_chargingsession')}</h5>
                                    <img src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"} className='pull-right cursor-p ml-5' onClick={this.toggleMileageNdInventory} height={14}/>
                                </div>
                                
                                <h5 className='remote-start-h5 mb-0'>{t('mileage_of_the_vehicle')}*</h5>
                                <div className='md-form input-group input-group-sm  wid-80 mb-3'>
                                    <input type="text"
                                        id="mileage"
                                        onChange={this.updateMileage.bind(this)}
                                        className="form-control"
                                        value={this.state.mileage}
                                        onKeyDown={this.onKeyPress}
                                        pattern="[0-9.]*"
                                        placeholder={t('current_mileage_of_vehicle')}
                                    />
                                    <span className="error_Msg w3-animate-top error_mileage mt-1" style={{ color: "red"}}></span>
                                </div>
                                <h5 className='remote-start-h5 mb-0'>{t('inventory_no_of_vehicle')}*</h5>
                                <div className='md-form input-group input-group-sm  wid-80 mb-4'>
                                    <input type="text"
                                        id="inventoryNumber"
                                        onChange={this.updateInventory.bind(this)}
                                        className="form-control"
                                        value={this.state.inventoryNumber}
                                        pattern="[a-zA-Z0-9]*"
                                        placeholder={t('inventory_num_of_vehicle')}
                                    />
                                    <span className="error_Msg w3-animate-top error_inventoryNumber mt-1" style={{ color: "red" }}></span>
                                </div>

                                <div className='mb-3 d-flex justify-content-center mt-1'>
                                    <button type="button" className="blueBtnNew w-50 p-2" onClick={this.saveMileageNdInventory.bind(this, this.state.mileage, this.state.inventoryNumber)}> {t('save')} </button>
                                    <button type="button" className="ltblueBtn ml-3 w-50 p-2" onClick={this.toggleMileageNdInventory}> {t('cancel')}</button>
                                </div>
                            </div>
                        </MDBModalBody>
                    </MDBModal>

                    {/* <MDBModal isOpen={this.state.modalMileageNdInventory} toggle={this.toggleSaveMileageNdInventory} size="md" className="model_top">
                        <MDBModalHeader toggle={this.toggleSaveMileageNdInventory}>{t('success')}</MDBModalHeader>
                        <MDBModalBody>
                            {t('mileage_and_inventory_no_saved_successfully')}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" className="btn_primary" onClick={() => { this.getTransactions(this.state.pageSize, this.state.activePage); this.toggleSaveMileageNdInventory(); }}>{t('ok')}</button>
                        </MDBModalFooter>
                    </MDBModal> */}
                     <SuccessPopup
                        open={this.state.modalMileageNdInventory}
                        image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                        content={t('mileage_and_inventory_no_saved_successfully')}
                        btnText={t("close")}
                        onClickOperation={() => { this.getTransactions(this.state.pageSize, this.state.activePage); this.toggleSaveMileageNdInventory(); }}
                    />

                    {/* table content popup success  */}
                    <MDBModal isOpen={this.state.successTbContent} toggle={this.successTableContent} size="md" className="model_top deleteReportModal mw-450">                        
                        <MDBModalBody className="p-4"><div className="pt-2">
                                <div className="popupRightClose mb-3">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"} alt="" className='pull-right closeIcon' onClick={() => { this.successTableContent.bind(this); window.location.reload(); }} />
                                </div>
                            </div>
                            <p className="text-center fontMedium pb-2 pt-4">{t('table_saved_successfully')}</p>                            
                        </MDBModalBody>
                        <div className="confirmdelete pb-5">
                            <button className="ltblueBtn w-170 mb-3" onClick={() => { this.successTableContent.bind(this); window.location.reload(); }}>{t('Ok')}</button>
                        </div>
                    </MDBModal>

                    {/* export popup as csv or xls  */}
                    <MDBModal isOpen={this.state.exportBtn} toggle={this.closeExportPopup} size="md" className="model_top mw-580">
                        <MDBModalBody className="p-4">
                            <div className="pt-2">
                                <div className="newPopupHeader mb-3">
                                    <h5 className='pull-left'>{t('export')}</h5>
                                    <img src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"} className='pull-right closeIcon' onClick={this.closeExportPopup} alt=""/>
                                </div>
                                <div className="exportPopupCenterContent">
                                    <p>{t('the_export_will_be_downloaded_with_the_currently_set_filters')}</p>
                                    <div className="custom-control custom-radio exportDiv">
                                        <div className='pull-left pl-0 pb-0'>
                                            <Form.Check
                                                custom
                                                type="radio"
                                                id="CSV_export"
                                                label={t('CSV') + Array(10).fill(String.fromCharCode(160)).join('') + t('download_data_as_csv_file')}
                                                name="report"
                                                onChange={this.handleExportTypeChange.bind(this, 'CSV')}
                                            />
                                        </div>
                                    </div>

                                    <div className="custom-control custom-radio exportDiv">
                                        <div className='pull-left pl-0 pb-0'>
                                            <Form.Check
                                                custom
                                                type="radio"
                                                id="XLS_export"
                                                label={t('XLS') + Array(11).fill(String.fromCharCode(160)).join('') + t('download_data_as_xls_file')}
                                                name="report"
                                                onChange={this.handleExportTypeChange.bind(this, 'XLS')}
                                            />
                                        </div>
                                    </div>
                                    <div className="custom-control custom-radio pl-0 pb-1 pt-2">
                                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["exportFormatErr"]}</span></span>
                                    </div>
                                    <button className="blueBtnNew exportBtn mt-1 mb-3" onClick={() => this.exportTransactionCSV(this.state.fileType)}>{t('download')}</button>
                                </div>
                            </div>
                        </MDBModalBody>
                    </MDBModal>

                    {/* export popup failure  */}
                    <MDBModal isOpen={this.state.exportError} toggle={this.toggleExportError} size="md" className="model_top">
                        <MDBModalHeader toggle={this.toggleExportError}>{t('alert')}</MDBModalHeader>
                        <MDBModalBody>
                            {t('unable_to_download_contact_your_admin')}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" className="btn_primary" onClick={this.toggleExportError}>{t('ok')}</button>
                        </MDBModalFooter>
                    </MDBModal>

                    {/* reports popup  */}
                    <MDBModal isOpen={this.state.reportModal} toggle={this.closeReportModal} size="lg" className="model__top mw-720">
                        <MDBModalBody>
                            <div className="p-4 reportListBlock">
                                <div className="newPopupHeader">
                                    <h5 className='pull-left pb-0'>{t('report_settings')}</h5>
                                    <img src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"} className='pull-right closeIcon' onClick={this.closeReportModal} alt=""/>
                                </div>
                                <div className="reportParent text-center">
                                    <button className="blueBtnNew createReportBtn" onClick={this.openCreateReport.bind(this, "")}><img src={process.env.PUBLIC_URL + "/assets/img/plus.svg"} width="24" className="pr-1" alt=""/> {t('create_a_new_report')}</button>
                                </div>

                                <div className="reportList">
                                    {this.state.reportList.map((report, index) => {
                                        const isLastReport = index === this.state.reportList.length - 1;
                                        const marginBottom = isLastReport ? '40px' : '15px';
                                        return (
                                            <div className="reportblock" style={{ marginBottom }}>
                                                <div className="leftcontent pl-3">
                                                    <p className="fontBold">{(report?.reportName?.length > 30) ? report?.reportName?.substring(0, 28) + "..." : report?.reportName}</p>
                                                    <p><img src={process.env.PUBLIC_URL + "/assets/img/mail.svg"} width="20" alt=""/>&nbsp; {report.userEmail ? report.userEmail : localStorage.getItem('email')}</p>
                                                    <p className="mb-0"><img src={process.env.PUBLIC_URL + "/assets/img/calender.svg"} width="20" alt=""/>&nbsp; <span>{report.reportCycle + " on 01"}</span> <img src={process.env.PUBLIC_URL + "/assets/img/to_icon.svg"} className="toIcon" alt=""/> <span> {moment(report.nextSchedule).format("DD MMM YYYY")} </span></p></div>
                                                <div className="editpart mainsettings" onClick={() => this.setState({ reportListIndex: index, reportToggle: !this.state.reportToggle })}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/img/cs_settings_menu_icon.svg"} width="36" className="icon" alt=""/>
                                                </div>
                                                {(this.state.reportList[this.state.reportListIndex]?.id === report?.id) && (this.state.reportToggle) && (
                                                    <div className="settingspopup">
                                                        <button type="button" data-test="button" className="setting-btn mb-3" onClick={this.openCreateReport.bind(this, report.id)}><img src={process.env.PUBLIC_URL + "/assets/img/edit_report.svg"} alt=""/> &nbsp;{t('Edit')}</button>
                                                        <button type="button" className="setting-btn" onClick={this.toggleDeleteReport.bind(this, report.id)}><img src={process.env.PUBLIC_URL + "/assets/img/delete_report.svg"} className="reportDeleteIcon" alt=""/> &nbsp;{t('Delete')}</button>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </MDBModalBody>
                    </MDBModal>

                    {/* create new report popup  */}
                    <MDBModal isOpen={this.state.createReportModal} toggle={this.closeCreateReport} size="lg" className="model__top mw-720">
                        <MDBModalBody>
                            <div className="p-4 reportListBlock">
                                <div className="newPopupHeader mb-3">
                                    <h5 className='pull-left pb-0'>{t('report_settings')}</h5>
                                    <img src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"} className='pull-right closeIcon' onClick={this.closeCreateReport} alt=""/>
                                </div>
                                <div className="reporteditblock">
                                    <div className='row mb-3 mr-1'>
                                        <div className="col-12">
                                            <h5 className="mb-3">1. {t('set_the_filters')}</h5>
                                            <div className style={{ display: "flex", flexDirection: "column" }}>
                                                <div style={{ display: 'flex' }}>
                                                    <div className="col-md-5 p-0 mb-3">
                                                        <CustomFilterDropdown
                                                            iconSrc={process.env.PUBLIC_URL + "/assets/img/cs_charge_point_icon.svg"}
                                                            placeholder={t('search_for_chargepoint_id')}
                                                            headerText={t('charging_station')}
                                                            option={this.state.filterReportChargerList}
                                                            handleCheckboxChange={this.handleCheckboxChangeInReport}
                                                            handleSubmit={() => this.handleSubmitReportDropdown("isOpen4")}
                                                            isOpen={this.state.isOpen4}
                                                            selectedOptions={this.state.selectedReportOptions}
                                                            searchValue={this.state.reportSearchValue}
                                                            handleSearchChange={this.handleSearchChangeReport.bind(this, 'filterReportChargerList')}
                                                            errorsMsg={this.state.errors["nodatafound"]}
                                                            category={"chargingStation"}
                                                            isApplyButtonDisabled={this.state.activeReportApplyBtn}
                                                            onSelect={this.handleFilterChange}
                                                            emptySearchValues={this.emptySearchValues}
                                                            showHistory = {false}
                                                        />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="selecteditems">
                                                            {(this.state.selectedReportValues.chargingStation).map((val, index) => (
                                                                (index === 0) ? <button className="icon-button mt-1">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/img/cs_charge_point_icon.svg"} width="32" className="icon pr-2" alt={val} />
                                                                    <span className="name">{(val?.length >= 21) ? val?.substring(0, 17) + "..." : val}</span>
                                                                    <span className="close-icon" onClick={() => this.removeSelectedValueForReport("chargingStation", val)}></span>
                                                                </button> : ""
                                                            ))}
                                                        </div>
                                                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["optionsErr"]}</span></span>
                                                    </div>
                                                    <div className="col-md-4 p-0">
                                                        {(this.state.selectedReportValues.chargingStation?.length > 1) && (
                                                            <div>
                                                                <button type="button" className="morebutton" onClick={() => this.setState({ moremodalChargingStation: !this.state.moremodalChargingStation })}>{(this.state.selectedReportValues.chargingStation?.length - 1) + "+"} {t('More')} <span className="caret-icon-w"></span></button>
                                                            </div>
                                                        )}
                                                        <div>
                                                            {(this.state.moremodalChargingStation) && (this.state.selectedReportValues.chargingStation?.length >= 2) && (<>
                                                                <div className='finaldatapopup'>
                                                                    <div className="selecteditems">
                                                                        {this.state.selectedReportValues.chargingStation.slice(1, this.state.selectedReportValues.chargingStation.length)?.map((value, index) => (
                                                                            <button className="icon-button mb-2">
                                                                                <img src={process.env.PUBLIC_URL + "/assets/img/cs_charge_point_icon.svg"} width="32" className="icon pr-2" alt=""/>
                                                                                <span className="name">{(value?.length >= 21) ? value?.substring(0, 17) + "..." : value}</span>
                                                                                <span className="close-icon" onClick={() => this.removeSelectedValueForReport("chargingStation", value)}></span>
                                                                            </button>
                                                                        )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex' }}>
                                                    <div className="col-md-5 p-0 mb-3">
                                                        <CustomFilterDropdown
                                                            iconSrc={process.env.PUBLIC_URL + "/assets/img/cs_rfid_tag_icon.svg"}
                                                            placeholder={t('search_for_rfid_tag')}
                                                            headerText={t('authorization_tag')}
                                                            option={this.state.filterReportRfidTagsList}
                                                            handleCheckboxChange={this.handleCheckboxChangeInReport}
                                                            handleSubmit={() => this.handleSubmitReportDropdown("isOpen5")}
                                                            isOpen={this.state.isOpen5}
                                                            selectedOptions={this.state.selectedReportOptions}
                                                            searchValue={this.state.reportSearchRfidValue}
                                                            handleSearchChange={this.handleSearchRfidChangeReport.bind(this, 'filterReportRfidTagsList')}
                                                            errorsMsg={this.state.errors["nodatarfidfound"]}
                                                            category={"RfidTag"}
                                                            isApplyButtonDisabled={this.state.activeReportApplyBtn}
                                                            onSelect={this.handleFilterChange}
                                                            emptySearchValues={this.emptySearchValues}
                                                            showHistory = {false}
                                                        />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="selecteditems">
                                                            {(this.state.selectedReportValues.RfidTag).map((val, index) => (
                                                                (index === 0) ? <button className="icon-button mt-1">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/img/cs_rfid_tag_icon.svg"} width="32" className="icon pr-2" alt={val} />
                                                                    <span className="name">{(val?.length >= 21) ? val?.substring(0, 17) + "..." : val}</span>
                                                                    <span className="close-icon" onClick={() => this.removeSelectedValueForReport("RfidTag", val)}></span>
                                                                </button> : ""
                                                            ))}
                                                        </div>
                                                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["optionsErr"]}</span></span>
                                                    </div>
                                                    <div className="col-md-4 p-0">
                                                        {(this.state.selectedReportValues.RfidTag?.length > 1) && (
                                                            <div>
                                                                <button type="button" className="morebutton" onClick={() => this.setState({ moremodalRfidTag: !this.state.moremodalRfidTag })}>{(this.state.selectedReportValues.RfidTag?.length - 1) + "+"} {t('More')} <span className="caret-icon-w"></span></button>
                                                            </div>
                                                        )}
                                                        <div>
                                                            {(this.state.moremodalRfidTag) && (this.state.selectedReportValues.RfidTag?.length >= 2) && (<>
                                                                <div className='finaldatapopup'>
                                                                    <div className="selecteditems">
                                                                        {this.state.selectedReportValues.RfidTag.slice(1, this.state.selectedReportValues.RfidTag.length)?.map((value, index) => (
                                                                            <button className="icon-button mb-2">
                                                                                <img src={process.env.PUBLIC_URL + "/assets/img/cs_rfid_tag_icon.svg"} width="32" className="icon pr-2" alt=""/>
                                                                                <span className="name">{(value?.length >= 21) ? value?.substring(0, 17) + "..." : value}</span>
                                                                                <span className="close-icon" onClick={() => this.removeSelectedValueForReport("RfidTag", value)}></span>
                                                                            </button>
                                                                        )
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div style={{ display: 'flex' }}>
                                                    <div className="col-md-5 p-0 mb-3">
                                                        <CustomFilterDropdown
                                                            iconSrc={process.env.PUBLIC_URL + "/assets/img/cs_user_icon.svg"}
                                                            placeholder={t('search_for_user')}
                                                            headerText={t('user')}
                                                            option={this.state.filterReportUserList}
                                                            toggleDropdown={() => this.toggleDropdown("isOpen6")}
                                                            handleCheckboxChange={this.handleCheckboxChangeInReport}
                                                            handleSubmit={() => this.handleSubmitReportDropdown("isOpen6")}
                                                            isOpen={this.state.isOpen6}
                                                            selectedOptions={this.state.selectedReportOptions}
                                                            searchValue={this.state.reportSearchUserValue}
                                                            handleSearchChange={this.handleSearchUserChangeReport.bind(this, 'filterReportUserList')}
                                                            errorsMsg={this.state.errors["nodatauserfound"]}
                                                            category={"Users"}
                                                            type = "filterReportUserListName"
                                                            filterReportUserListName={this.state.filterReportUserListName}
                                                        />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="selecteditems">
                                                            {(this.state.selectedReportValues.Users)?.map((val, index) => (
                                                                (index === 0) ? <button className="icon-button mt-1">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/img/cs_user_icon_sm.svg"} width="26" className="icon pr-2" alt={val} />
                                                                    <span className="name">{this.checkIdGetName(val,"selectedReportValues")}</span>
                                                                    <span className="name">{(val?.length >= 21) ? val?.substring(0, 17) + "..." : val}</span>
                                                                    <span className="close-icon" onClick={() => this.removeSelectedValueForReport("Users", val)}></span>
                                                                </button> : ""
                                                            ))}
                                                        </div>
                                                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["optionsErr"]}</span></span>
                                                    </div>
                                                    <div className="col-md-4 p-0">
                                                        {(this.state.selectedReportValues.Users?.length > 1) && (
                                                            <div>
                                                                <button type="button" className="morebutton" onClick={() => this.setState({ moremodalUsers: !this.state.moremodalUsers })}>{(this.state.selectedReportValues.Users?.length - 1) + "+"} {t('More')} <span className="caret-icon-w"></span></button>
                                                            </div>
                                                        )}
                                                        <div>
                                                            {(this.state.moremodalUsers) && (this.state.selectedReportValues.Users?.length >= 2) && (<>
                                                                <div className='finaldatapopup'>
                                                                    <div className="selecteditems">
                                                                        {this.state.selectedReportValues.Users.slice(1, this.state.selectedReportValues.Users.length)?.map((value, index) => (
                                                                            <button className="icon-button mb-2">
                                                                                <img src={process.env.PUBLIC_URL + "/assets/img/cs_user_icon_sm.svg"} width="28" className="icon pr-2" />
                                                                                <span className="name">{this.checkIdGetName(value,"selectedReportValues")}</span>
                                                                                <span className="name">{(value?.length >= 21) ? value?.substring(0, 17) + "..." : value}</span>
                                                                                <span className="close-icon" onClick={() => this.removeSelectedValueForReport("Users", value)}></span>
                                                                            </button>
                                                                        )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <hr className='thickborder' />
                                        </div>
                                    </div>
                                    <div className="row mb-3 mr-1">
                                        <div className="col-12 alternativeemail">
                                            <h5 className="mb-3">2. {t('enter_the_email_address')}</h5>
                                            <label className="switch mr-3" style={{ float: 'left' }}>
                                                <input type="checkbox" checked={this.state.isAlternativeEmailActive} onChange={this.onChangeAlternativeEmailActive} />
                                                <span className="slider round"></span>
                                            </label>
                                            <p>{t('do_you_want_to_send_the_report_to')}<br/>{t('an_alternative_mail_address')}</p>
                                            <span style={{ opacity: "0.5" }}>{localStorage.getItem('email')}</span>
                                            <div className="mb-5">
                                                {this.state.isAlternativeEmailActive && (
                                                    <>
                                                        <div className="md-form input-group input-group-sm mb-3">
                                                            <input type="text"
                                                                id="email"
                                                                placeholder={t('enter_an_email_address')}
                                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                                                value={this.state.reportFields["alternativeEmail"]}
                                                                onChange={this.reportChangeHandler.bind(this, "alternativeEmail")}
                                                                className="form-control"
                                                                aria-label="Sizing example input"
                                                                aria-describedby="inputGroupMaterial-sizing-sm" />
                                                        </div>
                                                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["emailErr"]}</span></span>
                                                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["entervalidemail"]}</span></span>
                                                    </>
                                                )}
                                            </div>
                                            <hr className='thickborder' />
                                        </div>
                                    </div>
                                    <div className="row mb-3 mr-1">
                                        <div className="col-12">
                                            <h5>3. {t('select_the_report_cycle')}</h5>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div className="custom-control custom-radio exportDiv">
                                                    <div className='pull-left pl-0 pb-0'>
                                                        <Form.Check
                                                            custom
                                                            type="radio"
                                                            id="Month"
                                                            label={t('monthly_on_01')}
                                                            name="report"
                                                            onChange={this.handleReportCycle.bind(this, 1)}
                                                            checked={this.state.reportType === 1 ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="custom-control custom-radio exportDiv mb-4">
                                                    <div className='pull-left pl-0 pb-0'>
                                                        <Form.Check
                                                            custom
                                                            type="radio"
                                                            id="Quarter"
                                                            label={t('quarterly_on_01')}
                                                            name="report"
                                                            onChange={this.handleReportCycle.bind(this, 3)}
                                                            checked={this.state.reportType === 3 ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["reportCycleErr"]}</span></span>
                                            <hr className='thickborder' />
                                        </div>
                                    </div>
                                    <div className="row mb-3 mr-1">
                                        <div className="col-12">
                                            <h5>4. {t('select_the_file_format')}</h5>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div className="custom-control custom-radio exportDiv">
                                                    <div className='pull-left pl-0 pb-0'>
                                                        <Form.Check
                                                            custom
                                                            type="radio"
                                                            id="export_CSV"
                                                            label={t('CSV') + Array(7).fill(String.fromCharCode(160)).join('') + t('download_data_as_csv_file')}
                                                            name="reportSheet"
                                                            onChange={this.handleExportFormat.bind(this, 'CSV')}
                                                            checked={this.state.reportExportType === 'CSV' ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="custom-control custom-radio exportDiv mb-4">
                                                    <div className='pull-left pl-0 pb-0'>
                                                        <Form.Check
                                                            custom
                                                            type="radio"
                                                            id="export_XLS"
                                                            label={t('XLS') + Array(8).fill(String.fromCharCode(160)).join('') + t('download_data_as_xls_file')}
                                                            name="reportSheet"
                                                            onChange={this.handleExportFormat.bind(this, 'XLS')}
                                                            checked={this.state.reportExportType === 'XLS' ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["exportFormatErr"]}</span></span>
                                            <hr className='thickborder' />
                                        </div>
                                    </div>
                                    <div className="row mb-4 mr-1">
                                        <div className="col-12">
                                            <h5 className="mb-4">5. {t('enter_a_name_for_the_report')}</h5>
                                            <div className="md-form input-group input-group-sm mb-3">
                                                <input type="text"
                                                    placeholder={t('enter_a_name') + " *"}
                                                    value={this.state.reportFields["reportName"]}
                                                    onChange={this.reportChangeHandler.bind(this, "reportName")}
                                                    maxLength="20"
                                                    className="form-control mr-5"
                                                    name="reportName" />
                                            </div>
                                            <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["reportNameErr"]}</span></span>
                                        </div>
                                    </div>
                                </div>
                                    <div className="newPopupFooer">
                                    {/* <button className="blueBtnNew createReportBtn" onClick={this.createReport.bind(this)}> {t('create_report')}</button> */}
                                        {this.state.reportAction === "Create" ?
                                            <button className="blueBtnNew createReportBtn" onClick={this.createReport.bind(this)}> {t('create_report')}</button>
                                            :
                                            <button className="blueBtnNew createReportBtn" onClick={this.updateReport.bind(this)}> {t('update_report')}</button>
                                        }
                                        <button className="ltblueBtn createReportBtn pull-right" onClick={this.closeCreateReport}> {t('cancel')}</button>
                                    </div>
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                    {/* report delete success popup  */}
                    <MDBModal isOpen={this.state.reportModal1} toggle={this.reportToggle1} size="md" className="model_top deleteReportModal mw-450">
                        <MDBModalBody className="p-4">
                            <div className="pt-2">
                                <div className="popupRightClose mb-3">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"} className='pull-right closeIcon' onClick={this.reportToggle1} alt=""/>
                                </div>
                            </div>
                            <p className="text-center fontMedium pb-2 pt-4">{t('report_deleted_successfully')}</p>
                        </MDBModalBody>
                        <div className="confirmdelete pb-5">
                            <button className="ltblueBtn w-170 mb-3" onClick={this.reportToggle1}>{t('Ok')}</button>
                        </div>
                    </MDBModal>
                    {/* report added success popup */}
                    <MDBModal isOpen={this.state.isCreateReportSuccess} toggle={this.reportCreateSuccess} size="md" className="model_top deleteReportModal mw-450">
                        <MDBModalBody className="p-4">
                            <div className="pt-2">
                                <div className="popupRightClose mb-3">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"} className='pull-right closeIcon' onClick={this.closeReportSuccessPopup} alt=""/>
                                </div>
                            </div>
                            <p className="text-center fontMedium pb-2 pt-4">{t(this.state.reportMsg)}</p>
                        </MDBModalBody>
                        <div className="confirmdelete pb-5">
                            <button className="ltblueBtn w-170 mb-3" onClick={() => { this.closeReportSuccessPopup.bind(this); window.location.reload(); }}>{t('Ok')}</button>
                        </div>
                    </MDBModal>

                    {/* report delete popup  */}
                    <MDBModal isOpen={this.state.deleteReport} toggle={this.closeDeleteReport} size="md" className="model_top deleteReportModal mw-450">
                        <MDBModalBody className="p-4">
                            <div className="pt-2">
                                <div className="popupRightClose mb-3">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"} className='pull-right closeIcon' onClick={this.closeDeleteReport} alt=""/>
                                </div>
                            </div>
                            <p className="text-center fontMedium pb-5 pt-5">{t('do_you_want_to_delete_this_report')}</p>
                            <div className="confirmdelete">
                                <button className="ltpinkBtn w-170 mb-3" onClick={this.handleDeleteReport}>{t('delete_report')}</button>
                                <button className="ltblueBtn w-170 mb-3" onClick={this.closeDeleteReport}>{t('cancel')}</button>
                            </div>
                        </MDBModalBody>
                    </MDBModal>

                    {/* report delete success popup  */}
                    {/* <MDBModal isOpen={this.state.reportModal1} toggle={this.reportToggle1} size="md" className="model_top deleteReportModal mw-450">
                        <MDBModalBody className="p-4">
                            <div className="pt-2">
                                <div className="popupRightClose mb-3">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/close_icon.svg"} className='pull-right closeIcon' onClick={this.reportToggle1} />
                                </div>
                            </div>
                            <p className="text-center fontMedium pb-2 pt-4">{t('report_deleted_successfully')}</p>
                        </MDBModalBody>
                        <div className="confirmdelete pb-5">
                            <button className="ltblueBtn w-170 mb-3" onClick={this.reportToggle1}>{t('cancel')}</button>
                        </div>
                    </MDBModal> */}
  
                    {/* set charging limit warning popup message */}
                    <CustomModal
                        open={this.state.ASetChargingLimit}
                        toggle={this.toggleASetChargingLimit}
                        title={t('alert')}
                        bodyMessage={t("reservation_slot_not_available")}
                        PrimaryButtonText={t('ok')}
                        PrimaryButtonOperation={this.toggleASetChargingLimit}
                    />

                    {/* cp online satus popup */}
                    <CustomModal
                    open={this.state.cponlinestatusAlert}
                    title={t('alert')}
                    bodyMessage={t(this.state.cponlinestatus)}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.closeCponlinestatus}
                />
                </main>
            </>
        );
    }
}
export default withTranslation()(transactions);