import React from 'react';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { withTranslation } from 'react-i18next';
import "react-circular-progressbar/dist/styles.css"

//API Imports
import * as baseUrl from '../constants';
import servicesUrl from '../../common/servicesUrl';
import APICallUtility from '../../common/APICallUtility';
import ApiMethodTypes from '../../common/ApiMethodTypes';
import { getSecurityHeaders } from '../../common/HttpRequestClass';

class ChargingPower extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPower: "",
            power: "",
            chargepointid: "",
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.refreshChargingPower = this.refreshChargingPower.bind(this);
    }

    handleChange(e) {
        if (e.target.value === "") {
            this.setState({ errors: {}, chargepointid: '' }, () => {
                this.getChargingPower("");
            });
        } else {
            this.setState({ chargepointid: e.target.value });
        }
    }

    keyPress(e) {
        if (e.keyCode === 13) {
            this.setState({ chargepointid: e.target.value }, () => {
                this.getChargingPower("");
            });
        }
    }

    componentDidMount() {
        this.getChargingPower("totalValue");
    }

    async getChargingPower(t_power) {
        var url = baseUrl.URLPath + servicesUrl.getChargingPowerWithCPFilter + this.state.chargepointid;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};
        let errors = {};

        document.getElementById("loader_image_div_charging_power").style.display = "block";

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

        if (response.status === 404) {
            setTimeout(() => {
                if (this.state.chargepointid !== "") {
                    errors["chargepointdoesNotExist"] = this.props.t('charge_point_not_exists');
                    this.setState({ errors: errors }, () => { document.getElementById("loader_image_div_charging_power").style.display = "none"; });
                }
            }, 1);
        } else if (response !== undefined) {
            setTimeout(() => {
                let state = { power: response.totalPower, errors: {} };
                if (t_power !== "") { state.totalPower = response?.totalPower };
                this.setState({ ...state }, () => { document.getElementById("loader_image_div_charging_power").style.display = "none"; })
            }, 1);
        }
    }

    refreshChargingPower() {
        this.getChargingPower("totalValue");
    }

    render() {
        let percentage = ((this.state.power / this.state.totalPower) * 100).toFixed(2);
        if (isNaN(percentage)) {
            percentage = 0;
        }
        return (
            <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success dashboard-height">
                <div className="content-box" >
                    <div className="dashboard-subtitle">
                        {this.props.t('charging_power')}
                        <span className="pull-right cursor-pointer">
                            <img src={process.env.PUBLIC_URL + "/assets/img/refresh_btn.svg"} width="22" onClick={this.refreshChargingPower.bind(this)} />
                        </span>
                    </div>
                    <div className="input-group input-search mt-2">
                        <i className="fas fa-search fa-sm"></i>
                        <input type="text" className="border-0 small" placeholder={this.props.t('search_chargepoint')} onKeyDown={this.keyPress} onChange={this.handleChange} value={this.state.chargepointid} />
                        <span className="error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.state.errors["chargepointdoesNotExist"]}</span></span>
                    </div>
                    <div className="circular-progress-bar w-100">
                        <CircularProgressbar
                            className="percentage"
                            value={percentage}
                            text={`${this.state.power}kW`}
                            strokeWidth="8"
                            styles={buildStyles({
                                backgroundColor: '#000',
                                pathColor: `#72c93e`,
                                textColor: '#72c93e',
                                trailColor: '#d6d6d6'
                            })}
                        />
                    </div>
                </div>
                {/* Loader */}
                <div className="loader center" id="loader_image_div_charging_power" style={{ display: "none", borderRadius: "18px" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>
            </div>
        )
    };
}

export default withTranslation()(ChargingPower);