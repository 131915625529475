import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { withTranslation } from 'react-i18next';

//API imports 
import * as baseUrl from '../constants'
import servicesUrl from '../../common/servicesUrl'
import APICallUtility from '../../common/APICallUtility'
import ApiMethodTypes from '../../common/ApiMethodTypes'
import {getSecurityHeaders} from '../../common/HttpRequestClass'

 class inviteUser extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            inviteMessage:'',
            errorCode: '',
            token:'',
            groupName:"",
            modalsession: false
        }
        this.verifyUser = this.verifyUser.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
    }
    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }
    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }
    async verifyUser(){
        var location = window.location.href;
        var allurl = location.split("/");
        var n = allurl.length;
        this.state.token = allurl[n - 2];
        let url  = baseUrl.LoginPath + servicesUrl.getVerifyUser+ this.state.token;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders(); 
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t); 
        if(response.errorCode === 200){
            let resMsg =  response.message.split(":")
            let inviteMsg = resMsg[0]
            let groupName = resMsg[1]
            this.setState({
                inviteMessage: this.props.t(inviteMsg),
                groupName : groupName,
                errorCode: response.errorCode
            });
        }else{
            this.setState({
                inviteMessage: this.props.t(response.errorMessage),
                errorCode: response.errorCode
            });
        }
    }
    // verifyUser(){
    //     var location = window.location.href;
    //     var allurl = location.split("/");
    //     var n = allurl.length;
    //     this.state.token = allurl[n - 2];

    //     let url = baseUrl.LoginPath + "/sitemanagement/invitation/"+ this.state.token;

    //     fetch(url,{
    //         method:"GET",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': localStorage.getItem('Authorization')
    //         }
    //     }).then(resp =>{
    //         if (resp.status === 401) {
    //             this.togglesession();
    //             localStorage.clear();
    //         }else if(resp.status === 200){
    //             return resp.json();
    //         }else if(resp.status === 403){
    //             return resp.json();
    //         }else if(resp.status === 404){
    //             return resp.json();
    //         }else if(resp.status === 500){
    //             return resp.json();
    //         }else if(resp.status === 400){
    //             return resp.json();
    //         }
    //     }).then(response =>{
    //         if(response.errorCode === 200){
    //             let resMsg =  response.message.split(":")
    //             let inviteMsg = resMsg[0]
    //             let groupName = resMsg[1]

    //             this.setState({
    //                 inviteMessage: this.props.t(inviteMsg),
    //                 groupName : groupName,
    //                 errorCode: response.errorCode
    //             });
    //         }else{
    //             this.setState({
    //                 inviteMessage: this.props.t(response.errorMessage),
    //                 errorCode: response.errorCode
    //             });
    //         }
    //     }).catch(error => console.error(error));
    // }
    componentDidMount(){
        this.verifyUser();
    }
    render(){
        const{t}=this.props
        return (
            <div className="login-page">
                <header>
                    {/* Navbar */}
                    {/* Intro Section */}
                    <section className="view intro-2">
                        <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                            <div className="container">
                                <form className="form-login" style={{width:"130%", marginLeft:"-14%"}}>
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-1">
                                            {/* Form with header */}
                                            <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                                                <div className="card-body br-10">
                                                    {/* Header */}
                                                    {/* Header */}
                                                    <div className="col-md-12 login_icon" style={{ marginLeft:'180px' }}>
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{ width: "138px" }} alt="" />
                                                    </div>
                                                    {/* Body */}
                                                    <div className='invite_msg'>{this.state.inviteMessage} {this.state.groupName ? ":" + this.state.groupName : this.state.groupName} <br />{t('please')} <a href='/' style={{cursor:'pointer', textDecoration:'underline'}}>{t('login')}</a> {t('to_the_portal')}</div>
                                                </div>
                                            </div>
                                            {/* Form with header */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    {/* Intro Section */}
                </header>
                <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                    <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
                    <MDBModalBody>
                       {t('please_login_again')}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
            </div>
        );
    }
}

export default withTranslation()(inviteUser);