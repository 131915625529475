import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';

class CustomDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isOpen: false,
      selectedItem : "",
    }
    this.wrapperRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selected, category){
    this.setState({selectedItem : selected, isOpen : false})
    this.props.onSelect(selected, category)
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleDropdown = () => {
   
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
   
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        isOpen: false,
      });
    }
  };
  

  render() {
    const { iconSrc,  headerText, options, category } = this.props;
    
    return (
      <div className="custom-filter-dropdown" ref={this.wrapperRef}>
        <div className="filter-header" onClick={this.toggleDropdown}>
          <button className="button">
            <img src={process.env.PUBLIC_URL + iconSrc} className={this.props.cssClass + " icon pr-2"} />
            <span className="filterName">{headerText}</span>
            <span className="caret-icon"></span>
          </button>
        </div>
       
         
          {
            (this.state.isOpen) && (
                <div className="dropdown-body d-flex flex-column">
                {
                     options.map((option, index) => (
                        <span className='dropdown-item' key={index} onClick={() => this.handleChange(option, category)}>{option}</span>
                    ))
                }
               
                </div>
            )
          }
        
      </div>
    );
  }
}

export default withTranslation()(CustomDropdown);
