import React from 'react';
import SchedulerComponent from '../components/schedulerComponent';

//i18n multilingual imports
import { withTranslation } from 'react-i18next';

class ReservationGraph extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <>
                <div className="d-flex" style={{ position: 'absolute', left: '200px' }}>
                    <span style={{ height: '25px', width: '25px', background: 'green', display: 'inline-block', marginRight: '10px' }}></span>
                    <div className="flex-column" style={{ width: '18%', position: 'relative', top: '-7px' }}>
                        <span>
                            {t('Slot_length_guaranted_slot_duration')}
                        </span>
                    </div>
                    <span style={{ height: '25px', width: '25px', background: 'yellow', display: 'inline-block', marginRight: '10px' }}></span>
                    <div className="flex-column" style={{ width: '18%', position: 'relative', top: '-7px' }}>
                        <span>
                        {t('Slot_length_shorter_guaranted_slot_duration')}
                        </span>
                    </div>
                    <span style={{ height: '25px', width: '25px', background: 'darkBlue', display: 'inline-block', marginRight: '10px' }}></span>
                    <div className="flex-column" style={{ width: '11%', position: 'relative', top: '3px' }}>
                        <span>
                        {t('reserved_slot')}
                        </span>
                    </div>
                    <span style={{ height: '25px', width: '25px', background: 'grey', display: 'inline-block', marginRight: '10px' }}></span>
                    <div className="flex-column" style={{ width: '20%', position: 'relative', top: '3px' }}>
                        <span>
                        {t('cooldown_slot')}
                        </span>
                    </div>
                </div>
                <button type="button" className="btn_primary" style={{ position: 'absolute', right: 40 }} onClick={() => {
                    this.props.resetGetChargersBasedOnEmailid();
                    this.props.resetSlotsBasedOnCpId();
                    this.props.resertemail();
                    this.props.setReservation('', '', '');
                    this.props.toggleAdd(this.props.groupId);
                }}>{t('add_reservation')}</button>
                <SchedulerComponent
                    reservationResponse={this.props.reservationResponse}
                    resertemail={this.props.resertemail}
                    setReservation={this.props.setReservation}
                    calenderResources={this.props.calenderResources}
                    calenderEvents={this.props.calenderEvents}
                    toggleOverview={this.props.toggleOverview}
                    getReservationSlots={this.props.getReservationSlots}
                    groupId={this.props.groupId}
                    toggleAdd={this.props.toggleAdd}
                    reserReservationId={this.props.reserReservationId}
                />
            </>
        )
    }
}

export default withTranslation()(ReservationGraph);