//React Imports
import React from 'react';
import { createBrowserHistory } from 'history';
//3rd Party Imports
import $ from "jquery";
import moment from 'moment';
import { withTranslation } from 'react-i18next';
//constants imports
import { hasPermission } from '../views/auth';

//API Imports
import * as baseUrl from '../views/constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

//Component Imports
import ChargePointOverview from '../Screens/ChargePointOverview';

const history = createBrowserHistory();

class chargePoint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paginationHide: false,
            onClickCheckBox: false,
            cpIdSorting: false,
            selectFindStatus: false,
            chargePoint: "",
            saveSelectedCpid: "",
            options: [],
            multiple: false,
            hideActionBtn: "none", // show hide btn varible based on permission 
            writePermission: this.props.ChargePoint_W, // ChargePoint_W conndition
            updatePermission: "", // ChargePoint_U conndition
            activePage: 1,
            active: "active",
            activeArray: [],
            checked: false,
            activeDeactiveArray: '',
            list: [],
            cpid1: '',
            filterData: [],
            dataArray: [],
            selectDataArray: [],
            noOfRecords: '',
            data: new Map(),
            intial_data: [],
            pageSize: "10",
            pageNo: 1,
            modalsession: false,
            hideUpdateActionBtn: "none",
            backendConnection: "",
            textdisplay: "",
            connectionoutTime: "10h",
            errors: {},
            lostBackendConnectionTime: "",
            isSitemanagementAvailable: false,
            modal1: false,
            modal2: false,
            modal3: false,
            modal4: false,
            saveRate: "",
            fwVersion: '',
            _backendConnection: false,
            selectedModel: '',
            onboardDate: '',
            onboardDate1: '',
            calenderOpen: 'none',
            export500: false,
            fromDateApi: '',
            toDateApi: '',
            curPageFirstCreTime: "",
            curPageLastCreTime: "",
            prevPageFirstCreTime: "",
            listOfFirstCreatedtimes: [],
            saveSno: "",
            message: "",
            successRate: false,
            modalLogout: false,
            openGateway: false,
            environment: '',
            gatewaySNO: '',
            environment: '',
            LoginId: '',
            transactionKey: '',
            confirmGatewayUpdate: false,
            successGateway: false,
            gatewaySuccessMessage: '',
            dxaccessEnable: false,
            accountId: "",
            isStripePaymentGatewayEnabled: false,
            isChargerOwner: false,
            user: {
                permissions: []
            },
            statusType: "all"
        }
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.getChargePoints = this.getChargePoints.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.activeChargePoint = this.activeChargePoint.bind(this);
        this.tagOk1 = this.tagOk1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.tagOk2 = this.tagOk2.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.bulkImport = this.bulkImport.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.chargepointIDChange = this.chargepointIDChange.bind(this);
        this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
        this.ClearChargepoint = this.ClearChargepoint.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.exportChargePoints = this.exportChargePoints.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.clearFWVersion = this.clearFWVersion.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.changeOnboardDate = this.changeOnboardDate.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.displayCalender = this.displayCalender.bind(this);
        this.closeCalender = this.closeCalender.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.clearModel = this.clearModel.bind(this);
        this.keyPressForFWVersion = this.keyPressForFWVersion.bind(this);
        this.keyPressForModel = this.keyPressForModel.bind(this);
        this.toggleexport500 = this.toggleexport500.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.createdDatesArray = this.createdDatesArray.bind(this);
        this.ascendingOrder = this.ascendingOrder.bind(this);
        this.getModelwithCP = this.getModelwithCP.bind(this);
        this.closeSuccess = this.closeSuccess.bind(this);
        this.updateRate = this.updateRate.bind(this);
        this.toggleUpdateSuccess = this.toggleUpdateSuccess.bind(this);
        this.toggleLogout = this.toggleLogout.bind(this);
        this.CloseLogOutArchive = this.CloseLogOutArchive.bind(this);
        this.onchangeInput = this.onchangeInput.bind(this);
        this.toggleGatewayUpdate = this.toggleGatewayUpdate.bind(this);
        this.getGatewayDetails = this.getGatewayDetails.bind(this);
        this.toggleGateway = this.toggleGateway.bind(this);
        this.closeConfirmGateway = this.closeConfirmGateway.bind(this);
        this.updateGateway = this.updateGateway.bind(this);
        this.closeGatewaySuccess = this.closeGatewaySuccess.bind(this);
        this.ascendingOrder = this.ascendingOrder.bind(this);
        this.toggleDxaccessEnable = this.toggleDxaccessEnable.bind(this);
        this.numberHandler = this.numberHandler.bind(this);
        this.toggleGatewaySuccess = this.toggleGatewaySuccess.bind(this);
        this.checkIsDXModel = this.checkIsDXModel.bind(this);
        this.statusChange = this.statusChange.bind(this);
    }

    statusChange(e) {
            this.setState({
                statusType: e.target.value,
                activePage: 1,
                fromDateApi: '',
                toDateApi: ''
            }, () => {
                if (this.state.saveSelectedCpid) {
                    let sno = this.state.saveSelectedCpid;
                    this.getChargePoints(sno, true, "");
                } else {
                    this.getChargePoints("", true, "");
                }
            })            
    }
    
    toggleDxaccessEnable() {
        this.setState({ dxaccessEnable: !this.state.dxaccessEnable })
    }

    onchangeInput(field, e) {
        e.preventDefault();
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({ fields: fields });
    }

    CloseLogOutArchive = () => {
        this.setState({
            modalLogout: !this.state.modalLogout
        });
    }

    toggleLogout() {
        this.setState({
            modalLogout: !this.state.modalLogout,
            modal4: !this.state.modal4
        });
    }

    toggleUpdateSuccess() {
        this.setState({
            successRate: !this.state.successRate
        });
    }

    updateRate() {
        this.setState({
            modalLogout: !this.state.modalLogout
        });
        var rate = this.state.saveRate;
        var getSno = this.state.saveSno;
        let errors = {};
        var url = baseUrl.WebPath + "chargers/rate";

        let data = { "chargePointId": getSno, "rate": rate, "dxaccessEnable": this.state.dxaccessEnable };
        fetch(url, {
            method: 'PUT', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem('Authorization')
            }
        }).then((resp) => {
            if (resp.status == 200) {
                this.setState({ modal4: false, message: "Rate Updated Successfully" })
                this.toggleUpdateSuccess();
            }
            else if (resp.status == 400) {
            }
            else if (resp.status == 403) {
            }
            else if (resp.status == 401) {
                this.toggle(true)
                localStorage.clear();
            } else if (resp.status == 404) {
                return resp.json();
            } else {
                return resp.json();
            }
        }).then((response) => {
            this.setState({ errors: errors })
        })
            .catch(error => console.log('Error:' + error));

        this.setState({ errors: errors });
    }

    closeSuccess() {
        window.location.reload();
    }
    
    toggleGatewayUpdate() {
        let fields = this.state;
        let errors = this.state.errors;

        if (fields.isStripePaymentGatewayEnabled === true) {
            if (fields.accountId == "") {
                errors["selectAccountId"] = "Please enter account Id";
            } else {
                errors["selectAccountId"] = "";
            }
        }

        if (fields.isStripePaymentGatewayEnabled === false) {
            if (fields.LoginId == "") {
                errors["selectLoginId"] = "Please enter Login Id";
            } else {
                errors["selectLoginId"] = "";
            }

            if (fields.transactionKey == "") {
                errors["selectTransactionKey"] = "Please enter transaction key"
            } else {
                errors["selectTransactionKey"] = "";
            }
        }

        if (fields.environment == "") {
            errors["selectEnvironment"] = "Please select Environmnt";
        } else {
            errors["selectEnvironment"] = "";
        }

        this.setState({
            errors
        });

        if ((fields.isStripePaymentGatewayEnabled && fields.environment && fields.accountId) || (!fields.isStripePaymentGatewayEnabled && fields.environment && fields.transactionKey && fields.LoginId)) {
            this.setState({
                confirmGatewayUpdate: true,
                openGateway: false
            });
        }
    }

    getGatewayDetails(serialNumber) {
        var url = baseUrl.URLPath + "charger/gateway/" + serialNumber;

        fetch(url, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem('Authorization')
            }
        }).then((resp) => {
            if (resp.status == 200) {
                return resp.json();
            } else if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            } else {
                return resp.json();
            }
        }).then((response) => {
            if (response != undefined) {
                let fields = this.state;
                fields.LoginId = response?.loginId ?? '';
                fields.environment = response?.environment ?? '';
                fields.transactionKey = response?.transactionKey ?? '';
                fields.gatewaySNO = serialNumber;
                fields.accountId = response?.accountId;
                fields.isStripePaymentGatewayEnabled = response?.isStripePaymentGatewayEnabled;

                this.setState({
                    ...this.state,
                    fields
                }, () => {
                    this.toggleGateway(serialNumber);
                });
            }
        }).catch(error => console.log('Error:' + error));
    }

    toggleGateway(serial_number) {
        this.setState({
            openGateway: !this.state.openGateway,
            gatewaySNO: serial_number,
            errors: {}
        });
    }
    updateGateway() {
        var url = baseUrl.URLPath + "charger/gateway";

        let payload = {
            "accountId": this.state.accountId,
            "environment": this.state.environment,
            "isStripePaymentGatewayEnabled": this.state.isStripePaymentGatewayEnabled,
            "loginId": this.state.LoginId,
            "serialNo": this.state.gatewaySNO,
            "transactionKey": this.state.transactionKey
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem('Authorization')
            }
        }).then((resp) => {
            if (resp.status == 200) {
                return resp.json();
            } else if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            } else {
                return resp.json();
            }
        }).then((response) => {
            if (response != undefined) {
                this.setState({
                    gatewaySuccessMessage: 'Gateway Details Updated Successfully'
                }, () => {
                    this.toggleGatewaySuccess();
                })
            }
        }).catch(error => console.log('Error:' + error));

    }

    toggleGatewaySuccess = () => {
        this.setState({
          successGateway: !this.state.successGateway,
          confirmGatewayUpdate: false
        });
      }

    closeConfirmGateway = () => {
        this.setState({
            confirmGatewayUpdate: false
        });
    }

    closeGatewaySuccess() {
        this.setState({
            successGateway: !this.state.successGateway,
            confirmGatewayUpdate: false
        })
    }

    ClearChargepoint(ref, event) {
        if (this.state.saveSelectedCpid !== "") {
            let state = this.state;
            state.activeArray = [];
            state.saveSelectedCpid = "";
            let errors = state.errors;
            if (errors["chargePointNotExists"] !== "") {
                errors["chargePointNotExists"] = "";
                errors["selectChargepoint"] = "";
            } else {
                state.activePage = 1;
                state.pageNo = 1;
                state.fromDateApi = '';
                state.toDateApi = '';
                errors["chargePointNotExists"] = "";
                errors["selectChargepoint"] = "";
            }
            this.setState({
                ...this.state,
                ...state,
                ...errors
            }, () => {
                this.getChargePoints();
            });
            ref.current.clear();
        } else {
            let errors = this.state.errors;
            errors["chargePointNotExists"] = "";
            errors["selectChargepoint"] = "";
            this.setState({
                errors: errors
            })
            ref.current.clear();
        }
    }

    chargepointIDChange(cpid) {
        this.setState({ saveSelectedCpid: cpid }, () => {
            if (cpid.length === 0) {
                this.getChargePoints(cpid, true, "no");
            } else {
                let errors = {};
                errors["chargePointNotExists"] = "";
                errors["selectChargepoint"] = "";
                this.setState({
                    errors: errors
                }, () => {
                    this.getChargePoints(cpid, true, "no");
                });
            }
        })
    }

    async getInventorybasedonchargepoint(pageSize, pageNo) {
        var errors = {};
        if (this.state.saveSelectedCpid === null || this.state.saveSelectedCpid === "") {
            errors["selectChargepoint"] = this.props.t('enter_chargepoint_id');
            this.setState({
                errors: errors
            });
        } else {
            var url = baseUrl.URLPath + "chargepoints/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;
            let type = ApiMethodTypes.GET;
            let headers = getSecurityHeaders();
            let payload = {};

            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

            if (response.status === 404) {
                errors["chargePointNotExists"] = this.props.t('chargepoint_not_exists');
                this.setState({
                    errors: errors
                });
            } else if (response !== undefined) {
                if (response.count.count === 0) {
                    errors["chargePointNotExists"] = this.props.t('chargepoint_not_exists');
                    this.setState({
                        errors: errors
                    });
                    $("#nochargeexists").show();
                } else {
                    $("#nochargeexists").hide();
                    this.setState({
                        selectDataArray: response.cpList,
                        noOfRecords: response.count.count,
                        activeArray: []
                    });
                }
            }
        }
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        history.push('/');
        window.location.reload();
    }

    createdDatesArray(dateTime) {
        let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
        this.setState({
            listOfFirstCreatedtimes: listOfFirstCreatedtimes
        });
    }

    handlePageChange(pageNumber) {
        if (pageNumber < this.state.activePage) {
            //prev
            if (pageNumber === 1) {
                this.setState({
                    activePage: pageNumber,
                    pageNo: pageNumber,
                    fromDateApi: '',
                    toDateApi: ''
                }, () => {
                    this.getChargePoints("", false, "");
                });
            } else {
                this.setState({
                    activePage: pageNumber,
                    pageNo: pageNumber,
                    fromDateApi: this.state.curPageFirstCreTime,
                    toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
                }, () => {
                    this.getChargePoints("", false, "");
                });
            }
        } else {
            //next
            this.setState({
                activePage: pageNumber,
                pageNo: pageNumber,
                fromDateApi: '',
                toDateApi: this.state.curPageLastCreTime,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
            }, () => {
                let listOfFirstCreatedtimes = this.state.listOfFirstCreatedtimes;
                listOfFirstCreatedtimes[pageNumber - 2] = this.state.curPageFirstCreTime;
                this.setState({
                    listOfFirstCreatedtimes: listOfFirstCreatedtimes
                }, () => {
                    this.getChargePoints("", false, "");
                });
            });
        }
    }

    bulkImport() {
        this.props.history.push('/bulkFCPImport')
    }

    toggle1() {
        this.setState({
            modal1: !this.state.modal1
        });
    }

    toggle2 = () => {
        this.setState({
            modal2: !this.state.modal2
        });
    }

    toggle3 = () => {
        this.setState({
            modal3: !this.state.modal3
        });
    }

    tagOk1() {
        // this.props.history.push('/chargepoint');
        window.location.reload();
    }

    tagOk2() {
        //  this.props.history.push('/chargepoint');
        window.location.reload();
    }

    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;
        this.setState({
            activePage: 1,
            pageSize: entries,
            fromDateApi: '',
            toDateApi: ''
        }, () => {
            this.getChargePoints("", false, "");
        })
    }

    async getChargePoints(sno, countValue, dates, type) {
        document.getElementById("loader_image_div").style.display = "block";
        let fromTime = this.state.fromDateApi;
        let toTime = this.state.toDateApi;
        let pageNo = this.state.pageNo;
        let status = this.state.statusType;

        if (dates === "no") {
            fromTime = '';
            toTime = '';
        }

        if (type === "search") {
            pageNo = 1;
        }

        let cpId = '';
        if (sno !== undefined) {
            cpId = sno
        }

        var url = baseUrl.URLPath + "chargepoints/search?pageSize=" + this.state.pageSize + "&pageNo=" + pageNo + "&chargePointId=" + cpId + "&fwVersion=" + this.state.fwVersion + "&backendConnection=" + this.state._backendConnection + "&model=" + this.state.selectedModel + "&onboardDate=" + this.state.onboardDate1 + "&fromTime=" + fromTime + "&toTime=" + toTime + "&status=" + status;
        let types = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, types, payload, headers, this.props.t, true);

        if (response !== undefined) {
            if (response.count.count === 0) {
                let errors = {};
                if (cpId !== '') {
                    errors["chargePointNotExists"] = this.props.t('chargepoint_not_exists');
                }
                if (this.state.fwVersion !== '') {
                    $('.fwVersion_noexists').text(this.props.t('fwversion_not_exists'));
                    this.setState({
                        dataArray: [],
                        noOfRecords: 0,
                        selectDataArray: []
                    })
                }
                if (this.state.selectedModel !== "") {
                    $('.selectedModel_noexists').text(this.props.t('modal_not_exists'));
                    this.setState({
                        dataArray: [],
                        noOfRecords: 0,
                        selectDataArray: []
                    })
                }
                if (this.state._backendConnection !== false) {
                    this.setState({
                        dataArray: [],
                        noOfRecords: 0,
                        selectDataArray: []
                    })
                }
                if (this.state.onboardDate1 !== '') {
                    this.setState({
                        dataArray: [],
                        noOfRecords: 0,
                        selectDataArray: []
                    })
                }
                if (this.state.statusType !== "") {
                    this.setState({
                        dataArray: [],
                        noOfRecords: 0,
                        selectDataArray: []
                    })
                }
                setTimeout(() => {
                    this.setState({
                        errors: errors
                    }, () => {
                        document.getElementById("loader_image_div").style.display = "none";
                    });
                }, 100);
            } else {
                let hide = false
                if (type === "search") {
                    hide = true
                } else {
                    hide = false
                }
                if (countValue === false) {
                    setTimeout(() => {
                        this.setState({
                            dataArray: response.cpList,
                            selectDataArray: response.cpList,
                            paginationHide: hide,
                            prevPageFirstCreTime: this.state.curPageFirstCreTime,
                            curPageFirstCreTime: moment(response.cpList[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                            curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                        }, () => {
                            document.getElementById("loader_image_div").style.display = "none";
                        });
                    }, 100);
                } else {
                    setTimeout(() => {
                        this.setState({
                            dataArray: response.cpList,
                            selectDataArray: response.cpList,
                            noOfRecords: response.count.count,
                            paginationHide: hide,
                            prevPageFirstCreTime: this.state.curPageFirstCreTime,
                            curPageFirstCreTime: moment(response.cpList[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                            curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                        }, () => {
                            document.getElementById("loader_image_div").style.display = "none";
                        });
                    }, 100);
                }
            }
        }
    }

    UNSAFE_componentWillMount() {
        var user = {
            permissions: []
        }
        user.permissions = localStorage.getItem("roleAccess");
        this.setState({
            user: user
        })
    }

    componentDidMount() {
        var user = {
            permissions: []
        }
        user.permissions = localStorage.getItem("roleAccess");
        let update_permission = hasPermission(user, ['updatePermission']);

        this.setState({
            updatePermission: update_permission,
            hideUpdateActionBtn: update_permission === true ? "inline-block" : this.state.hideUpdateActionBtn,
            hideActionBtn: this.state.writePermission === true ? "inline-block" : this.state.hideActionBtn
        }, () => {
            this.getChargePoints();
        });

        if (this.props.customizationSettings && Array.isArray(this.props.customizationSettings)) {
            const siteManagementObj = this.props.customizationSettings.find(el => el.operation === "Group Management");
            if (siteManagementObj) {
                this.setState({ ...this.state, isSitemanagementAvailable: siteManagementObj.active });
            } else {
                this.setState({ ...this.state, isSitemanagementAvailable: false });
            }
        }
    }
    
    checkIsDXModel(cpmodel) {
        let model_name = cpmodel;
        let position = model_name?.search(/(?:DX|FC)/i);
    
        if (model_name && position > -1) {
          return true;
        } else {
          return false;
        }
    }

    //  function after clicking on checkbox to stored in an array 
    handleChange(id, cpid, event) {
        var checkedValue = document.getElementById(id).checked;
        if (checkedValue === true) {
            this.state.list.push(cpid);
            this.setState({
                activeArray: this.state.list,
                onClickCheckBox: true
            });
        } else {
            const index = this.state.list.indexOf(cpid);
            if (index > -1) {
                this.state.list.splice(index, 1);
            }
            this.setState({
                activeArray: this.state.list
            });
        }
    }

    //  function after clicking on active and deactive button
    async activeChargePoint(status) {
        if (this.state.activeArray.length !== 0) {
            var url = baseUrl.URLPath + "chargepoints/bulkactivate";
            let types = ApiMethodTypes.PUT;
            let headers = getSecurityHeaders();
            let payload = { "id": this.state.activeArray, "active": status };

            await APICallUtility.cpmsAPIFetch(url, types, payload, headers, this.props.t);

            if (status === 0) {
                this.toggle1()

            } else {
                this.toggle2()
            }
        } else if (this.state.activeArray.length === 0) {
            this.toggle3()
        }
    }

    // search filter 
    handleKeyDown(ref, e) {
        var serial_no = e.target.value;
        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
        var sno = serial_no.slice(0, serial_no.length - 1);

        if (serial_no.length > 1) {
            $("#selectcharge").hide();
        } else {
            $("#selectcharge").show();
        }

        let errors = this.state.errors;
        errors["selectChargepoint"] = "";
        errors["chargePointNotExists"] = "";

        this.setState({
            errors: errors
        });

        if (key === "Backspace" || key === "Delete") {
            if (serial_no.length > 0) {
                if (sno === "") {
                    this.setState({ saveSelectedCpid: sno }, () => {
                        this.getChargePoints("", true, "")
                    });
                }
            }
        } else if (e.keyCode === 13) {
            $("#selectcharge").hide();
            this.FindCpName(ref, e);
            $("#rbt_id").hide();
        } else if ((e.keyCode === 86 && ctrl)) {
            $("#selectcharge").hide();
            this.getSearchInventoryList(serial_no)
        } else {
            this.getSearchInventoryList(sno)
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9 -]");
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    //function for sorting table data on click
    ascendingOrder() {
        if (this.state.cpIdSorting === false) {
            this.setState({ selectDataArray: this.state.selectDataArray.sort(this.dynamicSort("chargePointId")), cpIdSorting: true })
        } else {
            let selectDataArray = this.state.selectDataArray;
            selectDataArray = selectDataArray.sort(this.dynamicSort("chargePointId"));

            this.setState({ selectDataArray: selectDataArray.reverse(), cpIdSorting: false });
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;

        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a, b) {
            if (sortOrder === -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        }
    }

    async getSearchInventoryList(cpid) {
        var url = baseUrl.URLPath + servicesUrl.getChargepointsPartilaSearch + "?cpid=" + cpid;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if (response !== undefined) {
            this.setState({
                options: response,
                intial_data: response
            });
        }
    }

    FindCpName(ref, event) {
        event.preventDefault();
        let saveSelectedCpid = ref.current.inputNode.value;
        var errors = {};
        if (saveSelectedCpid === null || saveSelectedCpid === "") {
            errors["selectChargepoint"] = this.props.t('enter_chargepoint_id');
            this.setState({
                errors: errors,
                saveSelectedCpid: saveSelectedCpid
            });
        } else {
            errors["selectChargepoint"] = "";
            this.setState({
                errors: errors,
                saveSelectedCpid: saveSelectedCpid
            }, () => {
                this.getChargePoints(this.state.saveSelectedCpid, true, "no", 'search');
            });
        }

        var serial_no = event.target.value;
        if (serial_no.length > 1) {
            $("#selectcharge").hide();
        } else {
            $("#selectcharge").show();
        }
    }

    //export chargepoints data by Lakshmikanth
    async exportChargePoints() {        
        let status = this.state.statusType;
        let timeZone =  moment.tz.guess();
        let url = baseUrl.URLPath + "chargepoints/download/xls?chargePointId=" + this.state.saveSelectedCpid + "&fwVersion=" + this.state.fwVersion + "&backendConnection=" + this.state._backendConnection + "&model=" + this.state.selectedModel + "&onboardDate=" + this.state.onboardDate  + "&timeZone=" + timeZone + "&status=" + status;
        document.getElementById("loader_image_div").style.display = "block";
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                if (resp.status === 401) {
                    setTimeout(() => {
                        document.getElementById("loader_image_div").style.display = "none";
                        window.location.reload();
                    }, 100);
                } else if (resp.status === 200) {
                    return resp.blob();
                } else if (resp.status === 500) {
                    this.toggleexport500();
                }
            })
            .then((response) => {
                setTimeout(() => {
                    if (response !== undefined) {
                        let d = new Date();
                        let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                        let url = window.URL.createObjectURL(response);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'ChargePoints' + dformat + '.xls';
                        document.getElementById("loader_image_div").style.display = "none";
                        a.click();
                    }
                }, 100);
            })
            .catch((error) => {
                setTimeout(() => {
                    document.getElementById("loader_image_div").style.display = "none";
                    console.error(error, "charpoints exports functionality failed")
                }, 100);
            })
    }

    getModelwithCP() {
        if (this.state.saveSelectedCpid) {
            let sno = this.state.saveSelectedCpid;
            this.getChargePoints(sno, true, "");
        } else {
            this.getChargePoints("", true, "");
        }
    }

    changeHandler(field, e) {
        if (field === 'fwVersion') {
            if (e.target.validity.valid) {
                this.setState({ [field]: e.target.value }, () => {
                    if (this.state.fwVersion.length === 0) {
                        this.getChargePoints("", true, "")
                    }
                });
                $('.fwVersion_noexists').text("");
            }

        } else if (field === 'selectedModel') {
            this.setState({ [field]: e.target.value }, () => {
                if (this.state.selectedModel.length === 0) {
                    this.getChargePoints("", true, "")
                }
            });
            $('.selectedModel_noexists').text("");
        }
    }

    clearFWVersion() {
        if (this.state.fwVersion !== "") {
            let state = this.state;
            state.activeArray = [];
            if ($('.fwVersion_noexists').text() !== "") {
                $('.fwVersion_noexists').text('')
            } else {
                state.activePage = 1;
                state.pageNo = 1;
                state.fromDateApi = '';
                state.toDateApi = '';
                $('.fwVersion_noexists').text('')
            }
            this.setState({
                ...state,
                fwVersion: ''
            }, () => {
                this.getChargePoints("", true, "");
            });
        } else {
            $('.fwVersion_noexists').text('')
        }
    }

    clearModel() {
        if (this.state.selectedModel !== "") {
            let state = this.state;
            if ($('.selectedModel_noexists').text() !== "") {
                $('.selectedModel_noexists').text('')
            } else {
                state.activePage = 1;
                state.pageNo = 1;
                state.fromDateApi = '';
                state.toDateApi = '';
                $('.selectedModel_noexists').text('')
            }

            this.setState({
                ...state,
                selectedModel: '',
                activeArray: []
            }, () => {
                this.getChargePoints("", true, "");
            });
        } else {
            $('.selectedModel_noexists').text('')
        }
    }

    handleChecked() {
        this.setState({
            _backendConnection: !this.state._backendConnection,
            activePage: 1,
            fromDateApi: '',
            toDateApi: ''
        }, () => {
            this.getChargePoints("", true, "")
        })
    }

    changeOnboardDate(e) {
        this.setState({ onboardDate: e.target.value });
    }

    keyPress(e) {
        var key = e.key;
        let date = e.target.value;

        $('.future_err').text("");
        if (e.keyCode === 13) {
            if (new Date() >= new Date(e.target.value)) {
                this.setState({ onboardDate: e.target.value, onboardDate1: moment(e.target.value).format("YYYY-MM-DD") }, () => {
                    this.getChargePoints("", true, "");
                });
            } else {
                this.setState({ onboardDate: "", onboardDate1: "" });
                $('.future_err').text(this.props.t('cannot_select_futuredate'));
            }
        }
        if (key === "Delete" || key === "Backspace") {
            if (date.length === 1) {
                this.setState({ onboardDate1: "" }, () => {
                    this.getChargePoints("", true, "");
                });
            }
        }
    }

    displayCalender() {
        this.setState({
            calenderOpen: "block"
        });
    }

    closeCalender() {
        this.setState({
            calenderOpen: "none"
        });
    }

    selectDate = date => {
        var fdate = this.formatCalDate1(date);
        this.setState({ onboardDate: fdate, onboardDate1: this.formatCalDate(date), calenderOpen: "none" }, () => {
            this.getChargePoints("", true, "");
        });
    };

    formatCalDate1(date) {
        var format = date.toISOString();
        var xyz = moment(format).format("DD MMM YYYY")
        return xyz;
    }

    formatCalDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        // return [year, month, day].join('-');
        return [year, month, day].join('-');
    }

    keyPressForFWVersion(e) {
        var key = e.key;

        if (e.keyCode === 13) {
            if (this.state.fwVersion === "" || this.state.fwVersion === undefined || this.state.fwVersion === null) {
                $('.fwVersion_noexists').text(this.props.t('enter_fwversion'));
            } else {
                this.setState({ fwVersion: e.target.value }, () => {
                    this.getChargePoints("", true, "");
                });
            }
        }
        if (key === "Backspace" || key === "Delete") {
            var x = e.target.value;
            var delitem = x.slice(0, x.length - 1);
            if (delitem === "") {
                this.setState({ fwVersion: delitem }, () => {
                    this.getChargePoints("", true, "");
                })
            }
        }
    }

    keyPressForModel(e) {
        var key = e.key;

        if (e.keyCode === 13) {
            if (this.state.selectedModel === "" || this.state.selectedModel === undefined || this.state.selectedModel === null) {
                $('.selectedModel_noexists').text(this.props.t('enter_model_name'));
            } else {
                this.setState({ selectedModel: e.target.value }, () => {
                    this.getChargePoints("", true, "");
                });
            }
        }
        if (key === "Backspace" || key === "Delete") {
            var x = e.target.value;
            var delitem = x.slice(0, x.length - 1);
            if (delitem === "") {
                this.setState({ selectedModel: delitem }, () => {
                    this.getChargePoints("", true, "");
                });
            }
        }
    }

    toggleexport500() {
        this.setState({
            export500: !this.state.export500
        });
    }

    toggleEdit(rate, serial_number, dxaccess) {
        this.state.saveRate = rate;
        this.setState({
            modal4: !this.state.modal4,
            saveSno: serial_number,
            dxaccessEnable: dxaccess
        });
    }
    //This is for making user should enter only numbers
    numberHandler(field, e) {
        e.preventDefault();
        let fields = this.state;
        if (e.target.validity.valid) {
            fields[field] = e.target.value;
            this.setState({ fields: fields });
        } else if (e.target.value == "") {
            fields[field] = e.target.value;
            this.setState({ fields: fields });
        }
    }

    render() {
        return (
            <>
                <ChargePointOverview
                    selectDataArray={this.state.selectDataArray}
                    handleChange={this.handleChange}
                    isSitemanagementAvailable={this.state.isSitemanagementAvailable}
                    hideActionBtn={this.state.writePermission}
                    activeChargePoint={this.activeChargePoint}
                    bulkImport={this.bulkImport}
                    multiple={this.state.multiple}
                    options={this.state.options}
                    handleKeyDown={this.handleKeyDown}
                    chargepointIDChange={this.chargepointIDChange}
                    ClearChargepoint={this.ClearChargepoint}
                    FindCpName={this.FindCpName}
                    errors={this.state.errors}
                    ascendingOrder={this.ascendingOrder}
                    showEntriesChange={this.showEntriesChange}
                    pageSize={this.state.pageSize}
                    activePage={this.state.activePage}
                    noOfRecords={this.state.noOfRecords}
                    handlePageChange={this.handlePageChange}
                    modal1={this.state.modal1}
                    toggle1={this.toggle1}
                    tagOk1={this.tagOk1}
                    modal2={this.state.modal2}
                    toggle2={this.toggle2}
                    tagOk2={this.tagOk2}
                    modal3={this.state.modal3}
                    toggle3={this.toggle3}
                    modalsession={this.state.modalsession}
                    togglesession={this.togglesession}
                    sessiontagOk={this.sessiontagOk}
                    exportChargePoints={this.exportChargePoints}
                    fwVersion={this.state.fwVersion}
                    changeHandler={this.changeHandler}
                    getModelwithCP={this.getModelwithCP}
                    clearFWVersion={this.clearFWVersion}
                    getChargePoints={this.getChargePoints}
                    _backendConnection={this.state._backendConnection}
                    handleChecked={this.handleChecked}
                    onboardDate={this.state.onboardDate}
                    onboardDate1={this.state.onboardDate1}
                    changeOnboardDate={this.changeOnboardDate}
                    keyPress={this.keyPress}
                    displayCalender={this.displayCalender}
                    closeCalender={this.closeCalender}
                    calenderOpen={this.state.calenderOpen}
                    selectDate={this.selectDate}
                    selectedModel={this.state.selectedModel}
                    clearModel={this.clearModel}
                    keyPressForFWVersion={this.keyPressForFWVersion}
                    keyPressForModel={this.keyPressForModel}
                    export500={this.state.export500}
                    toggleexport500={this.toggleexport500}
                    toggleEdit={this.toggleEdit}
                    modal4={this.state.modal4}
                    paginationHide={this.state.paginationHide}
                    user={this.state.user}
                    dxaccessEnable={this.state.dxaccessEnable}
                    toggleDxaccessEnable={this.toggleDxaccessEnable}
                    saveRate={this.state.saveRate}
                    numberHandler={this.numberHandler}
                    toggleLogout={this.toggleLogout}
                    closeSuccess={this.closeSuccess}
                    updateRate={this.updateRate}
                    message={this.state.message}
                    modalLogout={this.state.modalLogout}
                    successRate={this.state.successRate}
                    toggleUpdateSuccess={this.toggleUpdateSuccess}
                    CloseLogOutArchive={this.CloseLogOutArchive}
                    openGateway={this.state.openGateway}
                    environment={this.state.environment}
                    onchangeInput={this.onchangeInput}
                    getGatewayDetails={this.getGatewayDetails}
                    toggleGateway={this.toggleGateway}
                    toggleGatewayUpdate={this.toggleGatewayUpdate}
                    transactionKey={this.state.transactionKey}
                    LoginId={this.state.LoginId}
                    accountId={this.state.accountId}
                    isStripePaymentGatewayEnabled={this.state.isStripePaymentGatewayEnabled}
                    closeConfirmGateway={this.closeConfirmGateway}
                    confirmGatewayUpdate={this.state.confirmGatewayUpdate}
                    updateGateway={this.updateGateway}
                    successGateway={this.state.successGateway}
                    closeGatewaySuccess={this.closeGatewaySuccess}
                    gatewaySuccessMessage={this.state.gatewaySuccessMessage}
                    checkIsDXModel={this.checkIsDXModel}
                    statusChange={this.statusChange}
                />

                {/* Loader */}
                <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>
            </>
        );
    }
}

export default withTranslation()(chargePoint);
