import React from 'react';
import { Link } from 'react-router-dom';
import { MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import Pagination from "react-js-pagination";
import { withTranslation } from 'react-i18next';
import Loader from '../views/loader';

import CustomModal from '../common/CustomModal';

class ConnectorStatusTemplate extends React.Component {
    render() {
        const { t } = this.props
        // const ref = React.createRef();

        const data = this.props.QRCodeMangData?.map((dataArray, index) => {
            return (
                <tr>
                    <td>
                        <img src={dataArray.imageUrl} alt="qrImg" style={{ maxHeight: '50px', marginTop: '4px' }} />
                    </td>
                    <td className="wrap-word">
                        <div className="d-flex flex-row mt-2">
                            <label className='pr-3'>{t('id')}: { dataArray.qrId}</label>
                            <label>{t('group')}: {dataArray.siteName}</label>
                        </div>
                        <div className="d-flex flex-row">
                            <label>{t('used_authorizationtag')}: {dataArray.authorizationTag}</label>
                        </div>
                    </td>
                    <td>

                    </td>
                    <td className='talign-right'>
                        {this.props.qrCodeStatus ? <button className="btn_primary  talign-right mr-3" type="button" onClick={this.props.exportQRCode.bind(this, dataArray.id)} > {t('export')} </button> : ""}
                        <div className="d-flex  width_auto">
                            {dataArray.status ? 
                               dataArray.expired? "": <button className="btn_secondary mr-3 mb-1 " type="button" onClick={this.props.changeActivateDeactivateStatus.bind(this, dataArray.id, false)} >  {t('deactivate_btn')} </button>
                               :
                               dataArray.expired? "": <button className="btn_secondary mr-3 mb-1 " type="button" onClick={this.props.changeActivateDeactivateStatus.bind(this, dataArray.id, true)}>  {t('activate_btn')} </button>
                            }
                            <button className="btn_primary mr-3" type="button">
                                <Link className='uline-none' to={{ pathname: '/viewTag', state: dataArray.authorizationTagId }}> {t('limitations')}</Link>
                            </button>
                        </div>
                    </td>
                </tr>
            )
        });

        return (
            <main className="content-div" >
                <p>{t('qrcode_management')}
                    <div className="breadcrumb_div">
                        {/* {t('charge_point')} &gt; <span className="breadcrumb_page">{t('Connectors')}</span> */}
                    </div>
                </p>
                <div className="page-outerdiv">
                    <div className="row mt-2">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-5">
                                    <div class="input-group">
                                        <i className="fas fa-search fa-sm input-searchIcon"></i>
                                        <input type="text" id="qrId" onKeyDown={this.props.keyPress.bind(this)} onChange={this.props.changeHandler.bind(this)} class="form-control input-searchBox pad-2" placeholder={t('enter_qr')} />

                                        <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.props.handleClick}>
                                            <span aria-hidden="true">x</span>
                                        </button>
                                        <div class="input-group-btn">
                                            <button class="input-searchBtn find-btn" type="submit" onClick={this.props.findQRcodeId} >{t('find')}</button>
                                        </div>
                                    </div>
                                    <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.props.errors["enterQR"]}</span></span>
                                    <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}> 
                                     {this.props.isSearchNotFound && t('qrcode_not_exists') }
                                     </span>
                                    <span className="error_msg w3-animate-top mt-2 noexists" style={{ color: "red" }}></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 pull-right t-8">
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <div className="pull-right">
                                        <label className="sub-text pull-left">{t('show_entries')}</label>
                                        <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                            <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.props.showEntriesChange} value={this.props.pageSize}>
                                                <option>10</option>
                                                <option>20</option>
                                                <option>30</option>
                                                <option>40</option>
                                                <option>50</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* active tab */}
                    <MDBNav className="mt-4 p-rel border-0 mb-4">
                        <MDBNavItem className="nav-first">
                            <MDBNavLink to="#" className={`b-75 ${this.props.activeItem === "1" ? "activetab" : ""}`} onClick={this.props.tabToggle.bind(this, "1", true)} role="tab" >
                                {t('active')}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem className="nav-last">
                            <MDBNavLink to="#" className={`b-75 ${this.props.activeItem === "2" ? "activetab" : ""}`} onClick={this.props.tabToggle.bind(this, "2", false)} role="tab" >
                                {t('inactive')}
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav>
                    {/* active tab */}

                    <MDBTabContent activeItem={this.props.activeItem} className="pt-2 p-rel border-0 pl-0 pr-0" >
                        {/* details */}
                        <MDBTabPane tabId="1" role="tabpanel">
                            <div className="col-md-12">
                                <Table striped hover size="sm" className="page-table">
                                    <tbody>
                                        {data}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="col-md-12 mt-4">
                                <Pagination
                                    // hideDisabled
                                    activePage={this.props.activePage}
                                    itemsCountPerPage={this.props.pageSize}
                                    totalItemsCount={this.props.noOfRecords}
                                    onChange={this.props.handlePageChange}
                                    prevPageText={t('prev')}
                                    nextPageText={t('next')}
                                    itemClassPrev="prevBtn"
                                    pageRangeDisplayed="1"
                                    activeClass="activeLi active"
                                    itemClassNext="nextBtn"
                                    hideFirstLastPages={true}
                                    disableInitialCallback={true}
                                    disabledClass="disabled disabledtextColor"
                                />
                            </div>
                        </MDBTabPane>
                        <MDBTabPane tabId="2" role="tabpanel">
                            <div className="col-md-12">
                                <Table striped hover size="sm" className="page-table">
                                    <tbody>
                                        {data}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="col-md-12 mt-4">
                                <Pagination
                                    // hideDisabled
                                    activePage={this.props.activePage}
                                    itemsCountPerPage={this.props.pageSize}
                                    totalItemsCount={this.props.noOfRecords}
                                    onChange={this.props.handlePageChange}
                                    prevPageText={t('prev')}
                                    nextPageText={t('next')}
                                    itemClassPrev="prevBtn"
                                    pageRangeDisplayed="1"
                                    activeClass="activeLi active"
                                    itemClassNext="nextBtn"
                                    hideFirstLastPages={true}
                                    disableInitialCallback={true}
                                    disabledClass="disabled disabledtextColor"
                                />
                            </div>
                        </MDBTabPane>
                    </MDBTabContent>

                </div>

                   {/* success popup  */}
                    <CustomModal
                    open={this.props.modal3}
                    toggle={this.props.toggle3}
                    title={t('success')}
                    bodyMessage={t('qr_deactive_succ_msg')}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.toggle3}
                    />

                {/* warning popup for qr code active deactivate */}
                <CustomModal
                    open={this.props.DeactModel}
                    title={t('confirmation')}
                    bodyMessage={t('qr_deactive_msg')}
                    PrimaryButtonText={t('close')}
                    PrimaryButtonOperation={this.props.DeactivateButton}
                    AdditionalButtonText={t('yes')}
                    AdditionalButtonOperation = {this.props.handleDeactivate}
                />

                 {/* success popup  */}
                 <CustomModal
                    open={this.props.modal2}
                    toggle={this.props.toggle2}
                    title={t('success')}
                    bodyMessage={t('qr_active_succ_msg')}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.toggle2}
                    />

                {/* warning popup for qr code active deactivate */}
                <CustomModal
                    open={this.props.ActModel}
                    title={t('confirmation')}
                    bodyMessage={t('qr_active_msg')}
                    PrimaryButtonText={t('close')}
                    PrimaryButtonOperation={this.props.ActivateButton}
                    AdditionalButtonText={t('yes')}
                    AdditionalButtonOperation = {this.props.handleActivate}
                />

                {/* warning popup */}
           
            <CustomModal
                open={this.props.alertModel}
                title={t('Warning')}
                bodyMessage={this.props.alertMsg}
                PrimaryButtonText={t('close')}
                PrimaryButtonOperation={this.props.closetoggleAlert}
            />

            {this.props.loading ? (
            <Loader loader={this.props.loading} />
            ) : ("")}

            </main>
        )
    }
}

export default withTranslation()(ConnectorStatusTemplate);