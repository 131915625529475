import React from 'react';
import * as baseUrl from '../views/constants';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';
import QRCodeManagementTemplate from '../Screens/QRCodeManagementTemplate';
import servicesUrl from '../common/servicesUrl';

class QRCodeManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            multiple: false,
            options: [],
            errors: {},
            pageSize: 10,
            activePage: 1,
            QRCodeMangData: [],
            noOfRecords: null,
            statusChange: '',
            chargePoint: '',
            activeItem: "1",
            qrcodeId: "",
            qrCodeStatus: true,
            searchStr: "",
            modal3:false,
            DeactModel:false,
            ActModel:false,
            modal2:false,
            alertModel: false,
            alertMsg: '',
            isSearchNotFound: false,
            loading: false,
        }
        this.findQRcodeId = this.findQRcodeId.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.tabToggle = this.tabToggle.bind(this);
        this.exportQRCode = this.exportQRCode.bind(this);
        this.changeActivateDeactivateStatus = this.changeActivateDeactivateStatus.bind(this)
        this.keyPress = this.keyPress.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.DeactivateButton = this.DeactivateButton.bind(this)
        this.toggle3 = this.toggle3.bind(this)
        this.handleDeactivate = this.handleDeactivate.bind(this)
        this.ActivateButton = this.ActivateButton.bind(this)
        this.toggle2 = this.toggle2.bind(this)
        this.handleActivate = this.handleActivate.bind(this)
        this.toggleAlert = this.toggleAlert.bind(this);
        this.closetoggleAlert = this.closetoggleAlert.bind(this)
    }

    componentDidMount() {
        this.getQRCode(1, this.state.pageSize);
    }

    async getQRCode(Page, pageSize) {
        this.setState({ loading: true })
        let isSearchNotFound = this.state.isSearchNotFound;
        let qrcodeId = this.state.qrcodeId;

        if(isSearchNotFound) {
            qrcodeId = "";
        }
        
        let url = `${baseUrl.URLPath}${servicesUrl.getQRcode}?qrId=${qrcodeId}&status=${this.state.qrCodeStatus}&pageSize=${pageSize}&pageNo=${Page}`;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (this.state.isOnSearchFind) {
            if (response.count > 0) {
                this.setState({ ...this.state, QRCodeMangData: response.data, noOfRecords: response.count, isSearchNotFound: false, isOnSearchFind: false, loading: false });
            } else {
                this.setState({ ...this.state, isSearchNotFound: true, loading: false });
            }
        } else {
            this.setState({ ...this.state, QRCodeMangData: response.data, noOfRecords: response.count, isSearchNotFound: false, isOnSearchFind: false, loading: false });
        }
    }


    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;

        this.setState({
            activePage: 1,
            pageSize: entries
        }, () => {
            this.getQRCode(1, this.state.pageSize);
        })
    }

    handlePageChange(pageNumber) {
        if (pageNumber !== this.state.activePage) {
            this.setState({ activePage: pageNumber, qrcodeId:""}, () => {
                this.getQRCode(pageNumber, this.state.pageSize);
            });
        }
    }

    // tab view click
    tabToggle(tab, value, event) {
        // var data = {
        //     id: this.state.id,
        //     chargePointId: this.state.chargePointId
        // }
        // this.props.location.state = data;
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab,
                qrCodeStatus: value
            }, () => {
                this.getQRCode(1, this.state.pageSize);
            });
        };
    }


    exportQRCode(id) {
        var url = baseUrl.URLPath + servicesUrl.exportQRcodeDetails + "?id=" + id;
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then((resp) => {
            if (resp.status === 401) {
                // this.togglesession();
                // localStorage.clear();
                window.location.reload();
            } else if (resp.status === 200) {
                return resp.blob();
            } else if (resp.status === 404 || resp.status === 403) {
                return resp.json();
            }
        }).then((response) => {
            if (response !== undefined) {
                if (response.message === "group_owner_detail_not_exist" || response.message === "qr_code_status" || response.message === "group_not_exists") {
                    this.setState({
                        alertMsg: this.props.t(response.message)
                    })
                    this.toggleAlert();
                } else {
                    let d = new Date();
                    let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                    let url = window.URL.createObjectURL(response);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'QRCode_Details_' + dformat + '.pdf';
                    a.click();
                }
            }
        }).catch((error) => {
            console.log(error, "catch the loop")
        })
    }


    findQRcodeId() {
        this.setState({
            isOnSearchFind:true
        })
        var qrcodeId = document.getElementById("qrId").value;
        var errors = {};
          this.setState({ qrcodeId: qrcodeId }, () => {
            if(this.state.qrcodeId === ""){
                errors["enterQR"] = this.props.t('enter_qr')
                this.setState({
                    errors:errors
                })
                $("#notexists").text(" ");
              }else{
                errors["enterQR"] = " "
                this.setState({
                    errors:errors
                })
                this.getQRCode(1, this.state.pageSize);
             }
          });
    }

    keyPress(e) {
        var key = e.key;
        var errors = {};
        if (e.keyCode === 13) {
            // this.state.isOnSearchFind = true;
            this.setState({ qrcodeId: e.target.value ,  isOnSearchFind :true}, ()=>{
                if (this.state.qrcodeId === "") {
                    errors["enterQR"] = this.props.t('enter_qr')
                    this.setState({
                        errors:errors
                    })
                    $("#notexists").text(" ");
                } else {
                    errors["enterQR"] = ""
                    this.setState({
                        errors:errors
                    })
                    this.getQRCode(this.state.activePage, this.state.pageSize);
                }
            });
        }

        if (key === "Backspace" || key === "Delete") {
            var x = e.target.value
            var sno = x.slice(0, x.length - 1)
            if (sno === "") {
                this.setState({ qrcodeId: sno }, ()=>{
                    this.getQRCode(1, this.state.pageSize);
                });
            }
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9., -]");
                 key = e.key;
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }


    changeHandler(e) {
       let errors = {}
        // let qrcodeId = e.target.value;
        errors["enterQR"] = ""
        this.setState({ errors:errors });
        $("#notexists").text(" ");
    }

    handleClick() {
        // let qrId = this.state.qrcodeId;
        document.getElementById("qrId").value = "";
        let errors = {}
        errors["enterQR"] = "";
        $("#notexists").text("");
        this.setState({
            qrcodeId:"",
            errors:errors,
            activePage:1
        },()=>{
            this.getQRCode(1, this.state.pageSize);
        });
      
    }

    async changeActivateDeactivateStatus(Idtag, status) {
        if(status){
            this.setState({
                ActModel: !this.state.ActModel,
                deactivateqrId:Idtag,
                qrstatus:status,
            });
        }else{
            this.setState({
                DeactModel: !this.state.DeactModel,
                deactivateqrId:Idtag,
                qrstatus:status,
            });
        }
    }


    handleDeactivate(){
        this.setState({
            DeactModel: !this.state.DeactModel
        });
         this.DeactivateQRCide();
    }

    handleActivate(){
        this.setState({
            ActModel: !this.state.ActModel
        });
         this.DeactivateQRCide();
    }

    async DeactivateQRCide(){
        if(this.state.qrstatus){
            this.setState({
                ActModel: !this.state.ActModel
            });
        }else{
            this.setState({
                DeactModel: !this.state.DeactModel
            });
        }
        var url = baseUrl.URLPath + servicesUrl.saveActivateDeactivateStatus + "?qrId=" + this.state.deactivateqrId + "&status=" + this.state.qrstatus;
        let type = ApiMethodTypes.PUT;
        let headers = getSecurityHeaders();
        let response = await APICallUtility.cpmsAPIFetch(url, type, {}, headers, this.props.t, true);
        if (response !== undefined) {
            if (response.status === 400 || response.status === 404) {
                if (response.message === "group_owner_detail_not_exist" || response.message === "qr_code_status" || response.message === "group_not_exists") {
                    this.setState({
                        alertMsg: this.props.t(response.message)
                    })
                    this.toggleAlert();
                }
            } else {
                this.getQRCode(1, this.state.pageSize);
                if(this.state.qrstatus){
                    this.toggle2();
                }else{
                    this.toggle3();
                }
                
            }
        }
    }

    DeactivateButton(){
        this.setState({
            DeactModel: !this.state.DeactModel
        });
    }
    toggle3(){
        this.setState({
            modal3: !this.state.modal3
        });
    }

    ActivateButton(){
        this.setState({
            ActModel: !this.state.ActModel
        });
    }
    toggle2(){
        this.setState({
            modal2: !this.state.modal2
        });
    }
    toggleAlert() {
        this.setState({
            alertModel: !this.state.alertModel
        })
    }

    closetoggleAlert() {
        this.setState({
            alertModel: !this.state.alertModel
        })
    }


    render() {
        // const { t } = this.props
        return (
            <QRCodeManagementTemplate
                {...this.state}
                findQRcodeId={this.findQRcodeId}
                showEntriesChange={this.showEntriesChange}
                handlePageChange={this.handlePageChange}
                activeItem={this.state.activeItem}
                tabToggle={this.tabToggle}
                exportQRCode={this.exportQRCode}
                alertModel = {this.state.alertModel}
                closetoggleAlert = {this.closetoggleAlert}
                alertMsg = {this.state.alertMsg}
                toggleAlert = {this.toggleAlert}
                changeActivateDeactivateStatus={this.changeActivateDeactivateStatus}
                keyPress={this.keyPress}
                changeHandler={this.changeHandler}
                handleClick = {this.handleClick}
                DeactivateButton = {this.DeactivateButton}
                handleDeactivate = {this.handleDeactivate}
                toggle3 = {this.toggle3}
                ActivateButton = {this.ActivateButton}
                handleActivate = {this.handleActivate}
                toggle2 = {this.toggle2}
                isSearchNotFound ={this.state.isSearchNotFound}
            />
        );
    }
}

export default withTranslation()(QRCodeManagement);