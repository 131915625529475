import React from 'react';
import { withTranslation } from 'react-i18next';
import { Typeahead } from 'react-bootstrap-typeahead';
import { createBrowserHistory } from 'history';
import { Table, Form } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import $ from "jquery";
import moment from 'moment';

import * as baseUrl from './constants';
import servicesUrl from '../common/servicesUrl';

const history = createBrowserHistory();

class ShowOfflineData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            multiple:false,
            options:[],
            pageSize: 10,
            activePage: 1,
            noOfRecords: '',
            errors: {},
            offlineData: [],
            modalsession: false,
            intial_data:[],
            saveSelectedCpid:"",
            icondisplay: ""
        }
        this.getOfflineData = this.getOfflineData.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.handleKeyDown=this.handleKeyDown.bind(this);
        this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
        this.chargepointIDChange=this.chargepointIDChange.bind(this);
        this.ClearChargepoint=this.ClearChargepoint.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        this.getOfflineData();
    }

    getOfflineData(action) {
        
        let url = baseUrl.URLPath + servicesUrl.getOfflineData + "?pageNo=" + this.state.activePage + "&pageSize=" + this.state.pageSize + "&chargePointId=" + this.state.saveSelectedCpid;

        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        }).then((resp) => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            } else if (resp.status == 200) {
                return resp.json();
            }
        }).then((response) => {
            
            if (response != undefined) {
                if (response?.count?.count == 0) {
                    if (action == "click" || action == "enter") {
                        let errors = this.state.errors;
                        errors["notexists"] = this.props.t('charge_point_id_not_exists.');
                        this.setState({errors})                        
                    }
                    this.setState({
                        noOfRecords: 0,
                        offlineData: []
                    })
                } else {
                    if (action == "click" || action == "enter") {
                        $("#notexists").text(" ");
                    }
                    this.setState({
                        offlineData: response?.offlineData ?? [],
                        noOfRecords: response?.count?.count ?? 0
                    });
                }
            }
        }).catch((error) => {
            console.log(error, "catch the loop")
        })
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        history.push('/');
        window.location.reload();
    }

    showEntriesChange(e){
        this.setState({
            pageSize: e.target.value
        }, ()=>{
            this.getOfflineData();
        })
    }

    handleKeyDown(ref, e) {
        var serial_no = e.target.value;
        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false);                            
        var errors = {};            
        if (serial_no != 0) {         
            this.state.errors["selectChargepoint"] = "";
            errors["chargePointNotExists"] = "";
            errors["selectChargepoint"] = "";
            errors["entercharge"] = "";
            errors["notexists"] = "";
        }
        if (key == "Backspace" || key == "Delete") {  
            this.state.errors["selectChargepoint"] = "";
            errors["chargePointNotExists"] = "";
            errors["selectChargepoint"] = "";
            errors["entercharge"] = "";
            errors["notexists"] = "";
            $("#notexists").text("");
            var x = e.target.value
            var sno = x.slice(0, x.length - 1)
            if (sno == "") {
                this.state.saveSelectedCpid = sno;
                this.getOfflineData();
            }
        }
        var sno = serial_no.slice(0, serial_no.length - 1)
        this.getSearchInventoryList(sno)

        if (e.keyCode == 13) {
            this.FindCpName(ref, "enter");
            $("#rbt_id").hide();
        }
        if ((e.keyCode == 86 && ctrl)) {
            this.getSearchInventoryList(serial_no)
        }
        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9., -]");
                var key = e.key;
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
        this.setState({errors})
    }

    getSearchInventoryList(cpid) {
        var url = baseUrl.URLPath + "diagnostics/lookup/search?cpid=" + cpid;

        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                } else if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({
                        options: response ?? [],
                        intial_data: response ?? []
                    });
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop");
            })
    }

    FindCpName(ref, event) {        
        let errors = this.state.errors;
        this.state.saveSelectedCpid = ref.current.inputNode.value;
        if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
            this.state.errors["entercharge"] = this.props.t('enter_charge_point_id')
            // $("#notexists").text("");
            this.setState({errors})
        } else {
            this.state.errors["entercharge"] = "";
            this.state.pageSize = 10;
            this.state.activePage = 1;
            this.getOfflineData(event);
        }
    }

    chargepointIDChange(cpid) {
        this.state.saveSelectedCpid = cpid
        this.setState({ saveSelectedCpid: cpid })
        if (cpid.length == 0) {
            this.getOfflineData();
        } else {
            let errors = {};
            errors["chargePointNotExists"] = "";
            errors["selectChargepoint"] = "";
            errors["entercharge"] = "";
            errors["notexists"] = "";
            this.setState({
                errors: errors
            }, ()=>{
                this.getOfflineData();
            });
        }
    }

    ClearChargepoint(ref, event) {
        let errors = this.state;
        errors["selectChargepoint"] = "";
        errors["entercharge"] = "";
        errors["notexists"] = "";
        $("#notexists").text("");
        this.state.saveSelectedCpid = "";
        this.state.pageSize = 10;
        this.state.activePage = 1;
        this.getOfflineData();
        ref.current.clear();
        this.setState({ chargePoint: "", errors })
    }

    handlePageChange(pageNumber) {
        if (pageNumber != this.state.activePage) {
            this.state.activePage = pageNumber;
            this.getOfflineData();
        }
    }

    render() {
        const { t } = this.props;
        const ref = React.createRef();

        const data = this.state.offlineData.map((getArray, index) => {
            if (getArray.offlindDataFileURL != null && getArray.offlindDataFileURL != "") {
                this.state.icondisplay = "inline-block"
            } else {
                this.state.icondisplay = "none"
            }

            if (getArray.startTime == null || getArray.startTime == "") {
                var startTime = "";
                var startTime1 = "";
            } else {
                var startTime = moment(getArray.startTime).format("DD MMM YYYY");
                var startTime1 = moment(getArray.startTime).format("HH:mm");
            }

            return (
                <tr>
                    <td>{getArray.chargePointId}</td>
                    <td>{getArray.fileName}</td>
                    <td className="wrap-word">{startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>}</td>
                    <td>
                        <a href={getArray.offlindDataFileURL} download> <i class="fas fa-download action-icon" title="Download Offline Data" style={{ display: this.state.icondisplay }} ></i></a>
                    </td>
                </tr>
            );
        })

        return (
            <main className="content-div" >
                <p>Show Offline Data
                    <div className="breadcrumb_div">
                        {t('Maintenance')} &gt; <span className="breadcrumb_page">Show Offline Data</span>
                    </div>
                </p>

                <div className="page-outerdiv">
                    <div className="row">
                        <div className="col-md-4 pull-right">
                            <div class="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <Typeahead
                                    labelKey="name"
                                    className="w_80"
                                    multiple={this.state.multiple}
                                    options={this.state.options}
                                    minLength={3}
                                    placeholder={t('choose_a_charge_point_id')}
                                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                                    onChange={this.chargepointIDChange.bind(this)}
                                    id="rbt_id"
                                    ref={ref}
                                />
                                <button type="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}>
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div class="input-group-btn">
                                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref, "click")}>{t('find')}</button>
                                </div>
                                <span className="error_msg w3-animate-top mt-2" id="" style={{ color: "red" }}>{this.state.errors["entercharge"]}</span>
                                <span className="error_msg w3-animate-top mt-2" id="" style={{ color: "red" }}>{this.state.errors["notexists"]}</span>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-right ml-0 selectdiv" >
                                <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                                    <option>10</option>
                                    <option>20</option>
                                    <option>30</option>
                                    <option>40</option>
                                    <option>50</option>
                                </Form.Control>
                            </Form.Group>
                            <label className="pull-right sub-text mb-0">{t('show_entries')}</label>
                        </div>
                    </div>
                    {/* end page menu bar  */}

                    {/* table start */}
                    <div className="row mt-2 ">
                        <div className="col-md-12">
                            <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        <th>{t('charge_point_id')}</th>
                                        <th>{t('file_name')}</th>
                                        <th>{t('received_date')}</th>
                                        <th>{t('action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data}
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-12 mt-2 mb-4 text-right">
                            <Pagination
                                hideDisabled
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.noOfRecords}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                        {/* End table  */}
                    </div>
                </div>
            </main>
        );
    }
}

export default withTranslation()(ShowOfflineData);