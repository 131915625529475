import React from 'react';
import * as baseUrl from '../views/constants';
import { withTranslation } from 'react-i18next';

class CPInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false,
            serialnumber: "190015948201",
            chargestationname: "CP1",
            cpDetails:{}
        }
        
        this.backButton = this.backButton.bind(this)
    }


    componentDidMount() {
        var id = localStorage.getItem('qrId');
        var cpDetails =  localStorage.getItem('cpDetails');
        cpDetails = JSON.parse(cpDetails)
        this.setState({
            cpDetails:cpDetails
        })
        
        // var Apiurl = baseUrl.LoginPath+"/qrcode/charging/history?qrId=" + id;
        // fetch(Apiurl, {
        //     method: "GET",
        //     headers: {
        //         "content-type": "application/json",
        //     }
        // })
        //     .then((resp) => {
        //         if (resp.status === 401) {

        //         }
        //         return resp.json();
        //     })
        //     .then((response) => {
        //         if (response !== undefined) {
        //             if (response.message === "no_transaction_found_for_qr_code") {
        //                 this.setState({
        //                     historyData: true
        //                 })
        //             } else {
        //                 this.setState({
        //                     chargehistory: response.history,
        //                     historyData: false,
        //                     expirationDate: response.expirationDate,
        //                     remainingEnergyCredit: response.remainingEnergyCredit,
        //                 })
        //             }
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error, "catch the loop")
        //     })
    }

    backButton() {
        this.props.setPage('selectCharger');
        localStorage.setItem('page', 'selectCharger');
    }

    render() {
        const { t } = this.props;
        
        return (
            <>
               <div className="welcome-parent">
               <div className="welcome-parent bg_white">
                  <div className="container-fluid">
                         <nav className="nav_bar d-inline-flex w-100">
                            <div className='info-title info_cont  d-inline-flex pl-0 pr-0'> {t('Information')}
                                <div className="info-close" onClick={this.backButton}>
                                    <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/close.svg"} style={{ width: "20px" }} alt="close-icon" />
                                </div>
                            </div>
                        </nav>
                        <div className='row justify-content-center '>
                            <div className='col-md-12 welcome-div pl-0 pr-0'>
                                <div className='card-body'>
                                     <div className=''>
                                        <label className='info-label'>{t('Charging_station_name')} </label>
                                        <p className='info-heading mb-2'>{this.state.cpDetails.aliasName}</p>
                                     </div>
                                     <div className=''>
                                        <label className='info-label'>{t('Charging_station_serial_number')} </label>
                                        <p className='info-heading mb-2'>{this.state.cpDetails.chargePointSerialNumber}</p>
                                     </div>
                                     <div className=''>
                                        <label className='info-heading'>{t('Description_of_charging_station')} </label>
                                        <p className='text_desc'>{this.state.cpDetails.chargePointDescription}</p>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  {/* <div className="container-fluid">
                         <nav className="nav_bar">
                            <span className="navbar-brand ml-auto" onClick={this.backButton} >
                               <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/close.svg"} style={{ width: "24px" }} alt ="close"/>
                            </span>
                        </nav>
                        <div className='row justify-content-center'>
                            <div className='col-md-12 welcome-div'>
                            <div className='card-body'>
                                <div className='card-title text-left'>Information</div>                            
                                <div className='card-container'>
                                    <div className='card-text text-center'>
                                        <p style={{ marginBottom: '0px' }}><span style={{ fontSize: '0.8rem', color: '#757D86' }}>Chargingstation Serial number</span></p>
                                        <p><span className="welcome-txt-bold">{ this.state.serialnumber}</span></p>
                                    </div>
                                    <div className='card-text text-center'>
                                        <p style={{ marginBottom: '0px' }}><span style={{ fontSize: '0.8rem', color: '#757D86' }}>Chargestation Name</span></p>
                                        <p><span className="welcome-txt-bold">{ this.state.chargestationname}</span></p>
                                    </div>
                                </div>
                                </div>

                                <div className='card-text pt-2 welcome_cardtxt' style={{borderTop: 'none'}}>
                                    <p style={{fontWeight: 'bold'}}>Description of chargingstation</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </>
        )

    }
}
export default withTranslation()(CPInformation);
