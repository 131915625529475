import React from 'react';
import { MDBRow,MDBCol, MDBInput } from 'mdbreact';
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import apiCallUtility from '../common/APICallUtility'
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class unlockConnector extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          
            fields:{
              connectorID:'',
              },
          cpList:[],
          sucess:false,
          colour:'sucess',
          page_title:"Unlock Connector",
          errors:{},
          chargePoint:'',
          operationId:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
         this.getSelectedCP=this.getSelectedCP.bind(this);
         this.onShowAlert=this.onShowAlert.bind(this);
}
componentDidMount(){
  let propsdata = this.props.location.state;
  if(propsdata!==undefined){
    if(propsdata.chargePoint!==undefined && propsdata.operationId!==undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }  
}

onShowAlert(){
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });
}

async handleSubmit(event) {
  var payload=this.getPayLoad();
  var errors={};
  var connectorId=payload.connectorId;
if(this.getCPList().length===0){
  errors["emptyChargePoint"]=this.props.t('select_charge_point')
}
if(isNaN(connectorId)){
  errors["emptyConnectorId"]=this.props.t('enter_connector_id')
}
if(this.getCPList().length!==0 && !isNaN(connectorId)){
  var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList(), this.props.t);

  if (response === this.props.t('unable_toprocess_request_try_after_some_time')) {
    this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
      this.onShowAlert();
    })
  } else if (response === 'Request is processed Successfully'){
    this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
      this.onShowAlert();
    })
  }

}
this.setState({errors:errors})
}

getCPList() {
  var Id = this.state.operationId;
  var cplist = "";
  if(Id !== null && Id!==""){
   cplist = Id.split();
  } else{
   cplist = this.state.cpList;
 }

 return cplist;
}

getPayLoad() {
  var payload = {
    "connectorId": parseInt(this.state.fields.connectorID),
  };
   
  return payload;
}

getMessageType() {
  return constants.UNLOCK_CONNECTOR;
}

numberHandler(field, e){ 
let errors = this.state.errors;
  let fields = this.state;
  if(e.target.validity.valid){
    fields[field] = e.target.value; 
    errors["emptyConnectorId"] = "";
    this.setState({fields, errors});
  }else if(e.target.value===""){
    fields[field] = e.target.value; 
    this.setState({fields});
  }
}

changeHandler(field, e){ 

  let fields = this.state.fields;
  fields[field] = e.target.value; 
  this.setState({fields});
  }
getSelectedCP(cpList){
  let errors = this.state.errors;
  if(cpList!==""){
    errors["emptyChargePoint"]="";
  }

  this.setState({cpList:cpList});
}

componentWillUnmount(){
  localStorage.setItem('chargePoint', '');
  localStorage.setItem('operationId', '');
}

render() {
  const{t}=this.props
  return (
    <main className="content-div" >
      <p>  {t('Unlock Connector')}
        <div className="breadcrumb_div">
          {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Unlock Connector')}</span>
        </div>
      </p>
      <div className="page-outerdiv">
        <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
        <MDBRow className="mb-4">
          <MDBCol sm="12">
            <div>
              <form className="needs-validation">
                <MDBRow>
                  <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="UnlockConnector" ></ChargePointSelection>
                  <MDBCol md="12">
                    <span className="selection_charge_point_error w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptyChargePoint"]}</span></span>
                  </MDBCol>
                  <MDBCol md="4">
                    <MDBInput
                      value={this.state.fields["connectorID"]}
                      name="ConnectorID"
                      onInput={this.numberHandler.bind(this, "connectorID")}
                      type="text" 
                      pattern="[0-9]*"
                      id="connectorID"
                      label={t('connector_id_integer') + " *"}
                      required
                    >
                      <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} >{this.state.errors["emptyConnectorId"]}</span></span>
                      <small id="heartbeatinterval" className="form-text text-muted">
                        {t('if_empty0charge_point')}
                      </small>
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
                <button type="button" className="mt-5 refresh-btn" onClick={this.handleSubmit} >{t('perform')}</button>
              </form>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    </main>
  );
}
}

export default withTranslation()(unlockConnector);