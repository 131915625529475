import React from 'react';
import { withTranslation } from 'react-i18next';
import {noDecimalCurrencies} from '../../common/noDecimalCurrencies';
import {MDBInput } from 'mdbreact';

 class NewSiteCosts extends React.Component{
    render(){
         const{t}=this.props
        let currecyList = this.props.currencyList?.map((country,index)=>{
            return <option value={country.currencysymbol+'_'+country.currencyCode} key={`currency_${index}`} style={{fontWeight:'bold'}}>{country.currencyCode}</option>
        });

        return(
            <div style={{margin: '10px'}}>
                <div className="mb-4 w-60">
                        <MDBInput
                          value={this.props.usergroupname}
                          name="userGroupName"
                          onChange={this.props.onChangeUserGroupName}
                          type="text"
                          id="userGroupName"
                          label={t('user_group_name') + " *"}
                          maxLength={30}
                        >
                        </MDBInput>
                </div>
                <div style={{ fontWeight: 'bold', fontSize: '15px' }}>{t('chargingsession_costs')}</div>
                <div className='mt-1'>{t('chargingsession_costs_description')}</div>
                <div className='row'>
                    <div style={{fontWeight: 'bold',flex: '0 0 20%', maxWidth: '20%', padding: '15px', fontSize: '15px'}}>{t('select_currency')}</div>
                    <div className="col-md-3 mt-1">
                        <select className="browser-default custom-select select_height" id="currency" value={this.props.currency+"_"+this.props.currencyCode} onChange={this.props.onChangeCurrency}>
                            {currecyList}
                        </select>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-md-6'>
                        <label style={{ fontWeight: 'bold', fontSize: '15px', marginRight: '20px' }}>{t('costsPerChargingSession')}</label>
                        <label className="switch">
                            <input type="checkbox" checked={!this.props.isCostPerSessionActive} onChange={this.props.onChangeCostPerSessionActive} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label style={{ fontWeight: 'bold', fontSize: '15px', marginRight: '20px' }}>{t('costs_based_on_usage')}</label>
                        <label className="switch">
                            <input type="checkbox" checked={!this.props.isCostBasedonUsageActive} onChange={this.props.onChangeCostBasedonUsageActive} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        {t('costs_per_session_description')}
                    </div>
                    <div className='col-md-6'>
                       {t('costs_on_usage_description')}
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-md-6'>
                        <div className="md-form">
                            <input
                                id="sessionCost"
                                type="text"
                                name="sessionCost"
                                pattern={noDecimalCurrencies.includes(this.props.currencyCode) ? "[0-9]*" : "[0-9.]*"}
                                className="form-control pl-0"
                                value={this.props.CostPerSessionValue}
                                disabled={this.props.isCostPerSessionActive}
                                onChange={this.props.onChangeCostPerSessionValue}
                                placeholder={t('costsPerChargingSession')}
                                maxLength={5}
                            />
                            <span style={{ color: '#00529e', position: 'relative', fontWeight: 'bold', left: '324px', top: '-42px' }}>{this.props.currencyCode}</span>
                            <div className="error_msg1 w3-animate-top ml-0" style={{ color: "red", marginTop: '-30px' }}>{this.props.errors['costPerSessionError']}</div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="md-form">
                            <input
                                id="usageCost"
                                type="text"
                                name="usageCost"
                                pattern={noDecimalCurrencies.includes(this.props.currencyCode) ? "[0-9]*" : "[0-9.]*"}
                                className="form-control pl-0"
                                value={this.props.CostPerUsageValue}
                                disabled={this.props.isCostBasedonUsageActive}
                                onChange={this.props.onChangeCostPerUsageValue}
                                placeholder={t('costs_based_on_usage')}
                                maxLength={5}
                            />
                            <span style={{ color: '#00529e', position: 'relative', fontWeight: 'bold', left: '290px', top: '-42px' }}>{this.props.currencyCode}/{t('kwh')}</span>
                            <div className="error_msg1 w3-animate-top ml-0" style={{ color: "red", marginTop: '-30px' }}>{this.props.errors['costPerUsageError']}</div>
                        </div>
                    </div>
                </div>
                <div className="text-right">
                    {
                        (this.props.isNewSite) ?
                            <button type="button" data-test="button" className="btn_primary mr-3 mb-2 Op-modal-close" onClick={this.props.saveNewSite}>
                                {t('save')} <i className="fas fa-save ml-2"></i>
                            </button> :
                            <button type="button" data-test="button" className="btn_primary mr-3 mb-2 Op-modal-close" onClick={()=>{this.props.updateExistingSite(this.props.id)}}>
                                {t('update')}
                            </button>
                    }
                    <button className="mt-5 btn_cancel" type="button" data-test="button" onClick={this.props.cancel}>
                        {t('cancel')} <i className="fas fa-times ml-2"></i>
                    </button>
                </div>
            </div>
        )
    }
}

export default withTranslation()(NewSiteCosts);