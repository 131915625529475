import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import $ from "jquery";

//API Imports
import * as baseUrl from '../constants';
import servicesUrl from '../../common/servicesUrl';
import APICallUtility from '../../common/APICallUtility';
import ApiMethodTypes from '../../common/ApiMethodTypes';
import { getSecurityHeaders } from '../../common/HttpRequestClass';

import * as roles from '../roles';

class ChargerConnectors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            todosPerPage: 4,
            upperPageBound: 3,
            lowerPageBound: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3,
            chargepointid: '',
            chargePointData: [{
                "tenantId": "",
                "chargePointId": "",
                "connectorId": '',
                "status": ""
            }],
            errors: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.getChargerConnector = this.getChargerConnector.bind(this);
        this.defaultgetChargerConnectors = this.defaultgetChargerConnectors.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.btnDecrementClick = this.btnDecrementClick.bind(this);
        this.btnIncrementClick = this.btnIncrementClick.bind(this);
        this.btnNextClick = this.btnNextClick.bind(this);
        this.btnPrevClick = this.btnPrevClick.bind(this);
        this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
        this.refreshChargerConnectors = this.refreshChargerConnectors.bind(this);
    }

    handleChange(e) {
        this.setState({ chargepointid: e.target.value });
        if (e.target.value === "") {
            this.setState({ errors: {}, chargePointData: [] });
            this.defaultgetChargerConnectors();
        }
    }

    keyPress(e) {
        var key = e.key;
        if (e.keyCode === 13) {
            this.setState({ chargepointid: e.target.value }, () => {
                this.getChargerConnector();
            });
        } else if (key === "Delete") {
            window.location.reload();
        }
    }

    async getChargerConnector() {
        let errors = {};
        var chargepointid = this.state.chargepointid;

        let url = baseUrl.URLPath + servicesUrl.getConnectorStatusWithCPFilter + chargepointid;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if (response.status === 404) {
            errors["chargepointdoesNotExist"] = this.props.t('charge_point_not_exists');
            this.setState({
                errors: errors,
                chargePointData: []
            })
        } else {
            this.setState({
                chargePointData: response ?? [],
                errors: {}
            })
        }
    }

    async defaultgetChargerConnectors() {
        let url = baseUrl.URLPath + servicesUrl.getConnectorStatus;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        document.getElementById("loader_image_div_charger_connector").style.display = "block";

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

        this.setState({
            chargePointData: response ?? [],
            errors: {}
        })
        document.getElementById("loader_image_div_charger_connector").style.display = "none";
    }

    componentDidMount() {
        this.defaultgetChargerConnectors();
        $(".cc_pagination li.active").removeClass('active');
        $('.cc_pagination li#' + this.state.currentPage).addClass('active');
    }

    handleClick(event) {
        let listid = Number(event.target.id);
        this.setState({ currentPage: listid });
        $(".cc_pagination li.active").removeClass('active');
        $('.cc_pagination li#' + listid).addClass('active');
        this.setPrevAndNextBtnClass(listid);
    }

    setPrevAndNextBtnClass(listid) {
        let totalPage = Math.ceil(this.state.chargePointData.length / this.state.todosPerPage);
        this.setState({ isNextBtnActive: 'disabled', isPrevBtnActive: 'disabled' });

        if (totalPage === listid && totalPage > 1) {
            this.setState({ isPrevBtnActive: '' });
        } else if (listid === 1 && totalPage > 1) {
            this.setState({ isNextBtnActive: '' });
        } else if (totalPage > 1) {
            this.setState({ isNextBtnActive: '', isPrevBtnActive: '' });
        }
    }

    btnIncrementClick() {
        this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound, lowerPageBound: this.state.lowerPageBound + this.state.pageBound }, () => {
            let listid = this.state.upperPageBound + 1;
            this.setState({ currentPage: listid });
            this.setPrevAndNextBtnClass(listid);
        });
    }

    btnDecrementClick() {
        this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound, lowerPageBound: this.state.lowerPageBound - this.state.pageBound }, () => {
            let listid = this.state.upperPageBound - this.state.pageBound;
            this.setState({ currentPage: listid });
            this.setPrevAndNextBtnClass(listid);
        });
    }

    btnPrevClick() {
        if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
            this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound, lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
        }
        let listid = this.state.currentPage - 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
    }

    btnNextClick() {
        if ((this.state.currentPage + 1) > this.state.upperPageBound) {
            this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound, lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
        }
        let listid = this.state.currentPage + 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
    }

    refreshChargerConnectors() {
        this.defaultgetChargerConnectors();
    }

    render() {

        const { currentPage, todosPerPage, upperPageBound, lowerPageBound } = this.state;
        // Logic for displaying current todos
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentTodos = this.state.chargePointData.slice(indexOfFirstTodo, indexOfLastTodo);

        const dataRow = currentTodos.map((dataArray, index) => {
            var status_bg = "";
            switch (dataArray.status) {
                case "Available":
                    status_bg = "status-lgreen"; break
                case "Preparing":
                    status_bg = "status-orange"; break
                case "Charging":
                    status_bg = "status-blue"; break
                case "Finishing":
                    status_bg = "status-green"; break
                case "Reserved":
                    status_bg = "status-yellow"; break
                case "Unavailable":
                    status_bg = "status-gray"; break
                case "Faulted":
                    status_bg = "status-red"; break
                case "SuspendedEV":
                    status_bg = "status-purple"; break
                default:
                    status_bg = ""; break
            }

            return (
                <div className="dash-row" key={`charger_connector_${index}`}>
                    <label className="">
                        {dataArray.chargePointId}
                    </label>
                    <label className="">
                        {dataArray.connectorId}
                    </label>
                    <label className="text-right">
                        <span className={`status-div ${status_bg}`}>
                            {dataArray.status}
                        </span>
                    </label>
                </div>
            )
        })

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.chargePointData.length / todosPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            if (number === 1 && currentPage === 1) {
                return (
                    <li key={number} className='active' id={number}>
                        <button type='button' id={number} onClick={this.handleClick}>{number}</button>
                    </li>
                )
            } else if ((number < upperPageBound + 1) && number > lowerPageBound) {
                return (
                    <li key={number} id={number}>
                        <button type='button' id={number} onClick={this.handleClick}>{number}</button>
                    </li>
                )
            } else {
                return <></>
            }
        });

        return (
            <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success dashboard-height">
                <div className="content-box p-rel">
                    <div className="dashboard-subtitle">
                        {this.props.t('charger_connectors')}
                        <span className="pull-right">
                            <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Settings.png"} style={{ width: "15px" }} alt="" />
                        </span>
                        <span className="pull-right cursor-pointer">
                            <img src={process.env.PUBLIC_URL + "/assets/img/refresh_btn.svg"} width="32" style={{ paddingRight: '10px' }} onClick={this.refreshChargerConnectors} />
                        </span>
                    </div>
                    <div className="input-group input-search mt-2">
                        <i className="fas fa-search fa-sm"></i>
                        <input type="text" className="border-0 small" placeholder={this.props.t('search_chargepoint')} onKeyDown={this.keyPress} onChange={this.handleChange} value={this.state.chargepointid} />
                        <span className="error_msg w3-animate-top mt-1"> <span style={{ color: "red" }}>{this.state.errors["chargepointdoesNotExist"]}</span></span>
                    </div>
                    <div className="mt-3" >
                        <div className="table-hrbg mr-15 pt-1 mb-2" >
                            <label className="dashboard-space">{this.props.t('chargePointId')}</label>
                            <label>{this.props.t('connector')}</label>
                            <label className="pull-right pr-3">{this.props.t('chargingSessionState')}</label>
                        </div>
                        <div className="pull-left w-100 charger_con">{dataRow}</div>
                        <div className="connectorSilder w-100 text-center">
                            <ul className="pagination cc_pagination">{renderPageNumbers}</ul>
                        </div>
                        {
                            (roles.WEBASTO_SUPERADMIN_FIRST_LEVEL === localStorage.getItem('role')) ?
                                <label className="more-btn" style={{ cursor: 'text', color: 'darkgray' }}>{this.props.t('more')}</label>
                                :
                                <Link to="./connectorStatus"><label className="more-btn">{this.props.t('more')}</label></Link>
                        }
                    </div>
                </div>
                {/* Loader */}
                <div className="loader center" id="loader_image_div_charger_connector" style={{ display: "none", borderRadius: "18px" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>
            </div>
        )
    };
}

export default withTranslation()(ChargerConnectors);
