import React from 'react';
import { withTranslation } from 'react-i18next';



//API Imports
import * as baseUrl from '../views/constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';
import moment from "moment";
//Component Imports
import LoadManagementTemplate from '../Screens/LoadManagementTemplate';
import { isArrayOrNodeList } from 'reactstrap/lib/utils';

class LoadManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataAvailable:true,
      selectedOptions: {
        chargingStation: [],
        group: [],
        llmStatus : [],
      },
      selectedValues: {
        chargingStation: [],
        group: [],
        llmStatus : [],
      },
      filterChargersList: [],
      filterGroupList: [],
      filterGroupListName: [],
      isOpen1: false,
      isOpen3: false,
      activeApplyBtn: false,
      isOpenLLMStatusDropdown : false,
      selectedLLMStatus : 'Active',
      isActivatePopupOpen : false,
      isDeactivatePopupOpen : false,
      isLLMActivateSuccessPopupOpen : false,
      isLLMDeactivateSuccessPopupOpen : false,
      isWarningPopupOpen : false,
      selectedChargers : [],
      isLlmExtendTrialPopupOpen : false,
      isLlmExtendSuccessPopupOpen : false,
      selectedExtendTrialCharger : "",
      tableSelectedLlmToggleStatus : "",
      showLoder: false,      
      errors: {},      
      searchValue: "",      
      searchGroupValue: "",
      chargerList:[],
      activePage:1,
      pageSize:10,
      noOfRecords:0,
      pageNo:1,
      list:[],
      listOfFirstCreatedtimes:[],
      activeInactiveCp:"",
      selectionSting:"",
      isAlertForSingleCPLllm : false,
      isExtendTrialAlertPopupOpen : false,
      
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getFilterHistory = this.getFilterHistory.bind(this);
    this.emptySearchValues = this.emptySearchValues.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handlePageChange =  this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.getChargeList = this.getChargeList.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.onChangeLlmStatus = this.onChangeLlmStatus.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.removeSelectedValue = this.removeSelectedValue.bind(this);
    this.removeAllSelected = this.removeAllSelected.bind(this);
    this.handleSearchChargersChange = this.handleSearchChargersChange.bind(this);
    this.handleSearchGroupChange = this.handleSearchGroupChange.bind(this);
    this.getChargersList = this.getChargersList.bind(this);
    this.getGroupsList = this.getGroupsList.bind(this);
    this.handleLLMStatusDropdown = this.handleLLMStatusDropdown.bind(this);
    this.handleActivateBtn = this.handleActivateBtn.bind(this);
    this.handleDeactivateBtn = this.handleDeactivateBtn.bind(this);
    this.handleActivateSuccess = this.handleActivateSuccess.bind(this);
    this.closeLLMActivatedSuccessPopup = this.closeLLMActivatedSuccessPopup.bind(this);
    this.closeDeactivateLLMPopup = this.closeDeactivateLLMPopup.bind(this);
    this.closeLLMDeactivatedSuccessPopup = this.closeLLMDeactivatedSuccessPopup.bind(this);
    this.handleDeactivateSuccess = this.handleDeactivateSuccess.bind(this);
    this.closeWarningPopup = this.closeWarningPopup.bind(this);
    this.handleExtendTrialBtn = this.handleExtendTrialBtn.bind(this);
    this.handleLlmExtendSuccess = this.handleLlmExtendSuccess.bind(this);
    this.createdDatesArray = this.createdDatesArray.bind(this);
    this.checkIdGetName = this.checkIdGetName.bind(this);
    this.extendTrialPeriod = this.extendTrialPeriod.bind(this)
  }

  onChangeLlmStatus(id,text,e) {
   if(!e.target.checked){
    this.setState({isDeactivatePopupOpen : true , activeInactiveCp:id, selectionSting : ""})
   }
   else{
    this.setState({isActivatePopupOpen : true,activeInactiveCp:id, selectionSting : ""})
   }
}



  handleChange(id, cpid, event) {
    var checkedValue = document.getElementById(id).checked;
    if (checkedValue === true) {
        this.state.list.push(cpid);
        this.setState({
          selectedChargers: this.state.list,
            onClickCheckBox: true
        });
    } else {
        const index = this.state.list.indexOf(cpid);
        if (index > -1) {
            this.state.list.splice(index, 1);
        }
        this.setState({
          selectedChargers: this.state.list
        });
    }
}
handleLlmExtendSuccess(){
  // API call for extending trial
  let chargePointId = this.state.selectedExtendTrialCharger;
this.extendTrialPeriod(this.state.selectedExtendTrialCharger);
}

closeLlmExtendSuccessPopup = () => {
  this.setState({isLlmExtendSuccessPopupOpen : false})
  this.getChargeList(this.state.pageSize, this.state.activePage);
}

closeLlmExtendTrialPopup=()=>{
  this.setState({isLlmExtendTrialPopupOpen : false})

}

handleExtendTrialBtn(id){
  this.setState({isLlmExtendTrialPopupOpen : true, selectedExtendTrialCharger : id})
 
}

handleActivateSuccess(){
  if(this.state.selectionSting === "multipal"){
    this.activeMultiChargePoint();
   
  }else{
    this.activeChargePoint(); 
  }
}
  
  //API call for Activate LLM for the selected chargers
closeLLMActivatedSuccessPopup(){
  this.setState({isLLMActivateSuccessPopupOpen : false}, () => {
    if(this.state.selectionSting === "multipal"){
      window.location.reload();
      // this.getChargeList(this.state.pageSize, this.state.activePage);

    }else{
     // window.location.reload();
       this.getChargeList(this.state.pageSize, this.state.activePage);
    }
  })
}

handleDeactivateSuccess(){
  
  //API call for Deactivate LLM for the selected chargers
  if(this.state.selectionSting === "multipal"){
    this.deactiveMultiChargePoint();
  }else{
    this.deactiveChargePoint();
  }
 
}

closeLLMDeactivatedSuccessPopup(){
  this.setState({isLLMDeactivateSuccessPopupOpen : false}, () => {
    if(this.state.selectionSting === "multipal"){
      window.location.reload();
      // this.getChargeList(this.state.pageSize, this.state.activePage);
    }else{
      //window.location.reload();
       this.getChargeList(this.state.pageSize, this.state.activePage);
    }
  })
}

handleActivateBtn(){
  
  if(this.state.selectedChargers.length > 0){
    this.state.selectionSting = "multipal";
    this.setState({isActivatePopupOpen : true, selectionSting:"multipal"})
  }
  else{
    this.setState({isWarningPopupOpen : true})
  }
}

closeActivateLLMPopup=() =>{
  this.setState({isActivatePopupOpen : false})
}

handleDeactivateBtn(){
  if(this.state.selectedChargers.length > 0){
    this.setState({isDeactivatePopupOpen : true,selectionSting:"multipal"})
  }
  else{
    this.setState({isWarningPopupOpen : true})
  }   
}

closeDeactivateLLMPopup(){
  this.setState({isDeactivatePopupOpen : false})
}

handleLLMStatusDropdown(value, category) {
  let updatedSelectedValues = { ...this.state.selectedValues };
  updatedSelectedValues.llmStatus = [value];
  this.setState({ selectedLLMStatus: value, selectedValues: updatedSelectedValues, activePage: 1 }, () => this.getChargeList());
}

closeWarningPopup(){
  this.setState({isWarningPopupOpen : false})
}

closeAlertForSingleCPPopup= () => {
  this.setState({isAlertForSingleCPLllm : false})
}

closeExtendTrialAlertPopup = () => {
  this.setState({isExtendTrialAlertPopupOpen : false})
}

  componentDidMount(){
  this.getChargeList(this.state.pageSize, this.state.activePage);
  }



  showEntriesChange = (selectedOption) => {
    var entries = selectedOption.value;
    this.setState(
      {
        activePage: 1,
        pageSize: entries,
        fromDateApi: '',
        toDateApi: '',
      },
      () => {
        this.getChargeList("","",false);
      }
    );
  };

  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
      //prev
      this.setState({
        activePage: pageNumber,
        fromDateApi: this.state.curPageFirstCreTime,
        toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getChargeList("","",false);
      });
    } else {
      //next
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({
        activePage: pageNumber,
        fromDateApi: '',
        toDateApi: this.state.curPageLastCreTime,
        prevPageFirstCreTime: this.state.curPageFirstCreTime,
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.createdDatesArray(this.state.curPageFirstCreTime);
        }
        this.getChargeList("","",false);
      });
    }
  }

  checkIdGetName(val, type){
    console.log(this.state.filterGroupListName)
    const a1 = val; 
    let ObjVal = this.state.filterGroupListName;
    const checking = ObjVal.find((item) => (item.id === a1 ? item.name : null));
    return checking?.name;
  };

  async getFilterHistory() {
    var url = baseUrl.URLPath + servicesUrl.LoadManagementGetFilterHistory;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let errors = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      if (response) {
        let filterChargersList = response.chargepoints;
        let filterData = response.siteData;
        let filterGroupList = filterData ? filterData.map(data => data.id) : [];
        let filterGroupListName = filterData ? filterData.map(data => data) : [];
        this.setState({
          filterChargersList: filterChargersList,
          filterGroupList: filterGroupList,
          filterGroupListName:[...this.state.filterGroupListName, ...filterGroupListName]
        })
      } else if (response.status === 404) {

      }
    }
  }

  async handleSearchChargersChange(field, event) {
    const value = event.target.value;
    this.setState({ searchValue: value })
    let errors = {};
    const lowercaseValue = value.toLowerCase();
    if (value !== "" && value.length >= 3) {
      let filterData = await this.getChargersList(value, "");
      let filterChargersList = filterData ? filterData.map(data => data) : [];
      if (filterChargersList.length === 0) {
        errors["nodatafound"] = this.props.t('No data found..!');
        this.setState({ errors: errors, filterChargersList: [] })
      } else {
        errors["nodatafound"] = "";
        this.setState({ filterChargersList: filterChargersList, errors: errors })
      }
    } else {
      this.setState({ errors: errors, filterChargersList: [] })
    }
  };

  async getChargersList (cpid, text) {  
        
        var url = baseUrl.URLPath + servicesUrl.getLMChargersList + "?cpId=" + cpid;  
             
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response !== undefined) {
            if (response.message === "cp_not_exists") {
                response = [];
            } else {
                response = response
            }
            // this.setState({ filterChargersList: response })
            return response;
        }
  }

  async handleSearchGroupChange (field, event) {
    const value = event.target.value;
    this.setState({ searchGroupValue: value })
    let errors = {};
    const lowercaseValue = value.toLowerCase();
    if (value !== "" && value.length >= 3) {
      let filterData = await this.getGroupsList(value, "");
      let filterGroupList = filterData ? filterData.map(data => data.id) : [];
      let filterGroupListName = filterData ? filterData.map(data => data) : [];
      if (filterGroupList.length === 0) {
        errors["nodatafound"] = this.props.t('No data found..!');
        this.setState({ errors: errors, filterGroupList: [] })
      } else {
        errors["nodatafound"] = "";
        this.setState({ filterGroupList: filterGroupList,
          filterGroupListName: [...this.state.filterGroupListName, ...filterGroupListName],
          errors: errors })
      }
    } else {
      this.setState({ errors: errors, filterGroupList: []})
    }
  };

  async getGroupsList(siteName, text) {  
    
    var url = baseUrl.URLPath + servicesUrl.getLMGroupsList + "?siteName=" + siteName;  
         
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
        if (response.message === "cp_not_exists") {
            response = [];
        } else {
            response = response
        }
        // this.setState({ filterGroupList: response })
        return response;
    }
}

  // handleSubmit for Custom Filter Dropdown
  handleSubmit = (value) => {
    const isOpenState = {};
    isOpenState[value] = !this.state[value];

    const categoryMapping = {
      isOpen1: "chargingStation",
      isOpen3: "group",
    };

    const updatedSelectedValues = { ...this.state.selectedOptions, ...this.state.selectedValues };

    if (categoryMapping[value]) {
      updatedSelectedValues[categoryMapping[value]] = this.state.selectedOptions[categoryMapping[value]];
    }

    this.setState({
      ...isOpenState,
      selectedValues: updatedSelectedValues,
      searchValue: "",
      searchGroupValue: "",
      filterChargersList: [],
      filterGroupList: [],
      activeApplyBtn: false,
      activePage: 1
    }, () => {
      this.getChargeList();
    })
  };

  emptySearchValues = () => {
    let errors = {};
    errors["nodatafound"] = "";
    this.setState({
      searchValue: "",
      searchGroupValue: "",
      errors: errors,
    })
  }

  handleCheckboxChange = (category, val) => {
    this.setState((prevState) => {
      const updatedOptions = { ...prevState.selectedOptions };
      const updatedCategoryOptions = updatedOptions[category];

      if (updatedCategoryOptions.includes(val)) {
        updatedOptions[category] = updatedCategoryOptions.filter((item) => item !== val);
      } else {
        updatedOptions[category] = [...updatedCategoryOptions, val];
      }

      this.setState({
        selectedOptions: updatedOptions,
        activeApplyBtn: updatedOptions[category].length > 0,
      });
    });
  };

  showEntriesChange = (selectedOption) => {
    // var entries = document.getElementById("showEntries").value;
    var entries = selectedOption.value;
    this.setState(
      {
        activePage: 1,
        pageSize: entries,
        fromDateApi: '',
        toDateApi: '',
      },
      () => {
        this.getChargeList("","",false);
      }
    );
  };

  async getChargeList(pageSize, pageNo, count) {
    var llmStat = this.state.selectedValues.llmStatus;
    var finalLlmStatus;
    // if(llmStat.length !== 0){
    //   finalLlmStatus = this.state.selectedValues.llmStatus[0]
    // }
    // else{
    //   finalLlmStatus = "";
    // }
    this.state.isDataAvailable = true;
     document.getElementById("loader_image_div").style.display = "block";
    let errors = {};
    var url = baseUrl.URLPath + servicesUrl.getLoadMangList;
    let types = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();
    let payload = {
      "chargePoints": this.state.selectedValues.chargingStation,
      "fromTime": "",
      "groupIds":this.state.selectedValues.group,
      "llmStatus": this.state.selectedValues?.llmStatus?.toString() ? this.state.selectedValues?.llmStatus?.toString() : null,
      "pageNo":this.state.activePage,
      "pageSize":this.state.pageSize,
      "toTime":"",
    };
    let response = await APICallUtility.cpmsAPIFetch(url, types, payload, headers, this.props.t);
    if (response) {
      document.getElementById("loader_image_div").style.display = "none";
        if (response.status === 404 || response.status === 403) {
          
        } else {
          if(response.count===0){
            document.getElementById("loader_image_div").style.display = "none";
            this.setState({isDataAvailable : false})

          }else{

          if(count===false){
            setTimeout(() => {
              this.setState({
                chargerList: response.chargers ? response.chargers :[] ,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                curPageFirstCreTime: moment(response.chargers[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                curPageLastCreTime: moment(response.chargers[response.chargers.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              }, () => {
                  document.getElementById("loader_image_div").style.display = "none";
              })
          }, 100);
          }else{
            setTimeout(() => {
              this.setState({
                chargerList: response.chargers ? response.chargers :[] ,
                noOfRecords: response.count ? response.count : 0,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                curPageFirstCreTime: moment(response.chargers[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                curPageLastCreTime: moment(response.chargers[response.chargers.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              }, () => {
                  document.getElementById("loader_image_div").style.display = "none";
              })
          }, 100);
          }
          }
        }
    }
}

  toggleDropdown = (value) => {
    let errors = {};
    const isOpenState = {};
    isOpenState[value] = !this.state[value];
    if (
      this.state.currentOpenDropdown &&
      this.state.currentOpenDropdown !== value
    ) {
      isOpenState[this.state.currentOpenDropdown] = false;
    }

    this.setState({
      ...isOpenState,
      currentOpenDropdown: value,
    });

    errors["nodatafound"] = "";

    this.setState({
      searchValue: "",
      filterChargersList: [],
      filterGroupList: [],
      errors: errors,
      activeApplyBtn: false,
    });
  };

  removeSelectedValue = (category, value) => {
    const { selectedValues } = this.state;
    const updatedSelectedValues = { ...selectedValues };
    updatedSelectedValues[category] = updatedSelectedValues[category].filter(
      (val) => val !== value
    );
    this.setState(
      {
        selectedOptions: updatedSelectedValues,
        selectedValues: updatedSelectedValues,
        activePage: 1
      },
      () => {
        this.getChargeList();
      }
    );
  };

  removeAllSelected = () => {
    const updatedObject = { ...this.state.selectedValues };
    updatedObject.chargingStation = [];
    updatedObject.group = [];
    updatedObject.llmStatus = [];

    const newObject = { ...this.state.selectedOptions };
    newObject.chargingStation = [];
    newObject.group = [];
    updatedObject.llmStatus = [];

    setTimeout(() => {
      this.setState(
        {
          selectedValues: updatedObject,
          selectedOptions: newObject,
          showLoder: !this.state.showLoder,
          isDataAvailable: true,
          activePage: 1
        },
        () => {
          this.getChargeList();
        }
      );
    }, 1000);
    this.setState({
      showLoder: !this.state.showLoder,
    });
  };

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [
      ...this.state.listOfFirstCreatedtimes,
      dateTime,
    ];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes,
    });
  }



  async extendTrialPeriod(cpid) {  
    
    var url = baseUrl.URLPath + servicesUrl.updateTrialPeriod + "?cpId=" + cpid;  
         
    let type = ApiMethodTypes.PUT;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
        if (response.message === "charger_llm_subscription_extend_success") {
          this.setState({isLlmExtendSuccessPopupOpen : true, isLlmExtendTrialPopupOpen : false})

        }else if(response.message ==="charger_llm_subscription_not_found"){
          this.setState({isExtendTrialAlertPopupOpen : true, isLlmExtendTrialPopupOpen : false})
        }
        return response;
    }
}

async activeChargePoint(cpid) {  
  var url = baseUrl.URLPath + servicesUrl.subsubscribeCP + "?cpId=" + this.state.activeInactiveCp;  
  let type = ApiMethodTypes.PUT;
  let headers = getSecurityHeaders();
  let payload = {}
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if (response !== undefined) {
      if (response.message === "chargers_llm_subscribe_success" || response.message === "charger_llm_subscription_update_success") {
        this.setState({isLLMActivateSuccessPopupOpen:true, isActivatePopupOpen:false})
      }else if(response.message === "charger_llm_subscription_already_exist"){
        //  use alert popup
          this.setState({isAlertForSingleCPLllm : true, isActivatePopupOpen : false})
      } 
      return response;
  }
}
async deactiveChargePoint(cpid) {  
    
  var url = baseUrl.URLPath + servicesUrl.unsubsubscribeCP + "?cpId=" + this.state.activeInactiveCp;  
       
  let type = ApiMethodTypes.PUT;
  let headers = getSecurityHeaders();
  let payload = {}
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if (response !== undefined) {
      if (response.message === "charger_llm_subscription_update_success") {
        this.setState({isLLMDeactivateSuccessPopupOpen : true, isDeactivatePopupOpen:false})
      } 
      return response;
  }
}

async activeMultiChargePoint(cpid) {  
  this.setState({isActivatePopupOpen:false})
  var url = baseUrl.URLPath + servicesUrl.multisubsubscribeCP;  
       
  let type = ApiMethodTypes.POST;
  let headers = getSecurityHeaders();
  let payload = {
    chargepoints:this.state.selectedChargers
  }
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if (response !== undefined) {
      if (response.message === "chargers_llm_subscription_update_success") {
        this.setState({isLLMActivateSuccessPopupOpen : true, isActivatePopupOpen:false})
      } 
      return response;
  }
}
async deactiveMultiChargePoint(cpid) {  
  this.setState({isDeactivatePopupOpen : false})
  var url = baseUrl.URLPath + servicesUrl.multiunsubsubscribeCP;  
  let type = ApiMethodTypes.PUT;
  let headers = getSecurityHeaders();
  let payload = {
    chargepoints:this.state.selectedChargers
  }
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  if (response !== undefined) {
      if (response.message === "chargers_llm_subscription_update_success") {
        this.setState({isLLMDeactivateSuccessPopupOpen : true, isDeactivatePopupOpen : false})
      } 
      return response;
  }
}



  render() {
    const llmStatusList = ["Active", "Inactive", "Expired"]
    return (
      <>
        <LoadManagementTemplate
          filterChargersList={this.state.filterChargersList}
          filterGroupList={this.state.filterGroupList}
          isOpen1={this.state.isOpen1}
          isOpen3={this.state.isOpen3}
          selectedOptions={this.state.selectedOptions}          
          activeApplyBtn={this.state.activeApplyBtn}
          selectedValues={this.state.selectedValues}
          showLoder={this.state.showLoder}
          errors={this.state.errors}
          searchValue={this.state.searchValue}
          searchGroupValue={this.state.searchGroupValue}
          isOpenLLMStatusDropdown = {this.state.isOpenLLMStatusDropdown}
          isActivatePopupOpen = {this.state.isActivatePopupOpen}
          isLLMActivateSuccessPopupOpen = {this.state.isLLMActivateSuccessPopupOpen}
          isDeactivatePopupOpen = {this.state.isDeactivatePopupOpen}
          isLLMDeactivateSuccessPopupOpen = {this.state.isLLMDeactivateSuccessPopupOpen}
          selectedChargers = {this.state.selectedChargers}
          isWarningPopupOpen = {this.state.isWarningPopupOpen}
          isLlmExtendTrialPopupOpen = {this.state.isLlmExtendTrialPopupOpen}
          isLlmExtendSuccessPopupOpen = {this.state.isLlmExtendSuccessPopupOpen}
          filterGroupListName = {this.state.filterGroupListName}
          isAlertForSingleCPLllm = {this.state.isAlertForSingleCPLllm}
          isExtendTrialAlertPopupOpen = {this.state.isExtendTrialAlertPopupOpen}

          chargerList = {this.state.chargerList}
          handleSubmit={this.handleSubmit}
          handleSearchChargersChange={this.handleSearchChargersChange}
          handleSearchGroupChange={this.handleSearchGroupChange}
          getFilterHistory={this.getFilterHistory}
          emptySearchValues={this.emptySearchValues}
          handleCheckboxChange={this.handleCheckboxChange}
          isDataAvailable = {this.state.isDataAvailable}
          activePage={this.state.activePage}
          pageSize={this.state.pageSize}
          noOfRecords={this.state.noOfRecords}
          handlePageChange={this.handlePageChange}
          showEntriesChange = {this.showEntriesChange}
          selectedOption = {this.selectedOption}
          handleChange = {this.handleChange}
          onChangeLlmStatus = {this.onChangeLlmStatus}
          toggleDropdown={this.toggleDropdown}
          removeSelectedValue={this.removeSelectedValue}
          removeAllSelected={this.removeAllSelected}
          llmStatusList = {llmStatusList}
          handleLLMStatusDropdown = {this.handleLLMStatusDropdown}
          handleActivateBtn = {this.handleActivateBtn}
          closeActivateLLMPopup = {this.closeActivateLLMPopup}
          handleActivateSuccess = {this.handleActivateSuccess}
          closeLLMActivatedSuccessPopup = {this.closeLLMActivatedSuccessPopup}
          handleDeactivateBtn = {this.handleDeactivateBtn}
          closeDeactivateLLMPopup = {this.closeDeactivateLLMPopup}
          handleDeactivateSuccess = {this.handleDeactivateSuccess}
          closeLLMDeactivatedSuccessPopup = {this.closeLLMDeactivatedSuccessPopup}
          closeWarningPopup = {this.closeWarningPopup}
          handleExtendTrialBtn = {this.handleExtendTrialBtn}
          closeLlmExtendTrialPopup = {this.closeLlmExtendTrialPopup}
          handleLlmExtendSuccess = {this.handleLlmExtendSuccess}
          closeLlmExtendSuccessPopup = {this.closeLlmExtendSuccessPopup}
          checkIdGetName = {this.checkIdGetName}
          closeAlertForSingleCPPopup = {this.closeAlertForSingleCPPopup}
          closeExtendTrialAlertPopup = {this.closeExtendTrialAlertPopup}
        />
       
      </>

    )
  }

}

export default withTranslation()(LoadManagement);