import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { MDBBtn, MDBRow, MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import $ from "jquery";
import moment from 'moment';
import SuccessPopup from "../common/SuccessPopup"
import CustomModal from '../common/CustomModal';



//common imports
import * as roles from './roles';

//API Imports
import * as baseUrl from './constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

//css imports
import './cpDashboard.css';

class preferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previousSelectedValues: "",
      enablecronjob: "true",
      cronjobdisplay: "",
      emailsecuritydisplay: "",
      startDate1: "",
      lastScheduleDate: "",
      writePermission: this.props.ChargePoint_W, // ChargePoint_W conndition
      page_title: "Preferences",
      message: "",
      display: "none",
      startDateApi: "",
      cpdisabledAttr: "true",
      digdisabledAttr: "true",
      secdisabledAttr: "true",
      emaildisabledAttr: "true",
      crondisabledAttr: "true",
      isChecked: 1,
      action: "Save",
      cpaction: "Save",
      securityaction: "Save",
      cronjobaction: "Save",
      emailaction: "Save",
      getOperationList:[],
      fields1: {
        frequency: "",
        location: "",
        cronfrequency: "",
        startDate: "",
        lastScheduleDate: "",
      },
      fields: {
        serviceTypeChange: "",       
        defaultProfile: "",
        isTxAssociated: 0,
        isDiagnosticSecurityEnabled: false,
        isEnabled: false,
        isBasicSecurityEnabled: false,
        isFreeChargeEnabled:false,
        enableTimeoutmechanism: false,
        isChangePassword: false,
        isRestoreEnable: false
      },
      sampleJSON: {
        object: {
          name: "Pluralsight",
          number: 1,
          address: "India",
        }
      },
      model1: "false",
      model2: "false",
      errors: {},
      activeItem: "1",
      preferenceList: [],
      sucess: false,
      colour: 'sucess',
      modalsession: false,
      modalSuccess: false,
      items: [],
      user: { permissions: [] },
      chargingPowerFrequency:null,
      customizesucess: false,
      customizefail:false,
      tenantfailMessage:"",
      deeplinks:{
        granttype:"",
        clientid:"",
        clientsecret:"",
        resource:""
      },
      deeplinksuccess:false,
      siteLimitValue: null,
      isSitelimitChecked: false,
      siteLimitErrors: {},
      siteLimitSuccess: false,
      isSitemanagementAvailable: false,
      siteLimitError:'',
      errorSiteLimit: false,
      listOfService:[],
      isPaymentOptionEnabled : false,
      paymentMode : "split",
      selectedPercentage1 : "",
      selectedPercentage2 : "",
      isChangesUpdatedPopupOpen : false,
      paymentWarningPopup: false,
      paymentWarningErrMsg : ""
    }
    this.addNew = this.addNew.bind(this)
    this.tagOk1 = this.tagOk1.bind(this)
    this.toggle2 = this.toggle2.bind(this)
    this.toggle1 = this.toggle1.bind(this)
    this.tabToggle = this.tabToggle.bind(this)
    this.toggleAdd = this.toggleAdd.bind(this)
    this.toggleAddCp = this.toggleAddCp.bind(this)
    this.toggleSecurity = this.toggleSecurity.bind(this)
    this.toggleMailNotification = this.toggleMailNotification.bind(this);
    this.toggleCronJob = this.toggleCronJob.bind(this)
    this.getPreferences = this.getPreferences.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.frequencyChange = this.frequencyChange.bind(this);
    this.numberHandler = this.numberHandler.bind(this);
    this.CloseSuccessPopup = this.CloseSuccessPopup.bind(this);
    this.CloseSuccesscpPopup = this.CloseSuccesscpPopup.bind(this);
    this.saveDignostics = this.saveDignostics.bind(this);
    this.saveChargingProfile = this.saveChargingProfile.bind(this);
    this.getchargingprofileData = this.getchargingprofileData.bind(this);
    this.chargingProfileChange = this.chargingProfileChange.bind(this);
    this.getSecurityData = this.getSecurityData.bind(this);
    this.updateCustomization = this.updateCustomization.bind(this);   
    this.CloseSuccesssecurity = this.CloseSuccesssecurity.bind(this);
    this.CloseSuccessemail = this.CloseSuccessemail.bind(this);
    this.CloseSuccesscron = this.CloseSuccesscron.bind(this);
    this.serviceTypeChange = this.serviceTypeChange.bind(this);
    this.getCronData = this.getCronData.bind(this);
    this.lastScheduleDateChange = this.lastScheduleDateChange.bind(this);
    this.saveCronJobChanges = this.saveCronJobChanges.bind(this);
    this.cronfrequencyChange = this.cronfrequencyChange.bind(this);
    this.emailDisableChange = this.emailDisableChange.bind(this);
    this.saveEmailNotificationChanges = this.saveEmailNotificationChanges.bind(this);
    this.getEmailNotificationData = this.getEmailNotificationData.bind(this);
    this.enableTimeoutChange = this.enableTimeoutChange.bind(this);
    this.enablePasswordChange = this.enablePasswordChange.bind(this);
    this.freechargeChange = this.freechargeChange.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.saveChargingPower=this.saveChargingPower.bind(this);
    this.togglechargepower=this.togglechargepower.bind(this);
    this.getChargingPowerData=this.getChargingPowerData.bind(this);
    this.handleOperation=this.handleOperation.bind(this);
    this.handleSubOperation=this.handleSubOperation.bind(this);
    this.toggleCustomization=this.toggleCustomization.bind(this);
    this.closeCustomization=this.closeCustomization.bind(this);
    this.toggleCustomizationfail=this.toggleCustomizationfail.bind(this);
    this.changeHandler=this.changeHandler.bind(this);
    this.getDeeplinkCredentials=this.getDeeplinkCredentials.bind(this);
    this.updateDeeplinkCredentials=this.updateDeeplinkCredentials.bind(this);
    this.toggledeeplink=this.toggledeeplink.bind(this);
    this.handleSiteLimit = this.handleSiteLimit.bind(this);
    this.updateSiteLimit = this.updateSiteLimit.bind(this);
    this.updateSiteLimitCheck = this.updateSiteLimitCheck.bind(this);
    this.toggoleSiteLimitPopupModal = this.toggoleSiteLimitPopupModal.bind(this);
    this.getSiteLimit = this.getSiteLimit.bind(this);
    this.showSiteLimit = this.showSiteLimit.bind(this);
    this.getCustomization = this.getCustomization.bind(this);
    this.saveSecurityChanges = this.saveSecurityChanges.bind(this);
    this.handleOnChangePaymentOption = this.handleOnChangePaymentOption.bind(this);
    this.savePaymentOption = this.savePaymentOption.bind(this);
    this.getPaymentDetails = this.getPaymentDetails.bind(this);

  }

  closeChangesUpdatedPopup=()=>{
    this.setState({ isChangesUpdatedPopupOpen: false })
  }
  toggleWarningPopup = () => {
    this.setState({paymentWarningPopup : !this.state.paymentWarningPopup})
  }

  async savePaymentOption(event){
    event.preventDefault();
    if(this.state.isPaymentOptionEnabled){
      if(this.state.paymentMode === "split" && (this.state.selectedPercentage1 !== undefined && this.state.selectedPercentage2 !== undefined)){
          let ownerShare = this.state.selectedPercentage1.slice(0,-1);
          let platformShare = this.state.selectedPercentage2.slice(0,-1);
          let total = parseInt(ownerShare) + parseInt(platformShare);
          if(total === 100){
            $('.percentages_error').text("");

              // API call for update Split payment
              this.updatePaymentDetails();

          }
          else{
              // show error
              $('.percentages_error').text(this.props.t('percentages_error'));
          }
          
      }
      else{
         // API call for update Direct payment
         this.updatePaymentDetails();
      }

    }else{
      this.updatePaymentDetails();
    }
  }

  updatePaymentDetails = async() => {
    let url = baseUrl.URLPath + servicesUrl.getPaymentDetails;
    let type = ApiMethodTypes.PUT;
    let headers = getSecurityHeaders();
    let payload = {
      "directPaymentEnabled" : this.state.paymentMode === "direct",
      "paymentModeActive" : this.state.isPaymentOptionEnabled,
      "splitDetails" : {
        "ownerShare" : this.state.selectedPercentage1 ? parseFloat(this.state.selectedPercentage1.slice(0,-1)) : null,
        "platformShare" : this.state.selectedPercentage2 ? parseFloat(this.state.selectedPercentage2.slice(0,-1)) : null
      },
      "splitEnabled" : this.state.paymentMode === "split",
    }

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if(response !== undefined){
      if(response.message === "Organization payment details updated successfully" || response.message === "oranization_payment_details_update_success"){
        this.setState({isChangesUpdatedPopupOpen : true})
      }else{
        this.setState({paymentWarningPopup: true,
          paymentWarningErrMsg : response.message})
      }
    }
    
  }

  handleChangeInPercentage1 = (e) => {
    $('.percentages_error').text("");
    this.setState({selectedPercentage1 : e.target.value})
  }
  handleChangeInPercentage2 = (e) => {
    $('.percentages_error').text("");
    this.setState({selectedPercentage2 : e.target.value})
  }
  handleOnchangePaymentMode = (id) => {
    this.setState({paymentMode : id})
  }

  handleOnChangePaymentOption(e){
    this.setState({isPaymentOptionEnabled : !this.state.isPaymentOptionEnabled})
  }

  async getCustomization() {
    let tenant = localStorage.getItem('tenant');
    let url = baseUrl.URLPath + servicesUrl.getCustomizationWithTenant + tenant;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if(response){
      if (response && Array.isArray(response)) {
        const siteManagementObj = response?.find(el => el.operation === "Group Management");
        if (siteManagementObj) {
          this.setState({ ...this.state, isSitemanagementAvailable: siteManagementObj.active });
        } else {
          this.setState({ ...this.state, isSitemanagementAvailable: false });
        }
      }
  
      this.setState({ getOperationList: response ?? [] })
    }    
  }

  showSiteLimit(){
    this.setState({ errorSiteLimit:!this.state.errorSiteLimit });
  }

  toggledeeplink(){
    this.setState({ deeplinksuccess: !this.state.deeplinksuccess });
  }

  async getDeeplinkCredentials() {
    var url = baseUrl.URLPath + servicesUrl.getDeeplinkCredentials;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    // payload
    let payload = {}

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      let deeplinks = this.state.deeplinks;
      deeplinks["granttype"] = response.grantType;
      deeplinks["clientid"] = response.clientId;
      deeplinks["clientsecret"] = response.clientSecret;
      deeplinks["resource"] = response.resource;
      this.setState({ deeplinks });
    }
  }

  async updateDeeplinkCredentials(){

    let deeplink_json = {
      "grantType": this.state.deeplinks.granttype,
      "clientId": this.state.deeplinks.clientid,
      "clientSecret": this.state.deeplinks.clientsecret,
      "resource": this.state.deeplinks.resource
    }

    if(this.state.deeplinks.granttype.trim()===""){
      $('.error_granttype').text(this.props.t('grant_must_not_empty'));
    }
    if(this.state.deeplinks.clientid.trim()===""){
      $('.error_clientid').text(this.props.t('clientid_must_not_empty'));
    }
    if(this.state.deeplinks.clientsecret.trim()===""){
      $('.error_clientsecret').text(this.props.t('secret_must_not_empty'));
    }
    if(this.state.deeplinks.resource.trim()===""){
      $('.error_resource').text(this.props.t('resource_must_not_empty'));
    }

    var url = baseUrl.URLPath + servicesUrl.getDeeplinkCredentials;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();

    if(this.state.deeplinks.granttype.trim()!=="" && this.state.deeplinks.clientid.trim()!=="" && this.state.deeplinks.clientsecret.trim()!=="" && this.state.deeplinks.resource.trim()!==""){
      let response = await APICallUtility.cpmsAPIFetch(url, type, deeplink_json, headers, this.props.t);

      if (response !== undefined) { this.toggledeeplink(); }
    }
  }

  changeHandler(field,e){
    let deeplinks = this.state.deeplinks;
    deeplinks[field]=e.target.value;
    this.setState({deeplinks});
    $(`.error_${field}`).text("");
  }
  toggleCustomization(){
    this.setState({
      customizesucess: !this.state.customizesucess
    });
  }
  toggleCustomizationfail(){
    this.setState({
      customizefail: !this.state.customizefail
    });
  }
  
  closeCustomization(){
    window.location.reload();
  }
  lastScheduleDateChange = date => {
    this.setState({ crondisabledAttr: "" });
    this.setState({ lastScheduleDate: date });
    var currentDate = new Date(date);
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var createdDateTo = twoDigitMonth + "-" + twoDigitDate + "-" + currentDate.getFullYear() + ' ' + twohoursDate + ':' + twominDate; 
    this.setState({ startDate1: createdDateTo });
    if (createdDateTo === "01-01-1970 05:30") {
      this.setState({ startDate1: "" });
    }
  };

  diagnosticDisableChange() {
    this.setState({ secdisabledAttr: "" });
    let fields = this.state.fields;

    var diagnosticSecurity = document.getElementById("diagnosticEnable").checked;
    if (diagnosticSecurity) {
      fields.isDiagnosticSecurityEnabled = true;
      this.setState({ isDiagnosticSecurityEnabled: true, fields })

    }
    else {
      fields.isDiagnosticSecurityEnabled = false;
      this.setState({ isDiagnosticSecurityEnabled: false, fields })

    }
  }

  emailDisableChange() {
    this.setState({ emaildisabledAttr: "" });
    let fields = this.state.fields;

    var emailSecurity = document.getElementById("emailEnable").checked;
    if (emailSecurity) {
      fields.isEnabled = true;
      this.setState({ isEnabled: true, fields })

    }
    else {
      fields.isEnabled = false;
      this.setState({ isEnabled: false, fields })

    }
  }
  basicSecurityChange() {
    this.setState({ secdisabledAttr: "" });
    let fields = this.state.fields;

    var basicSecurity = document.getElementById("basicEnable").checked;
    if (basicSecurity) {
      fields.isBasicSecurityEnabled = true;
      this.setState({ isBasicSecurityEnabled: true, fields })

    }
    else {
      fields.isBasicSecurityEnabled = false;
      this.setState({ isBasicSecurityEnabled: false, fields })

    }
  }
  enableTimeoutChange() {
    this.setState({ secdisabledAttr: "" });
    let fields = this.state.fields;

    var timeOutMechanism = document.getElementById("enableTimeout").checked;
    if (timeOutMechanism) {
      fields.enableTimeoutmechanism = true;
      this.setState({ enableTimeoutmechanism: true, fields })

    }
    else {
      fields.enableTimeoutmechanism = false;
      this.setState({ enableTimeoutmechanism: false, fields })

    }
  }

  enablePasswordChange() {
    this.setState({ secdisabledAttr: "" });
    let fields = this.state.fields;

    var passwordChange = document.getElementById("enablePassword").checked;
    if (passwordChange) {
      fields.isChangePassword = true;
      this.setState({ isChangePassword: true, fields })

    }
    else {
      fields.isChangePassword = false;
      this.setState({ isChangePassword: false, fields })

    }
  }

  freechargeChange() {
    let fields = this.state.fields;
    this.setState({ secdisabledAttr: "" });

    var freecharge = document.getElementById("freechargeEnable").checked;
    if (freecharge) {
      fields.isFreeChargeEnabled = true;
      this.setState({ isFreeChargeEnabled: true, fields })

    }
    else {
      fields.isFreeChargeEnabled = false;
      this.setState({ isFreeChargeEnabled: false, fields })

    }
  }

  restoreConfigFeature() {
    let fields = this.state.fields;

    this.setState({ secdisabledAttr: "" });

    var RestoreEnable = document.getElementById("RestoreEnable").checked;
    if (RestoreEnable) {
      fields.isRestoreEnable = true;
      this.setState({ isRestoreEnable: true, fields })
    } else {
      fields.isRestoreEnable = false;
      this.setState({ isRestoreEnable: false, fields })
    }
  }

  


  async togglesession() {
    ;
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  toggleChange = () => {
    let fields = this.state.fields;
    var x = document.getElementById("charging_profiletxn").checked;

    if (x) {
      fields.isTxAssociated = 1;
    } else {
      fields.isTxAssociated = 0;
    }
    
    this.setState({ cpdisabledAttr: "", fields });
  }

  toggleAdd() {
    this.setState({
      modalSuccess: !this.state.modalSuccess
    });
  }

  toggleAddCp() {
    this.setState({
      modalSuccesscp: !this.state.modalSuccesscp
    });
  }
  toggleSecurity() {
    this.setState({
      modalSuccesssecurity: !this.state.modalSuccesssecurity
    });
  }
  toggleMailNotification() {
    this.setState({
      modalSuccessemail: !this.state.modalSuccessemail
    });
  }
  toggleCronJob() {
    this.setState({
      modalSuccesscron: !this.state.modalSuccesscron
    });
  }
  togglechargepower(){
    this.setState({
      chargepowersucess: !this.state.chargepowersucess
    });
  }
  CloseSuccesscpPopup() {
    this.setState({
      modalSuccesscp: !this.state.modalSuccesscp
    });
  }
  CloseSuccesssecurity() {
    this.setState({
      modalSuccesssecurity: !this.state.modalSuccesssecurity
    });
  }
  CloseSuccessemail() {
    this.setState({
      modalSuccessemail: !this.state.modalSuccessemail
    });
  }
  CloseSuccesscron() {
    this.setState({
      modalSuccesscron: !this.state.modalSuccesscron
    });
  }

  CloseSuccessPopup() {
    this.setState({
      modalSuccess: !this.state.modalSuccess
    });
  }

  sessiontagOk() {
    this.props.history.push('/');
  }

  onShowAlert() {
    
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  toggle1(event) {
    event.preventDefault();
    ;
    this.setState({
      modal1: !this.state.modal1
    });
  }

  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }

  tagOk1() {
    this.props.history.push('/chargepoint');
  }

  componentDidMount() {
    this.getChargingProfile();
    this.getPreferences();
    this.getCustomization();
    this.getServiceType();
  }

  async getServiceType(){
    let url = baseUrl.URLPath + servicesUrl.getServiceUrl;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let result = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (result) {
      this.setState({
        listOfService: result
      })
    }
  }

  chargingProfileChange() {
    var defaultProfile = document.getElementById("defaultcpId").value;
    let fields = this.state.fields;
    fields["defaultProfile"] = defaultProfile;
    this.setState({ cpdisabledAttr: "", fields });

  }

  frequencyChange(event) {
    var curr = new Date();
    var frequency = document.getElementById("frequencyId").value;
    let fields = this.state.fields1;
    fields["frequency"] = frequency;
    let display = "none";
    let startDateApi = "";

    if (frequency === "") {
      fields["startDate"] = "";
      startDateApi = "";
    }

    if (frequency === "Daily") {
      startDateApi = curr;
      curr = moment(curr).format("DD-MMM-YYYY")
      fields["startDate"] = curr;
      display = "block";
    }

    if (frequency === "Weekly") {
      var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      // var last = first + 6; // last day is the first day + 6
      var firstday = new Date(curr.setDate(first + 1)).toUTCString();
      startDateApi = firstday;
      firstday = moment(firstday).format("DD-MMM-YYYY")
      fields["startDate"] = firstday;
      display = "block";
    }
    if (frequency === "Monthly") {
      var date = new Date();
      var firstDay1 = new Date(date.getFullYear(), date.getMonth(), 1);
      startDateApi = firstDay1;
      firstDay1 = moment(firstDay1).format("DD-MMM-YYYY")
      fields["startDate"] = firstDay1
      display = "block";
    }

    this.setState({ digdisabledAttr: "", fields, display: display, startDateApi: startDateApi });
  }

  async getChargingProfile() {
    var url = baseUrl.URLPath + servicesUrl.getChargingProfile;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    // payload
    let payload = {}

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({ items: response })
    }
  }

  // tab view click
  tabToggle(tab, event) {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
      if (tab === "1") {
        this.getPreferences();
      } else if (tab === "2") {
        this.getChargingPowerData();
      } else if (tab === "3") {
        this.getchargingprofileData();
      } else if (tab === "5") {
        let fields1 = this.state.fields1;
        fields1.cronfrequency = "-- Select --";

        this.setState({ fields1 })
      } else if (tab === "6") {
        this.getEmailNotificationData();
      } else if (tab === "4") {
        this.getSecurityData();
      } else if (tab === "7") { } 
      else if (tab === "8") {
        this.getDeeplinkCredentials();
      } else if (tab === "9") {
        this.getSiteLimit();
      }
      else if(tab === "10"){
        this.getPaymentDetails();
      }
    }
  }

  async getPaymentDetails(){
    let url = baseUrl.URLPath + servicesUrl.getPaymentDetails;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if(response !== undefined){
      let isPaymentOptionEnabled = response.paymentModeActive
      let paymentMode;
      let selectedPercentage1;
      let selectedPercentage2;
      if(response.splitEnabled){
        paymentMode = 'split'
        selectedPercentage1 = response.splitDetails ? parseInt(response.splitDetails["ownerShare"], 10) + '%' : '';
        selectedPercentage2 = response.splitDetails ? parseInt(response.splitDetails["platformShare"], 10) + '%' : '';
      }else{
        paymentMode = 'direct'
      }
      
      this.setState({
        isPaymentOptionEnabled, paymentMode, selectedPercentage1, selectedPercentage2
        
      })

    }

  }

  /* To get preferences list*/
  async getPreferences() {
    let url = baseUrl.URLPath + servicesUrl.getPreferences;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    // payload
    let payload = {}

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({
        fields1: response,
        action: (response.location === "" && response.frequency === "") ? "Save" : "Update"
      })
    }
  }

  /* To get preferences list*/
  async getchargingprofileData() {
    let url = baseUrl.URLPath + servicesUrl.getPreferencesProfiles;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    // payload
    let payload = {}

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({
        fields: response,
        action: (response?.location === "" && response?.frequency === "") ? "Save" : "Update",
        cpaction: (response?.defaultProfile === null && response?.isTxAssociated === null) ? "Save" : "Update",
        isChecked: response?.isTxAssociated
      }, ()=>{
        if (response.isTxAssociated === true) {
          document.getElementById("charging_profiletxn").checked = true;
        }
      })
    }
  }

  /* To get SecurityData list*/
  async getSecurityData() {
    let url = baseUrl.URLPath + servicesUrl.getSecurityPreferences;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    // payload
    let payload = {}

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      if (response.isDiagnosticSecurityEnabled === null && response.isBasicSecurityEnabled === null && response.enableTimeoutmechanism === null && response.isChangePassword === null && response.isFreeChargeEnabled === null) {
        this.setState({
          securityaction: "Save"
        })
      } else {
        this.setState({
          securityaction: "Update"
        })
      }

      this.setState({
        fields: response
      });

      let fields = this.state.fields;

      fields.isDiagnosticSecurityEnabled = response.isDiagnosticSecurityEnabled;
      fields.isBasicSecurityEnabled = response.isBasicSecurityEnabled;
      fields.enableTimeoutmechanism = response.enableTimeoutmechanism;
      fields.isChangePassword = response.isChangePassword;
      fields.isFreeChargeEnabled = response.isFreeChargeEnabled;
      fields.isRestoreEnable = response.isRestoreEnable;

      if (response.isDiagnosticSecurityEnabled === true) {
        document.getElementById("diagnosticEnable").checked = true;
      }
      if (response.isBasicSecurityEnabled === true) {
        document.getElementById("basicEnable").checked = true;
      }
      if (response.enableTimeoutmechanism === true) {
        document.getElementById("enableTimeout").checked = true;
      }
      if (response.isChangePassword === true) {
        document.getElementById("enablePassword").checked = true;
      }
      if (response.isFreeChargeEnabled === true) {
        document.getElementById("freechargeEnable").checked = true;
      }
      if (response.isRestoreEnable === true) {
        document.getElementById("RestoreEnable").checked = true;
      }

      this.setState({ fields })
    }
  }

  async saveDignostics(event) {
    event.preventDefault();

    let errors = {};
    var location = this.state.fields1['location'];
    var frequency = document.getElementById("frequencyId").value;
    var startDate = this.state.startDateApi;

    if (startDate !== "") { startDate = moment(startDate).format("MM-DD-YYYY HH:mm") }
    
    let url = baseUrl.URLPath + servicesUrl.getPreferences;
    let type = ApiMethodTypes.PUT;
    let headers = getSecurityHeaders();

    // payload
    let payload = { "frequency": frequency, "location": location, "startDate": startDate };

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if(!response.hasOwnProperty('status')){
      this.toggleAdd()
      this.props.history.push('/preferences');
    }

    this.setState({ errors: errors });
  }

  async saveChargingProfile(event) {
    event.preventDefault();

    let errors = {};
    var defaultProfile = document.getElementById("defaultcpId").value;
    var isTxAssociated = this.state.fields['isTxAssociated'];

    let url = baseUrl.URLPath + servicesUrl.getPreferencesProfiles;
    let type = ApiMethodTypes.PUT;
    let headers = getSecurityHeaders();

    // payload
    let payload = { "defaultProfile": defaultProfile, "isTxAssociated": isTxAssociated };

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if(!response.hasOwnProperty('status')){
      this.toggleAddCp()
      this.props.history.push('/preferences');
    }

    this.setState({ errors: errors });
  }

  async updateCustomization(event) {
    event.preventDefault();
    let tenant = localStorage.getItem('tenant');
    let url = baseUrl.URLPath + servicesUrl.updateCustomizationTenant + tenant;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();

    // payload
    let payload = this.state.getOperationList;

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response.success === true) {
      this.toggleCustomization();
    } else if (response.status === 400 || response.status === 409) {
      this.setState({ tenantfailMessage: response.message }, () => { this.toggleCustomizationfail(); });
    }
  }

  async saveSecurityChanges(event) {
    event.preventDefault();
    let errors = {};
    var isDiagnosticSecurityEnabled = this.state.fields['isDiagnosticSecurityEnabled'];
    var isBasicSecurityEnabled = this.state.fields['isBasicSecurityEnabled'];
    var enableTimeoutmechanism = this.state.fields['enableTimeoutmechanism'];
    var isChangePassword = this.state.fields['isChangePassword'];
    var isFreeChargeEnabled = this.state.fields['isFreeChargeEnabled'];
    var isRestoreEnable = this.state.fields['isRestoreEnable'];

    let url = baseUrl.URLPath + servicesUrl.getSecurityPreferences;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();
    let payload = { "isDiagnosticSecurityEnabled": isDiagnosticSecurityEnabled, "isBasicSecurityEnabled": isBasicSecurityEnabled, "enableTimeoutmechanism": enableTimeoutmechanism, "isChangePassword": isChangePassword, "isFreeChargeEnabled":isFreeChargeEnabled,"isRestoreEnable":isRestoreEnable};
    
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if(!response.hasOwnProperty('status')){
      this.toggleSecurity()
      this.props.history.push('/preferences');
    }

    this.setState({ errors: errors });
  }

  addNew() {
    this.props.history.push('/addPreference');
  }

  numberHandler(field, e) {
    this.setState({ digdisabledAttr: "" });
    
    let fields1 = this.state;
    if (e.target.validity.valid) {
      fields1[field] = e.target.value;
      this.setState({ fields1 });
    } else if (e.target.value === "" || e.target.value === ".") {
      fields1[field] = e.target.value;
      this.setState({ fields1 });
    }
  }

  // method to call onChange frequency 
  serviceTypeChange() {
    
    this.setState({ crondisabledAttr: "" });
    var id = document.getElementById("serviceType").value;
    var freqtab=document.getElementById("freqtab")
    let fields = this.state.fields;
    fields["serviceTypeChange"] = id;
    if (this.state.fields.serviceTypeChange === "VENDOR_SUPPORT"){
      freqtab.style.display='none'
    }else{
      freqtab.style.display='block'
    }
    if (this.state.fields.serviceTypeChange !== "") {

      this.setState({ serviceTypeChange: id }, () => {
        this.getCronData();
      });

      localStorage.setItem("saveService", this.state.fields.serviceTypeChange);
    }
  }
  cronfrequencyChange() {
    this.setState({ crondisabledAttr: "" });

    var frequency = document.getElementById("cronfrequency").value;
    let fields = this.state.fields1;
    fields["cronfrequency"] = frequency;
    this.setState({ cronfrequency: frequency });



  }

  /* To get cronjob list*/
  async getCronData() {
    let errors = {};
    let fields = this.state.fields1;

    let serviceType = this.state.fields.serviceTypeChange;
    let url = baseUrl.URLPath + servicesUrl.getTenantConfigurationServiceType + "?serviceType=" + serviceType;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      fields.cronfrequency = response.frequency;
      fields.lastScheduleDate = response.lastScheduledDate;

      // this.state.fields1.cronfrequency = response.frequency;
      // this.state.fields1.lastScheduleDate = response.lastScheduledDate;
      localStorage.setItem("frequency", fields.cronfrequency)
      localStorage.setItem("lastScheduledDate", fields.lastScheduleDate)

      if (fields.lastScheduleDate !== null) {
        this.setState({ lastScheduleDate: new Date(fields.lastScheduleDate) })
      } else {
        this.setState({ lastScheduleDate: "" })
      }

      if (fields.cronfrequency === "" || fields.cronfrequency === "-- Select --") {
        fields.cronfrequency = "-- Select --";
      } else if (fields.cronfrequency !== null) {
        this.setState({ cronfrequency: fields.cronfrequency })
      } else {
        fields.cronfrequency = "-- Select --";
      }

      this.setState({ fields1: fields })

      if (response.serviceType === null && response.frequency === null && response.lastScheduledDate === null) {
        this.setState({ cronjobaction: "Update" });
      } else {
        this.setState({ cronjobaction: "Update", crondisabledAttr: "" });
      }

      errors["frequencyEmpty"] = "";
      this.setState({ fields1: response, errors: errors })
    }
  }
  
  async saveCronJobChanges(event) {
    event.preventDefault();
    let errors = {};
    var data = {};

    var serviceType = localStorage.getItem("saveService");
    var frequency = this.state.fields1['cronfrequency'];

    if (this.state.fields.serviceTypeChange === undefined || this.state.fields.serviceTypeChange === "") {
      errors["serviceTypeEmpty"] = this.props.t('please_select_service');
    }

    if (serviceType === "" || serviceType === undefined) {
      errors["serviceTypeEmpty"] = this.props.t('please_select_service');
    }

    if (frequency === null || frequency === undefined) {
      frequency = localStorage.getItem("frequency");
    }

    if (frequency === null || frequency === "" || frequency === undefined || frequency === "-- Select --") {
      errors["frequencyEmpty"] = this.props.t('please_select_frequency');
    }

    if (errors.hasOwnProperty("frequencyEmpty") || errors.hasOwnProperty("serviceTypeEmpty")) { }
    else {
      let url = baseUrl.URLPath + servicesUrl.getTenantConfig;
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();

      if (this.state.startDate1 === "01-01-1970 05:30") {
        data = { "serviceType": serviceType, "frequency": frequency, "lastScheduledDate": "" };
      } else {
        if (this.state.startDate1 === "" || this.state.startDate1 === null) {
          var getResponseDate = localStorage.getItem("lastScheduledDate")
          if (getResponseDate === null) {
            data = { "serviceType": serviceType, "frequency": frequency, "lastScheduledDate": "" };
          } else {
            data = { "serviceType": serviceType, "frequency": frequency, "lastScheduledDate": getResponseDate };
          }
        } else {
          data = { "serviceType": serviceType, "frequency": frequency, "lastScheduledDate": this.state.startDate1 };
        }
      }

      let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t);

      if (!response.hasOwnProperty('status')) {
        this.toggleCronJob()
        this.props.history.push('/preferences');
      }

      this.setState({ errors: errors });
    }
  }

  async saveEmailNotificationChanges(event) {
    event.preventDefault();

    let errors = {};
    var isEnabled = this.state.fields['isEnabled'];

    let url = baseUrl.URLPath + servicesUrl.EmailNotifications;
    let type = ApiMethodTypes.PUT;
    let headers = getSecurityHeaders();
    let payload = { "isEnabled": isEnabled };

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (!response.hasOwnProperty('status')) {
      this.toggleMailNotification();
      this.props.history.push('/preferences');
    }

    this.setState({ errors: errors });
  }

  /* To get EmailNotificationData list*/
  async getEmailNotificationData() {
    let url = baseUrl.URLPath + servicesUrl.EmailNotifications;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    //payload
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      if (response.isEnabled === null) {
        this.setState({
          emailaction: "Save"
        })
      } else {
        this.setState({
          emailaction: "Update"
        })
      }

      // this.state.fields.isEnabled = response.isEnabled;

      this.setState({
        fields: response,
      })


      if (response.isEnabled === true) {
        document.getElementById("emailEnable").checked = true;
      }

    }
  }

  handleChange(e){
    $('.error_frequency').text("");
       this.setState({chargingPowerFrequency: e.target.value})
   
  }

  async saveChargingPower(){
    const url = baseUrl.URLPath + servicesUrl.saveChargingPower;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();

    // payload
    let payload = {
      wallBoxCombinedPowerFrequency:this.state.chargingPowerFrequency,
      tenant:localStorage.getItem("tenant")
    }     

    if (this.state.chargingPowerFrequency !== "" && this.state.chargingPowerFrequency !== null && this.state.chargingPowerFrequency > 0) {
      $('.error_frequency').text("");
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      if(response !== undefined){
        this.togglechargepower();
        this.setState({
          chargingPowerFrequency: response.wallBoxCombinedPowerFrequency
        });
      }
    } else {
      $('.error_frequency').text("frequency should be greater than 0");
    }
  }

  async getChargingPowerData() {
    const url = baseUrl.URLPath + servicesUrl.getCombinedChargingPowerFrequency;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    //payload
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if(response !== undefined){
      this.setState({ chargingPowerFrequency: response });
    }
  }

  handleOperation(operation){
    let temparray=this.state.getOperationList;
    for(let i=0;i<temparray.length;i++){
      if(temparray[i].operation === operation){
        temparray[i].active=!temparray[i].active;
      }
    }
    this.setState({
      getOperationList:temparray
    });
  }
  
  handleSubOperation(operation,subOperation){
    let temparray=this.state.getOperationList;
    for(let i=0;i<temparray.length;i++){
      if(temparray[i].operation === operation){
        for(let j=0;j<temparray[i].subOperations.length;j++){
          if(temparray[i].subOperations[j].name === subOperation){
            temparray[i].subOperations[j].access = !temparray[i].subOperations[j].access;
          }
        }
      }
    }
    this.setState({
      getOperationList:temparray
    });
  }

  handleSiteLimit(event) {
    this.setState({...this.state, siteLimitValue: event.target.value, siteLimitErrors: {}});
  }

  async updateSiteLimit() {
    const { isSitelimitChecked, siteLimitValue } = this.state;
    const data = { isSitelimitChecked };
    const errors = {};
    if(isSitelimitChecked) {
      data.sitelimit = siteLimitValue;
      if(siteLimitValue < 1 || isNaN(siteLimitValue)) {
        errors.siteLimitValue = this.props.t('enter_value_greater_than0');
      }
    }

    if(Object.keys(errors).length > 0) {
      this.setState({...this.state, siteLimitErrors: errors});
      return;
    }

    const url = baseUrl.URLPath + servicesUrl.saveSiteLimit;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();

    let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t);
    
    if(response.status === 400){
      this.setState({ siteLimitError: this.props.t(response.message) }, () => {
        this.showSiteLimit();
      });
    } else if (!response.hasOwnProperty('status')) {
      this.setState({...this.state, siteLimitSuccess: true});
    }
  }

  updateSiteLimitCheck(event) {
    this.setState({...this.state, isSitelimitChecked: event.target.checked});
  }

  toggoleSiteLimitPopupModal() {
    this.setState({...this.state, siteLimitSuccess: !this.state.siteLimitSuccess});
  }

  async getSiteLimit() {
    const url = baseUrl.URLPath + servicesUrl.getSiteLimit;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    
    //payload
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      const { isSitelimitChecked, sitelimit } = response;
      this.setState({ ...this.state, isSitelimitChecked, siteLimitValue: sitelimit });
    }
  }
  generatePercentageOptions =()=> {
    const percentages = [];
    for (let i = 0; i <= 100; i++) {
      percentages.push(`${i}%`);
    }
    return percentages;
  }

  render() {
    const{t}=this.props;
    const percentageOptions = this.generatePercentageOptions();

    let logOffAccount = localStorage.getItem("tenant");
    let getTenanatFord = localStorage.getItem("switchOrgName");

    if (getTenanatFord !== "FORD" && getTenanatFord !== "webasto" && getTenanatFord !== null) {
      localStorage.removeItem("enableFord")
    }

    let emailsecuritydisplay = "";
    let cronjobdisplay = "";

    let getTenant = localStorage.getItem("enableFord");

    if (getTenant === "ford" || getTenanatFord === "ford" || logOffAccount === "ford" ||
		getTenant === "webasto" || getTenanatFord === "webasto" || logOffAccount === "webasto") {
      cronjobdisplay = "block";
      emailsecuritydisplay = "block";

      // condition for enablecronjob
      let cronJob = localStorage.getItem("cronJobStatus")
      if (this.state.enablecronjob === cronJob) {
        cronjobdisplay = "block";
        emailsecuritydisplay = "block";
      } else {
        cronjobdisplay = "none";
        emailsecuritydisplay = "none";
      }
    } else {
      cronjobdisplay = "none";
      emailsecuritydisplay = "none";
    }

    var cpActionText = "";
    if (this.state.cpaction === "Save") {
      cpActionText = t('added');
    }
    if (this.state.cpaction === "Update") {
      cpActionText = t('updated');
    }
    var digAction_text = "";
    if (this.state.action === "Save") {
      digAction_text = t('added');
    }
    if (this.state.action === "Update") {
      digAction_text = t('updated');
    }
    var securityActionText = "";
    if (this.state.securityaction === "Save") {
      securityActionText = t('added');
    }
    if (this.state.securityaction === "Update") {
      securityActionText = t('updated');
    }

    var emailActionText = "";
    if (this.state.emailaction === "Save") {
      emailActionText = this.props.t('added');
    }
    if (this.state.emailaction === "Update") {
      emailActionText = this.props.t('updated');
    }

    var cronjobActionText = "";
    if (this.state.cronjobaction === "Save") {
      cronjobActionText = this.props.t('added');
    }
    if (this.state.cronjobaction === "Update") {
      cronjobActionText = this.props.t('updated');
    }



    let ChargingprofileData = "";
    if (this.state.items.length !== 0) {
      ChargingprofileData = this.state.items.map((data, index) =>
        <option value={data.chargingProfileId} >{data.chargingProfileName}</option>
      );
    }

    let listOfServiceOption = this.state.listOfService.map((model, index) => {
      return (
          <option value={model}>{model}</option>
      )
  });

    return (
      <>

        <main class="content-div" >
          <p>{t('Preferences')}
              <div className="breadcrumb_div">
              {t('Settings')} &gt; <span className="breadcrumb_page"> {t('Preferences')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <form
              className='needs-validation'
              onSubmit={this.toggle1}
              noValidate
            >
              <MDBNav className=" p-rel border-0">
                <MDBNavItem className="nav-first">
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "1" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "1")} role="tab" >
                    {t('diagnostics')}
            </MDBNavLink>
                </MDBNavItem>
           
                <MDBNavItem className="">
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "3" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "3")} role="tab" >
                    {t('chargingProfileId')}
            </MDBNavLink>
                </MDBNavItem>

                {
                   (localStorage.getItem("role") === "WEBASTO_SUPER_ADMIN_DEVOPS" && localStorage.getItem('tenant')==="webasto") ? 
                  ( <MDBNavItem className="">
                   <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "10" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "10")} role="tab" >
                     {t('payment')}
                    </MDBNavLink>
                 </MDBNavItem>) : null
                }

                <MDBNavItem className="">
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "2" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "2")} role="tab" >
                    {t('charging_power')}
                  </MDBNavLink>
                </MDBNavItem>
                {
                  (localStorage.getItem("role") === "WEBASTO_SUPER_ADMIN_DEVOPS" || localStorage.getItem("role") === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE")? (
                    <MDBNavItem className="">
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "7" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "7")} role="tab" >
                    {t('customization')}
                  </MDBNavLink>
                </MDBNavItem>
                  ):null
                }
                {this.state.isSitemanagementAvailable && <MDBNavItem className="">
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "9" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "9")} role="tab" >
                   {t('group_limit')}
                  </MDBNavLink>
                </MDBNavItem>}
                <MDBNavItem className={(getTenant === "ford" || getTenanatFord === "FORD" || logOffAccount === "ford" || getTenant === "webasto" || getTenanatFord === "WEBASTO" || logOffAccount === "webasto")? "":"nav-last"}>
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "4" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "4")} role="tab" >
                    {t('security')}
            </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem style={{ display: cronjobdisplay }} className="">
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "5" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "5")} role="tab" >
                    {t('cron_job')}
            </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem style={{ display: emailsecuritydisplay }} className={(this.props.deeplink_R)? "":"nav-last"}>
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "6" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "6")} role="tab" >
                    {t('email_notification')}
            </MDBNavLink>
                </MDBNavItem>
                {
                  (localStorage.getItem('tenant')==="ford" && this.props.deeplink_R)? 
                  <MDBNavItem  className="nav-last">
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem === "8" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "8")} role="tab" >
                   {t('deeplink_credentials')}
                  </MDBNavLink>
                </MDBNavItem>:""
                }
                
              </MDBNav>

              <MDBTabContent activeItem={this.state.activeItem} className="pt-2 p-rel border-0" >
                {/* start diagnostics */}
                <MDBTabPane tabId="1" role="tabpanel">
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <div className="row mb-3 mt-3">

                      <MDBCol md="5" className="mt-2">
                        <label className="pull-left mr-3 mt-2 pt-1">{t('frequency')}</label>
                        <select className="browser-default custom-select select_height w-70" id="frequencyId" onChange={this.frequencyChange.bind(this)} value={this.state.fields1.frequency} >
                          <option value="">--{t('select_frequency')}--</option>
                          <option value="Daily">{t('daily')}</option>
                          <option value="Weekly">{t('weekly')}</option>
                          <option value="Monthly">{t('monthly')}</option>
                        </select>
                      </MDBCol>

                      <MDBCol md="5" className="mt-2">
                        <MDBInput
                          value={this.state.fields1.location}
                          name="location"
                          onInput={this.numberHandler.bind(this, "location")}
                          type="text"
                          label={t('location_directory_uri')+" *"}
                        >
                          <span className="error_msg w3-animate-top" >  <span style={{ color: "red" }} >{this.state.errors["locationUrlError"]}</span></span>
                        </MDBInput>
                        <label className="pull-left mr-3 mt-0 pt-1">{t('note_Default_path')}</label>

                      </MDBCol>
                      <MDBCol md="4" className="mt-4" style={{ "display": this.state.display }}>
                        <label>{t('start_date')} {this.state.fields1.startDate} </label>
                      </MDBCol>
                      <MDBCol md="12" className="text-right mt-3">
                        {this.state.action === "Update" ? <button className="refresh-btn mt-5" disabled={this.state.digdisabledAttr} onClick={this.saveDignostics}>{t('update')}</button> : ""}
                        {this.state.action === "Save" ? <button className="refresh-btn mt-5" onClick={this.saveDignostics}><i class="fas fa-save mr-2"></i>{t('save')}</button> : ""}
                      </MDBCol>
                    </div>
                  </form>
                </MDBTabPane>
                {/* end diagnostics */}
                <MDBTabPane tabId="3" role="tabpanel">
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <div className="row mb-3 mt-3">
                      <MDBCol md="5" className="mt-2">
                        <label className="pull-left mr-3 mt-2 pt-1">{t('default_charging_profile')}</label>
                        <select className="browser-default custom-select select_height w-50" id="defaultcpId" onChange={this.chargingProfileChange.bind(this)} value={this.state.fields.defaultProfile}>
                          <option value="" >-- {t('select_label')} --</option>
                          {ChargingprofileData}
                        </select>
                      </MDBCol>
                      <MDBCol md="7" className="mt-2">
                        <Form.Check
                          className="mt-3"
                          custom
                          value={this.state.isChecked}
                          onChange={this.toggleChange}
                          type="checkbox"
                          id="charging_profiletxn"
                          label={t('transaction_associated_with_charging_profile')}
                        />
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} >{this.state.errors["locationUrlError"]}</span></span>
                      </MDBCol>
                      <MDBCol md="12" className="text-right mt-3">
                        {this.state.cpaction === "Update" ? <button className="mt-5 refresh-btn" disabled={this.state.cpdisabledAttr} onClick={this.saveChargingProfile}>{t('update')}</button> : ""}
                        {this.state.cpaction === "Save" ? <button className="mt-5 refresh-btn" onClick={this.saveChargingProfile}><i class="fas fa-save mr-2"></i>{t('save')}</button> : ""}
                      </MDBCol>
                    </div>
                  </form>
                </MDBTabPane>

                {/* start payment */}
                <MDBTabPane tabId="10" role="tabpanel">
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <div className="row mb-3 mt-3">
                      <MDBCol md="5" className="mt-2">
                        <div className='d-flex mb-2'>
                          <span className='admin-payopt-p'>{t('payment_option')}</span>
                          <label class="gv-switch mr-3">
                                    <input type="checkbox"
                                        checked={this.state.isPaymentOptionEnabled}
                                        onChange={this.handleOnChangePaymentOption}
                                    />
                                    <span class="gv-slider round"></span>
                            </label>
                        </div>
                        {this.state.isPaymentOptionEnabled ?
                          (<div className='payment-radio-types'>
                              <div style={{marginTop : '3rem'}}>
                                    <Form.Check
                                      onChange={this.handleOnchangePaymentMode.bind(this, "split")}
                                      custom
                                      type="radio"
                                      id = "split"
                                      label={t('split_payment')}
                                      name = "paymentmode"
                                      checked={this.state.paymentMode === "split"}
                                  />
                              </div>
                              {this.state.paymentMode === "split" &&
                              <div className='mt-3 d-flex mb-3' >
                              <div className='d-flex flex-column mr-3'> 
                                  <select
                                  id="percentageSelect1"
                                  value={this.state.selectedPercentage1}
                                  onChange={this.handleChangeInPercentage1}
                                  className='percentage-select w-100'
                                >
                                  <option>{t('owner-share')}</option>
                                  {percentageOptions.map((percentage, index) => (
                                    <option key={index} value={percentage}>
                                      {percentage}
                                    </option>
                                  ))}
                                </select>
                                <span style={{fontSize : '12px'}} className='mt-1'>{t('owner-share')}</span>
                              </div>
                              
                              <div className='d-flex flex-column'>
                              <select
                              id="percentageSelect2"
                              value={this.state.selectedPercentage2}
                              onChange={this.handleChangeInPercentage2}
                              className='percentage-select w-100'
                            >
                              <option>{t('platform-share')}</option>
                              {percentageOptions.map((percentage, index) => (
                                <option key={index} value={percentage}>
                                  {percentage}
                                </option>
                              ))}
                            </select>
                            <span style={{fontSize : '12px'}} className='mt-1'>{t('platform-share')}</span>

                              </div>

                           
                            </div>
                              }
                              
                              <div>
                                <span className="error_msg w3-animate-top percentages_error" style={{ color: "red" }}></span>
                              </div>
                              <div className='mt-3 d-flex align-items-center'>
                                
                                <Form.Check
                                  onChange={this.handleOnchangePaymentMode.bind(this, "direct")}
                                  custom
                                  type="radio"
                                  id = "direct"
                                  label={t('direct_payment')}
                                  name = "paymentmode"
                                  checked={this.state.paymentMode === "direct"}
                              />
                              
                              </div>
                        </div>) : <></>
                        }
                        
                        
                      </MDBCol>
                     
                      <MDBCol md="12" className="text-right mt-3">
                        <button className="mt-5 refresh-btn" onClick={this.savePaymentOption.bind(this)}>{t('update')}</button>
                      </MDBCol>
                    </div>
                  </form>
                </MDBTabPane>
                {/* Charging Power frequency start */}
                <MDBTabPane tabId="2" role="tabpanel">
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <div className="row mb-3 mt-3">
                      <MDBCol md="4" className="mt-2">
                        <div className="md-form">
                          <input type="text" id="frequency" className="form-control pl-0 text_lowercase" pattern="[0-9]+" onChange={this.handleChange} value={this.state.chargingPowerFrequency} />
                          <label htmlFor="frequency" className={(this.state.chargingPowerFrequency!=="" && this.state.chargingPowerFrequency!==null)? "active": ""}>{t('charging_power_frequency')}</label>
                          <span className="error_msg1 w3-animate-top error_frequency" style={{color: "red"}}></span> 
                        </div>
                      </MDBCol>
                     
                      <MDBCol md="12" className="text-right mt-3">
                        <button className="mt-5 refresh-btn" onClick={this.saveChargingPower.bind(this)}>{t('update')}</button>
                      </MDBCol>
                    </div>
                  </form>
                </MDBTabPane>
              {/* Charging Power frequency start */}

                {/*  end Charging Profile */}
{/*start customization*/}
<MDBTabPane tabId="7" role="tabpanel" id="customization">
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>
                   
                    <MDBCol md="4" >
                   
                      {
                              this.state.getOperationList.map((operation,index)=>{
                                ;
                              if(index<=4){
                                if((localStorage.getItem('tenant')!=='ford' && localStorage.getItem('tenant')!=='Ford') && operation.operation === 'Dashboard'){
                                  return <Form.Check
                                  disabled
                                  className="mt-3"
                                  custom
                                  checked={true}
                                  id={`header_${index}`}
                                  type="checkbox"
                                  onChange={this.handleOperation.bind(this,operation.operation)}
                                  label={<div ><b><img src={"/assets/img/sidebar-menu/"+operation.operation+".png"} alt="" class="menuicon_img"></img><label>{this.props.t(operation.operation)}{t('yes_no')}</label></b></div>}
                                />
                                }else{
                                  return(
                                    <>
                                    {
                                      ((localStorage.getItem('tenant')==='ford' || localStorage.getItem('tenant') === 'Ford') && operation.operation === 'Dashboard')? 
                                      <Form.Check
                                                     className="mt-3"
                                                     custom
                                                     disabled
                                                     checked={true}
                                                     id={`header_${index}`}
                                                     type="checkbox"
                                                     onChange={this.handleOperation.bind(this,operation.operation)}
                                                     label={<div ><b><img src={"/assets/img/sidebar-menu/"+operation.operation+".png"} class="menuicon_img" alt=""></img><label>{this.props.t(operation.operation)}{t('yes_no')}</label></b></div>}
                                                   />
                                      :
                                      <Form.Check
                                                     className="mt-3"
                                                     custom
                                                     checked={operation.active===true? true:false}
                                                     id={`header_${index}`}
                                                     type="checkbox"
                                                     onChange={this.handleOperation.bind(this,operation.operation)}
                                                     label={<div ><b><img src={"/assets/img/sidebar-menu/"+operation.operation+".png"} class="menuicon_img" alt=""></img><label>{this.props.t(operation.operation)}{t('yes_no')}</label></b></div>}
                                                   />
                                    }
                                                   
                                                   {
                                                     (operation.subOperations!==undefined)? <>
                                                       {
                                                         
                                                         operation.subOperations.map((suboperation, index) => {
                                                           if(suboperation.name === "Dashboard"){
                                                            return (
                                                              <Form.Check
                                                                className="mt-3"
                                                                custom
                                                                disabled
                                                                checked={true}
                                                                id={`${operation.operation}${suboperation.name}`}
                                                                type="checkbox"
                                                                label={<div id="customize-subtags">{this.props.t(suboperation.name)}{t('yes_no')}</div>}
                                                                onChange={this.handleSubOperation.bind(this,operation.operation,suboperation.name)}
                                                              />
                                                            )
                                                           }else{
                                                            return (
                                                              <Form.Check
                                                                className="mt-3"
                                                                custom
                                                                checked={suboperation.access===true? true:false}
                                                                id={`${operation.operation}${suboperation.name}`}
                                                                type="checkbox"
                                                                label={<div id="customize-subtags">{this.props.t(suboperation.name)}{t('yes_no')}</div>}
                                                                onChange={this.handleSubOperation.bind(this,operation.operation,suboperation.name)}
                                                              />
                                                            )
                                                           }
                                                           ;
                                                           
                                                         })
                                                       }
                                                     </>:null
                                                   }
                                                   </>
                                                   )
                                }
                                
                                  } else {
                                    return <></>
                                  }
                                  })
                                }
                           </MDBCol>
                             
                           <MDBCol md="4" className="customcheckbox-mid">
                   
                        {
                          this.state.getOperationList.map((operation, index) => {
                            ;
                            if (index === 5 || index === 6) {
                              return (
                                <>
                                {
                                  (operation.operation === "Settings")? (
                                    <>
                                    <div className="mt-3"><b><img src={"/assets/img/sidebar-menu/"+operation.operation+".png"} class="menuicon_img" alt=""></img>{this.props.t(operation.operation)}{t('yes_no')}</b></div>
                                    </>
                                  ):(
                                    <Form.Check
                                    className="mt-3"
                                    custom
                                    checked={operation.active ? "checked":""}
                                    id={`header_${index}`}
                                    type="checkbox"
                                    onChange={this.handleOperation.bind(this,operation.operation)}
                                    label={<div ><b><img src={"/assets/img/sidebar-menu/"+operation.operation+".png"} class="menuicon_img" alt=""></img>{this.props.t(operation.operation)}{t('yes_no')}</b></div>}
                                  />
                                  )
                                }
                                  
                                  {
                                    (operation.subOperations !== undefined) ? <>
                                      {
                                        operation.subOperations.map((suboperation, index) => {
                                          ;
                                          if(suboperation.name === "Preferences"){
                                            return (
                                              <Form.Check
                                                className="mt-3"
                                                disabled
                                                custom
                                                checked={"checked"}
                                                onChange={this.handleSubOperation.bind(this,operation.operation,suboperation.name)}
                                                id={`${operation.operation}${suboperation.name}`}
                                                type="checkbox"
                                                label={<div id="customize-subtags" >{this.props.t(suboperation.name)}{t('yes_no')}</div>}
                                              />
                                            )
                                          }else if(localStorage.getItem('role') === roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE && (suboperation.name==="Status Notification List")){
                                            return(<></>);
                                          }else{
                                            return (
                                              <Form.Check
                                                className="mt-3"
                                                custom
                                                checked={suboperation.access? "checked":""}
                                                onChange={this.handleSubOperation.bind(this,operation.operation,suboperation.name)}
                                                id={`${operation.operation}${suboperation.name}`}
                                                type="checkbox"
                                                label={<div id="customize-subtags" style={{width:"92%"}} >{this.props.t(suboperation.name)}{t('yes_no')}</div>}
                                              />
                                            )
                                          }
                                        })
                                      }
                                    </> : null
                                  }
                                </>
                              )
                            } else {
                              return <></>
                            }
                          })
                        }
                        </MDBCol>
                        
                        <MDBCol md="4" className="customcheckbox">
                        {
                          this.state.getOperationList.map((operation, index) => {
                            if (index > 6) {
                              return (
                                <>
                                  <Form.Check
                                    className="mt-3"
                                    custom
                                    checked={operation.active? "checked":""}
                                    onChange={this.handleOperation.bind(this,operation.operation)}
                                    id={`header_${index}`}
                                    type="checkbox"
                                    label={<div ><b><img src={"/assets/img/sidebar-menu/"+operation.operation+".png"} class="menuicon_img" alt=""></img>{this.props.t(operation.operation)}{t('yes_no')}</b></div>}
                                  />
                                  {
                                    (operation.subOperations !== undefined) ? <>
                                      {
                                        operation.subOperations.map((suboperation, index) => {
                                          if((localStorage.getItem('tenant')!=='ford' && localStorage.getItem('tenant')!=='webasto' && (suboperation.name==="Execution History Logs"||suboperation.name==="Error Logs"||suboperation.name === "Audit Mail")) || (localStorage.getItem('role') === roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE 
                                          && (suboperation.name==="FOTA - Builds" || suboperation.name === "Upload Diagnostics"))){
                                            return(<></>);
                                          }else{
                                            return (
                                              <Form.Check
                                                className="mt-3"
                                                custom
                                                checked={suboperation.access? "checked":""}
                                                id={`${operation.operation}${suboperation.name}`}
                                                type="checkbox"
                                                label={<div id="customize-subtags">{this.props.t(suboperation.name)}{t('yes_no')}</div>}
                                                onChange={this.handleSubOperation.bind(this,operation.operation,suboperation.name)}
                                              />
                                            )
                                          }
                                        })
                                      }
                                    </> : null
                                  }
                                </>
                              )
                            } else {
                              return <></>
                            }
                          })
                        }
                        </MDBCol>
                     
                        <MDBCol md="12" className="text-right mt-3">
                     <button className="refresh-btn mt-5" onClick={this.updateCustomization}>{t('update')}</button> 
                 
                      </MDBCol>
                      </MDBRow>
                  </form>
                </MDBTabPane>
{/*end customization*/}
                {/* start securitytab */}
                <MDBTabPane tabId="4" role="tabpanel">
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <div className="row mb-3 mt-3">

                      <MDBCol md="4" className="mt-2">
                        <Form.Check
                          className="mt-3"
                          custom
                          value={this.state.fields.isDiagnosticSecurityEnabled}
                          onChange={this.diagnosticDisableChange.bind(this, "diagnosticEnable")}
                          id="diagnosticEnable"
                          type="checkbox"
                          label={t('diagnostic_security_enabled')}
                        />
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} >{this.state.errors["locationUrlError"]}</span></span>
                      </MDBCol>
                      <MDBCol md="4" className="mt-2">
                        <Form.Check
                          className="mt-3"
                          custom
                          value={this.state.fields.isBasicSecurityEnabled}
                          onChange={this.basicSecurityChange.bind(this, "basicEnable")}
                          id="basicEnable"
                          type="checkbox"
                          label={t('basic_security_enabled')}
                        />
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} >{this.state.errors["locationUrlError"]}</span></span>
                      </MDBCol>
                      <MDBCol md="4" className="mt-2">
                        <Form.Check
                          className="mt-3"
                          custom
                          value={this.state.fields.enableTimeoutmechanism}
                          onChange={this.enableTimeoutChange.bind(this, "enableTimeout")}
                          id="enableTimeout"
                          type="checkbox"
                          label={t('timeout_mechanism_enabled')}
                        />
                      </MDBCol>
                      <MDBCol md="4" className="mt-2">
                        <Form.Check
                          className="mt-3"
                          custom
                          value={this.state.fields.isChangePassword}
                          onChange={this.enablePasswordChange.bind(this, "enablePassword")}
                          id="enablePassword"
                          type="checkbox"
                          label={t('change_password_enabled')}
                        />
                      </MDBCol>

                      <MDBCol md="4" className="mt-2">
                        <Form.Check
                          className="mt-3"
                          custom
                          value={this.state.fields.isFreeChargeEnabled}
                          onChange={this.freechargeChange.bind(this, "freechargeEnable")}
                          id="freechargeEnable"
                          type="checkbox"
                          label={t('free_charge_any_rfid_enabled')}
                        />
                      
                      </MDBCol>
                      <MDBCol md="4" className="mt-2">
                        <Form.Check
                          className="mt-3"
                          custom
                          value={this.state.fields.isRestoreEnable}
                          onChange={this.restoreConfigFeature.bind(this, "RestoreEnable")}
                          id="RestoreEnable"
                          type="checkbox"
                          label={t("plugin_rules_enabled")}
                        />
                      
                      </MDBCol>

                      <MDBCol md="12" className="text-right mt-3">
                      {this.state.securityaction === "Update" ? <button className="refresh-btn mt-5" disabled={this.state.secdisabledAttr} onClick={this.saveSecurityChanges}>{t('update')}</button> : ""}
                        {this.state.securityaction === "Save" ? <button className="refresh-btn mt-5" disabled={this.state.secdisabledAttr} onClick={this.saveSecurityChanges}><i class="fas fa-save mr-2"></i>{t('save')}</button> : ""}
                      </MDBCol>
                    </div>
                  </form>
                </MDBTabPane>


                {/* start cronjobtab */}
                <MDBTabPane tabId="5" role="tabpanel">
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <div className="row mb-3 mt-3">

                      <MDBCol md="5" className="mt-2">
                        <label className="pull-left mr-3 mt-2 pt-1">{t('service_type')} *</label>
                        <select className="browser-default custom-select select_height w-50" id="serviceType" onChange={this.serviceTypeChange.bind(this)} value={this.state.fields.serviceTypeChange}>
                          <option value="" >-- {t('select_label')} --</option>
                          {
                            (localStorage.getItem('tenant')==='webasto')? 
                            <>
                            {listOfServiceOption}
                            </>
                            : (localStorage.getItem('tenant')==='ford')? 
                            <>
                            <option value="DIAGNOSTICS_DOWNLOAD">{t('diagnostics_download')}</option>
                          <option value="INVENTORY_UPLOAD">{t('inventory_upload')}</option>
                          <option value="INVENTORY_IMPORT">{t('inventory_import')}</option>
                          <option value="WALLBOX_MESSAGE">{t('wallbox_message')}</option>
                          <option value="VENDOR_SUPPORT">{t('vendor_support')}</option>
                          <option value="ONBOARD_CHARGER">{t('onboard_charger')}</option>
                            </>:<></>
                          }                        
						</select>
                        <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["serviceTypeEmpty"]}</span></span>


                      </MDBCol>
                      <MDBCol md="5" className="mt-2" id="freqtab"  style={{ display:'block' }}>
                        <label className="pull-left mr-3 mt-2 pt-1">{t('frequency')} *</label>
                        <select className="browser-default custom-select select_height w-70" id="cronfrequency" onChange={this.cronfrequencyChange.bind(this)} value={this.state.fields1.cronfrequency}
                        >
                          <option value="">-- {t('select_label')} --</option>
                          <option value="daily">{t('daily')}</option>
                          <option value="Weekly">{t('weekly')}</option>
                          <option value="Monthly">{t('monthly')}</option>
                        </select>
                        <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["frequencyEmpty"]}</span></span>

                      </MDBCol>
                      <MDBCol md="6" className="mt-3">
                        <span className="pull-left mr-3 mt-2 pt-1">{t('last_schedule_date_time')}</span>
                        <div className="pull-left mt-2" style={{ width: "55%" }} >
                          <DatePicker
                            selected={this.state.lastScheduleDate}
                            onChange={this.lastScheduleDateChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            // disabled={this.state.disabled}
                            id="expiryDateId"
                            className="cal_margin tag_cal"
                            timeCaption="time"
                            dateFormat="MM-dd-yyyy HH:mm"

                          />

                        </div>
                        {/* <div className="error_msg w3-animate-top col-md-12  datetimeerror pl-0">  <span style={{color: "red"}}>{this.state.errors["expirydate"]}</span></div>  */}

                      </MDBCol>


                      <MDBCol md="12" className="text-right mt-3">
                        {this.state.cronjobaction === "Update" ?   <button type="button" data-test="button" disabled={this.state.crondisabledAttr} className="btn_primary mt-5" onClick={this.saveCronJobChanges}>{t('update')}</button> : ""}
                        {this.state.cronjobaction === "Save" ?  <button type="button" data-test="button" className="btn_primary mt-5" onClick={this.saveCronJobChanges.bind(this)}>{t('save')} <i class="fas fa-save ml-2"></i></button> : ""}
                      </MDBCol>


                    </div>
                  </form>
                </MDBTabPane>

                {/* start email security */}
                <MDBTabPane tabId="6" role="tabpanel">
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <div className="row mb-3 mt-3">

                      <MDBCol md="4" className="mt-2">
                        <Form.Check
                          className="mt-3"
                          custom
                          value={this.state.fields.isEnabled}
                          onChange={this.emailDisableChange.bind(this, "emailEnable")}
                          id="emailEnable"
                          type="checkbox"
                          label="Email enabled. (Yes/No)"
                        />
                      </MDBCol>

                      <MDBCol md="12" className="text-right mt-3">
                        {this.state.emailaction === "Update" ?   <button type="button" data-test="button" disabled={this.state.emaildisabledAttr} className="btn_primary mt-5" onClick={this.saveEmailNotificationChanges}>{t('update')}</button> : ""}
                        {this.state.emailaction === "Save" ?  <button type="button" data-test="button" className="btn_primary mt-5" onClick={this.saveEmailNotificationChanges.bind(this)}>{t('save')} <i class="fas fa-save ml-2"></i></button> : ""}
                      </MDBCol>

                      {/* <MDBCol md="12" className="text-right mt-3">
                        {this.state.emailaction === "Update" ? <MDBBtn color="primary" className="mt-5" disabled={this.state.emaildisabledAttr} onClick={this.saveEmailNotificationChanges}>Update</MDBBtn> : ""}
                        {this.state.emailaction === "Save" ? <MDBBtn color="primary" className="mt-5" onClick={this.saveEmailNotificationChanges.bind(this)}>Save</MDBBtn> : ""}
                      </MDBCol> */}
                    </div>
                  </form>
                </MDBTabPane>

                {/* Deeplink Credentials */}
                <MDBTabPane tabId="8" role="tabpanel">
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <div className="row mb-3 mt-3">
                      <MDBCol md="4" className="form_margin" >
                        <MDBInput
                          name="granttype"
                          value={this.state.deeplinks["granttype"]}
                          onChange={this.changeHandler.bind(this, "granttype")}
                          type="text"
                          id="granttype"
                          label={t('grant_type')+"*"}
                        >
                          <span className="error_msg w3-animate-top error_granttype" style={{ color: "red" }}></span>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="form_margin" >
                        <MDBInput
                          name="clientid"
                          value={this.state.deeplinks["clientid"]}
                          onChange={this.changeHandler.bind(this, "clientid")}
                          type="text"
                          id="clientid"
                          label={t('client_id')+"*"}
                        >
                          <span className="error_msg w3-animate-top error_clientid" style={{ color: "red" }}></span>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="form_margin" >
                        <MDBInput
                          name="clientsecret"
                          value={this.state.deeplinks["clientsecret"]}
                          onChange={this.changeHandler.bind(this, "clientsecret")}
                          type="text"
                          id="clientsecret"
                          label={t('client_secret')+"*"}
                        >
                          <span className="error_msg w3-animate-top error_clientsecret" style={{ color: "red" }}></span>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="form_margin" >
                        <MDBInput
                          name="resource"
                          value={this.state.deeplinks["resource"]}
                          onChange={this.changeHandler.bind(this, "resource")}
                          type="text"
                          id="resource"
                          label={t('resource')+"*"}
                        >
                          <span className="error_msg w3-animate-top error_resource" style={{ color: "red" }}></span>
                        </MDBInput>
                      </MDBCol>
                      {
                        (this.props.deeplink_W) ? 
                        <MDBCol md="12" className="text-right mt-3">
                        <button type="button" data-test="button" className="btn_primary mt-5" onClick={this.updateDeeplinkCredentials.bind(this)}>{t('update')}</button>
                      </MDBCol>: ""
                      }
                      
                    </div>
                  </form>
                </MDBTabPane>
                {/* Site limit of organization */}
                <MDBTabPane tabId="9" role="tabpanel">
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <div className="row mb-3 mt-3">
                      <MDBCol md="2" className="mt-2">
                        <Form.Check
                          className="mt-3"
                          custom
                          checked={this.state.isSitelimitChecked}
                          onChange={this.updateSiteLimitCheck}
                          type="checkbox"
                          label={t('has_group_limit')}
                          id="site_limit_checkbox"
                        />
                      </MDBCol>
                        {this.state.isSitelimitChecked && <MDBCol md="4" className="mt-2">
                          <div className="md-form">
                            <MDBInput
                              name="tag"
                              valueDefault={this.state.siteLimitValue}
                              onChange={this.handleSiteLimit}
                              type="text"
                              id="site_limit_input"
                              label={t('Group Limit')}>
                            </MDBInput>
                            {/* <input type="text" id="site_limit_input" className="form-control pl-0 text_lowercase" pattern="[0-9]+" onChange={this.handleSiteLimit} value={this.state.siteLimitValue} disabled={!this.state.isSitelimitChecked}/> */}
                            {/* <label className={this.state.siteLimitValue? "active": ""} htmlFor="site_limit_input">Site limit</label> */}
                            <span className="error_msg1 w3-animate-top error_frequency" style={{color: "red"}}>
                              {this.state.siteLimitErrors?.siteLimitValue}
                            </span> 
                          </div>
                        </MDBCol>}
                      </div>
                     
                      <div className="row mb-3 mt-3">
                      <MDBCol md="12" className="text-right mt-3">
                        <button className="mt-5 refresh-btn" onClick={this.updateSiteLimit}>{t('update')}</button>
                      </MDBCol>
                    </div>
                  </form>
                </MDBTabPane>
              </MDBTabContent>
            </form>
          </div>

          {/* customization success */}
          <MDBModal isOpen={this.state.customizesucess} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggleCustomization}>Success</MDBModalHeader>
            <MDBModalBody>
              {t('tenant_is updated_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.closeCustomization.bind(this)}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          {/* customization fail */}
          <MDBModal isOpen={this.state.customizefail} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
          <MDBModalHeader toggle={this.toggleCustomizationfail}>{t('Warning')}</MDBModalHeader>
            <MDBModalBody>
              {this.state.tenantfailMessage}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.toggleCustomizationfail.bind(this)}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          {/* popup for success */}
          <MDBModal isOpen={this.state.modalSuccess} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggleAdd}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('diagnostics_preferences_successfully')} {digAction_text}.
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.CloseSuccessPopup}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for success */}
          <MDBModal isOpen={this.state.modalSuccesscp} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggleAddCp}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('charging_Preferences_successfully')} {cpActionText}.
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.CloseSuccesscpPopup}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modalSuccesssecurity} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggleSecurity}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('security_for_Preferences_successfully')} {securityActionText}.
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.CloseSuccesssecurity.bind(this)}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modalSuccessemail} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggleMailNotification}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('email_Notification_successfully')} {emailActionText}.
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.CloseSuccessemail.bind(this)}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modalSuccesscron} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggleCronJob}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('tenant_configuration_successfully')} {cronjobActionText}.
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.CloseSuccesscron.bind(this)}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* charging power frequency success */}
          <MDBModal isOpen={this.state.chargepowersucess} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.togglechargepower}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('charging_frequency_updated_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.togglechargepower.bind(this)}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
        </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
              <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* Deeplink Credentials update success */}
          <MDBModal isOpen={this.state.deeplinksuccess} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggledeeplink}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('deeplink_updated_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.toggledeeplink.bind(this)}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          {/* Deeplink Credentials update success */}
          <MDBModal isOpen={this.state.siteLimitSuccess} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggledeeplink}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('group_limit_updated_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.toggoleSiteLimitPopupModal}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          {/* Site Limit Error Popup */}
          <MDBModal isOpen={this.state.errorSiteLimit} className="model_top" size="md" toggle={this.showSiteLimit}>
            <MDBModalHeader toggle={this.showSiteLimit}>{t('alert')}</MDBModalHeader>
            <MDBModalBody>
              {this.state.siteLimitError}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.showSiteLimit}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          <SuccessPopup
                    open={this.state.isChangesUpdatedPopupOpen}
                    image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                    content={t('changes_updated_successfully')}
                    btnText={t("close")}
                    onClickOperation={this.closeChangesUpdatedPopup}
            />

          <CustomModal
                    open={this.state.paymentWarningPopup}
                    title={t('Warning')}
                    bodyMessage={t(this.state.paymentWarningErrMsg)}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.toggleWarningPopup}
                />
          
        </main>
      </>
    );
  }
}

export default withTranslation()(withRouter(preferences));
