import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import { Table } from 'react-bootstrap'
import { createBrowserHistory } from 'history';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import {getSecurityHeaders} from '../common/HttpRequestClass'
const history = createBrowserHistory();

class EditChargingSchedule extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
            modal:false,  
            modalSuccess:false,  
            modalDelete:false,
            modal1:false, 
            modalsession:false,
            modalConfirmation:false,  
            disabledAttrPeriod:"",
            page_title:"Edit Charging Schedule",
            disabledAttr:true,
            disabledAttrName:true,
            scheduleId:"",
            delete_id:"",
            startScheduleDate:"",
            updatecppId:"",
            errors:{},
            action:"",
            fields:{
                  "duration":"",
                  "chargingScheduleName": "",
                  "startSchedule":"",
                  "schedulingUnit":"",
                  "minChargingRate":"",
                  "chargingSchedulePeriod":[
                     {
                        "startPeriod": "",
                        "limit": "",
                        "numberPhases": ""
                     }
                  ]
                },
                schedulePeriod:{
                                "startPeriod":"",
                                "limit":"",
                                "numberPhases":""
                               },

        }
        this.backToList = this.backToList.bind(this)
        this.setStartSchedule = this.setStartSchedule.bind(this)
        this.saveChargingSchedulePeriod = this.saveChargingSchedulePeriod.bind(this)
        this.addChargingSchedulePeriod = this.addChargingSchedulePeriod.bind(this)
        this.toggle = this.toggle.bind(this);
        this.saveChargingSchedule = this.saveChargingSchedule.bind(this);
        this.handlePeriodChange = this.handlePeriodChange.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.CloseSuccessPopup = this.CloseSuccessPopup.bind(this);
        this.chargeRateUnit = this.chargeRateUnit.bind(this);
        this.editChargingSchedulePeriod = this.editChargingSchedulePeriod.bind(this);
        this.toggle1 = this.toggle1.bind(this)
        this.deleteChargingSchedulePeriod = this.deleteChargingSchedulePeriod.bind(this)
        this.toggleConfirmation = this.toggleConfirmation.bind(this)

        this.toggleDelete = this.toggleDelete.bind(this)
        this.tagOk = this.tagOk.bind(this)



        this.toggleDelete = this.toggleDelete.bind(this)
        this.tagOk = this.tagOk.bind(this)

}


toggle1(event){
  var duration= this.state.fields['duration'];
    if(duration.length!==0){
      this.setState({
        modal1: !this.state.modal1
      });
      $('.err_duration').empty();
    }else{
      $('.err_duration').text(this.props.t('duration_should_not_empty'));
    }
}





toggleDelete = () => {
  this.setState({
    modalDelete: !this.state.modalDelete
  });
}

tagOk = () => {
  this.setState({
    modalDelete: !this.state.modalDelete
  });
}




toggleConfirmation = () => {
  this.setState({
    modalConfirmation: !this.state.modalConfirmation
  });
}

//This function is used for back button
backToList(){
 
}

secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600*24));
  var i_d = d < 10 ? "0" : ""
  d = i_d+d;
  var h = Math.floor(seconds % (3600*24) / 3600);
  var h_d = h < 10 ? "0" : ""
  h = h_d+h;
  var m = Math.floor(seconds % 3600 / 60);
  var m_d = m < 10 ? "0" : ""
  m = m_d + m;
  var s = Math.floor(seconds % 60);
  var s_d = s < 10 ? "0" : ""
  s = s_d + s;

  return d+":"+h+":"+m+":"+s;
  }

//This function for setting the date to state
setStartSchedule(date){
  var dateNew =  this.formatDateandTime(date)
  var fields = this.state.fields;
  fields["startSchedule"] = dateNew
  this.setState({ 
    startScheduleDate:date ,
    fields
   })
   this.setState({disabledAttr:""});
}

formatDateandTime(currentDate){
  var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
  var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
  var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
  var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
  var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" + currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
  return createdDateTo;
}

//This is for making user should enter only numbers
numberHandler(field, e){ 
    let fields = this.state.fields;
    if(e.target.validity.valid){
      if( field==="duration" && e.target.value.length > 0 ){
        this.setState({
          disabledAttrPeriod:""
        })
      }else{
        this.setState({
          disabledAttrPeriod:true
        })
      }
      fields[field] = e.target.value; 
      this.setState({fields});
    }
    this.setState({disabledAttr:""});
}

chargeRateUnit = (event) => {
  this.setState({disabledAttr:""});
  var x = event.target.value;
  let fields = this.state.fields;
  fields["schedulingUnit"] = x; 
  this.setState({fields});
}


// function to open popup
  async toggle(){
  
    var emptyschedulePeriod = {};
    emptyschedulePeriod["startPeriod"] = ""
    emptyschedulePeriod["limit"] = "";
    emptyschedulePeriod["numberPhases"] =""
    this.setState({
      schedulePeriod:emptyschedulePeriod,
    })
    this.setState({
      modal: !this.state.modal
    });
  }

  async togglesession(){
    ;
    this.setState({
       modalsession:!this.state.modalsession
    });
  }

  sessiontagOk(){
    history.push('/');
    window.location.reload();
  }

 //  function call when click on save button to save all the data 
async saveChargingSchedule(event){
  event.preventDefault();
  this.setState({modal1: !this.state.modal1});
  let errors = {};
    var SchedulingUnit= this.state.fields['schedulingUnit'];
    var chargingSchedulePeriod= this.state.fields['chargingSchedulePeriod'];
    if(SchedulingUnit===''){
      errors["schedulingUnitError"] = this.props.t('select_acharging_rate_unit');
    }
    if(chargingSchedulePeriod.length===0){
      errors["emptyaddChargingScheduleError"] = this.props.t('please_add_charging_schedule_period');
    }
    if(errors.hasOwnProperty("schedulingUnitError") || chargingSchedulePeriod.length===0 || errors.hasOwnProperty("emptyaddChargingScheduleError"))
    {
        
    }else{
      let url = baseUrl.URLPath + servicesUrl.getchargingschedules+ "?scheduleid="+this.state.scheduleId;
      let type = ApiMethodTypes.PUT;
      let headers = getSecurityHeaders();
      let payload = {
                      "duration":this.state.fields["duration"],
                      "chargingScheduleName": this.state.fields["chargingScheduleName"],
                      "startSchedule": this.state.fields["startSchedule"],
                      "schedulingUnit":this.state.fields["schedulingUnit"],
                      "minChargingRate":this.state.fields["minChargingRate"],
                      "chargingSchedulePeriod":this.state.fields["chargingSchedulePeriod"],
                      }
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if(response === 404){
        this.setState({errors: errors});
      }
      if(response === 409){
        errors["chargingScheduleNameError"] = this.props.t('charging_schedule_name_already_exists');
        this.setState({errors: errors});
      }
      if(response.status === undefined && response !==undefined && response !== ""){
        this.toggleAdd()
      }
    }
}

  addChargingSchedulePeriod(){
    var duration= this.state.fields['duration'];
    let errors = {};

    if(duration.length!==0){
      errors["emptyaddChargingScheduleError"] = "";
      var emptyschedulePeriod = {};
      emptyschedulePeriod["startPeriod"] = "";
      emptyschedulePeriod["limit"] = "";
      emptyschedulePeriod["numberPhases"] ="";

      this.setState({
        schedulePeriod:emptyschedulePeriod,
        updatecppId:"",
        modal: !this.state.modal,
        action:"Save",
        errors
      })
      $('.err_duration').empty();
    }else{
      $('.err_duration').text(this.props.t('duration_should_not_empty'));
    }
  }

  toggleAdd(){
    this.setState({
      modalSuccess: !this.state.modalSuccess
    });
  }
  
  CloseSuccessPopup(){
    this.setState({
      modalSuccess: !this.state.modalSuccess
    });
    this.props.history.push('/chargingSchedule');
  }

  handlePeriodChange(field,evt) {
    let errors = {};
    var schedulePeriod = this.state.schedulePeriod
    schedulePeriod[field] = (evt.target.validity.valid) ? evt.target.value : "";
    this.setState({ schedulePeriod, errors });
  }

  componentDidMount(){
    let scheduleId = "";
    if(this.props.location.state===null){
      scheduleId = this.state.scheduleId
    }else{
      scheduleId = this.props.location.state;
    }

    this.setState({ scheduleId: scheduleId }, ()=>{
      this.getChargingSchedule();
    })
  }

 
  editChargingSchedulePeriod(CPperiodId,event){
    var errors = {};
    let state = this.state;

    state.schedulePeriod = this.state.fields.chargingSchedulePeriod;

    for(var i=0; i<=this.state.fields.chargingSchedulePeriod.length; i++){
      if(this.state.fields.chargingSchedulePeriod.startPeriod !== "" && CPperiodId === i ){
        state.updatecppId =  i ;
        state.schedulePeriod["startPeriod"] = this.state.fields.chargingSchedulePeriod[i].startPeriod
        state.schedulePeriod["limit"] = this.state.fields.chargingSchedulePeriod[i].limit
        state.schedulePeriod["numberPhases"] = this.state.fields.chargingSchedulePeriod[i].numberPhases
      }
    }

    this.setState({disabledAttr:"", errors: errors, modal: !this.state.modal, action:"Edit"});
  }

  showConfirmationPopup(CPperiodId,event){
      this.setState({
        delete_id:CPperiodId
      })
      this.setState({
        modalConfirmation: !this.state.modalConfirmation
      });
  }

  deleteChargingSchedulePeriod() {
    this.setState({
      modalConfirmation: !this.state.modalConfirmation
    });

    for (var i = 0; i <= this.state.fields.chargingSchedulePeriod.length; i++) {
      if (this.state.fields.chargingSchedulePeriod.startPeriod !== "" && this.state.delete_id === i) {
        (this.state.fields.chargingSchedulePeriod).splice(i, 1);
        this.setState({
          schedulePeriod: this.state.fields.chargingSchedulePeriod,
          disabledAttr: "",
          modalDelete: !this.state.modalDelete,
          delete_id: i
        })
      }
    }
  }

  //  function call when click on add charging period save button 
  saveChargingSchedulePeriod() {
    let errors = {};
    let fields = this.state.fields;
    var startPeriod = this.state.schedulePeriod['startPeriod'];
    var limit = this.state.schedulePeriod['limit'];

    if (startPeriod < this.state.fields.duration) {
      if (startPeriod === '') {
        errors["emptyStartPeriodError"] = this.props.t('please_enter_start_period');
      }
      if (limit === '') {
        errors["emptylimitError"] = this.props.t('please_enter_alimit');
      } else {
        if (!errors.hasOwnProperty("emptyStartPeriodError") && !errors.hasOwnProperty("emptylimitError")) {
          this.setState({
            modal: !this.state.modal
          });
          if (this.state.action === "Save") {
            fields['chargingSchedulePeriod'].push(this.state.schedulePeriod);
          }
          if (this.state.action === "Edit") {
            for (var i = 0; i <= this.state.fields.chargingSchedulePeriod.length; i++) {
              if (this.state.fields.chargingSchedulePeriod.startPeriod !== "" && this.state.updatecppId === i) {
                fields.chargingSchedulePeriod[i].startPeriod = this.state.schedulePeriod["startPeriod"];
                fields.chargingSchedulePeriod[i].limit = this.state.schedulePeriod["limit"];
                fields.chargingSchedulePeriod[i].numberPhases = this.state.schedulePeriod["numberPhases"];
              }
            }
          }
          var emptyschedulePeriod = {};
          emptyschedulePeriod["startPeriod"] = ""
          emptyschedulePeriod["limit"] = "";
          emptyschedulePeriod["numberPhases"] = ""
          this.setState({
            schedulePeriod: emptyschedulePeriod,
            updatecppId: "",
            fields
          })
        }
      }

      this.setState({ disabledAttr: "" });
    } else {
      errors["emptyStartPeriodError"] = this.props.t('start_period_must_less_than_duration');
    }

    this.setState({
      errors: errors
    });
  }

  async getChargingSchedule(){
    let url = baseUrl.URLPath + servicesUrl.getchargingschedulesByscheduleid+ "?scheduleid="+this.state.scheduleId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if(response!==undefined){
      this.setState({
         fields:response,
         schedulePeriod:response.chargingSchedulePeriod
     })
      document.getElementById('chargeRateUnit').value = this.state.fields.schedulingUnit
      if(this.state.fields.startSchedule !== null){
        this.setState({
          startScheduleDate:new Date(this.state.fields.startSchedule)
      })
      }
    }
  }

render(){
   const{t}=this.props
  // print 
   let schedulePeriodRows = "";
   if(this.state.fields.chargingSchedulePeriod.length > 0){
        schedulePeriodRows  = this.state.fields.chargingSchedulePeriod.map((periodArray, index) => {
        return(
            <tr key={index} >
              <td>{ periodArray.startPeriod +" ("+this.secondsToDhms(periodArray.startPeriod)+") " }</td>
              <td>{ periodArray.limit }</td>
              <td>{ periodArray.numberPhases}</td>
              <td>
              <i class="fas fa-pencil-alt ml-3  pr-2 action-icon" title="Edit" onClick={this.editChargingSchedulePeriod.bind(this,index)}></i>
                <i class="fas fa-trash pr-2 action-icon" title="Delete" onClick={this.showConfirmationPopup.bind(this,index)} ></i>
                </td>
            </tr>
          )
      });
    }
  return (
    <>
     <main class="content-div" >
     <p>{t('Charging Schedule')}
              <div className="breadcrumb_div">
              {t('charge_point')} &gt; <Link to="/chargingSchedule" > <span className="">{t('Charging Schedule')}</span></Link>  &gt;  <Link to={{
                  pathname:'/viewChargingSchedule', 
                  state: this.state.scheduleId, 
                  }}> {t('view')} </Link>&gt; <span className="breadcrumb_page">  {t('edit')}</span>
              </div>
          </p>
        <form
          className='needs-validation'
          onSubmit={this.toggle1}
          noValidate>

            <div className="page-outerdiv">
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="pull-left">
                      <Link to={{
                  pathname:'/viewChargingSchedule', 
                  state: this.state.scheduleId
                }}><button className="btn_primary" onClick={this.backToList} type="button" data-test="button"><i class="fa fa-angle-left mr-2" aria-hidden="true"></i>{t('back')}</button>
                </Link>
                      </div>
                    </div>
                  </div>
              <div className="row mb-3">
                <div className="col-md-4 md-form">
                  <input disabled={this.state.disabledAttrName} name="chargingScheduleName" onInput={this.numberHandler.bind(this,"chargingScheduleName")} type="text" id="chargingScheduleNameId" className="form-control text_lowercase" onChange={this.changeHandler} value={this.state.fields["chargingScheduleName"]} />
                  <label htmlFor="chargingScheduleNameId" className={`${(this.state.fields['chargingScheduleName']!=="" && this.state.fields['chargingScheduleName']!==null)? "active" : ""} ml-15`}>{t('charging_schedule_name')}</label>
                  <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["chargingScheduleNameError"]}</span></span>
                </div>
                <div className="col-md-4 md-form">
                  <input pattern="[0-9]*" name="duration" onInput={this.numberHandler.bind(this,"duration")} type="text" id="durationId" className="form-control text_lowercase" onChange={this.changeHandler} value={this.state.fields["duration"]} />
                  <label htmlFor="durationId" className={`${(this.state.fields['duration']!=="" && this.state.fields['duration']!==null)? "active" : ""} ml-15`}>{t('chargingDuration')} ( {this.secondsToDhms(this.state.fields["duration"])} )</label>
                  <span className="pull-left err_duration w3-animate-top mt-1" style={{color: "red"}}></span>
                </div>
                <div className="col-md-4 md-form">
                  <input pattern="[{0-9}.]*" name="minChargingRate" onInput={this.numberHandler.bind(this,"minChargingRate")} type="text" id="minChargingRate" className="form-control text_lowercase" value={this.state.fields["minChargingRate"]} />
                  <label htmlFor="minChargingRate" className={`${(this.state.fields['minChargingRate']!=="" && this.state.fields['minChargingRate']!==null)? "active" : ""} ml-15`}>{t('min_charging_rate')}</label>
                </div>
                <MDBCol md="4" >
                <div className="w_f">
                    <label className="pull-left pt-3 mr-4" >{t('charging_rate_unit')}:</label>
                    <select className="browser-default custom-select select_height w-50" id="chargeRateUnit" onChange={this.chargeRateUnit}>
                      <option value="">{t('select_rate_unit')}</option>
                      <option value="W">{t('w')}</option>
                      <option value="A">{t('a')}</option>
                    </select>
                  </div>
                  <span className="error_msg w3-animate-top ml-0 mt-0">  <span style={{color: "red"}}>{this.state.errors["schedulingUnitError"]}</span></span> 
                </MDBCol>
                <MDBCol md="4" style={{position:"relative"}} >
                    <span className="cal_label">{t('start_schedule')}</span>
                      <div className="pull-left mt-2" style={{width:"50%"}} >
                        <DatePicker
                          selected={this.state.startScheduleDate}
                          onChange={this.setStartSchedule}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={1}
                          className="cal_margin tag_cal"
                          timeCaption="time"
                          dateFormat="dd-MMM-yyyy HH:mm"
                        />
                      </div>
                    <span className="error_msg w3-animate-top cal_errormsg"> <span style={{color: "red"}} >{this.state.errors["emptyscheduleDate"]}</span></span>      	
                </MDBCol>
               
                <MDBCol md="6" className="mb-2">
                  <label class="form-title charge_pl pull-left mt-3 mb-0">{t('add_charging_schedule_period')}:</label>
                </MDBCol>
                <MDBCol md="6">

                  <button data-test="button" type="button" className="pull-right mt-3 refresh-btn" onClick={this.addChargingSchedulePeriod}><i class="fas fa-plus mr-2"></i>{t('add')}</button>
                </MDBCol>
                  <MDBCol md="12" >
                    <Table striped hover size="sm" className="page-table mb-0">
                      <thead>
                        <tr>
                          <th>{t('start_period')}</th>
                          <th>{t('limit')}</th> 
                          <th>{t('number_phases')} </th>
                          <th>{t('action')}</th>
                        </tr>
                      </thead>
                      <tbody>
                       {schedulePeriodRows}
                      </tbody>
                    </Table>
                    <span className="error_msg w3-animate-top cal_errormsg ml-0 mt-0"> <span style={{color: "red"}} >{this.state.errors["emptyaddChargingScheduleError"]}</span></span>      	
                </MDBCol>

                <MDBCol md="12" className="text-right mt-3" >
                  <button className="mt-5 btn_primary mr-2" disabled={this.state.disabledAttr} onClick={this.toggle1} type="button" data-test="button">{t('save')} <i class="fas fa-save ml-2"></i></button>
                  
                </MDBCol>
              </div>
            </div>
         </form>

         {/* add charging schedule popup  */}
          <MDBModal isOpen={this.state.modal} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggle}>{t('add_charging_schedule_period')}</MDBModalHeader>
            <MDBModalBody>
              <div className="row">
                <div className="col-md-6 md-form">
                  <input pattern="[0-9]*" name="startPeriod" onInput={this.handlePeriodChange.bind(this,"startPeriod")} type="text" id="startperiod" className="form-control text_lowercase" value={this.state.schedulePeriod["startPeriod"]} />
                  <label htmlFor="startperiod" className={`${(this.state.schedulePeriod['startPeriod']!=="" && this.state.schedulePeriod['startPeriod']!==null)? "active" : ""} ml-15`}>{t('start_period')} ( {this.secondsToDhms( this.state.schedulePeriod["startPeriod"] )} )</label>
                  <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["emptyStartPeriodError"]}</span></span>
                </div>
                <div className="col-md-6 md-form">
                  <input pattern="[0-9]*" name="limit" onInput={this.handlePeriodChange.bind(this,"limit")} type="text" id="limit" className="form-control text_lowercase" value={this.state.schedulePeriod["limit"]} />
                  <label htmlFor="limit" className={`${(this.state.schedulePeriod["limit"]!=="" && this.state.schedulePeriod["limit"]!==null)? "active" : ""} ml-15`}>{t('limit')}</label>
                  <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["emptylimitError"]}</span></span>
                </div>
                <div className="col-md-6 md-form">
                  <input pattern="[0-9]*" name="numberPhases" onInput={this.handlePeriodChange.bind(this,"numberPhases")} type="text" id="numberPhases" className="form-control text_lowercase" value={this.state.schedulePeriod["numberPhases"]} />
                  <label htmlFor="numberPhases" className={`${(this.state.schedulePeriod["numberPhases"]!=="" && this.state.schedulePeriod["numberPhases"]!==null)? "active" : ""} ml-15`}>{t('number_of_phases')}</label>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter className="border-top-0">
              <button onClick={this.toggle} className="btn_cancel" type="button" data-test="button"> {t('cancel')} <i class="fas fa-times ml-2"></i></button>
              <button onClick={this.saveChargingSchedulePeriod} className="btn_primary" type="button" data-test="button"> {t('save')} <i class="fas fa-save ml-2"></i></button>
            </MDBModalFooter>
          </MDBModal>
           {/* end charging schedule popup  */}

           <MDBModal isOpen={this.state.modalSuccess}  backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggleAdd}>{t('success')}</MDBModalHeader>
              <MDBModalBody>
               {t('charging_schedule_successfully_updated')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="pull-right btn_primary" onClick={this.CloseSuccessPopup}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}> {t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="pull-right btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

        <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle1}>{t('confirmation')}</MDBModalHeader>
          <MDBModalBody>
              {t('sure_you_want_to_save_changes')}
          </MDBModalBody>
          <MDBModalFooter>
          <button type="button" data-test="button" className="pull-right btn_primary"  onClick={this.toggle1}>{t('close')}</button>
          <button type="button" data-test="button" className="pull-right btn_primary" onClick={this.saveChargingSchedule}>{t('yes')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalConfirmation} toggle={this.toggleConfirmation} size="md" className="model_top">
        <MDBModalHeader toggle={this.toggleConfirmation}> {t('confirmation')} </MDBModalHeader>
          <MDBModalBody>
            {t('you_want_to_delete')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="pull-right btn_primary"  onClick={this.toggleConfirmation}>{t('close')}</button>
            <button type="button" data-test="button" className="pull-right btn_primary" onClick={this.deleteChargingSchedulePeriod}>{t('yes')}</button>
          </MDBModalFooter>
         </MDBModal>




         <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete} size="md" className="model_top">
        <MDBModalHeader toggle={this.toggleDelete}>{t('success')}</MDBModalHeader>
        <MDBModalBody>
            {t('charging_period_deleted')}
        </MDBModalBody>
        <MDBModalFooter>
        <button type="button" data-test="button" className="pull-right btn_primary" onClick={this.tagOk}>{t('ok')}</button>
        </MDBModalFooter>
      </MDBModal>







      </main>
    </>
  );
}
}

export default withTranslation()(EditChargingSchedule);