/* eslint-disable no-useless-escape */
import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { storedSelectedCP } from '../Controllers/ChargePointModel';
import { Typeahead } from 'react-bootstrap-typeahead';
import $ from "jquery";
import ChargePointModel from '../common/ChargePointModel'
import { withTranslation } from 'react-i18next';
import Loader from '../views/loader';

//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders, getSecurityHeadersWithTimezone } from '../common/HttpRequestClass'

class vendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorNameSorting: false,
      assignAction: "",
      assignSuccessName: "",
      style: "block",
      finalStatus: [],
      sucess: false,
      colour: 'sucess',
      message: '',
      finalArray1: [],
      jsonArray: [],
      isCheckBox: "false",
      matchedPoints: [],
      cpList: [],
      allChargePoints: [],
      warningmodal: false,
      optionsdropdown: [],
      vendorList: [],
      chargePointIdList: [],
      getSelectedName: [],
      vendorActiveArray: [],
      activeArray: [],
      getVendorData: [],
      list: [],
      name: "",
      options: [],
      multiple: false,
      addCPIDInURL: true,
      activateBroker: true,
      inputdisplay: "none",
      noWallboxExists: "",
      checkBoxDisplay: "none",
      getVendors: [],
      deletemodal1: false,
      modalsession: false,
      confirmassignmodal: false,
      fromDate: "",
      toDate: "",
      fromDateApi: "",
      toDateApi: "",
      modalView: false,
      selectedData: [],
      data: new Map(),
      noOfRecords: '',
      errors: {},
      items: [],
      disabledAttr: false,
      prevPageFirstCreTime: "",
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      id: '',
      page_title: "Vendor",
      vendorArray: [],
      fields: { id: '', active: '', certificateChain: '', contextPath: '', description: '', ocppProtocol: '', tlsCommunication: '', ocppServerURL: '', privateCertificate: '', publicCertificate: '', vendorName: '', vendorSite: '' },
      cpactivePage: 1,
      cppageSize: "30",
      cppageNo: 1,
      cpnoOfRecords: "",
      listOfFirstCreatedtimes: [],
      assignsuccessmodal: false,
      updatemodal: false,
      deletemodal: false,
      selectedVendorId: '',
      isLoading: false,
    }

    this.ocppProtocolChange = this.ocppProtocolChange.bind(this);
    this.brokerEdit = this.brokerEdit.bind(this);
    this.brokerDelete = this.brokerDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addtoggle = this.addtoggle.bind(this);
    this.addtoggle1 = this.addtoggle1.bind(this);
    this.deletetoggle = this.deletetoggle.bind(this);
    this.saveeditdata = this.saveeditdata.bind(this);
    this.deletetoggleOk = this.deletetoggleOk.bind(this);
    this.deletetoggle1 = this.deletetoggle1.bind(this);
    this.toggleedit = this.toggleedit.bind(this);
    this.activeBroker = this.activeBroker.bind(this);
    this.cpidUrl = this.cpidUrl.bind(this);
    this.getVendorList = this.getVendorList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.getSearchVendorList = this.getSearchVendorList.bind(this);
    this.vendorNameChange = this.vendorNameChange.bind(this);
    this.getSelectedVendorName = this.getSelectedVendorName.bind(this);
    this.FindVendorName1 = this.FindVendorName1.bind(this);
    this.getSelectedVendorName1 = this.getSelectedVendorName1.bind(this);
    this.ClearVendorName = this.ClearVendorName.bind(this);
    this.getApiChargePoints = this.getApiChargePoints.bind(this);
    this.assignVendor = this.assignVendor.bind(this);
    this.confirmassigntoggle = this.confirmassigntoggle.bind(this);
    this.assignsuccesstoggle = this.assignsuccesstoggle.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.viewAssignedVendors = this.viewAssignedVendors.bind(this);
    this.comparingChargePoints = this.comparingChargePoints.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.assignclosetoggle = this.assignclosetoggle.bind(this);
    this.subassignclosetoggle = this.subassignclosetoggle.bind(this);
    this.closetoggle = this.closetoggle.bind(this);
    this.displayAssignChargePoints = this.displayAssignChargePoints.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.cpshowEntriesChange = this.cpshowEntriesChange.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.cphandlePageChange = this.cphandlePageChange.bind(this);
    this.getVendorList = this.getVendorList.bind(this);
    this.confirmOk = this.confirmOk.bind(this);
    this.removeCpId = this.removeCpId.bind(this);
  }

  removeCpId(id) {
    var idList = this.state.cpList;
    var index = this.state.cpList.indexOf(id);;
    idList.splice(index, 1);

    this.setState({ cpList: idList })
  }

  closetoggle = () => {
    let fields = this.state.fields;
    let errors = {};

    fields.vendorName = "";
    fields.ocppServerURL = "";
    fields.ocppProtocol = "";
    fields.vendorSite = "";
    fields.description = "";
    fields.contextPath = "";

    this.setState({
      modal: !this.state.modal,
      activateBroker: true,
      errors: errors,
      fields
    });
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  // method called when click on view icon
  toggleView = (id, vendorName) => {
    this.setState({ 
      selectedVendorId: id, 
      selectedVendorName: vendorName,
      modalView: !this.state.modalView
    }, ()=>{
      localStorage.setItem("getId", id);
      this.getApiChargePoints(id, true);
      if (this.state.modalView) {
        if (this.state.modelView !== undefined) {
          const { getChargePoints } = this.props;
          getChargePoints(this.state.cpList);
        }
      }
    })
  }

  assignsuccesstoggle() {
    this.setState({
      assignsuccessmodal: !this.state.assignsuccessmodal
    });
  }

  confirmassigntoggle(event) {
    if (event !== undefined) {
      var duplicateArray1 = Array.from(new Set(this.state.cpList));

      if (duplicateArray1.length === 0 && this.state.assignAction === "Assign") {
        this.setState({ message: this.props.t('enter_charge_point_id'), colour: 'danger' }, () => {
          this.onShowAlert();
        })
      } else {
        let errors = {};
        errors["chargePointNotExists"] = "";
        errors["selectChargepoint"] = "";

        this.setState({
          modalView: !this.state.modalView,
          confirmassignmodal: !this.state.confirmassignmodal,
          cppageSize: 30,
          cpactivePage: 1,
          fromDateApi: "",
          toDateApi: "",
          errors: errors
        });
      }
    }
  }

  // method to clear search filter
  ClearVendorName(ref, event) {
    let errors = {};
    errors["vehicleNameNotexists"] = "";
    errors["selectVendorName"] = "";
    ref.current.clear();
    this.setState({
      errors: errors,
      pageSize: 10,
      activePage: 1
    }, ()=>{
      this.getVendorList(this.state.pageSize, 1);
    });
  }

  FindVendorName1(ref, event) {
    var name = ref.current.inputNode.value;
    this.getSelectedVendorName1(name);
  }

  //api to get selected Venodr name
  async getSelectedVendorName1(name) {
    this.setState({ loading: true })
    let errors = {};

    if (!name) {
      errors["selectVendorName"] = this.props.t('enter_vendor_name');
      this.setState({ errors: errors, loading: false })
    } else {
      let url = baseUrl.URLPath + servicesUrl.getBrokerVendorName;
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      let payload = { "vendorName": name }
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      
      if (!response.status) {
        var getVendorData = [];
        getVendorData = response.data["vendorDTOS"]
        if (response.data.status === "FAILURE") {
          errors["vehicleNameNotexists"] = this.props.t('vendor_name_not_exists');
          this.setState({ errors: errors, loading: false })
        } else {
          this.setState({ getVendors: getVendorData, loading: false })
        }
      }
    }
  }

  // logic to get vendorName based on search filter
  vendorNameChange(name) {
    if (name.length === 0) {
      this.getVendorList(this.state.pageSize, this.state.activePage)
    } else {
      let errors = {};
      errors["vehicleNameNotexists"] = "";
      errors["selectVendorName"] = "";
      this.setState({
        errors: errors
      }, ()=>{
        this.getSelectedVendorName(name);
      });
    }
  }

  //api to get selected vehicle name
  async getSelectedVendorName(name) {
    let errors = {};

    if (!name) {
      errors["selectVendorName"] = this.props.t('enter_vendor_name');
      this.setState({ errors: errors })
    } else {
      var newArray = name.join();
      let url = baseUrl.URLPath + servicesUrl.getBrokerVendorName;
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      let payload = { "vendorName": newArray }

      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      if (!response.status) {
        var getVendorData = [];
        getVendorData = response.data["vendorDTOS"];

        if (response.data.status === "FAILURE") {
          errors["vehicleNameNotexists"] = this.props.t('vendor_name_not_exists');
          this.setState({ errors: errors });
        } else {
          this.setState({ getVendors: getVendorData });
        }
      }
    }
  }

  handleKeyDown(ref, e) {
    var serial_no = e.target.value;
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);

    var x = e.target.value;
    var sno = x.slice(0, x.length - 1)
    if (sno === "") {
      this.setState({ name: sno }, ()=>{
        this.getVendorList(this.state.pageSize, this.state.activePage)
      })
    }

    this.getSearchVendorList(sno);

    if (e.keyCode === 13) {
      this.FindVendorName1(ref, e);
      $("#rbt_id").hide();
    }

    if ((e.keyCode === 86 && ctrl)) {
      this.setState({ options: [] });
      this.getSearchVendorList(serial_no)
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }

  async getSearchVendorList(name) {
    var url = baseUrl.URLPath + servicesUrl.getBrokerVendorSearchmatchedname + "?name=" + name;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (!response.status) {
      this.setState({
        options: response
      });
    }
  }

  //show entries change
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    this.setState({ activePage: 1, pageSize: entries }, ()=>{
      this.getVendorList(this.state.pageSize, this.state.activePage, true);
    })
  }

  //show entries change
  cpshowEntriesChange() {
    var entries = document.getElementById("cpshowEntries").value;

    this.setState({
      cpactivePage: 1,
      cppageSize: entries,
      fromDateApi: "",
      toDateApi: ""
    }, () => {
      this.getApiChargePoints(this.state.selectedVendorId, true, false);
    })
  }

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {

      this.setState({ activePage: pageNumber }, ()=>{
        this.getVendorList(this.state.pageSize, this.state.activePage, true);
      });
    }
  }

  cphandlePageChange(pageNumber) {
    if (pageNumber < this.state.cpactivePage) {
      //prev
      this.setState({
        cpactivePage: pageNumber,
        fromDateApi: '',
        // fromDateApi: this.state.curPageFirstCreTime,
        toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getApiChargePoints(this.state.selectedVendorId, false, false);
      });
    } else {
      //next
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({
        cpactivePage: pageNumber,
        fromDateApi: '',
        toDateApi: this.state.curPageLastCreTime,
        prevPageFirstCreTime: this.state.curPageFirstCreTime,
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.createdDatesArray(this.state.curPageFirstCreTime);
        }
        this.getApiChargePoints(this.state.selectedVendorId, false, false);
      });
    }
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }

  activeBroker = () => {
    let x = document.getElementById("myCheck1").checked;

    this.setState({ 
      activateBroker: (x === true) ? true : false 
    });
  }

  cpidUrl = () => {
    let y = document.getElementById("myCheck2").checked;

    this.setState({ 
      addCPIDInURL: (y === true) ? true : false 
    });
  }

  async getVendorCount() {
    let url = baseUrl.URLPath + servicesUrl.getVendorCount;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeadersWithTimezone();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (!response.status) {
      this.setState({ noOfRecords: response.count });
    }
  }

  componentDidMount() {
    localStorage.setItem("vennoWallboxExists", "");
    this.getVendorCount()
    this.getVendorList(this.state.pageSize, this.state.activePage, true);
  }

  // to get vendor list
  async getVendorList(pageSize, pageNo, isLoading) {
    this.setState({ isLoading: isLoading })
    var url = baseUrl.URLPath + servicesUrl.getBrokerVendorSearch + "?pageNo=" + pageNo + "&pageSize=" + pageSize;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (!response.status) {
      this.setState({ getVendors: response, isLoading: false })
    }
  }

  confirmOk() {
    this.getVendorList(this.state.pageSize, this.state.activePage, true);
    this.deletetoggle();
  }

  toggle = () => {
    var errors = {};
    let fields = this.state.fields;

    fields.vendorName = "";
    fields.ocppServerURL = "";
    fields.vendorSite = "";
    fields.description = "";
    fields.contextPath = "";

    this.setState({
      modal: !this.state.modal,
      activateBroker: true,
      errors: errors,
      fields
    });
  }

  subassignclosetoggle = () => {
    this.setState({
      confirmassignmodal: !this.state.confirmassignmodal,
    });

    window.location.reload();
  }

  assignclosetoggle = () => {
    let errors = {};
    errors["chargePointNotExists"] = "";
    errors["selectChargepoint"] = "";

    this.setState({
      modalView: !this.state.modalView,
      cppageSize: 30,
      fromDateApi: "",
      toDateApi: "",
      cpactivePage: 1,
      cpList: [],
      errors: errors
    });
  }

  toggleedit() {
    this.setState({
      editmodal: !this.state.editmodal
    });
  }

  closetoggleedit = () => {
    this.setState({
      editmodal: !this.state.editmodal
    });
  }

  addtoggle = () => {
    this.setState({
      addmodal: !this.state.addmodal
    });
  }

  addtoggle1 = () => {
    this.setState({
      addmodal2: !this.state.addmodal2
    });
  }

  closeaddtoggle = () => {
    this.setState({
      addmodal: false
    });

    window.location.reload();
  }

  closeaddtoggle1 = () => {
    this.setState({
      addmodal2: false
    });
  }

  updatetoggle = () => {
    this.setState({
      updatemodal: !this.state.updatemodal,
      errors: {}
    });
  }

  closeupdatetoggle = () => {
    this.setState({
      updatemodal: false
    });

    window.location.reload();
  }

  deletetoggle = () => {
    this.setState({
      deletemodal: !this.state.deletemodal
    });
  }

  deletetoggle1 = () => {
    this.setState({
      deletemodal1: !this.state.deletemodal1
    });
  }

  closedeletetoggle = () => {
    this.setState({
      deletemodal1: !this.state.deletemodal1
    });
  }

  /* Edit Get*/
  async brokerEdit(id) {
    let url = baseUrl.URLPath + servicesUrl.getVendorBroker + "?id=" + id;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (!response.status) {
      this.setState({
        fields: response,
        activateBroker: response.activateBroker,
        addCPIDInURL: response.addCPIDInURL
      }, () => {
        this.toggleedit();
      });
    }
  }

  /* Edit Put */
  async saveeditdata() {
    let errors = {};
    let { vendorName, ocppServerURL, ocppProtocol } = this.state.fields;

    if (ocppProtocol === "") {
      errors["ocppProtocolEmpty"] = this.props.t('please_select_ocppProtocol');
    }
    if (vendorName === '') {
      errors["vendorNameempty"] = this.props.t('enter_vendor_name');
    }
    if (ocppServerURL === '') {
      errors["urlempty"] = this.props.t('enter_a_valid_url');
    }
    if (this.validateURL(ocppServerURL) === false) {
      errors["urlempty"] = this.props.t('enter_a_valid_url');
    }
    if (errors.hasOwnProperty("vendorNameempty") || errors.hasOwnProperty("urlempty") || errors.hasOwnProperty("ocppProtocolEmpty")) { }
    else {
      let url = baseUrl.URLPath + servicesUrl.getVendorBroker;
      let type = ApiMethodTypes.PUT;
      let headers = getSecurityHeaders();
      let data = { "id": this.state.fields.id, "vendorName": vendorName, "ocppServerURL": ocppServerURL, "ocppProtocol": ocppProtocol, "activateBroker": this.state.activateBroker, "addCPIDInURL": this.state.addCPIDInURL, "certificateChain": this.state.fields.certificateChain, "contextPath": this.state.fields.contextPath, "description": this.state.fields.description, "privateCertificate": this.state.fields.privateCertificate, "publicCertificate": this.state.fields.publicCertificate, "vendorSite": this.state.fields.vendorSite, "tlsCommunication": this.state.fields.tlsCommunication };
      
      let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t);
      
      if (!response.status) {
        if (response.data.status === "SUCCESS") {
          this.setState({
            updatestatus: this.props.t('succees_condition'),
            updatemsg: this.props.t('vendor_updated_successfully'),
            updatemodal: !this.state.updatemodal,
            errors: {}
          });
        } else if (response.data.status === "FAILURE") {
          this.setState({
            updatestatus: this.props.t('failure_condition'),
            updatemsg: this.props.t(response.data.errorMessage),
            updatemodal: !this.state.updatemodal,
            errors: {}
          });
        }
      }
    }

    this.setState({ errors: errors })
  }

  brokerDelete(id) {
    this.setState({ deleteId: id, deletemodal1: !this.state.deletemodal1 });
  }

  /* Delete */
  async deletetoggleOk() {
    let url = baseUrl.URLPath + servicesUrl.deleteVendor;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();
    let payload = { "id": this.state.deleteId, "vendorName": this.state.fields.vendorName, "ocppServerURL": this.state.fields.ocppServerURL, "ocppProtocol": this.state.fields.ocppProtocol, "activateBroker": this.state.activateBroker, "addCPIDInURL": this.state.addCPIDInURL, "certificateChain": this.state.fields.certificateChain, "contextPath": this.state.fields.contextPath, "description": this.state.fields.description, "privateCertificate": this.state.fields.privateCertificate, "publicCertificate": this.state.fields.publicCertificate, "vendorSite": this.state.fields.vendorSite, "tlsCommunication": this.state.fields.tlsCommunication };
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response) {
      if (response.data.status === "SUCCESS") {
        this.setState({
          deletemodal: !this.state.deletemodal,
          deletemodal1: false,
          deletestatus: this.props.t('succees_condition'),
          deletemsg: this.props.t('vendor_deleted_successfully')
        });
      } else if (response.data.status === "FAILURE") {
        this.setState({
          deletemodal: !this.state.deletemodal,
          deletestatus: this.props.t('failure_condition'),
          deletemsg: this.props.t(response.data.errorMessage),
          deletemodal1: false,
        });
      }
    }
  }

  validateURL(textval) {
    var urlregex = new RegExp("^(ws|wss)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}|[a-zA-Z]{3}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
    var var_name = urlregex.test(textval);
    return var_name;
  }

  /* Add */
  async handleSubmit(event) {
    event.preventDefault();
    let errors = {};
    var { vendorName, ocppServerURL, ocppProtocol } = this.state.fields;

    if (ocppProtocol === '') {
      errors["ocppProtocolEmpty"] = this.props.t('please_select_ocppProtocol');
    }
    if (vendorName === '') {
      errors["vendorNameempty"] = this.props.t('enter_vendor_name')
    }
    if (ocppServerURL === '') {
      errors["urlempty"] = this.props.t('enter_a_valid_url');
    }
    if (this.validateURL(ocppServerURL) === false) {
      errors["urlempty"] = this.props.t('enter_a_valid_url');
    }
    if (errors.hasOwnProperty("vendorNameempty") || errors.hasOwnProperty("urlempty") || errors.hasOwnProperty("ocppProtocolEmpty")) { }
    else {
      let url = baseUrl.URLPath + servicesUrl.getVendorBroker;
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      let payload = { "vendorName": vendorName, "ocppServerURL": ocppServerURL, "ocppProtocol": ocppProtocol, "activateBroker": this.state.activateBroker, "addCPIDInURL": this.state.addCPIDInURL, "certificateChain": this.state.fields.certificateChain, "contextPath": this.state.fields.contextPath, "description": this.state.fields.description, "privateCertificate": this.state.fields.privateCertificate, "publicCertificate": this.state.fields.publicCertificate, "vendorSite": this.state.fields.vendorSite, "tlsCommunication": this.state.fields.tlsCommunication };
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if (response) {
        if (response.data.status === "SUCCESS") {
          this.setState({
            addstatus: this.props.t('succees_condition'),
            addmsg: this.props.t('vendor_added_successfully'),
            addmodal: !this.state.addmodal
          });
        } else if (response.data.status === "FAILURE") {
          this.setState({
            addmodal2: !this.state.addmodal2,
            addstatus: this.props.t('failure_condition'),
            addmsg: this.props.t(response.data.errorMessage)
          });
        }
      }
    }

    this.setState({ errors: errors });
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  ocppProtocolChange() {
    var x = document.getElementById("ocppProtocol").value;
    let fields = this.state.fields;
    fields["ocppProtocol"] = x;
    this.setState({ fields });
  }

  getApiChargePoints(id, countValue, cpdisplay, cpid) {
    var cpId = "";
    let cpactivePage = "";
    let fromDateApi = "";
    let toDateApi = "";

    if (cpid !== "" && cpid !== undefined && cpid !== null) {
      cpId = cpid;
      cpactivePage = 1;
      fromDateApi = "";
      toDateApi = "";
    } else {
      cpactivePage = this.state.cpactivePage;
      fromDateApi = this.state.fromDateApi;
      toDateApi = this.state.toDateApi;
    }

    this.setState({ cpactivePage: cpactivePage, fromDateApi: fromDateApi, toDateApi: toDateApi }, () => {
      this.getApiCpIds(cpId, countValue, cpdisplay);
    });
  }

  async getApiCpIds(cpId, countValue, cpdisplay){
    this.setState({ isLoading: true })
    var id = localStorage.getItem("getId");
    var url = baseUrl.URLPath + servicesUrl.getBrokerVendorMapperMapCharger + "?vendorId=" + id + "&pageNo=" + this.state.cpactivePage + "&pageSize=" + this.state.cppageSize + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi + "&chargePointId=" + cpId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      if (cpId !== "") {
        localStorage.setItem("vennoWallboxExists", response?.count?.count ?? 0);
      }

      if (response === 0 || response === "") {
        this.setState({ message: this.props.t('points_not_available_for_selected_vendor'), colour: 'danger', assignAction: "Assign", disabledAttr: true }, ()=>{
          this.onShowAlert();
        })
      }

      var chargePoint;
      chargePoint = Array.from(Object.values(response.data), cp => cp.chargePointId)
      var totalcount = "";

      if (countValue === false) {
        totalcount = this.state.cpnoOfRecords
      } else {
        totalcount = response.count.count
      }

      var noWallboxExists1 = "";
      if (cpId !== "" && response.data.length === 0) {
        this.setState({
          noWallboxExists: 0,
          isLoading: false
        })
        noWallboxExists1 = 0;
      } else {
        this.setState({
          optionsdropdown: chargePoint,
          noWallboxExists: noWallboxExists1,
          selectedData: response.data,
          cpnoOfRecords: totalcount,
          prevPageFirstCreTime: this.state.curPageFirstCreTime,
          curPageFirstCreTime: moment(response?.data[0]?.modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
          curPageLastCreTime: moment(response?.data[response?.data?.length - 1]?.modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
          isLoading: false
        })
      }

      if (this.state.optionsdropdown.length !== 0) {
        this.viewAssignedVendors(id, cpdisplay, cpId);
      }
    }
  }

  // function get called when click on assign button
  async assignVendor() {
    this.setState({ confirmassignmodal: !this.state.confirmassignmodal });
    if (this.state.selectedVendorId !== "") {
      var url = baseUrl.URLPath + servicesUrl.getAssignVendor;
      var duplicateArray = Array.from(new Set(this.state.cpList));
      var payload = {
        "vendorBrokerId": this.state.selectedVendorId
      }

      if (duplicateArray.length === 0) {
        payload.chargePointIds = [];
      } else {
        payload.chargePointIds = duplicateArray;
      }

      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      
      if (response.message==="SUCCESS") {
        this.assignsuccesstoggle();
      }
    }
  }

  async viewAssignedVendors(id, countValue, cpid) {
    if (id !== "undefined" && id !== undefined) {
      let errors = {};
      let url = baseUrl.URLPath + servicesUrl.viewAssignedVendors;
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      let payload = { "vendorBrokerId": id }
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if (response) {
        var getAssignedVendors = [];
        var getChargePointIds = [];
        getAssignedVendors = response.data["vendorCSMapDTOS"]
        for (var i = 0; i < getAssignedVendors.length; i++) {
          getChargePointIds.push(getAssignedVendors[i]["chargePointId"]);
        }
        if (response.data.status === "FAILURE") {
          errors["vehicleNameNotexists"] = this.props.t('vendor_name_not_exists');
          this.setState({ errors: errors });
        } else {
          this.setState({ allChargePoints: getChargePointIds }, ()=>{
            this.comparingChargePoints(countValue, cpid);
          })
        }
      }

      this.setState({ errors: errors });
    }
  }

  displayAssignChargePoints() {
    var allChargePoints = this.state.allChargePoints;
    this.setState({ cpList: allChargePoints });
  }

  // method on check and uncheck the checkbox
  handleChange(id, e) {
    this.setState({ check_type: "Checkbox" });
    var cpValue = storedSelectedCP(id, this.state.cpList, this.state.finalStatus, this.state.optionsdropdown);
    this.setState({
      cpList: cpValue.cpList,
      disabledAttr: cpValue.disabledAttr
    })
  }

  comparingChargePoints(countValue, cpid) {

    let allChargePointsArray = this.state.optionsdropdown;
    let matchedArray = [];

    if (countValue === false) {
      matchedArray = this.state.cpList;
    } else {
      matchedArray = this.state.allChargePoints;
    }

    if (matchedArray.length === 0) {
      this.setState({
        assignAction: this.props.t('assign'),
        assignSuccessName: this.props.t('assigned')
      });
    } else if (matchedArray.length === 1 && allChargePointsArray.length === 1 && cpid === "") {
      this.setState({
        assignAction: this.props.t('remove'),
        assignSuccessName: this.props.t('removed'),
        disabledAttr: true
      });
    } else if (matchedArray.length >= 1 && allChargePointsArray.length >= 1) {
      this.setState({
        assignAction: this.props.t('reassign'),
        assignSuccessName: this.props.t('reassigned')
      });
    } else if (matchedArray.length > 1) {
      this.setState({
        assignAction: this.props.t('reassign'),
        assignSuccessName: this.props.t('reassigned')
      });
    }

    let map = {};
    allChargePointsArray.forEach(i => map[i] = false);
    matchedArray.forEach(i => map[i] === false && (map[i] = true));
    let comparingPoints = Object.keys(map).map(k => ({ matchedChargepointId: k, matched: map[k] }));

    var finalArray = [];
    var selectChargePointIds = []

    for (var i = 0; i < comparingPoints.length; i++) {
      finalArray.push(comparingPoints[i]);
    }

    for (var j = 0; j < finalArray.length; j++) {
      if (finalArray[j]["matched"] === true) {
        var cpIds = finalArray[j]["matchedChargepointId"];
        selectChargePointIds.push(cpIds);
      }
    }

    this.setState({ finalStatus: selectChargePointIds, jsonArray: finalArray })

    if (countValue === false) {
    } else {
      this.displayAssignChargePoints();
    }
  }

  //function for sorting table data on click
  ascendingOrder() {
    if (this.state.vendorNameSorting === false) {
      let vendors = this.state.getVendors;
      vendors = vendors.sort(this.dynamicSort("vendorName"));

      this.setState({ getVendors: vendors, vendorNameSorting: true })
    } else {
      let vendors = this.state.getVendors;
      vendors = vendors.sort(this.dynamicSort("vendorName"));

      this.setState({ getVendors: vendors.reverse(), vendorNameSorting: false });
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  render() {
    const { t } = this.props
    const ref = React.createRef();

    const data = this.state.getVendors.map((getVendors, index) => {
      return (
        <tr>
          <td className="text-rowcen td-wrap-word">{getVendors.vendorName}</td>
          <td className="text-rowcen td-wrap-word"> {getVendors.vendorSite}</td>
          <td className="text-rowcen">{getVendors.ocppProtocol}</td>
          <td className="wrap-word text-rowcen td-wrap-word">{getVendors.ocppServerURL}</td>
          <td className="text-rowcen">&nbsp;<i className="fas fa-pencil-alt" title="Edit" style={{ cursor: 'pointer' }} onClick={this.brokerEdit.bind(this, getVendors.id)} ></i>  &nbsp;
            <i className="far fa-trash-alt" title="Delete" style={{ cursor: 'pointer' }} onClick={this.brokerDelete.bind(this, getVendors.id)}></i>
          </td>
          <td className="text-rowcen"><i className="fas fa-eye action-icon  mt-1 pl-4" title="View" style={{ cursor: 'pointer' }} onClick={this.toggleView.bind(this, getVendors.id, getVendors.vendorName)}
          ></i></td>
        </tr>
      )
    })

    return (
      <main className="content-div" >
        <p>{t('Vendor')}
          <div className="breadcrumb_div">
            {t('Market Place')} &gt; <span className="breadcrumb_page"> {t('Vendor')}</span>
          </div>
        </p>
        <div className="page-outerdiv">
          <div className="row menubar">
            <div className="col-md-2 ">
              <div className="">
                <button onClick={this.toggle} type="button" data-test="button">
                  <i className="fa fa-plus"></i>
                  <span>{t('add')}</span>
                </button>
              </div>
            </div>
            <div className="col-md-10">
              <div className="pull-right">
                <label className="pull-left sub-text">{t('show_entries')}</label>
                <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4  pull-right">
              <div className="input-group">
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <Typeahead
                  labelKey="name"
                  className="w_80"
                  multiple={this.state.multiple}
                  options={this.state.options}
                  minLength={3}
                  placeholder={t('enter_vendor')}
                  onKeyDown={this.handleKeyDown.bind(this, ref)}
                  onChange={this.vendorNameChange.bind(this)}
                  id="rbt_id"
                  ref={ref}
                />
                <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearVendorName.bind(this, ref)}>
                  <span aria-hidden="true">×</span>
                </button>
                <div className="input-group-btn">
                  <button className="input-searchBtn find-btn" type="submit" onClick={this.FindVendorName1.bind(this, ref)}>{t('find')}</button>
                </div>
                <span className="pull-left error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.state.errors["vehicleNameNotexists"]}</span></span>
                <span className="pull-left error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.state.errors["selectVendorName"]}</span></span>
              </div>
            </div>
            <div className="col-md-3 pl-0"></div>
            <div className="col-md-2"></div>
          </div>

          <div className="row ">
            <div className="col-md-12">
              <Table striped hover size="sm" className="page-table">
                <thead>
                  <tr>
                    <th className="text-rowcen">{t('vendor_nam')} <i className="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                    <th className="text-rowcen">{t('vendor_site')}</th>
                    <th className="text-rowcen">{t('ocpp_protocol')}</th>
                    <th className="text-rowcen">{t('ocpp_server_url')}</th>
                    <th className="text-rowcen">{t('action')}</th>
                    <th className="text-rowcen">{t('mapping')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data}
                </tbody>
              </Table>
            </div>
            <div className="col-md-12 mt-2 mb-4 text-right">
              <Pagination
                hideDisabled
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.pageSize}
                totalItemsCount={this.state.noOfRecords}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </div>
        </div>

        <MDBModal isOpen={this.state.modal} toggle={this.toggle} className="model_quickview" size="xl" backdrop={true} wrapClassName="custom_backdropclass">
          <div data-test="modal-header" className="modal-header">
            <div className="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('insert_your_data')}</div>
            <button type="button" data-test="button" className="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <MDBModalBody className="popupScroll">
            <MDBRow className="mb-4 mt-3">
              <MDBCol sm="12">
                <div className="alert alert-success alert-dismissible mt-2" id="suc_msg" style={{ display: "none" }}></div>
                <div>
                  <form
                    className='needs-validation'
                    onSubmit={this.handleSubmit}
                    noValidate
                  >
                    <MDBRow>
                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.fields["vendorName"]}
                          name="vendorName"
                          onChange={this.changeHandler.bind(this, "vendorName")}
                          type="text"
                          id="vendorName"
                          label={t('vendor_name') + " *"}
                        >
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["vendorNameempty"]}</span></span>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          name="contextPath"
                          value={this.state.fields["contextPath"]}
                          onChange={this.changeHandler.bind(this, "contextPath")}
                          type="text"
                          id="contextPath"
                          label={t('context_Path')}
                        >
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="mt-3">
                        <Form.Check
                          custom
                          value={this.state.activateBroker}
                          checked={this.state.activateBroker}
                          onChange={this.activeBroker}
                          type="checkbox"
                          id="myCheck1"
                          label={t('activate_broker')}
                        />
                      </MDBCol>
                      <MDBCol md="4" className="form_margin" >
                        <MDBInput
                          value={this.state.fields["description"]}
                          name="description"
                          onChange={this.changeHandler.bind(this, "description")}
                          type="text" x
                          id="description"
                          label={t('description')}
                        />
                      </MDBCol>
                      <MDBCol md="4" className="form_margin" >
                        <select className="browser-default custom-select select_height mb_8" id="ocppProtocol" onChange={this.ocppProtocolChange.bind(this)} value={this.state.fields.ocppProtocol}>
                          <option value=""> -- {t('select_ocpp_protocol')} * --</option>
                          <option value="OCPP2.0">{t('ocpp20')}</option>
                          <option value="OCPP1.6">{t('ocpp16')}</option>
                        </select>
                        <span className="error_msg w3-animate-top">
                          <span style={{ color: "red" }}>{this.state.errors["ocppProtocolEmpty"]}</span>
                        </span>
                      </MDBCol>
                      <MDBCol md="4" className="mt-3" id="chkbox-down" style={{ display: this.state.checkBoxDisplay }}>
                        <Form.Check
                          custom
                          value={this.state.addCPIDInURL}
                          onChange={this.cpidUrl}
                          checked={this.state.addCPIDInURL}
                          type="checkbox"
                          id="myCheck2"
                          label={t('add_cpid_in_url')}
                        />
                      </MDBCol>
                      <MDBCol md="4" className="form_margin" >
                        <MDBInput
                          value={this.state.fields["ocppServerURL"]}
                          name="ocppServerURL"
                          onChange={this.changeHandler.bind(this, "ocppServerURL")}
                          type="text"
                          id="ocppServerURL"
                          label={t('ocpp_server_url') + "*"}
                        >
                          <span className="error_msg w3-animate-top">
                            <span style={{ color: "red" }}>{this.state.errors["urlempty"]}</span>
                          </span>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="form_margin" >
                        <MDBInput
                          value={this.state.fields["vendorSite"]}
                          name="noteId"
                          onChange={this.changeHandler.bind(this, "vendorSite")}
                          type="text"
                          id="noteId"
                          label={t('vendor_site')}
                        />
                      </MDBCol>
                    </MDBRow>
                  </form>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter className="border-top-0 p-3">
            <button onClick={this.closetoggle} className="btn_cancel" type="button" data-test="button"> {t('cancel')} <i className="fas fa-times ml-2"></i></button>
            <button onClick={this.handleSubmit} className="btn_primary" type="button" data-test="button"> {t('save')} <i className="fas fa-save ml-2"></i></button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.editmodal} toggle={this.toggleedit} className="model_quickview" size="xl" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
          <div data-test="modal-header" className="modal-header">
            <div className="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('edit_your_data')}</div>
            <button type="button" data-test="button" className="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <MDBModalBody className="popupScroll" >
            <form className="needs-validation">
              <MDBRow>
                <MDBCol md="4" className="" >
                  <MDBInput
                    value={this.state.fields["vendorName"]}
                    name="vendorName"
                    onChange={this.changeHandler.bind(this, "vendorName")}
                    type="text"
                    id="vendorName"
                    label={t('vendor_name') + " *"}
                  >
                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["vendorNameempty"]}</span></span>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="4">
                  <MDBInput
                    name="contextPath"
                    value={this.state.fields["contextPath"]}
                    onChange={this.changeHandler.bind(this, "contextPath")}
                    type="text"
                    id="contextPath"
                    label={t('context_Path')}
                  />
                </MDBCol>
                <MDBCol md="4" className="mt-3">
                  <Form.Check
                    custom
                    value={this.state.activateBroker}
                    checked={this.state.activateBroker}
                    onChange={this.activeBroker}
                    type="checkbox"
                    id="myCheck1"
                    label={t('activate_broker')}
                  />
                </MDBCol>
                <MDBCol md="4" className="form_margin" >
                  <MDBInput
                    value={this.state.fields["description"]}
                    name="description"
                    onChange={this.changeHandler.bind(this, "description")}
                    type="text"
                    id="description"
                    label={t('description')}
                  />
                </MDBCol>
                <MDBCol md="4" className="form_margin" >
                  <select className="browser-default custom-select select_height mb_8" id="ocppProtocol" onChange={this.ocppProtocolChange.bind(this)}  >
                    <option value=""> -- {t('select_ocpp_protocol')} * --</option>
                    <option value="OCPP2.0">{t('ocpp20')}</option>
                    <option value="OCPP1.6">{t('ocpp16')}</option>
                  </select>
                  <span className="error_msg w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["ocppProtocolEmpty"]}</span></span>
                </MDBCol>
                <MDBCol md="4" className="mt-3" id="chkbox-down" style={{ display: this.state.checkBoxDisplay }}>
                  <Form.Check
                    custom
                    value={this.state.addCPIDInURL}
                    onChange={this.cpidUrl}
                    checked={this.state.addCPIDInURL}
                    type="checkbox"
                    id="myCheck2"
                    label={t('add_cpid_in_url')}
                  />
                </MDBCol>
                <MDBCol md="4" className="form_margin" >
                  <MDBInput
                    value={this.state.fields["ocppServerURL"]}
                    name="ocppServerURL"
                    onChange={this.changeHandler.bind(this, "ocppServerURL")}
                    type="text"
                    id="ocppServerURL"
                    label={t('ocpp_server_url') + "*"}
                  >
                    <span className="error_msg w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["urlempty"]}</span></span>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="4" className="form_margin" >
                  <MDBInput
                    value={this.state.fields["vendorSite"]}
                    name="noteId"
                    onChange={this.changeHandler.bind(this, "vendorSite")}
                    type="text"
                    id="noteId"
                    label={t('vendor_site')}
                  />
                </MDBCol>
              </MDBRow>
            </form>
          </MDBModalBody>
          <MDBModalFooter className="border-top-0 mt-2">
            <button className="btn_cancel" onClick={this.closetoggleedit} type="button" data-test="button"> {t('cancel')} <i className="fas fa-times ml-2"></i></button>
            <button className="btn_primary" onClick={this.saveeditdata.bind(this)} type="button" data-test="button">{t('update')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.addmodal} toggle={this.addtoggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.addtoggle}>{this.state.addstatus}</MDBModalHeader>
          <MDBModalBody>
            {this.state.addmsg}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.closeaddtoggle}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.addmodal2} toggle={this.addtoggle1} size="md" className="model_top">
          <MDBModalHeader toggle={this.addtoggle1}>{this.state.addstatus}</MDBModalHeader>
          <MDBModalBody>
            {this.state.addmsg}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.closeaddtoggle1}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.updatemodal} toggle={this.updatetoggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.updatetoggle}>{this.state.updatestatus}</MDBModalHeader>
          <MDBModalBody>
            {this.state.updatemsg}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.closeupdatetoggle}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.closeupdatetoggle}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.deletemodal1} toggle={this.deletetoggle1} size="md" className="model_top">
          <MDBModalHeader toggle={this.deletetoggle1}>{t('confirmation')}</MDBModalHeader>
          <MDBModalBody>
            {t('are_you_want_to_delete')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_cancel" onClick={this.closedeletetoggle}>{t('cancel')} <i className="fas fa-times ml-2"></i></button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.deletetoggleOk}>{t('yes')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.deletemodal} toggle={this.deletetoggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.deletetoggle}>{this.state.deletestatus}</MDBModalHeader>
          <MDBModalBody>
            {this.state.deletemsg}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.confirmOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        {/* confirm assign */}
        <MDBModal isOpen={this.state.confirmassignmodal} toggle={this.confirmassigntoggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.confirmassigntoggle}>{t('confirmation')}</MDBModalHeader>
          <MDBModalBody>
            {t('are_you_want_to')} {this.state.assignAction} {t('the_vendor')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_cancel" onClick={this.subassignclosetoggle}>{t('cancel')} <i className="fas fa-times ml-2"></i></button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.assignVendor}>{t('yes')}</button>
          </MDBModalFooter>
        </MDBModal>

        {/* assignSuccess */}
        <MDBModal isOpen={this.state.assignsuccessmodal} size="md" className="model_top">
          <MDBModalHeader toggle={this.assignsuccesstoggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('Vendor')} {this.state.assignSuccessName} {t('successfully')}
          </MDBModalBody>
          <MDBModalFooter>
          <button type="button" data-test="button" className="btn_primary" onClick={() => {this.assignsuccesstoggle.bind(this); window.location.reload()}}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        {/* vendorassignment */}
        <ChargePointModel
          pageName="Vendor"
          data={this.state.jsonArray}
          cpList={this.state.cpList}
          isOpen={this.state.modalView}
          toggle={this.toggleView}
          selectedVendorName={this.state.selectedVendorName}
          cancelFunction={this.assignclosetoggle}
          doneFunction={this.confirmassigntoggle}
          btnDisable={this.state.disabledAttr}
          btnText={this.state.assignAction}
          cpactivePage={this.state.cpactivePage}
          itemsCountPerPage={this.state.cppageSize}
          noOfRecords={this.state.cpnoOfRecords}
          optionsdropdown={this.state.optionsdropdown}
          finalStatus={this.state.finalStatus}
          isCheckBox={this.state.isCheckBox}
          handlePageChange={this.cphandlePageChange}
          handleChange={this.handleChange}
          showEntriesChange={this.cpshowEntriesChange}
          getData={this.getApiChargePoints}
          vid={this.state.selectedVendorId}
          noWallboxExists={this.state.noWallboxExists}
          removeCpId={this.removeCpId}
        >

        </ChargePointModel>
        
        {this.state.isLoading ? (
          <Loader loader={this.state.isLoading} zIndex={true} />
        ) : ("")}
      </main>
    );
  }
}


export default withTranslation()(vendor);