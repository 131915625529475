import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as baseUrl from './constants';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import servicesUrl from '../common/servicesUrl';

import { withTranslation } from 'react-i18next';
//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders, getSecurityHeadersWithTimezone } from '../common/HttpRequestClass';


const history = createBrowserHistory();



class editInventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveMfgDate: "",
      fields: { masterPassword: '', chargePointName: '', startSchedule: '', serialNumber: '', manufacturingDate: '', mfgDate: '', modelName: '', partNumber: '', mesSerialNumber: '', fwVersion: '', ocppPassword: '', accessCode: '', vendorCode: '' },
      sucess: {},
      errors: {},
      activeItem: "1",
      model: "false",
      page_title: "Edit Inventory",
      dataArray: [],
      modalsession: false,
      roles: [],
      disabledAttr: true,

    }
    this.toggle = this.toggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.addNew = this.addNew.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.backToList = this.backToList.bind(this);
    this.confirmtoggle1 = this.confirmtoggle1.bind(this);
    this.setStartSchedule = this.setStartSchedule.bind(this)



  }
  //This function for setting the date to state
  setStartSchedule(date) {
    var dateNew = this.formatDateandTime(date)
    var fields = this.state.fields;
    fields["manufacturingDate"] = dateNew
    this.setState({
      saveMfgDate: date,
      fields
    })
    this.setState({ disabledAttr: "" });

  }
  formatDateandTime(currentDate) {
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var createdDateTo = twoDigitMonth + "-" + twoDigitDate + "-" + currentDate.getFullYear() + ' ' + twohoursDate + ':' + twominDate;
    // this.setState({saveMfgDate:createdDateTo});
    // if(createdDateTo === "01-01-1970 05:30"){
    //   this.state.saveMfgDate = "";
    // }
    return createdDateTo;
  }
  confirmtoggle1(event) {
    // event.preventDefault();
    ;
    this.setState({
      confirmmodal1: !this.state.confirmmodal1
    });
  }
  backToList() {
    this.props.history.push('/inventoryList');
  }

  async togglesession() {
    ;
    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }

  tagOk() {
    this.props.history.push('/inventoryList');
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }




  addNew() {
    this.props.history.push('/Userlist');
  }


  // checking the format of number
  numberHandler(field, e) {
    let fields = this.state;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }
  changeHandler(field, e) {


    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    this.setState({ disabledAttr: "" });

  }

  async handleSubmit(event) {
    ;
    event.preventDefault();
    this.setState({
      confirmmodal1: !this.state.confirmmodal1
    });
    ;
    let errors = {};
    var serialNumber = this.state.fields['serialNumber'];
    var mfgDate = this.state.fields['manufacturingDate'];
    var mesSerialNumber = this.state.fields['mesSerialNumber'];
    var cpid = this.props.location.state;

    mesSerialNumber = "ffff";
    this.state.fields['mesSerialNumber'] = "fff";

    if (serialNumber === '') {
      errors["serialnoEmpty"] = this.props.t('enter_chargepoint_id');
    }
    else {
      if (serialNumber.length < 6) {
        errors["min"] = this.props.t('charge_point_length_supports_6to20_char')
      }
      if (serialNumber.length > 20) {
        errors["max"] = this.props.t('charge_point_length_supports_6to20_char')

      }
    }
    if (mesSerialNumber === "" || mesSerialNumber === null) {
      errors["mesNoEmpty"] = this.props.t('enter_mes_serial_no');
    }

    if (errors.hasOwnProperty("serialnoEmpty") || errors.hasOwnProperty("mesNoEmpty") || errors.hasOwnProperty("min") || errors.hasOwnProperty("max")) { }
    else {

      if (this.state.fields.manufacturingDate === null || this.state.fields.manufacturingDate === "") {
        mfgDate = "";
      }
      else {
        mfgDate = moment(this.state.fields.manufacturingDate).format("MM-DD-YYYY HH:mm").toUpperCase();

      }

      let url = baseUrl.URLPath + servicesUrl.updateCpInventory + "?cpid=" + cpid;

      let data = { "serialNumber": this.state.fields.serialNumber, "mfgDate": mfgDate, "modelName": this.state.fields.modelName, "partNumber": this.state.fields.partNumber, "mesSerialNumber": this.state.fields.mesSerialNumber, "fwVersion": this.state.fields.fwVersion, "accessCode": this.state.fields.accessCode, "ocppPassword": this.state.fields.ocppPassword, "vendorCode": this.state.fields.vendorCode, "chargePointName": this.state.fields.chargePointName, "masterPassword": this.state.fields.masterPassword };

      let type = ApiMethodTypes.PUT;
      let headers = getSecurityHeadersWithTimezone();
      let payload = data;

      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      this.toggle()

      if (response.message === "") {
        errors["min"] = this.props.t('charge_point_length_supports_6to20_char')
        this.setState({
          errors: errors
        });
      }
      if (response.message === 'charge_point_id_already_exist') {
        errors["chargePointExists"] = this.props.t('charge_point_id_exist')
        this.setState({
          errors: errors
        });
      }
      if (response.message === 'mes_serial_no_not_be_empty') {
        errors["mesNoEmpty"] = this.props.t('enter_mes_serial_no');
        this.setState({
          errors: errors
        });
      }
      if (response.message === 'mes_serial_Number_exists') {
        errors["mesExists"] = this.props.t('mes_serial_Number_exists');
        this.setState({
          errors: errors
        });
      }



    }
    this.setState({ errors: errors });
  }



  componentDidMount() {
    var cpid = this.props.location.state;
    this.setState({ serialNumber: cpid }, ()=>{
      this.getCPInventory(cpid)
    });
  }

  async getCPInventory(cpid) {
    var url = baseUrl.URLPath + servicesUrl.getCpInventory + "?cpid=" + cpid;

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({
        fields: response,
      })

      if (this.state.fields.manufacturingDate !== null || this.state.fields.manufacturingDate !== "") {
        this.setState({
          saveMfgDate: new Date(this.state.fields.manufacturingDate),
        })
      }
      if (this.state.fields.manufacturingDate === null || this.state.fields.manufacturingDate === "") {
        this.setState({
          saveMfgDate: "",
        })
      }
    }
  }





  render() {
    const { t } = this.props



    return (
      <>
        <main class="content-div" >
          <p>{t('Inventory')}
            <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <Link to="/inventoryList"> <span className="">{t('Inventory')} &gt; </span></Link> <span className="breadcrumb_page">{t('edit')}</span>
            </div>
          </p>
          {/* <Header page_title={this.state.page_title}/> */}
          <div className="page-outerdiv">
            {/* <div className="breadcrumb_div">Maintenance ><Link to="/inventoryList"> <span className="">Inventory > </span></Link>  Edit Inventory</div> */}
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button className="btn_primary" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> {t('back')}</button>
                </div>
              </div>
            </div>
            <MDBRow className="mb-4">
              <MDBCol sm="12">
                <div>
                  <form
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>

                      <div className="col-md-12 mb-3">

                        <span class=" form-title">{t('inventory_details')}</span>

                      </div>



                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.fields["serialNumber"]}
                          onChange={this.changeHandler.bind(this, "serialNumber")}
                          type="text"
                          id="serialNumber"
                          name="serialNumber"
                          label={t('charge_point_id_label') + " *"}
                          disabled="true"

                        >
                          <div className="invalid-feedback">
                            {t('provide_valid_email')}
                          </div>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["serialnoEmpty"]}</span></span>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["min"]}</span></span>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["max"]}</span></span>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["chargePointExists"]}</span></span>


                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="mt-1">

                        <span className="cal_label">{t('mf_date')}</span>
                        <div className="pull-left mt-2" style={{ width: "70%" }} >
                          <DatePicker


                            selected={this.state.saveMfgDate}
                            onChange={this.setStartSchedule}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            className="cal_margin tag_cal"
                            timeCaption="time"
                            dateFormat="dd-MMM-yyyy HH:mm"
                          />
                        </div>
                        <span className="error_msg w3-animate-top cal_errormsg"> <span style={{ color: "red" }} >{this.state.errors["emptyscheduleDate"]}</span></span>
                      </MDBCol>

                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.fields["modelName"]}
                          onChange={this.changeHandler.bind(this, "modelName")}
                          type="text"
                          id="modelName"
                          name="modelName"
                          label={t('model')}

                        >
                          <div className="invalid-feedback">
                            {t('provide_valid_email')}
                          </div>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["modelNameEmpty"]}</span></span>

                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="mt-3" >
                        <MDBInput
                          value={this.state.fields["partNumber"]}
                          onChange={this.changeHandler.bind(this, "partNumber")}
                          type="text"
                          id="partNumber"
                          name="partNumber"
                          label={t('part_no')}

                        >
                          <div className="invalid-feedback">
                            {t('provide_valid_email')}
                          </div>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["partNoEmpty"]}</span></span>

                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="mt-3" >
                        <MDBInput
                          value={this.state.fields["mesSerialNumber"]}
                          onChange={this.changeHandler.bind(this, "mesSerialNumber")}
                          type="text"
                          id="mesSerialNumber"
                          name="mesSerialNumber"
                          label={t('mes_serial') + " *"}
                          disabled="true"

                        >
                          <div className="invalid-feedback">
                            {t('provide_valid_email')}
                          </div>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["mesNoEmpty"]}</span></span>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["mesExists"]}</span></span>

                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="mt-3" >
                        <MDBInput
                          value={this.state.fields["fwVersion"]}
                          onChange={this.changeHandler.bind(this, "fwVersion")}
                          type="text"
                          id="fwVersion"
                          name="fwVersion"
                          label={t('fw_version')}

                        >
                          <div className="invalid-feedback">
                            {t('provide_valid_email')}
                          </div>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["fwEmpty"]}</span></span>

                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="mt-3">
                        <MDBInput
                          value={this.state.fields["ocppPassword"]}
                          onChange={this.changeHandler.bind(this, "ocppPassword")}
                          type="text"
                          id="ocppPassword"
                          name="ocppPassword"
                          label={t('ocpp_password')}

                        >
                          <div className="invalid-feedback">
                            {t('provide_valid_email')}
                          </div>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["passwordEmpty"]}</span></span>

                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="mt-3">
                        <MDBInput
                          value={this.state.fields["vendorCode"]}
                          onChange={this.changeHandler.bind(this, "vendorCode")}
                          type="text"
                          id="vendorCode"
                          name="vendorCode"
                          label={t('vendor_code')}

                        >
                          <div className="invalid-feedback">
                            {t('provide_valid_email')}
                          </div>


                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="mt-3">
                        <MDBInput
                          value={this.state.fields["accessCode"]}
                          onChange={this.changeHandler.bind(this, "accessCode")}
                          type="text"
                          id="accessCode"
                          name="accessCode"
                          label={t('access_code')}

                        >
                          <div className="invalid-feedback">
                            {t('provide_valid_email')}
                          </div>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["accessCodeEmpty"]}</span></span>

                          {/* <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["accessCodeMatch"]}</span></span>  */}

                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="mt-3">
                        <MDBInput
                          value={this.state.fields["chargePointName"]}
                          onChange={this.changeHandler.bind(this, "chargePointName")}
                          type="text"
                          id="chargePointName"
                          name="chargePointName"
                          label={t('charge_point_name')}

                        >
                          <div className="invalid-feedback">
                            {t('provide_valid_email')}
                          </div>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["accessCodeEmpty"]}</span></span>

                          {/* <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["accessCodeMatch"]}</span></span>  */}

                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="mt-3">
                        <MDBInput
                          value={this.state.fields["masterPassword"]}
                          onChange={this.changeHandler.bind(this, "masterPassword")}
                          type="text"
                          id="masterPassword"
                          name="masterPassword"
                          label={t('master_password')}

                        >
                          <div className="invalid-feedback">
                            {t('provide_valid_email')}
                          </div>


                        </MDBInput>
                      </MDBCol>

                    </MDBRow>

                    <MDBCol md="12" className="text-right">

                      <button type="button" className="btn_primary mt-5" onClick={this.confirmtoggle1} disabled={this.state.disabledAttr}>{t('save')} <i class="fas fa-save ml-2"></i></button>
                      {/* <MDBBtn color="primary" className="mt-5"disabled={this.state.disabledAttr} onClick={this.confirmtoggle1}>Save</MDBBtn> */}


                    </MDBCol>
                  </form>
                </div>
              </MDBCol>
            </MDBRow>
          </div>

        </main>

        <MDBModal isOpen={this.state.confirmmodal1} toggle={this.confirmtoggle1} size="md" className="model_top">
          <MDBModalHeader toggle={this.confirmtoggle1}>{t('confirmation')}</MDBModalHeader>
          <MDBModalBody>
            {t('sure_you_want_to_save_changes')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.confirmtoggle1}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.handleSubmit}>{t('yes')}</button>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('inventory_updated_sucessfully')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        {/* popup for session expire */}

        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
            {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
            {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
            <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(editInventory);