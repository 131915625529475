import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";

export default class ConfirmationPopup extends React.Component {
    render() {
        let {title, btnImage, content, btnText, open, onClickOperation, btnBgColor, toggleOperation} = this.props
       
        return (
            <MDBModal isOpen={open} data-toggle="modal" backdrop="static" size={(this.props.size!=="")? this.props.size : "md"} toggle={toggleOperation}className={(this.props.ModalClass!=="" && this.props.ModalClass!==undefined && this.props.ModalClass!==null)? this.props.ModalClass:"model_top"}>
                <MDBModalBody>
                <div className="confirmationpopupheader">
                        <img src={process.env.PUBLIC_URL + "/assets/img/svg/close.svg"} className='pull-right closeIcon' onClick={toggleOperation} />
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center p-3'>
                        <h5 className='gv-deactivate-h5'>{title? title :""}</h5>
                        <p className='gv-deactivate-p mt-2'>{content}</p>
                        <button
                        className="gv-deactivate-btn"
                        type="button"
                        style={{ backgroundColor: btnBgColor ? btnBgColor : '' }}
                        onClick={onClickOperation}
                        >
                        {btnImage ? <img src={btnImage} height={22} width={22} alt='' className='mr-2'/> : ''}
                        {btnText}
                        </button>
                    </div>
                </MDBModalBody>
                
            </MDBModal>
        );
    }
}