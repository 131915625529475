import React from 'react';
import { Link } from 'react-router-dom';
import * as baseUrl from '../views/constants';
import ProgressBar from 'react-bootstrap/ProgressBar';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import InvalidQRCode from './InvalidQRCode';
import { MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";

// import { getQueryVariable } from '/common/commonFunction';

class chargingProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            remainingEnergyCreditkWh: "",
            duration: "",
            energy: "",
            transactionId: "",
            IntervalId: "",
            isEnergyLimitReached: false,
            message: "",
            totalEnergy: "",
            consumedEnergy:"",
            isChargingSessionCompleted : false,
            transactionIdErr: false,
            paymentTransactionId: ""
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.backButton = this.backButton.bind(this);
        this.openTransactionSummary = this.openTransactionSummary.bind(this);
        this.closeErrPopup = this.closeErrPopup.bind(this);
        this.getChargerSessionDetails = this.getChargerSessionDetails.bind(this);
    }

    closeErrPopup() {
        this.setState({ transactionIdErr: false })
    }
    openTransactionSummary(){
        this.props.setPage('transactionSummary');
        localStorage.setItem('page', 'transactionSummary');
        
        // this.props.setPage('selectCharger');
        // localStorage.setItem('page', 'selectCharger');
    }
    getMessageType() {
        return baseUrl.REMOTE_STOP_TRANSACTION;
    }

    getPayLoad() {
        var payload = {
            transactionId: parseInt(this.state.transactionId)
        };
        return payload;
    }

    async handleSubmit() {
        this.getChargerSessionDetails();
        if(this.state.transactionId === null || this.state.transactionId === 0 || this.state.transactionId === "" || this.state.transactionId === undefined) {
            this.setState({ transactionIdErr: true })
        } else {
            var cp = localStorage.getItem('cp')
            var id = localStorage.getItem('qrId');
            let type = this.getMessageType();
            var data = {
                "type": type,
                "payload": this.getPayLoad(),
                "cplist": [cp]
            };
            var timeZone = localStorage.getItem("timezone");
            let url = baseUrl.LoginPath + "/qrcode/charging/cs/operations/" + type + "?qrId=" + id;
    
            await fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "content-type": "application/json",
                    "Authorization": localStorage.getItem("Authorization"),
                    "timeZone": timeZone
                }
            }).then(resp => {
                if (resp.status === 401 || resp.status === 404 || resp.status === 500 || resp.status === 400) {
                    this.props.setPage('QRCodeDeactivated');
                    localStorage.setItem('page', 'QRCodeDeactivated');
                    localStorage.setItem('prePage', 'chargingprocess');
                    clearInterval(this.state.IntervalId);
                } else if (resp.status === 200) {
                    return resp.json();
                }
            }).then(response => {
                if (response && response[0] === "3") {
                    console.log("Request is processed Successfully");
                    // localStorage.setItem('page', 'StopChargeConfirmation');
                    // this.props.setPage('StopChargeConfirmation');
                    this.setState({isChargingSessionCompleted : true})
                    clearInterval(this.state.IntervalId);
                }
            }
            ).catch(error => {
                console.log(error)
            });
        }
    }


    backButton() {
        this.props.setPage('selectCharger');
        localStorage.setItem('page', 'selectCharger');
        clearInterval(this.state.IntervalId);
    }

    refreshEveryFifteenMins(Apiurl) {
        fetch(Apiurl, {
            method: "GET",
            headers: {
                "content-type": "application/json",
            }
        })
            .then((resp) => {
                if (resp.status === 401 || resp.status === 500 || resp.status === 400) {
                    localStorage.setItem('page', 'QRCodeDeactivated');
                    this.props.setPage('QRCodeDeactivated');
                    localStorage.setItem('prePage', 'selectCharger');
                }
                return resp.json();
            })
            .then((response) => {
                if (response.status === 404) {
                    if (response.message === "qr_code_expired" || response.message === "qr_code_status" || response.message === "reached_energy_limit" || response.message === "group_not_exists") {
                        this.setState({
                            message: response.message,
                            isEnergyLimitReached: false
                        })
                    }
                } else {
                    let time = "";
                    let arrayTime = "";
                    let sec = "";
                    if (response.chargingDuration) {
                        time = response.chargingDuration;
                        arrayTime = time.split(":");
                        sec = this.toSeconds(arrayTime[0], arrayTime[1], arrayTime[2], arrayTime[2]);
                    }
                    this.setState({
                        isEnergyLimitReached: true,
                        remainingEnergyCreditkWh: response.remainingEnergyCreditkWh,
                        chargedEnergy: response.chargedEnergy,
                        duration: sec ? this.secondsToHms(sec) : "",
                        transactionId: response.transactionId,
                        totalEnergy: response.totalEnergy,
                        consumedEnergy:response.consumedEnergy,
                        paymentTransactionId: response.paymentTransactionId
                    }, () => localStorage.setItem('transactionId', response.transactionId))
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }
    async getChargerSessionDetails() {
        var timeZone = localStorage.getItem("timezone");
        var cpId = localStorage.getItem('cp');        
        var id = localStorage.getItem('qrId');        
        var rfid = localStorage.getItem('rfid');      
        var paymentTransactionId = localStorage.getItem('transid');

        // let url = baseUrl.LoginPath + "/qrcode/charging/session/details?qrId=" + id + "&cpId=" + cp + "&rfId=" + rfid + "&time_zone=" + timeZone;
        let url = baseUrl.LoginPath + "/qrcode/charging/session/details?qrId=" + id + "&cpId=" + cpId + "&rfId=" + rfid + "&paymentTransactionId=" + paymentTransactionId;
        await fetch(url, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then(resp => {
            if (resp.status === 401 || resp.status === 404 || resp.status === 500 || resp.status === 400) {
                localStorage.setItem('page', 'QRCodeDeactivated');
                this.props.setPage('QRCodeDeactivated');
                localStorage.setItem('prePage', 'selectCharger');
            } else if (resp.status === 200) {
                return resp.json();
            }
        }).then(response => {           
            this.setState({ transactionId: response.transactionId })
        }
        ).catch(error => {
            console.log(error)
        });

    }
    componentDidMount() {
        let id = localStorage.getItem('qrId');
        let cpId = localStorage.getItem('cp');
        let rfid = localStorage.getItem('rfid');
        let pageName = localStorage.getItem('page');
        let paymentTransactionId = localStorage.getItem("transid");
        if (pageName === "chargingprocess") {
            var Apiurl = baseUrl.LoginPath + "/qrcode/charging/session/details?qrId=" + id + "&cpId=" + cpId + "&rfId=" + rfid + "&paymentTransactionId=" + paymentTransactionId;
            this.refreshEveryFifteenMins(Apiurl)
            const IntervalIdV = setInterval(() => {
                this.refreshEveryFifteenMins(Apiurl)
            }, 300000)
            this.setState({
                IntervalId: IntervalIdV
            })
        }
        // 120000 900000
    }

    toSeconds(days, hours, minutes, seconds) {
        return parseInt(days) * 86400 + parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    }

    secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var hDisplay = h > 0 ? h + (h === 1 ? " hr " : " hr ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " min " : " min ") : "";
        return hDisplay + mDisplay;
    }

    render() {
        const { t } = this.props;
        let energyLimit = localStorage.getItem('unlimitedEnergyAccess');
        let DaysAccess  =  localStorage.getItem('unlimitedDaysAccess');
        let expDate  =  localStorage.getItem('expDate');
        let modelType = localStorage.getItem('modelType');
      
        const consumedEnergy = this.state.consumedEnergy ? parseFloat(this.state.consumedEnergy) : 0;
        const usedEnergyValue = ( ( this.state.consumedEnergy ? parseFloat(this.state.consumedEnergy) : 0 )  / (this.state.totalEnergy ? parseFloat(this.state.totalEnergy) : 0 )) * 100;
        let date = expDate ? moment(expDate).format("DD MMM YYYY").split(" "):"";
            date = date[1] ? expDate ? moment(expDate).format("DD MMM YYYY").replace(date[1] , t(date[1])) : "" :"";
     
        if (this.state.isEnergyLimitReached) {
            return (
                <div className="welcome-parent">
                    <nav className="nav_bar activepage_bg">
                        <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/white-arrow.svg"} className="img-fluid rounded-start" onClick={this.backButton.bind(this)} alt="leftarrow" />
                        <h5 className='card-title' style={{ color: '#fff', fontSize: '19px', marginTop: '9px' }} >{t('active_charging_session')}</h5>
                        <span className="navbar-brand">
                            <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/refresh.svg"} className="img-fluid rounded-start" alt="refresh-img" style={{ display: 'none' }} />
                        </span>
                    </nav>
                    <div className="container-fluid" style={{ minHeight: "100vh" }}>
                        <div className='row justify-content-center'>
                            <div className='col-md-12 welcome-div'>
                                <div className="d-flex flex-column" style={{ width: 'auto' }}>
                                    <div className="card_mdiv">
                                        <div className="d-flex flex-row justify-content-between active-charging-process mt-3 mb-1">
                                            <div className="card mr-3">
                                                <div className='card-body text-center pt-3 pb-3'>
                                                    <div className='fs_12 ralign'>
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/time.svg"} className="img-fluid rounded-start pb-2" alt="refresh-img" />
                                                        <span style={{ fontSize: '0.95rem', fontWeight: 'bold', color: '#FFF', paddingBottom: "5px" }}>{this.state.duration ? this.state.duration : "0 hr 0 min"} </span>
                                                        {t('Duration')}</div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className='card-body text-center pt-3 pb-3'>
                                                    <div className='fs_12 ralign'>
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/battery.svg"} className="img-fluid rounded-start pb-2 pt-1" alt="refresh-img" />
                                                        <span style={{ fontSize: '0.95rem', fontWeight: 'bold', color: '#FFF', paddingBottom: "5px" }}>{this.state.chargedEnergy >= 0 ? parseFloat(this.state.chargedEnergy).toFixed(2) : 0} kWh</span>
                                                        {t('Energy')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {DaysAccess === "true" && energyLimit === "true"?
                                    <div className='card-container enegybox_unlimited'>
                                                <div className='card-text text-center'>
                                                    <p className="fs_25_bold">
                                                        {t("Unlimited")}
                                                        <br />
                                                        {t("voucher")}
                                                    </p>
                                                </div>
                                            </div> :
                                    <div className="card-container enegybox">
                                        <div className='card-text text-center'>
                                            <span className="welcome-txt-bold">
                                                {energyLimit === "true" ? <> {t('Unlimited')}  <span className='unit-f'> kWh </span></> 
                                                : <>
                                                {consumedEnergy ? parseFloat(consumedEnergy)?.toFixed(2):0}
                                                <span className='unit-f'> kWh / </span>

                                                {this.state.totalEnergy ? parseFloat(this.state.totalEnergy)?.toFixed(2) : 0}
                                                <span className='unit-f'> kWh</span>
                                                </> }
                                            </span>
                                        </div>

                                        <div className='card-text text-center'>
                                            <ProgressBar now={energyLimit === "true" ? "50" : isNaN(usedEnergyValue) ? "100" : usedEnergyValue} style={{ width: "86%" }} />
                                            <span className="percenttext">
                                                {energyLimit === "true" ? <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/unlimited.svg"} /> : usedEnergyValue ?  `${parseInt(usedEnergyValue)} %` : "0 %"}
                                            </span>
                                        </div>

                                        <div className='card-text text-center pb-0'>
                                            <p style={{ marginBottom: '0px' }}>
                                                <span className='grey-label'>
                                                    {DaysAccess === "true" ? t("unlimited_usage_time") : 
                                                            expDate && expDate!=="null" ?  t('available_until') + " " + date : ""
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                         }

                                    <div className="card card_mdiv infoicon-blue-border mt-3">
                                        <div className="d-flex flex-row justify-content-between card-body braduis bg_white" style={{ padding: '0px' }}>
                                            <div className="info-icon">
                                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/info_icon.svg"}
                                                    alt="info-icon" />
                                            </div>
                                            <div className="fs_12 grey-label" style={{ padding: '15px 18px' }}>{t('updated_approximately_every_15_minutes')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='row justify-content-center'>
                                <button type="button" className='btn qr-btn' onClick={this.handleSubmit.bind(this)}>{t('stop_charging')}</button>
                            </div>
                        </div>
                    </div>

                    <MDBModal isOpen={this.state.isChargingSessionCompleted} size="md" className="model-top">
                        <MDBModalBody className='text-center'>
                        <img
                            src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/successfully.svg"}
                            width="72"
                            height="72"
                            alt="" />
                        
                        <p className='esim-popup-para'>
                        Your charging session has been completed successfully.
                        </p>
                        <button className='btn qr-btn' onClick={this.openTransactionSummary} style={{ margin: "1rem 0" }}>
                            Close
                        </button>
                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.transactionIdErr} size="md" className="model-top">
                        <MDBModalBody className='text-center'>
                        <img
                            src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/error_icon.svg"}
                            width="72"
                            height="72"
                            alt="" />
                        
                        <p className='esim-popup-para'>
                        Transaction has not created, please wait for some time to stop charging.
                        </p>
                        <button className='btn qr-btn' onClick={this.closeErrPopup} style={{ margin: "1rem 0" }}>
                            Close
                        </button>
                        </MDBModalBody>
                    </MDBModal>

                </div>
            )
        }
        else {
            return (
                <InvalidQRCode message={this.state.message}></InvalidQRCode>
            )
        }
    }

}

export default withTranslation()(chargingProcess);
