import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Loader from '../views/loader';
//API imports 
import * as baseUrl from './constants'

import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders } from '../common/HttpRequestClass'

class statusnotificationlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idTagSorting: false,
      selectFindStatus: false,
      saveSelectedIdTag: "",
      idTags: "",
      selectDataArray: [],
      notificationType: [],
      models: [],
      intial_data: [],
      items: [],
      list: [],
      activeArray: [],
      model1: "false",
      model2: "false",
      model3: "false",
      page_title: "Charge Point OCPP Tags",
      fields: {
        modelChange: "",
        typeChange: ""
      },
      dataArray: [],

      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      pageNo: 1,
      activePage: 1,
      model: "",
      type: "",
      delete_msg: "",
      deleteHeader: "",
      deleteButton: "",
      deleteText: "",
      deletecolor: "danger",
      modalsession: false,
      user: {
        permissions: [

        ]
      },
      errors: {},
      loading: false,

    }
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.addNew = this.addNew.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggleactive = this.toggleactive.bind(this);
    this.toggledeactive = this.toggledeactive.bind(this);
    this.tagOk2 = this.tagOk2.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.tagOk3 = this.tagOk3.bind(this);
    this.activeChargePoint = this.activeChargePoint.bind(this);
    this.deactiveChargePoint = this.deactiveChargePoint.bind(this);
    this.modelChange = this.modelChange.bind(this);
    this.typeChange = this.typeChange.bind(this);
    this.gettypechangeFromDb = this.gettypechangeFromDb.bind(this);
    this.getConnectorsFromDb = this.getConnectorsFromDb.bind(this);
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    this.setState({
      activePage: 1,
      pageSize: entries
    }, () => {
      this.getConnectorsFromDb(this.state.pageSize, this.state.activePage);
    })

  }
  addNew() {
    this.props.history.push('/addstatusnotificationlist');
  };

  toggle1(event) {
    this.setState({
      modal1: !this.state.modal1
    });
    // event.preventDefault();
    if (this.state.list[0] !== "" && this.state.list[0] !== undefined) {


      this.setState({
        delete_msg: this.props.t('delete_confirmation'),
        deleteHeader: this.props.t('confirmation'),
        deleteButton: "",
        deleteText: this.props.t('Close'),
        deletecolor: "danger"
      })
    } else {

      this.setState({
        delete_msg: this.props.t('select_Push_Id'),
        deleteHeader: this.props.t('Warning'),
        deleteButton: "d-none",
        deleteText: this.props.t('ok'),
        deletecolor: "primary"
      })
    }

  }
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }
  toggleactive = () => {
    this.setState({
      modalactive: !this.state.modalactive
    });
  }
  toggledeactive = () => {
    this.setState({
      modaldeactive: !this.state.modaldeactive
    });
  }
  toggle3 = () => {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  tagOk3() {

    this.setState({
      modal3: !this.state.modal3
    });

  }
  tagOk2() {

    window.location.reload();
  }
  handlePageChange(pageNumber) {


    if (pageNumber !== this.state.activePage) {
      this.setState({ activePage: pageNumber }, () => {
        this.getConnectorsFromDb(this.state.pageSize, pageNumber);
      });
    }
  }
  componentDidMount() {
    let user = { permissions: [] };
    user.permissions = localStorage.getItem("roleAccess");

    this.setState({ user }, () => {
      this.getstatusnotificationlist(this.state.pageSize, this.state.pageNo, this.state.model, this.state.type);
      this.getModels();
      this.getNotificationType();
    });
  }

  async getstatusnotificationlist(pageSize, pageNo, model, type) {
    this.setState({ loading: true })
    let url = baseUrl.URLPath + "pushnotifications/search?pageNo=" + pageNo + "&pageSize=" + pageSize + "&model=" + model + "&type=" + type;
    let methods = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, methods, payload, headers, this.props.t);
    if (response !== undefined) {
      if(response.snList !== null) {
        this.setState({
          dataArray: response?.snList,
          noOfRecords: response?.count?.count,
          loading: false
        });
      } else {
        this.setState({
          dataArray: [],
          noOfRecords: 0,
          loading: false
        });
      }
    }
  }

  modelChange() {
    var id = document.getElementById("con_status").value;
    let fields = this.state.fields;
    fields["modelChange"] = id;
    this.setState({ modelChange: id, activePage: 1 }, () => {
      this.getConnectorsFromDb(10, 1, id);
    });
  }

  async gettypechangeFromDb(pageSize, pageNo, type) {
    this.setState({ loading: true })
    if (this.state.fields.modelChange !== "" && this.state.fields.modelChange !== null && this.state.fields.modelChange !== undefined) {
      var model = this.state.fields.modelChange;
    } else {
      var model = "";
    }
    var url = baseUrl.URLPath + "pushnotifications/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&type=" + type + "&model=" + model;
    let methods = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, methods, payload, headers, this.props.t);
    if (response.count !== undefined && response.count !== null) {
      this.setState({
        dataArray: response.snList,
        noOfRecords: response.count.count,
        items: response.snList,
        loading: false
      })
    }
    if (response.count === null) {
      this.setState({
        dataArray: [],
        noOfRecords: 0,
        items: [],
        loading: false
      })
    }
  }

  async getConnectorsFromDb(pageSize, pageNo, model) {
    this.setState({ loading: true })
    let type = "";
    if (this.state.fields.typeChange !== "" && this.state.fields.typeChange !== null && this.state.fields.typeChange !== undefined) {
      type = this.state.fields.typeChange;
    }
    var url = baseUrl.URLPath + "pushnotifications/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&model=" + this.state.fields.modelChange + "&type=" + type;
    let methods = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, methods, payload, headers, this.props.t);

    if (response.count !== undefined && response.count !== null) {
      this.setState({
        dataArray: response.snList,
        noOfRecords: response.count.count,
        items: response.snList,
        loading: false
      })
    }

    if (response.count === null) {
      this.setState({
        dataArray: [],
        noOfRecords: 0,
        items: [],
        loading: false
      })
    }
  }

  async activeChargePoint() {
    if (this.state.activeArray.length > 0) {
      let url = baseUrl.URLPath + "pushnotifications/bulkactivate";

      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      let payload = { "id": this.state.activeArray, "active": 1 };
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if (response.status === "SUCCESS") {
        this.toggleactive()
      }
    } else if (this.state.activeArray.length === 0) {
      this.toggle3()
    }
  }

  async deactiveChargePoint() {
    if (this.state.activeArray.length > 0) {
      var url = baseUrl.URLPath + "pushnotifications/bulkactivate";
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();
      let payload = { "id": this.state.activeArray, "active": 0 };
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if (response.status === "SUCCESS") {
        this.toggledeactive()
      }
    }else if (this.state.activeArray.length === 0) {
      this.toggle3()
    }
  }

  typeChange() {
    var id = document.getElementById("type_status").value;
    let fields = this.state.fields;
    fields["typeChange"] = id;
    this.setState({ typeChange: id, activePage: 1 }, () => {
      this.gettypechangeFromDb(10, 1, id);
    });
  }

  handleChange(id, cpid, event) {
    var checkedValue = document.getElementById(id).checked;

    if (checkedValue === true) {
      if (this.state.list[0] !== "" && this.state.list[0] !== undefined) {
        document.getElementById("custom-" + this.state.list[0]).checked = false;
        document.getElementById(id).checked = true;
      }

      let list = this.state.list;
      list[0] = cpid;
      this.setState({
        list,
        activeArray: this.state.list
      });
    }

    if (checkedValue === false) {
      let list = this.state.list;
      list[0] = "";
      this.setState({ list, activeArray: list });
      document.getElementById(id).checked = false;
    }
  }

  async handleDelete(event) {
    this.setState({ modal1: !this.state.modal1 });
    event.preventDefault();
    var url = baseUrl.URLPath + "pushnotifications/bulkactivate";
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();
    let payload = { "id": this.state.activeArray, "active": 2 };
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response.status === "SUCCESS") {
      this.toggle2()
    }
  }

  async getModels() {
    let url = baseUrl.URLPath + "cpinventory/evmodel/";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      this.setState({ models: response });
    }
  }

  async getNotificationType() {
    let url = baseUrl.URLPath + "pushnotifications/search/type";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      this.setState({ notificationType: response });
    }
  }


  render() {
    const { t } = this.props

    let models = this.state.models.map((model, index) => {
      return (
        <option value={model.trim()}>{model.trim()}</option>
      );
    })

    let notificationType = this.state.notificationType.map((type, index) => {
      return type !== null && <option value={type}>{t(type)}</option>
    })

    let products;
    if (this.state.dataArray !== undefined) {
      products =
        this.state.dataArray.map((dataArray, index) => {


          return (
            <tr key={index}>
              <td className="text-center">
                <Form.Check
                  custom
                  type="checkbox"
                  id={`custom-${dataArray.id}`}
                  onChange={this.handleChange.bind(this, `custom-${dataArray.id}`, dataArray.id)}
                  label=""
                />
              </td>


              <td className="">{dataArray.pushId} </td>
              <td className="">{dataArray.category}</td>
              <td className="">{dataArray.type}</td>
              <td className="">{dataArray.model}</td>
              <td className="">{dataArray.errorCode} </td>
              <td className="">{dataArray.info}</td>
              <td className="">{dataArray.description}</td>

              <td className="">
                {dataArray.status ? "Active" : "Deactive"}
              </td>
              <td className="">
                <Link to={{ pathname: "/editStatusNotification", pushId: dataArray.pushId, id: dataArray.id }}>
                  <i class="fas fa-pencil-alt pl-2 action-icon" title="Edit"></i>
                </Link>
              </td>

            </tr>
          )

        })
    }
    return (
      <>
        <main class="content-div" >
          <p> {t('Status Notification List')}
            <div className="breadcrumb_div">
              {t('Settings')} &gt; <span className="breadcrumb_page"> {t('Status Notification List')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            {/* <div className="breadcrumb_div"> Charge Point > OCPP Tags</div> */}

            {/* page title */}
            {/* <p> OCPP Tags</p> */}
            {/* end page title */}
            {/* page menu bar */}

            <div className="row menubar">
              <div className="col-md-12 ">
                <div className="">
                  <button type="button" data-test="button" onClick={this.addNew}>
                    <i className="fa fa-plus">
                    </i>
                    <span>{t('add')}</span>
                  </button>
                  <button onClick={this.activeChargePoint.bind(this, 1)}>
                    <i className="fas fa-toggle-on">
                    </i>
                    <span>{t('active')}</span>
                  </button>
                  <button onClick={this.deactiveChargePoint.bind(this, 1)}>
                    <i className="fas fa-toggle-off">
                    </i>
                    <span>{t('deactive')}</span>
                  </button>
                  <button type="button" data-test="button" onClick={this.toggle1}>
                    <i className="fas fa-trash">
                    </i>
                    <span>{t('delete')}</span>
                  </button>
                  {/* select dropdown for Table */}
                  <div className="pull-right">
                    <label className="sub-text pull-left">{t('show_entries')}</label>
                    <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                      <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>40</option>
                        <option>50</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>


            {/* end page menu bar  */}
            <div className="row">
              <div className="col-md-3  pull-right">

                <select className="browser-default custom-select select_height " id="con_status" onChange={this.modelChange}>
                  <option value="">--{t('select_model')}--</option>
                  <option value="All products">{t('all_products')}</option>
                  {models}

                </select>

              </div>

              <div className="col-md-3  pull-right">

                <select className="browser-default custom-select select_height " id="type_status" onChange={this.typeChange}>
                  <option value="">--{t('select_notification_type')}--</option>
                  {notificationType}

                </select>

              </div>

            </div>



            {/* table start */}
            <div className="row ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table" id="statusnotificationtable">
                  <thead>
                    <tr>
                      <th className="text-center"></th>
                      <th>{t('push_id')} </th>
                      <th className="">{t('category')}</th>
                      <th>{t('warning_error_info')} </th>
                      <th className="">{t('model')}</th>
                      <th className="">{t('errorcode_from_statusnotification')}</th>
                      <th className="">{t('info_from_statusnotification')}</th>
                      <th className="">{t('customerdescription_in_pushnotification')}</th>
                      <th className="">{t('chargingSessionState')}</th>
                      <th className="">{t('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products}
                  </tbody>
                </Table>
              </div>

              <div className="col-md-12 mt-2 mb-4 text-right">

                <Pagination
                  hideDisabled

                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>

              {/* End table  */}

            </div>
          </div>
          <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle1}> {this.state.deleteHeader}</MDBModalHeader>
            <MDBModalBody>
              {this.state.delete_msg}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" onClick={this.toggle1} className="btn_primary">{this.state.deleteText}</button>
              <button type="button" data-test="button" onClick={this.handleDelete} className={`btn_primary ${this.state.deleteButton}`}>{t('yes')}</button>
              {/* <MDBBtn color={this.state.deletecolor} onClick={this.toggle1}>{this.state.deleteText}</MDBBtn>
                  <MDBBtn color="primary" className={this.state.deleteButton} onClick={this.handleDelete}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>


          <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('status_successfully_deleted')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.toggle2}>Close</MDBBtn> */}
              <button type="button" data-test="button" onClick={this.tagOk2} className="btn_primary">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modalactive} toggle={this.toggleactive} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleactive}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('status_notification_activated')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.toggle2}>Close</MDBBtn> */}
              <button type="button" data-test="button" onClick={this.tagOk2} className="btn_primary">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modaldeactive} toggle={this.toggledeactive} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggledeactive}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('status_notification_dectivated')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.toggle2}>Close</MDBBtn> */}
              <button type="button" data-test="button" onClick={this.tagOk2} className="btn_primary">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modal3} toggle={this.toggle3} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle3}>{t('Warning')}</MDBModalHeader>
            <MDBModalBody>
              {t('select_Push_Id')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.toggle2}>Close</MDBBtn> */}
              <button type="button" data-test="button" onClick={this.tagOk3} className="btn_primary">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {this.state.loading ? (
            <Loader loader={this.state.loading} />
          ) : ("")}
        </main>
      </>
    );
  }
}

export default withTranslation()(statusnotificationlist);