import React from 'react';
import * as baseUrl from '../views/constants';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ProgressBar from 'react-bootstrap/ProgressBar';
class StopChargeConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false,
            showhistory: false,
            expirationDate: null,
            remainingEnergyCredit: "",
            totalEnergy:"",
            consumedEnergy: "",
            chargehistory: [
                {
                    // startDate: "2022-11-23T10:28:00.000+0000",
                    // chargeDuration: "100",
                    // chargedEnergy: "20.98"
                }
            ],
            historyData: false
        }
        this.showChargeHistory = this.showChargeHistory.bind(this)
        this.backButton = this.backButton.bind(this)
    }


    showChargeHistory(e) {
        this.setState({
            showhistory: !this.state.showhistory,
            expand:!this.state.expand
        })
    }

    componentDidMount() {
        var id = localStorage.getItem('qrId');
        var Apiurl = baseUrl.LoginPath+"/qrcode/charging/history?qrId=" + id;
        fetch(Apiurl, {
            method: "GET",
            headers: {
                "content-type": "application/json",
            }
        })
            .then((resp) => {
                if (resp.status === 401 || resp.status === 404 || resp.status === 500 || resp.status === 400 ) {
                    this.props.setPage('QRCodeDeactivated');
                    localStorage.setItem('page', 'QRCodeDeactivated');
                    localStorage.setItem('prePage', 'chargingprocess');
                }
                return resp.json();
            })
            .then((response) => {
                
                if (response !== undefined) {
                    if (response.message === "no_transaction_found_for_qr_code") {
                        this.setState({
                            historyData: true
                        })
                    } else {
                        const chHistory =  response.history?.map((ch) => {
                              var temp = Object.assign({}, ch);
                                if(temp.chargeDuration){
                                    let time = temp.chargeDuration;
                                    let arrayTime = time?.split(":");
                                    let sec =  this.toSeconds(arrayTime[0],arrayTime[1],arrayTime[2],arrayTime[2]);
                                    temp.chargeDuration =  this.secondsToHms(sec)
                                } 
                                return temp;
                           })
                            this.setState({
                                chargehistory: chHistory,
                                historyData: false,
                                expirationDate: response.expirationDate,
                                remainingEnergyCredit: response.remainingEnergy,
                                totalEnergy:response.totalEnergy,
                                consumedEnergy:response.consumedEnergy

                            })
                    }
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }

    backButton() {
        localStorage.setItem('page', 'selectCharger');
        this.props.setPage('selectCharger');
    }

    toSeconds(days , hours, minutes, seconds) {
         return parseInt(days) * 86400 + parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    }
    
    secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var hDisplay = h > 0 ? h + (h === 1 ? " hr " : " hr ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " min " : " min ") : "";
        return hDisplay + mDisplay ; 
    }

    render() {
        const{t}=this.props;
        let DaysAccess  =  localStorage.getItem('unlimitedDaysAccess');
        let energyLimit =  localStorage.getItem('unlimitedEnergyAccess');
        let expDate  =  localStorage.getItem('expDate');
        let modelType   = localStorage.getItem('modelType');
        const usedEnergyValue = ( (this.state.consumedEnergy? parseFloat(this.state.consumedEnergy):0) / (this.state.totalEnergy ? parseFloat(this.state.totalEnergy) :0 ) ) * 100;

        let date = this.state.expirationDate ? moment(this.state.expirationDate).format("DD MMM YYYY").split(" "):"";
        date = date[1] ? this.state.expirationDate ? moment(this.state.expirationDate).format("DD MMM YYYY").replace(date[1] , t(date[1])) : "" :"";

     
        return (
            <>
               <div className="welcome-parent">
                <nav className="nav_bar history_bg">
                        <span className="">
                            <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/close-white.svg"} className="img-fluid rounded-start" onClick={this.backButton.bind(this)} alt="leftarrow" />
                        </span>
                        <h5 className='card-title' style={{ color: '#fff', fontSize: '19px', marginTop: '9px' }} >{t('Session_completed')}</h5>
                        <span className="navbar-brand">
                            <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/refresh.svg"} className="img-fluid rounded-start" alt="refresh-img" style={{ display: 'none' }} />
                        </span>
                    </nav>
                  <div className="container-fluid">
                        <div className='row justify-content-center'>
                            <div className='col-md-12 welcome-div'>
                                <div className="success-msg-one text-center mt-5 mb-3">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/successfull.png"} className="img-fluid rounded-start mt-n5" alt="success-img" style={{ width: '54px' }} />
                                    <p className="mt-3"><strong>{t('charging_session_completed_successfully')}</strong></p>
                                </div>
                                {/* <div className="success-msg-two text-center">
                                    <p className="mb-0">{t('thank_you_for_charging_your_vehicle')}</p>
                                </div> */}
                                  {DaysAccess === "true" && energyLimit === "true"?
                                    <div className='card-container enegybox_unlimited'>
                                                <div className='card-text text-center'>
                                                    <p className="fs_25_bold">
                                                        {t("Unlimited")}
                                                        <br />
                                                        {t("voucher")}
                                                    </p>
                                                </div>
                                            </div> :
                                 <div className="card-container enegybox ">
                                        <div className='card-text text-center'> 
                                        <span className="welcome-txt-bold">
                                                {energyLimit === "true" ?  <> {t('Unlimited')}  <span className='unit-f'> kWh </span></> 
                                                : <>
                                                {this.state.consumedEnergy ? parseFloat(this.state.consumedEnergy)?.toFixed(2):0}
                                                <span className='unit-f'> kWh / </span>

                                                {this.state.totalEnergy ? parseFloat(this.state.totalEnergy)?.toFixed(2) : 0}
                                                <span className='unit-f'> kWh</span>
                                                </>}
                                            </span>
                                        </div>
                                        
                                        <div className='card-text text-center'>
                                        <ProgressBar now={energyLimit ==="true" ? "50":isNaN(usedEnergyValue) ? "100" : usedEnergyValue} style={{ width: "86%" }} />
                                            <span className="percenttext">
                                                {energyLimit ==="true" ? <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/unlimited.svg"} /> : usedEnergyValue ? `${parseInt(usedEnergyValue)} %` : "0 %"}
                                            </span>
                                        </div>
                                        
                                        <div className='card-text text-center pb-0'>
                                            <p style={{ marginBottom: '0px' }}>
                                                <span className='grey-label'>
                                                    {
                                                        DaysAccess === "true"
                                                            ? t("unlimited_usage_time") 
                                                            :this.state.expirationDate ?  t('available_until') + " " + date: ""
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                }
                                {/* <div className="full-width-card">
                                    <div className='card-body d-flex flex-column justify-content-center' style={{ background: '#EEF4FA', margin: '25px 0px' }}>
                                        <div className='welcome-txt-bold text-center'>{t('your_voucher')}</div>
                                        <div className='card-container' style={{ border: 'none', marginTop: '10px' }}>
                                            <div className='card-text text-center'>
                                                <p className="mb-1"><span className="small-grey-text" > {t('expiration_date')}</span></p>
                                                <p><span className="small-heading">{ DaysAccess === "true" ? "Unlimited" : this.state.expirationDate ? moment(this.state.expirationDate).format("DD.MMM.YYYY")  : " "}</span></p></div>
                                            <div className='card-text text-center'>
                                                <p className="mb-1"><span className="small-grey-text">{t('remaining_credit')}</span></p>
                                                <p><span className="small-heading">{ energyLimit === "true" ? "Unlimited": parseFloat(this.state.remainingEnergyCredit) ? parseFloat(this.state.remainingEnergyCredit)?.toFixed(1) + " kWh" : "" } </span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="charge-history " >
                                    <p className="text-center history-lbl">
                                        <span>{t('charging_history')}</span>
                                        {/* { this.state.expand ? <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/up-arrow.svg"} className="photo" alt="down-arrow"  /> : <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/down-arrow.svg"} className="photo" alt="down-arrow"  /> } */}
                                    </p>
                                    <div className='d-flex flex-column mb-3'>
                                        {this.state.chargehistory?.map((ch) => { 
                                               let date_h = ch.startDate ? moment(ch.startDate).format("DD MMM YYYY").split(" "):"";
                                               date_h = date_h[1] ? ch.startDate ? moment(ch.startDate).format("DD MMM YYYY").replace(date_h[1] , t(date_h[1])) : "" :"";
                                               return(
                                            <>
                                                <div className="card" style={{ background: '#EEF4FA' }}>
                                                    <div className="row g-0 box ml-0 mr-0" style={{ height: '80px' }}>
                                                        <div className="col-3 text-center" style={{ padding: '5px' }}>
                                                            {
                                                                (modelType?.includes('UNITE') || modelType==="UNITE" ) ? 
                                                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/Unite.svg"} className="img-fluid rounded-start chargeicon" alt="unite-img" />
                                                                : (modelType?.includes('TURBOCON') || modelType==="TURBOCON") ? 
                                                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/Turbo.svg"} className="img-fluid rounded-start chargeicon" alt="turbo-img" />
                                                                :
                                                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/Live.svg"} className="img-fluid rounded-start chargeicon" alt="next-live-img"  />
                                                                }
                                                        </div>
                                                        <div className='col-9'>
                                                            <div className='row'>
                                                                <div className="col-10 mb-2" >
                                                                    <div className="d-inline-flex " style={{ width: '175px', justifyContent:'space-between' }} >
                                                                        <div className="charger-details"><strong>{ch.chargedEnergy ? `${ch.chargedEnergy.toFixed(2)} kWh` : `0 kWh` }</strong></div>
                                                                        <div className="charger-details pr-1"><strong>{ch.chargeDuration}</strong></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-10">
                                                                    <div className="d-inline-flex " style={{ width: '175px' }}>
                                                                        <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/date-time.svg"} className="dateTime-icon" alt="date-time"  />
                                                                        <p className="charger-details" style={{ fontWeight: 'normal' }}> { date_h } <b className='pl-1 pr-1'>|</b></p>
                                                                        <p className="charger-details " style={{ fontWeight: 'normal' }}> {moment(ch.startDate).format("HH:mm")}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                               )
                                                                    })}
                                    </div>

                                    {/* <div className='d-flex flex-column mb-3'>
                                            <div className="card" style={{ background: '#EEF4FA' }}>
                                                <div className="row g-0 box ml-0 mr-0" style={{ height: '100px' }}>
                                                    <div className="col-3 box" style={{ padding: '10px' }}>
                                                        <img src={chargeHistoryIcon} className="img-fluid rounded-start chargeicon" alt="" />
                                                    </div>
                                                    <div className="col-5">
                                                        <div className="card-body">
                                                            <p className="charger-details"><strong>100 kWh</strong></p>
                                                            <p className="charger-details"><strong>240</strong></p>
                                                        </div>
                                                    </div>
                                                    <div className='col-4 box'>
                                                        <div className="card-body">
                                                            <p className="charger-details" style={{ fontWeight: 'normal' }}>26.04.2023</p>
                                                            <p className="charger-details" style={{ fontWeight: 'normal' }}>20:30</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }
}

export default withTranslation()(StopChargeConfirmation);
