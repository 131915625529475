import React from 'react';
import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBNav, MDBNavLink, MDBNavItem, MDBTabPane, MDBTabContent, MDBInput } from 'mdbreact';
import { Form, Table, Check } from 'react-bootstrap';
import * as baseUrl from './constants';
import * as Roles from './roles';
import { createBrowserHistory } from 'history'
import $ from 'jquery';
import Pagination from "react-js-pagination";
import { Line, Bar, Doughnut, Polar } from "react-chartjs-2";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Alert } from 'reactstrap';

const history = createBrowserHistory();

export default class cloudUserHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allUser: [],
      dataLine: '',
      userData: [],
      allUserValue: [],
      chargingHistory: [],
      userValue: [],
      yearmonthName: [],
      saveUserId: "",
      saveWallBoxNo: "",
      saveEmail: "",
      userStep: {
        min: 0,
        suggestedMax: 10
      },
      alluserStep: {
        min: 0,
        suggestedMax: 10
      },
      dataBar: [],
      modal: false,
      activeItem: "1",
      page_title: "Cloud User History",
      billingHistory: [],
      dataArray1: [],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      activeItem: "1",
      adminStatus: 1,
      pageSize1: 10,
      data1: new Map(),
      activePage1: 1,
      noOfRecords1: '',
      displayEditBtn: '',
      savedCardDetails: [],
      cardNumber: "",
      cvv: "",
      expiryDate: "",
      cardHolderName: "",
      fields: {
        cvv: "",
      },
      sucess: false,
      colour: '',
      message: '',
      modal1: false,
      errors: {},
      serialNo: "",
      dataArray: [],
      userInternalId: ""
    }
    this.tabToggle = this.tabToggle.bind(this);
    this.backToList = this.backToList.bind(this);
    this.toggle = this.toggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.showAdminChange = this.showAdminChange.bind(this);
    // user
    this.handlePageChangeUser = this.handlePageChangeUser.bind(this);
    this.getBillingHistory = this.getBillingHistory.bind(this);
    this.getBillingCount = this.getBillingCount.bind(this);
    this.UserhandleChange = this.UserhandleChange.bind(this);
    this.chargingHistory = this.chargingHistory.bind(this);
    this.getMonthList = this.getMonthList.bind(this);
    this.exportBillingHistoryCsv = this.exportBillingHistoryCsv.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.onChangeCardNo = this.onChangeCardNo.bind(this);
    this.onChangeExpiryDate = this.onChangeExpiryDate.bind(this);
    this.onChangeHolderName = this.onChangeHolderName.bind(this);
    this.submitSaveCards = this.submitSaveCards.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.getSavedCardDetails = this.getSavedCardDetails.bind(this);
  }
  getSavedCardDetails() {
    //var email = localStorage.getItem('saveLoginUser');
    var email = this.state.saveEmail;
    var url = baseUrl.APIPath + "users/cards/" + email;
    //var url = baseUrl.FOTAPath + "web/users/cards/sbe02491700705@webasto.com";
    //cordsetplusbapi.evsolutions.com/web/users/cards/sujatha@gmail.com     
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle()
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ savedCardDetails: response })
        }

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }
  submitSaveCards() {
    var errors = {};
    var cardNumber = this.state.cardNumber;
    var expiryDate = moment(this.state.expiryDate).format("YYYY-MM");
    var cardHolderName = this.state.cardHolderName;
    var cvv = this.state.fields.cvv;
    var expiryDateValid = new Date();
    expiryDateValid = moment(new Date()).format("YYYY-MM")

    if (cardNumber == "") {
      errors["cardNoEmpty"] = "Please enter the card number."
    }
    if (cardNumber != "" && cardNumber.length < 16) {
      errors["cardLengthEmpty"] = "card no should be 16 digits."
    }
    if (expiryDate == "") {
      errors["dateEmpty"] = "Please enter the expiry date."
    }
    if (expiryDate == "Invalid date") {
      errors["invalidDate"] = "Please enter the date in (YYYY-MM) format."
    }
    if (expiryDate <= expiryDateValid) {
      errors["validDate"] = "Expiry date should be greater than current date."
    }
    if (cardHolderName == "") {
      errors["nameEmpty"] = "Please enter the card holder name."
    }
    if (cardHolderName != "" && cardHolderName.length < 3) {
      errors["cardHolderLength"] = "Name sholud be minimum 3 letters."
    }

    let email = localStorage.getItem('saveLoginUser');
    if (cardNumber != "" && expiryDate != "" && expiryDate != "Invalid date" && cardHolderName != "" && expiryDate >= expiryDateValid && cardNumber.length >= 16 && cardHolderName.length >= 3) {
      var url = baseUrl.APIPath + "users/cards";
      //cordsetplusbapi.evsolutions.com/web/users/cards

      let data = { "cardNumber": this.state.cardNumber, "expiryDate": expiryDate, "cardHolderName": this.state.cardHolderName, "email": email };
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      }).then((resp) => {
        if (resp.status == 200) {
          this.setState({ modal1: false })
          this.setState({ message: "Card Details added sucessfully.", colour: 'success' })
          this.onShowAlert();
          this.getSavedCardDetails();
          this.state.cardHolderName = "";
          this.state.fields.cvv = "";
          this.state.expiryDate = "";
          this.state.cardNumber = "";
          errors["validDate"] = "";
        }
        else if (resp.status == 400) {
          this.setState({ modal1: false })
          this.setState({ message: "please add valid card details.", colour: 'danger' })
          this.onShowAlert();
        }
        else if (resp.status == 401) {
          this.toggle()
          localStorage.clear();
        } else if (resp.status == 409) {
          errors["cardexists"] = "Card is already exists."
          this.setState({ errors: errors })
          return resp.json();
        } else {
          return resp.json();
        }
      }).catch(error => alert('Error:' + error));
    }
    this.setState({ errors: errors });
  }
  onChangeHolderName(e) {
    var val = e.target.value;
    this.setState({
      cardHolderName: val
    });
  }
  onChangeExpiryDate(e) {
    var val = e.target.value;
    if (e.target.validity.valid) {
      var enteredtext1 = e.target.value;
      var enteredtext2 = "";
      var enteredtext = enteredtext1.replace(/-/g, '');
      for (var i = 0; i < enteredtext.length; i++) {
        if (i != 0 && i % 4 == 0) {
          enteredtext2 = enteredtext2 + "-";
        }
        enteredtext2 = enteredtext2 + enteredtext[i];
      }
      this.setState({ expiryDate: enteredtext2 });
    }
  }
  onChangeCardNo(e) {
    var val = e.target.value;
    if (e.target.validity.valid) {
      var text1 = e.target.value;
      var text2 = "";
      var text = text1.replace(/ /g, '');
      for (var i = 0; i < text.length; i++) {
        if (i != 0 && i % 4 == 0) {
          text2 = text2 + " ";
        }
        text2 = text2 + text[i];
      }
      this.setState({ cardNumber: text2 });
    }
  }
  toggleAdd() {
    this.setState({
      modal1: !this.state.modal1,

    });
    var errors = {};
    this.state.cardHolderName = "";
    this.state.fields.cvv = "";
    this.state.expiryDate = "";
    this.state.cardNumber = "";
    errors["validDate"] = ""
    this.setState({ errors: errors })
  }
  // tab view click
  tabToggle(tab, event) {
    this.setState({
      currentTab: tab
    });
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }

    if (tab == 2) {
      this.UserhandleChange();
      // this.getUserAcount()
      this.chargingHistory();
    }
    if (tab == 3) {
      this.chargingHistory();
    }
    if (tab == 1) {
      this.getBillingHistory(this.state.activePage, this.state.pageSize);

    }
    if (tab == 4) {
      this.getSavedCardDetails();
    }

  };
  handlePageChangeUser(pageNumber) {
    if (pageNumber != this.state.activePage1) {
      var data1 = this.state.data1;
      var pageData = data1.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage1: pageNumber });
      } else {
        this.setState({ activePage1: pageNumber });
        this.setState({ dataArray1: pageData })
      }
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.getBillingHistory(pageNumber, this.state.pageSize);

      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ dataArray: pageData })
      }
    }
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.state.data1.clear();
    this.setState({ pageSize1: entries });
    this.getUser(1, entries, this.state.userStatus);

  }
  showAdminChange() {
    var entries = document.getElementById("showAdminEntries").value;
    this.state.data.clear();
    this.setState({ pageSize: entries });
    this.getBillingHistory(1, entries);
  }
  showAdminChange3() {
    var entries = document.getElementById("showAdminEntries3").value;
    this.state.data.clear();
    this.setState({ pageSize: entries });
    this.getBillingHistory(1, entries);
  }

  backToList() {
    this.props.history.push('/cloudWallboxList');
    localStorage.setItem('isCloudUsersPage', false)
  }

  tagOk() {
    history.push('/');
    window.location.reload();
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  componentDidMount() {
    let cpview = JSON.parse(localStorage.getItem("cpview"));
    this.state.saveWallBoxNo = cpview.chargePointId;
    this.state.saveEmail = localStorage.getItem("selectedUserEmail");
    this.state.userInternalId = localStorage.getItem("selectedUserId");
    this.setState({
      saveEmail: localStorage.getItem("selectedUserEmail"),
      saveWallBoxNo: cpview.chargePointId,
      userInternalId: localStorage.getItem("selectedUserId")
    });
    this.getBillingCount();
    this.getMonthList();
    this.chargingHistory();
    this.UserhandleChange();
  }

  getBillingCount() {
    if (localStorage.getItem("role") == Roles.WEBASTO_CLOUD_USER) {
      var url = baseUrl.WebPath + "billings/" + localStorage.getItem("userId") + "/count";
    } else {
      var url = baseUrl.WebPath + "billings/" + this.state.userInternalId + "/count";
    }

    //cordsetplusbapi.evsolutions.com/web/billings/bbd712bb-30c2-4b8f-b967-647eab1c8be2/count

    fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ noOfRecords: response.count });
          console.log(this.state.noOfRecords);

        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
    this.getBillingHistory(this.state.activePage, this.state.pageSize);

  }

  // to get the billing history details
  getBillingHistory(pageNo, pageSize) {
    if (localStorage.getItem("role") == Roles.WEBASTO_CLOUD_USER) {
      var url = baseUrl.WebPath + "billings/" + localStorage.getItem("userId") + "?pageNo=" + parseInt(pageNo - 1) + "&pageSize=" + pageSize + "&serialNumber=" + this.state.saveWallBoxNo;
    } else {
      var url = baseUrl.WebPath + "billings/" + this.state.userInternalId + "?pageNo=" + parseInt(pageNo - 1) + "&pageSize=" + pageSize + "&serialNumber=" + this.state.saveWallBoxNo;
    }

    //cordsetplusbapi.evsolutions.com/web/billings/bbd712bb-30c2-4b8f-b967-647eab1c8be2?pageNo=1&pageSize=10
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle()
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ billingHistory: response.billings })
        }

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }


  UserhandleChange(e) {
    var user_month = [];
    var user_value = [];
    if (localStorage.getItem("role") == Roles.WEBASTO_END_USER_RESIDENTIAL) {
      var url = baseUrl.WebPath + "statistics/users/" + localStorage.getItem("userId") + "?serialNo=" + this.state.saveWallBoxNo;
    } else {
      var url = baseUrl.WebPath + "statistics/users/" + this.state.userInternalId + "?serialNo=" + this.state.saveWallBoxNo;
    }

    //cordsetplusbapi.evsolutions.com/web/statistics/users/1b068375-9a1c-4668-a4a2-228e01c33f64
    var header = { 'content-type': 'application/json', "Authorization": localStorage.getItem("Authorization") }

    fetch(url, {
      method: "GET",
      headers: header
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }

      })
      .then((response) => {
        if (response != undefined) {
          console.log(response);

          for (var i = 0; i < response.length; i++) {
            user_month.push(response[i]["month"])
            user_value.push(response[i]["value"])

          }

          // this.setState({ 
          //   userData:user_month,
          //   userValue:user_value
          // })
          this.state.userValue = user_value;
          var max_val = Math.max(...user_value);

          if (max_val < 10) {
            this.state.userStep = {
              min: 0,
              suggestedMax: 10,

            }
          } else {
            this.state.userStep = {
              min: 0,
              suggestedMax: max_val,
            }
          }

          this.state.dataBar = {
            labels: this.state.yearmonthName,
            datasets: [
              {
                label: "kWh of Charged energy usage",
                data: user_value.reverse(),
                backgroundColor: "rgba(255, 134,159,0.4)",
                borderWidth: 2,
                borderColor: "rgba(255, 134, 159, 1)"
              }
            ]
          }
          this.setState({ usersData: response })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  chargingHistory() {
    
    if (localStorage.getItem("role") == Roles.WEBASTO_END_USER_RESIDENTIAL) {
      var url = baseUrl.WebPath + "statistics/users/" + localStorage.getItem("userId") + "?serialNo=" + this.state.saveWallBoxNo;
    } else {
      var url = baseUrl.WebPath + "statistics/users/" + this.state.userInternalId + "?serialNo=" + this.state.saveWallBoxNo;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle()
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ chargingHistory: response })
        }

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
      
  }
  getMonthList() {
    var monthName = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec");
    var d = new Date();
    d.setDate(1);
    var i = 0;
    for (i = 0; i <= 11; i++) {
      this.state.yearmonthName.push(monthName[d.getMonth()] + ' ' + d.getFullYear().toString().substr(-2));
      d.setMonth(d.getMonth() - 1);
    }

    this.state.yearmonthName = this.state.yearmonthName.reverse();
    // this.state.userValue.reverse();

    this.state.dataBar = {
      labels: this.state.yearmonthName,
      datasets: [
        {
          label: "kWh of Charged energy usage",
          data: this.state.userValue.reverse(),
          backgroundColor: "rgba(255, 134,159,0.4)",
          borderWidth: 2,
          borderColor: "rgba(255, 134, 159, 1)",

        }
      ]
    }

    this.state.dataLine = {
      labels: this.state.yearmonthName,
      datasets: [
        {
          label: "EVSE accumulated charged energy",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "rgb(205, 130, 158)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(205, 130,1 58)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.state.allUserValue.reverse()
        }
      ]
    }
  }
  exportBillingHistoryCsv() {
    if (localStorage.getItem("role") == Roles.WEBASTO_CLOUD_USER) {
      var url = baseUrl.WebPath + "billings/export/" + localStorage.getItem("userId");
    } else {
      var url = baseUrl.WebPath + "billings/export/" + this.state.userInternalId;
    }

    //cordsetplusbapi.evsolutions.com/web/billings/export/f1a85d44-6dd5-4194-9041-e59f5912f6cd
    var header = { 'content-type': 'application/json', "Authorization": localStorage.getItem("Authorization") }
    fetch(url, {
      method: "GET",
      headers: header
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.blob();
        }
      })
      .then((response) => {
        if (response != undefined) {
          let d = new Date();
          let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'Billing_History_' + dformat + '.csv';
          a.click();
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

  }
  componentWillUnmount() {
    localStorage.setItem("isFromWallboxList", false)
  }
  render() {
    const getBillingDetails = this.state.billingHistory.map((billingHistory, index) => {
      let chargedKwh = 0;
      let rate = 0;
      let billAmount = 0;
      let paidAmount = 0;
      let refundAmount = 0;
      let convenienceFee = 0;
      if (billingHistory.chargedKwh != "" && billingHistory.chargedKwh != null && billingHistory.chargedKwh != undefined) {
        chargedKwh = parseFloat(billingHistory.chargedKwh).toFixed(2);
      }
      if (billingHistory.rate != "" && billingHistory.rate != null && billingHistory.rate != undefined) {
        rate = parseFloat(billingHistory.rate).toFixed(2);
      }
      if (billingHistory.billAmount != "" && billingHistory.billAmount != null && billingHistory.billAmount != undefined) {
        billAmount = parseFloat(billingHistory.billAmount).toFixed(2);
      }
      if (billingHistory.paidAmount != "" && billingHistory.paidAmount != null && billingHistory.paidAmount != undefined) {
        paidAmount = parseFloat(billingHistory.paidAmount).toFixed(2);
      }
      if (billingHistory.refundAmount != "" && billingHistory.refundAmount != null && billingHistory.refundAmount != undefined) {
        refundAmount = parseFloat(billingHistory.refundAmount).toFixed(2);
      }
      if (billingHistory.ccFee != "" && billingHistory.ccFee != null && billingHistory.ccFee != undefined) {
        convenienceFee = parseFloat(billingHistory.ccFee).toFixed(2);
      }

      const sec = parseInt(billingHistory.chargedTime, 10); // convert value to number if it's string
      let hours = Math.floor(sec / 3600); // get hours
      let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
      let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds

      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) { hours = "0" + hours; }
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }

      if (billingHistory.paymentDate == null || billingHistory.paymentDate == "") {
        var paymentDate = "";
        var paymentDate1 = "";
      } else {
        var paymentDate = moment(billingHistory.paymentDate).format("DD MMM YYYY");
        var paymentDate1 = moment(billingHistory.paymentDate).format("HH:mm");
      }

      return (
        <tr>
          <td><Link to={{ pathname: '/billDetails', state: billingHistory }}>{billingHistory.paymentTransactionId}</Link></td>
          <td className="wrap-word">{paymentDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {paymentDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {paymentDate1} </div> </>} </td>
          <td>{chargedKwh}</td>
          <td>{hours + ' : ' + minutes + ' : ' + seconds}</td>
          {/* <td>{rate}</td> */}
          {/* <td>{billingHistory.totalKwh}</td> */}
          <td>{billAmount}</td>
          <td>{paidAmount}</td>
          {/* <td>{convenienceFee}</td> */}
          <td>{refundAmount}</td>
          <>
            {
              (billingHistory.status == "PaymentSuccess") ? <td style={{ color: "green" }}>{billingHistory.status} <img className="ml-1" src={process.env.PUBLIC_URL + "/assets/img/SuccessIcon.png"} alt="" style={{ width: "0.9rem", height: "0.9rem", marginTop: "-3px" }} /></td> :
                (billingHistory.status == "PaymentFailed") ? <td style={{ color: "red" }}>{billingHistory.status} <img className="ml-1" src={process.env.PUBLIC_URL + "/assets/img/FailedIcon.png"} alt="" style={{ width: "0.9rem", height: "0.9rem", marginTop: "-3px" }} /></td> :
                  (billingHistory.status == "Refund is Initiated") ? <td style={{ color: "blue" }}>{billingHistory.status} <img className="ml-1" src={process.env.PUBLIC_URL + "/assets/img/RefundIcon.png"} alt="" style={{ width: "0.9rem", height: "0.9rem", marginTop: "-3px" }} /></td> : <td>{billingHistory.status}</td>
            }
          </>
          {/* <td>{billingHistory.status}</td> */}
          {/* <td>{billingHistory.</td> */}
          <td>{billingHistory?.freeCharge ? "Yes" : "No"}</td>

        </tr>)
    });
    
    const getChargingHistoryDetails = this.state.chargingHistory.map((chargingHistory, index) => {
      // if(chargingHistory.month == null){
      //   var startTime = "";
      // }else{
      //   var startTime = moment(`${chargingHistory.month}`).format("MMM")
      // }
      var months = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
      var monthName = months[chargingHistory.month]

      return (
        <tr>
          <td>{chargingHistory.year}</td>
          <td>{monthName}</td>
          <td>{chargingHistory.count}</td>
          <td>{chargingHistory.value}</td>
        </tr>)
    });
    const getCardDetails = this.state.savedCardDetails.map((details, index) => {
      var getCardNo = details.cardNumber;
      var lastFour = getCardNo.substr(getCardNo.length - 4)
      var cardFormat = "XXXX XXXX XXXX"
      var displayCardNo = cardFormat + " " + lastFour;
      var currentTime = moment(new Date()).format("YYYY-MM")
      if (currentTime > details.expiryDate) {
        this.state.displayEditBtn = "block";
      }
      else {
        this.state.displayEditBtn = "none";
      }
      return (
        <div key={index} class="mt-3 card bg-light mb-3">
          <div class="card-body">
            <MDBRow>
              <MDBCol md="1"> <i class="fas fa-credit-card visa_icon"></i></MDBCol>
              <MDBCol md="9" className="mr10">
                <div className="cardTextClr">{displayCardNo}</div>
                <div className="cardTextClr">{details.cardHolderName.toUpperCase()}</div>
                <div className="cardTextClr">{details.expiryDate}</div>
              </MDBCol>
              {/* <MDBCol md="1"className = "ml10" ><MDBBtn style={{ display: this.state.displayEditBtn }} className = "clr_w mt-3" color="primary accent-2 pull-right" onClick={this.toggleEdit.bind(this,details)}>Edit</MDBBtn></MDBCol>
             
                       <MDBCol md="1" ><MDBBtn className = "clr_w mt-3" color="primary accent-2  pull-right" onClick={this.toggleDelete.bind(this,details.id)} >Delete</MDBBtn></MDBCol>
              */}
            </MDBRow>

          </div>
        </div>
      )
    });
    return (
      <>

        <main class="content-div">
          <p>Cloud User History</p>
          <div className="page-outerdiv">
            <div className="pull-right ">
              {
                (localStorage.getItem("role") == Roles.WEBASTO_SUPER_ADMIN_DEVOPS) ?
                  <Link to={{
                    pathname: '/viewChargePoint'
                  }} >
                    <button class="btn btn-primary m-0 btn-blue" type="button"> Back
                    </button></Link> :
                  (localStorage.getItem("isFromWallboxList") == "true") ?
                    <Link to={{
                      pathname: '/viewChargePoint'
                    }} onClick={() => { localStorage.setItem("isFromWallboxList", false) }}><button class="btn btn-primary m-0 btn-blue" type="button"> Back
                      </button></Link> :
                    <Link to={{
                      pathname: '/viewChargePoint'
                    }}><button class="btn btn-primary m-0 btn-blue" type="button"> Back
                      </button></Link>
              }
            </div>

            {/* <div className="row mt-3 trans_lable">
           <div className="col-md-4">
            <b>Serial Number:</b> {this.state.saveWallBoxNo}
            </div>
        </div> */}
            <div className="row mt-3 trans_lable">
              <div className="col-md-4 pl-0">
                {
                  (localStorage.getItem("role") == Roles.WEBASTO_CLOUD_USER) ? <><b>User Email:</b> {localStorage.getItem("user")}</> : <><b>User Email:</b> {this.state.saveEmail}</>
                }

              </div>
            </div>
            <div className="row mt-3 trans_lable">
              <div className="col-md-4">
                <b>Serial Number:</b> {this.state.saveWallBoxNo}
              </div>
            </div>

            {/* end page menu bar  */}

            {/* table start */}
            <form
              className='needs-validation'
              // onSubmit={this.toggle1}
              noValidate
            >
              <MDBNav className="nav-tabs mt-3">
                <MDBNavItem>
                  <MDBNavLink to="#" className={this.state.activeItem == "1" ? "activetab" : ""} onClick={this.tabToggle.bind(this, "1")} role="tab" >
                    Payment History
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="#" className={this.state.activeItem == "2" ? "activetab" : ""} onClick={this.tabToggle.bind(this, "2")} role="tab" >
                    Statistics
                  </MDBNavLink>
                </MDBNavItem>
                {/* <MDBNavItem>
                <MDBNavLink to="#" className={this.state.activeItem == "3" ? "activetab" : ""}  onClick={this.tabToggle.bind(this,"3")} role="tab" >
                Charging History
                </MDBNavLink>
              </MDBNavItem> */}
                {/* <MDBNavItem>
                <MDBNavLink to="#" className={this.state.activeItem == "4" ? "activetab" : ""}  onClick={this.tabToggle.bind(this,"4")} role="tab" >
                Cloud User Cards
                </MDBNavLink>
              </MDBNavItem> */}
              </MDBNav>
              <MDBTabContent activeItem={this.state.activeItem} className="pt-2" >
        {/* Payment History Start */}
        <MDBTabPane tabId="1" role="tabpanel">
        <div className="">
        <div className="row  trans_lable">
            
            <div className="col-md-9 mt-3">
              <label className="pull-left sel_lbl">Show entries</label>
                        <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left " >
                          <Form.Control as="select" className="form_select showentry_sel"  id="showAdminEntries" onChange={this.showAdminChange}> 
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                            <option>50</option>
                          </Form.Control>
                        </Form.Group>
              </div>
              <div className="col-md-3  pull-right mt-2">
                     <MDBBtn color="primary accent-2  pull-right"  className="mt-2" style={{color:"#fff"}} onClick={this.exportBillingHistoryCsv} >
                        <i className="fas fa-file-export mr-2"></i>
                        Export
                     </MDBBtn>
                  </div>
    
                {/* <div className="col-md-3 mt-1 pull-right">
                    <select className="browser-default custom-select select_height" id="inactiveId" onChange={this.adminsStatusChanged.bind(this)}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                </div> */}
                
              </div>
          </div>
        <div className="row mt-2 ">
           <div className="col-md-12">
          <Table striped hover size="sm" className="page-table">
            <thead>
              <tr>
                <th>Transaction Id</th>
                <th>Date/Time</th>
                <th>Charged (kWh)</th>
                <th>Charged Time</th>
                {/* <th>Rate/kWh ($)</th> */}
                {/* <th>Total (kWh)</th> */}
                <th>Bill ($)</th>
                {/* <th>CC Fee</th> */}
                <th>Paid ($)</th>
                <th>Refund ($)</th>
                <th>Status</th>
                {/*<th>Download Receipt</th> */}
                <th>Free Charge</th>
              </tr>
            </thead>
            <tbody>
             {getBillingDetails}
              {
                (this.state.billingHistory.length == 0) ?
                  <tr>
                    <td colSpan="9" style={{ textAlign: "center" }}>No Data Available</td>
                  </tr> : null
              }
            </tbody>
          </Table>
          <Pagination
            hideDisabled
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.pageSize}
            totalItemsCount={this.state.billingHistory.length}
            onChange={this.handlePageChange.bind(this)}
          />
          </div>
   
          
          {/* End table  */}
          </div>
        </MDBTabPane>
        {/* Payment History End */}

                {/* Statistics Start */}
                <MDBTabPane tabId="2" role="tabpanel">
                  <div className="row mt-2">
                    {/* Graph Start */}
                    <div className="col-lg-6 col-md-6 mb-4 mt-2" id="graph4">
                      <div className="card card-cascade narrower pb-3 content">
                        <div className="view view-cascade gradient-card-header warning-color">
                          <h5 className="mb-0">User Monthly Report</h5>
                        </div>
                        <div className="card-body card-body-cascade text-center">
                          <Bar 
                          data={this.state.dataBar} 
                          options={{
                            scales: {
                              yAxes: [{
                                scaleLabel: {
                                  display: true,
                                  labelString: 'Kw / h'
                                },
                                ticks: this.state.userStep
                              }],
                              xAxes: [{
                                scaleLabel: {
                                  display: true,
                                  labelString: 'Month'
                                }
                              }]
                            }
                          }} />
                        </div>
                      </div>
                    </div>
                    {/* Graph End */}
                    {/* Cloud User History Table Start */}
                    <div className="col-lg-6 col-md-6 mb-4 mt-2" id="clouduser_history_table">
                      <div className="row mt-2 ">
                        <div className="col-md-12">
                          <Table striped hover size="sm" className="page-table mt-0">
                            <thead>
                              <tr>
                                <th>Year</th>
                                <th>Month </th>
                                <th>Count </th>
                                <th>Value (kWh)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getChargingHistoryDetails}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                    {/* Cloud User History Table End */}
                  </div>
                </MDBTabPane>
                {/* Statistics End */}

                <MDBTabPane tabId="3" role="tabpanel">
                  <div className="">
                    <div className="row  trans_lable">
                    </div>
                  </div>
                  <div className="row mt-2 ">
                    <div className="col-md-12">
                      <Table striped hover size="sm" className="page-table">
                        <thead>
                          <tr>
                            <th>Year</th>
                            <th>Month </th>
                            <th>Count </th>
                            <th>Value (kWh)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getChargingHistoryDetails}
                        </tbody>
                      </Table>
                    </div>
                    {/* End table  */}
                  </div>
                </MDBTabPane>
                <MDBTabPane tabId="4" role="tabpanel">
                  <div className="">
                    <div className="breadcrumb_div">Saved Cards</div>
                    {/* <div className="row  mt-1">
                    <div className="col-md-12  pull-right">
                        <MDBBtn color="primary accent-2  pull-right" className="mt-2" style={{ color: "#fff" }} onClick={this.toggleAdd}>Add Card </MDBBtn>
                        <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                    </div>
                </div> */}
                    {getCardDetails}
                  </div>
                </MDBTabPane>


              </MDBTabContent>

            </form>
          </div>
        </main>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>Session Expired</MDBModalHeader>
          <MDBModalBody>
            Please login again.
          </MDBModalBody>
          <MDBModalFooter>
            {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
            <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modal1} toggle={this.toggleAdd} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleAdd}>Add new card</MDBModalHeader>
          <MDBModalBody className="needs-validation">
            <MDBRow>
              <MDBCol md="10">
                <MDBInput label="Card number" outline
                  type="text" pattern="[0-9 ]*"
                  id="cardNumber"
                  value={this.state.cardNumber}
                  onChange={this.onChangeCardNo}
                  maxLength="19"
                />
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["cardNoEmpty"]}</span></span>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["cardLengthEmpty"]}</span></span>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["cardexists"]}</span></span>
              </MDBCol>
              <MDBCol md="2"></MDBCol>
              <MDBCol md="5">
                <MDBInput label="Expiry date" outline
                  id="expiryDate"
                  value={this.state.expiryDate}
                  onChange={this.onChangeExpiryDate}
                  type="text" pattern="[0-9-]*"
                  maxLength="7"
                />
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["dateEmpty"]}</span></span>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["validDate"]}</span></span>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["invalidDate"]}</span></span>
              </MDBCol>
              <MDBCol md="2">
              </MDBCol>
              <MDBCol md="10">
                <MDBInput label="Cardholder name" outline
                  id="cardHolderName"
                  value={this.state.cardHolderName}
                  onChange={this.onChangeHolderName}
                  type="text"
                />
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["nameEmpty"]}</span></span>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["cardHolderLength"]}</span></span>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="danger" onClick={this.toggleAdd}>Close</MDBBtn>
            <MDBBtn color="primary" onClick={this.submitSaveCards} >Save</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}