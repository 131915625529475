//React Imports
import React from 'react';
import { createBrowserHistory } from 'history';
//3rd Party Imports
import $ from "jquery";
import { withTranslation } from 'react-i18next';
//API Imports
import * as baseUrl from '../views/constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

//Component Imports
import OTAManagementTemplate from '../Screens/OTAManagementTemplate';

const history = createBrowserHistory();

class chargePoint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chargePoint: "",
            saveSelectedCpid: "",
            options: [],
            multiple: false,
            hideActionBtn: "none", // show hide btn varible based on permission 
            updatePermission: "", // ChargePoint_U conndition
            activePage: 1,
            active: "active",
            activeArray: [],
            checked: false,
            activeDeactiveArray: '',
            getModelNameList: [],
            cpid1: '',
            filterData: [],
            dataArray: [],
            selectDataArray: [],
            noOfRecords: '',
            data: new Map(),
            intial_data: [],
            pageSize: "10",
            pageNo: 1,
            modalsession: false,
            hideUpdateActionBtn: "none",
            errors: {},
            addModel: false,
            fromDateApi: '',
            toDateApi: '',
            curPageFirstCreTime: "",
            curPageLastCreTime: "",
            prevPageFirstCreTime: "",
            listOfFirstCreatedtimes: [],
            user: {
                permissions: []
            },
            selectedModel: "",
            modelValue: "",
            modalAddSuc: false,
            disabledAttr: true,
            confirmDeleteSeq: false,
            otaDeleteSuccess: false,
            confirmWarningExits: false,
            deleteModelStatusMessage: "",
            delModelId: "",
            isChecked: false,
            selectedOption: "",
            selectConfigkeyModal: false,
            isModelVariant: false,
            selectConfigkeyvalueModal: false,
            modelSelectOption: false,
            configKeyChecked: [],
            selectedConfigKey: "",
            selectedConfigKeyValues: [],
            config_key: [],
            isEditModel: false,
            modelName: "",
            modelMetertypes:[],
            configKeyList: [],
            configKeyValueList: [],            
            modelVariantStatus:{},
            modelMeterTypeValue:"",
            editModelId:"",
            modalErrMsg: "",
            isModelErr: false,
            loading: false,
            IsDataFound : true,
        }
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.getModelList = this.getModelList.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.onChangeModel = this.onChangeModel.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
        this.ClearModel = this.ClearModel.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.addNew = this.addNew.bind(this)
        this.modelOnChange = this.modelOnChange.bind(this)
        this.toggleAddModelPopup = this.toggleAddModelPopup.bind(this)
        this.toggleAddSuc = this.toggleAddSuc.bind(this)
        this.saveModel = this.saveModel.bind(this)
        this.closetoggleAddSuc = this.closetoggleAddSuc.bind(this)
        this.toggleConfirmation = this.toggleConfirmation.bind(this)
        this.deleteOtaDetails = this.deleteOtaDetails.bind(this)
        this.toggleconfirmWarningExits = this.toggleconfirmWarningExits.bind(this)
        this.toggleOtaDeleteSuccess = this.toggleOtaDeleteSuccess.bind(this)
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
        this.handleOptionChange = this.handleOptionChange.bind(this)
        this.modelMeterTypeChange = this.modelMeterTypeChange.bind(this)
        this.modelConfigKeyChange = this.modelConfigKeyChange.bind(this)
        this.toggle_list = this.toggle_list.bind(this)
        this.modelConfigKeyValueChange = this.modelConfigKeyValueChange.bind(this)
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        this.saveConfigKey = this.saveConfigKey.bind(this)
        this.saveConfigKeyValue = this.saveConfigKeyValue.bind(this)
        this.modelConfigKeyValueChange = this.modelConfigKeyValueChange.bind(this)
        this.handleChangeCheckboxForConfigKeyValue = this.handleChangeCheckboxForConfigKeyValue.bind(this)
        this.editModel = this.editModel.bind(this)
        this.updateModel = this.updateModel.bind(this)
        this.modalErrMsgPopup = this.modalErrMsgPopup.bind(this)
    }

    saveConfigKeyValue() {
        this.setState({
            selectedConfigKeyValues: this.state.selectedConfigKeyValues,
            selectedOption: "ConfigKey",
            selectConfigkeyvalueModal: false,
            addModel: true,
            // disabledAttr: !this.state.disabledAttr
        })
    }
    saveConfigKey() {
        this.setState({
            selectedConfigKey: this.state.selectedConfigKey,
            selectedOption: "ConfigKey",
            selectConfigkeyModal: false,
            addModel: true,
            disabledAttr: true
        })
    }

    handleChangeCheckbox = (value, index) => {
        this.setState({
            selectedConfigKey: value,
            disabledAttr: false
        },()=>{
            this.getModelConfigKeyValue();
        })
        
        // const { configKeyChecked, selectedConfigKey } = this.state;

        // if (configKeyChecked.includes(index)) {
        //     this.setState({
        //         configKeyChecked: configKeyChecked.filter(item => item !== index),
        //         selectedConfigKey: selectedConfigKey.filter(item => item !== value),
        //     });
        // } else {
        //     this.setState({
        //         configKeyChecked: [...configKeyChecked, index],
        //         selectedConfigKey: [...selectedConfigKey, value],
        //     });
        // }
    }
    handleChangeCheckboxForConfigKeyValue(value, index, e){
        const { selectedConfigKeyValues } = this.state;
        
        if(e.target.checked === true) {
            this.setState({disabledAttr: false})
        } else if (e.target.checked === false && selectedConfigKeyValues.length <= 1){
            this.setState({disabledAttr: true})
        } else if (e.target.checked === false && selectedConfigKeyValues.length >= 1){
            this.setState({disabledAttr: false})
        }

        if (selectedConfigKeyValues.includes(value)) {
            this.setState({
                selectedConfigKeyValues: selectedConfigKeyValues.filter(item => item !== value),
            });
        } else {
            this.setState({
                selectedConfigKeyValues: [...selectedConfigKeyValues, value],
            });
        }
    }
    handleCheckboxChange = (e) => {
        this.setState((prevState) => ({
            isChecked: !prevState.isChecked,
            disabledAttr: !prevState.disabledAttr
        }))
    }
    handleOptionChange = (event) => {
        let optionValue = event.target.value;
        if(optionValue==="ConfigKey"){
            this.getModelConfigKey();
        } else if(optionValue==="MeterType"){
            this.getModelMetertypes();
        } 
        this.setState({
            selectedOption: optionValue,
            modelSelectOption: !this.state.modelSelectOption
        })
    }

    modelMeterTypeChange(e) {
        let meterTypeValue = e.target.value;
        if(meterTypeValue === "") {
        this.setState({
            modelMeterTypeValue : meterTypeValue,
            disabledAttr: true
        }) 
        } else {
            this.setState({
                modelMeterTypeValue : meterTypeValue,
                disabledAttr: false
            })
        }        
    }
    modelConfigKeyChange() {
        this.setState({
            selectConfigkeyModal: !this.state.selectConfigkeyModal,
            selectedOption: "",
            addModel: false,
            disabledAttr: true
        })
    }
    modelConfigKeyValueChange() {
        this.setState({
            selectConfigkeyvalueModal: !this.state.selectConfigkeyvalueModal,
            selectedOption: "",
            addModel: false,
            disabledAttr: true
        })
    }
    toggle_list = () => {
        this.setState({
            selectConfigkeyModal: false,
            selectConfigkeyvalueModal: false
        })
    }
    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        history.push('/');
        window.location.reload();
    }


    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;
        this.setState({
            activePage: 1,
            pageSize: entries
        }, () => {
            this.getModelList(this.state.pageSize, this.state.activePage, this.state.modelValue);
        })
    }

    async getModelList(pageSize, pageNo, model) {
        this.setState({ loading: true })
        let errors = {};
        var url = baseUrl.URLPath + "otamanagement/models/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&model=" + model;
        let types = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};
        let response = await APICallUtility.cpmsAPIFetch(url, types, payload, headers, this.props.t);
        if (response) {
            this.setState({loading : false})
            if (response.status === 404) {
                if (model !== "" && response.message === "no_data_found") {
                    errors["chargePointNotExists"] = this.props.t('model_does_not_exist');
                    this.setState({
                        loading: false,
                        errors: errors,
                        modelValue: '',
                        IsDataFound : true
                    })
                }
                else{
                    this.setState({IsDataFound : false})
                }
            } else {
                this.setState({
                    dataArray: response.data,
                    noOfRecords: response.count,
                    loading: false,
                    IsDataFound : true
                })
            }
        }

    }

    UNSAFE_componentWillMount() {
        var user = {
            permissions: []
        }
        user.permissions = localStorage.getItem("roleAccess");
        this.setState({
            user: user
        })
    }

    componentDidMount() {
        var user = {
            permissions: []
        }
        user.permissions = localStorage.getItem("roleAccess");
        this.getModelList(this.state.pageSize, this.state.activePage, this.state.modelValue);
        this.getModelName();
    }

    FindCpName(ref, event) {

    }

    addNew() {
        this.toggleAddModelPopup();
        this.setState({
            modelVariantStatus:{},
            editModelId:"",
            
        })
    }

    editModel(modelid, modelName) {
        var modelid = modelid;
        var modelName = modelName;
        this.setState({
            addModel: !this.state.addModel,
            isEditModel: true,
            modelName: modelName,
            isModelVariant: true,
            modelSelectOption: true,
            editModelId:modelid
        },()=>{
            this.getModelDetails();
        })
    }
    modelOnChange() {
        let error = {}
        var modelName = document.getElementById("model").value;
        var disabledAttr = "";
        error['modelEmpty'] = "";
        modelName ? disabledAttr = false : disabledAttr = true;
        this.setState({
            selectedModel: modelName,
            disabledAttr: disabledAttr,
            errors: error,
            isModelVariant: true,
            modelVariantStatus:{},
            isChecked:false
        },()=>{
            this.getModelVariantStatus();
        })
        
    }

    async toggleAddModelPopup() {
        let error = {};
        error['modelEmpty'] = "";
        this.setState({
            addModel: !this.state.addModel,
            selectedModel: "",
            errors: error,
            disabledAttr: true,
            modelSelectOption: true,
            isChecked: false,
            selectedOption: "",
            isModelVariant: false,
            modelName: "",
            isEditModel: false,
            selectedConfigKey: "",
            modelMeterTypeValue:"",
            selectedConfigKeyValues: []
        });
    }

    async toggleAddSuc() {
        this.setState({
            modalAddSuc: !this.state.modalAddSuc,
        });
    }

    async closetoggleAddSuc() {
        this.setState({
            modalAddSuc: !this.state.modalAddSuc,
        }, () => {
            this.getModelList(this.state.pageSize, this.state.activePage, this.state.modelValue);
        });
    }

    async modalErrMsgPopup() {
        this.setState({
            isModelErr: !this.state.isModelErr,
            addModel: false
        });
    }

    async getModelName() {
        var url = baseUrl.URLPath + servicesUrl.getModelList;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (!response.status) {
            this.setState({ getModelNameList: response })
        }
    }

    async saveModel() {
        let error = {};
        let url = baseUrl.URLPath + servicesUrl.addModel;
        let type = ApiMethodTypes.POST;
        let headers = getSecurityHeaders();
        let payload =  {
            "configKey": this.state.selectedConfigKey,
            "configKeyValues": this.state.selectedConfigKeyValues,
            "enableConfigKey":  this.state.selectedOption=== "ConfigKey" ? true :false ,
            "enableMeterType": this.state.selectedOption=== "MeterType" ? true :false ,
            "enableModelVariant": this.state.isChecked,
            "meterTypeValue": this.state.modelMeterTypeValue,
            "modelName": this.state.selectedModel
          }
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);
        if (response !== undefined) {
            if (response.status === 400) {
                if(response.message === "ota_model_already_exists") {
                    this.setState({modalErrMsg:  this.props.t('ota_model_already_exists')})
                } else if(response.message === "ota_model_meter_type_missing") {
                    this.setState({modalErrMsg:  this.props.t('ota_model_meter_type_missing')})
                } else if(response.message === "ota_model_config_keys_missing") {
                    this.setState({modalErrMsg:  this.props.t('ota_model_config_keys_missing')})
                } else if(response.message === "ota_model_key_exists") {                    
                    this.setState({modalErrMsg:  this.props.t('ota_model_key_exists')})
                }
                this.modalErrMsgPopup();                
            }
            else {
                this.setState({
                    addModel: !this.state.addModel
                });
                this.toggleAddSuc();
                this.getModelList(this.state.pageSize, this.state.activePage, this.state.modelValue);
            }
        }
    }

    handlePageChange(pageNumber) {
        if (pageNumber !== this.state.activePage) {
            this.setState({ activePage: pageNumber }, () => {
                this.getModelList(this.state.pageSize, this.state.activePage, this.state.modelValue);
            });
        }
    }

    async getModelDetails() {
        var url = baseUrl.URLPath + servicesUrl.addModel+"?modelId="+this.state.editModelId;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (!response.status) {

            let checkValue  = ( (response.enableConfigKey || response.enableMeterType ) ) ? true :false;
           
            this.setState({ 
                selectedConfigKey: response.configKey,
                 selectedConfigKeyValues:response.configKeyValues,
                // selectedOption:response.enableConfigKey ? "ConfigKey" : response.enableMeterType ? "MeterType": "",
                // isModelVariant:response.enableModelVariant,
                selectedOption:"",
                selectedModel:response.modelName,
                modelMeterTypeValue:response.meterTypeValue,
                isChecked: checkValue,
                modelVariantStatus:response,
            })

            if(response.enableMeterType) {
                this.setState({
                    selectedOption: "MeterType",
                    modelSelectOption: false
            })
                this.getModelMetertypes();
            }
            if(response.enableConfigKey) {
                this.setState({
                    selectedOption: "ConfigKey",
                    modelSelectOption: false
                })
                this.getModelConfigKeyValue();
                // this.getModelConfigKey();
            }
        }
    }


    async updateModel() {
        let error = {};
        let url = baseUrl.URLPath + servicesUrl.addModel+"?modelId="+this.state.editModelId;
        let type = ApiMethodTypes.PUT;
        let headers = getSecurityHeaders();
        let payload =  {
            "configKey": this.state.selectedConfigKey,
            "configKeyValues": this.state.selectedConfigKeyValues,
            "enableConfigKey":  this.state.selectedOption=== "ConfigKey" ? true :false ,
            "enableMeterType": this.state.selectedOption=== "MeterType" ? true :false ,
            "enableModelVariant": this.state.isChecked,
            "meterTypeValue": this.state.modelMeterTypeValue,
            "modelName": this.state.selectedModel
          }
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);
        if (response !== undefined) {
            if (response.status === 400) {
                if(response.message === "ota_model_already_exists") {
                    this.setState({modalErrMsg:  this.props.t('ota_model_already_exists')})
                } else if(response.message === "ota_model_meter_type_missing") {
                    this.setState({modalErrMsg:  this.props.t('ota_model_meter_type_missing')})
                } else if(response.message === "ota_model_config_keys_missing") {
                    this.setState({modalErrMsg:  this.props.t('ota_model_config_keys_missing')})
                } else if(response.message === "ota_model_key_exists") {                    
                    this.setState({modalErrMsg:  this.props.t('ota_model_key_exists')})
                }
                this.modalErrMsgPopup();     
            } else {
                this.setState({
                    addModel: !this.state.addModel
                });
                this.toggleAddSuc();
                this.getModelList(this.state.pageSize, this.state.activePage, this.state.modelValue);
            }
        }
    }

    handlePageChange(pageNumber) {
        if (pageNumber !== this.state.activePage) {
            this.setState({ activePage: pageNumber }, () => {
                this.getModelList(this.state.pageSize, this.state.activePage, this.state.modelValue);
            });
        }
    }



    handleKeyDown(ref, e) {
        let errors = this.state.errors;
        var model_no = e.target.value;
        if (model_no !== 0) {
            errors["chargePointNotExists"] = "";
            this.setState({ errors });
        }
        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
        var model = model_no.slice(0, model_no.length - 1);
        this.getSearchModelList(model);
        if (e.keyCode === 13) {
            this.onChangeModel(ref, e);
            $("#rbt_id").hide();
        } else if (key === "Backspace" || key === "Delete") {
            if (model === "") {
                this.setState({
                    modelValue: model
                }, () => {
                    this.getModelList(this.state.pageSize, this.state.activePage, this.state.modelValue);
                })
            }
        } else if ((e.keyCode === 86 && ctrl)) {
            this.setState({
                options: []
            }, () => {
                this.getSearchModelList(model_no)
            });
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9., -]");
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    ClearModel(ref, event) {
        let errors = this.state.errors;
        if (errors['chargePointNotExists'] !== '') {
            errors["chargePointNotExists"] = "";
            this.setState({
                modelValue: '',
                errors: errors
            });
        } else {
            errors["chargePointNotExists"] = "";
            this.setState({
                modelValue: '',
                activePage: 1,
                errors: errors
            }, () => {
                this.getModelList(this.state.pageSize, this.state.activePage, this.state.modelValue);
            });
        }
        ref.current.clear();
    }



    onChangeModel(ref, e) {
        var model = ref.current.inputNode.value;
        var errors = {};
        if (model === null || model === "") {
            errors["chargePointNotExists"] = this.props.t('select_the_model');
            this.setState({
                errors: errors
            }, () => {
                this.getModelList(this.state.pageSize, this.state.activePage, this.state.modelValue);
            });
        } else {
            errors["chargePointNotExists"] = "";
            this.setState({
                errors: errors,
                modelValue: model
            }, () => {
                this.getModelList(this.state.pageSize, this.state.activePage, model);
            });
        }
    }

    async getSearchModelList(model) {
        var url = baseUrl.URLPath + "otamanagement/models/search/partial?model=" + model;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response !== undefined) {
            this.setState({
                options: response,
                intial_data: response
            });
        }
    }

    async deleteOtaDetails() {
        this.toggleConfirmation();
        var url = baseUrl.URLPath + servicesUrl.deleteOTAModel + "?modelId=" + this.state.delModelId;
        fetch(url, {
            method: ApiMethodTypes.DELETE,
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then((resp) => {
            if (resp.status === 401) {
                window.location.reload();
            }
            if (resp.status === 200) {
                return resp.json();
            }
            if (resp.status === 404 || resp.status === 400) {
                return resp.json();
            }
        }).then((response) => {
            if (response?.message === "ota_builds_data_exists" || response?.message === "ota_details_not_available" || response?.message === "ota_model_id_invalid") {
                this.setState({ deleteModelStatusMessage: response?.message });
                this.toggleconfirmWarningExits();
            } else if (response?.message === "firmware_delete_success") {
                this.toggleOtaDeleteSuccess();
                // this.setState({  }, ()=>{
                //     this.toggleOtaDeleteSuccess();
                // });
            }
        }).catch((error) => {
            console.log(error, "catch the loop")
        })

    }

    toggleConfirmation(id) {
        this.setState({ confirmDeleteSeq: !this.state.confirmDeleteSeq, delModelId: id })
    }

    toggleOtaDeleteSuccess() {
        this.setState({
            otaDeleteSuccess: !this.state.otaDeleteSuccess
        }, () => {
            this.getModelList(this.state.pageSize, this.state.activePage, this.state.modelValue);
        });

    }

    toggleconfirmWarningExits() {
        this.setState({ confirmWarningExits: !this.state.confirmWarningExits })
    }

    // model varient

    async getModelVariantStatus(modelName) {
        var url = baseUrl.URLPath + servicesUrl.getModelVariantStatus + "?model="+this.state.selectedModel;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (!response.status) {
            this.setState({ modelVariantStatus: response })
        }
    }
    
    async getModelConfigKey() {
        var url = baseUrl.URLPath + servicesUrl.getModelconfigkeys + "?model="+this.state.selectedModel;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (!response.status) {
            this.setState({ configKeyList: response })
        }
    }

    async getModelConfigKeyValue() {
        var url = baseUrl.URLPath + servicesUrl.getModelconfigkeysvalues + "?model="+this.state.selectedModel+"&configkey="+this.state.selectedConfigKey;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (!response.status) {
            this.setState({ configKeyValueList: response })
        }
    }

    async getModelMetertypes() {
        var url = baseUrl.URLPath + servicesUrl.getModelMetertypes + "?model="+this.state.selectedModel;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (!response.status) {
            this.setState({ modelMetertypes: response })
        }
    }

    

    render() {
        return (
            <>
                <OTAManagementTemplate
                    selectDataArray={this.state.selectDataArray}
                    multiple={this.state.multiple}
                    options={this.state.options}
                    handleKeyDown={this.handleKeyDown}
                    onChangeModel={this.onChangeModel}
                    ClearModel={this.ClearModel}
                    FindCpName={this.FindCpName}
                    errors={this.state.errors}
                    showEntriesChange={this.showEntriesChange}
                    pageSize={this.state.pageSize}
                    activePage={this.state.activePage}
                    noOfRecords={this.state.noOfRecords}
                    handlePageChange={this.handlePageChange}
                    modalsession={this.state.modalsession}
                    togglesession={this.togglesession}
                    changeHandler={this.changeHandler}
                    getChargePoints={this.getChargePoints}
                    modalAddSuc={this.state.modalAddSuc}
                    addNew={this.addNew}
                    modelOnChange={this.modelOnChange}
                    addModel={this.state.addModel}
                    selectedModel={this.state.selectedModel}
                    toggleAddModelPopup={this.toggleAddModelPopup}
                    getModelNameList={this.state.getModelNameList}
                    saveModel={this.saveModel}
                    dataArray={this.state.dataArray}
                    toggleAddSuc={this.toggleAddSuc}
                    disabledAttr={this.state.disabledAttr}
                    closetoggleAddSuc={this.closetoggleAddSuc}
                    modelValue={this.state.modelValue}
                    toggleConfirmation={this.toggleConfirmation}
                    confirmDeleteSeq={this.state.confirmDeleteSeq}
                    toggleOtaDeleteSuccess={this.toggleOtaDeleteSuccess}
                    otaDeleteSuccess={this.state.otaDeleteSuccess}
                    deleteOtaDetails={this.deleteOtaDetails}
                    toggleconfirmWarningExits={this.toggleconfirmWarningExits}
                    confirmWarningExits={this.state.confirmWarningExits}
                    deleteModelStatusMessage={this.state.deleteModelStatusMessage}
                    isChecked={this.state.isChecked}
                    handleCheckboxChange={this.handleCheckboxChange}
                    handleOptionChange={this.handleOptionChange}
                    selectedOption={this.state.selectedOption}
                    modelMeterTypeChange={this.modelMeterTypeChange}
                    modelConfigKeyChange={this.modelConfigKeyChange}
                    selectConfigkeyModal={this.state.selectConfigkeyModal}
                    toggle_list={this.toggle_list}
                    isModelVariant={this.state.isModelVariant}
                    selectConfigkeyvalueModal={this.state.selectConfigkeyvalueModal}
                    modelConfigKeyValueChange={this.modelConfigKeyValueChange}
                    modelSelectOption={this.state.modelSelectOption}
                    configKeyList={this.state.configKeyList}
                    configKeyValueList={this.state.configKeyValueList}
                    handleChangeCheckbox={this.handleChangeCheckbox}
                    saveConfigKey={this.saveConfigKey}
                    configKeyChecked={this.state.configKeyChecked}
                    selectedConfigKey={this.state.selectedConfigKey}
                    saveConfigKeyValue={this.saveConfigKeyValue}
                    selectedConfigKeyValues={this.state.selectedConfigKeyValues}
                    handleChangeCheckboxForConfigKeyValue={this.handleChangeCheckboxForConfigKeyValue}
                    editModel={this.editModel}
                    isEditModel={this.state.isEditModel}
                    modelName={this.state.modelName}
                    modelVariantStatus = {this.state.modelVariantStatus}
                    modelMetertypes = {this.state.modelMetertypes}
                    modelMeterTypeValue = {this.state.modelMeterTypeValue}
                    editModelId = {this.state.editModelId}
                    updateModel = {this.updateModel}
                    modalErrMsgPopup = {this.modalErrMsgPopup}
                    modalErrMsg = {this.state.modalErrMsg}
                    isModelErr = {this.state.isModelErr}
                    loading = {this.state.loading}
                    isDataFound = {this.state.IsDataFound}
                />

                {/* Loader */}
                {/* <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div> */}
            </>
        );
    }
}

export default withTranslation()(chargePoint);
