import React from 'react';
import { MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import {Form,Table} from 'react-bootstrap';
import $ from "jquery";
import { createBrowserHistory } from 'history';
import {Link} from 'react-router-dom';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Loader from '../views/loader';

//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import {getSecurityHeaders} from '../common/HttpRequestClass'
import {Typeahead} from 'react-bootstrap-typeahead';


const history = createBrowserHistory();


class chargingSchedule extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          saveSelectedSchedule:"",
          intial_data:[],
          selectFindStatus: false,
          errors: {},
          cpIdSorting:false,
          multiple:false,
          options:[],
          selectDataArray:[],
          page_title:"Charging Schedule",
          activePage: 1,
          noOfRecords:'',
          data:new Map(),
          pageSize:"10",
          modalsession:false,
          user:{
            permissions: [
                 
                ]
            },
          dataArray:[
                     
                  ],
          isLoading: false,
        }
        this.slideDownChildRow = this.slideDownChildRow.bind(this);
        this.addNew = this.addNew.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.showEntriesChange=this.showEntriesChange.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.FindCpName=this.FindCpName.bind(this);
        this.handleKeyDown=this.handleKeyDown.bind(this);
        this.chargingScheduleChange=this.chargingScheduleChange.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);



}

  chargingScheduleChange(chargingScheduleName) {
    this.setState({ saveSelectedSchedule: chargingScheduleName, selectFindStatus: true }, ()=>{
      if (chargingScheduleName.length === 0) {
        this.getChargingSchedule(this.state.pageSize, this.state.activePage)
      } else {
        let errors = {};
        errors["idTagNotExists"] = "";
        this.setState({
          errors: errors
        }, ()=>{
          this.getChargingSchedule(this.state.pageSize, this.state.activePage, this.state.saveSelectedSchedule);
        });
      }
    });
  }
      // search filter 
  handleKeyDown(ref, e) {
    
    let saveSelectedSchedule = e.target.value;
    let errors = this.state.errors;

    if (this.state.saveSelectedSchedule === null || this.state.saveSelectedSchedule === "") {
      errors["selectChargepoint"] = "";
      this.setState({
        errors,
        saveSelectedSchedule: ""
      }, ()=>{
        this.getChargingSchedule(this.state.pageSize, this.state.activePage)
      })
    }

    var profile_no = e.target.value;
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
    var getList = this.state.intial_data;

    if (key === "Backspace" || key === "Delete") {
      errors["selectChargepoint"] = "";
      $("#notexists").text("");
      if (profile_no.length <= 1) {
        getList = [];
        this.setState({
          options: getList,
          saveSelectedSchedule: ""
        }, () => this.getChargingSchedule(this.state.pageSize, this.state.activePage));
      } else {
        this.setState({
          options: getList,
        });
        this.getChargingSchedule(this.state.pageSize, this.state.activePage);
      }
    } else {
      if (e.keyCode === 13) {
        $("#selectcharge").hide();
        // this.setState({
        //   saveSelectedSchedule: saveSelectedSchedule
        // }, ()=>{
        //   this.FindCpName(ref, e);
        // })
        this.state.saveSelectedSchedule = saveSelectedSchedule;
        this.FindCpName(ref, e);
        $("#rbt_id").hide();
      }

      if ((e.keyCode === 86 && ctrl)) {
        $("#selectcharge").hide();
        this.setState({
          saveSelectedSchedule: saveSelectedSchedule
        }, ()=>{
          this.getChargingSchedule(this.state.pageSize, this.state.activePage);
        })
      }

      switch (e.keyCode) {
        case 8:  // Backspace
        case 9:  // Tab
        case 13: // Enter
        case 37: // Left
        case 38: // Up
        case 39: // Right
        case 40: // Down
          break;
        default:
          var regex = new RegExp("^[a-zA-Z0-9., -]");
          if (!regex.test(key)) {
            e.preventDefault();
            return false;
          }
          break;
      }
    }
  }

  FindCpName(ref, event) {
    let saveSelectedSchedule = ref.current.inputNode.value;
    let errors = this.state.errors;

    if (saveSelectedSchedule === null || saveSelectedSchedule === "") {
      errors["selectChargepoint"] = this.props.t('please_enter_charging_schedule');
      $("#notexists").text("");
      this.setState({ errors: errors });
    } else {
      errors["selectChargepoint"] = "";
      this.setState({ errors: errors, selectFindStatus: true, saveSelectedSchedule: saveSelectedSchedule }, ()=>{
        this.getChargingSchedule(this.state.pageSize, this.state.activePage, "click", true);
      });
    }

    if (this.state.selectDataArray.length === 0) {
      if (saveSelectedSchedule === null || saveSelectedSchedule === "") {
        let errors = {}
        errors["selectChargepoint"] = this.props.t('please_select_charging_profile');

        this.setState({ errors: errors });
      } else {
        let errors = {}
        errors["selectChargepoint"] = "";

        this.setState({ errors: errors });
      }
    }

  }


async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}

showEntriesChange(){
  
  var entries=document.getElementById("showEntries").value;
  this.state.data.clear();
  this.setState({pageSize:entries, activePage: 1});
  this.getChargingSchedule(entries,1, "", true);
}

sessiontagOk(){
  history.push('/');
  window.location.reload();
}

//This is for adding the charge profile
addNew(){
  this.props.history.push('/addchargingSchedule');
}

slideDownChildRow(u_email,e){
  var header = "";
  var rows = "";
  if(e===""){
     rows = $("#content0");
     header = $("#header0");

     $(header).text("▲");
     $(rows).slideDown();
  }else{
    rows = e.target.parentElement.nextElementSibling.firstElementChild.children;
    header = e.target;
      if ($(rows).is(":hidden")) {
        $(".wallboxrow div").slideUp();
        $(".arrow_icon").text("▼");
        $(header).text("▲");
        $(rows).slideDown();
        this.getChargingSchedule(this.state.pageSize,this.state.activePage);
      } else {
        $(header).text("▼");
        $(rows).slideUp();
      }
    }
}


  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      this.setState({ activePage: pageNumber }, ()=>{
        this.getChargingSchedule(this.state.pageSize, pageNumber, "", true);
      });
    }
  }

  async getChargingSchedule(pageSize, pageNo, action, isLoading) {
    this.setState({ isLoading: isLoading })
    var url = "";
    if (this.state.selectFindStatus === true) {
      url = baseUrl.URLPath + servicesUrl.getChargingSchedules + "?pageNo=" + 1 + "&pageSize=" + 10 + "&chargingScheduleName=" + this.state.saveSelectedSchedule;
    }
    else {
      url = baseUrl.URLPath + servicesUrl.getChargingSchedules + "?pageNo=" + pageNo + "&pageSize=" + pageSize + "&chargingScheduleName=" + this.state.saveSelectedSchedule;
    }
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      if (response.status===403) {
        this.setState({
          isLoading: false
        });
      }else{
      if (response.count.count === 0) {
        if (action === "click" || action === "enter") {
          $("#notexists").text(this.props.t('charging_schedule_not_exists'));
        }
        this.setState({
          items: [],
          noOfRecords: 0,
          selectDataArray: [],
          isLoading: false
        });
      } else {
        this.setState({
          dataArray: response.chargingSchedules,
          noOfRecords: response.count.count,
          selectDataArray: response.chargingSchedules,
          isLoading: false

        })
        if (action === "click" || action === "enter") {
          $("#notexists").text(" ");
        }
        var data = this.state.data;
        data.set(pageNo, response.count.count)
        var option = [];
        for (var i = 0; i < this.state.dataArray.length; i++) {
          option.push(this.state.dataArray[i].chargingScheduleName);
        }
        this.setState({ options: option });
      }
    }
  }
  }
  
componentDidMount(){
  let user = {
    permissions: []
  }

  user.permissions = localStorage.getItem("roleAccess");

  this.setState({ user: user }, ()=>{
    this.getChargingSchedule(this.state.pageSize,1, "", true)
  })
 $("#nochargeexists").hide();
}
  ClearChargepoint(ref, event) {
    $("#notexists").text("");
    var errors = {}
    errors["selectChargepoint"] = "";

    this.setState({
      errors: errors,
      saveSelectedSchedule: "",
      activePage: 1,
      selectFindStatus: false
    }, () => {
      this.getChargingSchedule(this.state.pageSize, this.state.activePage, this.state.saveSelectedSchedule);
    });
    ref.current.clear()
  }

//function for sorting table data on click
ascendingOrder(){
  // this.setState({dataArray : this.state.dataArray.reverse()})

  if(this.state.cpIdSorting === false){
    this.state.dataArray.sort(this.dynamicSort("chargingScheduleName"));
       this.setState({dataArray : this.state.dataArray.sort(this.dynamicSort("chargingScheduleName")), cpIdSorting: true})
  }
   else{
    this.state.dataArray.sort(this.dynamicSort("chargingScheduleName"));
       this.setState({dataArray : this.state.dataArray.reverse(), cpIdSorting: false});
   } 
  
}
minRateSorting(){
  if(this.state.cpIdSorting === false){
    this.state.dataArray.sort(function (a, b) {
      return a.minChargingRate - b.minChargingRate;
    });
this.setState({dataArray:this.state.dataArray, cpIdSorting: true})
    }
    else{
      this.setState({dataArray : this.state.dataArray.reverse(), cpIdSorting: false});
     }
}
dynamicSort(property) {
  var sortOrder = 1;

  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }

  return function (a,b) {
      if(sortOrder === -1){
          return b[property].localeCompare(a[property]);
      }else{

          return a[property].localeCompare(b[property]);
      }        
  }
}

render() {
  const{t}=this.props
  const ref = React.createRef();

  return (
    <>
  <main class="content-div" >
  <p>{t('Charging Schedule')}
              <div className="breadcrumb_div">
              {t('charge_point')} &gt; <span className="breadcrumb_page">{t('Charging Schedule')}</span>
              </div>
          </p>
        <div className="page-outerdiv">
        {/* <div className="breadcrumb_div">Charge Point > Charging Schedule</div> */}
          {/* page title */}
          {/* <p>Reservations</p> */}
          {/* end page title */}
          {/* page menu bar */}
            
          <div className="row menubar ">
            <div className="col-md-12 ">
              <div className="">

               {this.props.ChargingSchedule_W && 
                <button onClick={this.addNew} type="button" data-test="button">
                  <i className="fa fa-plus">
                  </i>
                  <span>{t('add')}</span>
                 </button> 
               }
               <div className="pull-right">
               <label className="sub-text pull-left">{t('show_entries')}</label>
               <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox"  id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}> 
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
               </div>
                
                



                {/* <button onClick={this.toggle1}>
                  <i className="fas fa-trash">
                  </i>
                  <span>Delete</span>
                </button>  */}
              </div>
            </div>
           </div>
           <div className="row">
            <div className="col-md-4  pull-right">
            <div class="input-group">
            <i className="fas fa-search fa-sm input-searchIcon"></i>
              <Typeahead
                 labelKey="name"
                 className="w_80"
                 multiple={this.state.multiple}
                 options={this.state.options}
                 id="rbt_id"
                 minLength={3}
                 placeholder={t('enter_charging_schedule')}
                 onKeyDown ={this.handleKeyDown.bind(this, ref)}
                 onChange={this.chargingScheduleChange.bind(this)}
                 ref={ref}              
                // onBlur={this.getScheduleNames}
              />
                <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this,ref)}
 >
                  <span aria-hidden="true">×</span>
                </button>
                {/* <MDBBtn color='primary' type="submit" className="btn btn-primary sm_btn btn-sm mt-1 pull-right" style={{color:"#fff"}} onClick={this.FindCpName.bind(this,ref)}
 >
                    Find
                </MDBBtn> */}
                <div class="input-group-btn">
                  <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this,ref)} >{t('find')}</button>
                </div>
                <span className="pull-left error_msg w3-animate-top mt-1" id="notexists" style={{color: "red"}}><span style={{color: "red"}}>{this.state.errors["chargePointNotExists"]}</span></span> 
                <span className="pull-left error_msg w3-animate-top mt-1"><span style={{color: "red"}}>{this.state.errors["selectChargepoint"]}</span></span> 
</div>
              </div>
          </div>
           
            {/* start page menu bar  */}
           {/* <div className="row mt-4">
              <div className="col-md-12">
                <label className="pull-left sel_lbl">Show entries</label>
                <Form.Group className="custom_select mb-0 pull-left " >
                  <Form.Control as="select" className="form_select showentry_sel"  id="showEntries" onChange={this.showEntriesChange}> 
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
            </div>
          </div> */}
          {/* end page menu bar  */}
          
          {/* table start */}
         <div className="row mt-2 ">
           <div className="col-md-12">
          <Table striped hover size="sm" className="page-table">
            <thead>
              <tr>
                <th></th>
                <th className="">{t('name')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                <th className="">{t('chargingDuration')}</th>
                <th>{t('start_schedule')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                <th className="">{t('charging_rate_unit')}</th>
                <th className="">{t('min_charging_rate')} <i class="fas fa-sort sortIcon" onClick={this.minRateSorting.bind(this)}></i></th>
              </tr>
            </thead>
          <tbody className="nested_Table" >
           {this.state.dataArray.map((items, index) =>{ 
            var date = "";
            var date1="";
             if(items.startSchedule===null || items.startSchedule===""){
              date = "";
              date1="";
            }else{
              date = moment(items.startSchedule).format("DD MMM YYYY");
              date1 = moment(items.startSchedule).format("HH:mm");
          
            }
             return(
             <>
             <tr>
              <td  id={'header'+index} className="arrow_icon " onClick={this.slideDownChildRow.bind(this,items.scheduleId)}>▼</td>
              <td className="td-wrap-word">
               <Link to={{
                pathname:'/viewchargingSchedule', 
                state:items.scheduleId, 
                }}>{items.chargingScheduleName}
              </Link>
              </td>
              <td className="td-wrap-word">{items.duration}</td>
              <td className="wrap-word">{ date && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {date}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {date1} </div> </>}</td>
              <td className="">{items.schedulingUnit}</td>
              <td className="">{items.minChargingRate}</td>
            </tr>
             <tr style={{backgroundColor:"#fff !important" }} className="wallboxrow">
             <td colSpan="8" style={{padding:"0px"}} >
                 <div id={'content'+index} class='content02' style={{display:"none"}} >
                     <table cellSpacing='0' cellPadding='0' width="100%" className='form_table2'>
                         <tr>
                            <th colSpan="2" className="pl-2 " > <b>{t('start_period')}</b></th>
                            <th colSpan="1" className="pl-2 " > <b> {t('limit')}</b> </th>
                            <th colSpan="1" className="pl-2 " > <b> {t('number_phases')} </b> </th>
                         </tr>
                         {items.chargingSchedulePeriod.map((childRows, index) => (
                             <tr key={childRows.index} style={{backgroundColor:"#fff"}} >
                             <td colSpan="2" className="">{childRows.startPeriod}</td>
                             <td colSpan="1" className="">{childRows.limit}</td>
                             <td colSpan="1" className="">{childRows.numberPhases}</td>
                         </tr>
                         ))}
                     </table>
                 </div>
             </td>
         </tr>    
      </>
            )})}
            </tbody>


          </Table>
          </div>
          <div className="col-md-12 mt-2 mb-4 text-right">
             <Pagination
              hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageSize}
              totalItemsCount={this.state.noOfRecords}
              onChange={this.handlePageChange.bind(this)}
             />
            </div>
          {/* End table  */}
          </div>
          </div>
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
             <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
            </MDBModalFooter>
        </MDBModal>
        {this.state.isLoading ? (
          <Loader loader={this.state.isLoading} />
          ) : ("")}
      </main>
 </>
  );
}
}

//export default chargingSchedule;
export default withTranslation()(chargingSchedule)