import React from 'react';
import Welcome from './Welcome';
import EsimActivated from "./EsimActivated";
import EsimError from './EsimError';


export default class Esim extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: ''
        }
        this.setPage = this.setPage.bind(this);
    }

    setPage(name) {
        this.setState({
            page: name
        })
    }

    componentDidMount() {
        localStorage.setItem('page', '');
    }

    render() {

        if (this.state.page === '' &&
            (localStorage.getItem('page') === null ||
                localStorage.getItem('page') === '' ||
                localStorage.getItem('page') === undefined)) {
            return <Welcome setPage={this.setPage} />

        }
        else if (this.state.page === 'esimActivated' || localStorage.getItem('page') === 'esimActivated') {
            return <EsimActivated setPage={this.setPage} />
        }
        else if (this.state.page === 'esimError' || localStorage.getItem('page') === 'esimError') {
            return <EsimError setPage={this.setPage} />
        }
    }
}