import React from 'react';
import { MDBRow, MDBModal, MDBModalBody, MDBModalFooter, MDBBtn, MDBCol, MDBModalHeader } from 'mdbreact';
import { Card, Accordion } from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import * as baseUrl from './constants';
import { Typeahead } from 'react-bootstrap-typeahead'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory();

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // collapseID: "",
      page_title: "User Statistics",
      options: [],
      multiple: false,
      items: [],
      intial_data: "",
      pre_items: [],
      sort_show: "block",
      sortedYear: [],
      sort_hide: "none",
      serialNumber: "",
      exportBtn: "disabled",
      userSelection: "disabled",
      errors: {}
    }
    
    this.handleChange = this.handleChange.bind(this)
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.toggle = this.toggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.sortByhandleChange = this.sortByhandleChange.bind(this);
    this.toggleCollapseYear = this.toggleCollapseYear.bind(this);
    this.toggleCollapseSubYear = this.toggleCollapseSubYear.bind(this);
    this.sortByYear = this.sortByYear.bind(this);
    this.exportStatisticsCSV = this.exportStatisticsCSV.bind(this);
    this.ClearChargepoint = this.ClearChargepoint.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
  }

  toggleCollapseYear(collapseID1) {
    this.setState(prevState => ({
      collapseID1: prevState.collapseID1 !== collapseID1 ? collapseID1 : ""
    }))
  }

  toggleCollapseSubYear(collapseID2, count_i) {
    var class_name = $("#" + collapseID2).hasClass("fa-plus");
    if (class_name == true) {
      $(".new_icon" + count_i).attr("class", "new_icon" + count_i + " fa fa-plus");
      $("#" + collapseID2).attr("class", "new_icon" + count_i + " fa fa-minus");
    } else {
      if ($("#" + collapseID2).hasClass("fa-minus")) {
        $("#" + collapseID2).attr("class", "new_icon" + count_i + " fa fa-plus");
      }
    }
  }

  ClearChargepoint(ref, event) {
    let errors = {};
    document.getElementById("sortbyid").value = "Sort By";
    errors["chargePointNotExists"] = "";
    this.setState({
      errors: errors,
      userSelection: "disabled"
    });

    ref.current.clear();
  }

  FindCpName(ref, event) {
    let sno = ref.current.inputNode.value;
    if (this.state.intial_data.includes(sno)) {
      let errors = this.state.errors;
      errors['nowallboxes'] = ''
      this.setState({
        errors: errors
      }, () => {
        this.handleChange(sno)
      })
    } else {
      let errors = this.state.errors;
      errors['nowallboxes'] = 'Invalid Serial No'
      this.setState({
        errors: errors
      })
    }
  }

  sortByYear() {
    var sortedData = [];
    var statistics = this.state.items;
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var sortedStatistics = {
      year: year,
      data: [

      ]
    }
    sortedData.push(sortedStatistics);
    var fdsf = sortedData.length;
    var yearMatched = true;
    for (var m = 1; m <= 12; m++) {
      var fs = date.getFullYear();
      if (year == date.getFullYear()) {
        var mon = {
          month: date.getMonth(),
          users: [

          ]
        }
        sortedData[0].data.push(mon);
      } else {
        if (sortedData.length == 1) {
          var sortedStatistics1 = {
            year: date.getFullYear(),
            data: [

            ]
          }
          sortedData.push(sortedStatistics1);
        }
        var mon = {
          month: date.getMonth(),
          users: [

          ]
        }
        sortedData[1].data.push(mon);
      }
      date = new Date(date.setMonth(date.getMonth() - 1));
    }


    var stas = sortedData.length;
    var stsf = sortedData[0].data.length;
    var stsds = sortedData[1].data.length;

    var namesData = this.state.items;
    for (var i = 0; i < sortedData.length; i++) {
      var sortedYear = sortedData[i];
      for (var j = 0; j < sortedYear.data.length; j++) {

        for (var x = 0; x < namesData.length; x++) {
          for (var y = 0; y < namesData[x].statistics.length; y++) {
            if (sortedYear.year == namesData[x].statistics[y].year && sortedYear.data[j].month == namesData[x].statistics[y].month) {
              var user = {
                email: namesData[x].email,
                value: namesData[x].statistics[y].value,
                count: namesData[x].statistics[y].count
              }
              sortedData[i].data[j].users.push(user);
            }
          }

        }

      }
    }
    this.state.sortedYear = sortedData;
    console.log(sortedData);
  }

  sortByhandleChange(e) {
    this.setState({
      sort_hide: "none",
      sort_show: "block"
    })
    var selected_value = e.target.value;
    var pre_data = [];
    var item_pre = this.state.items;
    for (var i = 0; i < item_pre.length; i++) {
      if (item_pre[i].email != "freecharge") {
        pre_data.push(item_pre[i]);
      }
    }
    var pre_data1 = this.state.pre_items;
    if (selected_value == "Email Id") {
      pre_data.sort((a, b) => {

        if (a.email < b.email)
          return -1;
        // if (a.email < b.email) alert("d"+a.email)
        //     return -1;
        // if (a.email > b.email) alert("3"+a.email)
        //     return 1;
        return 0;
      });

      this.setState({
        items: pre_data
      })
      for (var i = 0; i < item_pre.length; i++) {
        if (item_pre[i].email == "freecharge") {
          pre_data.unshift(item_pre[i]);
        }
      }
      console.log(pre_data);
    } else if (selected_value == "Sort By") {
      console.log(this.state.pre_items);
      this.state.items = this.state.pre_items
    } else if (selected_value == "Year") {
      if (this.state.serialNumber != "" && this.state.items != "") {
        this.sortByYear();
        this.setState({
          sort_hide: "block",
          sort_show: "none"
        })

      }

    }
  }

  handleKeyDown(e) {
    this.setState({
      exportBtn: "disabled"
    })
    var serial_no = e.target.value;
    var key = e.key;
    var list = this.state.intial_data;
    if (key == "Backspace" || key == "Delete") {

      var serial_no = e.target.value;
      if (serial_no.length <= 1) {
        list = [];
        this.setState({
          options: list,
          items: list,
        });
        this.setState({
          sort_hide: "none",
          sort_show: "block",
          serialNumber: "",
        })
        document.getElementById("sortbyid").options[0].selected = true;
      }
    } else {
      if (serial_no.length >= 0) {
        this.setState({
          options: list
        });
      }
    }
  }


  tagOk() {
    history.push('/');
    window.location.reload();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleCollapse(collapseID) {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }))
  }

  handleChange(serial_number) {
    if (serial_number == "") {
      this.setState({
        userSelection: "disabled"
      })
    }
    if (serial_number != "") {
      this.setState({
        userSelection: ""
      })
    }
    if (serial_number.length > 0) {
      this.state.serialNumber = serial_number;
      var url = baseUrl.URLPath + "web/statistics/" + serial_number;
      var header = { 'content-type': 'application/json', 'Authorization': localStorage.getItem("Authorization") }
      fetch(url, {
        method: "GET",
        headers: header
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.toggle();
            localStorage.clear();

          }
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {
            var data = [];
            this.setState({ items: response })
            this.setState({ pre_items: response })
            this.setState({
              sort_hide: "none",
              sort_show: "block",
              exportBtn: "",
            })
            document.getElementById("sortbyid").options[0].selected = true;
            // $("#").value("Sort By");
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    }


  }

  componentDidMount() {
    var url = baseUrl.URLPath + "web/statistics/chargers";
    var header = { 'content-type': 'application/json', 'Authorization': localStorage.getItem("Authorization") }
    fetch(url, {
      method: "GET",
      headers: header
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.toggle()
          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          var data = [];
          for (var i = 0; i < response.length; i++) {
            data.push(response[i]["chargePointId"])
          }
          this.setState({ intial_data: data })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  exportStatisticsCSV() {
    var url = baseUrl.URLPath + "web/statistics/download/CSV/getbySN/" + this.state.serialNumber;
    var header = { 'content-type': 'application/json', 'Authorization': localStorage.getItem("Authorization") }
    fetch(url, {
      method: "GET",
      headers: header
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.blob();
        }
      })
      .then((response) => {
        if (response != undefined) {
          let d = new Date();
          let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'Statistics_' + this.state.serialNumber + '_' + dformat + '.csv';
          a.click();
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  render() {

    const ref = React.createRef();
    const { collapseID } = this.state;
    const { collapseID1 } = this.state;
    const { collapseID2 } = this.state;

    var count_year = 0;
    var options_html = "";
    if (this.state.serialNumber == "") {
      options_html = ''
    } else {
      options_html = <><option value="Email Id">Email Id</option><option value="Year">Year</option></>;
    }

    //  for the year
    const operationYearData = this.state.sortedYear.map((item, index) => {

      var parent_index = index;


      const subaccordian = item.data.map((subrow, index) => {
        count_year++;

        var year_month = subrow.month;
        switch (year_month) {
          case 0: year_month = "January"; break;
          case 1: year_month = "February"; break;
          case 2: year_month = "March"; break;
          case 3: year_month = "April"; break;
          case 4: year_month = "May"; break;
          case 5: year_month = "June"; break;
          case 6: year_month = "July"; break;
          case 7: year_month = "August"; break;
          case 8: year_month = "September"; break;
          case 9: year_month = "October"; break;
          case 10: year_month = "November"; break;
          case 11: year_month = "December"; break;
        }

        const userData = subrow.users.map((users, index) => {
          var user_email = "";
          if (users.email == "freecharge") {
            user_email = "Free Charge";
          } else {
            user_email = users.email;
          }
          // for body data
          return (
            <MDBRow>
              <MDBCol sm="4">
                <div className="view-page-row view-bg-row charge_pointlogs" >
                  <label>
                    Name :
                  </label>
                  <label>
                    {user_email}
                  </label>
                </div>
              </MDBCol>

              <MDBCol sm="4">
                <div className="view-page-row view-bg-row charge_pointlogs" >
                  <label>
                    Count :
                  </label>
                  <label>
                    {users.count}
                  </label>
                </div>
              </MDBCol>

              <MDBCol sm="4">
                <div className="view-page-row view-bg-row charge_pointlogs">
                  <label>
                    Value :
                  </label>
                  <label>
                    {users.value}
                  </label>
                </div>
              </MDBCol>
            </MDBRow>
          )
        })


        return (
          <Card className="logs_row" >
            <Accordion.Toggle as={Card.Header} eventKey={`subyear-${index}`} id={`item1-${index}`} onClick={this.toggleCollapseSubYear.bind(this, `yearsub-${count_year}`, parent_index)}>
              <div className="row">
                <div class="col-md-4">{year_month} </div>
                <div className="col-md-8 accordion_angle ">
                  <i className={`fa fa-plus new_icon${parent_index}`} id={`yearsub-${count_year}`} />
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`subyear-${index}`} id={`yearsub-${index}`} >
              <Card.Body>
                {userData}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )
      })


      //  card for email
      return (
        <Card className="logs_row">
          {/* individual header */}
          <Accordion.Toggle as={Card.Header} eventKey={`yeare-${index}`} id={`yearmain-${index}`} onClick={this.toggleCollapseYear.bind(this, `year-${index}`)}>
            <div className="row">  <div class="col-md-4">{item.year}  </div>
              <div className="col-md-8 accordion_angle "> <i className={collapseID1 === `year-${index}` ? "fa fa-minus" : "fa fa-plus"} /></div>  </div>
            {/* <label className="pull-right mb-0">{items.timestamp}</label> */}
          </Accordion.Toggle>
          {/* individual body */}
          <Accordion.Collapse eventKey={`yeare-${index}`} id={`year-${index}`} >
            <Card.Body className="subcard_body" >
              <MDBRow>
                <MDBCol sm="12" >
                  <Accordion>
                    {subaccordian}
                  </Accordion>
                </MDBCol>
              </MDBRow>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )
    });

    // end for year


    //  for by defualt
    const operationData = this.state.items.map((item, index) => {
      var user_email = "";
      if (item.email == "freecharge") {
        user_email = "Free Charge";
      } else {
        user_email = item.email;
      }
      const userData = item.statistics.map((statistics, index) => {
        var year_month = statistics.month;
        switch (year_month) {
          case 0: year_month = "January"; break;
          case 1: year_month = "February"; break;
          case 2: year_month = "March"; break;
          case 3: year_month = "April"; break;
          case 4: year_month = "May"; break;
          case 5: year_month = "June"; break;
          case 6: year_month = "July"; break;
          case 7: year_month = "August"; break;
          case 8: year_month = "September"; break;
          case 9: year_month = "October"; break;
          case 10: year_month = "November"; break;
          case 11: year_month = "December"; break;
        }

        // for body data
        return (
          <MDBRow>
            <MDBCol sm="3">
              <div className="view-page-row view-bg-row charge_pointlogs" >
                <label>
                  Year :
                </label>
                <label>
                  {statistics.year}
                </label>
              </div>
            </MDBCol>

            <MDBCol sm="3">
              <div className="view-page-row view-bg-row charge_pointlogs" >
                <label>
                  Month :
                </label>
                <label>
                  {year_month}
                </label>
              </div>
            </MDBCol>

            <MDBCol sm="3">
              <div className="view-page-row view-bg-row charge_pointlogs" >
                <label>
                  Count :
                </label>
                <label>
                  {statistics.count}
                </label>
              </div>
            </MDBCol>

            <MDBCol sm="3">
              <div className="view-page-row view-bg-row charge_pointlogs">
                <label>
                  Value :
                </label>
                <label>
                  {statistics.value}
                </label>
              </div>
            </MDBCol>
          </MDBRow>
        )
      })
      //  card for email
      return (
        <Card className="logs_row">
          {/* individual header */}
          <Accordion.Toggle as={Card.Header} eventKey={index} id={item.id} onClick={this.toggleCollapse.bind(this, `custom-${index}`)}>
            <div className="row">  <div class="col-md-4">{user_email}  </div>
              <div className="col-md-8 accordion_angle "> <i className={collapseID === `custom-${index}` ? "fa fa-minus" : "fa fa-plus"} /></div>  </div>
            {/* <label className="pull-right mb-0">{items.timestamp}</label> */}
          </Accordion.Toggle>
          {/* individual body */}
          <Accordion.Collapse eventKey={index} id={`custom-${index}`} >
            <Card.Body>
              {userData}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )
    });

    // end for by defualt


    return (
      <>
        <main class="content-div">
          <p>User Statistics
            <div className="breadcrumb_div">Report &gt; <span className="breadcrumb_page">User Statistics</span></div>
          </p>
          <div className="page-outerdiv">
            {/* start page menu bar  */}
            <div className="row  mt-1">
              <div className="col-md-3  pull-right mt-2">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    multiple={this.state.multiple}
                    options={this.state.options ? this.state.options : []}
                    id="rbt_id"
                    minLength={3}
                    placeholder="Choose a Serial Number..."
                    onKeyDown={this.handleKeyDown.bind(this)}
                    onChange={this.handleChange.bind(this)}
                    ref={ref}
                  />
                  <button type="button" className="close close_icon" id="clearInput" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)}>Find</button>
                  </div>
                  <span className="pull-left error_msg w3-animate-top mt-1" id="nochargeexists"><span style={{ color: "red" }}>{this.state.errors['nowallboxes']}</span></span>
                </div>
              </div>

              <div className="col-md-3  pull-right mt-2">
                <select disabled={this.state.userSelection} className="browser-default custom-select select_height" id="sortbyid" onChange={this.sortByhandleChange.bind(this)}>
                  <option value="Sort By">Sort By</option>
                  {options_html}
                </select>
              </div>

              {/* export not in scope for now but in future */}

              <div className="col-md-6  pull-right mt-2">
                <MDBBtn color="primary accent-2  pull-right" className="mt-2" style={{ color: "#fff" }} onClick={this.exportStatisticsCSV} disabled={this.state.exportBtn} >
                  <i className="fas fa-file-export mr-2"></i>
                  Export
                </MDBBtn>
              </div>
            </div>

            {/* end page menu bar  */}

            <div className="row mt-3 ">

              {/* start by year display div*/}
              <div className="col-md-12" style={{ display: this.state.sort_hide }}>
                {/* title label */}
                <label className="log_title">
                  <div className="row">
                    <div className="col-md-4">
                      <span className="operation-text">Year</span>
                    </div>
                    <div className="col-md-8" id="righttext"></div>
                  </div>
                </label>
                {/* end title label */}
                {/* Accordion */}
                <div className="accordion md-accordion" id="accordionYear" role="tablist" ></div>
                <Accordion >
                  {operationYearData}
                </Accordion>
                {/* end Accordion */}
              </div>
              {/* end by year display div*/}

              {/* by default display*/}
              <div className="col-md-12" style={{ display: this.state.sort_show }}>
                <label className="log_title">
                  <div className="row">
                    <div className="col-md-4">
                      <span className="operation-text">User Name</span>
                    </div>
                    <div className="col-md-8" id="righttext"></div>
                  </div>
                </label>
                <div className="accordion md-accordion" id="accordionEx" role="tablist" ></div>
                <Accordion>
                  {operationData}
                </Accordion>
              </div>
              {/* end by default display div*/}

              {/* pagination */}
              <div className="col-md-12 mt-4" >
              </div>
              {/* end pagination */}
            </div>
          </div>
        </main>
        {/* end main div */}

        {/* popup */}
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>Session has expired</MDBModalHeader>
          <MDBModalBody>
            Please login again.
          </MDBModalBody>
          <MDBModalFooter>
            {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
            <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        {/* popup */}

      </>
    );
  }
}

export default Statistics;