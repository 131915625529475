import React from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import * as baseUrl from './constants';
import $ from "jquery";
import Datetime from 'react-datetime';
import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { hasRole, hasPermission } from './auth';
import moment from 'moment';
import CpSearch from '../common/chargePointSearch';
import Pagination from "react-js-pagination";
import { withTranslation } from 'react-i18next';
import servicesUrl from '../common/servicesUrl';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders,getSecurityHeadersWithTimezone} from '../common/HttpRequestClass';
import CustomModal from '../common/CustomModal';

class Ocppnewtag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideActionBtn: "none", // show hide btn varible based on permission 
      writePermission: this.props.ChargePoint_W, // ChargePoint_W conndition
      fields: { idTag: '', parentIdTag: '', expiryDate: '', inTransaction: '', note: '', blocked: '', tagType: '' },
      expdate: '',
      sucess: {},
      errors: {},
      disabled: "true",
      id: "",
      activeItem: "1",
      isChecked: "1",
      startDate1: "",
      startDate: new Date(),
      model1: "false",
      model2: "false",
      par_id_tag: "",
      multiple: false,
      options: [],
      page_title: "View OCPP Tag",
      user: {
        permissions: [

        ]
      },
      selectedItems: [],
      prevSelectedData: [],
      siteWallboxes: [],
      deletedWallboxes: [],
      allCPList: [],
      associatedCPList: [],
      isrfidSuccess: false,
      wallbox: '',
      noWallboxExists: "",
      getOperationsList: [],
      isFreeChargeEnabled: false,
      disabledbtn: "true",
      fromDateApi: "",
      toDateApi: "",
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: [],
      activePage: 1,
      pageSize: 30,
      // ass
      afromDateApi: "",
      atoDateApi: "",
      acurPageFirstCreTime: "",
      acurPageLastCreTime: "",
      aprevPageFirstCreTime: "",
      alistOfFirstCreatedtimes: [],
      aactivePage: 1,
      cpId: "",
      acpId: "",
      apageSize: 30,
      anoOfRecords: "",
      noOfRecords:"",
      a_wallbox: "",
      disabledbtn1: true,	
      energylimit: false,	
      sessionlimit: false,	
      limitexpiry: false,	
      expiryInDaysvalue: "",	
      energyLimitvalue: "",	
      totalEnergyConsumedvalue: "",	
      expiryDatevalue: null,	
      limitaionsDeta:false,	
      limitationSuccess:false,
      limitationStatus:false,
      modalEditLimitations: false,
      isLimitEnergyusageActive:false,
      isLimitPeriodOfUseActive:false,
      isEnergyLimitActive: false,
      isExpiryInDaysLimitActive: false,
      alertModel:false,
      tempExpiryInDaysvalue:"",
      tempEnergyLimitvalue: "",
      alertMsg:"",
      nowallBoxunassociated: ""
    }
    this.deleteTag = this.deleteTag.bind(this)
    this.idTagChange = this.idTagChange.bind(this);
    this.togglerfidSuccess = this.togglerfidSuccess.bind(this);
    this.editTag = this.editTag.bind(this)
    this.redirectToListPage = this.redirectToListPage.bind(this)
    this.backToList = this.backToList.bind(this)
    this.toggleChange = this.toggleChange.bind(this)
    this.isItemSelected = this.isItemSelected.bind(this);
    this.updateCheckboxSelection = this.updateCheckboxSelection.bind(this);
    this.handleWallboxChange = this.handleWallboxChange.bind(this);
    this.handleWallboxPress = this.handleWallboxPress.bind(this);
    this.FindRfidCp = this.FindRfidCp.bind(this);
    this.onUpdateSiteSelectAll = this.onUpdateSiteSelectAll.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.removeSelectedItem = this.removeSelectedItem.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this)
    this.ashowEntriesChange = this.ashowEntriesChange.bind(this)
    this.getRFIDCPlist = this.getRFIDCPlist.bind(this);
    this.getRFIDCPunassociatedlist = this.getRFIDCPunassociatedlist.bind(this);
    this.enableLimitations = this.enableLimitations.bind(this);	
    this.enablaSessionLimit = this.enablaSessionLimit.bind(this);	
    this.enableLimitationExpiry = this.enableLimitationExpiry.bind(this);	
    this.updateLimitations = this.updateLimitations.bind(this);	
    this.getlimitationsData = this.getlimitationsData.bind(this);	
    this.togglelimitationSuccess = this.togglelimitationSuccess.bind(this);
    this.toggleEditLimitations = this.toggleEditLimitations.bind(this);
    this.editLimitations = this.editLimitations.bind(this);
    this.onChangeLimitEnergyusageActive = this.onChangeLimitEnergyusageActive.bind(this);
    this.onChangeLimitEnergyusageValue = this.onChangeLimitEnergyusageValue.bind(this);
    this.onChangeLimitPeriodOfUseValue = this.onChangeLimitPeriodOfUseValue.bind(this);
    this.saveLimitations = this.saveLimitations.bind(this);
    this.canceleditToggle = this.canceleditToggle.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.closetoggleAlert = this.closetoggleAlert.bind(this);
    this.handleEnergyLimit = this.handleEnergyLimit.bind(this);
    this.handlePeriodOfUse = this.handlePeriodOfUse.bind(this);
    this.resetAsyncTimes = this.resetAsyncTimes.bind(this);
    this.resetTimes = this.resetTimes.bind(this);
  }

  resetAsyncTimes(){
    this.setState({
      afromDateApi: "",
        atoDateApi: ""
    })
  }

  resetTimes(){
    this.setState({
      fromDateApi: "",
      toDateApi: ""
    })
  }

  handleWallboxPress(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      this.setState({ wallbox: e.target.value });
      this.getRFIDCPlist();
    }
    if ((key == "Backspace" || key == "Delete") && e.target.value.length == 1) {
      this.setState({
        wallbox: ""
      }, () => {
        this.clearWallboxerror();
        this.getRFIDCPlist();
      });
    }


    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9_]");
        var key = e.key;

        break;

    }
  }

  togglerfidSuccess() {
    this.setState({
      isrfidSuccess: !this.state.isrfidSuccess
    })
  }


  updateCheckboxSelection(event, item) {
    const allItems = [...this.state.selectedItems];
    const selectedItemIndex = allItems.findIndex(el => el === item);
    $('.error_cpids').text("");
    if (event.target.checked) {
      allItems.push(item);
    } else {
      allItems.splice(selectedItemIndex, 1);
    }
    this.setState({ ...this.state, selectedItems: allItems, disabledbtn: "" });
  }

  // associated 
  aupdateCheckboxSelection(event, item) {
    const deletedWallboxes = [...this.state.deletedWallboxes];
    const selectedItemIndex = deletedWallboxes.findIndex(el => el === item);
    $('.error_cpids').text("");
    if (event.target.checked) {
      deletedWallboxes.splice(selectedItemIndex, 1);
    } else {
      deletedWallboxes.push(item);
    }
    this.setState({ ...this.state, deletedWallboxes, disabledbtn: "" });
  }

  isaItemSelected(item) {
    const retVal = this.state.deletedWallboxes.find(el => el === item) ? false : true;
    return retVal;
  }

  isItemSelected(item) {
    const retVal = this.state.selectedItems.find(el => el === item) ? true : false;
    return retVal;
  }

  //   getting Idtag based on search filter

  idTagChange(idTag) {
    this.state.selectFindStatus = true;

    this.state.saveSelectedIdTag = idTag;
    this.setState({ saveSelectedIdTag: idTag });

    if (idTag.length == 0) {

      this.getOCPPTags(this.state.pageSize, this.state.activePage)
      this.setState({
        dataArray: this.state.dataArray
      });
    } else {
      let errors = {};
      errors["idTagNotExists"] = "";
      //  errors["selectChargepoint"] = "";
      this.setState({
        errors: errors
      });
      // this.onClickFindbtn(idTag);
      this.getOCPPTags(this.state.pageSize, this.state.activePage, this.state.saveSelectedIdTag);

    }

  }

  getIdTags(event) {

    var idTags = event.target.value;
    this.setState({
      idTags: idTags
    })
    if (idTags.length == 0) {
      this.getOCPPTags(this.state.pageSize, this.state.activePage, this.state.saveSelectedIdTag)
      this.setState({
        dataArray: this.state.dataArray,
      });
    }
    var errors = {};
    errors["idTagNotExists"] = "";
    this.setState({ errors: errors });
  }

  backToList() {
    this.props.history.push('/Ocpptags');
  }

  handleChange = date => {
    this.setState({ startDate: date });
    //var tempDate = new Date(date);
    var currentDate = new Date(date);
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var createdDateTo = twoDigitMonth + "-" + twoDigitDate + "-" + currentDate.getFullYear() + ' ' + twohoursDate + ':' + twominDate;
    this.setState({ startDate1: createdDateTo });
  };



  componentDidMount() {
    // permission related code
    localStorage.setItem("noWallboxExists","");
    this.state.user.permissions = localStorage.getItem("roleAccess");

    if (this.state.writePermission == true) {
      this.setState({
        hideActionBtn: "inline-block"
      })
    }
    this.getCustomization();
    this.getSecurityData();

    var timeZone = localStorage.getItem("timezone");
    var id_tag = this.props.location.state;
    // var array = url.split('/');
    this.state.id = id_tag;
    this.getOCppDetails();
    $(".rdt").addClass("cal_disable");
  }

  async getOCppDetails(){
    var url = baseUrl.URLPath + servicesUrl.getOcpptagById + "?id=" + this.state.id;
    document.getElementById("loader_image_div").style.display = "block";

    let type = ApiMethodTypes.GET;
        let headers = getSecurityHeadersWithTimezone();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

        if(response){
          setTimeout(() => {
            ;
            var data1 = [];
            data1["idTag"] = response.idTag;
            if (response.parentIdTag != null) {
              data1["parentIdTag"] = response.parentIdTag.idTag;
            } else {
              data1["parentIdTag"] = "";
            }
            data1["expiryDate"] = response.expiryDate;
            data1["inTransaction"] = response.inTransaction;
            data1["blocked"] = response.blocked;
            data1["note"] = response.note;
            data1["tagType"] = response.tagType;
            this.getlimitationsData(response.idTag);
            this.setState({
              fields: data1,
              // startDate:response.expiryDate
            })
            if(this.state.isFreeChargeEnabled == false){
            this.getRFIDCPlist(true);
            this.getRFIDCPunassociatedlist(true)
            }

            this.setState({
              startDate1: this.state.fields.expiryDate
            })
  
            //this.state.startDate = this.state.fields.expiryDate;
  
            this.setState({ startDate: new Date(this.state.fields.expiryDate) })
  
  
            if (this.state.fields.blocked == "1") {
  
              this.state.fields.blocked = "Yes"
              // alert(this.state.fields.blocked)
              this.setState({
                blocked: this.state.fields.blocked
              })
            } else {
              this.state.fields.blocked = "No"
              this.setState({
                blocked: this.state.fields.blocked
              })
            }
            if (this.state.fields.inTransaction == "1") {
              this.state.fields.inTransaction = "Yes"
              this.setState({
                inTransaction: this.state.fields.inTransaction
              })
            } else {
              this.state.fields.inTransaction = "No"
              this.setState({
                inTransaction: this.state.fields.inTransaction
              })
            }
  
            document.getElementById("loader_image_div").style.display = "none";
  
          }, 5000);
        }
  }

  toggleChange = () => {
    var x = document.getElementById("myCheck").checked;
    if (x) {
      this.state.fields.blocked = "1";
    }
    else {
      this.state.fields.blocked = "0";
    }

  }

  /* To get SecurityData list*/
  async getSecurityData() {

    let url = baseUrl.URLPath + "preferences/security";

    let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if (response != undefined) {
          this.setState({
            isFreeChargeEnabled: response.isFreeChargeEnabled
          })
        }
  }


  transactionChange = () => {

    var y = document.getElementById("transcheck").checked;
    if (y) {
      this.state.fields.inTransaction = "1";
    }
    else {
      this.state.fields.inTransaction = "0";
    }

  }

  redirectToListPage() {
    this.props.history.push('/Ocpptags')
  }

  editTag() {
    this.props.history.push('./editTag');
  }

  // Delete tag 
  async deleteTag() {
    this.setState({
      modal: !this.state.modal
    });
    let errors = {};
    let url = baseUrl.URLPath + "ocpptags/delete/" + this.state.id;
    let type = ApiMethodTypes.DELETE;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, "NULL", headers, this.props.t);

        if(response){
          this.props.history.push('/Ocpptags');
        }
      this.setState({ errors: errors });
  }

  hideLoder() {
    document.getElementById("loader_image_div").style.display = "none";
  }
  async getRFIDCPlist(countValue, cpid, text, status) {
    var cpId = "";
    var afromDateApi = this.state.afromDateApi;
    var atoDateApi = this.state.atoDateApi;
    
    if (cpid != "" && cpid != undefined && cpid != null) {
      cpId = cpid
      this.setState({
        aactivePage: 1,
        apageSize: this.state.apageSize,
        acpId: cpid
      })
      atoDateApi = "";
    }
    if (text == "Clear") {
      this.setState({
        acpId: cpid
      })
    }
    if(status === "Clear"){
      this.setState({
        afromDateApi: afromDateApi,
        atoDateApi: atoDateApi
      })
    }
    document.getElementById("loader_image_div").style.display = "block";
    var errors = {};
    let url = baseUrl.URLPath + servicesUrl.getOcpptagsCPAssociated + "?ocppTagId=" + this.state.fields["idTag"] + "&cpid=" + cpId + "&fromTime=" + afromDateApi + "&toTime=" + atoDateApi + "&pageSize=" + this.state.apageSize;
    
    let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

        if (response !== undefined) {
          var noWallboxExists1 = 1;
          if (cpId !== "" && response.data) {
            localStorage.setItem("noWallboxExists", response.count.count);
          }
  
          if (response.data.length === 0) {
            setTimeout(() => {
              this.setState({
                // associatedCPList: [],
                // anoOfRecords: response.count.count,
              }, () => {
                this.hideLoder();
              });
            }, 100);
          } else {
            var chargePoint;
            chargePoint = Array.from(Object.values(response.data), cp => cp.chargePointId)
            var totalcount = "";
            if (countValue == false) {
              totalcount = this.state.anoOfRecords
            } else {
              totalcount = response.count.count
            }
            setTimeout(() => {
              this.setState({
                anoOfRecords: totalcount,
                noWallboxExists: noWallboxExists1,
                aprevPageFirstCreTime: this.state.curPageFirstCreTime,
                acurPageFirstCreTime: moment(response.data[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                acurPageLastCreTime: moment(response.data[response.data.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                associatedCPList: response.data,
              }, () => {
                this.hideLoder();
              });
            }, 100);
  
  
          }
        }
  }

  async getRFIDCPunassociatedlist(countValue, cpid, text, status) {
    var cpId = "";
    var fromDateApi = this.state.fromDateApi;
    var toDateApi = this.state.toDateApi;
    
    if (cpid != "" && cpid != undefined && cpid != null) {
      cpId = cpid
      this.setState({
        activePage: 1,
        pageSize: this.state.pageSize,
        cpId: cpid
      })
      toDateApi = "";
    }
    if (text == "Clear") {
      this.setState({
        cpId: cpid
      })
    }
    if(status === "Clear"){
      this.setState({
        fromDateApi: fromDateApi,
        toDateApi: toDateApi
      })
    }
    var errors = {};
    document.getElementById("loader_image_div").style.display = "block";

    let url = baseUrl.URLPath + servicesUrl.getOcpptagsCPUnassociated + "?ocppTagId=" + this.state.fields['idTag'] + "&cpid=" + cpId + "&fromTime=" + fromDateApi + "&toTime=" + toDateApi + "&pageSize=" + this.state.pageSize;
    let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

        if (response !== undefined) {
          if (cpId !== "" && response.data.length === 0) {
            localStorage.setItem("noWallboxExists", response.count.count);
            this.hideLoder();
          }
  
          if (response.data.length === 0) {
            setTimeout(() => {
              this.setState({
                // associatedCPList: [],
                // anoOfRecords: response.count.count,
              }, () => {
                this.hideLoder();
              });
            }, 100);
          
          } else {
            var noWallboxExists1 = 1;
            var totalcount = "";
            if (countValue == false) {
              totalcount = this.state.noOfRecords
            } else {
              totalcount = response.count.count
            }
            setTimeout(() => {
              this.setState({
                allCPList: response.data,
                noOfRecords: totalcount,
                noWallboxExists: noWallboxExists1,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                curPageFirstCreTime: moment(response.data[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                curPageLastCreTime: moment(response.data[response.data.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              }, () => {
                this.hideLoder();
              });
            }, 100);
          
          }
        }

  }





  async saveRFIDCP() {

    let url = baseUrl.URLPath + servicesUrl.getOcpptagUpdateCPAssociation + "?ocppTagId=" + this.state.fields['idTag'];
    let data = { "associatedCPList": this.state.selectedItems, "deAssociatedCPList": this.state.deletedWallboxes };

    let valid = true;

    if ((this.state.RfidAccessVal === true && this.state.isFreeChargeEnabled === false) > 0) {
      if ((!this.state.selectedItems.length > 0 && (!this.state.deletedWallboxes.length > 0 && !this.state.associatedCPList.length > 0)) || (!this.state.selectedItems.length > 0 && this.state.associatedCPList.length === this.state.deletedWallboxes.length && this.state.deletedWallboxes.length > 0)) {
        
        $('.error_cpids').text(this.props.t('select_atleast_onecharge_point'));
        valid = false;
      } else {
        valid = true;
        $('.error_cpids').text("");

      }
    }

    if (valid) {
      document.getElementById("loader_image_div").style.display = "block";
      let type = ApiMethodTypes.POST;
        let headers = getSecurityHeaders();
        let payload = data;

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

        if(response){
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            if (response != undefined) {
              this.togglerfidSuccess();
            }
          }, 100);
        }
  }
}


  async getCustomization() {
    let tenant = localStorage.getItem('tenant');
    let url = baseUrl.LoginPath + "/api/admin/v1.0/account/tenant/customization?orgName=" + tenant;
    
    let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};

        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if(response){
          let accessVal = false;
      response.map((operation, index) => {
        if (operation.operation == 'Charging Session') {
          operation.subOperations.map((subOperations, index) => {
            if (subOperations.name == "RFID Access Control") {
              accessVal = subOperations.access;
            }
          })
        }
      })
      this.setState({
        getOperationsList: response,
        RfidAccessVal: accessVal
      })
        }
  }

  handleWallboxChange(e) {
    this.setState({
      wallbox: e.target.value
    }, () => {
      this.clearWallboxerror();
      if (this.state.wallbox.length == 0) {
        this.getRFIDCPlist();
      }
    });
  }

  clearWallboxerror() {
    this.setState({
      noWallboxExists: ''
    })
  }

  FindRfidCp(e) {
    e.preventDefault();

    if (this.state.wallbox == "") {
      this.setState({
        noWallboxExists: "Please enter the charge point."
      })
    } else {
      this.getRFIDCPlist();
      this.setState({
        noWallboxExists: ""
      })
    }
  }

  getIsAllSitesSelected() {
    const { selectedItems, allCPList } = this.state;
    let isAllChecked = false;
    if (allCPList && selectedItems) {
      for (let i = 0; i < allCPList.length; i++) {
        if (selectedItems.some(e => e == allCPList[i]) == true) {
          isAllChecked = true;
        } else {
          isAllChecked = false;
          break;
        }
      }
    } else {
      isAllChecked = false;
    }
    return isAllChecked;
  }

  onUpdateSiteSelectAll(event) {
    document.getElementById("loader_image_div").style.display = "block";
    const isChecked = event;
    $('.error_cpids').text("");
    if (isChecked) {
      var selectedItems = [];
      // this.state.allCPList.map((site,index)=>{
      selectedItems = [...this.state.allCPList];
      // });
      setTimeout(() => {
        this.setState({ selectedItems: selectedItems, disabledbtn: "", deletedWallboxes: [] }, () => {
          document.getElementById("loader_image_div").style.display = "none";
        });
      }, 2000);

    } else {
      var deletedWallboxes = [];
      // this.state.allCPList.map((site,index)=>{
      deletedWallboxes = [...this.state.allCPList];
      // });
      setTimeout(() => {
        this.setState({ selectedItems: [], deletedWallboxes: deletedWallboxes, disabledbtn: "" }, () => {
          document.getElementById("loader_image_div").style.display = "none";
        });
      }, 2000);

    }
  }


  // 
  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
      //prev
      this.setState({
        activePage: pageNumber,
        fromDateApi: this.state.curPageFirstCreTime,
        toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getRFIDCPunassociatedlist(false);
      });
    } else {
      //next
      
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({
        activePage: pageNumber,
        fromDateApi: '',
        toDateApi: this.state.curPageLastCreTime,
        prevPageFirstCreTime: this.state.curPageFirstCreTime,
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.createdDatesArray(this.state.curPageFirstCreTime);
        }
        this.getRFIDCPunassociatedlist(false);
      });
    }
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }

  ahandlePageChange(pageNumber) {
    if (pageNumber < this.state.aactivePage) {
      //prev
      this.setState({
        aactivePage: pageNumber,
        afromDateApi: this.state.acurPageFirstCreTime,
        atoDateApi: this.state.alistOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getRFIDCPlist(false);
      });
    } else {
      //next
      
      let _datesArrayLength = this.state.alistOfFirstCreatedtimes.length;
      this.setState({
        aactivePage: pageNumber,
        afromDateApi: '',
        atoDateApi: this.state.acurPageLastCreTime,
        aprevPageFirstCreTime: this.state.acurPageFirstCreTime,
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.acreatedDatesArray(this.state.acurPageFirstCreTime);
        }
        this.getRFIDCPlist(false);
      });
    }
  }

  acreatedDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.alistOfFirstCreatedtimes, dateTime];
    this.setState({
      alistOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }



  removeSelectedItem(item, index) {
    const allItems = [...this.state.selectedItems];
    allItems.splice(index, 1);
    this.setState({ ...this.state, selectedItems: allItems });
  }

  removeUnSelectedItem(item, index) {
    const allItems = [...this.state.deletedWallboxes];
    allItems.splice(index, 1);
    this.setState({ ...this.state, deletedWallboxes: allItems });
  }

  //show entries change
  ashowEntriesChange() {
    var entries = document.getElementById("ashowEntries").value;
    this.setState({
      aactivePage: 1,
      apageSize: entries,
      afromDateApi: "",
      atoDateApi: ""
    }, () => {
      this.getRFIDCPlist(true, this.state.acpId);
    })
  }

  //show entries change
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.setState({
      activePage: 1,
      pageSize: entries,
      fromDateApi: "",
      toDateApi: ""
    }, () => {
      this.getRFIDCPunassociatedlist(true, this.state.cpId);
    })
  }



  enableLimitations() {	
    var errors = {};
    let tempEnergyLimitvalue = this.state.tempEnergyLimitvalue;
    if(this.state.isEnergyLimitActive === true){ tempEnergyLimitvalue = ''; }
    if(this.state.isEnergyLimitActive === false){  errors['daysLimiterr'] = ''; }
    this.setState({ isEnergyLimitActive: !this.state.isEnergyLimitActive, tempEnergyLimitvalue: tempEnergyLimitvalue !== 0 ? tempEnergyLimitvalue : "" , errors:errors});
  }	
  	
  enablaSessionLimit() {	
    this.setState({ sessionlimit: !this.state.sessionlimit, disabledbtn1: false });	
  }	

  enableLimitationExpiry() {	
    var errors = {};
    let tempExpiryInDaysvalue = this.state.tempExpiryInDaysvalue;
    if(this.state.isExpiryInDaysLimitActive === true){ tempExpiryInDaysvalue = ''; }
    if(this.state.isExpiryInDaysLimitActive === false){  errors['energyLimiterr'] = ''; }
    this.setState({ isExpiryInDaysLimitActive: !this.state.isExpiryInDaysLimitActive, tempExpiryInDaysvalue: tempExpiryInDaysvalue !== 0 ? tempExpiryInDaysvalue : "" , errors:errors });	
  }	
  /* To get SecurityData list*/	
  async getlimitationsData(idTag) {	
    let url = baseUrl.URLPath + servicesUrl.getLimitaionsDetails +"?rfIdTag=" + idTag; 	
    let type = ApiMethodTypes.GET;	
    let headers = getSecurityHeaders();	
    let payload = {};	
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);	
    if (response !== undefined) {	
      if(response.message === "qr_code_data_not_found"){	
          this.setState({
            limitaionsDeta:false
          })	
      }else{	
         this.setState({
              limitaionsDeta:true,	
              expiryInDaysvalue: response.expiryInDays,	
              energyLimitvalue: response.energyLimit,	
              totalEnergyConsumedvalue: response.totalEnergyConsumed,	
              expiryDatevalue: response.expiryDate,	
              energylimit:response.energyLimitActive,
              isEnergyLimitActive: response.energyLimitActive,
              limitexpiry:response.expiryInDaysLimitActive,
              isExpiryInDaysLimitActive: response.expiryInDaysLimitActive,
              limitationStatus:response.status,
              tempExpiryInDaysvalue: response.expiryInDays,
              tempEnergyLimitvalue: response.energyLimit
         })	
      }	
   }	
  }	
   	
  togglelimitationSuccess() {	
    this.setState({	
      limitationSuccess: !this.state.limitationSuccess	
    })	
  }


  // popup code 
  toggleEditLimitations() {
    this.setState({
      modalEditLimitations: !this.state.modalEditLimitations,
      tempEnergyLimitvalue:this.state.energyLimitvalue,
      tempExpiryInDaysvalue:this.state.expiryInDaysvalue
    });
  }

  canceleditToggle(){
    let errors = {};

    this.setState({
      modalEditLimitations: !this.state.modalEditLimitations,
      tempEnergyLimitvalue:this.state.energyLimitvalue,
      tempExpiryInDaysvalue:this.state.expiryInDaysvalue,
      isExpiryInDaysLimitActive:this.state.limitexpiry,
      isEnergyLimitActive:this.state.energylimit,
      errors: errors
    });
  }

  editLimitations() {
    this.setState({ modalEditLimitations: !this.state.modalEditLimitations });
  }

  onChangeLimitEnergyusageActive() {	
    this.setState({
        isLimitEnergyusageActive: !this.state.isLimitEnergyusageActive
    }, () => {	
        if (this.state.isLimitEnergyusageActive) {	
            // this.setState({	
            //   energyLimitvalue: ''	
            // })	
        }	
    })	
}	

onChangeLimitEnergyusageValue(e) {	
    let energyLimit = e.target.value;	
    let errors = {}	
    let error = this.state.errors;	
     if(e.target.validity.valid){
      if (energyLimit === '' || (/^\d*$/.test(energyLimit) && energyLimit >= 1 && energyLimit <= 1000)) {	
        error['energyLimiterr'] = ''	
            this.setState({	
               tempEnergyLimitvalue:energyLimit,	
                errors:error	
            });	
          }	else {	
                  errors["energyLimiterr"] = this.props.t('please_enter_1_to_1000');	
                  this.setState({ errors: errors })
          } 
     }
}

onChangeLimitPeriodOfUseValue(e) {
  let daysLimit = e.target.value;	
    let errors = {}	
    if(e.target.validity.valid){
    if (daysLimit === '' || (/^\d*$/.test(daysLimit) && daysLimit >= 1 && daysLimit <= 1000)) {	
        let error = this.state.errors;	
        error['daysLimiterr'] = ''	
            this.setState({	
              tempExpiryInDaysvalue: daysLimit,	
              errors: error	
            });	
           } else {	
            errors["daysLimiterr"] = this.props.t('please_enter_1_to_1000');	
            this.setState({ errors: errors })	
        }	
      }
}

 handleEnergyLimit (e) {
  let energyLimit = e.target.value;
  if (e.key === "Backspace" && (energyLimit.length === 2 || energyLimit.length === 3) && energyLimit.startsWith("0")) {
    this.setState({
      tempEnergyLimitvalue: energyLimit.slice(0, 1)
    });
  }
  if (e.key === "Delete" && (energyLimit.length === 2 || energyLimit.length === 3) && energyLimit.startsWith("0")) {
    this.setState({
      tempEnergyLimitvalue: energyLimit.slice(1, 2)
    });
  }
};
handlePeriodOfUse (e) {
  let daysLimit = e.target.value;	
  if (e.key === "Backspace" && (daysLimit.length === 2 || daysLimit.length === 3) && daysLimit.startsWith("0")) {
    this.setState({
      tempExpiryInDaysvalue: daysLimit.slice(0, 1)
    });
  }if (e.key === "Delete" && (daysLimit.length === 2 || daysLimit.length === 3) && daysLimit.startsWith("0")) {
    this.setState({
      tempExpiryInDaysvalue: daysLimit.slice(1, 2)
    });
  }
};

saveLimitations(event) {
  event.preventDefault();	
  let errors = { "energyLimiterr" : "", "daysLimiterr": "" }
  var energyLimit = this.state.tempEnergyLimitvalue ?? 0;	
  var daysLimit = this.state.tempExpiryInDaysvalue ?? 0;	
  var energyLimitUsage = this.state.isEnergyLimitActive;
  var periodOfUse = this.state.isExpiryInDaysLimitActive;
  if((energyLimitUsage === true && (energyLimit==="" || energyLimit=== 0)) || (periodOfUse === true && (daysLimit==="" || daysLimit===0))){	
      errors['energyLimiterr'] = ''
      errors["daysLimiterr"] = ''	
      if(energyLimitUsage) {
          if( (energyLimit === "" || energyLimit === 0 ) && energyLimitUsage) {
              errors["energyLimiterr"] = this.props.t('please_enter_energy_limit');
          } else {
            
              this.setState({
                  energyLimitvalue: energyLimit,
                  errors: errors	
              });                
          }
      }
      if(periodOfUse) {
          if((daysLimit === "" || daysLimit === 0) && periodOfUse) {	
              errors["daysLimiterr"] = this.props.t('please_enter_noof_days');
          } else {
              this.setState({
                  dayslimit: daysLimit,
                  errors: errors	
              });                
          }
      }            
  } 
  if(errors["energyLimiterr"] === "" && errors["daysLimiterr"] === "" ){
    this.updateLimitations()
  }
  this.setState({ errors: errors });	
}

async updateLimitations(){	
  var errors = {}
  let url = baseUrl.URLPath + servicesUrl.updateQRcodeLimitaions +"?rfId="+this.state.fields["idTag"];

  let data = {	
    "enableEnergyLimitUsage": this.state.isEnergyLimitActive,	
    "enableExpiryInDays": this.state.isExpiryInDaysLimitActive,	
    "energyLimit": this.state.tempEnergyLimitvalue ? parseFloat(this.state.tempEnergyLimitvalue) : 0,
    "expiryInDays": this.state.tempExpiryInDaysvalue ? parseFloat(this.state.tempExpiryInDaysvalue) : 0
  }

  document.getElementById("loader_image_div").style.display = "block";	
  let type = ApiMethodTypes.PUT;	
  let headers = getSecurityHeaders();	
  let payload = data;	
  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);	
  if (response) {	
    setTimeout(() => {	
      document.getElementById("loader_image_div").style.display = "none";	
      if (response !== undefined) {	
        if(response.status === 404 ||  response.status === 400 || response.status === 403 ){
            if(response.message==="qr_code_is_inactive" ){	
              console.log("QR Code is inactive");
            }else if(response.message==="qr_code_expiry_days_update"){
              errors["daysLimiterr"] = this.props.t('qr_code_expiry_days_update');
            }else if(response.message === "qr_code_energy_update"){
              errors["energyLimiterr"] = this.props.t('qr_code_energy_update');
              // this.props.t('please_enter_noof_days');
            }else if(response.message === "energy_limit_must_be_positive_and_max_value"){
              errors["energyLimiterr"] = this.props.t('energy_limit_must_be_positive_and_max_value');
            }else if(response.message === "expire_in_days_must_be_positive_and_max_value"){
              errors["daysLimiterr"] = this.props.t('expire_in_days_must_be_positive_and_max_value');
            }
            else if(response.message === "group_owner_detail_not_exist" || response.message === "qr_code_status" || response.message === "group_not_exists" || response.message === "qr_code_expired"){
              this.setState({
                alertMsg: this.props.t(response.message)
              })
              this.toggleEditLimitations();
              this.toggleAlert();
            }
            this.setState({
              errors:errors
            })
        }else{
          if( response.message === "qr_code_updated_succussfully"){
            this.toggleEditLimitations();
            this.togglelimitationSuccess();	
          }
        }	
      }	
    }, 100);	
  }	
}

toggleAlert() {
  this.setState({
    alertModel: !this.state.alertModel
  })
}

closetoggleAlert(){
  this.setState({
    alertModel: !this.state.alertModel,
    tempEnergyLimitvalue:this.state.energyLimitvalue,
    tempExpiryInDaysvalue:this.state.expiryInDaysvalue,
    isExpiryInDaysLimitActive:this.state.limitexpiry,
    isEnergyLimitActive:this.state.energylimit
  })
}


  render() {
    const{t}=this.props
    let user = this.state.user;
    const ref = React.createRef();

    var yesterday = Datetime.moment().subtract(1, 'day');
    var valid = function (current) {
      return current.isAfter(yesterday);
    };
    return (
      <>
        <main class="content-div" >
          <p>{t('Authorization')}
            <div className="breadcrumb_div">
              {t('charge_point')} &gt; <Link to="/Ocpptags"><span>{t('Authorization')}</span></Link > &gt; <span className="breadcrumb_page">{t('view')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            {/* breadCrumb */}
            {/* <div className="breadcrumb_div"><a>Charge Point > <Link to="/Ocpptags" > <span className="">OCPP Tags</span></Link> ></a>View</div> */}
            {/* end breadCrumb */}
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button class="pull-right btn_primary" type="button" data-test="button" onClick={this.backToList} >
                    <i className="fas fa-angle-left mr-2"></i>  {t('back')}
                  </button>
                </div>
              </div>
            </div>
            <form
              className='needs-validation'
              onSubmit={this.toggle1}
              noValidate
            >
              <div className="row mb-4 mt-3">
                <div className="col-sm-6">
                  <div className="content-innerdiv">
                    <div className="" >
                      <label>
                        {t('idTag')}:
                      </label>
                      <label >
                        {this.state.fields["idTag"]}
                      </label>
                    </div>

                    <div className="">
                      <label>
                        {t('additional_note')}:
                      </label>
                      <label>
                        {this.state.fields["note"]}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="content-innerdiv">

                    <div className=" ">
                      <label>
                         {t('expiry_date_time')}:
                      </label>
                      <label>
                        {
                          (this.state.startDate1 != null && this.state.startDate1 != undefined && this.state.startDate1 != "") ?
                            <><i className="fas fa-calendar-alt fa-sm mr-1"></i> {moment(this.state.startDate1).format("DD MMM YYYY")} <i className="far fa-clock fa-sm mr-1"></i>{moment(this.state.startDate1).format("HH:mm")}</>
                            : ""
                        }
                      </label>
                    </div>
                    <div className=" ">
                      <label>
                        {t('rfid_tag_type')}:
                      </label>
                      <label>
                        {(this.state.fields.tagType != null && this.state.fields.tagType != "") ? this.state.fields.tagType : "other"}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 text-right">
                  <Link to={{
                    pathname: '/editTag',
                    state: this.state.id,
                  }}>
                    {hasPermission(user, ['OCPPTags_W']) &&
                      <button className="refresh-btn mt-3 mr-1" style={{ "display": this.state.hideActionBtn }} onClick={this.editTag} id="editBtn" type="button" data-test="button">{t('edit')} <i class="fas fa-pencil-alt ml-2"></i></button>
                      //     <MDBBtn color="primary" className="mt-5 mr-3" style={{"display":this.state.hideActionBtn}} onClick={this.editTag}  id="editBtn" >
                      //    Edit
                      // </MDBBtn>
                    }
                  </Link>

                </div>

                {(this.state.limitaionsDeta == true ) > 0 &&	
                <>	
                <div className="col-md-12 mb-3">	
                  <span class=" form-title" style={{ fontWeight: 'bold', fontSize: '15px', marginRight: '20px' }}>{t('limitations')}:</span>	
                </div>               	
                <div className="col-sm-6">	
                  <div className="content-innerdiv">	
                    <div className="" >	
                      <label><b>{t('energylimitation')}: </b>	
                      </label>	
                      <label><b>{ this.state.energylimit === false ? "Unlimited":this.state.energyLimitvalue +" kWh"}</b></label>	
                      {/* {this.state.limitationStatus && <>
                        <label class="switch" style={{float: 'right', marginTop: '15px'}}>	
                          <input type="checkbox" checked={this.state.energylimit} onChange={this.enableLimitations} />	
                          <span class="slider round"></span>	
                        </label>
                      </>
                     } */}
                    </div>	
                    <div className="">	
                      <label><b>{t('used_already')}: </b>	
                      </label>	
                      <label><b>{this.state.totalEnergyConsumedvalue +" kWh"}</b></label>                   	
                    </div>	
                  </div>	
                </div>  
                               	
                <div className="col-sm-6">	
                  <div className="content-innerdiv">	
                    <div className="" >	
                      <label><b>{t('expiry_after_activation')}: </b>	
                      </label>	

                     <label><b>{ this.state.limitexpiry === false ? "Unlimited" : this.state.expiryInDaysvalue +" "+ t('days')}</b> </label>

                      {/* {this.state.limitationStatus && <>
                      <label class="switch" style={{float: 'right', marginTop: '15px'}}>	
                          <input type="checkbox" checked={this.state.limitexpiry} onChange={this.enableLimitationExpiry} />	
                          <span class="slider round"></span>	
                        </label>
                        </>
                       } */}

                    </div>	
                    <div className="">	
                      <label><b>{t('expires_at')}: </b>	
                      </label>	
                      <label><b>{ (this.state.expiryDatevalue && this.state.limitexpiry ) ? moment(this.state.expiryDatevalue).format("DD MMM YYYY") :"" }</b></label>	
                    </div>	
                  </div>	
                </div> 	
                {this.state.limitationStatus && <>
                <div className='col-md-12 mt-2'>	
                  {/* <button type="button" data-test="button" disabled={this.state.disabledbtn1} onClick={this.updateLimitations} className={this.state.disabledbtn1 == true ? "btn_primary mr-1  pull-right btn_cancel" : "btn_primary mr-1 pull-right "} >	
                      {t('save')} <i class="fas fa-save ml-2"></i>	
                  </button>	 */}
                  <button type="button" data-test="button" onClick={this.editLimitations} className="btn_primary mr-1 pull-right">	
                      {t('edit')} <i class="fas fa-pencil-alt ml-2"></i>	
                  </button>
                </div> 
                </>
                }      	
                <div className="col-md-12 mt-1"> 	
                <div className='hauto'>	
                  {/* <div className='row rfidDiv'>	
                    <div className="col-md-4 limitation-div">	
                      <label><b>{t('sessionlimit')}: </b>	
                      </label>	
                      <label><b>20 Sessions</b></label>	
                    </div>	
                    <div className="col-md-4 limitation-div">	
                      <label><b>{t('executed_already')}: </b>	
                      </label>	
                      <label><b>12</b></label>	
                    </div>	
                    <div className="col-md-4">	
                      <div className="">	
                        <label><b>{t('off')}</b></label> &nbsp;	
                        <label class="switch">	
                          <input type="checkbox" checked={!this.state.sessionlimit} onChange={this.enablaSessionLimit} />	
                          <span class="slider round"></span>	
                        </label> &nbsp;	
                        <label><b>{t('on')}</b></label>	
                      </div>	
                    </div>	
                  </div> */}                  	
                  </div>	
                </div>	
                </>	
                }


                {(this.state.RfidAccessVal === true && this.state.isFreeChargeEnabled === false) > 0 &&
                  <>
                    <div className="col-md-12 mb-3">
                      <span class=" form-title">{t('associated_charge_points')}:</span>
                    </div>
                    <div className="col-md-12" >
                      <div className='row'>
                        <div className="col-md-4 mt-1">
                          <CpSearch
                            getData={this.getRFIDCPlist}
                            pagename="associatedRfidTag"
                            reset={this.resetAsyncTimes}
                            id={this.state.fields['idTag']}
                            noWallboxExists={this.state.noWallboxExists}
                            activePage = {this.state.aactivePage}
                          >
                          </CpSearch>
                        </div>

                        <div className="col-md-8">
                          <div className="pull-right">
                            <label className="pull-left sub-text">{t('show_entries')}</label>
                            <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv p-rel cpselectdiv" >
                              <Form.Control as="select" className="showentry_sel custom_selectBox" id="ashowEntries" onChange={this.ashowEntriesChange}>
                                <option>10</option>
                                <option>20</option>
                                <option selected="selected">30</option>
                              </Form.Control>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-1">
                      <div className='content-innerdiv hauto'>
                        <div className='row rfidDiv'>
                          <div className="col-md-9 lightBlue">
                            <label><b>{t('rfid_tag_valid_for_charge_point')}:</b>
                            </label>
                            <div className='row'>
                              {this.state.associatedCPList.map((el, index) => {
                                return (
                                  <div className="col-md-3 p-0">
                                    <Form.Check
                                      className=""
                                      custom
                                      id={`${el.chargePointId}_${index}`}
                                      type="checkbox"
                                      checked={this.isaItemSelected(el.chargePointId)}
                                      onChange={(event) => this.aupdateCheckboxSelection(event, el.chargePointId)}
                                      label={el.chargePointId}
                                      disabled = {this.state.limitaionsDeta}
                                    />
                                  </div>
                                )
                              })
                              }
                            </div>
                          </div>

                          {this.state.deletedWallboxes.length > 0 &&
                            <div className="col-md-3 lightBlue">
                              <label><b>{t('selected_charge_point')}:</b>
                              </label>
                              <div className="row">
                                <div className="col-md-12">
                                  {
                                    this.state.deletedWallboxes.map((el, index) =>
                                      <span key={index} className="cpChargePointSspan">
                                        {el} <i class="fa fa-times" onClick={() => this.removeUnSelectedItem(el, index)}></i>
                                      </span>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3 mb-1 text-right">
                          <Pagination
                            activePage={this.state.aactivePage}
                            itemsCountPerPage={this.state.apageSize}
                            totalItemsCount={this.state.anoOfRecords}
                            onChange={this.ahandlePageChange.bind(this)}
                            prevPageText={t('prev')}
                            nextPageText={t('next')}
                            itemClassPrev="prevBtn"
                            pageRangeDisplayed="1"
                            activeClass="activeLi active"
                            itemClassNext="nextBtn"
                            disableInitialCallback={true}
                            disabledClass="disabled disabledtextColor"
                            hideFirstLastPages={true}
                          />
                        </div>
                      </div>


                    </div>

                    <div className="col-md-12 mb-3 mt-3">
                      <span class=" form-title">{t('charge_points')}:</span>
                    </div>

                    <div className="col-md-12" >
                      <div className='row'>
                        <div className="col-md-4 mt-1">
                          <CpSearch
                            getData={this.getRFIDCPunassociatedlist}
                            pagename="deassociatedRfidTag"
                            id={this.state.fields['idTag']}
                            reset={this.resetTimes}
                            noWallboxExists={this.state.noWallboxExists}
                            activePage = {this.state.activePage}
                          >
                          </CpSearch>
                        </div>

                        <div className="col-md-8">
                          <div className="pull-right">
                            <label className="pull-left sub-text">{t('show_entries')}</label>
                            <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv p-rel cpselectdiv" >
                              <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                                <option>10</option>
                                <option>20</option>
                                <option selected="selected">30</option>
                              </Form.Control>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-1">
                      <div className="mt-3"><span className="error_msg w3-animate-top error_cpids" style={{ color: "red" }}></span></div>
                      <div className='content-innerdiv hauto'>
                        <div className='row rfidDiv'>
                          <div className="col-md-9 lightBlue">
                            <label><b>{t('charge_point')}:</b>
                            </label>
                            <div className="row">
                              {this.state.allCPList.map((el, index) => {
                                return (
                                  <div className="col-md-3 p-0">
                                    <Form.Check
                                      className=""
                                      custom
                                      id={`${el.chargePointId}_${index}`}
                                      type="checkbox"
                                      checked={this.isItemSelected(el.chargePointId)}
                                      onChange={(event) => this.updateCheckboxSelection(event, el.chargePointId)}
                                      label={el.chargePointId}

                                    />
                                  </div>
                                )
                              })
                              }
                            </div>
                          </div>
                          {this.state.selectedItems.length > 0 &&
                            <div className="col-md-3 lightBlue">
                              <label>
                                {/* <b>Selected Charge Point:</b> */}
                                <b>{t('rfid_tag_valid_for_charge_point')}:</b>
                              </label>
                              <div className="row">
                                <div className="col-md-12">
                                  {
                                    this.state.selectedItems.map((el, index) =>
                                      <span key={index} className="cpChargePointSspan">
                                        {el} <i class="fa fa-times" onClick={() => this.removeSelectedItem(el, index)}></i>
                                      </span>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3 mb-1 text-right">
                          <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.pageSize}
                            totalItemsCount={this.state.noOfRecords}
                            onChange={this.handlePageChange.bind(this)}
                            prevPageText="Prev"
                            nextPageText="Next"
                            itemClassPrev="prevBtn"
                            pageRangeDisplayed="1"
                            activeClass="activeLi active"
                            itemClassNext="nextBtn"
                            disableInitialCallback={true}
                            disabledClass="disabled disabledtextColor"
                            hideFirstLastPages={true}
                          />
                        </div>
                      </div>


                    </div>

                    <div className='col-md-12 mt-2'>
                      <button type="button" data-test="button" disabled={this.state.disabledbtn} onClick={this.saveRFIDCP.bind(this)} className={this.state.disabledbtn == "true" ? "btn_primary mr-1  pull-right btn_cancel" : "btn_primary mr-1 pull-right "} >
                        {t('save')} <i class="fas fa-save ml-2"></i>
                      </button>
                    </div>
                  </>
                }


              </div>

            </form>

          </div>
          {/* Loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>
          <MDBModal isOpen={this.state.isrfidSuccess} toggle={this.togglerfidSuccess} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglerfidSuccess}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('rfid_association_charge_points_updated_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button className="btn_primary" data-test="button" type="button" onClick={() => { window.location.reload(); }}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.limitationSuccess} toggle={this.togglelimitationSuccess} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglelimitationSuccess}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('Limitations value updated successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button className="btn_primary" data-test="button" type="button" onClick={() => { window.location.reload(); }}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* warning popup */}
           
            <CustomModal
                open={this.state.alertModel}
                title={t('Warning')}
                bodyMessage={this.state.alertMsg}
                PrimaryButtonText={t('close')}
                PrimaryButtonOperation={this.closetoggleAlert}
            />
         
          {/* edit limitaions popup */}
          <MDBModal isOpen={this.state.modalEditLimitations} toggle={this.toggleEditLimitations} className="model_top">
              <MDBModalHeader toggle={this.toggleEditLimitations}><label class="fs-16"><b>{t('Edit Limitations')}</b></label></MDBModalHeader>
                <MDBModalBody>
                        <div className="mt-2 row">
                            <label className="pl-3 pr-3">{t('setup_qr_code_text2')}</label>
                        </div>
                        <div className="mt-2 row energyusage">
                            <div className="col-md-6">
                                <label style={{ fontWeight: 'bold', fontSize: '15px', marginRight: '20px' }}>{t('limit_energyusage')}</label>
                            </div>

                            <div className="col-md-6">
                                <label class="switch" style={{ float: 'right' }}>
                                    <input type="checkbox" checked={this.state.isEnergyLimitActive} onChange={this.enableLimitations} />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div className="mb-2 row pl-3 pr-3">
                                <label className="pl-3 pr-3">{t('energy_is_limited_to_defined_amount')}</label><br />
                                <MDBCol md="5" >
                                    <label className="pull-left sel_lbl">{t('energylimitation')} :</label>
                                </MDBCol>
                                <MDBCol md="5" >
                                    <MDBInput
                                        type="text"
                                        name="energylimit"
                                        id="energyLimit"
                                        value={this.state.tempEnergyLimitvalue}
                                        disabled={!this.state.isEnergyLimitActive}
                                        onChange={this.onChangeLimitEnergyusageValue}
                                        onKeyDown={this.handleEnergyLimit}
                                        placeholder={t('please_enter_energy_limit')}
                                        pattern="[0-9]*"
                                    >
                                        <p style={{ color: '#00529e', fontWeight: 'bold', position: "absolute", top: "10px", right: "-35px" }}>{t('kwh')}</p>
                                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["energyLimiterr"]}</span></span>
                                    </MDBInput>
                                </MDBCol>
                            </div>
                        </div>

                        <div className="mt-2 row energyusage">
                            <div className="col-md-6">
                                <label style={{ fontWeight: 'bold', fontSize: '15px', marginRight: '20px' }}>{t('limit_periodofuse')}</label>
                            </div>

                            <div className="col-md-6">
                                <label class="switch" style={{ float: 'right' }}>
                                    <input type="checkbox" checked={this.state.isExpiryInDaysLimitActive} onChange={this.enableLimitationExpiry} />
                                    <span class="slider round"></span>
                                </label>
                            </div>

                            <div className="mb-2 row pl-3 pr-3">
                                <label className="pl-3 pr-3">{t('qrcode_is_valid_for_period_of_days')}</label><br />
                                <MDBCol md="5" >
                                    <label className="pull-left sel_lbl">{t('periodofuse')} :</label>
                                </MDBCol>
                                <MDBCol md="5">
                                    <MDBInput
                                        type="text"
                                        name="dayslimit"
                                        id="daysLimit"
                                        value={this.state.tempExpiryInDaysvalue}
                                        disabled={!this.state.isExpiryInDaysLimitActive}
                                        onChange={this.onChangeLimitPeriodOfUseValue}                                        
                                        onKeyDown={this.handlePeriodOfUse}
                                        placeholder={t('please_enter_noof_days')}
                                        pattern="[0-9]*"
                                    >
                                        <p style={{ color: '#00529e', fontWeight: 'bold', position: "absolute", top: "10px", right: "-45px" }}>{t('days')}</p>
                                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["daysLimiterr"]}</span></span>
                                    </MDBInput>
                                </MDBCol>
                            </div>
                        </div>
                  </MDBModalBody>
                  <MDBModalFooter>
                        <button className="btn_cancel mr-2 ml-2" onClick={this.canceleditToggle} type="button" data-test="button"> {t('cancel')} <i class="fas fa-times ml-2"></i></button>
                        <button className="btn_primary mr-4 ml-2" onClick={this.saveLimitations} type="button" data-test="button">{t('save')}</button>
                  </MDBModalFooter>
            </MDBModal>

        </main>
      </>
    );
  }
}

export default withTranslation()(Ocppnewtag);




