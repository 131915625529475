import * as baseUrl from './constants';

//platform user apis
export const GET_USERS = baseUrl.URLPath + "platform/user";
export const ACTIVATE_USER = baseUrl.URLPath + "platform/user/activate?userId=";
export const DEACTIVATE_USER = baseUrl.URLPath + "platform/user/deactivate?userId=";
export const GET_USER_DETAILS = baseUrl.URLPath + "platform/user/findbyuserId?userId=";
export const DELETE_USER = baseUrl.URLPath + "platform/user?userId=";
export const UPDATE_USER = baseUrl.URLPath + "platform/user?userId=";
export const CREATE_USER = baseUrl.URLPath + "platform/user";
export const CREATE_USER_SERVICEPARTNER = baseUrl.URLPath + "platform/user/servicepartner";