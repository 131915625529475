import React from 'react';
import { withTranslation } from 'react-i18next';
class Information extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false,
        }
        
        this.backButton = this.backButton.bind(this)
    }


    componentDidMount() {
        
    }

    backButton() {
        this.props.setPage('selectCharger');
        localStorage.setItem('page', 'selectCharger');
    }

    render() {
        const { t } = this.props;
        return (
            <>
               <div className="welcome-parent bg_white">
                  <div className="container-fluid">
                         <nav className="nav_bar d-inline-flex w-100">
                            <div className='info-title info_cont  d-inline-flex pl-0 pr-0'>{t('Information')}
                                <div className="info-close" onClick={this.backButton}>
                                    <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/close.svg"} style={{ width: "20px" }} alt="close-icon" />
                                </div>
                            </div>
                        </nav>
                        <div className='row justify-content-center '>
                            <div className='col-md-12 welcome-div pl-0 pr-0'>
                            <div className='card-body'>
                                <div className='card-text welcome_cardtxt pt-2' style={{borderTop: 'none'}}>
                                    <p>{t('You_started_guestvoucher_using_shared_chargingstations')}</p>
                                    <p>{t('Charging_stations_with_indication')}  '<span className='status-available'> {t('available')}</span>' {t('can_be_used')}</p>
                                    <div className='d-inline-flex pb-2'><div><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/point_arrows.svg"} className="d-inline-block point_arrows" /> </div> {t('You_justneed_select_available_chargingstation')} </div>
                                    <div className='d-inline-flex pb-2'><div><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/point_arrows.svg"} className="d-inline-block point_arrows" /></div>  {t('Connect_yourvehicle_correctly_appropriate_charging_station')} </div>
                                    <div className='d-inline-flex pb-3'><div><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/point_arrows.svg"} className="d-inline-block point_arrows" /> </div> {t('Finally_click_Start_charging_button')} </div>
                                    <p>{t('You_can_use_this_voucher_until_limits_areused_up')}</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }
}

export default withTranslation()(Information);