import React from 'react';
import { MDBBtn,MDBRow,MDBCol, MDBInput, MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter } from 'mdbreact';
import apiCallUtility from '../common/APICallUtility';
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import * as baseUrl from './constants';
import { createBrowserHistory } from 'history';
import servicesUrl from '../common/servicesUrl';

import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();

class sendLocalList extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          cpList:[],
          tagList:[],
          fields:{
            listVersion: "",
          },
            tagId:"",
            updateType:"",
            sucess:false,
            colour:'sucess',
            page_title:"Send Local List",
            errors:{},
            modalsession:false,
            chargePoint:'',
          operationId:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.idTagChange=this.idTagChange.bind(this);
        this.onChangeUpdateType=this.onChangeUpdateType.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);
        this.togglesession = this.togglesession.bind(this);
}

onShowAlert(){
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}

async togglesession(){
  this.setState({
  modalsession: !this.state.modalsession
  });
}

sessiontagOk(){
  history.push('/');
  window.location.reload();
}

//This is for making user should enter only numbers
numberHandler(field, e){ 
    let fields = this.state;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }else if(e.target.value===""){
      fields[field] = e.target.value; 
      this.setState({fields});
    }
  }

  async handleSubmit() {
    var payload = this.getPayLoad();
    var listVersion = payload.listVersion;
    var updateType = payload.updateType;
    var errors = {};
    if (this.getCPList().length === 0) {
      errors["emptyChargePoint"] = this.props.t('select_charge_point')
    }
    if (isNaN(listVersion)) {
      errors["emptylistversion"] = this.props.t('enter_list_version')
    }
    if (payload.updateType === '' || payload.updateType === this.props.t('update_type_select')) {
      errors["emptyupdatetype"] = this.props.t('select_update_type')
    }
    if (this.getCPList().length !== 0 && !isNaN(listVersion) && updateType !== '' && updateType !== this.props.t('update_type_select')) {
      var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList(), this.props.t);

      if (response === this.props.t('unable_toprocess_request_try_after_some_time')) {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, () => {
          this.onShowAlert();
        })
      } else if (response === 'Request is processed Successfully') {
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, () => {
          this.onShowAlert();
        })
      }
    }
    this.setState({ errors: errors })
  }

  getCPList() {
    var Id = this.state.operationId;
    var cplist="";
    if (Id !== null && Id !== "") {
      cplist = Id.split();
    } else {
      cplist = this.state.cpList;
    }
    return cplist;
  }
  
  onChangeUpdateType(){
    var x=document.getElementById("updateType")?.value;
    let errors = this.state.errors;
    if(x!==this.props.t('update_type_select')){
      errors["emptyupdatetype"]="";
    }

    this.setState({updateType: x, errors});
  }

  getPayLoad() {
    var payload = {
      "listVersion": parseInt(this.state.fields.listVersion),
      "updateType": this.state.updateType
    };
   
    return payload;
  }
  
  getMessageType() {
    return constants.SEND_LOCAL_LIST;
  }

  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata !== undefined) {
      if (propsdata.chargePoint !== undefined && propsdata.operationId !== undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        }, () => {
          this.getSelectedCP(this.state.operationId)
        });
      }
    }

    // var url = constants.URLPath + "ocpptags"

    // fetch(url, {
    //   method: "GET",
    //   headers: {
    //     "content-type": "application/json",
    //     "Authorization": localStorage.getItem("Authorization")
    //   }
    // })
    //   .then((resp) => {
    //     if (resp.status === 401) {
    //       window.location.reload();
    //     } else if (resp.status === 200) {
    //       return resp.json();
    //     }
    //   })
    //   .then((response) => {
    //     if (response !== undefined) {
    //       this.setState({ tagList: response.data }, ()=>{
    //         this.setSelectedStatus();
    //       })
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error, "catch the loop")
    //   })
  }

  getSelectedCP(cpList){
    let errors = this.state.errors;
    if(cpList!==""){
      errors["emptyChargePoint"]="";     
    }

    this.setState({cpList:cpList, errors: errors});

    var url = baseUrl.URLPath +servicesUrl.getChargePointlocalListversion+ "?cpId="+cpList;

    fetch(url,{
      method: "GET",
      headers: {
       "content-type":"application/json",
       "Authorization":localStorage.getItem("Authorization")
      }})
      .then((resp) => {
        if(resp.status===401){
          window.location.reload();
        } else {
          return resp.json();
        }
      }) 
      .then((response) =>{
        if(response.listVersion===null){
            response.listVersion='0'
        }

        let fields = this.state.fields;
        fields.listVersion = response.listVersion;

        this.setState({ fields })
    })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
  }

  idTagChange() {
    let value = document.getElementById("tagList").value;
    this.setState({ tagId: value });
  }

  componentWillUnmount() {
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <main className="content-div" >
          <p>{t('Send Local List')}
            <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page">{t('Send Local List')}</span>
            </div>
          </p>

          <div className="page-outerdiv">
            <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
            <MDBRow className="mb-4">
              <MDBCol sm="12">
                <div>
                  <form
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>
                      <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="SendLocalList" ></ChargePointSelection>
                      <MDBCol md="12">
                        <span className="selection_charge_point_error w3-animate-top">
                          <span style={{ color: "red" }}>{this.state.errors["emptyChargePoint"]}</span>
                        </span>
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.fields.listVersion}
                          name="listVersion"
                          onInput={this.numberHandler.bind(this, "listVersion")}
                          type="text" pattern="[0-9]*"
                          id="listVersionId"
                          label={t('list_version_integer') + " *"}
                          disabled="true"
                          required
                        >
                          <span className="error_msg w3-animate-top">
                            <span style={{ color: "red" }}>{this.state.errors["emptylistversion"]}</span>
                          </span>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4">
                        <select className="browser-default custom-select select_height mb_8" id="updateType" onChange={this.onChangeUpdateType}>
                          <option>{t('update_type') + " *"}</option>
                          <option value="Full">{t('full')}</option>
                          <option value="Differential">{t('differential')}</option>
                        </select>
                        <span className="error_msg w3-animate-top">
                          <span style={{ color: "red" }}>{this.state.errors["emptyupdatetype"]}</span>
                        </span>
                      </MDBCol>
                    </MDBRow>
                    <button className="mt-5 refresh-btn" type="button" data-test="button" onClick={this.handleSubmit}>{t('perform')}</button>
                  </form>
                </div>
              </MDBCol>
            </MDBRow>
          </div>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}

export default withTranslation()(sendLocalList);