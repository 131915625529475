import React from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import * as baseUrl from './constants';
import { withTranslation } from 'react-i18next';
import Loader from './loader';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

class settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { configValue: '', configName: '' },
      preData: "",
      errors: {},
      model: "false",
      expiration: "2020-01-23 16:34:23",
      protocol: "ocpp 1.6",
      host: "webasto.com",
      port: "8081",
      from: "2020-01-23 16:34:24",
      recipients: "user",
      page_title: "OCPP",
      disabledAttr: true,
      modal: false,
      isLoading: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.changeHandlerToCheckEmpty = this.changeHandlerToCheckEmpty.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  changeHandlerToCheckEmpty(field, e) {
    if (e.target.value === "" || this.state.preData === e.target.value) {
      this.setState({
        disabledAttr: true,
      });
    } else {
      if (e.target.value !== "") {
        this.setState({
          disabledAttr: "",
        });
      }
    }
  }

  toggle = () => {
    this.setState({
        modal: !this.state.modal
      }, () => {
        window.location.reload();
      });
  }

  changeHandler(field, e) {
    var errors = {};
    let fields = this.state.fields;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      errors['hbempty'] = "";
      this.setState({ fields, disabledAttr: "", errors: errors });
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  async getConfig() {
    var url = baseUrl.URLPath + "configurations";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response) {
      if (response !== undefined) {
        this.setState({
          fields: response.data,
          preData: response.data.configValue
        })
      }
    }
  }

  componentDidMount() {
    this.getConfig();
  }

  async handleSubmit(event) {
    this.setState({ isLoading: true });
    event.preventDefault();
    const value = parseInt(this.state.fields["configValue"]);
    const minValue = parseInt(baseUrl.OcppHeartbeatMinValue);
    const maxValue = parseInt(baseUrl.OcppHeartbeatMaxValue);
    var errors = {};
    if (isNaN(value) || (value < minValue || value > maxValue)) {
      if (value < minValue) {
        errors['hbempty'] = this.props.t('heartbeat_min_value') + " " + minValue + ".";
      } else if (value > maxValue) {
        errors['hbempty'] = this.props.t('heartbeat_max_value') + " " + maxValue + ".";
      }
      this.setState({
        errors: errors
      })
    } else {
      errors['hbempty'] = "";
      let url = baseUrl.URLPath + "configurations"
      let data = { "configValue": this.state.fields.configValue, "configName": "HEARTBEAT_INTERVAL" };
      let type = ApiMethodTypes.PUT;
      let headers = getSecurityHeaders();
      let payload = data;
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if (response) {
        if (response.status === 400) {
          this.setState({ isLoading: false })
          if (response.message === "heartbeat_max_value") {
            errors['hbempty'] = this.props.t('heartbeat_max_value') + " " + maxValue + ".";
          } else if (response.message === "heartbeat_min_value") {
            errors['hbempty'] = this.props.t('heartbeat_min_value') + " " + minValue + ".";
          }
        } else if (response.message === "setting_is_updated_successfuly") {
          this.setState({ isLoading: false,
            modal: true })
        }
        this.setState({
          errors: errors
        })
      }
    }
  }

  render() {
    const { t } = this.props
    return (
      <main class="content-div" >
        <p>{t('OCPP')}
          <div className="breadcrumb_div">
            {t('Settings')} &gt; <span className="breadcrumb_page"> {t('OCPP')}</span>
          </div>
        </p>
        <div className="page-outerdiv">
          <MDBRow className="mb-4 mt-3">
            <MDBCol sm="12">
              <form className="needs-validation">
                <MDBRow>
                  <MDBCol md="4">
                    <MDBInput
                      name="chargebox"
                      oldData={this.state.preData}
                      value={this.state.fields["configValue"]}
                      onChange={this.changeHandler.bind(this, "configValue")}
                      onBlur={this.changeHandlerToCheckEmpty.bind(this, "configValue")}
                      type="text"
                      id="heartbeatInterval"
                      label={t('heartbeat_interval')}
                      pattern="[0-9]*"
                      required
                    >
                      <span className="error_msg w3-animate-top hbempty ml-0" style={{ color: "red" }}>{this.state.errors["hbempty"]}</span>
                      <small id="heartbeatinterval" className="form-text text-muted">
                        {t('heartbeat_interval_input_desc')}
                      </small>
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                </MDBRow>
                <button type="button" className="mt-5 refresh-btn" disabled={this.state.disabledAttr} onClick={this.handleSubmit}>{t('update')}</button>
              </form>
            </MDBCol>
          </MDBRow>
        </div>

        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('heartbeat_interval_been_updated')}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.toggle}>{t('ok')}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        {this.state.isLoading ? (
        <Loader loader={this.state.isLoading} />
        ) : ("")}
      </main>
    );
  }
}

//export default settings;
export default withTranslation()(settings);