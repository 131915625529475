import React from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { Link } from 'react-router-dom';

//API Imports
import * as baseUrl from './constants';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getAuthorization, getSecurityHeaders } from '../common/HttpRequestClass';

class bulkImport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: { organisation: '' },
            errors: {},
            docFile: "",
            identifier: "",
            downloadLink: "",
            modal: false,
            modal1: false,
            modelTile: "",
            modelBody: "",
            selectedFile: "",
            downloadUrl: "",
            dataArray: []
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.startDownload = this.startDownload.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getTemplate = this.getTemplate.bind(this);
        this.getUplodedTemplate = this.getUplodedTemplate.bind(this);
    }

    // functions call when click on download button for downloading status report
    async startDownload() {
        document.getElementById("loader_image_div").style.display = "block";
        this.setState({ 
            modal: !this.state.modal
        });

        var errors = {};
        var Url1 = baseUrl.URLPath + "chargepoint/bulkupload";
        var data = new FormData();
        data.append("file", this.state.docFile);
        data.append("tenantId", this.state.identifier)

        let type = ApiMethodTypes.POST;
        let headers = getAuthorization();

        let response = await APICallUtility.cpmsAPIFetch(Url1, type, data, headers, this.props.t, true);

        if (response.status === 400) {
            document.getElementById("loader_image_div").style.display = "none";
            errors["wrongFile"] = "There is a problem in processing the file.";
            this.setState({
                errors: errors
            });
        } else {
            if (response.status === "FAILURE") {
                document.getElementById("loader_image_div").style.display = "none";
                errors["choosefile"] = response.errorMessage;
                this.setState({
                    errors: errors
                });
            } else if (response.status === "SUCCESS") {
                setTimeout(() => {
                    document.getElementById("loader_image_div").style.display = "none";
                    this.setState({
                        modal1: !this.state.modal1,
                    });
                }, 2000)
                this.setState({
                    downloadLink: response.downloadLink
                })
            } else {
                document.getElementById("loader_image_div").style.display = "none";
                errors["choosefile"] = "There is a problem in processing the file.";
                this.setState({
                    errors: errors
                });
            }
        }
    }

    handleChange(e) {
        var imagedata = document.querySelector('input[type="file"]').files[0];
        this.setState({
            docFile: imagedata
        })
    }

    //toggle (show and hide) confirmation popup
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    //toggle (show and hide) success popup 
    toggleSuccess = (text) => {
        if(text==="Download"){
            this.getUplodedTemplate();
         }
        this.setState({
            modal1: !this.state.modal1
        });
        this.props.history.push('/chargePoint')
       
    }

    // download uploded template 
    async getUplodedTemplate() {
        var url = baseUrl.URLPath + "chargepoint/download";
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false, true);
        if (response !== undefined) {
            let url = window.URL.createObjectURL(response);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'Charge-Point-Onboard-Template.xlsx';
            a.click();
        }
    }

    //when click on save button
    handleSubmit(event) {
        var errors = {};
        if (this.state.docFile !== "") {
            event.preventDefault()
            errors["choosefile"] = "";
            this.setState({ errors: errors });
        } else {
            errors["choosefile"] = "Please choose a file.";
            this.setState({ errors: errors });
        }

        if (this.state.docFile !== "") {
            this.setState({
                modal: !this.state.modal
            });
        }
    }

    UNSAFE_componentWillMount() {
        var the_arr = baseUrl.URLPath.split('/');
        the_arr.pop();
        the_arr.pop();
        var newlink = the_arr.join('/') + "/";
        this.setState({
            downloadUrl: newlink
        })
    }

    async getTemplate() {
        var url = baseUrl.URLPath + "chargepoint/download/template";
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {};
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false, true);
        if (response !== undefined) {
            let url = window.URL.createObjectURL(response);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'Charge-Point-Onboard-Template.xlsx';
            a.click();
        }
      }

    render() {
        return (
            <>
                <main className="content-div" >
                    <div className="page-outerdiv">
                        <div className="breadcrumb_div">Charge Point {'>'} <Link to="/chargePoint" > <span className="">Overview</span></Link> {'>'} Bulk Import</div>
                        <form className='needs-validation'>
                            <MDBCol sm="12" >
                                <table id="table-to-xlsx" className="excel_visi">
                                    <thead>
                                        <th>
                                            ChargePoint Id*
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th>HouseNO</th>
                                        <th>Street</th>
                                        <th>Zipcode</th>
                                        <th>City</th>
                                        <th>Country</th>
                                        <th>Comments</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* </tableToExcel> */}
                            </MDBCol>
                            <MDBRow className="mb-4 mt-3" >
                                <MDBCol sm="12" >
                                    <div class="md-form input-group mb-0" >
                                            <div className="fa fa-download download_input cursor-pointer" onClick={this.getTemplate.bind(this)}>
                                            </div>
                                        <div class="input-group-append">
                                            <span class="input-group-text md-addon">Download Template</span>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol sm="6" className="mt-5" >
                                    <input type="file" accept=".xls,.xlsx" name="file" id="exampleFile" onChange={this.handleChange.bind(this)} />
                                    <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["choosefile"]}</span></span>
                                    <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["wrongFile"]}</span></span>
                                    <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["internalServer"]}</span></span>

                                </MDBCol>
                                <MDBCol sm="3" className="">
                                    <button type="button" className="btn_primary mt-5" onClick={this.handleSubmit}>Upload</button>
                                </MDBCol>
                            </MDBRow >
                        </form>
                    </div>
                </main>

                <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>

                <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggle}>Confirmation</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure, do you want to start import ?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={this.toggle} >Cancel</MDBBtn>
                        <MDBBtn color="primary" onClick={this.startDownload} >Start</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.modal1} toggle={this.toggleSuccess} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggleSuccess}>Success</MDBModalHeader>
                    <MDBModalBody>
                        Bulk import completed succesfully. Please select download button to downlaod the results.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={this.toggleSuccess.bind(this,"Download")} >Download
                        </MDBBtn>
                        <MDBBtn color="primary" onClick={this.toggleSuccess.bind(this,"ok")} >Ok</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </>
        )
    }
}

export default bulkImport;