import React from 'react';
import { MDBCol, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import { Link } from 'react-router-dom';
import $ from "jquery";
import { createBrowserHistory } from 'history'
import Pagination from "react-js-pagination";
import { hasPermission } from './auth';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import 'moment-timezone';
import servicesUrl from '../common/servicesUrl';
import { withTranslation } from 'react-i18next';
import Loader from '../views/loader';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders, getSecurityHeadersWithTimezoneLocationBased } from '../common/HttpRequestClass';

const history = createBrowserHistory();


class inventoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onSelectFindbtnStatus : false,
   
      onClickCheckBox : false,
      cpIdSorting: false,
      delete_msg: "",
      deleteHeader: "",
      deleteButton: "",
      deleteText: "",
      deletecolor: "danger",
      selectFindStatus: false,
      saveSelectedCpid: "",
      inventoryDisplay: "",
      enableInventory: "true",
      saveSno: "",
      timeoutdisplay: "none",
      timeoutdisplayno: "none",
      buttonacessdisplay: "none",
      e_mail: [],
      commercial_Users: [],
      items: [],
      cp_id: [],
      intial_data: [],
      getList: [],
      options: [],
      multiple: false,
      hideActionBtn: "none", // show hide btn varible based on permission 
      writePermission: this.props.ChargePoint_W, // ChargePoint_W conndition
      model: false,
      startDate: new Date(),
      pageNo: 1,
      list: [],
      list2: [],
      activeArray2: [],
      timeOutArray: [],
      selectDropdown: "none",
      modalView: false,
      modalInventory: false,

      dataArray: [

      ],
      orgArray: [],
      modal2: false,
      page_title: "Inventory",
      org_name: "",
      orgName: "",
      userName: "",
      fields: {
        materialNo:"",
        mesSerialNo:"",
        modelName:"",
        productName: "",
        description: "",
        buildType: "",
        productCode: "",
        serialnumber: "",
        siteAdmin: "",
        commercialUser: ""
      },
      orgId: "",
      sucess: false,
      colour: 'sucess',
      message: "",
      errors: {},
      noOfRecords: '',
      activePage: 1,
      data: new Map(),
      pageSize: "10",
      identifier: "",
      modalsession: false,
      activeArray: [],
      buttondisplay: "",
      reassigndisplay: "none",
      modal3: false,
      modalAdmin: false,
      user: {
        permissions: [

        ],
      },
      loading: false



    }

    this.getInventoryBasedOnorg = this.getInventoryBasedOnorg.bind(this);
    // this.onShowAlert=this.onShowAlert.bind(this);
    // this.sessiontoggle = this.sessiontoggle.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    // this.getPaginationcount = this.getPaginationcount.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.bulkImport = this.bulkImport.bind(this);
    this.getInventorybasedonchargepoint = this.getInventorybasedonchargepoint.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.saveChargePoint = this.saveChargePoint.bind(this);
    this.reAssignChargePoint = this.reAssignChargePoint.bind(this);
    this.organizationChange = this.organizationChange.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.admintoggle = this.admintoggle.bind(this);
    this.warningModelOk = this.warningModelOk.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggleadminsuccess = this.toggleadminsuccess.bind(this);
    this.tagOk1 = this.tagOk1.bind(this);
    this.releaseButton = this.releaseButton.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.deleteSucesstoggle = this.deleteSucesstoggle.bind(this);
    this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.selectedCpChange = this.selectedCpChange.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
    // this.onBlurEvent = this.onBlurEvent.bind(this);
    this.ClearCpName = this.ClearCpName.bind(this);
    // this.ClearCpNames = this.ClearCpNames.bind(this);
    this.confirmtoggle = this.confirmtoggle.bind(this);
    this.releasetoggle = this.releasetoggle.bind(this);
    this.deletetoggle = this.deletetoggle.bind(this);
    this.getSiteAdminList = this.getSiteAdminList.bind(this);
    this.siteAdminChange = this.siteAdminChange.bind(this);
    this.assignSiteAdmin = this.assignSiteAdmin.bind(this);
    this.siteadmintoggle = this.siteadmintoggle.bind(this);
    this.getCommercialUsers = this.getCommercialUsers.bind(this);
    this.commercialUserChange = this.commercialUserChange.bind(this);
    this.assignCommercialUsers = this.assignCommercialUsers.bind(this);
    this.commercialusertoggle = this.commercialusertoggle.bind(this);

    this.deleteInventory = this.deleteInventory.bind(this);
    this.displayAccessCode = this.displayAccessCode.bind(this);
    this.exportInventoryCSV = this.exportInventoryCSV.bind(this);
    this.getTimeOutHinder = this.getTimeOutHinder.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.getReleaseData = this.getReleaseData.bind(this);
    this.toggleAuthentication = this.toggleAuthentication.bind(this);
    this.CloseSuccessauthentication = this.CloseSuccessauthentication.bind(this);
    this.toggleView1 = this.toggleView1.bind(this);
    this.deletetoggle1 = this.deletetoggle1.bind(this);
    this.releaseWarningtoggle = this.releaseWarningtoggle.bind(this);
    this.changeModelName = this.changeModelName.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.onChangeMesSno = this.onChangeMesSno.bind(this);
    this.onChangeModeName = this.onChangeModeName.bind(this);
    this.onChangeMatno = this.onChangeMatno.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
  }

  //change function for mes status filter
  onChangeMesSno(serialNumber) {
    var mes = document.getElementById("mesSerialNo").value;
    let errors = this.state.errors;

    this.setState({ mesSerialNo: mes }, () => {
      this.getInventoryBasedOnorg(10, 1);
    });

    if (this.state.fields.mesSerialNo === "") {
      errors["mesSnoEmpty"] = this.props.t('enter_mes_serial_no');

      this.setState({ errors });
    } else {
      errors["mesSnoEmpty"] = "";

      if (this.state.list2 !== "") {
        this.setState({
          activeArray2: [],
          list2: []
        });
      }

      this.setState({ errors });

      $("input[type='checkbox']").prop("checked", false);
    }
  }

  onChangeMatno() {
    var matNo = document.getElementById("materialNo").value;
    let errors = this.state.errors;

    this.setState({ materialNo: matNo }, () => {
      this.getInventoryBasedOnorg(this.state.pageSize, 1);
    });

    if (this.state.fields.materialNo === "") {
      errors["matnoEmpty"] = this.props.t('enter_material_no');

      this.setState({ errors });
    } else {
      errors["matnoEmpty"] = "";

      if (this.state.list2 !== "") {
        this.setState({
          activeArray2: [],
          list2: []
        });
      }

      this.setState({ errors });
      $("input[type='checkbox']").prop("checked", false);
    }
  }

  onChangeModeName() {
    let errors = this.state.errors;
    var model = document.getElementById("modelName").value;

    this.setState({ modelName: model }, () => {
      this.getInventoryBasedOnorg(this.state.pageSize, 1);
    });

    if (this.state.fields.modelName === "") {
      errors["modelEmpty"] = this.props.t('enter_model_name');
      this.setState({ errors });
    } else {
      errors["modelEmpty"] = "";

      if (this.state.list2 !== "") {

        this.setState({
          activeArray2: [],
          list2: []
        });
      }

      this.setState({ errors });

      $("input[type='checkbox']").prop("checked", false);
    }
  }

  keyPress(e) {
    let errors = this.state.errors;
    let fields = this.state.fields;
    var key = e.key;

    if (e.keyCode === 13) {
      this.setState({ mesSerialNo: e.target.value, activeArray2: [] }, ()=>{
        this.getInventoryBasedOnorg(this.state.pageSize, 1, 'clickedMES');
      });

      // this.state.activeArray2 = [];
      $("input[type='checkbox']").attr('value', 'false');
    }
    if (key === "Delete" || key === "Backspace") {
      if (this.state.fields.mesSerialNo.length <= 1) {
        fields.mesSerialNo = "";
        this.setState({ fields }, ()=>{
          this.getInventoryBasedOnorg(this.state.pageSize, 1, 'clickedMES');
        })
      } else {
        if (key === "Delete") {
          fields.mesSerialNo = "";
          this.setState({ fields }, ()=>{
            this.getInventoryBasedOnorg(this.state.pageSize, 1, 'clickedMES');
          })
        }
      }
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        // var key = e.key;
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }

    if (this.state.fields.mesSerialNo === 0) {
      errors["mesSnoEmpty"] = "";
      this.setState({ errors })
    }
  }

  keyPress1(e) {
    let errors = this.state.errors;
    var key1 = e.key;
    let fields = this.state.fields;

    if (e.keyCode === 13) {
      this.setState({ modelName: e.target.value, activeArray2: [] }, ()=>{
        this.getInventoryBasedOnorg(this.state.pageSize, 1, "clickedModel");
      });

      // this.state.activeArray2 = [];
      $("input[type='checkbox']").attr('value', 'false');
    }
    if (key1 === "Delete" || key1 === "Backspace") {
      if (this.state.fields.modelName.length <= 1) {
        fields.modelName = "";
        this.setState({ fields }, ()=>{
          this.getInventoryBasedOnorg(this.state.pageSize, 1, "clickedModel");
        })
      } else {
        if (key1 === "Delete") {
          fields.modelName = "";
          this.setState({ fields }, ()=>{
            this.getInventoryBasedOnorg(this.state.pageSize, 1, "clickedModel");
          })
        }
      }
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -_]");
        var key = e.key;
        if (!regex.test(key)) {

          e.preventDefault();

          return false;


        }
        break;

    }
    if (this.state.fields.modelName === 0) {
      errors["modelEmpty"] = "";
      this.setState({ errors })
    }
  }
  keyPress2(e) {
    var key2 = e.key;
    let fields = this.state.fields;
    let errors = this.state.errors;

    if (e.keyCode === 13) {
      this.setState({ materialNo: e.target.value, activeArray2: [] }, ()=>{
        this.getInventoryBasedOnorg(this.state.pageSize, 1, "clickedMaterial");
      });
      // this.state.fields.modelName = "";
      // this.state.fields.mesSerialNo = ""
      // this.state.activeArray2 = [];
      $("input[type='checkbox']").attr('value', 'false');
    }

    if (key2 === "Delete" || key2 === "Backspace") {
      if (this.state.fields.materialNo.length <= 1) {
        fields.materialNo = "";
        this.setState({ fields }, ()=>{
          this.getInventoryBasedOnorg(this.state.pageSize, 1, "clickedMaterial");
        })
      } else {
        if (key2 === "Delete") {
          fields.materialNo = "";
          this.setState({ fields }, ()=>{
            this.getInventoryBasedOnorg(this.state.pageSize, 1, "clickedMaterial");
          })
        }

      }
    }
    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {

          e.preventDefault();

          return false;


        }
        break;

    }
    if (this.state.fields.materialNo === 0) {
      errors["matnoEmpty"] = "";
      this.setState({ errors })
    }

  }
 
  


  releaseWarningtoggle() {
    this.setState({
      releaseWarning: !this.state.releaseWarning
    });
  }

  deletetoggle1(event) {
    this.setState({
      deletemodal1: !this.state.deletemodal1
    });
    // event.preventDefault();
    // let getcheckBoxStatus = localStorage.getItem("checkBoxStatus")
    if (this.state.activeArray2.length > 0) {



      this.setState({
        delete_msg: this.props.t('delete_confirmation'),
        deleteHeader: this.props.t('confirmation'),
        deleteButton: "",
        deleteText: this.props.t('close'),
        deletecolor: "danger"
      })
    } else {

      this.setState({
        delete_msg: this.props.t('enter_charge_point_id'),
        deleteHeader: this.props.t('Warning'),
        deleteButton: "d-none",
        deleteText: this.props.t('ok'),
        deletecolor: "primary"
      })
    }

  }
  toggleView1() {
    this.setState({
      modalView: !this.state.modalView
    });
  }
  CloseSuccessauthentication() {
    this.setState({
      modalSuccessauthentication: !this.state.modalSuccessauthentication
    });
    window.location.reload();

  }
  toggleAuthentication() {
    this.setState({
      modalSuccessauthentication: !this.state.modalSuccessauthentication
    });
  }
  toggleView(no_of_attempts, timeoutMinutes, serialNumber) {
    // this.state.saveSno = serialNumber
    this.setState({ 
      displayReq: no_of_attempts,
      saveSno: serialNumber,
      displayRes: timeoutMinutes,
      modalView: !this.state.modalView
    })
    // this.setState({ displayRes: timeoutMinutes })
    // this.setState({
    //   modalView: !this.state.modalView
    // });
  }

  displayAccessCode(accessCode, id, index, status) {


    const newFile = this.state.orgArray.map((orgArray) => {
      return { ...orgArray, status: false };
    });

    newFile[index].status = true;
    this.setState({ orgArray: newFile });
    this.setState({ displayAccesCode: accessCode });
  }



  siteAdminChange() {
    var x = document.getElementById("siteAdmin").value;
    let fields = this.state.fields;
    fields["siteAdmin"] = x;
    this.setState({ fields });
  }

  commercialUserChange() {
    var x = document.getElementById("commercialUser").value;
    let fields = this.state.fields;
    fields["commercialUser"] = x;
    this.setState({ fields });
  }


  confirmtoggle(event) {
    event.preventDefault();
    var org = "";
  
    if (this.state.orgName === "") {
      org = document.getElementById("organization").value;
      this.setState({ orgName: org });
    }
    else {
      org = this.state.orgName;
    }
    if (this.state.activeArray.length > 0 && org !== "") {
      this.setState({
        confirmmodal: !this.state.confirmmodal
      });
    } else {
      this.toggle3();
    }
  }



  siteadmintoggle(event) {
    event.preventDefault();
    var userId = this.state.fields.siteAdmin;
    this.setState({ userName: userId });
    if (this.state.activeArray2.length > 0 && userId !== "") {
      this.setState({
        siteadminmodal: !this.state.siteadminmodal
      });
    } else {
      this.admintoggle();
    }
  }

  commercialusertoggle(event) {
    event.preventDefault();

    this.setState({
      commercialusermodal: !this.state.commercialusermodal
    });
  }

  releasetoggle(event) {
    event.preventDefault();
  
    if (this.state.activeArray.length > 0) {
      this.setState({
        releasemodal: !this.state.releasemodal
      });
    } else {
      this.setState({
        releaseWarning: !this.state.releaseWarning
      });
    }
  }

  deletetoggle(event) {
    event.preventDefault();
   
    this.setState({
      deletemodal: !this.state.deletemodal
    });
  }
  // ClearCpNames(ref, event) {
  //   if(this.state.onClickCheckBox === true){
      
  //     this.handlePageChange(this.state.activePage);
     
  //     this.state.onClickCheckBox = false;
  //    }
  //    this.state.errors["entercharge"] = " ";
  //    $("#notexists").text(" ");
  // }
  // method to clear search filter
  ClearCpName(ref, event) {
    // this.state.activeArray2 = "";
    let errors = this.state.errors;
    errors["entercharge"] = "";
    $("#notexists").text("");
    // this.state.saveSelectedCpid = "";
    // this.state.selectFindStatus = false;
    // this.getPaginationcount();
    // this.state.pageSize = 10;
    // this.state.activePage = 1;
    // document.getElementById("showEntries").value=10;
    this.setState({
      activeArray2: "",
      errors,
      saveSelectedCpid: "",
      selectFindStatus: false,
      pageSize: 10,
      activePage: 1
    }, ()=>{
      this.getInventoryBasedOnorg(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid);
    })
    ref.current.clear();
    this.setState({
      options: [],
      // errors: errors
    })
  }

  // onBlurEvent(event) {
  //   var vendorName1 = event.target.value;
  //   this.setState({
  //     vendorName1: vendorName1
  //   })

  //   if (vendorName1.length === 0) {
  //     this.getInventoryBasedOnorg(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid)
  //     this.setState({
  //       orgArray: this.state.items,
  //     });
  //   }
  //   let errors = {};
  //   errors["chargePointNotExists"] = "";
  //   errors["selectChargepoint"] = "";

  //   this.setState({
  //     errors: errors
  //   });

  // }

  FindCpName(ref, event) {
    // this.state.activeArray2 = [];
    // this.state.onSelectFindbtnStatus = true;
    let getcheckBoxStatus = localStorage.getItem("checkBoxStatus")
    // this.state.list2 = [];
    let saveSelectedCpid = ref.current.inputNode.value;
    let errors = this.state.errors;

    if (getcheckBoxStatus === "true") {

    }

    this.setState({
      activeArray2: [],
      onSelectFindbtnStatus: true,
      list2: [],
      saveSelectedCpid: saveSelectedCpid
    }, ()=>{
      this.getInventoryBasedOnorg(this.state.pageSize, 1, this.state.saveSelectedCpid);
      if (this.state.saveSelectedCpid === "") {
        errors["entercharge"] = this.props.t('enter_charge_point_id');
        $("#notexists").text("");
        this.setState({ errors })
      } else {
        errors["entercharge"] = "";
        this.setState({ errors })
        this.getInventoryBasedOnorg(this.state.pageSize, 1, "click");
      }
    })
  }

  // logic to get cpid based on search filter

  selectedCpChange(cpid) {
    // this.state.saveSelectedCpid = cpid
    this.setState({ saveSelectedCpid: cpid }, ()=>{
      if (cpid.length === 0) {
        this.getInventoryBasedOnorg(this.state.pageSize, this.state.activePage)
        this.setState({
          orgArray: this.state.items,
        });
      } else {
     
        this.getInventorybasedonchargepoint(cpid);
      }
    })
   
   
 
  }

  handleKeyDown(ref, e) {
    var serial_no = e.target.value;
    var sno = serial_no.slice(0, serial_no.length - 1);
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
    var errors = this.state.errors;
    
    if (serial_no !== 0) {
      errors["selectChargepoint"] = "";
    }

    if (key === "Backspace" || key === "Delete") {
      errors["selectChargepoint"] = "";
      $("#notexists").text("");

      if (sno === "") {
        this.setState({ saveSelectedCpid: sno }, ()=>{
          this.getInventoryBasedOnorg(this.state.pageSize, this.state.pageNo);
        })
      }
    }

    this.getSearchInventoryList(sno)


    if (e.keyCode === 13) {
      this.FindCpName(ref, e);
      $("#rbt_id").hide();
    }
    if ((e.keyCode === 86 && ctrl)) {
      this.setState({
        options: []
      })
      this.getSearchInventoryList(serial_no)
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        // var key = e.key;
        if (!regex.test(key)) {

          e.preventDefault();

          return false;


        }
        break;

    }

  }

  tagOk1() {
    window.location.reload();
  }

  toggle1() {
    this.setState({
      modal1: !this.state.modal1
    });
  }
  toggleadminsuccess() {
    this.setState({
      modaladminsuccess: !this.state.modaladminsuccess
    });
  }

  toggle2() {
    this.setState({
      modalr: !this.state.modalr
    });
  }
  deleteSucesstoggle() {
    this.setState({
      modalInventory: !this.state.modalInventory
    });
  }


  warningModelOk() {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  warningMessage() {
    this.setState({
      modalAdmin: !this.state.modalAdmin
    });
  }

  toggle3() {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  admintoggle() {
    this.setState({
      modalAdmin: !this.state.modalAdmin
    });
  }

  organizationChange(event) {
    var orgname = event.target.value;

    if (orgname === "webasto") {
      this.setState({
        buttondisplay: "block",
        reassigndisplay: "none",
        orgName: orgname
      });
    } else {
      this.setState({
        buttondisplay: "none",
        reassigndisplay: "block",
        orgName: orgname
      });
    }
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    this.setState({
      pageSize: entries,
      activePage: 1
    }, ()=>{
      this.getInventoryBasedOnorg(entries, 1);
    });
  }



  bulkImport() {
    this.props.history.push('/bulkCPImport')
  }
  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      this.setState({ activePage: pageNumber });
      this.getInventoryBasedOnorg(this.state.pageSize, pageNumber);
    }
  }
  


  async togglesession() {
   
    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }

async getAccount(){
  var url = baseUrl.URLPath + "admin/v1.0/account/all";
  let type = ApiMethodTypes.GET;
  let headers = getSecurityHeaders();
  let payload = {};

  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

  if (response !== undefined) {
    this.setState({ dataArray: response.organisationDTOS })
    var org_name = response.organisationDTOS;
    for (var i = 0; i < org_name.length; i++) {
      if (this.state.org_name.toLowerCase === org_name[i].company.toLowerCase) {
        this.setState({ identifier: org_name[i].identifier })
      }
    }
  }
  this.getInventoryBasedOnorg(this.state.pageSize, this.state.pageNo);
}

  componentDidMount() {
    let user = {
      permissions: []
    }

    user.permissions = localStorage.getItem("roleAccess");
    
    // permission related code
    if (this.state.writePermission === true) {
      this.setState({
        hideActionBtn: "inline-block"
      })
    }
    
    // api for get all the organization 
    var orgname = localStorage.getItem("tenant");
    if (orgname === "webasto") {
      this.setState({
        selectDropdown: "block"
      })
    }
    else {
      this.setState({
        selectDropdown: "none"
      })
    }

    this.setState({
      org_name: orgname, user
    })

    this.getAccount()

      if(hasPermission(user, ['assignInventory_R'])){
        this.getSiteAdminList();
      }
  }

  async getSiteAdminList() {

    var url = baseUrl.URLPath + "reassignment/users";
    let type = ApiMethodTypes.GET;
  let headers = getSecurityHeaders();
  let payload = {};

  let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

  if (response !== undefined) {
    this.setState({ e_mail: response })
  }
      
  }

  changeHandler(field, e) {

    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.chargePointid = document.getElementById('serialnumber')

    this.setState({ fields });
  }
  changeModelName(field, e){

    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.modelnm = document.getElementById('modelName')
     this.setState({ fields });
     if(e.target.value === ''){
      this.setState({ fields }, ()=>{
        this.getInventoryBasedOnorg(this.state.pageSize, 1, 'clickedModel');
      })
     }
  }
  changeMes(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    // fields.mes = document.getElementById('mesSerialNo')
     this.setState({ fields });
     if(e.target.value === ''){
      this.setState({ fields }, ()=>{
        this.getInventoryBasedOnorg(this.state.pageSize, 1, "clickedMES");
      })
     }
  }
  changeMaterialNo(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.materialN = document.getElementById('materialNo')
     this.setState({ fields });
     if(e.target.value === ''){
      this.setState({ fields }, ()=>{
        this.getInventoryBasedOnorg(this.state.pageSize, 1, 'clickedMaterial');
      })
     }
  }

  async getInventoryBasedOnorg(pageSize, pageNo, action) {
    this.setState({ loading: true })
    var url = "";

    if (this.state.selectFindStatus === true) {
      url = baseUrl.URLPath + "cpinventory/organization?pageNo=" + 1 + "&pageSize=" + pageSize + "&chargePointId=" + this.state.saveSelectedCpid + "&mesSerialNo=" + this.state.fields.mesSerialNo + "&modelName=" + this.state.fields.modelName + "&materialNo=" + this.state.fields.materialNo;
    } else {
      url = baseUrl.URLPath + "cpinventory/organization?pageNo=" + pageNo + "&pageSize=" + pageSize + "&chargePointId=" + this.state.saveSelectedCpid + "&mesSerialNo=" + this.state.fields.mesSerialNo + "&modelName=" + this.state.fields.modelName + "&materialNo=" + this.state.fields.materialNo;
    }

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      if (response.count.count === 0) {
        if (action === "click" || action === "enter") {
          this.setState({ loading: false })
          if(this.state.saveSelectedCpid !== ""){
            $("#notexists").text(this.props.t('charge_point_id_not_exists.'));
          }else{
            $("#notexists").text("");
          }
        }
        if(action === "clickedMES"){
          this.setState({ loading: false })
          if(this.state.fields.mesSerialNo !== ""){
          $("#mesSnoEmpty").text(this.props.t('mes_serialno_not_exists'));
          }else{
            $("#mesSnoEmpty").text("");
          }
        }
        if(action === "clickedModel"){
          this.setState({ loading: false })
          if(this.state.fields.modelName !== ""){
            $("#modelEmpty").text(this.props.t('model_name_not_exists'));
          }else{
            $("#modelEmpty").text("");
          }
        }
        if(action === "clickedMaterial"){
          this.setState({ loading: false })
          if(this.state.fields.materialNo !== ""){
            $("#matnoEmpty").text(this.props.t('material_no_not_exists'));
          }
          else{
            $("#matnoEmpty").text("");
          }
        }
        this.setState({
          orgArray: [],
          noOfRecords: 0,
          loading: false
        })
      } else {
        this.setState({
          orgArray: response.cpInventories,
          noOfRecords: response.count.count,
          loading: false
        })
        $("#notexists").text(" ");
        $("#mesSnoEmpty").text(" ");
        $("#modelEmpty").text(" ");
        $("#matnoEmpty").text(" ");
        

        var data = this.state.data;
        data.set(pageNo, response.count.count)

        if (this.state.onSelectFindbtnStatus === true) {
          // var getSno = this.state.orgArray[0].serialNumber;

          this.setState({ saveFindSno: this.state.orgArray[0].serialNumber, loading: false })
        }
      }
    }
  }

  getInventorybasedonchargepoint(cpid) {
    var errors = this.state.errors;;
    if (cpid === null || cpid === "") {
      errors["selectChargepoint"] = this.props.t('enter_charge_point_id');
      this.setState({
        errors: errors
      });
    } else {
      this.setState({
        selectFindStatus: true
      }, ()=>{
        this.getInventoryBasedOnorg(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid);
      });
    }
  }

  //  function after clicking on checkbox to stored cpid in an array 
  saveChargePoint(id, cpid, serialNumber, index, event) {
    var checkedValue = document.getElementById(id).checked;

    if (checkedValue === true) {
      let list = this.state.list;
      let list2 = this.state.list2;
      list.push(cpid);
      list2.push(serialNumber);

      localStorage.setItem("checkBoxStatus", checkedValue);

      this.setState({
        activeArray: list, onClickCheckBox: true, activeArray2: list2
      });
    } else {
      localStorage.setItem("checkBoxStatus", checkedValue)
      const index = this.state.list.indexOf(cpid);
      const index2 = this.state.list2.indexOf(serialNumber);

      if (index > -1) {
        this.state.list.splice(index, 1);
      }

      this.setState({
        activeArray: this.state.list
      });

      if (index2 > -1) {
        this.state.list2.splice(index2, 1);
      }

      this.setState({
        activeArray2: this.state.list2
      });
    }
  }

  // function get called when click on re_assign button
  async reAssignChargePoint() {
    var org = "";
    this.setState({
      confirmmodal: !this.state.confirmmodal
    });

    if (this.state.orgName === "") {
      org = document.getElementById("organization").value;
      this.setState({ orgName: org });

    }
    else {
      org = this.state.orgName;
    }
    if (this.state.activeArray !== "" && org !== "") {
      var url = baseUrl.URLPath + "cpinventory/reassign";
      let data = { "tenantId": org, "inventoryIds": this.state.activeArray };
      let type = ApiMethodTypes.PUT;
      let headers = getSecurityHeaders();
      let payload = data;
    
      await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      this.toggle1();

    } else {
      this.toggle3();
    }
  }
  // Assign to site admin users
  async assignSiteAdmin() {
    var userId = "";
    this.setState({
      siteadminmodal: !this.state.siteadminmodal
    });

    if (this.state.userName === "") {
      userId = document.getElementById("siteAdmin").value;
      this.setState({ userName: userId });

    }
    else {
      userId = this.state.userName;
    }
    if (this.state.activeArray2 !== "" && userId !== "") {
      var url = baseUrl.URLPath + servicesUrl.updateReassignmentUsers + "?userId=" + userId;
      let data = { "chargePoints": this.state.activeArray2 };

      let type = ApiMethodTypes.PUT;
      let headers = getSecurityHeaders();
      let payload = data;
    
      await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      this.toggleadminsuccess();

    } else {
      this.admintoggle();
    }
  }

  // Assign to commercial users
  async assignCommercialUsers() {
    var userId = "";
    this.setState({
      commercialusermodal: !this.state.commercialusermodal
    });

    if (this.state.userName === "") {
      userId = document.getElementById("commercialUser").value;
      this.setState({ userName: userId });

    }
    else {
      userId = this.state.userName;
    }
    if (this.state.activeArray2 !== "" && userId !== "") {
      var url = baseUrl.URLPath + "?userId=" + userId;
      
      let data = { "chargePoints": this.state.activeArray2 };

      let type = ApiMethodTypes.PUT;
      let headers = getSecurityHeaders();
      let payload = data;
    
      await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      this.toggleadminsuccess();

    } else {
      this.admintoggle();
    }
  }

  // function get called when click on release button
 async releaseButton() {
  
    var org = "webasto";
    this.setState({
      releasemodal: !this.state.releasemodal
    });

    if (this.state.activeArray !== "" && org !== "") {
      var url = baseUrl.URLPath + "cpinventory/reassign";
      let data = { "tenantId": org, "inventoryIds": this.state.activeArray };
      
      let type = ApiMethodTypes.PUT;
      let headers = getSecurityHeaders();
      let payload = data;
    
      await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      this.toggle2();
        

    }

    else {
      this.toggle3();
    }
  }


  async getSearchInventoryList(cpid) {
    
    // var cp_id = [];
    var url = baseUrl.URLPath + "cpinventory?cpid=" + cpid;

    let type = ApiMethodTypes.GET;
      let headers = getSecurityHeaders();
      let payload = {};
    
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      if (response !== undefined) {
        var option = [];
        var data = [];

        for (var i = 0; i < response.length; i++) {
          data.push(response[i].serialNumber);
        }

        this.setState({
          intial_data: option,
          options: data
        });
      }
     
  }


  async getCommercialUsers() {

    var url = baseUrl.URLPath + "reassignment/users/commercial";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
  
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({ commercial_Users: response })
    }
  }



  // Delete Inventory
  async deleteInventory() {

    this.setState({
      deletemodal1: !this.state.deletemodal1
    });

    if (this.state.onSelectFindbtnStatus === true) {
      var getCheckedSNo = this.state.saveFindSno;
      var convertedSNO = getCheckedSNo.split(',');

      this.setState({
        onSelectFindbtnStatus: false,
        activeArray2: convertedSNO
      })
    }

    if (this.state.activeArray2 !== "") {
      var url = baseUrl.URLPath + "cpinventory";

      let data = { "chargePointIds": this.state.activeArray2 };
      let type = ApiMethodTypes.DELETE;
      let headers = getSecurityHeaders();
      let payload = data;

      await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      this.deleteSucesstoggle();
    }
  }

  async exportInventoryCSV() {
    // var timezone = moment().tz(moment.tz.guess()).format('z');
    var url = baseUrl.URLPath + "cpinventory/download/CSV";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeadersWithTimezoneLocationBased();
    let payload = {};
  
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false, true);
    if (response !== undefined) {
      let d = new Date();
      let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
      let url = window.URL.createObjectURL(response);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'Inventory_' + dformat + '.csv';
      a.click();
    }
    
  }


  async getTimeOutHinder(serialNumber){
    var url = baseUrl.URLPath + servicesUrl.getTimeouthinderBycp + "?cpid=" + serialNumber;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
  
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({
        timeOutArray: response,

      })
      this.toggleView(this.state.timeOutArray.no_of_attempts, this.state.timeOutArray.timeoutMinutes, serialNumber);
    }
      
  }
  async getReleaseData(event) {
 
    event.preventDefault();
    this.setState({
      modalView: !this.state.modalView
    });
    let errors = {};
    var cpID = this.state.saveSno;


    let url = baseUrl.URLPath + "timeouthinder/minutes"

    var data = { "chargePointId": cpID, "no_of_attempts": 0, "timeoutMinutes": 0, "previousMinutes": 0 };
    let type = ApiMethodTypes.PUT;
    let headers = getSecurityHeaders();
    let payload = data;
  
    await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    this.toggleAuthentication()

    this.setState({ errors: errors });

  }

  //function for sorting table data on click
  ascendingOrder() {
    let orgArray = this.state.orgArray;
    if(this.state.cpIdSorting === false){
      orgArray = orgArray.sort(this.dynamicSort("serialNumber"));
      this.setState({orgArray : orgArray, cpIdSorting: true})
    } else{
      orgArray = orgArray.sort(this.dynamicSort("serialNumber"));
      this.setState({orgArray : orgArray.reverse(), cpIdSorting: false });
     } 
  }

  dynamicSort(property) {
    var sortOrder = 1;
  
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
  
    return function (a,b) {
        if(sortOrder === -1){
            return b[property].localeCompare(a[property]);
        }else{
  
            return a[property].localeCompare(b[property]);
        }        
    }
  }
  render() {
    const{t}=this.props
    let user = this.state.user;
    const ref = React.createRef();

    const userEmail = this.state.e_mail.map((e_mail, index) => {
      return (
        <option value={e_mail.id}>{e_mail.email} - {e_mail.lastName}</option>
      )
    })


    // const commercialUser = this.state.commercial_Users.map((commercial_Users, index) => {
    //   return (
    //     <option value={commercial_Users.id}>{commercial_Users.email} - {commercial_Users.lastName}</option>
    //   )

    // })


    const organisation = this.state.dataArray.map((dataArray, index) => {
      var setOrg = false;
      if (this.state.org_name.toLowerCase() === dataArray.company.toLowerCase()) {
        setOrg = true;
        // this.state.identifier = dataArray.identifier;
      }

      if(this.state.org_name.toLowerCase() !== dataArray.company.toLowerCase()){
        return (
          <option value={dataArray.identifier} selected={setOrg} >{dataArray.company}</option>
        )
      }else{
        return <></>
      }
      
    })
    // condition for edit and delete inventory
    let getInventoryStatus = localStorage.getItem("saveInventory")
    let inventoryDisplay = "none";

    if (this.state.enableInventory === getInventoryStatus) {
      inventoryDisplay = "display:block";
    } else {
      inventoryDisplay = "none";
    }

    const inventory = this.state.orgArray.map((orgArray, index) => {
      var displayTimeOut = '';
      var hideTimeOut = '';
      var timeoutdisplay = "none";
      var timeoutdisplayno = "none";
      var buttonacessdisplay = "none";

      if (this.state.orgArray[index].status === true) {
        buttonacessdisplay = "block";
      } else {
        buttonacessdisplay = "none";
      }

      if (orgArray.inTimeout === true) {
        displayTimeOut = "YES";
        timeoutdisplay = "block";
        timeoutdisplayno = "none";
      } else {
        hideTimeOut = "NO";
        timeoutdisplayno = "block";
        timeoutdisplay = "none";
      }

      var manufacturingDate = "";
      var manufacturingDate1="";

      if(orgArray.manufacturingDate===null || orgArray.manufacturingDate===""){
        manufacturingDate = "";
        manufacturingDate1="";
      }else{
        manufacturingDate = moment(orgArray.manufacturingDate).format("DD MMM YYYY");
        manufacturingDate1 = moment(orgArray.manufacturingDate).format("HH:mm");
      }

      var createdDate = "";
      var createdDate1="";

      if(orgArray.createdDate===null || orgArray.createdDate===""){
        createdDate = "";
        createdDate1="";
      }else{
        createdDate = moment(orgArray.createdDate).format("DD MMM YYYY");
        createdDate1 = moment(orgArray.createdDate).format("HH:mm");
      }

      var updatedDate = "";
      var updatedDate1="";

      if(orgArray.updatedDate===null || orgArray.updatedDate===""){
        updatedDate = "";
        updatedDate1="";
      }else{
        updatedDate = moment(orgArray.updatedDate).format("DD MMM YYYY");
        updatedDate1 = moment(orgArray.updatedDate).format("HH:mm");
      }

      return (

        <tr>
          {hasPermission(user, ['inventory_W']) &&
            <td className="text-center">
              <Form.Check
                custom
                type="checkbox"
                id={`custom-${orgArray.id}`}
            
                label=""
                onChange={this.saveChargePoint.bind(this, `custom-${orgArray.id}`, orgArray.id, orgArray.serialNumber,index)}
                key={orgArray.id}
              />
            </td>
          }
          <td>  <Link to={{
            pathname: '/viewInventory',
            state: orgArray.serialNumber,
            
          }}  >{orgArray.serialNumber}</Link></td>
          <td className="wrap-word">{ manufacturingDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {manufacturingDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {manufacturingDate1} </div> </>}</td>
          <td>{orgArray.modelName}</td>
          <td>{orgArray.partNumber}</td>
          <td> {orgArray.mesSerialNumber}</td>
          <td>{orgArray.materialNo}</td>
          <td>{orgArray.fwVersion}</td>
          <td>{orgArray.vendorCode}</td>
          <td className="wrap-word">{ createdDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdDate1} </div> </>}</td>
          <td className="wrap-word">{ updatedDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {updatedDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {updatedDate1} </div> </>}</td>

          <td className="text-center">
            {this.state.orgArray[index].accessCode !== null && (
              <span className='span_hover' onClick={this.displayAccessCode.bind(this, orgArray.accessCode, orgArray.id, index, orgArray.status)}>Show</span>
            )
            }
            {this.state.orgArray[index].status === true && (
              <MDBBtn style={{ display: buttonacessdisplay }} color='' type="submit" className="tooltipped tooltipped-s tooltipped-align-right-1 border access_code"
              >
                {this.state.displayAccesCode}
                {/* {orgArray.accessCode} */}
              </MDBBtn>)

            }



          </td>
          <td className="text-center"><h style={{ display: timeoutdisplayno }}>{hideTimeOut}</h>
            <span className='span_hover' style={{ display: timeoutdisplay }} onClick={this.getTimeOutHinder.bind(this, orgArray.serialNumber)}>{displayTimeOut}</span>
          </td>

          {hasPermission(user, ['inventory_U']) && <td style={{ display: inventoryDisplay }}> <Link to={{
            pathname: '/editInventory',
            state: orgArray.serialNumber,
          }}  ><i class="fas fa-pencil-alt   pr-2 action-icon edit_usericon" title="Edit"></i></Link></td>}
        </tr>
      )


    })


    // const data =this.state.dataArray.map((dataArray, index) => {
    //   return(
    //           <tr key={index}>
    //               <td></td>
    //               <td></td>
    //               <td></td>
    //               <td></td>
    //               <td></td>
    //               <td></td>
    //           </tr>
    //        )
    //   })

    return (
      <>
        <main class="content-div" >
        <p>{t('Inventory')}
              <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('Inventory')}</span>
              </div>
           </p>
          <div className="page-outerdiv">
            {/* <div className="breadcrumb_div"> Maintenance > Inventory</div> */}
            {/* end page title */}
            {/* page menu bar */}
            <div className="row menubar">
              <div className="col-md-12 ">
                <button style={{ "display": this.state.hideActionBtn }} onClick={this.bulkImport.bind(this, 0)} >
                  <i className="fas fa-file-import"></i>
                  <span>{t('bulk_import')}</span>
                </button>
                {hasPermission(user, ['inventory_CSV']) &&
                  <button color="primary accent-2" onClick={this.exportInventoryCSV} >
                    <i className="fas fa-file-export"></i>
                    <span>{t('export_all')}</span>
                  </button>
                }

                {hasPermission(user, ['inventory_D']) && <button style={{ display: inventoryDisplay }} onClick={this.deletetoggle1.bind(this)}>
                  <i className="fas fa-trash">
                  </i>
                  <span>{t('delete')}</span>
                </button>}
              
              <div className="pull-right">
              <label className="sub-text pull-left" >{t('show_entries')}</label>
                <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
              </div>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-md-4 pull-right mt-1">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    minLength={3}
                    multiple={this.state.multiple}
                    options={this.state.options}
                    placeholder={t('enter_charge_point')}
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    // onFocus={this.ClearCpNames.bind(this, ref)}
                    onChange={this.selectedCpChange.bind(this)}
                    // onBlur={this.onBlurEvent}
                    ref={ref}
                    id="rbt_id"
                  />
                  <button type="button" className="close close_icon" aria-label="Close" onClick={this.ClearCpName.bind(this, ref)}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)} >{t('find')}</button>
                  </div>
                  <span className="error_msg w3-animate-top mt-2">  <span style={{color: "red"}}>{this.state.errors["entercharge"]}</span></span> 
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{color: "red"}}>  <span style={{color: "red"}}>{this.state.errors["notexists"]}</span></span>
                </div>
              </div>
          
              <div className="col-md-3 pl-0" style={{ "display": this.state.selectDropdown }}>
                {hasPermission(user, ['inventory_W']) &&
                  <select className="browser-default custom-select select_height" id="organization" onChange={this.organizationChange.bind(this)}>
                    <option value="">{t('select_organization')}</option>
                    {organisation}
                  </select>
                }
              </div>
              <div class="col-md-1 pl-0">
                {hasPermission(user, ['inventory_W']) &&
                  <button type="submit" className="btn_primary mt-1 pull-left " style={{ display: this.state.reassigndisplay }} onClick={this.confirmtoggle}
                  >
                    {t('reassign')}
                  </button>
                }
                {hasPermission(user, ['inventory_W']) &&
                  <button type="submit" className=" btn_primary mt-1 pull-left" style={{ display: this.state.buttondisplay }} onClick={this.releasetoggle.bind(this)}
                  >
                    {t('release')}
                  </button>
                }
              </div>
              <div className="col-md-3 pl-0" >
                {hasPermission(user, ['assignInventory_R']) &&

                  <select className="browser-default custom-select select_height" id="siteAdmin" onChange={this.siteAdminChange.bind(this)}>
                    <option value="" >{t('select_site_admin')}</option>
                    {userEmail}
                  </select>
                }


              </div>
              <div class="col-md-1 pl-0">
                {hasPermission(user, ['assignInventory_R']) &&

                  <button  type="submit" className="btn_primary  mt-1 pull-left " onClick={this.siteadmintoggle.bind(this)}>
                    {t('assign')}
                  </button>
                }
              </div>
         
              {/* <div className="col-md-3 pull-right">

              
             {hasPermission(user, ['assignInventory_Commercial_R']) && 
             <select  className="browser-default custom-select select_height" id="commercialUser"  onChange={this.commercialUserChange.bind(this)}>
                        <option value="">Select Commercial User</option>
                        {commercialUser}                    
                        </select>
                         } 
                        </div> */}

              {/* <div class="col-md-1">
                                    {hasPermission(user, ['assignInventory_Commercial_R']) && 

                <MDBBtn color='primary' type="submit" className="btn btn-primary sm_btn btn-sm mt-1 pull-left " onClick={this.commercialusertoggle.bind(this)}>
                      Assign
                    </MDBBtn>
                                         }

                    </div> */}
            </div>
            <div className = "row">
              <div className="col-md-3 md-form mt-2">
                <input name="adminAddress" type="text" id="mesSerialNo" className="form-control" onChange={this.changeMes.bind(this, "mesSerialNo")} value={this.state.fields["mesSerialNo"]} onKeyDown={this.keyPress.bind(this)} />
                <label htmlFor="mesSerialNo" className={`${(this.state.fields['mesSerialNo'] !== "") ? "active" : ""} ml-16`}>{t('search_for_mes_serial_no')}</label>
                <span className="error_msg w3-animate-top" id="mesSnoEmpty" style={{color: "red"}}>  <span style={{ color: "red" }}>{this.state.errors["mesSnoEmpty"]}</span></span>
              </div>
            <MDBCol md="1" className="my-3 mt-4">
            <i class="fas fa-filter" onClick={this.onChangeMesSno}></i>
              </MDBCol>
              <div className="col-md-3 md-form mt-2">
                <input name="adminAddress" type="text" id="modelName" className="form-control" onChange={this.changeModelName.bind(this, "modelName")} value={this.state.fields["modelName"]} onKeyDown={this.keyPress1.bind(this)} />
                <label htmlFor="modelName" className={`${(this.state.fields['modelName'] !== "") ? "active" : ""} ml-16`}>{t('search_for_model_name')}</label>
                <span className="error_msg w3-animate-top" id="modelEmpty" style={{color: "red"}}>  <span style={{ color: "red" }}>{this.state.errors["modelEmpty"]}</span></span>
              </div>
            <MDBCol md="1" className="my-3 mt-4">
            <i class="fas fa-filter"onClick={this.onChangeModeName}></i>
              </MDBCol>
              <div className="col-md-3 md-form mt-2">
                <input name="adminAddress" type="text" id="materialNo" className="form-control" onChange={this.changeMaterialNo.bind(this, "materialNo")} value={this.state.fields["materialNo"]} onKeyDown={this.keyPress2.bind(this)} />
                <label htmlFor="materialNo" className={`${(this.state.fields['materialNo'] !== "") ? "active" : ""} ml-16`}>{t('search_for_material_no')}</label>
                <span className="error_msg w3-animate-top" id="matnoEmpty" style={{color: "red"}}>  <span style={{ color: "red" }}>{this.state.errors["matnoEmpty"]}</span></span>
              </div>
            <MDBCol md="1" className="my-3 mt-4">
            <i class="fas fa-filter" onClick={this.onChangeMatno}></i>
              </MDBCol>
</div>
            {/* end page menu bar  */}
            {/* table start */}
            <div className="row">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      {hasPermission(user, ['inventory_W']) &&
                        <th></th>
                      }
                      <th className="">{t('charge_point_id')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('mf_date')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('model')}</th>
                      <th className="">{t('part_no')}</th>
                      <th className="">{t('mes_serial_no')}</th>
                      <th>{t('material_no')}</th>
                      <th className="">{t('fw_version')}</th>
                      <th className="">{t('vendor_code')}</th>
                      <th>{t('created_date')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('Modified Date')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('access_code')}</th>
                      <th className="">{t('in_Timeout')}</th>
                      {hasPermission(user, ['inventory_U']) && <th style={{ display: inventoryDisplay }}>{t('action')}</th>}

                    </tr>
                  </thead>
                  <tbody>
                    {inventory}
                  </tbody>
                </Table>
              </div>
              {/* End table  */}
              <div className="col-md-12 mt-2 mb-4 text-right">

                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </div>


          {/* popup for session expire */}

          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
      </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
              <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          {/* confirm reassign */}
          <MDBModal isOpen={this.state.confirmmodal} toggle={this.confirmtoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.confirmtoggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('sure_you_want_to_reassign_charge_point')}
</MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.confirmtoggle.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.reAssignChargePoint.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color="danger" onClick={this.confirmtoggle}>Close</MDBBtn>
              <MDBBtn color="primary" onClick={this.reAssignChargePoint}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          {/* confirm siteAdmin */}
          <MDBModal isOpen={this.state.siteadminmodal} toggle={this.siteadmintoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.siteadmintoggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('sure_you_want_to_assign_charge_point')}
</MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.siteadmintoggle.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.assignSiteAdmin.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color="danger" onClick={this.siteadmintoggle}>Close</MDBBtn>
              <MDBBtn color="primary" onClick={this.assignSiteAdmin}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          {/* confirm commercilauser */}
          <MDBModal isOpen={this.state.commercialusermodal} toggle={this.commercialusertoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.commercialusertoggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('sure_you_want_to_assign_charge_point')}
</MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.commercialusertoggle.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.assignCommercialUsers.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color="danger" onClick={this.commercialusertoggle}>Close</MDBBtn>
              <MDBBtn color="primary" onClick={this.assignCommercialUsers}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          {/* confirm release */}

          <MDBModal isOpen={this.state.releasemodal} toggle={this.releasetoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.releasetoggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('sure_you_want_to_release_charge_point')}
  </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.releasetoggle.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.releaseButton.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color="danger" onClick={this.releasetoggle}>Close</MDBBtn>
              <MDBBtn color="primary" onClick={this.releaseButton}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          {/* confirm delete */}
          <MDBModal isOpen={this.state.deletemodal}  toggle={this.deletetoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.deletetoggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('sure_you_want_to_delete_the_inventory')}
</MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.deletetoggle.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.deleteInventory.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color="danger" onClick={this.deletetoggle}>Close</MDBBtn>
              <MDBBtn color="primary" onClick={this.deleteInventory}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle1}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('charge_Point_reassigned_successfully')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk1.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modaladminsuccess} toggle={this.toggleadminsuccess} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleadminsuccess}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('charge_point_assigned_successfully')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk1.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modalr} toggle={this.toggle2} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('charge_point_released_successfully')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk1.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modalInventory} backdrop="static" data-backdrop="static" data-keyboard="false" className="model_top" size="md">

            <MDBModalHeader toggle={this.deleteSucesstoggle}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('inventory_deleted_successfully')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk1.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modal3} toggle={this.toggle3} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle3}>{t('warning')}</MDBModalHeader>
            <MDBModalBody>
              {t('select_charge_point_id_and_org')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.warningModelOk.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.warningModelOk}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.deletemodal1} toggle={this.deletetoggle1} size="md" className="model_top" >
            <MDBModalHeader toggle={this.deletetoggle1}> {this.state.deleteHeader}</MDBModalHeader>
            <MDBModalBody>
              {this.state.delete_msg}
            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.deletetoggle1.bind(this)}>{this.state.deleteText}</button>
            <button type="button" data-test="button" className={`btn_primary ${this.state.deleteButton}`} onClick={this.deleteInventory.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color={this.state.deletecolor} onClick={this.deletetoggle1}>{this.state.deleteText}</MDBBtn>
              <MDBBtn color="primary" className={this.state.deleteButton} onClick={this.deleteInventory}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modalAdmin} toggle={this.admintoggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.admintoggle}>{t('Warning')}</MDBModalHeader>
            <MDBModalBody>
              {t('select_charge_point_id_and_site_admin')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.warningMessage.bind(this)}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* new added popup */}
          <MDBModal isOpen={this.state.releaseWarning} toggle={this.releaseWarningtoggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.releaseWarningtoggle}>{t('Warning')}</MDBModalHeader>
            <MDBModalBody>
              {t('select_charge_point_id')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.releaseWarningtoggle.bind(this)}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>


          {/* Popup for  View */}
          <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size="md" className="model_top">
            {/* <MDBModalHeader toggle={this.toggleView}>View</MDBModalHeader> */}
            <MDBModalBody>
              <div className="row timeout_counter" style={{ marginTop: '0.05rem' }}>
                <MDBCol md="12" className="text-center">
                  <label className="">{t('timeout_counter')}</label>&nbsp;<label className="">{this.state.displayReq}</label>

                </MDBCol>
                {/* <MDBCol md="1" >
    <label className="">{this.state.displayReq}</label>
   </MDBCol> */}
              </div>
              <div className="row timeout_counter" style={{ marginTop: '0.05rem' }}>
                <MDBCol md="12" className="text-center" >
                  <label className=""> {t('current_timeout_in_minutes')}</label>&nbsp;<label className="">{this.state.displayRes}</label>
                </MDBCol>
                {/* <MDBCol md="1" >
    <label className="">{this.state.displayRes}</label>
   </MDBCol> */}
              </div>

            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.toggleView}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.getReleaseData}>{t('reset_timeout')}</button>
              {/* onClick={this.addBuildtype} */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modalSuccessauthentication} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggleAuthentication}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('charger_released_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.CloseSuccessauthentication}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {this.state.loading ? (
            <Loader loader={this.state.loading} />
            ) : ("")}
            
        </main>
      </>
    );
  }
}

export default withTranslation()(inventoryList);
