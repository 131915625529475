import React from 'react';
import { MDBRow, MDBCol, MDBInput } from 'mdbreact';
import DatePicker from "react-datepicker";
import apiCallUtility from '../common/APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import * as baseUrl from './constants';

class GetDiagnostics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate1: '',
      startDate2: '',
      startDate: '',
      startDate_in: '',
      cpList: [],
      fields: {
        retries: "",
        retryInterval: ""
      },
      location: "",
      sucess: false,
      colour: 'sucess',
      errors: {},
      chargePoint: '',
      operationId: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectedCP = this.getSelectedCP.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.onShowAlert = this.onShowAlert.bind(this);
    this.numberHandler = this.numberHandler.bind(this);
    this.getCPList = this.getCPList.bind(this);
    this.locationChange = this.locationChange.bind(this);
    this.getPayLoad = this.getPayLoad.bind(this);
    this.getMessageType = this.getMessageType.bind(this);
    this.dateChange = this.dateChange.bind(this);
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {
    e.preventDefault();
    let fields = this.state;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  async handleSubmit() {
    var payload = this.getPayLoad();
    var errors = {};

    if (this.getCPList().length === 0) {
      errors["emptyChargePoint"] = this.props.t('select_charge_point')
    }
    if (payload.location === "") {
      errors["emptyLocation"] = this.props.t('enter_location')
    }
    if (this.getCPList().length !== 0 && payload.location !== "") {
      var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList(), this.props.t);

      if (response === this.props.t('unable_toprocess_request_try_after_some_time')) {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, () => {
          this.onShowAlert();
        })
      } else if (response === 'Request is processed Successfully') {
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, () => {
          this.onShowAlert();
        })
      }
    }

    this.setState({ errors: errors })
  }

  getCPList() {
    var cplist = "";
    var Id = this.state.operationId;

    if (Id !== null && Id !== "") {
      cplist = Id.split();
    } else {
      cplist = this.state.cpList;
    }

    return cplist;
  }

  locationChange(location) {
    this.setState({ location: location.target.value })
  }

  getPayLoad() {
    var payload = {
      "location": this.state.location,
      "startTime": this.state.startDate1,
      "stopTime": this.state.startDate2,
      "retries": parseInt(this.state.fields.retries),
      "retryInterval": parseInt(this.state.fields.retryInterval)
    }

    if (isNaN(payload.retries)) {
      delete payload.retries;
    }

    if (isNaN(payload.retryInterval)) {
      delete payload.retryInterval;
    }

    return payload;
  }

  getMessageType() {
    return constants.GET_DIAGNOSTICS;
  }

  getSelectedCP(cpList) {
    this.setState({ cpList: cpList });
  }

  handleChange(date) {
    var currentDate = new Date(date);
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var csFormatDate = currentDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate + "T" + twohoursDate + ":" + twominDate + ":00Z";

    this.setState({ startDate1: csFormatDate, startDate: date });
  }

  dateChange(date) {
    var currentDate = new Date(date);
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var csFormatDate = currentDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate + "T" + twohoursDate + ":" + twominDate + ":00Z";

    this.setState({ startDate2: csFormatDate, startDate_in: date });
  }

  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata !== undefined) {
      if (propsdata.chargePoint !== undefined && propsdata.operationId !== undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }

    this.setState({ location: baseUrl.URLPath + "diagnostics/SN/{cpId}" })
  }

  componentWillUnmount() {
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }

  render() {
    const { t } = this.props;
    return (
      <main className="content-div" >
        <p> {t('Get Diagnostics')}
          <div className="breadcrumb_div">
            {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Get Diagnostics')}</span>
          </div>
        </p>
        <div className="page-outerdiv">
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
          <MDBRow className="mb-4">
            <MDBCol sm="12">
              <div>
                <form className="needs-validation">
                  <MDBRow>
                    <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="GetDiagnostics"></ChargePointSelection>
                    <MDBCol md="12">
                      <span className="selection_charge_point_error w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptyChargePoint"]}</span></span>
                    </MDBCol>

                    <MDBCol md="4">
                      <MDBInput
                        value={this.state.location}
                        name="location"
                        disabled="true"
                        onChange={this.locationChange.bind(this)}
                        type="text"
                        label={t('location_directory_uri') + " *"}
                        required
                      >
                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["emptyLocation"]}</span></span>
                      </MDBInput>
                    </MDBCol>

                    <MDBCol md="4">
                      <MDBInput
                        value={this.state.fields["retries"]}
                        name="retries"
                        onInput={this.numberHandler.bind(this, "retries")}
                        type="text" pattern="[0-9]*"
                        id="retriesId"
                        label={t('retries_integer')}
                        required
                      >
                      </MDBInput>
                    </MDBCol>

                    <MDBCol md="4">
                      <MDBInput
                        value={this.state.fields["retryInterval"]}
                        name="RetryInterval"
                        onInput={this.numberHandler.bind(this, "retryInterval")}
                        type="text" pattern="[0-9]*"
                        id="retryIntervalId"
                        label={t('retry_interval_integer')}
                        required
                      >
                      </MDBInput>
                    </MDBCol>
                    
                    <MDBCol md="4" className="mt-2">
                      <span className="cal_label">{t('start_date_time')}</span>
                      <div className="pull-left mt-2" style={{ width: "55%" }} >
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={1}
                          className="cal_margin tag_cal"
                          timeCaption="time"
                          dateFormat="dd-MMM-yyyy HH:mm"
                        />
                      </div>
                    </MDBCol>

                    <MDBCol md="4" className="mt-2" style={{ position: "relative" }} >
                      <span className="cal_label">{t('stop_date_time')} </span>
                      <div className="pull-left mt-2" style={{ width: "55%" }} >
                        <DatePicker
                          selected={this.state.startDate_in}
                          onChange={this.dateChange}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={1}
                          className="cal_margin tag_cal"
                          timeCaption="time"
                          dateFormat="dd-MMM-yyyy HH:mm"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <button onClick={this.handleSubmit} type="button" className="mt-5 refresh-btn">{t('perform')}</button>
                </form>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </main>
    );
  }
}

export default withTranslation()(GetDiagnostics);