import React from 'react';
import { withTranslation } from 'react-i18next';
import CustomModal from '../common/CustomModal';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import SuccessPopup from '../common/SuccessPopup';
import ConfirmationPopup from "../common/ConfirmationPopup";
import Loader from '../views/loader';


class ApiAccessTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { t } = this.props;
        
        var menuItem = "";
        if (this.props.AccessAPIMenu !== null && this.props.AccessAPIMenu.length !== 0) {
            menuItem = this.props.AccessAPIMenu?.map((menu, index) => {
                return (
                    <ul key={menu.item} className={` api-sidebar-list ${(this.props.clikedMenu === menu.item || (this.props.clikedMenu === "" && menu.name === "Access" && index === 0)) ? "active" : ""} `}>
                        <label className='api-main-list-item' onClick={() => this.props.setContentData(menu)}>
                            <a className='access_a'>{menu.item}</a>
                        </label>
                        <ul className="api-sidebar-sublist">
                            {menu.subItems.map((item) => {
                                return <li key={item.name}>{item.name}</li>
                            })}
                        </ul>
                    </ul>
                )
            })
        }

        return (
            <main className="content-div">
                <p>{t('api_access')}</p>
                <div className="page-outerdiv api_access mb-5">
                    <div className='row'>
                        <div className="col-lg-4 pr-0">
                            <div className='content-innerdiv-left'>
                                <div className="mb-2">
                                    <label className='heading' style={{ marginRight: "15px" }}>{t('enable_api_access')}</label>
                                    <label className="switch">
                                        <input type="checkbox" checked={this.props.isOn} onChange={(e) => this.props.isEnableAPIAccess(e)} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-md-12 pl-0">
                                    <div className='fs-12'>
                                        <label className="pr-4 font-bold">
                                            {t('client_id')}:
                                        </label>
                                        <label className="pl-4">
                                            {this.props.clientId}
                                        </label>
                                    </div>
                                    <div className='fs-12'>
                                        <label className="pr-3 font-bold" >
                                            {t('client_secret')}:
                                        </label>
                                        <label className='pl-0' >
                                            {this.props.clientSecret}
                                        </label>
                                    </div>
                                     <div className='fs-12 '>
                                        <label className="pr-5 font-bold">
                                            {t('referer')}:
                                        </label>
                                        <label className='pl-2'>
                                        {this.props.referer}
                                        </label>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 pl-0">
                            <div className='content-innerdiv-right'>
                                <span className='api_access_description'>
                                {this.props.description}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-2 menu_desc'>
                        <div className="col-md-3 pr-0">
                            <div className="content-innerdiv api-sidebar">
                                {menuItem}
                            </div>
                        </div>
                        <div className="col-md-9 pb-3 pl-0">
                            {/* <label className='heading'>{t('Access')}</label>
                            <h6 className="pt-2 pb-0 descrption">{t('description')}</h6>
                            <span className='api_access_description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span> */}
                            {/* <SwaggerExample  data = {this.props.SwaggerData} /> */}
                            {/* <SwaggerUI spec={this.props.SwaggerData} /> */}
                            {/* {this.props.loading ? (
                                <div className='loadertext'>Loading...</div> // Display loader while loading
                            ) : (
                                <SwaggerUI spec={this.props.SwaggerData} />
                            )} */}
                            <SwaggerUI spec={this.props.SwaggerData} />
                            
                            {/* <SwaggerUI
                                url="https://petstore.swagger.io/v2/swagger.json"
                                // layout="BaseLayout"
                              //  docExpansion="none"
                               // deepLinking="true"
                                // defaultModelsExpandDepth="1"
                                // filter="true"
                                // operationsSorter="alpha"
                                // showExtensions="true"
                                // showCommonExtensions="true"
                                // tagsSorter="alpha"
                                // tryItOutEnabled="true"
                                //requestSnippetsEnabled="true"
                                // withCredentials="true"
                                // onComplete={complete}
                            /> */}
                        </div>
                    </div>
                </div>

                <ConfirmationPopup
                    open={this.props.disabledAPIAccess}
                    title={t('disable_api_access')}
                    content={`${t('are_you_sure_want_disable_api_access')}`}
                    btnImage={process.env.PUBLIC_URL + "/assets/img/deactive.svg"}
                    btnText={t("yes_disable")}
                    btnBgColor='#FFD3D8'
                    toggleOperation={this.props.closeWarnings}
                    onClickOperation={this.props.disableAPIAccess}
                />

                <SuccessPopup
                    open={this.props.apiAccessDisabled}
                    image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                    content={t('the_api_access_disabled_successfully')}
                    btnText={t("close")}
                    onClickOperation={this.props.closeDisableSuccessPopup}
                />
                
                <ConfirmationPopup
                    open={this.props.enabledAPIAccess}
                    title={t('enable_api_access')}
                    content={`${t('are_you_sure_want_enable_api_access')}`}
                    btnText={t("yes_enable")}
                    btnBgColor='#caf2c2'
                    toggleOperation={this.props.closeWarnings}
                    onClickOperation={this.props.enableAPIAccess}
                />

                <SuccessPopup
                    open={this.props.apiAccessEnabled}
                    image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                    content={t('the_api_access_enabled_successfully')}
                    btnText={t("close")}
                    onClickOperation={this.props.closeEnableSuccessPopup}
                />

                {this.props.loading ? (
                    <Loader loader={this.props.loading} />
                ) : ("")}
            </main>
        );
    }
}

export default withTranslation()(ApiAccessTemplate);