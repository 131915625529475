import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import * as baseUrl from '../views/constants';
import servicesUrl from "../common/servicesUrl";
import { MDBModal, MDBModalBody } from "mdbreact";

export default function CheckoutForm({ clientSecret, props }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [respData, setRespData] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [page, setPage] = useState('');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    // stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    //   switch (paymentIntent.status) {
    //     case "succeeded":
    //       setMessage("Payment succeeded!");
    //       break;
    //     case "processing":
    //       setMessage("Your payment is processing.");
    //       break;
    //     case "requires_payment_method":
    //       setMessage("Your payment was not successful, please try again.");
    //       break;
    //     default:
    //       setMessage("Something went wrong.");
    //       break;
    //   }
    // });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "https://google.com",
        },
        redirect: "if_required",
      });

      if (error) {
        // Handle error scenario
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
      } else {
        // Handle success scenario
        // setMessage("Payment successful!");
        setRespData(paymentIntent);
        localStorage.setItem("transid", paymentIntent.id)
        setPaymentSuccess(true);
        // You can perform additional actions here upon successful payment
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: {
      type: 'tabs'
    }
  }

  const proceedToCharge = () => {
    // Your logic for charging goes here
    saveTransaction();
    remoteStart();

  };

  const saveTransaction = async () => {
    
    var totalTime = localStorage.getItem("totalTime") * 60;
    var transactionId = localStorage.getItem("transid");
    const date = new Date();
    let createTransaction =
    {
      "billAmount": parseInt(localStorage.getItem("totalAmt")),
      "card": {
        "cardHolderName": "",
        "cardNumber": "",
        "cvv": 0,
        "email": "",
        "expiryDate": "",
        "id": "",
        "userId": ""
      },
      "ccFee": 0,
      "serialNumber": localStorage.getItem("cp"),
      "chargedKwh": 0,
      "chargedTime": 0,
      "chargingStatus": "charging",
      "count": {
        "count": 0
      },
      "customerProfileId": localStorage.getItem("customerProfile"),
      "id": "",
      "isSaveCard": true,
      "paidAmount": parseFloat(localStorage.getItem("totalAmt")),
      // "paymentDate": date,
      "paymentTransactionId": transactionId,
      "rate": (localStorage.getItem('chargingType') === 'ENERY_LEVEL' ? parseInt(localStorage.getItem('costPerkWh')) : parseInt(localStorage.getItem('costPerHr'))),
      "refundAmount": 0,
      "status": "AuthorizedPayment",
      "tenantId": "12345",
      "totalKwh": parseFloat(localStorage.getItem('totalKWh')),
      // "totalTime": 0,
      "totalTime": parseFloat(totalTime),
      "freeCharge": false,
      "qrId": localStorage.getItem('qrId'),
      "directPaymentEnabled": localStorage.getItem('directPaymentEnabled'),
      "chargingSessionCurrency": localStorage.getItem('chargingSessionCurrency')
    }

    var _this = this;

    let url = baseUrl.LoginPath + servicesUrl.saveBilling;
    // const data = await billRequest.callService('POST', createTransaction, url);
    const data = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(createTransaction),
      headers: {
        "content-type": "application/json",
        // "Authorization": localStorage.getItem("Authorization"),
        "Authorization": "abcdadsfasfwqerqwersadfasdf"
      }
    }).then(resp => {
      if (resp.status === 401 || resp.status === 404 || resp.status === 500) {

      } else if (resp.status === 400) {
        return resp.json();
      } else if (resp.status === 200) {
        return resp.json();
      }
    }).then(response => {
      return response
    }
    ).catch(error => {
      console.error(error)
    });

    if (data.executed) {
      if (data.response.success) {
        localStorage.setItem("refundId", data.response.id)
        alert(
          "Payment Success",
          "",
          [
            { text: "OK", onPress: () => _this.CallBack() }
          ]
        );
        localStorage.setItem('paymentDone', "START");
      } else if (data.response.status == "FAILURE") {

        if (data.response.errorCode == "1104") {
          _this.activateAccount();
          localStorage.setItem('token', data.response.token);
        } else if (data.response.errorCode == "3117") {
          localStorage.setItem('token', data.response.token);
        } else {
          // alert(translate(strings[data.response.errorMessage]));
        }
      }
    } else {
      const value = localStorage.getItem('isServerMaintance');
      if (value == "Yes") {
        _this.setState({
          isMaintancePage: true,
        })
      }
    }

  }
  function getPayLoad() {
    let energyLevel;
    let energyTime;
    if(localStorage.getItem('chargingType') === 'TIME_LEVEL') {
      energyTime = localStorage.getItem('totalTime');
    } else {
      energyLevel = localStorage.getItem('totalKWh');
    }
    // if(localStorage.getItem('totalKWh')!== "" && localStorage.getItem('totalKWh') !== null && localStorage.getItem('totalKWh') !== undefined){
    //   energyLevel = localStorage.getItem('totalKWh');
    //   energyTime = ""
    // }else{
    //   energyTime = localStorage.getItem('totalTime');
    //   energyLevel = ""
    // }

    var payload = {
      idTag: localStorage.getItem('rfid'),
      connectorId: 1,
      //   "chargingProfile" : this.state.fields.chargingProfile,
      paymentTransactionId: localStorage.getItem("transid"),
      energyLevel : energyLevel,
      energyTime : energyTime
    };
    return payload;
  }

  function getMessageType() {
    return baseUrl.REMOTE_START_TRANSACTION;
  }

  const remoteStart = async () => {
    
    if (localStorage.getItem('clicked') !== "" && localStorage.getItem('clicked') !== null && localStorage.getItem('clicked') !== undefined) {
      if (localStorage.getItem('transactionStatus') === "activeCharging" || (localStorage.getItem('transactionRfid') && localStorage.getItem('idTag') && localStorage.getItem('transactionRfid') === localStorage.getItem('idTag'))) {
        props.setPage('QRCodeDeactivated');
        localStorage.setItem('page', 'QRCodeDeactivated');
      } else {
        var id = localStorage.getItem('qrId');
        // localStorage.setItem('cp', this.state.cpId);
        // localStorage.setItem('rfid', this.state.idTag);
        // localStorage.setItem('modelType', this.state.chargePointModel);
        let type = getMessageType();
        var data = {
          "type": type,
          "payload": getPayLoad(),
          "cplist": [localStorage.getItem('cp')]
        };

        var timeZone = localStorage.getItem("timezone");
        let url = baseUrl.LoginPath + "/qrcode/charging/cs/operations/" + type + "?qrId=" + id;

        await fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization"),
            "timeZone": timeZone
          }
        }).then(resp => {
          setPaymentSuccess(false)
          if (resp.status === 401 || resp.status === 404 || resp.status === 500) {
            localStorage.setItem('page', 'QRCodeDeactivated');
            props.setPage('QRCodeDeactivated');
            localStorage.setItem('prePage', 'selectCharger');
          } else if (resp.status === 400) {
            return resp.json();
          } else if (resp.status === 200) {
            return resp.json();
          }
        }).then(response => {
          
          if (response && response[0] === "3") {
            localStorage.setItem('page', 'chargingprocess');
            props.setPage('chargingprocess');        
          } else if (response.status === 400 && response.message === "Charger is already in use. Please contact your administrator.") {
            console.log("Charger is already in use. Please contact your administrator.");
          }
        }
        ).catch(error => {
          console.error(error)
        });
      }
    } else {
     // console.log("select charger")
    }
  }

  return (
    <><form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <div className="cardfooter">
        <p className="totalamount">Total: <span style={{ fontSize: "20px", fontWeight: "bold" }}>{localStorage.getItem('currencySymbol')} {localStorage.getItem("totalAmt")}</span></p>
        <button disabled={isLoading || !stripe || !elements} id="submit" className="btn paynow-btn">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>

      <MDBModal isOpen={paymentSuccess} size="md" className="model-top">
        <MDBModalBody className='text-center'>
          <img
            src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/successfully.svg"}
            width="72"
            height="72"
            alt="" />
          <h3 style={{ color: "#3B886B", paddingTop: "15px" }}>{localStorage.getItem('currencySymbol')}{respData?.amount/100}</h3>
          <p className='esim-popup-para'>
            Your payment has been successful.
          </p>
          <button className='btn qr-btn' onClick={proceedToCharge} style={{ margin: "1rem 0" }}>
            Proceed to charge
          </button>
        </MDBModalBody>
      </MDBModal></>
  );
}