import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import moment from 'moment';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { Typeahead } from 'react-bootstrap-typeahead';
import $ from "jquery";
import Calendar from 'react-calendar';
import { withTranslation } from 'react-i18next';
import { activeDateFormat } from './_methods';
import Loader from '../views/loader';

//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders, getSecurityHeadersWithTimezone } from '../common/HttpRequestClass'

const history = createBrowserHistory();

class Unknown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpIdSorting: false,
      errors: {},
      saveSelectedCpid: "",
      startDate1: '',
      startDate2: '',
      options: [],
      selectDataArray: [],
      multiple: false,
      dataArray: [],
      noOfRecords: '',
      data: new Map(),
      pageSize: 10,
      activePage: 1,
      modalsession: false,
      startDate: "",
      toDate: "",
      calenderFrom: "none",
      calenderTo: "none",
      loading: false
    }

    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.chargepointIDChange = this.chargepointIDChange.bind(this);
    this.getUnknownCP = this.getUnknownCP.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.getPartialSearchList = this.getPartialSearchList.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
    this.ClearChargepoint = this.ClearChargepoint.bind(this);
    this.GetFromDate = this.GetFromDate.bind(this);
    this.exportUnknowncpCSV = this.exportUnknowncpCSV.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.changeScheduleFrom = this.changeScheduleFrom.bind(this);
    this.keyPress1 = this.keyPress1.bind(this);
    this.changeScheduleTo = this.changeScheduleTo.bind(this);
    this.keyPress2 = this.keyPress2.bind(this);
    this.fromTime = React.createRef();
    this.toTime = React.createRef();
  }

  keyPress1(e) {
    var key = e.key;

    if (e.keyCode === 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ startDate: e.target.value, startDate1: moment(e.target.value).format("YYYY-MM-DD") }, ()=>{
          this.getUnknownCP(this.state.pageSize, 1);
        });
        $('.future_err1').text("");
      } else {
        this.setState({ startDate: "", startDate1: "" });
        $('.future_err1').text("You can't select future date");
      }
    }

    if (key === "Delete" || key === "Backspace") {
      this.setState({
        startDate1: ""
      }, ()=>{
        this.getUnknownCP(this.state.pageSize, 1);
      });
      $('.future_err1').text("");
    }
  }

  keyPress2(e) {
    var key = e.key;

    if (e.keyCode === 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ toDate: e.target.value, startDate2: moment(e.target.value).format("YYYY-MM-DD") }, ()=>{
          this.getUnknownCP(this.state.pageSize, 1);
        });
        $('.future_err2').text("");
      } else {
        this.setState({ toDate: "", startDate2: "" });
        $('.future_err2').text("You can't select future date");
      }
    }

    if (key === "Delete" || key === "Backspace") {
      this.setState({ startDate2: "" }, ()=>{
        this.getUnknownCP(this.state.pageSize, 1);
      });
      $('.future_err2').text("");
    }
  }

  changeScheduleFrom(e) {
    this.setState({ startDate: e.target.value });
  }

  changeScheduleTo(e) {
    this.setState({ toDate: e.target.value });
  }

  GetFromDate = date => {
    var fdate = this.formatCalDate1(date);
    this.setState({ startDate: fdate, startDate1: this.formatCalDate(date), calenderFrom: "none" }, ()=>{
      this.getUnknownCP(this.state.pageSize, 1);
    });
    $('.date_range_error').text('');
  }

  // get date 
  formatCalDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day,].join('-');
  }

  dateChange = date => {
    var fdate = this.formatCalDate1(date);
    this.setState({ toDate: fdate, startDate2: this.formatCalDate(date), calenderTo: "none" }, ()=>{
      this.getUnknownCP(this.state.pageSize, 1);
    });
    $('.date_range_error').text('');
  }

  formatCalDate1(date) {
    var format = date.toISOString();
    var xyz = moment(format).format("DD MMM YYYY")
    return xyz;
  }

  ClearChargepoint(ref) {
    let errors = this.state.errors;
    errors["selectChargepoint"] = "";
    $("#notexists").text("");
    this.setState({
      errors,
      saveSelectedCpid: ""
    }, ()=>{
      this.getUnknownCP(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid);
    });
    ref.current.clear();
  }

  FindCpName(ref) {
    let errors = this.state.errors;
    let saveSelectedCpid = ref.current.inputNode.value;

    if (saveSelectedCpid === null || saveSelectedCpid === "") {
      errors["selectChargepoint"] = this.props.t('enter_chargepoint_id');

      this.setState({ errors }, ()=>{
        $("#notexists").text("");
      });
    } else {
      errors["selectChargepoint"] = "";

      this.setState({ errors, saveSelectedCpid: saveSelectedCpid }, ()=>{
        this.getUnknownCP(this.state.pageSize, this.state.activePage, "click");
      });
    }
  }

  // search filter 
  handleKeyDown(ref, e) {
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
    var serial_no = e.target.value;
    var sno = serial_no.slice(0, serial_no.length - 1);

    let errors = this.state.errors;

    if (serial_no !== 0) {
      errors["selectChargepoint"] = "";
    }

    if (key === "Backspace" || key === "Delete") {
      errors["selectChargepoint"] = "";
      $("#notexists").text("");

      var x = e.target.value;
      var delitem = x.slice(0, x.length - 1);

      if (delitem === "") {
        this.setState({
          saveSelectedCpid: delitem
        }, ()=>{
          this.getUnknownCP(this.state.pageSize, this.state.activePage);
        });
      }
    }
    
    this.getPartialSearchList(sno);

    if (e.keyCode === 13) {
      this.FindCpName(ref, e);
      $("#rbt_id").hide();
    }

    if ((e.keyCode === 86 && ctrl)) {
      this.getPartialSearchList(serial_no);
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }

  // api to get partial search list
  async getPartialSearchList(cpid) {
    var url = baseUrl.URLPath + servicesUrl.UnknownGetPartialSearchList + "?cpid=" + cpid;

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      this.setState({
        options: response
      });
    }
  }

  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    
    this.setState({
      pageSize: entries,
      activePage: 1
    }, ()=>{
      this.getUnknownCP(entries, 1);
    });
  }

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      this.setState({ activePage: pageNumber }, ()=>{
        this.getUnknownCP(this.state.pageSize, pageNumber);
      });
    }
  }

  async getUnknownCP(pageSize, pageNumber, action) {
    this.setState({ loading: true })
    var url = "";
    if (action === "click" || action === "enter") {
      url = baseUrl.URLPath + servicesUrl.getUnknownCP + "?chargePointId=" + this.state.saveSelectedCpid + "&pageSize=" + pageSize + "&pageNo=" + 1 + "&fromTime=" + this.state.startDate1 + "&toTime=" + this.state.startDate2;
    } else {
      url = baseUrl.URLPath + servicesUrl.getUnknownCP + "?chargePointId=" + this.state.saveSelectedCpid + "&pageSize=" + pageSize + "&pageNo=" + pageNumber + "&fromTime=" + this.state.startDate1 + "&toTime=" + this.state.startDate2;
    }

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeadersWithTimezone();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      if (response.count.count === 0) {
        if (action === "click" || action === "enter") {
          $("#notexists").text("Charge point id not exists.");
        }
        this.setState({
          dataArray: [],
          noOfRecords: 0,
          selectDataArray: [],
          loading: false
        })
      } else {
        this.setState({
          dataArray: response.unknownCPList,
          noOfRecords: response.count.count,
          selectDataArray: response.unknownCPList,
          loading: false
        })
        if (action === "click" || action === "enter") {
          $("#notexists").text(" ");
        }
      }
    }
  }

  componentDidMount() {
    let fromDate = moment().subtract(29, 'd').format('YYYY-MM-DD');
    let toDate = moment().format('YYYY-MM-DD');

    let startdate = moment().subtract(29, 'd');
    let enddate = moment();

    this.setState({
      startDate: this.formatCalDate1(startdate),
      toDate: this.formatCalDate1(enddate),
      startDate1: fromDate,
      startDate2: toDate
    }, ()=>{
      this.getUnknownCP(this.state.pageSize, this.state.activePage);
    })
  }

  // function to get chargepointId based on search filter
  chargepointIDChange(cpid) {
    this.setState({ saveSelectedCpid: cpid }, ()=>{
      if (cpid.length === 0) {
        this.getUnknownCP(this.state.pageSize, this.state.activePage);
      } else {
        this.getUnknownCP(this.state.pageSize, this.state.activePage, cpid);
      }
    })
  }

  exportUnknowncpCSV() {    
    let timeZone =  moment.tz.guess();
    var url = baseUrl.URLPath + "chargepoints/unknown/download/CSV?chargePointId=" + this.state.saveSelectedCpid + "&fromTime=" + this.state.startDate1 + "&toTime=" + this.state.startDate2;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
        "timeZone": timeZone
      }
    })
      .then((resp) => {
        if (resp.status === 401) {
          window.location.reload();
        } else if (resp.status === 200) {
          return resp.blob();
        }
      })
      .then((response) => {
        if (response !== undefined) {
          let d = new Date();
          let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'Unknown_CP_' + dformat + '.csv';
          a.click();
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  ascendingOrder() {
    if (this.state.cpIdSorting === false) {
      let data = this.state.dataArray;
      data = data.sort(this.dynamicSort("chargePointId"));

      this.setState({ dataArray: data, cpIdSorting: true })
    } else {
      let data = this.state.dataArray;
      data = data.sort(this.dynamicSort("chargePointId"));

      this.setState({ dataArray: data.reverse(), cpIdSorting: false });
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  //function to display calender popup
  displayCalender(type) {
    var calenderFrom = "";
    var calenderTo = "";

    if (type === "from") {
      calenderFrom = "block";
      calenderTo = "none";
    } else {
      calenderTo = "block";
      calenderFrom = "none";
    }

    this.setState({
      calenderFrom: calenderFrom,
      calenderTo: calenderTo
    })
  }

  //function to close calender popup
  closeCalender(type) {
    if (type === "from") {
      this.setState({
        calenderFrom: "none"
      })
    } else {
      this.setState({
        calenderTo: "none"
      })
    }
  }

  render() {
    const { t } = this.props;
    const ref = React.createRef();

    const data = this.state.selectDataArray.map((dataArray, index) => {
      var date = "";
      var date1 = "";
      var stringDate = dataArray.updatedDate;

      if (stringDate === null || stringDate === "") {
        date = "";
        date1 = "";
      } else {
        date = moment(stringDate).format("DD MMM YYYY");
        date1 = moment(stringDate).format("HH:mm");
      }

      if (dataArray.ipAddress === null || dataArray.ipAddress === "") {
        dataArray.ipAddress = "";
      }

      return (
        <tr key={`unknown_cpid_${index}`}>
          <td>{dataArray.chargePointId}</td>
          <td>{dataArray.ipAddress}</td>
          <td className="wrap-word">{date && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {date}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {date1} </div> </>}</td>
          <td>{dataArray.noOfAttempts}</td>
        </tr>
      )
    });

    return (
        <main className="content-div" >
          <p>{t('Unknown Charge Points')}</p>
          <div className="page-outerdiv">
            <div className="row mt-2">
              <div className="col-md-4 pull-right">
                <div className="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    multiple={this.state.multiple}
                    options={this.state.options}
                    minLength={3}
                    placeholder={t('enter_charge_point')}
                    onChange={this.chargepointIDChange.bind(this)}
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    id="rbt_id"
                    ref={ref}
                  />
                  <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="input-group-btn">
                    <button className="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)}>{t('find')}</button>
                  </div>
                  <span className="pull-left error_msg w3-animate-top mt-1" id="notexists" style={{ color: "red" }}><span style={{ color: "red" }}>{this.state.errors["chargePointNotExists"]}</span></span>
                  <span className="pull-left error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.state.errors["selectChargepoint"]}</span></span>
                </div>
              </div>
              <div className="col-md-3 p-rel">
                <div className="input-group">
                  <input type="text" className="form-control input-searchBox pl-3" placeholder={t('from_time')} name="srch-term" id="srch-term" value={this.state.startDate} onChange={this.changeScheduleFrom} onKeyDown={this.keyPress1} />
                  <div className="input-group-btn">
                    <button className="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'from')}><i className="fas fa-calendar-alt fa-sm"></i></button>
                  </div>
                </div>
                <div style={{ display: this.state.calenderFrom }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'from')}><i className="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.startDate}
                    onChange={date => this.GetFromDate(date)}
                    value={activeDateFormat(this.state.startDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    onBlur={this.closeCalender.bind(this, 'from')}
                    maxDate={(this.state.toDate !== "" && this.state.toDate !== null) ? new Date(this.state.toDate) : new Date()}
                  />
                </div>
                <span className="pull-left future_err1 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>
              <div className="col-md-3 p-rel">
                <div className="input-group">
                  <input type="text" className="form-control input-searchBox pl-3" placeholder={t('to_time')} name="srch-term" id="toDate" value={this.state.toDate} onChange={this.changeScheduleTo} onKeyDown={this.keyPress2} />
                  <div className="input-group-btn">
                    <button className="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'to')}> <i className="fas fa-calendar-alt fa-sm"></i></button></div>
                </div>
                <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'to')}><i className="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.toDate}
                    onChange={date => this.dateChange(date)}
                    value={activeDateFormat(this.state.toDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    onBlur={this.closeCalender.bind(this, 'to')}
                    minDate={new Date(this.state.startDate)}
                    maxDate={new Date()}
                  />
                </div>
                <span className="pull-left future_err2 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>
              <MDBCol md="2" >
                <button className="btn_primary pull-right" onClick={this.exportUnknowncpCSV} type="button" data-test="button">
                  {t('export')}
                  <i className="fas fa-file-export ml-2"></i>
                </button>
              </MDBCol>
              <div className='col-md-12 date_range_error' style={{ color: "red", textAlign: "center" }}></div>
              <div className="col-md-12 mt-2">
                <Form.Group className="custom_select mb-0 pull-right ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
                <label className="sub-text pull-right">{t('show_entries')}</label>
              </div>
            </div>

            {/* table start */}
            <div className="row ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th>{t('charge_point_id')} <i className="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('ip_address')}</th>
                      <th>{t('last_attempt')} <i className="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('no_of_attempts')} <i className="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </div>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" onClick={this.sessiontagOk} className="btn_primary">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {this.state.loading ? (
            <Loader loader={this.state.loading} />
            ) : ("")}
        </main>
    );
  }
}

export default withTranslation()(Unknown);