import React from 'react';
import {Link} from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Table } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import moment from 'moment';
import CustomModal from '../common/CustomModal';
import { withTranslation } from 'react-i18next';
import Loader from '../views/loader';

 class CsrLandingTemplate extends React.Component {
    
    render() {
        const{t}=this.props
        const ref = React.createRef();

        var data = this.props.selectDataArray.map((dataArray, index) => {

            var chargePoint = {
                chargePointId: dataArray.chargePointId, id: dataArray.id
            }
            
            var mfgDate = "";
            var mfgDate1 = "";

            if (dataArray.mfgDate === null || dataArray.mfgDate === "") {
                mfgDate = "";
                mfgDate1 = "";
            } else {
                mfgDate = moment(dataArray.mfgDate).format("DD MMM YYYY");
                mfgDate1 = moment(dataArray.mfgDate).format("HH:mm");
            }

            var installDate = "";
            var installDate1 = "";

            if (dataArray.installDate === null || dataArray.installDate === "") {
                installDate = "";
                installDate1 = "";
            } else {
                installDate = moment(dataArray.installDate).format("DD MMM YYYY");
                installDate1 = moment(dataArray.installDate).format("HH:mm");
            }

            if (dataArray.address !== null) {
                var cityCheck = dataArray.address.city;
                if (cityCheck !== null) {
                    return (
                        <tr>
                            <td className="">
                                <Link to={{
                                    pathname: '/cpDashboard',
                                    state: chargePoint,
                                }}>{dataArray.chargePointId}</Link>
                            </td>
                            <td className="wrap-word">{mfgDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {mfgDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {mfgDate1} </div> </>}</td>
                            <td className="wrap-word">{installDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {installDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {installDate1} </div> </>}</td>
                            <td className="">{dataArray.fwVersion}</td>
                            <td className="">{dataArray.chargePointModel}</td>
                            <td className="">{dataArray.tenantId}</td>
                            <td>{dataArray.adminUser}</td>
                            <td className="">{dataArray.address.city}&#44;&nbsp;{dataArray.address.country}&#44;&nbsp;{dataArray.address.zipCode}</td>
                            <td className="">{dataArray.address.locationLatitude}&nbsp;&nbsp;{dataArray.address.locationLongitude}</td>
                            <td className="">
                                <Link to={{  }} onClick={this.props.getQuickViewList.bind(this, dataArray.chargePointId)}>{t('quick_view')}  | </Link> <Link to={{
                                    pathname: '/viewDetails',
                                    state: chargePoint,
                                }}>{t('details')}</Link></td>
                        </tr>
                    );
                }
                else {
                    return (
                        <tr>
                            <td className="">
                                <Link to={{
                                    pathname: '/cpDashboard',
                                    state: chargePoint,
                                }}>{dataArray.chargePointId}</Link>
                            </td>
                            <td>{dataArray.mfgDate}</td>
                            <td>{dataArray.installDate}</td>
                            <td>{dataArray.fwVersion}</td>
                            <td>{dataArray.chargePointModel}</td>
                            <td>{dataArray.tenantId}</td>
                            <td>{dataArray.adminUser}</td>
                            <td></td>
                            <td></td>
                            <td className="">
                                <Link to={{ }} onClick={this.props.getQuickViewList.bind(this, dataArray.chargePointId)}>{t('quick_view')}  | </Link>
                                <Link to={{
                                    pathname: '/viewDetails',
                                    state: chargePoint,
                                }}>{t('details')}</Link></td>
                        </tr>
                    );
                }
            }
            else {
                return (
                    <tr>
                        <td className="">
                            <Link to={{
                                pathname: '/cpDashboard',
                                state: chargePoint,
                            }}>{dataArray.chargePointId}</Link>
                        </td>
                        <td>{dataArray.mfgDate}</td>
                        <td>{dataArray.installDate}</td>
                        <td>{dataArray.fwVersion}</td>
                        <td>{dataArray.chargePointModel}</td>
                        <td>{dataArray.tenantId}</td>
                        <td>{dataArray.adminUser}</td>
                        <td></td>
                        <td></td>
                        <td className="">
                            <Link to={{ }} onClick={this.props.getQuickViewList.bind(this, dataArray.chargePointId)}>{t('quick_view')}  | </Link>
                            <Link to={{
                                pathname: '/viewDetails',
                                state: chargePoint,
                            }}>{t('details')}</Link></td>
                    </tr>
                );
            }
        });

        var quickViewBodyData = <div className="row mb-4 mt-3">
            <div className="col-sm-4">
                <div className="content-innerdiv">
                    <div className="">
                        <label>{t('serial_no')}:</label>
                        <label>{this.props.fields["chargePointId"]}</label>
                    </div>
                    <div className="">
                        <label>{t('last_session')}:</label>
                        <label>{this.props.fields["lastChargingDate"]}</label>
                    </div>
                    <div className="">
                        <label>{t('latest_build_available')}:</label>
                        <label>{this.props.fields["latestFW"]}</label>
                    </div>
                    <div className="">
                        <label>{t('last_error')}:</label>
                        <label>{this.props.fields["lastBusinessError"]}</label>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="content-innerdiv">
                    <div className="">
                        <label>{t('Vendor')}:</label>
                        <label>{this.props.fields["tenantId"]}</label>
                    </div>
                    <div className="">
                        <label>{t('last_heartBeat')}:</label>
                        <label>{this.props.fields["heartbeatTime"]}</label>
                    </div>
                    <div className="">
                        <label>{t('current_build_available')}:</label>
                        <label>{this.props.fields["currentFW"]}</label>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="content-innerdiv">
                    <div className="">
                        <label>{t('chargingSessionState')}:</label>
                        <label>{(this.props.fields['active'] === "0" || this.props.fields['active'] === "Inactive") ? t('inactive') : (this.props.fields['active'] !=="") ? t('active') : ""}</label>
                    </div>
                    <div className="">
                        <label>{t('asset_id')}:</label>
                        <label>{this.props.fields[""]}</label>
                    </div>
                    <div className="">
                        <label>{t('latest_build_release_date')}:</label>
                        <label>{this.props.fields["fwReleaseDate"]}</label>
                    </div>
                </div>
            </div>
        </div>;

        var customHeader = <div data-test="modal-header" class="modal-header">
            <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('quick_view')}</div>
            <button type="button" data-test="button" class="close" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>

        return (
            <main className="content-div" >
                <p>{t('CSR')}</p>

                <div className="page-outerdiv">
                    {/* search filter */}
                    <div className="row ">
                        <div className="col-md-4 pull-right t-8">
                            <div class="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <Typeahead
                                    labelKey="name"
                                    className="w_80"
                                    id="rbt_id"
                                    multiple={this.props.multiple}
                                    options={this.props.options}
                                    minLength={3}
                                    placeholder={t('enter_charge_point')}
                                    onChange={this.props.chargepointIDChange}
                                    onKeyDown={this.props.handleKeyDown.bind(this, ref)}
                                    onBlur={this.props.getChargePoint}
                                    ref={ref}
                                />
                                <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.props.clearChargepoint.bind(this, ref)}>
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div class="input-group-btn">
                                    <button class="input-searchBtn find-btn" type="submit" onClick={this.props.FindCpName.bind(this, ref)}>{t('find')}</button>
                                </div>
                                <span className="pull-left error_msg w3-animate-top mt-1" id="notexists" style={{ color: "red" }}><span style={{ color: "red" }}>{this.props.errors["chargePointNotExists"]}</span></span>
                                <span className="pull-left error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.props.errors["selectChargepoint"]}</span></span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label className="pull-left mr-1 mt-2 pt-1 code_clr pl-2">{t('light_codes')}</label>
                            <select className="browser-default custom-select select_height w_59 indent" id="selectedColor" onChange={this.props.colorCodeChange}>
                                <option value="">{t('select_color_code')}</option>
                                <option value="Amber">{t('amber')}</option>
                                <option value="Blue">{t('blue')}</option>
                                <option value="Blue - Amber">{t('blue')} - {t('amber')}</option>
                                <option value="Cycle - All Colors">{t('cycle_all_colors')}</option>
                                <option value="Red">{t('red')}</option>
                                <option value="White">{t('white')}</option>
                            </select>
                        </div>
                        <div className="col-md-1">
                            <label className="mr-3 mt-2 pt-1 ">{this.props.fields['codeId']}</label>
                        </div>

                        <div className="col-md-4 mt-2 text-right">
                            <label className="pr-2 pt-2">{t('alerts')}: 0</label>
                            <div className="pull-right">
                                <label className="sub-text pull-left" >{t('show_entries')}</label>
                                <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.props.showEntriesChange} value={this.props.pageSize}>
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </div>

                    {/* table start */}
                    <div className="row">
                        <div className="col-md-12">
                            <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        <th className="">{t('serial_no')} <i class="fas fa-sort sortIcon" onClick={this.props.ascendingOrder}></i></th>
                                        <th>{t('mf_date')} <i class="fas fa-sort sortIcon" onClick={this.props.ascendingOrder}></i></th>
                                        <th>{t('install_date')} <i class="fas fa-sort sortIcon" onClick={this.props.ascendingOrder}></i></th>
                                        <th className="">{t('fw_version')}</th>
                                        <th className="">{t('model_name')}</th>
                                        <th className="">{t('tenant')}</th>
                                        <th className="">{t('admin_user')}</th>
                                        <th className="">{t('address')}</th>
                                        <th className="">{t('lat_long')}</th>
                                        <th className="">{t('actions')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data}
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-12 mt-2 mb-4 text-right">
                            <Pagination
                                // hideDisabled
                                activePage={this.props.activePage}
                                itemsCountPerPage={this.props.pageSize}
                                totalItemsCount={this.props.noOfRecords}
                                onChange={this.props.handlePageChange}
                                prevPageText={t('prev')}
                                nextPageText={t('next')}
                                itemClassPrev="prevBtn"
                                pageRangeDisplayed="1"
                                activeClass="activeLi active"
                                itemClassNext="nextBtn"
                                hideFirstLastPages={true}
                                disableInitialCallback={true}
                                disabledClass="disabled disabledtextColor"
                            />
                        </div>
                    </div>
                </div>

                {/* popup for session expire */}
                <CustomModal
                    open={this.props.modalsession}
                    title={t('session_has_expired')}
                    bodyMessage={t('please_login_again')}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.sessiontagOk}
                />

                {/* Popup for Quick View */}
                <CustomModal
                    open={this.props.modalView}
                    customHeader={customHeader}
                    bodyMessage={quickViewBodyData}
                    PrimaryButtonText={t('close')}
                    PrimaryButtonOperation={this.props.toggleView}
                    FooterClass = "border-top-0 p-3"
                    ModalClass="model_quickview"
                    size="xl"
                />

                {this.props.loading ? (
                    <Loader loader={this.props.loading} />
                  ) : ("")}
                
            </main>
        );
    }
}

export default withTranslation()(CsrLandingTemplate);