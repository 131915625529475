import React from 'react';
import { MDBRow, MDBCol, MDBInput } from 'mdbreact';
import DatePicker from "react-datepicker";
import apiCallUtility from '../common/APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import * as baseUrl from './constants';

class updateFirmware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {   
      retries: "",
      cpList: [],
      retryInterval: "",
      startDate1: "",
      location: '',
      sucess: false,
      colour: 'sucess',
      errors: {},
      chargePoint: '',
      operationId: '',
      productList: [],
      productId: '',
      buildTypeId: '',
      buildTypes: [],
      retries: "",
      cpList: [],
      zip: "",
      page_title: "Update Firmware",
      modalsession: false,
      isDXCharger: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectedCP = this.getSelectedCP.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.setStartDate1 = this.setStartDate1.bind(this);
    this.isDXCharger = this.isDXCharger.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.productChange = this.productChange.bind(this);
    this.getBuildTypes = this.getBuildTypes.bind(this);
    this.buildTypeChange = this.buildTypeChange.bind(this);
  }

  buildTypeChange(e) {
    this.setState({ buildTypeId: e.target.value });
  }

  sessiontagOk() {
    this.props.history.push('/');
    window.location.reload();
  }

  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  isDXCharger(cpid) {
    let url = baseUrl.URLPath + "chargepoints/dx2FirmwareChargerChecking?cpid=" + cpid;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        } else {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({
            isDXCharger: response?.status
          })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  setStartDate1(date) {
    this.setState({ startDate1: date })
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  async handleSubmit(event) {

    let isDXCharger = this.state.isDXCharger;
    var payload = this.getPayLoad();
    var errors = {};
    
    let valid = true;

    if (this.getCPList().length === 0) {
      errors["emptyChargePoint"] = this.props.t('select_charge_point')
    }

    if (payload.retrieveDate === "") {
      errors["emptyretrievedate"] = this.props.t('enter_retrieve_date/time')
    }

    if(isDXCharger){
      if (this.state.productId == "") {
        errors["prodError"] = "Please select product";
        valid = false;
      } else {
        valid = true;
      }

      if (this.state.buildTypeId == "") {
        errors["buildTypeError"] = "Please select build type";
        valid = false;
      } else {
        valid = true;
      }
    }

    if (this.getCPList().length !== 0 && payload.retrieveDate !== "" && valid == true) {
      var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList(), this.props.t);

      if (response === this.props.t('unable_toprocess_request_try_after_some_time')) {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, () => {
          this.onShowAlert();
        })
      } else if (response === 'Request is processed Successfully') {
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, () => {
          this.onShowAlert();
        })
      }
    }

    this.setState({ errors: errors })
  }

  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata !== undefined) {
      if (propsdata.chargePoint !== undefined && propsdata.operationId !== undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
      this.isDXCharger(propsdata.operationId)
    }
    this.getProducts();
  }

  getProducts() {
    
    let orgid = localStorage.getItem('orgId');
    if (orgid) {
      var url = baseUrl.URLPath + "web/dashboard/" + orgid + "/products?pageNo=0&pageSize=0";

      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          } else if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {
            this.setState({ productList: response?.productList ?? [] });
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    }
  }

  getCPList() {
    var Id = this.state.operationId;
    var cplist = "";
    if (Id !== null && Id !== "") {
      cplist = Id.split();
    } else {
      cplist = this.state.cpList;
    }

    return cplist;
  }

  getPayLoad() {
    var payload = {
      "retrieveDate": this.state.startDate1,
      "location": this.state.location,
      "retries": parseInt(this.state.retries),
      "retryInterval": parseInt(this.state.retryInterval),
      "buildTypeId": this.state.buildTypeId
    }

    if (isNaN(payload.retries)) {
      delete payload.retries;
    }

    if (isNaN(payload.retryInterval)) {
      delete payload.retryInterval;
    }

    return payload;
  }

  getMessageType() {
    return constants.UPDATE_FIRMWARE;
  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {
    let fields = this.state;

    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  getSelectedCP(cpList) {
    let errors = this.state.errors;

    this.setState({ cpList: cpList }, () => {
      this.isDXCharger(cpList[0])
    });

    if (cpList !== "") {
      errors["emptyChargePoint"] = "";
    }

    this.setState({ cpList: cpList, errors });
  }

  handleChange = date => {
    let errors = this.state.errors;

    if (date !== null) {
      errors["emptyretrievedate"] = "";
    }

    var currentDate = new Date(date);

    var twoDigitMonth = ((currentDate.getUTCMonth() + 1) >= 10) ? (currentDate.getUTCMonth() + 1) : '0' + (currentDate.getUTCMonth() + 1);
    var twoDigitDate = ((currentDate.getUTCDate()) >= 10) ? (currentDate.getUTCDate()) : '0' + (currentDate.getUTCDate());
    var twominDate = ((currentDate.getUTCMinutes()) >= 10) ? (currentDate.getUTCMinutes()) : '0' + (currentDate.getUTCMinutes());
    var twohoursDate = ((currentDate.getUTCHours()) >= 10) ? (currentDate.getUTCHours()) : '0' + (currentDate.getUTCHours());
    var csFormatDate = currentDate.getUTCFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate + "T" + twohoursDate + ":" + twominDate + ":00Z";

    this.setState({ startDate1: csFormatDate, startDate: date, errors });
  }

  productChange(e) {
    this.setState({ productId: e.target.value }, () => {
      this.getBuildTypes();
    });
  }

  getBuildTypes() {
    var url = baseUrl.URLPath + "web/dashboard/buildtypes/" + this.state.productId + "?pageNo=0&pageSize=0";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        } else if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ buildTypes: response ?? [] })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  render() {
    const { t } = this.props;
    const products = this.state.productList.map((product, index) => {
      return (
        <option value={product.product_id} key={`product_key_${index}`}>{product.product_name}</option>
      )
    });
    const buildTypes = this.state.buildTypes.map((data, index) => {
      return (
        <option value={data.id} key={`buildtype_key_${index}`}>{data.name}</option>
      )
    });
    return (
      <main className="content-div" >
        <p>{t('Update Firmware')}
          <div className="breadcrumb_div">
            {t('Operations')} &gt; <span className="breadcrumb_page">{t('Update Firmware')}</span>
          </div>
        </p>
        <div className="page-outerdiv">
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
          <MDBRow className="mb-4">
            <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="UpdateFirmware" ></ChargePointSelection>
            <MDBCol md="12">
              <span className="selection_charge_point_error w3-animate-top">
                <span style={{ color: "red" }}>{this.state.errors["emptyChargePoint"]}</span>
              </span>
            </MDBCol>
            <MDBCol sm="12">
              <div>
                <form className="needs-validation">
                  <MDBRow>
                    <MDBCol md="4">
                      <span className="cal_label">{t('retrieve_date') + " *"}</span>
                      <div className="pull-left mt-2" style={{ width: "55%" }} >
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={1}
                          className="cal_margin tag_cal"
                          timeCaption="time"
                          dateFormat="dd-MMM-yyyy HH:mm"
                        />
                      </div>
                      <span className="error_msg w3-animate-top cal_errormsg">
                        <span style={{ color: "red" }}>{this.state.errors["emptyretrievedate"]}</span>
                      </span>
                    </MDBCol>
                    <MDBCol md="4">
                      <MDBInput
                        value={this.state.retries}
                        name="retries" pattern="[0-9]*"
                        onInput={this.numberHandler.bind(this, "retries")}
                        type="text"
                        id="retriesid"
                        label={t('retries_integer')}
                        required
                      />
                    </MDBCol>
                    <MDBCol md="4">
                      <MDBInput
                        value={this.state.retryInterval}
                        name="retryInterval"
                        onInput={this.numberHandler.bind(this, "retryInterval")}
                        type="text" pattern="[0-9]*"
                        id="retryIntervalId"
                        label={t('retry_interval_integer')}
                        required
                      />
                    </MDBCol>
                      {
                        this.state.isDXCharger && <>
                          <MDBCol md="4">
                            <div className="md-form">
                              <select className="browser-default custom-select select_height mb_8" id="productId" onChange={this.productChange.bind(this)} value={this.state.productId}>
                                <option value="">Select Product *</option>
                                {products}
                              </select>
                              <span style={{ color: "red" }}>{this.state.errors["prodError"]}</span>
                            </div>
                          </MDBCol>
                          <MDBCol md="4">
                            <div className="md-form mt-2" >
                              <select className="browser-default custom-select select_height mb_8" id="buildTypes" onChange={this.buildTypeChange.bind(this)} value={this.state.buildTypeId}>
                                <option value="">Select Build Type *</option>
                                {buildTypes}
                              </select>
                              <span style={{ color: "red" }}>{this.state.errors["buildTypeError"]}</span>
                            </div>
                          </MDBCol>
                        </>
                      }
                  </MDBRow>
                  <button type="button" className="mt-5 refresh-btn" onClick={this.handleSubmit} >{t('perform')}</button>
                </form>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </main>
    );
  }
}

export default withTranslation()(updateFirmware);