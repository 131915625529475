import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBRow, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Calendar from 'react-calendar';
import $ from 'jquery';
import { activeDateFormat } from './_methods';
import { withTranslation } from 'react-i18next';
import servicesUrl from '../common/servicesUrl';
import Loader from '../views/loader';
//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

const history = createBrowserHistory();

class errorLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewdisplay: "",
      modalView: false,
      modalsession: false,
      fields: {
        chargePointId: "",
        serviceNameC: "",
        statusName: ""
      },
      startDate: '',
      toDate: '',
      startDate1: '',
      startDate2: '',
      collapseID: "",
      items: [],
      errorData: [],
      noOfRecords: '',
      data: new Map(),
      pageSize: 10,
      activePage: 1,
      pageNo: 1,
      calenderFrom: "none",
      calenderTo: "none",
      listOfService:[],
      loading: false
    }

    this.GetFromDate = this.GetFromDate.bind(this);
    this.getErrorLogs = this.getErrorLogs.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.serviceNameChange = this.serviceNameChange.bind(this);
    this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
    this.statusChange = this.statusChange.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.toggleView1 = this.toggleView1.bind(this);
    this.onChangeStatusCode = this.onChangeStatusCode.bind(this);
    this.changeFromdate = this.changeFromdate.bind(this);
    this.changeTodate = this.changeTodate.bind(this);
    this.keyPress1 = this.keyPress1.bind(this);
    this.keyPress2 = this.keyPress2.bind(this);
    this.displayCalender = this.displayCalender.bind(this);
    this.closeCalender = this.closeCalender.bind(this);
    this.formatCalDate1 = this.formatCalDate1.bind(this);
  }

  //change function for chargepoint status filter
  onChangeStatusCode() {
    var statusCode = document.getElementById("statusName").value;
    this.setState({ statusName: statusCode }, () => {
      this.getErrorLogs(this.state.pageSize, 1);
    });
  }

  toggleView(errorMessage) {
    this.setState({ displayError: errorMessage, modalView: !this.state.modalView })
  }

  toggleView1() {
    this.setState({
      modalView: !this.state.modalView
    });
  }

  // method to call onChange status 
  statusChange(field, e) {
    e.preventDefault();
    let fields = this.state.fields;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields: fields });
      // fields.statusName = document.getElementById('statusName')
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields: fields });
    }
  }

  // method to call onChange Service name
  serviceNameChange() {
    var id = document.getElementById("serviceName").value;
    let fields = this.state.fields;
    fields["serviceNameC"] = id;
    this.setState({ serviceNameC: id }, () => {
      this.getErrorLogs(this.state.pageSize, this.state.activePage);
    });
  }

  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    // fields.chargePointId = document.getElementById('chargePointId')

    this.setState({ fields });
  }

  changeFromdate(e) {
    this.setState({ startDate: e.target.value });
  }

  changeTodate(e) {
    this.setState({ toDate: e.target.value });
  }

  GetFromDate = date => {
    var fdate = this.formatCalDate1(date);
    this.setState({ startDate: fdate, startDate1: this.formatCalDate(date), calenderFrom: "none" }, ()=>{
      this.getErrorLogs(this.state.pageSize, 1);
    });
  }

  formatCalDate1(date) {
    var format = date.toISOString();
    var xyz = moment(format).format("DD MMM YYYY")
    return xyz;
  }

  dateChange = date => {
    var tdate = this.formatCalDate1(date);
    this.setState({ toDate: tdate, startDate2: this.formatCalDate(date), calenderTo: "none" }, ()=>{
      this.getErrorLogs(this.state.pageSize, 1);
    });
  }

  keyPress1(e) {
    var key = e.key;
    $('.future_err1').text("");

    if (e.keyCode === 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ startDate: e.target.value, startDate1: moment(e.target.value).format("YYYY-MM-DD") }, ()=>{
          this.getErrorLogs(this.state.pageSize, 1);
        });
      } else {
        this.setState({ startDate: "", startDate1: "" }, ()=>{
          $('.future_err1').text(this.props.t('you_not_select_future_date'));
        });
      }
    }

    if (key === "Delete" || key === "Backspace") {
      this.setState({ startDate1: "" }, ()=>{
        this.getErrorLogs(this.state.pageSize, 1);
      });
    }
  }

  keyPress2(e) {
    var key = e.key;
    $('.future_err2').text("");

    if (e.keyCode === 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ toDate: e.target.value, startDate2: moment(e.target.value).format("YYYY-MM-DD") }, ()=>{
          this.getErrorLogs(this.state.pageSize, 1);
        });
      } else {
        this.setState({ toDate: "", startDate2: "" }, ()=>{
          $('.future_err2').text(this.props.t('cannot_select_futuredate'));
        });
      }
    }

    if (key === "Delete" || key === "Backspace") {
      this.setState({ startDate2: "" }, ()=>{
        this.getErrorLogs(this.state.pageSize, 1);
      });
    }
  }

  displayCalender(type) {
    var calenderFrom = "";
    var calenderTo = "";

    if (type === "from") {
      calenderFrom = "block";
      calenderTo = "none";
    } else {
      calenderTo = "block";
      calenderFrom = "none";
    }

    this.setState({
      calenderFrom: calenderFrom,
      calenderTo: calenderTo
    })
  }

  closeCalender(type) {
    if (type === "from") {
      this.setState({
        calenderFrom: "none"
      })
    } else {
      this.setState({
        calenderTo: "none"
      })
    }
  }

  // get date 
  formatCalDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');

  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    
    this.setState({
      activePage: 1,
      pageSize: entries
    }, ()=>{
      this.getErrorLogs(entries, 1);
    });
  }

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
        this.setState({ activePage: pageNumber }, ()=>{
          this.getErrorLogs(this.state.pageSize, pageNumber);
        });
    }
  }

  async getErrorLogs(pageSize, pageNo) {
    this.setState({ loading: true })
    let serviceTypes = this.state.fields.serviceNameC;
    let statusCode = this.state.fields.statusName;
    let chargePoints = this.state.fields.chargePointId;

    var url = baseUrl.URLPath + "errorlogs?serviceType=" + serviceTypes + "&chargePointId=" + chargePoints + "&statusCode=" + statusCode + "&formdate=" + this.state.startDate1 + "&todate=" + this.state.startDate2 + "&pageNo=" + pageNo + "&pageSize=" + pageSize;

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({
        items: response.errorLogs,
        noOfRecords: response.count.count,
        loading: false
      })
    }
  }

  componentDidMount() {
    let fromDate = moment().subtract(29, 'd').format('YYYY-MM-DD');
    let toDate = moment().format('YYYY-MM-DD');

    let startdate = moment().subtract(29, 'd');
    let enddate = moment();

    this.setState({
      startDate1: fromDate,
      startDate2: toDate,
      startDate: this.formatCalDate1(startdate),
      toDate: this.formatCalDate1(enddate)
    }, ()=>{
      this.getErrorLogs(this.state.pageSize, this.state.activePage);
    });
    this.getServiceType();
  }


  async getServiceType(){
      let url = baseUrl.URLPath + servicesUrl.getServiceUrl;
      let type = ApiMethodTypes.GET;
      let headers = getSecurityHeaders();
      let payload = {};
  
      let result = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if (result) {
        this.setState({
          listOfService: result
        })
      }
  }

  //change function for chargepoint status filter
  onChangeChargepoint() {
    var chargePoint = document.getElementById("chargePointId").value;
    this.setState({ chargePointId: chargePoint }, () => {
      this.getErrorLogs(this.state.pageSize, 1);
    });
  }

  render() {
    const { t } = this.props
    const errorData = this.state.items.map((item, index) => {
      let viewdisplay = "block";
      var startTime = "";
      var startTime1 = "";

      if (item.errorMessage === null || item.errorMessage === "") {
        viewdisplay = "none";
      } else {
        viewdisplay = "block";
      }

      if (item.startTime === null || item.startTime === "") {
        startTime = "";
        startTime1 = "";
      } else {
        startTime = moment(item.startTime).format("DD MMM YYYY");
        startTime1 = moment(item.startTime).format("HH:mm");
      }

      return (
        <tr>
          <td>{item.jobName}</td>
          <td className="wrap-word">{startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>} </td>
          <td>{item.statusCode}</td>
          <td>{item.chargePointId}</td>
          <td className="text-center"><i className="fas fa-eye action-icon cursor_defult" style={{ display: viewdisplay }} title="View" onClick={this.toggleView.bind(this, item.errorMessage)} ></i></td>
        </tr>
      )
    });

    let listOfServiceOption = this.state.listOfService.map((model, index) => {
      return (
          <option value={model}>{model}</option>
      )
  });


    return (
        <main className="content-div" >
          <p>{t('Error Logs')}
            <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('Error Logs')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row mt-4">
              <MDBCol md="3" >
                <select className="browser-default custom-select select_height mb_8" id="serviceName" onChange={this.serviceNameChange} >
                  <option value="">{t('select_service_type')}</option>
                  {
                    (localStorage.getItem('tenant') === 'webasto') ?
                         <>
                            {listOfServiceOption}
                            </>
                      : (localStorage.getItem('tenant') === 'ford') ?
                        <>
                          <option value="DIAGNOSTICS_DOWNLOAD">{t('diagnostics_download')}</option>
                          <option value="INVENTORY_UPLOAD">{t('inventory_upload')}</option>
                          <option value="INVENTORY_IMPORT">{t('inventory_import')}</option>
                          <option value="WALLBOX_MESSAGE">{t('wallbox_message')}</option>
                          <option value="ONBOARD_CHARGER">{t('onboard_charger')}</option>
                          <option value="VENDOR_SUPPORT">{t('vendor_support')}</option>
                        </> : <></>
                  }
                </select>
              </MDBCol>
              <div className="col-md-3 p-rel mt-2">
                <div className="input-group">
                  <input type="text" className="form-control input-searchBox pl-3" placeholder={t('from_time')} name="srch-term" id="srch-term" value={this.state.startDate} onChange={this.changeFromdate} onKeyDown={this.keyPress1} />
                  <div className="input-group-btn">
                    <button className="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'from')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                  </div>
                </div>
                <div style={{ display: this.state.calenderFrom }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'from')}><i className="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.startDate}
                    onChange={date => this.GetFromDate(date)}
                    value={activeDateFormat(this.state.startDate)}
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    showTimeSelect
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    onBlur={this.closeCalender.bind(this, 'from')}
                    maxDate={(this.state.toDate !== "" && this.state.toDate !== null) ? new Date(this.state.toDate) : new Date()}
                  />
                </div>
                <span className="pull-left future_err1 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>
              <div className="col-md-3 p-rel mt-2">
                <div className="input-group">
                  <input type="text" className="form-control input-searchBox pl-3" placeholder={t('to_time')} name="srch-term" id="srch-term" value={this.state.toDate} onChange={this.changeTodate} onKeyDown={this.keyPress2} />
                  <div className="input-group-btn">
                    <button className="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'to')}> <i className="fas fa-calendar-alt fa-sm"></i></button>                      </div>
                </div>
                <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'to')}><i className="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.toDate}
                    onChange={date => this.dateChange(date)}
                    value={activeDateFormat(this.state.toDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    minDate={new Date(this.state.startDate)}
                    maxDate={new Date()}
                  />
                </div>
                <span className="pull-left future_err2 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>
              <div className="col-md-3 mt-2">
                <div className="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="chargePointId" value={this.state.fields["chargePointId"]} onChange={this.changeHandler.bind(this, "chargePointId")}
                    className="form-control input-searchBox pad-2" placeholder={t('enter_charge_point')} name="adminAddress" />
                  <div className="input-group-btn">
                    <button className="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint} >{t('find')}</button>
                  </div>
                </div>
                <span className="error_msg w3-animate-top"> <span style={{ color: "red" }}></span></span>
              </div>
              <div className='col-md-12 date_range_error' style={{ color: "red", textAlign: "center" }}></div>
              <div className="col-md-3 mt-2">
                <div className="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="statusName" value={this.state.fields["statusName"]} onChange={this.statusChange.bind(this, "statusName")}
                    className="form-control input-searchBox pad-2" placeholder={t('Status Code')} name="statusName" />
                  <div className="input-group-btn">
                    <button className="input-searchBtn find-btn" type="submit" onClick={this.onChangeStatusCode} >{t('find')}</button>
                  </div>
                </div>
                <span className="error_msg w3-animate-top"> <span style={{ color: "red" }}></span></span>
              </div>
              <div className="col-md-9 mt-3 pt-1">
                <div className="pull-right">
                  <label className="pull-left sub-text">{t('show_entries')}</label>
                  <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </div>

            {/* table start */}
            <div className="row ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th>{t('job_name')}</th>
                      <th>{t('startTime')}</th>
                      <th>{t('status_code')}</th>
                      <th>{t('charge_point_id')}</th>
                      <th>{t('view')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorData}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right" >
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </div>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.sessiontagOk}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* Popup for  View */}
          <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size="xl" className="model_quickview" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
            <div data-test="modal-header" className="modal-header">
              <div className="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('view')}</div>
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <MDBModalBody className="popupScroll">
              <MDBRow className="mb-4 mt-3" >
                <MDBCol sm="12">
                  <div className="view-page-row view-bg-row" >
                    <label>{t('error_message')}</label>
                    <label className="wrap-word">
                      {JSON.stringify(this.state.displayError)}
                    </label>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-4 mt-3" >
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.toggleView1}>{t('close')}</button>
            </MDBModalFooter>
          </MDBModal>

          {this.state.loading ? (
            <Loader loader={this.state.loading} />
            ) : ("")}
        </main>
    );
  }
}

export default withTranslation()(errorLogs);