import React from 'react';
import { MDBRow } from 'mdbreact';
import * as constants from './constants';
import { Alert } from 'reactstrap';
import apiCallUtility from '../common/APICallUtility';
import { Link } from 'react-router-dom';
import Dialog from 'react-bootstrap-dialog';
import ChargingSessions from './dashboard/chargingSessions';
import StatusNotification from './dashboard/statusNotification';
import { withTranslation } from 'react-i18next';
import HeartbeatStatus from './dashboard/heartbeatStatus';

//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders } from '../common/HttpRequestClass'
import { hasPermission } from './auth';

import './cpDashboard.css';

class reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_week: [],
      user_group: [],
      sucess: false,
      resetCPID: "",
      colour: 'sucess',
      firmwareFields: {
        latestVersion: "",
        currentVersion: '',
      },
      fields2: {
        status: "",
      },
      startDate: new Date(),
      options: [],
      users: [],
      serial_No: "",
      intial_data: [],
      multiple: false,
      yearmonthName: [],
      allUserMonth: [],
      allUserValue: [],
      alluserStep: {
        min: 0,
        suggestedMax: 10,
      },
      userStep: {
        min: 0,
        suggestedMax: 10
      },
      userData: [],
      userValue: [],
      dataBar: [],
      allUser: [],
      products: [],
      modal: false,
      userGroup: [],
      userGroupCount: [],
      dataBarGroup: [],
      allUserGroup: [],
      userStepGroup: {
        min: 0,
        suggestedMax: 10
      },
      user: {
        permissions: []
      }
    }

    this.getChargingSessionChart = this.getChargingSessionChart.bind(this);
    this.getNotificationChart = this.getNotificationChart.bind(this);
    this.getFirmwareStatus = this.getFirmwareStatus.bind(this);
    this.getWallboxStatus = this.getWallboxStatus.bind(this);
    this.backToList = this.backToList.bind(this);
    this.Reset = this.Reset.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  backToList() {
    this.props.history.push("/csrLanding");
  }

  async getChargePointDetails() {
    let url = baseUrl.URLPath + servicesUrl.getChargePointDetails + "?id=" + this.state.id;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response.data.address === null) {
      var fields1 = [];
      fields1.chargePointId = response.data.chargePointId
      fields1.id = response.data.id
      fields1.description = response.data.description
      fields1.description = response.data.description
      fields1.chargePointVendor = response.data.chargePointVendor
      fields1.chargePointModel = response.data.chargePointModel
      fields1.address = {
        addressId: '',
        street: "",
        houseNumber: "",
        zipCode: "",
        city: "",
        country: "",
        createdTime: "",
        modifiedTime: "",
        locationLatitude: "",
        locationLongitude: "",
      }
      this.setState({ fields: fields1 })
    } else {
      this.setState({ fields: response.data }, () => {
        if (this.state.fields.address.country) {
          this.state.fields.address.country = "country"
        }
      })

    }
  }

  componentDidMount() {
    this.getChargingSessionChart();
    this.getNotificationChart();
    this.getFirmwareStatus();
    this.getWallboxStatus();

    var id="";
    var chargePointId = "";
    var data = {};

    if (this.props.location.state === null) {
      data = JSON.parse(localStorage.getItem("cpview"));
      id = data.id;
      chargePointId = data.chargePointId;
    } else {
      id = this.props.location.state.id;
      chargePointId = this.props.location.state.chargePointId;

      data = {
        id: id,
        chargePointId: chargePointId
      }

      localStorage.setItem("cpview", JSON.stringify(data));
    }

    this.setState({
      id: id,
      chargePointId: chargePointId
    }, ()=>{
      this.getChargePointDetails();
    })
  }

  //  api to get charging session chart
  async getChargingSessionChart() {
    var user_week = [];
    var user_value = [];
    let chargePointId = localStorage.getItem("chargerId");
    var url = baseUrl.URLPath + servicesUrl.getHomeCPChargingsession + "?cpid=" + chargePointId + "&fromTime=1week";
    //localhost:8080/api/home/chargepoint/{chargepointid}/chargingsession?fromTime={1week/2week....}
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      for (var i = 0; i < response.length; i++) {
        user_week.push(response[i]["date"])
        user_value.push(response[i]["count"])
      }
      this.state.userValue = user_value;
      var max_val = Math.max(...user_value);
      if (max_val < 10) {
        this.state.userStep = {
          min: 0,
          suggestedMax: 10,
        }
      } else {
        this.state.userStep = {
          min: 0,
          suggestedMax: max_val,
        }
      }
      this.state.dataBar = {
        labels: user_week,
        datasets: [
          {
            label: "Count",
            data: user_value,
            backgroundColor: "rgba(255, 134,159,0.4)",
            borderWidth: 2,
            borderColor: "rgba(255, 134, 159, 1)"
          }
        ]
      }
      this.setState({ usersData: response })
    }
  }


  //  api to get notification group  chart

  async getNotificationChart() {
    var user_group = [];
    var user_value = [];
    let chargePointId = localStorage.getItem("chargerId");
    var url = baseUrl.URLPath + servicesUrl.getCustomerserviceCPErrorGroup + "?cpid=" + chargePointId + "&fromTime=1week";
    //fordtestcpms.evprowebasto.com/cpms/api/customerservice/chargepoints/1900064444/errorgroup?fromTime=1week
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      for (var i = 0; i < response.length; i++) {
        user_group.push(response[i]["group"])
        user_value.push(response[i]["count"])
      }
      this.state.userGroupCount = user_value;
      var max_val = Math.max(...user_value);
      if (max_val < 10) {
        this.state.userStepGroup = {
          min: 0,
          suggestedMax: 10,
        }
      } else {
        this.state.userStepGroup = {
          min: 0,
          suggestedMax: max_val,
        }
      }
      this.state.dataBarGroup = {
        labels: user_group,
        datasets: [
          {
            label: "Count",
            data: user_value,
            backgroundColor: "rgba(255, 134,159,0.4)",
            borderWidth: 2,
            borderColor: "rgba(255, 134, 159, 1)",
          }
        ]
      }
      this.setState({ usersData: response })
    }
  }

  // api to get firmware status

  async getFirmwareStatus() {
    let chargePointId = localStorage.getItem("chargerId");
    var url = baseUrl.URLPath + servicesUrl.getCustomerserviceCPfirmwareStatus + "?cpid=" + chargePointId;
    //  /api/customerservice/chargepoints/{cpid}/firmwarestatus
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response) {
      this.setState({ firmwareFields: response, })
    }
  }


  // api to get wallBox status

  async getWallboxStatus() {
    let chargePointId = localStorage.getItem("chargerId");
    let url = baseUrl.URLPath + servicesUrl.getCustomerserviceCPStatus + "?cpid=" + chargePointId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response) {
      this.setState({ fields2: response, })
    }
  }

  getPayLoad() {
    var payload = {
      "type": "Hard"
    };

    return payload;
  }

  getMessageType() {
    return constants.RESET;
  }

  async Reset() {
    var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), [this.state.chargePointId], this.props.t);
    if (response === "Unable to process your request, please try after some time.") {
      this.setState({ message: this.props.t(response), colour: 'danger' })
      this.onShowAlert();
    } else if (response === "Request is processed Successfully") {
      this.setState({ message: this.props.t('Request is Processed Successfully'), colour: 'success' })
      this.onShowAlert();
    }
  }

  UNSAFE_componentWillMount() {
    var user = {
      permissions: []
    }
    user.permissions = localStorage.getItem("roleAccess");
    this.setState({
      user: user
    })
  }

  render() {
    let user = this.state.user;
    localStorage.setItem("saveDetails", true);
    var page_title1 = "";
    if (this.props.location.state === null) {
      var data1 = JSON.parse(localStorage.getItem("cpview"));
      page_title1 = data1.chargePointId;
    } else {
      page_title1 = this.props.location.state.chargePointId;
      localStorage.setItem("chargerId", page_title1);
    }

    let status = "";
    if (this.state.fields2.status === 0 || this.state.fields2.status === "Inactive") {
      status = this.props.t('inactive');
    } else {
      if (this.state.fields2.status !== "") {
        status = this.props.t('active');
      }
    }

    return (
      <>
        <main class="content-div" >
          <p>{this.props.t('CSR')}
            <div className="breadcrumb_div">
              <Link to="/csrLanding"><span>{this.props.t('CSR')}</span></Link > &gt; <span className="breadcrumb_page">{this.props.t('cp_dashboard')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="pull-right ">
              <button class="refresh-btn" type="button" data-test="button" onClick={this.backToList} ><i className="fas fa-angle-left mr-2"></i> {this.props.t('back')}
              </button></div>
            <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
            <div className="row menubar mt-1" >
              <div className="col-md-12">
                <div className="" >
                </div>
              </div>
            </div>
            <label className="mt-0 status_Color" ><h5 className="mr-2">{this.props.t('wallbox')}&nbsp;: </h5> </label><label className="mt-0 status_Color" ><h5>{page_title1}</h5> </label>


            {/* end page menu bar  */}
            <MDBRow className="mt-2">
              <div className="col-lg-2 col-md-6 mb-4 mt-2" id="graph3">
                <label className="mt-3" ><h5 className="mb-0 status_Color">{this.props.t('Firmware Status')} </h5> </label>
                <table class="table page-table table-sm table-striped table-hover">
                  <thead>
                    <tr>
                      <th>{this.props.t('current')}</th>
                      <th>{this.props.t('build_repo_latest')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <td>{this.state.firmwareFields["currentVersion"]}</td>
                    <td>{this.state.firmwareFields["latestVersion"]}</td>
                  </tbody></table>
                <label className="mt-3" ><h5 className="mb-0 status_Color">{this.props.t('wallBox_status')}</h5> </label>
                <table class="table page-table table-sm table-striped table-bordered table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody className="p-style">
                    <td><b>{this.props.t('chargingSessionState')}</b></td>
                    <td>{status}</td>
                  </tbody></table>
              </div>
              <div className="col-lg-6 col-md-6 mb-1 mt-2 pr-0" id="graph3" >
                <HeartbeatStatus></HeartbeatStatus>
              </div>
              <div className="col-lg-4 col-md-6 mb-1 mt-2" id="graph4">
                <ChargingSessions></ChargingSessions>
              </div>
            </MDBRow>

            <MDBRow >
              <div className="col-lg-2 col-md-6 mb-2 " id="graph3">
                {hasPermission(user, ["ChargerRestart_R"]) && <>
                  <div><label className="mt-3" ><h5 className="mb-0 status_Color">{this.props.t('flash_code_display')} </h5> </label> </div>
                  <div><img src={process.env.PUBLIC_URL + "/assets/img/flashcode2.png"} className="flashcode_icon" alt="" /></div>
                  <div className="">
                    <button class="refresh-btn m-0 mt-4 pl-3 pr-3" type="button" data-test="button" onClick={this.Reset}> {this.props.t('restart')}
                    </button>
                  </div>
                </>
                }
              </div>
              <div className="col-lg-10 col-md-6 mb-4" id="graph5" style={{ height: 500 }}>
                <StatusNotification></StatusNotification>
              </div>
            </MDBRow>
          </div>
          <Dialog ref={(el) => { this.dialog = el }} size="md" />
        </main>
      </>
    );
  }
}

export default withTranslation()(reports);