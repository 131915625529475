import React from 'react';

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: this.props.loader,
      zIndex: this.props.zIndex
    }
  }

  render() {
    if (this.state.loading === true) {
      if(this.state.zIndex === true) {
        return <div className="page_loader center loaderWithzIndexHigh">
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>
      } else { 
        return <div className="page_loader center loaderWithzIndex">
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>
      }
    } else {
      return <></>
    }
  }
}

export default Loader;