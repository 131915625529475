import React from 'react';
import { MDBRow,MDBCol, MDBInput } from 'mdbreact';
import apiCallUtility from '../common/APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class clearChargingProfile extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {

          fields:{
                  id:'',
                  connectorId:'',
                  chargingProfilePurpose:null,
                  stackLevel:''
                },
        items:[],
        sucess:false,
        colour:'sucess',
        errors:{},
        cpList:[],
        page_title:"Clear Charging Profile",
        chargePoint:'',
        operationId:''

        }
        this.onShowAlert=this.onShowAlert.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.ProfileChange = this.ProfileChange.bind(this);
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);
}

ProfileChange(){
  
  var profileName = document.getElementById("profileNameId").value;
  let fields = this.state.fields;
  fields["id"] = profileName;
  this.setState({fields});
}

//This is for making user should enter only numbers
numberHandler(field, e){ 
  ;
    let fields = this.state.fields
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }else if(e.target.value===""){
      fields[field] = e.target.value; 
      this.setState({fields});
    }

  }

  onShowAlert(){
    
    this.setState({sucess:true},()=>{
      window.setTimeout(()=>{
        this.setState({sucess:false})
      },5000)
    });
  }


  async handleSubmit(event) {
    


  var errors={};


  if(this.getCPList().length===0){
    errors["emptyChargePoint"]=this.props.t('select_charge_point')
  }
  // if(isNaN(connectorId)){
  //   errors["emptyConnectorId"]="Please enter connector id."
  // }
  
  if(this.getCPList().length!==0 ){
    var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList(), this.props.t);
   // alert(response);
   if (response === this.props.t('unable_toprocess_request_try_after_some_time')) {
    this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
      this.onShowAlert();
    })
  } else  if (response === 'Request is processed Successfully'){
    this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
      this.onShowAlert();
    })
  }
  }
  this.setState({errors:errors})
  
  
  }
  getCPList() {
    var Id = this.state.operationId;
    var cplist="";
 if(Id !== null&& Id !== ""){
     cplist = Id.split();
    }
    else{
     cplist = this.state.cpList;
   
   }
   return cplist;
  }
  
  getPayLoad() {
    var payload =
    
    {
      "id":  parseInt(this.state.fields.id) ? parseInt(this.state.fields.id):"",
      "connectorId": parseInt(this.state.fields.connectorId)? parseInt(this.state.fields.connectorId):"",
      "stackLevel": parseInt(this.state.fields.stackLevel)? parseInt(this.state.fields.stackLevel):"",
      "chargingProfilePurpose": this.state.fields.chargingProfilePurpose ? this.state.fields.chargingProfilePurpose:""
  
    };
     
    return payload;
  
  }
  
  getMessageType() {
    return constants.CLEAR_CHARGING_PROFILE_REQUEST;
  }
  chargingprofileChange(){
    var x = document.getElementById("chargingprofile").value;
    ;
    let fields = this.state.fields;
    if(x!==""){
    
    fields["chargingProfilePurpose"] = x;
    }else{
      fields["chargingProfilePurpose"] = null;
    }

    this.setState({fields});
  }


  getSelectedCP(cpList){
    this.setState({cpList:cpList},()=>{
      if(cpList.length > 0){
        let error = this.state.errors;
        error['emptyChargePoint'] = '';
        this.setState({
          errors: error
        })
      }
    });
  }

  async getChargingProfile(){
    
    
    var url = constants.URLPath + "chargingprofiles/search"
    await fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization":localStorage.getItem("Authorization")
      }
      })
      .then((resp) => {
        ;
        if(resp.status===401){
          // this.togglesession();
          // localStorage.clear();
          window.location.reload();
        }
        if(resp.status===500){
          // alert(this.props.t('internal_server_error'))
        }
        if(resp.status===200){
          return resp.json();
        } 
      }) 
      .then((response) =>{
        
        if(response!==undefined){
            this.setState({
             items:response,
            })
        }
   
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
  }

  componentDidMount(){
    let propsdata = this.props.location.state;
  if(propsdata!==undefined){
    if(propsdata.chargePoint!==undefined && propsdata.operationId!==undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }
    this.getChargingProfile()
  }
  componentWillUnmount(){
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }
render() {
 const{t}=this.props
  let ChargingprofileData ="";
  if(this.state.items.length !== 0){
  ChargingprofileData = this.state.items.map((data,index) =>
        <option value={data.chargingProfileId} >{data.chargingProfileName}</option>
    );
  }


  return (
    <>
  
  <main class="content-div" >
  <p>  {t('Clear Charging Profile')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Clear Charging Profile')}</span>
              </div>
           </p>
    {/* <p className="breadcrumb_div"><a>Operations > </a> <a> Clear Charging Profile </a></p> */}
        <div className="page-outerdiv">
        {/* <div className="breadcrumb_div"><a>Operations > </a> <a> Clear Charging Profile </a></div> */}
        <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>

          {/* <p>Clear Charging Profile</p> */}
      {/* <section className="dashboard">
        <div class="col-12 breadcrumbrow">
            <div class="page-title-box">
                <h4 class="page-title float-left" >Clear Charging Profile</h4>
                <div class="clearfix"></div>
            </div>
        </div>
        <section className="content_wbg">  */}
       
           


        <MDBRow className="mb-4">
            <MDBCol sm="12">
              
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
            <MDBRow>
            <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName ="ClearChargingProfile"></ChargePointSelection>
            <MDBCol md="12">
          <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>
          </MDBCol>
            {/* <MDBCol md="12">
            <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">
              <button class="mr-2 select_btn btn_cursor" > Select All</button> <button class="mr-4 select_btn btn_cursor">Select None </button>
            </label>
            <select className="browser-default custom-select select_height1" id="confKeyList" name="confKeyList" multiple="multiple" size="14"></select>
             </div>
            </MDBCol> */}
           
            {/* <MDBCol md="4">
            <select className="browser-default custom-select select_height">
            	
            <option value="">Select Filter Type:</option>
            <option value="W">Charging Profile Id</option>
            <option value="A">Other Parameters</option>
            </select>
            </MDBCol> */}
            <MDBCol md="4">
            <select className="browser-default custom-select select_height"  id="profileNameId" onChange={this.ProfileChange}>
            	
            <option value="">{t('charging_profile_name')}:</option>
             {ChargingprofileData}
            </select>
             {/* <MDBInput
                value={this.state.fields.id}
                name="ID"
                onInput={this.numberHandler.bind(this,"id")}
                type="text"  pattern="[0-9]*"
                id="ID"
                label="ID (Integer)	"
                required
              >
                <div className="valid-feedback">Looks good!</div>
                <small className="form-text text-muted">
                if empty, 0 = charge point as a whole
                </small>cd
              </MDBInput> */}
            </MDBCol>
         
            <MDBCol md="4">
              <MDBInput
                value={this.state.fields.connectorId}
                name="connectorID"
                onInput={this.numberHandler.bind(this,"connectorId")}
                type="text"  pattern="[0-9]*"
                id="connectorID"
                label={t('connector_id_integer')}
                required
              >
    <span className="error_msg w3-animate-top">  <span style={{color: "red"}} >{this.state.errors["emptyConnectorId"]}</span></span>

                <div className="valid-feedback">{t('looks_good')}</div>
                <small className="form-text text-muted">
                {t('if_empty0charge_point')}
                </small>
              </MDBInput>
            </MDBCol>

            <MDBCol md="4">
            <select className="browser-default custom-select select_height" id="chargingprofile" onChange={this.chargingprofileChange.bind(this)}>
            <option value="">{t('charging_profile_purpose')}</option>
            <option value="ChargePointMaxProfile">{t('chargePointMaxProfile')} </option>
              <option value="TxDefaultProfile">{t('txDefaultProfile')}</option>
              <option value="TxProfile">{t('txProfile')}</option>
            </select>
            </MDBCol>
            <MDBCol md="4" >
              <MDBInput
                value={this.state.fields.stackLevel}
                name="stackLevel"
                onInput={this.numberHandler.bind(this,"stackLevel")}
                type="text" pattern="[0-9]*"
                id="stackLevelId"
                label={t('stack_level_integer')}
                required
              >
                <div className="valid-feedback">{t('looks_good')}</div>
              </MDBInput>
            </MDBCol>
         </MDBRow> 
         <button className="mt-5 refresh-btn" onClick={this.handleSubmit} type="button" data-test="button" >{t('perform')}</button>
        </form>
      </div>
         </MDBCol>
        </MDBRow>
        </div>
    </main>
    </>
  );
}
}

export default withTranslation()(clearChargingProfile);