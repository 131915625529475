import React from 'react';
import { MDBCol, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow } from "mdbreact";
import { Form, Table } from 'react-bootstrap'
import * as roles from './roles';
import * as GeneralUser from './GeneralUser';
import * as PlatformUser from './PlatformUser';
import * as TenantUser from './TenantUser';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import $ from "jquery";
import { createBrowserHistory } from 'history';
import { hasPermission } from './auth';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Typeahead } from 'react-bootstrap-typeahead';
import './cpDashboard.css';
import Loader from '../views/loader';

//api fetch call imports
import servicesUrl from '../common/servicesUrl';
import ApiMethodTypes from '../common/ApiMethodTypes';
import APICallUtility from '../common/APICallUtility';
import { getSecurityHeaders } from '../common/HttpRequestClass';
import * as baseUrl from './constants'; 
const history = createBrowserHistory();

class userManagementController extends React.Component {
  constructor(props) {
    super(props);
    let selectedSite;
    try {
      selectedSite = JSON.parse(localStorage.getItem('SelectedSite'));
    } catch (error) {
      selectedSite = null;
    }
    this.state = {
      saveSelectedCpid: "",
      fields: {
        emailId: ""
      },
      options: [],
      multiple: false,
      errors: {
        selectChargepoint: "",
        notexists: ""
      },
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      activePage1: 1,
      pageSize1: 10,
      noOfRecords1: '',
      delete_msg: "",
      deleteHeader: "",
      deleteButton: "",
      deleteText: "",
      deletecolor: "danger",
      activeArray: [],
      checked: false,
      activeDeactiveArray: '',
      list: [],
      page_title: "Users",
      sucess: false,
      userArray: [],
      model1: "false",
      model2: "false",
      id: "",
      dataArray: [],
      org_name: '',
      modalsession: false,
      user: {
        permissions: [],
      },
      fromDateApi: '',
      toDateApi: '',
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: [],
      roles: [],
      selectedSite,
      unblockmodal: false,
      unblocksuccess: false,
      user_id: '',
      userid: '',
      openFreeWallboxes: false,
      selectedWallboxes: [],
      availableWallboxes:[],
      deletedWallboxes:[],
      updateWallboxes: false,
      loading: false,
      isPaymentGateway: false
    }
    this.addNew = this.addNew.bind(this)
    this.closeModel = this.closeModel.bind(this)
    this.getUserslist = this.getUserslist.bind(this)
    this.activeUsers = this.activeUsers.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggle1 = this.toggle1.bind(this)
    this.toggle2 = this.toggle2.bind(this)
    this.tagOk2 = this.tagOk2.bind(this)
    this.tagOk1 = this.tagOk1.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
    this.closeOkPopup = this.closeOkPopup.bind(this)
    this.toggle = this.toggle.bind(this)
    this.togglesession = this.togglesession.bind(this);
    this.unblockAccount = this.unblockAccount.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this)
    this.getNonWebastoUsers = this.getNonWebastoUsers.bind(this);
    this.goLoginHistory = this.goLoginHistory.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onChangeOrg = this.onChangeOrg.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.createdDatesArray = this.createdDatesArray.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.saveFilterDetails = this.saveFilterDetails.bind(this);
    this.removeFilterDetails = this.removeFilterDetails.bind(this);
    this.initialRender = this.initialRender.bind(this);
    this.unblockalert = this.unblockalert.bind(this);
    this.unblocksuccess = this.unblocksuccess.bind(this);
    this.closeUblockSuccess = this.closeUblockSuccess.bind(this);
    this.toggleFreeWallboxes = this.toggleFreeWallboxes.bind(this);
    this.getAllWallboxes = this.getAllWallboxes.bind(this);
    this.getAllSelectedWallboxes = this.getAllSelectedWallboxes.bind(this);
    this.removeSelectedItem = this.removeSelectedItem.bind(this);
    this.isItemSelected = this.isItemSelected.bind(this);
    this.updateCheckboxSelection = this.updateCheckboxSelection.bind(this);
    this.toggleFreeWallboxes = this.toggleFreeWallboxes.bind(this);
    this.updateSelectedWallboxes = this.updateSelectedWallboxes.bind(this);
    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.showEntriesChange1 = this.showEntriesChange1.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.chargepointIDChange = this.chargepointIDChange.bind(this);
    this.ClearChargepoint = this.ClearChargepoint.bind(this);
    this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
    this.handlePageChange1 = this.handlePageChange1.bind(this);
    this.getCustomization = this.getCustomization.bind(this);
  }
  ClearChargepoint(ref, event) {
    this.state.selectFindStatus = false;
    let errors = {};
    errors["chargePointNotExists"] = "";
    this.setState({
      errors: errors
    });
    this.state.saveSelectedCpid = "";
    this.state.pageSize = 10;
    this.state.activePage = 1;
    this.getAllWallboxes(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid);
    this.setState({
      errors: errors
    });
    ref.current.clear();
  }
  toggleSuccess(){
    this.setState({
      updateWallboxes: !this.state.updateWallboxes
    })
  }
  toggleFreeWallboxes(id){
    this.setState({
      openFreeWallboxes: !this.state.openFreeWallboxes,
      userid: id
    })
  }
  updateSelectedWallboxes() {
    var url = baseUrl.WebPath + "chargers/clouduser/" + this.state.userid + "/freechargers";

    let selectedWallboxes = this.state.selectedWallboxes;

    fetch(url, {
      method: "PUT",
      body: JSON.stringify([...selectedWallboxes]),
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.message == "success") {
          this.toggleFreeWallboxes();
          this.toggleSuccess();
        }
      })
      .catch((error) => {
        console.log(error, "update all selected wallboxes api failed")
      })
  }
  updateCheckboxSelection(event, item) {
    debugger
    const allItems = [...this.state.selectedWallboxes];
    const deletedWallboxes = [...this.state.deletedWallboxes];
    const selectedItemIndex = allItems.findIndex(el => el === item);
    if (event.target.checked) {
      if (selectedItemIndex < 0) {
        allItems.push(item);
      }
    } else {
      const deletedWallboxIndex = this.state.availableWallboxes.findIndex(el => el === item);
      const isAlreadyDeleted = deletedWallboxes.findIndex(el => el === item) > -1;
      if (deletedWallboxIndex > -1 && !isAlreadyDeleted) {
        deletedWallboxes.push(item);
      }
      allItems.splice(selectedItemIndex, 1);
    }
    this.setState({
      ...this.state,
        selectedWallboxes: allItems,
        deletedWallboxes
    });
  }
  isItemSelected(item) {
    const retVal = this.state.selectedWallboxes.find(el => el === item) ? true : false;
    return retVal;
  }
  removeSelectedItem(item, index) {
    const allItems = [...this.state.selectedWallboxes];
    allItems.splice(index, 1);
    const deletedWallboxIndex = this.state.availableWallboxes.findIndex(el => el === item);
    const deletedWallboxes = [...this.state.deletedWallboxes];
    if (deletedWallboxIndex > -1) {
      deletedWallboxes.push(item);
    }
    this.setState({
      ...this.state,
        selectedWallboxes: allItems,
        deletedWallboxes
    })
  }
  getAllSelectedWallboxes(id){
    var url = baseUrl.WebPath + "chargers/clouduser/" + id + "/freechargers";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        this.setState({
          selectedWallboxes: response
        })
      })
      .catch((error) => {
        console.log(error, "update all selected wallboxes api failed")
      })
  }
  toggleFreeWallboxes(id){
    this.setState({
      openFreeWallboxes: !this.state.openFreeWallboxes,
      userid: id
    })
  }
  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }
  getSearchInventoryList(cpid) {
    
    var url = baseUrl.WebPath + "chargers/search/partial?cpid=" + cpid;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        } else if (resp.status == 200) {
          return resp.json();
        } else if (resp.status == 500) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.status == 500) {
          this.setState({
            options: [],
            intial_data: []
          });
        } else if (response != undefined) {
          this.setState({
            options: response,
            intial_data: response
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop");
      })
  }
  keyPress(e) {
    var key = e.key;
    let fields = this.state.fields;
    let errors = this.state.errors;

    if (e.keyCode === 13) {
      if (fields.emailId === "") {
        errors["selectChargepoint"] = this.props.t('please_enter_email')
        $("#notexists").text("");
        this.setState({ errors });
      } else {
        errors["selectChargepoint"] = "";
        this.setState({ errors }, ()=>{
          this.getUserslist(this.state.id, this.state.pageSize, 1, "enter");
        });
      }
    }

    if (key === "Backspace" || key === "Delete") {
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno === "") {
        fields.emailId = sno;
        this.setState({ fields }, ()=>{
          this.getUserslist(this.state.id, this.state.pageSize, 1);
        })
      }
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9!#$%&'*+-/=_`{|}~.@]");
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.setState({
      pageSize: entries,
      activePage: 1,
      fromDateApi: '',
      toDateApi: ''
    }, ()=>{
      this.getUserslist(this.state.id, entries, 1);
    })
  }
  showEntriesChange1() {
    var entries = document.getElementById("showEntries1").value;
    this.state.pageSize1 = entries;
    this.state.activePage1 = 1;
    this.getAllWallboxes(entries, 1);
  }
  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
        //prev
        this.setState({
            activePage: pageNumber,
            fromDateApi: this.state.curPageFirstCreTime,
            toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
        }, () => {
          this.getUserslist(this.state.id,this.state.pageSize, pageNumber,"",false);
        });
    } else {
        //next
        
        let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
        this.setState({
            activePage: pageNumber,
            fromDateApi: '',
            toDateApi: this.state.curPageLastCreTime,
            prevPageFirstCreTime: this.state.curPageFirstCreTime,
        }, () => {
            if (pageNumber > _datesArrayLength + 1) {
                this.createdDatesArray(this.state.curPageFirstCreTime);
            }
            this.getUserslist(this.state.id,this.state.pageSize, pageNumber,"",false);
        });
    }
} 
handlePageChange1(pageNumber){
  if (pageNumber != this.state.activePage1) {
    var data = this.state.data;
    var pageData = data.get(pageNumber);
    if (pageData == undefined) {
      this.setState({ activePage1: pageNumber }, ()=>{
        this.getAllWallboxes(this.state.pageSize1, pageNumber);
      });
    } else {
      this.setState({ activePage1: pageNumber });
    }
  }
}
  handleClick() {
    let errors = this.state.errors;
    if(this.state.errors["selectChargepoint"]!=="" || $("#notexists").text()!==""){
      let fields = this.state.fields;
      fields.emailId = '';
      errors["selectChargepoint"] = "";
      $("#notexists").text("");
      this.setState({ fields, errors });
    }else{
      let fields = this.state.fields;
      fields.emailId = '';
      errors["selectChargepoint"] = "";
      $("#notexists").text("");
      this.setState({ fields, errors, activePage: 1 }, ()=>{
        this.getUserslist(this.state.id, this.state.pageSize, this.state.activePage);
      });
    }
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    let errors = this.state.errors;
    fields[field] = e.target.value;
    // fields.emailId = document.getElementById('emailId')
    errors["selectChargepoint"] = "";
    this.setState({ fields, errors });
    $("#notexists").text("");
  }

  //change function for user  filter
  onChangeEmail() {    
    let errors = {}
    if (this.state.fields.emailId === "") {
      errors["selectChargepoint"] = this.props.t('please_enter_email')
      this.setState({
        errors: errors
      });
      $("#notexists").text(" ");
    } else {
      errors["selectChargepoint"] = "";
      this.setState({
        errors: errors
      },()=>{
        this.getUserslist(this.state.id, this.state.pageSize, 1, "click");
      });
    }
  }
  onChangeOrg() {
    this.setState({
      activePage: 1,
      fromDateApi: '',
      toDateApi: ''
    }, ()=>{
      this.getUserslist(this.state.id, this.state.pageSize, 1);
    })
  }
  async goLoginHistory() {
    this.props.history.push('/userLoginHistory');
  }
  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }
  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleConfirmation(event) {
    if (this.state.list[0] !== "" && this.state.list[0] !== undefined) {
      this.setState({
        delete_msg:this.props.t('are_you_want_to_delete'),
        deleteHeader:this.props.t('confirmation'),
        deleteButton: "",
        deleteText:this.props.t('close'),
        deletecolor: "danger",
        modal1: !this.state.modal1
      })
    } else {
      this.setState({
        delete_msg:this.props.t('please_select_user'),
        deleteHeader: "Warning",
        deleteButton: "d-none",
        deleteText:this.props.t('ok'),
        deletecolor: "primary",
        modal1: !this.state.modal1
      })
    }
  }
  closeOkPopup() {
    this.setState({
      modal: !this.state.modal
    });
    window.location.reload();
  }
  tagOk1() {
    window.location.reload();
  }
  tagOk2() {
    window.location.reload();
  }
  toggle1() {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }
  closeModel() {
    window.location.reload();
  }
  addNew() {
    this.props.history.push('/addUserManagement');
  }
  // calling function when click on checkbox
  handleChange(id, identifier, event) {
    let list = this.state.list;
    var checkedValue = document.getElementById(id).checked;
    if (checkedValue === true) {
      if (list[0] !== "" && list[0] !== undefined) {
        document.getElementById(list[0]).checked = false;
        document.getElementById(id).checked = true;
      }
      list[0] = id;
      this.setState({
        activeArray: identifier,
        list
      });
    }
    if (checkedValue === false) {
      list[0] = "";
      document.getElementById(id).checked = false;
      this.setState({
        activeArray: "",
        list
      });
    }
    
  }
  async getUserslist(id1, pageSize, pageNo, action, countValue) {
    var role = localStorage.getItem('role');
    let id = "";
    if (id !== "") {
      id = this.state.id;
    } else {
      id = id1;
    }

    this.setState({ id: id })
    id = document.getElementById("organizationid").value;
    localStorage.setItem('orgId', id);
    var roleId = '';
    if(localStorage.getItem('role') === roles.WEBASTO_SUPER_ADMIN_DEVOPS){
      roleId= document.getElementById("roleid").value;
    }
    var el = document.getElementById('organizationid')
    var selectedText = el.options[el.selectedIndex].text
    this.setState({
      org_name: selectedText
    });
    var url = "";
    if(role === roles.WEBASTO_CUSTOMER_ADMIN || role === roles.WEBASTO_SITE_ADMIN || role === roles.WEBASTO_CUSTOMER_ADMIN_READ_ONLY){
      url = TenantUser.GET_USERS +"?pageSize=" + pageSize + "&pageNo=" + pageNo + "&email=" + this.state.fields.emailId + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi +"&role=" + roleId;
    }else if(role === roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || role === roles.WEBASTO_SUPER_ADMIN_DEVOPS){
      url = PlatformUser.GET_USERS +"?pageSize=" + pageSize + "&pageNo=" + pageNo + "&email=" + this.state.fields.emailId + "&id=" + id + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi+"&role=" + roleId;
    }else if(role === roles.WEBASTO_END_USER_RESIDENTIAL || role === roles.WEBASTO_END_USER_COMMERCIAL){
      url = GeneralUser.GET_USERS +"?pageSize=" + pageSize + "&pageNo=" + pageNo + "&email=" + this.state.fields.emailId + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi+"&role=" + roleId;
    }
    document.getElementById("loader_image_div").style.display = "block";

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

    if (response !== undefined) {
      if (response.count.count === 0) {
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
          if (action === "click" || action === "enter") {
            $("#notexists").text(this.props.t('user_not_exists'));
          }else{
            $("#notexists").text(this.props.t('user_not_exists'));
          }
        }, 100);
      } else {
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
          if (countValue === false) {
            this.setState({
              userArray: response.userList,
              prevPageFirstCreTime: this.state.curPageFirstCreTime,
              curPageFirstCreTime: moment(response.userList[0].createdDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              curPageLastCreTime: moment(response.userList[response.userList.length - 1].createdDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
          });
          } else {
            this.setState({
              userArray: response.userList,
              prevPageFirstCreTime: this.state.curPageFirstCreTime,
              noOfRecords: response.count.count,
              curPageFirstCreTime: moment(response.userList[0].createdDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              curPageLastCreTime: moment(response.userList[response.userList.length - 1].createdDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
            });
          }
            $("#notexists").text("");
        }, 100);
      }
    }
  }
  async activeUsers(status) {
    var role = localStorage.getItem('role');
    var url = "";

    if (this.state.activeArray.length !== 0) {
      if (status === true) {
        if (role === roles.WEBASTO_CUSTOMER_ADMIN || role === roles.WEBASTO_SITE_ADMIN) {
          url = TenantUser.ACTIVATE_USER + this.state.activeArray;
        } else if (role === roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || role === roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
          url = PlatformUser.ACTIVATE_USER + this.state.activeArray;
        } else if (role === roles.WEBASTO_END_USER_RESIDENTIAL || role === roles.WEBASTO_END_USER_COMMERCIAL) {
          url = GeneralUser.ACTIVATE_USER + this.state.activeArray;
        }
      } else {
        if (role === roles.WEBASTO_CUSTOMER_ADMIN || role === roles.WEBASTO_SITE_ADMIN) {
          url = TenantUser.DEACTIVATE_USER + this.state.activeArray;
        } else if (role === roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || role === roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
          url = PlatformUser.DEACTIVATE_USER + this.state.activeArray;
        } else if (role === roles.WEBASTO_END_USER_RESIDENTIAL || role === roles.WEBASTO_END_USER_COMMERCIAL) {
          url = GeneralUser.DEACTIVATE_USER + this.state.activeArray;
        }
      }
      let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (status === false) {
      this.toggle1()
    } else {
      this.toggle2()
    }
    } else if (this.state.activeArray.length === 0) {
      this.toggleConfirmation();
    }
  }


  componentDidMount() {
    //to clear saved filters when click on F5 or click on page reload
    window.onload = () => {
      this.removeFilterDetails();
    }

    if(localStorage.getItem('role') === roles.WEBASTO_SUPER_ADMIN_DEVOPS){
      this.getRoles();
    }else{
      this.initialRender();
    }
    this.getCustomization();
  }
  
  async getCustomization() {
    let tenant = localStorage.getItem('tenant');
    let url = baseUrl.URLPath + servicesUrl.getCustomizationWithTenant + tenant;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if(response){
        if (response && Array.isArray(response)) {
            const settingsObj = response.find(el => el.operation === "Settings");
            if (settingsObj) {
                const subOperations = settingsObj.subOperations;
                const paymentGatewaySubOperation = subOperations.find(subOp => subOp.link === "paymentGateway");
                if (paymentGatewaySubOperation) {
                  this.setState({ isPaymentGateway: paymentGatewaySubOperation.access });
                } else {
                    console.log("Payment Gateway subOperation not found.");
                }
            }
        }
    }
}
  async initialRender(){
    let user = this.state.user;
    user.permissions = localStorage.getItem("roleAccess");
    var orgname = localStorage.getItem("tenant");
    let fields = this.state.fields;

    this.setState({
      org_name: orgname, user
    });

    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({ dataArray: response.organisationDTOS }, ()=>{
        //retrieving past filters data 
        if(localStorage.getItem('filterEmail')){
          fields['emailId'] = "";
          this.setState({ fields });
        }
        if(localStorage.getItem('filterOrganization')){
          document.getElementById("organizationid").value = localStorage.getItem('filterOrganization')
        }
        if(localStorage.getItem('filterRole')){
          document.getElementById("roleid").value = localStorage.getItem('filterRole')
        }
        if(localStorage.getItem('filterShowEntries')){
          this.setState({ pageSize: localStorage.getItem('filterShowEntries') });
        }
        if(localStorage.getItem('filterFromDate')){
          this.setState({ fromDateApi: localStorage.getItem('filterFromDate') });
        }
        if(localStorage.getItem('filterToDate')){
          this.setState({ toDateApi: localStorage.getItem('filterToDate') });
        }
        if(localStorage.getItem('filterActivePage')){
          this.setState({ activePage: parseInt(localStorage.getItem('filterActivePage')) });
        }
        if(localStorage.getItem('filterCurPageFirstCreTime')){
          this.setState({ curPageFirstCreTime: localStorage.getItem('filterCurPageFirstCreTime') });
        }
        if(localStorage.getItem('filterCurPageLastCreTime')){
          this.setState({ curPageLastCreTime: localStorage.getItem('filterCurPageLastCreTime') });
        }
        if(localStorage.getItem('filterPrevPageFirstCreTime')){
          this.setState({ prevPageFirstCreTime: localStorage.getItem('filterPrevPageFirstCreTime') });
        }
        if(localStorage.getItem('filterNoOfRecords')){
          this.setState({ noOfRecords: localStorage.getItem('filterNoOfRecords') }, ()=>{
            if(this.state.noOfRecords > 0){
              this.getUserslist(first_org, this.state.pageSize, this.state.activePage, '', false);
            }
          });
        }else{
          var user_data = [];
          user_data = response.organisationDTOS;
          var first_org = "";
          for (var i = 0; i <= user_data.length; i++) {
            first_org = user_data[0].id;
          }
          this.getUserslist(first_org, this.state.pageSize, this.state.activePage);
        }
        if(localStorage.getItem('filterListOfFirstCreatedtimes')){
          this.setState({ listOfFirstCreatedtimes: JSON.parse(localStorage.getItem('filterListOfFirstCreatedtimes')) })
        }
      })
    } else {
      this.getNonWebastoUsers();
    }
  }

  async getNonWebastoUsers() {
    var url = baseUrl.URLPath + "v1.0/tenant/Nonwebasto";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({ userArray: response.userDTOS });
    }
  }
  async deleteUser() {
    
    this.setState({ loading: true })
    var role = localStorage.getItem('role');
    this.setState({
      modal1: !this.state.modal1
    });
    var id;
    id = this.state.id;
    this.setState({ id: id });
    var url = "";
    if (role === roles.WEBASTO_CUSTOMER_ADMIN || role === roles.WEBASTO_SITE_ADMIN) {
      url = TenantUser.DELETE_USER + this.state.activeArray;
    } else if (role === roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || role === roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
      url = PlatformUser.DELETE_USER + this.state.activeArray;
    } else if (role === roles.WEBASTO_END_USER_RESIDENTIAL || role === roles.WEBASTO_END_USER_COMMERCIAL) {
      url = GeneralUser.DELETE_USER + this.state.activeArray;
    }

    let type = ApiMethodTypes.DELETE;
    let headers = getSecurityHeaders();
    let payload = {};

    await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if(localStorage.getItem('userId') === this.state.activeArray){
      localStorage.setItem('Authorization','');
      this.props.history.push('/');
      window.location.reload();
    }
    this.setState({
      loading: false
    }, () => {
      this.setState({ modal: !this.state.modal })
    })
    // this.setState({
    //   modal: !this.state.modal
    // });
  }
  async getRoles() {
    var url = baseUrl.URLPath + "roles/";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({ roles: response.roles }, ()=>{
        this.initialRender();
      })
    }
  }

  saveFilterDetails(){
    var id = document.getElementById("organizationid").value;
    var role = document.getElementById("roleid")?.value ?? '';
    localStorage.setItem('filterEmail', this.state.fields['emailId']);
    localStorage.setItem('filterOrganization', id);
    if(localStorage.getItem('role') === roles.WEBASTO_SUPER_ADMIN_DEVOPS){
      localStorage.setItem('filterRole', role);
    }
    localStorage.setItem('filterShowEntries', this.state.pageSize);
    localStorage.setItem('filterFromDate', this.state.fromDateApi);
    localStorage.setItem('filterToDate', this.state.toDateApi);
    localStorage.setItem('filterActivePage', this.state.activePage);
    localStorage.setItem('filterCurPageFirstCreTime', this.state.curPageFirstCreTime);
    localStorage.setItem('filterCurPageLastCreTime', this.state.curPageLastCreTime);
    localStorage.setItem('filterPrevPageFirstCreTime', this.state.prevPageFirstCreTime);
    localStorage.setItem('filterNoOfRecords', this.state.noOfRecords);
    localStorage.setItem('filterListOfFirstCreatedtimes', JSON.stringify(this.state.listOfFirstCreatedtimes));
  }

  removeFilterDetails(){
    localStorage.removeItem('filterEmail');
    localStorage.removeItem('filterOrganization');
    localStorage.removeItem('filterRole');
    localStorage.removeItem('filterShowEntries');
    localStorage.removeItem('filterFromDate');
    localStorage.removeItem('filterToDate');
    localStorage.removeItem('filterActivePage');
    localStorage.removeItem('filterCurPageFirstCreTime');
    localStorage.removeItem('filterCurPageLastCreTime');
    localStorage.removeItem('filterPrevPageFirstCreTime');
    localStorage.removeItem('filterNoOfRecords');
    localStorage.removeItem('filterListOfFirstCreatedtimes');
  }

  componentWillUnmount(){
    // if(window.location.pathname !== '/editUser'){
    //   this.removeFilterDetails();
    // } 
  }

  unblockalert(id) {
    this.setState({
      unblockmodal: !this.state.unblockmodal,
      user_id: id
    });
  }

  unblocksuccess(){
    this.setState({
      unblocksuccess: !this.state.unblocksuccess
    })
  }

  unblockAccount(id){
    let url = baseUrl.URLPath + servicesUrl.unblockUser + id;

    fetch(url, {
        method: ApiMethodTypes.PUT,
        headers:getSecurityHeaders()
    }).then(resp => {
        if (resp.status === 401) {
            setTimeout(() => {
                document.getElementById("loader_image_div").style.display = "none";
                // this.togglesession();
                // localStorage.clear();
                window.location.reload();
            }, 100);
        } else {
            return resp.json();
        }
    }).then((response) => { 
      if(response.message === 'user_account_unblocked'){
        this.unblockalert('');
        this.unblocksuccess();
      }
    }).catch((error) => {
            console.log(error, 'error in activate and deactivate reservation api');
    });
  }

  closeUblockSuccess(){
    this.unblocksuccess();
    if(localStorage.getItem('role') === roles.WEBASTO_SUPER_ADMIN_DEVOPS){
      this.getRoles();
    }else{
      this.initialRender();
    }
  }
    // api to get chargepointId based on search filter
    chargepointIDChange(cpid) {
      debugger
      this.state.selectFindStatus = true;
  
      this.state.saveSelectedCpid = cpid
      this.setState({ saveSelectedCpid: cpid })
      if (cpid.length == 0) {
        this.getAllWallboxes(this.state.pageSize, this.state.activePage)
      } else {
        let errors = {};
        errors["chargePointNotExists"] = "";
        errors["selectChargepointId"] = "";
        this.setState({
          errors: errors
        });
        this.getInventorybasedonchargepoint(this.state.pageSize, this.state.activePage, cpid);
      }
  
    }
    FindCpName(ref, event) {
      this.state.saveSelectedCpid = ref.current.inputNode.value;
      var errors = {};
      if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
        errors["selectChargepointId"] = "Please select the serial number.";
        this.setState({
          errors: errors
        });
        this.state.pageSize = 10;
        this.state.activePage = 1;
        this.getAllWallboxes(this.state.pageSize, this.state.activePage)
      } else {
        errors["selectChargepointId"] = "";
        this.state.selectFindStatus = true;
        this.state.pageSize = 10;
        this.state.activePage = 1;
        this.getInventorybasedonchargepoint(this.state.pageSize, this.state.activePage);
      }
  
      var serial_no = event.target.value;
  
      if (serial_no.length > 1) {
        $("#selectcharge").hide();
      } else {
        $("#selectcharge").show();
      }
    }
    getInventorybasedonchargepoint(pageSize, pageNo, saveSelectedCpid) {
      debugger
      var errors = {};
      if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
        errors["selectChargepointId"] = "Please select the serial number.";
        this.setState({
          errors: errors
        });
      } else {
        if (this.state.selectFindStatus == true) {
          var url = baseUrl.WebPath + "chargers/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;
        }
        else {
          var url = baseUrl.WebPath + "chargers/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;
        }
  
        fetch(url, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization")
          }
        })
          .then((resp) => {
            if (resp.status == 401) {
              this.togglesession();
              localStorage.clear();
            } else if (resp.status == 404) {
              errors["chargePointNotExists"] = "Serial Number not exists.";
              this.setState({
                errors: errors
              });
            } else if (resp.status == 200) {
              return resp.json();
            }
          })
          .then((response) => {
            debugger
            if (response != undefined) {
              
              if (response.count.count == 0) {
                errors["chargePointNotExists"] = "Serial Number not exists.";
                this.setState({
                  errors: errors
                });
                $("#nochargeexists").show();
              } else {
                this.state.activeArray = "";
                $("#nochargeexists").hide();
                let wallboxes = []
                let x = response.cpList?.map((list,index)=>{
                  wallboxes.push(list.chargePointId)
                })
                this.setState({
                  availableWallboxes: wallboxes, 
                  noOfRecords1: response.count.count
                });
              }
              console.log(this.state.availableWallboxes, "test");
            }
          })
          .catch((error) => {
            console.log(error, "catch the loop")
          })
      }
    }
    
  getAllWallboxes(pageSize, pageNo) {
    
    // var url = baseUrl.WebPath + "chargers/clouduser/allchargers?pageNo=1&pageSize=10";
    var url = baseUrl.WebPath + "chargers/clouduser/allchargers?pageSize=" + pageSize +"&pageNo=" + pageNo;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
    .then((resp) => {
      if (resp.status == 401) {
        this.togglesession();
      }
      if (resp.status == 200) {
        return resp.json();
      }
    })
    .then((response) => {
      if (response != undefined) {
        this.setState({ availableWallboxes: response.chargers, noOfRecords1: response.count.count })
      }
    })
    .catch((error) => {
      console.log(error, "get all free wallboxes api failed")
    })
  }
  handleKeyDown(ref, e) {
    var serial_no = e.target.value;

    $("#nochargeexists").hide();

    if (serial_no.length > 1) {
      $("#selectcharge").hide();
    } else {
      $("#selectcharge").show();
    }

    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false);
    var sno = serial_no.slice(0, serial_no.length - 1);

    this.getSearchInventoryList(sno);

    if (key == "Backspace" || key == "Delete") {
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno == "") {
        this.state.saveSelectedCpid = sno;
        this.getAllWallboxes(this.state.pageSize, this.state.activePage)
      }
    }

    if (e.keyCode == 13) {
      $("#selectcharge").hide();
      this.FindCpName(ref, e);
      $("#rbt_id").hide();
    }

    if ((e.keyCode == 86 && ctrl)) {
      $("#selectcharge").hide();
      this.getSearchInventoryList(serial_no)
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9 -]");
        var key = e.key;
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }
  render() {
    const ref = React.createRef();
    const{t}=this.props;

    let userTenant = localStorage.getItem("tenant");
    const user = this.state.user;
    let selectedId = "";

    const organisationDTOS = this.state.dataArray.map((dataArray, index) => {
      if (userTenant === dataArray.identifier) {
          selectedId = dataArray.id
      }
      return (
        <option value={dataArray.id} selected={selectedId === dataArray.id} >{dataArray.company}</option>
      )
    })

    const roleDTOS = this.state.roles.map((dataArray, index) => {
      return (
        <option value={dataArray.identifier}>{dataArray.name}</option>
      )
    })

    const userDTOS = this.state.userArray.map((userArray, index) => {

      // logic for getting rfids by comma saparated
      var dictRfids = userArray.rfids;
      var getRfids = [];

      if (dictRfids !== undefined) {
        for (var i = 0; i < dictRfids.length; i++) {
          getRfids.push(dictRfids[i]["rfidTag"]);
        }
      }

      var commaSepArray = getRfids.join(", ");

      if (userArray.status === true) {
        userArray.status = "Active";
      } else {
        if (userArray.status === false) {
          userArray.status = "Inactive";
        }
      }
      return (
        <tr>
          <td className="text-center">
            <Form.Check
              custom
              type="checkbox"
              id={`custom-${userArray.id}`}
              label=""
              onChange={this.handleChange.bind(this, `custom-${userArray.id}`, userArray.id)}
              key={userArray.id}
              className="checkbox"
            />
          </td>
          <td>{userArray.id}</td>
          {hasPermission(user, ['UserDetail_R']) &&
            <td>{userArray.email}</td>}
          <td> {commaSepArray}</td>
          {hasPermission(user, ['UserDetail_R']) &&
            <td>{userArray.firstName}</td>}
          {hasPermission(user, ['UserDetail_R']) &&
            <td>{userArray.lastName}</td>}
          <td>{userArray.role}</td>
          <td>{userArray.status}</td>
          {
            roles.WEBASTO_SUPER_ADMIN_DEVOPS === localStorage.getItem('role') ? 
            <>
            {
            userArray.blocked ?
              <td className="text-center">
                <Form.Check
                  custom
                  type="checkbox"
                  id={`blocked-${userArray.id}`}
                  label=""
                  onChange={this.unblockalert.bind(this, userArray.id)}
                  className="checkbox"
                  checked = {userArray.blocked}
                />
              </td> 
              :
              <td className="text-center">
                <Form.Check
                  custom
                  type="checkbox"
                  id={`blocked-${userArray.id}`}
                  label=""
                  className="checkbox"
                  disabled
                  checked = {userArray.blocked}
                />
              </td>
          }
            </> : <></>
          }
          {hasPermission(user, ['Users_U']) && <td>
            <Link to={{
              pathname: '/editUser',
              state: userArray.id,
            }}>
              <i class="fas fa-pencil-alt pr-2 action-icon edit_usericon" title="Edit" onClick={this.saveFilterDetails}></i>
            </Link>
          </td>}
          {JSON.parse(localStorage.getItem("showDXProperties")) && (this.state.isPaymentGateway === true) ? (
          <td>
          {
            (userArray.role == roles.WEBASTO_END_USER_COMMERCIAL || userArray.role == roles.WEBASTO_END_USER_RESIDENTIAL) ? <span className='edit_link' onClick={() => {
              this.toggleFreeWallboxes(userArray.id);
              this.getAllWallboxes(10, 1);
              this.getAllSelectedWallboxes(userArray.id);
            }}>Edit</span> : <div > - </div>
          }
          </td>
          ) : ""}
        </tr>
      )
    });

    let tenantDisable = false;

    if(this.state.selectedSite && this.state.selectedSite?.name){
      tenantDisable = true;
    }

    return (
      <>
        <main class="content-div" >
          <p>{t('Users')}
            <div className="breadcrumb_div">
              {t('Settings')} &gt; <span className="breadcrumb_page"> {t('Users')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
            <div className="row">
              <div className="col-md-12 ">
                <div className="menubar pull-left">
                  {hasPermission(user, ['Users_W']) && <button onClick={this.addNew}>
                    <i className="fa fa-plus">
                    </i>
                    <span>{t('add')}</span>
                  </button>}
                  {hasPermission(user, ['Users_AD']) && <button onClick={this.activeUsers.bind(this, true)} >
                    <i className="fas fa-toggle-on">
                    </i>
                    <span>{t('active')}</span>
                  </button>}
                  {hasPermission(user, ['Users_AD']) && <button onClick={this.activeUsers.bind(this, false)} >
                    <i className="fas fa-toggle-off">
                    </i>
                    <span>{t('deactive')}</span>
                  </button>}
                  {hasPermission(user, ['Users_D']) && <button onClick={this.toggleConfirmation}>
                    <i className="fas fa-trash">
                    </i>
                    <span>{t('delete')}</span>
                  </button>}
                </div>
                <button class="btn_primary pull-right mt-0  mr-0" type="button" onClick={this.goLoginHistory} >
                 {t('view_login_history')}
                </button>
              </div>
            </div>
            <div className="row  mt-3">
              <MDBCol md="3" className="mt-2" >
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="emailId" onKeyDown={this.keyPress} value={this.state.fields["emailId"]} style={{ width: "60%" }} onChange={this.changeHandler.bind(this, "emailId")} class="form-control input-searchBox pad-2" placeholder={t('enter_email_id')} name="adminAddress" />
                  <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeEmail} >{t('find')}</button>
                  </div>
                  <span className="error_msg w3-animate-top mt-2"><span style={{ color: "red" }}>{this.state.errors["selectChargepoint"]}</span></span>
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>
                </div>
              </MDBCol>
              <div className="col-md-3 mt-0">
                <select className="browser-default custom-select select_height " id="organizationid" onChange={this.onChangeOrg.bind(this)} disabled={tenantDisable}>
                  {organisationDTOS}
                </select>
              </div>
              <div className="col-md-3 pull-right mt-0">
              {
                localStorage.getItem('role') === "WEBASTO_SUPER_ADMIN_DEVOPS" &&
                  <select className="browser-default custom-select select_height" id="roleid" onChange={this.onChangeOrg.bind(this)}>
                    <option value=''>-- Select Role --</option>
                    {roleDTOS}
                  </select>
              }
              </div>
              <div className="col-md-3 mt-2">
                <div className="pull-right">
                  <label className="sub-text pull-left" >{t('show_entries')}</label>
                  <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </div>
            {/* {/ end page menu bar  /}
          
          {/ table start /} */}
            <div className="row mt-2 ">
              <div className="col-md-12 table-scroll">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="text-center"></th>
                      <th>{t('transactionId')}</th>
                      {hasPermission(user, ['UserDetail_R']) && <th>{t('email')}</th>}
                      <th>{t('idTag')}</th>
                      {hasPermission(user, ['UserDetail_R']) && <th>{t('firstName')}</th>}
                      {hasPermission(user, ['UserDetail_R']) && <th>{t('lastName')}</th>}
                      {/* <th>Mobile No</th> */}
                      <th>{t('role')}</th>
                      <th>{t('chargingSessionState')}</th>
                      {
                        roles.WEBASTO_SUPER_ADMIN_DEVOPS === localStorage.getItem('role') ? 
                        <th>Blocked?</th> : ''
                      }
                      {hasPermission(user, ['Users_U']) && <th>{t('action')}</th>}
                      {JSON.parse(localStorage.getItem("showDXProperties")) && (this.state.isPaymentGateway === true) ? (                  
                      <th>Free User</th>
                      ) : ""}
                    </tr>
                  </thead>
                  <tbody>
                    {userDTOS}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  // hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                  prevPageText={t('prev')}
                  nextPageText={t('next')}
                  itemClassPrev="prevBtn"
                  pageRangeDisplayed="1"
                  activeClass="activeLi active"
                  itemClassNext="nextBtn"
                  hideFirstLastPages={true}
                  disableInitialCallback={true}
                  disabledClass="disabled disabledtextColor"
                />
              </div>
              {/* {/ End table  /} */}
            </div>
          </div>
          {/* Loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>

          {/* User De - Activate Success Popup */}
          <MDBModal isOpen={this.state.modal3} toggle={this.toggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle1}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('user_deactive_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.tagOk1}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* User Activation Success Popup */}
          <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
             {t('user_activated_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
            <button type='button' className='btn_primary' onClick={this.tagOk2}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* User Deletion Popup */}
          <MDBModal isOpen={this.state.modal1} toggle={this.toggleConfirmation} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleConfirmation}> {this.state.deleteHeader}</MDBModalHeader>
            <MDBModalBody>
              {this.state.delete_msg}
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.toggleConfirmation}>{this.state.deleteText}</button>
              {/* <MDBBtn color={this.state.deletecolor} onClick={this.toggleConfirmation}>{this.state.deleteText}</MDBBtn> */}
              <button type='button' className={`btn_primary ${this.state.deleteButton}`} onClick={this.deleteUser}>{t('yes')}</button>
              {/* <MDBBtn color="primary" className={} onClick={this.deleteUser}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          {/* User Deletion Success Popup */}
          <MDBModal isOpen={this.state.modal} backdrop="static" data-backdrop="static" data-keyboard="false" size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
             {t('user_deleted_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.closeOkPopup}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.sessiontagOk}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

           {/* popup for unblock alert */}
           <MDBModal isOpen={this.state.unblockmodal} size="md" className="model_top">
            <MDBModalHeader>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('unblock_confirmation')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.unblockAccount.bind(this, this.state.user_id)}>{t('yes')}</button>
              <button type="button" data-test="button" className="btn_primary" onClick={this.unblockalert}>{t('no')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for unblock alert */}
          <MDBModal isOpen={this.state.unblocksuccess} size="md" className="model_top">
            <MDBModalHeader>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('user_account_unblocked')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.closeUblockSuccess}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.openFreeWallboxes} size="lg" className="model_top freeuseredit">
            <MDBModalHeader>{t('free_wallboxes')}</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="12">
                  {
                    this.state.selectedWallboxes.map((el, index) =>
                      <span key={index} className="chargepoint_span">
                        {el} <i class="fa fa-times" onClick={() => this.removeSelectedItem(el, index)}></i>
                      </span>
                    )
                  }
                </MDBCol>
              </MDBRow>
              <MDBRow>
              <div className="col-md-6 pull-right mt-2">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    multiple={this.state.multiple}
                    options={this.state.options}
                    id="rbt_id"
                    minLength={3}
                    placeholder="Choose a Serial Number..."
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    onChange={this.chargepointIDChange.bind(this)}
                    ref={ref}
                  />
                  <button type="button" className="close close_icon" id="clearInput" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)}>Find</button>
                  </div>
                  <span className="pull-left error_msg w3-animate-top mt-1" id="nochargeexists"><span style={{ color: "red" }}>{this.state.errors["chargePointNotExists"]}</span></span>
                  <span className="pull-left error_msg w3-animate-top mt-1" id="selectcharge"><span style={{ color: "red" }}>{this.state.errors["selectChargepointId"]}</span></span>
                </div>
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-3 mt-2">
                <div className="pull-right">
                  <label className="sub-text pull-left" >Show entries</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries1" onChange={this.showEntriesChange1}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
                <MDBCol md="12" className="chargepoint_listdiv" >
                  <ul>
                    {
                      this.state.availableWallboxes?.map((el, index) =>
                        <li key={index}>
                          <Form.Check
                            custom
                            type="checkbox"
                            id={`${el}_${index}`}
                            label={el}
                            checked={this.isItemSelected(el)}
                            onChange={(event) => this.updateCheckboxSelection(event, el)}
                          />
                        </li>
                      )
                    }
                  </ul>
                </MDBCol>
                <div className="col-md-12 mt-2 mb-4 text-right">
                  <Pagination
                    hideDisabled
                    activePage={this.state.activePage1}
                    itemsCountPerPage={this.state.pageSize1}
                    totalItemsCount={this.state.noOfRecords1}
                    onChange={this.handlePageChange1.bind(this)}
                  />
                </div>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={this.toggleFreeWallboxes}>{t('close')}</MDBBtn>
              <MDBBtn color="primary" onClick={this.updateSelectedWallboxes}>{t('update')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.updateWallboxes} size="md" className="model_top">
            <MDBModalHeader>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('free_wallboxes_updated_successfully') }
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.toggleSuccess}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {this.state.loading ? (
            <Loader loader={this.state.loading} />
            ) : ("")}

        </main>
      </>
    );
  }
}

export default withTranslation()(userManagementController);