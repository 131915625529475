import React from 'react';
import * as baseUrl from '../views/constants';
import { withTranslation } from 'react-i18next';
import InvalidQRCode from './InvalidQRCode';
// const navigate = useNavigate();
class SelectCharger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: "",
            charegimg: "",
            qrCodeValidate: false,
            listofchargers: [],
            idTag: "",
            cpId: "",
            totalEnergyConsumption: "",
            aliasName: "",
            transactionStatus: "",
            isIntransaction:false,
            chargePointModel:"",
            transactionRfid:"",
            message : '',
            isEnergyLimitReached : false,
            paymentSplitDetails: [],
            costPerkWh: "",
            currencySymbol: ''
        }
        this.handleClickRow = this.handleClickRow.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.backButton = this.backButton.bind(this)
        this.getCPInformation = this.getCPInformation.bind(this)
        this.getInformation = this.getInformation.bind(this)
    }
    getInformation() {
        this.props.setPage('informationpage');
        localStorage.setItem('page', 'informationpage');
    }
    getCPInformation(cpObj) {
        this.props.setPage('cpinformationpage');
        localStorage.setItem('page', 'cpinformationpage');
        localStorage.setItem('cpDetails',JSON.stringify(cpObj))
    }
    getMessageType() {
        return baseUrl.REMOTE_START_TRANSACTION;
    }

  getPayLoad() {
    var payload ={
      idTag: this.state.idTag,
      connectorId: 1,
    //   "chargingProfile" : this.state.fields.chargingProfile,
    };
    return payload;
  }

    async handleSubmit() {
        if (this.state.clicked !== "" && this.state.clicked !== null && this.state.clicked !== undefined) {
            if (this.state.transactionStatus === "activeCharging" || (this.state.transactionRfid && this.state.idTag && this.state.transactionRfid === this.state.idTag)) {
                this.props.setPage('QRCodeDeactivated');
                localStorage.setItem('page', 'QRCodeDeactivated');
            } else {
                var id = localStorage.getItem('qrId');
                localStorage.setItem('cp', this.state.cpId);
                localStorage.setItem('rfid', this.state.idTag);
                localStorage.setItem('modelType', this.state.chargePointModel);
                let type = this.getMessageType();
                var data = {
                    "type": type,
                    "payload": this.getPayLoad(),
                    "cplist": [this.state.cpId]
                };

                var timeZone = localStorage.getItem("timezone");
                let url = baseUrl.LoginPath + "/qrcode/charging/cs/operations/"+type+"?qrId=" + id;

               await  fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        "content-type": "application/json",
                        "Authorization": localStorage.getItem("Authorization"),
                        "timeZone": timeZone
                    }
                }).then(resp => {
                  if (resp.status === 401 || resp.status === 404 || resp.status === 500) {
                        localStorage.setItem('page', 'QRCodeDeactivated');
                        this.props.setPage('QRCodeDeactivated');
                        localStorage.setItem('prePage', 'selectCharger');
                    }else if(resp.status===400){
                        return resp.json();
                    } else if (resp.status === 200) {
                        return resp.json();
                    }
                }).then(response => {
                    if (response && response[0] === "3") {
                        localStorage.setItem('page', 'chargingprocess');
                        this.props.setPage('chargingprocess');
                    }else if(response.status===400 && response.message === "Charger is already in use. Please contact your administrator."){
                        console.log("Charger is already in use. Please contact your administrator.");
                    }
                }
                ).catch(error => {
                    console.log(error)
                });
            }
        } else {
            console.log("select charger")
        }
    }

    backButton() {
        localStorage.setItem('page', '');
        this.props.setPage('');
    }

    handleClickRow(id, cp, e) {
        
        if (cp.transactionStatus === "activeCharging" || (cp.transactionRfid && cp.ocppTag && cp.transactionRfid === cp.ocppTag)) {
            this.setState({
                clicked:id,
                transactionStatus:cp.transactionStatus,
                idTag:cp.ocppTag,
                cpId:cp.chargePointId,
                chargePointModel:cp.chargePointModel,
                transactionRfid:cp.transactionRfid
            })
            localStorage.setItem('cp', cp.chargePointId);
            localStorage.setItem('aliasName', cp.aliasName);
            localStorage.setItem('rfid', cp.ocppTag);
            localStorage.setItem('modelType', cp.chargePointModel);
            localStorage.setItem('clicked', id);
            localStorage.setItem('transactionStatus', cp.transactionStatus);
            localStorage.setItem('idTag', cp.ocppTag);
            localStorage.setItem('transactionRfid', cp.transactionRfid);
            this.props.setPage('chargingprocess');
            localStorage.setItem('page', 'chargingprocess');
            
            // this.props.setPage('addpayment');
            // localStorage.setItem('page', 'addpayment');
        } else {
            if(this.state.paymentSplitDetails.paymentModeActive) {                
                this.setState({
                    clicked: id,
                    idTag: cp.ocppTag,
                    cpId: cp.chargePointId,
                    totalEnergyConsumption: cp.totalEnergyConsumption,
                    aliasName: cp.aliasName,
                    chargePointModel: cp.chargePointModel
                }, () => {
                    this.props.setPage('addpayment');
                    localStorage.setItem('page', 'addpayment');
                    
                    localStorage.setItem('cp', cp.chargePointId);
                    localStorage.setItem('aliasName', cp.aliasName);
                    localStorage.setItem('rfid', cp.ocppTag);
                    localStorage.setItem('modelType', cp.chargePointModel);
                    localStorage.setItem('clicked', id);
                    localStorage.setItem('transactionStatus', cp.transactionStatus);
                    localStorage.setItem('idTag', cp.ocppTag);
                    localStorage.setItem('transactionRfid', cp.transactionRfid);
                })
        } else {
            this.setState({
                clicked: id,
                idTag: cp.ocppTag,
                cpId: cp.chargePointId,
                totalEnergyConsumption: cp.totalEnergyConsumption,
                aliasName: cp.aliasName,
                chargePointModel: cp.chargePointModel
            }, () => {                              
                this.handleSubmit();
            })            
        }
        }
    }

    componentDidMount() {
        this.getChargerList();
    }
    
    getChargerList() {
        
        var id = localStorage.getItem('qrId');
        var Apiurl = baseUrl.LoginPath + "/qrcode/charging/chargers?qrId=" + id;
        fetch(Apiurl, {
            method: "GET",
            headers: {
                "content-type": "application/json",
            }
        })
        .then(resp =>{
            return resp.json();
        })
        .then(resp => {
                if (resp.status === 401 || resp.status === 500 || resp.status === 404) {
                localStorage.setItem('page', 'QRCodeDeactivated');
                this.props.setPage('QRCodeDeactivated');
                localStorage.setItem('prePage', 'selectCharger');
            } 
            else if(resp.status === 400){
                this.setState({message : resp.message, isEnergyLimitReached : true})
            
            }
            else {
                const isIntransactionv = resp.chargersData?.find((val)=> {
                    return val.transactionStatus === "activeCharging" || (val.transactionRfid === val.ocppTag )
                })
                this.setState({
                    clicked:"",
                    listofchargers: resp.chargersData,
                    isIntransaction: isIntransactionv,
                    costPerkWh: resp.costPerkWh,
                    costPerHr: resp.costPerHr,
                    chargingType: resp.chargingType,
                    currencySymbol: resp.currencySymbol,
                    currencyCode: resp.currencyCode,
                    paymentSplitDetails: resp.paymentSplitDetails
                });
                localStorage.setItem("stripePublishKey", resp.stripePublishKey);                    
                localStorage.setItem("stripeSecretKey", resp.stripeSecretKey);                    
                localStorage.setItem("stripeAccountId", resp.stripeAccountId);
                localStorage.setItem("costPerkWh", resp.costPerkWh);
                localStorage.setItem("currencySymbol", resp.currencySymbol);
                localStorage.setItem("currencyCode", resp.currencyCode);
                localStorage.setItem("chargingSessionCurrency", resp.currencySymbol);
                localStorage.setItem("platformShare", resp.paymentSplitDetails.splitDetails.platformShare);
                localStorage.setItem("directPaymentEnabled", resp.paymentSplitDetails.directPaymentEnabled);
                localStorage.setItem("chargingType", resp.chargingType);
                localStorage.setItem("costPerHr", resp.costPerHr);
            }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }

    render() {
        const{t}=this.props;
        const {chargingType, costPerkWh, costPerHr} = this.state;

        if(!this.state.isEnergyLimitReached){
            return (
                <>
                    <div className="welcome-parent">
                        <div className='selectchr_bg container-w'>
                            <div className="nav_bar">
                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/left-arrow-blue.svg"} className="img-fluid rounded-start" alt="" style={{ cursor: 'pointer' }} onClick={this.backButton.bind(this)} />
                                <h3 className='text-center' style={{ color: '#00315E', fontSize: '19px', paddingTop: '5px' }}>
                                    {t('Chargers List')}
                                    </h3>
                                {/* <p>&nbsp;&nbsp;&nbsp;</p> */}
                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/refresh_grey.svg"} width="32" height="32" className="img-fluid rounded-start" alt="" style={{ cursor: 'pointer' }} onClick={() => this.getChargerList()} />
                            </div>
                            <h6 className='f-white text-center  mt-4 mb-2' style={{color: "#00315E"}}> {t('how_does_itwork')}
                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/info_icon_blue.svg"} className="img-fluid rounded-start" alt="" style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={this.getInformation} />
                            </h6>
                        </div>
                        <div className="container-fluid">
                            <div className='row justify-content-center'>
                                <div className='col-md-12 max-height  pt-3'>
                                    <div className='mb-3 welcome-div coninner-div'>
                                        {
                                        (this.state.listofchargers !== null || "") ? (
                                            this.state.listofchargers?.map((cp, i) => {
                                                var TimeValue = "";
                                                if(cp.lostBackendConnectionTime){   
                                                        var lostBackendConnectionTime = Number(cp.lostBackendConnectionTime);
                                                        var seconds = Math.floor(lostBackendConnectionTime / 1000);
                                                        var mins    = Math.floor(seconds / 60);
                                                        var hrs     = Math.floor(mins / 60);
                                                        var days    = Math.floor(hrs / 24);
            
            
                                                        var dDisplay = days > 0 ?  (days === 1 ? " "+this.props.t('yesterday') : days +" "+this.props.t('days')) : "";
                                                        var hDisplay = hrs > 0 ? hrs + (hrs === 1 ? "h" : "h") : "";
                                                        var mDisplay = mins > 0 ? mins + (mins === 1 ? "m" : "m") : "";
                                                        var sDisplay = seconds > 0 ? seconds + (seconds === 1 ? "s" : "s") : "";
                                                        
                                                        // condition for backendConnection and timeOut
                                                        if (cp.backEndConnection === false) {
                                                            if (hDisplay !== '') {
                                                                TimeValue = hDisplay
                                                            } else if (mDisplay !== '') {
                                                                TimeValue = mDisplay
                                                            } else if (sDisplay !== '') {
                                                                TimeValue = sDisplay
                                                            }
                                                        }                                               
                                                    }                                                
                                                      
                                                    if (cp.backEndConnection === false || cp.transactionStatus === "Occupied" || cp.transactionStatus === "SuspendedEV" || cp.transactionStatus === "SuspendedEVSE") {
                                                        return (
                                                            <div key={i} className={'card light-blue'} id={"selectedcp" + i}  >
                                                                <div className="row g-0 box" style={{ marginLeft: '0px', marginRight: '0px', height: '104px' }}>
                                                                    <div className="col-3 charger-div">
                                                                        {(cp.chargePointModel?.includes('UNITE') || cp.chargePointModel==="UNITE") ?
                                                                            <img src={this.state.clicked === "selectedcp" + i ? process.env.PUBLIC_URL + "/assets/img/shareqrcode/white-charger-icon2.svg" : process.env.PUBLIC_URL + "/assets/img/shareqrcode/charger-icon2.svg"} className="img-fluid rounded-start chargeicon" alt="" />
                                                                            : (cp.chargePointModel?.includes('TURBOCON') || cp.chargePointModel==="TURBOCON") ? 
                                                                            <img src={this.state.clicked === "selectedcp" + i ? process.env.PUBLIC_URL + "/assets/img/shareqrcode/turbo-charger-w.svg" : process.env.PUBLIC_URL + "/assets/img/shareqrcode/turbo-charger-b.svg"} className="img-fluid rounded-start chargeicon" alt="" />
                                                                            :
                                                                            <img src={this.state.clicked === "selectedcp" + i ? process.env.PUBLIC_URL + "/assets/img/shareqrcode/white-charger-icon1.svg" : process.env.PUBLIC_URL + "/assets/img/shareqrcode/charger-icon1.svg"} className="img-fluid rounded-start chargeicon" alt="" />}
                                                                            </div>
                                                                            <div className='col-9'>
                                                                                <div className='row'>
                                                                                    <div className="col-9">
                                                                                        <div className="card-body">
                                                                                            <p className="charger-title"><b>{cp.chargePointId}</b></p>
                                                                                            <p className="charger-details txtblack overflow-txt">{cp.aliasName}</p>
                                                                                            {/* <p className="charger-details txtblack">{parseFloat(cp.totalEnergyConsumption)?.toFixed(1) + " kWh"}</p> */}
                                                                                        
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-3 cpstatus pt-2'>
                                                                                        <p className='mb-0'><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/info_icon.svg"} className="img-fluid rounded-start" alt="" onClick={this.getCPInformation.bind(this, cp)} /></p>
                                                                                        {/* <p className="charger-details">
                                                                                            {cp.transactionStatus === null && cp.backEndConnection === false ? <span className="status-offline">Offline</span> : cp.transactionStatus ==="Occupied" ? <span className="org-color">Occupied</span> :"" }
                                                                                        </p> */}
                                                                                    </div>                                                                                    
                                                                                </div>
                                                                                <div className='row'>
                                                                                    <div className='col-12 pr-1' style={{marginTop: "-10px"}}>
                                                                                        <div className='card-body'>
                                                                                        <span className='smtext' style={{fontSize: "11px", color: "#283A4C"}}> {t('Charging cost ')}{this.state.currencySymbol}{chargingType === "ENERGY_LEVEL" ? (costPerkWh + '/KWh') : (costPerHr + '/hr')}</span> 
                                                                                            <p className="charger-details txtblack fw_n">
                                                                                                {cp.transactionStatus === null && cp.backEndConnection === false ? <span className="status-offline">{t('offline')} { dDisplay ? t('since')+": " + dDisplay : TimeValue ? t('since')+": " + t('today') : "" } </span> : (cp.transactionStatus ==="Occupied" || cp.transactionStatus === "SuspendedEV" || cp.transactionStatus === "SuspendedEVSE" || (cp.transactionRfid && cp.ocppTag && cp.transactionRfid !== cp.ocppTag) ) ? <span className="org-color">{t('occupied')}</span> :"" }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                </div>
                                                            </div>
                                                        )
            
                                                    } else {
                                                        return (
                                                            <div key={i} className={this.state.clicked === "selectedcp" + i ? 'card active-state' : 'card light-blue'} id={"selectedcp" + i} onClick={this.handleClickRow.bind(this, "selectedcp" + i, cp)} >
                                                                <div className="row g-0 box" style={{ marginLeft: '0px', marginRight: '0px', height: '104px' }}>
                                                                    <div className="col-3 charger-div">
                                                                        {(cp.chargePointModel?.includes('UNITE') || cp.chargePointModel==="UNITE") ?
                                                                            <img src={this.state.clicked === "selectedcp" + i ? process.env.PUBLIC_URL + "/assets/img/shareqrcode/white-charger-icon2.svg" : process.env.PUBLIC_URL + "/assets/img/shareqrcode/charger-icon2.svg"} className="img-fluid rounded-start chargeicon" alt="" />
                                                                            : (cp.chargePointModel?.includes('TURBOCON') || cp.chargePointModel==="TURBOCON") ? 
                                                                            <img src={this.state.clicked === "selectedcp" + i ? process.env.PUBLIC_URL + "/assets/img/shareqrcode/turbo-charger-w.svg" : process.env.PUBLIC_URL + "/assets/img/shareqrcode/turbo-charger-b.svg"} className="img-fluid rounded-start chargeicon" alt="" />
                                                                            :
                                                                            <img src={this.state.clicked === "selectedcp" + i ? process.env.PUBLIC_URL + "/assets/img/shareqrcode/white-charger-icon1.svg" : process.env.PUBLIC_URL + "/assets/img/shareqrcode/charger-icon1.svg"} className="img-fluid rounded-start chargeicon" alt="" />}
                                                                    </div>
                                                                    <div className='col-9'>
                                                                        <div className='row'>
                                                                            <div className="col-9">
                                                                                <div className="card-body">
                                                                                    <p className="charger-title"><b>{cp.chargePointId}</b></p>
                                                                                    <p className="charger-details txtblack overflow-txt">{cp.aliasName}</p>
                                                                                    {/* <p className="charger-details txtblack">{parseFloat(cp.totalEnergyConsumption)?.toFixed(1) + " kWh" } </p> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-3 cpstatus pt-2'>
                                                                                <p className='mb-0'>
                                                                                    <img src={this.state.clicked === "selectedcp" + i ?  process.env.PUBLIC_URL + "/assets/img/shareqrcode/info_icon_white.svg" : process.env.PUBLIC_URL + "/assets/img/shareqrcode/info_icon.svg"} className="img-fluid rounded-start" alt="" onClick={this.getCPInformation.bind(this, cp)} /></p>
                                                                                {/* <p className="charger-details">
                                                                                    
                                                                                </p> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-12 pr-1' style={{marginTop: "-10px"}}>
                                                                                <div className='card-body'>
                                                                                <span className='smtext' style={{fontSize: "11px", color: "#283A4C"}}> {t('Charging cost ')}{this.state.currencySymbol}{chargingType === "ENERGY_LEVEL" ? (costPerkWh + '/KWh') : (costPerHr + '/hr')}</span> 
                                                                                {/* <span className='smtext' style={{fontSize: "11px", color: "#283A4C"}}> {t('Charging cost ')}{this.state.currencySymbol}{this.state.costPerkWh}{t('/KWh')} + {t('Platform Fee(')}{this.state.paymentSplitDetails.splitDetails.platformShare}{t('%)')}</span>  */}
                                                                                    <p className="charger-details txtblack fw_n">
                                                                                            {
                                                                                            (cp.transactionStatus === null && cp.backEndConnection === true) ? 
                                                                                            <span className="status-available" >{t('available')}</span> 
                                                                                            : cp.transactionStatus === "activeCharging" ? <span className="org-color">{t('charging')}</span> 
                                                                                            : cp.transactionStatus === "activePreparing" ? <span className="org-color">{t('preparing')}</span>
                                                                                            : cp.transactionStatus === "activeNotCharging" ? <span className="org-color">{t('plugged_in')}</span>
                                                                                            : cp.transactionStatus === "Finishing" ? <span className="status-available">{t('finished')} </span>
                                                                                            : <span className="status-available">{cp.transactionStatus}</span>
                                                                                            }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                }
                                                )
                                        ) : <p className='text-center'>{t('Chargers are not available.')}</p>
                                        
                                        
                                        }
                                    </div>
                                </div>
                                {/* <div className='col-md-12'>
                                        {this.state.isIntransaction ? "": <button type="button" className='btn qr-btn' onClick={this.handleSubmit}>{t('start_charging')}</button>}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </>
            )
        }else{
            return (
                <InvalidQRCode message={this.state.message}></InvalidQRCode>
            )
        }
       
    }
}

export default withTranslation()(SelectCharger);
