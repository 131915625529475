import React from 'react';
import { MDBCol, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { Form, Table } from 'react-bootstrap';
import * as baseUrl from './constants';
import { Alert } from 'reactstrap';
import $ from "jquery";
import { createBrowserHistory } from 'history';
import { hasPermission } from './auth';
import Pagination from "react-js-pagination";
import { withTranslation } from 'react-i18next';
import './cpDashboard.css';

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

const history = createBrowserHistory();

class oauthUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        emailId: "",
      },
      errors: {
        selectChargepoint: "",
        notexists: "",


      },
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      delete_msg: "",
      deleteHeader: "",
      deleteButton: "",
      deleteText: "",
      deletecolor: "danger",
      activeArray: [],
      checked: false,
      activeDeactiveArray: '',
      list: [],
      page_title: "Users",
      sucess: false,
      userArray: [],
      model1: "false",
      model2: "false",
      id: "",
      dataArray: [],
      org_name: '',
      modalsession: false,

      user: {
        permissions: [

        ],
      }
    }
    this.addNew = this.addNew.bind(this)
    this.closeModel = this.closeModel.bind(this)
    this.getUserslist = this.getUserslist.bind(this)
    this.activeUsers = this.activeUsers.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggle1 = this.toggle1.bind(this)
    this.toggle2 = this.toggle2.bind(this)
    this.tagOk2 = this.tagOk2.bind(this)
    this.tagOk1 = this.tagOk1.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
    this.closeOkPopup = this.closeOkPopup.bind(this)
    this.toggle = this.toggle.bind(this)
    this.sessiontagOk = this.sessiontagOk.bind(this)
    this.getNonWebastoUsers = this.getNonWebastoUsers.bind(this);
    this.goLoginHistory = this.goLoginHistory.bind(this);

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onChangeOrg = this.onChangeOrg.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  keyPress(e) {
    var key = e.key;
    let errors = this.state.errors;

    if (e.keyCode === 13) {
      this.setState({ emailId: e.target.value, pageSize: 10, activePage: 1 }, () => {
        this.getUserslist(this.state.id, 10, 1);
      });

      if (this.state.fields.emailId === "") {
        errors["selectChargepoint"] = this.props.t('please_enter_email');
        $("#notexists").text("");
        this.setState({ errors });
      } else {
        errors["selectChargepoint"] = "";
        this.setState({
          errors,
          pageSize: 10,
          activePage: 1
        }, ()=>{
          this.getUserslist(this.state.id, 10, 1, "enter");
        })
      }
    }

    if (key === "Backspace" || key === "Delete") {
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno === "") {
        let fields = this.state.fields;
        fields.emailId = sno;

        this.setState({ fields, pageSize: 10, activePage: 1 }, ()=>{
          this.getUserslist(this.state.id, 10, 1);
        })
      }
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9.@-]");
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    this.setState({
      pageSize: entries,
      activePage: 1
    }, ()=>{
      this.getUserslist(this.state.id, entries, 1);
    })
  }
  handlePageChange(pageNumber) {


    if (pageNumber !== this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData === undefined) {
        this.setState({ activePage: pageNumber });
        this.getUserslist(this.state.id, this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
      }
    }
  }
  handleClick() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    fields.emailId = '';
    errors["selectChargepoint"] = " "
    $("#notexists").text("");
    this.setState({
      fields, pageSize: 10, activePage: 1, errors
    }, ()=>{
      this.getUserslist(this.state.id, this.state.pageSize, this.state.activePage);
    });
  }

  changeHandler(field, e) {
    let errors = this.state.errors;
    let fields = this.state.fields;
    fields[field] = e.target.value;
    errors["selectChargepoint"] = ""

    this.setState({ fields, errors });
    $("#notexists").text("");
  }

  //change function for user  filter
  onChangeEmail() {
    let errors = this.state.errors;
    
    if (this.state.fields.emailId === "") {
      errors["selectChargepoint"] = this.props.t('please_enter_email')
      $("#notexists").text("");
      this.setState({ errors });
    } else {
      errors["selectChargepoint"] = "";
      this.setState({ errors, pageSize: 10, activePage: 1 }, ()=>{
        this.getUserslist(this.state.id, 10, 1, "click");
      });
    }
  }

  onChangeOrg() {
    this.setState({ pageSize: 10, activePage: 1 }, ()=>{
      this.getUserslist(this.state.id, this.state.pageSize, 1);
    })
  }

  async goLoginHistory() {
    this.props.history.push('/userLoginHistory');
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });

  }




  toggleConfirmation(event) {

    this.setState({
      modal1: !this.state.modal1
    });
    // event.preventDefault();
    if (this.state.list[0] !== "" && this.state.list[0] !== undefined) {



      this.setState({
        delete_msg: this.props.t('delete_confirmation'),
        deleteHeader: this.props.t('confirmation'),
        deleteButton: "",
        deleteText: this.props.t('close'),
        deletecolor: "danger"
      })
    } else {

      this.setState({
        delete_msg: this.props.t('please_select_user'),
        deleteHeader: this.props.t('Warning'),
        deleteButton: "d-none",
        deleteText: this.props.t('ok'),
        deletecolor: "primary"
      })
    }

  }


  closeOkPopup() {
    this.setState({
      modal: !this.state.modal
    });
    // this.props.history.push('/userManagementController');
    window.location.reload();
  }


  tagOk1() {
    window.location.reload();
  }
  tagOk2() {
    window.location.reload();
  }

  toggle1() {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }

  closeModel() {
    window.location.reload();
  }




  addNew() {
    this.props.history.push('/addOauthUser');
  }
  // calling function when click on checkbox
  handleChange(id, identifier, event) {
    var checkedValue = document.getElementById(id).checked;
    if (checkedValue === true) {
      if (this.state.list[0] !== "" && this.state.list[0] !== undefined) {
        document.getElementById(this.state.list[0]).checked = false;
        document.getElementById(id).checked = true;
      }
      let list = this.state.list;
      list[0] = id;
      this.setState({
        activeArray: identifier, list
      });
    }
    if (checkedValue === false) {
      let list = this.state.list;
      list[0] = "";
      document.getElementById(id).checked = false;
      this.setState({
        activeArray: "", list
      });
    }
    
  }
  async getUserslist(id1, pageSize, pageNo, action) {
var id = "";
    if (id !== "") {
      id = this.state.id;
    } else {
      id = id1;
    }
    document.getElementById("loader_image_div").style.display = "block";

    this.setState({ id: id })
    id = document.getElementById("organizationid").value;

    var el = document.getElementById('organizationid')
    var selectedText = el.options[el.selectedIndex].text
    this.setState({
      org_name: selectedText
    })

    let email = "";
    if (this.state.fields.emailId !== "" && this.state.fields.emailId !== null && this.state.fields.emailId !== undefined) {
      email = this.state.fields.emailId;
    }

    let url = baseUrl.URLPath + "oauth/admins/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&email=" + email;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

    if (response) {
      document.getElementById("loader_image_div").style.display = "none";
      if (response !== undefined) {

        if (response.count.count === 0) {
          if (action === "click" || action === "enter") {
            $("#notexists").text(this.props.t('email_not_exists'));
          }

        } else {
          this.setState({
            userArray: response.userList,
            noOfRecords: response.count.count

          })
          if (action === "click" || action === "enter") {
            $("#notexists").text(" ");
          }
        }
      }
    }
  }

  async activeUsers(status) {
    var url = "";
    if (this.state.activeArray.length !== 0) {
      if (status === true) {
        url = baseUrl.URLPath + "v1.0/tenant/activate/" + this.state.activeArray;
      } else {
        url = baseUrl.URLPath + "v1.0/tenant/deactivate/" + this.state.activeArray;
      }

      let type = ApiMethodTypes.GET;
      let headers = getSecurityHeaders();
      let payload = {};

      await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      if (status === false) {
        this.toggle1()

      } else {
        this.toggle2()
      }
    } else if (this.state.activeArray.length === 0) {
      this.toggleConfirmation();
    }
  }

  async getAccountDetails() {
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      var user_data = [];
      user_data = response.organisationDTOS;
      var first_org = "";
      for (var i = 0; i <= user_data.length; i++) {
        first_org = user_data[0].id;
      }

      this.setState({ dataArray: response.organisationDTOS, pageSize: 10, activePage: 1 }, ()=>{
        this.getUserslist(first_org, this.state.pageSize, this.state.activePage);
      })
    } else {
      this.getNonWebastoUsers();
    }
  }

  componentDidMount() {
let user = this.state.user;
    user.permissions = localStorage.getItem("roleAccess");
    var orgname = localStorage.getItem("tenant");
    this.setState({
      org_name: orgname,
      user
    })
    this.getAccountDetails();
  }


  async getNonWebastoUsers() {
    var url = baseUrl.URLPath + "v1.0/tenant/Nonwebasto";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({ userArray: response.userDTOS });
    }

  }
  async deleteUser() {


    this.setState({
      modal1: !this.state.modal1
    });
    var id;
    id = this.state.id;
    this.setState({ id: id })
    var url = baseUrl.URLPath + "v1.0/tenant/" + this.state.activeArray;
    let type = ApiMethodTypes.DELETE;
    let headers = getSecurityHeaders();
    let payload = {};

    await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    this.setState({
      modal: !this.state.modal
    });

  }


  render() {
    const { t } = this.props

    let userTenant = localStorage.getItem("tenant");
    const user = this.state.user;
    let selectedId = "";

    const organisationDTOS = this.state.dataArray.map((dataArray, index) => {
      if (userTenant === dataArray.identifier) {
        selectedId = dataArray.id
      }
      return (
        <option value={dataArray.id} selected={selectedId === dataArray.id} >{dataArray.company}</option>
      )
    })

    const userDTOS = this.state.userArray.map((userArray, index) => {
      var dictRfids = userArray.rfids;
      var getRfids = [];

      if (dictRfids !== undefined) {
        for (var i = 0; i < dictRfids.length; i++) {
          getRfids.push(dictRfids[i]["rfidTag"]);
        }
      }
 
      if (userArray.status === true) {
        userArray.status = "Active";
      } else {
        if (userArray.status === false) {
          userArray.status = "Inactive";
        }
      }

      return (

        <tr>
          <td className="text-center">
            <Form.Check
              custom
              type="checkbox"
              id={`custom-${userArray.id}`}
              label=""
              onChange={this.handleChange.bind(this, `custom-${userArray.id}`, userArray.id)}
              key={userArray.id}
              className="checkbox"
            />
          </td>
          <td>{userArray.id}</td>

          <td>{userArray.email}</td>
          <td>{userArray.role}</td>
          <td>{userArray.status}</td>
        </tr>
      )


    })
    return (
        <main class="content-div" >
          <p>{t('OAuth Users')}
          </p>
          <div className="page-outerdiv">




            <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
            <div className="row">




              <div className="col-md-12 ">
                <div className="menubar pull-left">
                  <button onClick={this.addNew}><i className="fa fa-plus"></i><span>{t('add')}</span></button>
                  {hasPermission(user, ['Users_U']) && <button onClick={this.activeUsers.bind(this, true)} >

                    <i className="fas fa-toggle-on">
                    </i>
                    <span>{t('active')}</span>
                  </button>}
                  {hasPermission(user, ['Users_U']) && <button onClick={this.activeUsers.bind(this, false)} >
                    <i className="fas fa-toggle-off">
                    </i>
                    <span>{t('deactive')}</span>
                  </button>}
                </div>
              </div>


            </div>
            <div className="row  mt-3">

              <MDBCol md="3" className="mt-2" >
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="emailId" onKeyDown={this.keyPress} value={this.state.fields["emailId"]} style={{ width: "60%" }} onChange={this.changeHandler.bind(this, "emailId")} class="form-control input-searchBox pad-2" placeholder={t('enter_email_id')} name="adminAddress" />
                  <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeEmail} >{t('find')}</button>
                  </div>
                  <span className="error_msg w3-animate-top mt-2"><span style={{ color: "red" }}>{this.state.errors["selectChargepoint"]}</span></span>
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>
                </div>


              </MDBCol>
              <div className="col-md-3"></div>
              <div className=" col-md-3  pull-right mt-0" style={{ display: "none" }}>
                <select className="browser-default custom-select select_height " id="organizationid" onChange={this.onChangeOrg.bind(this)}>
                  {organisationDTOS}
                </select>
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-3 mt-2">
                <div className="pull-right">
                  <label className="sub-text pull-left" >{t('show_entries')}</label>
                  <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>

            </div>





            {/* {/ end page menu bar  /}
          
          {/ table start /} */}
            <div className="row mt-2 ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="text-center">
                      </th>
                      <th>{t('transactionId')}</th>
                      <th>{t('email')}</th>
                      <th>{t('role')}</th>
                      <th>{t('chargingSessionState')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userDTOS}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>

              {/* {/ End table  /} */}
            </div>
          </div>

          {/* {/ popup msg /} */}
          <MDBModal isOpen={this.state.modal3} toggle={this.toggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle1}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('user_deactive_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.tagOk1}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          {/* 
             {/ popup msg /} */}
          <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('user_activated_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.tagOk2} >{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modal1} toggle={this.toggleConfirmation} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleConfirmation}> {this.state.deleteHeader}</MDBModalHeader>
            <MDBModalBody>
              {this.state.delete_msg}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color={this.state.deletecolor} onClick={this.toggleConfirmation}>{this.state.deleteText}</MDBBtn>
              <MDBBtn color="primary" className={this.state.deleteButton} onClick={this.deleteUser}>{t('yes')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modal} backdrop="static" data-backdrop="static" data-keyboard="false" size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('user_deleted_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.closeOkPopup}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>
          {/* end loader */}

        </main>
    );
  }
}

export default withTranslation()(oauthUsers);