import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Table } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import Pagination from "react-js-pagination";
import CustomModal from '../common/CustomModal';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCol, MDBRow, MDBInput } from "mdbreact";
import { event } from 'jquery';
import Loader from '../views/loader';

class chargePointOverview extends React.Component {
  render() {
    
    // this.props.modelVariantStatus.enableModelVariant = true 
    // this.props.modelVariantStatus.enableMeterType = true

    const { t } = this.props
    const ref = React.createRef();
    
    const data = this.props.dataArray.map((model, index) => {
      let modelVarient  = model.enableModelVariant ? model.enableMeterType ? model.meterTypeValue : model.enableConfigKey ? model.configKeyValues.join(', ') : "" : "All";
      return (
        <tr>
          <td className='align-middle' width="10%">
            <h5 className='pl-4'><b># {(this.props.activePage - 1) * this.props.pageSize + index + 1}</b></h5>
          </td>
          <td className='align-middle'>
            <h5 className='mt-2'><b>Model: {model.modelName}</b></h5>
          </td>
          <td className='align-middle pr-4 modalvariant' width="45%">
            <h5 className='mt-2'><b>Modelvariant: {modelVarient}</b></h5>
          </td>
            {/* { model.enableModelVariant ? <>
              <td className='align-middle'>
                <h5 className='mt-2'><b>Modelvariant: {model.enableModelVariant}</b></h5>
              </td>
            </> } */}
          <td className='align-middle'>
            <h5>
              {/* <Link to={{ pathname: "/editBuild", state: { modelId: model.id, modelName: model.modelName } }}>
                <i className="fas fa-pencil-alt" title={t('edit')}></i>
              </Link> */}
              <span>
                <i className="fas fa-pencil-alt link" title={t('edit')} onClick={this.props.editModel.bind(this, model.id, model.modelName)} ></i>
              </span>
              <span>
                <i className="fas fa-trash-alt pl-3 action-icon" title={t('delete')} onClick={this.props.toggleConfirmation.bind(this, model.id)} ></i>
              </span>
              <Link to={{ pathname: "/editBuild", state: { modelId: model.id, modelName: model.modelName } }}>
                <i className="fas fa-gear pl-3" title={t('edit_builds')}></i>
              </Link>
            </h5>
          </td>
        </tr>
      )
    })


    let modelListRow = "";
    if(this.props.getModelNameList!=="" && this.props.getModelNameList!==undefined && this.props.getModelNameList!==null ){
      modelListRow = this.props.getModelNameList?.map((model, index) => {
        return (
          (model && model !== " " && model !== "") ? <option value={model}>{model}</option> : ""
        )
      });
    }

    let meterTypeRow = "";
    if(this.props.modelMetertypes!=="" && this.props.modelMetertypes!==undefined && this.props.modelMetertypes!==null ){
      meterTypeRow = this.props.modelMetertypes?.map((option, index) => {
        return (
          option ? <option key={index} value={option}>{option}</option> :""
        )
      });
    }

    



    let configKeyListLi = "";
    if (this.props.configKeyList !== "" || this.props.configKeyList !== undefined || this.props.configKeyList.length !== 0) {
      configKeyListLi = this.props.configKeyList?.map((configKeyListData, index) => {
        return (
          <li style={{ width: '33%' }}>
            <Form.Check
              custom
              type="radio"
              id={`custom-${index}`}
              label=""
              name="chargepoint"
              onChange={() => this.props.handleChangeCheckbox(configKeyListData, index)}
            //checked={this.props.configKeyChecked.includes(index)}
            />
            {configKeyListData}
          </li>
        )
      })
    }

    let configKeyValueListLi = "";
    if (this.props.configKeyValueList !== "" || this.props.configKeyValueList !== undefined || this.props.configKeyValueList.length !== 0) {
      configKeyValueListLi = this.props.configKeyValueList?.map((configKeyListData, index) => {
        return (
          (configKeyListData === "" || configKeyListData === null || configKeyListData === undefined) ? "" : 
          <li style={{ width: '50%' }}>
            <Form.Check
              custom
              type="checkbox"
              id={`custom-${index}`}
              label=""
              name="chargepoint"
              onChange={this.props.handleChangeCheckboxForConfigKeyValue.bind(this,configKeyListData, index)}
              checked={this.props.selectedConfigKeyValues?.includes(configKeyListData) ? true : false}
            />
            {configKeyListData}
          </li>
        )
      })
    }

    return (
      <main className="content-div ota_management">
        {/* page title */}
        <p>{t('ota_management')}
          <div className="breadcrumb_div">
            {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('ota_management')}</span>
          </div>
        </p>
        {/* end page title */}
        <div className="page-outerdiv">
          <div className="row menubar ">
            <div className="col-md-12 ">
              <button type="button" data-test="button" onClick={this.props.addNew.bind(this)}  >
                <i className="fa fa-plus"></i>
                <span>{t('add')}</span>
              </button>
              <div className="pull-right">
                <label className="sub-text pull-left" >{t('show_entries')}</label>
                <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.props.showEntriesChange} value={this.props.pageSize}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
          </div>
          {/* end page menu bar  */}

          <div className="row mb-2">
            {/* search filter */}
            <div className="col-md-4 pull-right t-8">
              <div className="input-group">
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <Typeahead
                  labelKey="name"
                  className="w_80"
                  multiple={this.props.multiple}
                  options={this.props.options}
                  minLength={3}
                  placeholder={t('enter_model')}
                  onKeyDown={this.props.handleKeyDown.bind(this, ref)}
                  id="rbt_id"
                  ref={ref}
                />
                <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.props.ClearModel.bind(this, ref)}>
                  <span aria-hidden="true">×</span>
                </button>
                <div className="input-group-btn">
                  <button className="input-searchBtn find-btn" type="submit" onClick={this.props.onChangeModel.bind(this, ref)} >{t('find')}</button>
                </div>
                <span className="pull-left error_msg w3-animate-top mt-1" style={{ color: "red" }}>{this.props.errors["chargePointNotExists"]}</span>
              </div>
            </div>
            {/* Export by Lakshmikanth */}

          </div>

          {/* table start */}
          <div className="row ">
            <div className="col-12">
              <Table striped hover size="sm" className="page-table">
                <tbody>
                  {data}
                  {
                    (this.props.dataArray.length === 0 && this.props.modelValue) ? <td colSpan={12} className='text-center pt-5 pb-5'>{t('no_data_found')}</td> : null
                  }
                  {
                    (!this.props.isDataFound) && <td colSpan={12} className='text-center align-middle pt-5 pb-5'>{t('no_data_found')}</td>
                  }
                </tbody>
              </Table>
            </div>

            <div className="col-md-12 mt-2 mb-4 text-right">
              <Pagination
                hideDisabled
                activePage={this.props.activePage}
                itemsCountPerPage={this.props.pageSize}
                totalItemsCount={this.props.noOfRecords}
                onChange={this.props.handlePageChange}
              />
            </div>

            {/* End table  */}
          </div>
        </div>

        <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>

        <MDBModal isOpen={this.props.addModel} size="md" className="model_top mw-580 ota_newmodel" backdrop="static" data-backdrop="static" data-keyboard="false" >
          <MDBModalHeader>
            {this.props.selectedOption === ("MeterType" || "ConfigKey") ? (t('model') + ": " + this.props.selectedModel) : (this.props.isEditModel ? t('edit_model') : t('add_model'))}
          </MDBModalHeader>
          <MDBModalBody>
            <div className="row">
              <div className='col-md-10 mt-2 mb-5'>
                {this.props.modelSelectOption &&
                  (
                    <MDBCol md="12">
                      {
                        this.props.isEditModel ?
                          <select className="browser-default custom-select select_height mb_8" id="model" disabled onChange={this.props.modelOnChange.bind(this)} value={this.props.selectedModel} >
                            <option value="">{this.props.modelName}</option>
                          </select>
                          :
                          <select className="browser-default custom-select select_height mb_8" id="model" onChange={this.props.modelOnChange.bind(this)} value={this.props.selectedModel} >
                            <option value=""> -- {t('select_model')} * --</option>
                            {modelListRow}
                          </select>
                      }
                      <span className="error_msg w3-animate-top"><span style={{ color: "red" }} >{this.props.errors["modelEmpty"]}</span></span>
                      {
                        this.props.modelVariantStatus.enableModelVariant && (
                          <div className='pt-2 mlMinus12'>
                            <Form.Check onChange={this.props.handleCheckboxChange}
                              custom
                              type="checkbox"
                              id={`custom`}
                              label={t('include_model_variant')}
                              checked={this.props.isChecked}
                            />
                          </div>
                        )
                      }
                      { (this.props.isChecked && this.props.modelVariantStatus.enableModelVariant) && (
                        <div className='mb-2 ml-4 pt-2'>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }} class="custom-control custom-radio pb-2">
                              <Form.Check
                                onChange={(event) => this.props.handleOptionChange(event)}
                                custom
                                type="radio"
                                id="MeterType"
                                label={t('basemodel_varient_on_metertype')}
                                name="options"
                                value="MeterType"
                                disabled= {this.props.modelVariantStatus.enableMeterType  ? false : true}
                                // checked={ this.props.modelMeterTypeValue ? true :false}
                              />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }} class="custom-control custom-radio">
                            <Form.Check
                                onChange={(event) => this.props.handleOptionChange(event)}
                                custom
                                type="radio"
                                id="ConfigKey"
                                label={t('basemodel_varient_on_configkey')}
                                name="options"
                                value="ConfigKey"
                                disabled= {this.props.modelVariantStatus.enableConfigKey  ? false : true}
                                // checked={ this.props.configKeyValues ? true :false}
                              />
                            </div>
                          </div>
                        </div>)}
                    </MDBCol>
                  )}

                {
                  this.props.selectedOption === "MeterType" || (this.props.selectedOption === "MeterType" && this.props.modelVariantStatus.enableMeterType === true) ? (        
                    <>
                    <p>{t('model_variant_based_on_metertype')}</p>
                    <select className="browser-default custom-select select_height mb_8" onChange={this.props.modelMeterTypeChange.bind(this)} value={this.props.modelMeterTypeValue}>
                     <option value=''>{t('select_meter_type')} *</option>
                    {meterTypeRow}
                    </select>
                  </>
                  ) :
                    this.props.selectedOption === "ConfigKey"  || (this.props.selectedOption === "MeterType" && this.props.modelVariantStatus.enableConfigKey === true) ? (
                      <>
                        <p>{t('model_variant_based_on_configkey')}</p>
                        <p className='mb-0'>{t('select_configuration_key')} *</p>
                        <form className="needs-validation">
                          <MDBRow>
                            <MDBCol md="12" className='mb-3'>
                              <MDBInput
                                name="chargebox"
                                onClick={this.props.modelConfigKeyChange}
                                type="text"
                                id=""
                                value={this.props.selectedConfigKey}
                                disabled = {this.props.isEditModel? true:false}
                              >
                              </MDBInput>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                          </MDBRow>
                        </form>

                        <p className='mb-4'>{t('select_configuration_keyvalue')} *</p>
                        <p style={{borderBottom: "1px solid #c7c5c5"}}
                          onClick={this.props.modelConfigKeyValueChange}>&nbsp;
                          {this.props.selectedConfigKeyValues.join(', ')}
                        </p>
                      </>
                    )
                      :
                      ""
                }
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_cancel" onClick={this.props.toggleAddModelPopup}>
              {t('cancel')} <i className="fas fa-times ml-2"></i>
            </button>

            {/* {!this.props.editModelId  && 
            <button type="button" data-test="button" className={this.props.disabledAttr === true ? "btn_primary btn_cancel" : "btn_primary"} onClick={this.props.editModelId ? this.props.saveModel : this.props.updateModel } disabled={this.props.disabledAttr} >
             {this.props.editModelId ? t('update') : t('save') } <i className="fas fa-save ml-2"></i>
            </button>
            } */}
            {!this.props.editModelId  && 
            <button type="button" data-test="button" className={this.props.disabledAttr === true ? "btn_primary btn_cancel" : "btn_primary"} onClick={this.props.saveModel} disabled={this.props.disabledAttr} >
            { t('save') } <i className="fas fa-save ml-2"></i>
            </button>
            }
            {this.props.editModelId  && 
            <button type="button" data-test="button" className={this.props.disabledAttr === true ? "btn_primary btn_cancel" : "btn_primary"} onClick={this.props.updateModel} disabled={this.props.disabledAttr} >
             { t('update')  } <i className="fas fa-save ml-2"></i>
            </button>
             }
          </MDBModalFooter>
        </MDBModal>

        {/* select configuration key model */}
        <MDBModal isOpen={this.props.selectConfigkeyModal} className="custom_confkey_w ota_newmodel" size="lg" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
          <div data-test="modal-header" className="modal-header">
            <div className="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>
              {(t('model') + ": " + this.props.selectedModel)}
            </div>
            <button type="button" data-test="button" className="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          {/* model body for single selection  */}
          <MDBModalBody>
            <MDBRow className="check-list">
              <div className="col-md-12">
                <ul>{configKeyListLi}</ul>
              </div>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <button className="btn_cancel" onClick={this.props.toggle_list} type="button" data-test="button"> {t('cancel')} <i className="fas fa-times ml-2"></i></button>
            <button type="button" data-test="button" className={this.props.disabledAttr === true ? "btn_primary btn_cancel btn_done" : "btn_primary btn_done"} onClick={this.props.saveConfigKey} disabled={this.props.disabledAttr} >
            {t('done')}</button>
          </MDBModalFooter>
        </MDBModal>

        {/* select configuration key value model */}
        <MDBModal isOpen={this.props.selectConfigkeyvalueModal}  className="model_top mw-580 ota_newmodel" size="md" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
          <div data-test="modal-header" className="modal-header">
            <div className="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>
              {(t('model') + ": " + this.props.selectedModel)}
            </div>
            <button type="button" data-test="button" className="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          {/* model body for single selection  */}
          <MDBModalBody>
            <MDBRow className="check-list">
              <div className="col-md-12">
                <p>{t('select_value_of_modelvariant')}</p>
                <ul>{configKeyValueListLi}</ul>
              </div>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <button className="btn_cancel" onClick={this.props.toggle_list} type="button" data-test="button"> {t('cancel')} <i className="fas fa-times ml-2"></i></button>
            <button type="button" data-test="button" className={this.props.disabledAttr === true ? "btn_primary btn_cancel btn_done" : "btn_primary btn_done"} onClick={this.props.saveConfigKeyValue} disabled={this.props.disabledAttr} >
            {t('done')}</button>
          </MDBModalFooter>
        </MDBModal>
        {/* success popup for add model */}
        <CustomModal
          open={this.props.modalAddSuc}
          toggle={this.props.toggleAddSuc}
          title={t('success')}
          bodyMessage={this.props.editModelId ? t('model_updated_successfully') : t('model_added_successfully') }
          PrimaryButtonText={t('ok')}
          PrimaryButtonOperation={this.props.closetoggleAddSuc}
        />

        {/* confirmation delete popup */}
        <CustomModal
          open={this.props.confirmDeleteSeq}
          title={t('confirmation')}
          bodyMessage={t('are_yousure_youwant_todelete_otaDetails')}
          PrimaryButtonText={t('yes')}
          PrimaryButtonOperation={this.props.deleteOtaDetails}
          AdditionalButtonText={t('no')}
          AdditionalButtonOperation={this.props.toggleConfirmation}
        />

        {/* Ota delete success popup */}
        <CustomModal
          open={this.props.otaDeleteSuccess}
          title={t('success')}
          bodyMessage={t('otaDetails_deleted_sucessfully')}
          PrimaryButtonText={t('ok')}
          PrimaryButtonOperation={this.props.toggleOtaDeleteSuccess}
        />

        {/* confirmation model build exists */}
        <CustomModal
          open={this.props.confirmWarningExits}
          title={t('warning')}
          bodyMessage={t(this.props.deleteModelStatusMessage)}
          PrimaryButtonText={t('ok')}
          PrimaryButtonOperation={this.props.toggleconfirmWarningExits}
        />

        {/* ota model error popup */}
        <CustomModal
          open={this.props.isModelErr}
          toggle={this.props.modalErrMsgPopup}
          title={t('warning')}
          bodyMessage={this.props.modalErrMsg}
          PrimaryButtonText={t('ok')}
          PrimaryButtonOperation={this.props.modalErrMsgPopup}
        />

        {this.props.loading ? (
          <Loader loader={this.props.loading} />
        ) : ("")}

      </main>
    );
  }
}

export default withTranslation()(chargePointOverview);