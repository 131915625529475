import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { hasPermission } from './auth';
import moment from 'moment';

//API imports 
import * as baseUrl from './constants'
import servicesUrl from '../common/servicesUrl'
import APICallUtility from '../common/APICallUtility'
import ApiMethodTypes from '../common/ApiMethodTypes'
import { getSecurityHeaders } from '../common/HttpRequestClass'

class viewChargingProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleId: "",
      hideActionBtn: "none",
      writePermission: this.props.ChargePoint_W,
      fields: {},
      disabled: "true",
      chargingProfileId: "",
      page_title: "View Charging Profile",
      modalDelete: false,
      modalConfirmation: false,
      user: {
        permissions: []
      }
    }

    this.deleteChargingProfile = this.deleteChargingProfile.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
    this.backToList = this.backToList.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.tagOk = this.tagOk.bind(this)
    this.toggleDeleteClose = this.toggleDeleteClose.bind(this)
  }

  backToList() {
    this.props.history.push('/chargingProfile');
  }

  toggleDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete
    });
  }

  tagOk() {
    this.props.history.push('/chargingProfile');
  }

  toggleConfirmation() {
    this.setState({
      modalConfirmation: !this.state.modalConfirmation
    });
  }

  handleDelete(event) {
    event.preventDefault();

    this.setState({
      modalConfirmation: !this.state.modalConfirmation
    }, ()=>{
      this.deleteChargingProfile();
    });
  }

  toggleDeleteClose() {
    this.setState({
      modalConfirmation: !this.state.modalConfirmation
    });
  }

  componentDidMount() {
    let user = { permissions: [] };
    user.permissions = localStorage.getItem("roleAccess");

    if (this.props.location.state !== null && this.props.location.state !== undefined) {
      this.setState({ chargingProfileId: this.props.location.state, user }, () => {
        this.getChargingProfile()
      })
    }
  }

  async getChargingProfile() {
    var url = baseUrl.URLPath + servicesUrl.getChargingprofilesByprofileId + "?profileId=" + this.state.chargingProfileId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      this.setState({ fields: response })
    }
  }

  async deleteChargingProfile() {
    let url = baseUrl.URLPath + servicesUrl.getChargingProfilesWId + "?profileId=" + this.state.chargingProfileId;
    let type = ApiMethodTypes.DELETE;
    let headers = getSecurityHeaders();
    let payload = {}
    await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    this.toggleDelete();
    return;
  }

  render() {
    const { t } = this.props
    let user = this.state.user;

    return (
      <main class="content-div" >
        <p>{t('chargingProfileId')}
          <div className="breadcrumb_div">
            {t('charge_point')} &gt; <Link to="/chargingProfile">{t('chargingProfileId')}</Link > &gt; <span className="breadcrumb_page">{t('view')}</span>
          </div>
        </p>
        <div className="page-outerdiv">
          <div className="row">
            <div className="col-md-12">
              <div className="pull-left">
                {hasPermission(user, ['ChargingProfile_R']) &&
                  <button class="pull-right btn_primary" type="button" data-test="button" onClick={this.backToList} >
                    <i className="fas fa-angle-left mr-2"></i>  {t('back')}
                  </button>
                }
              </div>

            </div>
          </div>
          <form className='needs-validation'>
            <div className="row mb-4 mt-3">
              <div className="col-sm-6">
                <div className="content-innerdiv">
                  <div className="" >
                    <label>
                      {t('charging_profilename')}:
                    </label>
                    <label >
                      {this.state.fields["chargingProfileName"]}
                    </label>
                  </div>
                  <div className=" ">
                    <label>
                      {t('charging_profilepurpose')}:
                    </label>
                    <label>
                      {this.state.fields["chargingProfilePurpose"]}
                    </label>
                  </div>
                  <div className="">
                    <label>
                      {t('recurrency_kind')}:
                    </label>
                    <label>
                      {this.state.fields["recurrencyKind"]}
                    </label>
                  </div>
                  <div className="">
                    <label>
                      {t('valid_from')}:
                    </label>
                    <label>
                      {this.state.fields["validFrom"] ? moment(this.state.fields["validFrom"]).format("DD-MMM-YYYY HH:mm").toUpperCase() : null}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="content-innerdiv">
                  <div className="">
                    <label>
                      {t('stack_level')}:
                    </label>
                    <label>
                      {this.state.fields["stackLevel"]}
                    </label>
                  </div>
                  <div className="">
                    <label>
                      {t('charging_profile_kind')}:
                    </label>
                    <label>
                      {this.state.fields["chargingProfileKind"] ? t(this.state.fields["chargingProfileKind"].toLowerCase()) : ""}
                    </label>
                  </div>
                  <div className="">
                    <label>
                      {t('Charging Schedule')}:
                    </label>
                    <label>
                      <Link to={{
                        pathname: '/viewchargingSchedule',
                        state: this.state.fields["scheduleId"]
                      }}>{this.state.fields["chargingSchedule"]}
                      </Link>

                    </label>
                  </div>
                  <div className="">
                    <label>
                      {t('valid_to')}:
                    </label>
                    <label>
                      {this.state.fields["validTo"] ? moment(this.state.fields["validTo"]).format("DD-MMM-YYYY HH:mm").toUpperCase() : null}
                    </label>
                  </div>
                </div>
                <div className="col-md-12 text-right">
                  {hasPermission(user, ['ChargingProfile_D']) &&
                    <button className="btn_secondary mt-5 mr-3" onClick={this.toggleConfirmation} id="deleteBtn" type="button" data-test="button"> {t('delete')} <i class="fas fa-trash-alt ml-2"></i></button>
                  }
                  {hasPermission(user, ['ChargingProfile_U']) &&
                    <Link to={{
                      pathname: '/editChargingProfile',
                      state: this.state.chargingProfileId,
                    }}>
                      <button className="btn_primary mt-5 mr-3" onClick={this.editChargingProfile} id="editBtn" type="button" data-test="button">{t('edit')} <i class="fas fa-pencil-alt ml-2"></i></button>
                    </Link>
                  }
                </div>

              </div>
            </div>
          </form>
        </div>

        <MDBModal isOpen={this.state.modalDelete} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleDelete}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('charging_profile_deleted')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" onClick={this.tagOk} className="btn_primary">{t('OK')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalConfirmation} toggle={this.toggleConfirmation} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleConfirmation}> {t('confirmation')} </MDBModalHeader>
          <MDBModalBody>
            {t('you_want_to_delete')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" onClick={this.toggleDeleteClose} className="btn_primary">{t('close')}</button>
            <button type="button" data-test="button" onClick={this.handleDelete} className="btn_primary">{t('yes')}</button>
          </MDBModalFooter>
        </MDBModal>
      </main>
    );
  }
}

export default withTranslation()(viewChargingProfile);