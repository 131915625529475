import React from 'react';
import * as baseUrl from '../views/constants';
import { withTranslation } from 'react-i18next';
import InvalidQRCode from './InvalidQRCode';
// const navigate = useNavigate();
class AddPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: "",
            charegimg: "",
            qrCodeValidate: false,
            listofchargers: [],
            idTag: "",
            cpId: "",
            totalEnergyConsumption: "",
            aliasName: "",
            transactionStatus: "",
            isIntransaction: false,
            chargePointModel: "",
            transactionRfid: "",
            message: '',
            isEnergyLimitReached: false,
            activeTab: '',
            showTimeDropdown: false,
            paymentAmount1: 0,
            paymentAmount2: 0,
            totalTime: 0,
            minutesOptions: [60, 30, 15, 10, 5, 1], // Array of minute options
            selectedMinutes: 0, // Initialize selected minutes to 0
            totalHours: "",
            totalMinutes: "",
            totalHrsMins: "",
            totalAmt: 0,
            totalKWh1: 0,
            totalKWh2: 0,
            inputValue: '',
            inputAmountValue: '',            
            errors: {},
            hoursSelected: 0,
            minutesSelected: 0
        }
        this.handleClickRow = this.handleClickRow.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.backButton = this.backButton.bind(this)
        this.getCPInformation = this.getCPInformation.bind(this)
        this.getInformation = this.getInformation.bind(this)
        this.handleActiveTab = this.handleActiveTab.bind(this);
        this.showTime = this.showTime.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.resetTime = this.resetTime.bind(this);
        this.submitTime = this.submitTime.bind(this);
        this.handleKWhChange = this.handleKWhChange.bind(this);
        this.convertMinsToHoursAndMinsFormat = this.convertMinsToHoursAndMinsFormat.bind(this);
        this.closeTime = this.closeTime.bind(this);
    }

    submitTime() {
        this.calculatePrice(this.state.selectedMinutes);
        if(this.state.hoursSelected && this.state.minutesSelected) {
            this.setState({
                //totalHrsMins: (this.state.hoursSelected + ' hrs' + ' : ' + this.state.minutesSelected + ' min'),  
                totalHrsMins: ((this.state.hoursSelected < 10 ? '0' + this.state.hoursSelected : this.state.hoursSelected) + ' hrs : ' + (this.state.minutesSelected < 10 ? '0' + this.state.minutesSelected : this.state.minutesSelected) + ' min'),
                showTimeDropdown: !this.state.showTimeDropdown,
                totalTime: this.state.selectedMinutes,}
            )
        } else {
            this.setState({ 
                showTimeDropdown: !this.state.showTimeDropdown,
                totalTime: this.state.selectedMinutes,
                totalHours: this.state.hoursSelected,
                totalMinutes: this.state.minutesSelected,
                totalHrsMins: ''
             })
        }
    }

    calculatePrice(minutes) {
        // const ratePerHour = 10;
        let cost = parseFloat(localStorage.getItem("costPerHr"));
        const minutesInHour = 60;

        const ratePerMinute = cost / minutesInHour;

        const totalPrice = minutes * ratePerMinute;
        const roundPrice = Math.round(totalPrice * 100) / 100;
        // this.setState({ totalAmt: totalPrice })
        this.setState({ paymentAmount1: roundPrice });
        localStorage.setItem("totalAmt", roundPrice);
    }
    resetTime() {
        this.setState({
            hoursSelected: 0,
            minutesSelected: 0,
        }, () => this.setState({ selectedMinutes: 0 }))
    }
    handleTimeChange = (minutes) => {
        
        // Update selectedMinutes in the state by adding the new minutes
        this.setState((prevState) => ({
            selectedMinutes: prevState.selectedMinutes + minutes, 
            errors: {}
        }), this.calculateTime);
    }
    calculateTime = () => {
        const totalMinutes = this.state.selectedMinutes;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        this.setState({
            hoursSelected: hours,
            minutesSelected: minutes
        });
    }
    handleKWhChange(e) {
        const formattedValue = e.target.value.replace(/\D/g, '');
        
        this.setState({inputValue: formattedValue, errors: {}})

        const kwh = parseFloat(formattedValue);
        let cost = parseFloat(localStorage.getItem("costPerkWh"));

        const amount = kwh * cost;

        this.setState({ paymentAmount1: amount, totalKWh1: kwh });
        

    }
    handleAmountChange(e) {
        const enteredAmount = e.target.value.replace(/\D/g, '');
        this.setState({inputAmountValue: enteredAmount, errors: {}});
        const basePrice = localStorage.getItem('chargingType') === "ENERY_LEVEL" ? parseFloat(localStorage.getItem("costPerkWh")) : parseFloat(localStorage.getItem("costPerHr"))
        const time = enteredAmount * basePrice;
        const totalTimeMinutes = this.calculateMinutesForAmount(parseFloat(enteredAmount), basePrice);
        console.log(totalTimeMinutes, "Test.....................................!!!!!!!!!!!!!!!");
        
        const formattedTime = this.convertMinsToHoursAndMinsFormat(totalTimeMinutes);
        const totalKWh = (enteredAmount * basePrice) / 100;
        this.setState({ totalKWh2: totalKWh, paymentAmount2: enteredAmount, totalHrsMins: formattedTime }, 
            () => console.log(totalKWh, enteredAmount, formattedTime, "Test.....................................!!!!!!!!!!!!!!!")
        );        
        localStorage.setItem("totalTime", time);
        
    }

    calculateMinutesForAmount(amount, basePricePerHour) {
        
        // if (typeof amount !== 'number' || amount <= 0 || typeof basePricePerHour !== 'number' || basePricePerHour <= 0) {
        //     return "Invalid input";
        // }
    
        // Calculate the number of hours that can be afforded with the given amount
        let hoursAffordable = amount / basePricePerHour;
    
        // Convert hours to minutes
        let minutes = Math.round(hoursAffordable * 60);
    
        return minutes;
    }
    convertMinsToHoursAndMinsFormat(minutes) {        
        if(minutes){
            let hours = Math.floor(minutes / 60);
        let remainingMinutes = minutes % 60;
        
        if (hours === 0) {
            return `${remainingMinutes}min`;
        } else if (remainingMinutes === 0) {
            return `${hours}hrs`;
        } else {
            return `${hours}hrs ${remainingMinutes}min`;
        }
        }else{
            return `0hrs 0min`;
        }
    }
    showTime() {
        this.setState({ showTimeDropdown: !this.state.showTimeDropdown })
    }
    closeTime() {
        this.setState({
            showTimeDropdown: !this.state.showTimeDropdown,
            totalHours: 0,
            totalMinutes: 0,
        }, () => this.setState({ selectedMinutes: 0 }))
    }
    handleActiveTab(tab, status) {
        this.setState({
            activeTab: tab,
            showTimeDropdown: false,
            errors: {}
        }, () => {
            //   this.getVouchersList(1, this.state.pageSize);
        })
    }
    getInformation() {
        this.props.setPage('informationpage');
        localStorage.setItem('page', 'informationpage');
    }
    getCPInformation(cpObj) {
        this.props.setPage('cpinformationpage');
        localStorage.setItem('page', 'cpinformationpage');
        localStorage.setItem('cpDetails', JSON.stringify(cpObj))
    }
    getMessageType() {
        return baseUrl.REMOTE_START_TRANSACTION;
    }

    getPayLoad() {
        var payload = {
            idTag: this.state.idTag,
            connectorId: 1,
            //   "chargingProfile" : this.state.fields.chargingProfile,
        };
        return payload;
    }

    async handleSubmit() {
        var errors = {};
        if(this.state.activeTab === "KWh") {            
        if(this.state.inputValue === "") {
            errors["enterKWh"] = this.props.t('Please enter KWh');
            this.setState({
                errors: errors
            });
        } else if(parseFloat(this.state.inputValue) === 0) {
            errors["enterMinKWh"] = this.props.t('Please enter minimum 1KWh');
            this.setState({
                errors: errors
            });
        } else {
            this.props.setPage('paymentprocess');
            localStorage.setItem('page', 'paymentprocess');
            localStorage.setItem('totalKWh', this.state.totalKWh1);            
            localStorage.setItem("totalAmt", this.state.paymentAmount1);
        }
        } else if(this.state.activeTab === "Time") {          
            if(this.state.selectedMinutes === "" || this.state.selectedMinutes === 0) {
                errors["enterTime"] = this.props.t('Please select time');
                this.setState({
                    errors: errors
                });
            } else {
                const hrs = Math.floor(this.state.selectedMinutes / 60);
                const remainingMinutes = this.state.selectedMinutes % 60;
                var time = `${hrs}.${remainingMinutes}`;
                this.props.setPage('paymentprocess');
                localStorage.setItem('page', 'paymentprocess');
                localStorage.setItem('totalTime', time);            
                localStorage.setItem("totalAmt", this.state.paymentAmount1);
            }
            } else if(this.state.activeTab === "Amount") {
            if(this.state.inputAmountValue === "") {
                errors["enterAmount"] = this.props.t('Please enter Amount');
                this.setState({
                    errors: errors
                });
            } else if(parseFloat(this.state.inputAmountValue) === 0) {
                errors["enterMinAmount"] = this.props.t('Please enter minimum 1');
                this.setState({
                    errors: errors
                });
            } else {
                this.props.setPage('paymentprocess');
                localStorage.setItem('page', 'paymentprocess');
                localStorage.setItem('totalKWh', this.state.totalKWh2);          
                localStorage.setItem("totalAmt", this.state.paymentAmount2);
            }
        }

    }

    backButton() {
        localStorage.setItem('page', 'selectCharger');
        this.props.setPage('selectCharger');
    }

    handleClickRow(id, cp, e) {
        if (cp.transactionStatus === "activeCharging" || (cp.transactionRfid && cp.ocppTag && cp.transactionRfid === cp.ocppTag)) {
            this.setState({
                clicked: id,
                transactionStatus: cp.transactionStatus,
                idTag: cp.ocppTag,
                cpId: cp.chargePointId,
                chargePointModel: cp.chargePointModel,
                transactionRfid: cp.transactionRfid
            })
            localStorage.setItem('cp', cp.chargePointId);
            localStorage.setItem('rfid', cp.ocppTag);
            localStorage.setItem('modelType', cp.chargePointModel);
            this.props.setPage('chargingprocess');
            localStorage.setItem('page', 'chargingprocess');
        } else {
            this.setState({
                clicked: id,
                idTag: cp.ocppTag,
                cpId: cp.chargePointId,
                totalEnergyConsumption: cp.totalEnergyConsumption,
                aliasName: cp.aliasName,
                chargePointModel: cp.chargePointModel
            })
        }
    }

    componentDidMount() {
        // this.getChargerDetails();
        if(localStorage.getItem("chargingType") === "ENERGY_LEVEL") {
            this.setState({ activeTab: "KWh"})
        } else {
            this.setState({ activeTab: "Time"})
        }
        localStorage.getItem("costPerHr");
    }

    render() {
        const { t } = this.props;
        if (!this.state.isEnergyLimitReached) {
            return (
                <>
                    <div className="welcome-parent">
                        <div className='selectchr_bg container-w'>
                            <div className="nav_bar">
                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/left-arrow-blue.svg"} className="img-fluid rounded-start" alt="" style={{ cursor: 'pointer' }} onClick={this.backButton.bind(this)} />
                                <h3 className='text-center' style={{ color: '#00315E', fontSize: '19px', paddingTop: '5px' }}>{t('Add payment')}</h3>
                                <p>&nbsp;&nbsp;&nbsp;</p>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className='row justify-content-center'>
                                <div className="row g-0" style={{ marginLeft: '0px', marginRight: '0px', marginBottom: '20px', height: '90px' }}>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className="col-9">
                                                <p className="charger-title"><b>
                                                    {/* {cp.chargePointId} */}
                                                    {localStorage.getItem('cp')}
                                                </b></p>
                                                <p className="charger-details txtblack overflow-txt">
                                                    {/* {cp.aliasName} */}
                                                    {localStorage.getItem('aliasName')}                                            
                                                </p>
                                            </div>
                                            <div className='col-3 cpstatus pt-2 pl-0'>
                                                <p className="charger-status">
                                                    {/* {cp.transactionStatus === null && cp.backEndConnection === false ? <span className="status-offline">{t('offline')} { dDisplay ? t('since')+": " + dDisplay : TimeValue ? t('since')+": " + t('today') : "" } </span> : (cp.transactionStatus ==="Occupied" || cp.transactionStatus === "SuspendedEV" || cp.transactionStatus === "SuspendedEVSE" || (cp.transactionRfid && cp.ocppTag && cp.transactionRfid !== cp.ocppTag) ) ? <span className="org-color">{t('occupied')}</span> :"" } */}
                                                    {t('• Available')}
                                                </p>
                                            </div>
                                            <br />
                                            <div className="col-12">
                                            <span className='smtext' style={{whiteSpace: 'normal', fontSize: '13px', color: "#757D86", fontFamily: 'Inter-Medium'}}> {t('Charging cost ')}{localStorage.getItem('currencySymbol')}{localStorage.getItem("chargingType") === "ENERGY_LEVEL" ? (localStorage.getItem("costPerkWh") + '/KWh') : (localStorage.getItem("costPerHr") + '/hr')}</span>
                                            {/* <span className='smtext' style={{whiteSpace: 'normal', fontSize: '13px', color: "#757D86", fontFamily: 'Inter-Medium'}}> {t('Charging cost ')}{localStorage.getItem('currencySymbol')}{localStorage.getItem("costPerkWh")}{t('/KWh')} + {t('Platform Fee(')}{localStorage.getItem("platformShare")}{t('%)')}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 max-height  pt-3'>
                                    <div className='mb-3 welcome-div coninner-div'>
                                        <div className="">
                                            <div className="row mb-0 pb-0">
                                                <div className="col-md-12 mb-0 pb-0" style={{ display: "flex", justifyContent: "space-around" }}>
                                                    {localStorage.getItem("chargingType") === "ENERGY_LEVEL" ? <button className={this.state.activeTab === 'KWh' ? 'qc-active' : 'qc-inactive'}
                                                        onClick={this.handleActiveTab.bind(this, 'KWh', true)}>{t('KWh')}</button> : <button className={this.state.activeTab === 'Time' ? 'qc-active' : 'qc-inactive'}
                                                        onClick={this.handleActiveTab.bind(this, 'Time', true)}>{t('Time')}</button>}                                                  

                                                    <button className={this.state.activeTab === 'Amount' ? 'qc-active' : 'qc-inactive'}
                                                        onClick={this.handleActiveTab.bind(this, 'Amount', false)}>{t('Amount')}</button>
                                                </div>
                                            </div>
                                            <hr className='gv-hr' />
                                            {this.state.activeTab === 'Time' &&
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className='time_amount_box'>
                                                            <p>How much time you need to charge the vehicle?</p>
                                                            {/* <select onClick={this.showTime} className='selectbox'>
                                                                <option value="">Select time</option>
                                                            </select> */}
                                                            <input
                                                                type='text'
                                                                readOnly
                                                                onClick={this.showTime}
                                                                className='selectbox'
                                                                placeholder='Select time'
                                                                // value={((this.state.totalHours < 10 ? '0' + this.state.totalHours : this.state.totalHours) + 'h: ' + (this.state.totalMinutes < 10 ? '0' + this.state.totalMinutes : this.state.totalMinutes) + 'm')}
                                                                value={this.state.totalHrsMins ? this.state.totalHrsMins :((this.state.totalHours >= 1 ? (this.state.totalHours + ' hrs') : "") + (this.state.totalMinutes >= 1 ? (this.state.totalMinutes + ' min') : ""))}
                                                            />
                                                            {/* <input type='number' className='selectbox' onChange={this.handleKWhChange} /> */}
                                                            
                                                            <div className='error_msg_red w3-animate-top mt-1'>{this.state.errors["enterKWh"]}</div>
                                                            <div className='error_msg_red w3-animate-top mt-1'>{this.state.errors["enterMinKWh"]}</div>
                                                            <div className='error_msg_red w3-animate-top mt-1'>{this.state.errors["enterTime"]}</div>
                                                            <p className='pt-3'>Price Estimation: <span style={{ fontWeight: "bold", float: "right" }}>{localStorage.getItem('currencySymbol')}{this.state.paymentAmount1 ? this.state.paymentAmount1 : 0}</span></p>
                                                            </div>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.activeTab === 'KWh' &&
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className='time_amount_box'>
                                                            <p>How much energy you need to charge the vehicle?</p>
                                                            {/* <select onClick={this.showTime} className='selectbox'>
                                                                <option value="">Select time</option>
                                                            </select> */}
                                                            {/* <input
                                                                type='text'
                                                                readOnly
                                                                onClick={this.showTime}
                                                                className='selectbox'
                                                                placeholder='Select time'
                                                            />*/}
                                                            {/* <input type='number' className='selectbox' onChange={this.handleKWhChange} /> */}
                                                            <input
                                                                className='selectbox'
                                                                type="text"
                                                                id="numberInput"
                                                                value={this.state.inputValue}
                                                                onChange={this.handleKWhChange}
                                                                placeholder="Enter KWh"
                                                            />
                                                            <div className='error_msg_red w3-animate-top mt-1'>{this.state.errors["enterKWh"]}</div>
                                                            <div className='error_msg_red w3-animate-top mt-1'>{this.state.errors["enterMinKWh"]}</div>
                                                            <p className='pt-3'>Price Estimation: <span style={{ fontWeight: "bold", float: "right" }}>{localStorage.getItem('currencySymbol')}{this.state.paymentAmount1 ? this.state.paymentAmount1 : 0}</span></p>
                                                            </div>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.activeTab === 'Amount' &&
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className='time_amount_box'>
                                                            <p>Enter amount</p>
                                                            <input 
                                                            className='selectbox'
                                                            type='text' 
                                                            id="numberInput"
                                                            value={this.state.inputAmountValue}
                                                            onChange={this.handleAmountChange}                                                                
                                                            placeholder="Enter amount"
                                                             />
                                                            <div className='error_msg_red w3-animate-top mt-1'>{this.state.errors["enterAmount"]}</div>
                                                            <div className='error_msg_red w3-animate-top mt-1'>{this.state.errors["enterMinAmount"]}</div>
                                                            {/* <p className='pt-3'>Total time: <span style={{ fontWeight: "bold", float: "right" }}>{(this.state.totalTime ? this.state.totalTime : '0hr 0m')}</span></p> */}
                                                            <p className='pt-3'>Total time: <span style={{ fontWeight: "bold", float: "right" }}>{this.state.totalHrsMins ? this.state.totalHrsMins : '0hrs 0min'}</span></p>
                                                            {/* <p className='pt-3'>Total KWh: <span style={{ fontWeight: "bold", float: "right" }}>{this.state.totalKWh2} KWh</span></p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    {this.state.isIntransaction ? "" : <button type="button" className='btn qr-btn' onClick={this.handleSubmit}>{t('Proceed to pay')}</button>}
                                </div>
                                        {
                                            this.state.showTimeDropdown && (
                                                <>
                                                    {/* <div className='card selecttimebox container-w'> */}
                                                    <div className={`card selecttimebox container-w slide-up-div ${this.state.showTimeDropdown ? 'show' : ''}`}>

                                                        <div className="nav_bar">
                                                            <p>&nbsp;&nbsp;&nbsp;</p>
                                                            <h3 className='text-center' style={{ color: '#00315E', fontSize: '19px', paddingTop: '5px' }}>{t('Select time')}</h3>
                                                            <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/cancel_grey.svg"} className="img-fluid rounded-start" alt="" style={{ cursor: 'pointer' }} onClick={this.closeTime} />
                                                        </div>
                                                        <div className='col-md-12 pt-3'>
                                                            <h3 style={{ textAlign: "center", marginBottom: "10px", color: "#000000", fontWeight: "bold" }}>+{this.state.hoursSelected < 10 ? '0' + this.state.hoursSelected + ' hrs' : this.state.hoursSelected + ' hrs'} : {this.state.minutesSelected < 10 ? '0' + this.state.minutesSelected + ' min' : this.state.minutesSelected + ' min'}</h3>
                                                            <div className="hours">
                                                                {
                                                                    this.state.minutesOptions.map((minutes) => <span key={minutes} className='hour' onClick={() => this.handleTimeChange(minutes)}>+{minutes}m</span>)
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="nav_bar mt-3" style={{ justifyContent: "space-between", width: "80%", margin: "0 auto", padding: "0" }}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/refresh_grey.svg"} width="32" height="32" className="img-fluid rounded-start ml-3" alt="refresh" style={{ cursor: 'pointer' }} onClick={this.resetTime} />
                                                            <button className='btn submit-btn' onClick={this.submitTime}>{t('Submit')}</button>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <InvalidQRCode message={this.state.message}></InvalidQRCode>
            )
        }
    }
}

export default withTranslation()(AddPayment);
