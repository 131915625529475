import React from 'react';
import { withTranslation } from 'react-i18next';
import { Line } from "react-chartjs-2";
import DatePicker from 'react-date-picker';
import moment from 'moment';

//common methods
import { dateformatinUTCwS } from '../_methods';

//API Imports
import * as baseUrl from '../constants';
import servicesUrl from '../../common/servicesUrl';
import APICallUtility from '../../common/APICallUtility';
import ApiMethodTypes from '../../common/ApiMethodTypes';
import { getSecurityHeaders } from '../../common/HttpRequestClass';

class comChargingPower extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMaxValues: {
        min: 0,
        suggestedMax: 10,
      },
      calendarValue: new Date(),
      startDate: "",
      fromDate: "",
      toDate: "",
      dataLine: {
        labels: [],
        dataLabels: { enabled: false },
        datasets: [
          {
            label: "kW",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "rgba(225, 204,230, .3)",
            borderColor: "#00529E",
            borderCapStyle: "butt",
            borderDash: [5, 5, 5, 5],
            borderDashOffset: 0.6,
            borderJoinStyle: "miter",
            pointBorderColor: "#00529E",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderWidth: 10,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgb(0, 0, 0)",
            pointHoverBorderColor: "rgba(220, 220, 220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: []
          }
        ],
        options: {
          legend: { display: false },
          tooltips: { enabled: false }
        }
      }
    }

    this.onChange = this.onChange.bind(this);
    this.getComChargingValues = this.getComChargingValues.bind(this);
    this.refreshComChargingPower = this.refreshComChargingPower.bind(this);
  }

  //api to get comcharging session chart
  async getComChargingValues() {
    let fromdate = this.state.fromDate;
    let todate = this.state.toDate;

    let url = baseUrl.URLPath + servicesUrl.getComChargingPower + fromdate + "&toTime=" + todate;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    document.getElementById("loader_image_div_comcharging_power").style.display = "block";

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

    if (response !== undefined) {
      let comDuration = response.map(a => moment.utc(a.fromTime).local().format("HH:mm"));
      let power = response.map(a => a.totalPower)
      let dataLine = {
        labels: comDuration,
        datasets: [{
          data: power,
          label: "kW",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "#00529E",
          borderCapStyle: "butt",
          borderDash: [5, 5, 5, 5],
          borderDashOffset: 0.6,
          borderJoinStyle: "miter",
          pointBorderColor: "#00529E",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
        }]
      }

      this.setState({ dataLine: dataLine })
    }
    document.getElementById("loader_image_div_comcharging_power").style.display = "none";
  }

  // date picker onchange function
  onChange(value, event) {
    let oneDay = new Date(value?.getTime() + 60 * 60 * 24 * 1000);

    if (value !== null && value !== "") {
      this.setState({
        calendarValue: value,
        fromDate: dateformatinUTCwS(value),
        toDate: dateformatinUTCwS(oneDay)
      }, () => {
        this.getComChargingValues();
      })
    }
  }

  componentDidMount() {
    this.setState({
      startDate: moment(new Date()).format("MM/DD/YYYY").toUpperCase(),
      value: moment(new Date()).format("MM/DD/YYYY").toUpperCase(),
      fromDate: dateformatinUTCwS(new Date().setHours(0, 0, 0, 0)),
      toDate: dateformatinUTCwS(new Date())
    }, () => {
      this.getComChargingValues();
    })
  }

  refreshComChargingPower() {
    this.setState({
      startDate: moment(new Date()).format("MM/DD/YYYY").toUpperCase(),
      value: moment(new Date()).format("MM/DD/YYYY").toUpperCase(),
      fromDate: dateformatinUTCwS(new Date().setHours(0, 0, 0, 0)),
      toDate: dateformatinUTCwS(new Date())
    }, () => {
      this.getComChargingValues();
    })
  }

  render() {
    let options = {
      responsive: true,
      scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'kW'
          },
          ticks: this.state.displayMaxValues
        }],
        xAxes: [{
          scaleLabel: {
            display: true,
          }
        }]
      }
    }

    return (
      <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success" style={{ minHeight: "346px" }}>
        <div className="content-box" >
          <div className="dashboard-subtitle mt-3" >
            <span className="pull-left">{this.props.t('combined_charging_power')}</span>
            <div className=" mt-2 mb-1 pull-right">
              <DatePicker
                onChange={this.onChange}
                value={this.state.calendarValue}
                dayPlaceholder=""
                yearPlaceholder=""
                monthPlaceholder=""
                nativeInputAriaLabel="Date"
                format="MM/d/y"
              />
              <span className="pull-left cursor-pointer">
                <img src={process.env.PUBLIC_URL + "/assets/img/refresh_btn.svg"} width="54" style={{ paddingRight: '30px', paddingTop: '3px' }} onClick={this.refreshComChargingPower} />
              </span>
            </div>
            <div className="mt-1 img-main-div">
              <Line
                data={this.state.dataLine}
                height={105}
                options={options}
              />
            </div>
          </div>
        </div>
        {/* Loader */}
        <div className="loader center" id="loader_image_div_comcharging_power" style={{ display: "none", borderRadius: "18px" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>
      </div>
    )
  }
}

export default withTranslation()(comChargingPower);
