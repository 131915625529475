import React from 'react';
import Welcome from './Welcome';
import SelectCharger from './SelectCharger';
import ChargingProcess from './ChargingProcess';
import StopChargeConfirmation from './StopChargeConfirmation';
import QRCodeDeactivated from './QRCodeDeactivated';
import InvalidQRCode from './InvalidQRCode';
import Information from './InformationPage';
import CPInformation from './CPInformationPage';
import Payment from './Payment';
import AddPayment from './AddPayment';
import TransactionSummary from "./TransactionSummary";


export default class Share extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            page: ''
        }
        this.setPage = this.setPage.bind(this);
    }

    setPage(name){
        this.setState({
            page: name
        })
    }

    componentDidMount(){
        let page = localStorage.getItem('page');
        if(page === "chargingprocess") {
            localStorage.setItem('page', page);
        } else {            
            localStorage.setItem('page', '');
        }
    }

    render(){
           
        if(this.state.page === '' && 
        (localStorage.getItem('page') === null || 
         localStorage.getItem('page') === '' || 
         localStorage.getItem('page') === undefined)){
            return <Welcome setPage={this.setPage} />
        } else if(this.state.page === 'selectCharger' || localStorage.getItem('page') === 'selectCharger') {
            return <SelectCharger setPage={this.setPage} />
        } else if(this.state.page === 'chargingprocess' || localStorage.getItem('page') === 'chargingprocess') {
            return <ChargingProcess setPage={this.setPage} />
        } else if(this.state.page === 'addpayment' || localStorage.getItem('page') === 'addpayment') {
            return <AddPayment setPage={this.setPage} />
        } else if(this.state.page === 'paymentprocess' || localStorage.getItem('page') === 'paymentprocess') {
            return <Payment setPage={this.setPage} />
        } else if(this.state.page === 'StopChargeConfirmation' || localStorage.getItem('page') === 'StopChargeConfirmation') {
            return <StopChargeConfirmation setPage={this.setPage} />
        } else if(this.state.page === 'QRCodeDeactivated' || localStorage.getItem('page') === 'QRCodeDeactivated') {
            return <QRCodeDeactivated setPage={this.setPage} />
        } else if(this.state.page === 'InvalidQRCode' || localStorage.getItem('page') === 'InvalidQRCode') {
            return <InvalidQRCode setPage={this.setPage} />
        } else if(this.state.page === 'informationpage' || localStorage.getItem('page') === 'informationpage') {
            return <Information setPage={this.setPage} />
        } else if(this.state.page === 'cpinformationpage' || localStorage.getItem('page') === 'cpinformationpage') {
            return <CPInformation setPage={this.setPage} />
        }else if(this.state.page === 'transactionSummary' || localStorage.getItem('page') === 'transactionSummary'){
            return <TransactionSummary setPage = {this.setPage}/>
        }
         else {
            return <></>
        }
     
    }
}