let SchedulerDataConfig = {
    dayStartFrom: 0,
    dayStopTo: 23,
    nonAgendaDayCellHeaderFormat: 'HH:mm',
    minuteStep: 10,
    schedulerWidth: '75%',
    startResizable: true,
    endResizable: true,
    calendarPopoverEnabled: true,
    eventItemPopoverEnabled: false,
    creatable: true,
    checkConflict: true,
    resourceName:"",
    movable: false
}

export default SchedulerDataConfig;