import React from 'react';
import { withTranslation } from 'react-i18next';
class InvalidQRCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleCancelSubmit = this.handleCancelSubmit.bind(this)
        this.backButton = this.backButton.bind(this)
    }


    backButton() {
        this.props.setPage('InvalidQRCode');
        localStorage.setItem('page', 'InvalidQRCode');
    }

    handleCancelSubmit() {
        window.open("about:blank", "_self");
        window.close();
    }

    render() {
        const{t}=this.props;
        var message = this.props.message;
        return (
            <>
               <div className="welcome-parent bg_white">
                  <div className="container-fluid">
                         <nav className="nav_bar">
                            <a className="navbar-brand" >
                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/close.svg"} className="img-fluid rounded-start" alt="" onClick={this.handleCancelSubmit.bind(this)} />
                            </a>
                            {/* <span className="navbar-brand margin-auto">
                                <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/logo.svg"} style={{width: '46px'}} alt="logo" />
                            </span>
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <span><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/germany.svg"} style={{ width: "32px" }} className="d-inline-block align-top logo" alt="germany" /></span> &nbsp;
                                    <span><img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/english.svg"} style={{ width: "32px" }} className="d-inline-block align-top logo" alt="english" /></span>
                                </li>
                            </ul> */}
                        </nav>
                        <div className='row justify-content-center'>
                            <div className="content-div pl-0 pr-0">
                                <div className='card-body d-flex flex-column justify-content-center' style={{ padding: '1rem 2rem' }}>
                                    <div className="mx-auto text-center ">
                                            { 
                                               ( message === "qr_code_data_not_found" || message === "qr_code_status" || message === "qr_code_is_inactive")  ?   <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/invalid.svg"} style={{ width: "200px"}} className="img-fluid rounded-start chargeicon pb-4" alt="error" />
                                               : ( message === "qr_code_expired" || message === "reached_energy_limit" || message === "group_not_exists" ) ?   <img src={process.env.PUBLIC_URL + "/assets/img/shareqrcode/expired.svg"} style={{ width: "200px"}} className="img-fluid rounded-start chargeicon pb-4" alt="error" /> : "" 
                                            }
                                      
                                        {/* <div className="card-text" style={{paddingBottom: '20px', color: '#757D86'}}>QR Code is deactivated, Please reach out to the issuer of the QR Code.</div> */}
                                        <h5 className="invalid-txt dblue-title" style={{ paddingBottom: '20px' }}>
                                            { 
                                               ( message === "qr_code_data_not_found" || message === "qr_code_status" || message === "qr_code_is_inactive")  ? t('voucher_invalid') 
                                               : ( message === "qr_code_expired" || message === "reached_energy_limit" || message === "group_not_exists" ) ? t('voucher_expired') : "" 
                                            }
                                            </h5>
                                        <div className="footer-blue mb-5">
                                        { 
                                               ( message === "qr_code_data_not_found" || message === "qr_code_status" || message === "qr_code_is_inactive" )  ? t('voucher_invalid_msg') 
                                               : ( message === "qr_code_expired" || message === "reached_energy_limit" || message === "group_not_exists" ) ? t('voucher_expired_msg') : ""
                                            }
                                            
                                            </div>
                                    </div>
                                    <button type="button" className='btn qr-btn mt-3' onClick={this.handleCancelSubmit.bind(this)}>{t('close')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withTranslation()(InvalidQRCode);
