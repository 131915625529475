import React from 'react';
import { Alert } from 'reactstrap';

//API Imports
import * as baseUrl from './constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

class updatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { oldpwd: this.props.location.state.syspassword, newpwd: '' },
      update_Password: false,
      errors: {},
      message: "",
      sucess: false,
      colour: 'sucess',
      Tokenmsg: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    var oldpwd = this.state.fields['oldpwd'];
    var newpwd = this.state.fields['newpwd'];
    let errors = {};
    let validPassword = false;

    if (newpwd === '') {
      errors["pwdempty"] = "Password cannot be empty";
    } else if (newpwd.length <= 20) {
        if (!newpwd.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&+=])[A-Za-z\\d!@#$%^&+=]{8,}$")) {
          errors["entervalidpwd"] = "Password must have at least 8 characters that include at least 1 lowercase character (a-z), 1 uppercase character (A-Z), 1 number (0-9) and 1 sepcial character (!@#$%^&+=)";
        } else{
        validPassword = true;
        }
    }
    else {
     errors["pwdexceedchars"] = "Password must not be more than 20 characters.";
   }

    if (newpwd !== '' && validPassword) {
      let url = baseUrl.URLPath + servicesUrl.updatePassword;
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();

      // payload
      let payload = {
        "oldPassword": oldpwd,
        "newPassword": newpwd
      }

      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      if (response.status === 400) {
          if(response.message!==""){
            if(response.message==="password_shouldn_t_contain_name")
            errors["entervalidpwd"] = "Password shouldn't contain first name or last name";
          }else if(response.message==="password_already_used"){
            errors["entervalidpwd"] = "Password is already used.";
          }else{
            errors["entervalidpwd"] = "Password must have at least 8 characters that include at least 1 lowercase character (a-z), 1 uppercase character (A-Z), 1 number (0-9) and 1 sepcial character (!@#$%^&+=)";
          }
          this.setState({errors:errors});
          // localStorage.clear()
      } else if (response.status === 404) {
        errors["pwdempty"] = "Sorry the reset password link has expired.";
        localStorage.clear()
      } else if (response.status === "FAILURE") {
        errors["pwdempty"] = response.errorMessage;
      } else {
        this.setState({ message: "Your password has been updated successfully!", colour: 'success', update_Password: true }, () => {
          this.onShowAlert();
        })
        localStorage.clear()
      }
    }

    this.setState({ errors: errors });
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 50000000)
    });
  }

  handleLogin() {
    this.props.history.push('/');
  }

  render() {
    if (this.state.update_Password === true) {
      return (
        <div className="login-page">
          <header>
            <section className="view intro-2">
              <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                      <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                        <div className="card-body">
                          <div className="col-md-12 login_icon">
                            <img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{ width: "138px" }} alt="" />
                          </div>
                          <p class="h4 text-center mb-3 pb-3 pt-2 title_font">Update Password</p>
                          <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                          <div className="text-center" >
                            <button type='submit' className="btn login-btn btn-lg waves-effect waves-light" onClick={this.handleLogin}>Sign In</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </header>
        </div>
      )
    } else {
      return (
        <div className="login-page">
          <header>
            <section className="view intro-2">
              <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                      <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                        <div className="card-body">
                          <div className="col-md-12 login_icon">
                            <img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{ width: "138px" }} alt="" />
                          </div>
                          <p className="h4 text-center mb-3 pb-3 pt-2 title_font">Update Password</p>
                          <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                          <div className="md-form">
                            <i className="fas fa-lock prefix white-text" />
                            <input type="password" disabled id="orangeForm-pass" className="form-control" value={this.state.fields["oldpwd"]} />
                            <label htmlFor="orangeForm-pass" className="active">Old-Password</label>
                          </div>
                          <div className="md-form">
                            <i className="fas fa-lock prefix white-text" />
                            <input type="password" id="orangeForm-name" className="form-control active" onChange={this.handleChange.bind(this, "newpwd")} value={this.state.fields["newpwd"]} />
                            <label htmlFor="orangeForm-name">New-Password</label>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["pwdempty"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidpwd"]}</span></p></span><span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["pwdexceedchars"]}</span></p></span>
                          </div>
                          <div className="text-center">
                            <button type='submit' className="btn login-btn btn-lg waves-effect waves-light mb-4" onClick={this.handleSubmit}>submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </header>
        </div>
      )
    }
  }
}

export default updatePassword;